import { Pipe, PipeTransform } from '@angular/core';
import { CountryCodeList, CountryEnum } from '@app/models/country-code-list';

@Pipe({name: 'CountryCode'})
export class CountryCodePipe implements PipeTransform {

  //transform the input country name to corresponding country code
  transform(country: CountryEnum): string {
    if (country) {
      const countryCodeEntry = CountryCodeList.find(c => c.id === country);
      return countryCodeEntry ? countryCodeEntry.code : country;
    } else {
      return '';
    }
  }
}
