import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";

@Injectable()
export class DocEstateService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.DOCESTATE;
  public displayUploader = true;

  constructor(private http: HttpClient) {}

  placeDocEstateOrder(orderId: string) {
    return this.http.post(this.endpoint + "/create-order/" + orderId, null);
  }

  getServiceInfo(postalCode: string) {
    return this.http.get<boolean>(this.endpoint + "/get-teilungs-info?zip=" + postalCode);
  }
}
