import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Package } from "@app/models/package";
import { FormGroup } from "@angular/forms";
import { AudioItem } from "@app/interfaces/audio-item.interface";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { environment } from "@environments/environment";

@Component({
  selector: "app-imo-video-editing-options",
  templateUrl: "./imo-video-editing-options.component.html",
  styleUrls: ["./imo-video-editing-options.component.css"],
})
export class ImoVideoEditingOptionsComponent implements OnInit {
  @Input() package: Package;
  @Input() parentForm: FormGroup;
  @Output() selectedItemEmitter: EventEmitter<AudioItem> = new EventEmitter<AudioItem>();
  @ViewChild("videoPlayer") videoPlayer;
  audioItems: AudioItem[];
  isPlayingVideo = false;

  videoEditingOptions: {
    label: string;
    isSelected: boolean;
    isDisabled: boolean;
    formName: string;
    description: string;
    tooltip: string;
    src?: string;
  }[] = [
    {
      label: "Video mit Musik hinterlegen",
      isSelected: false,
      isDisabled: false,
      formName: "backgroundMusic",
      description: "",
      tooltip: "Das Video kann nach Wahl mit Musik hinterlegt werden. Bitte wählen Sie dazu einen der 9 Titel aus.",
    },
    {
      label: "Logo einfügen",
      isSelected: false,
      isDisabled: false,
      formName: "logo",
      description: "",
      tooltip: "Ihr Logo wird standardmäßig unten rechts im Bild eingefügt.",
    },
    {
      label: "Videotitel hinzufügen",
      isSelected: false,
      isDisabled: false,
      formName: "title",
      description: "",
      tooltip: "",
      src: environment.platformUrl + "assets/video/title_example_edit.mp4",
    },
    {
      label: "Persönliches Intro/Outro hinzufügen",
      isSelected: false,
      isDisabled: false,
      formName: "introOrOutro",
      description: "",
      tooltip:
        "Auf Wunsch kann ihr persönliches Intro/Outro ins Video integriert werden. Stellen Sie uns dazu die Dateien im MP4 oder MOV Format entsprechend gekennzeichnet zur Verfügung.",
    },
    {
      label: "Nachbarhäuser weichzeichnen",
      isSelected: false,
      isDisabled: false,
      formName: "blurred",
      description: "",
      tooltip:
        "Nachbarhäuser werden bei dieser Option unkenntlich gemacht. Private Details wie Kennzeichen, Gesichter etc. werden standardmäßig weichgezeichnet.",
    },
  ];

  constructor() {}

  get primaryTitle() {
    return this.parentForm.controls["primaryTitle"];
  }

  get secondaryTitle() {
    return this.parentForm.controls["secondaryTitle"];
  }

  get backgroundMusic() {
    return this.parentForm.controls["backgroundMusic"];
  }

  ngOnInit() {
    this.audioItems = this.package["background_music"];
  }

  onTitleChange(item: AudioItem) {
    this.selectedItemEmitter.emit(item);
  }

  changeTitle($event: MatCheckboxChange) {
    if ($event.checked) {
      this.parentForm.controls["primaryTitle"].enable();
      this.parentForm.controls["secondaryTitle"].enable();
    } else {
      this.parentForm.controls["primaryTitle"].disable();
      this.parentForm.controls["secondaryTitle"].disable();
    }
  }

  playVideo() {
    this.videoPlayer.nativeElement.play();
  }

  stopVideo() {
    this.videoPlayer.nativeElement.pause();
  }
}
