<ng-container *ngIf="!hasCardTemplate">
  <h5>
    <span [ngClass]="titleCustomClass">Weiterleitung für E-Mail Benachrichtigungen</span>
    <i
      [tooltip]="tooltipText"
      [adaptivePosition]="false"
      class="fas fa-info-circle text-primary mx-1"
      placement="right"
    ></i>
  </h5>
  <ng-container *ngTemplateOutlet="emailTemplate"> </ng-container>
</ng-container>

<ng-container *ngIf="hasCardTemplate">
  <mat-card appearance="outlined" class="email-forwarding-card mb-4 flex-grow-1">
    <mat-card-title>
      E-mail Weiterleitung
      <i
        [tooltip]="tooltipText"
        [adaptivePosition]="false"
        class="fas fa-info-circle text-primary mx-1"
        placement="right"
      ></i>
    </mat-card-title>
    <mat-card-subtitle>Setzen Sie Ihre Kollegen automatisch in cc.</mat-card-subtitle>
    <mat-card-content>
      <ng-container *ngTemplateOutlet="emailTemplate"> </ng-container>
    </mat-card-content>
  </mat-card>
</ng-container>

<ng-template #emailTemplate>
  <div [formGroup]="form" class="row">
    <div class="col-12">
      <div class="form-group">
        <div class="input-group">
          <input
            type="email"
            class="form-control"
            formControlName="email"
            placeholder="Weiterleitung der Auftrags E-mails"
            (keyup)="onKeyUp($event)"
          />
          <div class="input-group-append">
            <button
              (click)="add()"
              class="btn btn-add-user"
              [ngClass]="{
                'btn-primary': email.value,
                'btn-secondary': !email.value
              }"
            >
              <i class="fas fa-user-plus"></i>
              <i *ngIf="pending" class="fa fas fa-spinner fa-pulse mx-1"></i>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="isFormSubmitted && email.invalid && email.value" class="alert alert-warning mb-2">
        <div *ngIf="email.errors?.required || email.errors?.pattern">
          {{ CONSTANTS.VALIDATION_ERROR_MSG.EMAIL_INVALID }}
        </div>
        <div *ngIf="email.errors?.invalidEmail">{{ email.errors.invalidEmail }}</div>
      </div>
    </div>
  </div>
  <div class="emails-list-container">
    <div *ngIf="emails.length === 0">
      Sie haben noch keine weitere Person hinzugefügt, die außer Ihnen E-Mails zu diesem Auftrag erhält.
    </div>
    <div *ngFor="let item of emails">
      <span
        *ngIf="item !== removingEmail && emails.length > 1"
        (click)="remove(item)"
        class="fas fa-times-circle text-danger file-remove mx-1"
      ></span>
      <i *ngIf="item === removingEmail" class="fa fas fa-spinner fa-pulse mx-1"></i>
      <span>{{ item }}</span>
    </div>
  </div>
</ng-template>
