import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";
import { Email } from "@app/interfaces/email.interface";

@Injectable({
  providedIn: "root",
})
export class SendEmailService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.SENDEMAIL;

  constructor(private http: HttpClient) {}

  sendEmail(emailData) {
    this.http.post<Email>(this.endpoint, emailData).subscribe((val) => {
      console.log("POST call successful value returned in body", val);
      return val;
    });
  }
}
