import { Pipe, PipeTransform } from "@angular/core";
import { AuthService } from "@app/auth/auth.service";
import { MoneyService } from "@app/_services/money.service";
import { CONSTANTS } from "@app/util/constants";
import { User } from "@app/interfaces/user.interface";

@Pipe({
  name: "getVat",
})
export class GetVatPipe implements PipeTransform {
  constructor(private auth: AuthService, private ms: MoneyService) {}

  transform(value: number, ...args: unknown[]): number {
    const loggedInUser = (args[0] as User) || this.auth.myUserObservable;

    if (loggedInUser?.isPrivateIndividual) {
      // show VAT amount for given price
      value = this.ms.unformat(this.ms.toFixed(value * (CONSTANTS.VAT_TAX_PERCENTAGE / 100), 2));
    }

    return value;
  }
}
