<!-- Page-Content -->
<div id="paddingdiv">
  <!-- Aligining content with Logo -->
  <div class="justify-content-center text-center limitWidth300 mt-4">
    <!-- Logo -->
    <img
      class="mb-4 object-fit-contain"
      src="assets/images/Logo_png_RGB_dunkelgruen.png"
      height="94px"
      width="300px"
      *ngIf="!showSpkLogo"
    />
    <img class="mb-4 maxH140" src="assets/images/SparkassenImmo_negativ.png" *ngIf="showSpkLogo" />
    <ng-container *ngIf="!formSubmitted">
      <!-- Password Reset Form -->
      <h3 class="b">Passwort vergessen</h3>
      Geben Sie die E-Mail-Adresse ein, mit der Sie auf der Imogent-Plattform registriert sind.
      <form>
        <!-- E-Mail -->
        <div class="form-group">
          <!-- E-Mail Input -->
          <label class="font-italic b sr-only" for="email">E-Mail</label>
          <input
            type="text"
            class="form-control mb-2"
            [ngClass]="{ 'form-control-sImmo': showSpkLogo }"
            placeholder="Email"
            #userEmail
            required
            autofocus
          />
        </div>

        <!-- Form Buttons: Reset & Cancel -->
        <div class="form-group">
          <!-- Cancel -->
          <a routerLink="/login" class="btn btn-danger">Abbrechen</a>
          <!-- Reset -->
          <button
            type="submit"
            class="btn btn-primary"
            (click)="sendLink(userEmail.value)"
            [ngClass]="{ 'btn-spk': showSpkLogo, 'btn-primary': !showSpkLogo }"
          >
            Link senden
          </button>
        </div>
      </form>
    </ng-container>

    <div class="mb-3 text-left justify-content-start" *ngIf="formSubmitted">
      <h3 class="b">Link versendet</h3>
      Wir haben einen Link zum zurücksetzen des Passworts an die angegebene E-Mail-Adresse gesendet. Sollten Sie keine
      E-Mail von uns erhalten haben, prüfen Sie bitte Ihren Spam-Ordner und die korrekte Schreibweise der
      E-Mail-Adresse.
    </div>
    <div>
      <button (click)="goBack()" class="btn btn-primary mb-5" *ngIf="formSubmitted">Zurück zur Startseite</button>
    </div>

    <!-- Footer -->
    <section>
      <p *ngIf="showSpkLogo">
        <i>powered by</i>
      </p>
      <p class="b nomarbot">&copy;{{ year }} IMOGENT GmbH</p>
      <!-- Privacy Policy & Imprint -->
      <a
        href="https://www.imogent.de/datenschutz"
        target="_blank"
        [ngClass]="{ 'my-link': !showSpkLogo, 'grey-link': showSpkLogo }"
        >Datenschutz</a
      ><span> | </span
      ><a [ngClass]="{ 'my-link': !showSpkLogo, 'grey-link': showSpkLogo }" href="https:www.imogent.de/impressum"
        >Impressum</a
      >
    </section>
  </div>
</div>
