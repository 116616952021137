import { Component, OnInit } from "@angular/core";
import PerfectScrollbar from "perfect-scrollbar";
import { Observable } from "rxjs";
import { AuthService } from "@app/auth/auth.service";
import { LoaderService } from "@app/_services/loader.service";
import { SidebarService } from "@app/sidebar/sidebar.service";
import { TranslateService } from "@ngx-translate/core";
import { UserRoleEnum } from "@app/models/user-role-list";
import { CompanyRoleEnum } from "@app/models/company-role-list";
import { GlobalService } from "@app/_services/global.service";

declare const $: any;

// Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
  roles: UserRoleEnum[];
  companyRoles?: CompanyRoleEnum[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/home",
    title: "sidebar.home",
    type: "link",
    icontype: "dashboard.svg",
    roles: [UserRoleEnum.Customer, UserRoleEnum.Administrator, UserRoleEnum.ServiceProvider],
  },
  {
    path: "/orderoverview/orderlist",
    title: "sidebar.orders",
    type: "link",
    icontype: "business_center.svg",
    roles: [UserRoleEnum.Customer, UserRoleEnum.Administrator, UserRoleEnum.ServiceProvider],
  },
  {
    path: "/orderoverview/savedorders",
    title: "sidebar.savedOrders",
    type: "link",
    icontype: "save.svg",
    roles: [UserRoleEnum.Customer, UserRoleEnum.Administrator],
  },
  {
    path: "/createorder/selectservice",
    title: "Service buchen",
    type: "link",
    icontype: "note_add.svg",
    roles: [UserRoleEnum.Customer, UserRoleEnum.Administrator],
  },
  {
    path: "/management-board",
    title: "Management Board",
    type: "link",
    icontype: "bar_chart.svg",
    roles: [UserRoleEnum.Customer, UserRoleEnum.Administrator],
    companyRoles: [CompanyRoleEnum.CompanyManager],
  },
  {
    path: "/realestate",
    title: "Immobilien",
    type: "link",
    icontype: "store.svg",
    roles: [UserRoleEnum.Customer, UserRoleEnum.Administrator],
  },
  {
    path: "/payments",
    title: "sidebar.payments",
    type: "link",
    icontype: "attach_money.svg",
    roles: [UserRoleEnum.Administrator],
  },
  {
    path: "/invoices",
    title: "Rechnungen",
    type: "link",
    icontype: "credit_card.svg",
    roles: [UserRoleEnum.Customer, UserRoleEnum.Administrator],
  },
  // Maybe this part will be needed in the future, so it probably makes sense to just hide it instead of removing it
  // {
  //   path: "/faq",
  //   title: "sidebar.requirements",
  //   type: "link",
  //   icontype: "question_answer",
  //   roles: [UserRoleEnum.ServiceProvider, UserRoleEnum.Administrator],
  // },
  {
    path: "/users",
    title: "User Management",
    type: "link",
    icontype: "supervisor_account.svg",
    roles: [UserRoleEnum.Administrator],
  },
  {
    path: "/companies",
    title: "Unternehmen",
    type: "link",
    icontype: "domain.svg",
    roles: [UserRoleEnum.Customer, UserRoleEnum.Administrator],
  },
  {
    path: "/accounting-positions",
    title: "Preisverwaltung",
    type: "link",
    icontype: "euro_symbol.svg",
    roles: [UserRoleEnum.Administrator],
  },
  {
    path: "/discounts",
    title: "Rabatte",
    type: "link",
    icontype: "local_offer.svg",
    roles: [UserRoleEnum.Administrator],
  },
  {
    path: "/product-bundles",
    title: "Produktpakete",
    type: "link",
    icontype: "widgets.svg",
    roles: [UserRoleEnum.Administrator],
  },
  {
    path: "/threesixtylist",
    title: "360 Degree Views",
    type: "link",
    icontype: "3d_rotation",
    roles: [],
  },
  {
    path: "/analytics",
    title: "Analytics",
    type: "link",
    icontype: "trending_up.svg",
    roles: [UserRoleEnum.Administrator],
  },
  {
    path: "/news-slides-management",
    title: "Das ist neu",
    type: "link",
    icontype: "news.svg",
    roles: [UserRoleEnum.Administrator],
  },
];

@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
  styleUrls: ["sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  public menuItems: RouteInfo[];
  public isCollapsed = false;
  public myUserObservable: Observable<any>;
  userRoleEnum = UserRoleEnum;
  public imoLogoObj = "ImoLogoFull.png";
  public misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
    sidebar_mini_active: false,
  };

  constructor(
    public sidebarservice: SidebarService,
    public authService: AuthService,
    public loader: LoaderService,
    public translate: TranslateService,
    public gs: GlobalService
  ) {}

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    if (!this.misc.active_collapse) {
      this.isCollapsed = true;
    }
    this.gs.componentDataObs.subscribe((data) => {
      if (
        data?.hasOwnProperty("minimizeSidebar") &&
        ((data?.minimizeSidebar && !this.misc.sidebar_mini_active) ||
          (!data?.minimizeSidebar && this.misc.sidebar_mini_active))
      ) {
        this.minimizeSidebar();
      }
    });
  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector(".sidebar .sidebar-wrapper");
      const ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
    }
  }
  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf("MAC") >= 0 || navigator.platform.toUpperCase().indexOf("IPAD") >= 0) {
      bool = true;
    }
    return bool;
  }
  inArray(val: any, arr: any[]) {
    return arr.indexOf(val) > -1;
  }

  //for foldable sidebar toggle
  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];

    if (this.misc.sidebar_mini_active) {
      body.classList.remove("sidebar-mini");
      this.misc.sidebar_mini_active = false;
      this.imoLogoObj = "ImoLogoFull.png";
    } else {
      body.classList.add("sidebar-mini");
      this.misc.sidebar_mini_active = true;
      this.imoLogoObj = "imoIcon.png";
    }
  }
}
