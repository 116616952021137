<mat-dialog-content>
  <div class="row">
    <div class="col-6">
      <span class="price-header"><b>Detailansicht</b></span>
    </div>
    <div class="col-6">
      <button mat-button class="close-icon float-right" (click)="closeEditModal()">
        <mat-icon class="white-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 product-price">
      <label class="form-label">Name</label>
      <input type="string" matInput placeholder="Name" class="form-control" [(ngModel)]="tempAccountingPosition.name" />
    </div>
  </div>
  <div class="row">
    <div class="col-12 product-price">
      <label class="form-label">Preis</label>
      <input
        type="number"
        matInput
        placeholder="Preis"
        class="form-control"
        [(ngModel)]="tempAccountingPosition.price"
      />
    </div>
  </div>
  <div class="row" class="mt-2">
    <span *ngIf="tempAccountingPosition.specialConditions?.length" class="special-price"><b>Spezialpreise</b></span>
  </div>
  <div
    class="row"
    *ngIf="tempAccountingPosition.specialConditions && tempAccountingPosition.specialConditions.length > 0"
  >
    <div class="col-md-6">
      <div class="edit-delete">
        <button class="option-bg pr-height" color="primary">
          <mat-checkbox [formControl]="selectAll" color="primary" (change)="selectAllRows($event)">
            <span>Alle markieren</span>
          </mat-checkbox>
        </button>
        <button class="option-bg" color="primary" (click)="deleteAllRows()" *ngIf="hasSelectedRows">
          <i class="fa fa-trash pr" aria-hidden="true"> </i>
          <label class="edit-delete-label">Löschen</label>
        </button>
        <button class="option-bg" color="primary" (click)="openCompanySearchModal()" *ngIf="hasSelectedRows">
          <i class="fa fa-edit pr" aria-hidden="true"> </i>
          <label class="edit-delete-label">Preis ändern</label>
        </button>
      </div>
    </div>
    <div class="col-6">
      <div class="generic-search">
        <label>Suche</label>
        <input
          type="text"
          class="form-control product-search"
          [formControl]="companyFilterCtrl"
          placeholder="Suche nach Unternehmen"
        />
        <i class="fas fa-search" [hidden]="companyFilterCtrl.value"></i>
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="tempAccountingPosition.specialConditions && tempAccountingPosition.specialConditions.length > 0"
  >
    <div class="col-md-6 product-price">
      <div class="price-heading">
        <label>Unternehmen</label>
      </div>
    </div>
    <div class="col-md-6 product-price">
      <label>Preis</label>
    </div>
  </div>
  <div class="row" *ngFor="let condition of filteredCompany | async; let i = index">
    <div class="col-md-6 product-price" *ngIf="condition.companyName != ''">
      <div class="price-heading"></div>
      <div class="price-name">
        <mat-checkbox
          color="primary"
          [checked]="condition.isSelected"
          class="price-cb"
          (change)="selectSingleRow($event, condition.companyId)"
        >
        </mat-checkbox>
        <input
          type="string"
          matInput
          placeholder="companyName"
          class="form-control"
          [(ngModel)]="condition.companyName"
        />
      </div>
    </div>
    <div class="col-md-6 product-price" *ngIf="condition.companyName != ''">
      <div class="price-name">
        <input type="number" matInput placeholder="Preis" class="form-control" [(ngModel)]="condition.price" />
        <div class="delete-item">
          <button color="primary" (click)="deleteSingleRow(i)">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row" class="mt-2">
    <div class="col-md-12">
      <button class="add-company" (click)="openCompanySearchModal()">
        <i class="fa fa-plus" aria-hidden="true"></i>
        <label class="search-label">{{ searchLabelText }}</label>
      </button>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-12 btn-save-cancel">
      <button class="border btn-cancel" (click)="closeEditModal()">VERWERFEN</button>
      <button class="btn" (click)="saveEditModal()">SPEICHERN</button>
    </div>
  </div>
</mat-dialog-content>
