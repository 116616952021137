<div id="modal-content-wrapper">
  <header id="modal-header">
    <h2 id="modal-title">Rechnungsdaten anpassen</h2>
    <app-info-box *ngIf="currentOrder.billedAmount">
      <b>Hinweis:</b> Bereits erstellte Rechnungen sind von der Änderung nicht betroffen.
    </app-info-box>
  </header>
  <section id="modal-body">
    <div class="form-row mb-3">
      <div class="col-12">
        <p class="mb-0 font-weight-bold">
          <b>Zahlungsmethode</b> (Die Rechnungsadresse kann nur angepasst werden, wenn "Kauf auf Rechnung" ausgewählt
          wurde)
        </p>
        <div class="select-wrapper fa fa-angle-down">
          <select
            class="form-control no-background"
            [(ngModel)]="selectedPaymentOption"
            (change)="onPaymentOptionChanged()"
          >
            <option *ngFor="let paymentOption of paymentOptionList" [value]="paymentOption.id">
              {{ paymentOption.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 text-danger" *ngIf="selectedPaymentOption === PaymentOptionEnum.Collective">
        <span *ngIf="billingAddressType === BillingAddressTypeEnum.CompanyAddress">
          Bei Sammelrechnungen wird die Adresse des Unternehmens als Rechnungsadresse verwendet.</span
        >
        <span *ngIf="billingAddressType === BillingAddressTypeEnum.ProfileAddress">
          Bei Sammelrechnungen (wöchentlich) wird die Adresse des Nutzerprofils als Rechnungsadresse verwendet.</span
        >
      </div>
    </div>
    <app-billing-address-form
      (billingAddressChanged)="onBillingAddressChanged($event)"
      [initialBillingAddress]="currentOrder.billingAddress"
      [isDisabled]="!canEditBillingAddress"
      #billingAddressForm
    >
    </app-billing-address-form>
  </section>
  <footer id="modal-footer">
    <button
      mat-raised-button
      class="btn btn-sm btn-primary float-right"
      (click)="saveBillingInformation()"
      [disabled]="!isBillingAddressValid"
    >
      Speichern
    </button>
    <button mat-raised-button class="btn btn-sm btn-back float-right" (click)="closeModal()">Zurück</button>
  </footer>
</div>
