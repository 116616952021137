import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { LoaderService } from "@app/_services/loader.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { GlobalService } from "@app/_services/global.service";
import { LANGUAGE } from "@app/language/language";
import { UploadService } from "@app/_services/upload.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { AuthService } from "@app/auth/auth.service";
import { ListViewEnum, listViewList } from "@app/models/list-view-list";
import { UserRoleEnum } from "@app/models/user-role-list";
import { CompanyRoleEnum } from "@app/models/company-role-list";
import { ListView } from "@app/interfaces/list-view.interface";
import { lastValueFrom, Subscription } from "rxjs";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { SubcollectionFileModel } from "@app/models/subcollectionFileModel";
import { InvoiceLogicService } from "@app/_services/invoice-logic.service";
import { Invoice } from "@app/interfaces/invoice.interface";
import { PaymentStatusEnum } from "@app/models/paymentStatusEnum";
import { CONSTANTS } from "@app/util/constants";
import { environment } from "@environments/environment";
import {
  dateToFirestoreTimestamp,
  getFirebaseBaseURL,
  translateAppListStrings,
  updateInTableDataSource,
} from "@app/util/helper";
import { NgxSmartModalService } from "ngx-smart-modal";
import { NotificationsService } from "@app/_services/notifications.service";
import { DunningTypeEnum } from "@app/models/dunningType.enum";
import { SetInvoicePaidResponse } from "@app/interfaces/setInvoicePaidResponse.interface";
import { MatCheckbox } from "@angular/material/checkbox";
import { CountryEnum } from "@app/models/country-code-list";

@Component({
  selector: "app-invoices",
  providers: [DatePipe],
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.css"],
})
export class InvoicesComponent implements OnInit, OnDestroy {
  role: string;
  columnsToDisplay = ["name", "lexOfficeInvoiceNumber", "company", "total", "createdOn", "dueDate", "status"];
  currentInvoiceData: Invoice;
  isTaxFreeInvoice: boolean;
  invoiceNumber = "";
  success = false;
  uploading = false;
  uploaded = false;
  public state = "default";
  invoice: string;
  invoiceDataSrc = new MatTableDataSource([]);
  selectedListViewId: ListViewEnum;
  listViewList: ListView[] = [];
  companyRoleEnum = CompanyRoleEnum;
  userRoleEnum = UserRoleEnum;
  paymentStatusEnum = PaymentStatusEnum;
  now: Date;
  openAmount: number;
  companyRole: CompanyRoleEnum;
  constants = CONSTANTS;
  environment = environment;
  firebaseBaseURL: string;
  newDueDate: Date;
  dunningTypeLabels: { [id: string]: string } = {};
  isSetPaidConfirmed: boolean;
  setPaidResult: SetInvoicePaidResponse[];
  keyValueQueryMap = new Map();
  invoiceCount: number;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("paginator") paginator: MatPaginator;
  @ViewChild("dunningsStoppage") dunningsStoppage: MatCheckbox;

  filterValues = {};
  statusList = [
    {
      label: LANGUAGE.app_list_strings.invoice_status_list.created,
      value: "created",
    },
    {
      label: LANGUAGE.app_list_strings.invoice_status_list.invoice,
      value: "invoice",
    },
    {
      label: LANGUAGE.app_list_strings.invoice_status_list.paid,
      value: "paid",
    },
    {
      label: LANGUAGE.app_list_strings.invoice_status_list.canceled,
      value: "canceled",
    },
  ];
  private subscriptions: Subscription[] = [];

  constructor(
    private ivls: InvoiceLogicService,
    public datePipe: DatePipe,
    public loader: LoaderService,
    public gs: GlobalService,
    private ns: NotificationsService,
    private uploadService: UploadService,
    public auth: AuthService,
    private router: Router,
    private ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.role = this.auth.myUserObservable.role;
    this.companyRole = this.auth.myUserObservable.companyRole;
    if (this.role === UserRoleEnum.Customer) {
      this.setSelectedViewListId();
      this.setListViewList();
    }
    this.setTableFilter();
    this.loadInvoices();
    if (
      (this.role !== UserRoleEnum.Administrator && this.companyRole === this.companyRoleEnum.CompanyManager) ||
      this.companyRole === this.companyRoleEnum.CompanyAccountant
    ) {
      this.subscriptions.push(this.ivls.getCompanyInvoices().subscribe());
    }
    this.now = new Date();
    this.firebaseBaseURL = getFirebaseBaseURL(CONSTANTS.COLLECTIONS.INVOICES);
    Object.values(DunningTypeEnum).forEach((value) => {
      this.dunningTypeLabels[value] = translateAppListStrings("dunning_types", value);
    });
    this.handleDeepLink();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Handles a deep link for the invoice number -> Opens the invoice in detail view.
   */
  handleDeepLink() {
    this.subscriptions.push(
      this.route.paramMap.subscribe(async (params) => {
        const invoiceNumberQueryParam = params.get("invoiceNumber");
        if (invoiceNumberQueryParam?.length) {
          const invoiceQueryMap = new Map([["lexOfficeInvoiceNumber", invoiceNumberQueryParam]]);

          try {
            const invoicesObservable = this.ivls.getAllInvoicesWithPaginator(
              0,
              100,
              "createdOn",
              "asc",
              invoiceQueryMap
            );
            const invoices = await lastValueFrom(invoicesObservable);

            const selectedInvoice = invoices.data[0]; // There should be only one invoice since the invoiceNumber is unique!
            if (selectedInvoice) {
              this.openDetailPage(selectedInvoice);
            }
          } catch (e) {
            console.error(`failed to open invoice deep Link: ${e}`);
          }
        }
      })
    );
  }

  private setSelectedViewListId() {
    this.selectedListViewId = ListViewEnum.MyList;
  }

  private setListViewList() {
    this.listViewList = listViewList.filter((item) => {
      return (
        item.id === ListViewEnum.MyList ||
        ((this.companyRole === CompanyRoleEnum.CompanyAccountant ||
          this.companyRole === CompanyRoleEnum.CompanyManager) &&
          item.id === ListViewEnum.CompanyList)
      );
    });
  }

  onListViewChange(selectedListViewId: ListViewEnum, userRole?: UserRoleEnum) {
    this.selectedListViewId = selectedListViewId;
    if (this.selectedListViewId === ListViewEnum.CompanyList) {
      this.invoiceDataSrc = new MatTableDataSource(this.ivls.companyInvoices);
    } else {
      this.loadInvoices();
    }
  }

  /**
   * Opens invoices detail view
   */
  async openDetailPage(row: Invoice) {
    this.auth.showLoader.emit(true);
    try {
      const invoice = await this.ivls.getInvoice(row.id);
      this.currentInvoiceData = invoice;
      this.isTaxFreeInvoice = this.currentInvoiceData.billingAddress.country !== CountryEnum.Germany;
      this.state = "detail-view";
      if (this.currentInvoiceData.number) {
        this.invoiceNumber = this.currentInvoiceData.number;
      }

      this.openAmount = this.getOpenAmount();
    } catch (e) {
      this.ns.showNotification(`Fehler beim Öffnen der Rechung: ${JSON.stringify(e)}`, "danger");
    } finally {
      this.auth.showLoader.emit(false);
    }
  }

  private getOpenAmount() {
    let paidAmountSum = this.currentInvoiceData.invoice_details[0].paid_amount || 0;
    for (let i = 1; i < this.currentInvoiceData.invoice_details.length; i++) {
      paidAmountSum += this.currentInvoiceData.invoice_details[i].paid_amount || 0;
    }

    return this.currentInvoiceData.total - paidAmountSum;
  }

  /**
   * Opens list view
   */
  openListView() {
    this.state = "default";
    this.setTableFilter();
  }

  addInvoiceNumber() {
    this.currentInvoiceData.number = this.invoiceNumber;
    this.ivls.updateInvoice(this.currentInvoiceData.id, { number: this.invoiceNumber });
    this.invoiceNumber = "";
  }

  invoicePaid() {
    this.currentInvoiceData.status = PaymentStatusEnum.Paid;
    this.ivls.updateInvoice(this.currentInvoiceData.id, { status: PaymentStatusEnum.Paid });
  }

  invoiceCanceled() {
    this.currentInvoiceData.status = PaymentStatusEnum.Canceled;
    this.ivls.updateInvoice(this.currentInvoiceData.id, { status: PaymentStatusEnum.Canceled });
  }

  /**
   * Gets triggered when user uploads the Pilot Contract document
   * Uploads the document to firebase and updates the verificationDocument0 url in user document on firebase
   */
  async uploadDocument(eventUpload: any, fileName: string, id: string) {
    let fieldAttachments: any;
    new Promise((resolve, reject) => {
      // `invoices/${filePath}/${fileName}`
      this.uploadService
        .uploadFile(eventUpload.target.files[0], `invoices/${id}/`, true, "")
        .subscribe((event: HttpEvent<any>) => {
          if (event.type === HttpEventType.Response) {
            const uploadResponse = <SubcollectionFileModel>event.body;
            resolve(uploadResponse);
          }
        });
    })
      .then((uploadResponse: SubcollectionFileModel) => {
        fieldAttachments = {
          fileURL: uploadResponse.download_url,
          fileName: eventUpload.target.files[0].name,
        };
        this.ivls.updateInvoice(id, fieldAttachments);
      })
      .catch((exception) => {
        throw exception;
      });
  }

  deleteInvoice(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    const confirmed = confirm(
      "Sind Sie sicher, dass Sie diese Datei löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden!"
    );
    if (confirmed) {
      this.uploadService
        .deleteFile(`invoices/${this.currentInvoiceData.id}/${this.currentInvoiceData.fileName}`)
        .subscribe((success) => {
          this.ivls.updateInvoice(this.currentInvoiceData.id, {
            file: "",
            fileName: "",
          });
        });
    }
  }

  setTableFilter(): void {
    this.invoiceDataSrc.filterPredicate = (object, filter) => {
      let flag = true;
      Object.keys(this.filterValues).forEach((key) => {
        let objValue = object[key] || "";
        if (key === "createdOn" && objValue.toDate()) {
          objValue = this.datePipe.transform(objValue.toDate(), "dd.MM.yyyy");
        }
        if (key === "total" && objValue.toString()) {
          objValue = objValue.toString();
        }
        if (key === "status" && objValue.toString()) {
          objValue = objValue.toString();
        }
        if (key === "company") {
          objValue = object["billingAddress"][key];
        }
        if (key === "name") {
          objValue = object["billingAddress"][key];
        }
        if (this.filterValues[key] && objValue && objValue?.toLowerCase().indexOf(this.filterValues[key]) < 0) {
          flag = false;
        }
      });
      return flag;
    };
  }

  onStatusFilter(event): void {
    this.filterValues["status"] = event["value"];
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.invoiceDataSrc.filter = "test";
  }

  updateFilterValues(event, column: string) {
    let value = event.currentTarget?.["value"]?.trim()?.toLowerCase() || event.value;
    if (this.keyValueQueryMap.has(column)) {
      this.keyValueQueryMap.delete(column);
    }
    if (value) {
      this.keyValueQueryMap.set(column, value);
    }
    this.filterTable(event, column);
  }

  filterTable(event, column: string): void {
    const query = event.currentTarget?.["value"]?.trim()?.toLowerCase() || event?.value;
    this.filterValues[column] = query;
    this.paginator.pageIndex = 0;
    if (this.selectedListViewId === ListViewEnum.CompanyList) {
      if (column === "status") {
        this.onStatusFilter(event);
      }
      this.setTableFilter();
      this.invoiceDataSrc.filter = "test";
    } else {
      this.loadInvoices();
    }
  }

  loadInvoices(): void {
    if (this.selectedListViewId === ListViewEnum.CompanyList) {
      this.invoiceDataSrc.filter = "test";
    } else {
      this.ivls
        .getAllInvoicesWithPaginator(
          this.paginator?.pageIndex,
          this.paginator?.pageSize,
          this.sort?.active,
          this.sort?.direction,
          this.keyValueQueryMap
        )
        .subscribe((invoiceList) => {
          this.invoiceDataSrc.data = invoiceList.data;
          this.invoiceCount = invoiceList.total_hits;
        });
    }
  }

  noSort(event: Event): void {
    event?.preventDefault();
    event?.stopPropagation();
  }

  routeToPayments() {
    this.router.navigate(["payments"]);
  }

  openSetPaidModal() {
    this.isSetPaidConfirmed = false;
    this.setPaidResult = undefined;
    this.ngxSmartModalService.getModal("confirmSetPaidModal").open();
  }

  async setPositionsPaid(invoiceId: string) {
    this.isSetPaidConfirmed = true;
    this.setPaidResult = (await this.ivls.setPositionsPaid(invoiceId)) as SetInvoicePaidResponse[];
    this.currentInvoiceData.isMarkedAsPaid = true;
    updateInTableDataSource(this.invoiceDataSrc, invoiceId, {
      ...this.currentInvoiceData,
      status: PaymentStatusEnum.Paid,
      isMarkedAsPaid: true,
    });
  }

  closeConfirmationModal() {
    this.ngxSmartModalService.getModal("confirmSetPaidModal").close();
  }

  /**
   * Handles invoice updates by updating local invoice data and writting changes to the database.
   * @param invoiceId id of the invoice
   * @param invoiceData updated invoice data
   */
  async updateInvoice(invoiceId: string, invoiceData: Partial<Invoice>) {
    await this.ivls.updateInvoice(this.currentInvoiceData.id, invoiceData);
    updateInTableDataSource(this.invoiceDataSrc, invoiceId, {
      ...this.currentInvoiceData,
      ...invoiceData,
    });
  }

  async updateDueDate() {
    try {
      const dueDate = this.newDueDate;

      await this.updateInvoice(this.currentInvoiceData.id, { dueDate: dateToFirestoreTimestamp(dueDate) });

      this.ns.showNotification("Zahlungsziel erfolgreich aktualisiert.", "success");
    } catch (e) {
      this.ns.showNotification(JSON.stringify(e), "danger");
    }
  }

  openInLexoffice() {
    window.open(CONSTANTS.LEXOFFICE_BASE_URL + this.currentInvoiceData.lexOfficeId);
  }

  addNoSortWhitespace($event) {
    this.noSort($event);
    $event.target.value += " ";
  }

  async toggleDunningsStoppage(event) {
    // stop the click from unchecking the checkbox
    event.preventDefault();

    if (
      (!this.dunningsStoppage.checked && confirm("Sind Sie sicher, dass Sie die Mahnungen stoppen wollen?")) ||
      this.dunningsStoppage.checked
    ) {
      this.currentInvoiceData.hasDunningsStopped = !this.dunningsStoppage.checked;
      try {
        await this.ivls.updateInvoice(this.currentInvoiceData.id, {
          hasDunningsStopped: this.currentInvoiceData.hasDunningsStopped,
        });
      } catch (e) {
        this.ns.showNotification(JSON.stringify(e), "danger");
      }
    }
  }

  async exportStoppedDunningsInvoices() {
    const response = await this.ivls.exportStoppedDunningsInvoices();
    const file = new Blob([response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
  }
}
