import { Injectable } from "@angular/core";
import { Company } from "@app/interfaces/company.interface";
import { catchError, map, tap } from "rxjs/operators";
import { of, Subject, throwError } from "rxjs";
import { CompaniesService } from "@app/_services/companies.service";
import { UserRoleEnum } from "@app/models/user-role-list";

@Injectable({
  providedIn: "root",
})
export class CompaniesLogicService {
  companiesList: Company[] = [];
  companiesListChanged = new Subject<void>();
  uniqueCompanyNamesList: {
    id: string;
    name: string;
    companies: Company[];
  }[] = [];
  isCompanyLogoOptionAvailable = false;

  constructor(private cs: CompaniesService) {}

  getCompanies() {
    return this.cs.getCompanies().pipe(
      tap((data) => {
        this.setListData(data);
        this.sortListDataByName();
      })
    );
  }

  getAllCompaniesWithPaginator(
    pageIndex: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    queryMap?: Map<string, string>
  ) {
    const from = pageIndex * pageSize;
    const to = (pageIndex + 1) * pageSize;

    return this.cs.getCompaniesWithPaginator(from, to, sortBy, sortDirection, queryMap).pipe(
      map((companiesList: QueryResult<Company>) => {
        return companiesList;
      })
    );
  }

  addCompany(data: Company) {
    return this.cs.postCompany(data).pipe(
      tap((company: Company) => {
        this.companiesList.push(company);
        // inform event subscribers that the list changed
        this.companiesListChanged.next();
      })
    );
  }

  getCompany(companyId: string, isFetchRemote = false, isMyCompany = false) {
    let localCompany: Company = null;

    if (!isFetchRemote) {
      // search the item locally 1st
      localCompany = this.companiesList.find((item) => item.cid === companyId);
    }

    if (localCompany) {
      // returning founded item
      return of(localCompany);
    } else {
      // fetching from database because it wasn't found locally
      let path = companyId;

      if (isMyCompany) {
        path = "my";
      }

      return this.cs.getCompany(path).pipe(
        tap((company) => {
          // adding to local list
          this.companiesList.push(company);
        }),
        catchError((error) => {
          // checking if error status is 404 and path is 'my'
          if (error.status === 404 && path === "my") {
            // return observable from getCompany with 'id'
            let company = {
              id: "invalid",
              name: "invalid",
            };
            return this.cs.getCompany(companyId).pipe(
              tap((company) => {
                this.companiesList.push(company);
              }),
              catchError((err) => throwError(err)) // handle error on getCompany('id') and rethrow
            );
          }
          // return the error observable if not 404 or path isn't 'my'
          return throwError(error);
        })
      );
    }
  }

  updateCompany(company: Company, userRole = UserRoleEnum.Administrator) {
    let path = company.cid;

    // When updating a company using a different role then Admins, we should use a different route since the default one is protected
    if (userRole !== UserRoleEnum.Administrator) {
      path = "my";
    }

    return this.cs.patchCompany(path, company).pipe(
      tap((updatedCompany: Company) => {
        const foundIndex = this.companiesList.findIndex((item) => item.cid === company.cid);

        // replace item with new data, but keep properties already changed in case the company update is only partial
        this.companiesList[foundIndex] = {
          ...this.companiesList[foundIndex],
          ...updatedCompany,
        };

        // inform event subscribers that the list changed
        this.companiesListChanged.next();
      })
    );
  }

  removeCompany(companyId: string) {
    return this.cs.deleteCompany(companyId).pipe(
      tap(() => {
        this.companiesList = this.companiesList.filter((item) => item.cid !== companyId);
        // inform event subscribers that the list changed
        this.companiesListChanged.next();
      })
    );
  }

  private setListData(data: QueryResult<Company>) {
    this.companiesList = [...data?.data].sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    this.setUniqueNamesListData(this.companiesList);
  }

  private setUniqueNamesListData(companies: Company[]) {
    companies.forEach((company) => {
      const transformedCompanyName = company.name.toLowerCase().replace(/\s+/g, "");
      const uniqueCompanyFound = this.uniqueCompanyNamesList.find((company) => company.id === transformedCompanyName);
      if (uniqueCompanyFound) {
        uniqueCompanyFound.companies.push(company);
      } else {
        this.uniqueCompanyNamesList.push({
          id: transformedCompanyName,
          name: company.name,
          companies: [company],
        });
      }
    });
  }

  private sortListDataByName() {
    this.companiesList.sort((x, y) => x["name"].localeCompare(y["name"]));
  }
  importUsersViaPath(companyId: string) {
    if (companyId) {
      return this.cs.importUsersViaPath(companyId);
    }
  }
  assignUserToCompanyViaPath() {
    return this.cs.assignUserToCompanyViaPath();
  }

  setCompanyLogoOptionAvailableFlag(flag: boolean) {
    this.isCompanyLogoOptionAvailable = flag;
  }
}
