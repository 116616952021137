import { Injectable } from "@angular/core";
import { ProductBundle } from "@app/interfaces/product-bundle.interface";
import { map, tap } from "rxjs/operators";
import { Subject } from "rxjs";
import { ProductBundlesService } from "@app/_services/product-bundles.service";

@Injectable({
  providedIn: "root",
})
export class ProductBundleLogicService {
  productBundlesList: ProductBundle[];
  productBundlesListChanged = new Subject();

  constructor(private pbs: ProductBundlesService) {}

  getProductBundles() {
    return this.pbs.getProductBundles().pipe(
      tap((data) => {
        this.productBundlesList = [...data.data];
        this.sortListDataByName();
      })
    );
  }

  getAllProductBundlesWithPaginator(
    pageIndex: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    queryMap?: Map<string, string>
  ) {
    const from = pageIndex * pageSize;
    const to = (pageIndex + 1) * pageSize;

    return this.pbs.getProductBundlesWithPaginator(from, to, sortBy, sortDirection, queryMap).pipe(
      map((bundleList: QueryResult<ProductBundle>) => {
        return bundleList;
      })
    );
  }

  /**
   * Sorts the productBundlesList ascending based on the product bundles titles
   */
  sortListDataByName() {
    this.productBundlesList.sort((x, y) => x.title.localeCompare(y.title));
  }

  /**
   * Adds a new product bundle to the database, adds the product bundle to the productBundlesList and emits productBundlesListChanged
   * @param data
   */
  addProductBundle(data) {
    return this.pbs.postProductBundle(data).toPromise();
  }

  /**
   * Updates an existing product bundle
   * @param id id of the bundle
   * @param data the new data of the bundle
   */
  async updateProductBundle(id: string, data: any) {
    await this.pbs.patchProductBundle(id, data).toPromise();
  }

  /**
   * Deletes the product bundle with the given document id from the database
   * @param productBundleId
   */
  removeProductBundle(productBundleId: string) {
    return this.pbs.deleteProductBundle(productBundleId);
  }
}
