import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObservableCachingService {

  constructor() { }

    private async resolveWhatsToCache(observableCall: () => Observable<any>, cache: any, ...bindings: any) {
        const [that, ...args] = bindings
        const a = observableCall.bind(that, ...args)()
        a.subscribe(async (result) => {
            cache = result;
        });
        return a;
    }

    /**
     * Caches the given Function call and serves its results on consecutive calls. Cache is updating itself async if accessed.
     * @param toCache
     * @param cache
     * @param bindings
     */
    public handleWithCache<T>(toCache: () => Observable<T>, cache: any, ...bindings): Observable<T> | Promise<T> {
        if (cache) {
            this.resolveWhatsToCache(toCache, cache, ...bindings)
            return of(cache);
        }
        return this.resolveWhatsToCache(toCache, cache, ...bindings);
    }

}
