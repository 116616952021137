<form [formGroup]="productBundlesForm" (ngSubmit)="onSubmit()">
  <div class="card fluid cgrid mb-0">
    <div class="card-header">
      <div class="card-title">
        <button mat-mini-fab color="primary" *ngIf="isFormExpanded" (click)="toggleFormExpansion()">
          <i class="fa fa-angle-up" aria-hidden="true"></i>
        </button>
        <button mat-mini-fab color="primary" *ngIf="!isFormExpanded" (click)="toggleFormExpansion()">
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </button>
        <span *ngIf="!isEditing" class="ml-2 font-sz-20">Produktpakete anlegen</span>
        <span *ngIf="isEditing" class="ml-2 font-sz-20">Produktpakete bearbeiten</span>
      </div>
    </div>
    <div class="card-body" *ngIf="isFormExpanded">
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="form-group">
            <div class="input-group mb-md-4">
              <mat-form-field>
                <mat-label>
                  Titel
                  <span class="text-danger">*</span>
                </mat-label>
                <input matInput formControlName="title" />
                <mat-error *ngIf="productBundlesForm.get('title').invalid">Pflichtfeld</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2">
          <div class="form-group">
            <div class="input-group mb-md-4 justify-content-end">
              <mat-slide-toggle
                class="example-margin primary mat-primary"
                [checked]="false"
                formControlName="isBundleExclusive"
              >
                Exklusiv
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3" [hidden]="!productBundlesForm.get('isBundleExclusive').value">
          <div class="form-group">
            <div class="input-group mb-md-4">
              <mat-form-field>
                <mat-label>
                  Unternehmen
                  <span class="text-danger">*</span>
                </mat-label>
                <mat-select formControlName="companyIds" multiple panelClass="company-long-panel">
                  <ngx-mat-select-search [formControl]="companyFilterCtrl"></ngx-mat-select-search>
                  <mat-option #allSelected [value]="0" (click)="selectAllCompanies()">Wählen Sie Alle</mat-option>
                  <mat-option
                    (click)="selectSingleCompany(allSelected.viewValue)"
                    *ngFor="let company of filteredCompany | async"
                    [value]="company.cid"
                    matTooltip="{{ company.name }}"
                  >
                    {{ company.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3" [hidden]="!productBundlesForm.get('isBundleExclusive').value">
          <div class="form-group">
            <div class="input-group mb-md-4">
              <mat-form-field>
                <mat-label>
                  External-Identifier
                  <span class="text-danger">*</span>
                </mat-label>
                <mat-select formControlName="externals" multiple>
                  <mat-option *ngFor="let external of externalList" [value]="external">
                    {{ external }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <div formArrayName="productsList">
        <div
          [formGroupName]="productItemIndex"
          *ngFor="let productsListControl of productsList.controls; let productItemIndex = index"
        >
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="input-group mb-md-4">
                  <mat-form-field>
                    <mat-label>
                      Produktbeschreibung
                      <span class="text-danger">*</span>
                    </mat-label>
                    <input matInput formControlName="productName" type="text" />
                    <mat-error *ngIf="productsListControl.get('productName').invalid">Pflichtfeld</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="input-group mb-md-4">
                  <mat-form-field>
                    <mat-label>
                      Produkt
                      <span class="text-danger">*</span>
                    </mat-label>
                    <mat-select formControlName="productId">
                      <mat-option>zurücksetzen</mat-option>
                      <mat-option *ngFor="let package of packagesList" [value]="package.package_key">
                        {{ package.title }} - {{ package.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="productsListControl.get('productId').invalid">Pflichtfeld</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-2">
              <div class="form-group">
                <div class="input-group mb-md-2">
                  <mat-form-field>
                    <mat-label>
                      Preis
                      <span class="text-danger">*</span>
                    </mat-label>
                    <input matInput formControlName="productPrice" type="number" min="0" />
                    <mat-error *ngIf="productsListControl.get('productPrice').invalid">Pflichtfeld</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-2">
              <i
                *ngIf="productsList.controls.length !== 1"
                class="fas fa-minus-circle text-danger fa-lg remove-item"
                (click)="removeProduct(productItemIndex)"
              ></i>
            </div>
          </div>
          <div formArrayName="productDefaultOptionList">
            <div
              [formGroupName]="productDefaultOptionIndex"
              *ngFor="
                let productDefaultOptionListControl of (productsListControl | anyCast).controls.productDefaultOptionList
                  .controls;
                let productDefaultOptionIndex = index
              "
            >
              <div class="row">
                <div class="col-12 col-md-8">
                  <div class="form-group">
                    <div class="input-group mb-md-8">
                      <mat-form-field>
                        <mat-label>
                          Produktstandardoption
                          <span class="text-danger">*</span>
                        </mat-label>
                        <mat-select formControlName="accountingPositionId">
                          <mat-option>zurücksetzen</mat-option>
                          <mat-option
                            *ngFor="
                              let accountingPosition of accountingPositionsList
                                | fieldFilter
                                  : 'ordertype'
                                  : packageServiceMap.get(productsListControl.get('productId').value)
                                  : 'name'
                            "
                            [value]="accountingPosition.id"
                          >
                            {{ accountingPosition.name }} ({{ accountingPosition.price }} €)
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="productDefaultOptionListControl.get('accountingPositionId').invalid">
                          Pflichtfeld</mat-error
                        >
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-2">
                  <div class="form-group">
                    <div class="input-group mb-md-2">
                      <mat-form-field>
                        <mat-label>
                          maximale Menge
                          <span class="text-danger">*</span>
                        </mat-label>
                        <input matInput formControlName="maxQuantity" type="number" min="1" step="1" />
                        <mat-error *ngIf="productDefaultOptionListControl.get('maxQuantity').invalid"
                          >Pflichtfeld
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-2">
                  <i
                    *ngIf="
                      (productsListControl | anyCast).controls.productDefaultOptionList.controls.length !==
                      productDefaultOptionIndex + 1
                    "
                    class="fas fa-minus-circle text-danger fa-lg remove-item"
                    (click)="removeProductDefaultOption(productItemIndex, productDefaultOptionIndex)"
                  ></i>
                  <button
                    *ngIf="
                      (productsListControl | anyCast).controls.productDefaultOptionList.controls.length ===
                      productDefaultOptionIndex + 1
                    "
                    class="btn btn-outline-primary"
                    (click)="addProductDefaultOption(productItemIndex)"
                  >
                    <i class="fas fa-plus-circle text-success fa-lg"></i> Produktstandardoption hinzufügen
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row float-right">
            <div class="col-12">
              <button
                *ngIf="productsList.controls.length === productItemIndex + 1"
                class="btn btn-outline-primary float-right"
                (click)="addProduct()"
              >
                <i class="fas fa-plus-circle text-success fa-lg"></i> Produkt hinzufügen
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-toolbar row col-12 mb-2 mb-md-0">
        <button *ngIf="isEditing" class="btn btn-back" type="button" (click)="cancelEditing()">Zurück</button>
        <button class="btn btn-primary" type="submit" [disabled]="!productBundlesForm.valid">
          <span *ngIf="isEditing">Speichern</span>
          <span *ngIf="!isEditing">Hinzufügen</span>
        </button>
      </div>
    </div>
  </div>
</form>

<!-- TODO: check MatTable Sort usage because if we create the table without being inside this ngIf, it doesnt work properly.
The 'sort' method runs before ngInit, and because of that has empty data on the 'sortables' property. -->
<div class="card cgrid mb-3 mt-3" *ngIf="!isEditing">
  <div class="card-body">
    <div class="mat-elevation-z8 material-table">
      <table mat-table [dataSource]="tableDataSrc" matSort (matSortChange)="loadProductBundles()">
        <!-- ID Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell class="pl-3" *matHeaderCellDef mat-sort-header width="45%">
            <div class="row">
              <div class="col-12 text-left">Titel</div>
              <div class="col-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Suchen"
                  (click)="noSort($event)"
                  (input)="updateFilterValues($event, 'title')"
                  (keydown.space)="addNoSortWhitespace($event)"
                />
              </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" width="45%">{{ row.title }}</td>
        </ng-container>

        <ng-container matColumnDef="productNamesList">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-top pl-2" width="45%">Produktliste</th>
          <td mat-cell *matCellDef="let row; let i = index" width="45%">
            <ul class="list-inline">
              <li class="list-inline-item" *ngFor="let product of row.productsList">{{ product.productName }};</li>
            </ul>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef width="10%"></th>
          <td mat-cell *matCellDef="let row; let i = index" width="10%">
            <i class="material-icons clickable" (click)="editItem(row.id)">edit</i>
            <i class="material-icons clickable text-danger" (click)="removeItem(row.id)">delete</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
      </table>
      <mat-paginator
        #paginator
        [pageSizeOptions]="[10, 20, 50]"
        [length]="productBundleCount"
        (page)="loadProductBundles()"
        [showFirstLastButtons]="true"
      ></mat-paginator>
    </div>
  </div>
</div>
