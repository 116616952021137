import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TileModel } from "./imo-tile.model";
import { GlobalService } from "@app/_services/global.service";
import { DiscountsService } from "@app/_services/discounts.service";
import { MoneyService } from "@app/_services/money.service";
import { OrdersLogicService } from "@app/_services/orders-logic.service";
import { Package } from "@app/models/package";
import { AuthService } from "@app/auth/auth.service";
import { CONSTANTS } from "@app/util/constants";
import { AccountingPosition } from "@app/interfaces/accounting-position.interface";
import { isClassicFloorplanViaOffice } from "@app/util/helper";

@Component({
  selector: "app-imo-tile",
  templateUrl: "./imo-tile.component.html",
  styleUrls: ["./imo-tile.component.css"],
})
export class ImoTileComponent implements OnInit {
  @Input() tile: TileModel | Package;
  @Input() type: string;
  @Input() titleField: string;
  @Input() isDiscountAvailable = false;
  @Input() title: string;
  @Input() hideDetails = false;
  @Input() mini = false;
  @Output() toggled = new EventEmitter<any>();
  @Output() packageKeyChange = new EventEmitter<string>();
  price: number;
  servicePrice: number;
  customPackageId: string;
  img_src: string;
  myCompanyId: string;

  constructor(
    public ols: OrdersLogicService,
    public gs: GlobalService,
    private ds: DiscountsService,
    public ms: MoneyService,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.myCompanyId = this.auth.myUserObservable?.company?.id;
    if (this.tile.price !== undefined) {
      let priceData: AccountingPosition;
      // Edge case for onOffice: We can have price overwrite for classic floorplans when a user comes from onOffice
      // (gs.ssoSource === onOffice)
      if (isClassicFloorplanViaOffice(this.tile.package_key, this.gs.ssoSource)) {
        priceData = this.gs.getFloorplanPackageAccountingPosition(this.tile.package_key, this.tile as Package, "0");
      } else {
        priceData = this.gs.apls.getPositionData(this.tile.price, (<Package>this.tile).productBundleData);
      }
      if (priceData) {
        this.price = priceData.price;
      }
    }
    if (this.type === "service") {
      this.tile["packages"].forEach((pack) => {
        let packPrice;
        const accountingPosition = this.gs.apls.getPositionData(pack.price);
        if (!accountingPosition) {
          console.log("No accPos found for pack", pack);
        }
        pack.price === undefined
          ? (packPrice = undefined)
          : pack.price === "0,00"
          ? (packPrice = 0.0)
          : isClassicFloorplanViaOffice(pack.package_key, this.gs.ssoSource)
          ? (packPrice = this.gs.apls.getPositionData(pack.price_onOffice)?.price)
          : (packPrice = accountingPosition?.price);

        if (!this.servicePrice && this.servicePrice !== 0) {
          this.servicePrice = packPrice;
        } else {
          if (packPrice < this.servicePrice) {
            this.servicePrice = packPrice;
          }
        }
      });
    }

    // override title field when using product bundle data
    if ((<Package>this.tile).productBundleData) {
      this.title = this.ols.getCustomServicePackageName(<Package>this.tile);
    }

    this.titleField = this.titleField || "title";
    this.customPackageId = this.ols.getCustomPackageId(<Package>this.tile);

    this.img_src = this.tile.imageUrl || this.tile.package_icons[0];
    if (
      this.auth.myUserObservable?.company?.id &&
      this.tile.package_images_companyId &&
      this.tile.package_images_companyId[this.auth.myUserObservable.company.id]
    ) {
      this.img_src = this.tile.package_images_companyId[this.auth.myUserObservable.company.id][0];
    }
  }

  toggleHandler(): void {
    if (!(<TileModel>this.tile).isFixed && !(<Package>this.tile).productBundleData) {
      this.toggled.emit(this.tile);
    }
  }

  navigate(package_key: string) {
    this.ols.currentPackageId.next(package_key);
  }

  onclick(tile: Package | TileModel) {
    const isTileDisabled = (tile as Package).disabled ?? (tile as TileModel).isDisabled ?? false;

    if (isTileDisabled) {
      window.open(CONSTANTS.BANNER_LINKS.IMO_BKBN, "_blank");
      return;
    }

    if (this.mini && tile.package_key !== this.ols.currentPackageId.value) {
      this.ols.currentPackageId.next(tile.package_key);
    }
    this.packageKeyChange.emit(tile.package_key);
  }

  protected readonly CONSTANTS = CONSTANTS;
}
