import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'anyCast'
})

/**
 * Casts any object using typescript Union Types, into an 'any' type,
 * in order to avoid non-existent property errors when using such object inside the templates.
 */
export class AnyCastPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value;
  }

}
