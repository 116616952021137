import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "@app/auth/auth.service";
import { SubOrdersLogicService } from "../../_services/sub-orders-logic.service";
import { CONSTANTS } from "../../util/constants";
import { UsersLogicService } from "@app/_services/users-logic.service";
import { NotificationsService } from "@app/_services/notifications.service";

@Component({
  selector: "app-forwarding-emails",
  templateUrl: "./forwarding-emails.component.html",
  styleUrls: ["./forwarding-emails.component.css"],
})
export class ForwardingEmailsComponent implements OnInit {
  @Input() emails: string[];
  @Input() userId?: string;
  @Input() suborderId?: string;
  @Input() isInitSave?: boolean;
  @Input() titleCustomClass?: string;
  @Input() hasCardTemplate?: boolean;
  @Output() emailsChanged = new EventEmitter<{ success: boolean; emails: string[] }>();
  form: FormGroup;
  pending: boolean;
  isFormSubmitted: boolean;
  removingEmail: string;
  tooltipText =
    "Die hier eingetragenen Mail Adressen erhalten eine Weiterleitung Ihrer Statusmails. Die Weiterleitung gilt für alle Bestellungen, die mit diesem Profil getätigt werden. Eine auftragsspezifische Weiterleitung kann beim Abschluss der jeweiligen Bestellung eingestellt werden. Eine produktspezifische Weiterleitung ist über die linke Seitenleiste innerhalb Ihrer Bestellung möglich.";
  CONSTANTS = CONSTANTS;

  private readonly logComponent = "ForwardingEmailsComponent :: ";

  constructor(
    private fb: FormBuilder,
    private uls: UsersLogicService,
    private ns: NotificationsService,
    private auth: AuthService,
    private ss: SubOrdersLogicService
  ) {}

  get email() {
    return <FormControl>this.form.get("email");
  }

  ngOnInit() {
    this.isFormSubmitted = false;
    this.form = this.fb.group({
      email: [""],
    });

    if (this.isInitSave) {
      this.save();
    }
  }

  async add() {
    this.isFormSubmitted = true;
    this.email.setValidators(Validators.compose([Validators.required, Validators.pattern(CONSTANTS.EMAILREGEX)]));
    this.email.updateValueAndValidity();
    const email = this.email.value;

    if (!email) {
      console.log(this.logComponent + "Email can not be empty.");
      return;
    }

    if (this.email.invalid) {
      console.log(this.logComponent + "Email field is invalid.");
      return;
    }

    // only add non-existent emails to the list
    if (this.emails.indexOf(email) === -1) {
      let success = true;
      this.emails.push(email);

      try {
        await this.save();
      } catch (err) {
        this.emails.pop();
        console.error(this.logComponent + "Could not save data:", err);
        success = false;
      }
      this.emailsChanged.emit({ success: success, emails: this.emails });
    }
    this.email.clearValidators();
    this.form.reset();
  }

  onKeyUp(event: KeyboardEvent) {
    this.isFormSubmitted = false;

    if (event.code === "Enter") {
      this.add();
    }
  }

  async remove(email: string) {
    if (this.emails.length <= 1) {
      console.error(this.logComponent + "The list needs at least 1 item.");
      return;
    }

    const confirmed = confirm(
      "Sind Sie sicher, dass Sie diese E-Mail (" + email + ") aus der Liste entfernen möchten?"
    );
    if (!confirmed) {
      return;
    }

    this.removingEmail = email;
    let success = true;
    const index = this.emails.indexOf(email);
    this.emails.splice(index, 1);

    try {
      await this.save(true);
    } catch (err) {
      this.emails.push(email);
      console.error(this.logComponent + "Could not save data:", err);
      success = false;
    }

    this.removingEmail = "";
    this.emailsChanged.emit({ success: success, emails: this.emails });
  }

  async save(isRemoval?: boolean) {
    if (!this.userId && !this.suborderId) {
      console.log(this.logComponent + "No userId nor suborderId found in order to save.");
      return;
    }

    this.pending = !isRemoval;
    const updatedData = { forwardEmails: this.emails };

    try {
      if (this.userId) {
        await this.uls.updateUser(this.userId, updatedData);
      }
      if (this.suborderId) {
        await this.ss.updateSubOrder(this.suborderId, updatedData);
      }
      // update user auth forwardEmails also
      this.auth.myUserObservable.forwardEmails = this.emails;
      this.ns.showNotification("Aktion erfolgreich abgeschlossen.", "success");
    } catch (err) {
      this.ns.showNotification("Es ist etwas falsches passiert, bitte versuchen Sie es erneut.", "danger");
      throw err;
    } finally {
      this.pending = false;
    }
  }
}
