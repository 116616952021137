import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";

@Injectable({
  providedIn: "root",
})
export class FactSheetsService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.FACT_SHEETS;
  constructor(private http: HttpClient) {}

  sendPost(path: string): Promise<any> {
    return this.http.post(this.endpoint + "/" + path, {}).toPromise();
  }
}
