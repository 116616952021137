<div>
  <h3 mat-dialog-title class="text-right mb-0">
    <i class="fas fa-window-close clickable" [mat-dialog-close]="CONFIRMATION_RESPONSE_TYPE.CANCEL"></i>
  </h3>
  <mat-dialog-content>
    <div class="row mb-2" *ngIf="modalData.iconPath">
      <div class="col-12 text-center">
        <img src="{{ modalData.iconPath }}" height="100" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <h4 class="font-weight-bold">{{ modalData.title }}</h4>
      </div>
      <div class="col-12 text-center">
        <p class="font-small">{{ modalData.message }}</p>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="row">
    <div class="col-6 pr-1 text-right">
      <button class="btn btn-outline-primary" [mat-dialog-close]="CONFIRMATION_RESPONSE_TYPE.CONTINUE">
        {{ modalData.continueButtonText }}
      </button>
    </div>
    <div class="col-6 pl-1">
      <button class="btn btn-primary" [mat-dialog-close]="CONFIRMATION_RESPONSE_TYPE.CANCEL">
        {{ modalData.cancelButtonText }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
