import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten'
})
export class ShortenPipe implements PipeTransform {
  /**
   * @param value the (original) input value
   * @param args args[0]: Number of remaining letters, args[1] forceShorten: If true, string will be shortened independently from display size., args[2] hide '...'
   */
  transform(value: string, ...args): any {
    if (window.innerWidth < 1700 || args[1]) { // TODO: Add Parameter to auto-enable this feature..
      if (!args[2]) {
        return value.substring(0, args[0] || 10) + '...';
      } else {
        return value.substring(0, args[0]);
      }
    } else {
      if (value.length > 35) {
        return value.substring(0, 35) + '...';
      } else {
        return value;
      }
    }
  }
}
