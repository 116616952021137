import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApisService } from "@app/_services/apis.service";
import { CONSTANTS } from "@app/util/constants";
import { environment } from "@environments/environment";
import { lastValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class OffenblendeService {
  private readonly endpoint = environment.apiUrl;

  constructor(private apis: ApisService, private http: HttpClient) {}

  /**
   * Calls the create-order order method for offenblende in our backend
   * @param suborderIds suborderIds for which orders shall be created at offenblende
   */
  createOrder(suborderIds: string[]) {
    return lastValueFrom(
      this.apis.callPost(CONSTANTS.EXTERNAL_APIs.OFFENBLENDE + "/create-order", { suborderIds: suborderIds })
    );
  }

  /**
   * Makes a GET request to retrieve the preferred photographer.
   *
   * @param {HttpParams} params - The parameters to be sent with the request.
   * @return {any} - The response from the GET request.
   */
  requestPreferredPhotographer(params: HttpParams): any {
    return this.http.get(this.endpoint + CONSTANTS.EXTERNAL_APIs.OFFENBLENDE + "/preferred-photographer", {
      params: params,
    });
  }
}
