import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class ConfigurationService {
  private readonly endpoint = environment.apiUrl + "configuration/";

  constructor(private http: HttpClient) {}

  getConfiguration(path: string, loginsStartDate?, loginsEndDate?) {
    if (loginsStartDate) {
      let queryString = "?";
      let params = new HttpParams();
      params = params.set("loginsStartDate", loginsStartDate.toString());
      params = params.set("loginsEndDate", loginsEndDate.toString());
      return this.http.get(this.endpoint + path + queryString + params.toString());
    } else {
      return this.http.get(this.endpoint + path);
    }
  }

  putConfiguration(path: string, data: any) {
    return this.http.put(this.endpoint + path, data);
  }

  postConfiguration(path: string, data: any) {
    return this.http.post(this.endpoint + path, data);
  }

  addLogs(collectionName: string, logs: any) {
    return this.http.post(this.endpoint + "logs/" + collectionName, logs).toPromise();
  }
}
