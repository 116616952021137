import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "@app/interfaces/dialog-data";

@Component({
  selector: "app-imo-validation-error-dialog",
  templateUrl: "./imo-validation-error-dialog.component.html",
  styleUrls: ["./imo-validation-error-dialog.component.css"],
})
export class ImoValidationErrorDialogComponent implements OnInit {
  icon: string;
  title: string;
  description: string;
  mainButtonLabel: string;
  secondaryButtonLabel: string;
  secondaryButtonCallback: Function;

  constructor(
    private dialogRef: MatDialogRef<ImoValidationErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.icon = this.data?.icon || "funny_guy.png";
    this.title = this.data?.title || "Achtung!";
    this.description =
      this.data?.description ||
      "Alle Felder (optionale Angaben ausgeschlossen) müssen ausgefüllt sein, um mit der Bearbeitung Ihrer Bestellung beginnen zu können.";
    this.mainButtonLabel = this.data?.mainButtonLabel || "Verstanden";
    this.secondaryButtonLabel = this.data?.secondaryButtonLabel;
    this.secondaryButtonCallback = this.data?.secondaryButtonCallback;
  }

  secondaryButtonClick() {
    this.closeDialog();
    this.secondaryButtonCallback();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
