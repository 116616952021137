<form [formGroup]="billingAddressForm">
  <div class="form-row">
    <div class="col-12 col-md-6">
      <div class="form-group">
        <p class="mb-0 font-weight-bold">Zusatz für Rechnungskopf</p>
        <input class="form-control" formControlName="invoiceHeader" type="text" />
      </div>
    </div>
    <div class="col-12 col-md-6"></div>
  </div>

  <div class="form-row" *ngIf="!isPrivateIndividualUser">
    <div class="col-12 col-md-6">
      <div class="form-group">
        <p class="mb-0 font-weight-bold">Unternehmensname<span class="text-danger">*</span></p>
        <input class="form-control" formControlName="company" type="text" />
        <div *ngIf="company.invalid && company.touched" class="alert alert-warning mt-2">
          Bitte geben Sie einen gültigen Unternehmensnamen an!
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group">
        <p class="mb-0 font-weight-bold">USt-ID</p>
        <input class="form-control" formControlName="taxId" type="text" />
      </div>
      <div *ngIf="taxId.invalid && taxId.touched" class="alert alert-danger mt-2">
        {{ CONSTANTS.VALIDATION_ERROR_MSG.TAXID_INVALID }}
      </div>
    </div>
  </div>

  <div class="form-row" *ngIf="showEndCustomerOptions">
    <div class="col-12 row form-group mt-3">
      <mat-checkbox formControlName="sentInvoiceToEndCustomer"> </mat-checkbox>
      <p class="ml-1 mb-1">
        <span class="font-weight-400"
          >Rechnung wird an Endkunden** gestellt (Eigentümer o.ä.). Die Daten werden für zukünftige Bestellungen nicht
          gespeichert</span
        >
      </p>
    </div>
  </div>

  <div class="form-row">
    <div class="col-12 col-md-6">
      <div class="form-group">
        <p class="mb-0 font-weight-bold">
          Vorname<span class="text-danger" *ngIf="isPrivateIndividualUser || sentInvoiceToEndCustomer?.value">*</span>
          <small *ngIf="!(isPrivateIndividualUser || sentInvoiceToEndCustomer?.value)" class="ml-1"
            >(auf Rechnung sichtbar, wenn ausgefüllt)</small
          >
        </p>
        <input class="form-control" formControlName="firstName" ype="text" />
        <div *ngIf="firstName.invalid && firstName.touched" class="alert alert-warning mt-2">
          Bitte geben Sie einen gültigen Vorname an!
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group">
        <p class="mb-0 font-weight-bold">
          Nachname<span class="text-danger" *ngIf="isPrivateIndividualUser || sentInvoiceToEndCustomer?.value">*</span>
          <small *ngIf="!(isPrivateIndividualUser || sentInvoiceToEndCustomer?.value)" class="ml-1"
            >(auf Rechnung sichtbar, wenn ausgefüllt)</small
          >
        </p>
        <input class="form-control" formControlName="lastName" type="text" />
        <div *ngIf="lastName.invalid && lastName.touched" class="alert alert-warning mt-2">
          Bitte geben Sie einen gültigen Nachname an!
        </div>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col-12 col-md-9">
          <div class="form-group">
            <p class="mb-0 font-weight-bold">Straße<span class="text-danger">*</span></p>
            <input class="form-control" formControlName="street" type="text" />
            <div *ngIf="street.invalid && street.touched" class="alert alert-warning mt-2">
              Bitte geben Sie eine gültige Straße an!
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group">
            <p class="mb-0 font-weight-bold">Nr. <span class="text-danger">*</span></p>
            <input class="form-control" formControlName="number" type="text" />
            <div *ngIf="number.invalid && number.touched" class="alert alert-warning mt-2">
              Bitte geben Sie eine gültige Hausnummer an!
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group">
        <p class="mb-0 font-weight-bold">Postleitzahl <span class="text-danger">*</span></p>
        <input class="form-control" formControlName="postalCode" type="text" />
        <div *ngIf="postalCode.invalid && postalCode.touched" class="alert alert-warning mt-2">
          Bitte geben Sie eine gültige Postleitzahl an!
        </div>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="col-12 col-md-6">
      <div class="form-group">
        <p class="mb-0 font-weight-bold">Stadt <span class="text-danger">*</span></p>
        <input class="form-control" formControlName="city" type="text" />
        <div *ngIf="city.invalid && city.touched" class="alert alert-warning mt-2">
          Bitte geben Sie eine (gültige) Stadt an!
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <div class="form-group">
        <p class="mb-0 font-weight-bold">Land <span class="text-danger">*</span></p>
        <div class="select-wrapper fa fa-angle-down w-100">
          <select
            formControlName="country"
            class="form-control no-background"
            [compareWith]="gs.compareString"
            (change)="updateValidators()"
          >
            <option *ngFor="let country of countryCodeList" [value]="country.id">
              {{ country.id }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-12 col-md-6">
      <div class="form-group">
        <p class="mb-0 font-weight-bold">Telefon <span class="text-danger">*</span></p>
        <input class="form-control" formControlName="phone" type="text" />
        <div *ngIf="phone.invalid && phone.touched" class="alert alert-warning mt-2">
          Bitte geben Sie eine (gültige) Telefonnummer an!
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group">
        <p class="mb-0 font-weight-bold">Email<span class="text-danger">*</span></p>
        <input class="form-control" formControlName="email" type="email" />
        <div *ngIf="email.invalid && email.touched" class="alert alert-warning mt-2">
          Bitte geben Sie eine (gültige) E-Mail-Adresse an!
        </div>
      </div>
    </div>
  </div>

  <div class="form-row mt-2" *ngIf="showEndCustomerOptions">
    <div class="col-12 col-md-6">
      <div *ngIf="!isPrivateIndividualUser">
        <p class="mb-0 font-weight-bold">
          **sollte keine E-Mail Adresse des Endkunden vorliegen, verpflichtet sich der Kunde die Rechnung diesem
          zukommen zu lassen
        </p>
      </div>
    </div>
    <div class="col-12 col-md-6 pl-2">
      <div class="row form-group">
        <mat-checkbox formControlName="useAccountEmail"> </mat-checkbox>
        <p class="ml-1 mb-1 font-weight-400">Account E-Mail verwenden</p>
      </div>
    </div>
  </div>
</form>
