<ng-container *ngIf="!authService.registering">
  <ng-container *ngIf="authService.myUserObservable as user">
    <div class="sidebar-wrapper">
      <div class="navbar-wrapper col-12 justify-content-center d-flex mt-4">
        <img class="logo" src="assets/images/{{ imoLogoObj }}" />
      </div>
      <div class="col-12 mt-3 navbar-minimize d-flex">
        <button
          (click)="minimizeSidebar()"
          mat-raised-button
          class="btn-sm btn btn-just-icon btn-white btn-fab btn-round d-flex align-items-center justify-content-center m-auto"
        >
          <mat-icon class="visible-on-sidebar-regular m-auto">chevron_left</mat-icon>
          <mat-icon class="visible-on-sidebar-mini m-auto">chevron_right</mat-icon>
        </button>
      </div>
      <div class="user row pr-0 d-flex flex-wrap justify-content-center">
        <div class="user-info col-11 col-sm-10 p-0 d-flex flex-wrap">
          <a data-toggle="collapse" href="#user-settings" class="collapsed col-11 p-2 m-auto">
            <div class="row justify-content-center" container="body">
              <div *ngIf="!misc.sidebar_mini_active; else shortName" class="col-10 pr-0 text-clip">
                <strong>{{ user.firstName }} {{ user.lastName }}</strong>
              </div>
              <ng-template #shortName
                ><div class="col-10 pt-2 pb-0 m-1 text-clip">
                  <p class="font-size-xlarge mt-2">
                    {{ user.firstName.substring(0, 1) }}{{ user.lastName.substring(0, 1) }}
                  </p>
                </div></ng-template
              >
            </div>
            <span
              *ngIf="user.role === userRoleEnum.Customer && !user.isPrivateIndividual && !misc.sidebar_mini_active"
              class="user-role mt-1 ml-3 text-truncate"
              >{{ user.companyName }}</span
            >
            <span
              *ngIf="user.role !== userRoleEnum.Customer && !misc.sidebar_mini_active"
              class="user-role mt-1 ml-3"
              >{{ user.role }}</span
            >
          </a>
          <div class="collapse col-12 p-0" id="user-settings" [ngClass]="{ show: user.role === userRoleEnum.Customer }">
            <ul class="nav">
              <li class="nav-item">
                <a [routerLink]="'profile'" class="nav-link" [ngClass]="{ 'p-0': misc.sidebar_mini_active }">
                  <i class="material-icons">person</i>
                  <span class="sidebar-normal">{{ "sidebar.profile" | translate }}</span>
                </a>
              </li>
              <li class="nav-item">
                <a [routerLink]="'changepassword'" class="nav-link" [ngClass]="{ 'p-0': misc.sidebar_mini_active }">
                  <i class="material-icons">lock</i>
                  <span class="sidebar-normal">{{ "sidebar.change_password" | translate }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="isMobileMenu()">
        <ul class="nav navbar-nav nav-mobile-menu">
          <li class="nav-item">
            <a class="nav-link clickable" (click)="loader.logout()">
              <i class="material-icons">power_settings_new</i>
              <p>
                <span class="d-lg-none d-md-block">Logout</span>
              </p>
            </a>
          </li>
        </ul>
      </div>
      <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
          <ng-container
            *ngIf="
              (!user.isPrivateIndividual || (user.isPrivateIndividual && menuitem.path !== '/companies')) &&
              inArray(user.role, menuitem.roles) &&
              (!menuitem.companyRoles?.length ||
                (user.company?.id && user.companyRole && inArray(user.companyRole, menuitem.companyRoles)))
            "
          >
            <!--If is a single link-->
            <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" class="d-flex nav-link">
              <span>
                <svg>
                  <use
                    [attr.xlink:href]="'../../assets/icons/sidebar/' + $any(menuitem.icontype) + '#Layer_1'"
                    [attr.href]="'../../assets/icons/sidebar/' + $any(menuitem.icontype) + '#Layer_1'"
                  ></use>
                </svg>
              </span>
              <span>
                <p>{{ menuitem.title | translate }}</p>
              </span>
            </a>
            <!--If it have a submenu-->
            <a
              data-toggle="collapse"
              href="#{{ menuitem.collapse }}"
              *ngIf="menuitem.type === 'sub'"
              (click)="updatePS()"
              class="nav-link"
            >
              <i class="material-icons">{{ menuitem.icontype }}</i>
              <p>{{ menuitem.title }}<b class="caret"></b></p>
            </a>

            <!--Display the submenu items-->
            <div id="{{ menuitem.collapse }}" class="collapse" *ngIf="menuitem.type === 'sub'">
              <ul class="nav">
                <li
                  routerLinkActive="active"
                  *ngFor="let childitem of menuitem.children"
                  class="nav-item nav-item-transform"
                >
                  <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
                    <span class="sidebar-mini">{{ childitem.ab }}</span>
                    <span class="sidebar-normal">{{ childitem.title }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </ng-container>
        </li>
        <!-- Display some Official links -->
        <li class="nav-item" routerLinkActive="active">
          <a data-toggle="collapse" href="#rechtliches" (click)="updatePS()" class="nav-link" aria-expanded="true">
            <i class="material-icons">view_headline</i>
            <p>{{ "sidebar.legal" | translate }}<b class="caret"></b></p>
          </a>
          <div id="rechtliches" class="collapse show">
            <!-- Impressum -->
            <ul class="nav">
              <li class="nav-item">
                <a href="https://www.imogent.de/impressum/" target="_blank" rel="noopener" class="d-flex nav-link">
                  <span>
                    <svg>
                      <use
                        [attr.xlink:href]="'../../assets/icons/sidebar/copyright.svg' + '#Layer_1'"
                        [attr.href]="'../../assets/icons/sidebar/copyright.svg' + '#Layer_1'"
                      ></use>
                    </svg>
                  </span>
                  <span class="sidebar-normal">{{ "sidebar.impressum" | translate }}</span>
                </a>
              </li>
            </ul>
            <!-- AGB -->
            <ul class="nav">
              <li class="nav-item">
                <a href="https://www.imogent.de/agb/" target="_blank" rel="noopener" class="d-flex nav-link">
                  <span>
                    <svg>
                      <use
                        [attr.xlink:href]="'../../assets/icons/sidebar/assignment.svg' + '#Layer_1'"
                        [attr.href]="'../../assets/icons/sidebar/assignment.svg' + '#Layer_1'"
                      ></use>
                    </svg>
                  </span>
                  <span class="sidebar-normal">{{ "sidebar.agb" | translate }}</span>
                </a>
              </li>
            </ul>
            <!-- Datenschutz -->
            <ul class="nav">
              <li class="nav-item">
                <a href="https://www.imogent.de/datenschutz/" target="_blank" rel="noopener" class="d-flex nav-link">
                  <span>
                    <svg>
                      <use
                        [attr.xlink:href]="'../../assets/icons/sidebar/security.svg' + '#Layer_1'"
                        [attr.href]="'../../assets/icons/sidebar/security.svg' + '#Layer_1'"
                      ></use>
                    </svg>
                  </span>
                  <span class="sidebar-normal">{{ "sidebar.privacy" | translate }}</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </ng-container>
</ng-container>
