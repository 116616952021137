import { Component, EventEmitter, OnInit, Output, Input, AfterViewInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { PreferredDates } from "@app/interfaces/suborder.interface";
import { Subscription } from "rxjs";
import * as moment from "moment";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";

@Component({
  selector: "app-imo-date-time-selector",
  templateUrl: "./imo-date-time-selector.component.html",
  styleUrls: ["./imo-date-time-selector.component.css"],
})
export class ImoDateTimeSelectorComponent implements OnInit, AfterViewInit {
  preferredDates: AbstractControl[];
  preferedDatesAreValid: boolean;
  preferredDatesForm: FormGroup;
  subscriptions: Subscription[] = [];
  timeList: string[] = [];
  timeListEnd: string[] = [];
  timeListAlt: string[] = [];
  timeListAltEnd: string[] = [];
  nextAppointmentDate: Date;
  currentHour: number;
  @Input() disableSetDatesLater = false;
  @Output() datesChanged = new EventEmitter<PreferredDates>();
  @Output() statusChanged = new EventEmitter();
  @Input() existingPreferredDates: PreferredDates;
  @Input() preferredDatesInvalid: boolean;
  private readonly defaultStartHour = 8;
  private readonly defaultEndHour = 18;

  constructor(private formBuilder: FormBuilder) {}

  get preferredDate1() {
    return this.preferredDatesForm.get("preferredDate1");
  }

  get preferredTimeFrom1() {
    return this.preferredDatesForm.get("preferredTimeFrom1");
  }

  get preferredTimeTo1() {
    return this.preferredDatesForm.get("preferredTimeTo1");
  }

  get preferredDate2() {
    return this.preferredDatesForm.get("preferredDate2");
  }

  get preferredTimeFrom2() {
    return this.preferredDatesForm.get("preferredTimeFrom2");
  }

  get preferredTimeTo2() {
    return this.preferredDatesForm.get("preferredTimeTo2");
  }

  get setDatesLaterCtrl() {
    return this.preferredDatesForm.get("setDatesLater");
  }

  ngOnInit() {
    this.currentHour = moment().get("hours");
    this.timeList = this.getTimeList();
    this.timeListAlt = this.getTimeList();
    this.timeListEnd = this.getTimeList();
    this.timeListAltEnd = this.getTimeList();

    this.nextAppointmentDate = this.getAppointmentMinimalDay();

    // TODO: IMO-278: For saved orders load and parse values from Input

    this.preferredDatesForm = this.formBuilder.group({
      preferredDate1: ["", [Validators.required]],
      preferredTimeFrom1: ["", [Validators.required]],
      preferredTimeTo1: ["", [Validators.required]],
      preferredDate2: ["", [Validators.required]],
      preferredTimeFrom2: ["", [Validators.required]],
      preferredTimeTo2: ["", [Validators.required]],
      setDatesLater: [false],
    });

    this.preferredDates = [
      this.preferredDate1,
      this.preferredTimeFrom1,
      this.preferredTimeTo1,
      this.preferredDate2,
      this.preferredTimeFrom2,
      this.preferredTimeTo2,
    ];

    if (this.existingPreferredDates) {
      this.preferredDate1.setValue(this.existingPreferredDates.preferredDate1);
      this.preferredTimeFrom1.setValue(this.existingPreferredDates.preferredTimeFrom1);
      this.preferredTimeTo1.setValue(this.existingPreferredDates.preferredTimeTo1);
      this.preferredDate2.setValue(this.existingPreferredDates.preferredDate2);
      this.preferredTimeFrom2.setValue(this.existingPreferredDates.preferredTimeFrom2);
      this.preferredTimeTo2.setValue(this.existingPreferredDates.preferredTimeTo2);
      this.preferredDatesForm.updateValueAndValidity();
    }

    this.setTimeValidators();
  }

  /**
   * Updates the time list based on the selected date.
   *
   * @param {MatDatepickerInputEvent<Date>} event - The date picker input event.
   * @param {boolean} [isPreferredDate] - Optional parameter to indicate if it is a preferred date.
   * @return {void}
   */
  onDateChanged(event: MatDatepickerInputEvent<Date>, isPreferredDate?: boolean) {
    let updateTarget = "timeList";
    let updateTargetEnd = "timeListEnd";
    if (!isPreferredDate) {
      updateTarget = "timeListAlt";
      updateTargetEnd = "timeListAltEnd";
    }
    if (moment(event.value).isSame(moment(this.nextAppointmentDate), "day")) {
      const minStartHour = this.currentHour > this.defaultStartHour ? this.currentHour : this.defaultStartHour;
      this[updateTarget] = this.getTimeList(minStartHour, this.defaultEndHour, moment().minute());
      this[updateTargetEnd] = this.getTimeList(minStartHour + 1);
    } else {
      this[updateTarget] = this.getTimeList(this.defaultStartHour, this.defaultEndHour);
      this[updateTargetEnd] = this.getTimeList(this.defaultStartHour + 1, this.defaultEndHour + 1);
    }
  }

  /**
   * Updates the end time based on the selected start time.
   * If the selected end time is less than or equal to the selected start time,
   * it updates the end time to the next available hour.
   *
   * @param {any} event - The event object containing the selected start time.
   * @param {boolean} [isPreferredDate] - Optional flag to indicate whether the preferred date is being used.
   *
   * @return {void} - This method does not return anything.
   */
  onBeginTimeChanged(event: any, isPreferredDate?: boolean) {
    const startHours = parseInt(event.value.split(":")[0]);
    const startMinutes = parseInt(event.value.split(":")[1]);
    const selectedStartTimeInt = parseInt(event.value.replace(":", "") || "0000");
    const selectedEndTimeInt = parseInt(
      isPreferredDate
        ? this.preferredTimeTo1.value.replace(":", "") || "0000"
        : this.preferredTimeTo2.value.replace(":", "") || "0000"
    );

    // In case a selectedEndTime has been selected which is less than 1h from the start time, auto update the end time.
    if (selectedEndTimeInt && selectedEndTimeInt <= selectedStartTimeInt + 100) {
      if (isPreferredDate) {
        this.preferredTimeTo1.setValue(this.addHours(event.value));
      } else {
        this.preferredTimeTo2.setValue(this.addHours(event.value));
      }
    }

    if (isPreferredDate) {
      this.timeListEnd = this.getTimeList(startHours + 1, this.defaultEndHour + 1, startMinutes);
    } else {
      this.timeListAltEnd = this.getTimeList(startHours + 1, this.defaultEndHour + 1, startMinutes);
    }
  }

  /**
   * Adds 1 hour to the given time string.
   *
   * @param {string} timeString - The time string in 'HH:mm' format.
   * @return {string} The modified time as 'HH:mm' formatted string.
   */
  addHours(timeString: string) {
    let time = moment(timeString, "HH:mm");

    // Add 1 hour
    time = time.add(1, "hour");

    // Return the modified time as 'hh:mm' formatted string
    return time.format("HH:mm");
  }

  /**
   * Generates a list of formatted time strings between the given start and end hours.
   *
   * @param {number} startHour - The start hour of the time range (default is 8).
   * @param {number} endHour - The end hour of the time range (default is 18).
   * @return {string[]} - An array of formatted time strings between the start and end hours.
   */
  getTimeList(startHour = 8, endHour = 18, startMinute = 0) {
    const timeList: string[] = [];
    for (let i = startHour; i < endHour; i++) {
      let min = i === startHour ? startMinute : 0;
      // Round up the start minute to the nearest quarter hour
      if (min % 15 !== 0) {
        min = Math.ceil(min / 15) * 15;
      }

      for (let j = min; j < 60; j += 15) {
        if (!(startHour === endHour && i === startHour && j === 0)) {
          timeList.push(("0" + i).slice(-2) + ":" + ("0" + j).slice(-2));
        }
      }
    }
    if (startHour < endHour) {
      timeList.push(endHour + ":00");
    }

    return timeList;
  }

  /**
   * Calculates the minimal day for a new appointment.
   *
   * @returns {Date} - The minimal day for a new appointment.
   */
  getAppointmentMinimalDay() {
    let startDay: Date;

    let currentDayOfWeek = moment().day();
    let currentHour = moment().hour();

    // From Friday 5pm till Monday 8am, set next wednesday
    if (
      currentDayOfWeek === 0 ||
      currentDayOfWeek === 6 ||
      (currentDayOfWeek === 5 && currentHour >= 17) ||
      (currentDayOfWeek === 1 && currentHour < 8)
    ) {
      startDay = this.getNextWednesday().toDate();
    } else if (currentDayOfWeek === 5) {
      // set next Tuesday same time if during Friday before weekend hours
      startDay = moment().add(1, "weeks").day(2).toDate();
    } else if (currentDayOfWeek === 4) {
      // Thursday
      startDay = moment().add(1, "week").day(1).toDate();
    } else {
      // set now + 48 hours otherwise
      startDay = moment().add(48, "hours").toDate();
    }

    return startDay;
  }

  getNextWednesday() {
    const today = moment();
    // If today is before Wednesday.
    if (today.day() < 3) {
      // Just get the date of this week's Wednesday.
      return today.day(3);
    } else {
      // Get the date of next week's Wednesday.
      return today.add(1, "weeks").day(3);
    }
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.preferredDatesForm.valueChanges.subscribe((newValues) => {
        this.datesChanged.emit(newValues);
      })
    );
    this.subscriptions.push(
      this.preferredDatesForm.statusChanges.subscribe((newStatus) => {
        this.statusChanged.emit(newStatus);
      })
    );
  }

  setTimeValidators() {
    if (this.preferredDatesForm.status !== "INVALID" || this.preferredDatesInvalid) {
      this.preferredTimeFrom1.setValidators([
        Validators.required,
        timeDurationValidator(this.preferredTimeFrom1, this.preferredTimeTo1),
      ]);
      this.preferredTimeTo1.setValidators([
        Validators.required,
        timeDurationValidator(this.preferredTimeFrom1, this.preferredTimeTo1),
      ]);
      this.preferredTimeFrom2.setValidators([
        Validators.required,
        timeDurationValidator(this.preferredTimeFrom2, this.preferredTimeTo2),
      ]);
      this.preferredTimeTo2.setValidators([
        Validators.required,
        timeDurationValidator(this.preferredTimeFrom2, this.preferredTimeTo2),
      ]);
    }
  }

  onSetDatesLater(event: MatCheckboxChange) {
    if (event.checked) {
      this.setValidators(null);
      this.preferredDates.forEach((ctrl) => {
        ctrl.disable();
      });
      this.preferedDatesAreValid = true;
    } else {
      this.setValidators([Validators.required]);
      this.preferredDates.forEach((ctrl) => {
        ctrl.enable();
      });
      this.preferedDatesAreValid = this.isPreferredDateValid();
    }
  }

  setValidators(validator: ValidatorFn[] | null) {
    this.preferredDates.forEach((el) => {
      el.setValidators(validator);
      el.updateValueAndValidity();
    });
  }

  isPreferredDateValid() {
    let valid = true;

    if (this.setDatesLaterCtrl.value) {
      return valid;
    } else {
      this.preferredDates.forEach((el) => {
        if (!el.valid) {
          valid = false;
        }
      });

      return valid;
    }
  }

  onOpenSelect(event, ctrl) {
    if (event && !this.preferredDatesForm.get(ctrl).value) {
      document.getElementById(ctrl + "-08:00") && document.getElementById(ctrl + "-08:00").focus();
    }
    this.preferredTimeFrom1.updateValueAndValidity();
    this.preferredTimeTo1.updateValueAndValidity();
    this.preferredTimeFrom2.updateValueAndValidity();
    this.preferredTimeTo2.updateValueAndValidity();
  }
}

export function timeDurationValidator(fromTimeCtrl: AbstractControl, toTimeCtrl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const fromTime = parseInt(fromTimeCtrl.value.replace(":", "") || "0000");
    const toTime = parseInt(toTimeCtrl.value.replace(":", "") || "0000");
    const error = toTime <= fromTime + 99 ? { invalidDuration: { value: control.value } } : null;
    return error;
  };
}
