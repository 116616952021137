import { Injectable } from "@angular/core";
import { ACCOUNTING_POSITIONS, AccountingPositions } from "@app/models/accounting-positions";
import { AccountingPosition } from "@app/interfaces/accounting-position.interface";
import { map, tap } from "rxjs/operators";
import { ProductBundleData } from "@app/interfaces/product-bundle-data.interface";
import { AccountingPositionsService } from "@app/_services/accounting-positions.service";

@Injectable({
  providedIn: "root",
})
export class AccountingPositionsLogicService {
  public allPositions: AccountingPosition[];

  constructor(private aps: AccountingPositionsService) {}

  getAllPositions() {
    return this.aps.getAccountingPositions().pipe(
      tap((data) => {
        this.setListData(data?.data);
      })
    );
  }

  getAllAccountingPositionsWithPaginator(
    pageIndex: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    queryMap?: Map<string, string>
  ) {
    const from = pageIndex * pageSize;
    const to = (pageIndex + 1) * pageSize;

    return this.aps.getAccountingPositionsWithPaginator(from, to, sortBy, sortDirection, queryMap).pipe(
      map((apsList: QueryResult<AccountingPosition>) => {
        return apsList;
      })
    );
  }

  private setListData(data: AccountingPosition[]) {
    this.allPositions = [...data];
  }

  updateAccountingPositions(accPositions?: AccountingPosition[]) {
    if (!accPositions) {
      accPositions = ACCOUNTING_POSITIONS;
    }
    if (this.allPositions) {
      accPositions.forEach((pos) => {
        const foundIndex = this.allPositions.findIndex((item) => item.id === pos.id);
        this.allPositions[foundIndex] = pos;
      });
    }

    this.aps.putAccountingPositions(accPositions).subscribe(
      (val) => {
        console.log("PUT call successful value returned in body", val);
      },
      (response) => {
        console.log("PUT call in error", response);
      },
      () => {
        console.log("The PUT observable is now completed.");
      }
    );
  }

  getPositionData(id: string, productBundleData: ProductBundleData = null): AccountingPosition {
    if (!this.allPositions.length) {
      this.getAllPositions();
    }
    const accountingPosition = this.allPositions.find((position) => position.id === id);

    if (!accountingPosition) {
      console.log("Could not find an accountingPosition for id", id);
      return;
    }

    let price = accountingPosition.price;

    if (productBundleData) {
      price = productBundleData.productPrice;
    }

    // overriding the accounting position price without changing the original value in the object
    return {
      ...accountingPosition,
      price,
    };
  }

  updateNewAccountingPositions() {
    const accPositions = ACCOUNTING_POSITIONS;
    const updatePositions = [];
    accPositions.forEach((pos) => {
      if (!this.allPositions.find((x) => x.id === pos.id)) {
        console.log(pos);
        updatePositions.push(pos);
      }
    });
    if (updatePositions.length > 0) {
      this.aps.putAccountingPositions(updatePositions).subscribe(
        (val) => {
          console.log("accounting positions updated", val);
        },
        (err) => {
          console.log("Error while updating accounting positions", err);
        }
      );
    } else {
      console.log("already up to date :)");
    }
  }
}
