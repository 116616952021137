import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "@app/auth/auth.service";
import { LoaderService } from "@app/_services/loader.service";
import { MatTableDataSource } from "@angular/material/table";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSort } from "@angular/material/sort";
import { of, Subscription } from "rxjs";
import { CompanyRoleEnum, companyRoleList } from "@app/models/company-role-list";
import { UserRoleEnum } from "@app/models/user-role-list";
import { NgxSmartModalService } from "ngx-smart-modal";
import { existingEmailValidator, forbiddenFileValidator } from "@app/shared-module/form.validators";
import { Company, CompanyLogo, InvoiceRecipient } from "@app/interfaces/company.interface";
import { User } from "@app/interfaces/user.interface";
import { GlobalService } from "@app/_services/global.service";
import { OnOfficeIntegrationScopeEnum } from "@app/models/onOfficeIntegrationScope.enum";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { UploadService } from "@app/_services/upload.service";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { FileDetails } from "@app/interfaces/file-details-data.interface";
import { catchError, switchMap } from "rxjs/operators";
import { FileItem, FileUploader } from "ng2-file-upload";
import {
  addToTableDataSource,
  filterUploadQueue,
  removeFromTableDataSource,
  shortener,
  updateInTableDataSource,
} from "@app/util/helper";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DialogImageFullsize } from "@app/shared-module/imo-attachment-explorer/imo-attachment-explorer.component";
import { CONSTANTS } from "@app/util/constants";
import { UsersLogicService } from "@app/_services/users-logic.service";
import { CompaniesLogicService } from "@app/_services/companies-logic.service";
import {
  CollectiveInvoiceIntervalEnum,
  collectiveInvoiceOptionList,
} from "@app/models/collectiveInvoiceInterval-option-list";
import { LexOfficeService } from "@app/_services/lex-office.service";
import { LexOfficeAPIContact, LexofficeContact } from "@app/interfaces/lexoffice-contact.interface";
import { CountryCodeList, CountryEnum } from "@app/models/country-code-list";
import { CompanyRoleHistoryModalComponent } from "./company-role-history-modal/company-role-history-modal.component";
import { NotificationsService } from "@app/_services/notifications.service";
import { SharedEstatesScope } from "@app/models/shared-estates-scope.enum";
import { slideXRightTransition } from "@app/shared-module/animations";
import { ConfirmationDialogComponent } from "@app/shared-module/confirmation-dialog/confirmation-dialog.component";
import { WebhookTypeEnum } from "@app/models/webhook-type.enum";
import { WebhookAuthenticationTypeEnum } from "@app/models/webhook-authentication-type.enum";
import { Webhook } from "@app/interfaces/webhook.interface";
import { EventSubscriptionsLogicService } from "@app/_services/event-subscriptions-logic.service";
import { environment } from "@environments/environment";
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: "app-companies",
  templateUrl: "./companies.component.html",
  styleUrls: ["./companies.component.css"],
  animations: [slideXRightTransition],
})
export class CompaniesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("uploadFileElem", { static: true }) uploadFileElem: ElementRef<any>;
  @ViewChild("lexOfficeContactNumber") lexOfficeContactNumber: ElementRef;
  @ViewChild("userTable") set userSort(value: MatSort) {
    // only platform admins and company admins have access to the users list
    if (
      this.auth.myUserObservable.role === this.userRoleEnum.Administrator ||
      this.auth.myUserObservable.companyRole === this.companyRoleEnum.CompanyManager
    ) {
      this.userDataSrc.sort = value;
    }
  }
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("paginator") paginator: MatPaginator;
  @ViewChild("userTable") userDataTableSort: MatSort;

  emailForm: any;
  companies: any;
  countryCodeList = CountryCodeList;
  curCompany: Company;
  curUser: any;
  curEmailSuffixes: string[];
  companyDataSrc = new MatTableDataSource([]);
  userDataSrc = new MatTableDataSource([]);
  columnsToDisplay: any;
  columnsToDisplayUserTable: any;
  expanded = false;
  mode = "list";
  companyDetailForm: FormGroup;
  webhooksForm: FormGroup;
  filterValuesCompanies = {};
  filterValuesUsers = {};
  companyRoleList = companyRoleList;
  companyRoleEnum = CompanyRoleEnum;
  userRoleEnum = UserRoleEnum;
  ScopeEnum = OnOfficeIntegrationScopeEnum;
  scopeKeys: string[];
  orderTypeEnum = OrderTypeEnum;
  enabledServicesKeys: string[];
  uploader: FileUploader = new FileUploader({});
  allowedFileExt = CONSTANTS.SUPPORTED_LOGOS_EXTENSIONS; // allowed file extensions
  shortNameSuggestion = "";
  collectiveInvoiceIntervalEnum = CollectiveInvoiceIntervalEnum;
  lexContact: LexofficeContact;
  private subscriptions: Subscription[] = [];
  showNoLexContactFoundError: boolean;
  isLexOfficeLoading: boolean;
  mainInvoiceRecipientOptions: InvoiceRecipient[] = [];
  invoiceExcludedOrderTypeOptions: string[];
  sharedEstatesScopeEnum = Object.values(SharedEstatesScope);
  webhookEventSelectionOptions = Object.values(WebhookTypeEnum);
  webhookAuthenticationTypeSelectionOptions = Object.values(WebhookAuthenticationTypeEnum);
  webhookAuthenticationTypeEnum = WebhookAuthenticationTypeEnum;
  CONSTANTS = CONSTANTS;
  collectiveInvoiceMap = new Map<string, string>();
  environment = environment;
  firebaseCompaniesURL =
    CONSTANTS.FIREBASE_URLS.CONSOLE +
    environment.firebaseProjectId +
    "/" +
    CONSTANTS.FIREBASE_URLS.FIRESTORE_PATH +
    "/" +
    CONSTANTS.COLLECTIONS.COMPANIES +
    "/";
  selectedSuffixes: string[];
  collectiveInvoiceOptionList = collectiveInvoiceOptionList;
  companyFormControls;
  keyValueQueryMap = new Map();
  companyCount: number;

  get companyPaths() {
    return this.companyDetailForm.get("companyPaths") as FormArray;
  }

  constructor(
    public auth: AuthService,
    public loader: LoaderService,
    private uls: UsersLogicService,
    private cls: CompaniesLogicService,
    private fb: FormBuilder,
    public globalService: GlobalService,
    private ns: NotificationsService,
    public ngxSmartModalService: NgxSmartModalService,
    private uploadService: UploadService,
    private lex: LexOfficeService,
    private dialog: MatDialog,
    private esls: EventSubscriptionsLogicService
  ) {
    this.scopeKeys = Object.keys(this.ScopeEnum);
    this.enabledServicesKeys = Object.keys(this.orderTypeEnum);
    this.invoiceExcludedOrderTypeOptions = Object.values(this.orderTypeEnum);
  }

  ngOnInit() {
    collectiveInvoiceOptionList.forEach((entry) => {
      this.collectiveInvoiceMap.set(entry.id, entry.name);
    });
    this.companyFormControls = {
      name: ["", [Validators.required]],
      shortName: [""],
      street: [""],
      streetNumber: [""],
      postalcode: [""],
      city: [""],
      country: [""],
      invoiceRecipientMail: ["", [Validators.pattern(CONSTANTS.EMAILREGEX)]],
      ustid: ["", [CONSTANTS.VALIDATION_PATTERN.TAXID_GER]],
      supplement: [""],
      sharedEstatesScope: [""],
      companyPaths: this.fb.array([
        this.fb.group({
          name: [],
        }),
      ]),
      mainInvoiceRecipient: [],
      isLogoMain: [false],
      collectiveInvoiceOption: [],
      totalVolume: [0],
      addEmailSuffix: [""],
      emailSuffixes: [],
      selectedSuffixesCtrl: [],
      integrations: this.fb.group({
        onOfficeIntegration: this.fb.group({
          onOfficeEnabled: [],
          scope: [],
          accessData: this.fb.group({
            accessToken: [""],
            secret: [""],
          }),
          enabledServices: [],
          autoForwardRetouching: [],
        }),
      }),
      uploadedFiles: [],
      availableFreePriceHubbleOrders: [],
      redeemedPriceHubbleOrders: [],
      invoicingExcludedProducts: [],
    };
    this.companyDetailForm = this.fb.group(this.companyFormControls);

    this.emailForm = this.fb.group({
      emailInput: ["", Validators.compose([Validators.required, Validators.pattern(CONSTANTS.EMAILREGEX)]), []],
    });

    this.webhooksForm = this.fb.group({
      webhooks: this.fb.array([]),
    });

    const user = JSON.parse(sessionStorage.getItem("user"));

    this.loadCompanies();
    this.setCompanyTableFilter();

    this.subscriptions.push(
      this.uls.getUserDetails(user.uid).subscribe((data) => {
        this.curUser = data;

        if (
          this.curUser.role !== this.userRoleEnum.Administrator &&
          this.curUser.companyRole !== this.companyRoleEnum.CompanyManager
        ) {
          this.companyDetailForm.disable();
        } else {
          this.companyDetailForm.enable();
        }

        if (this.curUser.role !== this.userRoleEnum.Administrator && this.curUser.company && this.mode !== "details") {
          this.showCompanyDetails(this.curUser.company.id);
        }
      })
    );

    this.columnsToDisplay = [
      "name",
      "supplement",
      "street",
      "lexOfficeNumber",
      "collectiveInvoiceOption",
      "postalcode",
      "city",
      "country",
    ];
    this.columnsToDisplayUserTable = ["lastName", "firstName", "email", "remove", "admin", "companyRoleHistory"];

    // remove column only available to Admins
    if (this.auth.myUserObservable.role !== this.userRoleEnum.Administrator) {
      if (this.auth.myUserObservable.companyRole !== this.companyRoleEnum.CompanyManager) {
        const index = this.columnsToDisplayUserTable.indexOf("remove");
        this.columnsToDisplayUserTable.splice(index, 1);
      }
      const historyIndex = this.columnsToDisplayUserTable.indexOf("companyRoleHistory");
      this.columnsToDisplayUserTable.splice(historyIndex, 1);
    }
  }

  ngAfterViewInit() {
    this.companyDataSrc.sort = this.sort;
    this.uploadedFiles.setValidators([forbiddenFileValidator(this.allowedFileExt, this.uploadFileElem?.nativeElement)]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  addCompanyPath(old = null) {
    const fg = this.fb.group({
      name: [old?.name || null],
    });
    const companyPaths = <FormArray>this.companyDetailForm.get("companyPaths");
    companyPaths.push(fg);
  }

  removeCompanyPath(pathIndex: number) {
    const companyPaths = <FormArray>this.companyDetailForm.get("companyPaths");
    companyPaths.removeAt(pathIndex);
  }

  get emailInput() {
    return <FormControl>this.emailForm.get("emailInput");
  }

  get addEmailSuffix() {
    return this.companyDetailForm.get("addEmailSuffix").value;
  }

  get onOfficeEnabled() {
    return this.companyDetailForm["controls"].integrations["controls"].onOfficeIntegration["controls"].onOfficeEnabled;
  }

  get uploadedFiles() {
    return this.companyDetailForm.get("uploadedFiles");
  }

  get name() {
    return this.companyDetailForm.get("name").value;
  }

  get hasCollectiveInvoice() {
    return this.companyDetailForm.get("hasCollectiveInvoice").value;
  }

  get collectiveInvoiceOption() {
    return this.companyDetailForm.get("collectiveInvoiceOption").value;
  }

  get ustid() {
    return this.companyDetailForm.get("ustid");
  }

  get country() {
    return this.companyDetailForm.get("country");
  }

  get webhooks() {
    return <FormArray>this.webhooksForm.get("webhooks");
  }

  addWebhook() {
    const newWebhook = this.fb.group({
      eventType: [WebhookTypeEnum.Results_Final_Delivery, [Validators.required]],
      callbackURL: ["", [Validators.required]],
      authenticationType: [WebhookAuthenticationTypeEnum.None, [Validators.required]],
      authenticationSecret: [],
    });

    (<FormArray>this.webhooksForm.get("webhooks")).push(newWebhook);
  }

  createNewEventSubscription() {
    this.esls.createEventSubscription({
      ...this.webhooks.value[this.webhooks.value.length - 1],
      companyId: this.curCompany.cid,
    });
  }

  async submitHandler() {
    let company: Company;
    // Destructuring the form in order not to save unnecessary fields in Firebase
    const { uploadedFiles, isLogoMain, ...formValues } = this.companyDetailForm.value;
    const file = this.uploader.queue[0];
    const currCompanyId = this.curCompany?.cid;
    const collectiveInvoiceOption = this.collectiveInvoiceOption || CollectiveInvoiceIntervalEnum.Weekly;
    if (formValues.companyPaths) {
      formValues.companyPaths = formValues.companyPaths.map((path) => path.name);
    }
    if (currCompanyId) {
      // save company changes

      company = <Company>{
        ...formValues,
        collectiveInvoiceOption: collectiveInvoiceOption,
        cid: currCompanyId,
        logo: {
          isMain: isLogoMain,
          file: this.curCompany.logo?.file,
        },
      };

      const updatePromises: Promise<Company | LexofficeContact>[] = [
        this.cls.updateCompany(company, this.auth.myUserObservable.role).toPromise(),
      ];

      if (this.curCompany.lexOfficeNumber) {
        updatePromises.push(
          this.lex.updateContact(this.curCompany.lexOfficeNumber, {
            name: company.name,
            supplement: company.supplement,
            city: company.city,
            zip: company.postalcode,
            countryCode: this.globalService.getCountryCode(company.country),
            taxId: company.ustid,
            street: company.street + " " + company.streetNumber,
          })
        );
      }

      await Promise.all(updatePromises);

      updateInTableDataSource<Company>(this.companyDataSrc, this.curCompany?.cid, {
        ...this.curCompany,
        ...company,
      });
    } else {
      // build company logo object
      formValues.logo = <CompanyLogo>{
        isMain: isLogoMain,
      };

      // add new company
      try {
        company = await this.cls.addCompany(formValues).toPromise();
        addToTableDataSource(this.companyDataSrc, formValues);
      } catch (err) {
        console.error(err);
      }
    }

    // upload logo
    const isUploadSuccessful = await new Promise((resolve) => {
      if (file) {
        this.uploadService.uploadFile(file.file.rawFile, `companies/${company.cid}/`, false, "").subscribe(
          async (event: HttpEvent<FileDetails>) => {
            if (event.type === HttpEventType.Response) {
              const fileDetails = event.body;
              console.log("Uploaded the document, updating the company details");

              company.logo.file = fileDetails;

              if (this.curCompany) {
                this.curCompany.logo = company.logo;
              }

              try {
                await this.cls.updateCompany(company, this.auth.myUserObservable.role).toPromise();
                this.uploader.clearQueue();
                resolve(true);
              } catch (error) {
                console.log("Could not update company details");
                console.log(error);
                this.ns.showNotification("Die Details konnten nicht aktualisiert werden", "danger");
              }
            }
          },
          (error) => {
            console.log("Could not upload the file");
            console.log(error);
            this.ns.showNotification("Die Datei konnte nicht hochgeladen werden", "danger");
          }
        );
      } else {
        resolve(true);
      }
    });

    if (isUploadSuccessful) {
      if (currCompanyId) {
        this.ns.showNotification("Die Unternehmensdaten wurden erfolgreich gespeichert.", "success");
        this.companyDetailForm.markAsPristine();
      } else {
        if (company) {
          this.ns.showNotification("Das Unternehmen wurde erfolgreich angelegt.", "success");
          this.companyDetailForm.reset();
        }
      }
    }
  }

  loadCompanies(): void {
    this.cls
      .getAllCompaniesWithPaginator(
        this.paginator?.pageIndex,
        this.paginator?.pageSize,
        this.sort?.active,
        this.sort?.direction,
        this.keyValueQueryMap
      )
      .subscribe((companyList) => {
        this.companyDataSrc.data = companyList.data;
        this.companyCount = companyList.total_hits;
      });
  }

  removeUserFromCompany(user: User) {
    this.uls.changeCompany(user.uid, "");
    this.userDataSrc.data = this.userDataSrc.data.filter((item) => item.uid !== user.uid);
  }

  async showCompanyDetails(companyId: string) {
    this.reset();
    this.mode = "details";
    this.expanded = true;
    this.emailInput.setAsyncValidators(existingEmailValidator(companyId, this.uls));
    this.emailInput.setValidators([Validators.required, Validators.pattern(CONSTANTS.EMAILREGEX)]);
    this.emailInput.updateValueAndValidity();

    this.subscriptions.push(
      this.uls.getAllUsersOfCompany(companyId).subscribe((data: any[]) => {
        this.userDataSrc.data = data;
        this.mainInvoiceRecipientOptions = this.getMainInvoiceRecipients(data);
        this.setUserTableFilter();
      })
    );

    const company = await this.cls.getCompany(companyId).toPromise();
    const webhooks = await this.esls.getEventSubscriptions(companyId);
    this.shortNameSuggestion = "";
    this.curCompany = company;
    this.curEmailSuffixes = company.emailSuffixes || [];
    this.companyDetailForm.patchValue({
      name: company.name,
      shortName: company.shortName,
      ustid: company.ustid || "",
      street: company.street,
      streetNumber: company.streetNumber,
      postalcode: company.postalcode,
      city: company.city,
      country: company.country,
      supplement: company.supplement,
      sharedEstatesScope: company.sharedEstatesScope,
      mainInvoiceRecipient: company.mainInvoiceRecipient,
      invoiceRecipientMail: company.invoiceRecipientMail,
      hasCollectiveInvoice: company.hasCollectiveInvoice,
      collectiveInvoiceOption: company.collectiveInvoiceOption || CollectiveInvoiceIntervalEnum.Weekly,
      isLogoMain: !!company.logo?.isMain,
      totalVolume: company.totalVolume,
      addEmailSuffix: "",
      emailSuffixes: this.curEmailSuffixes,
      integrations: {
        onOfficeIntegration: {
          onOfficeEnabled: company.integrations?.onOfficeIntegration?.onOfficeEnabled,
          scope: company.integrations?.onOfficeIntegration?.scope || [],
          accessData: company.integrations?.onOfficeIntegration?.accessData || undefined,
          enabledServices: company.integrations?.onOfficeIntegration?.enabledServices || undefined,
          autoForwardRetouching: company.integrations?.onOfficeIntegration?.autoForwardRetouching || false,
        },
      },
      availableFreePriceHubbleOrders: company.availableFreePriceHubbleOrders,
      redeemedPriceHubbleOrders: company.redeemedPriceHubbleOrders,
      invoicingExcludedProducts: company.invoicingExcludedProducts,
    });
    this.webhooksForm.setControl("webhooks", this.setExistingWebhooks(webhooks));
    this.updateValidators();
    if (company.companyPaths?.length) {
      this.companyDetailForm.removeControl("companyPaths");
      const companyPathsFormArray = new FormArray([]);
      company.companyPaths.forEach((path) => {
        companyPathsFormArray.push(
          this.fb.group({
            name: path,
          })
        );
      });
      this.companyDetailForm.setControl("companyPaths", companyPathsFormArray);
      if (company.companyPaths?.length > 0) {
        this.companyDetailForm.removeControl("companyPaths");
        const formArray = new FormArray([]);
        company.companyPaths.forEach((path) => {
          formArray.push(
            this.fb.group({
              name: path,
            })
          );
        });
        this.companyDetailForm.setControl("companyPaths", formArray);
      } else {
        this.companyDetailForm.setControl(
          "companyPaths",
          new FormArray([
            this.fb.group({
              name: "",
            }),
          ])
        );
      }
      this.companyDetailForm.setControl("webhooks", this.setExistingWebhooks(company.webhooks));
    }
  }

  updateValidators() {
    if (this.country.value === CountryEnum.Germany) {
      this.ustid.setValidators([CONSTANTS.VALIDATION_PATTERN.TAXID_GER]);
    } else {
      this.ustid.clearValidators();
    }
    this.companyDetailForm.updateValueAndValidity();
  }

  setExistingWebhooks(webhooks: Webhook[]): FormArray {
    const formArray = new FormArray([]);

    webhooks?.forEach((webhook: Webhook) => {
      const existingWebhook = this.fb.group({
        eventType: [webhook.eventType, [Validators.required]],
        callbackURL: [webhook.callbackURL, [Validators.required]],
        authenticationType: [webhook.authenticationType, [Validators.required]],
        authenticationSecret: [webhook.authenticationSecret],
      });
      formArray.push(existingWebhook);
    });

    return formArray;
  }

  getMainInvoiceRecipients(users: User[]): InvoiceRecipient[] {
    const recipients: InvoiceRecipient[] = [];
    users
      .sort((x, y) => x.email.localeCompare(y.email))
      .forEach((user: User) => {
        recipients.push({
          uid: user.uid,
          email: user.email,
        });
      });
    return recipients;
  }

  showList() {
    this.reset();
    this.mode = "list";
  }

  reset() {
    this.expanded = false;
    this.companyDetailForm.reset();
    this.curCompany = null;
    this.uploader.clearQueue();
  }

  addToCompany() {
    this.uls
      .changeCompany(this.uls.tempUser.uid, this.curCompany.cid)
      .then(() => {
        this.ns.showNotification("Successfully assigned the user to selected company", "success");
      })
      .catch((err) => {
        this.ns.showNotification(`Error ${err}`, "danger");
      });
    this.emailInput.setValue("");
    this.userDataSrc.data.push(this.uls.tempUser);
  }

  onCompanyRoleChange(row: User, companyRoleId: CompanyRoleEnum) {
    if (row.isPrivateIndividual) {
      return;
    }

    row.companyRoleHistory = row.companyRoleHistory || [];
    row.companyRoleHistory.push({
      timestamp: new Date(),
      oldRole: row.companyRole || "",
      newRole: companyRoleId,
      changedById: {
        id: this.auth.myUserObservable.uid,
      },
      changedByName: this.auth.myUserObservable.name,
    });
    row.companyRole = companyRoleId;
    this.uls.updateUser(row.uid, row);
  }

  setCompanyTableFilter(): void {
    this.companyDataSrc.filterPredicate = (object, filter) => {
      let flag = true;
      Object.keys(this.filterValuesCompanies).forEach((key) => {
        if (!this.filterValuesCompanies[key]) {
          return;
        }
        const filterValue = object[key]?.toString();
        if (!filterValue || filterValue?.toLowerCase().indexOf(this.filterValuesCompanies[key]) < 0) {
          flag = false;
        }
      });
      return flag;
    };
  }

  setUserTableFilter(): void {
    this.userDataSrc.filterPredicate = (object, filter) => {
      let flag = true;
      Object.keys(this.filterValuesUsers).forEach((key) => {
        if (this.filterValuesUsers[key] && object[key].toLowerCase().indexOf(this.filterValuesUsers[key]) < 0) {
          flag = false;
        }
      });
      return flag;
    };
  }

  filterUserTable(event: Event, column: string): void {
    this.noSort(event);
    const query = event.currentTarget["value"].trim().toLowerCase();
    this.filterValuesUsers[column] = query;
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.userDataSrc.filter = "test";
  }

  updateFilterValues($event, column: string) {
    let value = $event.currentTarget?.["value"]?.trim()?.toLowerCase() || $event.value;
    if (this.keyValueQueryMap.has(column)) {
      this.keyValueQueryMap.delete(column);
    }
    if (value) {
      this.keyValueQueryMap.set(column, value);
    }
    this.filterCompanyTable($event, column);
  }

  filterCompanyTable(event, column: string): void {
    const query = event.currentTarget?.["value"]?.trim()?.toLowerCase() || event?.value;
    this.filterValuesCompanies[column] = query;
    this.paginator.pageIndex = 0;
    this.loadCompanies();
  }

  onCollectiveOptionFilter(event): void {
    this.filterValuesCompanies["collectiveInvoiceOption"] = (event["value"] || "").toLowerCase();
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.companyDataSrc.filter = "test";
  }

  /**
   * Adds a new email suffix to the company
   */
  async saveEmailSuffix() {
    const newSuffixes = [...this.curEmailSuffixes, this.addEmailSuffix];
    this.curCompany.emailSuffixes = newSuffixes;
    await this.cls.updateCompany(this.curCompany).toPromise();
    this.showCompanyDetails(this.curCompany.cid);
  }

  noSort(event: Event) {
    event?.preventDefault();
    event?.stopPropagation();
  }

  async removeCompany(cid: string) {
    const logo = this.curCompany.logo?.file;
    await this.cls.removeCompany(cid).toPromise();

    if (logo) {
      const path = `companies/${cid}/${logo.file_name}`;
      this.uploadService.deleteFile(path).toPromise();
    }

    removeFromTableDataSource(this.companyDataSrc, cid);

    this.ngxSmartModalService.close("confirmCompanyRemovalModal");
    this.showList();
  }

  async removeSelectedSuffixes() {
    const remainingSuffixes = this.curEmailSuffixes.filter((x) => !this.selectedSuffixes.includes(x));
    this.curCompany.emailSuffixes = remainingSuffixes;
    await this.cls.updateCompany(this.curCompany);
    this.showCompanyDetails(this.curCompany.cid);
  }

  deleteFile(event: Event) {
    console.log("clicked trash");
    event.stopPropagation();
    event.preventDefault();
    const confirmed = confirm(
      "Sind Sie sicher, dass Sie diese Datei löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden!"
    );
    const path = `companies/${this.curCompany.cid}/${this.curCompany.logo.file.file_name}`;

    if (confirmed) {
      this.uploadService
        .deleteFile(path)
        .pipe(
          catchError((error: any) => {
            console.log("Could not delete the file");
            console.log(error);
            this.ns.showNotification("Die Datei konnte nicht gelöscht werden", "danger");

            // returning empty observable since we already caught the error
            return of();
          }),
          switchMap(() => {
            // remove logo data
            this.curCompany.logo = null;
            return this.cls.updateCompany(this.curCompany, this.auth.myUserObservable.role);
          }),
          catchError((error: any) => {
            console.log("Could not update company details");
            console.log(error);
            this.ns.showNotification(
              "Die Datei wurde gelöscht, aber die Details konnten nicht aktualisiert werden",
              "danger"
            );

            // returning empty observable since we already caught the error
            return of();
          })
        )
        .subscribe();
    }
  }

  onChange(): void {
    const uploaderQueue = this.uploader.queue;

    // always remove the last upload, since we're only allowing 1 file upload
    if (uploaderQueue.length > 1) {
      uploaderQueue[0].remove();
    }

    const filteredQueue = filterUploadQueue(uploaderQueue);
    const fileList = filteredQueue.fileList;
    const duplicatedFiles = filteredQueue.duplicatedFiles;

    if (duplicatedFiles.length > 0) {
      this.ngxSmartModalService.setModalData(duplicatedFiles, "fileUploadErrorModal", true);
      this.ngxSmartModalService.getModal("fileUploadErrorModal").open();
    }
    duplicatedFiles.forEach((fl) => fl.remove());
    this.uploadedFiles.patchValue(fileList);
    this.uploadedFiles.updateValueAndValidity();
  }

  onRemoveFile(file: FileItem): void {
    file.remove();
    // clean input file native element value, in order for the onChange event to be fired when chosing the same file
    this.uploadFileElem.nativeElement.value = null;
    this.onChange();
  }

  openDialog(idx: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      attachments: this.uploader.queue,
      index: idx,
      canDelete: false,
      hideDownload: true,
    };
    const promise = new Promise((resolve, reject) => {
      let file: any;
      const files = [];
      for (let i = 0; i < this.uploader.queue.length; i++) {
        file = this.uploader.queue[i];
        const reader: FileReader = new FileReader();
        reader.onload = (event: any) => {
          files.push({
            download_url: event.target.result,
            file_name: reader["fileName"],
          });
          if (files.length >= this.uploader.queue.length) {
            resolve(files);
          }
          reader.abort();
        };
        reader["fileName"] = file.file.name;
        reader.readAsDataURL(file.file.rawFile);
      }
    });
    promise
      .then((files) => {
        dialogConfig.data.attachments = files;
        this.dialog.open(DialogImageFullsize, dialogConfig);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onNameChange() {
    if (this.name) {
      this.shortNameSuggestion = "Vorschlag '" + shortener(this.name, 4) + "'";
    }
  }

  addNoSortWhitespace($event) {
    this.noSort($event);
    $event.target.value += " ";
  }

  async searchLexOfficeContact() {
    const lexOfficeContactNumber = this.lexOfficeContactNumber.nativeElement.value;
    this.isLexOfficeLoading = true;
    const lexContactResponse = await this.lex.findContact(lexOfficeContactNumber);
    this.isLexOfficeLoading = false;
    const lexContact = <LexOfficeAPIContact>lexContactResponse;

    if (lexContact) {
      const primaryBillingAddress = lexContact.addresses.billing[0];
      this.lexContact = {
        contactId: lexContact.id,
        organizationId: lexContact.organizationId,
        number: lexContact.roles.customer.number,
        companyName: lexContact.company.name,
        billingAddress: {
          street: primaryBillingAddress.street,
          zip: primaryBillingAddress.zip,
          city: primaryBillingAddress.city,
          countryCode: primaryBillingAddress.countryCode,
        },
      };
      this.showNoLexContactFoundError = false;
    } else {
      this.showNoLexContactFoundError = true;
    }
  }

  resetLexSearch() {
    this.lexContact = undefined;
    this.showNoLexContactFoundError = false;
  }

  async saveLexOfficeContact() {
    this.isLexOfficeLoading = true;
    await this.cls
      .updateCompany({
        ...this.curCompany,
        lexOfficeNumber: this.lexContact.number,
        lexOfficeContactId: this.lexContact.contactId,
      })
      .toPromise();
    this.isLexOfficeLoading = false;
  }

  onInvoicingExcludedProductsChanged() {
    this.companyDetailForm.markAllAsTouched();
  }

  onShowCompanyRoleChangeHistory(user: User) {
    this.dialog.open(CompanyRoleHistoryModalComponent, {
      data: user,
      width: "120vh",
    });
  }
  async importUsersViaPath(cid: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message:
        "Hierdurch wird ein Massenimport von Nutzern gestartet. Dies hat Auswirkungen auf die Berechtigungen aller betroffenen Nutzer! Möchten Sie fortfahren?",
    };
    dialogConfig.width = "70vh";
    const detailsDialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    detailsDialogRef.afterClosed().subscribe(async (action) => {
      if (action === "continue") {
        await this.cls.importUsersViaPath(cid).toPromise();
        this.showCompanyDetails(cid);
      }
    });
  }

  expandCompanyForm(flag: boolean) {
    this.companyDetailForm = this.fb.group(this.companyFormControls);
    this.expanded = flag;
  }
}
