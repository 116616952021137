import { AccountingPosition } from "@app/interfaces/accounting-position.interface";
import { OrderTypeEnum } from "@app/models/order-type.enum";

// Accounting Positions model
export class AccountingPositions {}

export const ACCOUNTING_POSITIONS: AccountingPosition[] = [
  // dronemedia

  // dronemedia photo
  {
    id: "dronemedia_photo_price",
    name: "Professionelle Luftaufnahmen - Fotos (10 Fotos)",
    price: 290.0,
    ordertype: "dronemedia",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 235,
      },
    ],
  },
  {
    id: "dronemedia_photo_add_5_price",
    name: "5 zusätzliche Fotos - Luftaufnahmen - Fotos",
    price: 40,
    ordertype: "dronemedia",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 95,
      },
    ],
  },
  {
    id: "dronemedia_photo_add_20_price",
    name: "20 zusätzliche Fotos - Luftaufnahmen - Fotos",
    price: 200,
    ordertype: "dronemedia",
  },
  {
    id: "dronemedia_photo_no_photo",
    name: "Drohnenflug ohne Fotos",
    price: 0,
    ordertype: "dronemedia",
  },
  {
    id: "dronemedia_additional_panorama",
    name: "360° Drohnenpanorama (Zusatzoption)",
    price: 44,
    ordertype: "dronemedia",
  },
  {
    id: "dronemedia_panorama_only",
    name: "360° Drohnenpanorama",
    price: 235,
    ordertype: "dronemedia",
  },
  {
    id: "dronemedia_photo_add_10_price",
    name: "10 zusätzliche Fotos - Luftaufnahmen - Fotos",
    price: 70,
    ordertype: "dronemedia",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 125,
      },
    ],
  },
  {
    id: "dronemedia_photo_extra_feedback",
    name: "zusätzliches Feedback - Luftaufnahmen - Fotos",
    price: 10,
    ordertype: "dronemedia",
  },
  // dronemedia video
  {
    id: "dronemedia_video_price",
    name: "Luftaufnahmen - Video (45 Sekunden)",
    price: 290.0,
    ordertype: "dronemedia",
  },
  {
    id: "dronemedia_video_add_15_price",
    name: "15 zusätzliche Sekunden - Luftaufnahmen - Video",
    price: 40,
    ordertype: "dronemedia",
  },
  {
    id: "dronemedia_video_add_45_price",
    name: "45 zusätzliche Sekunden - Luftaufnahmen - Video",
    price: 100,
    ordertype: "dronemedia",
  },
  {
    id: "dronemedia_video_extra_feedback",
    name: "zusätzliches Feedback - Luftaufnahmen - Video",
    price: 10,
    ordertype: "dronemedia",
  },
  {
    id: "dronemedia_video_extra_feedback_price_retouching",
    name: "Zusätzliches Feedback - Luftaufnahmen - Video",
    price: 25.0,
    ordertype: "dronemedia",
  },
  {
    id: "dronemedia_photo_extra_feedback_price_retouching",
    name: "Zusätzliches Feedback - Luftaufnahmen - Foto",
    price: 5.0,
    ordertype: "dronemedia",
  },
  {
    id: "dronemedia_video_additional_video_link_price",
    name: "Zusätzliches Video Link - Luftaufnahmen - Video",
    price: 0,
    ordertype: "dronemedia",
  },
  // hdphotos
  {
    id: "hdphoto_price",
    name: "Professionelle Immobilienfotos - Fotos (Basispreis)",
    price: 290.0,
    ordertype: "hdphotos",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 235,
      },
    ],
  },
  {
    id: "hdphoto_add_5_price",
    name: "5 zusätzliche Fotos - Professionelle Immobilienfotos - Fotos",
    price: 40.0,
    ordertype: "hdphotos",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 80,
      },
    ],
  },
  {
    id: "hdphoto_option_10_photo_3_objects",
    name: "Variante: Außenaufnahmen von drei verschiedenen Objekten (Insgesamt 10 Aufnahmen)",
    price: 80.0,
    ordertype: "hdphotos",
  },
  {
    id: "hdphoto_option_25_photo_3_objects",
    name: "Variante: Außenaufnahmen von drei verschiedenen Objekten (Insgesamt 25 Aufnahmen)",
    price: 260.0,
    ordertype: "hdphotos",
  },
  {
    id: "hdphoto_add_10_price",
    name: "10 zusätzliche Fotos - Professionelle Immobilienfotos - Fotos",
    price: 100.0,
    ordertype: "hdphotos",
  },
  {
    id: "hdphoto_add_15_price",
    name: "15 zusätzliche Fotos - Professionelle Immobilienfotos - Fotos",
    price: 175.0,
    ordertype: "hdphotos",
  },
  {
    id: "hdphoto_add_20_price",
    name: "20 zusätzliche Fotos - Professionelle Immobilienfotos - Fotos",
    price: 180.0,
    ordertype: "hdphotos",
  },
  {
    id: "hdphoto_backbone_price",
    name: "Backbone Immobilienfotos - Fotos (Basispreis)",
    price: 121.91,
    ordertype: "hdphotos",
  },
  {
    id: "hdphoto_backbone_add_5_price",
    name: "5 zusätzliche Fotos - Backbone Immobilienfotos - Fotos",
    price: 18.0,
    ordertype: "hdphotos",
  },
  {
    id: "hdphoto_backbone_add_10_price",
    name: "10 zusätzliche Fotos - Backbone Immobilienfotos - Fotos",
    price: 18.0,
    ordertype: "hdphotos",
  },
  // editorial shooting
  {
    id: "hdphoto_editorial_price",
    name: "Editorial Shooting - Fotos (25 Fotos)",
    price: 690.0,
    ordertype: "hdphotos",
  },
  {
    id: "hdphoto_editorial_add_15_price",
    name: "15 zusätzliche Fotos - Professionelle Immobilienfotos - Fotos",
    price: 150.0,
    ordertype: "hdphotos",
  },
  {
    id: "hdphoto_editorial_add_30_price",
    name: "30 zusätzliche Fotos - Professionelle Immobilienfotos - Fotos",
    price: 300.0,
    ordertype: "hdphotos",
  },
  {
    id: "hdphoto_extra_feedback",
    name: "zusätzliches Feedback - Professionelle Außen- und Innenfotos - Fotos",
    price: 10,
    ordertype: "hdphotos",
  },

  // retouching

  // retouching standard
  {
    id: "retouching_price",
    name: "Bildbearbeitung - Fotonachbearbeitung (Basic)",
    price: 3.9,
    ordertype: "retouching",
  },
  {
    id: "retouching_extra_feedback",
    name: "zusätzliches Feedback Fotonachbearbeitung",
    price: 2.0,
    ordertype: "retouching",
  },
  // retouching basic
  {
    id: "retouching_price_basic",
    name: "Bildbearbeitung - Fotomontage (Basic)",
    price: 12.9,
    ordertype: "retouching",
  },
  {
    id: "retouching_basic_extra_feedback",
    name: "zusätzliches Feedback - Bildbearbeitung - Fotomontage (Basic)",
    price: 5.0,
    ordertype: "retouching",
  },
  // retouching erweitert
  {
    id: "retouching_price_erweitert",
    name: "Bildbearbeitung - Fotomontage (Erweitert)",
    price: 39.9,
    ordertype: "retouching",
  },
  {
    id: "retouching_erweitert_extra_feedback",
    name: "zusätzliches Feedback - Bildbearbeitung - Fotomontage (Erweitert)",
    price: 5.0,
    ordertype: "retouching",
  },

  // floorplans

  {
    id: "floorplan_trueToScale",
    name: "Maßstabsgetreu",
    price: 10.0,
    ordertype: "floorplan",
  },
  // floorplans klassisch
  {
    id: "floorplan_price_klassisch_wohnen",
    name: "Grundrisse (Klassisch) Wohnen",
    price: 16.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_klassisch_wohnen_onOffice",
    name: "Grundrisse (Klassisch) Wohnen - OnOffice",
    price: 10.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_klassisch_gewerbe",
    name: "Grundrisse (Klassisch) Gewerbe",
    price: 19.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_klassisch_gewerbe_onOffice",
    name: "Grundrisse (Klassisch) Gewerbe - OnOffice",
    price: 10.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_klassisch_extra_feedback",
    name: "zusätzliches Feedback - 2D Grundrisse - Klassisch - bis 140qm Grundfläche",
    price: 7.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_klassisch_extra_feedback_onOffice",
    name: "zusätzliches Feedback - 2D Grundrisse - Klassisch - bis 140qm Grundfläche - OnOffice",
    price: 2.9,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_klassisch_area_calc",
    name: "Fläche neu berechnen nach WoFIV (Klassisch)",
    price: 35.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_klassisch_area_calc_certification",
    name: "Zertifikat Flächenberechnung (Klassisch)",
    price: 20.0,
    ordertype: "floorplan",
  },
  // floorplans premium
  {
    id: "floorplan_price_premium_wohnen",
    name: "Grundrisse (Premium) Wohnen",
    price: 29.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_premium_gewerbe",
    name: "Grundrisse (Premium) Gewerbe",
    price: 35.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_premium_extra_feedback",
    name: "zusätzliches Feedback - 3D Grundrisse - Premium - bis 140qm Grundfläche",
    price: 10.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_premium_area_calc",
    name: "Fläche neu berechnen nach WoFIV (Premium)",
    price: 35.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_premium_area_calc_certification",
    name: "Zertifikat Flächenberechnung (Premium)",
    price: 20.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_premium_modification",
    name: "Modifizierung des Bodenbelags (Premium)",
    price: 15.0,
    ordertype: "floorplan",
  },
  // floorplans extravagant
  {
    id: "floorplan_price_extravagant_wohnen",
    name: "Grundrisse (Extravagant) Wohnen",
    price: 29.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_extravagant_gewerbe",
    name: "Grundrisse (Extravagant) Gewerbe",
    price: 35.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_extravagant_extra_feedback",
    name: "zusätzliches Feedback - 3D Grundrisse - Extravagant - bis 140qm Grundfläche",
    price: 10.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_extravagant_additional_view",
    name: "zusätzliche Ansicht - 3D Grundrisse - Extravagant - bis 140qm Grundfläche",
    price: 5.9,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_extravagant_area_calc",
    name: "Fläche neu berechnen nach WoFIV (Extravagant)",
    price: 35.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_extravagant_area_calc_certification",
    name: "Zertifikat Flächenberechnung (Extravagant)",
    price: 20.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_extravagant_modification",
    name: "Modifizierung des Bodenbelags (Extravagant)",
    price: 15.0,
    ordertype: "floorplan",
  },
  // floorplans Sparkasse
  {
    id: "floorplan_price_sparkasse_wohnen",
    name: "Grundrisse (Sparkasse) Wohnen",
    price: 10.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_sparkasse_gewerbe",
    name: "Grundrisse (Sparkasse) Gewerbe",
    price: 19.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_sparkasse_extra_feedback",
    name: "zusätzliches Feedback Grundrisse (Sparkasse)",
    price: 5.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_sparkasse_area_calc",
    name: "Fläche neu berechnen nach WoFIV (Sparkasse)",
    price: 35.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_sparkasse_area_calc_certification",
    name: "Zertifikat Flächenberechnung (Sparkasse)",
    price: 20.0,
    ordertype: "floorplan",
  },

  // floorplans LBS
  {
    id: "floorplan_price_lbs_wohnen",
    name: "Grundrisse (LBS) Wohnen",
    price: 10.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_lbs_gewerbe",
    name: "Grundrisse (LBS) Gewerbe",
    price: 19.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_lbs_extra_feedback",
    name: "zusätzliches Feedback Grundrisse (LBS)",
    price: 5.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_lbs_area_calc",
    name: "Fläche neu berechnen nach WoFIV (LBS)",
    price: 35.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_lbs_area_calc_certification",
    name: "Zertifikat Flächenberechnung (LBS)",
    price: 20.0,
    ordertype: "floorplan",
  },

  // floorplans FALC
  {
    id: "floorplan_price_falc_wohnen",
    name: "Grundrisse (FALC) Wohnen",
    price: 0.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_falc_gewerbe",
    name: "Grundrisse (FALC) Gewerbe",
    price: 0.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_falc_extra_feedback",
    name: "zusätzliches Feedback Grundrisse (FALC)",
    price: 5.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_falc_area_calc",
    name: "Fläche neu berechnen nach WoFIV (FALC)",
    price: 35.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_falc_area_calc_certification",
    name: "Zertifikat Flächenberechnung (FALC)",
    price: 20.0,
    ordertype: "floorplan",
  },

  // floorplans Remax
  {
    id: "floorplan_price_remax_wohnen",
    name: "Grundrisse (RE/MAX) Wohnen",
    price: 10.5,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_remax_gewerbe",
    name: "Grundrisse (RE/MAX) Gewerbe",
    price: 10.5,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_remax_extra_feedback",
    name: "zusätzliches Feedback Grundrisse (RE/MAX)",
    price: 5.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_remax_area_calc",
    name: "Fläche neu berechnen nach WoFIV (RE/MAX)",
    price: 35.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_remax_area_calc_certification",
    name: "Zertifikat Flächenberechnung (RE/MAX)",
    price: 20.0,
    ordertype: "floorplan",
  },

  // floorplans BPD
  {
    id: "floorplan_price_bpd_wohnen",
    name: "Grundrisse (BPD) Wohnen",
    price: 19.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_bpd_gewerbe",
    name: "Grundrisse (BPD) Gewerbe",
    price: 19.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_bpd_extra_feedback",
    name: "zusätzliches Feedback Grundrisse (BPD)",
    price: 5.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_bpd_area_calc",
    name: "Fläche neu berechnen nach WoFIV (BPD)",
    price: 35.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_bpd_area_calc_certification",
    name: "Zertifikat Flächenberechnung (BPD)",
    price: 20.0,
    ordertype: "floorplan",
  },

  // floorplans Dahler & Company
  {
    id: "floorplan_price_dahler-company_wohnen",
    name: "Grundrisse (Dahler & Company) Wohnen",
    price: 9.5,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_dahler-company_gewerbe",
    name: "Grundrisse (Dahler & Company) Gewerbe",
    price: 9.5,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_dahler-company_extra_feedback",
    name: "zusätzliches Feedback Grundrisse (Dahler & Company)",
    price: 7.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_dahler-company_area_calc",
    name: "Fläche neu berechnen nach WoFIV (Dahler & Company)",
    price: 35.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_dahler-company_area_calc_certification",
    name: "Zertifikat Flächenberechnung (Dahler & Company)",
    price: 20.0,
    ordertype: "floorplan",
  },

  {
    id: "floorplan_price_sis_wohnen",
    name: "Grundrisse (SIS) Wohnen",
    price: 9.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_sis_gewerbe",
    name: "Grundrisse (SIS) Gewerbe",
    price: 29.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_sis_extra_feedback",
    name: "zusätzliches Feedback - Grundrisse - SIS - bis 140qm Grundfläche",
    price: 5.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_sis_area_calc",
    name: "Fläche neu berechnen nach WoFIV (SIS)",
    price: 35.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_sis_area_calc_certification",
    name: "Zertifikat Flächenberechnung (SIS)",
    price: 20.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_sis_modification",
    name: "Modifizierung des Bodenbelags (SIS)",
    price: 15.0,
    ordertype: "floorplan",
  },

  {
    id: "floorplan_price_planethome_wohnen",
    name: "Grundrisse (PlanetHome) Wohnen",
    price: 9.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_planethome_gewerbe",
    name: "Grundrisse (PlanetHome) Gewerbe",
    price: 19.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_planethome_extra_feedback",
    name: "zusätzliches Feedback - Grundrisse - PlanetHome - bis 140qm Grundfläche",
    price: 5.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_planethome_area_calc",
    name: "Fläche neu berechnen nach WoFIV (PlanetHome)",
    price: 35.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_planethome_area_calc_certification",
    name: "Zertifikat Flächenberechnung (PlanetHome)",
    price: 20.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_planethome_modification",
    name: "Modifizierung des Bodenbelags (PlanetHome)",
    price: 15.0,
    ordertype: "floorplan",
  },

  // Profimeo
  {
    id: "floorplan_price_profimeo_wohnen",
    name: "Grundrisse (PROFIMEO) Wohnen",
    price: 16.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_profimeo_gewerbe",
    name: "Grundrisse (PROFIMEO) Gewerbe",
    price: 19.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_profimeo_extra_feedback",
    name: "zusätzliches Feedback - Grundrisse - PROFIMEO - bis 140qm Grundfläche",
    price: 5.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_profimeo_area_calc",
    name: "Fläche neu berechnen nach WoFIV (PROFIMEO)",
    price: 35.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_profimeo_area_calc_certification",
    name: "Zertifikat Flächenberechnung (PROFIMEO)",
    price: 20.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_profimeo_modification",
    name: "Modifizierung des Bodenbelags (PROFIMEO)",
    price: 15.0,
    ordertype: "floorplan",
  },

  // Schmidt&Schurig
  {
    id: "floorplan_price_schmidt_schurig_wohnen",
    name: "Grundrisse (Schmidt & Schurig) Wohnen",
    price: 16.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_schmidt_schurig_gewerbe",
    name: "Grundrisse (Schmidt & Schurig) Gewerbe",
    price: 19.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_schmidt_schurig_extra_feedback",
    name: "zusätzliches Feedback - Grundrisse - Schmidt & Schurig - bis 140qm Grundfläche",
    price: 5.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_schmidt_schurig_modification",
    name: "Modifizierung des Bodenbelags (Schmidt & Schurig)",
    price: 15.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_schmidt_schurig_area_calc",
    name: "Fläche neu berechnen nach WoFIV (Schmidt & Schurig)",
    price: 35.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_schmidt_schurig_area_calc_certification",
    name: "Zertifikat Flächenberechnung (Schmidt & Schurig)",
    price: 20.0,
    ordertype: "floorplan",
  },

  // Classic (New Version of the "Klassisch" Floorplan
  {
    id: "floorplan_price_classic_wohnen",
    name: "Grundrisse (Klassisch) Wohnen",
    price: 16.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_classic_gewerbe",
    name: "Grundrisse (Klassisch) Gewerbe",
    price: 19.0,
    ordertype: "floorplan",
  },
  // Classic (New Version of the "Klassisch" Floorplan
  {
    id: "floorplan_price_classic_wohnen_onOffice",
    name: "Grundrisse (Klassisch) Wohnen - onOffice",
    price: 10.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_price_classic_gewerbe_onOffice",
    name: "Grundrisse (Klassisch) Gewerbe - onOffice",
    price: 10.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_classic_extra_feedback",
    name: "zusätzliches Feedback - Grundrisse - Klassisch - bis 140qm Grundfläche",
    price: 2.9,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_classic_extra_feedback_onOffice",
    name: "zusätzliches Feedback - Grundrisse - Klassisch - bis 140qm Grundfläche - onOffice",
    price: 2.9,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_classic_modification",
    name: "Modifizierung des Bodenbelags (Klassisch)",
    price: 15.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_classic_area_calc",
    name: "Fläche neu berechnen nach WoFIV (Klassisch)",
    price: 35.0,
    ordertype: "floorplan",
  },
  {
    id: "floorplan_classic_area_calc_certification",
    name: "Zertifikat Flächenberechnung (Klassisch)",
    price: 20.0,
    ordertype: "floorplan",
  },

  // floor_overview

  // floor_overview Wohnungsfinder
  {
    id: "floor_overview_wohnungsfinder_price",
    name: "Wohnungsfinder ab",
    price: 109.0,
    ordertype: "floor_overview",
  },
  {
    id: "floor_overview_wohnungsfinder_extra_feedback",
    name: "zusätzliches Feedback - Etagenübersichten - 360° Rundumsicht",
    price: 50.0,
    ordertype: "floor_overview",
  },
  // style Standard
  {
    id: "floor_overview_wohnungsfinder_standard_flat_price",
    name: "Etagenübersicht - Wohnungsfinder - (1) Wohnung hervorheben (Standard)",
    price: 10.0,
    ordertype: "floor_overview",
  },
  {
    id: "floor_overview_wohnungsfinder_standard_building_price",
    name: "Etagenübersicht - Wohnungsfinder - (1) Gebäudekörper (Standard)",
    price: 99.0,
    ordertype: "floor_overview",
  },
  {
    id: "floor_overview_wohnungsfinder_standard_rundumsicht_price",
    name: "Etagenübersicht - Wohnungsfinder - 360° Rundumsicht (Standard)",
    price: 249.0,
    ordertype: "floor_overview",
  },
  {
    id: "floor_overview_wohnungsfinder_standard_einbindung_price",
    name: "Etagenübersicht - Wohnungsfinder - technische Einbindung (Standard)",
    price: 249.0,
    ordertype: "floor_overview",
  },
  // style Premium
  {
    id: "floor_overview_wohnungsfinder_premium_flat_price",
    name: "Etagenübersicht - Wohnungsfinder - (1) Wohnung hervorheben (Premium)",
    price: 15.0,
    ordertype: "floor_overview",
  },
  {
    id: "floor_overview_wohnungsfinder_premium_building_price",
    name: "Etagenübersicht - Wohnungsfinder - (1) Gebäudekörper (Premium)",
    price: 250.0,
    ordertype: "floor_overview",
  },
  {
    id: "floor_overview_wohnungsfinder_premium_rundumsicht_price",
    name: "Etagenübersicht - Wohnungsfinder - 360° Rundumsicht (Premium)",
    price: 299.0,
    ordertype: "floor_overview",
  },
  {
    id: "floor_overview_wohnungsfinder_premium_einbindung_price",
    name: "Etagenübersicht - Wohnungsfinder - technische Einbindung (Premium)",
    price: 249.0,
    ordertype: "floor_overview",
  },
  // floor_overview 360°-Rundumsicht
  {
    id: "floor_overview_360_price",
    name: "360°-Rundumsicht ab",
    price: 199.0,
    ordertype: "floor_overview",
  },
  {
    id: "floor_overview_360_extra_feedback",
    name: "Zusätzliches Feedback - Etagenübersichten - 360° Rundumsicht",
    price: 75.0,
    ordertype: "floor_overview",
  },
  // style Standard
  {
    id: "floor_overview_360_standard_flat_price",
    name: "360°-Rundumsicht Wohnungen (Standard)",
    price: 0.0,
    ordertype: "floor_overview",
  },
  {
    id: "floor_overview_360_standard_building_price",
    name: "360°-Rundumsicht Gebäudekörper (Standard)",
    price: 199.0,
    ordertype: "floor_overview",
  },
  {
    id: "floor_overview_360_standard_rundumsicht_price",
    name: "Etagenübersichten - 360° Rundumsicht - technische Einbindung (Standard)",
    price: 149.0,
    ordertype: "floor_overview",
  },
  {
    id: "floor_overview_360_standard_einbindung_price",
    name: "360°-Rundumsicht Einbindung (Standard)",
    price: 0.0,
    ordertype: "floor_overview",
  },
  // style Premium
  {
    id: "floor_overview_360_premium_flat_price",
    name: "360°-Rundumsicht Wohnungen (Premium)",
    price: 0.0,
    ordertype: "floor_overview",
  },
  {
    id: "floor_overview_360_premium_building_price",
    name: "Etagenübersichten - 360° Rundumsicht - (1) Gebäudekörper (Premium)",
    price: 349.0,
    ordertype: "floor_overview",
  },
  {
    id: "floor_overview_360_premium_rundumsicht_price",
    name: "Etagenübersichten - 360° Rundumsicht - technische Einbindung (Premium)",
    price: 149.0,
    ordertype: "floor_overview",
  },
  {
    id: "floor_overview_360_premium_einbindung_price",
    name: "Wohnungsfinder Einbindung (Premium)",
    price: 0.0,
    ordertype: "floor_overview",
  },

  // vstaging

  // vstaging Standbild
  {
    id: "vstaging_standbild_price",
    name: "Digital Staging Standbild ab",
    price: 79.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_standbild_extra_feedback",
    name: "zusätzliches Feedback - Digitales Staging - Standbilder",
    price: 15.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_standbild_furniture",
    name: "zusätzliches Möbel entfernen - Digitales Staging - Standilder",
    price: 12.9,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_standbild_renovation",
    name: "zusätzliches Renovieren - Digitales Staging - Standbilder",
    price: 30.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_standbild_wall_move",
    name: "zusätzlich Wände versetzten - Digitales Staging - Standbilder",
    price: 20.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_standbild_choose_furniture_price",
    name: "Einrichtung auswählen - Digitales Staging - 360° Bilder",
    price: 15.0,
    ordertype: "vstaging",
  },
  // vstaging Standbild Standard
  {
    id: "vstaging_standbild_standard_price",
    name: "Digitales Staging - Standbilder - Standard-Qualität - Standard",
    price: 79.0,
    ordertype: "vstaging",
  },
  // vstaging Standbild Basic
  {
    id: "vstaging_standbild_premium_price",
    name: "Digitales Staging - Standbilder - Premium-Qualität - Standard",
    price: 109.0,
    ordertype: "vstaging",
  },
  // vstaging Standbild Erweitert
  {
    id: "vstaging_standbild_premium-plus_price",
    name: "Digitales Staging - Standbilder - Premium Plus-Qualität - Standard",
    price: 309.0,
    ordertype: "vstaging",
  },
  // vstaging 360
  {
    id: "vstaging_360_price",
    name: "Digital Staging 360°-Bilder ab",
    price: 129.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_360_extra_feedback",
    name: "zusätzliches Feedback - Digitales Staging - 360° Bilder",
    price: 20.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_360_furniture",
    name: "zusätzliches Möbel entfernen - Digitales Staging - 360° Bilder - Basic-Katalog",
    price: 12.9,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_360_renovation",
    name: "zusätzliches Renovieren - Digitales Staging - 360° Bilder",
    price: 30.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_360_wall_move",
    name: "zusätzlich Wände versetzten - Digitales Staging - 360° Bilder",
    price: 20.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_360_choose_furniture_price",
    name: "Einrichtung auswählen - Digitales Staging - 360° Bilder",
    price: 15.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_360_fp",
    name: "zusätzlich Grundriss für Virtuelle Tour - 3D Grundrisse - Premium - bis 140qm Grundfläche",
    price: 29.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_360_virtual_tour",
    name: "zusätzliche virtuelle Tour - Digitales Staging - 360° Bilder",
    price: 9.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_360_individual_tour",
    name: "zusätzliche virtuelle Tour (Einzelspots) - Digitales Staging - 360° Bilder",
    price: 9.0,
    ordertype: "vstaging",
  },
  // vstaging 360°-Bilder Standard
  {
    id: "vstaging_360_standard_price",
    name: "Digitales Staging - 360° Bilder - Standard-Qualität - Standard",
    price: 129.0,
    ordertype: "vstaging",
  },
  // vstaging 360°-Bilder Basic
  {
    id: "vstaging_360_premium_price",
    name: "Digitales Staging - 360° Bilder - Premium-Qualität - Standard",
    price: 159.0,
    ordertype: "vstaging",
  },
  // vstaging 360°-Bilder Erweitert
  {
    id: "vstaging_360_premium-plus_price",
    name: "Digitales Staging - 360° Bilder - Premium Plus-Qualität - Standard",
    price: 329.0,
    ordertype: "vstaging",
  },

  // vstaging Office Standbilder
  {
    id: "vstaging_office_standbild_price",
    name: "Digital Staging Office Standbild ab",
    price: 79.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_office_standbild_extra_feedback",
    name: "zusätzliches Feedback - Digitales Staging - Office Standbilder",
    price: 15.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_office_standbild_furniture",
    name: "zusätzliches Möbel entfernen - Digitales Staging - Office Standilder",
    price: 12.9,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_office_standbild_renovation",
    name: "zusätzliches Renovieren - Digitales Staging - Office Standbilder",
    price: 30.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_office_standbild_wall_move",
    name: "zusätzlich Wände versetzten - Digitales Staging - Office Standbilder",
    price: 20.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_office_standbild_choose_furniture_price",
    name: "Einrichtung auswählen - Digitales Staging - 360° Bilder",
    price: 15.0,
    ordertype: "vstaging",
  },
  // vstaging Standbild Basic
  {
    id: "vstaging_office_standbild_premium_price",
    name: "Digitales Staging - Office Standbilder - Premium-Qualität - Standard",
    price: 109.0,
    ordertype: "vstaging",
  },
  // vstaging Standbild Erweitert
  {
    id: "vstaging_office_standbild_premium-plus_price",
    name: "Digitales Staging - Office Standbilder - Premium Plus-Qualität - Standard",
    price: 309.0,
    ordertype: "vstaging",
  },
  // vstaging Office 360 bilder
  {
    id: "vstaging_office_360_bilder_price",
    name: "Digital Staging Office 360°-Bilder ab",
    price: 129.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_office_360_bilder_extra_feedback",
    name: "zusätzliches Feedback - Digitales Staging - Office 360° Bilder",
    price: 20.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_office_360_bilder_furniture",
    name: "zusätzliches Möbel entfernen - Digitales Staging - Office 360° Bilder - Basic-Katalog",
    price: 12.9,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_office_360_bilder_renovation",
    name: "zusätzliches Renovieren - Digitales Staging - Office 360° Bilder",
    price: 30.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_office_360_bilder_wall_move",
    name: "zusätzlich Wände versetzten - Digitales Staging - Office 360° Bilder",
    price: 20.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_office_360_bilder_choose_furniture_price",
    name: "Einrichtung auswählen - Digitales Staging - Office 360° Bilder",
    price: 15.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_office_360_bilder_fp",
    name: "zusätzlich Grundriss für Virtuelle Tour - 3D Grundrisse - Premium - bis 140qm Grundfläche",
    price: 29.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_office_360_bilder_virtual_tour",
    name: "zusätzliche virtuelle Tour - Digitales Staging - Office 360° Bilder",
    price: 9.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_office_360_bilder_individual_tour",
    name: "zusätzliche virtuelle Tour (Einzelspots) - Digitales Staging - Office 360° Bilder",
    price: 9.0,
    ordertype: "vstaging",
  },
  // vstaging 360°-Bilder Basic
  {
    id: "vstaging_office_360_bilder_premium_price",
    name: "Digitales Staging - Office 360° Bilder - Premium-Qualität - Standard",
    price: 159.0,
    ordertype: "vstaging",
  },
  // vstaging 360°-Bilder Erweitert
  {
    id: "vstaging_office_360_bilder_premium-plus_price",
    name: "Digitales Staging - Office 360° Bilder - Premium Plus-Qualität - Standard",
    price: 329.0,
    ordertype: "vstaging",
  },

  // vstaging Office 360° Bilder
  {
    id: "vstaging_office-360_price",
    name: "Digitales Staging - Office Staging - 360° Bilder",
    price: 119.0,
    ordertype: "vstaging",
  },
  {
    id: "vstaging_office-360_extra_feedback",
    name: "Digitales Staging - Office Staging - Zusätzliches Feedback",
    price: 19.0,
    ordertype: "vstaging",
  },

  // visualisation

  // visualisation indoor
  {
    id: "visualisation_indoor_price",
    name: "Innenvisualisierung ab",
    price: 390.0,
    ordertype: "visualisation",
  },
  // visualisation indoor additional rooms
  {
    id: "visualisation_indoor_additional_standard",
    name: "Innenvisualisierung zusätzliche kostenpflichtige Räume Standard",
    price: 250.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_indoor_additional_premium",
    name: "Innenvisualisierung zusätzliche kostenpflichtige Räume Premium",
    price: 300.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_indoor_additional_premium_plus",
    name: "Innenvisualisierung zusätzliche kostenpflichtige Räume Premium Plus",
    price: 350.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_indoor_additional_insane",
    name: "nicht verfügbar! Innenvisualisierung zusätzliche kostenpflichtige Räume Insane",
    price: 0.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor additional rooms
  {
    id: "visualisation_outdoor_additional_standard",
    name: "Außenvisualisierung zusätzliche kostenpflichtige Räume Standard",
    price: 250.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_outdoor_additional_premium",
    name: "Außenvisualisierung zusätzliche kostenpflichtige Räume Premium",
    price: 300.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_outdoor_additional_premium_plus",
    name: "Außenvisualisierung zusätzliche kostenpflichtige Räume Premium Plus",
    price: 350.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_outdoor_additional_insane",
    name: "nicht verfügbar! Außenvisualisierung zusätzliche kostenpflichtige Räume Insane",
    price: 0.0,
    ordertype: "visualisation",
  },
  // visualisation indoor additional 360
  {
    id: "visualisation_indoor_additional_360_standard",
    name: "zusätzliches 360° Bild aus - 3D Visualisierungen - Innenvisualisierung Video - Standard (in Sekunden)",
    price: 200.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_indoor_additional_360_premium",
    name: "zusätzliches 360° Bild aus - 3D Visualisierungen - Innenvisualisierung Video - Premium (in Sekunden)",
    price: 400.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_indoor_additional_360_premium_plus",
    name: "zusätzliches 360° Bild aus - 3D Visualisierungen - Innenvisualisierung Video - Premium Plus (in Sekunden)",
    price: 500.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_indoor_additional_360_insane",
    name: "nicht verfügbar! Innenvisualisierung zusätzliche 360°-Tour Insane",
    price: 0.0,
    ordertype: "visualisation",
  },
  // visualisation indoor additional photos
  {
    id: "visualisation_indoor_additional_photos_standard",
    name: "zusätzliches Standbild aus - 3D Visualisierungen - Innenvisualisierung 360° - Standard",
    price: 100.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_indoor_additional_photos_premium",
    name: "zusätzliches Standbild aus - 3D Visualisierungen - Innenvisualisierung 360° - Premium",
    price: 150.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_indoor_additional_photos_premium_plus",
    name: "zusätzliches Standbild aus - 3D Visualisierungen - Innenvisualisierung 360° - Premium Plus",
    price: 200.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_indoor_additional_photos_insane",
    name: "zusätzliches Standbild aus - 3D Visualisierungen - Innenvisualisierung 360° - Insane",
    price: 800.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor additional 360
  {
    id: "visualisation_outdoor_additional_360_standard",
    name: "zusätzliches 360° Bild aus - 3D Visualisierungen - Außenvisualisierung Video - Standard (in Sekunden)",
    price: 300.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_outdoor_additional_360_premium",
    name: "zusätzliches 360° Bild aus - 3D Visualisierungen - Außenvisualisierung Video - Premium (in Sekunden)",
    price: 400.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_outdoor_additional_360_premium_plus",
    name: "zusätzliches 360° Bild aus - 3D Visualisierungen - Außenvisualisierung Video - Premium Plus (in Sekunden)",
    price: 500.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_outdoor_additional_360_insane",
    name: "nicht verfügbar! Außenvisualisierung zusätzliche 360°-Tour Insane",
    price: 0.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor additional photos
  {
    id: "visualisation_outdoor_additional_photos_standard",
    name: "zusätzliches Standbild aus - 3D Visualisierungen - Außenvisualisierung Video - Standard",
    price: 250.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_outdoor_additional_photos_premium",
    name: "zusätzliches Standbild aus - 3D Visualisierungen - Außenvisualisierung Video - Premium",
    price: 300.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_outdoor_additional_photos_premium_plus",
    name: "zusätzliches Standbild aus - 3D Visualisierungen - Außenvisualisierung Video - Premium Plus",
    price: 350.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_outdoor_additional_photos_insane",
    name: "zusätzliches Standbild aus - 3D Visualisierungen - Außenvisualisierung Video - Insane",
    price: 1100.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_indoor_extra_feedback",
    name: "Innenvisualisierung zusätzliches Feedback",
    price: 70.0,
    ordertype: "visualisation",
  },
  // extra feedback Standard
  {
    id: "visualisation_indoor_extra_feedback_standard",
    name: "zusätzliches Feedback - 3D Visualisierungen - Innenvisualisierung Standbild - Basic",
    price: 70.0,
    ordertype: "visualisation",
  },
  // extra feedback Premium
  {
    id: "visualisation_indoor_extra_feedback_premium",
    name: "zusätzliches Feedback - 3D Visualisierungen - Innenvisualisierung Standbild - Premium",
    price: 100.0,
    ordertype: "visualisation",
  },
  // extra feedback Premium Plus
  {
    id: "visualisation_indoor_extra_feedback_premium_plus",
    name: "zusätzliches Feedback - 3D Visualisierungen - Innenvisualisierung Standbild - Premium Plus",
    price: 150.0,
    ordertype: "visualisation",
  },
  // extra feedback Insane
  {
    id: "visualisation_indoor_extra_feedback_insane",
    name: "zusätzliches Feedback - 3D Visualisierungen - Innenvisualisierung Standbild - Insane",
    price: 450.0,
    ordertype: "visualisation",
  },
  // visualisation indoor photo standard
  {
    id: "visualisation_indoor_photo_standard",
    name: "3D Visualisierungen - Innenvisualisierung Standbild - Standard",
    price: 390.0,
    ordertype: "visualisation",
  },
  // visualisation indoor photo premium
  {
    id: "visualisation_indoor_photo_premium",
    name: "3D Visualisierungen - Innenvisualisierung Standbild - Premium",
    price: 490.0,
    ordertype: "visualisation",
  },
  // visualisation indoor photo premium plus
  {
    id: "visualisation_indoor_photo_premium_plus",
    name: "3D Visualisierungen - Innenvisualisierung Standbild - Premium Plus",
    price: 790.0,
    ordertype: "visualisation",
  },
  // visualisation indoor photo insane
  {
    id: "visualisation_indoor_photo_insane",
    name: "3D Visualisierungen - Innenvisualisierung Standbild - Insane",
    price: 1500.0,
    ordertype: "visualisation",
  },
  // visualisation indoor 360 standard
  {
    id: "visualisation_indoor_360_standard",
    name: "3D Visualisierungen - Innenvisualisierung 360° - Standard",
    price: 400.0,
    ordertype: "visualisation",
  },
  // visualisation indoor 360 premium
  {
    id: "visualisation_indoor_360_premium",
    name: "3D Visualisierungen - Innenvisualisierung 360° - Premium",
    price: 550.0,
    ordertype: "visualisation",
  },
  // visualisation indoor 360 premium plus
  {
    id: "visualisation_indoor_360_premium_plus",
    name: "3D Visualisierungen - Innenvisualisierung 360° - Premium Plus",
    price: 850.0,
    ordertype: "visualisation",
  },
  // visualisation indoor 360 insane
  {
    id: "visualisation_indoor_360_insane",
    name: "3D Visualisierungen - Innenvisualisierung 360° - Insane",
    price: 1900.0,
    ordertype: "visualisation",
  },
  // visualisation indoor video standard
  {
    id: "visualisation_indoor_video_standard",
    name: "3D Visualisierungen - Innenvisualisierung Video - Standard (in Sekunden)",
    price: 60.0,
    ordertype: "visualisation",
  },
  // visualisation indoor video premium
  {
    id: "visualisation_indoor_video_premium",
    name: "3D Visualisierungen - Innenvisualisierung Video - Premium (in Sekunden)",
    price: 65.0,
    ordertype: "visualisation",
  },
  // visualisation indoor video premium plus
  {
    id: "visualisation_indoor_video_premium_plus",
    name: "3D Visualisierungen - Innenvisualisierung Video - Premium Plus (in Sekunden)",
    price: 70.0,
    ordertype: "visualisation",
  },
  // visualisation indoor video insane
  {
    id: "visualisation_indoor_video_insane",
    name: "3D Visualisierungen - Innenvisualisierung Video - Insane (in Sekunden)",
    price: 0.0,
    ordertype: "visualisation",
  },

  // visualisation outdoor
  {
    id: "visualisation_outdoor_price",
    name: "Außenvisualisierung ab",
    price: 450.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_outdoor_additional",
    name: "Außenvisualisierung zusätzliche kostenpflichtige Räume",
    price: 100.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_outdoor_additional_360",
    name: "Außenvisualisierung zusätzliche 360°-Tour",
    price: 150.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_outdoor_additional_photos",
    name: "Außenvisualisierung zusätzliche Standbilder",
    price: 100.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_outdoor_extra_feedback",
    name: "Außenvisualisierung zusätzliches Feedback",
    price: 70.0,
    ordertype: "visualisation",
  },
  // extra feedback Standard
  {
    id: "visualisation_outdoor_extra_feedback_standard",
    name: "zusätzliches Feedback - 3D Visualisierungen - Außenvisualisierung Standbild - Standard",
    price: 70.0,
    ordertype: "visualisation",
  },
  // extra feedback Premium
  {
    id: "visualisation_outdoor_extra_feedback_premium",
    name: "zusätzliches Feedback - 3D Visualisierungen - Außenvisualisierung Standbild - Premium",
    price: 100.0,
    ordertype: "visualisation",
  },
  // extra feedback Premium Plus
  {
    id: "visualisation_outdoor_extra_feedback_premium_plus",
    name: "zusätzliches Feedback - 3D Visualisierungen - Außenvisualisierung Standbild - Premium Plus",
    price: 150.0,
    ordertype: "visualisation",
  },
  // extra feedback Insane
  {
    id: "visualisation_outdoor_extra_feedback_insane",
    name: "zusätzliches Feedback - 3D Visualisierungen - Außenvisualisierung Standbild - Insane",
    price: 450.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor photo standard
  {
    id: "visualisation_outdoor_photo_standard",
    name: "3D Visualisierungen - Außenvisualisierung Standbild - Standard",
    price: 450.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor photo premium
  {
    id: "visualisation_outdoor_photo_premium",
    name: "3D Visualisierungen - Außenvisualisierung Standbild - Premium",
    price: 690.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor photo premium plus
  {
    id: "visualisation_outdoor_photo_premium_plus",
    name: "3D Visualisierungen - Außenvisualisierung Standbild - Premium Plus",
    price: 1050.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor photo insane
  {
    id: "visualisation_outdoor_photo_insane",
    name: "3D Visualisierungen - Außenvisualisierung Standbild - Insane",
    price: 1800.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor 360 standard
  {
    id: "visualisation_outdoor_360_standard",
    name: "3D Visualisierungen - Außenvisualisierun 360° - Standard",
    price: 490.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor 360 premium
  {
    id: "visualisation_outdoor_360_premium",
    name: "3D Visualisierungen - Außenvisualisierun 360° - Premium",
    price: 750.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor 360 premium plus
  {
    id: "visualisation_outdoor_360_premium_plus",
    name: "3D Visualisierungen - Außenvisualisierun 360° - Premium Plus",
    price: 1100.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor 360 insane
  {
    id: "visualisation_outdoor_360_insane",
    name: "3D Visualisierungen - Außenvisualisierun 360° - Insane",
    price: 1900.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor video standard
  {
    id: "visualisation_outdoor_video_standard",
    name: "3D Visualisierungen - Außenvisualisierung Video - Standard (in Sekunden)",
    price: 60.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor video premium
  {
    id: "visualisation_outdoor_video_premium",
    name: "3D Visualisierungen - Außenvisualisierung Video - Premium (in Sekunden)",
    price: 65.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor video premium plus
  {
    id: "visualisation_outdoor_video_premium_plus",
    name: "3D Visualisierungen - Außenvisualisierung Video - Premium Plus (in Sekunden)",
    price: 70.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor video insane
  {
    id: "visualisation_outdoor_video_insane",
    name: "3D Visualisierungen - Außenvisualisierung Video - Insane (in Sekunden)",
    price: 0.0,
    ordertype: "visualisation",
  },

  // visualisation outdoor 2 buildings standard
  {
    id: "visualisation_outdoor_2_buildings_standard",
    name: "zusätzliche Gebäudeeinheit (1) aus - 3D Visualisierungen - Außenvisualisierung - Standard",
    price: 200.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor 3 buildings standard
  {
    id: "visualisation_outdoor_3_buildings_standard",
    name: "zusätzliche Gebäudeeinheit (2) aus - 3D Visualisierungen - Außenvisualisierung - Standard",
    price: 350.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor 4 buildings standard
  {
    id: "visualisation_outdoor_4_buildings_standard",
    name: "zusätzliche Gebäudeeinheit (3) aus - 3D Visualisierungen - Außenvisualisierung - Standard",
    price: 450.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor 2 buildings premium
  {
    id: "visualisation_outdoor_2_buildings_premium",
    name: "zusätzliche Gebäudeeinheit (1) aus - 3D Visualisierungen - Außenvisualisierung - Premium",
    price: 250.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor 3 buildings premium
  {
    id: "visualisation_outdoor_3_buildings_premium",
    name: "zusätzliche Gebäudeeinheit (2) aus - 3D Visualisierungen - Außenvisualisierung - Premium",
    price: 400.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor 4 buildings premium
  {
    id: "visualisation_outdoor_4_buildings_premium",
    name: "zusätzliche Gebäudeeinheit (3) aus - 3D Visualisierungen - Außenvisualisierung - Premium",
    price: 550.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor 2 buildings premium_plus
  {
    id: "visualisation_outdoor_2_buildings_premium_plus",
    name: "zusätzliche Gebäudeeinheit (1) aus - 3D Visualisierungen - Außenvisualisierung - Premium Plus",
    price: 250.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor 3 buildings premium_plus
  {
    id: "visualisation_outdoor_3_buildings_premium_plus",
    name: "zusätzliche Gebäudeeinheit (2) aus - 3D Visualisierungen - Außenvisualisierung - Premium Plus",
    price: 400.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor 4 buildings premium_plus
  {
    id: "visualisation_outdoor_4_buildings_premium_plus",
    name: "zusätzliche Gebäudeeinheit (3) aus - 3D Visualisierungen - Außenvisualisierung - Premium Plus",
    price: 550.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor 2 buildings insane
  {
    id: "visualisation_outdoor_2_buildings_insane",
    name: "zusätzliche Gebäudeeinheit (1) aus - 3D Visualisierungen - Außenvisualisierung - Insane",
    price: 450.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor 3 buildings insane
  {
    id: "visualisation_outdoor_3_buildings_insane",
    name: "zusätzliche Gebäudeeinheit (2) aus - 3D Visualisierungen - Außenvisualisierung - Insane",
    price: 800.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor 4 buildings insane
  {
    id: "visualisation_outdoor_4_buildings_insane",
    name: "zusätzliche Gebäudeeinheit (3) aus - 3D Visualisierungen - Außenvisualisierung - Insane",
    price: 1150.0,
    ordertype: "visualisation",
  },

  // visualisation outdoor apartments standard
  {
    id: "visualisation_outdoor_apartments_standard",
    name: "zusätzliche Wohneinheiten (>10) aus - 3D Visualisierungen - Außenvisualisierung - Standard",
    price: 150.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor apartments premium
  {
    id: "visualisation_outdoor_apartments_premium",
    name: "zusätzliche Wohneinheiten (>10) aus - 3D Visualisierungen - Außenvisualisierung - Premium",
    price: 250.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor apartments premium_plus
  {
    id: "visualisation_outdoor_apartments_premium_plus",
    name: "zusätzliche Wohneinheiten (>10) aus - 3D Visualisierungen - Außenvisualisierung - Premium Plus",
    price: 300.0,
    ordertype: "visualisation",
  },
  // visualisation outdoor apartments insane
  {
    id: "visualisation_outdoor_apartments_insane",
    name: "zusätzliche Wohneinheiten (>10) aus - 3D Visualisierungen - Außenvisualisierung - Insane",
    price: 450.0,
    ordertype: "visualisation",
  },

  // visualisation 360
  // base price
  {
    id: "visualisation_360_price",
    name: "Visualisierung 360°",
    price: 700.0,
    ordertype: "visualisation",
  },
  // individual design
  {
    id: "visualisation_360_individual",
    name: "Individuelles Design - Visualisierung 360°",
    price: 1450.0,
    ordertype: "visualisation",
  },
  // additional rooms
  {
    id: "visualisation_360_additional_rooms",
    name: "zusätzliche Zimmer - Visualisierung 360°",
    price: 175.0,
    ordertype: "visualisation",
  },
  {
    id: "visualisation_360_individual_additional_rooms",
    name: "zusätzliche Zimmer - Visualisierung 360° individual",
    price: 500.0,
    ordertype: "visualisation",
  },
  // spots outside
  {
    id: "visualisation_360_spots_outside",
    name: "Außenspots - Visualisierung 360°",
    price: 1000.0,
    ordertype: "visualisation",
  },
  // extra feedback 360
  {
    id: "visualisation_360_extra_feedback",
    name: "zusätzliches Feedback - Visualisierung 360°",
    price: 70.0,
    ordertype: "visualisation",
  },

  // virtual tour

  // matterport
  {
    id: "matterport_bis_99qm_price",
    name: "Matterport für eine Wohnfläche bis 99 m2",
    price: 249.0,
    ordertype: "virtual_tour",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 179.0,
      },
      {
        companyId: "OZthUnBReNW1F9Yqvai0",
        price: 179.0,
      },
      {
        companyId: "M5IYpTepB9g0b9P74poO",
        price: 179.0,
      },
    ],
  },
  {
    id: "matterport_bis_199qm_price",
    name: "Matterport für eine Wohnfläche bis 199 m2",
    price: 349.0,
    ordertype: "virtual_tour",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 259.0,
      },
      {
        companyId: "OZthUnBReNW1F9Yqvai0",
        price: 259.0,
      },
      {
        companyId: "M5IYpTepB9g0b9P74poO",
        price: 259.0,
      },
    ],
  },
  {
    id: "matterport_bis_299qm_price",
    name: "Matterport für eine Wohnfläche bis 299 m2",
    price: 479.0,
    ordertype: "virtual_tour",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 359.0,
      },
      {
        companyId: "OZthUnBReNW1F9Yqvai0",
        price: 359.0,
      },
      {
        companyId: "M5IYpTepB9g0b9P74poO",
        price: 359.0,
      },
    ],
  },
  {
    id: "matterport_bis_399qm_price",
    name: "Matterport für eine Wohnfläche bis 399 m2",
    price: 589.0,
    ordertype: "virtual_tour",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 449.0,
      },
      {
        companyId: "OZthUnBReNW1F9Yqvai0",
        price: 449.0,
      },
      {
        companyId: "M5IYpTepB9g0b9P74poO",
        price: 449.0,
      },
    ],
  },
  {
    id: "matterport_bis_499qm_price",
    name: "Matterport für eine Wohnfläche bis 499 m2",
    price: 709.0,
    ordertype: "virtual_tour",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 539.0,
      },
      {
        companyId: "OZthUnBReNW1F9Yqvai0",
        price: 539.0,
      },
      {
        companyId: "M5IYpTepB9g0b9P74poO",
        price: 539.0,
      },
    ],
  },
  {
    id: "matterport_bis_599qm_price",
    name: "Matterport für eine Wohnfläche bis 599 m2",
    price: 829.0,
    ordertype: "virtual_tour",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 629.0,
      },
      {
        companyId: "OZthUnBReNW1F9Yqvai0",
        price: 629.0,
      },
      {
        companyId: "M5IYpTepB9g0b9P74poO",
        price: 629.0,
      },
    ],
  },
  {
    id: "matterport_bis_699qm_price",
    name: "Matterport für eine Wohnfläche bis 699 m2",
    price: 939.0,
    ordertype: "virtual_tour",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 719.0,
      },
      {
        companyId: "OZthUnBReNW1F9Yqvai0",
        price: 719.0,
      },
      {
        companyId: "M5IYpTepB9g0b9P74poO",
        price: 719.0,
      },
    ],
  },
  {
    id: "matterport_bis_799qm_price",
    name: "Matterport für eine Wohnfläche bis 799 m2",
    price: 999.0,
    ordertype: "virtual_tour",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 769.0,
      },
      {
        companyId: "OZthUnBReNW1F9Yqvai0",
        price: 769.0,
      },
      {
        companyId: "M5IYpTepB9g0b9P74poO",
        price: 769.0,
      },
    ],
  },
  {
    id: "matterport_bis_899qm_price",
    name: "Matterport für eine Wohnfläche bis 899 m2",
    price: 1069.0,
    ordertype: "virtual_tour",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 819.0,
      },
      {
        companyId: "OZthUnBReNW1F9Yqvai0",
        price: 819.0,
      },
      {
        companyId: "M5IYpTepB9g0b9P74poO",
        price: 819.0,
      },
    ],
  },
  {
    id: "matterport_bis_999qm_price",
    name: "Matterport für eine Wohnfläche bis 999 m2",
    price: 1129.0,
    ordertype: "virtual_tour",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 869.0,
      },
      {
        companyId: "OZthUnBReNW1F9Yqvai0",
        price: 869.0,
      },
      {
        companyId: "M5IYpTepB9g0b9P74poO",
        price: 869.0,
      },
    ],
  },
  {
    id: "matterport_1000qm_price",
    name: "Matterport für eine Wohnfläche 1000 m2",
    price: 1199.0,
    ordertype: "virtual_tour",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 919.0,
      },
      {
        companyId: "OZthUnBReNW1F9Yqvai0",
        price: 919.0,
      },
      {
        companyId: "M5IYpTepB9g0b9P74poO",
        price: 919.0,
      },
    ],
  },
  {
    id: "matterport_extra_feedback",
    name: "zusätzliches Feedback - Matterport",
    price: 10,
    ordertype: "virtual_tour",
  },

  // mirror reflex
  {
    id: "mirror_reflex_tour_price",
    name: "360° Rundgang Spiegelreflex-Qualität für bis zu 15 Spots",
    price: 390.0,
    ordertype: "virtual_tour",
  },
  {
    id: "mirror_reflex_addunits",
    name: "Zusätzliche Spots (Spiegelreflex)",
    price: 15.0,
    ordertype: "virtual_tour",
  },
  {
    id: "mirror_reflex_tour_extra_feedback",
    name: "zusätzliches Feedback - Spiegelreflex",
    price: 10,
    ordertype: "virtual_tour",
  },

  // immoviewer
  {
    id: "virtual_tour_immoviewer_10-15_photos_jll_price",
    name: "Immoviewer 10-15 Photos (JLL)",
    price: 299.0,
    ordertype: "virtual_tour",
  },
  {
    id: "virtual_tour_immoviewer_10-15_photos_price",
    name: "Immoviewer 10-15 Photos",
    price: 249.0,
    ordertype: "virtual_tour",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 299.0,
      },
      {
        companyId: "OZthUnBReNW1F9Yqvai0",
        price: 299.0,
      },
      {
        companyId: "M5IYpTepB9g0b9P74poO",
        price: 299.0,
      },
    ],
  },
  {
    id: "virtual_tour_immoviewer_16-25_photos_price",
    name: "Immoviewer 16-25 Photos",
    price: 399.0,
    ordertype: "virtual_tour",
  },
  {
    id: "virtual_tour_immoviewer_26-35_photos_price",
    name: "Immoviewer 26-35 Photos",
    price: 449.0,
    ordertype: "virtual_tour",
  },
  {
    id: "virtual_tour_immoviewer_6_months_hosting_price",
    name: "Hosting für 6 Monate (zu einer Immoviewer Tour)",
    price: 49.0,
    ordertype: "virtual_tour",
  },
  {
    id: "virtual_tour_immoviewer_dronepanorama",
    name: "360° Drohnenpanorama (zu einer Immoviewer Tour)",
    price: 100.0,
    ordertype: "virtual_tour",
    specialConditions: [
      {
        companyId: "1xtGCdNse3d27fd6HqnI",
        price: 0,
      },
      {
        companyId: "OZthUnBReNW1F9Yqvai0",
        price: 0,
      },
      {
        companyId: "M5IYpTepB9g0b9P74poO",
        price: 0,
      },
    ],
  },
  {
    id: "virtual_tour_immoviewer_extra_feedback",
    name: "zusätzliches Feedback - Immoviewer Tour",
    price: 10,
    ordertype: "virtual_tour",
  },
  // energy pass packages
  {
    id: "energy_pass_demand_certificate",
    name: "Bedarfsausweis",
    price: 89.0,
    ordertype: "energy_pass",
  },
  {
    id: "energy_pass_demand_certificate_additional_feedback",
    name: "Bedarfsausweis - Zusätzliches Feedback",
    price: 0.0,
    ordertype: "energy_pass",
  },

  {
    id: "energy_pass_consumption_certificate",
    name: "Verbrauchsausweis",
    price: 39.0,
    ordertype: "energy_pass",
  },
  {
    id: "energy_pass_consumption_certificate_additional_feedback",
    name: "Verbrauchsausweis - Zusätzliches Feedback",
    price: 0.0,
    ordertype: "energy_pass",
  },

  // documents procurement packages
  {
    id: "documents_procurement_landregister_unknown",
    name: "Grundstücksdaten unbekannt",
    price: 24.95,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_addUnits",
    name: "Flur oder Flurstück",
    price: 20,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_promo_bundle_default",
    name: "Paket 1 - Standard",
    price: 29.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_promo_bundle_premium",
    name: "Paket 2 - Premium",
    price: 79.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_promo_bundle_premium_plus",
    name: "Paket 3 - Premium Plus",
    price: 129.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_default_package",
    name: "Behördenauskünfte Paket",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_grundbuch_beglaubigt",
    name: "Grundbuchauszug (beglaubigt)",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_grundbuch_unbeglaubigt",
    name: "Grundbuchauszug (unbeglaubigt)",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_bewilligungsurkunde_abteilung2",
    name: "Bewilligungsurkunde (Abteilung II)",
    price: 64.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_bewilligungsurkunde_abteilungX",
    name: "Bewilligungsurkunde (Alle Abteilungen)",
    price: 64.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_bewilligungsurkunde_abteilung3",
    name: "Bewilligungsurkunde (Abteilung III)",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_teilungserklaerung",
    name: "Teilungserklärung",
    price: 64.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_altlastenauskunft",
    name: "Altlastenauskunft",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_baulastenauskunft",
    name: "Baulastenauskunft",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_kampfmittelauskunft",
    name: "Kampfmittelauskunft",
    price: 64.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_gebaeudedenkmalauskunft",
    name: "Gebäudedenkmalauskunft",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_bodendenkmalauskunft",
    name: "Bodendenkmalauskunft",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_erschliessungsbeitraege",
    name: "Erschließungsbeiträge",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_kanalanschlussgebuehren",
    name: "Kanalanschlussgebühren",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_negativtesta",
    name: "Negativtestat steuerliche Rückstände",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_kommunalabgabe",
    name: "Bescheinigung Kommunalabgaben",
    price: 64.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_liegenschaftskarte", //Please note that, this ID is for uncertified property card
    name: "Liegenschaftskarte (nicht amtlich)",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_liegenschaftskarte_beglaubigt", //Please note that, this ID is for certified property card
    name: "Liegenschaftskarte (amtlich)",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_bestandsnachweis",
    name: "Bestandsnachweis",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_flurundeigentuemernachweis",
    name: "Flurstücks und Eigentümernachweis",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_fortfuehrungsnachweis",
    name: "Fortführungsnachweis",
    price: 64.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_grenzbescheinigung",
    name: "Grenzbescheinigung",
    price: 64.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_bodenrichtwertkarte",
    name: "Bodenrichtwert (-Karte)",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_bodenrichtwertauskunf",
    name: "Bodenrichtwertauskunft",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_grundsteuerbescheid",
    name: "Grundsteuerbescheid",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_einheitswertbescheid",
    name: "Einheitswertbescheid",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_grundsteuermessbescheid",
    name: "Grundsteuermessbescheid",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_steuerlicher_unbedenklichkeitsbescheid",
    name: "Steuerlicher Unbedenklichkeitsbescheid (Objekt)",
    price: 64.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_handelsregisterauszug",
    name: "Handelsregisterauszug",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_flaechennutzungsplan",
    name: "Flächennutzungsplan",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_bebauungsplan_auskunft",
    name: "Bebauungsplan(-Auskunft)",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_staedtebaulicher_vertrag",
    name: "Städtebaulicher Vertrag",
    price: 64.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_satzungs_und_gebietsauskuenfte",
    name: "Satzungs- und Gebietsauskünfte",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_flurbereinigung_stadtumbaugebiet",
    name: "Auskunft Flurbereinigungs- u. Stadtumbaugebiet",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_vorkaufsrechtssatzung",
    name: "Vorkaufsrechtssatzung § 25 BauGB",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_business_improvement_district",
    name: "Business Improvement District",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_bau_laermschutz",
    name: "Auskunft Bau- u. Lärmschutz",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_natur_wasser_landschaft",
    name: "Auskunft Natur-, Wasser- u. Landschaftsschutz",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_hochwasserschutzgebiete",
    name: "Hochwasserschutzgebiete",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_bauakte_essentiell",
    name: "Bauakte - Essentiell",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_bauakte_standard",
    name: "Bauakte - Standard",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_bauakte_premium",
    name: "Bauakte - Premium",
    price: 32.0,
    ordertype: "documents_procurement",
  },
  {
    id: "documents_procurement_teilun_onsite",
    name: "Teilungserklärung (Vor-Ort-Service)",
    price: 189.0,
    ordertype: "documents_procurement",
  },

  // video tour
  {
    id: "video_tour_price",
    name: "Objektbegehung Preis",
    price: 349.0,
    ordertype: "video_tour",
  },
  {
    id: "video_tour_additional_room_price",
    name: "Objektbegehung weiteren Raum",
    price: 50.0,
    ordertype: "video_tour",
  },
  {
    id: "video_tour_5_additional_rooms_price",
    name: "Objektbegehung - 5 weitere Räume",
    price: 50.0,
    ordertype: "video_tour",
  },
  {
    id: "video_tour_10_additional_rooms_price",
    name: "Objektbegehung - 10 weitere Räume",
    price: 100.0,
    ordertype: "video_tour",
  },
  {
    id: "video_tour_drone_footage",
    name: "Drohnenaufnahmen für Objektbegehung",
    price: 150.0,
    ordertype: "video_tour",
  },
  {
    id: "video_tour_extra_feedback_price",
    name: "Zusätzliches Feedback - Objektbegehung",
    price: 20.0,
    ordertype: "video_tour",
  },
  {
    id: "video_tour_additional_video_link_price",
    name: "Zusätzliches Video Link - Objektbegehung",
    price: 0,
    ordertype: "video_tour",
  },
  {
    id: "image_video_price",
    name: "Imagefilm Preis",
    price: 690.0,
    ordertype: "video_tour",
  },
  {
    id: "image_video_15_additional_seconds_price",
    name: "Imagefilm - weitere 15 Sekunden",
    price: 25.0,
    ordertype: "video_tour",
  },
  {
    id: "image_video_45_additional_seconds_price",
    name: "Imagefilm - weitere 45 Sekunden",
    price: 50.0,
    ordertype: "video_tour",
  },
  {
    id: "image_video_drone_footage",
    name: "Drohnenaufnahmen für Imagefilm",
    price: 150.0,
    ordertype: "video_tour",
  },
  {
    id: "image_video_extra_feedback_price",
    name: "Zusätzliches Feedback - Imagefilm",
    price: 20.0,
    ordertype: "video_tour",
  },
  {
    id: "image_video_additional_video_link_price",
    name: "Zusätzliches Video Link - Imagefilm",
    price: 0,
    ordertype: "video_tour",
  },
  {
    id: "video_tour_diy_extra_seconds_price",
    name: "DIY Videotour - 15 zusätzliche Sekunden",
    price: 15.0,
    ordertype: "video_tour",
  },
  {
    id: "video_tour_diy_premium_package",
    name: "DIY Videotour - Premium Paket",
    price: 20.0,
    ordertype: "video_tour",
  },
  {
    id: "video_tour_diy_premium_plus_package",
    name: "DIY Videotour - Premium Plus Paket",
    price: 40.0,
    ordertype: "video_tour",
  },
  {
    id: "video_tour_diy_extra_feedback_price",
    name: "Zusätzliches Feedback - DIY Objektbegehung",
    price: 20.0,
    ordertype: "video_tour",
  },
  {
    id: "video_tour_diy_price",
    name: "DIY Videotour",
    price: 89.0,
    ordertype: "video_tour",
  },
  {
    id: "video_tour_diy_additional_video_link_price",
    name: "Zusätzliches Video Link - DIY Objektbegehung",
    price: 0,
    ordertype: "video_tour",
  },
  {
    id: "price_hubble_price",
    name: "Price Hubble Bewertungsdossier",
    price: 29.0,
    ordertype: "price_hubble",
  },
  {
    id: "price_hubble_price_free_volume",
    name: "Price Hubble Bewertungsdossier (Frei)",
    price: 0.0,
    ordertype: "price_hubble",
  },
  {
    id: "area_calculation_price",
    name: "Fläche neu berechnen nach WoFIV",
    price: 44.0,
    ordertype: OrderTypeEnum.Area_Calculation,
  },
  {
    id: "area_calculation_certificate",
    name: "Zertifikat Flächenberechnung",
    price: 20.0,
    ordertype: OrderTypeEnum.Area_Calculation,
  },
  {
    id: "area_calculation_feedback",
    name: "zusätzliches Feedback Flächenberechnung",
    price: 15.0,
    ordertype: OrderTypeEnum.Area_Calculation,
  },
  {
    id: "area_calculation_additional_room",
    name: "zusätzlicher Raum - kostenpflichtig",
    price: 10.0,
    ordertype: OrderTypeEnum.Area_Calculation,
  },
  {
    id: "drone_photos_format_4:3",
    name: "Luftaufnahmen - Fotoformat 4:3",
    price: 0.0,
    ordertype: OrderTypeEnum.Drone_Media,
  },
  {
    id: "drone_photos_format_3:2",
    name: "Luftaufnahmen - Fotoformat 3:2",
    price: 0.0,
    ordertype: OrderTypeEnum.Drone_Media,
  },
  {
    id: "hd_photos_format_4:3",
    name: "Fotografie - Fotoformat 4:3",
    price: 0.0,
    ordertype: OrderTypeEnum.Hd_Photos,
  },
  {
    id: "hd_photos_format_3:2",
    name: "Fotografie - Fotoformat 3:2",
    price: 0.0,
    ordertype: OrderTypeEnum.Hd_Photos,
  },
  {
    id: "retouching_format_4:3",
    name: "Bildbearbeitung - Fotoformat 4:3",
    price: 0.0,
    ordertype: OrderTypeEnum.Retouching,
  },
  {
    id: "retouching_format_3:2",
    name: "Bildbearbeitung - Fotoformat 3:2",
    price: 0.0,
    ordertype: OrderTypeEnum.Retouching,
  },
  {
    id: "measurement_price",
    name: "Vermessungsservice (Objektart)",
    price: 355.0,
    ordertype: OrderTypeEnum.Area_Calculation,
  },
  {
    id: "measurement_EFH_price",
    name: "Vermessungsservice (Objektart)",
    price: 459.0,
    ordertype: OrderTypeEnum.Area_Calculation,
  },
  {
    id: "measurement_ETW_price",
    name: "Vermessungsservice (Objektart)",
    price: 389.0,
    ordertype: OrderTypeEnum.Area_Calculation,
  },
  {
    id: "measurement_basement_price",
    name: "Vermessungsservice (Keller)",
    price: 245.0,
    ordertype: OrderTypeEnum.Area_Calculation,
  },
  {
    id: "measurement_attic_price",
    name: "Vermessungsservice (Dachgeschoss)",
    price: 139.0,
    ordertype: OrderTypeEnum.Area_Calculation,
  },
  {
    id: "measurement_per_room_price_price",
    name: "Vermessungsservice (Zusätzliche Räume)",
    price: 20.0,
    ordertype: OrderTypeEnum.Area_Calculation,
  },
  {
    id: "measurement_by_size_price",
    name: "Vermessungsservice (Zusätzliche Quadratmeter)",
    price: 2.0,
    ordertype: OrderTypeEnum.Area_Calculation,
  },
  {
    id: "measurement_by_floor_price",
    name: "Vermessungsservice (Zusätzliche Geschosse)",
    price: 139.0,
    ordertype: OrderTypeEnum.Area_Calculation,
  },
  {
    id: "measurement_by_residential_units_price",
    name: "Vermessungsservice (Zusätzliche Wohneinheiten)",
    price: 279.0,
    ordertype: OrderTypeEnum.Area_Calculation,
  },
  {
    id: "measurement_visit_price",
    name: "Vermessungsservice (Anfahrt)",
    price: 29.0,
    ordertype: OrderTypeEnum.Area_Calculation,
  },
  {
    id: "measurement_additional_charge",
    name: "Vermssungsservice (Stundenbasis)",
    price: 120.0,
    ordertype: OrderTypeEnum.Area_Calculation,
  },
  {
    id: "measurement_cancellation_charge",
    name: "Vermessungsservice Stornogebühren",
    price: 100.0,
    ordertype: OrderTypeEnum.Area_Calculation,
  },

  // Preffered photographer fees
  {
    id: "preferred_photographer_price",
    name: "Wunschfotografen für den Auftrag",
    price: 19.0,
    ordertype: OrderTypeEnum.Hd_Photos,
  },

  //Energy Assessment
  {
    id: "energy_assessment_price",
    name: "Energetische Ersteinschätzung",
    price: 25.0,
    ordertype: OrderTypeEnum.Energy_Pass,
  },
];
