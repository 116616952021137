<!-- Page-Content -->
<ng-container *ngIf="user; else loading">
  <div class="card" *ngIf="user.offenblendeUser">
    <div class="card-header">
      <h5 class="card-title text-left wsn mb-0">Keine Berechtigung!</h5>
    </div>
    <div class="card-body">Diese Funktion steht Ihnen nicht zur Verfügung.</div>
  </div>
  <div class="shadow card cgrid" *ngIf="!user.offenblendeUser">
    <div class="card-body">
      <form *ngIf="authService.emailVerified === true" [formGroup]="profileForm" (ngSubmit)="submitHandler()">
        <div *ngIf="[userRoleEnum.Administrator, userRoleEnum.Customer].includes(user.role)" class="row">
          <div class="col-12 col-md-6 col-lg-4" *ngIf="[userRoleEnum.Administrator].includes(user.role)">
            <div class="form-group mt-4">
              <p class="mb-0">Administrative Unternehmenszuordnung:</p>
              <input
                type="text"
                class="form-control"
                placeholder="Unternehmensgruppe"
                formControlName="companyName"
                [readonly]="true"
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4" *ngIf="!user.isPrivateIndividual">
            <div class="form-group mt-4">
              <p class="mb-0">Unternehmen:</p>
              <input type="text" class="form-control" placeholder="Unternehmen" formControlName="companyNameString" />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4" *ngIf="!user.isPrivateIndividual">
            <div class="form-group mt-4">
              <p class="mb-0">USt-ID:</p>
              <input type="text" class="form-control" placeholder="Ust-ID" formControlName="ustid" />
            </div>
            <div *ngIf="ustid.invalid && ustid.touched" class="alert alert-danger">
              {{ "profile.tax_id_invalid" | translate }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group mt-4">
              <p class="mb-0">{{ "profile.first_name" | translate }} <span class="text-danger">*</span>:</p>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'profile.first_name' | translate }}"
                formControlName="firstName"
              />
            </div>
            <!-- Form Verification Error -->
            <div *ngIf="firstName.invalid && firstName.touched" class="alert alert-warning">
              {{ "profile.first_name_invalid" | translate }}
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group mt-4">
              <p class="mb-0">{{ "profile.lastname" | translate }} <span class="text-danger">*</span>:</p>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'profile.lastname' | translate }}"
                formControlName="lastName"
              />
            </div>
            <div *ngIf="lastName.invalid && lastName.touched" class="alert alert-warning">
              {{ "profile.lastname_invalid" | translate }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group mt-4">
              <p class="mb-0">{{ "profile.street" | translate }} <span class="text-danger">*</span>:</p>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'profile.street' | translate }}"
                formControlName="street"
              />
            </div>
            <!-- Form Verification Error -->
            <div *ngIf="street.invalid && street.touched" class="alert alert-warning">
              {{ "profile.street_invalid" | translate }}
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group mt-4">
              <p class="mb-0">{{ "profile.house_number" | translate }} <span class="text-danger">*</span>:</p>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'profile.house_number' | translate }}"
                formControlName="streetNumber"
              />
            </div>
            <!-- Form Verification Error -->
            <div *ngIf="streetNumber.invalid && streetNumber.touched" class="alert alert-warning">
              {{ "profile.house_number_invalid" | translate }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group mt-4">
              <p class="mb-0">{{ "profile.post_code" | translate }} <span class="text-danger">*</span>:</p>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'profile.post_code' | translate }}"
                formControlName="postalcode"
              />
            </div>
            <!-- Form Verification Error -->
            <div *ngIf="postalcode.invalid && postalcode.touched" class="alert alert-warning">
              {{ "profile.post_code_invalid" | translate }}
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group mt-4">
              <p class="mb-0">{{ "profile.city" | translate }} <span class="text-danger">*</span>:</p>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'profile.city' | translate }}"
                formControlName="city"
              />
            </div>
            <!-- Form Verification Error -->
            <div *ngIf="city.invalid && city.touched" class="alert alert-warning">
              {{ "profile.city_invalid" | translate }}
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group mt-4">
              <p class="mb-0">{{ "profile.country" | translate }} <span class="text-danger">*</span>:</p>
              <div class="select-wrapper fa fa-angle-down w-100">
                <select
                  formControlName="country"
                  class="form-control no-background"
                  [compareWith]="gs.compareString"
                  (change)="updateValidators()"
                >
                  <option *ngFor="let country of countryCodeList" [value]="country.id">
                    {{ country.id }}
                  </option>
                </select>
              </div>
              <div *ngIf="country.invalid && country.touched" class="alert alert-danger">
                {{ "profile.tax_id_invalid" | translate }}
              </div>
            </div>
          </div>
          <div class="col-8 col-md-3"></div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group mt-4">
              <p class="mb-0">{{ "profile.phone" | translate }} <span class="text-danger">*</span>:</p>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'profile.phone' | translate }}"
                formControlName="phone"
              />
            </div>
            <!-- Form Verification Error -->
            <div *ngIf="phone.invalid && phone.touched" class="alert alert-warning">
              {{ "profile.phone_invalid" | translate }}
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group mt-4">
              <p class="mb-0">Rechnungsempfänger E-Mail</p>
              <input type="text" class="form-control" formControlName="invoiceRecipientMail" />
            </div>
          </div>
        </div>
        <div class="row">
          <div *ngIf="user.role === userRoleEnum.Customer" class="col-12 col-md-6 col-lg-4">
            <div class="form-group mt-4">
              <p class="mb-0">Erreichbarkeit:</p>
              <input
                type="text"
                class="form-control"
                placeholder="z.B. Mo-Fr 9:00 Uhr bis 17:00 Uhr"
                formControlName="reachability"
              />
            </div>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-4">
            <div class="form-group mt-4">
              <p class="mb-0">{{ 'profile.language' | translate }} <span class="text-danger">*</span>:</p>
              <mat-select (selectionChange)="gs.changeLanguage($event.value)" [ngClass]="gs.translate.currentLang">
                <mat-select-trigger></mat-select-trigger>
                <mat-option *ngFor="let lang of gs.aviableLanguages" [value]="lang.tag">
                  <img with="20" height="20" src="../assets/i18n/flag-icons/{{lang.tag}}.png"> -
                  {{lang.name | translate}}</mat-option>
              </mat-select>
            </div>
          </div>
        </div> -->

        <!-- Service provider skills -->
        <div *ngIf="user.role === 'Dienstleister'" class="row mt-3">
          <!-- <div class="col-12 col-md-6 shadow card fluid cgrid"> -->
          <!-- <div class="card-header"> -->
          <!-- <h5 class="card-title text-left wsn">Dienstleistungen</h5> -->
          <!-- </div> -->

          <div class="col-12 skills-container">
            <h5 class="card-title text-left wsn">{{ "profile.services" | translate }}</h5>
            <div class="skill-check">
              <mat-checkbox #isoCheck formControlName="dronepilot" color="primary"
                >{{ "profile.drone" | translate }}
              </mat-checkbox>
            </div>
            <div class="skill-check">
              <mat-checkbox #isoCheck formControlName="visualisation" color="primary">
                {{ "profile.visualisation" | translate }}
              </mat-checkbox>
            </div>
            <div class="skill-check">
              <mat-checkbox #isoCheck formControlName="hdphotos" color="primary">
                {{ "profile.photographer" | translate }}
              </mat-checkbox>
            </div>
            <div class="skill-check">
              <mat-checkbox #isoCheck formControlName="floor_overview" color="primary">
                {{ "profile.floor_overview" | translate }}
              </mat-checkbox>
            </div>
            <div class="skill-check">
              <mat-checkbox #isoCheck formControlName="virtual_tour" color="primary">
                {{ "profile.virtual_tour" | translate }}
              </mat-checkbox>
            </div>
            <div class="skill-check">
              <mat-checkbox formControlName="video_tour" color="primary">
                {{ "profile.video_tour" | translate }}
              </mat-checkbox>
            </div>
          </div>
          <!-- </div> -->
        </div>

        <div class="row">
          <div class="col">
            <!-- Form Status -->
            <div class="form-group" *ngIf="profileForm.pristine || !formChanged">
              <!-- No changes (unclickable button that acts as a status),  -->
              <button class="btn btn-outline-secondary" [disabled]="true">
                {{ "profile.button_save_disabled" | translate }}
              </button>
              <span class="text-danger"> *</span> Pflichtfeld
            </div>

            <!-- Form Submit Button -->
            <div class="form-group" *ngIf="!profileForm.pristine && formChanged">
              <!-- Save changes -->
              <button class="btn btn-primary" [disabled]="profileForm.invalid || profileForm.pristine">
                {{ "profile.button_save" | translate }}
              </button>
              <span class="text-danger"> *</span> Pflichtfeld
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="shadow card cgrid">
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <mat-slide-toggle
              color="primary"
              class="consent-toggle"
              labelPosition="before"
              (change)="showConsentBanner()"
              [(ngModel)]="isConsentGiven"
            >
              Datenschutzeinstellungen
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="user.dronepilot && !user.pilotVerified">
    <div *ngIf="!user.verificationDocument0" class="shadow card cgrid">
      <div class="card-header">
        <h5 class="card-title text-left wsn">Vertragliche Vereinbarungen</h5>
      </div>

      <div class="card-body">
        <p style="color: #dc3545">
          Bevor Sie Aufträge annehmen können, müssen Sie diese Vereinbarung unterzeichnen und unterschrieben wieder
          hochladen
        </p>
        <a
          class="btn btn-primary"
          target="_blank"
          rel="noopener"
          href="../../assets/documents/pilot/Vertragliche_Vereinbarung.pdf"
        >
          <i class="far fa-file-pdf"></i> - Vertragliche Vereinbarung
        </a>
      </div>
    </div>
  </div>

  <div class="shadow card fluid cgrid" id="sepa">
    <div class="card-header">
      <h5 class="card-title text-left wsn">Sepa Lastschrift</h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <ng-container *ngIf="paymentMandates.length === 0; else showPaymentMandates">
            <strong>Sie haben noch kein Sepa Mandat angelegt!</strong>
            <br />
            Über die Schaltfläche "SEPA-Mandat hinzufügen" können Sie Ihr Bankkonto für das SEPA-Verfahren hinterlegen.
            Füllen Sie die Daten vollständig aus und kehren Sie anschließend zu Ihrer Bestellung zurück. Sie können auch
            mehrere Mandate hinterlegen.
          </ng-container>
          <ng-template #showPaymentMandates>
            <ul *ngIf="paymentMandates.length > 0">
              <li *ngFor="let mandate of paymentMandates" [value]="mandate.id">
                {{ $any(mandate.details).consumerName + " (" + $any(mandate.details).consumerAccount + ")" }}
                <i
                  [tooltip]="
                    'Mandat wird widerrufen. Achtung, diese Aktion kann nicht rückgängig gemacht werden und es ist eine erneute Verifizierung des Kontos notwendig.'
                  "
                  [adaptivePosition]="false"
                  placement="right"
                  (click)="removeMandate(mandate.id)"
                  class="fas fa-trash text-danger clickable"
                ></i>
              </li>
            </ul>
          </ng-template>
        </div>
        <div
          class="col-12 col-md-6"
          [ngClass]="{
            'd-flex align-items-center flex-row-reverse': paymentMandates.length === 0
          }"
        >
          <ng-container *ngIf="paymentMandates.length > 0">
            <strong>Info</strong>
            <br />
            Fügen Sie weitere Konten hinzu, indem Sie die Schaltfläche "SEPA-Mandat hinzufügen" wählen. Veraltete Mandat
            können jederzeit gelöscht werden.
            <br /><br />
          </ng-container>
          <button class="btn btn-primary mr-5" (click)="addNewAccount()">SEPA-Mandat hinzufügen</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="user.role === userRoleEnum.Customer || user.role === userRoleEnum.Administrator">
    <div class="shadow card cgrid">
      <div class="card-header">
        <h5 class="card-title text-left wsn">Rechnungsadresse</h5>
        <app-imo-billing-address-selector
          [user]="user"
          [mode]="BillingAddressSelectorModeEnum.MANAGE"
        ></app-imo-billing-address-selector>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      (!user.isPrivateIndividual && user.role === userRoleEnum.Customer) || user.role === userRoleEnum.Administrator
    "
  >
    <div class="shadow card cgrid">
      <div class="card-header">
        <h5 class="card-title text-left wsn">Firmenlogo</h5>
      </div>

      <div class="card-body">
        <!-- Don't show upload fields while uploading -->
        <ng-container class="mb-2" *ngIf="uploadingLogo === false; else loading">
          <div class="mb-4 form-group row uploadArea">
            <div *ngIf="!user.companyLogo" class="col-12 col-xl-4 buttonUpload">
              <label class="col-12">
                <div class="row fileContainer text-center align-items-center">
                  <i class="material-icons col-12">cloud_upload</i>
                  <div class="col-12">
                    <span class="btn btn-dark btn-sm pl-2 pr-2 clickable">Datei hochladen</span>
                  </div>
                </div>
                <input
                  type="file"
                  class="alert alert-info"
                  (change)="uploadDocument($event, 'companyLogo', user.uid)"
                />
              </label>
            </div>
            <div *ngIf="user.companyLogo" class="row col-12 align-bottom align-text-bottom pd-2">
              <img
                src="{{ user.companyLogo }}"
                class="img-fluid col-12 col-md-4 col-lg-3 pt-3 pb-3"
                alt="Company Logo"
              />
              <div class="col-12 col-md-4 col-lg-3 text-right logo-delete-icon">
                <i (click)="deleteFile($event, 'companyLogo')" class="fas fa-trash align-bottom clickable"></i>
              </div>
            </div>
          </div>

          <!-- Start file upload -->
          <!-- <div class="form-group row uploadArea">
            <div *ngIf="user.companyLogo === undefined" class="col-2 upload-button-container buttonUpload">
              <label class="fileContainer btn btn-dark btn-sm text-white">
                Datei hochladen
                <input type="file" class="alert alert-info" (change)="uploadDocument($event, 'companyLogo', user.uid)">
              </label>
            </div>
            <div *ngIf="user.companyLogo" class="row col-12 align-bottom align-text-bottom">
              <img src="{{user.companyLogo}}" class="img-fluid col-4" alt="Company Logo">
              <div class="col-4 align-bottom">
                <i (click)="deleteCompanyLogo($event)" [ngClass]="color" (mouseover)="color='text-warning'"
                  (mouseout)="color='text-gray'" class="fas fa-trash fa-4x align-bottom clickable"></i>
              </div>
            </div>
          </div> -->
        </ng-container>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      (!user.isPrivateIndividual && user.role === userRoleEnum.Customer) || user.role === userRoleEnum.Administrator
    "
  >
    <div class="shadow card cgrid">
      <div class="card-header">
        <h5 class="card-title text-left wsn">Wasserzeichen</h5>
      </div>

      <div class="card-body">
        <!-- Don't show upload fields while uploading -->
        <ng-container class="mb-2" *ngIf="uploadingWatermark === false; else loading">
          <div class="mb-4 form-group row uploadArea">
            <div *ngIf="!user.watermark" class="col-12 col-xl-4 buttonUpload">
              <label class="col-12">
                <div class="row fileContainer text-center align-items-center">
                  <i class="material-icons col-12">cloud_upload</i>
                  <div class="col-12">
                    <span class="btn btn-dark btn-sm pl-2 pr-2 clickable">Datei hochladen</span>
                  </div>
                </div>
                <input type="file" class="alert alert-info" (change)="uploadDocument($event, 'watermark', user.uid)" />
              </label>
            </div>
            <div *ngIf="user.watermark" class="row col-12 align-bottom align-text-bottom pd-2">
              <img
                src="{{ user.watermark }}"
                class="img-fluid col-12 col-md-4 col-lg-3 pt-3 pb-3"
                alt="Wasserzeichen"
              />
              <div class="col-12 col-md-4 col-lg-3 text-right logo-delete-icon">
                <i (click)="deleteFile($event, 'watermark')" class="fas fa-trash align-bottom clickable"></i>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="user.dronepilot && !user.pilotVerified">
    <div class="shadow card cgrid">
      <div class="card-header">
        <h5 class="card-title text-left wsn">Dokumentenupload</h5>
      </div>

      <div class="card-body">
        <!-- Don't show upload fields while uploading -->
        <ng-container class="mb-2" *ngIf="uploading === false; else loading">
          <!-- Start file upload -->
          <div class="form-group row">
            <div class="col-4 upload-button-container">
              Vertragliche Vereinbarung <span class="text-danger">*</span>
            </div>
            <div *ngIf="!user.verificationDocument0" class="col-2 upload-button-container">
              <label class="fileContainer btn btn-dark btn-sm text-white">
                Datei hochladen
                <input
                  type="file"
                  class="alert alert-info"
                  (change)="uploadDocument($event, 'vertragliche-vereinbarung', user.uid)"
                />
              </label>
            </div>
            <div *ngIf="user.verificationDocument0" class="col-4">
              <a class="btn btn-primary" target="_blank" rel="noopener" href="{{ user.verificationDocument0 }}">
                <i class="far fa-file"></i> - Vertragliche Vereinbarung
              </a>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-4 upload-button-container">Haftpflicht <span class="text-danger">*</span></div>
            <div *ngIf="!user.verificationDocument1" class="col-2 upload-button-container">
              <label class="fileContainer btn btn-dark btn-sm text-white">
                Datei hochladen
                <input
                  type="file"
                  class="alert alert-info"
                  (change)="uploadDocument($event, 'haftpflicht', user.uid)"
                />
              </label>
            </div>
            <div *ngIf="user.verificationDocument1" class="col-4">
              <a class="btn btn-primary" target="_blank" rel="noopener" href="{{ user.verificationDocument1 }}">
                <i class="far fa-file"></i> - Haftpflicht
              </a>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-4 upload-button-container">Kenntnisnachweis <span class="text-danger">*</span></div>
            <div *ngIf="!user.verificationDocument2" class="col-2 upload-button-container">
              <label class="fileContainer btn btn-dark btn-sm text-white">
                Datei hochladen
                <input
                  type="file"
                  class="alert alert-info"
                  (change)="uploadDocument($event, 'kenntnisnachweis', user.uid)"
                />
              </label>
            </div>
            <div *ngIf="user.verificationDocument2" class="col-4">
              <a class="btn btn-primary" target="_blank" rel="noopener" href="{{ user.verificationDocument2 }}">
                <i class="far fa-file"></i> - Kenntnisnachweis
              </a>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-4 upload-button-container">Aufstiegsgenehmigung (optional)</div>
            <div *ngIf="!user.verificationDocument3" class="col-2 upload-button-container">
              <label class="fileContainer btn btn-dark btn-sm text-white">
                Datei hochladen
                <input
                  type="file"
                  class="alert alert-info"
                  (change)="uploadDocument($event, 'aufstiegsgenehmigung', user.uid)"
                />
              </label>
            </div>
            <div *ngIf="user.verificationDocument3" class="col-4">
              <a class="btn btn-primary" target="_blank" rel="noopener" href="{{ user.verificationDocument3 }}">
                <i class="far fa-file"></i> - Aufstiegsgenehmigung
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-template #loading>
    <!-- CSS Loading Spinner -->
    <div class="lds-ring ring-default">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </ng-template>
</ng-container>

<!-- Information regarding personal data -->
<div class="shadow card fluid cgrid">
  <div class="card-header">
    <h5 class="card-title text-left wsn">{{ "sidebar.privacy" | translate }}</h5>
  </div>

  <div class="card-body">
    <!-- Dynamic Content -->
    <p *ngIf="role === userRoleEnum.ServiceProvider" class="card-text">
      {{ "profile.privacy_text" | translate }}
    </p>
    <p *ngIf="role === userRoleEnum.Customer" class="card-text">
      Ihre geschäftliche Adresse dient lediglich der Rechnungsstellung von IMOGENT an Sie. Alle Daten werden über eine
      gesicherte SSL-Verbindung übertragen und sind für Dritte nicht mitlesbar oder einsehbar.
    </p>
    <a target="_blank" rel="noopener" href="https://www.imogent.de/datenschutz/" class="my-link">{{
      "profile.more_privacy" | translate
    }}</a>
  </div>
</div>

<!-- Information regarding personal data -->
<div class="shadow card fluid cgrid">
  <div class="card-body">
    <div class="row">
      <div class="col-6">
        <app-forwarding-emails
          [isInitSave]="isInitForwardEmailsSave"
          [emails]="forwardEmails"
          [userId]="user.uid"
        ></app-forwarding-emails>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <!-- CSS Loading Spinner -->
  <div class="lds-ring ring-default centered">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-template>
