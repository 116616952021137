<div class="row mb-4">
  <div
    [ngClass]="{
      'col-4': customerInteractionType === CustomerInteractionTypeEnum.DOC_ESTATE,
      'd-none': customerInteractionType === CustomerInteractionTypeEnum.MOOVIN
    }"
  >
    <mat-card class="p-2 interaction-card-shadow">
      <mat-selection-list [multiple]="false" [hideSingleSelectionIndicator]="true">
        <ng-container *ngIf="customerInteractionType === CustomerInteractionTypeEnum.DOC_ESTATE">
          <mat-list-option
            class="ci-list rounded"
            *ngFor="let ci of cils.docEstateCustomerInteraction.customerInteractions; let i = index"
            [value]="ci.id"
            (click)="onCISelect(ci.id)"
            (select)="onCISelect(ci.id)"
            [selected]="selectedInteractionId === ci.id"
          >
            <div class="row align-items-center">
              <div class="col-5 text-left">Interaktion {{ i + 1 }}</div>
              <div class="col-7 text-right">
                <ng-container
                  *ngTemplateOutlet="
                    ciStatusTemplate;
                    context: {
                      status: ci.status
                    }
                  "
                >
                </ng-container>
              </div>
            </div>
          </mat-list-option>
        </ng-container>
        <ng-container *ngIf="customerInteractionType === CustomerInteractionTypeEnum.MOOVIN">
          <mat-list-option
            class="ci-list rounded"
            *ngFor="let ci of cils.moovinCustomerInteraction.customerInteractions; let i = index"
            [value]="ci.id"
            (click)="onCISelect(ci.id)"
            [selected]="selectedInteractionId === ci.id"
          >
            <div class="row align-items-center">
              <div class="col-5 text-left">Interaktion {{ i + 1 }}</div>
              <div class="col-7 text-right">
                <ng-container
                  *ngTemplateOutlet="
                    ciStatusTemplate;
                    context: {
                      status: ci.status
                    }
                  "
                >
                </ng-container>
              </div>
            </div>
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </mat-card>
  </div>
  <div
    [ngClass]="{
      'col-8': customerInteractionType === CustomerInteractionTypeEnum.DOC_ESTATE,
      'col-12': customerInteractionType === CustomerInteractionTypeEnum.MOOVIN
    }"
  >
    <mat-card class="interaction-content-window interaction-card-shadow p-3">
      <div class="col-10 offset-1 text-center mb-3 font-weight-bold">
        <ng-container *ngIf="customerInteractionType === CustomerInteractionTypeEnum.DOC_ESTATE">
          <ng-container [ngSwitch]="cils.selectedDocEstateInteraction?.status">
            <ng-container *ngSwitchCase="DocEstateCustomerInteractionStatusEnum.OPEN">
              Bitte stellen Sie uns die fehlenden Informationen/Dokumente zur Verfügung, damit die Bearbeitung Ihrer
              Bestellung fortgesetzt werden kann.
            </ng-container>
            <ng-container *ngSwitchCase="DocEstateCustomerInteractionStatusEnum.WAITING_FOR_DOCESTATE">
              Die zur Verfügung gestellten Informationen/Dokumente befinden sich in Prüfung. Sobald uns alle Angaben
              vorliegen, kann die Bearbeitung Ihrer Bestellung fortgesetzt werden.
            </ng-container>
            <ng-container *ngSwitchCase="DocEstateCustomerInteractionStatusEnum.CLOSED">
              Alle in dieser Interaktion angefragten Informationen/Dokumente liegen uns nun vor. Sobald alle
              Interaktionen abgeschlossen sind, kann die Bearbeitung Ihrer Bestellung fortgesetzt werden.
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="customerInteractionType === CustomerInteractionTypeEnum.MOOVIN">
          <ng-container [ngSwitch]="cils.selectedMoovinInteraction?.status">
            <ng-container *ngSwitchCase="MoovinCustomerInteractionStatusEnum.EMPTY_INTERACTION">
              Sie können hier Ihre Anmerkungen zu dem vorliegenden Entwurf hinterlassen.
            </ng-container>
            <ng-container *ngSwitchCase="MoovinCustomerInteractionStatusEnum.CUSTOMER_ACTION_REQUIRED">
              Bitte beantworten Sie die vorliegende Rückfrage, damit die Bearbeitung Ihrer Bestellung fortgesetzt werden
              kann.
            </ng-container>
            <ng-container *ngSwitchCase="MoovinCustomerInteractionStatusEnum.SERVICE_PROVIDER_ACTION_REQUIRED">
              Wir bearbeiten Ihre zuletzt gesendete Anfrage. Falls wir weitere Rückfragen haben oder ein neuer Entwurf
              vorliegt, informieren wir Sie.
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="customerInteractionType === CustomerInteractionTypeEnum.DOC_ESTATE">
        <div class="row mb-3 align-items-center" *ngFor="let message of cils.selectedDocEstateInteraction?.messages">
          <div class="col-2 text-center" *ngIf="message.author === docEstateMessageAuthorType.DOCESTATE">
            <img src="../../../assets/icons/icon-72x72.png" class="imogent-icon" />
            <p class="font-smaller mb-0">{{ message.date | date : "dd.MM.yyyy" }}</p>
          </div>
          <div class="col-10">
            <i
              class="fas fa-caret-left chat-triangle-left fa-2x"
              *ngIf="message.author === docEstateMessageAuthorType.DOCESTATE"
            ></i>
            <div
              class="message-box rounded w-75 pl-3 py-2"
              [class.user-message]="message.author === docEstateMessageAuthorType.USER"
              [class.imo-message]="message.author === docEstateMessageAuthorType.DOCESTATE"
            >
              <ng-container *ngIf="message.type === messageTypeEnum.FILE">
                <a
                  (click)="openFile(message.id, cils.selectedDocEstateInteraction?.propertyId, $event)"
                  class="clickable"
                  >Datei hochgeladen</a
                >
              </ng-container>
              <ng-container *ngIf="message.type !== messageTypeEnum.FILE">{{ message.message }}</ng-container>
            </div>
            <i
              class="fas fa-caret-right chat-triangle-right fa-2x"
              *ngIf="message.author === docEstateMessageAuthorType.USER"
            ></i>
          </div>
          <div class="col-2 text-center" *ngIf="message.author === docEstateMessageAuthorType.USER">
            <i class="fas fa-user-circle fa-2x text-secondary"></i>
            <p class="font-smaller mb-0">{{ message.date | date : "dd.MM.yyyy" }}</p>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="customerInteractionType === CustomerInteractionTypeEnum.MOOVIN">
        <div class="row mb-3 align-items-center" *ngFor="let message of cils.selectedMoovinInteraction?.messages">
          <div class="col-2 text-center" *ngIf="message.author === MoovinMessageAuthorTypeEnum.MOOVIN">
            <img src="../../../assets/icons/icon-72x72.png" class="imogent-icon" />
            <p class="font-smaller mb-0">{{ message.date | date : "dd.MM.yyyy" }}</p>
          </div>
          <div class="col-10">
            <i
              class="fas fa-caret-left chat-triangle-left fa-2x"
              *ngIf="message.author === MoovinMessageAuthorTypeEnum.MOOVIN"
            ></i>
            <div
              class="message-box rounded w-75 pl-3 py-2"
              [class.user-message]="message.author === MoovinMessageAuthorTypeEnum.CUSTOMER"
              [class.imo-message]="message.author === MoovinMessageAuthorTypeEnum.MOOVIN"
            >
              {{ message.message }}
            </div>
            <i
              class="fas fa-caret-right chat-triangle-right fa-2x"
              *ngIf="message.author === MoovinMessageAuthorTypeEnum.CUSTOMER"
            ></i>
          </div>
          <div class="col-2 text-center" *ngIf="message.author === MoovinMessageAuthorTypeEnum.CUSTOMER">
            <i class="fas fa-user-circle fa-2x text-secondary"></i>
            <p class="font-smaller mb-0">{{ message.date | date : "dd.MM.yyyy" }}</p>
          </div>
        </div>
      </ng-container>

      <div
        class="row"
        *ngIf="
          cils.selectedDocEstateInteraction?.status === DocEstateCustomerInteractionStatusEnum.OPEN ||
          cils.moovinCustomerInteraction
        "
      >
        <div class="col-12 text-center">
          <mat-button-toggle-group [(ngModel)]="userResponseType">
            <mat-button-toggle
              [value]="customerResponseType.FILE"
              (click)="setResponseType(customerResponseType.FILE, customerResponseType.TEXT)"
              >Dokument hinzufügen
            </mat-button-toggle>
            <mat-button-toggle
              [value]="customerResponseType.TEXT"
              (click)="setResponseType(customerResponseType.TEXT, customerResponseType.FILE)"
              >Freitextantwort geben
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="col-12 mt-3">
          <ng-container *ngIf="userResponseType === customerResponseType.FILE">
            <div class="col-12 form-group uploadArea mt-3" *ngIf="fileUploader.queue.length <= 0">
              <div class="buttonUpload">
                <label class="w-100">
                  <div
                    class="row fileContainer text-center align-items-center"
                    [class.error]="inputError && fileUploader.queue.length <= 0"
                  >
                    <i class="fas fa-file-upload col-12 mt-3"></i>
                    <div class="col-12">
                      <span class="text-gray font-weight-bold"
                        >Bitte laden Sie hier Ihre Unterlagen hoch
                        <ng-container *ngIf="customerInteractionType === CustomerInteractionTypeEnum.DOC_ESTATE"
                          >(pdf, <{{ CONSTANTS.FILE_SIZE_LIMIT_5MB | bytes }}
                        </ng-container>
                        <ng-container *ngIf="customerInteractionType === CustomerInteractionTypeEnum.MOOVIN">
                          (max. {{ CONSTANTS.FILE_SIZE_LIMIT_10MB | bytes }}
                        </ng-container>
                        ).</span
                      >
                    </div>
                  </div>
                  <input
                    [uploader]="fileUploader"
                    [writeFile]="true"
                    [accept]="customerInteractionType === CustomerInteractionTypeEnum.DOC_ESTATE ? ['pdf'] : ['*']"
                    multiple="false"
                    ng2FileSelect
                    type="file"
                  />
                </label>
              </div>
            </div>
            <div class="col-12 file-list" *ngIf="fileUploader.queue.length > 0">
              <div class="file-entry row" *ngFor="let file of fileUploader.queue">
                <div class="col-8 file-name">
                  {{ file.file.name }}
                </div>
                <div class="col-4 text-right">
                  <span class="fas fa-trash-alt text-danger clickable" (click)="removeFileFromQueue(file)"></span>
                </div>
              </div>
            </div>
            <div
              class="col-12 mt-3 info-box p-2 rounded light-blue-bg"
              *ngIf="customerInteractionType === CustomerInteractionTypeEnum.DOC_ESTATE"
            >
              <div class="d-flex flex-row">
                <div>
                  <i class="fas fa-info-circle text-primary mr-2 font-size-large"></i>
                </div>
                <div class="text-justify">
                  Nutzen Sie einen Scanner oder eine Scannerapp, um händisch signierte Vollmachten zu digitalisieren.
                  Bitte laden Sie keine handgeschriebenen Vollmachten hoch.
                </div>
              </div>
            </div>
            <div class="col-12 mt-3 info-box p-2 rounded warning-background-light" [hidden]="hideFileUploaderWarning">
              <div class="d-flex flex-row">
                <div>
                  <i class="fas fa-exclamation-triangle warning-text mr-2 font-size-large"></i>
                </div>
                <div class="text-justify warning-text">
                  Bitte laden Sie eine Datei hoch, die kleiner als {{ CONSTANTS.FILE_SIZE_LIMIT_5MB | bytes }} ist.
                  <a href="{{ CONSTANTS.PDF_EDITOR_APP_LINK }}" class="warning-text font-weight-bold" target="_blank">
                    <u>Hier</u>
                  </a>
                  können Sie die Dateigröße reduzieren.
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="userResponseType === customerResponseType.TEXT">
            <label>Ihre Nachricht</label>
            <textarea
              class="form-control"
              [(ngModel)]="userResponseText"
              placeholder="Geben Sie hier Ihre Nachricht ein."
              [class.error]="inputError && !userResponseText"
            ></textarea>
            <div class="text-danger mt-1 font-small" *ngIf="inputError && !userResponseText">Pflichtfeld</div>
          </ng-container>
        </div>
        <div class="col-12 mt-3">
          <button
            class="btn btn-primary col-12"
            [disabled]="
              (userResponseType === customerResponseType.TEXT && !userResponseText.trim()) ||
              (userResponseType === customerResponseType.FILE && !fileUploader.queue.length)
            "
            (click)="sendResponse()"
          >
            Absenden
          </button>
        </div>
      </div>
    </mat-card>
  </div>
</div>

<ng-template #ciStatusTemplate let-status="status">
  <ng-container [ngSwitch]="status">
    <ng-container *ngSwitchCase="DocEstateCustomerInteractionStatusEnum.OPEN">
      <span class="text-danger bg-danger-light font-smaller py-1 px-2 rounded">
        {{ status | applisttranslate : "docestate_ci_status" }}
      </span>
      <span class="fas fa-chevron-right ml-1"></span>
    </ng-container>
    <ng-container *ngSwitchCase="DocEstateCustomerInteractionStatusEnum.WAITING_FOR_DOCESTATE">
      <span class="text-warning bg-alert-light font-smaller py-1 px-2 rounded">
        {{ status | applisttranslate : "docestate_ci_status" }}
      </span>
      <span class="fas fa-chevron-right ml-1"></span>
    </ng-container>
    <ng-container *ngSwitchCase="DocEstateCustomerInteractionStatusEnum.HOLD">
      <span class="text-info bg-info-light font-smaller py-1 px-2 rounded">
        {{ status | applisttranslate : "docestate_ci_status" }}
      </span>
      <span class="fas fa-chevron-right ml-1"></span>
    </ng-container>
    <ng-container *ngSwitchCase="DocEstateCustomerInteractionStatusEnum.CLOSED">
      <span class="text-primary bg-primary-light font-smaller py-1 px-2 rounded">
        {{ status | applisttranslate : "docestate_ci_status" }}
      </span>
      <span class="fas fa-chevron-right ml-1"></span>
    </ng-container>
  </ng-container>
</ng-template>
