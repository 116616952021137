import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UploadService } from "@app/_services/upload.service";
import { AuthService } from "@app/auth/auth.service";
import { LoaderService } from "@app/_services/loader.service";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { SubcollectionFileModel } from "@app/models/subcollectionFileModel";
import { UserRoleEnum } from "@app/models/user-role-list";
import { ExternalRealEstateInformation, RealestateFloors } from "@app/interfaces/real-estate.interface";
import { UsersLogicService } from "@app/_services/users-logic.service";
import { CountryCodeList, CountryEnum } from "@app/models/country-code-list";
import { PaymentsLogicService } from "@app/_services/payments-logic.service";
import { MollieMandate } from "@app/interfaces/mollie-mandate.interface";
import { MolliePaymentMethodEnum } from "@app/models/mollie-payment-method.enum";
import { ViewportScroller } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { MolliePayment } from "@app/interfaces/mollie-payment.interface";
import { CONSTANTS } from "@app/util/constants";
import { BroadcasterService } from "@app/_services/broadcaster.service";
import { NotificationsService } from "@app/_services/notifications.service";
import { GlobalService } from "@app/_services/global.service";
import { MatDialog } from "@angular/material/dialog";
import { CookieService } from "ngx-cookie-service";
import { ImoConsentBannerComponent } from "@app/imo-consent-banner/imo-consent-banner.component";
import { init, routingInstrumentation } from "@sentry/angular";
import { environment } from "@environments/environment";
import { BrowserTracing } from "@sentry/tracing";
import { User } from "@app/interfaces/user.interface";
import { BillingAddressSelectorModeEnum } from "@app/models/billingAddressSelectorMode.enum";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly logComponent = "ProfileComponent :: ";

  profileForm: FormGroup;
  // Form State
  success = false;
  uploading = false;
  uploadingLogo = false;
  uploadingWatermark = false;
  user$;
  user: any;
  userRoleEnum = UserRoleEnum;
  profile;
  role: string;
  color = "text-gray";
  countryCodeList = CountryCodeList;
  originalData = "";
  formChanged = false;
  userId = "";
  forwardEmails: string[] = [];
  isInitForwardEmailsSave: boolean;
  floors: RealestateFloors[] = [];
  paymentMandates: MollieMandate[] = [];
  directDebitMandates: MollieMandate[] = [];
  isConsentGiven = false;

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    private uls: UsersLogicService,
    private uploadService: UploadService,
    public loader: LoaderService,
    public ns: NotificationsService,
    private ps: PaymentsLogicService,
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute,
    private bs: BroadcasterService,
    public gs: GlobalService,
    public dialog: MatDialog,
    private cookieService: CookieService
  ) {
    this.profileForm = this.fb.group({
      firstName: ["", [Validators.required, Validators.pattern("\\S.*")]],
      lastName: ["", [Validators.required, Validators.pattern("\\S.*")]],
      companyName: [""],
      companyNameString: [""],
      ustid: ["", [CONSTANTS.VALIDATION_PATTERN.TAXID_GER]],
      street: ["", [Validators.required, Validators.pattern("^\\D+$")]],
      streetNumber: ["", [Validators.required]],
      postalcode: ["", [Validators.required, Validators.pattern("^[0-9]{4,5}$")]],
      city: ["", [Validators.required]],
      country: ["", [Validators.required]],
      phone: ["", [Validators.required, Validators.pattern("[0-9+/ -]*")]],
      invoiceRecipientMail: [
        "",
        [Validators.pattern("^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$")],
      ],
      drone: [""],
      dronepilot: [""],
      visualisation: [""],
      hdphotos: [""],
      // floorplan: [''],,
      // vstaging: [''],
      reachability: [""],
      floor_overview: [""],
      virtual_tour: [""],
      video_tour: [""],
      business_line: [""],
      business_line_location: [""],
    });
  }

  async ngOnInit() {
    this.role = this.authService.myUserObservable["role"];
    this.user = this.authService.myUserObservable;
    this.userId = this.user.uid;
    this.preloadData();
    this.profileForm.valueChanges.subscribe(() => {
      if (this.originalData === JSON.stringify(this.profileForm.value)) {
        this.formChanged = false;
      } else {
        this.formChanged = true;
      }
    });
    this.forwardEmails = this.user.forwardEmails?.length > 0 ? [...this.user.forwardEmails] : [this.user.email];
    this.isInitForwardEmailsSave = typeof this.user.forwardEmails === "undefined";

    await this.loadMandates();
  }

  updateValidators() {
    if (this.country.value === CountryEnum.Germany) {
      this.ustid.setValidators([CONSTANTS.VALIDATION_PATTERN.TAXID_GER]);
    } else {
      this.ustid.clearValidators();
    }
    this.ustid.updateValueAndValidity();
    this.profileForm.updateValueAndValidity();
  }

  async loadMandates() {
    try {
      this.paymentMandates = await this.ps.getMandates(MolliePaymentMethodEnum.directdebit).toPromise();
      this.bs.sendMessage(this.paymentMandates);
    } catch (err) {
      console.log(this.logComponent + "No payment mandates found.");
    }
  }

  ngAfterViewInit() {
    const routeFragment = this.route.snapshot.fragment;

    if (routeFragment) {
      this.viewportScroller.scrollToAnchor(routeFragment);
    }
  }

  ngOnDestroy() {
    this.profile && this.profile.unsubscribe(); // Missing or insufficient permissions Error, Memory Leak fix
  }

  get firstName() {
    return this.profileForm.get("firstName");
  }

  get lastName() {
    return this.profileForm.get("lastName");
  }

  get companyName() {
    return this.profileForm.get("companyName");
  }

  get companyNameString() {
    return this.profileForm.get("companyNameString");
  }

  get ustid() {
    return this.profileForm.get("ustid");
  }

  get street() {
    return this.profileForm.get("street");
  }

  get streetNumber() {
    return this.profileForm.get("streetNumber");
  }

  get postalcode() {
    return this.profileForm.get("postalcode");
  }

  get city() {
    return this.profileForm.get("city");
  }

  get country() {
    return this.profileForm.get("country");
  }

  get phone() {
    return this.profileForm.get("phone");
  }

  get dronepilot() {
    return this.profileForm.get("dronepilot");
  }

  get visualisation() {
    return this.profileForm.get("visualisation");
  }

  get hdphotos() {
    return this.profileForm.get("hdphotos");
  }

  // get floorplan() {
  //   return this.profileForm.get('floorplan');
  // }
  // get vstaging() {
  //   return this.profileForm.get('vstaging');
  // }

  get reachability() {
    return this.profileForm.get("reachability");
  }

  get floor_overview() {
    return this.profileForm.get("floor_overview");
  }

  /**
   * Submit handler for profile form. Updates the user document in firebase
   */
  async submitHandler() {
    this.profileForm.value.profileCompleted = true;
    const formValue = this.profileForm.value;
    this.profileForm.markAsPristine();
    let isBillingAddressAutoAdded = false;
    const userData = { ...formValue };
    if (!this.user.billingAddresses?.length) {
      userData.billingAddresses = [
        {
          firstName: userData.firstName,
          lastName: userData.lastName,
          name: userData.firstName + " " + userData.lastName,
          email: userData.invoiceRecipientMail,
          company: userData.companyNameString,
          taxId: userData.ustid,
          street: userData.street,
          number: userData.streetNumber,
          postalCode: userData.postalcode,
          city: userData.city,
          phone: userData.phone,
          country: userData.country,
        },
      ];
      isBillingAddressAutoAdded = true;
    }

    this.uls
      .updateUser(this.userId, userData)
      .then((userDetails) => {
        this.ns.showNotification("Profil erfolgreich aktualisiert", "success");
        if (isBillingAddressAutoAdded) {
          this.ns.showNotification("Adresse wurde automatisch als Rechnungsadresse hinzugefügt.", "success");
        }
        this.user = userDetails;
        this.profileForm.patchValue(userDetails);
        this.originalData = JSON.stringify(this.profileForm.value);
        this.authService.myUserObservable = { ...this.authService.myUserObservable, ...userDetails };
      })
      .catch((error) => {
        console.log("Profile details could not be updated");
        console.log(error);
        this.ns.showNotification("Das Profil konnte nicht aktualisiert werden", "danger");
      });
  }

  /**
   * Preload data for user profile
   */
  async preloadData() {
    if (!this.user) {
      this.uls.getUserDetails(this.userId).subscribe(
        (userDetails) => {
          this.user = userDetails;
        },
        (error) => {
          console.log("Could not fetch user details");
          console.log(error);
          this.ns.showNotification("Profile details could not be fetched", "danger");
        }
      );
    } else {
      this.updateUserDetailsInComponent(this.user);
    }
  }

  updateUserDetailsInComponent(user: User) {
    this.isConsentGiven = this.user.userConsentForSentry === "true";
    this.profileForm.patchValue(user);
    this.originalData = JSON.stringify(this.profileForm.value);
  }

  /**
   * Gets triggered when user uploads the Pilot Contract document
   * Uploads the document to firebase and updates the verificationDocument0 url in user document on firebase
   */
  async uploadDocument(event: any, fileName: string, uid: string) {
    if (fileName === "companyLogo") {
      this.uploadingLogo = true;
    } else if (fileName === "watermark") {
      this.uploadingWatermark = true;
    } else {
      this.uploading = true;
    }
    this.uploadService.uploadFile(event.target.files[0], `users/${uid}/`, false, "").subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.Response) {
          const fileDetails = <SubcollectionFileModel>event.body;
          console.log("Uploaded the document, updating the user details");
          const documentFields = {
            "vertragliche-vereinbarung": "verificationDocument0",
            haftpflicht: "verificationDocument1",
            kenntnisnachweis: "verificationDocument2",
            aufstiegsgenehmigung: "verificationDocument3",
            companyLogo: "companyLogo",
            watermark: "watermark",
          };
          const requestData = {};
          requestData[documentFields[fileName]] = fileDetails.download_url;
          requestData[documentFields[fileName] + "_file_name"] = fileDetails.file_name;
          requestData[documentFields[fileName] + "_file_size"] = fileDetails.file_size;
          if (fileName !== "companyLogo" && fileName !== "watermark") {
            requestData["verificationNeeded"] = true;
          }
          this.uls
            .updateUser(uid, requestData)
            .then((userDetails) => {
              this.ns.showNotification("Dokument erfolgreich hochgeladen", "success");
              this.user = userDetails;
              this.profileForm.patchValue(userDetails);
              this.originalData = JSON.stringify(this.profileForm.value);
              this.authService.myUserObservable = { ...this.authService.myUserObservable, ...userDetails };
            })
            .catch((error) => {
              console.log("Could not update user details");
              console.log(error);
              this.ns.showNotification(
                "Das Dokument wurde hochgeladen, aber die Details konnten nicht aktualisiert werden",
                "danger"
              );
            });
        }
      },
      (error) => {
        console.log("Could not upload the document");
        console.log(error);
        this.ns.showNotification("Das Dokument konnte nicht hochgeladen werden", "danger");
      }
    );

    this.uploading = false;
    this.uploadingLogo = false;
    this.uploadingWatermark = false;
  }

  deleteFile(event: Event, fileType: string) {
    console.log("clicked trash");
    event.stopPropagation();
    event.preventDefault();
    const confirmed = confirm(
      "Sind Sie sicher, dass Sie diese Datei löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden!"
    );
    let path = `users/${this.userId}/`;
    if (fileType === "companyLogo") {
      if (this.user && this.user.companyLogo_file_name) {
        path += this.user.companyLogo_file_name;
      } else if (this.user && this.user.companyLogo) {
        path += "companyLogo";
      }
    } else if (fileType === "watermark") {
      if (this.user && this.user.watermark_file_name) {
        path += this.user.watermark_file_name;
      } else if (this.user && this.user.watermark) {
        path += "watermark";
      }
    }

    if (confirmed) {
      this.uploadService.deleteFile(path).subscribe(
        () => {
          let requestData = {};
          if (fileType === "companyLogo") {
            requestData = {
              companyLogo: "",
              companyLogo_file_name: "",
              companyLogo_file_size: "",
            };
          } else if (fileType === "watermark") {
            requestData = {
              watermark: "",
              watermark_file_name: "",
              watermark_file_size: "",
            };
          }
          this.uls
            .updateUser(this.user.uid, requestData)
            .then((userDetails) => {
              this.user = userDetails;
              this.profileForm.patchValue(userDetails);
              this.originalData = JSON.stringify(this.profileForm.value);
              this.authService.myUserObservable = { ...this.authService.myUserObservable, ...userDetails };
            })
            .catch((error) => {
              console.log("Could not update the document details to user");
              console.log(error);
              this.ns.showNotification(
                "Die Datei wurde gelöscht, aber die Details konnten nicht aktualisiert werden",
                "danger"
              );
            });
        },
        (error) => {
          console.log("Could not upload the document");
          console.log(error);
          this.ns.showNotification("Die Datei konnte nicht gelöscht werden", "danger");
        }
      );
    }
  }

  compareBusinessLineObjects(object1: any, object2: any) {
    return object1 && object2 && object1.businessLine === object2.businessLine;
  }

  compareString(object1: string, object2: string) {
    return object1 && object2 && object2 === object1;
  }

  async addNewAccount() {
    let payment: MolliePayment;
    let paymentCheckoutUrl: string;

    const description = "Neue Kontoverbindung";
    // SEPA Direct Debit can only be activated after an initial payment using a specific set of payment methods (directdebit is not allowed here, only sofort and a few others). Check 'Setting up the first payment' notes from here https://docs.mollie.com/payments/recurring
    const molliePaymentMethod = MolliePaymentMethodEnum.sofort;
    // 10cts is the minimum amount we can charge for Sofort payment method https://help.mollie.com/hc/en-us/articles/115000667365
    const amount = { value: "0.10", currency: CONSTANTS.CURRENCIES.EURO };
    const redirectUrl = window.location.href;
    const webhookUrl = this.ps.getMollieWebhookUrl();
    const metadata = { userId: this.userId };

    ({ payment, paymentCheckoutUrl } = await this.ps.createFirstPayment(
      molliePaymentMethod,
      amount,
      description,
      redirectUrl,
      webhookUrl,
      metadata
    ));

    // update user with mollie payment
    if (!this.user.molliePayments) {
      this.user.molliePayments = [];
    }
    this.user.molliePayments.push(payment);
    this.uls.updateUser(this.userId, {
      molliePayments: this.user.molliePayments,
    });

    if (paymentCheckoutUrl) {
      // redirecting the customer to the payment checkout url
      window.location.replace(paymentCheckoutUrl);
    }
  }

  removeMandate(mandateId: string) {
    const confirmed = confirm(
      "Sind Sie sicher, dass Sie dies Mandat löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden!"
    );

    if (confirmed) {
      this.ps.removeMandate(mandateId).subscribe(
        async () => {
          this.ns.showNotification("Mandat erfolgreich entfernt.", "success");
          await this.loadMandates();
        },
        (error) => {
          console.error(error);
          this.ns.showNotification(
            "Die Aktion konnte nicht abgeschlossen werden. Bitte versuchen Sie es erneut.",
            "danger"
          );
        }
      );
    }
  }

  showConsentBanner() {
    const consentDialog = this.dialog.open(ImoConsentBannerComponent, {
      disableClose: true,
      maxWidth: 550,
      panelClass: "consent-banner",
    });
    consentDialog.beforeClosed().subscribe(async (result) => {
      const value = result.toString();
      if (this.user.userConsentForSentry !== value) {
        await this.uls.updateUser(this.userId, {
          userConsentForSentry: value,
        });
        if (!this.isConsentGiven) {
          init({});
        } else {
          init({
            dsn: environment.sentryService.dsn,
            environment: environment.env,
            integrations: [
              // Registers and configures the Tracing integration,
              // which automatically instruments your application to monitor its
              // performance, including custom Angular routing instrumentation
              new BrowserTracing({
                tracingOrigins: ["localhost", environment.apiUrl],
                routingInstrumentation: routingInstrumentation,
              }),
            ],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: environment.sentryService.tracesSampleRate,
          });
        }
      }
      this.cookieService.set(CONSTANTS.USER_CONSENT_COOKIE_NAME, value);
      this.user.userConsentForSentry = value;
      this.authService.myUserObservable.userConsentForSentry = value;
      this.isConsentGiven = value === "true";
    });
  }

  protected readonly BillingAddressSelectorModeEnum = BillingAddressSelectorModeEnum;
}
