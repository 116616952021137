export enum BlurOptions {
  PhotosDocumentsPlates = "PhotosDocumentsPlates",
  SurroundingHouses = "SurroundingHouses",
}

export const blurOptionsList: { id: string; name: string }[] = [
  {
    id: BlurOptions.SurroundingHouses,
    name: "Nachbarhäuser weichzeichnen",
  },
  {
    id: BlurOptions.PhotosDocumentsPlates,
    name: "Nachbarhäuser nicht weichzeichnen",
  },
];
