import { CollectiveInvoiceIntervalOption } from "@app/interfaces/collectiveInvoiceIntervalOption.interface";

export enum CollectiveInvoiceIntervalEnum {
  Monthly = "Monthly",
  Weekly = "Weekly",
  WeeklyCompany = "WeeklyCompany",
  Individual = "Individual",
  None = "",
}

export const collectiveInvoiceOptionList: CollectiveInvoiceIntervalOption[] = [
  {
    id: CollectiveInvoiceIntervalEnum.Monthly,
    name: "Monatlich",
  },
  {
    id: CollectiveInvoiceIntervalEnum.Weekly,
    name: "Wöchentlich",
  },
  {
    id: CollectiveInvoiceIntervalEnum.WeeklyCompany,
    name: "Wöchentlich (Unternehmen)",
  },
  {
    id: CollectiveInvoiceIntervalEnum.Individual,
    name: "Individuell",
  },
];
