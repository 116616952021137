// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// `ng build --configuration=staging` replaces `environment.ts` with `environment.staging.ts`
// `ng build --configuration=dev` replaces `environment.ts` with `environment.dev.ts`
// The list of file replacements can be found in `angular.json`.

/* Following is the dev key */

// Use either the localhost for localdevelopment or, to access the dev server the api-dev domain.
const apiBaseURL = "https://api-dev.imogent.com";
// const apiBaseURL = "http://localhost:3002";

export const environment = {
  production: false,
  apiBaseUrl: "https://functions-dev.imogent.com/",
  firebaseProjectId: "imogent-platform-staging",
  esoft: {
    // configuration related to esoft integration
    apiURL: "https://dev-m2.esoftsystems.com/ewarp/",
    apiUser: "test",
    apiPassword: "test",
    clientId: "50100166",
    receivingCompany: "ESVN",
    callbackUrl: "https://functions-dev.imogent.com/webApi/api/v1/entryPoint/esoft",
    orderContacts: [
      {
        name: "Daniel Druchleben",
        phoneMobile: "+4915156089653",
        email: "dev@imogent.com",
        contactType: "OWNER",
      },
    ],
  },
  offenblende: {
    portalURL: "https://dev.portal.offenblende.de/app/",
  },
  mollieTestMode: true,
  platformUrl: "https://imogent-platform-staging.web.app/",
  env: "dev",
  sentryService: {
    dsn: "https://c8602f8d83d842feb3d1ef5e761836d1@sentry.imogent.com/2",
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
  },
  externalApiGatewayUrl: apiBaseURL + "/api/v1/apis/out/",
  apiUrl: apiBaseURL + "/api/v1/",
  websocketUrl: apiBaseURL + "",

  imogentCompanyId: "1xtGCdNse3d27fd6HqnI",
  FALC_companyId: "6Lwdpx4cSEjuJQatlxJQ",
  fairFleetUserId: "pDGh1pjF1zQmQvC4OHBSQkgd4HU2",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
