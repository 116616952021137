import { CONSTANTS } from "@app/util/constants";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { DocEstateCustomerInteractionStatusEnum, DocEstateInteractionTypeEnum } from "@app/models/docEstate-ci.enum";
import { InvoiceStatusEnum } from "@app/models/invoiceStatus.enum";
import { DunningTypeEnum } from "@app/models/dunningType.enum";

export const LANGUAGE = {
  app_list_strings: {
    order_type_list: {
      [OrderTypeEnum.Drone_Media]: "Luftaufnahmen",
      [OrderTypeEnum.Retouching]: "Bildbearbeitung",
      [OrderTypeEnum.Floor_Plan]: "Grundrisse",
      [OrderTypeEnum.V_Staging]: "Digital Staging",
      [OrderTypeEnum.Visualisation]: "Visualisierungen",
      [OrderTypeEnum.Hd_Photos]: "Fotografie",
      [OrderTypeEnum.Expose]: "Exposé",
      [OrderTypeEnum.Floor_Overview]: "Wohnungsfinder",
      [OrderTypeEnum.Virtual_Tour]: "Virtueller Rundgang",
      [OrderTypeEnum.Documents_Procurement]: "Behördenauskünfte",
      [OrderTypeEnum.Video_Tour]: "Videotour",
      [OrderTypeEnum.Price_Hubble]: "PriceHubble",
      [OrderTypeEnum.Area_Calculation]: "Wohnflächenberechnung",
      [OrderTypeEnum.Energy_Pass]: "Energieausweis",
      [OrderTypeEnum.Energy_Pass]: "Energieausweis",
    },
    status_list: {
      opened: "Prüfung erforderlich",
      pending: "Wartet auf Aufnahmen",
      checked: "In Vermittlung",
      processing: "In Bearbeitung",
      finalizing: "In Bearbeitung (Finalisierung)",
      completed: "Abgeschlossen",
      accepted: "Akzeptiert",
      uploaded: "In Nachbearbeitung",
      requested: "Angefragt",
      canceled: "Storniert",
    },
    action_list: {
      adminAction: "Admin-Aktion erforderlich",
      customerAction: "Kunden-Aktion erforderlich",
      serviceProviderAction: "Dienstleister-Aktion erforderlich",
      noAction: "Keine Aktion erforderlich",
    },
    invoice_status_list: {
      [InvoiceStatusEnum.Created]: "Rechnung erstellt",
      [InvoiceStatusEnum.Invoice]: "Rechnung erstellt",
      [InvoiceStatusEnum.Paid]: "Zahlung eingegangen",
      [InvoiceStatusEnum.Canceled]: "Rechnung storniert",
    },
    dunning_types: {
      [DunningTypeEnum.First_Reminder]: "Zahlungserinnerung",
      [DunningTypeEnum.Dunning]: "Mahnung",
      [DunningTypeEnum.Final_Dunning]: "Letzte Mahnung",
    },
    add_unit_map: {
      Foto: "Bilder",
      Sekunden: "Videolänge",
    },
    status_class_list: {
      opened: "text-danger",
      checked: "text-warning",
      processing: "text-success",
      completed: "text-success",
      accepted: "text-success",
      uploaded: "text-danger",
      requested: "text-warning",
      canceled: "text-danger",
      pending: "text-danger",
    },
    service_provider_status: {
      opened: "bald verfügbar",
      pending: "bald verfügbar",
      requested: "Angefragt",
      processing: "In Bearbeitung",
      uploaded: "In Prüfung",
      completed: "Abgeschlossen",
      accepted: "Akzeptiert",
    },
    docestate_ci_status: {
      [DocEstateCustomerInteractionStatusEnum.OPEN]: "Aktion erforderlich",
      [DocEstateCustomerInteractionStatusEnum.WAITING_FOR_DOCESTATE]: "In Bearbeitung",
      [DocEstateCustomerInteractionStatusEnum.HOLD]: "Pausiert",
      [DocEstateCustomerInteractionStatusEnum.CLOSED]: "Abgeschlossen",
    },
    docestate_ci_type: {
      [DocEstateInteractionTypeEnum.MISSING_AUTHORITY]: "Vollmacht anpassen",
      [DocEstateInteractionTypeEnum.INCORRECT_DATA]: "Objektdaten anpassen",
    },
  },
  app_strings: {
    // add standalone translations in this object e.g 'foo' : 'bar'
    LBL_ADD_Foto_DESC: "Sie können das gewählte Paket um zusätzliche Bilder ergänzen",
    LBL_ADD_Sekunden_DESC: "Sie können beliebig Videolänge hinzufügen",
    LBL_ADD: "hinzufügen",
    LBL_INDV_VT: "Virtuelle Tour (Einzelspots)",
    LBL_INDV_VT_DESC: "Jedes 360°-Bild wird unter einem separaten Link aufgeführt. Beispiel:",
    LBL_FREE: "gratis",
  },
};

// TODO : Replace all the hardcoded packageNames with constants from constant file
export const SUBORDER_DETAILS_MAP = {
  [OrderTypeEnum.Drone_Media]: {
    packageName: {
      label: "Produkt",
      type: "string",
    },
    numberOfPhotos: {
      label: "Anzahl der Fotos",
      dependentField: {
        field: "selectedPackage",
        value: "Fotos",
      },
      type: "string",
    },
    duration: {
      label: "Dauer",
      dependentField: {
        field: "selectedPackage",
        value: "Videos",
      },
      type: "string",
    },
    optionalWishPilot: {
      label: "Kommentar für den Piloten",
      type: "string",
      isComment: true,
    },
    additionalPanorama: {
      label: "360° Drohnenpanorama",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: "Fotos",
      },
    },
    photoFormat: {
      label: "Fotoformat",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: "Fotos",
      },
    },
    blueSky: {
      label: "Blauer Himmel",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: "Fotos",
      },
    },
    blurred: {
      label: "Weichzeichnen",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["Videos", "Fotos"],
      },
    },
    gras: {
      label: "Rasen verschönern",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: "Fotos",
      },
    },
    logoAtBeginning: {
      label: "Logo als Intro",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["Videos"],
      },
    },
    logoAtEnd: {
      label: "Logo als Outro",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["Videos"],
      },
    },
    logoAllTheTime: {
      label: "Logo im gesamten Video",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["Videos"],
      },
    },
    introOrOutro: {
      label: "Intro / Outro",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["Videos"],
      },
    },
    backgroundMusic: {
      label: "Hintergrundmusik",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["Videos"],
      },
    },
    selectedBackgroundMusic: {
      label: "Ausgewählter Titel",
      type: "audioItem",
      dependentField: {
        field: "selectedPackage",
        value: ["Videos"],
      },
    },
    preferredPhotographerName: {
      label: "Wunschfotograf",
      type: "string",
      dependentField: {
        field: "preferredPhotographerRequested",
        value: [true],
      },
    },
    preferredPhotographerAltName: {
      label: "Alternativer Wunschfotograf",
      type: "string",
      dependentField: {
        field: "preferredPhotographerRequested",
        value: [true],
      },
    },
    logoImage: {
      label: "Logo",
      type: "image",
      dependentField: {
        field: "logo",
        value: "true",
      },
    },
    primaryTitle: {
      label: "Haupttitel",
      type: "string",
      isComment: true,
      dependentField: {
        field: "selectedPackage",
        value: ["Videos"],
      },
    },
    secondaryTitle: {
      label: "Nebentitel",
      type: "string",
      isComment: true,
      dependentField: {
        field: "selectedPackage",
        value: ["Videos"],
      },
    },
  },
  [OrderTypeEnum.Expose]: {
    description: {
      label: "Kommentare",
      type: "string",
      isComment: true,
    },
  },
  [OrderTypeEnum.Floor_Plan]: {
    packageName: {
      label: "Produkt",
      type: "string",
    },
    styleName: {
      label: "Style",
      type: "string",
    },
    isometric: {
      label: "Zusätzliche Isometrische Ansicht",
      type: "boolean",
      dependentField: {
        field: "dimension",
        value: "3d",
      },
    },
    propertyType: {
      label: "Art der Immobilie",
      type: "string",
    },
    drawingType: {
      label: "Art der Zeichnung",
      type: "string",
      dependentField: {
        field: "propertyType",
        value: "Wohnen",
      },
    },
    preRender: {
      label: "PreRender",
      type: "boolean",
      dependentField: {
        field: "withPreRender",
        value: true,
      },
    },
    mobliert: {
      label: "Möbliert",
      type: "boolean",
    },
    bemasung: {
      label: "Maßketten",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["Klassisch", "Sparkasse", "LBS", "FALC", "FALC Variante 2", "Premium"],
      },
    },
    nordpfeil: {
      label: "Nordpfeil",
      type: "boolean",
    },
    trueToScale: {
      label: "Maßstabsgetreu auf A4",
      type: "boolean",
    },
    trueToScaleRadio: {
      label: "Maßstab",
      type: "string",
    },
    quadratMeter: {
      label: "Quadratmeterangaben",
      type: "string",
    },
    waitForAreaCalculation: {
      label: "Auf das Ergebnis der Wohnflächenberechnung warten",
      type: "boolean",
    },
    raumbezeichnung: {
      label: "Raumbezeichnung",
      type: "boolean",
    },
    apartmentName: {
      label: "Wohnungsbezeichnung",
      type: "boolean",
    },
    floorplanName: {
      label: "Grundrissbezeichnung",
      type: "boolean",
    },
    alignNorth: {
      label: "Nach Norden ausrichten",
      type: "boolean",
    },
    modification: {
      label: "Modifikation",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["Premium", "Extravagant"],
      },
    },
    meterBars: {
      label: "Meterbalken",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: [
          "Klassisch",
          "Sparkasse",
          "LBS",
          "FALC",
          "FALC Variante 2",
          "BPD",
          "RE/MAX",
          "Dahler",
          "SIS",
          "PlanetHome",
          "Profimeo",
        ],
      },
    },
    additionalFloor: {
      label: "Anzahl der Wohnungen",
      type: "string",
    },
    logoImage: {
      label: "Logo",
      type: "image",
      dependentField: {
        field: "logo",
        value: "true",
      },
    },
    comments: {
      label: "Kommentare/ Anmerkungen",
      type: "string",
      isComment: true,
    },
  },
  [OrderTypeEnum.Hd_Photos]: {
    packageName: {
      label: "Produkt",
      type: "string",
    },
    numberOfPhotos: {
      label: "Anzahl der Fotos",
      type: "string",
    },
    photoFormat: {
      label: "Fotoformat",
      type: "string",
    },
    gras: {
      label: "Rasen verschönern",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: [CONSTANTS.PACKAGE_NAMES[OrderTypeEnum.Hd_Photos].HD_PHOTOS_EDITORIAL],
      },
    },
    threeObjectsOption: {
      label: "Außenfotos von drei Objekten",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: "Immobilienfotos",
      },
    },
    preferredPhotographerName: {
      label: "Wunschfotograf",
      type: "string",
      dependentField: {
        field: "preferredPhotographerRequested",
        value: [true],
      },
    },
    preferredPhotographerAltName: {
      label: "Alternativer Wunschfotograf",
      type: "string",
      dependentField: {
        field: "preferredPhotographerRequested",
        value: [true],
      },
    },
    photographerComments: {
      label: "Kommentare/ Anmerkungen für Fotografen",
      type: "string",
      isComment: true,
    },
  },
  [OrderTypeEnum.Price_Hubble]: {
    title: {
      label: "Titel",
      type: "string",
    },
    description: {
      label: "Beschreibung",
      type: "string",
    },
    dealType: {
      label: "Verkauf / Vermietung",
      type: "string",
    },
    propertyType: {
      label: "Objektart",
      type: "string",
    },
    propertySubtype: {
      label: "Objekt Subtyp",
      type: "string",
    },
    buildingYear: {
      label: "Baujahr",
      type: "string",
    },
    livingArea: {
      label: "Wohnfläche",
      type: "string",
    },
    landArea: {
      label: "Grundstücksfläche",
      type: "string",
    },
    numberOfRooms: {
      label: "Anzahl Räume",
      type: "string",
    },
    numberOfBathrooms: {
      label: "Anzahl Badezimmer",
      type: "string",
    },
    balconyArea: {
      label: "Balkon-/ Terrassenfläche",
      type: "string",
    },
    isNew: {
      label: "Erstbezug",
      type: "boolean",
    },
    renovationYear: {
      label: "Zuletzt renoviert in",
      type: "string",
    },
    numberOfIndoorParkingSpaces: {
      label: "Garagenstellplätze",
      type: "string",
    },
    numberOfOutdoorParkingSpaces: {
      label: "Parkplätze",
      type: "string",
    },
    floorNumber: {
      label: "Etage",
      type: "string",
      dependentField: {
        field: "propertyType",
        value: ["Apartment"],
      },
    },
    hasLift: {
      label: "Fahrstuhl",
      type: "boolean",
      dependentField: {
        field: "propertyType",
        value: ["Apartment"],
      },
    },
    hasSauna: {
      label: "Sauna",
      type: "boolean",
      dependentField: {
        field: "propertyType",
        value: ["House"],
      },
    },
    hasPool: {
      label: "Pool",
      type: "boolean",
      dependentField: {
        field: "propertyType",
        value: ["House"],
      },
    },
    conditionBathrooms: {
      label: "Zustand Badezimmer",
      type: "string",
      dependentField: {
        field: "propertyType",
        value: ["Apartment", "House"],
      },
    },
    conditionKitchen: {
      label: "Zustand Küche",
      type: "string",
      dependentField: {
        field: "propertyType",
        value: ["Apartment", "House"],
      },
    },
    conditionFlooring: {
      label: "Zustand Böden",
      type: "string",
      dependentField: {
        field: "propertyType",
        value: ["Apartment", "House"],
      },
    },
    conditionWindows: {
      label: "Zustand Fenster",
      type: "string",
      dependentField: {
        field: "propertyType",
        value: ["Apartment", "House"],
      },
    },
    conditionMasonry: {
      label: "Zustand Mauerwerk",
      type: "string",
      dependentField: {
        field: "propertyType",
        value: ["Apartment", "House"],
      },
    },
    qualityBathrooms: {
      label: "Qualität Badezimmer",
      type: "string",
      dependentField: {
        field: "propertyType",
        value: ["Apartment", "House"],
      },
    },
    qualityKitchen: {
      label: "Qualität Küche",
      type: "string",
      dependentField: {
        field: "propertyType",
        value: ["Apartment", "House"],
      },
    },
    qualityFlooring: {
      label: "Qualität Böden",
      type: "string",
      dependentField: {
        field: "propertyType",
        value: ["Apartment", "House"],
      },
    },
    qualityWindows: {
      label: "Qualität Fenster",
      type: "string",
      dependentField: {
        field: "propertyType",
        value: ["Apartment", "House"],
      },
    },
    qualityMasonry: {
      label: "Qualität Mauerwerk",
      type: "string",
      dependentField: {
        field: "propertyType",
        value: ["Apartment", "House"],
      },
    },
    numberOfUnits: {
      label: "Anzahl Wohneinheiten",
      type: "string",
      dependentField: {
        field: "propertyType",
        value: ["multi_family_house"],
      },
    },
    annualRentIncome: {
      label: "Jährliche Mieteinnahmen",
      type: "string",
      dependentField: {
        field: "propertyType",
        value: ["multi_family_house"],
      },
    },
    logoImage: {
      label: "Logo",
      type: "image",
      dependentField: {
        field: "logo",
        value: "true",
      },
    },
    comments: {
      label: "Kommentare",
      type: "string",
      isComment: true,
    },
  },
  [OrderTypeEnum.Retouching]: {
    packageName: {
      label: "Produkt",
      type: "string",
    },
    numberOfPhotos: {
      label: "Anzahl",
      type: "string",
      dependentField: {
        field: "packageNum",
        value: [
          CONSTANTS.PACKAGE_KEYS.DRONEMEDIA_FOTOS,
          CONSTANTS.PACKAGE_KEYS.HD_PHOTOS_REALESTATE,
          CONSTANTS.PACKAGE_KEYS.HD_PHOTOS_EDITORIAL,
          CONSTANTS.PACKAGE_KEYS.RETOUCHING_BASIC,
          CONSTANTS.PACKAGE_KEYS.RETOUCHING_STANDARD,
          CONSTANTS.PACKAGE_KEYS.RETOUCHING_EXTENDED,
        ],
      },
    },
    duration: {
      label: "Dauer",
      dependentField: {
        field: "packageName",
        value: CONSTANTS.PACKAGE_NAMES[OrderTypeEnum.Drone_Media].DRONE_VIDEO_RETOUCHING,
      },
      type: "string",
    },
    title: {
      label: "Titel",
      type: "string",
      dependentField: {
        field: "packageName",
        value: CONSTANTS.PACKAGE_NAMES[OrderTypeEnum.Virtual_Tour].IMMOVIEWER_RETOUCHING,
      },
    },
    blueSky: {
      label: "Blauer Himmel",
      type: "boolean",
    },
    blurred: {
      label: "Weichzeichnen",
      type: "boolean",
    },
    gras: {
      label: "Rasen verschönern",
      type: "boolean",
      dependentField: {
        field: "packageNum",
        value: [
          CONSTANTS.PACKAGE_KEYS.DRONEMEDIA_FOTOS,
          CONSTANTS.PACKAGE_KEYS.HD_PHOTOS_REALESTATE,
          CONSTANTS.PACKAGE_KEYS.HD_PHOTOS_EDITORIAL,
          CONSTANTS.PACKAGE_KEYS.RETOUCHING_BASIC,
          CONSTANTS.PACKAGE_KEYS.RETOUCHING_STANDARD,
          CONSTANTS.PACKAGE_KEYS.RETOUCHING_EXTENDED,
        ],
      },
    },
    photoFormatText: {
      label: "Fotoformat",
      type: "string",
      dependentField: {
        field: "packageNum",
        value: [
          CONSTANTS.PACKAGE_KEYS.RETOUCHING_BASIC,
          CONSTANTS.PACKAGE_KEYS.RETOUCHING_STANDARD,
          CONSTANTS.PACKAGE_KEYS.RETOUCHING_EXTENDED,
        ],
      },
    },
    photoFormat: {
      label: "Fotoformat",
      type: "string",
      dependentField: {
        field: "packageNum",
        value: [
          CONSTANTS.PACKAGE_KEYS.DRONEMEDIA_FOTOS,
          CONSTANTS.PACKAGE_KEYS.HD_PHOTOS_REALESTATE,
          CONSTANTS.PACKAGE_KEYS.HD_PHOTOS_EDITORIAL,
        ],
      },
    },
    logoImage: {
      label: "Logo",
      type: "image",
      dependentField: {
        field: "logo",
        value: "true",
      },
    },
    retouchingComment: {
      label: "Kommentar für die Nachbearbeitung",
      type: "string",
      isComment: true,
    },
  },
  [OrderTypeEnum.V_Staging]: {
    packageName: {
      label: "Produkt",
      type: "string",
    },
    quality: {
      label: "Qualität",
      type: "string",
    },
    styleName: {
      label: "Style",
      type: "string",
    },
    accessoires: {
      label: "Accessoires",
      type: "string",
    },
    numberOfPhotos: {
      label: "Anzahl",
      type: "string",
    },
    removeFurniture: {
      label: "Möbel entfernen",
      type: "boolean",
    },
    renovateRooms: {
      label: "Renovierung",
      type: "boolean",
    },
    numberOfWalls: {
      label: "Wände versetzen",
      type: "booleanString",
    },
    virtualTour: {
      label: "Virtuelle Tour",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["360° Bilder", "Wohnen - 360° Bilder"],
      },
    },
    indvVirtualTour: {
      label: "Virtuelle Tour (Einzelspots)",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["360° Bilder", "Wohnen - 360° Bilder"],
      },
    },
    contactName: {
      label: "Ansprechpartner",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["360° Office Staging", "Office - Immoviewer 360°"],
      },
    },
    contactPhone: {
      label: "Ansprechpartner (Tel.)",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["360° Office Staging", "Office - Immoviewer 360°"],
      },
    },
    immoviewerTourId: {
      label: "Rundgang ID",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["360° Office Staging", "Office - Immoviewer 360°"],
      },
    },
    logoImage: {
      label: "Logo",
      type: "image",
      dependentField: {
        field: "logo",
        value: "true",
      },
    },
    description: {
      label: "Kommentare",
      type: "string",
      isComment: true,
    },
  },
  [OrderTypeEnum.Visualisation]: {
    packageName: {
      label: "Produkt",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["Innenvisualisierung", "Außenvisualisierung"],
      },
    },
    propertyType: {
      label: "Art der Immobilie",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["360°-Rundgang"],
      },
    },
    outputFormat: {
      label: "Ausgabeformat",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["Innenvisualisierung", "Außenvisualisierung"],
      },
    },
    quality: {
      label: "Qualitätsstufe",
      type: "string",
    },
    numRooms: {
      label: "Anzahl der Zimmer",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["360°-Rundgang"],
      },
    },
    length: {
      label: "Videolänge",
      type: "string",
      dependentField: {
        field: "outputFormat",
        value: "Rundflug",
      },
    },
    numPhotos: {
      label: "Anzahl der Bilder/ Räume",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["Innenvisualisierung", "Außenvisualisierung"],
      },
    },
    numAdditionalPhotos: {
      label: "Anzahl zusätzlicher Standbilder",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["Innenvisualisierung", "Außenvisualisierung"],
      },
    },
    numAdditional360Tour: {
      label: "Anzahl zusätzlicher 360 Grad Touren",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["Innenvisualisierung", "Außenvisualisierung"],
      },
    },
    apartments: {
      label: "Anzahl der Wohneinheiten",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["Außenvisualisierung"],
      },
    },
    buildings: {
      label: "Anzahl der Gebäude",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["Außenvisualisierung"],
      },
    },
    typeRoom: {
      label: "Nutzungstyp",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["360°-Rundgang"],
      },
    },
    individualDesign: {
      label: "Modifizierbarkeit",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["360°-Rundgang"],
      },
    },
    style: {
      label: "Stil",
      type: "string",
      dependentField: {
        field: "individualDesign",
        value: "Standard",
      },
    },
    description: {
      label: "Kommentare",
      type: "string",
      isComment: true,
    },
  },
  [OrderTypeEnum.Floor_Overview]: {
    packageName: {
      label: "Produkt",
      type: "string",
    },
    buildingQuantity: {
      label: "Anzahl der Gebäudekörper",
      type: "string",
    },
    flatQuantity: {
      label: "Anzahl der Wohnungen",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: "Wohnungsfinder",
      },
    },
    rundumBlick: {
      label: "360°-Rundum Blick",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: "Wohnungsfinder",
      },
    },
    flatFinderTool: {
      label: "Einbinden in Flatfinder",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: "Wohnungsfinder",
      },
    },
    flatColor: {
      label: "Farbe des markierten Apartments",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: "Wohnungsfinder",
      },
    },
    buildingColor: {
      label: "Farbe des Gebäudekörpers",
      type: "string",
    },
    backgroundColor: {
      label: "Farbe des Untergrundes",
      type: "string",
    },
    apartmentNumber: {
      label: "Wohnungsnummer",
      type: "boolean",
    },
    streetReference: {
      label: "Straßenreferenz",
      type: "boolean",
    },
    northArrow: {
      label: "Nordpfeil",
      type: "boolean",
    },
    logo: {
      label: "Auftraggeberlogo",
      type: "boolean",
    },
    logoImage: {
      label: "Logo",
      type: "image",
      dependentField: {
        field: "logo",
        value: "true",
      },
    },
    comments: {
      label: "Kommentare/ Anmerkungen",
      type: "string",
      isComment: true,
    },
  },
  [OrderTypeEnum.Virtual_Tour]: {
    packageName: {
      label: "Produkt",
      type: "string",
    },
    immoviewerItemId: {
      label: "Immoviewer Item ID",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["Immoviewer DIY Tour", "Immoviewer"],
      },
    },
    hasDronePanorama: {
      label: "360° Drohnenpanorama",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["Immoviewer"],
      },
    },
    buildingUID: {
      label: "Gebäude UID",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["Immoviewer DIY Tour"],
      },
    },
    objectUID: {
      label: "Objekt UID",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["Immoviewer DIY Tour"],
      },
    },
    squareMeterArea: {
      label: "Fläche in qm",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["Matterport"],
      },
    },
    packagePriceOptionName: {
      label: "Fotos",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["Immoviewer"],
      },
    },
    title: {
      label: "Titel",
      type: "string",
    },
    startingPointName: {
      label: "Startpunkt",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["Matterport"],
      },
    },
    room: {
      label: "Raum",
      type: "string",
      dependentField: {
        field: "startingPoint",
        value: ["Other"],
      },
    },
    outsideSpotsNames: {
      label: "Außenspots",
      type: "list",
      dependentField: {
        field: "selectedPackage",
        value: ["Matterport"],
      },
    },
    blurOptionsNames: {
      label: "Weichzeichnen",
      type: "list",
      dependentField: {
        field: "selectedPackage",
        value: ["Immoviewer"],
      },
    },
    additionalUnitsSpots: {
      label: "Zusätzliche Spots",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["360° Rundgang Spiegelreflex-Qualität"],
      },
    },
    preferredPhotographerName: {
      label: "Wunschfotograf",
      type: "string",
      dependentField: {
        field: "preferredPhotographerRequested",
        value: [true],
      },
    },
    preferredPhotographerAltName: {
      label: "Alternativer Wunschfotograf",
      type: "string",
      dependentField: {
        field: "preferredPhotographerRequested",
        value: [true],
      },
    },
    comments: {
      label: "Kommentare/ Anmerkungen",
      type: "string",
      isComment: true,
    },
  },
  [OrderTypeEnum.Energy_Pass]: {
    packageName: {
      label: "Produkt",
      type: "string",
    },
    comments: {
      label: "Kommentar",
      type: "string",
      isComment: true,
    },
  },
  [OrderTypeEnum.Documents_Procurement]: {
    packageName: {
      label: "Produkt",
      type: "string",
    },
    landRegisterUnknown: {
      label: "Grundstücksdatenrecherche",
      type: "boolean",
    },
    comments: {
      label: "Kommentare",
      type: "string",
      isComment: true,
    },
  },
  [OrderTypeEnum.Video_Tour]: {
    duration: {
      label: "Dauer",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["Imagefilm Fotograf", "Imagefilm"],
      },
    },
    numberOfRooms: {
      label: "Max. Anzahl Räume",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: ["Objektbegehung", "Objektbegehung Fotograf"],
      },
    },
    droneFootage: {
      label: "Drohnenaufnahmen",
      type: "boolean",
    },
    logoAtBeginning: {
      label: "Logo als Intro",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["Objektbegehung", "Imagefilm", "DIY Objektbegehung"],
      },
    },
    logoAtEnd: {
      label: "Logo als Outro",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["Objektbegehung", "Imagefilm", "DIY Objektbegehung"],
      },
    },
    logoAllTheTime: {
      label: "Logo im gesamten Video",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["Objektbegehung", "Imagefilm", "DIY Objektbegehung"],
      },
    },
    introOrOutro: {
      label: "Intro / Outro",
      type: "boolean",
    },
    blurred: {
      label: "Umgebung weichzeichnen",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["Objektbegehung", "Imagefilm", "DIY Objektbegehung"],
      },
    },
    backgroundMusic: {
      label: "Hintergrundmusik",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["Objektbegehung", "Imagefilm", "DIY Objektbegehung"],
      },
    },
    selectedBackgroundMusic: {
      label: "Ausgewählter Titel",
      type: "audioItem",
      dependentField: {
        field: "selectedPackage",
        value: ["Objektbegehung", "Imagefilm", "DIY Objektbegehung"],
      },
    },
    retouchingComment: {
      label: "Kommentare/ Anmerkungen",
      type: "string",
      isComment: true,
      dependentField: {
        field: "selectedPackage",
        value: ["Objektbegehung", "Imagefilm", "DIY Objektbegehung"],
      },
    },
    photographerComments: {
      label: "Kommentare/ Anmerkungen",
      type: "string",
      isComment: true,
      dependentField: {
        field: "selectedPackage",
        value: ["Objektbegehung Fotograf", "Imagefilm Fotograf"],
      },
    },
    primaryTitle: {
      label: "Haupttitel",
      type: "string",
      isComment: true,
      dependentField: {
        field: "selectedPackage",
        value: ["Objektbegehung", "DIY Objektbegehung", "Imagefilm"],
      },
    },
    secondaryTitle: {
      label: "Nebentitel",
      type: "string",
      isComment: true,
      dependentField: {
        field: "selectedPackage",
        value: ["Objektbegehung", "DIY Objektbegehung", "Imagefilm"],
      },
    },
    logoImage: {
      label: "Logo",
      type: "image",
      dependentField: {
        field: "logo",
        value: "true",
      },
    },
    hasVideoLink: {
      label: "Link zum Video erhalten",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: ["Objektbegehung", "DIY Objektbegehung", "Imagefilm"],
      },
    },
  },
  //TODO :: need to handle this for Measurements package as well. Because of conflicting service keys and properties.
  [OrderTypeEnum.Area_Calculation]: {
    //For Area calculation
    packageName: {
      label: "Produkt",
      type: "string",
    },
    quantity: {
      label: "Etagen",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: [CONSTANTS.AREA_CALCULATION_FLOORPLAN_PACKAGE],
      },
    },
    propertyType: {
      label: "Objektart",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: [CONSTANTS.AREA_CALCULATION_MEASUREMENT_PACKAGE, CONSTANTS.AREA_CALCULATION_FLOORPLAN_PACKAGE],
      },
    },
    withCertificate: {
      label: "Architektenstempel",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: [CONSTANTS.AREA_CALCULATION_FLOORPLAN_PACKAGE],
      },
    },
    areaCalculationTypeValue: {
      label: "Flächenberechnung",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: [CONSTANTS.AREA_CALCULATION_FLOORPLAN_PACKAGE],
      },
    },
    //For Measurements
    number_of_rooms: {
      label: "Anzahl Räume (inkl. Flur, Bad, WC, etc.)",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: [CONSTANTS.AREA_CALCULATION_MEASUREMENT_PACKAGE],
      },
    },
    total_size_to_be_measured: {
      label: "Zu vermessende Gesamtgröße (m²)",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: [CONSTANTS.AREA_CALCULATION_MEASUREMENT_PACKAGE],
      },
    },
    number_of_floors_to_be_measured: {
      label: "Anzahl der zu vermessenden Geschosse (ohne Keller und Dachboden)",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: [CONSTANTS.AREA_CALCULATION_MEASUREMENT_PACKAGE],
      },
    },
    number_of_residential_units_to_be_measured: {
      label: "Anazhl der zu vermessenden Einheit",
      type: "string",
      dependentField: {
        field: "selectedPackage",
        value: [CONSTANTS.AREA_CALCULATION_MEASUREMENT_PACKAGE],
      },
    },
    basement: {
      label: "Keller",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: [CONSTANTS.AREA_CALCULATION_MEASUREMENT_PACKAGE],
      },
    },
    attic: {
      label: "Dachgeschoss",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: [CONSTANTS.AREA_CALCULATION_MEASUREMENT_PACKAGE],
      },
    },
    documentsForTheBank: {
      label: "Unterlagen für die Bank (Finanzierungsprojekt)",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: [CONSTANTS.AREA_CALCULATION_MEASUREMENT_PACKAGE],
      },
    },
    rentDispute: {
      label: "Mietstreitigkeit",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: [CONSTANTS.AREA_CALCULATION_MEASUREMENT_PACKAGE],
      },
    },
    purchaseOrSale: {
      label: "Kauf / Verkauf",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: [CONSTANTS.AREA_CALCULATION_MEASUREMENT_PACKAGE],
      },
    },
    propertyTaxReturn: {
      label: "Grundsteuererklärung",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: [CONSTANTS.AREA_CALCULATION_MEASUREMENT_PACKAGE],
      },
    },
    otherReason: {
      label: "Sonstiges",
      type: "boolean",
      dependentField: {
        field: "selectedPackage",
        value: [CONSTANTS.AREA_CALCULATION_MEASUREMENT_PACKAGE],
      },
    },
    comments: {
      label: "Kommentar",
      type: "string",
      isComment: true,
    },
  },
};
