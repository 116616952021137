import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AccountingPosition, SpecialCondition } from "@app/interfaces/accounting-position.interface";
import { Company } from "@app/interfaces/company.interface";
import { AccountingPositionsLogicService } from "@app/_services/accounting-positions-logic.service";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { CompanySearchModalComponent } from "../company-search-modal/company-search-modal.component";
import { PriceDeleteModalComponent } from "../price-delete-modal/price-delete-modal.component";

@Component({
  selector: "app-price-detail-modal",
  templateUrl: "./price-detail-modal.component.html",
  styleUrls: ["./price-detail-modal.component.css"],
})
export class PriceDetailModalComponent implements OnInit {
  dialogData: any = {};
  companyList: Company[] = [];
  deletedCompaniesSpecialConditions: SpecialCondition[] = [];
  searchLabelText = "";
  priceReadOnly: true;
  tempAccountingPosition: AccountingPosition;
  hasSelectedRows = false;
  public companyCtrl: FormControl = new FormControl();
  public selectAll: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompany: ReplaySubject<SpecialCondition[]> = new ReplaySubject<SpecialCondition[]>(1);
  protected _onDestroy = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public apls: AccountingPositionsLogicService,
    private dialogRef: MatDialogRef<PriceDetailModalComponent>
  ) {
    this.dialogData = data;
  }

  ngOnInit(): void {
    this.companyList = this.dialogData.companiesList;
    this.tempAccountingPosition = this.dialogData.tempAccountingPosition;
    if (this.tempAccountingPosition.specialConditions?.length) {
      this.searchLabelText = "weitere hinzufügen";
      this.tempAccountingPosition.specialConditions.forEach((x) => {
        const companyObj = this.companyList.find((y) => y.cid === x.companyId);
        x.companyName = companyObj
          ? companyObj.name + (companyObj.supplement ? " - " + companyObj.supplement : "")
          : "";
        x.isSelected = false;
      });
    } else {
      this.searchLabelText = "Spezialpreise hinzufugen";
    }
    this.resetCompanyFilter();
    this.companyFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCompanyMulti();
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected filterCompanyMulti() {
    if (!this.companyList) {
      return;
    }
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filteredCompany.next(this.tempAccountingPosition.specialConditions);
    } else {
      search = search.toLowerCase();
      this.filteredCompany.next(
        this.tempAccountingPosition.specialConditions.filter(
          (company) => company.companyName.toLowerCase().indexOf(search) > -1
        )
      );
    }
  }

  protected resetCompanyFilter() {
    this.filteredCompany.next(
      this.tempAccountingPosition.specialConditions?.length ? this.tempAccountingPosition.specialConditions : []
    );
  }

  protected setSelectedOption() {
    let selectedRows = 0;
    this.tempAccountingPosition.specialConditions.forEach((element) => {
      if (element.isSelected) {
        selectedRows++;
      }
    });
    if (selectedRows > 1) {
      this.hasSelectedRows = true;
    } else {
      this.hasSelectedRows = false;
      this.selectAll.setValue(false);
    }
    if (selectedRows == this.tempAccountingPosition.specialConditions.length) {
      this.selectAll.setValue(true);
    } else {
      this.selectAll.setValue(false);
    }
  }

  closeEditModal() {
    this.dialogRef.close({
      cancel: true,
    });
  }

  saveEditModal() {
    if (this.deletedCompaniesSpecialConditions.length > 0) {
      this.deletePriceDialog(-1, true);
    } else {
      this.closeSaveDialog();
    }
  }

  closeSaveDialog() {
    this.dialogRef.close({
      cancel: false,
      tempAccountingPosition: this.tempAccountingPosition,
    });
  }

  selectAllRows($event: any) {
    this.hasSelectedRows = $event.checked;
    this.tempAccountingPosition.specialConditions.forEach((x) => {
      x.isSelected = $event.checked;
    });
    this.resetCompanyFilter();
  }

  deletePriceDialog(index, beforeSave = false) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "120vh";
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    if (beforeSave) {
      dialogConfig.data.deletedCompaniesSpecialConditions = this.deletedCompaniesSpecialConditions;
      dialogConfig.data.beforeSave = true;
    }
    const dialogRef = this.dialog.open(PriceDeleteModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((val) => {
      if (val.delete && val.saveOnlyPrice) {
        this.deletedCompaniesSpecialConditions.forEach((x) => {
          x.isSelected = false;
          this.tempAccountingPosition.specialConditions.push(x);
        });
        this.apls.updateAccountingPositions([this.tempAccountingPosition]);
        this.resetCompanyFilter();
        this.closeSaveDialog();
      } else if (val.delete) {
        if (index < 0) {
          this.tempAccountingPosition.specialConditions = this.tempAccountingPosition.specialConditions.filter(
            (x) => !x.isSelected
          );
        } else {
          this.tempAccountingPosition.specialConditions.splice(index, 1);
        }
        this.apls.updateAccountingPositions([this.tempAccountingPosition]);
        this.resetCompanyFilter();
        this.closeSaveDialog();
      } else {
        this.deletedCompaniesSpecialConditions.forEach((x) => {
          x.isSelected = false;
          this.tempAccountingPosition.specialConditions.push(x);
        });
      }
      this.deletedCompaniesSpecialConditions = [];
    });
  }

  deleteAllRows() {
    this.deletePriceDialog(-1, false);
  }

  selectSingleRow($event, companyId) {
    this.tempAccountingPosition.specialConditions.find((x) => x.companyId === companyId).isSelected = $event.checked;
    this.setSelectedOption();
  }

  deleteSingleRow(i: number) {
    this.deletedCompaniesSpecialConditions.push(this.tempAccountingPosition.specialConditions[i]);
    this.tempAccountingPosition.specialConditions.splice(i, 1);
  }

  openCompanySearchModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "120vh";
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      companiesList: this.companyList,
      defaultPrice: this.tempAccountingPosition.price,
      selectedCompaniesSpecialConditions: this.tempAccountingPosition.specialConditions,
    };
    const dialogRef = this.dialog.open(CompanySearchModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((val) => {
      if (val && val.data && val.cancel === false) {
        this.tempAccountingPosition.specialConditions = val.data;
        this.tempAccountingPosition.specialConditions.forEach((element) => {
          element.isSelected = false;
        });
        this.resetCompanyFilter();
        this.setSelectedOption();
      }
    });
  }
}
