import { EventEmitter, Injectable } from "@angular/core";
import { UsersService } from "@app/_services/users.service";
import { Resolve } from "@angular/router";
import { User } from "@app/interfaces/user.interface";
import { map } from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { ContactPerson } from "@app/interfaces/suborder.interface";
import { RealestateLogicService } from "@app/_services/realestate-logic.service";
import { of } from "rxjs";
import { UserRoleEnum } from "@app/models/user-role-list";

@Injectable({
  providedIn: "root",
})
export class UsersLogicService implements Resolve<User[]> {
  allUsers = [];
  allServiceProviders = [];
  allCustomers: User[] = [];
  onUsersRefresh: EventEmitter<boolean> = new EventEmitter();
  tempUser: User = null;

  constructor(private us: UsersService, private rels: RealestateLogicService) {}

  resolve() {
    return this.getAllUsers();
  }

  getAllUsers() {
    return this.us.getUsers().pipe(
      map((usersList: QueryResult<User>) => {
        usersList.data.sort((a, b) => a.firstName?.localeCompare(b.firstName));
        this.allUsers = usersList.data;
        this.onUsersRefresh.emit();
        return usersList.data;
      })
    );
  }

  getAllUsersWithPaginator(
    pageIndex: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    queryMap?: Map<string, string>
  ) {
    const from = pageIndex * pageSize;
    const to = (pageIndex + 1) * pageSize;

    return this.us.getUsersWithPaginator(from, to, sortBy, sortDirection, queryMap);
  }

  getAllServiceProviders() {
    return this.us.getUsersByRole("service-providers").pipe(
      map((usersList: User[]) => {
        usersList.sort((a, b) => a.firstName?.localeCompare(b.firstName));
        this.allServiceProviders = usersList;
        this.onUsersRefresh.emit();
        return usersList;
      })
    );
  }

  getAllCustomers() {
    return this.us.getUsersByRole("customers").pipe(
      map((usersList: User[]) => {
        const filteredUsersList = usersList.filter((item) => !!item.name);
        filteredUsersList.sort((a, b) => a.name.localeCompare(b.name));
        this.allCustomers = filteredUsersList;
        return usersList;
      })
    );
  }

  /**
   * Get user details by uid
   * @param uid the users uid
   */
  getUserDetails(uid: string): Observable<User> {
    const userToFetch = this.allUsers.find((user) => user.uid === uid);
    if (userToFetch) return of(userToFetch);
    return this.us.getUserByProperty("uid", uid).pipe(
      map((usersList) => {
        this.allUsers.push(usersList[0]);
        return usersList[0];
      })
    );
  }

  /**
   * Get user details by email address
   * @param mail The users mail address
   */
  getUserRefByEMail(mail: string) {
    return this.us.getUserByProperty("email", mail).pipe(
      map((usersList) => {
        this.tempUser = usersList[0];

        return this.tempUser;
      })
    );
  }

  /**
   * Update user properties
   * @param uid user uid
   * @param data new data
   */
  updateUser(uid: string, data: Partial<User>) {
    return this.us
      .patchUser(uid, data)
      .pipe(
        map((userData: any) => {
          const userIndex = this.allUsers.findIndex((user) => user.uid === userData.uid);
          if (userIndex >= 0) {
            this.allUsers[userIndex] = userData;
            this.onUsersRefresh.emit();
          }
          return userData;
        })
      )
      .toPromise();
  }

  /**
   * Changes the company of the user and updates the companyId field of all related companies.
   * @param userId user id of the user whose company will be changed
   * @param companyId The (new) company of the user with the given uid.
   */
  changeCompany(userId: string, companyId: string) {
    return new Promise((resolve1, reject) => {
      let newValues = {};
      if (companyId) {
        newValues = {
          id: companyId,
        };
      } else {
        newValues = "";
      }
      this.us
        .patchUser(userId, {
          company: newValues,
        })
        .toPromise()
        .then((userData) => {
          this.rels
            .updateRealEstatesOfUser(userId)
            .then((result) => {
              resolve1(userData);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  /**
   * Returns Observable with all users of the given company
   * @param companyId id of the company
   */
  getAllUsersOfCompany(companyId): Observable<User[]> {
    return this.us.getUserByProperty("company", companyId);
  }

  /**
   * Gets contact person of the given orderType
   * @param orderType The order type.
   */
  getOrderTypeResponsible(orderType: OrderTypeEnum): Observable<ContactPerson> {
    return this.us.getOrderTypeResponsible(orderType);
  }
}
