<div class="shadow card cgrid">
  <div class="card-header">
    <a class="my-link" (click)="active = !active" [attr.aria-expanded]="active" aria-controls="collapse1">
      <h5 class="card-title text-left wsn">Information</h5>
    </a>
  </div>
  <div>
    <div class="card-body">
      <ng-container *ngIf="auth.myUserObservable as user">
        <div *ngIf="user.role === 'Administrator'">
          <mat-accordion [multi]="true">
            <mat-expansion-panel>
              <mat-expansion-panel-header> Emailbestätigungen </mat-expansion-panel-header>
              <div class="col-12">
                <form [formGroup]="settingsForm">
                  <div class="row">
                    <div class="col-1 position">
                      <mat-checkbox formControlName="sendConfirmationEmail" color="primary" (change)="submitHandler()">
                      </mat-checkbox>
                    </div>
                    <div class="col-6">Emailbestätigungen</div>
                  </div>
                </form>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header> Funktionen </mat-expansion-panel-header>
              <div class="row">
                <div class="col-12">
                  <button class="btn btn-primary" (click)="apls.updateNewAccountingPositions()">
                    Update new Accounting Positions
                  </button>
                  <button class="btn btn-danger" (click)="conf.createAssignServiceProviderConfiguration()">
                    overwrite assignServiceProvider configuration
                  </button>
                  <button class="btn btn-primary" (click)="sos.addPaymentsValue()">
                    Add payments value to suborders
                  </button>
                  <button class="btn btn-danger" (click)="sos.markAsPaid()">mark all suborders as paid</button>
                  <!-- configuration live platform: YCMIpYuBymXrK8ZVdDipp4D0fkj2 for documents_procurement, floorplan, retouching, vstaging -->
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <button class="btn btn-primary" (click)="mcls.getAllBackend()">Get All Orders McGrundriss</button>

                  <button class="btn btn-primary" (click)="mcls.getProductsBackend()">Get Products McGrundriss</button>
                </div>
              </div>

              <br />

              <div class="row">
                <div class="col-2">
                  <mat-form-field>
                    <input matInput placeholder="suborderId" [(ngModel)]="mcgrundriss.suborderId" />
                  </mat-form-field>
                </div>

                <div class="col-2">
                  <mat-form-field>
                    <input
                      type="number"
                      matInput
                      placeholder="feedbackNumber"
                      [(ngModel)]="mcgrundriss.feedbackNumber"
                    />
                  </mat-form-field>
                </div>

                <div class="col-2">
                  <mat-form-field>
                    <input matInput placeholder="feedbackMode" [(ngModel)]="mcgrundriss.feedbackMode" />
                  </mat-form-field>
                </div>

                <div class="col-3">
                  <button class="btn btn-primary" (click)="mcls.createOrderBackend(mcgrundriss.suborderId)">
                    Create Order McGrundriss
                  </button>
                </div>

                <div class="col-3">
                  <button
                    class="btn btn-primary"
                    (click)="
                      mcls.sendFeedbackBackend(
                        mcgrundriss.suborderId,
                        mcgrundriss.feedbackNumber,
                        mcgrundriss.feedbackMode
                      )
                    "
                  >
                    Send Feedback McGrundriss
                  </button>
                </div>
              </div>

              <br />

              <div class="row">
                <div class="col-3">
                  <mat-form-field>
                    <input matInput placeholder="orderId" [(ngModel)]="mcgrundriss.orderId" />
                  </mat-form-field>
                </div>

                <div class="col-3">
                  <button class="btn btn-primary" (click)="mcls.getOrderBackend(mcgrundriss.orderId)">
                    Get Order Details McGrundriss
                  </button>
                </div>

                <div class="col-3">
                  <mat-form-field>
                    <input matInput placeholder="productId" [(ngModel)]="mcgrundriss.productId" />
                  </mat-form-field>
                </div>

                <div class="col-3">
                  <button class="btn btn-primary" (click)="mcls.getProductsByIdBackend(mcgrundriss.productId)">
                    Get ProductsByID McGrundriss
                  </button>
                </div>
              </div>
              <div class="row">
                <button class="btn btn-primary m-3" (click)="assignUserToCompanyViaPath()">
                  Assign users to company via path
                </button>
              </div>
              <div class="row">
                <button class="btn btn-primary m-3" (click)="updatePlanetHomeRealEstatesBrokerDetails()">
                  Update Planet Home users broker details
                </button>
              </div>
              <form [formGroup]="servicesForm">
                <div class="row mt-3">
                  <div class="col-4">
                    <mat-select class="form-control" formControlName="selectedServices" multiple>
                      <mat-option *ngFor="let orderType of orderTypes" [value]="orderType.id">
                        {{ orderType.name }}
                      </mat-option>
                    </mat-select>
                  </div>
                  <div class="col-2">
                    <button class="btn btn-primary" (click)="services.updateServices(selectedServices.value)">
                      Update Services
                    </button>
                  </div>
                </div>
              </form>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="!isProductionEnvironment">
              <mat-expansion-panel-header> Invoice Funktionen </mat-expansion-panel-header>
              <div class="row">
                <div class="col-12">
                  <button class="btn btn-primary" (click)="ivls.runInvoicesScript('weekly-collective-invoices')">
                    Run Weekly Collective Invoice Job
                  </button>
                  <button class="btn btn-primary" (click)="ivls.runInvoicesScript('monthly-collective-invoices')">
                    Run Monthly Collective Invoice Job
                  </button>
                  <button class="btn btn-primary" (click)="ivls.runInvoicesScript('weekly-on-account-invoices')">
                    Run weekly "Kauf auf Rechnung" Invoice Job
                  </button>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header> Imogent Benutzer Profilbilder </mat-expansion-panel-header>
              <div class="col-12">
                <table class="table">
                  <tr *ngFor="let user of imogentUsers; let i = index">
                    <td width="25%">{{ user.firstName }} {{ user.lastName }}</td>
                    <td width="25%">{{ user.email }}</td>
                    <td width="50%">
                      <!-- Don't show upload fields while uploading -->
                      <ng-container class="mb-2" *ngIf="uploadingProfilePhoto === false; else loading">
                        <div class="mb-4 form-group row uploadArea">
                          <div *ngIf="!user.profilePhoto" class="col-12 col-xl-4 buttonUpload">
                            <label class="col-12">
                              <div class="row fileContainer text-center align-items-center">
                                <i class="material-icons col-12">cloud_upload</i>
                                <div class="col-12">
                                  <span class="btn btn-dark btn-sm pl-2 pr-2 clickable">Profilfoto hochladen</span>
                                </div>
                              </div>
                              <input
                                type="file"
                                class="alert alert-info"
                                (change)="uploadDocument($event, 'profilePhoto', user, i)"
                              />
                            </label>
                          </div>
                          <div *ngIf="user.profilePhoto" class="row col-12 align-bottom align-text-bottom pd-2">
                            <img
                              src="{{ user.profilePhoto }}"
                              class="img-fluid col-12 col-md-4 col-lg-3 pt-3 pb-3"
                              alt="Profilfoto"
                            />
                            <div class="col-12 col-md-4 col-lg-3 text-right logo-delete-icon">
                              <i
                                (click)="deleteFile($event, 'profilePhoto', user, i)"
                                class="fas fa-trash align-bottom clickable"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </td>
                    <!-- add default contact person for suborder type -->
                    <!-- <td width="25%">suborder type</td> -->
                  </tr>
                </table>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header> Zuordnung Dienstleister </mat-expansion-panel-header>
              <div class="col-12">
                <table class="table">
                  <tr>
                    <th>Produkt Number</th>
                    <th>Standard Dienstleister</th>
                    <th>Unternehmen</th>
                    <th>Kunden</th>
                    <th></th>
                  </tr>
                  <ng-container *ngFor="let serviceProviderAssignment of serviceProvidersData; let i = index">
                    <tr>
                      <td width="20%">{{ serviceProviderAssignment.packageNum }}</td>
                      <td width="20%">{{ serviceProviderAssignment.defaultName }}</td>
                      <td width="25%">
                        <ul>
                          <li *ngFor="let company of serviceProviderAssignment.companies">
                            {{ company.companyName }}: {{ company.serviceProvider }}
                          </li>
                        </ul>
                      </td>
                      <td width="25%">
                        <ul>
                          <li *ngFor="let user of serviceProviderAssignment.users">
                            {{ user.userName }}: {{ user.serviceProvider }}
                          </li>
                        </ul>
                      </td>
                      <td width="10%" class="p-1">
                        <button class="btn btn-outline-secondary p-2" (click)="editServiceProvider(i)">
                          bearbeiten
                        </button>
                      </td>
                    </tr>
                    <tr *ngIf="serviceProviderAssignment.expanded" class="card card-body d-table-row">
                      <td colspan="4">
                        <h4>Bearbeitung {{ serviceProviderAssignment.packageNum }}</h4>
                        <div class="row mt-4">
                          <div class="col-12">
                            <h5>Standard Dienstleister:</h5>
                            <div class="form-group">
                              <mat-label>Dienstleister</mat-label>
                              <mat-select
                                name="serviceProviderForm"
                                [(value)]="serviceProviderForm"
                                (selectionChange)="onServiceProviderChanged($event, i)"
                              >
                                <mat-option [value]="">Zurücksetzen</mat-option>
                                <mat-option
                                  *ngFor="let serviceProvider of uls.allServiceProviders"
                                  [value]="serviceProvider.uid"
                                >
                                  {{ serviceProvider.firstName }} {{ serviceProvider.lastName }} ({{
                                    serviceProvider.email
                                  }})
                                </mat-option>
                              </mat-select>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12">
                            <h5>Unternehmen:</h5>
                            <div
                              *ngFor="let company of serviceProviderAssignment.companies; let companyIndex = index"
                              class="row"
                            >
                              <div class="col-5">
                                {{ company.companyName }}
                              </div>
                              <div class="col-5">
                                {{ company.serviceProvider }}
                              </div>
                              <div class="col-2 m-auto">
                                <button
                                  class="btn btn-outline-danger"
                                  (click)="deleteCompanyServiceProvider(i, companyIndex)"
                                >
                                  <i class="far fa-trash-alt"></i>
                                </button>
                              </div>
                            </div>

                            <!-- add new -->
                            <div class="row">
                              <div class="col-5">
                                <div class="form-group">
                                  <mat-label>Unternehmen</mat-label>
                                  <mat-select name="companyForm" [(value)]="companyForm">
                                    <mat-option [value]="">Zurücksetzen</mat-option>
                                    <mat-option *ngFor="let company of cls.companiesList" [value]="company.cid">
                                      {{ company.name }}
                                    </mat-option>
                                  </mat-select>
                                </div>
                              </div>
                              <div class="col-5">
                                <div class="form-group">
                                  <mat-label>Dienstleister</mat-label>
                                  <mat-select name="companyServiceProviderForm" [(value)]="companyServiceProviderForm">
                                    <mat-option [value]="">Zurücksetzen</mat-option>
                                    <mat-option
                                      *ngFor="let serviceProvider of uls.allServiceProviders"
                                      [value]="serviceProvider.uid"
                                    >
                                      {{ serviceProvider.firstName }} {{ serviceProvider.lastName }} ({{
                                        serviceProvider.email
                                      }})
                                    </mat-option>
                                  </mat-select>
                                </div>
                              </div>
                              <div class="col-2 m-auto">
                                <button class="btn btn-outline-success" (click)="addCompanyServiceProvider(i)">
                                  <i class="fas fa-plus-circle"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12">
                            <h5>Kunden:</h5>
                            <div
                              *ngFor="let user of serviceProviderAssignment.users; let userIndex = index"
                              class="row"
                            >
                              <div class="col-5">
                                {{ user.userName }}
                              </div>
                              <div class="col-5">
                                {{ user.serviceProvider }}
                              </div>
                              <div class="col-2 m-auto">
                                <button
                                  class="btn btn-outline-danger"
                                  (click)="deleteUserServiceProvider(i, userIndex)"
                                >
                                  <i class="far fa-trash-alt"></i>
                                </button>
                              </div>
                            </div>

                            <!-- add new -->
                            <div class="row">
                              <div class="col-5">
                                <div class="form-group">
                                  <mat-label>Kunde</mat-label>
                                  <mat-select name="userForm" [(value)]="userForm">
                                    <mat-option [value]="">Zurücksetzen</mat-option>
                                    <mat-option *ngFor="let user of uls.allUsers" [value]="user.uid">
                                      {{ user.firstName }} {{ user.lastName }}
                                    </mat-option>
                                  </mat-select>
                                </div>
                              </div>
                              <div class="col-5">
                                <div class="form-group">
                                  <mat-label>Dienstleister</mat-label>
                                  <mat-select name="userServiceProviderForm" [(value)]="userServiceProviderForm">
                                    <mat-option [value]="">Zurücksetzen</mat-option>
                                    <mat-option
                                      *ngFor="let serviceProvider of uls.allServiceProviders"
                                      [value]="serviceProvider.uid"
                                    >
                                      {{ serviceProvider.firstName }} {{ serviceProvider.lastName }} ({{
                                        serviceProvider.email
                                      }})
                                    </mat-option>
                                  </mat-select>
                                </div>
                              </div>
                              <div class="col-2 m-auto">
                                <button class="btn btn-outline-success" (click)="addUserServiceProvider(i)">
                                  <i class="fas fa-plus-circle"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="p-1">
                        <button class="btn btn-outline-success p-2" (click)="saveServiceProvider(i)">speichern</button>
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header> Auftragstypen verantwortliche Benutzer </mat-expansion-panel-header>
              <div class="col-12">
                <form [formGroup]="orderTypeResponsibleForm" (ngSubmit)="addOrderTypeResponsible()">
                  <div class="row">
                    <div class="col-12 col-md-3">
                      <div class="form-group">
                        <div class="input-group mb-md-3">
                          <mat-label>
                            Order Type
                            <span class="text-danger">*</span>
                          </mat-label>
                          <mat-select formControlName="orderType">
                            <mat-option [value]="">zurücksetzen</mat-option>
                            <mat-option
                              *ngFor="let type of orderTypes | fieldFilter: 'enabled':true:'name'"
                              [value]="type.id"
                            >
                              {{ type.name }}
                            </mat-option>
                          </mat-select>
                        </div>
                        <!-- Form Verification Error -->
                        <div class="alert alert-danger" *ngIf="orderType.invalid && orderType.touched">Pflichtfeld</div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <div class="input-group mb-md-4">
                          <mat-label>
                            Responsible
                            <span class="text-danger">*</span>
                          </mat-label>
                          <mat-select formControlName="orderTypeResponsible">
                            <mat-option [value]="">zurücksetzen</mat-option>
                            <mat-option *ngFor="let responsible of imogentUsers" [value]="responsible.uid">
                              {{ responsible.name }} ({{ responsible.email }})
                            </mat-option>
                          </mat-select>
                        </div>
                        <!-- Form Verification Error -->
                        <div
                          class="alert alert-danger"
                          *ngIf="orderTypeResponsible.invalid && orderTypeResponsible.touched"
                        >
                          Pflichtfeld
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-3">
                      <button
                        class="btn btn-primary float-left"
                        type="submit"
                        [disabled]="!orderTypeResponsibleForm.valid"
                      >
                        <span>Hinzufügen</span>
                      </button>
                    </div>
                  </div>
                </form>
                <div class="row" *ngFor="let responsible of orderTypeResponsibles; let i = index">
                  <div class="col-12 col-md-3">
                    {{ getOrderTypeName(responsible.type) }}
                  </div>
                  <div class="col-12 col-md-4">
                    {{ getUser(responsible.userId) }}
                  </div>
                  <div class="col-12 col-md-3">
                    <button class="btn btn-danger float-left" type="button" (click)="removeOrderTypeResponsible(i)">
                      <span>Entfernen</span>
                    </button>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>Email Templates</mat-expansion-panel-header>
              <div class="col-12 p-0">
                <div class="card m-0 p-2">
                  <form #emailTemplates [formGroup]="emailTemplatesForm">
                    <div class="row">
                      <div class="form-group col-4">
                        <label class="font-weight-bold">Produkt</label>
                        <mat-select multiple formControlName="product" #product>
                          <mat-optgroup *ngFor="let service of allServices | keyvalue" [label]="service?.key">
                            <mat-option *ngFor="let package of service.value" [value]="package?.package_key">
                              {{ package?.name }}
                            </mat-option>
                          </mat-optgroup>
                          <mat-optgroup label="Platzhalter">
                            <mat-option value="standard"> Standard </mat-option>
                          </mat-optgroup>
                        </mat-select>
                      </div>
                      <div class="form-group col-4">
                        <label class="font-weight-bold">Bezeichnung</label>
                        <input
                          type="text"
                          class="form-control"
                          formControlName="templateTitle"
                          placeholder="Bezeichnung"
                          name="Bezeichnung"
                        />
                      </div>
                      <div class="form-group col-4">
                        <label class="font-weight-bold">Betreff</label>
                        <input
                          type="text"
                          class="form-control"
                          formControlName="subject"
                          placeholder="Betreff"
                          name="Betreff"
                        />
                      </div>
                    </div>
                    <div class="col-12 p-0">
                      <quill-editor trackChanges="all" [modules]="modules" formControlName="templateText">
                      </quill-editor>
                    </div>
                    <br />
                    <div class="row col-12 justify-content-end">
                      <button
                        class="btn btn-primary btn-sm"
                        (click)="createEmailTemplate()"
                        [disabled]="emailTemplatesForm.invalid"
                      >
                        Hinzufügen
                      </button>
                      <button class="btn btn-danger btn-sm mr-1" (click)="emailTemplates.reset()">Abbrechen</button>
                    </div>
                  </form>
                </div>

                <div class="mt-4 mat-elevation-z8">
                  <div class="row justify-content-end mr-2">
                    <div class="form-group col-4">
                      <mat-select multiple (selectionChange)="templateFilterChange($event)" placeholder="Filter">
                        <mat-optgroup *ngFor="let service of allServices | keyvalue" [label]="service?.key">
                          <mat-option *ngFor="let package of service.value" [value]="package?.package_key">
                            {{ package?.name }}
                          </mat-option>
                        </mat-optgroup>
                        <mat-optgroup label="Platzhalter">
                          <mat-option value="standard"> Standard </mat-option>
                        </mat-optgroup>
                      </mat-select>
                    </div>
                  </div>

                  <table mat-table [dataSource]="emailTemplateList" matSort>
                    <!-- Product Column -->
                    <ng-container matColumnDef="product">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
                      <td mat-cell *matCellDef="let element">{{ element?.product }} - {{ element?.packageName }}</td>
                    </ng-container>

                    <!-- templateTitle Column -->
                    <ng-container matColumnDef="templateTitle">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
                      <td mat-cell *matCellDef="let element">{{ element?.templateTitle }}</td>
                    </ng-container>

                    <!-- subject Column -->
                    <ng-container matColumnDef="subject">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Subject</th>
                      <td mat-cell *matCellDef="let element">{{ element?.subject }}</td>
                    </ng-container>

                    <!-- action Column -->
                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element">
                        <button class="btn btn-primary btn-sm" (click)="updateEmailTemplate(element)">
                          Bearbeiten
                        </button>
                        <button class="btn btn-danger btn-sm mr-1" (click)="deleteEmailTemplate(element?.id)">
                          Entfernen
                        </button>
                      </td></ng-container
                    >

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                  </table>

                  <mat-paginator
                    [pageSizeOptions]="[5, 10, 20]"
                    pageSize="20"
                    showFirstLastButtons
                    aria-label="Select page of periodic elements"
                  >
                  </mat-paginator>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header> Zielseitenverfolgung </mat-expansion-panel-header>
              <div class="col-12">
                <form [formGroup]="landingPageTrackingForm" (ngSubmit)="saveLandingPageTrackingItem()">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <mat-form-field>
                          <mat-label>Referenz-ID:</mat-label>
                          <input formControlName="refId" matInput />
                          <mat-error *ngIf="refId.invalid">Pflichtfeld</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <mat-slide-toggle
                          color="primary"
                          labelPosition="before"
                          formControlName="hasNewsletterInfoEnabled"
                        >
                          Newsletter anzeigen
                        </mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <mat-form-field>
                          <mat-label>Willkommensnachricht:</mat-label>
                          <input formControlName="welcomeMessage" matInput />
                          <mat-error *ngIf="welcomeMessage.invalid">Pflichtfeld</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <mat-form-field>
                          <mat-label>Firmen-Tag:</mat-label>
                          <input formControlName="companyTag" matInput />
                          <mat-error *ngIf="companyTag.invalid">Pflichtfeld</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <button (click)="resetLandingPageTrackingForm()" class="btn btn-back" type="button">
                      Abbrechen
                    </button>
                    <button
                      [disabled]="landingPageTrackingForm.invalid || landingPageTrackingForm.pristine"
                      class="btn btn-primary"
                    >
                      <span *ngIf="!currentLandingPageTrackingItem">Neu hinzufügen</span>
                      <span *ngIf="currentLandingPageTrackingItem">Änderungen speichern</span>
                    </button>
                  </div>
                </form>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Referenz-ID</th>
                      <th scope="col">Firmen-Tag</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of landingPageTrackingList; let i = index">
                      <td>{{ item.refId }}</td>
                      <td>{{ item.companyTag }}</td>
                      <td class="text-right">
                        <button class="btn btn-outline-secondary" (click)="editLandingPageTrackingItem(i)">
                          Bearbeiten
                        </button>
                        <button class="btn btn-danger" type="button" (click)="removeLandingPageTrackingItem(i)">
                          <span>Entfernen</span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div *ngIf="user.role !== 'Administrator'">
          <p>
            Diese Funktion befindet sich aktuell noch in der Entwicklung. In Kürze können Sie hier Einstellungen für
            Emailbenachrichtigungen vornehmen.
          </p>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #loading>
  <!-- CSS Loading Spinner -->
  <div class="lds-ring ring-default">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-template>
