import { Component, Input } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";

@Component({
  selector: "app-input-number",
  templateUrl: "./input-number.component.html",
  styleUrls: ["./input-number.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputNumberComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputNumberComponent,
    },
  ],
})
export class InputNumberComponent implements ControlValueAccessor, Validator {
  @Input() isValid: boolean;
  value: any;
  onChange = (value) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  constructor() {}

  onAdd() {
    this.markAsTouched();
    if (!this.disabled) {
      this.value += 1;
      this.onChange(this.value);
    }
  }

  onRemove() {
    this.markAsTouched();
    if (!this.disabled && this.value > 0) {
      this.value -= 1;
      this.onChange(this.value);
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  writeValue(value: number) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return null;
  }
}
