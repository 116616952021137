import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-download-zip',
  templateUrl: './download-zip.component.html',
  styleUrls: ['./download-zip.component.css']
})
export class DownloadZipComponent implements OnInit {

  downloadZipUrl = environment.apiUrl + 'files/downloadzip?oid=';

  constructor(
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    const sid = this.activeRoute.snapshot.params['sid'] || '';
    const dir = this.activeRoute.snapshot.params['dir'] || '';
    const token = this.activeRoute.snapshot.params['token'] || '';
    this.downloadZipUrl += sid + '&uid=' + token + '&dir=' + dir;
  }

}
