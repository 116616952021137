import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  active = true; // Initial States der einklappbaren Cards
  active2 = true;
  active3 = true;

  constructor() {
  }

  ngOnInit() {
  }

}
