import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";

@Injectable({
  providedIn: "root",
})
export class ServicesService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.SERVICES;

  constructor(private http: HttpClient) {}

  getServices() {
    return this.http.get(this.endpoint);
  }

  putServices(data) {
    return this.http.put(this.endpoint, data);
  }
}
