import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {SubcollectionFileModel} from '@app/models/subcollectionFileModel';
import {UploadService} from '@app/_services/upload.service';

@Component({
  selector: 'app-floortour',
  templateUrl: './floortour.component.html',
  styleUrls: ['./floortour.component.css']
})
export class FloortourComponent implements OnInit, AfterViewInit {

  imageList: any[] = [];
  flatFinderImages: SubcollectionFileModel[] = [];
  currentIndex = 0;
  selectedFlatIndex = 0;
  subOrderId = '';
  isLoaded = false;

  constructor(
    private uploadService: UploadService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.subOrderId = this.activatedRoute.snapshot.params['sid'];
    if (this.subOrderId) {
      this.uploadService.getViewsSubcollections(this.subOrderId, 'threesixty').subscribe((subDocs: SubcollectionFileModel[]) => {
        subDocs = subDocs.sort(function (a, b) {
          if (a.file_name > b.file_name) {
            return 1;
          } else if (a.file_name < b.file_name) {
            return -1;
          } else {
            return 0;
          }
        });
        this.imageList = subDocs;
        this.isLoaded = true;
      });
      this.uploadService.getViewsSubcollections(this.subOrderId, 'flatFinder').subscribe((subDocs: SubcollectionFileModel[]) => {
        this.flatFinderImages = subDocs;
        this.isLoaded = true;
      });
    } else {

    }
  }

  onSlide(event: any) {
    this.currentIndex = event.value;
  }

  selectFlat(index: number) {
    this.selectedFlatIndex = index;
  }

  ngAfterViewInit() {

  }
}
