import { Injectable } from "@angular/core";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";

@Injectable({
  providedIn: "root",
})
export class SubordersService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.SUBORDERS;

  constructor(private http: HttpClient) {}

  postSuborder(path: string, data: any) {
    return this.http.post(this.endpoint + "/" + path, data).toPromise();
  }

  patchSuborder(id: SubOrder["id"], data: any) {
    return this.http.patch<string>(this.endpoint + "/" + id, data).toPromise();
  }

  deleteSuborder(id: string) {
    return this.http.delete(this.endpoint + "/" + id).toPromise();
  }

  getSuborder(id: string) {
    return this.http.get<SubOrder>(this.endpoint + "/" + id).toPromise();
  }

  getSuborders(revenueStartDate?, revenueEndDate?) {
    let queryString = "?";
    let params = new HttpParams();
    params = params.set("startAfter", revenueStartDate.toString());
    params = params.set("endBefore", revenueEndDate.toString());
    return this.http.get<SubOrder[]>(this.endpoint + queryString + params.toString()).toPromise();
  }

  getRelatedSuborders(id: SubOrder["id"]) {
    return this.http.get<SubOrder[]>(this.endpoint + "/" + id + "/related").toPromise();
  }

  getSubordersOfOrder(orderId: string): Promise<SubOrder[]> {
    return this.http.get<SubOrder[]>(this.endpoint + "/order-id/" + orderId + "/related").toPromise();
  }
}
