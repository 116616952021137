import { ProductBundleData } from "@app/interfaces/product-bundle-data.interface";
import { ProductDefaultOption } from "@app/interfaces/product-default-option.interface";
import { DocEstateInvoiceDetail, InvoiceDetail } from "@app/interfaces/invoice-detail.interface";
import { MediaTypeEnum } from "@app/models/media-type.model";
import { FileItem, FileLikeObject } from "ng2-file-upload";
import { ProductBundle } from "@app/interfaces/product-bundle.interface";
import { ProductBundlePackage } from "@app/interfaces/product-bundle-package.interface";
import { CONSTANTS } from "./constants";
import { UserAuthModel } from "@app/models/user-auth.model";
import { environment } from "@environments/environment";
import { FileUploadListTargetEnum } from "@app/models/file-upload-list-target.enum";
import { FileDetails } from "@app/interfaces/file-details-data.interface";
import { FileUploadListItem } from "@app/interfaces/file-upload-list-item.interface";
import { LANGUAGE } from "@app/language/language";
import * as _ from "underscore";
import * as moment from "moment";
import * as business from "moment-business-days";
import { User } from "@app/interfaces/user.interface";
import { Package } from "@app/models/package";
import { HttpParams } from "@angular/common/http";
import { v4 as uuidv4 } from "uuid";
import { RealEstate } from "@app/interfaces/real-estate.interface";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { MailLinkTypeEnum } from "../../../functions/src/models/mail-Link-type.enum";
import Holidays from "date-holidays";
import { SsoSourcesEnum } from "@app/models/sso-sources.enum";
import { MatTableDataSource } from "@angular/material/table";
import { CollectiveInvoiceIntervalEnum } from "@app/models/collectiveInvoiceInterval-option-list";
import { ComparatorEnum } from "@app/models/comparator.enum";

const logFile = "Helper :: ";

/**
 * Checks if a filename is using a valid image extension.
 * @param fileName The filename to check.
 * @returns A flag indicating the result of the check.
 */
export function isImage(fileName: string): boolean {
  const imgExtns = ["jpg", "jpeg", "png"];
  let isImg = false;
  const ext = fileName.substr(fileName.lastIndexOf(".") + 1);
  if (imgExtns.indexOf(ext) >= 0) {
    isImg = true;
  }
  return isImg;
}

/**
 * Finds the max quantity value for the product default options used in product bundles.
 * @param productDefaultOptionList The product default options list used in product bundles.
 * @param accountingPositionId The accounting position id to search for.
 * @param defaultMaxQuantity The default max quantity to use when no max quantity was found.
 * @returns The max quantity found.
 */
export function getProductDefaultOptionMaxQuantity(
  productDefaultOptionList: ProductDefaultOption[],
  accountingPositionId: string,
  defaultMaxQuantity = 0
) {
  const foundProductDefaultOption = productDefaultOptionList.find(
    (item) => item.accountingPositionId === accountingPositionId
  );

  return foundProductDefaultOption ? foundProductDefaultOption.maxQuantity : defaultMaxQuantity;
}

/**
 * Sets a default max quantity for the accounting position id.
 * @param accountingPositionsDefaultMaxQuantityMap The accounting positions default max quantity map keeping track of all max quanitites.
 * @param productDefaultOptionList The product default options list used in product bundles.
 * @param accountingPositionId The accounting position id to keep track of the default max quantity.
 * @param defaultMaxQuantity The default max quantity to save.
 * @returns
 */
export function setAccountingPositionDefaultMaxQuantity(
  accountingPositionsDefaultMaxQuantityMap: Map<string, number>,
  productDefaultOptionList: ProductDefaultOption[] = [],
  accountingPositionId: string,
  defaultMaxQuantity = 0
) {
  if (!accountingPositionId) {
    console.warn(
      logFile + "No accounting position Id detected! Will not set accountingPositionDefaultMaxQuantity then."
    );
    return;
  }

  accountingPositionsDefaultMaxQuantityMap.set(
    accountingPositionId,
    getProductDefaultOptionMaxQuantity(productDefaultOptionList, accountingPositionId, defaultMaxQuantity)
  );
}

/**
 * Get the default max quantity for the accounting position id.
 * @param accountingPositionsDefaultMaxQuantityMap The accounting positions default max quantity map keeping track of all max quanitites.
 * @param accountingPositionId The accounting position id to look for.
 * @returns The max quantity found.
 */
export function getAccountingPositionDefaultMaxQuantity(
  accountingPositionsDefaultMaxQuantityMap: Map<string, number>,
  accountingPositionId: string
) {
  return accountingPositionId ? accountingPositionsDefaultMaxQuantityMap.get(accountingPositionId) : 0;
}

export function createInvoiceEntryObj(
  qty: number,
  description: string,
  value: number,
  accountingPositionId: string
): InvoiceDetail {
  return {
    quantity: qty,
    description: description,
    total: value,
    "accounting-position": accountingPositionId + "_" + uuidv4(),
  };
}

export function createDocEstateInvoiceEntryObj(
  qty: number,
  description: string,
  value: number,
  accountingPositionId: string,
  docEstateProductId: number
): DocEstateInvoiceDetail {
  return {
    quantity: qty,
    description: description,
    total: value,
    "accounting-position": accountingPositionId,
    docEstateProductId: docEstateProductId,
  };
}

/**
 * Finds the default package option in product bundle options.
 * @param packageOptionKeywordMap The package options keyword map.
 * @param productDefaultOptionList The product default options list used in product bundles.
 * @param defaultOption The default option to be used when no option was found.
 * @returns The option found.
 */
export function getDefaultPackageOptionFromProductBundleOptions(
  packageOptionKeywordMap: Map<string, string>,
  productDefaultOptionList: ProductDefaultOption[] = [],
  defaultOption: string
) {
  let foundOption = "";

  if (!productDefaultOptionList.length) {
    return defaultOption;
  }

  const it = packageOptionKeywordMap.entries();
  let result = it.next();
  while (!result.done) {
    const [key, value] = result.value;
    const regex = new RegExp("_(" + value + "_)|(" + value + "$)");
    const hasFoundKeywordInAccountingPositions = !!productDefaultOptionList.find((item) => {
      const found = item.accountingPositionId.match(regex);
      return !!found;
    });

    if (hasFoundKeywordInAccountingPositions) {
      foundOption = key;
      result.done = true;
    } else {
      result = it.next();
    }
  }

  return foundOption || defaultOption;
}

/**
 * Checks if any of the suborders contain a photography order type.
 *
 * @param {SubOrder[]} suborders - An array of suborders.
 * @return {boolean} - Returns true if any of the suborders contain a photography order type, otherwise returns false.
 */
export function containsPhotographyOrderType(suborders: SubOrder[]) {
  return suborders.some((suborder) => isPhotographyOrderType(suborder));
}

/**
 * Checks if a given suborder is of photography order type.
 *
 * @param {SubOrder} suborder - The suborder to check.
 * @return {boolean} - True if the suborder is of photography order type, false otherwise.
 */
export function isPhotographyOrderType(suborder: SubOrder) {
  return CONSTANTS.PHOTOGRAPHY_ORDER_TYPES.includes(suborder.orderType);
}

export function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks
  const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
}

/**
 * Returns the number of days of the month in the defined year.
 * @param year The year as full year (like 2020)
 * @param month The month 0-indexed (January: 0, Feb: 1, ...)
 */
export function getDaysInMonth(year: number, month: number) {
  return new Date(year, month, 0).getDate();
}

/**
 * returns the file extension based on the filename
 * @param fileName fileName as a string.
 */
export function getFileExtension(fileName: string) {
  if (!fileName) {
    return "";
  }
  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex === -1) {
    // If there's no dot in the name
    return "";
  }
  return fileName.slice(lastDotIndex + 1).toLowerCase();
}

/**
 * Returns the type of the media based on its filename / extension.
 * @param fileName Input filename
 */
export function getMediaType(fileName: string, download_url_m?: string) {
  const extensionMapping = {
    jpg: MediaTypeEnum.image,
    jpeg: MediaTypeEnum.image,
    png: MediaTypeEnum.image,
    dng: MediaTypeEnum.image,
    pdf: MediaTypeEnum.pdf,
    mp4: MediaTypeEnum.video,
    mov: MediaTypeEnum.video,
    avi: MediaTypeEnum.video,
    mkv: MediaTypeEnum.video,
    tour: MediaTypeEnum.tour,
  };

  if (isImgPreviewFromPDF(fileName, download_url_m)) {
    return MediaTypeEnum.imgPdfPreview;
  }
  const fileExtn = getFileExtension(fileName).toLowerCase();

  if (Object.keys(extensionMapping).includes(fileExtn)) {
    return extensionMapping[fileExtn];
  } else {
    return undefined;
  }
}

/**
 * Checks if the image from the downloadUrl is a preview of a PDF (defined in the fileName).
 * @param {string} originalFileName - The original file name of the file.
 * @param {string} downloadUrl - The URL where the image is downloaded from.
 * @return {boolean} - Return true if the image is a preview of a PDF, otherwise return false.
 */
export function isImgPreviewFromPDF(originalFileName: string, downloadUrl?: string) {
  const fileExtn = getFileExtension(originalFileName).toLowerCase();

  if (downloadUrl) {
    let url = new URL(downloadUrl);
    let pathnamePieces = decodeURIComponent(url.pathname).split("/");
    let fileNameDownloadUrlM = pathnamePieces[pathnamePieces.length - 1];
    if (fileExtn === "pdf" && getFileExtension(fileNameDownloadUrlM).toLowerCase() === "png") {
      return true;
    }
  }
  return false;
}

/**
 * Returns duplicates (based on filename only!) in the upload queue.
 * @param queue FileItem[]
 * @returns {duplicatedFiles: FileItem[], fileList: fileList[]}
 */
export function filterUploadQueue(queue: FileItem[]) {
  const fileList = new Array<FileLikeObject>(); // File list of files to be uploaded.
  const duplicatedFiles = new Array<FileItem>(); // Files that won't be uploaded because a file with the same filename already exists.

  queue.forEach((fl) => {
    if (fileList.findIndex((entry) => fl.file.name.toLowerCase() === entry.name.toLowerCase()) >= 0) {
      duplicatedFiles.push(fl);
    } else {
      fileList.push(fl.file);
    }
  });

  return { duplicatedFiles: duplicatedFiles, fileList: fileList };
}

export function createCustomEvent(eventName: string, data: any) {
  return new CustomEvent(eventName, {
    detail: data,
  });
}

/**
 * Applys bundle data on the given package_key and returns the productBundleData
 * @param bundleData The product bundle data to be applied.
 * @param package_key The package its package_key
 */
export function applyBundleData(bundleData: ProductBundle, package_key: string): ProductBundleData {
  const applicableProduct = findPackageByKeyInBundleData(bundleData, package_key);

  return {
    ...applicableProduct,
    title: applicableProduct?.productData?.productBundleData.title,
    id: applicableProduct?.productData?.productBundleData.id,
  };
}

/**
 * Searches the productList of a ProductBundle and returns a ProductBundlePackage suitable to package_key if available.
 * @param bundleData The product bundle data
 * @param package_key The package its package_key to be searched for
 */
export function findPackageByKeyInBundleData(bundleData: ProductBundle, package_key: string): ProductBundlePackage {
  return bundleData.productsList.find((product: ProductBundlePackage) => {
    return product.productId === package_key;
  });
}

/**
 * Returns if it's a finite number
 * @param value a value
 */
export function isNumberFinite(value: any): value is number {
  return isNumber(value) && isFinite(value);
}

/**
 * Returns whether the number is positive
 * @param value a number
 */
// Not strict positive
export function isPositive(value: number): boolean {
  return value >= 0;
}

/**
 * Returns whether a number is integer
 * @param value a number
 */
export function isInteger(value: number): boolean {
  // No rest, is an integer
  return value % 1 === 0;
}

/**
 * Returns decimal value
 * @param value
 * @param decimal
 */
export function toDecimal(value: number, decimal: number): number {
  return Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

/**
 * returns whether the value of type number
 * @param value
 */
export function isNumber(value: any): value is number {
  return typeof value === "number";
}

/**
 * Returns if it's a finite number
 * @param value a value
 */
export function isString(value: any): boolean {
  return typeof value === "string";
}

export function addDaysToDate(date: Date, days: number): Date {
  if (days < 0) {
    days = 0;
  }

  setBusinessHolidaysConfig(date.getFullYear(), date.getFullYear() + 1);

  const finalDate = business(date).businessAdd(days, "days").toDate();
  return finalDate;
}

/**
 * Add count number of days in date moment excluding weekends.
 *
 * @param {Date|string} date
 * @param {number} count
 * @returns Date object
 * @memberof Helper
 */
export function addBusinessDaysToDate(date: any, count: number) {
  const momentDate = business(date);
  const convertedDate = momentDate.toDate();
  const dateYear = convertedDate.getFullYear();
  setBusinessHolidaysConfig(dateYear - 1, dateYear + 1);
  return business(convertedDate).businessAdd(count, "days").toDate();
}

/**
 * Data converter function used to parse all data from an object and transform all
 * found ISO format dates into a Date object.
 * @param {object} data
 */
export function parseIsoStringtoDate(data: any) {
  if (typeof data !== "object" || !data) {
    return;
  }

  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "object" && data[key]) {
      switch (data[key].constructor.name) {
        case "Object":
          parseIsoStringtoDate(data[key]);
          break;
        case "Array":
          for (let i = 0; i < data[key].length; i++) {
            let element = data[key][i];
            if (new RegExp(CONSTANTS.REG_EXP.ISO_DATE).test(element)) {
              data[key][i] = convertIsoStringToDate(element);
            } else {
              parseIsoStringtoDate(element);
            }
          }
          break;
      }
    } else if (new RegExp(CONSTANTS.REG_EXP.ISO_DATE).test(data[key])) {
      data[key] = convertIsoStringToDate(data[key]);
    }
  });
}

function convertIsoStringToDate(val: string) {
  const timestamp = Date.parse(val);
  return new Date(timestamp);
}

/**
 * Shortens a string to x length and transforms it into uppercase.
 * @param val The string to be shortened.
 * @param len The length of the string to shorten.
 * @returns The shortened string.
 */
export function shortener(val: string, len: number) {
  val = val.replace(/\s+/g, "").toUpperCase();
  return val.substr(0, len);
}

export function dateToString(date: Date) {
  const y = date.getFullYear().toString().padStart(4, "0");
  const m = date.getMonth().toString().padStart(2, "0");
  const d = date.getDate().toString().padStart(2, "0");

  return `${d}${m}${y}`;
}

/**
 * Checks if a string is ending with a certain suffix.
 * @param inputString The string to check.
 * @param stringArray The array with suffixes to check.
 * @param delimiter A delimiter to be used on the suffix.
 * @returns A flag indicating if its valid.
 */
export function endsWithAny(inputString: string, stringArray: string[], delimiter?: string) {
  return stringArray.some((suffix) => {
    return inputString.endsWith((delimiter || "") + suffix);
  });
}

/**
 * Stores the provided data in the session storage with the specified key.
 *
 * @param {string} key - The key under which to store the data in session storage.
 * @param {any} data - The data to be stored in session storage.
 *
 * @return {void} - This method does not return anything.
 */
export function storeInSession(key: string, data: any) {
  sessionStorage.setItem(key, JSON.stringify(data));
}

/**
 * Retrieves data from the session storage based on the provided key.
 *
 * @param {string} key - The key used to identify the data in the session storage.
 * @returns {any} The parsed data retrieved from the session storage, or null if an error occurred or session storage is not available.
 */
export function loadFromSession(key: string) {
  let sessionData: any;
  try {
    if (typeof sessionStorage !== "undefined") {
      sessionData = sessionStorage.getItem(key);
      return JSON.parse(sessionData);
    } else {
      console.warn("sessionStorage is not defined or not available");
    }
  } catch (error) {
    console.error("An error occured while trying to load from session: ", error);
  }
  return null;
}

/**
 * Removes an item from the session storage based on the provided key.
 *
 * @param {string} key - The key of the item to be removed from the session storage.
 * @return {void}
 */
export function removeFromSession(key: string) {
  sessionStorage.removeItem(key);
}

export function createFileUploaderOptions(mimeTypes: string[] = [], maxFileSize?: number) {
  const user: UserAuthModel = JSON.parse(localStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER) || "{}");
  const fileUploaderOptions = {
    url: environment.apiUrl + "files",
    authToken: "Bearer " + user.idToken,
    autoUpload: true,
    method: "post",
    allowedMimeType: undefined,
    maxFileSize: undefined,
    headers: [{ name: "usertoken", value: user?.userToken }],
  };

  if (mimeTypes.length) {
    fileUploaderOptions.allowedMimeType = mimeTypes;
  }
  if (maxFileSize) {
    fileUploaderOptions.maxFileSize = maxFileSize;
  }

  return fileUploaderOptions;
}

export function createInitialFileUploadItem(item: { target: FileUploadListTargetEnum; fileDetails: FileDetails }) {
  return <FileUploadListItem>{
    name: item.fileDetails.file_name,
    hasFinishedSuccessfully: true,
    fileDetails: item.fileDetails,
  };
}

/**
 * Returns the label_key translation from list_name defined in LANGUAGE.app_list_strings constant in language.ts
 */
export function translateAppListStrings(list_name: string, label_key: string): string {
  if (_.isEmpty(list_name) || _.isEmpty(label_key)) {
    return "";
  }
  if (
    !_.isEmpty(LANGUAGE["app_list_strings"][list_name]) &&
    !_.isEmpty(LANGUAGE["app_list_strings"][list_name][label_key])
  ) {
    return LANGUAGE["app_list_strings"][list_name][label_key];
  }
  return label_key; // return the un translated label if translation not found
}

export function getWeeksOfYear() {
  const weeks = [];
  let startDate = moment().startOf("week").isoWeekday(8);
  if (startDate.date() == 8) {
    startDate = startDate.isoWeekday(-6);
  }
  let today = moment().add(180, "days").isoWeekday("Sunday");
  while (startDate.isBefore(today)) {
    let currentDate = moment(startDate);
    let startDateWeek = currentDate.isoWeekday("Monday").locale("de").format("Do MMM");
    let endDateWeek = currentDate.isoWeekday("Sunday").locale("de").format("ll");
    weeks.push(`KW ${startDate.isoWeek()}  :  ${startDateWeek} - ${endDateWeek}`);
    startDate.add(7, "days");
  }
  return weeks;
}

export function isPlanetHomeUser(user: User) {
  const mailDomain = user.email?.substring(user.email?.indexOf("@") + 1) || "";

  return user.external === CONSTANTS.SSO_SOURCES.PLANET_HOME && CONSTANTS.PLANETHOME_EMAIL_DOMAINS.includes(mailDomain);
}

export function isPropstackUser(user: User) {
  return user.external === CONSTANTS.SSO_SOURCES.PROPSTACK;
}

export function getFirebaseBaseURL(collection: string) {
  return (
    CONSTANTS.FIREBASE_URLS.CONSOLE +
    environment.firebaseProjectId +
    "/" +
    CONSTANTS.FIREBASE_URLS.FIRESTORE_PATH +
    "/" +
    collection +
    "/"
  );
}

export function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function getPackageName(pack?: Package) {
  if (!pack) {
    return "";
  }

  return pack.service_title + " - " + pack.name;
}

/**
 * Flattens an object with the paths for keys. Eg: { a: { b: { c: 1 } }, d: 1 } => {"a.b.c":1,"d":1}
 * @param obj The object to flatten.
 * @param prefix An optional prefix in case we want to add it to every key.
 * @returns
 */
export function flattenObject(obj, prefix = "") {
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + "." : "";
    if (typeof obj[k] === "object") Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});
}

/**
 * Returns a query string.
 * @param from pagination from
 * @param to pagination to
 * @param sortBy property the data shall be sorted by
 * @param sortDirection sort direction: 'asc' or 'desc'
 * @param queryMap A Map<string, string> with filter properties
 */
export function buildQueryString(
  from?: number,
  to?: number,
  sortBy?: string,
  sortDirection?: string,
  queryMap?: Map<string, string>
): string {
  if (!from) {
    from = 0;
  }
  if (!to) {
    to = 10;
  }
  if (!sortBy) {
    sortBy = "createdOn";
  }
  if (!sortDirection) {
    sortDirection = "desc";
  }

  const queryString = "?";
  let params = new HttpParams();
  if (queryMap) {
    for (const [key, value] of queryMap) {
      params = params.set(key, encodeURIComponent(value));
    }
  }

  params = params.set("from", from.toString());
  params = params.set("to", to.toString());
  params = params.set("sortBy", sortBy);
  params = params.set("sortDirection", sortDirection);

  return queryString + params.toString();
}

/**
 * Parses a mixed string with values separated by a separator and returns the last item from the list.
 * Useful for cases when we need to extract the suborder packageNum field, since it can hold mixed values like a normal package key or a bundle id mixed with a package key eg: xyz|123.
 * @param val The value to parse.
 * @param separator The separator dividing the data. Defaults to the "|" symbol.
 * @returns The parsed string with the respective package key.
 */
export function parseMixedVal(val: string, separator = "|") {
  return val.split(separator).pop();
}

/**
 * Returns the directlink to the suborder.
 * @param subOrder The suborder object
 * @param mailLinkType The mailLinkType, e.g. to modify the direct-Link
 * @param realEstate The realEstate object of the suborder. Required if mailLinkType is "propstack"
 */
export function getSuborderURL(subOrder: SubOrder, mailLinkType: MailLinkTypeEnum, realEstate?: RealEstate) {
  if (mailLinkType === MailLinkTypeEnum.PROPSTACK) {
    let objectNumber;
    if (realEstate) {
      objectNumber = realEstate.objectNumber;
    }

    return CONSTANTS.SUBORDER_DIRECT_LINK_TEMPLATES.PROPSTACK.replace(
      "${objectNumber}",
      realEstate.objectNumber
    ).replace("${suborderId}", subOrder.id);
  } else {
    return CONSTANTS.SUBORDER_DIRECT_LINK_TEMPLATES.DEFAULT.replace("${platformURL}", environment.platformUrl).replace(
      "${subOrderId}",
      subOrder.id
    );
  }
}

/**
 * Returns a URL to an IMOGENT PDF-Viewer based on another pdf URL. Can be used as a proxy to avoid CORS issues and to add auth headers in the backend.
 * @param originalURL Original URL pointing to a PDF
 */
export function getPDFViewerURL(originalURL: string) {
  return environment.apiUrl + "views/pdf-viewer?url=" + encodeURIComponent(originalURL);
}

/**
 * Returns a URL to an IMOGENT PDF-Downloader based on another pdf URL. Can be used as a proxy to avoid CORS issues and to add auth headers in the backend.
 * @param originalURL Original URL pointing to a PDF
 */
export function getPDFDownloaderURL(originalURL: string, filename?: string) {
  let downloadURL = environment.apiUrl + "views/pdf-viewer?download=true";
  if (filename) {
    downloadURL += `&filename=${filename}`;
  }
  downloadURL += "&url=" + encodeURIComponent(originalURL);

  return downloadURL;
}

/**
 * Set the business holidays configuration based on the specified year period.
 *
 * @param startYear  Starting year.
 * @param endYear Ending year.
 */
export function setBusinessHolidaysConfig(startYear: number, endYear: number) {
  const holidaysList = getAllHolidays(startYear, endYear);

  business.updateLocale(CONSTANTS.COMPANY_LOCATION.COUNTRY, {
    holidays: holidaysList,
    holidayFormat: "YYYY-MM-DD",
    workingWeekdays: [1, 2, 3, 4, 5], // Note: 0, is Sunday, 6 is Saturday
  });
}

/**
 * Fetches all holidays for the specified year period from the german state where our company is registered, which is Nordrhein-Westfalen (NW).
 *
 * @param startYear  Starting year.
 * @param endYear Ending year.
 * @returns A list with all the holidays for the specified year period
 */
export function getAllHolidays(startYear: number, endYear: number) {
  const holidays: string[] = [];

  var hd = new Holidays(CONSTANTS.COMPANY_LOCATION.COUNTRY, CONSTANTS.COMPANY_LOCATION.STATE);

  while (startYear <= endYear) {
    const allHolidays = hd.getHolidays(startYear);
    const publicHolidays = allHolidays
      .filter((item) => item.type === "public")
      .map((item) => item.date.substring(0, 10));
    holidays.push(...publicHolidays);
    ++startYear;
  }

  return holidays;
}

/**
 * Calculates how many business days passed between two dates, respecting german business days and holidays.
 *
 * @param startDate Starting date.
 * @param endDate Ending date.
 * @returns The number of business days between both dates.
 */
export function calculateBusinessDays(startDate?: Date, endDate?: Date) {
  if (!startDate || !endDate) {
    return null;
  }

  const momentEndDate = business(endDate);
  let momentCurrentStartDate = business(startDate);
  let momentCurrentEndDate = business(startDate).add(1, "days").hours(0).minutes(0).seconds(0).milliseconds(0);
  let businessDays = 0;

  // handle short date intervals (<=1 day)
  if (momentCurrentEndDate.isSameOrAfter(momentEndDate) && momentCurrentStartDate.isBusinessDay()) {
    businessDays += momentEndDate.diff(momentCurrentStartDate, "days", true);
  } else {
    while (momentCurrentStartDate.isBefore(momentCurrentEndDate) && momentCurrentEndDate.isBefore(momentEndDate)) {
      if (momentCurrentStartDate.isBusinessDay()) {
        businessDays += momentCurrentEndDate.diff(momentCurrentStartDate, "days", true);
      }
      momentCurrentStartDate = momentCurrentStartDate.add(1, "days").hours(0).minutes(0).seconds(0).milliseconds(0);
      momentCurrentEndDate = momentCurrentEndDate.add(1, "days").hours(0).minutes(0).seconds(0).milliseconds(0);

      if (momentCurrentEndDate.isAfter(momentEndDate)) {
        businessDays += momentEndDate.diff(momentCurrentStartDate, "days", true);
      }
    }
  }

  businessDays = Number(businessDays.toFixed(3));

  //console.log('Calculated bussiness days: ', businessDays);
  return businessDays;
}

// TODO: When we switched the service provider for 2D floorplans to immoviewer CONSTANTS.PACKAGE_KEYS.FLOORPLAN_KLASSICH can be removed.
export function isClassicFloorplanViaOffice(package_key: string, ssoSource?: SsoSourcesEnum) {
  return !!(
    [CONSTANTS.PACKAGE_KEYS.FLOORPLAN_KLASSICH, CONSTANTS.PACKAGE_KEYS.FLOORPLAN_CLASSIC].includes(package_key) &&
    ssoSource === SsoSourcesEnum.ON_OFFICE
  );
}

export function addToTableDataSource<T>(dataSourceRef: MatTableDataSource<T>, newData: T) {
  // When the the existing table data is just updated the change is somehow not reflected in the frontend.
  // Thus, a new array is created and assigned to the dataSource
  const tableDataCopy = dataSourceRef.data.slice();
  dataSourceRef.data = [newData].concat(tableDataCopy);
}

/**
 *
 * @param dataSourceRef
 * @param id
 */
export function removeFromTableDataSource<T extends { id?: string }>(dataSourceRef: MatTableDataSource<T>, id: string) {
  const foundIndex = dataSourceRef.data.findIndex((item) => item.id === id);
  // For some reason the table is not being updated when splice is used on the original dataSource.data.
  // Thus, a copy is created, the removed element is removed and the new array is assigned to the dataSource.data.
  const newList = dataSourceRef.data.slice();
  newList.splice(foundIndex, 1);
  dataSourceRef.data = newList;
}

export function updateInTableDataSource<T extends { id?: string }>(
  dataSourceRef: MatTableDataSource<T>,
  id: string,
  updatedData: T
) {
  const foundIndex = dataSourceRef.data.findIndex((item) => item.id === id);
  dataSourceRef.data[foundIndex] = updatedData;
}

export function dateToFirestoreTimestamp(date: Date) {
  const _moment = moment(date);

  return {
    _seconds: _moment.unix(),
    _nanoseconds: _moment.milliseconds() * 1000000, // Nanoseconds of firestore timestamps
  };
}

export function isCompanyInvoice(collectiveInvoiceOption?: CollectiveInvoiceIntervalEnum) {
  return [CollectiveInvoiceIntervalEnum.Monthly, CollectiveInvoiceIntervalEnum.WeeklyCompany].includes(
    collectiveInvoiceOption
  );
}

export function validateCharacters(regexPattern: RegExp, event: KeyboardEvent) {
  let inputChar = event.key;
  if (!regexPattern.test(inputChar)) {
    // invalid character, prevent input
    event.preventDefault();
  }
}

/**
 * Removes any leading or trailing whitespace from the given postcode and adds a leading zero if the length is 4.
 *
 * @param {string|number} inputPostcode - The input postcode which can be a string or number.
 * @returns {string} - The sanitized postcode as a string.
 */
export function getSanitizedPostcode(inputPostcode: string | number) {
  // Force the value to be treated as a string
  let postalcodeValue = inputPostcode.toString().trim();
  // Remove any non-numeric character
  postalcodeValue = postalcodeValue.replace(/\D/g, "");

  // Add a leading zero, if the string has 4 digits, else return the first 5 digits (this truncates any extra digit)
  return postalcodeValue.length === 4 ? "0" + postalcodeValue : postalcodeValue.slice(0, 5);
}

/**
 * Evaluates if the expression is true or false
 * @param value1
 * @param value2
 * @param comparator
 */
export function evaluate(value1: any, value2: any, comparator: ComparatorEnum) {
  switch (comparator) {
    case ComparatorEnum.Equal:
      return value1 === value2;
    case ComparatorEnum.GreaterEqual:
      return value1 >= value2;
    case ComparatorEnum.GreaterThan:
      return value1 > value2;
    case ComparatorEnum.SmallerEqual:
      return value1 <= value2;
    case ComparatorEnum.SmallerThan:
      return value1 < value2;
    case ComparatorEnum.UnEqual:
      return value1 !== value2;
    case ComparatorEnum.OneOf:
      return Array.isArray(value2) && value2.includes(value1);
    case ComparatorEnum.NotIn:
      return !value2.includes(value1);
    default:
      return undefined;
  }
}
