import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { AccountingPosition } from "@app/interfaces/accounting-position.interface";
import { AccountingPositionsLogicService } from "./accounting-positions-logic.service";
import { Observable, of } from "rxjs";
import { ObservableCachingService } from "@app/_services/observable-caching.service";

@Injectable({
  providedIn: "root",
})
export class AccountingPositionResolverService
  implements Resolve<Observable<QueryResult<AccountingPosition>> | Promise<QueryResult<AccountingPosition>>>
{
  private resolvedAccountingPositions: QueryResult<AccountingPosition>;

  constructor(private apls: AccountingPositionsLogicService, private ocs: ObservableCachingService) {}

  resolve() {
    if (this.apls.allPositions?.length >= 0) {
      return this.ocs.handleWithCache(this.apls.getAllPositions, this.resolvedAccountingPositions, this.apls);
    } else {
      return this.apls.getAllPositions();
    }
  }
}
