import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "@app/auth/auth.service";
import { LoaderService } from "@app/_services/loader.service";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription } from "rxjs";
import { UploadService } from "@app/_services/upload.service";
import { UserRoleEnum } from "@app/models/user-role-list";
import { UsersLogicService } from "@app/_services/users-logic.service";
import { CompaniesLogicService } from "@app/_services/companies-logic.service";
import { NotificationsService } from "@app/_services/notifications.service";
import { CONSTANTS } from "@app/util/constants";
import { environment } from "@environments/environment";
import { PaymentOptionEnum } from "@app/models/payment-option-list";
import { User } from "@app/interfaces/user.interface";
import { updateInTableDataSource } from "@app/util/helper";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("paginator") paginator: MatPaginator;

  columnsToDisplayCustomer = ["createdOn", "Desc", "packages", "status", "priceNum", "info"];
  columnsToDisplay = ["name", "email", "role", "uid", "registeredOn", "companyName", "external", "status"];
  userRoleEnum = UserRoleEnum;
  currentUserData: any;
  curUserId: string;
  public state = "default";
  public success = false;
  showSuccessMessage = false;
  successMessage = "";
  userDataSrc: MatTableDataSource<User> = new MatTableDataSource();
  keyValueQueryMap = new Map();
  userCount: number;
  filterValues = {};
  rolesList = [];
  disabledPaymentOptions: PaymentOptionEnum[];
  paymentOptionEnum = PaymentOptionEnum;
  curCompanyId: string;
  curCompany: any;
  selectedCompany: any;
  usersUpdatesub$: Subscription;
  serviceProviderData;
  companiesListCopy = [];
  forwardEmails: string[] = [];
  isInitForwardEmailsSave: boolean;
  constants = CONSTANTS;
  environment = environment;
  firebaseUsersURL =
    CONSTANTS.FIREBASE_URLS.CONSOLE +
    environment.firebaseProjectId +
    "/" +
    CONSTANTS.FIREBASE_URLS.FIRESTORE_PATH +
    "/" +
    CONSTANTS.COLLECTIONS.USERS +
    "/";
  isPaymentOptionUpdatePending: boolean;

  constructor(
    public auth: AuthService,
    public loader: LoaderService,
    private uls: UsersLogicService,
    public cls: CompaniesLogicService,
    private router: Router,
    private ns: NotificationsService,
    private uploadService: UploadService
  ) {}

  ngOnInit() {
    this.loadUsers();
    this.setTableFilter();
    this.usersUpdatesub$ = this.uls.onUsersRefresh.subscribe(() => {
      this.setTableFilter();
    });
    this.rolesList = [
      {
        value: "Administrator",
        label: "Administrator",
      },
      {
        value: "Dienstleister",
        label: "Dienstleister",
      },
      {
        value: "Kunde",
        label: "Kunde",
      },
    ];

    this.disabledPaymentOptions = [];
  }

  ngOnDestroy() {
    this.usersUpdatesub$ && this.usersUpdatesub$.unsubscribe();
  }

  setTableFilter(): void {
    this.userDataSrc.filterPredicate = (object, filter) => {
      let flag = true;
      Object.keys(this.filterValues).forEach((key) => {
        if (!object[key] || (this.filterValues[key] && object[key].toLowerCase().indexOf(this.filterValues[key]) < 0)) {
          flag = false;
        }
      });
      return flag;
    };
  }

  /**
   * Opens user's detail view
   */
  openDetailPage(row) {
    this.currentUserData = row;
    this.curUserId = row.uid;
    this.cls.companiesList.forEach((val) => this.companiesListCopy.push(Object.assign({}, val)));
    this.uls.getUserDetails(this.curUserId).subscribe((user) => {
      if (user.disabledPaymentOptions) {
        this.disabledPaymentOptions = user.disabledPaymentOptions;
      }
      if (row["company"] && row["company"]["id"]) {
        this.curCompanyId = row["company"]["id"];
        this.selectedCompany = this.companiesListCopy.find((c) => c.cid === this.curCompanyId);
      } else {
        this.selectedCompany = undefined;
      }
      this.forwardEmails =
        this.currentUserData.forwardEmails?.length > 0
          ? [...this.currentUserData.forwardEmails]
          : [this.currentUserData.email];
      this.isInitForwardEmailsSave = typeof this.currentUserData.forwardEmails === "undefined";
      // The state should be changed only after the current user details are fetched.
      // Since we update forward email addresses on init of forwarding-email component, if we show user details before getting response, previously opened user's email is added to current user
      this.state = "detail-view";
    });
  }

  /**
   * Sets the pilot as verified (called by admin) after he(the pilot/service provider) uploads the required documents.
   */
  async setVerified(uidParam: string) {
    await this.uls
      .updateUser(uidParam, {
        pilotVerified: true,
        verificationNeeded: false,
        verificationFailed: false,
      })
      .then((userData) => {
        console.log("pilot verified successfully");
        this.success = true;
        this.currentUserData = userData;
        updateInTableDataSource(this.userDataSrc, uidParam, userData);
      })
      .catch((error) => {
        this.ns.showNotification("Unable to verify pilot", "danger");
        console.log("unable to verify pilot: ", error);
      });
    setTimeout(() => {
      this.success = false;
    }, 2000);
  }

  /**
   * Sets the emailVerfied as true in user document (called by admin)
   */
  async setEmailVerified(uid: string) {
    try {
      const userData = await this.auth.setEMailVerified(uid);
      console.log("email verfied successfully");
      this.successMessage = "E-Mail erfolgreich verifiziert!";
      this.showSuccessMessage = true;
      this.currentUserData = userData;
      updateInTableDataSource(this.userDataSrc, uid, userData);
    } catch (error) {
      this.ns.showNotification("Unable to verify email", "danger");
      console.log("unable to verify email: ", error);
    }

    setTimeout(() => {
      this.showSuccessMessage = false;
      this.successMessage = "";
    }, 2000);
  }
  /**
   *  Admin can delete the uploaded documents by users (service providers)
   */
  deleteDoc(doc: string, uid: string) {
    const confirmed = confirm(
      "Das Dokument wird aus dem Profil gelöscht und der Nutzer auf unverifiziert gesetzt. Er kann die Plattform dann nicht mehr nutzen, falls er vorher bereits verifiziert war. Bitte nur nutzen, wenn bei der anfänglichen Verifikation falsche oder ungültige Dokumente hochgeladen wurden! Nach dem Löschvorgang wird der Nutzer darüber informiert, dass die Verifikation gescheitert ist und er betreffende Dokumente neu hochladen muss."
    );
    if (confirmed) {
      let docName = "";
      const updatedDetails: any = {
        pilotVerified: false,
        verificationNeeded: false,
        verificationFailed: true,
      };
      switch (doc) {
        case "doc0":
          docName = this.currentUserData.verificationDocument0_file_name || "vertragliche-vereinbarung";
          updatedDetails.verificationDocument0 = "";
          updatedDetails.verificationDocument0_file_name = "";
          updatedDetails.verificationDocument0_file_size = "";
          break;
        case "doc1":
          docName = this.currentUserData.verificationDocument1_file_name || "haftpflicht";
          updatedDetails.verificationDocument1 = "";
          updatedDetails.verificationDocument1_file_name = "";
          updatedDetails.verificationDocument1_file_size = "";
          break;
        case "doc2":
          docName = this.currentUserData.verificationDocument2_file_name || "kenntnisnachweis";
          updatedDetails.verificationDocument2 = "";
          updatedDetails.verificationDocument2_file_name = "";
          updatedDetails.verificationDocument2_file_size = "";
          break;
        case "doc3":
          docName = this.currentUserData.verificationDocument3_file_name || "aufstiegsgenehmigung";
          updatedDetails.verificationDocument3 = "";
          updatedDetails.verificationDocument3_file_name = "";
          updatedDetails.verificationDocument3_file_size = "";
          break;
      }
      this.uploadService.deleteFile(`users/${uid}/${docName}`).subscribe(
        () => {
          this.uls
            .updateUser(uid, updatedDetails)
            .then((userData) => {
              console.log("User document details updated successfully");
              this.ns.showNotification("Successfully deleted the document", "success");
              this.currentUserData = userData;
            })
            .catch((error) => {
              this.ns.showNotification("Document deleted but the user details were not updated", "danger");
              console.log("User document details were not updated: ", error);
            });
        },
        (error) => {
          console.log("Could not delete the document");
          console.log(error);
          this.ns.showNotification("Could not delete the document", "danger");
        }
      );
    }
  }

  createNewUser(): void {
    this.auth.adminFlag = true;
    this.router.navigate(["/register"]);
  }

  updateFilterValues($event, column: string) {
    let value = $event.currentTarget?.["value"]?.trim()?.toLowerCase() || $event.value;
    if (this.keyValueQueryMap.has(column)) {
      this.keyValueQueryMap.delete(column);
    }
    if (value) {
      this.keyValueQueryMap.set(column, value);
    }

    this.filterTable($event, column);
  }

  filterTable(event: any, column: string): void {
    const query = event?.currentTarget?.value
      ? event?.currentTarget["value"].trim().toLowerCase()
      : <any>event?.value?.trim()?.toLowerCase();
    this.paginator.pageIndex = 0;
    this.filterValues[column] = query;
    this.loadUsers();
  }

  loadUsers(): void {
    this.uls
      .getAllUsersWithPaginator(
        this.paginator?.pageIndex,
        this.paginator?.pageSize,
        this.sort?.active,
        this.sort?.direction,
        this.keyValueQueryMap
      )
      .subscribe((userList) => {
        this.userDataSrc.data = userList.data;
        this.userCount = userList.total_hits;
      });
  }

  noSort(event: Event): void {
    event?.preventDefault();
    event?.stopPropagation();
  }

  changeCompany() {
    this.uls
      .changeCompany(this.curUserId, this.selectedCompany.cid)
      .then((userData) => {
        this.ns.showNotification("Successfully assigned the user to selected company", "success");
        this.currentUserData = userData;
        updateInTableDataSource(this.userDataSrc, this.curUserId, this.currentUserData);
      })
      .catch((error) => {
        this.ns.showNotification("Could not assign the user to selected company", "danger");
        console.log("Company could not be assigned: ", error);
      });
  }

  filterList(inputText) {
    this.companiesListCopy = this.cls.companiesList.filter(
      (company) => company.name.toLowerCase().indexOf(inputText.toLowerCase()) > -1
    );
  }

  goBack() {
    this.state = "default";
    this.userDataSrc.filter = "test";
  }

  addNoSortWhitespace($event) {
    this.noSort($event);
    $event.target.value += " ";
  }

  setDisabledPaymentOptions(paymentOption: PaymentOptionEnum) {
    if (!this.disabledPaymentOptions.includes(paymentOption))
      switch (paymentOption) {
        case this.paymentOptionEnum.Collective:
          this.disabledPaymentOptions.push(PaymentOptionEnum.Collective);
          break;
        case this.paymentOptionEnum.Sepa:
          this.disabledPaymentOptions.push(PaymentOptionEnum.Sepa);
          break;
        case this.paymentOptionEnum.Visa:
          this.disabledPaymentOptions.push(PaymentOptionEnum.Visa);
          break;
        case this.paymentOptionEnum.OnAccount:
          this.disabledPaymentOptions.push(PaymentOptionEnum.OnAccount);
          break;
      }
    else {
      this.disabledPaymentOptions = this.disabledPaymentOptions.filter((option) => option != paymentOption);
    }
  }

  async updateDisabledPaymentOptionsForUser() {
    this.isPaymentOptionUpdatePending = true;
    await this.uls.updateUser(this.curUserId, { disabledPaymentOptions: this.disabledPaymentOptions });
    this.isPaymentOptionUpdatePending = false;
    this.ns.showNotification("Die Zahlungsoptionen wurden erfolgreich gespeichert", "success");
  }
}
