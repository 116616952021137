import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmationResponseTypeEnum } from "@app/models/confirmation-response-type.enum";
import { ConfirmationModalDataInterface } from "@app/interfaces/confirmation-modal-data.interface";

@Component({
  selector: "app-imo-confirmation-modal",
  templateUrl: "./imo-confirmation-modal.component.html",
  styleUrls: ["./imo-confirmation-modal.component.css"],
})
export class ImoConfirmationModalComponent {
  modalData: ConfirmationModalDataInterface = <ConfirmationModalDataInterface>{};
  CONFIRMATION_RESPONSE_TYPE = ConfirmationResponseTypeEnum;

  constructor(@Inject(MAT_DIALOG_DATA) data: ConfirmationModalDataInterface) {
    this.modalData.title = data.title;
    this.modalData.message = data.message;
    this.modalData.continueButtonText = data.continueButtonText;
    this.modalData.cancelButtonText = data.cancelButtonText;
    this.modalData.iconPath = data.iconPath;
  }
}
