import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { LoaderService } from "@app/_services/loader.service";
import { AuthService } from "@app/auth/auth.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { RealestateService } from "@app/_services/realestate.service";
import { UsersService } from "@app/_services/users.service";
import { Router } from "@angular/router";
import { CONSTANTS } from "@app/util/constants";

@Component({
  templateUrl: "home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent {
  public emailResent = false;
  constants = CONSTANTS;

  constructor(
    public auth: AuthService,
    public ngxSmartModalService: NgxSmartModalService,
    public res: RealestateService,
    public router: Router
  ) {}
}
