import { Injectable } from "@angular/core";
import {
  ExternalRealEstateInformation,
  ExternalRealEstateTypeEnum,
  RealEstate,
  RealestateFloors,
} from "@app/interfaces/real-estate.interface";
import { AuthService } from "@app/auth/auth.service";
import { RealestateLogicService } from "@app/_services/realestate-logic.service";

@Injectable({
  providedIn: "root",
})
export class JllService {
  public companyIds = [
    "M5IYpTepB9g0b9P74poO",
    "OZthUnBReNW1F9Yqvai0", // JLL company Ids
  ];

  public floorsDropdown: RealestateFloors[] = [
    { name: "Gebäude", index: -100, createdOn: undefined },
    { name: "Mehrere Etagen/ Regelgeschoss", index: -99, createdOn: null },
    { name: "U6", index: -6, createdOn: undefined },
    { name: "U5", index: -5, createdOn: undefined },
    { name: "U4", index: -4, createdOn: undefined },
    { name: "U3", index: -3, createdOn: undefined },
    { name: "U2", index: -2, createdOn: undefined },
    { name: "U1", index: -1, createdOn: undefined },
    { name: "EG", index: 0, createdOn: undefined },
    { name: "1.OG", index: 1, createdOn: undefined },
    { name: "2.OG", index: 2, createdOn: undefined },
    { name: "3.OG", index: 3, createdOn: undefined },
    { name: "4.OG", index: 4, createdOn: undefined },
    { name: "5.OG", index: 5, createdOn: undefined },
    { name: "6.OG", index: 6, createdOn: undefined },
    { name: "7.OG", index: 7, createdOn: undefined },
    { name: "8.OG", index: 8, createdOn: undefined },
    { name: "9.OG", index: 9, createdOn: undefined },
    { name: "10.OG", index: 10, createdOn: undefined },
    { name: "11.OG", index: 11, createdOn: undefined },
    { name: "12.OG", index: 12, createdOn: undefined },
    { name: "13.OG", index: 13, createdOn: undefined },
    { name: "14.OG", index: 14, createdOn: undefined },
    { name: "15.OG", index: 15, createdOn: undefined },
    { name: "16.OG", index: 16, createdOn: undefined },
    { name: "17.OG", index: 17, createdOn: undefined },
    { name: "18.OG", index: 18, createdOn: undefined },
    { name: "19.OG", index: 19, createdOn: undefined },
    { name: "20.OG", index: 20, createdOn: undefined },
    { name: "21.OG", index: 21, createdOn: undefined },
    { name: "22.OG", index: 22, createdOn: undefined },
    { name: "23.OG", index: 23, createdOn: undefined },
    { name: "24.OG", index: 24, createdOn: undefined },
    { name: "25.OG", index: 25, createdOn: undefined },
    { name: "26.OG", index: 26, createdOn: undefined },
    { name: "27.OG", index: 27, createdOn: undefined },
    { name: "28.OG", index: 28, createdOn: undefined },
    { name: "29.OG", index: 29, createdOn: undefined },
    { name: "30.OG", index: 30, createdOn: undefined },
    { name: "31.OG", index: 31, createdOn: undefined },
    { name: "32.OG", index: 32, createdOn: undefined },
    { name: "33.OG", index: 33, createdOn: undefined },
    { name: "34.OG", index: 34, createdOn: undefined },
    { name: "35.OG", index: 35, createdOn: undefined },
    { name: "36.OG", index: 36, createdOn: undefined },
    { name: "37.OG", index: 37, createdOn: undefined },
    { name: "38.OG", index: 38, createdOn: undefined },
    { name: "39.OG", index: 39, createdOn: undefined },
    { name: "40.OG", index: 40, createdOn: undefined },
    { name: "41.OG", index: 41, createdOn: undefined },
    { name: "42.OG", index: 42, createdOn: undefined },
    { name: "43.OG", index: 43, createdOn: undefined },
    { name: "44.OG", index: 44, createdOn: undefined },
    { name: "45.OG", index: 45, createdOn: undefined },
    { name: "46.OG", index: 46, createdOn: undefined },
    { name: "47.OG", index: 47, createdOn: undefined },
    { name: "48.OG", index: 48, createdOn: undefined },
    { name: "49.OG", index: 49, createdOn: undefined },
    { name: "50.OG", index: 50, createdOn: undefined },
    { name: "51.OG", index: 51, createdOn: undefined },
    { name: "52.OG", index: 52, createdOn: undefined },
    { name: "53.OG", index: 53, createdOn: undefined },
    { name: "54.OG", index: 54, createdOn: undefined },
    { name: "55.OG", index: 55, createdOn: undefined },
    { name: "56.OG", index: 56, createdOn: undefined },
    { name: "57.OG", index: 57, createdOn: undefined },
    { name: "58.OG", index: 58, createdOn: undefined },
    { name: "59.OG", index: 59, createdOn: undefined },
    { name: "60.OG", index: 60, createdOn: undefined },
  ];

  public businessLineDropdown = [
    {
      businessLine: "Office Leasing",
      locationBusinessLine: [
        "Frankfurt",
        "Köln",
        "Düsseldorf",
        "Berlin",
        "Hamburg",
        "Hannover",
        "Stuttgart",
        "München",
        "Rhein Neckar",
        "Rhein Ruhr",
        "Leipzig",
        "Nürnberg",
      ],
    },
    {
      businessLine: "Industrial Agency",
      locationBusinessLine: [
        "Frankfurt",
        "Köln",
        "Düsseldorf",
        "Berlin",
        "Hamburg",
        "Hannover",
        "Stuttgart",
        "München",
        "Leipzig",
      ],
    },
    { businessLine: "Retail Leasing", locationBusinessLine: ["Ost", "West", "Nord", "Süd"] },
    { businessLine: "Smart Leasing", locationBusinessLine: ["Smart Leasing"] },
    {
      businessLine: "Others",
      locationBusinessLine: [
        "Frankfurt",
        "Köln",
        "Düsseldorf",
        "Berlin",
        "Hamburg",
        "Hannover",
        "Stuttgart",
        "München",
        "Rhein Neckar",
        "Rhein Ruhr",
        "Leipzig",
        "Nürnberg",
      ],
    },
  ];

  public emptyExternalRealestateInformation: ExternalRealEstateInformation = {
    type: ExternalRealEstateTypeEnum.JLL,
    data: {
      objectUID: "",
      buildingUID: "",
      businessLine: "",
      locationBusinessLine: "",
      responsible: "",
    },
  };

  selectedRealestate: RealEstate;
  isJLLCustomer: boolean;

  constructor(private auth: AuthService, private rels: RealestateLogicService) {
    if (this.checkJLLCustomer(this.auth.myUserObservable?.company?.id)) {
      this.isJLLCustomer = true;
    }
  }

  public checkJLLCustomer(customerCompanyId: string) {
    return this.companyIds.findIndex((companyId) => companyId === customerCompanyId) > -1;
  }

  search(a, v) {
    if (a[0]["index"] > v["index"]) {
      return 0;
    }
    let i = 1;
    while (i < a.length && !(a[i]["index"] > v["index"] && a[i - 1]["index"] <= v["index"])) {
      i = i + 1;
    }
    return i;
  }

  public addMultipleFloors(start: number, end: number, realEstate: RealEstate) {
    this.selectedRealestate = realEstate;
    let floor;
    for (let index = start; index <= end; index++) {
      floor = this.floorsDropdown.find((f) => f.index === index);
      this.addFloor(floor);
    }

    this.updateFloors();
    return this.selectedRealestate["floors"];
  }

  public addFloor(floor: RealestateFloors, realEstate?: RealEstate) {
    if (realEstate) {
      this.selectedRealestate = realEstate;
    }
    floor["createdOn"] = new Date();
    if (this.selectedRealestate.floors?.length) {
      const foundIndex = this.selectedRealestate.floors.findIndex((item) => item.index === floor.index);
      if (foundIndex === -1) {
        this.selectedRealestate.floors.splice(this.search(this.selectedRealestate.floors, floor), 0, floor);
      }
    } else {
      this.selectedRealestate.floors
        ? this.selectedRealestate.floors.push(floor)
        : (this.selectedRealestate["floors"] = [floor]);
    }
    if (realEstate) {
      this.updateFloors();
      return this.selectedRealestate["floors"];
    }
  }

  async updateFloors() {
    try {
      await this.rels.updateRealEstate(this.selectedRealestate).toPromise();
    } catch (err) {
      console.error(err);
    }
  }
}
