import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogImageFullsize } from "../imo-attachment-explorer/imo-attachment-explorer.component";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.css"],
})
export class ConfirmationDialogComponent implements OnInit {
  data;
  constructor(private dialogRef: MatDialogRef<DialogImageFullsize>, @Inject(MAT_DIALOG_DATA) data) {
    this.data = data;
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
  continue() {
    this.dialogRef.close("continue");
  }
}
