import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { OrderTypeResponsible } from '@app/interfaces/order-type-responsible.interface';
import { CONSTANTS } from '@app/util/constants';
import { Observable } from 'rxjs';
import { ConfigurationLogicService } from './configuration-logic.service';

@Injectable({
  providedIn: 'root'
})
export class OrderTypeResponsiblesConfigResolverService implements Resolve<OrderTypeResponsible[]> {

  constructor(
    private cs: ConfigurationLogicService
  ) { }

  resolve() {
    return <Observable<OrderTypeResponsible[]>>this.cs.getConfiguration(CONSTANTS.CONFIGURATIONS.ORDER_TYPE_RESPONSIBLES);
  }
}
