import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import * as moment from "moment";
import { UserRoleEnum } from "@app/models/user-role-list";
import { CustomerInteractionLogicService } from "@app/_services/customer-interaction-logic.service";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { CustomerInteractionTypeEnum } from "@app/models/customerInteractionType.enum";
import { MoovinMessageAuthorTypeEnum } from "@app/models/moovin-message-author-type.enum";
import { calculateBusinessDays, setBusinessHolidaysConfig } from "@app/util/helper";
import { OrderStatusEnum } from "@app/models/order-status-list";

@Component({
  selector: "app-status-dates",
  templateUrl: "./status-dates.component.html",
  styleUrls: ["./status-dates.component.css"],
})
export class StatusDatesComponent implements OnInit, OnChanges {
  @Input("suborder") suborder: SubOrder;
  @Input("role") role: any;
  @Input("iteration") iteration: number;
  @Input() isFeedbackLoop: boolean;

  delayed = false;
  delayDays = 0;
  statusDates: any;
  userRoleEnum = UserRoleEnum;
  hasCustomerInteraction = false;
  showCustomerInteractions = false;
  customerInteractionType: CustomerInteractionTypeEnum;
  CustomerInteractionTypeEnum = CustomerInteractionTypeEnum;
  isCustomerActionRequired: boolean;

  constructor(public dcls: CustomerInteractionLogicService) {}

  ngOnInit() {
    this.dcls.customerInteractionsOpened.subscribe(() => {
      this.showCustomerInteractions = true;
    });

    if (this.suborder.orderType === OrderTypeEnum.Energy_Pass) {
      // Show customer interaction if approval is requested or moovin has sent a message
      if (
        this.suborder.moovinStatus?.approvalRequestedOn ||
        this.dcls.moovinCustomerInteraction.customerInteractions.length
      ) {
        this.hasCustomerInteraction = true;
        const noCustomerInteractions = this.dcls.moovinCustomerInteraction.customerInteractions.length;
        const curCustomerInteraction =
          this.dcls.moovinCustomerInteraction.customerInteractions?.[noCustomerInteractions - 1];
        if (
          curCustomerInteraction?.messages?.[curCustomerInteraction?.messages?.length - 1].author ===
          MoovinMessageAuthorTypeEnum.MOOVIN
        )
          // Latest message comes from moovin.
          this.showCustomerInteractions = true;
      } else {
        this.hasCustomerInteraction = false;
      }
      this.customerInteractionType = CustomerInteractionTypeEnum.MOOVIN;
    }

    if (
      this.suborder.orderType === OrderTypeEnum.Documents_Procurement &&
      this.dcls.docEstateCustomerInteraction &&
      this.dcls.docEstateCustomerInteraction.customerInteractions?.length
    ) {
      this.hasCustomerInteraction = true;
      this.customerInteractionType = CustomerInteractionTypeEnum.DOC_ESTATE;
      if (this.dcls.docEstateCustomerInteraction.customerActionRequired) {
        this.showCustomerInteractions = true;
      }
    }
    if (this.suborder.statusDates["feedbacks"]) {
      if (
        this.suborder.statusDates["feedbacks"][this.iteration] &&
        this.suborder.statusDates["feedbacks"][this.iteration]["estimatedDelivery"]
      ) {
        let currentDate: moment.Moment = moment().startOf("day");
        this.statusDates = this.suborder.statusDates["feedbacks"][this.iteration];
        let estimatedMoment: moment.Moment = moment(this.statusDates["estimatedDelivery"]).startOf("day");
        if (currentDate.diff(estimatedMoment, "days") > 0 && !this.statusDates["delivery"]) {
          this.delayed = true;
        }
        if (this.statusDates["delivery"]) {
          let deliveredMoment: moment.Moment = moment(this.statusDates["delivery"]);
          const convertedEstimatedDate = estimatedMoment.toDate();
          const convertedDeliveredDate = deliveredMoment.toDate();
          setBusinessHolidaysConfig(convertedEstimatedDate.getFullYear(), convertedDeliveredDate.getFullYear());
          this.delayDays = calculateBusinessDays(convertedEstimatedDate, convertedDeliveredDate);
        }
      } else {
        this.statusDates = {};
      }
      this.statusDates.created = this.suborder.statusDates.created;
      this.statusDates.processed = this.suborder.statusDates.processing;
      this.statusDates.completed = this.suborder.statusDates.completed;
      this.updateIsCustomerActionRequired();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateIsCustomerActionRequired();
  }

  updateIsCustomerActionRequired() {
    this.isCustomerActionRequired =
      this.dcls?.moovinCustomerInteraction?.customerActionRequired &&
      this.suborder.status !== OrderStatusEnum.Completed;
  }

  toggleCustomerInteractionsView() {
    this.showCustomerInteractions = !this.showCustomerInteractions;
  }

  protected readonly OrderStatusEnum = OrderStatusEnum;
}
