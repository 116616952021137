<ngx-smart-modal
  #confirmSetPaidModal
  [closable]="true"
  [dismissable]="true"
  [escapable]="true"
  identifier="confirmSetPaidModal"
>
  <!-- Modal Title -->
  <h3 class="card-title b">Fortfahren?</h3>
  <hr />
  <div>
    Hierdurch werden alle Positionen der Rechnung in den verknüpften Bestellungen als bezahlt markiert. Wenn alle
    Suborders einer Bestellung bezahlt sind, wird auch die (Haupt-)Order automatisch als bezahlt markiert.
  </div>
  <div>
    <span class="text-danger"><b>Achtung:</b></span> Verknüpfte Rechnungspositionen / Orders werden zukünftig nicht
    automatisch abgerechnet.
  </div>
  <div class="text-danger">
    Die Funktion sollte nur bei Rechnungen, die außerhalb der Plattform verwaltet werden, verwendet werden.
  </div>
  <div class="text-danger">
    IdR besitzen diese Rechnungen den Status storniert auf der Plattform und werden manuell über Lexoffice verschickt
  </div>
  <div *ngIf="!isSetPaidConfirmed">
    <button class="btn btn-primary" (click)="setPositionsPaid(currentInvoiceData.id)">
      Rechnungspositionen als bezahlt markieren.
    </button>
  </div>

  <div *ngIf="isSetPaidConfirmed">
    <span *ngIf="!setPaidResult?.length">
      Bitte warten... <mat-spinner [color]="'primary'" [diameter]="10"></mat-spinner>
    </span>
    <ul>
      <li *ngFor="let info of setPaidResult">
        <span *ngIf="setPaidResult.length">
          <div><b>SuborderId:</b> {{ info.suborderId }}</div>
          <div>
            <b>Vollständig bezahlt?: </b>
            <span *ngIf="info.isFullyPaid"> <i class="fas fa-check-circle text-success"></i></span>
            <span *ngIf="!info.isFullyPaid"> <i class="fas fa-times-circle text-danger"></i></span>
          </div>
          <div *ngIf="info.failedPositions.length">
            Fehler:
            <span *ngFor="let failedPosition; in: info.failedPositions">
              {{ failedPosition }} <i class="fas fa-times-circle text-danger"></i>
            </span>
          </div>
          <div *ngIf="!info.failedPositions.length">Keine Fehler <i class="fas fa-check-circle text-success"></i></div>
        </span>
      </li>
    </ul>
  </div>
</ngx-smart-modal>

<ng-container *ngIf="state === 'default'">
  <div class="card cgrid" *ngIf="role === userRoleEnum.Administrator">
    <div class="card-body">
      <button class="btn btn-primary" (click)="exportStoppedDunningsInvoices()">Pausierte Mahnungen exportieren</button>
    </div>
  </div>

  <!-- Show User list in a card -->
  <div class="card cgrid">
    <!-- Display the table inside a card -->
    <div *ngIf="role === userRoleEnum.Customer" class="card-header">
      <mat-button-toggle-group
        *ngIf="companyRole === companyRoleEnum.CompanyManager || companyRole === companyRoleEnum.CompanyAccountant"
        [value]="selectedListViewId"
        (change)="onListViewChange($event.value, role)"
      >
        <mat-button-toggle [value]="listView.id" *ngFor="let listView of listViewList"
          >{{ listView.name }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="card-body">
      <div class="mat-elevation-z8 material-table">
        <table mat-table [dataSource]="invoiceDataSrc" matSort (matSortChange)="loadInvoices()">
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">
              <div class="row">
                <div class="col-12">Name</div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Suchen"
                    (click)="noSort($event)"
                    (input)="updateFilterValues($event, 'name')"
                    (keydown.space)="addNoSortWhitespace($event)"
                  />
                </div>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" width="15%">{{ row.billingAddress.name }}</td>
          </ng-container>

          <!-- Number Column -->
          <ng-container matColumnDef="lexOfficeInvoiceNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">
              <div class="row">
                <div class="col-12">Rechnungsnummer</div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Suchen"
                    (click)="noSort($event)"
                    (input)="updateFilterValues($event, 'lexOfficeInvoiceNumber')"
                    (keydown.space)="addNoSortWhitespace($event)"
                  />
                </div>
              </div>
            </th>
            <ng-container *matCellDef="let row">
              <td *ngIf="row.lexOfficeInvoiceNumber" mat-cell width="15%">{{ row.lexOfficeInvoiceNumber }}</td>
              <td *ngIf="!row.lexOfficeInvoiceNumber" mat-cell width="15%">--</td>
            </ng-container>
          </ng-container>

          <!-- Company Column -->
          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">
              <div class="row">
                <div class="col-12">Unternehmen</div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Suchen"
                    (click)="noSort($event)"
                    (input)="updateFilterValues($event, 'company')"
                    (keydown.space)="addNoSortWhitespace($event)"
                  />
                </div>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" width="15%">{{ row.billingAddress.company }}</td>
          </ng-container>

          <!-- Price Column -->
          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">
              <div class="row">
                <div class="col-12">Summe</div>
                <div class="col-12">
                  <!-- TODO :: ES not allowing filter on long type field, need to update API -->
                  <!-- <input
                    type="text"
                    class="form-control"
                    placeholder="Suchen"
                    (click)="noSort($event)"
                    (input)="updateFilterValues($event, 'total')"
                  /> -->
                </div>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" width="15%">{{ gs.format(row.total) }} €</td>
          </ng-container>

          <!-- CreatedOn Column -->
          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">
              <div class="row">
                <div class="col-12">Rechnungsdatum</div>
                <div class="col-12">
                  <!-- TODO :: Need a date picket here because of ES date format -->
                  <!-- <input
                    type="text"
                    class="form-control"
                    placeholder="Suchen"
                    (click)="noSort($event)"
                    (input)="updateFilterValues($event, 'createdOn')"
                  /> -->
                </div>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" width="15%">
              {{ row.createdOn ? (row.createdOn._seconds * 1000 | date : "dd.MM.yyyy") : "" }}
            </td>
          </ng-container>

          <!-- DueDate Column -->
          <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">
              <div class="row">
                <div class="col-12">Fälligkeitsdatum</div>
                <div class="col-12">
                  <!-- TODO :: Need a date picket here because of ES date format -->
                  <!-- <input
                    type="text"
                    class="form-control"
                    placeholder="Suchen"
                    (click)="noSort($event)"
                    (input)="updateFilterValues($event, 'dueDate')"
                  /> -->
                </div>
              </div>
            </th>
            <td mat-cell [ngClass]="row.dueDate | deliveryAlarm" *matCellDef="let row" width="15%">
              {{ row.dueDate ? (row.dueDate._seconds * 1000 | date : "dd.MM.yyyy") : "" }}
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%" class="filter-select">
              <div class="row">
                <div class="col-12">Status</div>
                <div class="col-12">
                  <mat-select
                    class="form-control"
                    (click)="noSort($event)"
                    (selectionChange)="updateFilterValues($event, 'status')"
                  >
                    <mat-option [value]="">zurücksetzen</mat-option>
                    <mat-option *ngFor="let entry of statusList" [value]="entry.value">
                      {{ entry.label }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" width="20%">
              <span [ngSwitch]="row.status">
                <span class="text-warning" *ngSwitchCase="paymentStatusEnum.Invoice"> Rechnung erstellt </span>
                <span class="text-success" *ngSwitchCase="paymentStatusEnum.Paid"> Vollständig bezahlt </span>
                <span class="text-danger" *ngSwitchCase="paymentStatusEnum.Canceled"> Rechnung storniert </span>
                <span class="text-danger" *ngSwitchCase="paymentStatusEnum.Underpaid"> Rechnung angezahlt </span>
                <span class="text-danger" *ngSwitchCase="paymentStatusEnum.Overpaid">
                  Rechnung überbezahlt - Rückerstattung prüfen.
                </span>
                <span class="text-danger" *ngSwitchCase="paymentStatusEnum.Prepayment"> Anzahlung erhalten </span>
              </span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay" (click)="openDetailPage(row)"></tr>
        </table>
        <mat-paginator
          #paginator
          [pageSizeOptions]="[10, 20, 50]"
          [length]="invoiceCount"
          (page)="loadInvoices()"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </div>
</ng-container>

<!-- Detailed Page for each user -->
<ng-container *ngIf="state === 'detail-view'">
  <ng-container>
    <!-- <ng-container *ngIf="currentInvoiceData | async as invoice; else loading"> -->
    <div class="shadow card fluid cgrid">
      <div class="card-header">
        <button class="btn btn-sm btn-back float-right" (click)="openListView()">Zurück</button>

        <!-- Display some general user details in the header -->
        <h5>
          Rechnung <span *ngIf="currentInvoiceData.number">({{ currentInvoiceData.number }}) </span>vom
          {{
            currentInvoiceData.createdOn ? (currentInvoiceData.createdOn?._seconds * 1000 | date : "dd.MM.yyyy") : ""
          }}, {{ currentInvoiceData.billingAddress.name }}
        </h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            <p>
              <b>Rechnungsnummer: {{ currentInvoiceData.lexOfficeInvoiceNumber }}</b>
              <a
                target="_blank"
                class="text-primary"
                [href]="firebaseBaseURL + currentInvoiceData.id"
                (click)="$event.stopPropagation()"
                *ngIf="role === userRoleEnum.Administrator"
                ><i
                  class="fas fa-fire text-warning clickable ml-2"
                  [tooltip]="'In Firebase öffnen'"
                  [adaptivePosition]="false"
                ></i>
              </a>

              <a
                target="_blank"
                class="text-warning"
                [href]="constants.LEXOFFICE_BASE_URL + currentInvoiceData.lexOfficeId"
                *ngIf="role === userRoleEnum.Administrator"
                ><i
                  class="fa fa-times text-warning clickable ml-2"
                  [tooltip]="'In Lexoffice öffnen'"
                  [adaptivePosition]="false"
                ></i>
              </a>
            </p>
            <p>Name: {{ currentInvoiceData.billingAddress.name }}</p>
            <p>
              Email:
              <a
                *ngIf="currentInvoiceData.number"
                href="{{
                  'mailto:' + currentInvoiceData.billingAddress.email + '?subject=' + currentInvoiceData.number
                }}"
                >{{ currentInvoiceData.billingAddress.email }}</a
              >
              <a *ngIf="!currentInvoiceData.number" href="{{ 'mailto:' + currentInvoiceData.billingAddress.email }}">{{
                currentInvoiceData.billingAddress.email
              }}</a>
            </p>
            <p>Unternehmen: {{ currentInvoiceData.billingAddress.company }}</p>
            <p>
              Adresse: {{ currentInvoiceData.billingAddress.street }} {{ currentInvoiceData.billingAddress.number }},
              {{ currentInvoiceData.billingAddress.postalCode }} {{ currentInvoiceData.billingAddress.city }}
            </p>

            <p>Immobilien: {{ currentInvoiceData.realestateNames }}</p>
            <p *ngIf="role === userRoleEnum.Administrator">Bestellungen: {{ currentInvoiceData.orderIds }}</p>
            <p *ngIf="role === userRoleEnum.Administrator">(Zahl-)Typ: {{ currentInvoiceData.invoiceType }}</p>
            <p *ngIf="role === userRoleEnum.Administrator">Intervall: {{ currentInvoiceData.invoiceInterval }}</p>
            <p>
              Status:
              <span [ngSwitch]="currentInvoiceData.status">
                <span class="text-warning" *ngSwitchCase="paymentStatusEnum.Invoice"> Rechnung erstellt </span>
                <span class="text-success" *ngSwitchCase="paymentStatusEnum.Paid"> Zahlung eingegangen </span>
                <span class="text-danger" *ngSwitchCase="paymentStatusEnum.Canceled">
                  Rechnung storniert
                  <span class="text-success" *ngIf="currentInvoiceData.isMarkedAsPaid"> Extern bezahlt</span>
                </span>
                <span class="text-warning" *ngSwitchCase="paymentStatusEnum.Underpaid">
                  Bezahlte Rechnungssumme abweichend - Offene Forderungen
                </span>
                <span class="text-warning" *ngSwitchCase="paymentStatusEnum.Overpaid">
                  Bezahlte Rechnungssumme abweichend - Offene Rückzahlung an Kunden
                </span>
              </span>
            </p>
            Zahlungsziel:
            <span class="text-success" [ngClass]="currentInvoiceData.dueDate | deliveryAlarm">
              {{
                currentInvoiceData.dueDate ? (currentInvoiceData.dueDate?._seconds * 1000 | date : "dd.MM.yyyy") : ""
              }}
            </span>

            <div class="row" *ngIf="role === userRoleEnum.Administrator">
              <div class="col-8">
                <mat-form-field class="mw-100" appearance="fill">
                  <mat-label>Neues Zahlungsziel</mat-label>
                  <input readonly matInput [matDatepicker]="picker" [(ngModel)]="newDueDate" />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-4">
                <button matSuffix class="btn btn-primary" tooltip="Speichern" (click)="updateDueDate()">
                  <i class="fas fa-save"></i>
                </button>
              </div>
            </div>
            <p>
              <mat-checkbox
                #dunningsStoppage
                color="primary"
                [checked]="currentInvoiceData.hasDunningsStopped"
                (click)="toggleDunningsStoppage($event)"
              >
                <span>Mahnungsstopp</span>
              </mat-checkbox>
            </p>
          </div>
          <div class="col-8">
            <table class="invoice-table">
              <thead>
                <tr>
                  <th>Artikel</th>
                  <th>Auftrags-PIN</th>
                  <th>Anzahl</th>
                  <th>Preis</th>
                  <th>Rabatt</th>
                  <th>Gesamt</th>
                  <th *ngIf="role === userRoleEnum.Administrator">Bezahlt</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of currentInvoiceData.invoice_details">
                  <tr class="suborder-line">
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ item.suborderId | shorten : 6 : true : true | uppercase }}
                    </td>
                    <td>
                      {{ item.quantity }}
                    </td>
                    <td>{{ gs.format(item.total) }} €</td>
                    <td>
                      <span *ngIf="item.discount_type !== '€'"
                        >{{ (item.discount || 0) * 100 | number : "1.0-2" }} %</span
                      >
                      <span *ngIf="item.discount_type === '€'">{{ item.discount }} €</span>
                    </td>
                    <td>
                      <span>{{ item.billed_amount | number : "1.2-2" }} €</span>
                    </td>
                    <td *ngIf="role === userRoleEnum.Administrator">{{ gs.format(item.paid_amount) }} €</td>
                  </tr>
                </ng-container>
                <tr class="suborderSumLine">
                  <td colspan="2">
                    <b>Gesamtpreis</b>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>{{ gs.format(currentInvoiceData.total) }} €</b>
                  </td>
                  <td></td>
                </tr>
                <tr *ngIf="currentInvoiceData.status !== paymentStatusEnum.Canceled">
                  <td colspan="4"></td>
                  <td colspan="2" *ngIf="currentInvoiceData.status !== paymentStatusEnum.Overpaid">
                    <b>Offen</b> (netto)
                  </td>
                  <td colspan="2" *ngIf="currentInvoiceData.status === paymentStatusEnum.Overpaid">
                    <b>Zu Erstatten</b>
                  </td>
                  <td>{{ openAmount | number : "1.2-2" }} €</td>
                </tr>
                <tr *ngIf="currentInvoiceData.status !== paymentStatusEnum.Canceled">
                  <td colspan="4">
                    <span *ngIf="isTaxFreeInvoice"
                      >Umsatzsteuer entfällt (Rechnungsadresse in {{ currentInvoiceData.billingAddress.country }})</span
                    >
                  </td>
                  <td colspan="2"><b [ngClass]="{ 'text-decoration-line-through': isTaxFreeInvoice }">+19% USt.</b></td>
                  <td>{{ isTaxFreeInvoice ? 0 : (openAmount * 0.19 | number : "1.2-2") }} €</td>
                </tr>
                <tr *ngIf="currentInvoiceData.status !== paymentStatusEnum.Canceled">
                  <td colspan="4"></td>
                  <td colspan="2" *ngIf="currentInvoiceData.status !== paymentStatusEnum.Overpaid">
                    <b>Offen</b> (gesamt)
                  </td>
                  <td>{{ (isTaxFreeInvoice ? openAmount : openAmount * 1.19) | number : "1.2-2" }} €</td>
                </tr>
              </tbody>
            </table>
            <br />
            <ng-container *ngIf="role === userRoleEnum.Administrator">
              <div class="row">
                <div class="col-12" *ngIf="currentInvoiceData.status === paymentStatusEnum.Invoice">
                  <button matSuffix class="btn btn-sm btn-warning" (click)="openInLexoffice()">
                    In Lexoffice stornieren
                  </button>
                </div>
              </div>
              <ng-container
                *ngIf="
                  currentInvoiceData.status === paymentStatusEnum.Canceled ||
                  currentInvoiceData.status === paymentStatusEnum.Invoice
                "
              >
                <div *ngIf="currentInvoiceData.status === paymentStatusEnum.Canceled">
                  Hinweis: Die Rechnung wurde storniert.
                </div>
                <button
                  class="btn btn-sm btn-primary"
                  (click)="openSetPaidModal()"
                  *ngIf="!currentInvoiceData.isMarkedAsPaid"
                >
                  Rechnungspositionen als bezahlt markieren.
                </button>
                <div *ngIf="currentInvoiceData.isMarkedAsPaid">
                  Hinweis: Die Positionen dieser Rechnung wurden als bezahlt markiert.
                </div>
              </ng-container>
              <button
                *ngIf="currentInvoiceData.status === paymentStatusEnum.Paid"
                class="btn btn-sm btn-muted"
                disabled
              >
                Rechnung wurde bezahlt
              </button>
            </ng-container>
          </div>
        </div>
        <div class="row" *ngIf="currentInvoiceData.dunnings?.length">
          <div class="col-12">
            <h3>Mahnungen</h3>
          </div>

          <div class="col-12" *ngFor="let dunning of currentInvoiceData.dunnings">
            Datum: {{ dunning.createdOn | date : "dd.MM.yyyy" }} - <a [href]="dunning.downloadURL">download</a> ({{
              dunningTypeLabels[dunning.type]
            }})
          </div>
        </div>
        <br />

        <br />
        <div *ngIf="role === userRoleEnum.Administrator" class="row">
          <div class="col-12" *ngIf="!currentInvoiceData.isExcelInvoice">
            <!-- Don't show upload fields while uploading -->
            <ng-container class="mb-2" *ngIf="uploading === false; else loading">
              <p *ngIf="!currentInvoiceData.fileURL">Bitte nur eine PDF Datei hochladen!</p>

              <div class="mb-4 form-group row uploadArea">
                <div *ngIf="!currentInvoiceData.fileURL" class="col-12 col-xl-4 buttonUpload">
                  <label class="col-12">
                    <div class="row fileContainer text-center align-items-center">
                      <i class="material-icons col-12">cloud_upload</i>
                      <div class="col-12">
                        <span class="btn btn-dark btn-sm pl-2 pr-2 clickable">Datei hochladen</span>
                      </div>
                    </div>
                    <input
                      type="file"
                      class="alert alert-info"
                      (change)="uploadDocument($event, 'Rechnung', currentInvoiceData.id)"
                    />
                  </label>
                </div>
                <a class="btn btn-primary" target="_blank" rel="noopener" href="{{ currentInvoiceData.fileURL }}">
                  <i class="far fa-file-pdf"></i> - Rechnung herunterladen
                </a>
                <div class="col-12 col-md-4 col-lg-3 text-right logo-delete-icon">
                  <i (click)="deleteInvoice($event)" class="fas fa-trash align-bottom clickable"></i>
                </div>
                <div *ngIf="currentInvoiceData.fileURL" class="row col-12 align-bottom align-text-bottom pd-2">
                  <pdf-viewer
                    [autoresize]="true"
                    [original-size]="false"
                    [page]="1"
                    [render-text]="false"
                    [src]="currentInvoiceData.fileURL"
                    [zoom-scale]="'page-fit'"
                    class="hide-overflow"
                  ></pdf-viewer>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-12" *ngIf="currentInvoiceData.isExcelInvoice">
            <a [href]="currentInvoiceData.excelUrl" target="_blank" *ngIf="currentInvoiceData.excelUrl"
              >Excel Invoice Reporting herunterladen</a
            >
            <b *ngIf="!currentInvoiceData.excelUrl">Fehler: Kein Excel Reporting gefunden.</b>
          </div>
        </div>
        <div *ngIf="role === userRoleEnum.Customer" class="row">
          <div *ngIf="currentInvoiceData.fileURL" class="col-12 align-bottom align-text-bottom pd-2">
            <a class="btn btn-primary" target="_blank" rel="noopener" href="{{ currentInvoiceData.fileURL }}">
              <i class="far fa-file-pdf"></i> - Rechnung herunterladen
            </a>
            <pdf-viewer
              [autoresize]="true"
              [original-size]="false"
              [page]="1"
              [render-text]="false"
              [src]="currentInvoiceData.fileURL"
              [zoom-scale]="'page-fit'"
              class="hide-overflow"
            ></pdf-viewer>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<!-- CSS Loading Spinner -->
<ng-template #loading>
  <div class="lds-ring ring-small">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-template>
