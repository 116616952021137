<div class="shadow card cgrid">
  <div class="card-header">
    <a class="my-link clickable" (click)="active = !active" [attr.aria-expanded]="active" aria-controls="collapse1">
      <h5 class="card-title text-left wsn">Aerial // Extended</h5>
    </a>
  </div>
  <div [collapse]="!active">
    <div class="card-body">
      <p class="card-text">Wählt der Kunde keine speziellen Perspektiven und Höhen für die Luftaufnahmen aus,
        beinhaltet das Extended Paket folgende Aufnahmen für den Kunden:</p>
      <div class="row">
        <div class="col-sm-5">
          <p><strong>Luftaufnahmen:</strong></p>
          <ul>
            <li>1. Seitenansicht <strong>(P1, H1)</strong></li>
            <li>2. Seitenansicht <strong>(P1, H2)</strong></li>
            <li>3. Seitenansicht <strong>(P3, H2)</strong></li>
            <li>4. Seitenansicht <strong>(P5, H2)</strong></li>
            <li>5. Seitenansicht <strong>(P7, H2)</strong></li>
            <li>6. Draufsicht <strong>H2</strong></li>
            <li>7. Draufsicht <strong>H9</strong></li>
          </ul>
        </div>
        <div class="col-4">
          <img class="img-fluid" src="../../assets/images/perspektiven.png" style="max-width: 195px;">
        </div>
        <div class="col-3">
          <img class="img-fluid" src="../../assets/images/draufsicht.png" style="max-width: 140px;">
        </div>
      </div>
      <p class="card-text">Der Winkel und die Flughöhe sollen so gewählt werden, dass die Immobilie in einer
        vorteilhaften Perspektive gezeigt wird und keine Teile der Immobilie abgeschnitten werden. Die optimale
        Flughöhe bei Wohnhäusern liegt zwischen 20-30 Metern und bei Mehrfamilienhäusern zwischen 30-40 Metern.</p>
      <p class="card-text mb-0 text-danger"><strong>Wichtig!</strong> Die Haustür entspricht immer
        der Perspektive P1.</p>
    </div>
  </div>
</div>
<div class="shadow card cgrid">
  <div class="card-header">
    <a class="my-link clickable" (click)="active2 = !active2" [attr.aria-expanded]="active2" aria-controls="collapse2">
      <h5 class="card-title text-left wsn">Beispiele Seitenansichten</h5>
    </a>
  </div>
  <div [collapse]="!active2">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-4">
          <img class="img-fluid" src="../../assets/images/seitenansicht.png">
        </div>
        <div class="col-8">
          <img class="img-fluid" src="../../assets/images/beispiele_seitenansicht.jpeg">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="shadow card cgrid">
  <div class="card-header">
    <a class="my-link clickable" (click)="active3 = !active3" [attr.aria-expanded]="active3" aria-controls="collapse2">
      <h5 class="card-title text-left wsn">Beispiele Draufsichten</h5>
    </a>
  </div>
  <div [collapse]="!active3">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-4">
          <img class="img-fluid" src="../../assets/images/draufsicht1.png">
        </div>
        <div class="col-8">
          <img class="img-fluid" src="../../assets/images/beispiele_draufsicht.jpeg">
        </div>
      </div>
    </div>
  </div>
</div>