import { Injectable } from "@angular/core";
import { ApisService } from "@app/_services/apis.service";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { CONSTANTS } from "@app/util/constants";

@Injectable({
  providedIn: "root",
})
export class MoovinLogicService {
  constructor(private apis: ApisService) {}

  /**
   * Calls the create-order order method for moovin in our backend
   * @param suborderId imogent suborderId
   */
  createOrder(suborderId: string) {
    return this.apis.callPost(CONSTANTS.EXTERNAL_APIs.MOOVIN + "/create-order/" + suborderId, {}).toPromise();
  }

  /**
   * Accept draft
   * @param suborderId imogent suborderId
   */
  acceptDraft(suborderId: string) {
    return this.apis.callPost(CONSTANTS.EXTERNAL_APIs.MOOVIN + "/accept-draft/" + suborderId, {}).toPromise();
  }
}
