import {animate, animation, group, sequence, style, transition, trigger, useAnimation} from '@angular/animations';

/**
 * Fade in
 */
export const fadeInTransition = trigger('fade-in-transition', [
    transition(':enter', [
        style({opacity: 0, height: 0}),
        group([
            animate('150ms linear', style({opacity: 1})),
            animate('300ms ease-out', style({height: '*'}))
        ])
    ]),
])
/**
 * Fade in and out
 */
export const fadeInOutTransition = trigger('fade-in-out-transition', [
    transition(':enter', [
        style({opacity: 0}),
        animate('150ms linear', style({opacity: 1}))
    ]),
    transition(':leave', [
        style({opacity: 1}),
        animate('150ms linear', style({opacity: 0}))
    ])
])
/**
 * Fade in and out and grow/shrink in height
 */
export const fadeInOutHeightTransition = trigger('fade-in-out-height-transition', [
    transition(':enter', [
        style({opacity: 0, height: 0}),
        group([
            animate('150ms linear', style({opacity: 1})),
            animate('250ms ease', style({height: '*'}))
        ])

    ]),
    transition(':leave', [
        style({opacity: 1, height: '*'}),
        group([
            animate('150ms linear', style({opacity: 0})),
            animate('250ms ease', style({height: 0}))
        ])

    ])
])

/**
 * Sliding in and out animations
 */
const slideInAnimation = animation([
        style({opacity: 0, transform: '{{ slideFrom }}', height: '0'}),
        group([
            animate('650ms ease', style({transform: 'translate(0,0)'})),
            animate('650ms linear', style({opacity: 1})),
            animate('350ms ease', style({height: '*'}))
        ]),
    ]
);
const slideOutAnimation = animation([
    style({height: '*'}),
    group([
        animate('650ms cubic-bezier(.8, -0.6, 0.2, 1.5)', style({transform: '{{ slideTo }}'})),
        animate('650ms linear', style({opacity: 0})),
        animate('350ms ease', style({height: '0', padding: '0', margin: '0'}))
    ])
]);
export const slideXRightTransition = trigger('slide-x-right-transition', [
    transition(':enter', useAnimation(slideInAnimation, {
        params: {
            slideFrom: 'translateX(40px)'
        }
    })),
    transition(':leave', useAnimation(slideOutAnimation, {
        params: {
            slideTo: 'translateX(40px)'
        }
    }))
]);
export const slideXLeftTransition = trigger('slide-x-left-transition', [
    transition(':enter', [
        style({opacity: 0, transform: 'translate(-40px,0)'}),
        group([
            animate('650ms ease', style({transform: 'translate(0,0)'})),
            animate('650ms linear', style({opacity: 1})),
        ]),
    ]),
    transition(':leave', [
        style({opacity: 1, position: 'absolute', top:0}),
        group([
            animate('650ms ease', style({transform: 'translate(-40px,0)'})),
            animate('650ms linear', style({opacity: 0})),
        ]),
    ])
]);

export const slideYUpTransition = trigger('slide-y-up-transition', [
    transition(':enter', useAnimation(slideInAnimation, {
        params: {
            slideFrom: 'translateY(40px)'
        }
    })),
    transition(':leave', useAnimation(slideOutAnimation, {
        params: {
            slideTo: 'translateY(40px)'
        }
    }))
]);
export const slideYDownTransition = trigger('slide-y-down-transition', [
    transition(':enter', useAnimation(slideInAnimation, {
        params: {
            slideFrom: 'translateY(-40px)'
        }
    })),
    transition(':leave', useAnimation(slideOutAnimation, {
        params: {
            slideTo: 'translateY(-40px)'
        }
    }))
]);

