
<mat-form-field class="example-full-width">
  <mat-label>Filter</mat-label>
  <input matInput placeholder="" value="" (input)="filterUserList($any($event.target).value)">
</mat-form-field>
<div style="overflow-y: scroll; height:400px;">
  <mat-radio-group aria-label="Select a user">
    <p *ngFor="let user of filteredUserList">
      <mat-radio-button [value]="user" (change)="selectionChanged($event)">{{user.lastName}}, {{user.firstName}}, {{user.email}}</mat-radio-button>
    </p>
  </mat-radio-group>
</div>

<div class="font-small-400">
  gewählter Nutzer: {{selectedUser?.firstName || ''}} {{selectedUser?.lastName || ''}}
  <button [disabled]="selectionConfirmed" class="btn btn-sm btn-primary float-right" (click)="confirmSelection()">Bestätigen</button>
</div>
