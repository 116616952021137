<div class="col-12 info-box p-2 rounded warning-background-light mb-3" [hidden]="!errorFilesList.length">
  <div class="d-flex flex-row">
    <div>
      <i class="fas fa-exclamation-triangle warning-text mr-2 font-size-large"></i>
    </div>
    <div class="text-justify warning-text">
      <!-- maxFileSize & file Type Error -->
      <p
        class="mb-1"
        *ngIf="
          errorTypeList.includes(UploadErrorTypeEnum.MIME_TYPE) && errorTypeList.includes(UploadErrorTypeEnum.FILE_SIZE)
        "
      >
        Bitte laden Sie Dateien hoch, die kleiner als {{ uploader.options.maxFileSize | bytes }} sind und ein gültiges
        Format haben.
        <a href="{{ CONSTANTS.PDF_EDITOR_APP_LINK }}" class="warning-text font-weight-bold" target="_blank">
          <u>Hier</u>
        </a>
        können Sie die Dateigröße reduzieren. {{ extraErrorInfo }}
      </p>

      <!-- maxFileSize Error only -->
      <p
        class="mb-1"
        *ngIf="
          !errorTypeList.includes(UploadErrorTypeEnum.MIME_TYPE) &&
          errorTypeList.includes(UploadErrorTypeEnum.FILE_SIZE)
        "
      >
        Achtung, Ihre Datei übersteigt die <b>maximale Dateigröße von {{ uploader.options.maxFileSize | bytes }}</b
        >.
        <a href="{{ CONSTANTS.PDF_EDITOR_APP_LINK }}" class="warning-text font-weight-bold" target="_blank">
          <u>Hier</u>
        </a>
        können Sie die Dateigröße reduzieren.
      </p>
      <!-- file type error only -->
      <p
        class="mb-1"
        *ngIf="
          errorTypeList.includes(UploadErrorTypeEnum.MIME_TYPE) &&
          !errorTypeList.includes(UploadErrorTypeEnum.FILE_SIZE)
        "
      >
        Achtung, Ihre Datei hat ein ungültiges Dateiformat. Bitte nutzen Sie eines der angezeigten Dateiformate.
      </p>

      <!-- Queue Limit -->
      <p class="mb-2" *ngIf="errorTypeList.includes(UploadErrorTypeEnum.QUEUE_LIMIT)">
        Achtung, in diesem Feld ist nur <b>eine Datei</b> erlaubt. Bitte laden Sie alle zusätzlichen Dokumente im
        letzten Uploadfenster hoch oder fügen Sie die Dateien in einem Dokument zusammen.
      </p>

      <span *ngIf="errorFilesList.length">Fehler:<br /></span>
      <p class="mb-0" *ngFor="let error of errorFilesList" [innerHtml]="'- ' + error | safeHtml"></p>
    </div>
  </div>
</div>
<div *ngIf="uploadList.length > 0" class="col-12 file-list">
  <div *ngFor="let entry of uploadList; let index = index" class="file-entry row">
    <div class="col-6 file-name">
      {{ entry?.name }}
    </div>
    <div class="col-6 text-right">
      <div *ngIf="!entry.hasFinishedSuccessfully && !entry.error" class="progress-box text-center d-inline-block">
        <div
          [ngStyle]="{
            width: (entry.progress | async) + '%'
          }"
          class="progress-bar text-center"
        ></div>
        <span *ngIf="entry.progress | async as pcnt" class="upload-percent">
          {{ pcnt.toFixed(2) + "%" }}
        </span>
      </div>
      <div
        *ngIf="entry.error"
        class="fas fa-exclamation-triangle text-danger px-1"
        tooltip="Fehler beim Hochladen der Datei, bitte versuchen Sie es erneut!"
        [adaptivePosition]="false"
        container="body"
      ></div>
      <div
        *ngIf="entry.fileItem && !entry.fileDetails && (entry.progress | async) === 100 && !entry.error"
        class="fas fa-cog fa-spin px-1"
      ></div>
      <div
        *ngIf="entry.fileItem && !entry.fileDetails && (entry.progress | async) !== 100 && !entry.error"
        class="fas fa-times text-danger file-remove px-1"
        (click)="onCancelFile(entry.fileItem)"
      ></div>
      <div
        *ngIf="entry.fileDetails?.download_url"
        class="fas fa-trash-alt text-danger file-remove"
        (click)="onRemoveFile(entry.fileDetails)"
      ></div>
    </div>
  </div>
</div>
