import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "@app/app.module";
import { environment } from "@environments/environment";
import { captureMessage, init, routingInstrumentation } from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import proxyPolyfillFunc from "proxy-polyfill/proxy.min.js";

/**
 * Angular fire analytics stops loading the app / browser support page.
 * This does not make analyitcs work, but just ignores error and loads browser support page with some console errors on IE.
 */
if (!window["Proxy"]) {
  window["Proxy"] = proxyPolyfillFunc();
}

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function () {}; // keine console.logs im Production Mode
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

// Capturing Resource 404s
document.body.addEventListener(
  "error",
  (event: any) => {
    if (!event.target) return;

    if (event.target.tagName === "IMG") {
      captureMessage(`Failed to load image: ${event.target.src}`, "warning");
    } else if (event.target.tagName === "LINK") {
      captureMessage(`Failed to load css: ${event.target.href}`, "warning");
    }
  },
  true // useCapture - necessary for resource loading errors
);
