import { EventEmitter, Injectable } from "@angular/core";
import { DocestateCiService } from "@app/_services/docestate-ci.service";
import {
  DocEstateCustomerInteractionsContainer,
  DocEstateCustomerInteraction,
} from "@app/interfaces/docEstate-ci.interface";
import { tap } from "rxjs/operators";
import {
  MoovinCustomerInteraction,
  MoovinCustomerInteractionsContainer,
} from "@app/interfaces/moovin/moovinCustomerInteraction.interface";
import { MoovinCustomerInteractionsService } from "@app/_services/moovin-customer-interactions.service";

@Injectable({
  providedIn: "root",
})
export class CustomerInteractionLogicService {
  public docEstateCustomerInteraction: DocEstateCustomerInteractionsContainer;
  public moovinCustomerInteraction: MoovinCustomerInteractionsContainer;

  public selectedDocEstateInteraction: DocEstateCustomerInteraction;
  public selectedMoovinInteraction: MoovinCustomerInteraction;
  public customerInteractionsOpened: EventEmitter<void> = new EventEmitter<void>();

  constructor(private dcs: DocestateCiService, private mcs: MoovinCustomerInteractionsService) {}

  getCIForSuborder(suborderId: string): Promise<MoovinCustomerInteractionsContainer> {
    return this.mcs
      .getCustomerInteractionsForSuborderId(suborderId)
      .pipe(
        tap((responseData: MoovinCustomerInteractionsContainer) => {
          this.moovinCustomerInteraction = responseData as MoovinCustomerInteractionsContainer;
        })
      )
      .toPromise();
  }

  getCIForOrder(orderId: string): Promise<DocEstateCustomerInteractionsContainer> {
    return this.dcs
      .getCustomerInteractionsForOrderId(orderId)
      .pipe(
        tap((responseData: DocEstateCustomerInteractionsContainer) => {
          this.docEstateCustomerInteraction = responseData as DocEstateCustomerInteractionsContainer;
        })
      )
      .toPromise();
  }

  sendCustomerResponse(interactionId: string, formData: FormData): Promise<DocEstateCustomerInteractionsContainer> {
    return this.dcs
      .sendCustomerResponse(interactionId, formData)
      .pipe(
        tap((responseData) => {
          this.docEstateCustomerInteraction = responseData;
        })
      )
      .toPromise();
  }

  sendMoovinCustomerResponse(suborderId: string, formData: FormData): Promise<MoovinCustomerInteractionsContainer[]> {
    return this.mcs
      .sendMoovinCustomerResponse(suborderId, formData)
      .pipe(
        tap((responseData) => {
          this.moovinCustomerInteraction = responseData[0];
        })
      )
      .toPromise();
  }

  getResponseFile(interactionId: string, responseId: string) {
    return this.dcs.getResponseFile(interactionId, responseId).toPromise();
  }

  openCustomerInteraction() {
    this.customerInteractionsOpened.emit();
  }
}
