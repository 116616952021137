<!-- Modals, included here so they are already loaded before we even render the home.component.html -->
<section>
  <ngx-smart-modal
    #emailVerificationModal
    identifier="emailVerificationModal"
    [closable]="false"
    [dismissable]="false"
    [escapable]="false"
  >
    <!-- Modal Title -->
    <h2 class="card-title b">E-Mail-Bestätigung</h2>

    <!-- Modal Text -->
    <p>Bitte bestätigen Sie jetzt Ihre E-Mail und drücken Sie dann auf "E-Mail bestätigt".</p>
    <p>Danach können Sie die ersten Schritte auf der Plattform machen!</p>

    <!-- Loading Spinner: It is shown while checking the e-mail verification status -->
    <ng-container *ngIf="auth.checkingEmail">
      <div class="lds-ring ring-small">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </ng-container>

    <!-- Modal Buttons -->
    <div class="row">
      <!-- Button to tell app to check if E-Mail was verified -->
      <div class="col">
        <button
          class="btn btn-primary btn-modal"
          (click)="auth.verifyEmail()"
          [disabled]="auth.emailVerificationFailed"
          [hidden]="auth.checkingEmail"
        >
          E-Mail bestätigt
        </button>
      </div>

      <!-- Resend Verification E-Mail Button -->
      <div class="col d-none-block d-sm-flex flex-wrap justify-content-end">
        <button class="btn btn-warning btn-modal" (click)="resendVerificationEmail()" [hidden]="auth.checkingEmail">
          E-Mail erneut senden
        </button>
      </div>
    </div>

    <!-- Verification Failed Alert -->
    <span *ngIf="auth.emailVerificationFailed === true" [hidden]="auth.checkingEmail"
      ><br /><br /><span class="alert alert-danger">Sie haben Ihre E-Mail noch nicht bestätigt!</span></span
    >

    <!-- Verification E-Mail resent Alert -->
    <span *ngIf="emailResent === true"
      ><br /><br /><span class="alert alert-success">E-Mail wurde erneut versendet!</span></span
    >
  </ngx-smart-modal>
  <ngx-smart-modal
    #firstImmoModal
    identifier="firstImmoModal"
    [closable]="false"
    [dismissable]="false"
    [escapable]="false"
  >
    <!-- Modal Title -->
    <h2 class="card-title b">Willkommen!</h2>

    <!-- Modal Text -->
    <p>Legen Sie jetzt Ihre erste Immobilie an, um loszulegen!</p>
    <p>Wenn Sie sich selbst umsehen möchten, können Sie das Tutorial überspringen.</p>

    <!-- Modal Buttons -->
    <div class="row">
      <!-- Button to follow the tutorial -->
      <div class="col">
        <button class="btn btn-primary btn-modal" (click)="navigateToRealestates()">
          <span>Immobilie anlegen</span>
        </button>
      </div>

      <!-- Button to skip the tutorial -->
      <div class="col d-none-block d-sm-flex flex-wrap justify-content-end">
        <button class="btn btn-danger btn-modal" (click)="skipTutorial()">
          <!-- Longer Button Text on bigger devices -->
          <span class="d-none d-sm-block">Tutorial überspringen</span>
          <!-- Shorter Button Text on small devices -->
          <span class="d-sm-none">Überspringen</span>
        </button>
      </div>
    </div>
  </ngx-smart-modal>
  <ngx-smart-modal
    #newDienstleisterModal
    identifier="newDienstleisterModal"
    [closable]="false"
    [dismissable]="false"
    [escapable]="false"
  >
    <!-- Modal Title -->
    <h2 class="card-title b">Willkommen!</h2>

    <!-- Modal text -->
    <p>
      Um als Dienstleister loszulegen, legen Sie bitte Ihr Profil an, geben Sie ihre angebotenen Dienstleistungen an und
      laden Sie gegebenenfalls die erforderlichen Dokumente hoch!
    </p>

    <!-- Add profile data button -->
    <button class="btn btn-primary" (click)="navigateToProfile()">Profil anlegen</button>
  </ngx-smart-modal>
</section>

<!-- This html is rendered on every page of the app. We include the sidebar and router here, and add some dynamic css
so that the login, register & password-reset-page have different background-colors and padding -->

<!-- Only show content to the user if all necessary data is loaded, else show loading spinner -->
<ng-container *ngIf="loader.loaded; else loading">
  <!-- Specific body styling for login/register/reset-page vs all other pages -->
  <div [className]="!isLoggedIn ? 'body-white' : 'tschau'">
    <!-- Styling wrapper for all pages, if sidebar is active, add css class toggled (see navcss.css) -->
    <div class="page-wrapper chiller-theme" [ngClass]="{ toggled: getSideBarState() }">
      <div class="wrapper">
        <!-- Only show the sidebar if user is logged in -->
        <div
          *ngIf="isLoggedIn && !gs.isSidebarDisabled"
          class="sidebar"
          data-color="white"
          data-background-color="imogent-green"
        >
          <app-sidebar-cmp></app-sidebar-cmp>
        </div>
        <div class="main-panel h-100" [ngClass]="{ 'mp-no-login': !isLoggedIn || gs.isSidebarDisabled }">
          <div
            class="main-content mb-0 pb-0 h-auto"
            [ngClass]="{
              'no-top-margin':
                this.orderCreationPath.includes(this.currentRoutePath) || (gs.isSidebarDisabled && gs.isNavbarDisabled)
            }"
          >
            <div class="container-fluid p-0 h-auto">
              <app-navbar-cmp *ngIf="!gs.isNavbarDisabled"></app-navbar-cmp>
              <div [ngClass]="{ 'mx-10': !this.orderCreationPath.includes(this.currentRoutePath) }">
                <router-outlet [ngClass]="{ 'navbar-disabled': gs.isNavbarDisabled }"></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- CSS Loading Spinner -->
<ng-template #loading>
  <div *ngIf="!isDownloadZip" class="lds-ring ring-default centered">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-template>

<ng-container *ngIf="isDownloadZip">
  <router-outlet></router-outlet>
</ng-container>

<div *ngIf="showLoader" class="custom-spinner">
  <div class="spinner-overlay"></div>
  <div class="lds-ring ring-default centered">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
