<ng-container *ngIf="subOrderId; else noSubOrder">
  <mat-tab-group *ngIf="(imageList && imageList.length > 0) || (flatFinderImages && flatFinderImages.length > 0)">
    <mat-tab label="360 VIEW" *ngIf="imageList && imageList.length > 0">
      <div class="container-fluid">
        <div class="rotatebox row justify-content-center align-items-bottom mt-3">
          <div class="images col-12">
            <img src="../../assets/images/360_0000.png" class="first-img op-zero" />
            <ng-container *ngFor="let file of imageList; let i = index">
              <img
                src="{{ file.download_url }}"
                [ngClass]="{
                  'op-zero': i !== currentIndex
                }"
              />
            </ng-container>
          </div>
          <mat-slider class="slider col-12 col-md-6 mt-3" [max]="imageList.length - 1" [min]="0" #ngSlider
            ><input
              matSliderThumb
              (input)="onSlide({ source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value })"
              #ngSliderThumb="matSliderThumb"
            />
          </mat-slider>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="FLAT FINDER" *ngIf="flatFinderImages && flatFinderImages.length > 0">
      <div class="container-fluid">
        <div class="row justify-contents-center align-items-center">
          <div class="col-12 col-md-8">
            <ng-container *ngFor="let image of flatFinderImages; let i = index">
              <img src="{{ image.download_url }}" class="res-image" [hidden]="selectedFlatIndex !== i" />
            </ng-container>
          </div>
          <div class="col-12 col-md-4">
            <mat-nav-list>
              <mat-list-item
                *ngFor="let image of flatFinderImages; let i = index"
                [ngClass]="{
                  'selected-item': i === selectedFlatIndex
                }"
                (click)="selectFlat(i)"
              >
                <h3 matLine>{{ "Flat " + (i + 1) }}</h3>
              </mat-list-item>
            </mat-nav-list>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <ng-container
    *ngIf="isLoaded && flatFinderImages && flatFinderImages.length <= 0 && imageList && imageList.length <= 0"
  >
    <div class="mt-5 container-fluid text-center">
      <h3 class="text-warning">This order does not have 360&deg; Images.</h3>
    </div>
  </ng-container>
</ng-container>
<ng-template #noSubOrder>
  <div class="mt-5 container-fluid text-center">
    <h3 class="text-danger">Order ID does not exist!</h3>
  </div>
</ng-template>
