import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  AfterViewInit,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { FileItem, FileUploader } from "ng2-file-upload";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { forbiddenFileValidator, fileRequiredValidator } from "../form.validators";
import * as _ from "underscore";
import { UploadService } from "@app/_services/upload.service";
import { take } from "rxjs/operators";
import { Subscription } from "rxjs";
import { NgxSmartModalService } from "ngx-smart-modal";
import { filterUploadQueue, translateAppListStrings } from "@app/util/helper";
import { SubOrdersLogicService } from "@app/_services/sub-orders-logic.service";
import { SendEmailService } from "@app/_services/send-email.service";
import { CONSTANTS } from "@app/util/constants";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { environment } from "@environments/environment";

@Component({
  selector: "app-imo-file-upload",
  templateUrl: "./imo-file-upload.component.html",
  styleUrls: ["./imo-file-upload.component.css"],
})
export class ImoFileUploadComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input("parentType") parentType: string = "";
  @Input("id") parentId: string;
  @Input("folderName") folderName: string;
  @Input("allowedExtn") allowedExtn: string[] = [];
  @Input("small") small: boolean = false;
  @Input("required") required: boolean = true;
  @Input("showUploadedFiles") showUploadedFiles: boolean = false;
  @Input("isHeaderHidden") isHeaderHidden = false;
  @Input("isUploadButtonHidden") isUploadButtonHidden = false;
  @Input() sequentialUpload = false;
  @Input() showFileUploadComment = false;
  @Input() additionalProperties: { [key: string]: any };
  @Output() onUploadFinish: EventEmitter<any> = new EventEmitter();
  @Output() newQueueLength: EventEmitter<number> = new EventEmitter();
  @ViewChild("fileInput", { static: true }) fileInput: ElementRef<any>;
  @Input() isEmptyUploaderAllowed: boolean;
  @Input("subOrder") subOrder: SubOrder;
  existingFiles = [];
  containsNewFiles: boolean = false;

  public uploader: FileUploader = new FileUploader({ additionalParameter: { isNew: true } });
  filesForm: FormGroup;
  filesElem: ElementRef;
  files = [];
  startUpload: boolean = false;
  startUploadArr: boolean[] = [];
  duplicatedFiles: FileItem[];
  private readonly logComponent = "ImoFileUploadComponent :: ";
  private subscriptions: Subscription[] = [];
  maxFileSize: number;

  constructor(
    private formBuilder: FormBuilder,
    private uploadService: UploadService,
    public ngxSmartModalService: NgxSmartModalService,
    private sos: SubOrdersLogicService,
    private ses: SendEmailService
  ) {
    this.filesForm = this.formBuilder.group({
      uploadedFiles: ["", this.required ? [Validators.required, fileRequiredValidator(this.uploader.queue)] : []],
      comments: this.formBuilder.array([]),
    });
    this.startUpload = false;
  }

  get uploadedFiles() {
    return this.filesForm.get("uploadedFiles");
  }

  get comments() {
    return <FormArray>this.filesForm.get("comments");
  }

  ngOnInit() {
    this.uploadService.getSubcollection(this.parentType, this.parentId, this.folderName).subscribe((files) => {
      this.existingFiles = files || [];
      this.checkNewlyAddedFiles(files);
    });
    if (this.subOrder.orderType === OrderTypeEnum.Floor_Plan) {
      this.maxFileSize = CONSTANTS.MAX_FILESIZES.MC_GRUNDRISS;
    }
  }

  ngAfterViewInit() {
    this.filesElem = this.fileInput.nativeElement;
    if (this.required) {
      if (_.isEmpty(this.allowedExtn)) {
        this.uploadedFiles.setValidators([Validators.required]);
      } else {
        this.uploadedFiles.setValidators([
          Validators.required,
          fileRequiredValidator(this.uploader.queue),
          forbiddenFileValidator(this.allowedExtn, this.filesElem),
        ]);
      }
    } else {
      this.uploadedFiles.setValidators(null);
      if (!_.isEmpty(this.allowedExtn)) {
        this.uploadedFiles.setValidators([forbiddenFileValidator(this.allowedExtn, this.filesElem)]);
      }
    }
    this.uploadedFiles.updateValueAndValidity();
    const subscription = this.filesForm.valueChanges.subscribe(() => {
      this.files = [];
      this.uploader.queue.forEach((entry: FileItem) => {
        this.files.push(entry.file.rawFile);
        this.startUploadArr.push(false);
        this.checkNewlyAddedFiles(this.files);
      });
    });

    this.subscriptions.push(subscription);
  }

  onChange(): void {
    const filteredQueue = filterUploadQueue(this.uploader.queue);
    let fileList = filteredQueue.fileList;
    const duplicatedFiles = filteredQueue.duplicatedFiles;
    const filesWhichAreTooBig = [];

    /*TODO: Only a temporary fix, we need to implement a way which is applicable to every service provider/ordertype
     *  What are the file size limits? */

    if (this.subOrder.orderType === OrderTypeEnum.Floor_Plan) {
      fileList = fileList.filter((currentFile) => {
        const sizeInMB = currentFile.size / 1024 ** 2;
        if (sizeInMB > this.maxFileSize) {
          filesWhichAreTooBig.push(currentFile);
          this.uploader.queue = this.uploader.queue.filter((element) => element.file !== currentFile);
          return false;
        } else {
          return true;
        }
      });
    }

    if (filesWhichAreTooBig.length) {
      this.ngxSmartModalService.setModalData(filesWhichAreTooBig, "feedbackErrorModal", true);
      this.ngxSmartModalService.getModal("feedbackErrorModal").open();
    } else if (duplicatedFiles.length) {
      this.ngxSmartModalService.setModalData(duplicatedFiles, "fileUploadErrorModal", true);
      this.ngxSmartModalService.getModal("fileUploadErrorModal").open();
    }
    duplicatedFiles.forEach((fl) => fl.remove());
    this.uploadedFiles.patchValue(fileList);
    this.uploadedFiles.updateValueAndValidity();

    for (let i = 0; i < fileList.length; i++) {
      this.startUploadArr.push(false);
    }

    this.addComments(fileList.length - this.comments.length);
    this.newQueueLength.emit(this.uploader.queue.length);
  }

  /**
   * Check if there is any file added to originalPhotos after order has been placed.
   */
  checkNewlyAddedFiles(files: any) {
    this.containsNewFiles = !!files.find((f) => f.isNew === true);
  }

  addComments(numCommentsToAdd: number) {
    if (!this.showFileUploadComment) {
      // nothing to add
      return;
    }

    for (let i = 0; i < numCommentsToAdd; i++) {
      this.comments.push(this.formBuilder.control(""));
    }
  }

  uploadFiles() {
    this.uploadedFiles.markAsDirty();
    if (this.filesForm.valid) {
      this.startUpload = true;
      this.startUploadArr[0] = true;
    }
  }

  finishFilesUpload() {
    const confirmed = confirm("Möchten Sie die Datei-Uploads abschließen?");
    if (confirmed) {
      // disable uploadAllowed
      this.sos.updateSubOrder(this.parentId, { uploadAllowed: false, customerActionRequired: true });

      let recipient = CONSTANTS.SUPPORT_EMAILS.DEVELOP;
      let subjectBeginning = "Test email - Dem Unterauftrag ";

      if (environment.production) {
        recipient = CONSTANTS.SUPPORT_EMAILS.PRODUCTION;
        subjectBeginning = "Dem Unterauftrag ";
      }

      // inform admin through email
      let emailObj = {
        to: recipient,
        subject:
          subjectBeginning +
          this.parentId +
          " wurden neue Dokumente hinzugefügt (" +
          (translateAppListStrings("order_type_list", this.subOrder.orderType) || this.subOrder.orderType) +
          ")",
        body: "",
      };

      this.ses.sendEmail(emailObj);
    }
  }

  /**
   * Remove the file from upload queue
   */
  onRemoveFile(file: FileItem | File, i: number): void {
    if (_.isFunction((<FileItem>file).remove)) {
      (<FileItem>file).remove();
      this.startUploadArr.shift();
      this.startUploadArr[0] = true;
    } else {
      this.uploader.queue.forEach((entry) => {
        if (entry.file.name === (<File>file).name) {
          entry.remove();
          this.startUploadArr.shift();
          this.startUploadArr[0] = true;
          this.onUploadFinish.emit(true);
        }
        if (_.isEmpty(this.uploader.queue)) {
          this.startUpload = false;
          this.startUploadArr = [];
        }
      });
      // when uploader queue is empty reset the form touch state to remove
      // validation errors as the form is submitted
      if (this.uploader.queue.length === 0) {
        Object.keys(this.filesForm.controls).forEach((control) => {
          this.filesForm.controls[control].markAsUntouched();
          this.filesForm.controls[control].markAsPristine();
          this.startUploadArr = [];
        });
        this.onUploadFinish.emit(true);
      }
    }
    this.uploadService
      .getSubcollection(this.parentType, this.parentId, this.folderName)
      .pipe(take(1))
      .subscribe((files) => {
        this.existingFiles = files || [];
        this.checkNewlyAddedFiles(files);
      });
    this.newQueueLength.emit(this.uploader.queue.length);
    this.filesForm.controls.uploadedFiles.updateValueAndValidity();
    this.removeCommentEntry(i);
  }

  removeCommentEntry(i: number) {
    if (!this.showFileUploadComment) {
      // nothing to remove
      return;
    }

    this.comments.removeAt(i);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
