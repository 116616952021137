import { Component, EventEmitter, Output } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "@app/auth/auth.service";

@Component({
  selector: "app-bkbn-transition-modal",
  templateUrl: "./bkbn-transition-modal.component.html",
  styleUrls: ["./bkbn-transition-modal.component.css"],
})
export class BkbnTransitionModalComponent {
  showCloseForeverButton: boolean = false;
  @Output() isCloseForever = new EventEmitter<boolean>();

  constructor(private dialogRef: MatDialogRef<BkbnTransitionModalComponent>, private auth: AuthService) {
    if (this.auth.myUserObservable.external === "planethome") {
      this.showCloseForeverButton = true;
    }
  }

  openBkbnPlatform() {
    window.open("https://app.bkbn.com", "_blank");
  }

  onCloseBanner(isCloseForever: boolean) {
    this.isCloseForever.emit(isCloseForever);
    this.dialogRef.close();
  }
}
