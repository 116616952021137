import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { LoaderService } from "@app/_services/loader.service";
import { Router } from "@angular/router";
import { passwordValidator, repeatPasswordValidator } from "@app/shared-module/form.validators";
import { CONSTANTS } from "@app/util/constants";
import { NotificationsService } from "@app/_services/notifications.service";
import { loadFromSession, storeInSession } from "@app/util/helper";
import { LandingPageTrackingItem } from "@app/interfaces/landing-page-tracking-item.interface";
import { ConfigurationLogicService } from "@app/_services/configuration-logic.service";
import { UserRoleEnum } from "@app/models/user-role-list";
import { GlobalService } from "@app/_services/global.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit, AfterViewInit {
  lowerCase = false;
  upperCase = false;
  digit = false;
  length = false;
  passwordHelp = false;
  @ViewChild("newPwd") newPwd;
  showSpkLogo = false;
  year: number;

  public registerForm: FormGroup;
  public passwordError = false;
  public roleError = false;
  adminFlag: boolean;
  popoverPlacement: string;
  submitted = false; // sets to true when the registeration form is submitted
  currentLandingPageTrackingItem: LandingPageTrackingItem;
  landingPageTrackingList: LandingPageTrackingItem[];

  constructor(
    public auth: AuthService,
    private fb: FormBuilder,
    public loader: LoaderService,
    private router: Router,
    private ns: NotificationsService,
    public gs: GlobalService,
    private conf: ConfigurationLogicService
  ) {
    Window["rself"] = this;
  }

  ngOnInit() {
    this.landingPageTrackingList = this.conf.landingPageTrackingList;
    this.setCurrentLandingPageTrackingItem();

    this.registerForm = this.fb.group({
      anrede: ["Anrede auswählen", [Validators.required, Validators.pattern("[a-zA-Z]*")]],
      firstName: ["", [Validators.required, Validators.pattern("\\S.*")]],
      lastName: ["", [Validators.required, Validators.pattern("\\S.*")]],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"),
        ],
      ],
      password1: ["", [Validators.required, passwordValidator()]],
      password2: ["", [Validators.required, passwordValidator()]],
      isPrivateIndividual: [undefined, [Validators.required]],
      role: ["", [Validators.required, Validators.pattern("[a-zA-Z]*")]],
      conditions: [false, [Validators.required]],
      newsletter: [
        {
          value: false,
          disabled:
            this.currentLandingPageTrackingItem && !this.currentLandingPageTrackingItem.hasNewsletterInfoEnabled,
        },
      ],
    });

    this.adminFlag = this.auth.adminFlag;
    // CR: 29.11.2019 Only show the role field if user is created by Admin
    // By default if a user registers to platform at his/her own, its registered as role : "Kunde"
    if (!this.adminFlag) {
      this.registerForm.get("role").setValue("Kunde");
    }
    this.auth.checkAuthState().then((result) => {
      if (result && !this.adminFlag) {
        this.router.navigate(["/users"]);
      }
    });
    if (window.matchMedia(`(min-width: 960px)`).matches) {
      this.popoverPlacement = "right";
    } else {
      this.popoverPlacement = "top";
    }

    if (CONSTANTS.SPARKASSEN_DOMAINS.includes(document.location.hostname)) {
      this.showSpkLogo = true;
    }
    this.year = CONSTANTS.CUR_YEAR;
  }

  ngAfterViewInit() {
    const newPwd = this.newPwd.nativeElement;
    this.password2.setValidators([Validators.required, passwordValidator(), repeatPasswordValidator(newPwd)]);
  }

  get anrede() {
    return <FormControl>this.registerForm.get("anrede");
  }
  get firstName() {
    return <FormControl>this.registerForm.get("firstName");
  }
  get lastName() {
    return <FormControl>this.registerForm.get("lastName");
  }
  get email() {
    return <FormControl>this.registerForm.get("email");
  }
  get password1() {
    return <FormControl>this.registerForm.get("password1");
  }
  get password2() {
    return <FormControl>this.registerForm.get("password2");
  }
  get isPrivateIndividual() {
    return <FormControl>this.registerForm.get("isPrivateIndividual");
  }
  get role() {
    return <FormControl>this.registerForm.get("role");
  }

  get conditions() {
    return <FormControl>this.registerForm.get("conditions");
  }

  get newsletter() {
    return <FormControl>this.registerForm.get("newsletter");
  }

  setCurrentLandingPageTrackingItem() {
    let landingPageTrackingRef = this.gs.landingPageTrackingRef;

    if (landingPageTrackingRef) {
      storeInSession(CONSTANTS.SESSION_STORAGE_KEYS.LANDING_PAGE_TRACKING_REF, landingPageTrackingRef);
    } else {
      landingPageTrackingRef = loadFromSession(CONSTANTS.SESSION_STORAGE_KEYS.LANDING_PAGE_TRACKING_REF);
    }

    this.currentLandingPageTrackingItem = this.landingPageTrackingList.find(
      (item) => item.refId === landingPageTrackingRef
    );
  }

  async submitHandler() {
    this.submitted = true;
    const formValue = this.registerForm.value;
    console.log(this.registerForm);
    if (!this.registerForm.valid || !this.registerForm.get("conditions").value) {
      return;
    }
    try {
      if (formValue.password1 === formValue.password2) {
        const requestData = {
          title: this.anrede.value,
          email: this.email.value.trim(),
          password: this.password1.value,
          firstName: this.firstName.value.trim(),
          lastName: this.lastName.value.trim(),
          role: this.role.value || UserRoleEnum.Customer,
          isPrivateIndividual: this.isPrivateIndividual.value,
          newsletter: this.registerForm.get("newsletter").value,
          external: this.currentLandingPageTrackingItem?.companyTag,
        };
        this.roleError = false;
        this.passwordError = false;
        if (!this.adminFlag) {
          this.auth.registering = true;
        }
        this.auth.showLoader.emit(true);
        await this.auth
          .register(requestData)
          .then((result) => {
            if (this.adminFlag) {
              this.router.navigate(["/users"]);
            }
          })
          .catch((err) => {
            err = err.error || err;
            if (err.message) {
              this.ns.showNotification(err.message, "danger");
            }
          })
          .finally(() => {
            this.auth.showLoader.emit(false);
          });
      } else {
        this.passwordError = true;
        console.log("Passwort Error");
      }
    } catch (err) {
      window.alert(err);
    }
  }
}
