import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { DocEstateCustomerInteraction } from "@app/interfaces/docEstate-ci.interface";
import {
  CustomerResponseType,
  DocEstateCIMessageTypeEnum,
  DocEstateCustomerInteractionStatusEnum,
  DocEstateMessageAuthorTypeEnum,
} from "@app/models/docEstate-ci.enum";
import { FileItem, FileUploader } from "ng2-file-upload";
import { AuthService } from "@app/auth/auth.service";
import { CustomerInteractionLogicService } from "@app/_services/customer-interaction-logic.service";
import { NotificationsService } from "@app/_services/notifications.service";
import { MatDialog } from "@angular/material/dialog";
import { ImoConfirmationModalComponent } from "@app/shared-module/imo-confirmation-modal/imo-confirmation-modal.component";
import { ConfirmationResponseTypeEnum } from "@app/models/confirmation-response-type.enum";
import { CustomerInteractionsComponentInterface } from "@app/shared-module/customer-interactions/customer-interactions-component.interface";
import { CustomerInteractionTypeEnum } from "@app/models/customerInteractionType.enum";
import { MoovinCustomerInteractionStatusEnum } from "@app/models/moovinCustomerInteractionStatus.enum";
import { MoovinCustomerInteraction } from "@app/interfaces/moovin/moovinCustomerInteraction.interface";
import { MoovinMessageAuthorTypeEnum } from "@app/models/moovin-message-author-type.enum";
import { CONSTANTS } from "@app/util/constants";
import { BytePipe } from "@app/shared-module/byte.pipe";
import { UploadErrorTypeEnum } from "@app/models/upload-error-type.enum";

@Component({
  selector: "app-customer-interactions",
  templateUrl: "./customer-interactions.component.html",
  styleUrls: ["./customer-interactions.component.css"],
})
export class CustomerInteractionsComponent implements OnInit, CustomerInteractionsComponentInterface {
  DocEstateCustomerInteractionStatusEnum = DocEstateCustomerInteractionStatusEnum;
  MoovinCustomerInteractionStatusEnum = MoovinCustomerInteractionStatusEnum;
  MoovinMessageAuthorTypeEnum = MoovinMessageAuthorTypeEnum;
  selectedInteractionId: string;
  userResponseType = CustomerResponseType.TEXT;
  userResponseText = "";
  inputError = false;
  fileUploader = new FileUploader({
    additionalParameter: { isNew: true },
    allowedMimeType: ["application/pdf"],
    maxFileSize: CONSTANTS.FILE_SIZE_LIMIT_5MB,
  });
  messageTypeEnum = DocEstateCIMessageTypeEnum;
  docEstateMessageAuthorType = DocEstateMessageAuthorTypeEnum;
  customerResponseType = CustomerResponseType;
  CONFIRMATION_RESPONSE_TYPE = ConfirmationResponseTypeEnum;
  @Input() customerInteractionType: CustomerInteractionTypeEnum = CustomerInteractionTypeEnum.DOC_ESTATE;
  @Input() suborderId: string;
  CustomerInteractionTypeEnum = CustomerInteractionTypeEnum;
  CONSTANTS = CONSTANTS;
  hideFileUploaderWarning = true;

  constructor(
    private auth: AuthService,
    public cils: CustomerInteractionLogicService,
    private ns: NotificationsService,
    private changeDetection: ChangeDetectorRef,
    private dialog: MatDialog,
    private bytePipe: BytePipe
  ) {}

  ngOnInit(): void {
    if (this.customerInteractionType === CustomerInteractionTypeEnum.DOC_ESTATE) {
      this.fileUploader = new FileUploader({
        additionalParameter: { isNew: true },
        allowedMimeType: ["application/pdf"],
        maxFileSize: CONSTANTS.FILE_SIZE_LIMIT_5MB,
      });

      this.cils.selectedDocEstateInteraction =
        (<DocEstateCustomerInteraction[]>this.cils.docEstateCustomerInteraction.customerInteractions).find(
          (ci) => ci.status === DocEstateCustomerInteractionStatusEnum.OPEN
        ) || this.cils.docEstateCustomerInteraction.customerInteractions[0];
      this.selectedInteractionId = this.cils.selectedDocEstateInteraction?.id;
    }

    if (this.customerInteractionType === CustomerInteractionTypeEnum.MOOVIN) {
      this.fileUploader = new FileUploader({
        additionalParameter: { isNew: true },
        maxFileSize: CONSTANTS.FILE_SIZE_LIMIT_10MB,
      });
      this.cils.selectedMoovinInteraction =
        (<MoovinCustomerInteraction[]>this.cils.moovinCustomerInteraction.customerInteractions).find(
          (ci: MoovinCustomerInteraction) => ci.status === MoovinCustomerInteractionStatusEnum.CUSTOMER_ACTION_REQUIRED
        ) || this.cils.moovinCustomerInteraction.customerInteractions[0];
      this.selectedInteractionId = this.cils.selectedMoovinInteraction?.id;
    }

    this.fileUploader.onWhenAddingFileFailed = (item, error) => {
      if (error.name === UploadErrorTypeEnum.FILE_SIZE) {
        this.hideFileUploaderWarning = false;
        this.ns.showNotification(
          "Bitte laden Sie eine Datei hoch, die kleiner als " +
            this.bytePipe.transform(CONSTANTS.FILE_SIZE_LIMIT_5MB) +
            " ist",
          "warning"
        );
      } else if (error.name === UploadErrorTypeEnum.MIME_TYPE) {
        this.ns.showNotification("Es sind nur .pdf Dateien erlaubt", "warning");
      }
    };
  }

  onCISelect(interactionId: string) {
    if (this.customerInteractionType === CustomerInteractionTypeEnum.DOC_ESTATE) {
      this.selectedInteractionId = interactionId;
      this.cils.selectedDocEstateInteraction = this.cils.docEstateCustomerInteraction.customerInteractions.find(
        (ci: DocEstateCustomerInteraction) => ci.id === interactionId
      );
    }

    if (this.customerInteractionType === CustomerInteractionTypeEnum.MOOVIN) {
      this.selectedInteractionId = interactionId;
      this.cils.selectedMoovinInteraction = this.cils.moovinCustomerInteraction.customerInteractions.find(
        (ci: MoovinCustomerInteraction) => ci.id === interactionId
      );
    }
  }

  setResponseType(responseType: CustomerResponseType, currentResponseType: CustomerResponseType) {
    if (
      (currentResponseType === CustomerResponseType.TEXT && !this.userResponseText.trim()) ||
      (currentResponseType === CustomerResponseType.FILE && !this.fileUploader.queue.length)
    ) {
      this.userResponseType = responseType;
      return;
    }
    const confirmDialogRef = this.dialog.open(ImoConfirmationModalComponent, {
      width: "350px",
      data: {
        title: currentResponseType === CustomerResponseType.TEXT ? "Nachricht verwerfen" : "Upload verwerfen",
        message:
          currentResponseType === CustomerResponseType.TEXT
            ? "Möchten Sie Ihre Nachricht verwerfen, um zum Uploadfeld zu wechseln?"
            : "Möchten Sie Ihren Upload verwerfen, um zum Nachrichtenfeld zu wechseln?",
        continueButtonText: "VERWERFEN",
        cancelButtonText: "ABBRECHEN",
        iconPath: "./../../../assets/images/discard.png",
      },
    });
    confirmDialogRef.afterClosed().subscribe((result) => {
      if (result === ConfirmationResponseTypeEnum.CONTINUE) {
        this.userResponseType = responseType;
        currentResponseType === CustomerResponseType.TEXT && (this.userResponseText = "");
        currentResponseType === CustomerResponseType.FILE && this.removeFileFromQueue(this.fileUploader.queue[0]);
      } else {
        this.userResponseType = currentResponseType;
      }
    });
  }

  removeFileFromQueue(file: FileItem) {
    this.fileUploader.removeFromQueue(file);
  }

  sendResponse() {
    const requestForm = new FormData();
    if (this.userResponseType === CustomerResponseType.TEXT) {
      if (!this.userResponseText.trim()) {
        return;
      }
      requestForm.set("type", CustomerResponseType.TEXT);
      requestForm.set("message", this.userResponseText.trim());
    }
    if (this.userResponseType === CustomerResponseType.FILE) {
      if (!this.fileUploader.queue.length) {
        return;
      }
      requestForm.set("type", CustomerResponseType.FILE);
      requestForm.set("file", this.fileUploader.queue[0].file.rawFile);
    }
    this.auth.showLoader.emit(true);
    if (this.customerInteractionType === CustomerInteractionTypeEnum.DOC_ESTATE) {
      this.cils
        .sendCustomerResponse(this.selectedInteractionId, requestForm)
        .then((updatedInteraction) => {
          this.userResponseText = "";
          this.fileUploader.queue.pop();
          this.cils.docEstateCustomerInteraction = updatedInteraction;
          this.cils.selectedDocEstateInteraction = <DocEstateCustomerInteraction>{};
          this.cils.selectedDocEstateInteraction = this.cils.docEstateCustomerInteraction.customerInteractions.find(
            (ci: DocEstateCustomerInteraction) => ci.id === this.selectedInteractionId
          );
          this.changeDetection.detectChanges();
        })
        .catch((error) => {
          this.ns.showNotification("Could not send the response", "danger");
        })
        .finally(() => {
          this.auth.showLoader.emit(false);
        });
    } else {
      this.cils
        .sendMoovinCustomerResponse(this.suborderId, requestForm)
        .then((response) => {
          this.userResponseText = "";
          this.fileUploader.queue.pop();
          this.cils.moovinCustomerInteraction = response[0];

          this.cils.selectedMoovinInteraction = <MoovinCustomerInteraction>{};
          this.cils.selectedMoovinInteraction = {
            id: this.cils.moovinCustomerInteraction.id,
            messages: this.cils.moovinCustomerInteraction.customerInteractions[0].messages,
          };
          this.changeDetection.detectChanges();
        })
        .catch((error) => {
          this.ns.showNotification("Beim Senden der Nachricht ist ein Fehler aufgetreten.", "danger");
        })
        .finally(() => {
          this.auth.showLoader.emit(false);
        });
    }
  }

  openFile(responseId: string, interactionId: string, event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.auth.showLoader.emit(true);
    this.cils
      .getResponseFile(interactionId, responseId)
      .then((fileContent: any) => {
        const file = new Blob([fileContent], { type: DocEstateCIMessageTypeEnum.FILE });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      })
      .catch((error) => {
        this.ns.showNotification("Could not download the file, try again later", "danger");
      })
      .finally(() => {
        this.auth.showLoader.emit(false);
      });
  }
}
