<div class="row">
  <div class="col-6">
    <div class="btn-group downloadTooltipContainer">
      <a class="btn btn-primary" href="{{downloadUrl}}" target="_blank" (click)="onUrlClick()">
        <i class="fa fa-download fa-1x clickable"></i>
        Alle herunterladen
      </a>
      <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split px-2" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" #downloadTooltip="bs-tooltip" placement="bottom"
              containerClass="info-tooltip"
              [adaptivePosition]="false"
              [tooltip]="tooltipText" triggers="" (click)="closeTooltip()">
        <span class="sr-only">Toggle Dropdown</span>
      </button>
      <div class="dropdown-menu">
        <a class="dropdown-item" href="{{downloadUrl}}">Original</a>
        <a class="dropdown-item" href="{{downloadURL_XL}}">Groß</a>
        <a class="dropdown-item" href="{{downloadURL_M}}">Mittel</a>
        <a class="dropdown-item" href="{{downloadURL_XS}}">Klein</a>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="btn-group float-right">
      <a class="btn btn-primary" href="mailto:?subject=Downloadlink zu Ihren Dateien&body={{encodeURIComponent()}}" target="_blank">
        <i class="fa fa-envelope fa-1x clickable text"></i>
      </a>
      <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="sr-only">Toggle Dropdown</span>
      </button>
      <div class="dropdown-menu">
        <a class="dropdown-item" href="mailto:?subject=Alle herunterladen&body={{encodeURIComponent()}}">Per E-Mail senden</a>
        <a class="dropdown-item" href="javascript: void();" [cdkCopyToClipboard]="downloadUrl">In Zwischenablage kopieren</a>
      </div>
    </div>
  </div>
</div>
<ng-template #tooltipText>
  <div>
    <span class="float-right clickable"><i class="fas fa-times" (click)="closeTooltip()"></i></span>
    <h5 class="mb-1">Hinweis:</h5>
    Nutzen Sie den Pfeil und laden Sie Ihre Fotos in der gewünschten Qualität herunter.
  </div>
</ng-template>
