import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { BackboneCreateOrderRequestBody } from "@app/interfaces/backbone-create-order-request-body.interface";

@Injectable({
  providedIn: "root",
})
export class BackboneService {
  private readonly ownEndpoint = environment.apiUrl + "backbone/";

  constructor(private http: HttpClient) {}

  /**
   * Sends an asynchronous POST request to the specified path with the given data.
   *
   * @param {string} path - The relative path of the request.
   * @param {BackboneCreateOrderRequestBody} data - The data to be sent in the request body.
   * @returns {Promise<unknown>} - A Promise that resolves with the response from the server.
   */
  async post(path: string, data: BackboneCreateOrderRequestBody) {
    const url = this.ownEndpoint + path;
    return lastValueFrom(this.http.post(url, data));
  }

  async get<T>(path: string, httpParams?: HttpParams): Promise<T> {
    const url = this.ownEndpoint + path;
    return lastValueFrom(this.http.get<T>(url, { params: httpParams }));
  }
}
