import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";
import { AccountingPositions } from "@app/models/accounting-positions";
import { AccountingPosition } from "@app/interfaces/accounting-position.interface";
import { Observable } from "rxjs/internal/Observable";
import { buildQueryString } from "@app/util/helper";

@Injectable({
  providedIn: "root",
})
export class AccountingPositionsService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.ACCOUNTINGPOSITIONS;

  constructor(private http: HttpClient) {}

  getAccountingPositions(): Observable<QueryResult<AccountingPosition>> {
    return this.http.get<QueryResult<AccountingPosition>>(this.endpoint);
  }

  getAccountingPositionsWithPaginator(
    from,
    to,
    sortBy,
    sortDirection,
    queryMap
  ): Observable<QueryResult<AccountingPosition>> {
    const queryString = buildQueryString(from, to, sortBy, sortDirection, queryMap);
    return this.http.get<QueryResult<AccountingPosition>>(this.endpoint + queryString);
  }

  putAccountingPositions(accPositions: AccountingPositions[]) {
    return this.http.put(this.endpoint, accPositions);
  }

  deleteAccountingPosition(id: string) {
    return this.http.delete(this.endpoint + "/" + id);
  }
}
