import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { UserAuthModel } from "@app/models/user-auth.model";
import { environment } from "@environments/environment";
import { catchError } from "rxjs/operators";
import { captureException } from "@sentry/angular";
import { CONSTANTS } from "@app/util/constants";
/** Pass untouched request through to the next request handler. */
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // OAuth2 Authorization header should only be added for our backend api requests
    // Preserve original http-headers for http-request to external APIs.
    if (!req.url.startsWith(environment.apiUrl) || req.url.includes("api/v1/apis/out/")) {
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          captureException(error);
          if (error instanceof HttpErrorResponse) {
            //server side error
            return throwError(error.message);
          } else {
            //client side error
            return throwError(error);
          }
        })
      );
    }

    const user: UserAuthModel = JSON.parse(localStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER) || "{}");
    let authRequest = req.clone();
    if (user && user.idToken) {
      authRequest = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + user.idToken).set("userToken", user.userToken),
      });
    }

    return next.handle(authRequest).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        errorResponse.error.user = user;
        captureException(errorResponse);
        if (errorResponse instanceof HttpErrorResponse) {
          //server side error
          const error = errorResponse.error;
          return throwError(error);
        } else {
          //client side error
          return throwError(errorResponse);
        }
      })
    );
  }
}
