import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { fromEvent, Subscription } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "@app/auth/auth.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ActivatedRoute, Router } from "@angular/router";
import { ListViewEnum, listViewList } from "@app/models/list-view-list";
import { CompanyRoleEnum } from "@app/models/company-role-list";
import { UserRoleEnum } from "@app/models/user-role-list";
import { LoaderService } from "@app/_services/loader.service";
import { ListView } from "@app/interfaces/list-view.interface";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import {
  ExternalRealEstateInformation,
  ExternalRealEstateTypeEnum,
  RealEstate,
  RealestateFloors,
} from "@app/interfaces/real-estate.interface";
import { WebsocketsService } from "@app/_services/websockets.service";
import { JllService } from "@app/_services/jll.service";
import { CONSTANTS } from "@app/util/constants";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { SubOrdersLogicService } from "../_services/sub-orders-logic.service";
import { environment } from "@environments/environment";
import { OrderStatusEnum } from "@app/models/order-status-list";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { LANGUAGE } from "@app/language/language";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { MatTooltip } from "@angular/material/tooltip";
import { RealestateLogicService } from "@app/_services/realestate-logic.service";
import { NotificationsService } from "@app/_services/notifications.service";
import { isPlanetHomeUser } from "@app/util/helper";
import { ImmoviewerLogicService } from "@app/_services/immoviewer-logic.service";

@Component({
  selector: "app-realestate",
  templateUrl: "./realestate.component.html",
  styleUrls: ["./realestate.component.css"],
})
export class RealestateComponent implements OnInit, OnDestroy, AfterViewInit {
  realestateForm: FormGroup;
  jllSpecificForm: FormGroup;
  realEstateSearchForm: FormGroup;
  jllPropertySearchForm: FormGroup;
  // Form States:
  loading = false;
  success = false;
  filterValues = {};
  formSub: any;
  mySelectedRealestate: any;
  mySelectedRealestateName: string;
  mySelectedRealestateData: RealEstate;
  userRef: any;
  downloadZipUrl = environment.apiUrl + "files/downloadzip?uid=";
  dataloaded: any;
  realestateSub: any;
  paramRealestateId: string;
  paramRealestateName: string;
  jllCondition = false;
  jllRealEstateData: ExternalRealEstateInformation;
  jllDropdownSelection: any;
  floors: RealestateFloors[] = [];
  newFloor: RealestateFloors;
  floorRangeStart: RealestateFloors;
  floorRangeEnd: RealestateFloors;
  data: any;
  isJLL = false;
  searchValue = "";
  private subscriptions: Subscription[] = [];
  listViewList: ListView[] = [];
  companyRoleEnum = CompanyRoleEnum;
  userRoleEnum = UserRoleEnum;
  statusList: { label: string; value: OrderStatusEnum }[] = [];
  columnsToDisplay: any;
  realEstateDataSrc: MatTableDataSource<any>;
  private suborders: any;
  realEstateList: RealEstate[] = []; // The real-estate list, only with the filter applied
  searchedRealEstateList: RealEstate[] = []; // The real-estate list after the search is applied
  selectedListViewId = ListViewEnum.MyList;
  private initialSearchFormValues: any;
  private initialJLLPropertySearchFormValues: any;
  totalNumberOfEstates: number;
  estatesPerPageOptions = [CONSTANTS.REAL_ESTATES_DEFAULT_PAGE_SIZE, 50, 100];
  estatesPerPage = CONSTANTS.REAL_ESTATES_DEFAULT_PAGE_SIZE;
  paginateFrom = 0;
  paginateTo = this.estatesPerPage;
  isLoadingEstates = true;
  isFilterEnabled = false;
  orderStatusEnum = OrderStatusEnum;
  orderTypeEnum = OrderTypeEnum;
  CONSTANTS = CONSTANTS;

  @ViewChild("generalSearch") generalSearch: ElementRef;
  @ViewChild("JLLTooltip") JLLTooltip: MatTooltip;

  @ViewChild(MatSort) set sort(value: MatSort) {
    if (this.realEstateDataSrc) {
      this.realEstateDataSrc.sort = value;
    }
  }
  /**
   * Set the real estate form validation in the constructor.
   */
  constructor(
    public realestateLogicService: RealestateLogicService,
    private fb: FormBuilder,
    public auth: AuthService,
    public ngxSmartModalService: NgxSmartModalService,
    private router: Router,
    private loader: LoaderService,
    private route: ActivatedRoute,
    public ws: WebsocketsService,
    public jll: JllService,
    private ivs: ImmoviewerLogicService,
    private ns: NotificationsService,
    private sos: SubOrdersLogicService
  ) {
    if (this.jll.checkJLLCustomer(this.auth.myUserObservable.company?.id)) {
      this.isJLL = true;
      this.jllDropdownSelection = this.jll.businessLineDropdown;
    }

    this.realestateForm = this.fb.group({
      title: ["", !this.isJLL ? [Validators.required] : []],
      objectNumber: [""],
      street: [
        "",
        !this.isJLL
          ? [CONSTANTS.VALIDATION_PATTERN.STREET]
          : [CONSTANTS.VALIDATION_PATTERN.STREET, Validators.required],
      ],
      streetnumber: [
        "",
        !this.isJLL
          ? [CONSTANTS.VALIDATION_PATTERN.STREET_NUMBER]
          : [CONSTANTS.VALIDATION_PATTERN.STREET_NUMBER, Validators.required],
      ],
      postalcode: [
        "",
        !this.isJLL
          ? [CONSTANTS.VALIDATION_PATTERN.POSTALCODE]
          : [CONSTANTS.VALIDATION_PATTERN.POSTALCODE, Validators.required],
      ],
      city: [
        "",
        !this.isJLL ? [CONSTANTS.VALIDATION_PATTERN.CITY] : [CONSTANTS.VALIDATION_PATTERN.CITY, Validators.required],
      ],
      brokerName: [undefined],
      brokerPhoneNumber: [undefined],
      brokerEmail: [undefined, [Validators.pattern(CONSTANTS.EMAILREGEX)]],
    });

    this.jllSpecificForm = this.fb.group({
      objectUID: ["", [Validators.required]],
      buildingUID: ["", [Validators.required]],
      businessLine: [""],
      locationBusinessLine: [""],
      responsible: [""],
    });

    this.realEstateSearchForm = this.fb.group({
      title: [""],
      objectNumber: [""],
      street: [""],
      streetnumber: [""],
      postalcode: [""],
      city: [""],
    });
    this.initialSearchFormValues = this.realEstateSearchForm.value;

    this.jllPropertySearchForm = this.fb.group({
      objectUID: [""],
      buildingUID: [""],
      business_line: [""],
      business_line_location: [""],
      responsible: [""],
    });
    this.initialJLLPropertySearchFormValues = this.jllPropertySearchForm.value;
  }

  /**
   * Gets called by default when the class is initialised
   * store the logged in user id in the class attribute to be used later on
   */
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.paramRealestateId = params["realestateId"];
      this.paramRealestateName = params["realestateName"];
    });
    const user = JSON.parse(sessionStorage.getItem("user"));
    this.userRef = user.uid; // UID

    this.setListViewList();
    this.setRealEstateList({
      total_hits: this.realestateLogicService.totalRealEstates,
      data: this.realestateLogicService.realEstateList,
    });
    this.subscriptions.push(
      this.realestateLogicService.realEstateListChanged.subscribe(() => {
        this.setRealEstateList({
          total_hits: this.realestateLogicService.realEstateList.length,
          data: this.realestateLogicService.realEstateList,
        });
      })
    );

    this.columnsToDisplay = ["createdOn", "id", "packageName", "status", "action", "total_price"];
    if (this.isJLL) {
      this.columnsToDisplay = [
        "createdOn",
        "id",
        "floor",
        "businessLine",
        "businessLineLocation",
        "packageName",
        "status",
        "action",
        "total_price",
        "isActive",
      ];
    }
    this.realestateLogicService.add = false;
    this.realestateLogicService.edit = false;
    this.realestateLogicService.showSuborderList = false;
    if (this.paramRealestateId) {
      this.showSuborders(this.paramRealestateId, this.paramRealestateName);
    }
    this.jllCondition = this.jll.checkJLLCustomer(this.auth.myUserObservable.company?.id);
    if (this.jllCondition) {
      this.jllRealEstateData = { ...this.jll.emptyExternalRealestateInformation };
    }

    // disable form add/edit for Planet Home users, since they are added or edited in their original source at Planet Home
    if (isPlanetHomeUser(this.auth.myUserObservable)) {
      this.realestateForm.disable();
    }
  }

  ngAfterViewInit() {
    this.initiateDirectSearch();
  }

  addMultipleFloors() {
    this.mySelectedRealestateData["floors"] = this.jll.addMultipleFloors(
      this.floorRangeStart.index,
      this.floorRangeEnd.index,
      this.mySelectedRealestateData
    );
    this.floors = this.mySelectedRealestateData["floors"];
  }

  addFloor() {
    this.mySelectedRealestateData["floors"] = this.jll.addFloor(this.newFloor, this.mySelectedRealestateData);
    this.floors = this.mySelectedRealestateData["floors"];
  }

  private setRealEstateList(realEstateData: QueryResult<RealEstate>) {
    this.realEstateList = realEstateData.data;
    this.isLoadingEstates = false;
    if (realEstateData.total_hits < this.paginateTo) {
      this.paginateTo = realEstateData.total_hits;
    }
    this.totalNumberOfEstates = realEstateData.total_hits || 0;
  }

  async filterRealEstates() {
    this.isLoadingEstates = true;
    const title = this.realEstateSearchForm.get("title").value.toLowerCase();
    const objectNumber = this.realEstateSearchForm.get("objectNumber").value.toLowerCase();
    const street = this.realEstateSearchForm.get("street").value.toLowerCase();
    const streetnumber = this.realEstateSearchForm.get("streetnumber").value.toLowerCase();
    const postalcode = this.realEstateSearchForm.get("postalcode").value.toLowerCase();
    const city = this.realEstateSearchForm.get("city").value.toLowerCase();

    const jllObjectUID = this.jllPropertySearchForm.get("objectUID").value.toLowerCase();
    const jllBuildingUID = this.jllPropertySearchForm.get("buildingUID").value.toLowerCase();
    const jllBusinessLine = this.jllPropertySearchForm.get("business_line").value.toLowerCase();
    const jllBusinessLineLocation = this.jllPropertySearchForm.get("business_line_location").value.toLowerCase();
    const jllResponsible = this.jllPropertySearchForm.get("responsible").value.toLowerCase();

    const keyValueQuery: [string, string | number][] = [];

    if (title) {
      keyValueQuery.push(["title", title]);
    }
    if (objectNumber) {
      keyValueQuery.push(["objectNumber", objectNumber]);
    }
    if (street) {
      keyValueQuery.push(["street", street]);
    }
    if (streetnumber) {
      keyValueQuery.push(["streetnumber", streetnumber]);
    }
    if (postalcode) {
      keyValueQuery.push(["postalcode", postalcode]);
    }
    if (city) {
      keyValueQuery.push(["city", city]);
    }

    if (this.jll.isJLLCustomer) {
      if (jllObjectUID) {
        keyValueQuery.push(["externalInformation.data.objectUID", jllObjectUID]);
      }
      if (jllBuildingUID) {
        keyValueQuery.push(["externalInformation.data.buildingUID", jllBuildingUID]);
      }
      if (jllBusinessLine) {
        keyValueQuery.push(["externalInformation.data.businessLine", jllBusinessLine]);
      }
      if (jllBusinessLineLocation) {
        keyValueQuery.push(["externalInformation.data.locationBusinessLine", jllBusinessLineLocation]);
      }
      if (jllResponsible) {
        keyValueQuery.push(["externalInformation.data.responsible", jllResponsible]);
      }
    }

    if (this.searchValue) {
      keyValueQuery.push([CONSTANTS.REAL_ESTATES_GENERAL_SEARCH, this.searchValue]);
    }

    // The admin can query all real estates of all users but in this case we are only interested in his own estates.
    // Also when myList is chosen only the ownerIDs real estates shall be queried.
    if (
      this.auth.myUserObservable.role === UserRoleEnum.Administrator ||
      this.selectedListViewId === ListViewEnum.MyList
    ) {
      keyValueQuery.push(["ownerID", this.auth.myUserObservable.uid]);
    }

    if (this.estatesPerPage < this.estatesPerPageOptions[0]) {
      this.estatesPerPage = this.estatesPerPageOptions[0];
    }

    // Initially the first results should be shown.
    if (!this.isFilterEnabled) {
      this.paginateFrom = 0;
      this.isFilterEnabled = true;
    }
    this.paginateTo = this.paginateFrom + this.estatesPerPage;
    const realEstateList = await this.realestateLogicService
      .queryRealEstates(keyValueQuery, this.paginateFrom, this.paginateTo)
      .toPromise();
    this.setRealEstateList(realEstateList);
    this.isLoadingEstates = false;
  }

  private setListViewList() {
    this.listViewList = listViewList.filter((item) => {
      return (
        item.id !== ListViewEnum.All &&
        item.id !== ListViewEnum.Automated &&
        item.id !== ListViewEnum.PartlyAutomated &&
        item.id !== ListViewEnum.NonAutomated
      );
    });
  }

  async changeRealestateStatus(realEstate: RealEstate, checked: boolean) {
    console.log(checked, realEstate);
    realEstate["isActive"] = checked;
    this.realestateLogicService.changeRealestateStatus(realEstate);
  }

  changeFloorStatus(floorIndex: number, checked: boolean) {
    console.log(checked, floorIndex, this.mySelectedRealestateData);
    const foundIndex = this.mySelectedRealestateData.floors.findIndex((item) => item.index === floorIndex);

    this.mySelectedRealestateData.floors[foundIndex]["isActive"] = checked;
    this.realestateLogicService.changeFloorStatus(this.mySelectedRealestateData, floorIndex);
  }

  /**
   * Gets called by default when the component is destroyed.
   * We unsubscribe from real estate document in this method.
   */
  ngOnDestroy() {
    // Fixing memory leak / permission error thrownif uses leaves open edit tab and logs out
    if (this.formSub !== undefined) {
      this.formSub.unsubscribe();
    }

    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.ws.unsubscribeFromRealEstateSuborders();
  }
  /**
   * The below getters are called when the form is rendered. For each input element there is a get function.
   */
  get title() {
    return this.realestateForm.get("title");
  }
  get objectNumber() {
    return this.realestateForm.get("objectNumber");
  }
  get street() {
    return this.realestateForm.get("street");
  }
  get streetnumber() {
    return this.realestateForm.get("streetnumber");
  }
  get postalcode() {
    return this.realestateForm.get("postalcode");
  }
  get city() {
    return this.realestateForm.get("city");
  }

  get brokerName() {
    return this.realestateForm.get("brokerName");
  }

  get brokerPhoneNumber() {
    return this.realestateForm.get("brokerPhoneNumber");
  }

  get brokerEmail() {
    return this.realestateForm.get("brokerEmail");
  }

  get objectUID() {
    return this.jllSpecificForm.get("objectUID");
  }

  get buildingUID() {
    if (this.isJLL) {
      return this.jllSpecificForm.get("buildingUID");
    }
  }

  async saveCustomerSpecific() {
    this.loading = true;
    const data = {
      ...this.mySelectedRealestateData,
      externalInformation: this.jllRealEstateData,
      floors: this.floors,
      id: this.mySelectedRealestate,
    };

    try {
      await this.realestateLogicService.updateRealEstate(data).toPromise();
      this.success = true;
      setTimeout(() => {
        this.success = true;
      }, 1000);
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
  }
  jllDropdownChanged() {
    this.jllRealEstateData.data.businessLine = this.jllDropdownSelection["businessLine"];
    this.jllRealEstateData.data.locationBusinessLine = "";
  }

  /**
   * Resets the real estate add/edit form. Is called when user try to add a new real estate
   * or cancel/exits the real estate form (during add/edit)
   */
  resetForm() {
    this.realestateForm.reset();
    this.realestateLogicService.add = false;
    this.realestateLogicService.edit = false;
    if (this.formSub !== undefined) {
      this.formSub.unsubscribe();
    }
    this.initiateDirectSearch();
  }

  initiateDirectSearch() {
    setTimeout(() => {
      fromEvent(this.generalSearch?.nativeElement, "input")
        .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
        .pipe(debounceTime(500))
        .pipe(distinctUntilChanged())
        .subscribe((data) => this.filterRealEstates());
    }, 500);
  }

  skipTutorial() {
    this.auth.tutorialSkipped = true;
    this.ngxSmartModalService.close("firstImmoCreatedModal");
  }

  navigateToServices() {
    this.router.navigate(["/createorder"]);
  }
  /**
   * Is triggered when user submits the form to add a new realstate. Stores the new property in the database.
   */
  async submitNewHandler() {
    this.realestateLogicService.add = false;
    this.loading = true;
    const data = {
      ...this.realestateForm.value,
      ownerID: this.userRef,
      createdOn: new Date(),
      isActive: true,
    };

    if (this.isJLL) {
      if (!this.title.value) {
        if (this.buildingUID.value) {
          data.title = CONSTANTS.JLL_PREFIXES.BUILDING + this.buildingUID.value;
        } else {
          data.title = CONSTANTS.JLL_PREFIXES.OBJECT + this.objectUID.value;
        }
      }
      data.externalInformation = {
        type: ExternalRealEstateTypeEnum.JLL,
        data: {
          ...this.jllSpecificForm.value,
          businessLine: this.jllDropdownSelection.businessLine,
          locationBusinessLine: this.jllRealEstateData.data.locationBusinessLine,
        },
      };
    }
    try {
      await this.realestateLogicService.addRealEstate(data).toPromise();
      this.realestateForm.reset();
      this.realestateForm.markAsPristine();
      this.success = true;
      setTimeout(() => {
        this.success = false;
        if (this.auth.role === UserRoleEnum.Customer && this.realestateLogicService.realEstateList.length === 1) {
          this.ngxSmartModalService.getModal("firstImmoCreatedModal").open();
        }
      }, 1000);
    } catch (err) {
      console.error(err);
      this.ns.showNotification(err?.error?.message, "danger");
    }
    this.loading = false;
  }
  /**
   * Is triggered when user finishes editing a property (clicks Änderungen speichern button)
   * Updates the realstate document in the database
   */
  async submitUpdateHandler(realestateIdParam: string) {
    this.loading = true;
    const data = {
      ...this.realestateForm.value,
      id: realestateIdParam,
    };
    this.mySelectedRealestateData = this.realestateForm.value;

    try {
      await this.realestateLogicService.updateRealEstate(data).toPromise();
      this.success = true;
      setTimeout(() => {
        this.realestateForm.markAsPristine();
      }, 1000);
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
  }
  /**
   * Triggered when user edits a property (clicks Bearbeiten button).
   * Subscribes to the real state document which is edited
   */
  loadEditData(realestateIdParam: string) {
    this.mySelectedRealestate = realestateIdParam;
    this.realestateLogicService.edit = true;
    this.formSub = this.realestateLogicService.getRealestate(realestateIdParam).subscribe((data) => {
      const realEstate = data;
      this.realestateForm.patchValue(realEstate);
      this.mySelectedRealestateData = realEstate;
      if (this.jllCondition) {
        this.jllRealEstateData = this.mySelectedRealestateData.externalInformation || {
          ...this.jll.emptyExternalRealestateInformation,
        };
        this.floors = this.mySelectedRealestateData.floors || [];
        this.jllDropdownSelection = this.jll.businessLineDropdown.find(
          (item) => item.businessLine === this.jllRealEstateData.data.businessLine
        ) || { businessLine: this.jllRealEstateData.data.businessLine, locationBusinessLine: [] };
      }
    });
  }
  /**
   * Triggered when user deletes a real estate
   * Deletes the real estate document from the database
   */
  async deleteRealestate(realestate: RealEstate) {
    await this.realestateLogicService.deleteRealestate(realestate);
    this.ngxSmartModalService.close("confirmRealEstateRemovalModal");
  }

  /**
   * Show the Suborders of the selected real estate
   * @param id Id of the selected real estate
   * @param realEstateName name of the selected real estate
   */
  showSuborders(id: string, realEstateName: string) {
    this.mySelectedRealestate = id;
    this.mySelectedRealestateName = realEstateName;
    this.realestateLogicService.showSuborderList = true;
    this.realestateLogicService.add = false;
    this.realestateLogicService.edit = false;

    this.ws.subOrdersOfRealEstate.next([]);
    this.subscriptions.push(
      this.ws.subOrdersOfRealEstate.subscribe((data) => {
        this.suborders = data;
        const availableStatuses = [];
        this.suborders.forEach((suborder: SubOrder) => {
          if (!availableStatuses.includes(suborder.status)) {
            availableStatuses.push(suborder.status);
            this.statusList.push({
              label: LANGUAGE.app_list_strings.status_list[suborder.status],
              value: suborder.status,
            });
          }
        });

        this.realEstateDataSrc = new MatTableDataSource(this.suborders);
        if (this.realEstateDataSrc) {
          this.setTableFilter();
        }
      })
    );
    this.ws.getSubordersOfRealEstate(this.mySelectedRealestate);
  }

  setTableFilter(): void {
    this.realEstateDataSrc.filterPredicate = (object, filter) => {
      let flag = true;
      Object.keys(this.filterValues).forEach((key) => {
        let value: string;

        if (key === "floor") {
          value = object[key]?.name || "";
        } else {
          value = object[key] || "";
        }

        if (this.filterValues[key] && value.toLowerCase().indexOf(this.filterValues[key]) < 0) {
          flag = false;
        }
      });
      return flag;
    };
  }

  filterTable(event: Event, column: string): void {
    this.noSort(event);
    let query = "";
    try {
      console.log(event.currentTarget["value"].trim());
      query = event.currentTarget["value"].trim().toLowerCase();
      console.log(query);
    } catch (e) {
      query = "";
      console.log(e);
    }
    this.filterValues[column] = query;
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.realEstateDataSrc.filter = "test";
  }

  onStatusFilter(event): void {
    this.filterValues["status"] = event["value"];
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.realEstateDataSrc.filter = "test";
  }

  /**
   * @param event: The click event
   * Prevents sorting when user clicks on the filter input.
   */
  noSort(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * @param id: Suborder Id
   * Sets the realestate view to the overview-mode and routes to the selected suborder.
   */
  showSuborder(id: string) {
    this.realestateLogicService.showSuborderList = false;
    this.realestateLogicService.add = false;
    this.realestateLogicService.edit = false;
    this.router.navigate(["orderoverview/suborder/" + id], { queryParams: { route: "realestate" } });
  }

  async onListViewChange(selectedListViewId: ListViewEnum) {
    this.isLoadingEstates = true;
    this.selectedListViewId = selectedListViewId;
    this.paginateFrom = 0;
    this.paginateTo = this.paginateFrom + this.estatesPerPage;
    this.setRealEstateList(await this.fetchCorrespondingEstates());
  }

  resetFilter() {
    this.isFilterEnabled = false;
    this.setRealEstateList({
      data: this.realestateLogicService.realEstateList,
      total_hits: this.realestateLogicService.realEstateList.length,
    });
    this.realEstateSearchForm.reset(this.initialSearchFormValues);
    this.jllPropertySearchForm.reset(this.initialJLLPropertySearchFormValues);
  }

  async isActiveChanged($event: Event, row: SubOrder) {
    $event.preventDefault();
    $event.stopPropagation();

    if (row.isActive) {
      if (row.immoviewerItemId) {
        try {
          await this.ivs.unpublish(row.immoviewerItemId);
          await this.sos.updateSubOrder(row.id, { isActive: false });
          this.ns.showNotification("Tour in Immoviewer deaktiviert.", "success");
        } catch (e) {
          if (e.status === CONSTANTS.HTTP_STATUS.CONFLICT) {
            // we return status code 409 in case the resource was already deactivated by a conflicting Request or because the test-tour in dev is already disabled.
            this.sos.updateSubOrder(row.id, { isActive: false });
            this.ns.showNotification(
              "Anfrage steht im Konflikt mit einer vorherigen Anfrage. Die Tour wurde deaktiviert.",
              "warning"
            );
          } else {
            this.ns.showNotification("Tour konnte nicht deaktiviert werden. Fehler: " + JSON.stringify(e), "danger");
          }
        }
      } else {
        await this.sos.updateSubOrder(row.id, { isActive: false });
      }
    } else {
      if (row.immoviewerItemId) {
        try {
          await this.ivs.publish(row.immoviewerItemId);
          await this.sos.updateSubOrder(row.id, { isActive: true });
          this.ns.showNotification("Tour in Immoviewer veröffentlicht.", "success");
        } catch (e) {
          this.ns.showNotification("Tour konnte nicht deaktiviert werden. Fehler: " + JSON.stringify(e), "danger");
        }
      } else {
        await this.sos.updateSubOrder(row.id, { isActive: true });
      }
    }
    setTimeout(() => {
      this.JLLTooltip.show();
    }, 500);
  }

  async paginateNext() {
    this.isLoadingEstates = true;
    if (this.totalNumberOfEstates > this.paginateTo) {
      this.paginateFrom += this.estatesPerPage;
      this.paginateTo += this.estatesPerPage;
    }

    if (this.isFilterEnabled) {
      this.filterRealEstates();
    } else {
      this.setRealEstateList(await this.fetchCorrespondingEstates());
    }
  }

  async paginatePrevious() {
    this.isLoadingEstates = true;
    this.paginateFrom -= this.estatesPerPage;
    if (this.paginateFrom < 0) {
      this.paginateFrom = 0;
    }
    this.paginateTo -= this.estatesPerPage;
    if (this.paginateTo < this.estatesPerPage) {
      this.paginateTo = this.estatesPerPage;
    }

    this.setRealEstateList(await this.fetchCorrespondingEstates());
  }

  // Fetches the real estates corresponding to the selected listView from the database.
  async fetchCorrespondingEstates() {
    let realEstates: QueryResult<RealEstate>;
    if (this.selectedListViewId === ListViewEnum.CompanyList) {
      realEstates = await this.realestateLogicService
        .getMyCompanyRealEstates(this.paginateFrom, this.paginateTo)
        .toPromise();
    } else {
      realEstates = await this.realestateLogicService.getMyRealestates(this.paginateFrom, this.paginateTo).toPromise();
    }

    return realEstates;
  }

  compare(object1: number, object2: number) {
    return object1 && object2 && object2 === object1;
  }

  async estatesPerPageChanged() {
    this.isLoadingEstates = true;
    this.paginateTo = this.paginateFrom + this.estatesPerPage;
    if (this.paginateTo < this.estatesPerPage) {
      this.paginateTo = this.estatesPerPage;
    }
    if (this.isFilterEnabled) {
      this.filterRealEstates();
    } else {
      this.setRealEstateList(await this.fetchCorrespondingEstates());
    }
  }

  addEstate() {
    this.realestateLogicService.add = true;
    this.realestateForm.reset();
    this.realestateForm.markAsPristine();
    this.realestateForm.markAllAsTouched();
    if (this.jllCondition) {
      this.jllSpecificForm.reset();
      this.jllSpecificForm.markAsPristine();
      this.jllSpecificForm.markAllAsTouched();
    }
  }

  updateRequiredJLLFields() {
    if (this.buildingUID.value || this.objectUID.value) {
      this.buildingUID.setValidators([]);
      this.objectUID.setValidators([]);
    } else {
      this.buildingUID.setValidators([Validators.required]);
      this.objectUID.setValidators([Validators.required]);
    }

    this.buildingUID.updateValueAndValidity();
    this.objectUID.updateValueAndValidity();
  }

  showOverview() {
    this.realestateLogicService.showSuborderList = false;
    this.initiateDirectSearch();
  }
}
