<mat-card appearance="outlined" class="mt-2 p-3">
  <mat-card-title class="feedback-info">
    Sie haben eine Änderung oder eine Frage zu Ihrer Bestellung? Folgendes ist zu beachten:
  </mat-card-title>
  <mat-card-content class="mt-4">
    <p>
      <strong class="feedback-info-subtitle"
        >Änderungen sind lediglich innerhalb der Korrekturschleifen möglich.</strong
      >
      <br />
      Die Bearbeitung Ihres Auftrages beginnt, sobald ein Lieferdatum eingetragen ist. Ab diesem Zeitpunkt sind
      Änderungen lediglich nach der Lieferung der Ergebnisse möglich und
      <u>zwischenzeitliche Änderung können nicht berücksichtigt werden</u>. Ausnahmen für zwischenzeitliche Änderungen
      sind Terminvereinbarungen bei Leistungen, die durch einen Dienstleister vor Ort erbracht werden aufgrund von
      Schlechtwetter o.ä.
    </p>
    <p>
      <strong class="feedback-info-subtitle"
        >Nur mithilfe des Feedbacktools kann ihr Feedback zeitnah und korrekt umgesetzt werden.</strong
      >
      <br />
      Korrekturen können <u>nicht</u> per Mail oder Telefon entgegen genommen werden. Wir bitten Sie, zur schnelleren
      Bearbeitung Ihrer Änderungen, unser Feedback Tool zu verwenden und Anmerkungen/Änderungen direkt in die
      entsprechende Datei einzuzeichnen. Hierzu stehen Ihnen diverse Möglichkeiten zur Verfügung und alle Änderungen
      sind digital erfasst.
      <dfn class="video-edit-tooltip xl">
        <strong class="text-highlight d-none d-xl-inline" (mouseover)="playVideo()" (mouseout)="stopVideo()"
          >So funktionierts!</strong
        >
        <strong class="text-highlight d-none d-md-inline d-xl-none">So funktionierts:</strong>
        <br class="d-none d-md-inline d-xl-none" />
        <br class="d-none d-md-inline d-xl-none" />
        <span role="tooltip">
          <video [autoplay]="isPlayingVideo" controls loop #videoPlayer>
            <source src="assets/video/feedback_tutorial.mp4" type="video/mp4" />
          </video>
        </span>
      </dfn>
    </p>

    <p>
      <strong class="feedback-info-subtitle">Fragen können per Mail an unsere Service Mail gesendet werden.</strong>
      <br />
      Lassen Sie uns Ihre Fragen bitte unbedingt per <u>E Mail</u> zukommen. So können wir bewerkstelligen, dass die
      Information den richtigen Ansprechpartner erreicht. Ihr Anliegen können Sie an <u>service@imogent.com</u> senden.
      Bitte nutzen Sie nur in dringenden Fällen unseren telefonischen Support.
    </p>
  </mat-card-content>
</mat-card>
