<div class="col-12">
  <div class="header font-size-xlarge font-weight-bold row mt-2">
    <div class="col-10">
      <ng-container *ngIf="mode !== BillingAddressSelectorModeEnum.MANAGE">
        <span *ngIf="!isAddressFormDisplayed">Wählen Sie die gewünschte Rechnungsadresse</span>
        <span *ngIf="isAddressFormDisplayed"
          >Rechnungsadresse
          <span *ngIf="editIndex !== undefined"> bearbeiten</span>
          <span *ngIf="editIndex === undefined"> hinzufügen</span>
        </span>
      </ng-container>
    </div>
    <div class="col-2">
      <span class="float-right" *ngIf="!isAddressFormDisplayed">
        <button class="btn btn-primary btn-sm" (click)="editInvoiceAddress()">Adresse hinzufügen</button>
      </span>
    </div>
  </div>
  <ng-container *ngIf="!isAddressFormDisplayed">
    <div class="row mt-5">
      <div class="col-12"></div>
    </div>
    <mat-radio-group
      aria-labelledby="example-radio-group-label"
      class="example-radio-group"
      [(ngModel)]="selectedIndex"
    >
      <mat-card
        appearance="outlined"
        class="invoice-address-card mb-5"
        *ngFor="let billingAddress of user.billingAddresses; let i = index"
      >
        <div class="row">
          <div class="col-3">
            <mat-radio-button
              class="example-radio-button center-vertically address-title"
              [value]="i"
              *ngIf="mode !== BillingAddressSelectorModeEnum.MANAGE"
            >
              Rechnungsadresse {{ i + 1 }}
            </mat-radio-button>
            <span class="center-vertically address-title" *ngIf="mode === BillingAddressSelectorModeEnum.MANAGE"
              >Rechnungsadresse {{ i + 1 }}</span
            >
          </div>
          <div class="col-3">
            <span *ngIf="billingAddress.invoiceHeader">{{ billingAddress.invoiceHeader }}</span
            ><br />
            <span *ngIf="billingAddress.company">{{ billingAddress.company }}</span
            ><br />
            <span *ngIf="billingAddress.taxId">{{ billingAddress.taxId }}</span
            ><br />
          </div>
          <div class="col-3">
            <span *ngIf="billingAddress.name">{{ billingAddress.name }}</span
            ><br />
            <span *ngIf="billingAddress.street">{{ billingAddress.street }} {{ billingAddress.number }}</span
            ><br />
            <span *ngIf="billingAddress.postalCode">{{ billingAddress.postalCode }} {{ billingAddress.city }}</span>
          </div>
          <div class="col-2">
            <span></span><br />
            <span *ngIf="billingAddress.country">{{ billingAddress.country }}</span
            ><br />
            <span *ngIf="billingAddress.email">{{ billingAddress.email }}</span>
          </div>
          <div class="col-1">
            <i class="material-icons float-right text-primary clickable mr-2" (click)="editInvoiceAddress(i)"
              >mode_edit</i
            >
            <i class="material-icons float-right text-danger clickable mr-2" (click)="confirmAddressDeletion(i)"
              >delete</i
            >
          </div>
        </div>
      </mat-card>
    </mat-radio-group>
  </ng-container>
  <ng-container *ngIf="isAddressFormDisplayed">
    <app-billing-address-form
      (billingAddressChanged)="onBillingAddressChanged($event)"
      [initialBillingAddress]="selectedBillingAddress"
      [showEndCustomerOptions]="mode !== BillingAddressSelectorModeEnum.MANAGE"
    >
    </app-billing-address-form>
    <div class="col-12">
      <span class="float-right">
        <button class="btn btn-back" (click)="abort()">Zurück</button>
        <button class="btn btn-primary" [disabled]="!isAddressFormValid" (click)="save()">Speichern</button>
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="!isAddressFormDisplayed && mode !== BillingAddressSelectorModeEnum.MANAGE">
    <div class="col-12">
      <span class="float-right">
        <button class="btn btn-back" (click)="abort()">Zurück</button>
        <button class="btn btn-primary" [disabled]="!selectedIndex && selectedIndex !== 0" (click)="saveSelection()">
          Speichern
        </button>
      </span>
    </div>
  </ng-container>
</div>

<ngx-smart-modal #confirmDelete identifier="confirmDelete" [closable]="true" [escapable]="true" [dismissable]="true">
  <h3>Achtung</h3>
  <p>Sind Sie sicher, dass Sie Rechnungsadresse {{ deleteIndex + 1 }} löschen möchten?</p>
  <button class="btn btn-danger" (click)="deleteInvoiceAddress(deleteIndex)">Löschen</button>
</ngx-smart-modal>
