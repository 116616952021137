<div class="col-12">
  <div class="mt-5 mb-5" *ngIf="isPageLoading">
    <mat-spinner [diameter]="30" [color]="'primary'"></mat-spinner>
  </div>
  <ng-container *ngIf="!isPageLoading">
    <div class="mt-3" *ngIf="isAllowed">
      <div class="api-key-input-outer" id="secretDiv">
        <input
          class="api-key-input"
          name="secret"
          type="text"
          placeholder="API-key hier reinkopieren"
          id="secret"
          [(ngModel)]="apiUserSecret"
        />
      </div>
      <input
        class="unlock-now-button"
        type="button"
        [value]="isActive ? 'Aktiv' : 'Jetzt Freischalten'"
        [disabled]="isActive"
        title="Bitte zuerst der Datenschutzerklärung zustimmen"
        alt="Bitte zuerst der Datenschutzerklärung zustimmen"
        (click)="unlockProvider()"
        *ngIf="!isProcessing"
      />
      <div class="unlock-load-spinner" *ngIf="isProcessing"></div>
      <label class="api-key-label">API-Key</label>
      <div class="mt-5 text-danger" *ngIf="isError">
        <b>Fehler:</b> Freischaltung nicht erfolgreich. Bitte API-Key prüfen.
      </div>
    </div>
    <div *ngIf="!isAllowed">Forbidden: Invalid Signature provided.</div>
  </ng-container>
</div>
