<ng-container *ngIf="showBanner">
  <ng-container *ngIf="!(activatedRoute === 'orderconfirm'); else checkout">
    <ngb-carousel
      *ngIf="partnerContent.length > 1"
      [showNavigationArrows]="false"
      [showNavigationIndicators]="true"
      [interval]="5000"
      [wrap]="true"
    >
      <ng-template ngbSlide *ngFor="let partner of partnerContent">
        <mat-card
          appearance="outlined"
          class="row m-2 partner-card for-service"
          [style.background]="partner.background"
        >
          <div class="col-2 partner-image">
            <span class="cross text-black" *ngIf="closePartnerBannerForever" [matMenuTriggerFor]="menu"
              ><mat-icon class="text-black">cancel_presentation</mat-icon>
              <mat-menu #menu="matMenu" xPosition="before">
                <button mat-menu-item (click)="hideBannerOnce(partner.partnerId)">Schließen</button>
                <button mat-menu-item (click)="canCloseBannerForever(partner.partnerId)">
                  Schließen und nicht mehr anzeigen
                </button>
              </mat-menu>
            </span>
            <img class="partner-logo mt-auto mb-auto" [src]="partner.pathToLogo" />
          </div>
          <div class="col-10">
            <p class="banner-header" [innerHTML]="partner.bannerHeader"></p>
            <p class="banner-text" [innerHTML]="bannerText || partner.bannerText"></p>
            <span *ngIf="partner?.link" class="forward mt-3">
              <a class="btn btn-white btn-banner" [href]="partner.link" target="_blank"
                ><span class="btn-text">Mehr erfahren <mat-icon *ngIf="closePartnerBannerForever">east</mat-icon></span>
              </a>
            </span>
          </div>
        </mat-card>
      </ng-template>
    </ngb-carousel>

    <ng-container *ngIf="partnerContent.length === 1">
      <mat-card
        appearance="outlined"
        class="row m-2 partner-card for-service"
        *ngIf="
          !auth.closePartnerBannerOnce &&
          !auth.myUserObservable?.restrictedPartnerBanners?.includes(partnerContent[0].partnerId)
        "
        [style.background]="partnerContent[0].background"
      >
        <div class="col-3 partner-image">
          <span class="cross text-black" *ngIf="closePartnerBannerForever" [matMenuTriggerFor]="menu"
            ><mat-icon class="text-black">cancel_presentation</mat-icon>
            <mat-menu #menu="matMenu" xPosition="before">
              <button mat-menu-item (click)="auth.closePartnerBannerOnce = true">Schließen</button>
              <button mat-menu-item (click)="canCloseBannerForever(partnerContent[0].partnerId)">
                Schließen und nicht mehr anzeigen
              </button>
            </mat-menu>
          </span>
          <img class="partner-logo mt-auto mb-auto" [src]="partnerContent[0].pathToLogo" />
        </div>
        <div class="col-9">
          <p class="banner-header" [innerHTML]="partnerContent[0].bannerHeader"></p>
          <p class="banner-text" [innerHTML]="bannerText || partnerContent[0].bannerText"></p>
          <span *ngIf="partnerContent[0]?.link" class="forward mt-1">
            <a class="btn btn-white btn-banner" [href]="partnerContent[0].link" target="_blank"
              ><span class="btn-text d-inline-flex align-items-center"
                >Mehr erfahren
                <span class="cta-arrow" style="margin-left: 15px; font-size: 20px; font-weight: 600">→</span></span
              ></a
            >
          </span>
        </div>
      </mat-card>
    </ng-container>
  </ng-container>

  <ng-template #checkout>
    <ng-container *ngFor="let partner of partnerContent">
      <mat-card
        appearance="outlined"
        class="col-12 partner-card checkout-card"
        *ngIf="
          !auth.closePartnerBannerOnce && !auth.myUserObservable?.restrictedPartnerBanners?.includes(partner.partnerId)
        "
        [style.background]="partner.background"
      >
        <div class="col-4 col-sm-12 col-xs-12 partner-image for-checkout">
          <span class="cross" *ngIf="closePartnerBannerForever" [matMenuTriggerFor]="menu"
            ><mat-icon class="text-black">cancel_presentation</mat-icon>
            <mat-menu #menu="matMenu" xPosition="before">
              <button mat-menu-item (click)="auth.closePartnerBannerOnce = true">Schließen</button>
              <button mat-menu-item (click)="canCloseBannerForever(partner.partnerId)">
                Schließen und nicht mehr anzeigen
              </button>
            </mat-menu>
          </span>
          <img class="m-2 partner-logo mt-auto mb-auto" [src]="partner.pathToLogo" />
        </div>
        <div class="col-8 col-sm-12 col-xs-12 checkout-text">
          <p class="banner-header" [innerHTML]="partnerContent[0].bannerHeader"></p>
          <p class="banner-text mb-2 mt-4" [innerHTML]="bannerText || partner.bannerText"></p>
          <span *ngIf="partner?.link" class="forward m-3">
            <a class="btn btn-white btn-checkout" [href]="partner?.link" target="_blank"
              ><span class="btn-checkout-tex"
                >Mehr erfahren <mat-icon *ngIf="closePartnerBannerForever">east</mat-icon></span
              >
            </a>
          </span>
        </div>
      </mat-card>
    </ng-container>
  </ng-template>
</ng-container>
