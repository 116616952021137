import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RealEstate } from '@app/interfaces/real-estate.interface';
import { RealestateLogicService } from '@app/_services/realestate-logic.service';

@Injectable({
  providedIn: 'root'
})
export class MyRealEstatesResolverService implements Resolve<QueryResult<RealEstate>> {

  constructor(
    private rels: RealestateLogicService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.rels.getRealestates(true);
  }
}
