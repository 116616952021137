import { Injectable } from "@angular/core";
import { ApisService } from "@app/_services/apis.service";
import { CONSTANTS } from "@app/util/constants";
import { LexofficeContactUpdate } from "@app/interfaces/lexofficeContactUpdate.interface";
import { LexofficeContact } from "@app/interfaces/lexoffice-contact.interface";

@Injectable({
  providedIn: "root",
})
export class LexOfficeService {
  private readonly path = CONSTANTS.EXTERNAL_APIs.LEXOFFICE + "/";

  constructor(private apis: ApisService) {}

  async findContact(customerNumber: number) {
    return this.apis.callGet(this.path + "contacts/" + customerNumber).toPromise();
  }

  async updateContact(customerNumber: number, updatedContactData: LexofficeContactUpdate): Promise<LexofficeContact> {
    return this.apis.callPut(this.path + "contacts/" + customerNumber, updatedContactData).toPromise();
  }
}
