import { Pipe, PipeTransform } from "@angular/core";
import { AuthService } from "@app/auth/auth.service";
import { MoneyService } from "@app/_services/money.service";
import { CONSTANTS } from "@app/util/constants";
import { User } from "@app/interfaces/user.interface";
import { GlobalService } from "@app/_services/global.service";

@Pipe({
  name: "accPosReplace",
})
export class AccPosReplacerPipe implements PipeTransform {
  constructor(private auth: AuthService, private ms: MoneyService, private gs: GlobalService) {}

  transform(text: string, ...args: unknown[]): string {
    const loggedInUser = (args[0] as User) || this.auth.myUserObservable;
    let finalizedText = "";
    const pattern = "accPos:";
    if (text && text.length > 0) {
      let start = text.indexOf(pattern);
      while (start !== -1) {
        // End index of accounting position is next occurance of : since the pattern would be "accPos:<some_pos_id>:"
        const end = text.indexOf(":", start + pattern.length + 1);
        const position = text.substring(start, end);
        const pos = this.gs.apls.getPositionData(position.split(":")[1]);
        let value = pos.price;
        if (loggedInUser?.isPrivateIndividual) {
          // add VAT amount for given price
          value = this.ms.unformat(this.ms.toFixed(value * (1 + CONSTANTS.VAT_TAX_PERCENTAGE / 100), 2));
        }
        text = text.replace(position + ":", this.ms.formatMoneyProxy(value, ""));
        start = text.indexOf(pattern, start + 1);
      }
    }
    return text;
  }
}
