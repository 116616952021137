import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DiscountTypeEnum, discountTypeList } from "@app/models/discount-type-list";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { Company, CompanyFilter } from "@app/interfaces/company.interface";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ServicesLogicService } from "@app/_services/services-logic.service";
import { AccountingPositionsLogicService } from "@app/_services/accounting-positions-logic.service";
import { UniqueDiscountNameValidator } from "@app/shared-module/form.validators";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { ConfigurationLogicService } from "@app/_services/configuration-logic.service";
import { DiscountRestrictionEnum } from "@app/models/discount-restriction-enum";
import { DiscountCodeTypeEnum } from "@app/models/discount-code-type-enum";
import { CompaniesLogicService } from "@app/_services/companies-logic.service";
import { DiscountsLogicService } from "@app/_services/discounts-logic.service";
import { NotificationsService } from "@app/_services/notifications.service";
import { MatSelect } from "@angular/material/select";
import { take, takeUntil } from "rxjs/operators";
import { MatOption } from "@angular/material/core";
import { MatPaginator } from "@angular/material/paginator";
import { SsoSourcesEnum } from "@app/models/sso-sources.enum";
import { Discount } from "@app/interfaces/discount.interface";
import {
  addToTableDataSource,
  dateToFirestoreTimestamp,
  removeFromTableDataSource,
  updateInTableDataSource,
} from "@app/util/helper";

@Component({
  selector: "app-discounts",
  templateUrl: "./discounts.component.html",
  styleUrls: ["./discounts.component.css"],
})
export class DiscountsComponent implements OnInit, AfterViewInit, OnDestroy {
  discountForm: FormGroup;
  isFormExpanded: boolean;
  isEditing = false;
  discountTypeList = discountTypeList;
  discountTypeEnum = DiscountTypeEnum;
  productList: any[];
  companyList: Company[];
  companyFilterList: CompanyFilter[] = [];
  partnerCompanies = Object.values(SsoSourcesEnum).sort((a, b) => a.localeCompare(b));
  tableDataSrc: MatTableDataSource<Discount> = new MatTableDataSource();
  columnsToDisplay = [
    "name",
    "modifiedOn",
    "companyNames",
    "external",
    "discountCodes",
    "productId",
    "packageId",
    "positionIds",
    "formattedValue",
    "action",
  ];
  filterValues = {};
  packageList = {};
  positionsList = {};
  discountRestrictionEnum = DiscountRestrictionEnum;
  discountCodeTypeEnum = DiscountCodeTypeEnum;
  editingDiscount: Discount;
  private subscriptions: Subscription[] = [];
  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompany: ReplaySubject<CompanyFilter[]> = new ReplaySubject<CompanyFilter[]>(1);
  @ViewChild("allSelected") private allSelected: MatOption;
  @ViewChild("multiSelect", { static: true }) multiSelect: MatSelect;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("paginator") paginator: MatPaginator;

  protected _onDestroy = new Subject<void>();
  keyValueQueryMap = new Map();
  discountCount: number;

  constructor(
    private ns: NotificationsService,
    private fb: FormBuilder,
    private cls: CompaniesLogicService,
    private dls: DiscountsLogicService,
    private services: ServicesLogicService,
    private apls: AccountingPositionsLogicService
  ) {}

  ngOnInit() {
    this.discountForm = this.fb.group({
      name: ["", [Validators.required], [UniqueDiscountNameValidator(this.dls)]],
      type: ["", [Validators.required]],
      value: [1, [Validators.required, Validators.min(1)]],
      productId: [""],
      packageId: [""],
      positionIds: [""],
      restriction: [DiscountRestrictionEnum.Unrestricted, [Validators.required]],
      codeType: [""],
      discountCodeStartDate: [""],
      discountCodeEndDate: [""],
      companyId: [""],
      external: [""],
      oneTimeCode: [false],
      discountCode: [""],
      shortDescription: [""],
    });
    this.isFormExpanded = false;
    this.companyList = this.cls.companiesList;

    const services = this.services.allServices.filter((s) => s.packages.map((p) => p.disabled).indexOf(false) >= 0);
    this.productList = services;
    services.forEach((service) => {
      this.packageList[service.id] = service.packages.map((x) => {
        return {
          value: x.package_id,
          label: x.name,
        };
      });
    });

    this.apls.allPositions.forEach((pos) => {
      this.positionsList[pos.ordertype] = this.positionsList[pos.ordertype] || [];
      this.positionsList[pos.ordertype].push({
        value: pos.id,
        label: pos.name,
      });
    });

    this.loadDiscounts();
    this.setTableFilter();

    this.subscriptions.push(
      this.dls.onDataRefresh.subscribe(() => {
        this.loadDiscounts();
        this.setTableFilter();
      })
    );

    this.productIdCtl.valueChanges.subscribe(() => {
      this.packageId.reset();
      this.positionIds.reset();
    });
    this.packageId.valueChanges.subscribe(() => {
      this.positionIds.reset();
    });

    this.setCompanyFilters();
  }

  get name() {
    return this.discountForm.get("name");
  }

  get type() {
    return this.discountForm.get("type").value;
  }

  get value() {
    return this.discountForm.get("value").value;
  }

  get productId() {
    return this.discountForm.get("productId").value;
  }

  get productIdCtl() {
    return this.discountForm.get("productId");
  }

  get packageId() {
    return this.discountForm.get("packageId");
  }

  get positionIds() {
    return this.discountForm.get("positionIds");
  }

  get restriction() {
    return this.discountForm.get("restriction");
  }

  get codeType() {
    return this.discountForm.get("codeType");
  }

  get companyId() {
    return this.discountForm.get("companyId").value;
  }

  get companyCtl() {
    return this.discountForm.get("companyId");
  }

  get externalCtl() {
    return this.discountForm.get("external");
  }

  get discountCodeCtl() {
    return this.discountForm.get("discountCode");
  }

  get discountCodeStartDate() {
    return this.discountForm.get("discountCodeStartDate");
  }

  get discountCodeEndDate() {
    return this.discountForm.get("discountCodeEndDate");
  }

  set type(value: string) {
    this.discountForm.get("type").setValue(value);
  }

  set value(value: number) {
    this.discountForm.get("value").setValue(value);
  }

  set productId(value: string) {
    this.discountForm.get("productId").setValue(value);
  }

  set companyId(value: string[]) {
    this.discountForm.get("companyId").setValue(value);
  }

  set external(value: string[]) {
    this.discountForm.get("external").setValue(value);
  }

  set codeTypeValue(value: string) {
    this.discountForm.get("codeType").setValue(value);
  }

  set discountCodeValue(value: string) {
    this.discountForm.get("discountCode").setValue(value);
  }

  set oneTimeCodeValue(value: boolean) {
    this.discountForm.get("oneTimeCode").setValue(value);
  }

  set shortDescriptionValue(value: string) {
    this.discountForm.get("shortDescription").setValue(value);
  }

  toggleFormExpansion() {
    this.isFormExpanded = !this.isFormExpanded;
  }

  async onSubmit() {
    const discountCodes = [];
    if (this.discountCodeCtl.value) {
      discountCodes.push(this.discountCodeCtl.value);
    }
    delete this.discountForm.value["discountCode"];
    const data = {
      ...this.discountForm.value,
      discountCodes: discountCodes,
      productId: this.productId || "",
      companyId: this.companyId,
      name: this.name.value,
    };

    const now = dateToFirestoreTimestamp(new Date());
    data.modifiedOn = now;
    if (!this.isEditing) {
      data.createdOn = now;
    }

    try {
      await this.dls.createOrReplace(data).toPromise();

      // When reading companies from the backend we do this resolution in the backend. Since we now directly pass data
      // to the table we need to resolve the company names manually.
      data.companyRef = this.resolveCompanyIds(data.companyId);
      if (this.isEditing) {
        updateInTableDataSource(this.tableDataSrc, data.name, { ...this.editingDiscount, ...data });
        this.cancelEditing();
        this.ns.showNotification("Rabatt erfolgreich geupdated.", "success");
      } else {
        const newDiscount = { ...data, id: data.name };
        addToTableDataSource(this.tableDataSrc, newDiscount);
        this.discountForm.reset();
        this.ns.showNotification("Rabatt erfolgreich hinzugefügt.", "success");
      }
    } catch (err) {
      console.error(err);
      this.ns.showNotification(
        "Die Aktion konnte nicht abgeschlossen werden. Bitte versuchen Sie es erneut.",
        "danger"
      );
    }
  }

  private resolveCompanyIds(companyIds: string[]) {
    const companyRefs = companyIds.map((companyId) => {
      const company = this.companyList.find((company) => company.id === companyId);
      return { name: company.name, id: company.id };
    });

    return companyRefs;
  }

  private setCompanyFilters() {
    this.companyList.forEach((x) => {
      this.companyFilterList.push({ cid: x.cid, name: x.name + (x.supplement ? " - " + x.supplement : "") });
    });
    this.filteredCompany.next(this.companyFilterList);
    this.companyFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCompanyMulti();
    });
  }

  private setTableFilter(): void {
    this.tableDataSrc.filterPredicate = (object) => {
      let flag = true;
      Object.keys(this.filterValues).forEach((key) => {
        if (
          this.filterValues[key] &&
          !Array.isArray(object[key]) &&
          object[key].toLowerCase().indexOf(this.filterValues[key]) < 0
        ) {
          flag = false;
        } else if (
          this.filterValues[key] &&
          Array.isArray(object[key]) &&
          object[key].findIndex((i) => i.toLowerCase() === this.filterValues[key]) < 0
        ) {
          flag = false;
        }
      });
      return flag;
    };
  }

  updateFilterValues($event, column: string) {
    let value = $event.currentTarget?.["value"]?.trim() || $event.value;

    if (this.keyValueQueryMap.has(column)) {
      this.keyValueQueryMap.delete(column);
    }
    if (value) {
      this.keyValueQueryMap.set(column, value);
    }
    this.filterTable($event, column);
  }

  filterTable(event: Event, column: string): void {
    const query = event.currentTarget["value"].trim();
    this.filterValues[column] = query;
    this.paginator.pageIndex = 0;
    this.loadDiscounts();
  }

  loadDiscounts(): void {
    this.dls
      .getAllDiscountsWithPaginator(
        this.paginator?.pageIndex,
        this.paginator?.pageSize,
        this.sort?.active,
        this.sort?.direction,
        this.keyValueQueryMap
      )
      .subscribe((discountList) => {
        this.tableDataSrc.data = discountList.data;
        this.discountCount = discountList.total_hits;
      });
  }

  noSort(event: Event) {
    event?.preventDefault();
    event?.stopPropagation();
  }

  editItem(discountId: string) {
    this.isEditing = true;
    this.isFormExpanded = true;
    this.editingDiscount = this.tableDataSrc.data.find((item) => item.id === discountId);

    this.name.setValue(this.editingDiscount.id);
    this.type = this.editingDiscount.type;
    this.value = this.editingDiscount.value;
    this.productId = this.editingDiscount.productId || "";
    this.companyId = this.editingDiscount.companyRef.map((i) => i.id);
    this.discountForm.patchValue({ companyId: this.companyId });
    this.packageId.setValue(this.editingDiscount.packageId);
    this.positionIds.setValue(this.editingDiscount.positionIds);
    if (this.editingDiscount.companyRef && this.editingDiscount.companyRef.length > 0) {
      this.restriction.setValue(DiscountRestrictionEnum.Company);
    }
    if (this.editingDiscount.restriction) {
      if (this.editingDiscount.restriction === DiscountRestrictionEnum.External) {
        this.restriction.setValue(DiscountRestrictionEnum.External);
        this.external = this.editingDiscount.external;
      }
      if (this.editingDiscount.restriction === DiscountRestrictionEnum.Unrestricted) {
        this.restriction.setValue(DiscountRestrictionEnum.Unrestricted);
      }
      if (this.editingDiscount.restriction === DiscountRestrictionEnum.DiscountCodes) {
        this.restriction.setValue(DiscountRestrictionEnum.DiscountCodes);
      }
    }
    if (this.editingDiscount.codeType) {
      this.restriction.setValue(DiscountRestrictionEnum.DiscountCodes);
      this.discountCodeStartDate.patchValue(this.editingDiscount.discountCodeStartDate);
      this.discountCodeEndDate.patchValue(this.editingDiscount.discountCodeEndDate);
      if (this.editingDiscount.codeType === DiscountCodeTypeEnum.FixedCode) {
        this.codeType.setValue("fixedCode");
        if (this.editingDiscount.discountCodes && this.editingDiscount.discountCodes.length > 0) {
          this.discountCodeValue = this.editingDiscount.discountCodes[0];
        }
      }
    }
    if (this.editingDiscount.oneTimeCode) {
      this.oneTimeCodeValue = true;
    }
    if (this.editingDiscount.shortDescription) {
      this.shortDescriptionValue = this.editingDiscount.shortDescription;
    }

    this.name.disable();
  }

  cancelEditing() {
    this.isEditing = false;
    this.isFormExpanded = false;
    this.discountForm.reset();
    this.discountForm.enable();
    this.name.setAsyncValidators([UniqueDiscountNameValidator(this.dls)]);
    this.name.updateValueAndValidity();
  }

  async removeItem(discountId: string, event: Event) {
    event.stopPropagation();
    event.preventDefault();
    const confirmed = confirm(
      "Möchten Sie den Rabatt wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!"
    );
    if (confirmed) {
      try {
        await this.dls.delete(discountId);
        removeFromTableDataSource(this.tableDataSrc, discountId);
        this.ns.showNotification("Rabatt erfolgreich entfernt.", "success");
      } catch (error) {
        console.error(error);
        this.ns.showNotification(
          "Die Aktion konnte nicht abgeschlossen werden. Bitte versuchen Sie es erneut.",
          "danger"
        );
      }
    }
  }

  ngAfterViewInit() {
    this.setInitialValue();
    this.tableDataSrc.sort = this.sort;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((x) => x.unsubscribe());
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  selectAllCompanies() {
    if (this.allSelected.selected) {
      this.discountForm.controls.companyId.patchValue([...this.companyFilterList.map((item) => item.cid), 0]);
    } else {
      this.discountForm.controls.companyId.patchValue([]);
    }
  }

  selectSingleCompany() {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.discountForm.controls.companyId.value.length == this.companyFilterList.length) this.allSelected.select();
  }

  protected filterCompanyMulti() {
    if (!this.companyFilterList) {
      return;
    }

    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filteredCompany.next(this.companyFilterList);
    } else {
      search = search.toLowerCase();
      this.filteredCompany.next(
        this.companyFilterList.filter((company) => company.name.toLowerCase().indexOf(search) > -1)
      );
    }
  }

  protected setInitialValue() {
    this.filteredCompany.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      if (this.multiSelect)
        this.multiSelect.compareWith = (a: CompanyFilter, b: CompanyFilter) => a && b && a.cid === b.cid;
    });
  }

  updateRestriction($event: MatButtonToggleChange) {
    switch ($event.value) {
      case DiscountRestrictionEnum.Unrestricted:
        this.companyCtl.setValidators(null);
        this.externalCtl.setValidators(null);
        this.codeTypeValue = undefined;
        break;
      case DiscountRestrictionEnum.Company:
        if (this.companyId && this.companyId.length > 0) {
          this.discountForm.patchValue({ companyId: this.companyId });
        } else {
          this.companyCtl.patchValue(null);
        }
        this.companyCtl.setValidators([Validators.required]);
        this.codeTypeValue = undefined;
        this.externalCtl.setValidators(null);

        break;
      case DiscountRestrictionEnum.External:
        this.externalCtl.patchValue(null);
        this.companyCtl.setValidators(null);
        this.codeTypeValue = undefined;
        this.externalCtl.setValidators([Validators.required]);
        break;
      case DiscountRestrictionEnum.DiscountCodes:
        this.codeTypeValue = DiscountCodeTypeEnum.FixedCode;
        this.externalCtl.setValidators(null);
        this.companyCtl.setValidators(null);
        this.discountCodeCtl.setValidators([Validators.required]);
        break;
    }
    this.companyCtl.updateValueAndValidity();
    this.externalCtl.updateValueAndValidity();
    this.discountCodeCtl.updateValueAndValidity();
    this.discountForm.updateValueAndValidity();
  }

  addNoSortWhitespace($event) {
    this.noSort($event);
    $event.target.value += " ";
  }
}
