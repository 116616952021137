import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { PackageDeliveryTimeItem } from "@app/interfaces/package-delivery-time-item.interface";
import { CONSTANTS } from "@app/util/constants";
import { Observable } from "rxjs";
import { ConfigurationLogicService } from "./configuration-logic.service";

@Injectable({
  providedIn: "root",
})
export class PackagesDeliveryTimesConfigResolverService implements Resolve<PackageDeliveryTimeItem[]> {
  constructor(private cs: ConfigurationLogicService) {}

  resolve() {
    return <Observable<PackageDeliveryTimeItem[]>>(
      this.cs.getConfiguration(CONSTANTS.CONFIGURATIONS.PACKAGES_DELIVERY_TIMES)
    );
  }
}
