import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { CONSTANTS } from '@app/util/constants';
import { Webhook } from '@app/interfaces/webhook.interface';

@Injectable({
  providedIn: 'root'
})
export class EventSubscriptionsService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.EVENT_SUBSCRIPTIONS;

  constructor(
    private http: HttpClient
  ) {

  }

  postEventSubscription(data: Webhook) {
    return this.http.post(this.endpoint, data);
  }

  getEventSubscriptions(companyId: string) {
    return this.http.get<Webhook[]>(this.endpoint + `/${companyId}`);
  }
}
