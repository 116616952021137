import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '@app/interfaces/user.interface';

@Component({
  selector: 'app-company-role-history-modal',
  templateUrl: './company-role-history-modal.component.html',
  styleUrls: ['./company-role-history-modal.component.css', './../../../styles/table.css']
})
export class CompanyRoleHistoryModalComponent implements OnInit {

  user: User;
  displayColumns = ["timestamp", "oldRole", "newRole", "changedByName"];
  roleHistorySrc = new MatTableDataSource([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.user = data;
    this.roleHistorySrc = new MatTableDataSource(this.user.companyRoleHistory);
  }

  ngOnInit() {
  }

}
