import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { User } from "@app/interfaces/user.interface";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { ContactPerson } from "@app/interfaces/suborder.interface";
import { buildQueryString } from "@app/util/helper";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  private readonly apiBaseUrl = environment.apiUrl + "users/";

  constructor(private http: HttpClient) {}

  getUser(id: string): Observable<User> {
    return this.http.get<User>(this.apiBaseUrl + id);
  }

  getUsers(): Observable<QueryResult<User>> {
    return this.http.get<QueryResult<User>>(this.apiBaseUrl);
  }

  getUsersWithPaginator(from, to, sortBy, sortDirection, queryMap): Observable<QueryResult<User>> {
    const queryString = buildQueryString(from, to, sortBy, sortDirection, queryMap);
    return this.http.get<QueryResult<User>>(this.apiBaseUrl + queryString);
  }

  getUsersByRole(path: string): Observable<User[]> {
    return this.http.get<User[]>(this.apiBaseUrl + path);
  }

  getUserByProperty(property: string, value: string): Observable<User[]> {
    return this.http.get<User[]>(this.apiBaseUrl + property + "/" + value);
  }

  patchUser(uid: string, data: object) {
    return this.http.patch(this.apiBaseUrl + uid, data);
  }

  getOrderTypeResponsible(orderType: OrderTypeEnum) {
    return this.http.get<ContactPerson>(this.apiBaseUrl + "order-type-responsible/" + orderType);
  }
}
