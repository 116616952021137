import { Injectable } from '@angular/core';
import { EventSubscriptionsService } from '@app/_services/event-subscriptions.service';
import { Webhook } from '@app/interfaces/webhook.interface';

@Injectable({
  providedIn: 'root'
})
/**
 * Service used to manage event subscriptions on the imogent platfrom. Those event subscriptions will be utilized to send webhooks to external endpoints.
 */
export class EventSubscriptionsLogicService {

  constructor(
    private ess: EventSubscriptionsService
  ) { }

  /**
   * Subscribes to the specified event. Note, that the corresponding company can be defined with the companyId property.
   * @param event
   */
  createEventSubscription(event: Webhook) {
    return this.ess.postEventSubscription(event).toPromise();
  }

  /**
   * Retrieves the event subscriptions of a given company.
   * @param companyId
   */
  getEventSubscriptions(companyId: string) {
    return this.ess.getEventSubscriptions(companyId).toPromise();
  }
}
