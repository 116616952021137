<mat-card appearance="outlined" class="title-card mb-2">
  <mat-card-title>Wählen Sie Ihre Zahlungsmethode</mat-card-title>
</mat-card>
<div class="row" *ngIf="visiblePaymentOptions.includes(paymentOptionEnum.Collective)">
  <div class="col-12">
    <mat-card
      appearance="outlined"
      [tooltip]="
        disabledPaymentOptions.includes(paymentOptionEnum.Collective) ? disabledPaymentOptionTooltipText : undefined
      "
      [adaptivePosition]="false"
      class="option-card collective-option mb-4 clickable"
      [ngClass]="{
        expanded: isCollectivePaymentOptionExpanded,
        selected: selectedPaymentOption === paymentOptionEnum.Collective,
        'is-invalid': hasMainFormSubmitted && errorType === paymentOptionEnum.Collective,
        disabled: disabledPaymentOptions.includes(paymentOptionEnum.Collective)
      }"
      (click)="selectPaymentOption(paymentOptionEnum.Collective)"
    >
      <mat-card-content class="py-0">
        <div class="d-flex align-items-center">
          <div
            class="icon-container"
            [style.visibility]="selectedPaymentOption === paymentOptionEnum.Collective ? 'visible' : 'hidden'"
          >
            <span class="material-icons text-primary">check_circle</span>
          </div>
          <div class="pl-2">
            <strong>
              <span class="option-card-title mr-2">Sammelrechnung</span>
              <small *ngIf="collectiveInvoiceInterval === collectiveInvoiceIntervalEnum.Monthly">(Monatlich)</small>
              <small *ngIf="collectiveInvoiceInterval === collectiveInvoiceIntervalEnum.WeeklyCompany"
                >(Abrechnung 2x wöchentlich - Unternehmensrechnung)</small
              >
              <small *ngIf="collectiveInvoiceInterval === collectiveInvoiceIntervalEnum.Individual"
                >(Individuelles Abrechnungsintervall)</small
              >
              <small *ngIf="collectiveInvoiceInterval === collectiveInvoiceIntervalEnum.Weekly"
                >(Abrechnung 2x wöchentlich)</small
              >
            </strong>
            <span class="pl-4 text-primary font-italic clickable" (click)="toggleCollectivePaymentOptionExpansion()">
              Details {{ isCollectivePaymentOptionExpanded ? "ausblenden" : "einblenden" }}
            </span>
          </div>
          <div class="ml-auto">
            <span
              *ngIf="!isCollectivePaymentOptionExpanded"
              class="material-icons clickable expansion-icon"
              (click)="toggleCollectivePaymentOptionExpansion()"
              >format_list_bulleted</span
            >
          </div>
        </div>
        <div *ngIf="isCollectivePaymentOptionExpanded" class="row">
          <div class="col-12 px-5">
            <p
              *ngIf="
                collectiveInvoiceInterval !== collectiveInvoiceIntervalEnum.Individual &&
                collectiveInvoiceInterval !== collectiveInvoiceIntervalEnum.Monthly
              "
            >
              Bei Bestellungen mit einem Warenwert von über 1000€ (netto), die Visualisierungen enthalten, wird eine
              Anzahlung von 50% der Bestellsumme bereits nach Prüfung des Auftrages berechnet und ist mit einem
              Zahlungsziel von 10 Tagen fällig. Neue Aufträge sowie Restzahlungen für Aufträge über 1000€ (netto) werden
              nach erbrachter Leistung montags und donnerstags kumuliert abgerechnet. Sie erhalten eine Sammelrechnung
              per Mail mit einem Zahlungsziel von 10 Tagen.<br /><br />

              Im Premiumabo sind auch Monatsrechnungen möglich. Kontaktieren Sie uns bei Interesse gerne unter
              service@imogent.com.
            </p>
            <p *ngIf="collectiveInvoiceInterval === collectiveInvoiceIntervalEnum.Monthly">
              Bei Bestellungen mit einem Warenwert von über 1000€ (netto), die Visualisierungen enthalten, wird eine
              Anzahlung von 50% der Bestellsumme bereits nach Prüfung des Auftrages berechnet und ist mit einem
              Zahlungsziel von 10 Tagen fällig. Aufträge des letzten Monats sowie Restzahlungen für Aufträge über 1000€
              (netto) werden nach erbrachter Leistung zum Ende des Monats kumuliert abgerechnet. Sie erhalten eine
              Sammelrechnung per Mail mit einem Zahlungsziel von 10 Tagen.<br /><br />
              <strong
                ><span class="option-card-title"
                  >Wichtiger Hinweis für die monatliche Sammelrechnung (30 Tage):</span
                ></strong
              ><br />
              Der Zahlungsverkehr der monatlichen Sammelrechnungen wird über die abcfinance GmbH abgewickelt. Bitte
              beachten Sie den geänderten Zahlungsempfänger in der Rechnung.<br />
              IMOGENT ist berechtigt, Forderungen gegen in Deutschland und Ländern der EU sitzende Besteller zur
              Refinanzierung an die abcfinance GmbH, Kamekestr. 2-8, 50672 Köln, abzutreten.<br />
            </p>
            <p *ngIf="collectiveInvoiceInterval === collectiveInvoiceIntervalEnum.Individual">
              Abgeschlossene Aufträge werden nach denen mit Ihnen vereinbarten Bedingungen abgerechnet. Sie erhalten
              eine Sammelrechnung per Mail. Die Rechnung ist mit einem Zahlungsziel von 10 Tagen zu begleichen.
            </p>
            <p>
              <small class="text-primary font-italic"
                >Um eine separate Rechnung für diese Bestellung zu erhalten, nutzen Sie die Option "Kauf auf
                Rechnung".</small
              >
            </p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-3" *ngIf="visiblePaymentOptions.includes(paymentOptionEnum.Sepa)">
    <mat-card
      appearance="outlined"
      class="option-card sepa-option mb-4 clickable"
      [ngClass]="{
        selected: selectedPaymentOption === paymentOptionEnum.Sepa && canUseSEPA,
        'is-invalid': hasMainFormSubmitted && errorType === paymentOptionEnum.Sepa,
        disabled: disabledPaymentOptions.includes(paymentOptionEnum.Sepa) || !canUseSEPA
      }"
      [tooltip]="sepaTooltipText"
      [adaptivePosition]="false"
      (click)="selectPaymentOption(paymentOptionEnum.Sepa)"
    >
      <mat-card-title class="d-flex justify-content-center align-items-center"> SEPA </mat-card-title>
    </mat-card>
  </div>
  <div class="col-12 col-md-3" *ngIf="visiblePaymentOptions.includes(paymentOptionEnum.Paypal)">
    <mat-card
      appearance="outlined"
      class="option-card mb-4 clickable"
      [tooltip]="
        disabledPaymentOptions.includes(paymentOptionEnum.Paypal) ? disabledPaymentOptionTooltipText : undefined
      "
      [adaptivePosition]="false"
      [ngClass]="{
        selected: selectedPaymentOption === paymentOptionEnum.Paypal,
        'is-invalid': hasMainFormSubmitted && errorType === paymentOptionEnum.Paypal,
        disabled: disabledPaymentOptions.includes(paymentOptionEnum.Paypal)
      }"
      (click)="selectPaymentOption(paymentOptionEnum.Paypal)"
    >
      <mat-card-title class="d-flex justify-content-center align-items-center">
        <i class="fab fa-paypal"></i>
      </mat-card-title>
    </mat-card>
  </div>
  <div class="col-12 col-md-3" *ngIf="visiblePaymentOptions.includes(paymentOptionEnum.Visa)">
    <mat-card
      appearance="outlined"
      class="option-card mb-4 clickable"
      [tooltip]="disabledPaymentOptions.includes(paymentOptionEnum.Visa) ? disabledPaymentOptionTooltipText : undefined"
      [ngClass]="{
        selected: selectedPaymentOption === paymentOptionEnum.Visa,
        'is-invalid': hasMainFormSubmitted && errorType === paymentOptionEnum.Visa,
        disabled: disabledPaymentOptions.includes(paymentOptionEnum.Visa)
      }"
      (click)="selectPaymentOption(paymentOptionEnum.Visa)"
    >
      <mat-card-title class="d-flex justify-content-center align-items-center">
        <i class="fab fa-cc-visa"></i>
      </mat-card-title>
    </mat-card>
  </div>
  <div class="col-12 col-md-3" *ngIf="visiblePaymentOptions.includes(paymentOptionEnum.OnAccount)">
    <mat-card
      appearance="outlined"
      class="option-card on-account-option mb-4 clickable"
      [tooltip]="
        disabledPaymentOptions.includes(paymentOptionEnum.OnAccount) ? disabledPaymentOptionTooltipText : undefined
      "
      [ngClass]="{
        selected: selectedPaymentOption === paymentOptionEnum.OnAccount,
        'is-invalid': hasMainFormSubmitted && errorType === paymentOptionEnum.OnAccount,
        disabled: disabledPaymentOptions.includes(paymentOptionEnum.OnAccount)
      }"
      (click)="selectPaymentOption(paymentOptionEnum.OnAccount)"
    >
      <mat-card-title class="d-flex justify-content-center align-items-center"> Kauf Auf Rechnung </mat-card-title>
    </mat-card>
  </div>
</div>
<div *ngIf="hasMainFormSubmitted && !selectedPaymentOption" class="alert alert-warning mb-4">
  Bitte wählen Sie eine Zahlungsoption
</div>
<div
  class="row mb-4"
  [style.display]="selectedPaymentOption && selectedPaymentOption !== paymentOptionEnum.Collective ? 'block' : 'none'"
>
  <div class="col-12">
    <form [formGroup]="paymentForm">
      <mat-card
        appearance="outlined"
        class="option-card-info"
        *ngIf="selectedPaymentOption === paymentOptionEnum.Sepa && canUseSEPA"
        [ngClass]="{
          'is-invalid': hasMainFormSubmitted && errorType === paymentOptionEnum.Sepa
        }"
      >
        <mat-card-content>
          <div class="row">
            <div class="col-12 col-md-6">
              <ng-container *ngIf="directDebitMandates.length === 0; else showPaymentMandates">
                <strong>Sie haben noch kein SEPA-Mandat angelegt.</strong><br />
                Legen Sie ein Mandat in Ihrem Profil an und kehren Sie anschließend zum Bestellprozess zurück. Bitte
                schließen Sie diesen Tab nicht!
              </ng-container>
              <ng-template #showPaymentMandates>
                <span class="mb-3"><strong>Hinterlegte Konten</strong></span
                ><br />
                <mat-radio-group formControlName="mandateId" (change)="selectMandate($event.value)">
                  <mat-radio-button class="d-block" *ngFor="let mandate of directDebitMandates" [value]="mandate.id">{{
                    $any(mandate.details).consumerName + " (" + $any(mandate.details).consumerAccount + ")"
                  }}</mat-radio-button>
                </mat-radio-group>
              </ng-template>
            </div>
            <div
              class="col-12 col-md-6"
              [ngClass]="{
                'd-flex align-items-center flex-row-reverse': directDebitMandates.length === 0
              }"
            >
              <ng-container *ngIf="directDebitMandates.length > 0">
                <strong>Info</strong><br />
                Sie haben die Möglichkeit ein weiteres Mandat in Ihrem Profil anzulegen. Bitte schließen Sie diesen Tab
                nicht. Sobald ein weiteres Mandat hinterlegt wurde, wird Ihnen dieses automatisch angezeigt.
                <br /><br />
                Bei Bestellungen mit einem Warenwert von über 1000€ (netto), die Visualisierungen enthalten, wird eine
                Anzahlung von 50% der Bestellsumme bereits nach Prüfung des Auftrages eingezogen. Beträge mit einem Wert
                von unter 1000€ (netto) sowie Restzahlungen für Aufträge über 1000€ (netto) werden nach erbrachter
                Leistung eingezogen.
              </ng-container>
              <br />
              <a class="btn btn-back mr-5" [routerLink]="['/profile']" [fragment]="'sepa'" target="_blank"
                >Zum Profil</a
              >
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card
        appearance="outlined"
        class="option-card-info"
        *ngIf="selectedPaymentOption === paymentOptionEnum.Paypal"
        [ngClass]="{
          'is-invalid': hasMainFormSubmitted && errorType === paymentOptionEnum.Paypal
        }"
      >
        <mat-card-content>
          <div class="row">
            <ng-container *ngIf="paypalMandates.length > 0">
              <div class="col-12 col-md-6">
                <strong>Liste der Mandatszahlungen</strong><br />
                <mat-radio-group formControlName="mandateId" (change)="selectMandate($event.value)">
                  <mat-radio-button class="d-block" *ngFor="let mandate of paypalMandates" [value]="mandate.id">{{
                    $any(mandate.details).consumerName + " (" + $any(mandate.details).consumerAccount + ")"
                  }}</mat-radio-button>
                  <mat-radio-button value="">Neues Zahlungsmandat hinzufügen</mat-radio-button><br />
                </mat-radio-group>
              </div>
            </ng-container>
            <div
              class="col-12"
              [ngClass]="{
                'col-md-6': paypalMandates.length > 0
              }"
            >
              <strong>Info</strong><br />
              Bei Abschluss der Bestellung werden Sie automatisch an PayPal weitergeleitet, um die Zahlung zu tätigen.
              Bei der Prüfung Ihrer Bestellung kann es zu Preiskorrekturen kommen. Sollte dies bei Ihrem Auftrag der
              Fall sein, passen wir den Betrag an und leiten die notwendigen Schritte ein (Rückerstattung /
              Nachzahlung). Selbstverständlich werden Sie vorab über jede Anpassung informiert und haben die Möglichkeit
              Ihren Auftrag rechtzeitig zu stornieren.
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card
        appearance="outlined"
        class="option-card-info"
        [style.display]="selectedPaymentOption === paymentOptionEnum.Visa ? 'block' : 'none'"
        [ngClass]="{
          'is-invalid': hasMainFormSubmitted && errorType === paymentOptionEnum.Visa
        }"
      >
        <mat-card-content>
          <div class="row">
            <div class="col-12 col-md-6">
              <div [style.display]="creditCardMandates.length > 0 ? 'block' : 'none'">
                <strong>Liste der Mandatszahlungen</strong><br />
                <mat-radio-group formControlName="mandateId" (change)="selectMandate($event.value)">
                  <mat-radio-button class="d-block" *ngFor="let mandate of creditCardMandates" [value]="mandate.id">{{
                    "**** **** **** " + $any(mandate.details).cardNumber + " (" + $any(mandate.details).cardLabel + ")"
                  }}</mat-radio-button>
                  <mat-radio-button value="">Neues Zahlungsmandat hinzufügen</mat-radio-button><br />
                </mat-radio-group>
                <br />
              </div>
              <div [style.display]="!mandateId.value ? 'block' : 'none'">
                <div class="form-group">
                  <p class="label m-0" for="card-holder"><strong>Name auf der Karte</strong></p>
                  <div id="card-holder"></div>
                </div>
                <div id="card-holder-error" class="mb-2" role="alert" #cardHolderError></div>
                <div class="form-group">
                  <p class="label m-0" for="card-number"><strong>Kartennummer</strong></p>
                  <div id="card-number"></div>
                </div>
                <div id="card-number-error" class="mb-2" role="alert" #cardNumberError></div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <p class="label m-0" for="expiry-date"><strong>Gültig bis</strong></p>
                      <div id="expiry-date"></div>
                    </div>
                    <div id="expiry-date-error" class="mb-2" role="alert" #expiryDateError></div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <p class="label m-0" for="verification-code"><strong>Kartenprüfnummer</strong></p>
                      <div id="verification-code"></div>
                    </div>
                    <div id="verification-code-error" class="mb-2" role="alert" #verificationCodeError></div>
                  </div>
                </div>
                <div id="credit-card-form-error" class="mb-2" role="alert" #creditCardFormError></div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <strong>Info</strong><br />
              Bei der Prüfung Ihrer Bestellung kann es zu Preiskorrekturen kommen. Sollte dies bei Ihrem Auftrag der
              Fall sein, passen wir den Betrag an und leiten die notwendigen Schritte ein (Rückerstattung /
              Nachzahlung). Selbstverständlich werden Sie vorab über jede Anpassung informiert und haben die Möglichkeit
              Ihren Auftrag rechtzeitig zu stornieren.
              <br /><br />
              Bei Bestellungen mit einem Warenwert von über 1000€ (netto), die Visualisierungen enthalten, wird eine
              Anzahlung von 50% der Bestellsumme bereits nach Prüfung des Auftrages eingezogen. Beträge mit einem Wert
              von unter 1000€ (netto) sowie Restzahlungen für Aufträge über 1000€ (netto) werden nach erbrachter
              Leistung eingezogen.
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card
        appearance="outlined"
        class="option-card-info"
        *ngIf="selectedPaymentOption === paymentOptionEnum.OnAccount"
        [ngClass]="{
          'is-invalid': hasMainFormSubmitted && errorType === paymentOptionEnum.OnAccount
        }"
      >
        <mat-card-content>
          <div class="row">
            <div class="col-12">
              <strong>Info</strong><br />
              Bitte überprüfen Sie die unter "Rechnungsadresse" angegebenen Rechnungsdetails und passen Sie diese ggf.
              an. Bei Bestellungen mit einem Warenwert von über 1000€ (netto), die Visualisierungen enthalten, wird eine
              Anzahlung von 50% der Bestellsumme bereits nach Prüfung des Auftrages berechnet und ist mit einem
              Zahlungsziel von 10 Tagen fällig. Beträge mit einem Wert von unter 1000€ (netto) sowie Restzahlungen für
              Aufträge über 1000€ (netto) werden nach erbrachter Leistung mit einem Zahlungsziel von 10 Tagen
              abgerechnet. Alle Rechnungen erhalten Sie per Mail.
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
</div>
