import { Injectable } from "@angular/core";
import { InvoiceService } from "@app/_services/invoice.service";
import { UserRoleEnum } from "@app/models/user-role-list";
import { Invoice } from "@app/interfaces/invoice.interface";
import { map, tap } from "rxjs/operators";
import { AuthService } from "@app/auth/auth.service";
import { OrdersLogicService } from "@app/_services/orders-logic.service";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { CONSTANTS } from "@app/util/constants";
import { GlobalService } from "@app/_services/global.service";
import { PaymentStatusEnum } from "@app/models/paymentStatusEnum";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { PaymentsLogicService } from "./payments-logic.service";

@Injectable({
  providedIn: "root",
})
export class InvoiceLogicService {
  public companyInvoices: Invoice[];
  public allInvoices: Invoice[] = [];

  constructor(
    private is: InvoiceService,
    private auth: AuthService,
    private ols: OrdersLogicService,
    private gs: GlobalService,
    private pls: PaymentsLogicService
  ) {}

  getAllInvoices() {
    if (this.auth.myUserObservable.role === UserRoleEnum.Customer) {
      return this.is.getMyInvoices().pipe(
        tap((data) => {
          this.setListData(data?.data, false);
        })
      );
    } else if (this.auth.myUserObservable.role === UserRoleEnum.Administrator) {
      return this.is.getInvoices().pipe(
        tap((data) => {
          this.setListData(data?.data);
        })
      );
    }
  }

  getInvoice(id: string): Promise<Invoice> {
    return this.is.getInvoice(id);
  }

  getAllInvoicesWithPaginator(
    pageIndex: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    queryMap?: Map<string, string>
  ) {
    const from = pageIndex * pageSize;
    const to = (pageIndex + 1) * pageSize;

    if (this.auth.myUserObservable.role === UserRoleEnum.Customer) {
      return this.is.getMyInvoicesWithPaginator(from, to, sortBy, sortDirection, queryMap).pipe(
        map((invoiceList: QueryResult<Invoice>) => {
          return invoiceList;
        })
      );
    } else if (this.auth.myUserObservable.role === UserRoleEnum.Administrator) {
      return this.is.getInvoicesWithPaginator(from, to, sortBy, sortDirection, queryMap).pipe(
        map((invoiceList: QueryResult<Invoice>) => {
          return invoiceList;
        })
      );
    }
  }

  getCompanyInvoices() {
    return this.is.getMyCompanyInvoices().pipe(
      tap((data) => {
        this.setListData(data, true);
      })
    );
  }

  createInvoice(invoiceData: Invoice) {
    return this.is.postInvoice("", invoiceData).subscribe(
      (val) => {
        this.allInvoices.push(invoiceData);
      },
      (response) => {
        console.log("POST call in error", response);
      },
      () => {
        console.log("The POST observable is now completed.");
      }
    );
  }

  createIndividualInvoice(invoiceData: Invoice) {
    return this.is.postInvoice("individual", invoiceData).toPromise();
  }

  updateInvoice(invoiceId, invoiceData) {
    return this.is.patchInvoice(invoiceId, invoiceData).toPromise();
  }

  private setListData(data: Invoice[], company?: boolean) {
    if (company) {
      this.companyInvoices = [...data];
    } else {
      this.allInvoices = [...data];
    }
  }

  runInvoicesScript(type: "weekly-collective-invoices" | "weekly-on-account-invoices" | "monthly-collective-invoices") {
    return this.is.postInvoice(type, {}).toPromise();
  }

  async sendSingleInvoice(orderId: SubOrder["orderId"]) {
    // Fetch newest status of the order
    const order = await this.ols.getOrder(orderId);

    // Cases: 1. Mollie payment in order-checkout, 2. Prepayment after order approval.
    const hasVisualisation = !!order.allSuborders.find((so) => so.packageTypes === OrderTypeEnum.Visualisation); // Only orders including visualisations will have prepayment invoices in feature.
    const isDirectPayment = this.pls.isMolliePaymentMethod(order.paymentOption);

    if (
      (order.approvedOn && this.gs.unformat(order.price) > CONSTANTS.ORDER_PRICE_DEPOSIT_VALUE && hasVisualisation) ||
      isDirectPayment
    ) {
      // Create prepayment invoice for orders including visus with value over 1000€ directly.
      await this.handleSingleInvoiceCreation(orderId, isDirectPayment);
    }
  }

  async handleSingleInvoiceCreation(orderId: string, isDirectPayment: boolean) {
    await this.is.postInvoice("single-invoice/" + orderId, { isDirectPayment: isDirectPayment }).toPromise();
    this.ols.updateOrder(orderId, {
      payments: PaymentStatusEnum.Invoice,
    });
  }

  /**
   * Sets all positions in the related suborders as paid.
   * @param invoiceId The invoiceId (Firebase document Id, NOT: invoice number)
   * @returns string[] A list invoice-positions that could not be updated.
   */
  async setPositionsPaid(invoiceId: string) {
    return this.is.postInvoice("set-paid/" + invoiceId, {}).toPromise();
  }

  /**
   * Exports an excel file with all the invoices with stopped dunnings.
   */
  exportStoppedDunningsInvoices() {
    return this.is.exportStoppedDunningsInvoices();
  }
}
