import { Component, Inject, OnInit } from "@angular/core";
import { LoaderService } from "@app/_services/loader.service";
import { AuthService } from "@app/auth/auth.service";
import { DOCUMENT } from "@angular/common";
import { CONSTANTS } from "@app/util/constants";
import { Router } from "@angular/router";
import { UserRoleEnum } from "@app/models/user-role-list";
import { NotificationsService } from "@app/_services/notifications.service";
import { GlobalService } from "@app/_services/global.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  showSpkLogo = false;
  year = 2020;
  isSessionRestoring: boolean;

  constructor(
    public authService: AuthService,
    public loader: LoaderService,
    private ns: NotificationsService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    public gs: GlobalService
  ) {}

  ngOnInit() {
    if (CONSTANTS.SPARKASSEN_DOMAINS.includes(document.location.hostname)) {
      this.showSpkLogo = true;
    }
    this.year = new Date().getFullYear();
    this.redirectIfLoggedIn();
  }

  /**
   * If the user is already logged-in he will be redirected to the createdOrder screen
   */
  async redirectIfLoggedIn() {
    const authorized = await this.authService.checkAuthState();
    if (authorized) {
      this.isSessionRestoring = true;
      if (this.authService.myUserObservable.role === UserRoleEnum.ServiceProvider) {
        this.router.navigate(["/orderoverview"]);
      } else {
        this.router.navigate(["/createorder"]);
      }
    }
  }

  /**
   * Called when login button is pressed. Uses the auth service to login.
   */
  login(email, password) {
    this.authService.showLoader.emit(true);
    this.authService
      .login(email.trim(), password.trim())
      .catch((err) => {
        err = err.error || err;
        if (err.message) {
          this.ns.showNotification(err.message, "danger");
        }
      })
      .finally(() => {
        this.authService.showLoader.emit(false);
      });
  }
}
