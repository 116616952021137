import { Injectable } from "@angular/core";
import { Discount } from "@app/interfaces/discount.interface";
import { HttpClient } from "@angular/common/http";
import { CONSTANTS } from "@app/util/constants";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { buildQueryString } from "@app/util/helper";

@Injectable({
  providedIn: "root",
})
export class DiscountsService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.DISCOUNTS;

  constructor(private http: HttpClient) {}

  getDiscounts(path: string): Observable<QueryResult<Discount>> {
    return this.http.get<QueryResult<Discount>>(this.endpoint + "/" + path);
  }

  getDiscountsWithPaginator(from, to, sortBy, sortDirection, queryMap): Observable<QueryResult<Discount> | Discount[]> {
    const queryString = buildQueryString(from, to, sortBy, sortDirection, queryMap);
    return this.http.get<QueryResult<Discount>>(this.endpoint + queryString);
  }

  putDiscount(data: Discount) {
    return this.http.put<Discount>(this.endpoint, data);
  }

  deleteDiscount(discountId: string) {
    return this.http.delete(this.endpoint + "/" + encodeURIComponent(discountId));
  }

  getDiscountsNameList() {
    return this.http.get<string[]>(this.endpoint + "/get-names");
  }
}
