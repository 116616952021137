import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit, AfterViewInit {
  @Input() downloadUrl: string;
  @Output() clickedUrl = new EventEmitter();
  @ViewChild('downloadTooltip') downloadTooltip: TooltipDirective;

  downloadURL_XS = '';
  downloadURL_M = '';
  downloadURL_XL = '';

  constructor() { }

  ngOnInit() {
    this.downloadURL_XS = this.downloadUrl + '&size=xs';
    this.downloadURL_M = this.downloadUrl + '&size=m';
    this.downloadURL_XL = this.downloadUrl + '&size=xl';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.downloadTooltip?.show();
    }, 3000);
  }

  encodeURIComponent() {
    return encodeURIComponent(this.downloadUrl);
  }

  onUrlClick() {
    this.clickedUrl.emit();
  }

  closeTooltip() {
    this.downloadTooltip?.hide();
  }

}
