<div *ngIf="gs.isWhitelabelVersion; else imogent" id="paddingdiv">
  <!-- Aligning the contents of the page with the logo -->
  <div class="justify-content-center text-center limitWidth300 mt-4">
    <!-- Logo -->
    <img
      class="mb-4 object-fit-contain"
      src="assets/images/Logo_png_RGB_dunkelgruen.png"
      height="94px"
      width="300px"
      *ngIf="!showSpkLogo"
    />
    <img class="mb-4 maxH140" src="assets/images/SparkassenImmo_negativ.png" *ngIf="showSpkLogo" />

    <!-- Login-Form -->
    <h3 class="b text-center" *ngIf="!isSessionRestoring">{{ "login.title" | translate }}</h3>
    <div class="mb-5" *ngIf="isSessionRestoring">
      <h3>Sitzung wird wiederhergestellt</h3>
    </div>
    <form *ngIf="!isSessionRestoring">
      <!-- E-Mail -->
      <div class="form-group">
        <label class="font-italic b sr-only" for="email">{{ "login.email" | translate }}</label>
        <input
          (keydown.enter)="login(userEmail.value, userPassword.value)"
          type="text"
          class="form-control mb-2"
          [ngClass]="{ 'form-control-sImmo': showSpkLogo }"
          autocomplete="username"
          placeholder="{{ 'login.email' | translate }}"
          #userEmail
          autofocus
        />
      </div>

      <!-- Password -->
      <div class="form-group">
        <label class="font-italic b sr-only" for="password">{{ "login.password" | translate }}</label>
        <input
          (keydown.enter)="login(userEmail.value, userPassword.value)"
          type="password"
          class="form-control mb-2"
          [ngClass]="{ 'form-control-sImmo': showSpkLogo }"
          autocomplete="current-password"
          placeholder="{{ 'login.password' | translate }}"
          #userPassword
        />
      </div>

      <!-- Form Buttons -->
      <div class="form-group">
        <!-- Login -->
        <button
          type="button"
          class="btn btn-light"
          [ngClass]="{ 'btn-spk': showSpkLogo, 'btn-primary': !showSpkLogo }"
          (click)="login(userEmail.value, userPassword.value)"
        >
          {{ "login.button_login" | translate }}
        </button>
        <!-- Go to register-page -->
        <a routerLink="/register" class="btn btn-link">{{ "login.button_register" | translate }}</a>
      </div>

      <!-- Link to Reset-Password Page -->
      <a routerLink="/forgot" class="btn btn-link pl-0">{{ "login.reset_password" | translate }}</a>
    </form>

    <!-- Footer -->
    <div class="text-center">
      <p *ngIf="showSpkLogo" style="font-style: italic">powered by</p>
      <p class="b nomarbot">&copy;{{ year }} IMOGENT GmbH</p>
      <!-- Privacy Policy & Imprint -->
      <a
        href="https://www.imogent.de/datenschutz"
        target="_blank"
        [ngClass]="{ 'my-link': !showSpkLogo, 'grey-link': showSpkLogo }"
      >
        {{ "login.privacy" | translate }}</a
      >
      <span> | </span>
      <a
        href="https://www.imogent.de/impressum"
        target="_blank"
        [ngClass]="{ 'my-link': !showSpkLogo, 'grey-link': showSpkLogo }"
      >
        {{ "login.impressum" | translate }}</a
      >
    </div>
  </div>
</div>

<ng-template #imogent>
  <div class="main">
    <div class="header col-12 p-0 mb-5">
      <div class="col-md-7 col-sm-12 px-0 pt-3 row m-auto">
        <div class="col-12 mt-2 pl-0">
          <img
            class="imo-logo mb-4 object-fit-contain"
            src="assets/images/Logo_png_RGB_dunkelgruen.png"
            height="40px"
            width="150px"
          />
        </div>
        <div class="col-12 row ml-2 mb-3">
          <div class="col-md-6 col-sm-12">
            <h3 class="col-12 font-weight-800 letter-spacing-1">Hier anmelden!</h3>

            <form class="col-md-11 col-sm-12" *ngIf="!isSessionRestoring">
              <!-- E-Mail -->
              <div class="form-group pb-0">
                <label class="font-italic b sr-only" for="email">{{ "login.email" | translate }}</label>
                <input
                  (keydown.enter)="login(userEmail.value, userPassword.value)"
                  type="text"
                  class="form-control mb-2"
                  [ngClass]="{ 'form-control-sImmo': showSpkLogo }"
                  autocomplete="username"
                  placeholder="{{ 'login.email' | translate }}*"
                  #userEmail
                  autofocus
                />
              </div>

              <!-- Password -->
              <div class="form-group">
                <label class="font-italic b sr-only" for="password">{{ "login.password" | translate }}</label>
                <input
                  (keydown.enter)="login(userEmail.value, userPassword.value)"
                  type="password"
                  class="form-control mb-2"
                  [ngClass]="{ 'form-control-sImmo': showSpkLogo }"
                  autocomplete="current-password"
                  placeholder="{{ 'login.password' | translate }}*"
                  #userPassword
                />
              </div>

              <!-- Form Buttons -->
              <div class="row form-group">
                <!-- Login -->
                <button
                  type="button"
                  class="btn btn-light font-weight-600 rounded-pill"
                  [ngClass]="{ 'btn-spk': showSpkLogo, 'btn-primary': !showSpkLogo }"
                  (click)="login(userEmail.value, userPassword.value)"
                >
                  {{ "login.button_login" | translate | uppercase }}
                </button>
                <!-- Link to Reset-Password Page -->
                <a routerLink="/forgot" class="btn btn-link px-3 text-imo font-weight-600">{{
                  "login.reset_password" | translate | uppercase
                }}</a>
              </div>
            </form>
          </div>
          <div class="col-md-5 col-sm-12 ml-5">
            <div class="col-12 p-0 mt-4">
              <h4 class="font-weight-800 letter-spacing-1">Sie haben noch kein Konto bei uns?</h4>
              <div class="font-size-normal my-3">
                Die Nutzung der Plattform ist für Sie kostenfrei. Nach wenigen Klicks können Sie direkt loslegen.
              </div>
            </div>
            <a routerLink="/register" class="btn btn-primary font-weight-600 rounded-pill">{{
              "login.button_register" | translate | uppercase
            }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="content pb-2 col-12 p-0">
      <div class="col-md-7 col-sm-10 row m-auto pb-2">
        <div class="col-md-6 col-sm-12 d-flex justify-content-end">
          <img class="service-avatar" height="300px" width="300px" src="assets/images/imogent_hero_support1.png" />
        </div>
        <div class="col-md-5 col-sm-12 p-0 ml-5">
          <div class="col-12">
            <h4 class="font-weight-800 letter-spacing-1">Haben Sie Fragen?</h4>
            <div class="font-size-normal my-3">
              Gerne steht Ihnen unser Support-Team Montag bis Freitag von 9:00 bis 17:00 Uhr zur Verfügung.
            </div>
          </div>
          <div class="col-12">
            <h5 class="font-weight-800 text-imo mb-0">service@imogent.com</h5>
            <h5 class="font-weight-800 text-imo mb-0">+49 (0) 521 54 38 26 95</h5>
          </div>
          <div class="col-12">
            <div class="font-size-normal my-3">
              Die Antworten auf die häufigsten Fragen finden Sie übrigens in unserem Hilfe-Center.
            </div>
          </div>
          <a
            class="ml-3 btn btn-primary font-weight-600 rounded-pill"
            target="_blank"
            href="https://www.imogent.de/hilfe-center/"
            >{{ "ZUM HILFE-CENTER" | uppercase }}</a
          >
        </div>
      </div>
    </div>

    <div class="col-12 footer pt-5 font-weight-bold">
      <div class="col-12 pt-5 m-auto">
        <p class="b nomarbot">&copy; {{ year }} IMOGENT GmbH</p>
        <!-- Privacy Policy & Imprint -->
        <a href="https://www.imogent.de/datenschutz" target="_blank"> {{ "login.privacy" | translate }}</a>
        <span> | </span>
        <a href="https://www.imogent.de/agb/" target="_blank">AGB</a>
        <span> | </span>
        <a href="https://www.imogent.de/impressum" target="_blank"> {{ "login.impressum" | translate }}</a>
      </div>
    </div>
  </div>
</ng-template>
