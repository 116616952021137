<div (keydown)="updateImage($event)" tabindex="0">
  <div class="pdfviewer" *ngIf="fileType === mediaTypeEnum.pdf">
    <pdf-viewer [src]="download_url" [render-text]="true" [autoresize]="true" style="display: block"></pdf-viewer>
  </div>

  <img
    class=""
    [src]="download_url"
    default
    style="max-width: 60vh; max-height: 70vh"
    *ngIf="fileType === mediaTypeEnum.image"
  />

  <video [src]="download_url" height="660px" controls *ngIf="fileType === mediaTypeEnum.video"></video>

  <iframe [src]="download_url | safeURL" *ngIf="fileType === mediaTypeEnum.tour" width="100%" height="550"></iframe>
  <hr />
  <div class="row">
    <div class="col-8 font-small-400">
      {{ filename }}
      <span *ngIf="createdOn">- {{createdOn | date: 'dd.MM.yyyy'}}</span>
    </div>
    <div class="col-4 text-right float-right" *ngIf="!hideDownload">
      <div class="btn-group">
        <a target="_blank" class="btn bg-light" [href]="download_url" download>
          <i
            class="fa fa-download fa-1x clickable text-primary"
            aria-hidden="true"
            *ngIf="fileType !== mediaTypeEnum.tour"
          ></i>
          <i class="fa fa-external-link-alt clickable text-primary" *ngIf="fileType === mediaTypeEnum.tour"></i>
        </a>
        <button
          *ngIf="fileType !== mediaTypeEnum.tour"
          type="button"
          class="btn text-primary bg-light dropdown-toggle dropdown-toggle-split px-2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div class="dropdown-menu">
          <a
            target="_blank"
            *ngFor="let downloadSize of downloadSizes"
            class="dropdown-item"
            [href]="downloadSize.url"
            download
            >{{downloadSize.label}}</a
          >
        </div>
      </div>
      <i *ngIf="delete" class="fas fa-trash text-danger ml-2 clickable" (click)="deleteAttachement($event)"></i>
    </div>
  </div>
</div>
