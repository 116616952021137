import {Component, Input, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'app-imo-info-icon',
    templateUrl: './imo-info-icon.component.html',
    styleUrls: ['./imo-info-icon.component.css']
})
export class ImoInfoIconComponent implements OnInit {
    @Input() tooltipTitle: string;
    @Input() tooltipContent: string;
    @Input() tooltipClassList: string[] = [];
    @ViewChild('tooltip') tooltip;
    @Input() materialIcon = 'info';
    @Input() onClick = false;
    @Input() container = 'body'; // should popover be attached to body or element
    triggers = 'mouseenter:mouseleave';


    constructor() {
    }

    ngOnInit() {
        if (this.onClick) {
            this.triggers = 'click';
        }
    }

    getTooltipClass() {
        return this.tooltipClassList.join(' ');
    }
}
