import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SpecialCondition } from "@app/interfaces/accounting-position.interface";
import { Company } from "@app/interfaces/company.interface";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-company-search-modal",
  templateUrl: "./company-search-modal.component.html",
  styleUrls: ["./company-search-modal.component.css"],
})
export class CompanySearchModalComponent implements OnInit, OnDestroy, AfterViewInit {
  dialogData: any = {};
  selectedCompanySpecialConditions: SpecialCondition[] = [];
  priceReadOnly = false;
  defaultPrice: number;
  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompany: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);
  companySearchForm: FormGroup;
  protected _onDestroy = new Subject<void>();
  selectedCompanies = false;
  selectAllText = "Alle auswählen";

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CompanySearchModalComponent>
  ) {
    this.dialogData = data;
    this.defaultPrice = data.defaultPrice;
  }

  ngOnInit(): void {
    this.companySearchForm = this.fb.group({
      companyID: [""],
      price: this.defaultPrice,
      setAllPrice: false,
    });
    if (this.dialogData.selectedCompaniesSpecialConditions?.length) {
      this.dialogData.companiesList.forEach((x) => {
        const selectedCompany = this.dialogData.selectedCompaniesSpecialConditions.find((y) => y.companyId === x.cid);
        if (selectedCompany) {
          if (selectedCompany.isSelected) {
            x.isSelected = true;
          } else {
            x.isSelected = false;
          }
          x.isCompanyBold = true;
          selectedCompany.isNewCompany = false;
          this.selectedCompanySpecialConditions.push(selectedCompany);
        } else {
          x.isSelected = false;
        }
      });
    } else {
      this.dialogData.companiesList.forEach((x) => {
        x.isSelected = false;
        x.isCompanyBold = false;
      });
    }
    this.filteredCompany.next(this.dialogData.companiesList);
    this.companyFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCompanyMulti();
    });
  }

  get companyPrice() {
    return this.companySearchForm.get("price");
  }

  get setAllCompanyPrices() {
    return this.companySearchForm.get("setAllPrice");
  }

  protected setInitialValue() {
    this.filteredCompany.pipe(take(1), takeUntil(this._onDestroy));
  }

  protected filterCompanyMulti() {
    if (!this.dialogData.companiesList) {
      return;
    }
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filteredCompany.next(this.dialogData.companiesList);
    } else {
      search = search.toLowerCase();
      this.filteredCompany.next(
        this.dialogData.companiesList.filter((company) => company.name.toLowerCase().indexOf(search) > -1)
      );
    }
  }

  closeCompanySearchModal() {
    this.dialogRef.close({
      cancel: true,
      data: this.selectedCompanySpecialConditions,
    });
  }

  selectAllCompanies() {
    this.selectedCompanies = !this.selectedCompanies;
    if (this.selectedCompanies) {
      const filterValue = this.companyFilterCtrl.value || "";
      this.dialogData.companiesList
        .filter((company) => company.name.toLowerCase().includes(filterValue.toLowerCase()))
        .forEach((company) => {
          let existingCompany = this.selectedCompanySpecialConditions.find((x) => x.companyId === company.cid);
          if (existingCompany) {
            existingCompany.isSelected = true;
            existingCompany.isNewCompany = false;
          } else {
            this.selectedCompanySpecialConditions.push({
              companyId: company.cid,
              companyName: company.name,
              price: this.companyPrice.value,
              isSelected: true,
              isNewCompany: true,
              isCompanyBold: false,
            });
          }
          company.isSelected = true;
        });
      this.selectAllText = "Alles wiederufen";
    } else {
      this.dialogData.companiesList.forEach((element) => {
        element.isSelected = false;
      });
      this.selectedCompanySpecialConditions = this.selectedCompanySpecialConditions.filter(
        (x) => x.isNewCompany === false
      );
      this.selectedCompanySpecialConditions.forEach((cmp) => {
        cmp.isSelected = false;
      });
      this.selectAllText = "Alle auswählen";
    }
  }

  saveCompanySearch() {
    if (this.setAllCompanyPrices.value) {
      this.selectedCompanySpecialConditions.forEach((x) => {
        if (x.isSelected) {
          x.price = this.companyPrice.value;
          x.isSelected = false;
        }
      });
    } else {
      this.selectedCompanySpecialConditions.forEach((x) => {
        if (x.isSelected && x.isNewCompany) {
          x.price = this.companyPrice.value;
          x.isSelected = false;
        }
      });
    }
    this.dialogRef.close({
      cancel: false,
      data: this.selectedCompanySpecialConditions,
    });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleCompanyChange($event: any, company: Company) {
    company.isSelected = $event.checked;
    if (!company.isCompanyBold) {
      if ($event.checked) {
        this.selectedCompanySpecialConditions.push({
          companyId: company.cid,
          companyName: company.name,
          price: this.companyPrice.value,
          isSelected: true,
          isNewCompany: true,
          isCompanyBold: false,
        });
      } else {
        company.isSelected = false;
        this.selectedCompanySpecialConditions = this.selectedCompanySpecialConditions.filter(
          (x) => x.companyId !== company.cid
        );
      }
    } else {
      this.selectedCompanySpecialConditions.forEach((x) => {
        if (x.companyId === company.cid) {
          x.isSelected = $event.checked;
        }
      });
    }
  }

  changeCompanyPrice($event: any) {
    this.priceReadOnly = $event.checked;
  }
}
