<!-- This component is only rendered for administrators -->

<!-- Page-Content below -->
<ng-container *ngIf="state === 'default'">
  <ng-container *ngIf="auth.myUserObservable as user">
    <!-- Only show content to users that are Administrators -->
    <ng-container *ngIf="user.role === 'Administrator'; else forbidden">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap flex-row-reverse bbcg pt-1">
        <div class="btn-toolbar mb-2 mb-md-0">
          <div class="btn-group mr-2">
            <button class="btn btn-primary" (click)="createNewUser()">Benutzer anlegen</button>
          </div>
        </div>
      </div>
      <!-- Show User list in a card -->
      <div class="shadow card mt-0">
        <div class="card-body">
          <div class="mat-elevation-z8 material-table">
            <table mat-table [dataSource]="userDataSrc" matSort (matSortChange)="loadUsers()">
              <!-- ID Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">
                  <div class="row">
                    <div class="col-12">Name</div>
                    <div class="col-12">
                      <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="$any(filterValues).name"
                        placeholder="Suchen"
                        (click)="noSort($event)"
                        (keydown.space)="addNoSortWhitespace($event)"
                        (input)="updateFilterValues($event, 'name')"
                      />
                    </div>
                  </div>
                </th>
                <td mat-cell *matCellDef="let row" width="15%">{{ row.name }}</td>
              </ng-container>

              <!-- Progress Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">
                  <div class="row">
                    <div class="col-12">Email</div>
                    <div class="col-12">
                      <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="$any(filterValues).email"
                        placeholder="Suchen"
                        (click)="noSort($event)"
                        (keydown.space)="addNoSortWhitespace($event)"
                        (input)="updateFilterValues($event, 'email')"
                      />
                    </div>
                  </div>
                </th>
                <td mat-cell *matCellDef="let row" width="15%">{{ row.email }}</td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%" class="filter-select">
                  <div class="row">
                    <div class="col-12 p-0">Nutzertyp</div>
                    <div class="col-12">
                      <mat-form-field>
                        <mat-select (click)="noSort($event)" (selectionChange)="updateFilterValues($event, 'role')">
                          <mat-option [value]="">zurücksetzen</mat-option>
                          <mat-option *ngFor="let entry of rolesList" [value]="entry.value">
                            {{ entry.label }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </th>
                <td mat-cell *matCellDef="let row" width="15%">{{ row.role }}</td>
              </ng-container>

              <!-- Color Column -->
              <ng-container matColumnDef="uid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">
                  <div class="row">
                    <div class="col-12">ID</div>
                    <div class="col-12">
                      <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="$any(filterValues).uid"
                        placeholder="Suchen"
                        (click)="noSort($event)"
                        (keydown.space)="addNoSortWhitespace($event)"
                        (input)="updateFilterValues($event, 'uid')"
                      />
                    </div>
                  </div>
                </th>
                <td mat-cell *matCellDef="let row" width="15%">{{ row.uid }}</td>
              </ng-container>

              <!-- Test: RegisteredOn -->
              <ng-container matColumnDef="registeredOn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%">
                  <div class="row">
                    <div class="col-12">Registriert am</div>
                    <div class="col-12">
                      <!-- TODO :: ES not allowing filter on datetime type field, need to update API -->
                      <!-- <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="$any(filterValues).registeredOn"
                        placeholder="Suchen"
                        (click)="noSort($event)"
                        (keydown.space)="addNoSortWhitespace($event)"
                        (input)="updateFilterValues($event, 'registeredOn')"
                      /> -->
                    </div>
                  </div>
                </th>
                <td mat-cell *matCellDef="let row" width="10%">
                  {{ row.registeredOn ? (row.registeredOn._seconds * 1000 | date: "dd.MM.yyyy") : "" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="companyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%">
                  <div class="row">
                    <div class="col-12">Unternehmen</div>
                    <div class="col-12">
                      <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="$any(filterValues).companyName"
                        placeholder="Suchen"
                        (click)="noSort($event)"
                        (keydown.space)="addNoSortWhitespace($event)"
                        (input)="updateFilterValues($event, 'companyName')"
                      />
                    </div>
                  </div>
                </th>
                <td mat-cell *matCellDef="let row" width="10%">
                  <ng-container *ngIf="row.companyName">{{ row.companyName }}</ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="external">
                <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%">
                  <div class="row">
                    <div class="col-12">Kooperationskunde</div>
                    <div class="col-12">
                      <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="$any(filterValues).external"
                        placeholder="Suchen"
                        (click)="noSort($event)"
                        (keydown.space)="addNoSortWhitespace($event)"
                        (input)="updateFilterValues($event, 'external')"
                      />
                    </div>
                  </div>
                </th>
                <td mat-cell *matCellDef="let row" width="10%">
                  <ng-container *ngIf="row.external">{{ row.external }}</ng-container>
                </td>
              </ng-container>
              <!-- Color Column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef width="10%">Status</th>
                <td mat-cell *matCellDef="let row" width="10%">
                  <span *ngIf="row.emailVerified === true">
                    <span *ngIf="row.test !== true">
                      <span *ngIf="row.profileCompleted === false" class="badge badge-danger"
                        >Profil nicht ausgefüllt</span
                      >
                      <span *ngIf="row.verificationNeeded === true" class="badge badge-danger">Prüfung notwendig</span>
                      <div *ngIf="row.role === 'Dienstleister'">
                        <span *ngIf="row.floorplan === true" class="badge badge-success">Grundrisse</span>
                        <span *ngIf="row.retouching === true" class="badge badge-success">Bildbearbeitung</span>
                        <span *ngIf="row.dronepilot === true" class="badge badge-success">Drohnenpilot</span>
                        <span *ngIf="row.vstaging" class="badge badge-success">Virtual Staging</span>
                        <span *ngIf="row.visualisation" class="badge badge-success">3D-Visualisierung</span>
                        <span *ngIf="row.hdphotos" class="badge badge-success">HD Fotos</span>
                      </div>
                    </span>
                    <span *ngIf="row.test === true" class="badge badge-dark">Testaccount</span>
                  </span>
                  <span *ngIf="row.emailVerified !== true">
                    <span class="badge badge-danger">E-Mail nicht bestätigt!</span>
                  </span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr mat-row *matRowDef="let row; columns: columnsToDisplay" (click)="openDetailPage(row)"></tr>
            </table>
            <mat-paginator
              #paginator
              [pageSizeOptions]="[10, 20, 50]"
              [length]="userCount"
              (page)="loadUsers()"
              showFirstLastButtons
            ></mat-paginator>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Message displayed to all users that are not administrators but try to access this route manually in their browser -->
    <ng-template #forbidden>
      <h2>Du hast keine Zugangsberechtigung für diese Seite!</h2>
    </ng-template>
  </ng-container>
</ng-container>

<!-- Detailed Page for each user -->
<ng-container *ngIf="state === 'detail-view'">
  <ng-container *ngIf="currentUserData as user; else loadingSmall">
    <ng-container *ngIf="auth.myUserObservable as loggedInUser">
      <div class="shadow card fluid cgrid">
        <div class="card-header">
          <button class="btn btn-sm btn-back float-right" (click)="goBack()">Zurück</button>

          <!-- Display some general user details in the header -->
          <span>
            <h5 class="d-inline">{{ user.firstName }} {{ user.lastName }} ({{ user.uid }})</h5>

            <span>
              <a
                target="_blank"
                class="text-primary"
                [href]="firebaseUsersURL + curUserId"
                (click)="$event.stopPropagation()"
                *ngIf="user.role === userRoleEnum.Administrator"
                ><i
                  class="fas fa-fire text-warning clickable ml-2"
                  [tooltip]="'In Firebase öffnen'"
                  [adaptivePosition]="false"
                ></i>
              </a>
            </span>
          </span>
        </div>
        <div class="card-body">
          <div class="row">
            <!-- This column displays almost all relevant fields of the selected user's database entry -->
            <div class="col-6">
              <h5 class="card-title text-left wsn">Nutzer-Details:</h5>
              <p class="mb-0">
                <strong>Name: </strong> {{ user.firstName }} {{ user.lastName }} ({{
                  user.isPrivateIndividual ? "Ich bin eine Privatperson" : "Ich bin ein Unternehmen"
                }})
              </p>
              <p class="mb-0" *ngIf="auth.myUserObservable.role === userRoleEnum.Administrator">
                <strong>Administrative Unternehmenszuordnung: </strong>{{ selectedCompany ? selectedCompany.name : "" }}
              </p>
              <p class="mb-0"><strong>Unternehmen: </strong>{{ user.companyNameString || "" }}</p>
              <p class="mb-0">
                <strong>Adressdaten: </strong> {{ user.street }} {{ user.streetNumber }}, {{ user.postalcode }}
                {{ user.city }}
              </p>
              <p class="mb-0"><strong>Telefonnummer: </strong> {{ user.phone }}</p>
              <p class="mb-0"><strong>E-Mail Adresse: </strong> {{ user.email }}</p>
              <p class="mb-0"><strong>Vermittelt von: </strong> {{ user.external || " - " }}</p>
              <p class="mb-0"><strong>Erreichbarkeit: </strong> {{ user.reachability || " - " }}</p>
              <!-- <p class="mb-2"><strong>Registriert am: </strong> {{ user.registeredOn.toDate() | date: 'dd.MM.yyyy HH:mm'}}
              Uhr</p> -->
            </div>

            <!-- This column displays the uploaded documents of the user to the Admin -->
            <div class="col-6">
              <h5 class="card-title text-left wsn">Profildokumente:</h5>
              <p
                *ngIf="
                  !user.verificationDocument0 &&
                  !user.verificationDocument1 &&
                  !user.verificationDocument2 &&
                  !user.verificationDocument3
                "
              >
                Keine Dokumente vorhanden.
              </p>
              <ng-container *ngIf="user.verificationDocument0">
                <a class="my-link" href="{{ user.verificationDocument0 }}" target="_blank" rel="noopener"
                  >Vertragliche Vereinbarung</a
                ><span> - </span><a style="color: #dc3545" (click)="deleteDoc('doc0', user.uid)">löschen</a><br />
              </ng-container>
              <ng-container *ngIf="user.verificationDocument1">
                <a class="my-link" href="{{ user.verificationDocument1 }}" target="_blank" rel="noopener">Haftpflicht</a
                ><span> - </span><a style="color: #dc3545" (click)="deleteDoc('doc1', user.uid)">löschen</a><br />
              </ng-container>
              <ng-container *ngIf="user.verificationDocument2">
                <a class="my-link" href="{{ user.verificationDocument2 }}" target="_blank" rel="noopener"
                  >Kenntnisnachweis</a
                ><span> - </span><a style="color: #dc3545" (click)="deleteDoc('doc2', user.uid)">löschen</a><br />
              </ng-container>
              <ng-container *ngIf="user.verificationDocument3">
                <a class="my-link" href="{{ user.verificationDocument3 }}" target="_blank" rel="noopener"
                  >Allg. Aufstiegsgenehmigung</a
                ><span> - </span><a style="color: #dc3545" (click)="deleteDoc('doc3', user.uid)">löschen</a><br />
              </ng-container>
              <app-forwarding-emails
                [isInitSave]="isInitForwardEmailsSave"
                [emails]="forwardEmails"
                [userId]="user.uid"
              ></app-forwarding-emails>
              <div>
                <b>Zahlungsoptionen</b><br />
                <mat-checkbox
                  color="primary"
                  [checked]="!disabledPaymentOptions.includes(paymentOptionEnum.Collective)"
                  (change)="setDisabledPaymentOptions(paymentOptionEnum.Collective)"
                >
                  <div class="text-black">Sammelrechnung</div>
                </mat-checkbox>
                <br />
                <mat-checkbox
                  color="primary"
                  [checked]="!disabledPaymentOptions.includes(paymentOptionEnum.Sepa)"
                  (change)="setDisabledPaymentOptions(paymentOptionEnum.Sepa)"
                >
                  <div class="text-black">SEPA</div>
                </mat-checkbox>
                <br />
                <mat-checkbox
                  color="primary"
                  [checked]="!disabledPaymentOptions.includes(paymentOptionEnum.Visa)"
                  (change)="setDisabledPaymentOptions(paymentOptionEnum.Visa)"
                >
                  <div class="text-black">Visa</div>
                </mat-checkbox>
                <br />
                <mat-checkbox
                  color="primary"
                  [checked]="!disabledPaymentOptions.includes(paymentOptionEnum.OnAccount)"
                  (change)="setDisabledPaymentOptions(paymentOptionEnum.OnAccount)"
                >
                  <div class="text-black">Kauf auf Rechnung</div>
                </mat-checkbox>
                <br />
                <button
                  class="btn btn-warning mt-2 float-left"
                  (click)="updateDisabledPaymentOptionsForUser()"
                  [disabled]="isPaymentOptionUpdatePending"
                >
                  <ng-container *ngIf="!isPaymentOptionUpdatePending">Zahlungsoptionen speichern</ng-container>
                  <ng-container *ngIf="isPaymentOptionUpdatePending">Änderung wird gespeichert...</ng-container>
                </button>
              </div>
            </div>

            <div class="col-6" style="margin-top: 3vh" *ngIf="loggedInUser.role === 'Administrator'">
              <h5>Unternehmen</h5>
              <mat-form-field>
                <mat-label>Unternehmen</mat-label>
                <mat-select placeholder="Unternehmen" (selectionChange)="changeCompany()" [(ngModel)]="selectedCompany">
                  <input
                    class="text-filter"
                    matInput
                    type="text"
                    (keyup)="filterList($any($event.target).value)"
                    placeholder="Filtern nach Unternehmensname"
                    autocomplete="off"
                  />
                  <mat-option
                    *ngFor="let company of companiesListCopy"
                    [value]="company"
                    matTooltip="{{ company.name + (company.supplement ? ' - ' + company.supplement : '') }}"
                  >
                    {{ company.name + (company.supplement ? " - " + company.supplement : "") }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <!-- Button to set pilots to verified, after the Admin has checked the documents of the pilot -->
          <span *ngIf="success === true" class="alert alert-success float-left">Erfolgreich gesetzt!</span>
          <button
            *ngIf="user.verificationNeeded === true"
            class="btn btn-warning mt-2 float-left"
            (click)="setVerified(user.uid)"
          >
            Dokumente geprüft
          </button>
          <span *ngIf="showSuccessMessage === true" class="alert alert-success float-left">{{ successMessage }}</span>
          <button
            *ngIf="!user.emailVerified"
            class="btn btn-warning mt-2 float-left"
            (click)="setEmailVerified(user.uid)"
          >
            E-Mail bestätigen
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<!-- CSS Loading Spinner -->
<ng-template #loadingSmall>
  <div class="lds-ring ring-small">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-template>
