import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";
import { Invoice } from "@app/interfaces/invoice.interface";
import { Observable } from "rxjs/internal/Observable";
import { buildQueryString } from "@app/util/helper";
import { lastValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class InvoiceService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.INVOICES;

  constructor(private http: HttpClient) {}

  getInvoices(): Observable<QueryResult<Invoice>> {
    return this.http.get<QueryResult<Invoice>>(this.endpoint);
  }

  getMyInvoices(): Observable<QueryResult<Invoice>> {
    return this.http.get<QueryResult<Invoice>>(this.endpoint + "/my");
  }

  getMyCompanyInvoices(): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(this.endpoint + "/my-company");
  }

  getMyInvoicesWithPaginator(from, to, sortBy, sortDirection, queryMap): Observable<QueryResult<Invoice>> {
    const queryString = buildQueryString(from, to, sortBy, sortDirection, queryMap);
    return this.http.get<QueryResult<Invoice>>(this.endpoint + "/my" + queryString);
  }
  getInvoicesWithPaginator(from, to, sortBy, sortDirection, queryMap): Observable<QueryResult<Invoice>> {
    const queryString = buildQueryString(from, to, sortBy, sortDirection, queryMap);
    return this.http.get<QueryResult<Invoice>>(this.endpoint + queryString);
  }

  patchInvoice(id: string, data: Invoice) {
    return this.http.patch<Invoice>(this.endpoint + "/" + id, data);
  }

  postInvoice(path: string, data: Invoice | {}) {
    return this.http.post(this.endpoint + "/" + path, data);
  }

  /**
   * Exports an excel file with all the invoices with stopped dunnings.
   */
  exportStoppedDunningsInvoices() {
    return this.http
      .post(this.endpoint + "/export-stopped-dunnings", null, { responseType: "arraybuffer" })
      .toPromise();
  }

  /**
   * Retrieves an invoice by its ID.
   *
   * @param {string} id - The ID of the invoice to retrieve.
   * @return {Observable<Invoice>} - An observable that emits the invoice object.
   */
  getInvoice(id: string): Promise<Invoice> {
    return lastValueFrom(this.http.get<Invoice>(this.endpoint + "/" + id));
  }
}
