import { Injectable } from "@angular/core";
import { Discount } from "@app/interfaces/discount.interface";
import { map, tap } from "rxjs/operators";
import { DiscountsService } from "@app/_services/discounts.service";
import { AuthService } from "@app/auth/auth.service";
import { MoneyService } from "@app/_services/money.service";
import { DiscountTypeEnum } from "@app/models/discount-type-list";
import { Observable, Subject } from "rxjs";
import { Package } from "@app/models/package";
import { DocumentProcurementPackage } from "@app/interfaces/document-procurement-package.interface";
import { AccountingPosition } from "@app/interfaces/accounting-position.interface";
import { User } from "@app/interfaces/user.interface";

@Injectable({
  providedIn: "root",
})
export class DiscountsLogicService {
  discountCodeAdded = new Subject(); // To inform order-creation forms of added discount-cods
  discountsList: Discount[];
  onDataRefresh = new Subject();

  constructor(private ds: DiscountsService, private auth: AuthService, private ms: MoneyService) {}

  getAll() {
    return this.ds.getDiscounts("").pipe(
      tap((data) => {
        this.setListData(data?.data);
      })
    );
  }

  getAllDiscountsWithPaginator(
    pageIndex: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    queryMap?: Map<string, string>
  ) {
    const from = pageIndex * pageSize;
    const to = (pageIndex + 1) * pageSize;

    return this.ds.getDiscountsWithPaginator(from, to, sortBy, sortDirection, queryMap).pipe(
      map((discountList: QueryResult<Discount>) => {
        return discountList;
      })
    );
  }

  getMyDiscounts() {
    return this.ds.getDiscounts("my").pipe(
      tap((data: any) => {
        this.setListData(data);
      })
    );
  }

  /**
   * Returns a discount based on the given discount code. The returned value of the observable will be an array, either with one or zero Discount Elements
   * @param discountCode discount Code to search for.
   */
  getDiscountByCode(discountCode: string) {
    return this.ds.getDiscounts("discount-code/" + discountCode).pipe(
      tap((discount) => {
        if (discount && discount["length"]) {
          this.addOrUpdateDiscountInList(discount[0]);
        }
      })
    );
  }

  /**
   * Price of an accounting positions can be different for a specific company
   */
  getCompanySpecialCondition(accountingPosition: AccountingPosition, user?: User): AccountingPosition["price"] {
    if (user.company?.id) {
      if (accountingPosition.specialConditions) {
        const specialCondition = accountingPosition.specialConditions.find(
          (condition) => condition.companyId === user.company.id
        );
        if (specialCondition) {
          return specialCondition.price;
        }
      }
    }
    return accountingPosition.price;
  }

  createOrReplace(data: any) {
    return this.ds.putDiscount(data).pipe(
      tap((discount) => {
        this.addDiscountFormattedValue(discount);
      })
    );
  }

  delete(discountId: string) {
    return this.ds.deleteDiscount(discountId).toPromise();
  }

  private setListData(dataList: Discount[]) {
    this.discountsList = dataList.map((discount) => {
      this.addDiscountFormattedValue(discount);

      return discount;
    });
  }

  private addDiscountFormattedValue(discount: Discount) {
    let formattedValue = this.ms.floatToString(discount.value);
    discount.companyNames = discount.companyRef.map((c) => c.name).join(", ");
    if (discount.type === DiscountTypeEnum.Percentage) {
      formattedValue += " %";
    } else {
      formattedValue += " €";
    }

    discount.formattedValue = formattedValue;
  }

  private addOrUpdateDiscountInList(discount: Discount) {
    const foundIndex = this.discountsList.findIndex((item) => item.id === discount.id);

    if (foundIndex > -1) {
      // update found discount in list
      this.discountsList[foundIndex] = discount;
    } else {
      // add new discount to list
      this.discountsList.push(discount);
    }

    this.discountCodeAdded.next(true);
  }

  calculateDiscountedPrice(price: number, discount: Discount) {
    let priceDiscounted: number;
    let discountValue: number;

    const companySubsidisedVolume =
      (this.auth.myUserObservable.companyObj && this.auth.myUserObservable.companyObj.subsidisedVolume) || 0;
    const companyTotalVolume =
      (this.auth.myUserObservable.companyObj && this.auth.myUserObservable.companyObj.totalVolume) || 0;

    if (!discount || !this.auth.myUserObservable.companyObj || companySubsidisedVolume >= companyTotalVolume) {
      return price;
    }

    if (discount.type === DiscountTypeEnum.Percentage) {
      discountValue = (price * discount.value) / 100;
    } else {
      discountValue = discount.value;
    }

    // TODO: IMO-270: It is unclear whether the discount should be limited if the subsidised volume is exceeded or whether the discount should nevertheless be granted in full. See https://imogent.atlassian.net/browse/IMO-270
    discountValue = Math.min(discountValue, companyTotalVolume - companySubsidisedVolume);

    priceDiscounted = price - discountValue;
    priceDiscounted = priceDiscounted > 0 ? priceDiscounted : 0;

    return priceDiscounted;
  }

  /**
   * Calculates the general discount volume as a monetary value. I.e. only discounts that can be applied to the total sum
   * are considered here. If a discount is possible, the monetary discount sum will be returned, else 0.
   * @param discount The Discount Object for which the general discount will be calculated.
   * @param service The package for which the general discount will be calculated.
   */
  calculateGeneralDiscount(discount: Discount, service: Package | DocumentProcurementPackage, total_price: number) {
    let curDiscount = 0;

    if (
      (!discount.positionIds || discount.positionIds.length === 0) &&
      (!discount.packageId ||
        (discount.packageId && discount.packageId.length <= 0) ||
        (discount.packageId && !discount.packageId[0]) ||
        discount.packageId.includes(service["package_id"]))
    ) {
      if (discount.type === DiscountTypeEnum.Percentage) {
        curDiscount = total_price * (discount.value / 100);
      } else if (discount.type === DiscountTypeEnum.Monetary) {
        curDiscount = discount.value;
      }
    }

    return curDiscount;
  }

  getDiscountsNameList(): Observable<string[]> {
    return this.ds.getDiscountsNameList();
  }
}
