import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Room } from '@app/interfaces/room.interface';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { OrdersLogicService } from '../../_services/orders-logic.service';
import { RoomTypeEnum } from '@app/models/room-type.enum';
import { OrderTypeEnum } from '@app/models/order-type.enum';

@Component({
  selector: 'app-imo-room-descriptor',
  templateUrl: './imo-room-descriptor.component.html',
  styleUrls: ['./imo-room-descriptor.component.css']
})
export class ImoRoomDescriptorComponent implements OnInit {
  @Input() loadingSavedValues: boolean;
  @Output() roomChanged = new EventEmitter<any>();
  public roomsArray: Room[] = [];
  customConfigSub: Subscription;
  roomDescriptionsForm: FormGroup;
  RoomTypeEnum = RoomTypeEnum;
  roomKeys: string[];
  roomTypeKeys: string[];
  selectedRoomType = RoomTypeEnum.One_Person_Office;

  constructor(
    private fb: FormBuilder
  ) {
    this.roomTypeKeys = Object.keys(this.RoomTypeEnum);
    this.roomDescriptionsForm = this.fb.group({
      rooms: this.fb.array([])
    });
  }

  addRoom(identifier?: string, roomType?: RoomTypeEnum, comment?: string) {
    const newGroup = this.fb.group({
      identifier: [identifier || ''],
      roomType: [roomType || ''],
      comment: [comment || '']
    });

    (<FormArray>this.roomDescriptionsForm.get('rooms')).push(newGroup);
  }

  setSavedValues(rooms: Room[]) {
    for (const room of rooms) {
      this.addRoom(room.identifier, room.roomType, room.comment);
    }
  }

  ngOnInit() {
    if (!this.loadingSavedValues) {
      this.addRoom();
    }
    this.roomDescriptionsForm.valueChanges.subscribe((newVals) => {
      this.roomChanged.emit(newVals);
    });
  }

  removeRoom(i: number) {
    console.log(i);
    (<FormArray>this.roomDescriptionsForm.get('rooms')).removeAt(i);
  }
}
