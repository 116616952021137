import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as accounting from 'accounting';

@Pipe({ name: 'DefaultEuroPriceFormat' })
export class DefaultEuroPriceFormatPipe implements PipeTransform {

    //transform the input string to specific date format (used throughout the application)
    transform(price: number): string {
        if (price) {
            return accounting.formatMoney(price, '', 2, '.', ',');
          } else {
            return '0,00';
          }
    }

}