<h1 mat-dialog-title class="text-center">Datenschutzeinstellungen</h1>
<div mat-dialog-content>
  <div class="mt-4">
    <form [formGroup]="consentForm">
      <div class="row">
        <div class="col-12">
          <mat-checkbox [formControl]="sentryConsent" color="primary"> Analyse und Fehlerbehebung </mat-checkbox>
        </div>
        <div class="col-12">
          Ich stimme der Verarbeitung meiner personenbezogenen Daten durch die von der Imogent GmbH genutzte Anwendung
          Sentry wie nachfolgend beschrieben zu. Die Imogent GmbH nutzt Sentry, um die Stabilität ihrer Plattform zu
          überwachen und mögliche Codefehler zu ermitteln. Ich kann diese Einwilligung jederzeit ohne Angabe von Gründen
          für die Zukunft widerrufen, indem ich der Imogent GmbH postalisch unter Technologiepark 8, 33100 Paderborn
          oder per E-Mail unter
          <a href="mailto:kontakt@imogent.com">kontakt@imogent.com</a> meinen Widerruf gegen die Verarbeitung meiner
          personenbezogenen Daten mit Sentry mitteile. Weitere Informationen zur Datenverarbeitung mit Sentry finden Sie
          unter
          <a href="https://www.imogent.de/datenschutz/" target="_blank">https://www.imogent.de/datenschutz/</a>
          <hr />
        </div>
        <div class="col-12">
          <mat-checkbox [formControl]="essentialConsent" color="primary"> Essentielle Cookies </mat-checkbox>
        </div>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions class="px-4">
  <button class="col-12 mt-3 btn btn-primary" [mat-dialog-close]="true">Alle akzeptieren</button>
  <button class="col-12 my-3 ml-0 btn btn-primary" (click)="onSelectedConsentSave()">Aktuelle Auswahl speichern</button>
</div>
