<div class="justify-content-center text-center limitWidth500">
  <!-- Logo -->
  <img class="mb-4" src="assets/images/Logo-IMOGENT-mit-Schrift-300x94.png" *ngIf="!showSpkLogo" />
  <img class="mb-4 maxH140" src="assets/images/SparkassenImmo_negativ.png" *ngIf="showSpkLogo" />

  <div class="card">
    <div class="card-header" *ngIf="!submitted">
      <h3>Passwort zurücksetzen</h3>
    </div>
    <div class="card-header" *ngIf="submitted">
      <ng-container *ngIf="passwordChanged">
        <h3>Passwort erfolgreich geändert</h3>
      </ng-container>
      <ng-container *ngIf="passwordChanged === false">
        <h3>Passwort ändern fehlgeschlagen</h3>
      </ng-container>
    </div>
    <div class="card-body">
      <ng-container *ngIf="!submitted">
        <div class="row col-12 limitWidth500">Bitte legen Sie hier Ihr neues Passwort fest:</div>
        <div class="col-12">
          <form [formGroup]="passwordForm" (ngSubmit)="submitHandler()">
            <!-- E-Mail -->
            <div class="form-group">
              <!-- E-Mail Input -->
              <label class="font-italic b sr-only">Neues Passwort</label>
              <input
                formControlName="password"
                (keydown.enter)="resetPassword(newPassword.value)"
                type="password"
                class="form-control mb-2"
                placeholder="Neues Passwort"
                #newPassword
                [ngbPopover]="popContent"
                [popoverTitle]="popTitle"
                [autoClose]="false"
                placement="right"
                triggers="focus active"
                autofocus
              />
              <button
                class="btn btn-primary"
                [ngClass]="{ 'btn-spk': showSpkLogo, 'btn-primary': !showSpkLogo }"
                [disabled]="password.invalid"
              >
                Passwort ändern
              </button>
              <span *ngIf="password.invalid && password.touched">
                <br />
                <div class="alert alert-warning">{{ "register.password1_invalid" | translate }}</div>
              </span>
              <ng-template #popTitle
                ><b>{{ "register.password_requirements.requirements" | translate }}</b>
              </ng-template>
              <ng-template #popContent>
                <ul>
                  <li>
                    <i
                      *ngIf="!password.errors || password.errors.passwordError.length; else notCorrect"
                      class="fas fa-check-circle text-success"
                    ></i>
                    {{ "register.password_requirements.number" | translate }}
                  </li>
                  <li>
                    <i
                      *ngIf="!password.errors || password.errors.passwordError.lowerCase; else notCorrect"
                      class="fas fa-check-circle text-success"
                    ></i>
                    {{ "register.password_requirements.lowercase" | translate }}
                  </li>
                  <li>
                    <i
                      *ngIf="!password.errors || password.errors.passwordError.upperCase; else notCorrect"
                      class="fas fa-check-circle text-success"
                    ></i>
                    {{ "register.password_requirements.capital" | translate }}
                  </li>
                  <li>
                    <i
                      *ngIf="!password.errors || password.errors.passwordError.digit; else notCorrect"
                      class="fas fa-check-circle text-success"
                    ></i>
                    {{ "register.password_requirements.number2" | translate }}
                  </li>
                </ul>
              </ng-template>
              <ng-template #notCorrect>
                <i class="fas fa-times-circle text-danger"></i>
              </ng-template>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="submitted">
        <div class="row col-12" *ngIf="passwordChanged === undefined">
          <mat-spinner></mat-spinner>
        </div>
        <div class="row col-12" *ngIf="passwordChanged === true">
          <i class="fas fa-2x fa-check text-success"></i> <br />Passwort erfolgreich geändert. Sie können sich mit Ihrem
          neuen Passwort einloggen.
        </div>
        <div>
          <button (click)="goBack()" class="btn btn-primary mb-2 mt-2" *ngIf="passwordChanged">
            Zurück zur Startseite
          </button>
        </div>
        <div class="row col-12" *ngIf="passwordChanged === false">
          <i class="fas fa-2x fa-exclamation-triangle text-danger"></i>
          Passwort konnte nicht geändert werden. <br />
          <ng-container *ngIf="errorMsg">Fehler: {{ errorMsg }}</ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <p *ngIf="showSpkLogo" style="font-style: italic">powered by</p>
  <p class="b nomarbot">&copy;{{ year }} IMOGENT GmbH</p>
  <!-- Privacy Policy & Imprint -->
  <a
    href="https://www.imogent.de/datenschutz"
    target="_blank"
    [ngClass]="{ 'my-link': !showSpkLogo, 'grey-link': showSpkLogo }"
  >
    {{ "login.privacy" | translate }}</a
  >
  <span> | </span>
  <a
    href="https://www.imogent.de/impressum"
    target="_blank"
    [ngClass]="{ 'my-link': !showSpkLogo, 'grey-link': showSpkLogo }"
  >
    {{ "login.impressum" | translate }}</a
  >
</div>
