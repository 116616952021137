<!-- https://ng-bootstrap.github.io/#/components/popover/examples -->
<!-- https://material.angular.io/components/icon/overview -->
<!-- https://fonts.google.com/icons?selected=Material+Icons -->
<mat-icon class="text-primary imo-info-icon"
          [ngbPopover]="tooltipContent ? popContent : null"
          [popoverTitle]="tooltipTitle ? popTitle : null"
          [popoverClass]="getTooltipClass()"
          [triggers]="triggers"
          container="{{ container }}"
>{{ materialIcon }}</mat-icon>
<ng-template #popTitle ><div [innerHtml]="tooltipTitle | safeHtml"></div></ng-template>
<ng-template #popContent ><div [innerHtml]="tooltipContent | safeHtml"></div></ng-template>
