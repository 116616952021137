import { Injectable } from '@angular/core';

declare const $: any;

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor() { }

  showNotification(message: string, type?: 'info' | 'success' | 'danger' | 'warning' , options?: {type?: string, timer?: number, placement?: { from: string, align: string}}) {
    let icon = '';
    switch (type) {
      case 'info':
        icon = 'info';
        break;
      case 'success':
        icon = 'check_circle';
        break;
      case 'danger':
        icon = 'error';
        break;
      case 'warning':
        icon = 'warning';
        break;
      default:
        icon = 'notifications';
    }
    $.notify({
      icon: 'notifications',
      message: message
    }, {
      type: type || options?.type || 'info',
      timer: options?.timer || 3000,
      placement: {
        from: options?.placement?.from || 'top',
        align: options?.placement?.align || 'center'
      },
      template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }
}
