import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { CONSTANTS } from '@app/util/constants';

@Injectable({
  providedIn: 'root'
})
export class McGrundrissService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.MCGRUNDRISS;

  constructor(
    private http: HttpClient
  ) { }

  callGet(path: string) {
    return this.http.get(this.endpoint + '/' + path);
  }

  callPost(path: string, data: any) {
    return this.http.post(this.endpoint + '/' + path, data);
  }
}
