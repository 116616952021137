<div (mouseenter)="iconHovered(true)" (mouseleave)="iconHovered(false)" (click)="iconClicked()">
  <div *ngIf="contactPerson && contactPerson.firstName">
    <img *ngIf="contactPerson.profilePhoto" class="photo-icon" src="{{ contactPerson.profilePhoto }}" />
    <div
      class="contactPersonCircle float-right"
      *ngIf="contactPerson && contactPerson.firstName && contactPerson.lastName && !contactPerson.profilePhoto"
    >
      {{ contactPerson.firstName.substring(0, 1) }}{{ contactPerson.lastName.substring(0, 1) }}
    </div>
    <div class="text-box" *ngIf="expanded">
      <strong>Ansprechpartner: </strong> {{ contactPerson.firstName }} {{ contactPerson.lastName }}
      <br />
      <a
        href="{{
          'mailto:' +
            supportEmail +
            '?subject=Nachricht Bestellung ' +
            suborderInfo.packageName +
            ' (' +
            suborderInfo.id +
            ')'
        }}"
        >{{ supportEmail }}</a
      >
      <br />
      {{ contactPerson.phone }}
    </div>
  </div>
  <div
    *ngIf="!(contactPerson && contactPerson.firstName) && auth.myUserObservable.role !== userRoleEnum.ServiceProvider"
    class="message-icon"
  >
    <i class="far fa-envelope fa-3x"></i>
  </div>
  <div class="message-box" *ngIf="expanded" [ngClass]="{ invisible: !messages?.length }">
    <h4>Nachrichtenverlauf</h4>
    <div
      class="row message"
      [ngClass]="{
        message_sent: message.fromId == auth.myUserObservable.uid,
        message_received: message.fromId != auth.myUserObservable.uid
      }"
      *ngFor="let message of messages"
    >
      <div class="col-12">
        <div class="row message-from">
          {{ message.from }}
        </div>
        <div class="row message-text">
          <span *ngIf="message.type === 'html'" [innerHTML]="message.message"></span>
          <span *ngIf="message.type !== 'html'">{{ message.message }}</span>
        </div>
        <div class="row message-time">{{ message.time | date: "dd.MM.yyyy HH:mm" }} Uhr</div>
      </div>
    </div>
  </div>
  <div *ngIf="unreadMessages > 0" class="badge">{{ unreadMessages }}</div>
</div>
