<form [formGroup]="discountForm" (ngSubmit)="onSubmit()">
  <div class="mb-0 card fluid cgrid">
    <div class="card-header">
      <div class="card-title font-sz-20">
        <button mat-mini-fab class="imogentGreen" *ngIf="isFormExpanded" (click)="toggleFormExpansion()">
          <i class="fa fa-angle-up" aria-hidden="true"></i>
        </button>
        <button
          mat-mini-fab
          color="primary"
          class="imogentGreen"
          *ngIf="!isFormExpanded"
          (click)="toggleFormExpansion()"
        >
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </button>
        <span *ngIf="!isEditing" class="ml-2">Rabatte anlegen</span>
        <span *ngIf="isEditing" class="ml-2">Rabatte bearbeiten</span>
      </div>
    </div>
    <div class="card-body" *ngIf="isFormExpanded">
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="form-group">
            <div class="input-group mb-md-3">
              <mat-form-field>
                <mat-label>
                  Rabatt Name
                  <span class="text-danger">*</span>
                </mat-label>
                <input matInput formControlName="name" type="text" />
              </mat-form-field>
            </div>
            <!-- Form Verification Error -->
            <div class="alert alert-danger" *ngIf="name.invalid && name.touched">
              <div *ngIf="name.errors.duplicateDiscountName; else defaultError">
                {{ name.errors.duplicateDiscountName }}
              </div>
              <ng-template #defaultError>Pflichtfeld</ng-template>
            </div>
          </div>
        </div>

        <mat-button-toggle-group
          class="maxH50px"
          formControlName="restriction"
          name="restriction"
          (change)="updateRestriction($event)"
        >
          <mat-button-toggle value="{{ discountRestrictionEnum.Unrestricted }}">Unbeschränkt</mat-button-toggle>
          <mat-button-toggle value="{{ discountRestrictionEnum.Company }}">Unternehmen</mat-button-toggle>
          <mat-button-toggle value="{{ discountRestrictionEnum.External }}">Kooperation</mat-button-toggle>
          <mat-button-toggle value="{{ discountRestrictionEnum.DiscountCodes }}">Discount-Codes</mat-button-toggle>
        </mat-button-toggle-group>

        <div class="col-12 col-md-4" [hidden]="restriction.value !== discountRestrictionEnum.Company">
          <div class="form-group">
            <div class="input-group mb-md-3">
              <label> Der Discount gilt für: </label>
              <mat-form-field>
                <mat-label>
                  Unternehmen
                  <span class="text-danger">*</span>
                </mat-label>
                <mat-select formControlName="companyId" multiple panelClass="company-long-panel">
                  <ngx-mat-select-search [formControl]="companyFilterCtrl"></ngx-mat-select-search>
                  <mat-option #allSelected [value]="0" (click)="selectAllCompanies()">Wählen Sie Alle</mat-option>
                  <mat-option
                    (click)="selectSingleCompany()"
                    *ngFor="let company of filteredCompany | async"
                    [value]="company.cid"
                    matTooltip="{{ company.name }}"
                  >
                    {{ company.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- Form Verification Error -->
            <div
              class="alert alert-danger"
              *ngIf="discountForm.get('companyId').invalid && discountForm.get('companyId').touched"
            >
              Pflichtfeld
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-4 align-items-end"
          [hidden]="restriction.value !== discountRestrictionEnum.Unrestricted"
        >
          <label>Der Discount gilt für alle Nutzer der Plattform.</label>
        </div>
        <div class="col-12 col-md-4 align-items-end" [hidden]="restriction.value !== discountRestrictionEnum.External">
          <div class="form-group">
            <div class="input-group mb-md-3">
              <label> Der Discount gilt für: </label>
              <mat-form-field>
                <mat-label>
                  Kooperationsgruppe
                  <span class="text-danger">*</span>
                </mat-label>
                <mat-select formControlName="external" multiple>
                  <mat-option *ngFor="let company of partnerCompanies" [value]="company">
                    {{ company }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- Form Verification Error -->
            <div
              class="alert alert-danger"
              *ngIf="discountForm.get('companyId').invalid && discountForm.get('companyId').touched"
            >
              Pflichtfeld
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-4 align-items-end"
          [hidden]="restriction.value !== discountRestrictionEnum.DiscountCodes"
        >
          <mat-button-toggle-group class="maxH50px" formControlName="codeType" name="codeType">
            <mat-button-toggle value="{{ discountCodeTypeEnum.FixedCode }}">Fester Code</mat-button-toggle>
          </mat-button-toggle-group>

          <label *ngIf="codeType.value === discountCodeTypeEnum.FixedCode"
            >Der Discount-Code wird hier fest definiert.</label
          >
        </div>
      </div>
      <div class="row mb-4" *ngIf="restriction.value === discountRestrictionEnum.DiscountCodes">
        <div class="col-12 col-md-5 col-xl-3">
          <input
            formControlName="discountCode"
            class="form-control"
            placeholder="Discount-Code hier definieren."
            *ngIf="codeType.value === discountCodeTypeEnum.FixedCode"
          />
        </div>
        <div class="col-12 col-md-4 col-xl-3">
          <mat-form-field appearance="fill">
            <mat-label>Gültigkeitszeitraum</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Startdatum" formControlName="discountCodeStartDate" />
              <input matEndDate placeholder="Enddatum" formControlName="discountCodeEndDate" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
          <label>Bezieht sich auf den Zeitpunkt, zu dem der generierte Code eingelöst wird.</label>
        </div>
        <div class="col-12 col-md-4 col-xl-3">
          <mat-checkbox color="primary" class="example-margin" formControlName="oneTimeCode"> </mat-checkbox>
          <label *ngIf="codeType.value === discountCodeTypeEnum.FixedCode"
            >Der Code ist nur einmal pro Nutzer einlösbar</label
          >
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-3">
          <div class="form-group">
            <div class="input-group mb-md-3">
              <mat-form-field>
                <mat-label>
                  Rabatt Typ
                  <span class="text-danger">*</span>
                </mat-label>
                <mat-select formControlName="type">
                  <mat-option *ngFor="let discountType of discountTypeList" [value]="discountType.id">
                    {{ discountType.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- Form Verification Error -->
            <div
              class="alert alert-danger"
              *ngIf="discountForm.get('type').invalid && discountForm.get('type').touched"
            >
              Pflichtfeld
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group">
            <div class="input-group mb-md-3">
              <mat-form-field>
                <mat-label>
                  Rabatt Wert
                  <span class="text-danger">*</span>
                </mat-label>
                <input matInput formControlName="value" type="number" min="1" />
                <span matSuffix *ngIf="discountForm.controls['type'].value == discountTypeEnum.Monetary">€ </span>
                <span matSuffix *ngIf="discountForm.controls['type'].value == discountTypeEnum.Percentage">% </span>
              </mat-form-field>
            </div>
            <!-- Form Verification Error -->
            <div
              class="alert alert-danger"
              *ngIf="discountForm.get('value').invalid && discountForm.get('value').touched"
            >
              Pflichtfeld
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group">
            <div class="input-group mb-md-3">
              <mat-form-field>
                <mat-label> Produkt </mat-label>
                <!-- companyId & productId are both used for the Firestore document ID and shouldn't be changed when editing the discount -->
                <mat-select formControlName="productId">
                  <mat-option *ngFor="let product of productList" [value]="product.id">
                    {{ product.id }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- Form Verification Error -->
            <div
              class="alert alert-danger"
              *ngIf="discountForm.get('productId').invalid && discountForm.get('productId').touched"
            >
              Pflichtfeld
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group">
            <div class="input-group mb-md-3">
              <mat-form-field>
                <mat-label> Paket </mat-label>
                <mat-select formControlName="packageId" multiple>
                  <mat-option *ngFor="let package of packageList[productId] || []" [value]="package.value">
                    {{ package.label }} ({{ package.value }})
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- Form Verification Error -->
            <div
              class="alert alert-danger"
              *ngIf="discountForm.get('packageId').invalid && discountForm.get('packageId').touched"
            >
              Pflichtfeld
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group">
            <div class="input-group mb-md-3">
              <mat-form-field>
                <mat-label> Preispositionen </mat-label>
                <mat-select formControlName="positionIds" multiple>
                  <ng-container *ngFor="let position of positionsList[productId] || []">
                    <mat-option [value]="position.value" matTooltip="{{ position.label }}">
                      {{ position.label }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- Form Verification Error -->
            <div
              class="alert alert-danger"
              *ngIf="discountForm.get('positionIds').invalid && discountForm.get('positionIds').touched"
            >
              Pflichtfeld
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-3">
          <mat-form-field>
            <mat-label> Kurzbeschreibung </mat-label>
            <input matInput formControlName="shortDescription" type="text" />
          </mat-form-field>
        </div>
      </div>

      <div class="btn-toolbar mb-2 mb-md-0">
        <button *ngIf="isEditing" class="btn btn-danger" type="button" (click)="cancelEditing()">Abbrechen</button>
        <button class="btn btn-primary" type="submit" [disabled]="!discountForm.valid">
          <span *ngIf="isEditing">Speichern</span>
          <span *ngIf="!isEditing">Hinzufügen</span>
        </button>
      </div>
    </div>
  </div>
</form>

<!-- TODO: check MatTable Sort usage because if we create the table without being inside this ngIf, it doesnt work properly.
  The 'sort' method runs before ngInit, and because of that has empty data on the 'sortables' property. -->
<div class="card cgrid mt-2" *ngIf="!isEditing">
  <div class="card-body">
    <div class="mat-elevation-z8 material-table">
      <table
        mat-table
        [dataSource]="tableDataSrc"
        matSortActive="companyNames"
        matSortDirection="asc"
        matSort
        (matSortChange)="loadDiscounts()"
      >
        <!-- ID Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">
            <div class="row">
              <div class="col-12">Rabatt Name</div>
              <div class="col-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Suchen"
                  (click)="noSort($event)"
                  (input)="updateFilterValues($event, 'name')"
                  (keydown.space)="addNoSortWhitespace($event)"
                />
              </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" width="15%" class="maxW10pct">{{ row.name }}</td>
        </ng-container>

        <ng-container matColumnDef="modifiedOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%">
            <div class="row">
              <div class="col-12">Datum</div>
              <div class="col-12"></div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" width="10%" class="maxW5pct">
            {{ row.modifiedOn?._seconds * 1000 | date: "dd.MM.yyyy HH:mm" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="companyNames">
          <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">
            <div class="row">
              <div class="col-12">Unternehmen</div>
              <div class="col-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Suchen"
                  (click)="noSort($event)"
                  (input)="updateFilterValues($event, 'companyNames')"
                  (keydown.space)="addNoSortWhitespace($event)"
                />
              </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" width="15%" class="maxW10pct">
            <span *ngFor="let companyRef of row?.companyRef"> {{ companyRef?.name }} , </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="external">
          <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%">
            <div class="row">
              <div class="col-12">Kooperation</div>
              <div class="col-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Suchen"
                  (click)="noSort($event)"
                  (input)="updateFilterValues($event, 'external')"
                  (keydown.space)="addNoSortWhitespace($event)"
                />
              </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" width="15%" class="maxW10pct">{{ row.external || "" }}</td>
        </ng-container>

        <ng-container matColumnDef="discountCodes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%">
            <div class="row">
              <div class="col-12">Gutscheincode</div>
              <div class="col-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Suchen"
                  (click)="noSort($event)"
                  (input)="updateFilterValues($event, 'discountCodes')"
                  (keydown.space)="addNoSortWhitespace($event)"
                />
              </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" width="10%" class="maxW10pct">
            {{ row.discountCodes && row.discountCodes[0] ? row.discountCodes[0] : "" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="productId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%">
            <div class="row">
              <div class="col-12">Produkt</div>
              <div class="col-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Suchen"
                  (click)="noSort($event)"
                  (input)="updateFilterValues($event, 'productId')"
                  (keydown.space)="addNoSortWhitespace($event)"
                />
              </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" width="10%" class="maxW10pct">{{ row.productId }}</td>
        </ng-container>

        <ng-container matColumnDef="packageId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%">
            <div class="row">
              <div class="col-12">Pakete</div>
              <div class="col-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Suchen"
                  (click)="noSort($event)"
                  (input)="updateFilterValues($event, 'packageId')"
                  (keydown.space)="addNoSortWhitespace($event)"
                />
              </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" width="10%" class="maxW10pct">{{ row.packageId }}</td>
        </ng-container>

        <ng-container matColumnDef="positionIds">
          <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">
            <div class="row">
              <div class="col-12">Preispositionen</div>
              <div class="col-12">
                <input
                  type="text"
                  class="form-control maxW250"
                  placeholder="Suchen"
                  (click)="noSort($event)"
                  (input)="updateFilterValues($event, 'positionIds')"
                  (keydown.space)="addNoSortWhitespace($event)"
                />
              </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" width="15%" class="maxW10pct">{{ row.positionIds }}</td>
        </ng-container>

        <ng-container matColumnDef="formattedValue">
          <th mat-header-cell *matHeaderCellDef mat-sort-header width="5%">
            <div class="row">
              <div class="col-12">Wert</div>
              <div class="col-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Suchen"
                  (click)="noSort($event)"
                  (input)="updateFilterValues($event, 'formattedValue')"
                  (keydown.space)="addNoSortWhitespace($event)"
                />
              </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" width="5%" class="maxW5pct">{{ row.formattedValue }}</td>
        </ng-container>

        <!--        <ng-container matColumnDef="chargingInvoiceUserName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">
            <div class="row">
              <div class="col-12">Rechnungsbenutzer</div>
              <div class="col-12">
                <input type="text" class="form-control" placeholder="Suchen"
                  (input)="filterTable($event, 'chargingInvoiceUserName')" (click)="noSort($event)">
              </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" width="15%" class="maxW5pct">
            {{row.chargingInvoiceUserRef && row.chargingInvoiceUserRef.name}}</td>
        </ng-container>-->

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef width="10%"></th>
          <td mat-cell *matCellDef="let row; let i = index" width="10%" class="maxW5pct">
            <i class="material-icons clickable" (click)="editItem(row.id)">edit</i>
            <i class="material-icons clickable text-danger" (click)="removeItem(row.id, $event)">delete</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay" (click)="editItem(row.id)"></tr>
      </table>
      <mat-paginator
        #paginator
        [pageSizeOptions]="[10, 20, 50]"
        [length]="discountCount"
        (page)="loadDiscounts()"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
