import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FixedCartModule } from "./fixed-cart/fixed-cart.module";
import { NavbarModule } from "./navbar/navbar.module";
import { UploadTaskComponent } from "./upload-task/upload-task.component";
import { ImoTileComponent } from "./imo-tile/imo-tile.component";
import { LinkifyPipe } from "./linkify.pipe";
import { SelectTilesComponent } from "./select-tiles/select-tiles.component";
import { ImoFileUploadComponent } from "./imo-file-upload/imo-file-upload.component";
import {
  DialogImageFullsize,
  ImoAttachmentExplorerComponent,
} from "./imo-attachment-explorer/imo-attachment-explorer.component";
import { FileUploadModule } from "ng2-file-upload";
import { MaterialModule } from "../material/material.module";
import { StatusDatesComponent } from "./status-dates/status-dates.component";
import { FullscreenDialogComponent } from "./fullscreen-dialog/fullscreen-dialog.component";
import { AnyCastPipe } from "./any-cast.pipe";
import { FieldFilterPipe } from "./field-filter.pipe";
import { ImoAudioPlayerComponent } from "./imo-audio-player/imo-audio-player.component";
import { ImoDateTimeSelectorComponent } from "./imo-date-time-selector/imo-date-time-selector.component";
import { OrderFeedbackRatingComponent } from "./order-feedback-rating/order-feedback-rating.component";
import { DeliveryAlarmPipe } from "@app/shared-module/deliveryDateAlarm.pipe";
import { InfoIconComponent } from "./info-icon/info-icon.component";
import { ContactPersonIconComponent } from "./contact-person-icon/contact-person-icon.component";
import { NgxComparisonSliderComponent } from "./ngx-comparison-slider/ngx-comparison-slider.component";
import { DownloadComponent } from "./download/download.component";
import { ImoRetouchingOptionsComponent } from "./imo-retouching-options/imo-retouching-options.component";
import { ImoVideoEditingOptionsComponent } from "./imo-video-editing-options/imo-video-editing-options.component";
import { ShortenPipe } from "@app/shared-module/shorten.pipe";
import { ImoUserSelectorComponent } from "./imo-user-selector/imo-user-selector.component";
import { ImoRoomDescriptorComponent } from "./imo-room-descriptor/imo-room-descriptor.component";
import { BytePipe } from "@app/shared-module/byte.pipe";
import { ForwardingEmailsComponent } from "./forwarding-emails/forwarding-emails.component";
import { SafeURLPipe } from "./safe-url.pipe";
import { ImagePreloadDirective } from "./image-preload.directive";
import { PaymentOptionsComponent } from "./payment-options/payment-options.component";
import { ImoBillingAddressSelectorComponent } from "./imo-billing-address-selector/imo-billing-address-selector.component";
import { RouterModule } from "@angular/router";
import { ImoFileUploadListComponent } from "./imo-file-upload-list/imo-file-upload-list.component";
import { ImoFileUploadContainerComponent } from "./imo-file-upload-container/imo-file-upload-container.component";
import { ImoInfoIconComponent } from "./imo-info-icon/imo-info-icon.component";
import { SafeHtmlPipe } from "@app/shared-module/safe-html.pipe";
import { RequiredLabelDirective } from "@app/shared-module/directives/required-label.directive";
import { DefaultDateFormatPipe } from "./pipes/date-format.pipe";
import { DefaultEuroPriceFormatPipe } from "./pipes/price-euro-format.pipe";
import { CountryCodePipe } from "@app/shared-module/pipes/CountryCodePipe";
import { PartnerAdvertisementBannerComponent } from "./partner-advertisement-banner/partner-advertisement-banner.component";
import { MatMenuModule } from "@angular/material/menu";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { ImoValidationErrorDialogComponent } from "./imo-validation-error-dialog/imo-validation-error-dialog.component";
import { AddVatPipe } from "./add-vat.pipe";
import { CustomerInteractionsComponent } from "@app/shared-module/customer-interactions/customer-interactions.component";
import { AppListTranslatePipe } from "@app/shared-module/applist-translate.pipe";
import { ImoConfirmationModalComponent } from "./imo-confirmation-modal/imo-confirmation-modal.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { TranslateModule } from "@ngx-translate/core";
import { InfoBoxComponent } from "./info-box/info-box.component";
import { InputNumberComponent } from "./form-controls/input-number/input-number.component";
import { SuborderClosingDateExtensionComponent } from "./suborder-closing-date-extension/suborder-closing-date-extension.component";
import { EditBillingAddressComponent } from "./edit-billing-address/edit-billing-address.component";
import { DownloadButtonComponent } from "./download-button/download-button.component";
import { FeedbackToolComponent } from "@app/shared-module/feedback-tool/feedback-tool.component";
import { MarkerToolService } from "./feedback-tool/marker-tool.service";
import { SuborderFeedbackInfoComponent } from "./suborder-feedback-info/suborder-feedback-info.component";
import { GetVatPipe } from "@app/shared-module/get-vat.pipe";
import { AccPosReplacerPipe } from "@app/shared-module/acc-pos-replacer.pipe";
import { BillingAddressFormComponent } from "./billing-address-form/billing-address-form.component";
import { NumberToWordPipe } from "./pipes/number-to-word.pipe";
import { ImoCarouselSelectorComponent } from "@app/shared-module/imo-carousel-selector/imo-carousel-selector.component";
import { ImoCarouselComponent } from "./imo-carousel/imo-carousel.component";
import { ImoCommentBoxComponent } from "./imo-comment-box/imo-comment-box.component";
import { BkbnTransitionModalComponent } from "@app/shared-module/bkbn-transition-modal/bkbn-transition-modal.component";

@NgModule({
  declarations: [
    UploadTaskComponent,
    ImoTileComponent,
    LinkifyPipe,
    SelectTilesComponent,
    ImoFileUploadComponent,
    ImoAttachmentExplorerComponent,
    DialogImageFullsize,
    StatusDatesComponent,
    FullscreenDialogComponent,
    AnyCastPipe,
    FieldFilterPipe,
    DeliveryAlarmPipe,
    BytePipe,
    ShortenPipe,
    ImoAudioPlayerComponent,
    ImoDateTimeSelectorComponent,
    OrderFeedbackRatingComponent,
    InfoIconComponent,
    DefaultDateFormatPipe,
    DefaultEuroPriceFormatPipe,
    ContactPersonIconComponent,
    NgxComparisonSliderComponent,
    DownloadComponent,
    ImoRetouchingOptionsComponent,
    ImoVideoEditingOptionsComponent,
    ImoUserSelectorComponent,
    ImoRoomDescriptorComponent,
    SafeURLPipe,
    ForwardingEmailsComponent,
    ImagePreloadDirective,
    PaymentOptionsComponent,
    ImoBillingAddressSelectorComponent,
    ImoFileUploadListComponent,
    ImoFileUploadContainerComponent,
    ImoInfoIconComponent,
    SafeHtmlPipe,
    RequiredLabelDirective,
    CountryCodePipe,
    PartnerAdvertisementBannerComponent,
    ConfirmationDialogComponent,
    ImoValidationErrorDialogComponent,
    AddVatPipe,
    ImoValidationErrorDialogComponent,
    CustomerInteractionsComponent,
    AppListTranslatePipe,
    ImoConfirmationModalComponent,
    DownloadButtonComponent,
    NavbarComponent,
    InfoBoxComponent,
    InputNumberComponent,
    SuborderClosingDateExtensionComponent,
    EditBillingAddressComponent,
    DownloadButtonComponent,
    FeedbackToolComponent,
    SuborderFeedbackInfoComponent,
    GetVatPipe,
    AccPosReplacerPipe,
    BillingAddressFormComponent,
    NumberToWordPipe,
    ImoCarouselSelectorComponent,
    ImoCarouselComponent,
    ImoCommentBoxComponent,
    BkbnTransitionModalComponent,
  ],
  imports: [
    CommonModule,
    FixedCartModule,
    NavbarModule,
    ReactiveFormsModule,
    FormsModule,
    FileUploadModule,
    MaterialModule,
    RouterModule,
    MatMenuModule,
    TranslateModule.forChild(),
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    FixedCartModule,
    NavbarModule,
    UploadTaskComponent,
    ImoTileComponent,
    LinkifyPipe,
    AnyCastPipe,
    FieldFilterPipe,
    DeliveryAlarmPipe,
    DefaultDateFormatPipe,
    DefaultEuroPriceFormatPipe,
    BytePipe,
    SafeHtmlPipe,
    SelectTilesComponent,
    ImoFileUploadComponent,
    ImoAttachmentExplorerComponent,
    DialogImageFullsize,
    FileUploadModule,
    StatusDatesComponent,
    ImoAudioPlayerComponent,
    ImoDateTimeSelectorComponent,
    OrderFeedbackRatingComponent,
    InfoIconComponent,
    ContactPersonIconComponent,
    NgxComparisonSliderComponent,
    DownloadComponent,
    ImoRetouchingOptionsComponent,
    ImoVideoEditingOptionsComponent,
    ShortenPipe,
    ImoUserSelectorComponent,
    ImoRoomDescriptorComponent,
    SafeURLPipe,
    ForwardingEmailsComponent,
    PaymentOptionsComponent,
    ImoBillingAddressSelectorComponent,
    ImoFileUploadListComponent,
    ImoFileUploadContainerComponent,
    ImoInfoIconComponent,
    RequiredLabelDirective,
    CountryCodePipe,
    PartnerAdvertisementBannerComponent,
    ImoValidationErrorDialogComponent,
    AddVatPipe,
    CustomerInteractionsComponent,
    AppListTranslatePipe,
    DownloadButtonComponent,
    NavbarComponent,
    InfoBoxComponent,
    InputNumberComponent,
    SuborderClosingDateExtensionComponent,
    DownloadButtonComponent,
    FeedbackToolComponent,
    SuborderFeedbackInfoComponent,
    GetVatPipe,
    AccPosReplacerPipe,
    NumberToWordPipe,
    ImoCarouselSelectorComponent,
    ImoCarouselComponent,
    ImoCommentBoxComponent,
  ],
  providers: [AddVatPipe, BytePipe, MarkerToolService, GetVatPipe, AccPosReplacerPipe],
})
export class SharedModule {}
