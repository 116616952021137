import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "@app/auth/auth.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AccountingPositionsLogicService } from "@app/_services/accounting-positions-logic.service";
import { ServicesLogicService } from "@app/_services/services-logic.service";
import { environment } from "@environments/environment";
import { UploadService } from "@app/_services/upload.service";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { McGrundrissLogicService } from "@app/_services/mc-grundriss-logic.service";
import { SubcollectionFileModel } from "@app/models/subcollectionFileModel";
import { ConfigurationLogicService } from "@app/_services/configuration-logic.service";
import { UserRoleEnum } from "@app/models/user-role-list";
import { CONSTANTS } from "@app/util/constants";
import { User } from "@app/interfaces/user.interface";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { OrderTypeResponsible } from "@app/interfaces/order-type-responsible.interface";
import { SubOrdersLogicService } from "../_services/sub-orders-logic.service";
import { UsersLogicService } from "@app/_services/users-logic.service";
import { CompaniesLogicService } from "@app/_services/companies-logic.service";
import { FeedbackToolModeEnum } from "@app/models/feedback-tool-mode.enum";
import { InvoiceLogicService } from "@app/_services/invoice-logic.service";
import { NotificationsService } from "@app/_services/notifications.service";
import { isPlanetHomeUser, translateAppListStrings } from "@app/util/helper";
import { ConfirmationDialogComponent } from "@app/shared-module/confirmation-dialog/confirmation-dialog.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { EmailTemplatesService } from "@app/_services/email-templates.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { LandingPageTrackingItem } from "@app/interfaces/landing-page-tracking-item.interface";
import { EmailTemplate } from "@app/interfaces/settings.interface";
import * as _ from "underscore";
import { GlobalService } from "@app/_services/global.service";
import { RealestateLogicService } from "@app/_services/realestate-logic.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.css"],
})
export class SettingsComponent implements OnInit {
  active = true; // Initial State der einklappbaren Card
  settingsForm: any;
  orderTypeResponsibleForm: FormGroup;
  landingPageTrackingForm: FormGroup;
  imogentUsers: User[];
  uploadingProfilePhoto = false;
  serviceProvidersData = [];
  serviceProviderForm: string;
  companyForm: string;
  companyServiceProviderForm: string;
  userForm: string;
  userServiceProviderForm: string;
  expandedTableRow = -1;
  mcgrundriss = {
    orderId: "",
    suborderId: "",
    productId: 0,
    feedbackNumber: 1,
    feedbackMode: FeedbackToolModeEnum.Normal,
  };
  orderTypes: { id: string; name: string; enabled: boolean }[] = [];
  orderTypeResponsibles: OrderTypeResponsible[] = [];
  isProductionEnvironment: boolean;
  modules = {};
  allServices = [];
  emailTemplatesForm: FormGroup;
  emailTemplateList: any = new MatTableDataSource();
  originalEmailTemplateList: any;
  templateToUpdate: any;
  displayedColumns = ["product", "templateTitle", "subject", "action"];
  @ViewChild("product") mySelect;
  private scrollTopBeforeSelection: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  landingPageTrackingList: LandingPageTrackingItem[] = [];
  currentLandingPageTrackingItem: LandingPageTrackingItem;
  servicesForm: FormGroup;

  constructor(
    public auth: AuthService,
    private fb: FormBuilder,
    public services: ServicesLogicService,
    public apls: AccountingPositionsLogicService,
    public ivls: InvoiceLogicService,
    public cls: CompaniesLogicService,
    public uls: UsersLogicService,
    private ns: NotificationsService,
    private uploadService: UploadService,
    public conf: ConfigurationLogicService,
    public mcls: McGrundrissLogicService,
    public sos: SubOrdersLogicService,
    private dialog: MatDialog,
    public ets: EmailTemplatesService,
    private gs: GlobalService,
    private rels: RealestateLogicService
  ) {
    this.settingsForm = this.fb.group({
      sendConfirmationEmail: [""],
    });

    this.orderTypeResponsibleForm = this.fb.group({
      orderType: ["", [Validators.required]],
      orderTypeResponsible: ["", [Validators.required]],
    });

    this.emailTemplatesForm = this.fb.group({
      id: [""],
      product: ["", [Validators.required]],
      templateTitle: ["", [Validators.required]],
      subject: ["", [Validators.required]],
      templateText: ["", [Validators.required]],
      checked: [false],
    });

    this.modules = {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        // ['blockquote', 'code-block'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction
        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ 'font': [] }],
        // [{ 'align': [] }],
        ["clean"], // remove formatting button
        // ['link', 'image', 'video'],                         // link and image, video
        // ['emoji']
      ],
    };
    this.landingPageTrackingForm = this.fb.group({
      refId: ["", [Validators.required]],
      hasNewsletterInfoEnabled: [false],
      welcomeMessage: ["", [Validators.required]],
      companyTag: ["", [Validators.required]],
    });

    this.servicesForm = this.fb.group({
      selectedServices: [[]],
    });
  }

  get selectedServices(): FormControl {
    return <FormControl>this.servicesForm.get("selectedServices");
  }

  get orderType() {
    return this.orderTypeResponsibleForm.get("orderType");
  }

  get orderTypeResponsible() {
    return this.orderTypeResponsibleForm.get("orderTypeResponsible");
  }

  get refId() {
    return this.landingPageTrackingForm.get("refId");
  }

  get welcomeMessage() {
    return this.landingPageTrackingForm.get("welcomeMessage");
  }

  get companyTag() {
    return this.landingPageTrackingForm.get("companyTag");
  }

  get hasNewsletterInfoEnabled() {
    return this.landingPageTrackingForm.get("hasNewsletterInfoEnabled");
  }

  saveLandingPageTrackingItem() {
    const refId = this.refId.value;
    const hasNewsletterInfoEnabled = !!this.hasNewsletterInfoEnabled.value;
    const welcomeMessage = this.welcomeMessage.value;
    const companyTag = this.companyTag.value;

    if (this.currentLandingPageTrackingItem) {
      // edit existing item
      const currentItem = this.currentLandingPageTrackingItem;
      currentItem.refId = refId;
      currentItem.hasNewsletterInfoEnabled = hasNewsletterInfoEnabled;
      currentItem.welcomeMessage = welcomeMessage;
      currentItem.companyTag = companyTag;
    } else {
      // add new item
      this.landingPageTrackingList.push({
        refId: refId,
        hasNewsletterInfoEnabled: hasNewsletterInfoEnabled,
        welcomeMessage: welcomeMessage,
        companyTag: companyTag,
      });
    }

    this.saveLandingPageTrackingList();
  }

  resetLandingPageTrackingForm() {
    this.currentLandingPageTrackingItem = undefined;
    this.landingPageTrackingForm.reset();
  }

  removeLandingPageTrackingItem(index: number) {
    const confirmed = confirm("Möchten Sie den Eintrag entfernen?");

    if (confirmed) {
      this.landingPageTrackingList.splice(index, 1);
      this.saveLandingPageTrackingList();
    }
  }

  saveLandingPageTrackingList() {
    this.resetLandingPageTrackingForm();

    this.conf
      .updateConfiguration(CONSTANTS.CONFIGURATIONS.LANDING_PAGE_TRACKING_LIST, { ...this.landingPageTrackingList })
      .subscribe(
        () => {
          console.log("Landing page tracking list saved successfully.");
          this.ns.showNotification("Landing Page Tracking-Liste erfolgreich gespeichert.", "success");
        },
        (error) => {
          console.error("Error found:", error);
          this.ns.showNotification("Error: " + error.message, "danger");
        }
      );
  }

  editLandingPageTrackingItem(index: number) {
    this.resetLandingPageTrackingForm();
    this.currentLandingPageTrackingItem = this.landingPageTrackingList[index];
    this.landingPageTrackingForm.patchValue(this.currentLandingPageTrackingItem);
  }

  /**
   * Submit handler for settings form. Updates the user settings info in firebase
   */
  async submitHandler() {
    const formValue = this.settingsForm.value;
    this.uls
      .updateUser(this.auth.myUserObservable.uid, formValue)
      .then(() => {
        this.ns.showNotification("Benutzereinstellungsinformationen aktualisiert.", "success");
      })
      .catch((error) => {
        console.log("Could not update user settings info.");
        console.error(error);
        this.ns.showNotification("Benutzereinstellungsinformationen konnten nicht aktualisiert werden.", "danger");
      });
  }

  /**
   * Preload data for user profile
   */
  async preloadData() {
    if (this.auth.myUserObservable.role === UserRoleEnum.Administrator) {
      this.prepareEmailList();
      await this.conf.getServiceProviders();
      this.getServiceProviderData(this.conf.serviceProviders);

      let users = await this.uls.getAllUsersOfCompany(environment.imogentCompanyId).toPromise();
      users = users.sort((x, y) => x["firstName"].localeCompare(y["firstName"]));
      this.imogentUsers = users;

      this.orderTypeResponsibles = this.conf.orderTypeResponsibles;
      this.landingPageTrackingList = this.conf.landingPageTrackingList;

      this.orderTypes = Object.values(OrderTypeEnum).map((id: OrderTypeEnum) => {
        let enabled = true;
        const orderTypeResponsibleFound = this.orderTypeResponsibles.find((item) => item.type === id);

        if (orderTypeResponsibleFound) {
          enabled = false;
        }

        return this.createOrderType(id, enabled);
      });
    }

    this.uls.getUserDetails(this.auth.myUserObservable.uid).subscribe(
      (userDetails) => {
        this.settingsForm.patchValue(userDetails);
      },
      (error) => {
        console.log("Could not fetch user settings info.");
        console.error(error);
        this.ns.showNotification("Benutzereinstellungsinformationen konnten nicht abgerufen werden.", "danger");
      }
    );
  }

  createOrderType(id: string, enabled: boolean) {
    return {
      id: id,
      name: translateAppListStrings("order_type_list", id),
      enabled: enabled,
    };
  }

  async ngOnInit() {
    await this.services.getImoServices();
    this.isProductionEnvironment = this.gs.getEnvironmentConfig("env") === "prod";
    this.preloadData();
    this.allServices = this.services.imoPackages;

    this.mySelect.openedChange.subscribe((open) => {
      if (open) {
        this.mySelect.panel.nativeElement.addEventListener(
          "scroll",
          (event) => (this.scrollTopBeforeSelection = event.target.scrollTop)
        );
      }
    });

    this.mySelect.optionSelectionChanges.subscribe(() => {
      this.mySelect.panel.nativeElement.scrollTop = this.scrollTopBeforeSelection;
    });
  }

  async getServiceProviderData(serviceProviders: {}) {
    this.uls.getAllServiceProviders().subscribe(
      () => {
        this.serviceProvidersData = [];
        for (let key in serviceProviders) {
          let serviceProviderData = {};
          let companyData = [];
          let userData = [];
          if (serviceProviders[key].default !== "") {
            serviceProviderData = this.getServiceProviderDetails(serviceProviders[key].default);
          }
          for (let keyCompany in serviceProviders[key].companies) {
            let companyDetails = this.getCompanyDetails(keyCompany);
            let companyUserData = this.getServiceProviderDetails(serviceProviders[key].companies[keyCompany]);
            companyData.push({
              companyName: companyDetails ? companyDetails.name : "undefiniert",
              companyId: companyDetails ? companyDetails.cid : "",
              serviceProvider: companyUserData
                ? companyUserData.firstName + " " + companyUserData.lastName
                : "undefiniert",
              serviceProviderId: companyUserData ? companyUserData.uid : "",
            });
          }
          for (let keyUser in serviceProviders[key].users) {
            let userDetails = this.getUserDetails(keyUser);
            let userUserData = this.getServiceProviderDetails(serviceProviders[key].users[keyUser]);
            userData.push({
              userName: userDetails ? userDetails.firstName + " " + userDetails.lastName : "undefiniert",
              userId: userDetails ? userDetails.uid : "",
              serviceProvider: userUserData ? userUserData.firstName + " " + userUserData.lastName : "undefiniert",
              serviceProviderId: userUserData ? userUserData.uid : "",
            });
          }
          this.serviceProvidersData.push({
            packageNum: key,
            default: serviceProviderData && Object.keys(serviceProviderData).length ? serviceProviderData["uid"] : "",
            defaultName:
              serviceProviderData && Object.keys(serviceProviderData).length
                ? serviceProviderData["firstName"] + " " + serviceProviderData["lastName"]
                : "undefiniert",
            companies: companyData,
            users: userData,
            expanded: false,
            changed: false,
          });
        }
        this.serviceProvidersData.sort((a, b) => (a.packageNum < b.packageNum ? -1 : 1));
      },
      (error) => {
        console.error(error);
      }
    );
  }

  editServiceProvider(index) {
    if (this.expandedTableRow >= 0) {
      this.serviceProvidersData[this.expandedTableRow]["expanded"] = false;
      this.serviceProvidersData[this.expandedTableRow]["changed"] = false;
      this.serviceProviderForm = "";
      this.companyForm = "";
      this.companyServiceProviderForm = "";
      this.userForm = "";
      this.userServiceProviderForm = "";
    }
    this.expandedTableRow = index;
    this.serviceProvidersData[index]["expanded"] = true;
    this.serviceProviderForm = this.serviceProvidersData[index]["default"] || "";
  }

  saveServiceProvider(index) {
    if (this.serviceProvidersData[index]["changed"]) {
      let configurationData = {};

      let companyData = {};
      this.serviceProvidersData[index]["companies"].forEach((company) => {
        companyData[company.companyId] = company.serviceProviderId;
      });

      let userData = {};
      this.serviceProvidersData[index]["users"].forEach((user) => {
        userData[user.userId] = user.serviceProviderId;
      });

      configurationData[this.serviceProvidersData[index]["packageNum"]] = {
        default: this.serviceProviderForm || "",
        companies: companyData,
        users: userData,
      };
      this.conf.updateConfiguration(CONSTANTS.CONFIGURATIONS.SERVICE_PROVIDERS, configurationData).subscribe(
        async (serviceProviderData) => {
          console.log("Service provider configuration updated", serviceProviderData);
          await this.conf.getServiceProviders();
          this.getServiceProviderData(this.conf.serviceProviders);
        },
        (error) => {
          console.log("Service provider configuration could not be updated");
          console.log(error);
        }
      );
    }
    this.serviceProvidersData[index]["expanded"] = false;
    this.serviceProvidersData[index]["changed"] = false;
    this.expandedTableRow = -1;
  }

  onServiceProviderChanged(event, index) {
    this.serviceProvidersData[index]["changed"] = true;
    this.serviceProvidersData[index]["defaultName"] = "ID: " + this.serviceProviderForm;
  }

  deleteCompanyServiceProvider(index, companyIndex) {
    this.serviceProvidersData[index]["changed"] = true;
    this.serviceProvidersData[index]["companies"].splice(companyIndex, 1);
  }

  deleteUserServiceProvider(index, userIndex) {
    this.serviceProvidersData[index]["changed"] = true;
    this.serviceProvidersData[index]["users"].splice(userIndex, 1);
  }

  addCompanyServiceProvider(index) {
    this.serviceProvidersData[index]["changed"] = true;
    let companyDetails = this.getCompanyDetails(this.companyForm);
    let companyUserData = this.getServiceProviderDetails(this.companyServiceProviderForm);
    if (this.checkForDuplicates(this.serviceProvidersData[index]["companies"], this.companyForm, "companyId")) {
      this.serviceProvidersData[index]["companies"].push({
        companyName: companyDetails ? companyDetails.name : "undefiniert",
        companyId: companyDetails ? companyDetails.cid : "",
        serviceProvider: companyUserData ? companyUserData.firstName + " " + companyUserData.lastName : "undefiniert",
        serviceProviderId: companyUserData ? companyUserData.uid : "",
      });
    }
    this.companyForm = "";
    this.companyServiceProviderForm = "";
  }

  addUserServiceProvider(index) {
    this.serviceProvidersData[index]["changed"] = true;
    let userDetails = this.getUserDetails(this.userForm);
    let userUserData = this.getServiceProviderDetails(this.userServiceProviderForm);
    if (this.checkForDuplicates(this.serviceProvidersData[index]["users"], this.userForm, "userId")) {
      this.serviceProvidersData[index]["users"].push({
        userName: userDetails ? userDetails.firstName + " " + userDetails.lastName : "undefiniert",
        userId: userDetails ? userDetails.uid : "",
        serviceProvider: userUserData ? userUserData.firstName + " " + userUserData.lastName : "undefiniert",
        serviceProviderId: userUserData ? userUserData.uid : "",
      });
    }
    this.userForm = "";
    this.userServiceProviderForm = "";
  }

  checkForDuplicates(data: [], id: string, field: string) {
    let isDuplicate = false;
    data.forEach((element) => {
      if (element[field] === id) {
        isDuplicate = true;
      }
    });
    return !isDuplicate;
  }

  getServiceProviderDetails(uid) {
    return this.uls.allServiceProviders.find((user) => user.uid === uid);
  }

  getCompanyDetails(cid) {
    return this.cls.companiesList.find((company) => company.cid === cid);
  }

  getUserDetails(uid) {
    return this.uls.allUsers.find((user) => user.uid === uid);
  }

  async uploadDocument(event: any, fileName: string, user, index) {
    if (fileName === "profilePhoto") {
      this.uploadingProfilePhoto = true;
    }
    this.uploadService.uploadFile(event.target.files[0], `users/${user.uid}/`, false, "").subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.Response) {
          const fileDetails = <SubcollectionFileModel>event.body;
          console.log("Uploaded the document, updating the user details");
          const documentFields = {
            profilePhoto: "profilePhoto",
          };
          const requestData = {};
          requestData[documentFields[fileName]] = fileDetails.download_url;
          requestData[documentFields[fileName] + "_file_name"] = fileDetails.file_name;
          requestData[documentFields[fileName] + "_file_size"] = fileDetails.file_size;
          this.uls
            .updateUser(user.uid, requestData)
            .then((userDetails) => {
              this.ns.showNotification("Dokument erfolgreich hochgeladen", "success");
              user = userDetails;
              this.imogentUsers[index] = userDetails;
              this.uploadingProfilePhoto = false;
            })
            .catch((error) => {
              console.log("Could not update user details");
              console.log(error);
              this.ns.showNotification(
                "Das Dokument wurde hochgeladen, aber die Details konnten nicht aktualisiert werden",
                "danger"
              );
            });
        }
      },
      (error) => {
        console.log("Could not upload the document");
        console.log(error);
        this.ns.showNotification("Das Dokument konnte nicht hochgeladen werden", "danger");
      }
    );
  }

  addOrderTypeResponsible() {
    const type = this.orderType.value;
    const userId = this.orderTypeResponsible.value;
    this.orderTypeResponsibleForm.reset();

    this.orderTypeResponsibles.push({
      type: type,
      userId: userId,
    });

    this.enableOrderType(type, false);
    this.saveOrderTypeResponsibles();
  }

  enableOrderType(type: string, enabled: boolean) {
    const orderTypeFound = this.orderTypes.find((item) => item.id === type);
    orderTypeFound.enabled = enabled;
    // this is forcing a change detection to occur in angular in order to make use of the filter on the template
    this.orderTypes = [...this.orderTypes];
  }

  removeOrderTypeResponsible(index: number) {
    const removedOrderTypeResponsibles = this.orderTypeResponsibles.splice(index, 1);
    const type = removedOrderTypeResponsibles[0].type;
    this.enableOrderType(type, true);
    this.saveOrderTypeResponsibles();
  }

  saveOrderTypeResponsibles() {
    this.conf
      .updateConfiguration(CONSTANTS.CONFIGURATIONS.ORDER_TYPE_RESPONSIBLES, { ...this.orderTypeResponsibles })
      .subscribe(
        () => {
          console.log("Order type responsibles save successfully.");
        },
        (error) => {
          console.error("Error found:", error);
        }
      );
  }

  getOrderTypeName(type: OrderTypeEnum) {
    const orderTypeFound = this.orderTypes.find((item) => item.id === type);
    return orderTypeFound.name;
  }

  getUser(userId: string) {
    const userFound = this.imogentUsers.find((item) => item.uid === userId);
    if (userFound) {
      return userFound.name + " (" + userFound.email + ")";
    } else {
      return undefined;
    }
  }

  deleteFile(event: Event, fileType: string, user, index) {
    event.stopPropagation();
    event.preventDefault();
    const confirmed = confirm(
      "Sind Sie sicher, dass Sie diese Datei löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden!"
    );
    let path = `users/${user.uid}/`;
    if (fileType === "profilePhoto") {
      path += user.profilePhoto_file_name;
    }

    if (confirmed) {
      this.uploadService.deleteFile(path).subscribe(
        () => {
          let requestData = {};
          if (fileType === "profilePhoto") {
            requestData = {
              profilePhoto: "",
              profilePhoto_file_name: "",
              profilePhoto_file_size: "",
            };
          }
          this.uls
            .updateUser(user.uid, requestData)
            .then((userDetails) => {
              this.imogentUsers[index] = userDetails;
              user = userDetails;
            })
            .catch((error) => {
              console.log("Could not update the document details to user");
              console.log(error);
              this.ns.showNotification(
                "Die Datei wurde gelöscht, aber die Details konnten nicht aktualisiert werden",
                "danger"
              );
            });
        },
        (error) => {
          console.log("Could not upload the document");
          console.log(error);
          this.ns.showNotification("Die Datei konnte nicht gelöscht werden", "danger");
        }
      );
    }
  }
  async assignUserToCompanyViaPath() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message:
        "Hierdurch wird ein Massenimport von Nutzern gestartet. Dies hat Auswirkungen auf die Berechtigungen aller betroffenen Nutzer! Möchten Sie fortfahren?",
    };
    dialogConfig.width = "70vh";
    const detailsDialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    detailsDialogRef.afterClosed().subscribe(async (action) => {
      if (action === "continue") {
        await this.cls.assignUserToCompanyViaPath().toPromise();
      }
    });
  }

  async updatePlanetHomeRealEstatesBrokerDetails() {
    await this.rels.updatePlanetHomeBrokerDetails();
  }

  async createEmailTemplate() {
    if (this.emailTemplatesForm.valid) {
      const emailTemplate: EmailTemplate = this.emailTemplatesForm.value;
      if (emailTemplate.id) {
        let templatesList: any[] = [];
        if (emailTemplate.product) {
          emailTemplate.product.forEach((p: string) => {
            let templateObject = { ...emailTemplate, product: p };
            if (
              p !==
                (Array.isArray(this.templateToUpdate.product)
                  ? this.templateToUpdate.product[0]
                  : this.templateToUpdate.product) &&
              p !== "standard"
            )
              delete templateObject.id;
            templatesList.push(templateObject);
          });
        }
        const isEmailTemplateUpdated = await this.ets.updateEmailTemplate(templatesList).toPromise();
        if (isEmailTemplateUpdated) {
          this.ns.showNotification("Email Template has been updated.", "success");
        }
      } else {
        const isEmailTemplateCreated = await this.ets.createEmailTemplate(emailTemplate).toPromise();
        if (isEmailTemplateCreated) this.ns.showNotification("Email Template has been created.", "success");
      }
      this.prepareEmailList();
    }
  }

  updateEmailTemplate(emailTemplate) {
    this.templateToUpdate = emailTemplate;
    if (!Array.isArray(emailTemplate.product)) emailTemplate.product = [emailTemplate.product];
    this.emailTemplatesForm.patchValue(emailTemplate);
  }

  async deleteEmailTemplate(emailTemplateId) {
    const confirmed = confirm("Are you sure you want to delete this email template?");
    if (confirmed) {
      const isEmailTemplateDeleted = await this.ets.deleteEmailTemplate(emailTemplateId).toPromise();
      if (isEmailTemplateDeleted) {
        this.ns.showNotification("Email Template has been deleted.", "danger");
        this.prepareEmailList();
      }
    }
  }

  async prepareEmailList() {
    const emailTemplateList = [];
    (await this.ets.getEmailTemplateList().toPromise()).forEach((e) => {
      if (e.product !== "standard") {
        Object.keys(this.allServices).forEach((service) => {
          this.allServices[service].find((pack) => {
            if (pack.package_key == e.product) {
              e["packageName"] = pack.name;
              emailTemplateList.push(e);
            }
          });
        });
      } else {
        e["packageName"] = "Platzhalter";
        emailTemplateList.push(e);
      }
    });
    this.emailTemplateList = new MatTableDataSource(emailTemplateList);
    this.originalEmailTemplateList = emailTemplateList;
    this.emailTemplateList.paginator = this.paginator;
    this.emailTemplateList.sort = this.sort;
  }

  templateFilterChange($event) {
    if (!($event.value.length === 0)) {
      let filteredData = _.filter(this.originalEmailTemplateList, (item) => {
        return $event.value.includes(item.product);
      });
      this.emailTemplateList = new MatTableDataSource(filteredData);
    } else {
      this.emailTemplateList = new MatTableDataSource(this.originalEmailTemplateList);
    }
    this.emailTemplateList.paginator = this.paginator;
    this.emailTemplateList.sort = this.sort;
  }
}
