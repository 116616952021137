import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "numberToWord",
})
export class NumberToWordPipe implements PipeTransform {
  transform(number: number, gender: "m" | "f" | "n", rank: "r" | "n" | undefined): string {
    const rankDict: { [key: number]: { [gender: string]: string } } = {
      1: { f: "erste", m: "erster", n: "erste" },
      2: { f: "zweite", m: "zweiter", n: "zweite" },
      3: { f: "dritte", m: "dritter", n: "dritte" },
      4: { f: "vierte", m: "vierter", n: "vierte" },
      5: { f: "fünfte", m: "fünfter", n: "fünfte" },
      6: { f: "sechste", m: "sechster", n: "sechste" },
      7: { f: "siebte", m: "siebter", n: "siebte" },
      8: { f: "achte", m: "achter", n: "achte" },
      9: { f: "neunte", m: "neunter", n: "neunte" },
    };

    const singleNumberDict: { [key: number]: { [gender: string]: string } } = {
      1: { f: "eine", m: "einer", n: "ein" },
    };

    const genericNumbersDict: { [key: number]: string } = {
      1: "eins",
      2: "zwei",
      3: "drei",
      4: "vier",
      5: "fünf",
      6: "sechs",
      7: "sieben",
      8: "acht",
      9: "neun",
    };

    if (rank === "r") {
      return rankDict[number][gender] || "";
    }

    if (number === 1 && rank === "n") {
      return singleNumberDict[number][gender];
    } else {
      return genericNumbersDict[number];
    }
  }
}
