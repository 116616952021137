import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";
import { tap } from "rxjs/operators";
import { ServicesLogicService } from "./services-logic.service";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { AutoPilotConfig } from "@app/interfaces/auto-pilot-config.interface";
import { OrderTypeResponsible } from "@app/interfaces/order-type-responsible.interface";
import { ConfigurationService } from "@app/_services/configuration.service";
import { LoginData } from "@app/interfaces/login-data.interface.ts";
import { PackageDeliveryTimeItem } from "@app/interfaces/package-delivery-time-item.interface";
import { LandingPageTrackingItem } from "@app/interfaces/landing-page-tracking-item.interface";
import { OffenblendeConfig } from "@app/interfaces/offenblende-config.interface";
import { AuthService } from "@app/auth/auth.service";
import { FairfleetConfig } from "@app/interfaces/fairfleet-config.interface";
import { RevenueInformation } from "@app/interfaces/revenueInformation.interface";
import { AnalyticsTypeEnum } from "@app/models/analyticsType.enum";
import { BillingInformation } from "@app/interfaces/billingInformation.interface";
import { ReportingPreset } from "@app/interfaces/reporting-preset.interface";
import { ImmoviewerConfig } from "@app/interfaces/immoviewer-config.interface";
import { GrundrissSchmiedeConfig } from "@app/interfaces/grundrissSchmiedeConfig.interface";
import { BackboneConfig } from "@app/interfaces/backbone-config.interface";
import { lastValueFrom, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ConfigurationLogicService {
  public serviceProviders: {};
  public disabledServices: any;
  public loginData: LoginData[];
  apiBaseUrl = environment.apiUrl + "configuration/";
  autoPilotConfig: AutoPilotConfig;
  orderTypeResponsibles: OrderTypeResponsible[] = [];
  landingPageTrackingList: LandingPageTrackingItem[] = [];
  packagesDeliveryTimes: PackageDeliveryTimeItem[] = [];
  reportingPresets: ReportingPreset[] = [];
  immoviewerConfig: ImmoviewerConfig;
  grundrissSchmiedeConfig: GrundrissSchmiedeConfig;
  mcGrundrissPackages: string[];
  offenblendeConfig: OffenblendeConfig;
  backboneConfig: BackboneConfig;
  ffConfig: FairfleetConfig;

  constructor(
    private cs: ConfigurationService,
    private servicesService: ServicesLogicService,
    private auth: AuthService
  ) {}

  getConfiguration<T>(configId: string): Observable<T> {
    return this.cs.getConfiguration(configId).pipe(
      tap((data: T) => {
        switch (configId) {
          case CONSTANTS.CONFIGURATIONS.AUTO_PILOT:
            this.autoPilotConfig = <AutoPilotConfig>data;
            break;
          case CONSTANTS.CONFIGURATIONS.ORDER_TYPE_RESPONSIBLES:
            if (data) {
              const objectArray = Object.entries(data);
              this.orderTypeResponsibles = objectArray.map(([key, value]: [string, OrderTypeResponsible]) => value);
            }
            break;
          case CONSTANTS.CONFIGURATIONS.PACKAGES_DELIVERY_TIMES:
            if (data) {
              const objectArray = Object.entries(data);
              this.packagesDeliveryTimes = objectArray.map(([key, value]: [string, PackageDeliveryTimeItem]) => value);
            }
            break;
          case CONSTANTS.CONFIGURATIONS.LANDING_PAGE_TRACKING_LIST:
            if (data) {
              const objectArray = Object.entries(data);
              this.landingPageTrackingList = objectArray.map(
                ([key, value]: [string, LandingPageTrackingItem]) => value
              );
            }
            break;
          case CONSTANTS.CONFIGURATIONS.OFFENBLENDE:
            this.offenblendeConfig = <OffenblendeConfig>data;
            break;
          case CONSTANTS.CONFIGURATIONS.FAIRFLEET:
            this.ffConfig = <FairfleetConfig>data;
            break;
          case CONSTANTS.CONFIGURATIONS.REPORTING_PRESETS:
            this.reportingPresets = <ReportingPreset[]>data;
            break;
          case CONSTANTS.CONFIGURATIONS.IMMOVIEWER:
            this.immoviewerConfig = <ImmoviewerConfig>data;
            break;
          case CONSTANTS.CONFIGURATIONS.GRUNDRISS_SCHMIEDE:
            this.grundrissSchmiedeConfig = <GrundrissSchmiedeConfig>data;
            break;
          case CONSTANTS.CONFIGURATIONS.BACKBONE:
            this.backboneConfig = <BackboneConfig>data;
            break;
        }
      })
    );
  }

  getLoginData(loginsStartDate, loginsEndDate) {
    return this.cs.getConfiguration("login-data", loginsStartDate, loginsEndDate).pipe(
      tap((loginData: LoginData[]) => {
        this.loginData = loginData;
      })
    );
  }

  getAnalyticsData(analyticsType: AnalyticsTypeEnum): Promise<RevenueInformation | BillingInformation> {
    return this.cs.getConfiguration("analytics/" + analyticsType).toPromise() as Promise<
      RevenueInformation | BillingInformation
    >;
  }

  getServiceProviders() {
    return this.getConfiguration(CONSTANTS.CONFIGURATIONS.SERVICE_PROVIDERS)
      .pipe(tap((serviceProviders) => (this.serviceProviders = serviceProviders)))
      .toPromise();
  }

  async getAssignedServiceProvider(
    packageNumber: string,
    uid: string,
    cid: string,
    otherSelectedPackages: string[],
    qualitySelection?: number,
    preventBackbone?: boolean
  ) {
    const packageNum = packageNumber.split("|").pop();
    const assignedServiceProvider = {
      isDefault: false,
      id: "",
    };

    // TODO: Should make it smarter.. At least call it in parallel:
    const offenBlendeConfig =
      this.offenblendeConfig ||
      <OffenblendeConfig>await this.getConfiguration(CONSTANTS.CONFIGURATIONS.OFFENBLENDE).toPromise();

    const backboneConfig =
      this.backboneConfig ||
      <BackboneConfig>await lastValueFrom(this.getConfiguration(CONSTANTS.CONFIGURATIONS.BACKBONE));

    // check for user
    if (this.serviceProviders[packageNum]?.users[uid] && !assignedServiceProvider.id) {
      assignedServiceProvider.id = this.serviceProviders[packageNum]["users"][uid];
    }
    // check for company
    if (this.serviceProviders[packageNum]?.companies[cid] && !assignedServiceProvider.id) {
      assignedServiceProvider.id = this.serviceProviders[packageNum]["companies"][cid];
    }
    // check for default
    if (this.serviceProviders[packageNum]?.default && !assignedServiceProvider.id) {
      assignedServiceProvider.isDefault = true;
      assignedServiceProvider.id = this.serviceProviders[packageNum]["default"];
    }

    // In case the preventBackbone-Flag is set and the service provider would be backbone, reassign the service-provider to offenblende.
    if (preventBackbone && this.backboneConfig.backboneUIDs.some((uid) => uid === assignedServiceProvider.id)) {
      assignedServiceProvider.id = this.offenblendeConfig.offenblendeUIDs[0];
    }

    const vStagingPackageKeys = [
      CONSTANTS.PACKAGE_KEYS.VSTAGING_STILL_IMAGE,
      CONSTANTS.PACKAGE_KEYS.VSTAGING_360_IMAGE,
      CONSTANTS.PACKAGE_KEYS.VSTAGING_OFFICE_360_IMAGE,
      CONSTANTS.PACKAGE_KEYS.VSTAGING_OFFICE_STANDBILD,
      CONSTANTS.PACKAGE_KEYS.VSTAGING_OFFICE_360_BILDER,
    ];
    // overriding the assigned service provider for digital staging with premium plus quality
    if (vStagingPackageKeys.includes(packageNum) && qualitySelection === 1) {
      assignedServiceProvider.isDefault = false;
      assignedServiceProvider.id = CONSTANTS.ZAITSEV_SERVICE_PROVIDER_ID;
    }

    /**
     * Below block of code is only for testing orders from Homeday. It can be removed later
     */
    const HOMEDAY_FLOORPLAN_PACKAGES = [
      CONSTANTS.PACKAGE_KEYS.FLOORPLAN_KLASSICH,
      CONSTANTS.PACKAGE_KEYS.FLOORPLAN_PREMIUM,
      CONSTANTS.PACKAGE_KEYS.FLOORPLAN_EXTRAVAGANT,
    ];
    if (
      this.auth.myUserObservable.company?.id === CONSTANTS.HOMEDAY_COMPANY_ID &&
      (offenBlendeConfig.offenblendeProducts.includes(packageNum) || HOMEDAY_FLOORPLAN_PACKAGES.includes(packageNum))
    ) {
      assignedServiceProvider.isDefault = false;
      if (HOMEDAY_FLOORPLAN_PACKAGES.includes(packageNum)) {
        assignedServiceProvider.id = "";
      }
    }

    return assignedServiceProvider;
  }

  updateConfiguration(configId: string, configData: any) {
    return this.cs.putConfiguration(configId, configData || {});
  }

  async createAssignServiceProviderConfiguration() {
    await this.servicesService.getImoServices();
    let configurationData = {};
    for (let key in this.servicesService.imoPackages) {
      this.servicesService.imoPackages[key].forEach((packages) => {
        configurationData[packages?.package_key] = { default: "", companies: {}, users: {} };
      });
    }
    this.updateConfiguration(CONSTANTS.CONFIGURATIONS.SERVICE_PROVIDERS, configurationData).subscribe(
      (serviceProviderData) => {
        console.log("Service provider configuration updated", serviceProviderData);
      },
      (error) => {
        console.log("Service provider configuration could not be updated");
        console.log(error);
      }
    );
  }

  addLogs(collectionName: string, logs: any) {
    return this.cs.addLogs(collectionName, logs);
  }
}
