import { Injectable, EventEmitter } from '@angular/core';
import { RealestateService } from '@app/_services/realestate.service';
import { ConfigurationLogicService } from '@app/_services/configuration-logic.service';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import * as _ from 'underscore';
import { EsoftApiService } from './esoft-api.service';
import {OrdersLogicService} from '@app/_services/orders-logic.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  /* This service is used to do all necessary upfront-database-reads */

  private readonly logName = '-- LoaderService :: ';
  public uid;
  public emailVerified;
  public loaded = false;

  public initializeScroller: EventEmitter<boolean> = new EventEmitter<boolean>();
  public scrollerInitialized = false;

  constructor(private res: RealestateService,
    private ols: OrdersLogicService,
    private router: Router,
    private auth: AuthService,
    private conf: ConfigurationLogicService,
    private ngxSmartModalService: NgxSmartModalService,
    private esoftClient: EsoftApiService
  ) { }

  logout() {
    console.log('Starting to unsubscribe!');
    this.router.navigate['/home'];
    this.auth.sessionEnded = true;
    this.esoftClient.subscriptions.forEach(sub => {
      if (_.isFunction(sub.unsubscribe)) {
        sub.unsubscribe();
      }
    });
    if (!_.isEmpty(this.esoftClient.$esoftDBConfigSub) && _.isFunction(this.esoftClient.$esoftDBConfigSub.unsubscribe)) {
      this.esoftClient.$esoftDBConfigSub.unsubscribe();
    }
    this.ols.cartItems = [];
    this.ols.selectedPackageIds = [];
    this.ols.selectedPackageNames = [];
    this.ols.selectedRealEstate = null;
    this.ols.selectedServices = [];
    this.auth.logout();
  }
}
