<div *ngIf="suborder.statusDates['feedbacks']" class="card card-body">
  <div class="row" *ngIf="isFeedbackLoop && statusDates">
    <div class="col-4">
      <div class="row">
        <div class="col-2">
          <i class="far fa-clock"></i>
        </div>
        <ng-container *ngIf="statusDates['estimatedDelivery']">
          <div class="col-10" [ngClass]="{ 'text-danger': delayed }">
            voraussichtlich
            {{ statusDates["estimatedDelivery"] | date : "dd.MM.yyyy" }}
            <span *ngIf="delayDays > 1" class="text-danger"> (+ {{ delayDays | number : "2.0-0" }} Tage) </span>
            <span *ngIf="delayDays === 1" class="text-danger"> (+ {{ delayDays | number : "2.0-0" }} Tag) </span>
          </div>
        </ng-container>
        <ng-container *ngIf="!statusDates['estimatedDelivery']"> keine Angabe </ng-container>
      </div>
    </div>
    <div class="col-4">
      <div class="row">
        <div class="col-2">
          <i class="fas fa-images"></i>
        </div>
        <ng-container *ngIf="statusDates['delivery']">
          <div class="col-10">
            Ergebnisse
            {{ statusDates["delivery"] | date : "dd.MM.yyyy" }}
          </div>
        </ng-container>
        <ng-container *ngIf="delayed">
          <div class="text-danger">verspätet</div>
        </ng-container>
        <ng-container *ngIf="!statusDates['delivery'] && !delayed"> keine Angabe </ng-container>
      </div>
    </div>
    <div class="col-4">
      <div class="row">
        <div class="col-2">
          <i class="far fa-comments"></i>
        </div>
        <ng-container *ngIf="statusDates['feedback']">
          <div class="col-10">
            Feedback
            <ng-container *ngIf="role !== userRoleEnum.Customer">erhalten</ng-container>
            <ng-container *ngIf="role === userRoleEnum.Customer">gesendet</ng-container>
            {{ statusDates["feedback"] | date : "dd.MM.yyyy" }}
          </div>
        </ng-container>
        <ng-container *ngIf="!statusDates['feedback']"> keine Angabe </ng-container>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!isFeedbackLoop && statusDates">
    <div [class.col-3]="hasCustomerInteraction" [class.col-4]="!hasCustomerInteraction">
      <div class="row">
        <div class="col-2">
          <i class="far fa-clock"></i>
        </div>
        <ng-container *ngIf="statusDates['created']">
          <div class="col-10">
            Auftrag eingegangen
            {{ statusDates["created"] | date : "dd.MM.yyyy" }}
          </div>
        </ng-container>
        <ng-container *ngIf="!statusDates['created']"> keine Angabe </ng-container>
      </div>
    </div>
    <div [class.col-3]="hasCustomerInteraction" [class.col-4]="!hasCustomerInteraction">
      <div class="row">
        <div class="col-2">
          <i class="far fa-clock"></i>
        </div>
        <ng-container *ngIf="statusDates['processed']">
          <div class="col-10">
            Start der Bearbeitung
            {{ statusDates["processed"] | date : "dd.MM.yyyy" }}
          </div>
        </ng-container>
        <ng-container *ngIf="!statusDates['processed']"> keine Angabe </ng-container>
      </div>
    </div>
    <div [class.col-3]="hasCustomerInteraction" [class.col-4]="!hasCustomerInteraction">
      <div class="row">
        <div class="col-2">
          <i class="far fa-clock"></i>
        </div>
        <ng-container *ngIf="statusDates['completed']">
          <div class="col-10">
            Ergebnisse
            {{ statusDates["completed"] | date : "dd.MM.yyyy" }}
          </div>
        </ng-container>
        <ng-container *ngIf="!statusDates['completed']">
          <ng-container *ngIf="statusDates['estimatedDelivery']">
            <div class="col-10" [ngClass]="{ 'text-danger': delayed }">
              voraussichtlich
              {{ statusDates["estimatedDelivery"] | date : "dd.MM.yyyy" }}
              <span *ngIf="delayDays >= 1" class="text-danger">
                (+ {{ delayDays }} {{ delayDays > 1 ? "Tage" : "Tag" }})
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="!statusDates['estimatedDelivery']"> keine Angabe </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="col-3" *ngIf="hasCustomerInteraction">
      <div class="row clickable" (click)="toggleCustomerInteractionsView()">
        <!--
        TODO: Row skeleton! Implement later.
        -->
        <ng-container *ngIf="customerInteractionType === CustomerInteractionTypeEnum.MOOVIN">
          <div class="col-2">
            <i
              class="fas"
              [class.fa-exclamation-triangle]="isCustomerActionRequired"
              [class.text-danger]="isCustomerActionRequired"
              [class.fa-info-circle]="isCustomerActionRequired"
            ></i>
          </div>
          <div>
            <span [ngClass]="{ 'text-danger': isCustomerActionRequired }"> Nachrichten </span>
            <i
              class="fas ml-2"
              [class.text-danger]="isCustomerActionRequired"
              [class.fa-chevron-left]="showCustomerInteractions"
              [class.fa-chevron-down]="!showCustomerInteractions"
            ></i>
          </div>
        </ng-container>
        <ng-container *ngIf="customerInteractionType === CustomerInteractionTypeEnum.DOC_ESTATE">
          <div class="col-2">
            <i
              class="fas"
              [class.fa-exclamation-triangle]="dcls.docEstateCustomerInteraction.customerActionRequired"
              [class.text-danger]="dcls.docEstateCustomerInteraction.customerActionRequired"
              [class.fa-info-circle]="!dcls.docEstateCustomerInteraction.customerActionRequired"
            ></i>
          </div>
          <div>
            <span class="text-danger" *ngIf="dcls.docEstateCustomerInteraction.customerActionRequired"
              >Information benötigt</span
            >
            <span *ngIf="!dcls.docEstateCustomerInteraction.customerActionRequired">Verlauf ansehen</span>
            <i
              class="fas ml-2"
              [class.text-danger]="dcls.docEstateCustomerInteraction.customerActionRequired"
              [class.fa-chevron-left]="showCustomerInteractions"
              [class.fa-chevron-down]="!showCustomerInteractions"
            ></i>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showCustomerInteractions">
  <app-customer-interactions
    [customerInteractionType]="customerInteractionType"
    [suborderId]="suborder.id"
  ></app-customer-interactions>
</div>
