<nav
  class="navbar navbar-expand-lg"
  *ngIf="
    hiddenTopMenuPathList.indexOf(currentRoutePath) === -1 &&
    (hiddenNavbarUrlPathList.indexOf(currentRoutePath) === -1 || isLoggedIn)
  "
  [ngClass]="{ 'nagative-left-margin': !this.orderCreationPath.includes(this.currentRoutePath) }"
>
  <div class="container-fluid">
    <div class="navbar-wrapper row">
      <span class="col-12 d-inline nav-item ml-3">{{ getTitle() | translate }}</span>
    </div>
    <button
      *ngIf="auth.myUserObservable"
      mat-button
      class="navbar-toggler btn-no-ripple"
      type="button"
      (click)="sidebarToggle()"
    >
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div *ngIf="auth.myUserObservable" class="collapse navbar-collapse justify-content-end" id="navigation">
      <span
        (click)="openModal('lockedEstateModal')"
        class="btn-text pl-3 locked-estate-warning clickable"
        *ngIf="gs.lockedRealEstate?.title"
      >
        {{ gs.lockedRealEstateDisplayString | shorten: 100 }}
        <span class="circle ml-3">
          <span class="icon"> i </span>
        </span>
      </span>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0);" (click)="openModal('newsModal')">
            <span
              matTooltip="Benachrichtigungen"
              class="material-icons material-icons-outlined text-imo"
              [class]="{ bell: state }"
              >notifications</span
            >
            <p>
              <span class="d-lg-none d-md-block">Benachrichtigungen</span>
            </p>
            <span *ngIf="unreadNotifications" class="badge badge-danger">{{ unreadNotifications }}</span>
          </a>
        </li>
        <li
          class="nav-item"
          *ngIf="this.router.url === '/createorder/selectservice' || this.router.url === '/createorder/selectpackage'"
        >
          <a class="nav-link dropdown cursor-pointer" appClickOutside (clickOutside)="onOutsideClick($event)" dropdown>
            <i
              dropdownToggle
              class="material-icons material-icons-outlined text-imo"
              data-bs-auto-close="false"
              aria-haspopup="true"
              aria-expanded="false"
              matTooltip="Warenkorb"
              (click)="toggledCart($event)"
              >shopping_cart</i
            >
            <ng-container *dropdownMenu>
              <div
                class="col-12 pt-0 m-auto rounded dropdown-menu"
                *ngIf="isMenuOpened"
                (click)="$event.stopPropagation()"
              >
                <form class="p-1 m-2">
                  <div class="d-flex col-12 m-1 p-1">
                    <div class="col-10 p-1">
                      <div class="col-12 p-0 font-weight-500 font-size-large">Warenkorb</div>
                      <div class="col-12 p-0 font-weight-500 font-size-small text-gray">
                        {{ ols.selectedPackageIds.length }} Produkt(e)
                      </div>
                    </div>
                    <div class="col-2 pr-1 text-right">
                      <mat-icon class="material-icons-outlined text-gray" (click)="isMenuOpened = false"
                        >highlight_off</mat-icon
                      >
                    </div>
                  </div>
                  <div class="product-details d-flex flex-wrap">
                    <ng-container *ngFor="let item of ols.cartItems; let serviceIndex = index">
                      <div class="col-12 mt-3 font-weight-500 font-size-noraml">{{ item.data.title }}</div>
                      <ng-container
                        *ngTemplateOutlet="
                          selectedServiceAndPackages[item.data.id] ? content : contentLoader;
                          context: {
                            item: item,
                            serviceIndex: serviceIndex,
                            selectedServiceAndPackages: selectedServiceAndPackages
                          }
                        "
                      ></ng-container>
                      <ng-template
                        #content
                        let-item="item"
                        let-serviceIndex="serviceIndex"
                        let-selectedServiceAndPackages="selectedServiceAndPackages"
                      >
                        <div
                          *ngFor="let package of selectedServiceAndPackages[item.data.id]"
                          class="d-flex flex-wrap col-12 mt-2 border-bottom pb-2"
                        >
                          <div class="col-2 p-0 mt-1">
                            <ng-container *ngIf="package.package_icons[0]?.split('.').pop() === 'svg'">
                              <svg class="package-images cart-item-thumbnail d-none d-xl-block">
                                <use
                                  [attr.xlink:href]="'../../assets/images/' + package.package_icons[0] + '#Layer_1'"
                                  [attr.href]="'../../assets/images/' + package.package_icons[0] + '#Layer_1'"
                                ></use>
                              </svg>
                            </ng-container>
                            <ng-container *ngIf="package.package_icons[0]?.split('.').pop() !== 'svg'">
                              <img class="package-images" src="../../assets/images/{{ package.package_icons[0] }}" />
                            </ng-container>
                          </div>
                          <div class="col-6 pr-0">
                            <span class="row font-weight-500 products">{{ package?.name }}</span>
                            <span *ngIf="package?.formValues?.price_qty > 1" class="row font-small-400"
                              >Anzahl: {{ package.formValues.price_qty }}</span
                            >
                            <span
                              *ngIf="!package.productBundleData"
                              class="row font-weight-400 text-light-gray-2 font-size-noraml"
                            >
                              <span (click)="onPackageRemove(package, serviceIndex)"
                                ><u>Produkt entfernen</u></span
                              ></span
                            >
                          </div>
                          <div class="col-4 align-self-end text-right font-weight-500 flex-column pr-0">
                            <span
                              *ngIf="package?.formValues?.price_qty > 1"
                              class="font-small-400 d-flex justify-content-end"
                              >{{
                                ms.floatToString(
                                  ms.stringToFloat(package.formValues.display_price) / package.formValues.price_qty
                                )
                              }}
                              €</span
                            >
                            <span class="col-12 pr-0">
                              <span *ngIf="package.formValues">
                                <span
                                  *ngIf="
                                    package.formValues.undiscounted_price &&
                                    package.formValues.undiscounted_price * 1 > package.formValues.display_price * 1
                                  "
                                >
                                  <!--TODO: avoid the function call from html-->
                                  <s>{{ ols.getUndiscountedPrice(package) }}</s>
                                </span>
                                {{ ms.floatToString(ms.stringToFloat(package.formValues.display_price)) }}
                              </span>
                              <span *ngIf="!package.formValues" class="">
                                <!--TODO: avoid the function call from html-->
                                {{ ols.getPrice(package) }}
                              </span>
                              €</span
                            >
                          </div>
                        </div>
                      </ng-template>
                      <ng-template #contentLoader let-item="item">
                        <div *ngIf="item.type === 'service'" class="d-flex flex-wrap col-12 mt-2 border-bottom pb-2">
                          <div class="col-2 p-0 mt-1">
                            <svg class="package-images cart-item-thumbnail d-none d-xl-block">
                              <use
                                [attr.xlink:href]="'../../assets/images/' + $any(item.data).imageUrl + '#Layer_1'"
                                [attr.href]="'../../assets/images/' + $any(item.data).imageUrl + '#Layer_1'"
                              ></use>
                            </svg>
                          </div>
                          <div class="col-10 pr-0">
                            <span class="row font-weight-500 products">Produktauswahl treffen</span>
                            <span class="row font-weight-500 products"
                              >ab {{ ms.floatToString(getServicePrice(item) | addVat) + " €" }}</span
                            >
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>
                  </div>
                  <div class="d-flex col-12 m-auto pt-2 justify-content-around">
                    <button
                      mat-stroked-button
                      class="btn btn-outline-primary col-6 m-1 ml-0 h6"
                      (click)="removeAllProducts()"
                    >
                      ALLES ENTFERNEN
                    </button>
                    <button
                      *ngIf="!gs.isSidebarDisabled"
                      (click)="saveForLaterAndGoBack()"
                      class="btn btn-primary col-6 h6 px-1 m-1 ml-0"
                    >
                      FÜR SPÄTER SPEICHERN
                    </button>
                  </div>
                  <div class="d-flex col-12 m-auto pt-2 p-0" *ngIf="auth.myUserObservable.isPrivateIndividual">
                    <div class="col-7 pl-2 mt-3">
                      <span class="font-weight-600">zzgl. MwSt.</span>
                    </div>
                    <h5 class="mb-0 mt-3 col-5 pl-0 text-right font-weight-500">
                      <ng-container *ngIf="ols.totalSum !== ols.undiscountedTotalSum">
                        <small>
                          <s>{{ ms.floatToString(ms.stringToFloat(ols.undiscountedTotalSum) | getVat) }} €</s>
                        </small>
                      </ng-container>
                      {{ ms.floatToString(ms.stringToFloat(ols.totalSum) | getVat) }} €
                    </h5>
                    <hr />
                  </div>
                  <div class="d-flex col-12 m-auto pt-2 p-0">
                    <div class="col-7 pl-2 mt-3">
                      <span class="font-weight-600">Gesamtsumme</span>
                      <span class="text-light-gray-2 font-weight-400 font-size-small">
                        <ng-container *ngIf="!auth.myUserObservable.isPrivateIndividual"> (zzgl.</ng-container>
                        <ng-container *ngIf="auth.myUserObservable.isPrivateIndividual"> (inkl.</ng-container>
                        MwSt.)
                      </span>
                    </div>
                    <h5 class="mb-0 mt-3 col-5 pl-0 text-right font-weight-500">
                      <ng-container *ngIf="ols.totalSum !== ols.undiscountedTotalSum">
                        <small>
                          <s>{{ ms.floatToString(ms.stringToFloat(ols.undiscountedTotalSum) | addVat) }} €</s>
                        </small>
                      </ng-container>
                      {{ ms.floatToString(ms.stringToFloat(ols.totalSum) | addVat) }} €
                    </h5>
                    <hr />
                  </div>
                  <hr />
                  <div class="col-12 d-flex m-1 p-0">
                    <div class="rebatt col-12 pl-1">
                      <span>Rabattcode hier eingeben oder einfügen</span>
                    </div>
                  </div>
                  <div class="col-12 d-flex align-items-center px-1">
                    <div class="col-8 px-1">
                      <input
                        type="text"
                        name="activatedDiscountCode"
                        [(ngModel)]="activatedDiscountCode"
                        (keydown.enter)="updateDiscount()"
                        class="form-control product-search"
                      />
                    </div>
                    <div class="col-4 p-0 ml-2">
                      <button class="btn btn-primary h6 m-auto" (click)="updateDiscount()">EINLÖSEN</button>
                    </div>
                  </div>
                  <div class="col-12 mt-1">
                    <div *ngIf="isValidCode" class="col-12 font-small-400 row p-0 pt-1">
                      <div class="col-8 px-2">
                        <span class="text-success font-weight-500"
                          ><img height="22px" width="22px" src="../assets/images/discountTag.png"
                        /></span>
                        {{ activatedDiscountCode }}
                        <div class="row">
                          <span class="col-12 font-weight-500 text-light-gray-2">
                            <span (click)="updateDiscount(true)">Rabattcode entfernen</span></span
                          >
                        </div>
                      </div>
                      <div class="col-4 p-0 font-size-large font-weight-500 text-right">
                        -
                        {{
                          ms.floatToString(ms.stringToFloat(ols.undiscountedTotalSum) - ms.stringToFloat(ols.totalSum))
                        }}
                        €
                      </div>
                    </div>

                    <div *ngIf="invalidCode" class="col-12 p-0 font-small-400">
                      <br />
                      <span class="text-danger"
                        ><img height="22px" width="22px" src="../assets/images/discountCodeWarning.png" /> Den
                        Gutscheincode {{ invalidCode }} gibt es leider nicht. Stellen Sie sicher, dass Sie den richtigen
                        Gutscheincode verwenden.
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </ng-container>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'profile'">
            <i matTooltip="Profil" class="material-icons material-icons-outlined text-imo">account_circle</i>
            <p>
              <span class="d-lg-none d-md-block">Profil</span>
            </p>
          </a>
        </li>
        <li class="nav-item" *ngIf="auth.myUserObservable.role === userRoleEnum.Administrator">
          <a class="nav-link" [routerLink]="'settings'">
            <i matTooltip="Einstellungen" class="material-icons material-icons-outlined text-imo">settings</i>
            <p>
              <span class="d-lg-none d-md-block">Einstellungen</span>
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://www.imogent.de/hilfe-center/">
            <i matTooltip="Hilfe" class="material-icons material-icons-outlined text-imo">help_outline</i>
            <p>
              <span class="d-lg-none d-md-block">Hilfe</span>
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link clickable" (click)="loader.logout()">
            <i matTooltip="Logout" class="material-icons material-icons-outlined text-imo">logout</i>
            <p>
              <span class="d-lg-none d-md-block">Logout</span>
            </p>
          </a>
        </li>
      </ul>
    </div>
    <div *ngIf="!auth.myUserObservable" class="justify-content-end">
      <a class="btn btn-back mx-4" href="javascript: window.history.back();">Zurück zur Website</a>
      <a class="btn btn-primary" routerLink="/login"> Anmelden </a>
    </div>
  </div>
</nav>

<ngx-smart-modal
  #newsModal
  identifier="newsModal"
  [closable]="false"
  [dismissable]="true"
  [escapable]="true"
  [customClass]="'pack-modal-custom'"
>
  <div class="row customMargin" *ngIf="newFeatures.length">
    <div class="col-6 text-left">
      <p class="text-primary text-center news-header py-3">Das ist neu auf der Plattform!</p>
      <p class="text-center font-weight-bolder">{{ newFeatures[featureIdx].title }}</p>
      <p class="text-center feature-text m-0" [innerHTML]="newFeatures[featureIdx].text"></p>
      <div class="container">
        <div class="row align-items-end">
          <div class="col-12 text-center bottomdiv30">
            <button
              class="btn btn-primary btn-sm align-self-end"
              (click)="goNextNews()"
              *ngIf="featureIdx + 1 < newFeatures.length"
            >
              Weiter
            </button>
            <button
              class="btn btn-primary btn-sm align-self-end"
              (click)="closeModal('newsModal')"
              *ngIf="featureIdx + 1 == newFeatures.length"
            >
              Schließen
            </button>
          </div>
          <div class="col-12 text-center bottomdiv">
            <app-dot-nav
              class="align-self-end"
              [numberOfDots]="newFeatures.length"
              (selectionChanged)="changeNews($event)"
              #dotNav
            ></app-dot-nav>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 p-0 text-right feature-image">
      <img [src]="newFeatures[featureIdx].image" class="img-fluid" />
    </div>
  </div>
  <i class="fas fa-times-circle icon-close clickable text-white" (click)="closeModal('newsModal')"></i>
</ngx-smart-modal>

<ngx-smart-modal
  #lockedEstateModal
  identifier="lockedEstateModal"
  [closable]="false"
  [dismissable]="false"
  [escapable]="false"
  [customClass]="'pack-modal-custom'"
>
  <div class="row customMargin">
    <div class="col-6 text-left">
      <div class="container">
        <div class="row align-items-end">
          <div class="col-12 text-center mt-5">
            <div class="font-weight-bold">
              Bitte beachten Sie, dass ausschließlich Bestellungen für folgende Immobilie getätigt werden können:
            </div>
            <div class="text-primary mt-3">
              Immobilie {{ gs.lockedRealEstate?.objectNumber
              }}<span *ngIf="gs.lockedRealEstate?.title"><br />{{ gs.lockedRealEstate.title }}</span>
              <span *ngIf="gs.lockedRealEstate?.street"><br />{{ gs.lockedRealEstate.street }}</span
              ><span *ngIf="gs.lockedRealEstate?.streetnumber"> {{ gs.lockedRealEstate.streetnumber }}</span>
              <span *ngIf="gs.lockedRealEstate?.postalcode"><br />{{ gs.lockedRealEstate.postalcode }}</span
              ><span *ngIf="gs.lockedRealEstate?.city"> {{ gs.lockedRealEstate?.city }}</span>
            </div>
            <div class="mt-3 mb-5">
              Wenn Sie Bestellungen für eine andere Immobilie aufgeben möchten, ändern Sie die Immobilie direkt in
              OnOffice und öffnen Sie die IMOGENT Plattform erneut.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 p-0 text-right locked-estate-background">
      <img src="../../assets/images/lockedEstate.png" class="centered locked-estate-image" />
    </div>
  </div>
  <!--<i class="fas fa-times-circle icon-close clickable text-white" (click)="closeModal('lockedEstateModal')"></i>-->
  <span class="cross icon-close no-shadow" [matMenuTriggerFor]="menu"
    ><mat-icon>cancel_presentation</mat-icon>
    <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item (click)="closeModal('lockedEstateModal')">Schließen</button>
      <button
        mat-menu-item
        (click)="closeAndHideModal('lockedEstateModal')"
        *ngIf="!auth.myUserObservable?.hideLockedEstateModal"
      >
        Schließen und nicht mehr anzeigen
      </button>
      <button
        mat-menu-item
        (click)="closeAndAutoShowModal('lockedEstateModal')"
        *ngIf="auth.myUserObservable?.hideLockedEstateModal"
      >
        Schließen & wieder automatisch anzeigen
      </button>
    </mat-menu>
  </span>
</ngx-smart-modal>
