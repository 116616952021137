import { Component, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { CONSTANTS } from "@app/util/constants";
import { Router } from "@angular/router";
import { GlobalService } from "@app/_services/global.service";
import { AuthService } from "@app/auth/auth.service";

@Component({
  selector: "app-reset-session",
  templateUrl: "./reset-session.component.html",
  styleUrls: ["./reset-session.component.css"],
})
export class ResetSessionComponent implements OnInit {
  constructor(
    private cookieService: CookieService,
    private router: Router,
    private gs: GlobalService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.gs.isSidebarDisabled = true;
    this.gs.isNavbarDisabled = true;
    if (this.cookieService.check(CONSTANTS.USER_CONSENT_COOKIE_NAME)) {
      this.cookieService.delete(CONSTANTS.USER_CONSENT_COOKIE_NAME);
    }

    const allCookies = this.cookieService.getAll();
    Object.keys(allCookies).forEach((key) => {
      this.cookieService.delete(key);
    });

    sessionStorage.clear();
    localStorage.clear();
    console.log("Done?");

    this.authService.logout();
  }

  goToLogin() {
    this.router.navigate(["/login"]);
  }
}
