import { Injectable } from "@angular/core";
import { CONSTANTS } from "@app/util/constants";
import { environment } from "@environments/environment";
import { ProductBundle } from "@app/interfaces/product-bundle.interface";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { buildQueryString } from "@app/util/helper";

@Injectable({
  providedIn: "root",
})
export class ProductBundlesService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.PRODUCT_BUNDLES;

  constructor(private http: HttpClient) {}

  getProductBundles() {
    return this.http.get<QueryResult<ProductBundle>>(this.endpoint);
  }

  getProductBundlesWithPaginator(from, to, sortBy, sortDirection, queryMap): Observable<QueryResult<ProductBundle>> {
    const queryString = buildQueryString(from, to, sortBy, sortDirection, queryMap);
    return this.http.get<QueryResult<ProductBundle>>(this.endpoint + queryString);
  }

  patchProductBundle(path: string, data: ProductBundle) {
    return this.http.patch(this.endpoint + "/" + path, data);
  }

  postProductBundle(data) {
    return this.http.post<ProductBundle>(this.endpoint, data);
  }

  deleteProductBundle(productBundleId: string) {
    return this.http.delete(this.endpoint + "/" + productBundleId);
  }
}
