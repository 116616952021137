import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Package } from "@app/models/package";
import { FormControl, FormGroup } from "@angular/forms";
import { OrdersLogicService } from "../../_services/orders-logic.service";
import { ImoRetouchingOptions } from "@app/interfaces/imo-retouching-options.interface";
import { SelectionTile } from "@app/shared-module/select-tiles/select-tiles.component";
import { getDefaultPackageOptionFromProductBundleOptions } from "@app/util/helper";
import { ProductBundleData } from "@app/interfaces/product-bundle-data.interface";
import { OrderTypeEnum } from "@app/models/order-type.enum";

@Component({
  selector: "app-imo-retouching-options",
  templateUrl: "./imo-retouching-options.component.html",
  styleUrls: ["./imo-retouching-options.component.css"],
})
export class ImoRetouchingOptionsComponent implements OnInit {
  @Input() package: Package;
  @Input() parentForm: FormGroup;
  @Input() hiddenOptions: number[];
  @Input() isEditorial: boolean;
  @Input() heading: string;
  @Input() showOriginalFormatOption: boolean;
  // @Output() postProcessingChanged: EventEmitter<MatCheckboxChange> = new EventEmitter<MatCheckboxChange>();
  isDroneFootage: boolean;
  retouchingOptions: ImoRetouchingOptions[];
  droneFile = "";
  photoFormatOptions: SelectionTile[] = [
    {
      title1: "4:3",
      value: "4:3",
    },
    {
      title1: "3:2",
      value: "3:2",
    },
  ];
  productBundleData: ProductBundleData;
  packagePhotoFormatOptionKeywordMap: Map<string, string> = new Map();

  constructor(private ols: OrdersLogicService) {}

  /*  get postProcessing() {
    return this.parentForm.get('postProcessing');
  }*/

  ngOnInit() {
    this.isDroneFootage = this.package.service_key === OrderTypeEnum.Drone_Media;
    this.productBundleData = (<Package>this.package).productBundleData;
    this.retouchingOptions = [
      {
        name: "Blauer Himmel",
        photo1: "../../assets/images/slider/BlueSky_Before",
        photo2: "../../assets/images/slider/BlueSky_After",
        isSelected: true,
        isDisabled: false,
        formName: "blueSky",
        info: false,
        description: "Bei dieser Option ersetzen wir einen bedeckten Himmel durch einen natürlichen, blauen Himmel.",
      },
      {
        name: "Nachbarhäuser weichzeichnen",
        photo1: "../../assets/images/slider/Blurring_before",
        photo2: "../../assets/images/slider/Blurring_after",
        isSelected: true,
        isDisabled: false,
        formName: "blurred",
        info: false,
        description: this.isEditorial
          ? "Nachbarhäuser werden bei dieser Option unkenntlich gemacht. Private Details wie Kennzeichen, Gesichter etc. werden standardmäßig weichgezeichnet."
          : this.isDroneFootage
          ? "Nachbarhäuser werden bei dieser Option unkenntlich gemacht. Private Details wie Kennzeichen, Gesichter etc. werden standardmäßig weichgezeichnet."
          : "Nachbarhäuser werden bei dieser Option unkenntlich gemacht. Private Details wie Dokumente, Kennzeichen, Gesichter etc. werden standardmäßig weichgezeichnet.",
      },
      {
        name: "Logo einfügen",
        photo1: "../../assets/images/slider/Ohne-Logo",
        photo2: "../../assets/images/slider/Mit-Logo",
        isSelected: false,
        isDisabled: false,
        formName: "logo",
        info: false,
        description: "Ihr Logo wird standardmäßig unten rechts im Bild eingefügt.",
      },
      {
        name: "Rasen verschönern",
        photo1: "../../assets/images/slider/GrassPatching_Before",
        photo2: "../../assets/images/slider/GrassPatching_After",
        isSelected: true,
        isDisabled: false,
        formName: "gras",
        info: false,
        description: "Unschöne, kahle Stellen im Rasen können in der Nachbearbeitung auf Wunsch ausgebessert werden.",
      },
    ];

    if (this.package.service_key === "dronemedia") {
      this.droneFile = "_drone";
    }
    if (this.hiddenOptions) {
      this.hiddenOptions.forEach((option) => {
        this.retouchingOptions[option].isSelected = false;
      });
    }
    for (let i = 0; i < this.retouchingOptions.length; i++) {
      const option = this.retouchingOptions[i];
      if (this.parentForm.controls[option.formName].value === null) {
        // Only overwrite the value when the component is initially loaded in order to prevent resetting the form each time!
        this.parentForm.controls[option.formName].setValue(option.isSelected);
      } else {
        // When reloading the component update the retouchingOptions array with the parent forms values
        this.retouchingOptions[i].isSelected = this.parentForm.controls[option.formName].value;
      }
    }
    this.packagePhotoFormatOptionKeywordMap.set("4:3", "photos_format_4:3");
    this.packagePhotoFormatOptionKeywordMap.set("3:2", "photos_format_3:2");
    const productDefaultOptionList = this.productBundleData && this.productBundleData.productDefaultOptionList;
    const defaultFormat = this.parentForm.get("photoFormat").value || "3:2";
    if (this.showOriginalFormatOption) {
      this.photoFormatOptions.push({
        title1: "Format übernehmen",
        value: "Same as input",
      });
    }
    const defaultPhotoFormat = getDefaultPackageOptionFromProductBundleOptions(
      this.packagePhotoFormatOptionKeywordMap,
      productDefaultOptionList,
      defaultFormat
    );

    this.parentForm.get("photoFormat").setValue(defaultPhotoFormat);
    this.parentForm.updateValueAndValidity();
  }

  onPostProcessingChange($event: MatCheckboxChange) {
    if (!$event.checked) {
      this.parentForm.get("logo").reset(false);
      this.parentForm.get("logo").disable();
      this.parentForm.get("blurred").reset(false);
      this.parentForm.get("blurred").disable();
      this.parentForm.get("blueSky").reset(false);
      this.parentForm.get("blueSky").disable();
      this.parentForm.get("logoImage").reset(false);
      this.parentForm.get("logoImage").disable();

      // this.logoUploader.clearQueue();
      // this.retouchingComment.reset();
      // this.retouchingComment.disable();
      // this.outputFormat = [];
      if (
        this.ols.editingSavedOrder &&
        this.package.subOrderValues &&
        this.package.subOrderValues.dependentValues &&
        this.package.subOrderValues.dependentValues.id &&
        this.ols.deletedSubOrderIds.indexOf(this.package.subOrderValues.dependentValues.id) < 0
      ) {
        this.ols.deletedSubOrderIds.push(this.package.subOrderValues.dependentValues.id);
      }
    } else {
      this.parentForm.get("logo").enable();
      this.parentForm.get("blurred").enable();
      this.parentForm.get("blueSky").enable();
      this.parentForm.get("logoImage").enable();
      this.parentForm.get("retouchingComment").enable();
      // this.active2 = true;
    }
    this.package.form = this.parentForm;
    // this.emitFormValues();
  }

  tileSelectHandler(option: number) {
    this.retouchingOptions[option].isSelected = !this.retouchingOptions[option].isSelected;
    this.parentForm.controls[this.retouchingOptions[option].formName].setValue(
      this.retouchingOptions[option].isSelected
    );
  }

  onInfoClick(event: Event, value: boolean, id: number) {
    event.stopPropagation();
    this.retouchingOptions[id].info = value;
  }
}
