import {Injectable} from '@angular/core';
import * as accounting from 'accounting';

@Injectable({
  providedIn: 'root'
})
export class MoneyService {

  constructor() {
  }

  public floatToString(money: number, thousand = '.', decimal = ',') {
    if (typeof money === 'string') {
      if (money === '') {
        return decimal === '.' ? '0.00' : '0,00';
      }
      return money;
      // money = accounting.unformat(money, ',');
    }
    return accounting.formatMoney(money, '', 2, thousand, decimal);
  }

  public stringToFloat<T>(money, precision?: number) {
    if (typeof money === 'number') {
      return money;
      // money = accounting.formatMoney(money, '', 2, '.', ',');
    }

    return this.unformat(money, ',');
  }

  public formatMoneyProxy(money: string | number, symbol: string = '€', precision: number = 2, thousand: string = '.', decimal: string = ','): string {
    return accounting.formatMoney(money, symbol, precision, thousand, decimal);
  }

  public toFixed(val: number, precision: number) {
    return accounting.toFixed(val, precision);
  }

  public unformat(val: string, decimal?: string) {
    return accounting.unformat(val, decimal);
  }
}
