import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "@app/auth/auth.service";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { FeedbackToolModeEnum } from "@app/models/feedback-tool-mode.enum";
import { SubOrderDraftStatusEnum } from "@app/models/suborder-draft-status-list";
import { SuborderOutputFormat } from "@app/models/suborder-output-format.enum";
import { SubOrdersLogicService } from "@app/_services/sub-orders-logic.service";

@Component({
  selector: "app-stand-alone-feedback-tool",
  templateUrl: "./stand-alone-feedback-tool.component.html",
  styleUrls: ["./stand-alone-feedback-tool.component.css"],
})
export class StandAloneFeedbackToolComponent implements OnInit {
  suborderId: string;
  suborder: SubOrder;
  mode = FeedbackToolModeEnum.Normal;
  outputFormat: SuborderOutputFormat;
  isFeedbackFinished = false;
  isFeedbackAvailable = false;
  isSuborderAvailable = true;
  latestEstimatedDeliveryDate: Date;

  constructor(private sls: SubOrdersLogicService, private activatedRoute: ActivatedRoute, public auth: AuthService) {}

  async ngOnInit(): Promise<void> {
    this.showLoader(true);

    try {
      this.suborderId = this.activatedRoute.snapshot.params["sid"];
      if (this.suborderId) {
        this.suborder = await this.sls.getSuborder(this.suborderId);
        await this.setOutputFormat();
        this.setFeedbackAvailable();
        this.latestEstimatedDeliveryDate = <Date>(
          this.suborder?.statusDates?.feedbacks[this.suborder?.feedbackNumber - 1]?.estimatedDelivery
        );
      }
      this.showLoader(false);
    } catch (err) {
      this.isSuborderAvailable = false;
      this.showLoader(false);
    }
  }

  async setOutputFormat() {
    if (this.suborder?.pendingOnSuborderId) {
      const parentSubOrder = await this.sls.getSuborder(this.suborder?.pendingOnSuborderId);

      this.outputFormat =
        parentSubOrder.selectedPackage === SuborderOutputFormat.Video
          ? SuborderOutputFormat.Video
          : SuborderOutputFormat.Photo;
    } else {
      this.outputFormat = SuborderOutputFormat.Video;
      if (this.suborder?.selectedPackage && this.suborder?.selectedPackage !== SuborderOutputFormat.Video) {
        this.outputFormat = SuborderOutputFormat.Photo;
      }
      if (this.suborder?.virtualTour || this.suborder?.indvVirtualTour) {
        this.outputFormat = SuborderOutputFormat.Both;
      }
    }
  }

  setFeedbackAvailable() {
    const latestDeliveryDate = this.suborder?.statusDates?.feedbacks[this.suborder?.feedbackNumber - 1]?.delivery;
    if (latestDeliveryDate) {
      this.isFeedbackAvailable = true;
    }
  }

  showLoader(flag: boolean) {
    this.auth.showLoader.emit(flag);
  }

  async onFinish() {
    this.auth.showLoader.emit(true);
    this.isFeedbackFinished = true;
    await this.sls.finalizeSuborderFeedback(SubOrderDraftStatusEnum.Feedback, this.suborder);
    this.auth.showLoader.emit(false);
  }
}
