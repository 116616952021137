import { ListView } from "@app/interfaces/list-view.interface";

export enum ListViewEnum {
  All = "All",
  MyList = "MyList",
  CompanyList = "CompanyList",
  Automated = "Automated",
  PartlyAutomated = "PartlyAutomated",
  NonAutomated = "NonAutomated",
  PinnedOrders = "pinnedOrders",
  PhotographyOrders = "photographyOrders",
}

export const listViewList: ListView[] = [
  {
    id: ListViewEnum.All,
    name: "All",
  },
  {
    id: ListViewEnum.MyList,
    name: "Mein Account",
  },
  {
    id: ListViewEnum.CompanyList,
    name: "Unternehmen",
  },
  {
    id: ListViewEnum.Automated,
    name: "Automatisiert",
  },
  {
    id: ListViewEnum.PartlyAutomated,
    name: "Teilautomatisiert",
  },
  {
    id: ListViewEnum.NonAutomated,
    name: "Nicht automatisiert",
  },
  {
    id: ListViewEnum.PinnedOrders,
    name: "Gepinnte Aufträge",
  },
  {
    id: ListViewEnum.PhotographyOrders,
    name: "Fotografie",
  },
];
