import { Component, Inject, OnInit } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { CONSTANTS } from "@app/util/constants";

@Component({
  selector: "app-forgot",
  templateUrl: "./forgot.component.html",
  styleUrls: ["./forgot.component.css"],
})
export class ForgotComponent implements OnInit {
  showSpkLogo = false;
  year: number;
  formSubmitted: boolean;

  constructor(public authService: AuthService, private router: Router, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {
    if (CONSTANTS.SPARKASSEN_DOMAINS.includes(document.location.hostname)) {
      this.showSpkLogo = true;
    }
    this.year = CONSTANTS.CUR_YEAR;
  }

  sendLink(eMail: string) {
    this.formSubmitted = true;
    this.authService.sendPasswordResetLink(eMail, this.showSpkLogo);
  }

  goBack() {
    this.router.navigate(["/"]);
  }
}
