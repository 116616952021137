<div class="carousel-container">
  <div class="carousel-wrapper">
    <img alt="{{ currentImage?.url || 'style' }}" class="img-fluid w-100" src="{{ currentImage?.url }}" />
  </div>
  <div class="dot-navigation">
    <div
      *ngFor="let image of images; let i = index"
      class="dot"
      [ngClass]="{ active: currentImage?.index === i }"
      (click)="jumpToImage(i)"
    ></div>
  </div>
  <div class="carousel-controls">
    <div class="arrow-icon p-2" (click)="previousImage()">
      <i
        class="fas fa-chevron-left text-center"
        [ngClass]="{ gray: currentImage?.index === 0, 'text-primary clickable': currentImage?.index !== 0 }"
      ></i>
    </div>
    <div class="arrow-icon p-2" (click)="nextImage()">
      <i
        class="fas fa-chevron-right text-center"
        [ngClass]="{
          gray: currentImage?.index + 1 === images?.length,
          'text-primary clickable': currentImage?.index + 1 !== images?.length
        }"
      ></i>
    </div>
  </div>
</div>
