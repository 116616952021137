import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MollieMandate } from "@app/interfaces/mollie-mandate.interface";
import { MolliePayment } from "@app/interfaces/mollie-payment.interface";
import { MolliePaymentMethodEnum } from "@app/models/mollie-payment-method.enum";
import { MolliePaymentSequenceTypeEnum } from "@app/models/mollie-payment-sequence-type-enum";
import { CONSTANTS } from "@app/util/constants";
import { environment } from "@environments/environment";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { UserAuthModel } from "@app/models/user-auth.model";

@Injectable({
  providedIn: "root",
})
export class PaymentsService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.PAYMENTS;

  constructor(private http: HttpClient) {}

  getMethods(sequenceType: MolliePaymentSequenceTypeEnum) {
    return this.http.get<string[]>(this.endpoint + "/methods/" + sequenceType);
  }

  getMandates(method?: MolliePaymentMethodEnum) {
    let options: { params?: HttpParams } = {};

    if (method) {
      options.params = new HttpParams().append("method", method);
    }

    return this.http.get<MollieMandate[]>(this.endpoint + "/mandates", options);
  }

  async createFirstPayment(
    method: MolliePaymentMethodEnum,
    amount: { value: string; currency: string },
    description: string,
    redirectUrl: string,
    webhookUrl?: string,
    metadata?: any,
    cardToken?: string
  ) {
    const data = {
      method: method,
      amount: amount,
      description: description,
      redirectUrl: redirectUrl,
      webhookUrl: webhookUrl,
      metadata: metadata,
      cardToken: cardToken,
    };

    const response = await this.http.post<any>(this.endpoint + "/first", data).toPromise();
    const payment = response.payment;
    const newUserToken = response.newUserToken;
    // replacing existent userToken data with new one in case it was received, since its storing the Mollie customer ID and this value gets created when doing 1st payments in Mollie.
    if (newUserToken) {
      let user: UserAuthModel = JSON.parse(localStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER));
      user.userToken = newUserToken;
      localStorage.setItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER, JSON.stringify(user));
    }

    return {
      payment: <MolliePayment>{
        id: payment.id,
        description: payment.description,
        amount: payment.amount,
        status: payment.status,
        sequenceType: MolliePaymentSequenceTypeEnum.first,
      },
      paymentCheckoutUrl: payment._links?.checkout?.href,
    };
  }

  async createRecurringPayment(
    amount: { value: string; currency: string },
    description: string,
    mandateId?: string,
    webhookUrl?: string,
    metadata?: any
  ) {
    const data = {
      amount: amount,
      description: description,
      mandateId: mandateId,
      webhookUrl: webhookUrl,
      metadata: metadata,
    };

    const payment = await this.http.post<any>(this.endpoint + "/recurring", data).toPromise();

    return <MolliePayment>{
      id: payment.id,
      description: payment.description,
      amount: payment.amount,
      status: payment.status,
      sequenceType: MolliePaymentSequenceTypeEnum.recurring,
    };
  }

  getMollieWebhookUrl() {
    if (window.location.hostname === "localhost") {
      // localhost environment needs to pass Mollie a valid origin (localhost ISNT a valid origin), or else it will return an error saying that.
      origin = environment.platformUrl + "dummy";
    } else {
      origin = this.endpoint;
    }

    return origin + "/webhooks/mollie";
  }

  getOpenIndividualPaymentSuborders() {
    return this.http.get<SubOrder[]>(this.endpoint + "/open-individual-payments").toPromise();
  }

  removeMandate(mandateId: string) {
    return this.http.delete<void>(this.endpoint + "/mandates/" + mandateId);
  }
}
