import { PaymentOption } from "@app/interfaces/payment-option.interface";

export enum PaymentOptionEnum {
  Collective = "Collective",
  Sepa = "Sepa",
  Paypal = "Paypal",
  Visa = "Visa",
  OnAccount = "OnAccount",
}

export const paymentOptionList: PaymentOption[] = [
  {
    id: PaymentOptionEnum.Collective,
    name: "Sammelrechnung",
  },
  {
    id: PaymentOptionEnum.Sepa,
    name: "SEPA",
  },
  {
    id: PaymentOptionEnum.Paypal,
    name: "PayPal",
  },
  {
    id: PaymentOptionEnum.Visa,
    name: "Visa",
  },
  {
    id: PaymentOptionEnum.OnAccount,
    name: "Kauf auf Rechnung",
  },
];
