<!-- Page-Content -->
<ng-container *ngIf="auth.myUserObservable as user; else loading" xmlns="http://www.w3.org/1999/html">
  <!-- Show User list in a card -->
  <div class="shadow card cgrid">
    <div class="card-body">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <h4>Rechnungszeitraum auswählen</h4>
            <mat-checkbox
              color="primary"
              class="example-margin"
              [formControl]="restrictDates"
              (change)="updateInvoiceData()"
            >
            </mat-checkbox>
            <label class="m-2"> Zeitraum einschränken </label>
            <ng-container *ngIf="restrictDates.value">
              <div class="row">
                <div class="col-6">
                  <mat-form-field>
                    <input
                      matInput
                      [matDatepicker]="picker1"
                      placeholder="Start"
                      (dateChange)="addEvent('start', $event)"
                      [formControl]="dateToPassStart"
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field>
                    <input
                      matInput
                      [matDatepicker]="picker2"
                      placeholder="Ende"
                      (dateChange)="addEvent('end', $event)"
                      [formControl]="dateToPassEnd"
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-3"></div>
          <div class="col-3">
            Gruppieren
            <mat-radio-group aria-label="Select a grouping option">
              <p *ngFor="let groupOption of groupOptions">
                <mat-radio-button
                  [checked]="selectedGroupOption === groupOption"
                  [value]="groupOption"
                  (change)="groupOptionChanged($event)"
                  >{{ groupOption }}</mat-radio-button
                >
              </p>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="shadow card cgrid" *ngIf="!isTableLoading; else loading">
    <div class="card-body">
      <div class="mat-elevation-z8 material-table orders-table">
        <table mat-table [dataSource]="invoiceData" multiTemplateDataRows class="mat-elevation-z8" matSort>
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">
              <div class="row">
                <div class="col-12">Name</div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Suchen"
                    (click)="noSort($event)"
                    (input)="filterTable($event, 'name')"
                  />
                </div>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" width="15%">{{ row.name }}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="20%">
              <div class="row">
                <div class="col-12">Unternehmen</div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Suchen"
                    (click)="noSort($event)"
                    (input)="filterTable($event, 'company')"
                  />
                </div>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" width="20%">{{ row.company }}</td>
          </ng-container>

          <ng-container matColumnDef="sum_formatted">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%">
              <div class="row">
                <div class="col-12">Rechnungsbetrag</div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Suchen"
                    (click)="noSort($event)"
                    (input)="filterTable($event, 'sum_formatted')"
                  />
                </div>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" width="20%">{{ row.sum_formatted }} €</td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayDetails.length">
              <div
                class="example-element-detail"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
              >
                <div class="row subtable">
                  <!--                    <button class="btn btn-primary col-12 col-md-3" (click)="mergeRealEstates(currentInvoiceDetailGroup, true)"
                            *ngIf="currentInvoiceDetailGroup && currentInvoiceDetailGroup.length>1">Immobilien zusammenfassen
                    </button>-->
                  <!-- Not needed anymore. ?! At least when positions and multiple estates can be selected
                    in the fronted  -->
                  <div class="col-6">
                    <label id="example-radio-group-label">Rechnungsadresse</label>
                    <mat-radio-group
                      [(ngModel)]="chosenAddress"
                      aria-labelledby="example-radio-group-label"
                      class="example-radio-group"
                    >
                      <mat-radio-button
                        class="example-radio-button"
                        *ngFor="let address of availableBillingAddresses"
                        [value]="address"
                      >
                        <ng-container *ngIf="address">
                          <span *ngIf="address.company">{{ address.company }}</span>
                          <span *ngIf="address.taxId">({{ address.taxId }})</span>
                          <span *ngIf="address.company">,</span> {{ address.name }}, {{ address.street }}
                          {{ address.number }}, {{ address.postalCode }} {{ address.city }}
                          <span *ngIf="address.isCompanyAddress">(<b>Unternehmensadresse - lex-KNr.:</b></span>
                          <span *ngIf="address.lexOfficeNumber"> {{ address.lexOfficeNumber }}</span>
                          <span *ngIf="!address.lexOfficeNumber"> - </span>
                          <span *ngIf="address.isCompanyAddress">)</span>
                        </ng-container>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="col-6">
                    <button class="btn btn-warning" (click)="createInvoice(element)">Rechnung erstellen</button>
                  </div>
                  <div *ngFor="let realestate of currentInvoiceDetailGroup; let realestateIndex = index" class="pb-5">
                    <mat-card appearance="outlined">
                      <mat-card-title>
                        <div class="row">
                          <div class="col-6">
                            <mat-checkbox
                              color="primary"
                              (click)="toggleSelectionRealEstate($event, realestateIndex)"
                              [checked]="realestate.isSelected"
                            ></mat-checkbox>
                            {{ realestate.realestateName }}
                          </div>
                          <div class="col-3">{{ gs.format(realestate.total) }} €</div>
                          <div class="col-12">
                            <span class="font-size-normal"><b>Suborders: </b></span>
                            <span class="font-size-normal mr-2" *ngFor="let suborderId of realestate.suborderIds">
                              <mat-checkbox
                                color="primary"
                                (click)="toggleSuborderSelection($event, realestate, suborderId)"
                                [checked]="suborderIdSelectionDict[suborderId]"
                                [disabled]="!realestate.isSelected"
                              >
                              </mat-checkbox>
                              {{ suborderId | shorten : 6 : true : true | uppercase }}
                            </span>
                          </div>
                          <div class="col-3 float-right"></div>
                        </div>
                      </mat-card-title>

                      <mat-card-content>
                        <div class="row">
                          <div class="col-6"></div>
                          <div class="col-6"></div>
                        </div>
                        <div class="row">
                          <table class="table-size">
                            <thead>
                              <tr>
                                <th width="5%">
                                  <span *ngIf="realestate.isSelected">
                                    <i class="fas fa-check text-success"></i>
                                  </span>
                                  <span *ngIf="!realestate.isSelected">
                                    <i class="fas fa-times text-danger"></i>
                                  </span>
                                </th>
                                <th width="5%">Pos.</th>
                                <th width="20%">Artikel</th>
                                <th width="10%">PIN</th>
                                <th width="5%">Anzahl</th>
                                <th width="10%">Preis</th>
                                <th width="10%">Rabatt</th>
                                <th width="10%">Bezahlt</th>
                                <th width="10%">Gesamt(Offen)</th>
                                <th width="10%">Bestelldatum</th>
                                <th width="10%">Lieferdatum</th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngFor="let detail of realestate.details; let i = index">
                                <tr class="suborder-line">
                                  <td>
                                    <span *ngIf="detail.isSelected">
                                      <i class="fas fa-check text-success"></i>
                                    </span>
                                    <span *ngIf="!detail.isSelected">
                                      <i class="fas fa-times text-danger"></i>
                                    </span>
                                  </td>
                                  <td>
                                    {{ i + 1 }}
                                  </td>
                                  <td>
                                    {{ detail.description }}
                                  </td>
                                  <td class="text-primary hover-effect" (click)="showSuborder(detail.suborderId)">
                                    {{ detail.suborderId | shorten : 6 : true : true | uppercase }}
                                  </td>
                                  <td>
                                    {{ detail.quantity }}
                                  </td>
                                  <td>
                                    <span *ngIf="detail.price_formatted !== ''">{{ detail.price_formatted }} €</span>
                                    <span *ngIf="detail.price_formatted == ''">--</span>
                                  </td>
                                  <td>
                                    <span *ngIf="detail.discount && detail.discount_type == '%'"
                                      >{{ detail.discount * 100 }} %</span
                                    >
                                    <span *ngIf="!detail.discount">--</span>
                                  </td>
                                  <td>{{ detail.paid_amount | number : "1.2-2" }} €</td>
                                  <td>{{ detail.total_formatted }} €</td>
                                  <td>
                                    {{ detail.createdOn | date : "dd.MM.yyyy" }}
                                  </td>
                                  <td>
                                    <span *ngIf="detail.completedOn">{{
                                      detail.completedOn | date : "dd.MM.yyyy"
                                    }}</span>
                                    <span *ngIf="!detail.completedOn">nicht beendet</span>
                                  </td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </table>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: columnsToDisplay"
            class="example-element-row"
            [class.example-expanded-row]="expandedElement === row"
            (click)="getInvoiceDetails(row)"
          ></tr>
          <!--(click)="openDetailPage(row)"-->
          <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']; let rowIndex = index"
            class="example-detail-row"
          ></tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="user.role !== 'Administrator'">
    <div class="shadow card cgrid">
      <div class="card-header">
        <!-- <h5 class="mb-0">Alle Aufträge</h5> -->
        <h5 class="mb-0">Diese Funktion befindet sich aktuell noch in Bearbeitung</h5>
      </div>
      <div class="card-body">
        <p>Diese Funktion befindet sich aktuell noch in der Entwicklung.</p>
        <p>In der Zwischenzeit erhalten Sie Ihre Rechnungsdokumente am Monatsende per Mail.</p>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <div class="lds-ring ring-default centered">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-template>

<div class="lds-ring ring-default centered" *ngIf="isTableLoading">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>

<ngx-smart-modal #invoiceCreationModal [identifier]="'invoiceCreationModal'">
  <div class="row">
    <div class="col-12">
      <div class="header">Zusammenfassung</div>
    </div>
  </div>

  <div *ngIf="invoiceCreationModal.hasData()">
    <div *ngIf="invoiceCreationModal.getData() as data">
      <span><b>Immobilien: </b></span>{{ data.realestateNames }} <br />
      <span><b>Rechnungsadresse: </b></span> <br />
      <span *ngIf="data.billingAddress?.company">{{ data.billingAddress?.company }}</span
      ><br />
      <span *ngIf="data.billingAddress?.name">{{ data.billingAddress?.name }}</span
      ><br />
      <span
        >{{ data.billingAddress?.street }} {{ data.billingAddress?.number }}, {{ data.billingAddress?.postalCode }}
        {{ data.billingAddress?.city }}</span
      ><br />
      <span><b>E-Mail:</b></span
      >{{ data.billingAddress?.email }}<br />
      <span *ngIf="data.billingAddress?.lexOfficeNumber"
        ><b>Lex-Office KNr.: {{ data.billingAddress?.lexOfficeNumber }}</b></span
      ><br />
      <span><b>Rechnungssumme: </b></span>{{ data.total | number : "1.2-2" }} €<br />
      <hr />
      <table>
        <tr>
          <th>Artikel</th>
          <th>Preis</th>
          <th>Anzahl</th>
          <th>Discount</th>
          <th>Gesamt</th>
        </tr>
        <tr *ngFor="let detail of invoiceToCreate.invoice_details">
          <td>{{ detail.description }}</td>
          <td>{{ detail.total | number : "1.2-2" }} €</td>
          <td>{{ detail.quantity }}</td>
          <td>
            <span *ngIf="detail.discount">{{ detail.discount }}</span>
          </td>
          <td>{{ detail.total * detail.quantity | number : "1.2-2" }} €</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <b>{{ data.total | number : "1.2-2" }} €</b>
          </td>
        </tr>
      </table>
    </div>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description> JSON Raw Data </mat-panel-description>
      </mat-expansion-panel-header>
      <pre>{{ invoiceCreationModal.getData() | json }}</pre>
    </mat-expansion-panel>
  </div>
  <hr />
  <div class="row">
    <div class="col-6">
      <button class="btn btn-danger" (click)="invoiceCreationModal.close()">Abbrechen</button>
    </div>
    <div class="col-6 text-right">
      <button class="btn btn-primary" [disabled]="isInvoiceCreationExecuted" (click)="createAndSendInvoice()">
        Rechnung erstellen
      </button>
    </div>
  </div>
</ngx-smart-modal>
