<form [formGroup]="parentForm">
  <div class="row mt-4 no-gutters" [hidden]="true">
    <div class="col-4 col-md-auto">
      <div class="checkbox_align">
        <mat-checkbox
          class="mx-4"
          (change)="onPostProcessingChange($event)"
          color="primary"
          formControlName="postProcessing"
          id="{{ 'postprocessing-check' + package.name }}"
        >
        </mat-checkbox>
        <div class="font-small-400">hinzufügen</div>
      </div>
    </div>
    <div class="col-8 d-block d-md-none text-right text-primary">
      <span>gratis</span>
    </div>
    <div class="col-10 col-md-6 pt-3 pt-md-0 px-md-4">
      <div class="font-300">Nachbearbeitung</div>
      <div class="font-small-400">Farbkorrekturen, Licht-und Winkelanpassungen</div>
    </div>
    <div class="col d-none d-md-block text-right text-primary">
      <span>gratis</span>
    </div>
  </div>

  <div class="step-title mb-2">{{ heading }}</div>

  <app-select-tiles
    [classSingleTile]="'col-12 col-sm-4'"
    [isMandatory]="true"
    [multiple]="false"
    [tiles]="photoFormatOptions"
    [defaultIdx]="-1"
    class="col-12"
    formControlName="photoFormat"
  >
  </app-select-tiles>

  <hr />

  <div class="row">
    <div class="col-4 cards" [hidden]="hiddenOptions && hiddenOptions.includes(0)">
      <div
        [ngClass]="{
          'selected-tile': retouchingOptions[0].isSelected,
          'disabled-tile': retouchingOptions[0].isDisabled
        }"
        class="card card-pricing card-raised tile margin"
      >
        <app-ngx-comparison-slider
          [postImageUrl]="retouchingOptions[0].photo1 + droneFile + '.jpg'"
          [preImageUrl]="retouchingOptions[0].photo2 + droneFile + '.jpg'"
          [sliderPosPct]="50"
          class="slider"
        >
        </app-ngx-comparison-slider>
        <div *ngIf="retouchingOptions[0].info" class="infoText display-table">
          <span class="table-center-vertically correct-bottom-distance">{{ retouchingOptions[0].description }}</span>
        </div>
        <div class="card-body px-0">
          <mat-checkbox
            (change)="tileSelectHandler(0)"
            color="primary"
            [checked]="retouchingOptions[0].isSelected"
            [disabled]="retouchingOptions[0].isDisabled"
          >
          </mat-checkbox>
          {{ retouchingOptions[0].name }}
          <i
            (mouseenter)="onInfoClick($event, true, 0)"
            (mouseleave)="onInfoClick($event, false, 0)"
            class="fas fa-info-circle text-primary clickable fa-lg"
            >&nbsp;</i
          >
        </div>
      </div>
      <div *ngIf="retouchingOptions[0].isSelected" class="selected-tick"></div>
      <i *ngIf="retouchingOptions[0].isSelected" class="fa fa-check tick-icon"></i>
    </div>

    <div class="col-4 cards" [hidden]="hiddenOptions && hiddenOptions.includes(1)">
      <div
        [ngClass]="{
          'selected-tile': retouchingOptions[1].isSelected,
          'disabled-tile': retouchingOptions[1].isDisabled
        }"
        class="card card-pricing card-raised tile margin"
      >
        <app-ngx-comparison-slider
          [postImageUrl]="retouchingOptions[1].photo1 + droneFile + '.jpg'"
          [preImageUrl]="retouchingOptions[1].photo2 + droneFile + '.jpg'"
          [sliderPosPct]="50"
          class="slider"
        >
        </app-ngx-comparison-slider>
        <div *ngIf="retouchingOptions[1].info" class="infoText display-table">
          <span class="table-center-vertically correct-bottom-distance">{{ retouchingOptions[1].description }}</span>
        </div>
        <div class="card-body px-0">
          <mat-checkbox
            (change)="tileSelectHandler(1)"
            color="primary"
            [checked]="retouchingOptions[1].isSelected"
            [disabled]="retouchingOptions[1].isDisabled"
          >
          </mat-checkbox>
          {{ retouchingOptions[1].name }}
          <i
            (mouseenter)="onInfoClick($event, true, 1)"
            (mouseleave)="onInfoClick($event, false, 1)"
            class="fas fa-info-circle text-primary clickable fa-lg"
            >&nbsp;</i
          >
        </div>
      </div>
      <div *ngIf="retouchingOptions[1].isSelected" class="selected-tick"></div>
      <i *ngIf="retouchingOptions[1].isSelected" class="fa fa-check tick-icon"></i>
    </div>
  </div>
  <div class="row">
    <div class="col-4 cards" [hidden]="hiddenOptions && hiddenOptions.includes(2)">
      <div
        [ngClass]="{
          'selected-tile': retouchingOptions[2].isSelected,
          'disabled-tile': retouchingOptions[2].isDisabled
        }"
        class="card card-pricing card-raised tile margin"
      >
        <app-ngx-comparison-slider
          [postImageUrl]="retouchingOptions[2].photo1 + droneFile + '.jpg'"
          [preImageUrl]="retouchingOptions[2].photo2 + droneFile + '.jpg'"
          [sliderPosPct]="50"
          class="slider"
        >
        </app-ngx-comparison-slider>
        <div *ngIf="retouchingOptions[2].info" class="infoText display-table">
          <span class="table-center-vertically correct-bottom-distance">{{ retouchingOptions[2].description }}</span>
        </div>
        <div class="card-body px-0">
          <mat-checkbox
            (change)="tileSelectHandler(2)"
            color="primary"
            [checked]="retouchingOptions[2].isSelected"
            [disabled]="retouchingOptions[2].isDisabled"
          >
          </mat-checkbox>
          {{ retouchingOptions[2].name }}
          <i
            (mouseenter)="onInfoClick($event, true, 2)"
            (mouseleave)="onInfoClick($event, false, 2)"
            class="fas fa-info-circle text-primary clickable fa-lg"
            >&nbsp;</i
          >
        </div>
      </div>
      <div *ngIf="retouchingOptions[2].isSelected" class="selected-tick"></div>
      <i *ngIf="retouchingOptions[2].isSelected" class="fa fa-check tick-icon"></i>
    </div>

    <div class="col-4 cards" [hidden]="hiddenOptions && hiddenOptions.includes(3)">
      <div
        [ngClass]="{
          'selected-tile': retouchingOptions[3].isSelected,
          'disabled-tile': retouchingOptions[3].isDisabled
        }"
        class="card card-pricing card-raised tile margin"
      >
        <app-ngx-comparison-slider
          [postImageUrl]="retouchingOptions[3].photo1 + droneFile + '.jpg'"
          [preImageUrl]="retouchingOptions[3].photo2 + droneFile + '.jpg'"
          [sliderPosPct]="50"
          class="slider"
        >
        </app-ngx-comparison-slider>
        <div *ngIf="retouchingOptions[3].info" class="infoText display-table">
          <span class="table-center-vertically correct-bottom-distance">{{ retouchingOptions[3].description }}</span>
        </div>
        <div class="card-body px-0">
          <mat-checkbox
            (change)="tileSelectHandler(3)"
            color="primary"
            [checked]="retouchingOptions[3].isSelected"
            [disabled]="retouchingOptions[3].isDisabled"
          >
          </mat-checkbox>
          {{ retouchingOptions[3].name }}
          <i
            (mouseenter)="onInfoClick($event, true, 3)"
            (mouseleave)="onInfoClick($event, false, 3)"
            class="fas fa-info-circle text-primary clickable fa-lg"
            >&nbsp;</i
          >
        </div>
      </div>
      <div *ngIf="retouchingOptions[3].isSelected" class="selected-tick"></div>
      <i *ngIf="retouchingOptions[3].isSelected" class="fa fa-check tick-icon"></i>
    </div>
  </div>
</form>
