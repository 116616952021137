import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";
import { HttpClient } from "@angular/common/http";
import { FactSheetsService } from "@app/_services/fact-sheets.service";

@Injectable({
  providedIn: "root",
})
export class GrundrissSchmiedeService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.GRUNDRISS_SCHMIEDE;

  constructor(private http: HttpClient, private fss: FactSheetsService) {}

  /**
   * Creates an order using the provided sub order ID.
   *
   * @param {string} subOrderId - The sub order ID to create the order for.
   * @returns {Promise<any>} A promise that resolves to the created order.
   */
  createOrder(subOrderId: string): Promise<any> {
    return this.http.post(this.endpoint + "/create-order/" + subOrderId, null).toPromise();
  }

  /**
   * Sends an appointment confirmation email for a given sub-order ID.
   *
   * @param {string} subOrderId - The ID of the sub-order for which the appointment confirmation email will be sent.
   *
   * @return {Promise<any>} - A promise that resolves to the response of the HTTP request made to send the email.
   */
  sendAppointmentConfirmationEmail(subOrderId: string): Promise<any> {
    return this.http.post(this.endpoint + "/appointment-confirm/" + subOrderId, null).toPromise();
  }

  /**
   * Creates a fact sheet order for the given suborder id.
   *
   * @param {string} suborderId - The id of the suborder.
   *
   * @return {Promise<any>} - A Promise that resolves when the fact sheet order is created.
   */
  createFactSheetsOrder(suborderId: string): Promise<any> {
    return this.fss.sendPost("create-order/" + suborderId);
  }

  /**
   * Sends feedback for a suborder.
   *
   * @param {string} suborderId - The ID of the suborder.
   * @param {number} feedbackNumber - The feedback number.
   * @param {boolean} [isClaim=false] - Indicates whether the feedback is a claim.
   *
   * @return {Promise<any>} - A promise that resolves when the feedback is sent.
   */
  sendFeedback(suborderId: string, feedbackNumber: number, isClaim?: boolean) {
    return this.http
      .post(this.endpoint + `/send-feedback/${suborderId}/${feedbackNumber}/${isClaim || false}`, {})
      .toPromise();
  }
}
