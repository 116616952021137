import {Component, OnInit, ViewChild, Renderer2, forwardRef, Input, Output, EventEmitter, AfterViewInit, HostListener} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, FormControl, NG_VALIDATORS} from '@angular/forms';

export interface SelectionTile {
    'title1': string;
    'title2'?: string;
    'title3'?: string;
    'isSelected'?: boolean;
    'isDisabled'?: boolean;
    'addPhotos'?: number;
    price?: any;
    'value': string;
    'tooltipInfo'?: string;
}

// https://dev.to/bitovi/understanding-angular-s-control-value-accessor-interface-5e7k
@Component({
    selector: 'app-select-tiles',
    templateUrl: './select-tiles.component.html',
    styleUrls: ['./select-tiles.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SelectTilesComponent),
        multi: true,
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => SelectTilesComponent),
        multi: true,
    }],
})
export class SelectTilesComponent implements ControlValueAccessor, Validator, AfterViewInit {

    @ViewChild('parent') parent;
    @Input() tiles: SelectionTile[];
    @Input() multiple: boolean;
    @Input() defaultIdx = 0;
    @Input() isMandatory: boolean;
    @Input() delimiter: string;
    @Input() classSingleTile = 'col';
    @Input() classTileWrapper = 'row';
    @Input() classCards: string;
    @Input() classCardsBody: string;
    @Input() classCardsTitle: string;
    @Input() showBadge = true;
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() change: EventEmitter<any> = new EventEmitter();

    value: any;
    onChanged: any = () => {
    };
    onTouched: any = () => {
    };


    ngAfterViewInit() {
        this.handleTileClick(this.defaultIdx);

        // we need a timeout because sometimes this.tiles can be empty even ngAfterViewInit
        setTimeout(() => {
            this.handleSelection(this.value);
        }, 300);
    }

    /**
     * Writes a new value to the element.
     * This method is called by the forms API to write to the view when programmatic changes from model to view are requested.
     * Likewise, it is called on instantiation.
     * E.g.: FormControl.setValue('abc') / FormGroup.patchValue({test:abc})
     */
    writeValue(obj: string): void {
        this.value = obj;
        this.onChanged(obj);
        this.onTouched();
        this.change.emit(obj);

        // Handle selection property for styling
        this.handleSelection(obj);

    }

    async handleSelection(val) {
        if (val) {
            let selection = [];
            if (this.multiple) {
                selection = val.split(this.delimiter);
            } else {
                this.tiles.forEach(tile => {
                    tile.isSelected = false;
                });
                selection = [val];
            }
            this.tiles.forEach(tile => {
                if (selection.indexOf(tile.value) >= 0) {
                    tile.isSelected = true;
                }
            });
        } else {
            this.value = val;
            this.tiles.forEach(tile => {
                tile.isSelected = false;
            });
        }
    }

    handleTileClick(tileIndex) {
        if (
            !this.tiles[tileIndex]
            || this.tiles[tileIndex]?.isDisabled
            || tileIndex === -1
        ) {
            return;
        }

        const checked = this.tiles[tileIndex].isSelected;
        const selectedTiles = this.tiles.filter((item) => item.isSelected);
        let data;

        if (checked) {
            if (this.isMandatory && selectedTiles.length > 1 || !this.isMandatory) {
                this.tiles[tileIndex].isSelected = !this.tiles[tileIndex].isSelected;
            }
        } else {
            if (!this.multiple) {
                this.tiles.forEach(tile => tile.isSelected = false);
            }
            this.tiles[tileIndex].isSelected = !this.tiles[tileIndex].isSelected;
        }
        data = [];
        this.tiles.forEach(tile => {
            if (tile.isSelected) {
                data.push(tile.value);
            }
        });
        if (this.multiple) {
            data = data.join(this.delimiter);
        } else {
            data = data[0];
        }

        this.value = data;
        this.onChanged(data);
        this.onTouched();

        // Fixme: ControlValueAccessor changes should be listened to via Angular FormGroup valueChanges()
        this.change.emit(data);
    }


    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     */
    registerOnChange(fn: any) {
        this.onChanged = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.tiles.forEach(tile => tile.isDisabled = isDisabled);
    }

    public validate(c: FormControl) {
        /* return (this.value) ? null : {
          required: {
            valid: false
          },
        }; */
        return null;
    }

}
