<div *ngIf="gs.isWhitelabelVersion; else imogent" id="paddingdiv">
  <div class="logout">
    <img class="mb-4 object-fit-contain" src="assets/images/Logo_png_RGB_dunkelgruen.png" height="94px" width="300px" />
    <ng-container *ngIf="authservice.sessionEnded === true; else forbidden">
      <h4 class="mb-2">{{ "logout.title" | translate }}</h4>
      <h4>{{ "logout.title_2" | translate }}</h4>
      <br />
    </ng-container>
    <ng-template #forbidden>
      <h4 class="b mb-2">{{ "logout.title_3" | translate }}</h4>
      <h4>{{ "logout.title_4" | translate }}</h4>
      <br />
    </ng-template>
    <a class="btn btn-link" routerLink="/login" style="padding-left: 0em">{{ "logout.login" | translate }}</a>
    <p class="b nomarbot">&copy;{{ year }} IMOGENT GmbH</p>
    <a href="https://www.imogent.de/datenschutz" target="_blank" class="my-link">{{ "logout.privacy" | translate }}</a
    ><span> | </span
    ><a class="my-link" href="https:www.imogent.de/impressum" target="_blank">{{ "logout.impressum" | translate }}</a>
  </div>
</div>

<ng-template #imogent>
  <div class="main">
    <div class="header col-12 p-0 mb-5">
      <div class="col-md-7 col-sm-12 px-0 m-auto py-3 pb-5">
        <div class="col-12 mt-2">
          <img
            class="imo-logo mb-4 object-fit-contain"
            src="assets/images/Logo_png_RGB_dunkelgruen.png"
            height="40px"
            width="150px"
          />
        </div>
        <div class="col-md-5 col-sm-12 pl-5 ml-3">
          <ng-container>
            <h3 class="font-weight-800 black-color letter-spacing-1">{{ "logout.title" | translate }}</h3>
            <div class="font-size-normal my-3">{{ "logout.title_2" | translate }}</div>
          </ng-container>
          <a class="btn btn-primary font-weight-600 rounded-pill" routerLink="/login">{{
            "logout.login" | translate | uppercase
          }}</a>
        </div>
      </div>
    </div>

    <div class="content pb-2">
      <div class="col-md-7 col-sm-10 row m-auto">
        <div class="col-md-6 col-sm-12 p-0 mt-4 d-flex justify-content-end">
          <img class="service-avatar" height="300px" width="300px" src="assets/images/imogent_hero_support1.png" />
        </div>
        <div class="col-md-5 col-sm-12 mt-4 mb-3 ml-5">
          <div class="col-12">
            <h4 class="font-weight-800 black-color letter-spacing-1">Haben Sie Fragen?</h4>
            <div class="font-size-normal my-3">
              Gerne steht Ihnen unser Support-Team Montag bis Freitag von 9:00 bis 17:00 Uhr zur Verfügung.
            </div>
          </div>
          <div class="col-12">
            <h5 class="font-weight-800 text-black text-imo mb-0">service@imogent.com</h5>
            <h5 class="font-weight-800 text-black text-imo mb-0">+49 (0) 521 54 38 26 95</h5>
          </div>
          <div class="col-12">
            <div class="font-size-normal my-3">
              Die Antworten auf die häufigsten Fragen finden Sie übrigens in unserem Hilfe-Center.
            </div>
          </div>
          <a
            class="ml-3 btn btn-primary font-weight-600 rounded-pill"
            target="_blank"
            href="https://www.imogent.de/hilfe-center/"
            >{{ "ZUM HILFE-CENTER" | uppercase }}</a
          >
        </div>
      </div>
    </div>

    <div class="col-12 footer pt-5 font-weight-bold">
      <div class="col-12 pt-5 m-auto">
        <p class="b nomarbot">&copy; {{ year }} IMOGENT GmbH</p>
        <!-- Privacy Policy & Imprint -->
        <a href="https://www.imogent.de/datenschutz" target="_blank"> {{ "login.privacy" | translate }}</a>
        <span> | </span>
        <a href="https://www.imogent.de/agb/" target="_blank">AGB</a>
        <span> | </span>
        <a href="https://www.imogent.de/impressum" target="_blank"> {{ "login.impressum" | translate }}</a>
      </div>
    </div>
  </div>
</ng-template>
