<ngx-smart-modal
  #deleteSubcollectionModal
  [closable]="true"
  [dismissable]="true"
  [escapable]="true"
  identifier="deleteSubcollectionModal"
>
  <!-- Modal Title -->
  <h3 class="card-title b">Löschen bestätigen</h3>
  <hr />
  <div>
    <span class="text-danger"><b>Achtung:</b></span> Es werden sämtliche Datenbankeinträge und Dateien in
    {{ deleteSubcollectionModal.getData()?.path }}
    {{
      deleteSubcollectionModal.getData()?.filterCriteria
        ? "von
    Uploadfenster " + (deleteSubcollectionModal.getData()?.filterCriteria.isMarked ? "1" : "2")
        : ""
    }}
    <b>unwiderruflich gelöscht!</b>
  </div>

  <button (click)="ngxSmartModalService.close('deleteSubcollectionModal')" class="btn btn-sm btn-back float-left">
    Zurück
  </button>
  <button (click)="deleteSubcollection()" class="btn btn-sm btn-danger float-right">Löschen bestätigen</button>
</ngx-smart-modal>

<ng-container
  *ngIf="
    (feedbackMode || resultRejectionMode) &&
    suborder &&
    suborder.feedback_comments &&
    suborder.feedback_comments[feedbackStep]
  "
>
  <div
    *ngIf="
      suborder.feedback_comments[feedbackStep]['user_comment'] ||
      suborder.feedback_comments[feedbackStep]['rejection_comment']
    "
    class="row centeredFeedback displayBlock mb-3"
  >
    <div class="col-12">
      <h5 *ngIf="!suborder.resultText">Feedback, das für mehrere Bilder gilt:</h5>
      <h5 *ngIf="suborder.resultText">Feedback:</h5>
    </div>
    <div class="centeredFeedback col-12 row">
      <textarea [readonly]="true" class="form-control ml-3 mr-3 col-6">{{
        suborder.feedback_comments[feedbackStep]["user_comment"]
      }}</textarea>
      <textarea *ngIf="feedbackBy === 'Administrator'" [readonly]="true" class="form-control ml-3 mr-3 col-6">
        {{ suborder.feedback_comments[feedbackStep]["admin_comment"] }}
      </textarea>
    </div>
  </div>
</ng-container>
<div *ngIf="feedbackMode && !suborder?.resultText" class="container">
  <div class="row" class="centeredHeader">
    <div class="col-12">
      <hr />
      <h5>Feedback zu den einzelnen Bildern:</h5>
    </div>
  </div>
</div>
<div *ngIf="!feedbackMode && mode === 'carousel' && attachments.length" class="container">
  <div class="row" class="float-right mb-3">
    <div class="col-12">
      <hr />
      <i
        (click)="setDisplayMode('carousel')"
        [ngClass]="{ 'text-primary': displayMode === 'carousel' }"
        class="far fa-image fa-lg clickable"
      ></i>
      <i
        (click)="setDisplayMode('tiles')"
        [ngClass]="{ 'text-primary': displayMode === 'tiles' }"
        class="fas fa-th fa-lg ml-2 mr-2 clickable"
      ></i>
      <i
        (click)="setDisplayMode('list')"
        [ngClass]="{ 'text-primary': displayMode === 'list' }"
        class="fas fa-list fa-lg clickable"
      ></i>
    </div>
  </div>
</div>
<ng-container *ngIf="showCustomerImages">
  <ng-container *ngIf="mode === 'carousel'; else normalMode">
    <ng-container [ngSwitch]="displayMode">
      <ng-container *ngSwitchCase="'carousel'">
        <div
          *ngIf="showCarousel"
          [ngClass]="{
            'hide-left': firstIndex === 1,
            'hide-right': lastIndex === attachments?.length
          }"
          class="col-12 carousel-container"
        >
          <carousel
            (click)="onClick($event)"
            [interval]="0"
            [itemsPerSlide]="tilesPerSlide"
            [noWrap]="true"
            [showIndicators]="indicators"
            [singleSlideOffset]="true"
          >
            <slide (click)="onImageExpand(index, slide)" *ngFor="let slide of attachments; let index = index">
              <div
                [ngClass]="{
                  'active-img': selectedIndex === index
                }"
                class="img-container clickable"
              >
                <ng-container [ngSwitch]="slide.mediaType">
                  <div class="position-relative img-container">
                    <img
                      *ngSwitchCase="mediaTypeEnum.image"
                      [src]="slide.download_url_xs"
                      default
                      alt="image slide"
                      class="uploaded-img"
                      style="display: block; width: 100%"
                    />

                    <img
                      *ngSwitchCase="mediaTypeEnum.imgPdfPreview"
                      [src]="slide.download_url_xs"
                      default
                      alt="image slide"
                      class="uploaded-img"
                      style="display: block; width: 100%"
                    />

                    <pdf-viewer
                      *ngSwitchCase="mediaTypeEnum.pdf"
                      [autoresize]="true"
                      [original-size]="false"
                      [page]="1"
                      [render-text]="false"
                      [src]="slide.download_url"
                      [zoom-scale]="'page-fit'"
                      class="hide-overflow"
                    ></pdf-viewer>

                    <img
                      *ngSwitchCase="mediaTypeEnum.video"
                      alt="image slide"
                      class="uploaded-img"
                      src="assets/images/video_thumb.jpg"
                      style="display: block; width: 100%"
                    />

                    <img
                      *ngSwitchDefault
                      alt="image slide"
                      class="uploaded-img"
                      src="assets/images/no-image.png"
                      style="display: block; width: 100%"
                    />

                    <div
                      [ngClass]="{
                        mediaTypeOverlay: selectedIndex !== index,
                        mediaTypeOverlayActive: selectedIndex === index
                      }"
                      class="font-small-400"
                    >
                      {{ slide.fileType }}
                    </div>
                  </div>
                </ng-container>
              </div>
            </slide>
          </carousel>
        </div>
        <div class="col-12 text-center mt-2" *ngIf="attachments?.length > 0">
          <span *ngIf="attachments?.length == 1">{{
            "Bild " + firstIndex + " von insgesamt " + attachments?.length
          }}</span>
          <span *ngIf="attachments?.length > 1">{{
            "Bilder " + firstIndex + " - " + lastIndex + " von insgesamt " + attachments?.length
          }}</span>
          <br />
          <button
            class="btn btn-sm btn-danger"
            (click)="deleteAttachment(selectedImg?.id, selectedImg?.file_name, $event, selectedImg.download_url)"
            *ngIf="isLargeImageHidden && canDelete"
          >
            Foto löschen
          </button>
          <span *ngIf="auth.myUserObservable.role !== UserRoleEnum.Customer && canDelete">
            <button (click)="openDeleteModal()" class="btn btn-sm btn-danger">Alle löschen</button></span
          >
          <hr />
        </div>
        <div class="col-12 text-center mb-4" *ngIf="attachments?.length > 0 && selectedImg && !isLargeImageHidden">
          <div class="row">
            <div class="col-12" *ngIf="feedbackMode">
              <!-- Display Media based on mediaType -->
              <ng-container [ngSwitch]="selectedImg.mediaType">
                <img
                  [ngClass]="{ 'expanded-img2Cols': layoutCols === 2, 'expanded-img': layoutCols === 1 }"
                  class="expanded-img-feedbacked"
                  src="{{ selectedImg.download_url_m }}"
                  default
                  *ngSwitchCase="mediaTypeEnum.image"
                />

                <img
                  [ngClass]="{ 'expanded-img2Cols': layoutCols === 2, 'expanded-img': layoutCols === 1 }"
                  class="expanded-img-feedbacked"
                  src="{{ selectedImg.download_url_m }}"
                  default
                  *ngSwitchCase="mediaTypeEnum.imgPdfPreview"
                />

                <div
                  *ngSwitchCase="mediaTypeEnum.pdf"
                  [ngClass]="{ pdfContainer2Cols: layoutCols === 2, pdfContainer: layoutCols === 1 }"
                  class="pdfContainer"
                >
                  <pdf-viewer
                    [autoresize]="true"
                    [original-size]="false"
                    [src]="selectedImg.download_url"
                    [zoom-scale]="'page-fit'"
                    class="col-12 col-md-10 col-lg-8 col-xl-6"
                  ></pdf-viewer>
                </div>
                <div
                  [ngClass]="{ pdfContainer2Cols: layoutCols === 2, pdfContainer: layoutCols === 1 }"
                  class="pdfContainer"
                  *ngSwitchCase="mediaTypeEnum.video"
                >
                  <video
                    height="400px"
                    [src]="selectedImg.download_url"
                    class="videoContainer"
                    controls
                    *ngIf="layoutCols == 2"
                  ></video>
                  <video
                    height="660px"
                    [src]="selectedImg.download_url"
                    class="videoContainer"
                    controls
                    *ngIf="layoutCols == 1"
                  ></video>
                </div>
                <div *ngSwitchCase="mediaTypeEnum.tour">
                  <iframe [src]="selectedImg.download_url | safeURL" width="100%" height="550"></iframe>
                </div>
              </ng-container>
              <!-- Filename and download (+delete) Option -->
              <div class="row mt-3">
                <div class="col-8">
                  {{ selectedImg.file_name }}
                  <span *ngIf="selectedImg.createdOn">- {{ selectedImg.createdOn | date : "dd.MM.yyyy" }}</span>
                </div>
                <div class="col-4">
                  <i
                    class="fas fa-trash fa-2x mr-2 text-danger clickable"
                    (click)="
                      deleteAttachment(selectedImg?.id, selectedImg?.file_name, $event, selectedImg.download_url)
                    "
                    *ngIf="canDelete"
                  ></i>
                  <app-download-button
                    [selectedImage]="selectedImg"
                    [downloadSizes]="downloadSizes"
                  ></app-download-button>
                  <button
                    *ngIf="selectedImg.mediaType === mediaTypeEnum.tour"
                    class="btn btn-primary ml-2"
                    [cdkCopyToClipboard]="selectedImg?.download_url"
                  >
                    Link kopieren
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="!feedbackMode">
              <!-- Image "large" Preview -->
              <ng-container [ngSwitch]="selectedImg.mediaType">
                <img
                  *ngSwitchCase="mediaTypeEnum.image"
                  src="{{ selectedImg.download_url_m }}"
                  default
                  [ngClass]="{ 'expanded-img2Cols': layoutCols === 2, 'expanded-img': layoutCols === 1 }"
                />
                <img
                  *ngSwitchCase="mediaTypeEnum.imgPdfPreview"
                  src="{{ selectedImg.download_url_m }}"
                  default
                  [ngClass]="{ 'expanded-img2Cols': layoutCols === 2, 'expanded-img': layoutCols === 1 }"
                />
                <div
                  [ngClass]="{ pdfContainer2Cols: layoutCols === 2, pdfContainer: layoutCols === 1 }"
                  *ngSwitchCase="mediaTypeEnum.pdf"
                >
                  <pdf-viewer
                    [src]="selectedImg.download_url"
                    [original-size]="false"
                    [zoom-scale]="'page-fit'"
                    [autoresize]="true"
                    class="col-12 col-md-10 col-lg-8 col-xl-6"
                  ></pdf-viewer>
                </div>
                <div
                  *ngSwitchCase="mediaTypeEnum.video"
                  [ngClass]="{ pdfContainer2Cols: layoutCols === 2, pdfContainer: layoutCols === 1 }"
                >
                  <video
                    *ngIf="layoutCols === 2"
                    [src]="selectedImg.download_url"
                    class="videoContainer"
                    controls
                    height="400px"
                  ></video>
                  <video
                    *ngIf="layoutCols === 1"
                    [src]="selectedImg.download_url"
                    class="videoContainer"
                    controls
                    height="660px"
                  ></video>
                </div>
                <div *ngSwitchCase="mediaTypeEnum.tour">
                  <iframe [src]="selectedImg.download_url | safeURL" width="100%" height="550"></iframe>
                </div>
                <ng-container *ngSwitchDefault>
                  <strong>Hinweis: </strong>Diese Datei kann in Ihrem Browser nicht dargestellt werden.<br />
                  Laden Sie die Datei herunter, um Sie mit einem geeigneten Programm anzuzeigen. <br />
                  <img src="assets/images/no-image.png" alt="image slide" class="uploaded-img" *ngSwitchDefault />
                </ng-container>
              </ng-container>
              <!-- Filename and download (+delete) Option -->
              <div class="row mt-3 align-items-center">
                <div class="col-8">
                  {{ selectedImg.file_name }}
                  <span *ngIf="selectedImg.createdOn">- {{ selectedImg.createdOn | date : "dd.MM.yyyy" }}</span>
                </div>
                <div class="col-4">
                  <i
                    class="fas fa-trash fa-2x mr-1 text-danger clickable"
                    (click)="
                      deleteAttachment(selectedImg?.id, selectedImg?.file_name, $event, selectedImg.download_url)
                    "
                    *ngIf="canDelete"
                  ></i>
                  <app-download-button
                    [selectedImage]="selectedImg"
                    [downloadSizes]="downloadSizes"
                  ></app-download-button>
                  <button
                    *ngIf="selectedImg.mediaType === mediaTypeEnum.tour"
                    class="btn btn-primary ml-2"
                    [cdkCopyToClipboard]="selectedImg?.download_url"
                  >
                    Link kopieren
                  </button>
                </div>
              </div>
            </div>
            <div
              class="col-12 mt-3"
              *ngIf="
                feedbackMode &&
                ((selectedImg.comment && selectedImg.comment != '' && selectedImg.comment != ' ') ||
                  selectedImg.adminComment ||
                  selectedImg.counterMarkerComments?.length)
              "
            >
              <div>
                <h5>Feedback zum aktuellen Bild</h5>
              </div>
              <div>
                <textarea
                  [readonly]="true"
                  *ngIf="feedbackMode && selectedImg.comment && selectedImg.comment != '' && selectedImg.comment != ' '"
                  class="form-control2 textFieldSize col-6"
                  >{{ selectedImg.comment }}</textarea
                >
                <textarea
                  [readonly]="true"
                  *ngIf="feedbackMode && selectedImg.adminComment"
                  class="form-control2 textFieldSize col-6"
                  >{{ selectedImg.adminComment }}</textarea
                >
              </div>
              <ol *ngIf="selectedImg.counterMarkerComments?.length">
                <li class="text-left" *ngFor="let item of selectedImg.counterMarkerComments; let i = index">
                  <div>{{ item }}</div>
                  <div
                    *ngIf="
                      feedbackMode &&
                      selectedImg.adminCounterMarkerComments &&
                      selectedImg.adminCounterMarkerComments[i]
                    "
                    class="text-white bg-dark"
                  >
                    {{ selectedImg.adminCounterMarkerComments[i] }}
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'tiles'">
        <div class="container">
          <div class="row">
            <div *ngFor="let element of attachments; let i = index" class="col-12 col-md-6 col-xl-4">
              <ng-container [ngSwitch]="element.mediaType">
                <img
                  [src]="element.download_url_xs"
                  default
                  class="img-fluid mb-2 clickable"
                  (click)="openDialog(i)"
                  *ngSwitchCase="mediaTypeEnum.image"
                />

                <img
                  *ngSwitchCase="mediaTypeEnum.imgPdfPreview"
                  src="{{ selectedImg.download_url_xs }}"
                  default
                  [ngClass]="{ 'expanded-img2Cols': layoutCols === 2, 'expanded-img': layoutCols === 1 }"
                />

                <div class="position-relative col-12 clickable" *ngSwitchCase="mediaTypeEnum.pdf">
                  <pdf-viewer
                    [autoresize]="true"
                    [original-size]="false"
                    [page]="1"
                    [render-text]="false"
                    [src]="element.download_url"
                    [zoom-scale]="'page-fit'"
                    [show-all]="false"
                    class="hide-overflow"
                    (click)="openDialog(i)"
                  ></pdf-viewer>
                </div>

                <img
                  *ngSwitchCase="mediaTypeEnum.video"
                  alt="image slide"
                  class="img-fluid clickable"
                  src="assets/images/video_thumb.jpg"
                  (click)="openDialog(i)"
                />

                <img
                  *ngSwitchDefault
                  alt="image slide"
                  class="img-fluid clickable"
                  src="assets/images/no-image.png"
                  (click)="openDialog(i)"
                />
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'list'">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          matSortActive="file_name"
          matSortDirection="asc"
          (matSortChange)="sortData($event)"
          class="mat-elevation-z8 mb-5"
        >
          <ng-container matColumnDef="file_preview">
            <th mat-header-cell *matHeaderCellDef>Vorschau</th>
            <td mat-cell *matCellDef="let element; let i = index">
              <ng-container [ngSwitch]="element.mediaType">
                <div class="position-relative">
                  <img
                    *ngSwitchCase="mediaTypeEnum.image"
                    [src]="element.download_url_xs"
                    default
                    alt="image slide"
                    class="inTablePreview clickable"
                    (click)="openDialog(i)"
                  />

                  <img
                    *ngSwitchCase="mediaTypeEnum.imgPdfPreview"
                    [src]="element.download_url_xs"
                    default
                    alt="image slide"
                    class="inTablePreview clickable"
                    (click)="openDialog(i)"
                  />

                  <div class="position-relative img-container-xs clickable" *ngSwitchCase="mediaTypeEnum.pdf">
                    <pdf-viewer
                      [autoresize]="true"
                      [original-size]="false"
                      [page]="1"
                      [render-text]="false"
                      [src]="element.download_url"
                      [zoom-scale]="'page-fit'"
                      [show-all]="false"
                      class="hide-overflow inTablePreview"
                      (click)="openDialog(i)"
                    ></pdf-viewer>
                  </div>

                  <img
                    *ngSwitchCase="mediaTypeEnum.video"
                    alt="image slide"
                    class="inTablePreview clickable"
                    src="assets/images/video_thumb.jpg"
                    (click)="openDialog(i)"
                  />

                  <img
                    *ngSwitchDefault
                    alt="image slide"
                    class="inTablePreview clickable"
                    src="assets/images/no-image.png"
                    (click)="openDialog(i)"
                  />
                </div>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="file_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Dateiname</th>
            <td mat-cell *matCellDef="let file">{{ file.file_name }}</td>
          </ng-container>

          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Erstellt am</th>
            <td mat-cell *matCellDef="let file">{{ file.createdOn | date : "dd.MM.yyyy" }}</td>
          </ng-container>

          <ng-container matColumnDef="file_type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Dateityp</th>
            <td mat-cell *matCellDef="let element">{{ element.fileType }}</td>
          </ng-container>

          <ng-container matColumnDef="file_size">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Dateigröße</th>
            <td mat-cell *matCellDef="let element">
              {{ element.file_size | bytes : (element.file_size >= 1048576 ? 0 : 1) : "B" : "MB" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Aktion</th>
            <td mat-cell *matCellDef="let element">
              <i
                (click)="deleteAttachment(element?.id, element?.file_name, $event, element.download_url)"
                *ngIf="canDelete"
                class="fas fa-trash fa mr-2 text-danger clickable float-right"
              ></i>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #normalMode>
  <div class="container-fluid">
    <div class="row mb-5 col-12">
      <div *ngFor="let attachment of attachments; let index = index" class="col-md-3 padding-0">
        <div class="card card-product fullHeight padding-0 mb-0">
          <a [attr.data-target]="'#' + attachment.id" rel="noopener" target="_blank"></a>
          <a (click)="openDialog(index)">
            <div class="card-header card-header-image clickable">
              <img *ngIf="attachment.isImage" default [src]="attachment?.download_url_xs" data-toggle="modal" />
              <pdf-viewer
                *ngIf="attachment.mediaType === MediaTypeEnum.pdf"
                [autoresize]="true"
                [fit-to-page]="true"
                [original-size]="true"
                [src]="attachment.download_url"
              ></pdf-viewer>
              <img *ngIf="attachment.isVideo" src="assets/images/video_thumb.jpg" />
              <img
                *ngIf="
                  !attachment.isImage && attachment.mediaType === MediaTypeEnum.pdf && !isVideo(attachment.file_name)
                "
                src="assets/images/no-image.png"
              />
              <img
                *ngIf="attachment.mediaType === mediaTypeEnum.imgPdfPreview"
                [src]="attachment.download_url_xs"
                default
                alt="image slide"
                class="clickable"
              />
            </div>
          </a>
          <div class="card-body h-0"></div>
          <div class="card-footer text-muted">
            <div class="col-9" style="font-size: 10px">{{ attachment?.shortendFilename }}</div>
            <div class="col-3 text-right" *ngIf="attachment?.isMarked !== undefined">
              <i
                class="fas fa-2x fa-star text-light clickable"
                [ngClass]="{ 'text-primary': attachment?.isMarked === true }"
                (click)="toggleAttachmentMark(attachment)"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-group" *ngIf="auth.myUserObservable.role === UserRoleEnum.Administrator && attachments.length">
      <span class="btn bg-light" (click)="downloadAllFiles($event)">
        <span class="m-2 text-primary">Alles herunterladen </span>
        <i class="fa fa-download fa-1x clickable text-primary" aria-hidden="true"></i>
      </span>
      <button
        type="button"
        class="btn text-primary bg-light dropdown-toggle dropdown-toggle-split px-2"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        *ngIf="downloadSizes.length"
      >
        <span class="sr-only">Toggle Dropdown</span>
      </button>
      <div class="dropdown-menu mb-3" *ngIf="downloadSizes.length">
        <span
          *ngFor="let downloadSize of downloadSizes"
          class="dropdown-item"
          (click)="downloadAllFiles($event, downloadSize)"
          >{{ downloadSize.label }}</span
        >
      </div>
    </div>
  </div>
</ng-template>
