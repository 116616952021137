import { MediaType } from "@app/interfaces/mediaType.interface";

export enum MediaTypeEnum {
  image = "image",
  pdf = "pdf",
  video = "video",
  audio = "audio",
  tour = "tour",
  /**
   * Representing the image preview of a PDF document.
   */
  imgPdfPreview = "imgPdfPreview",
}

export const userRoleList: MediaType[] = [
  {
    id: MediaTypeEnum.image,
    name: "Image",
  },
  {
    id: MediaTypeEnum.pdf,
    name: "PDF",
  },
  {
    id: MediaTypeEnum.video,
    name: "Video",
  },
  {
    id: MediaTypeEnum.audio,
    name: "Audio",
  },
];
