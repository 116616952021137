import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { LandingPageTrackingItem } from "@app/interfaces/landing-page-tracking-item.interface";
import { CONSTANTS } from "@app/util/constants";
import { Observable } from "rxjs";
import { ConfigurationLogicService } from "./configuration-logic.service";

@Injectable({
  providedIn: "root",
})
export class LandingPageTrackingConfigResolverService implements Resolve<LandingPageTrackingItem[]> {
  constructor(private cs: ConfigurationLogicService) {}

  resolve() {
    return <Observable<LandingPageTrackingItem[]>>(
      this.cs.getConfiguration(CONSTANTS.CONFIGURATIONS.LANDING_PAGE_TRACKING_LIST)
    );
  }
}
