import { Injectable } from "@angular/core";
import { ImmoviewerService } from "@app/_services/immoviewer.service";
import { ApisService } from "@app/_services/apis.service";
import { CONSTANTS } from "@app/util/constants";

@Injectable({
  providedIn: "root",
})
export class ImmoviewerLogicService {
  private readonly APIsPath = CONSTANTS.EXTERNAL_APIs.IMMOVIEWER + "/";

  constructor(private is: ImmoviewerService, private apis: ApisService) {}

  async publish(immoviewerItemId: string) {
    return this.apis.callPut(this.APIsPath + immoviewerItemId, {}).toPromise();
  }

  async unpublish(immoviewerItemId: string) {
    return this.apis.callDelete(this.APIsPath + immoviewerItemId).toPromise();
  }

  async createFloorplanOrder(suborderId: string) {
    return this.is.post("create-order", { suborderId: suborderId }).toPromise();
  }
}
