<div class="row justify-content-center">
  <div class="col-12 col-md-8 col-lg-6" *ngIf="authService.myUserObservable; else loading">
    <div class="card" *ngIf="!authService.myUserObservable.offenblendeUser">
      <div class="card-header">
        <h5 class="card-title text-left wsn mb-0">Passwort ändern</h5>
      </div>
      <div class="card-body">
        <form [formGroup]="changePasswordForm">
          <div class="form-group">
            <input
              autocomplete="current-password"
              type="password"
              formControlName="currentPassword"
              class="form-control"
              #currentPwd
              placeholder="Aktuelles Passwort *"
              (input)="validateOtherFields(['newPassword1'])"
            />
            <span *ngIf="currentPassword.invalid && currentPassword.touched">
              <br />
              <span *ngIf="currentPassword.errors.required" class="alert alert-warning"
                >Bitte geben Sie Ihr aktuelles Passwort ein!</span
              >
              <span
                *ngIf="!currentPassword.errors.required && currentPassword.errors.passwordError"
                class="alert alert-warning"
                >{{ currentPassword.errors.passwordError.value }}</span
              >
            </span>
          </div>
          <div class="form-group">
            <input
              autocomplete="new-password"
              type="password"
              formControlName="newPassword1"
              class="form-control"
              #newPwd
              placeholder="Neues Passwort *"
              (input)="validateOtherFields(['newPassword2'])"
              [ngbPopover]="popContent"
              [popoverTitle]="popTitle"
              [autoClose]="false"
              placement="{{ popoverPlacement }}"
              triggers="focus active"
            />
            <span *ngIf="newPassword1.invalid && newPassword1.touched">
              <br />
              <span *ngIf="newPassword1.errors.required" class="alert alert-warning"
                >Bitte geben Sie ein neues Passwort ein!</span
              >
              <span
                *ngIf="!newPassword1.errors.required && newPassword1.errors.passwordError"
                class="alert alert-warning"
                >{{ newPassword1.errors.passwordError.value }}</span
              >
              <span
                *ngIf="
                  !newPassword1.errors.required &&
                  !newPassword1.errors.passwordError &&
                  newPassword1.errors.noRepeatPasswordError
                "
                class="alert alert-warning"
                >{{ newPassword1.errors.noRepeatPasswordError.value }}</span
              >
            </span>
            <ng-template #popTitle
              ><b>{{ "register.password_requirements.requirements" | translate }}</b>
            </ng-template>
            <ng-template #popContent>
              <ul>
                <li>
                  <i
                    *ngIf="
                      !newPassword1.errors ||
                        (newPassword1.errors.passwordError && newPassword1.errors.passwordError.length);
                      else notCorrect
                    "
                    class="fas fa-check-circle text-success"
                  ></i>
                  {{ "register.password_requirements.number" | translate }}
                </li>
                <li>
                  <i
                    *ngIf="
                      !newPassword1.errors ||
                        (newPassword1.errors.passwordError && newPassword1.errors.passwordError.lowerCase);
                      else notCorrect
                    "
                    class="fas fa-check-circle text-success"
                  ></i>
                  {{ "register.password_requirements.lowercase" | translate }}
                </li>
                <li>
                  <i
                    *ngIf="
                      !newPassword1.errors ||
                        (newPassword1.errors.passwordError && newPassword1.errors.passwordError.upperCase);
                      else notCorrect
                    "
                    class="fas fa-check-circle text-success"
                  ></i>
                  {{ "register.password_requirements.capital" | translate }}
                </li>
                <li>
                  <i
                    *ngIf="
                      !newPassword1.errors ||
                        (newPassword1.errors.passwordError && newPassword1.errors.passwordError.digit);
                      else notCorrect
                    "
                    class="fas fa-check-circle text-success"
                  ></i>
                  {{ "register.password_requirements.number2" | translate }}
                </li>
              </ul>
            </ng-template>
            <ng-template #notCorrect>
              <i class="fas fa-times-circle text-danger"></i>
            </ng-template>
          </div>
          <div class="form-group">
            <input
              autocomplete="repeat-password"
              type="password"
              formControlName="newPassword2"
              class="form-control"
              placeholder="Neues Passwort wiederholen *"
            />
            <span *ngIf="newPassword2.invalid && newPassword2.touched">
              <br />
              <span *ngIf="newPassword2.errors.required" class="alert alert-warning"
                >Die eingegebenen Passwörter stimmen nicht überein!</span
              >
              <span
                *ngIf="!newPassword2.errors.required && newPassword2.errors.passwordError"
                class="alert alert-warning"
                >{{ newPassword2.errors.passwordError.value }}</span
              >
              <span
                *ngIf="
                  !newPassword2.errors.required &&
                  !newPassword2.errors.passwordError &&
                  newPassword2.errors.repeatPasswordError
                "
                class="alert alert-warning"
                >{{ newPassword2.errors.repeatPasswordError.value }}</span
              >
            </span>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <button mat-raised-button type="submit" (click)="changePassword()" class="btn btn-fill btn-primary">
          Passwort ändern
        </button>
      </div>
    </div>
    <div class="card" *ngIf="authService.myUserObservable.offenblendeUser">
      <div class="card-header">
        <h5 class="card-title text-left wsn mb-0">Keine Berechtigung!</h5>
      </div>
      <div class="card-body">Diese Funktion steht Ihnen nicht zur Verfügung.</div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="lds-ring ring-default centered">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-template>
