<mat-dialog-content>
  <div class="row" *ngIf="!beforeSave">
    <div class="col-md-12 inline-col">
      <p class="delete-company delete-text">Sind Sie sicher, dass Sie alle Unternehmen löschen möchten?</p>
    </div>
  </div>
  <div class="row" *ngIf="beforeSave">
    <div class="col-md-12 inline-col">
      <p class="delete-company delete-text">Sind Sie sicher, dass Sie folgende Unternehmen löschen möchten?</p>
    </div>
  </div>
  <div class="row delete-row">
    <div class="col-md-12 inline-col">
      <div class="delete-item">
        <span>
          <i class="fa fa-trash fa-3x" aria-hidden="true"></i>
        </span>
      </div>
    </div>
  </div>
  <div class="row" *ngFor="let company of deletedCompaniesSpecialConditions">
    <div class="col-md-12 inline-col">
      <p class="delete-company">
        <b>{{ company.companyName }}</b>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 inline-col btn-text">
      <button class="btn btn-primary" (click)="closeDeleteModal()">ZURÜCK</button>
      <button class="btn btn-danger" *ngIf="!beforeSave" (click)="saveDeleteModal()">LÖSCHEN</button>
      <button class="btn btn-danger" *ngIf="beforeSave" (click)="saveOnlyPrice()">
        Nein, nur Preisanpassungen speichern
      </button>
      <button class="btn btn-danger" *ngIf="beforeSave" (click)="saveDeleteCompany()">
        Ja, löschen und fortfahren
      </button>
    </div>
  </div>
</mat-dialog-content>
