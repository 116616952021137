import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { passwordValidator, repeatPasswordValidator, noRepeatPasswordValidator } from '@app/shared-module/form.validators';
import { AuthService } from '@app/auth/auth.service';
import { LoaderService } from '@app/_services/loader.service';
import { NotificationsService } from '@app/_services/notifications.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, AfterViewInit {

  changePasswordForm: FormGroup;
  @ViewChild('currentPwd', { static: true }) currentPwd;
  @ViewChild('newPwd', { static: true }) newPwd;
  popoverPlacement = '';

  constructor(
    public authService: AuthService,
    private ns: NotificationsService,
    private loader: LoaderService
  ) {
    this.changePasswordForm = new FormGroup({
      'currentPassword': new FormControl('', [
        Validators.required
      ]),
      'newPassword1': new FormControl('', [Validators.required, passwordValidator()]),
      'newPassword2': new FormControl('', [Validators.required, passwordValidator()])
    });
  }

  ngOnInit() {
    if (window.matchMedia(`(min-width: 960px)`).matches) {
      this.popoverPlacement = 'right';
    } else {
      this.popoverPlacement = 'top';
    }
  }

  ngAfterViewInit() {
    const currentPwd = this.currentPwd.nativeElement;
    const newPwd = this.newPwd.nativeElement;
    this.newPassword1.setValidators([Validators.required, passwordValidator(), noRepeatPasswordValidator(currentPwd)]);
    this.newPassword2.setValidators([Validators.required, passwordValidator(), repeatPasswordValidator(newPwd)]);
  }

  get currentPassword() {
    return this.changePasswordForm.get('currentPassword');
  }

  get newPassword1() {
    return this.changePasswordForm.get('newPassword1');
  }

  get newPassword2() {
    return this.changePasswordForm.get('newPassword2');
  }

  changePassword(): void {
    this.changePasswordForm.updateValueAndValidity();
    if (this.changePasswordForm.valid) {
      this.authService.changePassword(this.newPassword1.value).subscribe(
        () => {
          this.ns.showNotification('Password has been changed successfully. Please login with new credentials.', 'success');
          this.loader.logout();
        },
        () => {
          this.ns.showNotification('Password could not be changed. Please try again later.', 'danger');
        });
    }
  }

  validateOtherFields(controls: string[]): void {
    controls.forEach(control => {
      if (this.changePasswordForm.controls[control].touched) {
        this.changePasswordForm.controls[control].updateValueAndValidity();
      }
    });
  }
}
