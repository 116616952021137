import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ServicesLogicService } from './services-logic.service';
import { of } from 'rxjs';
import { ObservableCachingService } from '@app/_services/observable-caching.service';

@Injectable({
  providedIn: 'root'
})
export class ServicesResolverService implements Resolve<any> {
  private resolvedServices: any;

  constructor(
    private ss: ServicesLogicService,
    private ocs: ObservableCachingService
  ) {
  }

  resolve() {
    if (this.ss.imoServices?.length >= 0) {
      return this.ocs.handleWithCache(() => of(this.ss.getImoServices()), this.resolvedServices, this.ss);
    } else {
      return this.ss.getImoServices();
    }
  }
}
