import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@app/auth/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { passwordValidator } from "@app/shared-module/form.validators";
import { CONSTANTS } from "@app/util/constants";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  userId: string;
  token: string;
  showSpkLogo: boolean;
  year: number;
  passwordChanged: boolean;
  submitted = false;

  public passwordForm: FormGroup;
  errorMsg: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe((params) => {
      this.userId = params["uid"];
      this.token = params["token"];
    });

    this.passwordForm = this.fb.group({
      password: ["", [Validators.required, passwordValidator()]],
    });
  }

  get password() {
    return this.passwordForm.get("password");
  }

  ngOnInit() {
    if (CONSTANTS.SPARKASSEN_DOMAINS.includes(document.location.hostname)) {
      this.showSpkLogo = true;
    }
    this.year = new Date().getFullYear();
  }

  resetPassword(newPassword: string) {
    this.authService.resetPassword(this.userId, this.token, newPassword);
  }

  submitHandler() {
    this.submitted = true;

    this.authService.resetPassword(this.userId, this.token, this.password.value).subscribe(
      (response) => {
        console.log(response);
        this.passwordChanged = true;
      },
      (errorResponse) => {
        this.errorMsg = errorResponse.error.message;
        this.passwordChanged = false;
      }
    );
  }

  goBack() {
    this.router.navigate(["/"]);
  }
}
