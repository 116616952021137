import { Component, Input, OnInit } from "@angular/core";
import { MediaTypeEnum } from "@app/models/media-type.model";
import { SubcollectionFileModel } from "@app/models/subcollectionFileModel";
import { getPDFDownloaderURL } from "@app/util/helper";

@Component({
  selector: "app-download-button",
  templateUrl: "./download-button.component.html",
  styleUrls: ["./download-button.component.css"],
})
export class DownloadButtonComponent implements OnInit {
  @Input() selectedImage?: SubcollectionFileModel;
  @Input() downloadSizes?: { label: string; url: string }[];
  @Input() isPdfDownload?: boolean;
  @Input() filename: string;

  mediaTypeEnum = MediaTypeEnum;
  constructor() {}

  ngOnInit(): void {
    console.log(this.selectedImage);

    if (this.isPdfDownload && this.selectedImage) {
      this.selectedImage.download_url = getPDFDownloaderURL(this.selectedImage.download_url, this.filename);
    }
  }
}
