import { Directive, ElementRef, Input, AfterContentInit } from '@angular/core';
import { AbstractControl, NgModel, Validators } from '@angular/forms';

@Directive({
  selector: '[appRequiredLabel]'
})
export class RequiredLabelDirective implements AfterContentInit {

  @Input() appRequiredLabel: AbstractControl | NgModel;
  formControl: AbstractControl;

  constructor(private el: ElementRef) {
  }

  ngAfterContentInit() {
    this.handleLabelRequirement();
    this.appRequiredLabel && this.appRequiredLabel.valueChanges.subscribe(() => {
      this.handleLabelRequirement();
    });
  }

  handleLabelRequirement() {
    let isRequired = false;
    if (this.appRequiredLabel) {
      if (this.appRequiredLabel instanceof NgModel) {
        isRequired = this.appRequiredLabel.validator(this.appRequiredLabel.control)?.required;
      } else {
        isRequired = this.appRequiredLabel.hasValidator(Validators.required);
      }
      if (isRequired) {
        this.el.nativeElement.classList.add('required-field');
      } else {
        this.el.nativeElement.classList.remove('required-field');
      }
    }
  }

}
