<div class="shadow card cgrid">
  <div *ngIf="auth.myUserObservable.role === UserRoleEnum.Administrator" class="card-body">
    <div class="row price-search">
      <div class="col-6 product-price">
        <label class="form-label">Suche</label>
        <div class="align-item">
          <input
            type="text"
            class="form-control product-search"
            [formControl]="productFilterCtrl"
            placeholder="Suche nach Preisverwaltung"
          />
          <i class="fas fa-search" [hidden]="productFilterCtrl.value"></i>
          <button class="btn btn-primary btn-search" (click)="searchAccountingData()">SUCHE</button>
        </div>
      </div>
      <div class="col-6 product-price">
        <label class="form-label">Produkt</label>
        <div class="align-item">
          <mat-select (selectionChange)="onProductFilter($event)" class="form-control product-select">
            <mat-option [value]="">zurücksetzen</mat-option>
            <mat-option *ngFor="let entry of productList" [value]="entry.value">
              {{ entry.label }}
            </mat-option>
          </mat-select>
          <button class="btn btn-primary btn-search">ANWENDEN</button>
        </div>
      </div>
    </div>
    <div class="mat-elevation-z8 material-table">
      <table mat-table [dataSource]="accountingPositionsDataSrc" matSort (matSortChange)="loadAccountingPositions()">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">
            <div class="row">
              <div class="col-12">Name</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row; let i = index" width="15%">
            <mat-form-field *ngIf="i === editIndex" floatLabel="auto">
              <input matInput placeholder="Name" [(ngModel)]="tempAccountingPosition.name" />
            </mat-form-field>
            <span *ngIf="i !== editIndex">{{ row.name }}</span>
          </td>
        </ng-container>

        <!-- Price Column -->
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef mat-sort-header width="20%">
            <div class="row">
              <div class="col-12">Kundenpreis</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row; let i = index" width="15%">
            <mat-form-field *ngIf="i === editIndex" floatLabel="auto">
              <input type="number" matInput placeholder="Preis" [(ngModel)]="tempAccountingPosition.price" />
            </mat-form-field>
            <span *ngIf="i !== editIndex">{{ row.price }}</span>
          </td>
        </ng-container>

        <!-- Ordertype Column -->
        <ng-container matColumnDef="ordertype">
          <th mat-header-cell *matHeaderCellDef mat-sort-header width="20%">
            <div class="row">
              <div class="col-12">Produkt</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" width="20%">{{ row.ordertype }}</td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef width="5%">
            <div class="row">
              <div class="col-12">Options</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row; let i = index" width="5%">
            <!-- row selected -->
            <div *ngIf="i === editIndex">
              <i class="fas fa-ban text-danger clickable" (click)="onCancelClick()"></i> &nbsp;
              <i class="far fa-save text-success clickable" (click)="onSaveClick()"></i>
            </div>
            <!-- row not selected -->
            <div *ngIf="i !== editIndex">
              <i
                class="fas fa-edit clickable"
                (click)="onEditClick(row, i)"
                [tooltip]="'Schnellbearbeitung'"
                [adaptivePosition]="false"
              ></i>
              <i
                class="fas fa-external-link-alt ml-4"
                (click)="openEditModal(row, i)"
                [tooltip]="'Details bearbeiten'"
                [adaptivePosition]="false"
              ></i>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true" class="tb-row-bg"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay" class="example-element-row"></tr>
        <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
      </table>
      <mat-paginator
        #paginator
        [pageSizeOptions]="[10, 20, 50]"
        [length]="accountingPositionsCount"
        (page)="loadAccountingPositions()"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
