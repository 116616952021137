import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'deliveryAlarm'
})
export class DeliveryAlarmPipe implements PipeTransform {
  transform(value: any, ...args): any {
    const today = moment(new Date()).startOf('day').toDate().valueOf();
    const estDelivery = moment(value).startOf('day').toDate().valueOf();
    if (today === estDelivery) {
      return 'text-warning';
    } else if (today < estDelivery) {
      return 'text-success';
    } else {
      return 'text-danger';
    }
  }
}
