import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CONSTANTS } from "@app/util/constants";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EmailTemplatesService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.EMAIL_TEMPLATES;
  public emailTemplateList = [];

  constructor(private http: HttpClient) {}

  async makeTemplateAvailable() {
    this.emailTemplateList = this.emailTemplateList.length
      ? this.emailTemplateList
      : await this.getEmailTemplateList().toPromise();
    return this.emailTemplateList;
  }

  getEmailTemplateList(): Observable<any[]> {
    return this.http.get<any[]>(this.endpoint);
  }

  createEmailTemplate(templateData: any): Observable<any> {
    return this.http.post<any>(this.endpoint, templateData);
  }

  updateEmailTemplate(templateData: any): Observable<any> {
    return this.http.put<any>(this.endpoint, templateData);
  }

  deleteEmailTemplate(queryParams: any): Observable<any> {
    return this.http.delete<any>(this.endpoint + "/" + queryParams);
  }
}
