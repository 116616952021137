import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import { ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http"; // Angular Http module
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { environment } from "@environments/environment";

import { AppComponent } from "./app.component";
import { FaqComponent } from "./faq";
import { HomeComponent } from "./home";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { ProfileComponent } from "./profile";
import { RealestateComponent } from "./realestate";
import { InboxComponent } from "./inbox";
import { SettingsComponent } from "./settings";
import { LogoutComponent } from "./logout/logout.component";
import { ForgotComponent } from "./forgot/forgot.component";
import { PaymentsComponent } from "./payments/payments.component";
import { TermsComponent } from "./terms/terms.component";
import { UsersComponent } from "./users/users.component";
import { AnalyticsComponent } from "./analytics/analytics.component";
import { SidebarModule } from "./sidebar/sidebar.module";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { SharedModule } from "./shared-module/shared.module";
import { MaterialModule } from "./material/material.module";
import { AppRoutingModule } from "./app-routing.module";
import { DownloadZipModule } from "./download-zip-module/download-zip.module";
import { AuthService } from "./auth/auth.service";
import { AuthGuard } from "./_guards/auth.guard";
import { RealestateService } from "./_services/realestate.service";
import { UsersService } from "./_services/users.service";
import { LoaderService } from "./_services/loader.service";
import { SidebarService } from "./sidebar/sidebar.service";
import { UploadService } from "./_services/upload.service";
import { GlobalService } from "./_services/global.service";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { FloortourModule } from "./floortour/floortour.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CompaniesComponent } from "./companies/companies.component";
import { AccountingPositionsComponent } from "./accounting-positions/accounting-positions.component";
import { InvoicesComponent } from "./invoices/invoices.component";
import { DocEstateService } from "./_services/docestate.service";
import { McGrundrissLogicService } from "./_services/mc-grundriss-logic.service";
import { DiscountsComponent } from "./discounts/discounts.component";
import { BrowserSupportComponent } from "./browser-support/browser-support.component";
import { RequestInterceptor } from "./_guards/request.interceptor";
import { SocketIoConfig, SocketIoModule } from "ngx-socket-io";
import { OrdersLogicService } from "./_services/orders-logic.service";
import { ProductBundlesComponent } from "./product-bundles/product-bundles.component";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { NgxMatDatetimePickerModule } from "@angular-material-components/datetime-picker";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import { BarChartModule } from "@swimlane/ngx-charts";
import { LoginWithTokenComponent } from "./login-with-token/login-with-token.component";
import { CompanyRoleHistoryModalComponent } from "./companies/company-role-history-modal/company-role-history-modal.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { CompanySearchModalComponent } from "./accounting-positions/company-search-modal/company-search-modal.component";
import { PriceDetailModalComponent } from "./accounting-positions/price-detail-modal/price-detail-modal.component";
import { PriceDeleteModalComponent } from "./accounting-positions/price-delete-modal/price-delete-modal.component";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { QuillModule } from "ngx-quill";
import { StandAloneFeedbackToolComponent } from "./stand-alone-feedback-tool/stand-alone-feedback-tool.component";
import { createErrorHandler, TraceService } from "@sentry/angular";
import { Router } from "@angular/router";
import { ImoConsentBannerComponent } from "./imo-consent-banner/imo-consent-banner.component";
import { MatDialogModule } from "@angular/material/dialog";
import { CookieService } from "ngx-cookie-service";
import { OnOfficeActivationComponent } from "./on-office-activation/on-office-activation.component";
import { ResetSessionComponent } from "./reset-session/reset-session.component";
import { NewsSlidesManagerComponent } from "./news-slides-manager/news-slides-manager.component";
import { PopoverModule } from "ngx-bootstrap/popover";
const config: SocketIoConfig = {
  url: environment.websocketUrl,
  options: { autoConnect: false, transports: ["websocket", "polling"] },
}; // Use websockets, if connection is not possible fall back to polling.

registerLocaleData(localeDe, "de-DE", localeDeExtra);

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    SidebarModule,
    HttpClientModule,
    SharedModule,
    MaterialModule,
    AppRoutingModule,
    NgxMatSelectSearchModule,
    DownloadZipModule,
    FloortourModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SocketIoModule.forRoot(config),
    BarChartModule,
    BsDropdownModule.forRoot(),
    QuillModule.forRoot(),
    MatDialogModule,
    PopoverModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ProfileComponent,
    RealestateComponent,
    InboxComponent,
    FaqComponent,
    SettingsComponent,
    LogoutComponent,
    ForgotComponent,
    PaymentsComponent,
    TermsComponent,
    UsersComponent,
    ChangePasswordComponent,
    AccountingPositionsComponent,
    CompaniesComponent,
    DiscountsComponent,
    InvoicesComponent,
    BrowserSupportComponent,
    ProductBundlesComponent,
    ResetPasswordComponent,
    AnalyticsComponent,
    LoginWithTokenComponent,
    CompanyRoleHistoryModalComponent,
    CompanySearchModalComponent,
    PriceDetailModalComponent,
    PriceDeleteModalComponent,
    StandAloneFeedbackToolComponent,
    OnOfficeActivationComponent,
    ImoConsentBannerComponent,
    ResetSessionComponent,
    NewsSlidesManagerComponent,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: "de-DE",
    },
    OrdersLogicService,
    AuthService,
    AuthGuard,
    RealestateService,
    UsersService,
    LoaderService,
    SidebarService,
    UploadService,
    GlobalService,
    MatDatepickerModule,
    DocEstateService,
    McGrundrissLogicService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    { provide: MAT_DATE_LOCALE, useValue: "de-DE" },
    CookieService,
  ],
  bootstrap: [BrowserSupportComponent], // Check for browser support before loading the main component.
})
export class AppModule {
  constructor(trace: TraceService) {}
}
// platformBrowserDynamic().bootstrapModule(AppModule);

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
