<div class="dialog text-center">
  <div class="float-right">
    <svg
      (click)="closeDialog()"
      class="cross"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.6796 0.998413C35.4596 0.998413 39.9996 5.75841 39.9996 12.8384V29.1804C39.9996 36.2404 35.4596 40.9984 28.6796 40.9984H11.3396C4.55963 40.9984 -0.000366211 36.2404 -0.000366211 29.1804V12.8384C-0.000366211 5.75841 4.55963 0.998413 11.3396 0.998413H28.6796ZM26.0196 14.9404C25.3396 14.2584 24.2396 14.2584 23.5396 14.9404L19.9996 18.4984L16.4396 14.9404C15.7396 14.2584 14.6396 14.2584 13.9596 14.9404C13.2796 15.6204 13.2796 16.7404 13.9596 17.4184L17.5196 20.9804L13.9596 24.5204C13.2796 25.2204 13.2796 26.3204 13.9596 26.9984C14.2996 27.3384 14.7596 27.5204 15.1996 27.5204C15.6596 27.5204 16.0996 27.3384 16.4396 26.9984L19.9996 23.4604L23.5596 26.9984C23.8996 27.3604 24.3396 27.5204 24.7796 27.5204C25.2396 27.5204 25.6796 27.3384 26.0196 26.9984C26.6996 26.3204 26.6996 25.2204 26.0196 24.5404L22.4596 20.9804L26.0196 17.4184C26.6996 16.7404 26.6996 15.6204 26.0196 14.9404Z"
        fill="#314149"
      />
    </svg>
  </div>
  <div class="image"><img src="../../../assets/icons/{{ icon }}" /></div>
  <div class="title mb-5 mt-0">{{ title }}</div>
  <div class="content p-0 mb-5" [innerHTML]="description"></div>
  <div class="button-parent mt-3 mb-3 d-flex justify-content-center">
    <button
      type="button"
      class="btn btn-outline-primary mr-3"
      *ngIf="secondaryButtonLabel"
      (click)="secondaryButtonClick()"
    >
      {{ secondaryButtonLabel }}
    </button>
    <button type="button" class="btn btn-primary" (click)="closeDialog()">{{ mainButtonLabel }}</button>
  </div>
</div>
