import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApisService } from "@app/_services/apis.service";

@Injectable({
  providedIn: "root",
})
export class TranslationService {
  private readonly logId = "TranslationService :: ";

  constructor(private apis: ApisService) {}

  /**
   * Calls an external service to translate multiple sources of text from german to english.
   * @param {string[]} sources The texts to translate.
   * @returns
   */
  getTranslation(sources: string[]): Observable<any> {
    let url = "translate?source=de&target=en";

    sources.forEach((source) => {
      url = url.concat("&q=" + encodeURIComponent(source));
    });

    console.log(this.logId + "Calling translation service endpoint:", url);
    return this.apis.callGet(url);
  }

  /**
   * Translates text in german to english, using an external service.
   * @param source The text to translate.
   * @returns
   */
  async translate(source: string) {
    console.log(this.logId + "Translating text:", source);
    let translation: string;

    try {
      const responseBody = await this.getTranslation([source]).toPromise();
      console.log(this.logId + "Translation response:", responseBody);
      if (responseBody.data && responseBody.data.translations && responseBody.data.translations[0]) {
        translation = responseBody.data.translations[0].translatedText;
      }
    } catch (error) {
      console.warn(this.logId + "Error found while translating text:", error);
    }

    console.log(this.logId + "Returning translated text:", translation);
    return translation;
  }
}
