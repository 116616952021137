import { Injectable } from "@angular/core";
import { MollieMandate } from "@app/interfaces/mollie-mandate.interface";
import { MolliePayment } from "@app/interfaces/mollie-payment.interface";
import { MolliePaymentMethodEnum } from "@app/models/mollie-payment-method.enum";
import { MolliePaymentSequenceTypeEnum } from "@app/models/mollie-payment-sequence-type-enum";
import { PaymentOptionEnum } from "@app/models/payment-option-list";
import { CONSTANTS } from "@app/util/constants";
import { environment } from "@environments/environment";
import * as Mollie from "../../assets/js/mollie";
import { PaymentsService } from "./payments.service";
import { AuthService } from "@app/auth/auth.service";
import { resolve } from "url";
import { Observable, Observer } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PaymentsLogicService {
  private readonly logId = "PaymentsLogicService :: ";
  paymentOptionMollieMethodMap = new Map<
    MolliePaymentSequenceTypeEnum,
    Map<PaymentOptionEnum, MolliePaymentMethodEnum>
  >();
  firstPaymentOptionMollieMethodMap = new Map<PaymentOptionEnum, MolliePaymentMethodEnum>();
  recurringPaymentOptionMollieMethodMap = new Map<PaymentOptionEnum, MolliePaymentMethodEnum>();
  mollie: any; // Mollie lib used to create the Credit Card holder data components

  constructor(private p: PaymentsService, private as: AuthService) {
    this.firstPaymentOptionMollieMethodMap.set(PaymentOptionEnum.Sepa, MolliePaymentMethodEnum.sofort);
    this.firstPaymentOptionMollieMethodMap.set(PaymentOptionEnum.Visa, MolliePaymentMethodEnum.creditcard);
    this.firstPaymentOptionMollieMethodMap.set(PaymentOptionEnum.Paypal, MolliePaymentMethodEnum.paypal);

    this.recurringPaymentOptionMollieMethodMap.set(PaymentOptionEnum.Sepa, MolliePaymentMethodEnum.directdebit);
    this.recurringPaymentOptionMollieMethodMap.set(PaymentOptionEnum.Visa, MolliePaymentMethodEnum.creditcard);
    this.recurringPaymentOptionMollieMethodMap.set(PaymentOptionEnum.Paypal, MolliePaymentMethodEnum.paypal);

    this.paymentOptionMollieMethodMap.set(MolliePaymentSequenceTypeEnum.first, this.firstPaymentOptionMollieMethodMap);
    this.paymentOptionMollieMethodMap.set(
      MolliePaymentSequenceTypeEnum.recurring,
      this.recurringPaymentOptionMollieMethodMap
    );
  }

  getMethods(sequenceType: MolliePaymentSequenceTypeEnum) {
    return this.p.getMethods(sequenceType);
  }

  getMandates(method?: MolliePaymentMethodEnum) {
    if (!this.as.myUserObservable.mollieCustomerId) {
      console.log(this.logId + "No Mollie customer Id found to load mandates.");
      return new Observable((observer: Observer<MollieMandate[]>) => {
        observer.next([]);
        observer.complete();

        // unsubscribe function doesn't need to do anything in this
        // because values are delivered synchronously
        return { unsubscribe() {} };
      });
    }
    return this.p.getMandates(method);
  }

  async createFirstPayment(
    method: MolliePaymentMethodEnum,
    amount: { value: string; currency: string },
    description: string,
    redirectUrl: string,
    webhookUrl?: string,
    metadata?: any,
    cardToken?: string
  ) {
    return await this.p.createFirstPayment(method, amount, description, redirectUrl, webhookUrl, metadata, cardToken);
  }

  async createRecurringPayment(
    amount: { value: string; currency: string },
    description: string,
    mandateId?: string,
    webhookUrl?: string,
    metadata?: any
  ) {
    return await this.p.createRecurringPayment(amount, description, mandateId, webhookUrl, metadata);
  }

  isMolliePaymentMethod(paymentOption: PaymentOptionEnum) {
    return [PaymentOptionEnum.Sepa, PaymentOptionEnum.Visa, PaymentOptionEnum.Paypal].includes(paymentOption);
  }

  getMollieWebhookUrl() {
    return this.p.getMollieWebhookUrl();
  }

  addMollieRef() {
    this.mollie = Mollie(CONSTANTS.CONFIGURATIONS.MOLLIE.PROFILE_ID, {
      locale: CONSTANTS.CONFIGURATIONS.MOLLIE.LOCALE,
      testmode: environment.mollieTestMode,
    });
  }

  removeMollieRef() {
    // Mollie lib is adding this element to our page everytime we create its object, so we need to remove it everytime in order to have a clean slate.
    document.querySelectorAll(".mollie-components-controller").forEach((elem) => elem.remove());
    this.mollie = null;
  }

  removeMandate(mandateId: string) {
    return this.p.removeMandate(mandateId);
  }
}
