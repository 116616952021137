import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";
import { HttpClient } from "@angular/common/http";
import { MoovinCustomerInteractionsContainer } from "@app/interfaces/moovin/moovinCustomerInteraction.interface";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MoovinCustomerInteractionsService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.MOOVIN_CI;

  constructor(private http: HttpClient) {}

  getCustomerInteractionsForSuborderId(suborderId: string): Observable<MoovinCustomerInteractionsContainer> {
    return this.http.get<MoovinCustomerInteractionsContainer>(this.endpoint + "/suborderId/" + suborderId);
  }

  sendMoovinCustomerResponse(
    suborderId: string,
    formData: FormData
  ): Observable<MoovinCustomerInteractionsContainer[]> {
    return this.http.post<MoovinCustomerInteractionsContainer[]>(this.endpoint + "/response/" + suborderId, formData);
  }
}
