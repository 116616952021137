<div [class]="classTileWrapper">
    <div
      #parent
      [class]="classSingleTile"
      *ngFor="let tile of tiles; let index = index"
      (click)="handleTileClick(index)"
    >
        <div
                class="card"
                [class]="classCards"
                [class.selected]="tile.isSelected"
                [class.with-badge]="tile.isSelected && showBadge"
                [class.disabled]="tile.isDisabled"
        >
            <div class="card-body" [class]="classCardsBody">
                <h4 class="card-title" [class]="classCardsTitle">
                    {{tile.title1}}
                    <ng-container *ngIf="tile.tooltipInfo">
                        <ng-template #popTemplate>
                            <div [innerHtml]="tile.tooltipInfo"></div>
                        </ng-template>
                        <mat-icon class="text-primary" [tooltip]="popTemplate" [adaptivePosition]="false">info
                        </mat-icon>
                    </ng-container>
                </h4>
                <div *ngIf="tile.title2">{{tile.title2}}</div>
                <div *ngIf="tile.title3">{{tile.title3}}</div>
            </div>
            <i *ngIf="tile.isSelected && showBadge" class="fa fa-check selected-icon"></i>
        </div>
    </div>
</div>
