<div>
  <h3 mat-dialog-title>
    Company Role Change History
  </h3>
  <mat-dialog-content>
    <div class="row">
      <div class="col-3">
        <label>Name:</label>
      </div>
      <div class="col-9">
        <span>{{user.name}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <label>Company:</label>
      </div>
      <div class="col-9">
        <span>{{user.companyShortName}}</span>
      </div>
    </div>
    <div class="material-table row col-12">
      <table mat-table [dataSource]="roleHistorySrc" width="100%">
        <ng-container matColumnDef="timestamp">
          <th mat-header-cell *matHeaderCellDef width="25%">
            <div class="row">
              <div class="col-12">Date</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" width="25%"> {{row.timestamp | date:'dd.MM.yyyy HH:mm'}}</td>
        </ng-container>
        <ng-container matColumnDef="oldRole">
          <th mat-header-cell *matHeaderCellDef width="25%">
            <div class="row">
              <div class="col-12">Old Role</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" width="25%"> {{row.oldRole}}</td>
        </ng-container>
        <ng-container matColumnDef="newRole">
          <th mat-header-cell *matHeaderCellDef width="25%">
            <div class="row">
              <div class="col-12">New Role</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" width="25%"> {{row.newRole}}</td>
        </ng-container>
        <ng-container matColumnDef="changedByName">
          <th mat-header-cell *matHeaderCellDef width="25%">
            <div class="row">
              <div class="col-12">Changed By</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" width="25%"> {{row.changedByName}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;">
        </tr>
      </table>
      <table width="100%" class="mt-2">
        <tr *ngIf="!roleHistorySrc.data.length">
          <td style="text-align: center;" [attr.colspan]="displayColumns.length">
            No Records Found!
          </td>
        </tr>
      </table>
    </div>
  </mat-dialog-content>
  <hr>
  <mat-dialog-actions class="text-right justify-content-end">
    <button class="btn btn-back" [mat-dialog-close]="'cancel'">Zurück</button>
  </mat-dialog-actions>
</div>