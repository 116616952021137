<ng-container *ngIf="canLoad">
  <app></app>
</ng-container>
<ng-container *ngIf="!canLoad">
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-12 col-md-10 col-lg-6 offset-md-1 offset-lg-3">
        <div class="row col-12">
          <h3 class="text-center">Ihr aktueller Browser wird nicht unterstützt.</h3>
          <h5 class="text-center">Wir empfehlen Ihnen einen der folgenden Browser:</h5>
        </div>
      </div>
      <div class="col-12 row mt-5">
        <div class="col-12 col-sm-6 col-lg-3">
          <div class="row col-12 mt-5">
            <img class="browser-icon text-center" src="./../../assets/browser-icons/chrome.png">
          </div>
          <div class="row col-12 mt-4">
            <strong class="text-center">Chrome</strong>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3">
          <div class="row col-12 mt-5">
            <img class="browser-icon text-center" src="./../../assets/browser-icons/firefox.png">
          </div>
          <div class="row col-12 mt-4">
            <strong class="text-center">Firefox</strong>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3">
          <div class="row col-12 mt-5">
            <img class="browser-icon text-center" src="./../../assets/browser-icons/safari.png">
          </div>
          <div class="row col-12 mt-4">
            <strong class="text-center">Safari</strong>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 ms-icon-container">
          <div class="row col-12 mt-5">
            <img class="ms-icon text-center" src="./../../assets/browser-icons/edge.png">
          </div>
          <div class="row col-12 mt-4">
            <strong class="text-center">Microsoft Edge</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>