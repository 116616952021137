import { Injectable } from "@angular/core";
import { SubcollectionFileModel } from "@app/models/subcollectionFileModel";
import { environment } from "@environments/environment";
import { HttpClient } from "@angular/common/http";
import { CONSTANTS } from "@app/util/constants";
import { FileDetails } from "@app/interfaces/file-details-data.interface";
import { Observable, Subject } from "rxjs";
import { isImage } from "@app/util/helper";

@Injectable({
  providedIn: "root",
})
export class UploadService {
  onFilesUploaded = new Subject<{ collection: string; document: string; subcollection: string }>();

  constructor(private http: HttpClient) {}

  /**
   * Returns Subcollection Observable
   * @param docType The main collection name
   * @param docId The id of the document in the main collection
   * @param subColName The name of the subcollection
   */
  getSubcollection(docType: string, docId: string, subColName: string) {
    return this.http.get<SubcollectionFileModel[]>(
      `${environment.apiUrl}${CONSTANTS.BACKEND_ENDPOINTS.SUBCOLLECTIONS}/${docType}/${docId}/${subColName}`
    );
  }

  /**
   * Adds a document to the specified subcollection
   * @param parentType The main collection name
   * @param parentId The id of the document in the main collection
   * @param subCollectionName The name of the subcollection
   * @param data Document data for the newly added document
   */
  addSubCollectionDocument(
    parentType: string,
    parentId: string,
    subCollectionName: string,
    data: SubcollectionFileModel
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.apiUrl}${CONSTANTS.BACKEND_ENDPOINTS.SUBCOLLECTIONS}/${parentType}/${parentId}/${subCollectionName}`,
          data
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  /**
   * Updates a subcollection document with the given data
   * @param parentType The main collection name
   * @param parentId The id of the main document
   * @param subCollectionName The subcollection name
   * @param id The id of the subcollection doc that shall be updated
   * @param data The updated data
   */
  updateSubCollectionDocument(
    parentType: string,
    parentId: string,
    subCollectionName: string,
    id: string,
    data: Partial<SubcollectionFileModel>
  ) {
    return this.http.put(
      `${environment.apiUrl}${CONSTANTS.BACKEND_ENDPOINTS.SUBCOLLECTIONS}/${parentType}/${parentId}/${subCollectionName}/${id}`,
      data
    );
  }

  /**
   * Deletes Subcollection Document from firestore and file located under filePath from storage
   * @param docType The main collection name
   * @param docId The id of the document in the main collection
   * @param subColName The name of the subcollection
   * @param subColDocId The id of the doc in the subcollection
   * @param filePath path to the corresponding file which will be deleted from the storage
   */
  deleteAttachment(docType: string, docId: string, subColName: string, subColDocId: string, filePath: string) {
    const fileDeletePromises: Promise<any>[] = [];

    // Delete Subcollection Document
    return this.http
      .delete(
        `${environment.apiUrl}${CONSTANTS.BACKEND_ENDPOINTS.SUBCOLLECTIONS}/${docType}/${docId}/${subColName}/${subColDocId}`
      )
      .toPromise()
      .then(() => {
        console.log("Document deleted, now deleting actual file");
        const fileName = filePath.split("/").pop();
        if (subColName.endsWith("_feedbacks") && !isImage(fileName)) {
          console.log(
            "No actual file to delete since feedbacks given to non-image files arent uploaded but just referenced through their original url."
          );
          return;
        }

        // Delete main File
        fileDeletePromises.push(
          this.http
            .request("delete", `${environment.apiUrl}files/`, {
              body: {
                path: filePath,
              },
            })
            .toPromise()
        );

        // Delete thumbnails images
        CONSTANTS.THUMBNAIL_SIZES_SUFFIXES.forEach((thumbSizeSuffix) => {
          const filePathLastSlashIndex = filePath.lastIndexOf("/");
          const fileName = filePath.substr(filePathLastSlashIndex + 1);
          const fileNameLastDotIndex = fileName.lastIndexOf(".");
          const thumbFileName =
            fileName.substr(0, fileNameLastDotIndex) + "_" + thumbSizeSuffix + fileName.substr(fileNameLastDotIndex);
          const thumbFileFolderPath = filePath.substr(0, filePathLastSlashIndex) + "/";
          const thumbFilePath = thumbFileFolderPath + thumbFileName;

          fileDeletePromises.push(
            this.http
              .request("delete", `${environment.apiUrl}files/`, {
                body: {
                  path: thumbFilePath,
                },
              })
              .toPromise()
          );
        });

        Promise.all(fileDeletePromises);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   * Deletes a document from a subcollection
   * @param docType collection Name
   * @param docId id of the document in the collection
   * @param subColName name of the subcollection from the document
   * @param subColDocId ID of the subcollection document
   */
  deleteSubcollectionDocument(docType: string, docId: string, subColName: string, subColDocId: string) {
    return this.http.delete(
      `${environment.apiUrl}${CONSTANTS.BACKEND_ENDPOINTS.SUBCOLLECTIONS}/${docType}/${docId}/${subColName}/${subColDocId}`
    );
  }

  /**
   * Deletes the whole specified subcollection. Optionally also the related files.
   * @param collection collection Name
   * @param docId id of the document in the collection
   * @param subCollectionName name of the subcollection from the document
   * @param deleteFilesFromStorage If true, the files will also be deleted from the storage, else only database entries will be deleted.
   * @param filterCriteria The filter criteria to use when wanting to delete just some files. Eg: Only marked files.
   */
  deleteSubcollection(
    collection: string,
    docId: string,
    subCollectionName: string,
    deleteFilesFromStorage: boolean,
    filterCriteria?: { [key: string]: any }
  ) {
    return this.http
      .request(
        "delete",
        `${environment.apiUrl}${CONSTANTS.BACKEND_ENDPOINTS.SUBCOLLECTIONS}/${collection}/${docId}/${subCollectionName}`,
        {
          body: {
            deleteFilesFromStorage: deleteFilesFromStorage,
            filterCriteria: filterCriteria,
          },
        }
      )
      .toPromise();
  }

  /**
   * Uploads a file to the specified path. Thumbnails can be created and the target File name can be prefixed.
   * @param file The file that will be uploaded
   * @param path The path to which the file will be uploaded in the storage
   * @param createThumbnails? If true, different sizes of the image will be generated on the server and the urls returned
   * @param prefix? Can be used to add a prefix to the filename
   */
  uploadFile(file: any, path: string, createThumbnails?: boolean, prefix?: string) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("path", path);
    formData.append("createThumbnails", String(createThumbnails));
    formData.append("targetFilename", prefix + file.name);

    return this.http.post<FileDetails>(`${environment.apiUrl}${CONSTANTS.BACKEND_ENDPOINTS.FILES}/`, formData, {
      reportProgress: true,
      observe: "events",
    });
  }

  /**
   * Deletes a file from the specified path.
   * @param filePath Path to the file in the storage
   * @param suborderId The suborderId used in order to be able to update references of the file in the suborder data.
   */
  deleteFile(filePath: string, suborderId?: string) {
    return this.http.request("delete", `${environment.apiUrl}${CONSTANTS.BACKEND_ENDPOINTS.FILES}/`, {
      body: {
        path: filePath,
        suborderId: suborderId,
      },
    });
  }

  /**
   * Returns the meta file information of a file in storage folder assets/folder/file.
   * @param folder directory name in storage assets directory
   * @param file the file name
   */
  getAssetMetadata(folder: string, file: string): Promise<FileDetails> {
    return this.http
      .get<FileDetails>(`${environment.apiUrl}${CONSTANTS.BACKEND_ENDPOINTS.FILES}/asset-metadata/${folder}/${file}`)
      .toPromise();
  }

  /**
   * Returns an observable to list of documents in a subcollection under views subcollection
   * @param documentId id of the views document
   * @param subCollectionName name of the subcollection ('flatFinder'/'threesixty')
   */
  getViewsSubcollections(documentId: string, subCollectionName: string): Observable<SubcollectionFileModel[]> {
    return this.http.get<SubcollectionFileModel[]>(
      `${environment.apiUrl}${CONSTANTS.BACKEND_ENDPOINTS.SUBCOLLECTIONS}/get/views/${documentId}/${subCollectionName}`
    );
  }

  /**
   * Copies a collection from sourceCollection/sourceId/sourceFolder to targetCollection/targetId/targetFolder
   * @param sourceCollection source collection name
   * @param sourceId docId in source collection
   * @param sourceFolder folder in doc in source collection
   * @param targetCollection target collection Name
   * @param targetId docId in target collection
   * @param targetFolder folderName (subcollection) in target collectionDoc
   * @param deleteAfterCopy boolean that indicates if the file will be removed after copying.
   */
  copyToOtherCollection(
    sourceCollection,
    sourceId,
    sourceFolder,
    targetCollection,
    targetId,
    targetFolder,
    deleteAfterCopy?
  ) {
    this.getSubcollection(sourceCollection, sourceId, sourceFolder).subscribe((res) => {
      console.log(`No of documents: ${res.length}`);
      res.forEach((entry: SubcollectionFileModel) => {
        this.addSubCollectionDocument(targetCollection, targetId, targetFolder, {
          createdOn: new Date(),
          download_url: entry.download_url,
          file_name: entry.file_name,
          file_size: entry.file_size,
          comment: entry.comment || undefined,
          admin_comment: entry.admin_comment || undefined,
        }).then((response) => {
          console.log(entry.file_name + " added to " + targetId + " - " + targetFolder);
          if (deleteAfterCopy) {
            this.deleteSubcollectionDocument(sourceCollection, sourceId, sourceFolder, entry.id).subscribe();
          }
        });
      });
    });
  }
}
