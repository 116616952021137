<form [formGroup]="filesForm">
  <strong class="col-12 font-weight-light" *ngIf="!isHeaderHidden">
    Dateien:
    <ng-container *ngIf="allowedExtn.length > 0"> ({{ allowedExtn.toString() }})</ng-container>
  </strong>
  <div [ngClass]="{ 'mt-3': !isHeaderHidden }" class="form-group row upload-area">
    <div
      [ngClass]="{ 'col-md-6': !small, 'col-md-12 col-12': uploader.queue.length === 0 }"
      class="col-12 upload-button-container"
    >
      <label class="col-12 row fileContainer text-center align-items-center">
        <i class="material-icons col-12">cloud_upload</i>
        <div class="col-12">
          <span class="btn btn-dark btn-sm clickable">Datei auswählen</span>
        </div>
        <input
          type="file"
          [writeFile]="true"
          formControlName="uploadedFiles"
          multiple="true"
          #fileInput
          (change)="onChange()"
          [accept]="'.' + allowedExtn.join(',.')"
          ng2FileSelect
          [uploader]="uploader"
        />
      </label>
    </div>
    <div
      formArrayName="comments"
      *ngIf="uploader.queue.length"
      [ngClass]="{ 'col-md-6': !small }"
      class="col-12 file-list mt-2 mb-2"
    >
      <div class="file-entry row" *ngFor="let file of uploader.queue; let i = index">
        <div class="col-10 file-name">
          {{ file?.file?.name }}
          <textarea
            *ngIf="showFileUploadComment"
            class="form-control file-comment"
            [formControlName]="$any(i)"
            placeholder="Dateikommentar"
          ></textarea>
          <app-upload-task
            *ngIf="startUpload && (!sequentialUpload || startUploadArr[i])"
            (removeFileFromQueue)="onRemoveFile($event, i)"
            [file]="files[i]"
            [path]="parentType + '/' + parentId + '/' + folderName + '/'"
            [parentId]="parentId"
            [additionalProperties]="additionalProperties"
            [parentType]="parentType"
            [subCollectionName]="folderName"
            [comment]="comments.controls[i]?.value"
          ></app-upload-task>
        </div>
        <div class="col-2 text-right">
          <span class="fas fa-trash-alt text-danger file-remove" (click)="onRemoveFile(file, i)"></span>
        </div>
      </div>
    </div>
    <div
      *ngIf="showUploadedFiles && existingFiles.length"
      [ngClass]="{ 'col-md-6': !small }"
      class="col-12 file-list mt-2 mb-2"
    >
      <div class="file-entry row" *ngFor="let file of existingFiles; let i = index">
        <div class="col-8 file-name">
          {{ file.file_name }}
        </div>
        <div class="col-4 text-right">
          <span class="fas fa-check text-success"></span>
        </div>
      </div>
    </div>
    <div
      *ngIf="uploadedFiles.invalid && (uploadedFiles.dirty || uploadedFiles.touched)"
      class="alert alert-danger col-12"
    >
      <div *ngIf="uploadedFiles.errors.required">Bitte wählen Sie eine (gültige) Datei aus!</div>
      <div *ngIf="uploadedFiles.errors.forbiddenFile">Bitte laden Sie ein gültiges Dateiformat hoch</div>
      <div *ngIf="!uploadedFiles.errors.required && uploadedFiles.errors.fileRequired">
        Bitte wählen Sie eine (gültige) Datei aus!
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="!isUploadButtonHidden && uploadedFiles.valid">
    <button class="btn btn-primary btn-sm" (click)="uploadFiles()">Datei hochladen</button>
    <ng-container *ngIf="startUpload"
      >Dateien werden hochgeladen. Bitte warten. (Offen: {{ uploader.queue.length }} ))</ng-container
    >
  </div>
  <div
    class="col-12"
    *ngIf="
      parentType === 'suborders' &&
      folderName === 'originalPhotos' &&
      existingFiles.length !== 0 &&
      containsNewFiles &&
      subOrder.uploadAllowed
    "
  >
    <button class="btn btn-warning btn-sm" (click)="finishFilesUpload()">Upload abschließen</button>
  </div>
</form>
