import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CONSTANTS } from "@app/util/constants";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class RatingsService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.RATING;

  constructor(private http: HttpClient) {}

  addOrderFeedbackRating(ratingData) {
    this.http.post<any>(this.endpoint + "/create-order-rating", ratingData).subscribe();
  }
}
