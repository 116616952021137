import { Component, OnInit, Input } from "@angular/core";
import { SubOrdersLogicService } from "../../_services/sub-orders-logic.service";
import { AuthService } from "@app/auth/auth.service";
import { Message } from "@app/interfaces/suborder.interface";
import { UserRoleEnum } from "@app/models/user-role-list";
import { CONSTANTS } from "@app/util/constants";
import { environment } from "@environments/environment";

@Component({
  selector: "app-contact-person-icon",
  templateUrl: "./contact-person-icon.component.html",
  styleUrls: ["./contact-person-icon.component.css"],
})
export class ContactPersonIconComponent implements OnInit {
  @Input() contactPerson?: {
    firstName: string;
    lastName: string;
    id: string;
    email?: string;
    phone?: string;
    profilePhoto?: string;
  };
  @Input() suborderInfo: {
    id: string;
    packageName: string;
  };
  @Input() messages?: Message[];

  expanded = false;
  clicked = false;
  unreadMessages = 0;
  userRoleEnum = UserRoleEnum;
  supportEmail = environment.production ? CONSTANTS.SUPPORT_EMAILS.PRODUCTION : CONSTANTS.SUPPORT_EMAILS.DEVELOP;
  constructor(private sos: SubOrdersLogicService, public auth: AuthService) {}

  ngOnInit() {
    this.countUnreadMessages();
  }

  countUnreadMessages() {
    this.messages?.forEach((message) => {
      if (message.new && message.fromId !== this.auth.myUserObservable.uid) {
        this.unreadMessages += 1;
      }
    });
  }

  iconClicked() {
    this.expanded = true;
    this.clicked = !this.clicked;
    this.changeMessageStatus();
  }
  iconHovered(value: boolean) {
    if (!this.clicked) {
      this.expanded = !this.expanded;
      this.changeMessageStatus();
    }
  }

  changeMessageStatus() {
    let update = false;
    if (this.unreadMessages > 0) {
      this.messages.forEach((message) => {
        if (
          message.fromId !== this.auth.myUserObservable.uid &&
          this.auth.myUserObservable.role !== UserRoleEnum.Administrator
        ) {
          message.new = false;
          update = true;
        }
      });
      this.unreadMessages = 0;
      if (update) {
        this.sos.changeSuborder(this.suborderInfo.id, "messages", this.messages, { newMessage: false });
      }
    }
  }
}
