import { Component, OnDestroy, OnInit } from "@angular/core";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { ConfigurationLogicService } from "@app/_services/configuration-logic.service";
import { SubOrdersLogicService } from "@app/_services/sub-orders-logic.service";
import { WebsocketsService } from "@app/_services/websockets.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-suborder-closing-date-extension",
  templateUrl: "./suborder-closing-date-extension.component.html",
  styleUrls: ["./suborder-closing-date-extension.component.css"],
})
export class SuborderClosingDateExtensionComponent implements OnInit, OnDestroy {
  autoCloseExcludedPackages: string[];
  autoCloseDaysIncrease: number;
  packageNum: string;
  subscriptions: Subscription[] = [];
  currentSuborder: SubOrder;

  constructor(
    public ws: WebsocketsService,
    public sos: SubOrdersLogicService,
    private conf: ConfigurationLogicService
  ) {}

  ngOnInit(): void {
    this.autoCloseDaysIncrease = this.conf.autoPilotConfig.autoCloseDaysIncrease;
    this.autoCloseExcludedPackages = this.conf.autoPilotConfig.autoCloseExcludedPackages || [];

    this.subscriptions.push(
      this.ws.currentSuborder.subscribe((suborder) => {
        this.currentSuborder = suborder;
        this.packageNum = this.currentSuborder.packageNum.split("|").pop();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
