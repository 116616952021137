<ngx-smart-modal
  #firstImmoCreatedModal
  [closable]="false"
  [dismissable]="false"
  [escapable]="false"
  identifier="firstImmoCreatedModal"
>
  <h2 class="card-title b">Super!</h2>
  <p>Sie haben Ihre erste Immobilie angelegt! Jetzt können Sie das erste Mal einen Service buchen!</p>
  <button (click)="navigateToServices()" class="btn btn-primary">Service buchen</button>
  <button (click)="skipTutorial()" class="btn btn-danger" style="margin-left: 1.024em">Tutorial überspringen</button>
</ngx-smart-modal>
<div
  *ngIf="!(realestateLogicService.add || realestateLogicService.edit || realestateLogicService.showSuborderList)"
  class="container-fluid"
  name="show-when-not-add"
>
  <mat-button-toggle-group
    (change)="onListViewChange($event.value)"
    *ngIf="
      auth.myUserObservable.role === userRoleEnum.Customer &&
      auth.myUserObservable.companyRole === companyRoleEnum.CompanyManager
    "
    [value]="selectedListViewId"
    class="mt-4"
  >
    <mat-button-toggle *ngFor="let listView of listViewList" [value]="listView.id">{{
      listView.name
    }}</mat-button-toggle>
  </mat-button-toggle-group>
  <div class="row mt-4">
    <div class="col-12 col-lg-3">
      <mat-card appearance="outlined" class="p-0">
        <mat-card-content class="max-h-56">
          <mat-form-field>
            <mat-label> Suche </mat-label>
            <input #generalSearch [(ngModel)]="searchValue" matInput placeholder="Suchen" type="text" />
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-12 col-lg-9">
      <mat-expansion-panel class="py-1">
        <mat-expansion-panel-header>
          <mat-panel-title> Filter (erweitert) </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <form (keyup.enter)="filterRealEstates()" [formGroup]="realEstateSearchForm" class="col-12">
            <span class="sectionHeaderGrey">Immobilien-Eigenschaften</span>
            <div class="form-group">
              <div class="row">
                <div class="col-6">
                  <div class="form-group mt-4">
                    <p class="mb-0">Objektname:</p>
                    <input class="form-control" matInput formControlName="title" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group mt-4">
                    <p class="mb-0">Objektnummer:</p>
                    <input class="form-control" matInput formControlName="objectNumber" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-lg-8">
                  <div class="form-group mt-4">
                    <p class="mb-0">Straße:</p>
                    <input class="form-control" formControlName="street" type="text" />
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form-group mt-4">
                    <p class="mb-0">Hausnummer:</p>
                    <input class="form-control" formControlName="streetnumber" placeholder="Hausnummer" type="text" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-lg-4">
                  <div class="form-group mt-4">
                    <p class="mb-0">Postleitzahl:</p>
                    <input class="form-control" formControlName="postalcode" placeholder="Postleitzahl" type="text" />
                  </div>
                </div>
                <div class="col-12 col-lg-8">
                  <div class="form-group mt-4">
                    <p class="mb-0">Stadt:</p>
                    <input class="form-control" formControlName="city" placeholder="Stadt" type="text" />
                  </div>
                </div>
              </div>
            </div>
          </form>

          <form (keyup.enter)="filterRealEstates()" *ngIf="isJLL" [formGroup]="jllPropertySearchForm" class="col-12">
            <span class="sectionHeaderGrey">Kundenspezifische Abfrage</span>
            <div class="col-12">
              <div class="form-group">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="form-group mt-4">
                      <p class="mb-0">Objekt UID:</p>
                      <input class="form-control" formControlName="objectUID" />
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group mt-4">
                      <p class="mb-0">Gebäude UID:</p>
                      <input class="form-control" formControlName="buildingUID" type="text" />
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group mt-4">
                      <p class="mb-0">Business Line:</p>
                      <input class="form-control" formControlName="business_line" type="text" />
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group mt-4">
                      <p class="mb-0">Business Line Location:</p>
                      <input class="form-control" formControlName="business_line_location" type="text" />
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group mt-4">
                      <p class="mb-0">Bearbeiter:</p>
                      <input class="form-control" formControlName="responsible" type="text" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="row col-12 ml-1">
            <button (click)="resetFilter()" class="btn btn-danger">Filter zurücksetzen</button>
            <button (click)="filterRealEstates()" class="btn btn-primary">Filter anwenden</button>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div>

  <div class="row">
    <div #estateList class="shadow card cgrid scroll mt-2 mb-2">
      <div class="card-body">
        <div class="row">
          <div *ngIf="isLoadingEstates" class="col-12 justify-content-center text-center">
            <mat-spinner [color]="'primary'" [diameter]="30"></mat-spinner>
          </div>

          <!-- For a new real estate -->
          <div [hidden]="isLoadingEstates" class="col-6 col-md-4 col-xl-3 pb-3">
            <div
              (click)="resetForm(); addEstate()"
              class="shadow card fluid cgrid card-realestate add-new-realestate align-items-center mt-2 mb-2"
            >
              <div class="card-body adress-card-body add-new-realestate-body text-center">
                <div class="plus-box">
                  <div class="tl"></div>
                  <div class="tr"></div>
                  <div class="bl"></div>
                  <div class="br"></div>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngFor="let realestate of realEstateList"
            [hidden]="isLoadingEstates"
            class="col-6 col-md-4 col-xl-3 pb-3"
          >
            <div class="shadow card fluid cgrid card-realestate mt-2 mb-2">
              <div class="card-body adress-card-body">
                <div class="row">
                  <div class="col-10">
                    <h5 class="card-title text-left wsn">{{ realestate.title }}</h5>
                  </div>
                  <div class="col-2">
                    <div class="buttonline">
                      <span
                        (click)="
                          mySelectedRealestateData = realestate;
                          mySelectedRealestate = realestate.id;
                          mySelectedRealestateName = realestate.title;
                          ngxSmartModalService.open('confirmRealEstateRemovalModal')
                        "
                        class="fas fa-times fa-2x text-danger file-remove fa clickable realEstateIcon"
                        matTooltip="Löschen"
                      ></span>
                    </div>
                  </div>
                </div>
                <hr />
                <p class="card-text text-left">
                  Straße: {{ realestate.street }} {{ realestate.streetnumber }},
                  <br />
                  Ort: {{ realestate.postalcode }} {{ realestate.city }}
                </p>
                <p>
                  Objektnummer (extern): <span class="font-weight-bold">{{ realestate.objectNumber }}</span>
                </p>
                <p *ngIf="realestate.externalInformation" class="mb-0">
                  Objekt UID: <span class="font-weight-bold">{{ realestate.externalInformation.data?.objectUID }}</span>
                  <br />
                  Gebäude UID:
                  <span class="font-weight-bold">{{ realestate.externalInformation.data?.buildingUID }}</span>
                  <br />
                  Business Line:
                  <span class="font-weight-bold">{{ realestate.externalInformation.data?.businessLine }}</span>
                  <br />
                  Standort:
                  <span class="font-weight-bold">{{ realestate.externalInformation.data?.locationBusinessLine }}</span>
                  <br />
                  Bearbeiter:
                  <span class="font-weight-bold">{{ realestate.externalInformation.data?.responsible }}</span>
                </p>
              </div>
              <div class="card-footer buttonline pt-0">
                <span *ngIf="realestate.isActive === false" class="mr-2">deaktiviert</span>
                <mat-slide-toggle
                  (change)="changeRealestateStatus(realestate, $event.checked)"
                  [checked]="realestate.isActive !== false"
                  class="primary mat-primary realEstateIcon"
                  color="primary"
                ></mat-slide-toggle>

                <span
                  (click)="loadEditData(realestate.id)"
                  class="fas fa-edit text-primary fa clickable realEstateIcon"
                  matTooltip="Bearbeiten"
                ></span>
                <span
                  (click)="showSuborders(realestate.id, realestate.title)"
                  class="fas fa-list text-primary fa clickable realEstateIcon"
                  matTooltip="Bestellungen anzeigen"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" [hidden]="isLoadingEstates">
    <div class="col-4">
      <mat-select
        (selectionChange)="estatesPerPageChanged()"
        [(value)]="estatesPerPage"
        [compareWith]="compare"
        style="max-width: 100px"
      >
        <mat-option *ngFor="let option of estatesPerPageOptions" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </div>
    <div class="col-4 text-center">
      <i
        (click)="paginatePrevious(); estateList.scrollTop = 0"
        [hidden]="paginateFrom <= 0"
        class="fas fa-chevron-left text-center text-primary clickable mr-3"
      ></i>
      <span class="mr-3">{{ paginateFrom }} - {{ paginateTo }} </span>

      <i
        (click)="paginateNext(); estateList.scrollTop = 0"
        [hidden]="paginateTo >= totalNumberOfEstates"
        class="fas fa-chevron-right text-center text-primary clickable mr-2"
      ></i>
      <span class="font-small-400">von: {{ totalNumberOfEstates }}</span>
    </div>
    <div class="col-4"></div>
  </div>
</div>

<div *ngIf="realestateLogicService.add" name="show-when-add">
  <div class="shadow card cgrid">
    <div class="card-body">
      <button (click)="resetForm()" class="btn btn-back float-right">Zurück</button>
      <div class="row">
        <form [formGroup]="realestateForm" class="col-10 form-group">
          <h4>Angaben zur Immobilie</h4>

          <div class="row">
            <div class="col-6 col-lg-6 mt-2">
              <mat-label>Objektname<span class="text-danger"> * </span></mat-label>
              <input
                class="form-control px-2"
                formControlName="formtitle"
                matInput
                placeholder="z.B.: Modernes Einfamilienhaus am Stadtrand"
              />
              <mat-error *ngIf="title.touched && title.invalid">Bitte geben Sie einen Objektnamen an</mat-error>
            </div>
            <div class="col-6 col-lg-6 mt-2">
              <mat-label>Objektnummer</mat-label>
              <input class="form-control px-2" formControlName="objectNumber" matInput />
            </div>
          </div>

          <div class="row">
            <div class="col-8 mt-2">
              <mat-label>Straße</mat-label>
              <input class="form-control px-2" formControlName="street" matInput placeholder="Straße" type="text" />
              <mat-error *ngIf="street.invalid && street.touched">
                Bitte geben Sie einen gültigen Straßennamen an
              </mat-error>
            </div>
            <div class="col-4 mt-2">
              <mat-label>Hausnummer</mat-label>
              <input class="form-control px-2" formControlName="streetnumber" matInput placeholder="Hausnummer" />
              <mat-error *ngIf="streetnumber.invalid && streetnumber.touched">
                Bitte geben Sie eine gültige Hausnummer an
              </mat-error>
            </div>
          </div>

          <div class="row">
            <div class="col-4 mt-2">
              <mat-label>Postleitzahl</mat-label>
              <input class="form-control px-2" formControlName="postalcode" matInput placeholder="Postleitzahl" />
              <mat-error *ngIf="postalcode.invalid && postalcode.touched">
                Bitte geben Sie eine gültige Postleitzahl an
              </mat-error>
            </div>
            <div class="col-8 mt-2">
              <mat-label>Stadt</mat-label>
              <input class="form-control px-2" formControlName="city" matInput placeholder="Stadt" />
              <mat-error *ngIf="city.invalid && city.touched"> Bitte geben Sie eine gültige Stadt an </mat-error>
            </div>
          </div>
          <ng-container *ngIf="!auth.myUserObservable.isPrivateIndividual">
            <div class="row">
              <div class="col-12 mt-2">
                <mat-label>Zuständige:r Makler:in</mat-label>
                <input class="form-control px-2" formControlName="brokerName" matInput />
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-6">
                <mat-label>Telefonnummer</mat-label>
                <input class="form-control px-2" formControlName="brokerPhoneNumber" matInput />
              </div>
              <div class="col-6">
                <mat-label>E-Mail</mat-label>
                <input class="form-control px-2" formControlName="brokerEmail" matInput type="email" />
                <mat-error *ngIf="brokerEmail.invalid && brokerEmail.touched">{{
                  CONSTANTS.VALIDATION_ERROR_MSG.EMAIL_INVALID
                }}</mat-error>
              </div>
            </div>
          </ng-container>
        </form>
      </div>
      <div *ngIf="isJLL">
        <form [formGroup]="jllSpecificForm" class="col-6 mt-4">
          <h4>Kundenspezifische Abfrage</h4>

          <div class="row">
            <div class="col-4 mt-2">
              <mat-form-field>
                <mat-label>Objekt UID</mat-label>
                <input formControlName="objectUID" matInput (input)="updateRequiredJLLFields()" />
                <mat-error *ngIf="objectUID.invalid">Bitte Objekt UID oder Gebäude UID angeben</mat-error>
              </mat-form-field>
            </div>
            <div class="col-4 mt-2">
              <mat-form-field>
                <mat-label>Gebäude UID</mat-label>
                <input formControlName="buildingUID" matInput (input)="updateRequiredJLLFields()" />
                <mat-error *ngIf="buildingUID.invalid">Bitte Objekt UID oder Gebäude UID angeben</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-4 mt-2">
              <mat-form-field>
                <mat-label>Business Line</mat-label>
                <mat-select
                  (selectionChange)="jllDropdownChanged()"
                  [(value)]="jllDropdownSelection"
                  formControlName="businessLine"
                >
                  <mat-option [value]="">Zurücksetzen</mat-option>
                  <mat-option *ngFor="let line of jll.businessLineDropdown" [value]="line">
                    {{ line.businessLine }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-4 mt-2">
              <mat-form-field>
                <mat-label>Standort</mat-label>
                <mat-select
                  *ngIf="jllRealEstateData.data.businessLine && jllDropdownSelection['locationBusinessLine'].length"
                  [(value)]="jllRealEstateData.data.locationBusinessLine"
                >
                  <mat-option [value]="">Zurücksetzen</mat-option>
                  <mat-option *ngFor="let line of jllDropdownSelection['locationBusinessLine']" [value]="line">
                    {{ line }}
                  </mat-option>
                </mat-select>
                <input
                  *ngIf="!(jllRealEstateData.data.businessLine && jllDropdownSelection['locationBusinessLine'].length)"
                  formControlName="locationBusinessLine"
                  matInput
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-4 mt-2">
              <mat-form-field>
                <mat-label>Bearbeiter</mat-label>
                <input formControlName="responsible" matInput />
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
      <div class="row col-12 ml-1">
        <button (click)="resetForm()" class="btn btn-danger" type="button">Abbrechen</button>
        <button
          (click)="submitNewHandler()"
          [disabled]="realestateForm.invalid || realestateForm.pristine || (isJLL && jllSpecificForm.invalid)"
          class="btn btn-primary"
        >
          Immobilie anlegen
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="realestateLogicService.edit" name="show-when-edit">
  <div class="shadow card cgrid">
    <div class="card-body">
      <div class="row">
        <div class="col-10 align-self-center">
          <h4>Angaben zur Immobilie</h4>
        </div>
        <div class="col-2 float-right">
          <button (click)="resetForm()" class="btn btn-back float-right">Zurück</button>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <form (ngSubmit)="submitUpdateHandler(mySelectedRealestate)" [formGroup]="realestateForm">
            <div class="form-group">
              <div class="row mt-2">
                <div class="col-6">
                  <mat-label>Objektname: <span class="text-danger"> * </span></mat-label>
                  <input
                    class="form-control px-2"
                    formControlName="title"
                    matInput
                    placeholder="z.B.: Modernes Einfamilienhaus am Stadtrand"
                  />
                  <!-- Form Verification Error -->
                  <div *ngIf="title.invalid && title.touched" class="alert alert-warning">
                    Bitte geben Sie einen Objektnamen an!
                  </div>
                </div>
                <div class="col-6">
                  <mat-label>Objektnummer: </mat-label>
                  <input formControlName="objectNumber" matInput type="text" class="form-control px-2" />
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-8">
                  <mat-label>Straße</mat-label>
                  <input class="form-control px-2" formControlName="street" matInput placeholder="Straße" type="text" />
                  <mat-error *ngIf="street.invalid && street.touched">
                    Bitte geben Sie einen gültigen Straßennamen an
                  </mat-error>
                </div>
                <div class="col-4">
                  <mat-label>Hausnummer</mat-label>
                  <input class="form-control px-2" formControlName="streetnumber" matInput placeholder="Hausnummer" />
                  <mat-error *ngIf="streetnumber.invalid && streetnumber.touched">
                    Bitte geben Sie eine gültige Hausnummer an
                  </mat-error>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-4">
                  <mat-label>Postleitzahl</mat-label>
                  <input class="form-control px-2" formControlName="postalcode" matInput placeholder="Postleitzahl" />
                  <mat-error *ngIf="postalcode.invalid && postalcode.touched">
                    Bitte geben Sie eine gültige Postleitzahl an
                  </mat-error>
                </div>
                <div class="col-8">
                  <mat-label>Stadt</mat-label>
                  <input class="form-control px-2" formControlName="city" matInput placeholder="Stadt" />
                  <mat-error *ngIf="city.invalid && city.touched"> Bitte geben Sie eine gültige Stadt an </mat-error>
                </div>
              </div>
              <ng-container *ngIf="!auth.myUserObservable.isPrivateIndividual">
                <div class="row mt-2">
                  <div class="col-12">
                    <mat-label>Zuständige:r Makler:in</mat-label>
                    <input class="form-control px-2" formControlName="brokerName" matInput />
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-6">
                    <mat-label>Telefonnummer</mat-label>
                    <input class="form-control px-2" formControlName="brokerPhoneNumber" matInput />
                  </div>
                  <div class="col-6">
                    <mat-label>E-Mail</mat-label>
                    <input class="form-control px-2" formControlName="brokerEmail" matInput type="email" />
                    <mat-error *ngIf="brokerEmail.invalid && brokerEmail.touched">{{
                      CONSTANTS.VALIDATION_ERROR_MSG.EMAIL_INVALID
                    }}</mat-error>
                  </div>
                </div>
              </ng-container>

              <div class="row col-12 mt-2">
                <button (click)="resetForm()" class="btn btn-back" type="button">Abbrechen</button>
                <button [disabled]="realestateForm.invalid || realestateForm.pristine" class="btn btn-primary ml-2">
                  Änderungen speichern
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-4 pb-3">
          <div class="shadow card fluid cgrid card-realestate mt-2 mb-2">
            <div class="card-body adress-card-body">
              <h5 class="card-title text-left wsn">{{ this.realestateForm.get("title").value }}</h5>
              <hr />
              <p>
                {{ this.realestateForm.get("objectNumber").value }}
                <br />
                {{ this.realestateForm.get("street").value }} {{ this.realestateForm.get("streetnumber").value }}
                <br />
                {{ this.realestateForm.get("postalcode").value }} {{ this.realestateForm.get("city").value }}
              </p>

              <p *ngIf="jllCondition">
                Objekt UID: <span class="font-weight-bold">{{ jllRealEstateData.data?.objectUID || "" }}</span>
                <br />
                Gebäude UID: <span class="font-weight-bold">{{ jllRealEstateData.data?.buildingUID || "" }}</span>
                <br />
                Business Line: <span class="font-weight-bold">{{ jllRealEstateData.data?.businessLine || "" }}</span>
                <br />
                Standort: <span class="font-weight-bold">{{ jllRealEstateData.data?.locationBusinessLine || "" }}</span>
                <br />
                Bearbeiter: <span class="font-weight-bold">{{ jllRealEstateData.data?.responsible || "" }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- customer specific -->
  <div *ngIf="jllCondition" class="shadow card cgrid">
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <!-- JLL -->
          <div class="row mt-4">
            <div class="col-4">
              <h4>Kundenspezifische Abfrage</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <div class="form-group mt-4">
                <mat-form-field>
                  <mat-label>Objekt UID</mat-label>
                  <input
                    [(ngModel)]="jllRealEstateData.data.objectUID"
                    matInput
                    name="jllRealEstateData.data.objectUID"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group mt-4">
                <mat-form-field>
                  <mat-label>Gebäude UID</mat-label>
                  <input
                    [(ngModel)]="jllRealEstateData.data.buildingUID"
                    matInput
                    name="jllRealEstateData.data.buildingUID"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <mat-form-field>
                <mat-label>Business Line</mat-label>
                <mat-select (selectionChange)="jllDropdownChanged()" [(value)]="jllDropdownSelection">
                  <mat-option [value]="">Zurücksetzen</mat-option>
                  <!-- <div *ngFor="let item of testObject | keyvalue">
                    Key: <b>{{item.key}}</b> and Value: <b>{{item.value}}</b>
                </div> -->
                  <mat-option *ngFor="let line of jll.businessLineDropdown" [value]="line">
                    {{ line.businessLine }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field>
                <mat-label>Standort</mat-label>
                <mat-select
                  *ngIf="jllRealEstateData.data.businessLine && jllDropdownSelection['locationBusinessLine'].length"
                  [(value)]="jllRealEstateData.data.locationBusinessLine"
                >
                  <mat-option [value]="">Zurücksetzen</mat-option>
                  <mat-option *ngFor="let line of jllDropdownSelection['locationBusinessLine']" [value]="line">
                    {{ line }}
                  </mat-option>
                </mat-select>
                <input
                  *ngIf="!(jllRealEstateData.data.businessLine && jllDropdownSelection['locationBusinessLine'].length)"
                  [(ngModel)]="jllRealEstateData.data.locationBusinessLine"
                  matInput
                  name="jllRealEstateData.data.locationBusinessLine"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <mat-form-field>
                <mat-label>Bearbeiter</mat-label>
                <input
                  [(ngModel)]="jllRealEstateData.data.responsible"
                  matInput
                  name="jllRealEstateData.data.responsible"
                />
              </mat-form-field>
            </div>
          </div>
          <button (click)="saveCustomerSpecific()" class="btn btn-primary">Änderungen speichern</button>
        </div>
        <div class="col-2">
          <div class="shadow card fluid cgrid">
            <div class="card-body adress-card-body">
              <h5 class="card-title text-left wsn">Weitere Etage hinzufügen</h5>
              <hr />
              <mat-form-field>
                <mat-label>Etage</mat-label>
                <mat-select [(value)]="newFloor">
                  <mat-option [value]="">Zurücksetzen</mat-option>
                  <mat-option *ngFor="let floor of jll.floorsDropdown" [value]="floor">
                    {{ floor.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button (click)="addFloor()" class="btn btn-primary">hinzufügen</button>
              <p>oder</p>
              <div class="row">
                <div class="col-6">
                  <mat-form-field>
                    <mat-label>Start</mat-label>
                    <mat-select [(value)]="floorRangeStart">
                      <mat-option [value]="">Zurücksetzen</mat-option>
                      <mat-option *ngFor="let floor of jll.floorsDropdown | slice : 2" [value]="floor">
                        {{ floor.index }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field>
                    <mat-label>Ende</mat-label>
                    <mat-select [(value)]="floorRangeEnd">
                      <mat-option [value]="">Zurücksetzen</mat-option>
                      <mat-option *ngFor="let floor of jll.floorsDropdown | slice : 2" [value]="floor">
                        {{ floor.index }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <button (click)="addMultipleFloors()" class="btn btn-primary">hinzufügen</button>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div *ngFor="let floor of floors" class="shadow card fluid cgrid">
            <div class="card-body adress-card-body">
              <div class="row">
                <div class="col-6">
                  <h5 class="card-title text-left wsn">
                    {{ floor.name }} ({{ floor.createdOn | date : "dd.MM.yyyy" }})
                  </h5>
                </div>
                <div class="col-4">
                  <div class="buttonline">
                    <mat-slide-toggle
                      (change)="changeFloorStatus(floor.index, $event.checked)"
                      [checked]="floor.isActive !== false"
                      class="primary mat-primary realEstateIcon"
                      color="primary"
                    ></mat-slide-toggle>
                    <span
                      (click)="realestateLogicService.deleteFloor(mySelectedRealestateData, floor.index)"
                      class="fas fa-times fa-2x text-danger file-remove fa clickable realEstateIcon"
                      matTooltip="Löschen"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="realestateLogicService.showSuborderList" name="show-when-edit">
  <div class="shadow card cgrid">
    <div class="card-header">
      <div class="smallHeader">
        Bestellungen für die Immobilie {{ mySelectedRealestateName }}
        <button (click)="showOverview()" class="btn btn-sm btn-back float-right">Zurück</button>
      </div>
    </div>
    <div class="card-body">
      <!-- USE: getSubordersByRealEstate() and display table :) -->
      <div>
        <div class="mat-elevation-z8 material-table">
          <table [dataSource]="realEstateDataSrc" mat-table matSort matSortActive="createdOn" matSortDirection="desc">
            <ng-container matColumnDef="createdOn">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="10%">
                <div class="row">
                  <div class="col-12">Auftragsdatum</div>
                  <div class="col-12">
                    <!--<input type="text" class="form-control" placeholder="Suchen" (click)="noSort($event)"
                           (input)="filterTable($event, 'createdOn')">-->
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="10%">{{ row.createdOn | date : "dd.MM.yyyy HH:mm" }}</td>
            </ng-container>

            <ng-container matColumnDef="id">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="10%">
                <div class="row">
                  <div class="col-12">ID</div>
                  <div class="col-12">
                    <input
                      (click)="noSort($event)"
                      (input)="filterTable($event, 'id')"
                      class="form-control"
                      placeholder="Suchen"
                      type="text"
                    />
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="10%">{{ row.id | shorten : 6 : true : true | uppercase }}</td>
            </ng-container>

            <ng-container matColumnDef="floor">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="10%">
                <div class="row">
                  <div class="col-12">Etage</div>
                  <div class="col-12">
                    <input
                      (click)="noSort($event)"
                      (input)="filterTable($event, 'floor')"
                      class="form-control"
                      placeholder="Suchen"
                      type="text"
                    />
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="10%">{{ row.floor?.name || "" }}</td>
            </ng-container>

            <ng-container matColumnDef="businessLine">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="10%">
                <div class="row">
                  <div class="col-12">Business Line</div>
                  <div class="col-12">
                    <input
                      (click)="noSort($event)"
                      (input)="filterTable($event, 'businessLine')"
                      class="form-control"
                      placeholder="Suchen"
                      type="text"
                    />
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="10%">{{ row.businessLine || "" }}</td>
            </ng-container>

            <ng-container matColumnDef="businessLineLocation">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="10%">
                <div class="row">
                  <div class="col-12">Standort</div>
                  <div class="col-12">
                    <input
                      (click)="noSort($event)"
                      (input)="filterTable($event, 'businessLineLocation')"
                      class="form-control"
                      placeholder="Suchen"
                      type="text"
                    />
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="10%">{{ row.businessLineLocation || "" }}</td>
            </ng-container>

            <ng-container matColumnDef="packageName">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="15%">
                <div class="row">
                  <div class="col-12">Auftragstyp</div>
                  <div class="col-12">
                    <input
                      (click)="noSort($event)"
                      (input)="filterTable($event, 'packageName')"
                      class="form-control"
                      placeholder="Suchen"
                      type="text"
                    />
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="15%">{{ row.packageName }}</td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="10%">
                <div class="row">
                  <div class="col-12">Status</div>
                  <div class="col-12">
                    <mat-select
                      (click)="noSort($event)"
                      (selectionChange)="onStatusFilter($event)"
                      class="form-control"
                    >
                      <mat-option [value]="">zurücksetzen</mat-option>
                      <mat-option *ngFor="let entry of statusList" [value]="entry.value">
                        {{ entry.label }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="10%">
                <span [ngSwitch]="row.status">
                  <span *ngSwitchCase="'opened'" class="text-danger"> Prüfung erforderlich </span>
                  <span *ngSwitchCase="'checked'" class="text-warning"> In Bearbeitung </span>
                  <span *ngSwitchCase="'pending'" class="text-warning"> Wartet auf Aufnahmen </span>
                  <span *ngSwitchCase="'processing'" class="text-warning">
                    In Bearbeitung
                    <ng-container *ngIf="auth.myUserObservable.role === 'Administrator'">
                      <ng-container *ngIf="row.customerActionRequired"> - Kundenaktion erforderlich </ng-container>
                      <ng-container *ngIf="row.serviceProviderActionRequired">
                        - Dienstleisteraktion erforderlich
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="auth.myUserObservable.role === 'Kunde'">
                      <ng-container *ngIf="row.customerActionRequired"> - Aktion erforderlich </ng-container>
                    </ng-container>
                    <ng-container *ngIf="auth.myUserObservable.role === 'Dienstleister'">
                      <ng-container *ngIf="row.serviceProviderActionRequired"> - Aktion erforderlich </ng-container>
                    </ng-container>
                  </span>
                  <span *ngSwitchCase="'finalizing'" class="text-warning"> In Bearbeitung - finales rendering </span>
                  <span *ngSwitchCase="'canceled'" class="text-danger"> Storniert </span>
                  <span *ngSwitchCase="'completed'" class="text-success"> Abgeschlossen </span>
                  <span *ngSwitchCase="'accepted'" class="text-success"> Akzeptiert </span>
                  <span *ngSwitchDefault>
                    {{ row.status }}
                  </span>
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="5%">
                <div class="row">
                  <div class="col-12">Aktion</div>
                  <div class="col-12"></div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="5%">
                <ng-container *ngTemplateOutlet="quickAction; context: { s_order: row }"></ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="total_price">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="5%">
                <div class="row">
                  <div class="col-12">Preis</div>
                  <div class="col-12">
                    <input
                      (click)="noSort($event)"
                      (input)="filterTable($event, 'total_price')"
                      class="form-control"
                      placeholder="Suchen"
                      type="text"
                    />
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="5%">{{ row.total_price }} €</td>
            </ng-container>

            <ng-container matColumnDef="isActive">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="10%">
                <div class="row">
                  <div class="col-12">Aktiv</div>
                  <div class="col-12">
                    <input
                      (click)="noSort($event)"
                      (input)="filterTable($event, 'isActive')"
                      class="form-control"
                      placeholder="Suchen"
                      type="text"
                    />
                  </div>
                </div>
              </th>
              <td (click)="$event.stopPropagation()" *matCellDef="let row" mat-cell width="10%">
                <mat-slide-toggle
                  (click)="isActiveChanged($event, row)"
                  *ngIf="CONSTANTS.CONFIGURATIONS.ONLINE_ORDER_TYPES.includes(row.orderType)"
                  [checked]="row.isActive"
                  class="example-margin primary mat-primary"
                  (mouseenter)="$event.stopImmediatePropagation()"
                  color="primary"
                  #JLLTooltip="matTooltip"
                  matTooltip="Achtung! Denke bitte daran, deine Änderung in argo zu prüfen und deine eBrochure zu aktualisieren!"
                  matTooltipPosition="right"
                  aria-tooltip="Achtung! Denke bitte daran, deine Änderung in argo zu prüfen und deine eBrochure zu aktualisieren!"
                >
                </mat-slide-toggle>
                <span *ngIf="row.immoviewerItemId" class="float-right"
                  ><i
                    [placement]="'left'"
                    [tooltip]="'Wird mit Immoviewer synchronisiert'"
                    [adaptivePosition]="false"
                    class="fas fa-link text-primary"
                  ></i
                ></span>
              </td>
            </ng-container>

            <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
            <tr (click)="showSuborder(row.id)" *matRowDef="let row; columns: columnsToDisplay" mat-row></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="success" class="alert alert-success float-right" style="position: absolute; right: 0.5em; bottom: 0.5em">
  Erfolgreich gespeichert!
</div>

<ngx-smart-modal
  #confirmRealEstateRemovalModal
  [closable]="true"
  [dismissable]="true"
  [escapable]="true"
  identifier="confirmRealEstateRemovalModal"
>
  <!-- Modal Title -->
  <h2 class="card-title b">Löschen bestätigen</h2>
  <hr />
  <div>Hierdurch wird die Immobilie „{{ mySelectedRealestateName }}“ gelöscht.</div>
  <div>Die Aktion kann nicht rückgängig gemacht werden!</div>
  <hr />
  <div>
    <button (click)="deleteRealestate(mySelectedRealestateData)" class="btn btn-danger col-12 buttonSpacing">
      Löschen
    </button>
  </div>
</ngx-smart-modal>

<!-- CSS Loading Spinner -->
<div *ngIf="loading" class="lds-ring ring-default centered">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>

<ng-template #quickAction let-s_order="s_order">
  <a
    (click)="$event.stopPropagation()"
    *ngIf="
      s_order.packageNum !== CONSTANTS.PACKAGE_KEYS.DRONEMEDIA_VIDEO &&
      !s_order.packageNum.endsWith('|' + CONSTANTS.PACKAGE_KEYS.DRONEMEDIA_VIDEO) &&
      s_order.orderType !== orderTypeEnum.Virtual_Tour &&
      s_order.orderType !== orderTypeEnum.Price_Hubble &&
      s_order.pendingOnSuborderId &&
      !s_order.resultText &&
      !s_order.outputVideoUrl &&
      [orderStatusEnum.Completed, orderStatusEnum.Accepted].includes(s_order.status)
    "
    href="{{ downloadZipUrl + s_order.id + '&dir=outputPhotos' }}"
    target="_blank"
  >
    <i class="fas fa-download mr-2"></i>
  </a>
  <ng-container
    *ngIf="
      (s_order.resultText && s_order.status === orderStatusEnum.Completed) ||
      (s_order.outputVideoUrl &&
        $any(s_order.outputVideoUrl) !== true &&
        (s_order.status === orderStatusEnum.Completed || s_order.status === orderStatusEnum.Accepted))
    "
  >
    <a
      (click)="$event.stopPropagation()"
      [href]="s_order.resultText || s_order.outputVideoUrl"
      class="text-primary"
      target="_blank"
    >
      <i [tooltip]="'Ergebnis in neuem Tab öffnen'" [adaptivePosition]="false" class="fas fa-external-link-alt"></i>
    </a>
    <a (click)="$event.stopPropagation()" class="text-primary ml-2">
      <i
        [cdkCopyToClipboard]="s_order.resultText || s_order.outputVideoUrl"
        [tooltip]="'Link zum Ergebnis in Zwischenablage kopieren'"
        [adaptivePosition]="false"
        class="fas fa-copy"
      ></i>
    </a>
  </ng-container>
</ng-template>
