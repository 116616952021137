import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-imo-comment-box",
  templateUrl: "./imo-comment-box.component.html",
  styleUrls: ["./imo-comment-box.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ImoCommentBoxComponent,
      multi: true,
    },
  ],
})
export class ImoCommentBoxComponent implements ControlValueAccessor {
  @Input() heading: string = "Kommentar:";
  @Input() placeHolderText: string = "";
  @Input() infoBoxText: string;
  @Input() name;
  public formControl = new FormControl();

  constructor() {}

  registerOnChange(fn: Function): void {
    this.formControl.valueChanges.subscribe((val) => fn(val));
  }

  registerOnTouched(fn: Function): void {
    this.formControl.valueChanges.subscribe((val) => fn(val));
  }

  writeValue(value: any): void {
    this.formControl.setValue(value);
  }
}
