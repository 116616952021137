import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FloorplanStyle } from "@app/interfaces/style.interface";

@Component({
  selector: "app-imo-carousel-selector",
  templateUrl: "./imo-carousel-selector.component.html",
  styleUrls: ["./imo-carousel-selector.component.css"],
})
export class ImoCarouselSelectorComponent implements OnInit {
  @Output() selected = new EventEmitter();
  @Input() styles: FloorplanStyle[];

  currentPage: number = 1;
  itemsPerPage: number = 4;
  totalPages: number;
  selectedStyleIndex: number = 0;
  currentPageStyles: FloorplanStyle[];

  ngOnInit() {
    this.totalPages = Math.ceil(this.styles.length / this.itemsPerPage);
    this.updateCurrentPageStyles();
    this.onSelected(this.styles[0]); // Initiate parent component with the first style
  }

  updateCurrentPageStyles() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    this.currentPageStyles = this.styles.slice(startIndex, startIndex + this.itemsPerPage);
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updateCurrentPageStyles();
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updateCurrentPageStyles();
    }
  }

  onSelected(style: FloorplanStyle) {
    this.selectedStyleIndex = style.index;
    this.selected.emit(style);
  }
}
