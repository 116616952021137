import { Injectable } from "@angular/core";
import { Company } from "@app/interfaces/company.interface";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";
import { Observable } from "rxjs";
import { buildQueryString } from "@app/util/helper";

@Injectable({
  providedIn: "root",
})
export class CompaniesService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.COMPANIES;

  constructor(private http: HttpClient) {}

  getCompanies() {
    return this.http.get<QueryResult<Company>>(this.endpoint);
  }

  getCompaniesWithPaginator(from, to, sortBy, sortDirection, queryMap): Observable<QueryResult<Company>> {
    const queryString = buildQueryString(from, to, sortBy, sortDirection, queryMap);
    return this.http.get<QueryResult<Company>>(this.endpoint + queryString);
  }

  getCompany(path: string) {
    return this.http.get<Company>(this.endpoint + "/" + path);
  }

  postCompany(data: Company) {
    return this.http.post<Company>(this.endpoint, data);
  }

  patchCompany(path: string, data: Company) {
    return this.http.patch<Company>(this.endpoint + "/" + path, data);
  }

  deleteCompany(companyId: string) {
    return this.http.delete(this.endpoint + "/" + companyId);
  }
  importUsersViaPath(path: string) {
    return this.http.patch<Company>(this.endpoint + "/import-user-via-path/" + path, {});
  }
  assignUserToCompanyViaPath() {
    return this.http.put<Company>(this.endpoint + "/assign-users-to-company-via-path", {});
  }
}
