import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { OnOfficeApiService } from "@app/_services/on-office-api.service";
import { PlatformLocation } from "@angular/common";
import { GlobalService } from "@app/_services/global.service";

@Component({
  selector: "app-on-office-activation",
  templateUrl: "./on-office-activation.component.html",
  styleUrls: ["./on-office-activation.component.css", "./marketplaceUnlockProviderIFrame.css"],
})
export class OnOfficeActivationComponent implements OnInit {
  // Indicates whether the activation page is still loading
  isPageLoading: boolean = true;
  // Indicates - based on the provided signature - if the user is allowed to activate the integration
  isAllowed: boolean;
  apiUserSecret: string;
  private apiClaim: string;
  private apiToken: string;
  private customerName: string;
  private customerWebId: string;
  private parameterCacheId: string;
  private timestamp: number;
  private userId: string | number;
  signature: string;
  // Indicates whether the activation request is being processed
  isProcessing: boolean;
  // Indicates whether the integration has been activated
  isActive: boolean;
  isError: boolean;

  constructor(
    private route: ActivatedRoute,
    private gs: GlobalService,
    private onos: OnOfficeApiService,
    private platformLocation: PlatformLocation
  ) {}

  ngOnInit(): void {
    this.gs.isNavbarDisabled = true;

    this.route.queryParams.subscribe((params: Params) => {
      this.apiToken = params.apiToken;
      this.customerName = params.customerName;
      this.customerWebId = params.customerWebId;
      this.userId = params.userId;
      this.parameterCacheId = params.parameterCacheId;
      this.timestamp = params.timestamp;
      this.signature = params.signature;

      this.apiClaim = params.apiClaim;
    });

    this.onos.calculateSignature(this.platformLocation.href).then((res) => {
      this.isPageLoading = false;
      this.isAllowed = this.signature === res.hmac;
    });
  }

  unlockProvider() {
    this.isProcessing = true;
    this.isError = false;
    const apiToken = this.apiToken;
    const parameterCacheId = this.parameterCacheId;
    const apiClaim = this.apiClaim;
    const apiUserSecret = this.apiUserSecret;
    const customerName = this.customerName;
    const customerWebId = this.customerWebId;
    const userId = this.userId;

    this.onos
      .unlockProvider(
        apiToken,
        parameterCacheId,
        apiClaim,
        apiUserSecret,
        customerName,
        customerWebId,
        userId.toString()
      )
      .then((response) => {
        // Update parent window.
        this.updateParent("active");
        this.isProcessing = false;
        this.isActive = true;
      })
      .catch((e) => {
        this.isError = true;
        setTimeout(() => (this.isProcessing = false), 2000); // Delay button activation to make
        // the user read the error message before clicking again.
      });
  }

  updateParent(message) {
    const target = window.parent;
    target.postMessage(message, "*");
  }
}
