<div class="row">
  <mat-radio-group class="col-12" (change)="setTitle($event)" [disabled]="disabled">
    <div class="row">
      <div class="col-3" *ngFor="let group of audioItemGroups; let groupIndex = index">
        <div *ngFor="let title of group; let i = index" class="col-radio-group">
          <!-- Add a unique class here -->
          <audio id="player" src="{{ title.src }}"></audio>
          <div>
            <mat-radio-button class="mr-1" color="primary" [value]="i"
              ><span class="mr-2">Titel {{ title.id }}</span></mat-radio-button
            >
            <i
              (click)="play(title.id)"
              [hidden]="currentlyPlayingId === title.id"
              class="fa fa-play fa text-primary clickable mr-2 fa-inline hover-shadow"
            ></i>
            <i
              (click)="pause(title.id)"
              [hidden]="currentlyPlayingId !== title.id"
              class="fa fa-pause fa text-primary clickable mr-2 fa-inline hover-shadow"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </mat-radio-group>
</div>
