import { Injectable } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import * as JSZipUtils from "jszip-utils";
import * as markerjs2 from "../../../assets/js/markerjs2-master/src/index";
import { CONSTANTS } from "@app/util/constants";

@Injectable({
  providedIn: "root",
})
export class MarkerToolService {
  mark: markerjs2.MarkerArea;
  photos: any;
  savedPhotoUrl: any;
  suborderID: any;
  feedbackNumber: any;
  orderId: any;
  generalPhotoComment: string;
  uploading: boolean;
  feedbacks: any[] = [];
  approvedFeedbacks: any[] = [];
  finalPhotos: any[] = [];

  constructor(private modalService: NgxSmartModalService) {
    this.uploading = false;
  }

  // Intializing marker.js library
  initialize(img, index?) {
    return new Promise((resolve, reject) => {
      try {
        if (img.src.includes("no-image")) {
          throw "No image found!";
        }

        // Set License Key
        markerjs2.Activator.addKey(CONSTANTS.MARKERJS.LICENSE_KEY);

        this.mark = new markerjs2.MarkerArea(img);
        this.mark.availableMarkerTypes = [
          markerjs2.FrameMarker,
          markerjs2.FreehandMarker,
          markerjs2.ArrowMarker,
          markerjs2.MeasurementMarker,
          markerjs2.CoverMarker,
          markerjs2.LineMarker,
          markerjs2.EllipseMarker,
          markerjs2.HighlightMarker,
          markerjs2.CalloutCounterMarker,
        ];
        this.mark.uiStyleSettings.toolbarBackgroundColor = "var(--off-white)";
        this.mark.uiStyleSettings.toolbarBackgroundHoverColor = "var(--gray-lightest)";
        this.mark.uiStyleSettings.toolbarColor = "var(--primary-color)";
        this.mark.uiStyleSettings.toolboxColor = "var(--gray-dark)";
        // set targetRoot to a specific div instead of document.body
        this.mark.targetRoot = document.getElementById("canvas-container");
        this.mark.show();
      } catch (e) {
        console.log("Error while initializing mark:");
        console.log(e);
        console.log("img:");
        console.log(img);
      }

      resolve("Success");
      this.generalPhotoComment = "";
    });
  }

  // opens modal for user to upload an attachment to his feedback
  openAttachment() {
    this.modalService.getModal("upload-attachment-modal").open();
  }

  // Close MarkerArea Application
  closeMarkerArea(isReset = false) {
    try {
      if (this.mark) {
        this.mark.close();
        if (isReset) {
          this.mark.show();
        } else {
          delete this.mark;
        }
      }
    } catch (e) {
      console.log("Error");
      console.log(e);
    }
  }

  hasChanged() {
    if (!this.mark) {
      return false;
    }

    return !!this.mark.isDirty();
  }

  pushToFeedbacks(
    fileName: string,
    index: number,
    comment: string,
    adminComment: string,
    counterMarkerComments: string[],
    adminCounterMarkerComments: string[],
    hasAdminApproval: boolean,
    unitId?: string,
    floorName?: string
  ) {
    const self = this;
    return new Promise<void>((resolve, reject) => {
      if (this.isImage(fileName)) {
        if (self.mark) {
          console.log("Rendering annotated image!");
          self.mark.render().then((dataUrl: string) => {
            document.getElementById("resultImage") as HTMLImageElement;
            const image = dataUrl;
            self.feedbacks[index].fileName = fileName.substr(0, fileName.lastIndexOf(".")) + "_" + index + ".png";
            self.feedbacks[index].file = image;
            this.addComments(
              index,
              comment,
              adminComment,
              counterMarkerComments,
              adminCounterMarkerComments,
              hasAdminApproval,
              unitId,
              floorName
            );

            resolve();
          });
        } else {
          reject("Failure: Image in FB-Tool, but !self.mark");
        }
      } else {
        this.addComments(
          index,
          comment,
          adminComment,
          counterMarkerComments,
          adminCounterMarkerComments,
          hasAdminApproval,
          unitId,
          floorName
        );
        resolve();
      }
    });
  }

  addComments(
    index: number,
    comment: string,
    adminComment: string,
    counterMarkerComments: string[],
    adminCounterMarkerComments: string[],
    hasAdminApproval: boolean,
    unitId?: string,
    floorName?: string
  ) {
    this.feedbacks[index].comment = comment;
    this.feedbacks[index].feedbackGiven = true;
    this.feedbacks[index].counterMarkerComments = counterMarkerComments;
    this.feedbacks[index].unitId = unitId;
    this.feedbacks[index].floorName = floorName;

    if (hasAdminApproval) {
      // approved feedbacks are stored in a different list
      this.approvedFeedbacks[index] = JSON.parse(JSON.stringify(this.feedbacks[index]));
      this.approvedFeedbacks[index].adminComment = adminComment;
      this.approvedFeedbacks[index].adminCounterMarkerComments = adminCounterMarkerComments;
      this.approvedFeedbacks[index].unitId = unitId;
      this.approvedFeedbacks[index].floorName = floorName;
    } else {
      this.feedbacks[index].adminComment = adminComment;
      this.feedbacks[index].adminCounterMarkerComments = adminCounterMarkerComments;
    }
  }

  urlToPromise(url) {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  getDataURL(url) {
    return new Promise((resolve, reject) => {
      this.urlToPromise(url).then((data: ArrayBuffer) => {
        const t = new Blob([new Uint8Array(data)], {
          type: "image/jpeg",
        });
        const fl = new FileReader();
        fl.addEventListener(
          "load",
          function () {
            resolve(fl.result);
          },
          false
        );
        fl.readAsDataURL(t);
      });
    });
  }

  isImage(fileName: string): boolean {
    if (!fileName) {
      return false;
    }

    const imageExtns = ["jpeg", "jpg", "png"];
    fileName = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
    return imageExtns.indexOf(fileName) >= 0;
  }
}
