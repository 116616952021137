import { Injectable } from "@angular/core";
import { NewsSlidesService } from "@app/_services/news-slides.service";
import { NewsSlideDataInterface } from "@app/interfaces/news-slide-data.interface";
import { tap } from "rxjs/operators";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class NewsSlidesLogicService {
  public currentNotifications: NewsSlideDataInterface[];
  public currentNotificationsVersion: string;

  constructor(private nss: NewsSlidesService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.getNotificationSlides();
  }

  getNotificationSlides() {
    return this.nss
      .getNewsSlidesConfig()
      .pipe(
        tap((responseData) => {
          this.currentNotifications = responseData.slides;
          // We store this value in browser local storage and it always stores data as string
          this.currentNotificationsVersion = responseData.version.toString();
        })
      )
      .toPromise();
  }

  updateNewsSlides(slides: NewsSlideDataInterface[]) {
    return this.nss.updateSlides(slides).toPromise();
  }
}
