import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ConfigurationLogicService } from "@app/_services/configuration-logic.service";
import { AuthService } from "@app/auth/auth.service";
import * as dataForge from "data-forge";
import { DataFrame, IDataFrame } from "data-forge";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { flattenObject, getDaysInMonth, getPackageName, getWeekNumber } from "@app/util/helper";
import { WebsocketsService } from "@app/_services/websockets.service";
import * as accounting from "accounting";
import { GlobalService } from "@app/_services/global.service";
import { take, takeUntil } from "rxjs/operators";
import { OrderStatusEnum } from "@app/models/order-status-list";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { Company } from "@app/interfaces/company.interface";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { OrdersLogicService } from "../_services/orders-logic.service";
import { SubOrdersLogicService } from "../_services/sub-orders-logic.service";
import { UsersLogicService } from "@app/_services/users-logic.service";
import { CompaniesLogicService } from "@app/_services/companies-logic.service";
import { NotificationsService } from "@app/_services/notifications.service";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { MatOption } from "@angular/material/core";
import { User } from "@app/interfaces/user.interface";
import { ServicesLogicService } from "@app/_services/services-logic.service";
import { CONSTANTS } from "@app/util/constants";
import { KeyValue } from "@angular/common";
import { AnalytisService } from "@app/_services/analytics.service";
import { analyticsToolExportingDataViewList } from "@app/models/analytics-tool-exporting-data-view-list";
import { RevenueInformation } from "@app/interfaces/revenueInformation.interface";
import { CredentialEnum } from "@app/models/credential.enum";
import { AnalyticsTypeEnum } from "@app/models/analyticsType.enum";
import { BillingInformation } from "@app/interfaces/billingInformation.interface";
import { MultiSeries } from "@swimlane/ngx-charts";
import { InvoicePreview } from "@app/interfaces/order.interface";
import { UserRoleEnum } from "../models/user-role-list";
import { ReportingPreset } from "@app/interfaces/reporting-preset.interface";

@Component({
  selector: "app-analytics",
  templateUrl: "./analytics.component.html",
  styleUrls: ["./analytics.component.css"],
})
export class AnalyticsComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly UserRoleEnum = UserRoleEnum;
  orderData = [];
  loginChartData = [];
  revenueChartData = [];
  loginConfigForm: FormGroup;
  revenueConfigForm: FormGroup;
  suborderProcessingTimesExportForm: FormGroup;
  months: string[] = [];
  csvOutputFields: string[] = [];
  customers: User[] = [];
  packagesNamesMap: Map<string, string> = new Map();
  uniqueVis = {};
  revenueValues = {};
  minDate = moment({ year: 2020, month: 7, day: 1 }).toDate();
  minDateRevenue = moment({ year: 2019, month: 0, day: 1 }).toDate();
  maxDate = new Date();
  groupingUnits = [];
  units = [];
  completeLoginDataDF;
  completeOrderDataDF;
  orderTypes: string[] = ["Alle"];
  companiesList: string[] = ["Alle"];
  revenueUnits: string[] = [];
  displayedColumns = ["time", "suborderId", "orderType", "value", "units", "email", "external"];
  displayedColumnsBillingAnalytics = [
    "createdOn",
    "orderId",
    "email",
    "nextInvoiceScheduledOn",
    "unbilledAmount",
    "billedAmount",
    "paidAmount",
    "totalPrice",
    "action",
  ];
  isOrderDataLoading = false;
  isLoginDataLoading = false;
  isExportingCsv = false;
  isLoadingPackages = false;
  analyticsToolExportingDataViewList = analyticsToolExportingDataViewList;
  dataSource: MatTableDataSource<
    {
      email: string;
      external: string;
      id: string;
      orderType: OrderTypeEnum;
      time: Date | string;
      value: number;
      companyName: string;
      units: number;
    }[]
  >;
  dataSourceBillingAnalytics: MatTableDataSource<
    {
      createdOn: Date | string;
      orderId: string;
      email: string;
      unbilledAmount: number;
      billedAmount: number;
      paidAmount: number;
      totalPrice: number;
    }[]
  >;
  monthlyRevenue: RevenueInformation;
  monthlyRevenueChartData: { name: string; value: number }[];
  linearInterpolationFactor = moment(new Date()).daysInMonth() / moment(new Date()).date();
  hasAnalyticsPermission: boolean;
  showAnalyticsTool: boolean;
  showLoginData: boolean;
  showExporting: boolean;
  currentMonthYearString: string = moment(new Date()).format("MM-YYYY");
  previousMonthYearString: string = moment(new Date()).subtract(1, "month").format("MM-YYYY");
  analyticsMonthOptions = [moment(new Date()).format("MM-YYYY")];
  selectedAnalyticsMonth: string = moment(new Date()).format("MM-YYYY");
  /**
   * Maximal number of values shown in the Analytics (Quick Check) Chart
   */
  limitAnalyticsValues: number = 20;
  limitAnalyticsOptions: number[] = [10, 20, 30, 40, 50];
  monthByMonthDiffPct: number;
  billingAnalyticsChartData: MultiSeries;
  billingAnalyticsColorScheme: { name: string; value: string }[] = [
    { name: "unbilledAmount", value: "#000" },
    { name: "paidAmount", value: "#22bca4" },
    { name: "scheduledAmount", value: "#dc3545" },
    { name: "billedAmount", value: "#f0ae2c" },
  ];
  // " #000", " #22bca4", " #eb5e5a", "#dc3545"
  billingAnalyticsOrderCount: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) billingAnalyticsPaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort) billingAnalyticsSort: MatSort;
  @ViewChild("allSelected") private allSelected: MatOption;
  @ViewChild("allOutputFieldsSelected") private allOutputFieldsSelected: MatOption;
  @ViewChild("allExportingCompaniesSelected") private allExportingCompaniesSelected: MatOption;
  @ViewChild("allExportingUsersSelected") private allExportingUsersSelected: MatOption;
  @ViewChild("allExportingPackagesSelected") private allExportingPackagesSelected: MatOption;

  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompany: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  @ViewChild("multiSelect", { static: true }) multiSelect: MatSelect;

  public customerFilterCtrl: FormControl = new FormControl("");
  public companyFilterToExportCtrl: FormControl = new FormControl("");
  public packageFilterCtrl: FormControl = new FormControl("");
  public outputFieldFilterCtrl: FormControl = new FormControl("");
  public filteredCustomers: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  public filteredCompaniesToExport: ReplaySubject<
    {
      id: string;
      name: string;
      companies: Company[];
    }[]
  > = new ReplaySubject<
    {
      id: string;
      name: string;
      companies: Company[];
    }[]
  >(1);
  companyIds: string[] = [];
  public filteredPackages: ReplaySubject<Map<string, string>> = new ReplaySubject<Map<string, string>>(1);
  public filteredOutputFields: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  @ViewChild("customersSelect", { static: true }) customersSelect: MatSelect;
  @ViewChild("companiesSelect", { static: true }) companiesSelect: MatSelect;
  @ViewChild("packagesSelect", { static: true }) packagesSelect: MatSelect;
  @ViewChild("outputFieldsSelect", { static: true }) outputFieldsSelect: MatSelect;

  protected _onDestroy = new Subject();

  private subscriptions: Subscription[] = [];
  private billingData: BillingInformation;
  billingAnalyticsMonth: string;
  billingAnalyticsFilterProperty: string;
  invoicePreviewData: InvoicePreview;
  invoicePreviewKeys: string[];
  isLoadingExportingTool: boolean;

  constructor(
    public conf: ConfigurationLogicService,
    public auth: AuthService,
    private fb: FormBuilder,
    private ws: WebsocketsService,
    private gs: GlobalService,
    private uls: UsersLogicService,
    private sls: ServicesLogicService,
    private cls: CompaniesLogicService,
    private ols: OrdersLogicService,
    private sos: SubOrdersLogicService,
    private ns: NotificationsService,
    private as: AnalytisService
  ) {
    this.dataSource = new MatTableDataSource([]);
    this.dataSourceBillingAnalytics = new MatTableDataSource([]);
    this.dataSourceBillingAnalytics.sort = this.billingAnalyticsSort;
  }

  async ngOnInit() {
    this.billingAnalyticsColorScheme = [
      { name: "unbilledAmount", value: getComputedStyle(document.documentElement).getPropertyValue("--black") },
      { name: "paidAmount", value: getComputedStyle(document.documentElement).getPropertyValue("--green") },
      { name: "scheduledAmount", value: getComputedStyle(document.documentElement).getPropertyValue("--danger") },
      { name: "billedAmount", value: getComputedStyle(document.documentElement).getPropertyValue("--yellow") },
    ];
    this.isLoginDataLoading = true;
    this.ws.subscribeToSubordersList();

    this.units = [
      {
        id: "logins",
        title: "Logins",
      },
      {
        id: "unique",
        title: "Unique user",
      },
    ];
    this.revenueUnits = ["Euro", "Anzahl Bestellungen", "Anzahl"];
    this.groupingUnits = [
      {
        id: "day",
        title: "Tag",
      },
      {
        id: "week",
        title: "Woche",
      },
      {
        id: "month",
        title: "Monat",
      },
      {
        id: "year",
        title: "Jahr",
      },
      {
        id: "total",
        title: "Gesamt",
      },
      {
        id: "time",
        title: "Uhrzeit",
      },
    ];

    this.loginConfigForm = this.fb.group({
      loginsStartDate: [moment().subtract(6, "d").toDate()],
      unit: ["logins"],
      loginsEndDate: [new Date()],
      aggregation: ["day"],
    });
    this.loginConfigForm.valueChanges.subscribe((value) => console.log(value));

    this.isLoginDataLoading = true;

    this.revenueConfigForm = this.fb.group({
      revenueStartDate: [new Date(moment().subtract(2, "month").toDate().setDate(1))],
      revenueEndDate: [new Date()],
      aggregation: ["month"],
      orderType: ["Alle"],
      unit: ["Euro"],
      companyName: [""],
      onlyImmoviewer: [false],
    });

    this.initiateExportingTool();

    try {
      await Promise.all([this.fetchRevenueData(), this.loadInvoicePreview()]);
      this.analyticsMonthOptions = Object.keys(this.monthlyRevenue);
      this.analyticsMonthOptions.sort((a, b) => {
        return a.substring(3) + a.substring(0, 2) > b.substring(3) + b.substring(0, 2) ? -1 : 1;
      });
      this.monthByMonthDiffPct =
        (this.monthlyRevenue[this.currentMonthYearString].total * this.linearInterpolationFactor) /
          this.monthlyRevenue[this.previousMonthYearString].total -
        1;
    } catch (e) {
      this.ns.showNotification(JSON.stringify(e), "danger");
    } finally {
      this.isLoginDataLoading = false;
    }
  }

  async initiateExportingTool() {
    this.setMonths();
    this.customers = await this.uls.getAllCustomers().toPromise();
    await this.setPackagesMap();
    this.setCsvOutputFields();

    this.suborderProcessingTimesExportForm = this.fb.group({
      dataView: [analyticsToolExportingDataViewList[0].id],
      month: [this.months[0]],
      customerIds: [[]],
      uniqueCompanies: [[]],
      packageKeys: [[]],
      selectedCsvOutputFields: [[...this.csvOutputFields, "-"]],
    });

    // load the initial lists
    this.filteredCustomers.next(this.customers);
    this.filteredCompaniesToExport.next(this.cls.uniqueCompanyNamesList);
    this.filteredPackages.next(this.packagesNamesMap);
    this.filteredOutputFields.next(this.csvOutputFields);

    // listen for search field value changes
    this.customerFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCustomers();
    });
    this.companyFilterToExportCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCompaniesToExport();
    });
    this.packageFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterPackages();
    });
    this.outputFieldFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterOutputFields();
    });
  }

  /**
   * Initiates data fetch (users and companies) for exporting tool and handles loading spinner.
   */
  async enableExportingTool() {
    this.isLoadingExportingTool = true;
    await this.initiateExportingTool();
    this.isLoadingExportingTool = false;
    this.showExporting = true;
  }

  async initiateAnalyticsQuickCheckChart() {
    await this.fetchRevenueData();
    await this.updateRevenueData();
  }

  async fetchRevenueData() {
    this.monthlyRevenue = (await this.conf.getAnalyticsData(AnalyticsTypeEnum.MONTHLY_REVENUE)) as RevenueInformation;
  }

  async loadInvoicePreview() {
    this.invoicePreviewData = await this.ols.getInvoicePreview();

    this.invoicePreviewKeys = Object.keys(this.invoicePreviewData);
  }

  async fetchBillingAnalyticsData() {
    this.billingData = (await this.conf.getAnalyticsData(AnalyticsTypeEnum.BILLING_ANALYTICS)) as BillingInformation;
    const chartData = this.getMultiSeriesChartData(this.billingData);
    this.billingAnalyticsChartData = chartData;
  }

  async updateRevenueData() {
    const monthYearString = this.selectedAnalyticsMonth;
    this.updateAnalyticsQuickCheckChart(this.monthlyRevenue, monthYearString);
  }

  updateAnalyticsQuickCheckChart(monthlyRevenue: RevenueInformation, monthYearString) {
    const chartData = this.getSingleSeriesChartData(monthlyRevenue, monthYearString);
    chartData.sort((a, b) => {
      return a.value > b.value ? -1 : 1;
    });
    this.monthlyRevenueChartData = chartData.slice(0, this.limitAnalyticsValues);
  }

  getSingleSeriesChartData(revenueInfos: RevenueInformation, monthYearString: string) {
    const data: { name: string; value: number }[] = [];
    const monthRevenue = revenueInfos[monthYearString];
    Object.keys(monthRevenue).forEach((key) => {
      if (key !== "id") {
        data.push({
          name: key,
          value: monthRevenue[key],
        });
      }
    });

    return data;
  }

  getMultiSeriesChartData(billingInfos: BillingInformation): MultiSeries {
    const multiSeries = [];

    Object.keys(billingInfos).map((key) => {
      multiSeries.push({
        name: key,
        series: [
          {
            name: "unbilledAmount",
            value: billingInfos[key].unbilledAmount,
          },
          {
            name: "scheduledAmount",
            value: billingInfos[key].scheduledAmount || 0,
          },
          {
            name: "billedAmount",
            value: billingInfos[key].billedAmount,
          },
          {
            name: "paidAmount",
            value: billingInfos[key].paidAmount,
          },
        ],
      });
    });

    return multiSeries;
  }

  protected filterCustomers() {
    if (!this.customers) {
      return;
    }

    let filteredCustomers = this.getDefaultFilteredCustomers();
    // get the search keyword
    let search = this.customerFilterCtrl.value;
    if (!search) {
      this.filteredCustomers.next(filteredCustomers);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the customers
    this.filteredCustomers.next(
      filteredCustomers.filter((customer) => customer.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private getDefaultFilteredCustomers() {
    return (
      (this.companyIds.length > 0 &&
        this.customers.filter((item) => item.company && this.companyIds.includes(item.company.id))) ||
      this.customers
    );
  }

  protected filterCompaniesToExport() {
    if (!this.cls.companiesList) {
      return;
    }
    // get the search keyword
    let search = this.companyFilterToExportCtrl.value;
    if (!search) {
      this.filteredCompaniesToExport.next(this.cls.uniqueCompanyNamesList);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the companies
    this.filteredCompaniesToExport.next(
      this.cls.uniqueCompanyNamesList.filter((company) => company.name.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterPackages() {
    if (!this.packagesNamesMap) {
      return;
    }
    // get the search keyword
    let search = this.packageFilterCtrl.value;
    if (!search) {
      this.filteredPackages.next(this.packagesNamesMap);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the packages
    this.filteredPackages.next(
      new Map([...this.packagesNamesMap].filter(([k, v]) => v.toLowerCase().indexOf(search) > -1))
    );
  }

  protected filterOutputFields() {
    if (!this.csvOutputFields) {
      return;
    }
    // get the search keyword
    let search = this.outputFieldFilterCtrl.value;
    if (!search) {
      this.filteredOutputFields.next(this.csvOutputFields);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the packages
    this.filteredOutputFields.next(this.csvOutputFields.filter((field) => field.toLowerCase().indexOf(search) > -1));
  }

  async setPackagesMap() {
    this.isLoadingPackages = true;
    const packages = await this.sls.getImoServices();
    this.isLoadingPackages = false;
    packages.forEach((pack) => {
      this.packagesNamesMap.set(pack.package_key, getPackageName(pack));
    });
  }

  setCsvOutputFields() {
    this.csvOutputFields = Object.keys(flattenObject(this.getAnalyticsToolExportingObjectExample())).sort();
  }

  packagesMapCompareFn(a: KeyValue<string, string>, b: KeyValue<string, string>): number {
    // Order by ascending property value
    return a.value.localeCompare(b.value);
  }

  async updateLoginData() {
    const data = await this.conf
      .getLoginData(this.loginsStartDate.value.setHours(0, 0, 0), this.loginsEndDate.value.setHours(23, 59, 59))
      .pipe(take(1))
      .toPromise();
    const rawLoginDataDF = new dataForge.DataFrame(data);
    this.completeLoginDataDF = new dataForge.DataFrame(this.transformData(rawLoginDataDF, true, true).toArray());
    this.update("logins", this.completeLoginDataDF);
    const today = new Date();
    const lastMonthStart = new Date(moment(new Date()).subtract(1, "month").toDate().setDate(1));
    const lastMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    this.uniqueVis["7days"] = this.getUniqueVisNo(moment().subtract(7, "d").toDate(), today, this.completeLoginDataDF);
    this.uniqueVis["7to14days"] = this.getUniqueVisNo(
      moment().subtract(14, "d").toDate(),
      moment().subtract(7, "d").toDate(),
      this.completeLoginDataDF
    );
    this.uniqueVis["7daysDif"] = this.uniqueVis["7days"] / this.uniqueVis["7to14days"] - 1;
    this.uniqueVis["curMonth"] = this.getUniqueVisNo(
      moment().subtract(new Date().getDate(), "d").toDate(),
      today,
      this.completeLoginDataDF
    );
    this.uniqueVis["curMonthPrediction"] = Math.round(
      this.getUniqueVisNo(new Date(new Date().setDate(1)), new Date(), this.completeLoginDataDF) /
        (today.getDate() / getDaysInMonth(today.getFullYear(), today.getMonth()))
    );
    this.uniqueVis["lastMonth"] = this.getUniqueVisNo(lastMonthStart, lastMonthEnd, this.completeLoginDataDF);
    this.uniqueVis["30days"] = this.getUniqueVisNo(
      moment().subtract(30, "d").toDate(),
      today,
      this.completeLoginDataDF
    );
    this.uniqueVis["30to60days"] = this.getUniqueVisNo(
      moment().subtract(60, "d").toDate(),
      moment().subtract(30, "d").toDate(),
      this.completeLoginDataDF
    );
    this.uniqueVis["30daysDif"] = this.uniqueVis["30days"] / this.uniqueVis["30to60days"] - 1;
    this.uniqueVis["samePeriodLastMonth"] = this.getUniqueVisNo(
      moment().subtract(1, "months").date(1).toDate(),
      new Date(new Date().setMonth(new Date().getMonth() - 1)),
      this.completeLoginDataDF
    );
    this.uniqueVis["curMonthDif"] = this.uniqueVis["curMonth"] / this.uniqueVis["samePeriodLastMonth"] - 1;
    this.isLoginDataLoading = false;
  }

  async updateOrderData() {
    await this.uls.getAllCustomers().toPromise();
    const queryMap = new Map();
    queryMap.set(
      "dateRange",
      `${this.revenueStartDate.value.setHours(0, 0, 0, 0)}|${this.revenueEndDate.value.setHours(23, 59, 59, 0)}`
    );
    this.isOrderDataLoading = true;
    const allOrdersResponse = await Promise.all([
      this.ols.getOrders(0, 9999, "createdOn", "asc", queryMap).toPromise(), // TODO: to should be more flexible - With a single query only 10.000 elements can be returned.
      this.sos.getSuborders(
        moment(this.revenueStartDate.value.setHours(0, 0, 0, 0)),
        moment(this.revenueEndDate.value.setHours(23, 59, 59, 0))
      ),
    ]);
    const allOrders = allOrdersResponse[0].data;
    let suborders = allOrdersResponse[1];
    this.orderData = [];
    suborders.forEach((suborder) => {
      if (
        !suborder.savedForLater &&
        suborder.status !== OrderStatusEnum.Canceled &&
        !suborder.isExcludedFromInvoicing
      ) {
        const curOrder = allOrders.find((order) => order.orderId === suborder.orderId);
        const curUser = this.uls.allCustomers.find((user) => user.uid === suborder["createdBy"]);
        const curCompanyName = curUser?.company?.id
          ? this.cls.companiesList.find((company: Company) => company.cid === curUser.company.id)?.name ||
            "Unbekannte companyId"
          : "Nicht zugeordnet";
        this.orderData.push({
          time: suborder["createdOn"],
          value: accounting.unformat(String(suborder["total_price"]), ","),
          external: curUser?.external || "",
          orderType: suborder.orderType,
          id: suborder.id,
          email: curOrder ? curOrder.customerEmail : "",
          companyName: curCompanyName || "",
          units: this.getUnits(suborder),
        });
        if (!this.orderTypes.includes(suborder.orderType)) {
          this.orderTypes.push(suborder.orderType);
        }
        if (!this.companiesList.includes(curCompanyName)) {
          this.companiesList.push(curCompanyName);
        }
      }
    });
    this.completeOrderDataDF = this.transformData(new dataForge.DataFrame(this.orderData), false, false);
    this.update("revenue", this.completeOrderDataDF);
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(moment(new Date()).subtract(1, "month").toDate().setDate(1));
    const lastMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    this.revenueValues["currentMonth"] = this.getRevenueValue(
      firstDayOfMonth,
      lastDayOfMonth,
      this.completeOrderDataDF
    );
    this.revenueValues["currentMonth_formatted"] = this.gs.format(this.revenueValues["currentMonth"]);
    this.revenueValues["currentMonth_prediction"] = this.revenueValues["currentMonth"] * this.linearInterpolationFactor;
    this.revenueValues["currentMonth_prediction_formatted"] = this.gs.format(
      this.revenueValues["currentMonth_prediction"]
    );
    this.revenueValues["lastMonth"] = this.getRevenueValue(lastMonthStart, lastMonthEnd, this.completeOrderDataDF);
    this.revenueValues["lastMonth_formatted"] = this.gs.format(this.revenueValues["lastMonth"]);
    this.revenueValues["lastMonthSamePeriod"] = this.getRevenueValue(
      lastMonthStart,
      new Date(moment(new Date()).subtract(1, "month").toDate()),
      this.completeOrderDataDF
    );
    this.revenueValues["lastMonthDif"] = this.revenueValues["currentMonth"] / this.revenueValues["lastMonth"] - 1;
    this.revenueValues["lastMonthPredictionDif"] =
      this.revenueValues["currentMonth_prediction"] / this.revenueValues["lastMonth"] - 1;
    this.isOrderDataLoading = false;
    this.companiesList.sort();
    this.companiesList = this.companiesList.filter((x) => x !== "Alle");
    this.filteredCompany.next(this.companiesList);
    if (this.companyName.value.length === 0) {
      this.companyName.setValue([...this.companiesList, 0]);
    }
    this.companyFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCompanyMulti();
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.setInitialValue();
    this.setFilteredCustomersInitialValue();
    this.setFilteredCompaniesToExportInitialValue();
    this.setFilteredPackagesInitialValue();
    this.setFilteredOutputFieldsInitialValue();
    this.initiateAnalyticsQuickCheckChart();
    this.fetchBillingAnalyticsData();
  }

  get loginsStartDate() {
    return this.loginConfigForm.get("loginsStartDate");
  }

  get loginsEndDate() {
    return this.loginConfigForm.get("loginsEndDate");
  }

  get revenueStartDate() {
    return this.revenueConfigForm.get("revenueStartDate");
  }

  get revenueEndDate() {
    return this.revenueConfigForm.get("revenueEndDate");
  }

  get aggregation() {
    return this.loginConfigForm.get("aggregation");
  }

  get revenueAggregation() {
    return this.revenueConfigForm.get("aggregation");
  }

  get revenueUnit() {
    return this.revenueConfigForm.get("unit");
  }

  get unit() {
    return this.loginConfigForm.get("unit");
  }

  get onlyImmoviewer() {
    return this.revenueConfigForm.get("onlyImmoviewer");
  }

  get companyName() {
    return this.revenueConfigForm.get("companyName");
  }

  get orderType() {
    return this.revenueConfigForm.get("orderType");
  }

  get dataView() {
    return this.suborderProcessingTimesExportForm.get("dataView");
  }

  get month() {
    return this.suborderProcessingTimesExportForm.get("month");
  }

  get customerIds() {
    return this.suborderProcessingTimesExportForm.get("customerIds");
  }

  get uniqueCompanies() {
    return this.suborderProcessingTimesExportForm.get("uniqueCompanies");
  }

  get packageKeys() {
    return this.suborderProcessingTimesExportForm.get("packageKeys");
  }

  get selectedCsvOutputFields() {
    return this.suborderProcessingTimesExportForm.get("selectedCsvOutputFields");
  }

  /*
   * Ensures date format is date and filtering of non-successfull attempts. Optional filters also logins from Imogent GmbH users.
   */
  transformData(df: DataFrame, filterImogent = true, filterSuccess = true) {
    let transformedDf = df.where(
      (row) => (!filterImogent || row.companyName !== "Imogent GmbH") && (!filterSuccess || row.success === true)
    );
    transformedDf = transformedDf.transformSeries({ time: (val) => new Date(val) });
    return transformedDf;
  }

  /*
   * Returns the number of unique visitors for the given time Range.
   */
  getUniqueVisNo(startDate: Date, endDate: Date, df: DataFrame) {
    const filteredDF = this.filterByDateRange(startDate, endDate, df);
    return this.countUniqueUsers(filteredDF);
  }

  /**
   * Counts the number of unique uids in the given DataFrame
   */
  countUniqueUsers(df: DataFrame | IDataFrame) {
    const countDf = df.distinct((user) => user.email);
    return countDf.count();
  }

  /**
   * Returns the revenue in the given timespan.
   */
  getRevenueValue(startDate: Date, endDate: Date, df: DataFrame) {
    const filteredDF = this.filterByDateRange(startDate, endDate, df);
    return filteredDF.getSeries("value").sum();
  }

  /**
   * Filter by selected date-range and aggregate
   */
  update(target: string, df: DataFrame | IDataFrame) {
    if (target === "logins") {
      const filteredDF = df;
      const groupedDF = this.aggregate(filteredDF, this.aggregation.value, "user", this.unit.value);
      const chartData = this.prepareDataForChart(groupedDF, this.aggregation.value);
      this.updateLoginChartData(chartData);
    } else if (target === "revenue") {
      let filteredDF = df;
      if (this.onlyImmoviewer.value) {
        filteredDF = this.filterByProperty("external", "immoviewer", filteredDF);
      }
      if (this.orderType.value !== "Alle") {
        filteredDF = this.filterByProperty("orderType", this.orderType.value, filteredDF);
      }
      if (this.companyName.value.length > 0 && this.companyName.value.length < this.companiesList.length) {
        filteredDF = this.filterByCompany("companyName", this.companyName.value, filteredDF);
      }
      this.dataSource.data = filteredDF.toArray();
      const groupedDF = this.aggregate(filteredDF, this.revenueAggregation.value, "revenue", this.revenueUnit.value);
      const chartData = this.prepareDataForChart(groupedDF, this.revenueAggregation.value);
      console.log("aggregation: ", this.revenueAggregation.value);
      this.updateRevenueChartData(chartData);
    }
  }

  /**
   * Filters the complete dataframe based on the given dates. endDates is included in the returned DataFrame
   * @param startDate start date of the time-range
   * @param endDate end date of the time-range
   */
  filterByDateRange(startDate: Date, endDate: Date, df: DataFrame | IDataFrame): DataFrame | IDataFrame {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(24, 0, 0, 0);
    return df.where((row) => row.time >= startDate && row.time <= endDate);
  }

  /**
   * Returns a filtered data frame where the 'property' equals value.
   * @param property name of the property
   * @param value value of the property
   * @param df input Dataframe
   */
  filterByProperty(property: string, value: string, df: DataFrame | IDataFrame): DataFrame | IDataFrame {
    return df.where((row) => row[property] === value);
  }

  filterByCompany(property: string, value: [], df: DataFrame | IDataFrame): DataFrame | IDataFrame {
    return df.filter((x) => value.find((y) => y === x[property]));
  }

  /**
   * Aggregates the given DataFrame based on the selected aggregation criterion.
   * @param filteredDF A (filtered) input dataFrame
   * @param aggregation can be: 'day', 'week', 'month', 'time'
   * @param mode either 'user' or 'revenue'
   * @param unit if mode === 'user: 'unique' | 'logins', if mode === 'revenue': 'Euro' |'Anzahl Bestellungen' | 'Anzahl'
   */
  aggregate(filteredDF: DataFrame | IDataFrame, aggregation: string, mode: string, unit: string): void | any {
    let groupedDF: any;

    let aggregatedDF: IDataFrame<number, any> = new DataFrame();
    // 1. "format" Date
    switch (aggregation) {
      case "day": {
        aggregatedDF = filteredDF.transformSeries({
          time: (val) => val.getDate() + "." + val.getMonth() + "." + val.getFullYear(),
        });
        break;
      }
      case "week": {
        aggregatedDF = filteredDF.transformSeries({
          time: (val) => getWeekNumber(val)[1] + "/" + getWeekNumber(val)[0],
        });
        break;
      }
      case "month": {
        aggregatedDF = filteredDF.transformSeries({ time: (val) => val.getMonth() + 1 + "/" + val.getFullYear() });
        break;
      }
      case "year": {
        aggregatedDF = filteredDF.transformSeries({ time: (val) => val.getFullYear() });
        break;
      }
      case "total": {
        aggregatedDF = filteredDF.transformSeries({ time: (val) => "Gesamt" });
        break;
      }
      case "time": {
        aggregatedDF = filteredDF.transformSeries({ time: (val) => val.getHours() });
        break;
      }
    }

    if (mode === "user") {
      // Aggregate: Group by time
      const aggregatedDF_cleaned = aggregatedDF.dropSeries(["email", "success", "companyId", "companyName"]);
      groupedDF = aggregatedDF_cleaned
        .groupBy((row) => row.time)
        .select((group) => {
          // Transform each group
          return {
            group: group,
            time: group.first().time,
            count: unit === "logins" ? group.count() : group.distinct((value) => value.email).count(),
          };
        })
        .inflate();
    }

    if (mode === "revenue") {
      const aggregatedDF_cleaned = aggregatedDF.dropSeries(["email", "success", "companyId", "companyName"]);
      groupedDF = aggregatedDF_cleaned
        .groupBy((row) => row.time)
        .select((group) => {
          return {
            group: group,
            time: group.first().time,
            count:
              unit === "Euro"
                ? group.deflate((row) => row.value).sum()
                : unit === "Anzahl Bestellungen"
                ? group.distinct((value) => value.id).count()
                : group.deflate((row) => row.units).sum(),
          };
        });
    }

    return groupedDF;
  }

  /**
   * Prepares the data for ngx-charts and stores the updated data (triggers chart update)
   * ngx-charts needs an array of objects { name: string, value: number }
   * @param df
   */
  prepareDataForChart(df: DataFrame, aggregation: string) {
    const inputArray = df.toArray();
    const dataArray = [];

    switch (aggregation) {
      case "day": {
        for (let row of inputArray) {
          dataArray.push({
            name: moment({
              year: row.time.split(".")[2],
              month: row.time.split(".")[1],
              day: row.time.split(".")[0],
            }).toDate(),
            value: row.count,
          });
        }
        dataArray.sort((a, b) => {
          return a.name - b.name;
        });
        // TODO: Fill empty days with zero
        break;
      }
      case "week":
      case "month":
      case "year":
      case "total": {
        for (let row of inputArray) {
          dataArray.push({ name: row.time, value: row.count });
        }
        dataArray.sort((a, b) => {
          return (a.name.split("/")[1] - b.name.split("/")[1]) * 100 + (a.name.split("/")[0] - b.name.split("/")[0]);
        });
        break;
      }
      case "time": {
        for (let row of inputArray) {
          dataArray.push({ name: row.time, value: row.count });
        }

        dataArray.sort((a, b) => {
          return +a.name - +b.name;
        });
        break;
      }
    }
    return dataArray;
  }

  updateLoginChartData(dataArray: any[]) {
    this.loginChartData = dataArray;
  }

  updateRevenueChartData(dataArray: any[]) {
    this.revenueChartData = dataArray;
  }

  getUnits(suborder: SubOrder) {
    switch (suborder.orderType) {
      case OrderTypeEnum.Floor_Plan:
        return Number(suborder.additionalFloor) || 1;
      case OrderTypeEnum.Retouching:
      case OrderTypeEnum.Hd_Photos:
        return Number(suborder.numberOfPhotos) || 1;
      default:
        return 1;
    }
  }

  ngOnDestroy() {
    this.ws.unsubscribeToSubordersList();
  }

  selectAllCompanies() {
    if (this.allSelected.selected) {
      this.companyName.setValue([...this.companiesList, 0]);
    } else {
      this.companyName.setValue([]);
    }
  }

  selectSingleCompany() {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return;
    }
    if (this.companyName.value.length == this.companiesList.length) this.allSelected.select();
  }

  selectAllOutputFields() {
    if (this.allOutputFieldsSelected.selected) {
      this.selectedCsvOutputFields.setValue([...this.csvOutputFields, "-"]);
    } else {
      this.selectedCsvOutputFields.setValue([]);
    }
  }

  selectSingleOutputField() {
    if (this.allOutputFieldsSelected.selected) {
      this.allOutputFieldsSelected.deselect();
      return;
    }
    if (this.selectedCsvOutputFields.value.length == this.csvOutputFields.length) this.allOutputFieldsSelected.select();
  }

  protected filterCompanyMulti() {
    if (!this.companiesList) {
      return;
    }

    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filteredCompany.next(this.companiesList);
    } else {
      search = search.toLowerCase();
      this.filteredCompany.next(this.companiesList.filter((company) => company.toLowerCase().indexOf(search) > -1));
    }
  }

  protected setInitialValue() {
    this.filteredCompany.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      if (this.multiSelect) this.multiSelect.compareWith = (a: String, b: String) => a && b && a === b;
    });
  }

  /**
   * Sets the initial value after the filteredCustomers are loaded initially
   */
  protected setFilteredCustomersInitialValue() {
    this.filteredCustomers.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // setting the compareWith property to a comparison function
      // triggers initializing the selection according to the initial value of
      // the form control (i.e. _initializeSelection())
      // this needs to be done after the filteredCustomers are loaded initially
      // and after the mat-option elements are available
      if (this.customersSelect) {
        this.customersSelect.compareWith = (a: User, b: User) => a && b && a.id === b.id;
      }
    });
  }

  /**
   * Sets the initial value after the filteredCompaniesToExport are loaded initially
   */
  protected setFilteredCompaniesToExportInitialValue() {
    this.filteredCompaniesToExport.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // setting the compareWith property to a comparison function
      // triggers initializing the selection according to the initial value of
      // the form control (i.e. _initializeSelection())
      // this needs to be done after the filteredCompaniesToExport are loaded initially
      // and after the mat-option elements are available
      if (this.companiesSelect) {
        this.companiesSelect.compareWith = (a: Company, b: Company) => a && b && a.id === b.id;
      }
    });
  }

  /**
   * Sets the value of showLoginData property
   * @param value {boolean} indicates if the logindata shall be shown
   */
  setShowLoginData(value: boolean) {
    this.showLoginData = value;
    this.updateLoginData();
  }

  protected setFilteredPackagesInitialValue() {
    this.filteredPackages.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      if (this.packagesSelect) {
        this.packagesSelect.compareWith = (a: { key: string; value: string }, b: { key: string; value: string }) =>
          a && b && a.key === b.key;
      }
    });
  }

  protected setFilteredOutputFieldsInitialValue() {
    this.filteredOutputFields.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      if (this.outputFieldsSelect) {
        this.outputFieldsSelect.compareWith = (a: string, b: string) => a && b && a === b;
      }
    });
  }

  setMonths() {
    const currentYear = new Date().getFullYear();
    for (let y = currentYear; y >= CONSTANTS.SUBORDERS_STARTING_YEAR; y--) {
      const lastMonth = y === currentYear ? new Date().getMonth() + 1 : 12;
      for (let m = lastMonth; m >= 1; m--) {
        const auxMonth = m < 10 ? "0" + m : m;
        this.months.push(y + "/" + auxMonth);
      }
    }
  }

  async exportCSV() {
    this.isExportingCsv = true;

    try {
      const response = await this.as.exportCSV(
        this.dataView.value,
        this.month.value,
        this.customerIds.value?.filter((item) => item !== "-"),
        this.companyIds,
        this.packageKeys.value?.filter((item) => item !== "-"),
        this.selectedCsvOutputFields.value?.filter((item) => item !== "-")
      );
      const file = new Blob([response], { type: "text/csv" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    } catch (err) {
      console.log(err);
    }

    this.isExportingCsv = false;
  }

  getAnalyticsToolExportingObjectExample() {
    return {
      suborder: {
        id: "",
        orderType: "",
        createdOn: "",
        completedOn: "",
        approvedOn: "",
        packageNum: "",
        packageNameRaw: "",
        packageName: "",
        status: "",
        numberOfUnits: "",
        numberOfDeliveries: "",
        numberOfOverdueDeliveries: "",
        numberOfEstimatedDeliveryModifications: "",
        numberOfMessages: "",
        isFirstDeliveryOverdue: "",
        hasCustomerInteraction: "",
        price: "",
        businessDays: {
          customer: {
            firstDelivery: "",
            firstDeliveryFromApproval: "",
          },
          serviceProvider: {
            firstDelivery: "",
            firstDeliveryFromApproval: "",
          },
          finalDelivery: "",
          finalDeliveryFromApproval: "",
          feedbackLoop1: "",
          feedbackLoop2: "",
          feedbackLoop3: "",
          feedbackLoop4: "",
          feedbackLoop1FromApproval: "",
          feedbackLoop2FromApproval: "",
          feedbackLoop3FromApproval: "",
          feedbackLoop4FromApproval: "",
          completion: "",
          completionFromApproval: "",
          docProcCustomerInteractionTimespan: "",
        },
        accountingPositions: {
          id: "",
          name: "",
          qty: "",
          unitPrice: "",
          discountPercentage: "",
          totalPrice: "",
          billedAmount: "",
          paidAmount: "",
        },
        hasQueryBeenRaised: "",
        queryRaisedOn: "",
        hasMessageSentBeforeApproval: "",
        bundesland: "",
        workingDaysBlockedByRequests: "",
        comments: "",
      },
      user: {
        id: "",
        registrationDate: "",
        email: "",
        company: {
          id: "",
          name: "",
          region: "",
        },
        firstOrderFromUserDate: "",
        firstOrderFromCompanyDate: "",
      },
      realEstate: {
        id: "",
        address: {
          street: "",
          house: "",
          postalCode: "",
          city: "",
        },
        objectNumber: "",
        title: "",
        owner: "",
      },
      order: {
        id: "",
        billableOn: "",
        createdOn: "",
        createdOnYear: "",
        createdOnMonthYear: "",
        completedOn: "",
      },
    };
  }

  activateAnalyticsTool() {
    this.updateOrderData();
    this.showAnalyticsTool = true;
  }

  async onBillingChartSelect(selection: { label: string; name: string; series: string; value: number }) {
    console.log(`fetching data for month ${selection.series} where ${selection.name} is not 0.`);

    this.billingAnalyticsMonth = selection.series;
    this.billingAnalyticsFilterProperty = selection.name;

    await this.loadBillingAnalyticsOrders();
  }

  async loadBillingAnalyticsOrders() {
    const from = this.billingAnalyticsPaginator.pageIndex * this.billingAnalyticsPaginator.pageSize;
    const to = (this.billingAnalyticsPaginator.pageIndex + 1) * this.billingAnalyticsPaginator.pageSize;

    this.billingAnalyticsPaginator.length;
    const orders = await this.ols.searchBillingAnalytics(
      this.billingAnalyticsFilterProperty,
      this.billingAnalyticsMonth,
      this.billingAnalyticsSort.active,
      this.billingAnalyticsSort.direction,
      from,
      to
    );
    const mappedOrderData = orders.results.map((order) => {
      return {
        orderId: order.orderId || order.id,
        email: order.customerEmail,
        nextInvoiceScheduledOn: order.nextInvoiceScheduledOn,
        unbilledAmount: order.unbilledAmount || 0,
        paidAmount: order.paidAmount || 0,
        billedAmount: order.billedAmount || 0,
        createdOn: order.createdOn,
        totalPrice: accounting.unformat(order.price, ","),
        suborderIds: order.suborderIds,
      };
    });

    // @ts-ignore // TODO: What is wrong? Why is an array of array expected? The given array seems to be fine..
    this.dataSourceBillingAnalytics.data = mappedOrderData;
    this.billingAnalyticsOrderCount = orders.total_hits;
  }

  onCompaniesSelectionChange() {
    const uniqueCompanies: string[] = this.uniqueCompanies.value;

    this.companyIds = this.cls.uniqueCompanyNamesList
      .filter((uc) => uniqueCompanies.includes(uc.id))
      .flatMap((uc) => uc.companies.map((company) => company.id));
    this.customerFilterCtrl.reset();
    if (!uniqueCompanies.length) {
      this.customerIds.reset();
      this.customerIds.setValue([]);
    }
    this.filterCustomers();
  }

  selectAllExportingCompanies() {
    if (this.allExportingCompaniesSelected.selected) {
      const uniqueCompanyNamesList = this.cls.uniqueCompanyNamesList;
      const uniqueCompaniesIds = uniqueCompanyNamesList.map((uc) => uc.id);

      this.uniqueCompanies.setValue([...uniqueCompaniesIds, "-"]);
    } else {
      this.uniqueCompanies.setValue([]);
    }

    this.onCompaniesSelectionChange();
  }

  selectSingleExportingCompany() {
    if (this.allExportingCompaniesSelected.selected) {
      this.allExportingCompaniesSelected.deselect();
      return;
    }
    const filteredCustomers = this.getDefaultFilteredCustomers();
    const filteredCustomersIds = filteredCustomers.map((item) => item.uid);
    this.customerIds.setValue(this.customerIds.value.filter((customerId) => filteredCustomersIds.includes(customerId)));
    const uniqueCompaniesIds = this.cls.uniqueCompanyNamesList.map((uc) => uc.id);
    if (this.uniqueCompanies.value.length == uniqueCompaniesIds.length) this.allExportingCompaniesSelected.select();

    this.onCompaniesSelectionChange();
  }

  selectAllExportingUsers() {
    if (this.allExportingUsersSelected.selected) {
      let filteredCustomers = this.getDefaultFilteredCustomers();
      const filteredCustomersIds = filteredCustomers.map((customer) => customer.uid);
      this.customerIds.setValue([...filteredCustomersIds, "-"]);
    } else {
      this.customerIds.setValue([]);
    }
  }

  selectSingleExportingUser() {
    if (this.allExportingUsersSelected.selected) {
      this.allExportingUsersSelected.deselect();
      return;
    }
    let filteredCustomers = this.getDefaultFilteredCustomers();
    const filteredCustomersIds = filteredCustomers.map((customer) => customer.uid);
    if (this.customerIds.value.length == filteredCustomersIds.length) this.allExportingUsersSelected.select();
  }

  selectAllExportingPackages() {
    if (this.allExportingPackagesSelected.selected) {
      const packageKeys = this.packagesNamesMap.keys();
      this.packageKeys.setValue([...packageKeys, "-"]);
    } else {
      this.packageKeys.setValue([]);
    }
  }

  selectSingleExportingPackage() {
    if (this.allExportingPackagesSelected.selected) {
      this.allExportingPackagesSelected.deselect();
      return;
    }

    const packageKeysSize = this.packagesNamesMap.size;
    if (this.packageKeys.value.length == packageKeysSize) this.allExportingPackagesSelected.select();
  }

  loadReportingPreset(reportingPreset: ReportingPreset) {
    this.uniqueCompanies.reset();
    this.customerIds.reset();
    this.packageKeys.reset();
    this.selectedCsvOutputFields.reset();

    const companies = reportingPreset.companies;
    const packages = reportingPreset.packages;
    const outputFields = reportingPreset.outputFields;

    if (companies?.length) {
      const transformedCompaniesNames = companies.map((item) => item.toLowerCase().replace(/\s+/g, ""));
      const uniqueCompanyNamesList = this.cls.uniqueCompanyNamesList;
      const uniqueCompaniesIds = uniqueCompanyNamesList
        .filter((uc) => transformedCompaniesNames.includes(uc.id.toLowerCase().replace(/\s+/g, "")))
        .map((uc) => uc.id);

      this.uniqueCompanies.setValue([...uniqueCompaniesIds]);
      this.onCompaniesSelectionChange();
    }

    if (packages?.length) {
      this.packageKeys.setValue(packages);
    }

    if (outputFields?.length) {
      this.selectedCsvOutputFields.setValue(outputFields);
    }
  }
}
