import { AfterViewInit, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Order } from "@app/interfaces/order.interface";
import { CONSTANTS } from "@app/util/constants";
import { CountryCodeList } from "@app/models/country-code-list";
import { PaymentOptionEnum, paymentOptionList } from "@app/models/payment-option-list";
import { CollectiveInvoiceIntervalEnum } from "@app/models/collectiveInvoiceInterval-option-list";
import { BillingAddress } from "@app/interfaces/billing-address.interface";
import { Company } from "@app/interfaces/company.interface";
import { BillingAddressTypeEnum } from "@app/models/billingAddressType-list";
import { BillingAddressFormComponent } from "@app/shared-module/billing-address-form/billing-address-form.component";
import { isCompanyInvoice } from "@app/util/helper";
import { BillingAddressFormStatus } from "@app/interfaces/billing-address-form-status.interface";

@Component({
  selector: "app-edit-billing-address",
  templateUrl: "./edit-billing-address.component.html",
  styleUrls: ["./edit-billing-address.component.css"],
})
export class EditBillingAddressComponent implements OnInit, AfterViewInit {
  @ViewChild("billingAddressForm") billingAddressForm: BillingAddressFormComponent;
  currentOrder: Order;
  collectiveInvoiceAddress: BillingAddress;
  initialBillingAddress: BillingAddress;
  updatedBillingAddress: BillingAddress;
  backupBillingAddress: BillingAddress; // to store the latest individual address and being able to restore it when coming back from a collective payment method.
  isBillingAddressValid: boolean;
  currentCompany: Company;
  selectedPaymentOption: PaymentOptionEnum;
  canEditBillingAddress: boolean;
  billingAddressType: BillingAddressTypeEnum;
  protected readonly BillingAddressTypeEnum = BillingAddressTypeEnum;
  protected readonly CollectiveInvoiceIntervalEnum = CollectiveInvoiceIntervalEnum;
  protected readonly PaymentOptionEnum = PaymentOptionEnum;
  CONSTANTS = CONSTANTS;

  protected readonly CountryCodeList = CountryCodeList;
  protected readonly paymentOptionList = paymentOptionList.filter(
    (po) => po.id === PaymentOptionEnum.Collective || po.id === PaymentOptionEnum.OnAccount
  );

  constructor(@Inject(MAT_DIALOG_DATA) data, public dialogRef: MatDialogRef<EditBillingAddressComponent>) {
    this.currentOrder = data.currentOrder;
    this.collectiveInvoiceAddress = data.collectiveInvoiceAddress;
    this.currentCompany = data.currentCompany;
  }

  ngOnInit(): void {
    this.selectedPaymentOption = this.currentOrder.paymentOption;
    this.canEditBillingAddress = this.selectedPaymentOption === PaymentOptionEnum.OnAccount;
    this.initialBillingAddress = this.currentOrder.billingAddress;
  }

  ngAfterViewInit() {
    this.onPaymentOptionChanged();
  }

  saveBillingInformation() {
    let consent = confirm("Bist du sicher, dass du die Rechnungsadresse ändern möchtest?");
    if (consent) {
      // To avoid unnecessary API calls only emit values in case billing address or payment Option changed.
      if (
        JSON.stringify(this.updatedBillingAddress) !== JSON.stringify(this.initialBillingAddress) ||
        this.currentOrder.paymentOption != this.selectedPaymentOption
      ) {
        let result: Partial<Order> = {
          billingAddress: this.updatedBillingAddress,
          paymentOption: this.selectedPaymentOption,
        };

        // In case the selectedPayment option changed (from collective) to OnAccount we also have to reset
        // collectiveInvoiceInterval to have clean data.
        if (this.selectedPaymentOption === PaymentOptionEnum.OnAccount) {
          result.collectiveInvoiceInterval = CollectiveInvoiceIntervalEnum.None;
        }

        this.dialogRef.close(result);
      } else {
        this.closeModal();
      }
    }
  }

  onBillingAddressChanged(billingAddressFormStatus: BillingAddressFormStatus) {
    if (this.selectedPaymentOption === PaymentOptionEnum.OnAccount) {
      this.backupBillingAddress = { ...billingAddressFormStatus.billingAddress };
      this.isBillingAddressValid = billingAddressFormStatus.isValid;
    } else {
      this.isBillingAddressValid = true; // Skip validity check since we will always use the companies info anyway.
    }

    this.updatedBillingAddress = billingAddressFormStatus.billingAddress;
  }

  closeModal() {
    this.dialogRef.close();
  }

  onPaymentOptionChanged() {
    if (this.selectedPaymentOption === PaymentOptionEnum.Collective) {
      // -> collectiveInvoice
      this.billingAddressForm.setBillingAddress(this.collectiveInvoiceAddress);
      this.billingAddressForm.disableFormControls();

      if (isCompanyInvoice(this.currentCompany?.collectiveInvoiceOption)) {
        this.billingAddressType = BillingAddressTypeEnum.CompanyAddress;
      } else {
        // Profile Address will be used.
        this.billingAddressType = BillingAddressTypeEnum.ProfileAddress;
      }
    }
    if (this.selectedPaymentOption === PaymentOptionEnum.OnAccount) {
      if (this.backupBillingAddress) {
        this.billingAddressForm.setBillingAddress(this.backupBillingAddress);
      }
      this.billingAddressForm.enableFormControls();
    }

    this.canEditBillingAddress = this.selectedPaymentOption === PaymentOptionEnum.OnAccount;
  }
}
