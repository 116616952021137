import { Injectable } from "@angular/core";
import { ExternalRealEstateTypeEnum, RealEstate } from "@app/interfaces/real-estate.interface";
import { skip, take } from "rxjs/operators";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { CONSTANTS } from "@app/util/constants";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { environment } from "@environments/environment";
import * as moment from "moment";
import { WebsocketsService } from "@app/_services/websockets.service";
import { UploadService } from "@app/_services/upload.service";
import { GlobalService } from "@app/_services/global.service";
import { FunctionsService } from "@app/_services/functions.service";
import { UsersLogicService } from "@app/_services/users-logic.service";
import { NotificationTypeEnum } from "@app/models/notificationType.enum";
import { SendEmailService } from "@app/_services/send-email.service";
import { shortener, isPropstackUser, translateAppListStrings, isPlanetHomeUser } from "@app/util/helper";
import { MoneyService } from "./money.service";
import { AddVatPipe } from "@app/shared-module/add-vat.pipe";
import { SsoSourcesEnum } from "@app/models/sso-sources.enum";
import { RealestateLogicService } from "@app/_services/realestate-logic.service";

@Injectable({
  providedIn: "root",
})
export class OrderMailService {
  constructor(
    private fs: FunctionsService,
    private gs: GlobalService,
    private uploadService: UploadService,
    private uls: UsersLogicService,
    private ws: WebsocketsService,
    private ses: SendEmailService,
    private ms: MoneyService,
    private avp: AddVatPipe,
    private rls: RealestateLogicService
  ) {}

  /**
   * Collects data and creates a proper representation for mustache template engine. Then, calls the cloud function to send the order confirmation email.
   * @param orderId The orderId of the confirmation emails order
   * @param toWho Either 'customer', 'serviceProvider' or 'archilogic'. Archilogic is a special case because their e-mail is nearly identical to the customers confirmatin e-mail since we don't need to hide customer related infos.
   * @param realEstate The order's corresponding realEstate. Only necessary if external realEstate inforamtion shall be added to the email.
   * @param isPlanetHomeUser Indicates whether the customer is a planetHome user.
   * @param hasFairFleetOrder boolean indicator whether the order includes a fairfleet suborder
   * @param isPrivateIndividual Indicates whether the customer is a private individual user.
   * @param orderPrice The order price.
   * @param orderPaymentOptionName The selected payment option name.
   * @param forwardEmails Additional email addresses to forward the e-mail.
   * @param base64EncodedPdf Base64 encoded PDF to attach.
   * @param usePropstackLink If true the propstack CRM Link will be used instead of the default IMOGENT suborder link.
   * @param ssoSource If the user creates the order via an SSO-Source, it should provided here. The link in the e-mail will then include a SSO redirect
   */
  sendConfirmationEmail(
    orderId: SubOrder["orderId"],
    toWho: "customer" | "serviceProvider" | "backbone" | "measurement",
    realEstate?: RealEstate,
    forwardEmails?: string[],
    hasFairFleetOrder?: boolean,
    isPlanetHomeUser?: boolean,
    isPrivateIndividual?: boolean,
    orderPrice?: string,
    orderPaymentOptionName?: string,
    base64EncodedPdf?: string,
    usePropstackLink?: boolean,
    ssoSource?: SsoSourcesEnum
  ): void {
    this.ws.allSuborders.pipe(skip(1), take(1)).subscribe(async (value) => {
      const user = await this.uls.getUserDetails(value[0].createdBy).toPromise();
      const realEstate = await this.rls.getRealestate(value[0].realestateId).toPromise();
      let requestData = {
        subOrderDetails: [],
        attachments: [],
        orderId: orderId,
        details: {},
        isPrivateIndividual: isPrivateIndividual,
        orderPaymentOptionName: isPrivateIndividual ? orderPaymentOptionName : undefined,
        orderPrice: isPrivateIndividual
          ? this.ms.floatToString(this.avp.transform(this.ms.stringToFloat(orderPrice))) + " €"
          : undefined,
        vatTax: isPrivateIndividual ? this.ms.floatToString(CONSTANTS.VAT_TAX_PERCENTAGE) + "%" : undefined,
        base64EncodedPdf: base64EncodedPdf,
        realEstate: realEstate,
      };
      const preparationRequiredOrderTypes: OrderTypeEnum[] = [OrderTypeEnum.Hd_Photos, OrderTypeEnum.Virtual_Tour];
      const preparationRequiredPackageKeys = [
        CONSTANTS.PACKAGE_KEYS.VIDEO_TOUR_OBJEKTBEGEHUNG,
        CONSTANTS.PACKAGE_KEYS.VIDEO_TOUR_IMAGEFILM,
      ];
      const hasPreparatoryPDFs =
        value.findIndex((suborder: SubOrder) => {
          return (
            ((toWho === "customer" &&
              preparationRequiredOrderTypes.findIndex((orderType) => orderType === suborder.orderType) >= 0) ||
              preparationRequiredPackageKeys.findIndex((pk) => suborder.packageNum === pk)) >= 0
          );
        }) >= 0;

      const orderTypesWithStyle = [];
      const orderTypesWithFiles = ["floorplan", "vstaging", "retouching", "visualisation"];
      const subOrders = value;
      const allSubOrders = new Promise<void>((res, rej) => {
        const subOrderCount = subOrders.length;
        let iteration = 0;
        subOrders.forEach(async (subOrder, index) => {
          this.uploadService.getSubcollection("suborders", subOrder.id, "originalPhotos").subscribe((files) => {
            if (files && files.length > 0) {
              subOrder["attachmentsLink"] =
                environment.apiUrl + "files/downloadzip?oid=" + subOrder.id + "&dir=originalPhotos&uid=" + user.uid;
            }
            if (orderTypesWithFiles.indexOf(subOrder.orderType) >= 0 && !subOrder["pendingOnSuborderId"]) {
              subOrder["attachmentsLink"] =
                environment.apiUrl + "files/downloadzip?oid=" + subOrder.id + "&dir=originalPhotos&uid=" + user.uid;
            }
            if (
              subOrder.orderType === "visualisation" &&
              ((subOrder["customField"] && subOrder["customField"].length > 0) ||
                (subOrder["configurationObjects"] && subOrder["configurationObjects"].length > 0))
            ) {
              subOrder["customFieldLink"] = environment.apiBaseUrl + "downloadvisform?oid=" + subOrder.id;
            }
            subOrder["attachments"] = [];
            files.forEach((file) => {
              subOrder["attachments"].push({
                fileName: file.file_name,
                url: file.download_url,
              });
            });
            const preferredDates = subOrder.preferredDates;
            subOrder["logoImage"] && subOrder["attachments"].push(subOrder["logoImage"]);

            const suborderFields = this.gs.getEmailSubOrderKeyValuePairs(subOrder, realEstate);
            // Adding suborder price info to suborder fields for private individuals
            if (isPrivateIndividual) {
              suborderFields.push({
                label: "Preis inkl. " + requestData.vatTax + " MwSt.",
                value:
                  "<strong>" +
                  this.ms.floatToString(this.avp.transform(this.ms.stringToFloat(subOrder.total_price))) +
                  " €</strong>",
              });
            }

            requestData.subOrderDetails.push({
              fields: suborderFields,
              produkt: subOrder["packageName"] || translateAppListStrings("order_type_list", subOrder.orderType),
              orderType: subOrder.orderType,
              referenceFile:
                orderTypesWithStyle.indexOf(subOrder.orderType) < 0
                  ? false
                  : {
                      cid: index + "_styleimage.com",
                      filename:
                        subOrder.orderType +
                        "_" +
                        index +
                        "_style" +
                        subOrder["referenceImage"].substr(subOrder["referenceImage"].lastIndexOf(".")),
                      path: subOrder["referenceImage"],
                    },
              attachmentsLink: subOrder["attachmentsLink"] || "",
              customFieldLink: subOrder["customFieldLink"] || "",
              details: subOrder,
              showSetDatesLaterText: !!(preferredDates && preferredDates.setDatesLater),
              prefDate: preferredDates?.preferredDate1
                ? moment(preferredDates.preferredDate1).format("DD.MM.YYYY") +
                  ", " +
                  preferredDates.preferredTimeFrom1 +
                  " - " +
                  preferredDates.preferredTimeTo1
                : "",
              prefDate2: preferredDates?.preferredDate2
                ? moment(preferredDates.preferredDate2).format("DD.MM.YYYY") +
                  ", " +
                  preferredDates.preferredTimeFrom2 +
                  " - " +
                  preferredDates.preferredTimeTo2
                : "",
              prefContact:
                subOrder.packageNum === CONSTANTS.PACKAGE_KEYS.ON_SITE_AREA_CALC
                  ? {
                      calendarWeekOne: subOrder?.calendarWeekOne,
                      calendarWeekTwo: subOrder?.calendarWeekTwo,
                      alternateContactPersonName: subOrder?.alternateContactPersonName,
                      alternateContactPersonRole: subOrder?.alternateContactPersonRole,
                      alternateContactPersonPhone: subOrder?.alternateContactPersonPhone,
                      alternateContactPersonEmail: subOrder?.alternateContactPersonEmail,
                    }
                  : false,
            });
            requestData["hasPreparatoryPDFs"] = hasPreparatoryPDFs;
            requestData["hasFairFleetOrder"] = !!hasFairFleetOrder;
            requestData["isBackboneMail"] = toWho === "backbone";
            requestData["isCustomerMail"] = toWho === "customer";
            requestData["isMeasurementMail"] = toWho === "measurement";
            if (toWho === "customer") {
              if (isPlanetHomeUser) {
                requestData["isPlanetHomeUser"] = true;
              }
              if (usePropstackLink) {
                requestData["usePropstackLink"] = true;
              }
              if (ssoSource === SsoSourcesEnum.ON_OFFICE) {
                requestData["onOfficeSSOUid"] = user.uid;
              }
            }
            iteration += 1;
            if (iteration === subOrderCount) {
              res();
            }
          });
        });
      });
      allSubOrders.then(() => {
        let emailType = "";
        if (toWho === "customer" || toWho === "backbone" || toWho === "measurement") {
          emailType = "sendOrderConfirmationEmail";
        } else if (toWho === "serviceProvider") {
          emailType = "sendFpOrderToServiceProvider";
        }
        requestData["customerEmail"] = forwardEmails?.length > 0 ? forwardEmails.join(",") : user.email;
        this.fs.callFunction(emailType, requestData).subscribe();
      });
      // It will unsubscribe from all existing subscriptions. Since we are using take(1) email will be sent only once.
      // It affects order status update
      // this.ws.unsubscribeFromSuborders();
    });
    this.ws.getSuborders(orderId);
  }

  /**
   * Collects data and creates a proper representation for mustache template engine.
   * Then, calls the cloud function to send the order completion email.
   * @param subOrderId the suborderId of the finished suborder
   * @param outputFolder the outputFolder with the final files.
   * @param comments optional comments
   * @param withPlayer with our '360°' Player?
   */
  sendOrderCompletionEmail(subOrderId: string, outputFolder?: string, comments?: string, withPlayer?: boolean): void {
    const folderName = outputFolder || "outputPhotos";
    const subOrder = this.ws.currentSuborder.value;
    this.uls.getUserDetails(subOrder.createdBy).subscribe(async (customerData: any) => {
      const realEstate = await this.rls.getRealestate(subOrder.realestateId).toPromise();
      subOrder.id = subOrderId;
      const requestData = {
        subOrderDetails: [],
        attachments: [],
        orderId: subOrder["orderId"],
        customerEmail: subOrder.forwardEmails?.length > 0 ? subOrder.forwardEmails.join(",") : customerData.email,
        comments: comments || "",
        isWithPlayer: withPlayer || false,
        isFALCUser: environment.FALC_companyId === customerData.company?.id,
        isPlanetHomeUser: isPlanetHomeUser(customerData),
        usePropstackLink: isPropstackUser(customerData),
      };
      subOrder.attachmentsLink =
        environment.apiUrl + "files/downloadzip?oid=" + subOrderId + "&dir=" + folderName + "&uid=" + customerData.uid;
      const fields = this.gs.getEmailSubOrderKeyValuePairs(subOrder, realEstate);
      requestData.subOrderDetails.push({
        fields: fields,
        produkt: subOrder["packageName"] || translateAppListStrings("order_type_list", subOrder.orderType),
        orderType: subOrder.orderType,
        referenceFile: false,
        attachmentsLink:
          subOrder.selectedPackage === "Video" ||
          subOrder.selectedPackage === "Drohnenaufnahmen - Video" ||
          subOrder.orderType === OrderTypeEnum.Virtual_Tour
            ? ""
            : subOrder.attachmentsLink || "",
        isVideo: !!subOrder.outputVideoUrl,
        videoLink: subOrder.outputVideoUrl || "",
        videoLinkMsg:
          subOrder.orderType === OrderTypeEnum.V_Staging
            ? "Link zur Ihrer fertiggestellten virtuellen Tour"
            : "Link zur Ihrem fertiggestellten Video",
        subOrder: subOrder,
      });
      this.fs.callFunction("sendOrderCompletionEmail", requestData).subscribe();
    });
  }

  sendNotificationMail(notificationType: NotificationTypeEnum, suborder: SubOrder) {
    let subject = "";
    let body = "";
    if (notificationType === NotificationTypeEnum.AutoPilotDisabled) {
      subject = "Autopilot automatisch deaktiviert (" + suborder.id + " (" + suborder.orderType + "))";
      body = `Der Autopilot für die suborder id ${suborder.id} (${suborder.orderType}) wurde automatisch deaktiviert.`;
    }
    if (notificationType === NotificationTypeEnum.AreaCalculationOrdered) {
      subject = `Aktion erforderlich: Flächenberechnung für suborder ${suborder.id} bestellt.`;
      body = `Für die Suborder mit id ${suborder.id} (${suborder.orderType}) wurde eine Flächenberechnung bestellt. Diese muss manuell bei einem DL bestellt werden.`;
    }
    if (notificationType === NotificationTypeEnum.VStagingWithVirtualTourResultsFinallyAccepted) {
      subject = `Aktion erforderlich: 360 Digital Staging mit Virtueller Tour.`;
      body = `Die Ergebnisse wurde vom Kunden final akzeptiert. Bitte die virtuelle Tour beauftragen.`;
    }
    if (notificationType === NotificationTypeEnum.EnergyPassResultsFinallyAccepted) {
      const mainOrderId = shortener(suborder.orderId, 6);
      subject = `Energieausweise Ergebnisse akzeptiert (Auftrags-PIN: ${mainOrderId}).`;
      body = `Die Ergebnisse wurde vom Kunden final akzeptiert.`;
    }
    if (notificationType === NotificationTypeEnum.VStagingPremiumPlusOrdered) {
      subject = `Autopilot inaktiv - Bestellung erhalten -  Digital Staging in Premium Plus Qualität - ${suborder.id}`;
      body = `Die suborder ${suborder.id} ist ein Digital Staging in Premium Plus Qualität. <b>Achtung:</b> Autopilot ist nicht aktiv. Die Bestellung muss manuell an den zuständigen Dienstleister weitergeleitet werden:`;
    }

    const fromEmail = `Imogent Service <${CONSTANTS.SUPPORT_EMAILS.PRODUCTION}>`;
    let recipient = CONSTANTS.SUPPORT_EMAILS.DEVELOP;
    if (environment.production) {
      recipient = CONSTANTS.SUPPORT_EMAILS.PRODUCTION;
    }

    this.ses.sendEmail({
      from: fromEmail,
      to: recipient,
      subject: subject,
      body:
        body +
        `
      Direktlink zum Auftrag: ${environment.platformUrl}orderoverview/suborder/${suborder.id}`,
    });
  }

  sendFinalResultsAcceptedEmail(suborder: SubOrder) {
    const suborderPackageNum = suborder.packageNum.split("|").pop();
    const isVStaging360PackageNum = CONSTANTS.VSTAGING_360_PACKAGE_NUM_LIST.includes(suborderPackageNum);
    // currently we only need this email to be sent for 360° Staging packages with virtual tour selected
    if (isVStaging360PackageNum && (suborder.virtualTour || suborder.indvVirtualTour)) {
      this.sendNotificationMail(NotificationTypeEnum.VStagingWithVirtualTourResultsFinallyAccepted, suborder);
    }
    if (suborder.orderType === OrderTypeEnum.Energy_Pass) {
      this.sendNotificationMail(NotificationTypeEnum.EnergyPassResultsFinallyAccepted, suborder);
    }
  }
}
