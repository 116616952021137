import { FormGroup } from "@angular/forms";
import { ProductBundleData } from "@app/interfaces/product-bundle-data.interface";
import { DocEstateProductCategory, PromoBundle } from "@app/interfaces/document-procurement-package.interface";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { DocEstateProduct } from "@app/interfaces/docestate-service-provider.interface";
import { EnergyPassPackageTypeEnum } from "@app/models/energy_pass";
import { Discount } from "@app/interfaces/discount.interface";
import { InvoiceDetail } from "@app/interfaces/invoice-detail.interface";
import { QtyPriceOptions } from "@app/interfaces/qty-price-options.interface";
import { CONSTANTS } from "@app/util/constants";

// Package model
export class Package {
  name: string;
  title: string;
  service_key: Service["id"];
  service_title: string;
  disabled: boolean;
  package_key: string; // needs to be unique
  price: string;
  price_onOffice?: string;
  package_contents: string[];
  package_images: string[];
  package_icons: string[];
  configuration?:
    | {
        possibilities?: {
          img_url: string;
          title: string;
        }[];
        type: string;
        folder: string;
      }[]
    | {
        standard?: any;
        premium?: {};
      };
  tooltip?: string;
  styles?: {
    baseUrl?: string;
    featuresUrl?: string;
    index?: number;
    name?: string;
    styleUrl?: string;
    rundumsBlickUrl?: string;
    flatFinderUrl?: string;
    flat_price?: string;
    building_price?: string;
    rundumsicht_price?: string;
    einbindung_price?: string;
    styleImage?: string;
    styleImages?: {
      [key: number]: string[];
    };
    styleLink?: string;
    styleName?: string;
    selector?: string;
    folder?: string;
  }[];
  styleImage?: string;
  lengthMatrix?: number[];
  customerSpecificInfos?: {
    [key: string]: string;
  };
  customerSpecificServiceProviderInfos?: {
    [key: string]: string;
  };
  background_music?: {
    id: number;
    title: string;
    src: string;
  }[];
  phsysical_products?: boolean;
  feedbacks_included_retouching?: number;
  example_flatfinder?: string;
  example_rundumblick?: string;
  panorama_available_company_ids?: string[];
  retouchingForbiddenCompanyIds?: string[];
  additional_video_link_price?: string;
  panorama_price?: string[];
  form?: FormGroup;
  formValues?: any;
  package_id?: string;
  additional_sec_price?: string;
  quality_price?: string[];
  postprocessingHiddenCompanyIds?: string[];
  price_free?: string;
  drone_footage_price?: string;
  price_individual_tour?: string;
  price_virtual_tour?: string;
  price_additional_caption?: string;
  additional_room_price?: string;
  fp_price?: string;
  wall_move_price?: string;
  isVirtualTour?: boolean;
  furniture_price?: string;
  renovation_price?: string;
  maximalFileUploadNumber?: number;
  price_pre_text?: string;
  price_note?: string;
  extra_details?: string;
  dimension?: string;
  withModification?: boolean;
  description_placeholder?: string;
  description?: string;
  service_image?: string;
  details?: any;
  price_three_object_options?: string[];
  packageDescHeading?: string;
  packageDesc?: string;
  addUnit?: string; // unit for add-on e.g Foto, Sekunden
  priceAddUnits?: string; // price for additional
  defaultQuantity?: number; // default quantity e.g 7 for fotos 40 for videos
  delivery_time?: any;
  withPreRender?: boolean;
  dateArrangementRequired?: boolean;
  addressInputRequired?: boolean;
  productBundleData?: ProductBundleData;
  docEstateProductCategories?: DocEstateProductCategory[];
  docEstateProductList?: DocEstateProduct[];
  area_calc_certification_price?: string;
  additional_unit_price?: string;
  additional_drone_panorama_price?: string;
  max_squaremeters?: number;
  buildingsMatrix?: {};
  individual_design_price?: string;
  additional_rooms_price?: string;
  individual_additional_rooms_price?: string;
  spots_outside_price?: string;
  priceMatrix?: {};
  opFormats?: {}[];
  apartmentsMatrix?: string[];
  additional360Tour?: string[];
  additionalPhotos?: string[];
  additional_hosting_price?: string;
  numberOfDrafts?: any;
  newProperty?: boolean;
  physicalProduct?: false;
  price_options?: any; // DEPRECATED! Use qty_price_options instead!
  area_calc_price?: string;
  price_furniture?: string[];
  extra_feedback_price?: string;
  extra_feedback_price_onOffice?: string;
  quality_attributes?:
    | { value: string }[]
    | {
        [key: number]: string[];
      };
  quality_levels?: { feedbacks_included: number }[];
  infoMessages?: {
    label: string;
    value: string;
  }[];
  choose_furniture_price?: string;
  feedbacks_included?: number;
  feedbacks_included_onOffice?: number;
  additionalPrice?: string[];
  modification_price?: string;
  trueToScale_price?: string;
  additional_view_price?: string;
  package_price?: {
    [key: string]: string | string[];
  };
  package_price_onOffice?: {
    [key: string]: string | string[];
  };
  feature_samples?: {
    field: string;
    header: string;
    text: any;
    samples: string[];
  }[];
  variants?: {
    [key: number]: string[];
  };
  photo_price?: {
    // DEPRECATED! Use qty_price_options instead!
    [key: number]: string[];
  };
  qty_price_options?: {
    [key: number]: QtyPriceOptions;
  };
  companyAlternativeServiceKeyMap?: {
    [key: string]: {
      [key: number]: number;
    };
  };
  price_calculator?: {
    [key: string]: {
      step: number;
      increment: number;
    };
  };
  helperTexts?: {
    text: string;
    title: string;
    keyPoints?: string[];
  }[];
  extra_feedback_price_retouching?: string;
  companies?: string[];
  companiesBlacklist?: string[];
  isDiscountAvailable?: boolean;
  subOrderValues?: any;
  promoBundles?: PromoBundle[];
  package_images_companyId?: object;
  helpTexts?: any; // TODO: Type
  imageUrl?: string;
  priceLandRegisterUnknown?: string;
  total_price?: string | number; // used to save total price on package object
  discount?: Discount;
  certificate_price?: string;
  invoice_details?: InvoiceDetail[];
  validate?: boolean;
  EFHprice?: string;
  ETWPrice?: string;
  basement_price?: string;
  attic_price?: string;
  visit_price?: string;
  per_room_price?: string;
  by_size_price?: string;
  by_floor_price?: string;
  by_residential_units_price?: string;
  numberOfAdditionalPictures?: number;
  /**
   * default delivery time in business days
   */
  defaultDeliveryTime?: number;
  isNewBkbnProduct?: boolean;
}

interface Service {
  id: OrderTypeEnum;
  displayPosition: number;
  packages: Package[];
  packageDescription?: string;
  packageLink?: string;
  packageButtonText?: string;
}

const cancellationHintPhotographyProducts =
  "Bitte beachten Sie unsere Stornierungsbedingungen in der linken Seitenleiste. Eine kostenfreie Stornierung ist lediglich bis zu 24 Stunden vor dem geplanten Aufnahmetermin möglich.";
const cancellationPolicyPhotographyProducts =
  "<strong>Bis zu 72h vor dem geplanten Termin:</strong> kostenfrei<br><strong>Bis zu 24h vor dem geplanten Termin:</strong> 50% des Rechnungsbetrages<br><strong>Weniger als 24h vor dem geplanten Termin:</strong> 100% des Rechnungsbetrages";
// Temporary commented: "<strong>Stornierung bis zu 24h vor dem geplanten Termin:</strong> kostenfrei<br><strong>Stornierung weniger als 24h vor dem geplanten Termin:</strong> 75% des Rechnungsbetrages fällig<br><br><strong>Stornierung vor Ort: </strong> 100% des Rechnungsbetrages fällig";

/* eslint-disable max-len */
export const SERVICES: Service[] = [
  {
    id: OrderTypeEnum.Energy_Pass,
    displayPosition: 20,
    packages: [
      {
        name: "Bedarfsausweis",
        packageDescHeading: "Bedarfsausweis",
        title: "Bedarfsausweis",
        service_key: OrderTypeEnum.Energy_Pass,
        service_title: "Energetische Gebäudedokumente",
        disabled: false,
        package_key: EnergyPassPackageTypeEnum.DemandCertificate,
        feedbacks_included: -1,
        price: "energy_pass_demand_certificate",
        extra_feedback_price: "energy_pass_demand_certificate_additional_feedback",
        package_images: ["energy_pass/Symbol_Bedarfsausweis.jpg"],
        package_icons: ["energy_pass/Bedarfsausweis.svg"],
        package_contents: [
          "10 Jahre rechtssicher",
          "inklusive DIBt Registrierungsnummer",
          "Direkter Download nach Freigabe ",
        ],
        details: [
          {
            label: "Korrekturschleifen",
            value:
              "Bei diesem Produkt gibt es keine begrenzte Anzahl an Korrekturschleifen. Vielmehr sind Sie im stetigen Austausch mit unserem Team, das Ihnen mit einem ganzheitlichen Support zur Seite steht. Erst wenn alle Fragen geklärt werden konnten, wird die Erstellung des finalen Ausweises veranlasst.",
          },
          {
            label: "Bearbeitungszeiten",
            value:
              "Die Bearbeitungszeiten variieren stark und sind von der Genauigkeit und Richtigkeit der im Bestellprozess getätigten Angaben abhängig. Sofern alle Angaben eindeutig und somit keine Rückfragen notwendig sind, ist mit einer durchschnittlichen Bearbeitungszeit von 48 h zu rechnen.",
          },
          {
            label: "Zusatzkosten",
            value:
              "Sobald der Energieausweis final erstellt wurde, können Anpassungen wie eine Adressänderung nur gegen einen Aufpreis von 15,00 € vorgenommen werden. Bitte kontrollieren Sie Ihre Angaben sorgfältig, um Zusatzkosten zu vermeiden.",
          },
          {
            label: "Gültigkeit",
            value:
              "Nachdem Sie von uns das finale Ausweisdokument zum Download zur Verfügung gestellt bekommen, gilt dies als rechtskonformer Nachweis für die Energieeffizienz des Gebäudes. Alle von uns erstellten Energieausweise sind zehn Jahre nach GEG 2020 (Gebäudeenergiegesetz) rechtssicher und beinhalten eine offizielle Registrierungsnummer vom Deutschen Institut für Bautechnik.",
          },
        ],
        numberOfDrafts: 0,
        addressInputRequired: true,
        service_image: "service_images/Energieausweise.svg",
      },
      {
        name: "Verbrauchsausweis",
        packageDescHeading: "Verbrauchsausweis",
        title: "Verbrauchsausweis",
        feedbacks_included: -1,
        service_key: OrderTypeEnum.Energy_Pass,
        service_title: "Energetische Gebäudedokumente",
        disabled: false,
        package_key: EnergyPassPackageTypeEnum.ConsumptionCertificate,
        price: "energy_pass_consumption_certificate",
        extra_feedback_price: "energy_pass_consumption_certificate_additional_feedback",
        package_images: ["energy_pass/Symbol_Verbrauchsausweis.jpg"],
        package_icons: ["energy_pass/Verbrauchsausweis.svg"],
        package_contents: [
          "10 Jahre rechtssicher",
          "inklusive DIBt Registrierungsnummer",
          "Direkter Download nach Freigabe ",
        ],
        details: [
          {
            label: "Korrekturschleifen",
            value:
              "Bei diesem Produkt gibt es keine begrenzte Anzahl an Korrekturschleifen. Vielmehr sind Sie im stetigen Austausch mit unserem Team, das Ihnen mit einem ganzheitlichen Support zur Seite steht. Erst wenn alle Fragen geklärt werden konnten, wird die Erstellung des finalen Ausweises veranlasst.",
          },
          {
            label: "Bearbeitungszeiten",
            value:
              "Die Bearbeitungszeiten variieren stark und sind von der Genauigkeit und Richtigkeit der im Bestellprozess getätigten Angaben abhängig. Sofern alle Angaben eindeutig und somit keine Rückfragen notwendig sind, ist mit einer durchschnittlichen Bearbeitungszeit von 48 h zu rechnen.",
          },
          {
            label: "Zusatzkosten",
            value:
              "Sobald der Energieausweis final erstellt wurde, können Anpassungen wie eine Adressänderung nur gegen einen Aufpreis von 15,00 € vorgenommen werden. Bitte kontrollieren Sie Ihre Angaben sorgfältig, um Zusatzkosten zu vermeiden.",
          },
          {
            label: "Gültigkeit",
            value:
              "Nachdem Sie von uns das finale Ausweisdokument zum Download zur Verfügung gestellt bekommen, gilt dies als rechtskonformer Nachweis für die Energieeffizienz des Gebäudes. Alle von uns erstellten Energieausweise sind zehn Jahre nach GEG 2020 (Gebäudeenergiegesetz) rechtssicher und beinhalten eine offizielle Registrierungsnummer vom Deutschen Institut für Bautechnik.",
          },
        ],
        numberOfDrafts: 0,
        addressInputRequired: true,
        service_image: "service_images/Energieausweise.svg",
      },
      {
        companies: ["^dummy_not_available"],
        defaultDeliveryTime: 4,
        addressInputRequired: true,
        details: [
          {
            label: "Korrekturschleifen",
            value: `Korrekturschleifen sind nicht im Preis enthalten und können nicht erworben werden. Bei Fragen oder Anmerkungen schreiben Sie uns bitte eine E-Mail an <a href="mailto:service@imogent.com">service@imogent.com</a>.`,
          },
          {
            label: "Bearbeitungszeit",
            value: `Das Ergebnis liegt in der Regel innerhalb von 2-4 Werktagen nach Bestellaufgabe vor. Eine ausführliche und vollständige Datengrundlage beschleunigt die Erstellung.`,
          },
          {
            label: "Stornierungsbedingungen",
            value:
              "Eine kostenfreie Stornierung ist innerhalb von 2h nach Auftragseingang möglich. Bei einer späteren Stornierung muss der Rechnungsbetrag in voller Höhe gezahlt werden.",
          },
          {
            label: "Haftung",
            value: `Alle genannten Werte inkl. der Sanierungskosten für Gewerke beruhen auf Erfahrungswerten und garantieren keine mögliche Umsetzung zu diesen Preisen. Alle Bauteile werden zudem als technisch intakt angenommen, sodass es in der Praxis zu Diskrepanzen und Abweichungen kommen kann. Eine genauere Schätzung der Preise kann von Eigentümern beispielsweise durch die Erstellung eines individuellen Sanierungsfahrplans ermittelt werden. Für konkrete Preise sind jedoch immer Handwerkerangebote hinzuzuziehen.`,
          },
          {
            label: "Gültigkeit",
            value:
              "Das Produkt hat keine beschränkte Gültigkeit. Angegebene Fördermittel orientieren sich jedoch an den aktuell gültigen Förderrichtlinien.",
          },
          {
            label: "Ausgabeformat",
            value: `Das Produkt wird im PDF-Format ausgegeben. Ein Beispiel finden Sie <a href="https://firebasestorage.googleapis.com/v0/b/imogent-plattform.appspot.com/o/assets%2Fdocuments%2Fenergy-assessment%2FBeispiel_Energetische_Ersteinsch%C3%A4tzung.pdf?alt=media&token=5a4bd3a3-7241-473c-ad15-2887acf2e9bf" target="_blank">hier</a>.`,
          },
        ],
        disabled: true,
        feedbacks_included: -1,
        name: "Energetische Ersteinschätzung",
        numberOfDrafts: 0,
        packageDescHeading: "Energetische Ersteinschätzung",
        package_contents: [
          "Energetische Ersteinschätzung d. Ist-Zustandes",
          "Schätzung gewerkespezifischer Kosten",
          "Schätzung überregionaler Förderungen",
        ],
        package_images: ["energy_pass/energy_assessment.png"],
        package_icons: ["energy_pass/energy_assessment_icon.svg"],
        package_key: CONSTANTS.PACKAGE_KEYS.ENERGY_ASSESSMENT,
        price: "energy_assessment_price",
        service_key: OrderTypeEnum.Energy_Pass,
        service_title: "Energetische Gebäudedokumente",
        title: "Energetische Ersteinschätzung",
        dateArrangementRequired: false,
        price_pre_text: "ab",
        tooltip: "Energetische Ersteinschätzung",
        service_image: "service_images/Energieausweise.svg",
      },
    ],
  },
  {
    displayPosition: 10,
    id: OrderTypeEnum.Documents_Procurement,
    packages: [
      {
        addressInputRequired: true,
        description_placeholder: "My starter package description",
        details: [
          {
            label: "Korrekturschleifen",
            value:
              "Korrekturschleifen sind bei diesem Produkt nicht enthalten. " +
              'Bei Fragen oder Anmerkungen wenden Sie sich gerne direkt an unser Serviceteam und schreiben Sie eine Mail an <a href="mailto:service@imogent.com">service@imogent.com</a>.',
          },
          {
            label: "Vollmachten",
            value:
              "Um mit der Bearbeitung Ihrer Bestellung zu beginnen, ist es wichtig, dass alle im Bestellprozess abgefragten Vollmachten vorliegen. " +
              "Nutzen Sie einen <b>Scanner</b> oder eine <b>Scannerapp</b>, um händisch signierte Vollmachten zu digitalisieren. " +
              "Sollten Ihnen bei Aufgabe der Bestellung noch nicht alle Dokumente unterschrieben vorliegen, haben Sie die Möglichkeit Ihren Auftrag zwischenzuspeichern und die Bearbeitung zu einem späteren Zeitpunkt fortzusetzen. " +
              "Als Premiummitglied ist es möglich eine Generalvollmacht zu hinterlegen. " +
              'Wenden Sie sich bei Fragen gerne an unser Serviceteam und schreiben Sie eine Mail an <a href="mailto:service@imogent.com">service@imogent.com</a>.',
          },
          {
            label: "Lieferzeiten",
            value:
              "Lieferzeiten sind  stark von der zuständigen Behörde sowie vom Dokumententyp abhängig. " +
              "Sollte es bei der zuständigen Behörde zu Verzögerungen kommen, werden Sie über unsere Nachrichtenfunktion im Auftrag darüber informiert. " +
              "Eine Liste mit den zu erwartenden Bearbeitungszeiten der einzelnen Dokumente finden sie " +
              '<a href="https://www.imogent.de/behoerdenauskuenfte-bearbeitungszeiten-und-auslagen" target="_blank">hier</a> sowie im Bestellprozess ' +
              "unter der jeweiligen Dokumenteninfo. Aufgrund der großen, regionalen Unterschiede, sind die angegeben Werte jedoch nur als grobe Richtwerte zu betrachten.",
          },
          {
            label: "Stornierungsbedigungen",
            value: `
            Aufgrund der operativen Handlungsfolge sind <b>Stornierungen grundsätzlich nicht möglich</b>. Sämtliche Anträge werden unmittelbar nach der Bestellaufgabe automatisch generiert und an die zuständige Behörde übermittelt. Da die Behörden den Antrangseingang chronologisch abarbeiten, werden Stornierungen erst nach der Bearbeitung wahrgenommen.
            <br/>
            Irrtümliche Bestellungen können ausdrücklich nicht herausgefiltert werden und werden gemäß der vereinbarten Preisbestimmungen <b>in voller Höhe in Rechnung gestellt</b>.
            Telefonische Stornierungen können aufgrund der schweren Erreichbarkeit der Behörden wirtschaftlich nicht umgesetzt werden.
            <br/>
            Ausgenommen sind jene Auskünfte, die trotz mehrfacher schriftlicher Nachfrage vom Amt verweigert werden.
            `,
          },
          {
            label: "Auslagen",
            value:
              "Die anfallenden Auslagen sind nicht im Preis enthalten und variieren stark je nach Dokumententyp und Region. " +
              "Die Auslagen werden Ihnen bei Fertigstellung des Auftrages kumuliert in Rechnung gestellt. " +
              "Eine Liste mit den zu erwartenden Auslagen je Dokumententyp finden Sie " +
              '<a href="https://www.imogent.de/behoerdenauskuenfte-bearbeitungszeiten-und-auslagen" target="_blank">hier</a> ' +
              "sowie im Bestellprozess unter der jeweiligen Dokumenteninfo. " +
              "Aufgrund der großen, regionalen Unterschiede, sind die angegeben Werte jedoch nur als grobe Richtwerte zu betrachten.",
          },
          {
            label: "Sonstiges",
            value:
              "Nicht alle Auskünfte sind in jedem Bundesland verfügbar. " +
              "Sollte es vorkommen, dass eine Auskunft für die angegebene Objektadresse nicht möglich ist, wird Ihnen eine Datei mit der entsprechenden Info zurückgespielt. " +
              "Bitte informieren Sie uns in diesem Fall unter " +
              '<a href="mailto:service@imogent.com">service@imogent.com</a>' +
              ", damit der Preis reduziert werden kann.",
          },
        ],
        disabled: false,
        extra_feedback_price: "",
        feedbacks_included: -1,
        name: "Behördenauskünfte",
        newProperty: false,
        numberOfDrafts: 0,
        package_contents: [
          "Vollautomatisierte Beschaffung von Dokumenten",
          "Kumulierte Abrechnung von Auslagen",
          "Preisvorteile im Paket",
        ],
        package_id: "documents_individual",
        package_images: ["documents_procurement/starter.jpg"],
        package_icons: ["documents_procurement/Behoerdenauskuenfte.svg"],
        package_key: "28",
        packageDescHeading: "Behördenauskünfte",
        physicalProduct: false,
        price: "documents_procurement_default_package",
        price_note: "Preise für die Servicegebühr. Hinzu kommen die Behördenauslagen",
        price_pre_text: "ab ",
        priceAddUnits: "documents_procurement_addUnits",
        priceLandRegisterUnknown: "documents_procurement_landregister_unknown",
        service_key: OrderTypeEnum.Documents_Procurement,
        service_title: "Behördenauskünfte",
        title: "",
        promoBundles: [
          {
            description: "Paket 1 - Standard",
            id: "standard",
            price: "documents_procurement_promo_bundle_default",
            products: [
              {
                id: 101,
                price_override: null,
              },
              {
                id: 158,
                price_override: null,
              },
            ],
          },
          {
            description: "Paket 2 - Premium",
            id: "premium",
            price: "documents_procurement_promo_bundle_premium",
            products: [
              {
                id: 101,
                price_override: null,
              },
              {
                id: 158,
                price_override: null,
              },
              {
                id: 200,
                price_override: null,
              },
              {
                id: 201,
                price_override: null,
              },
            ],
          },
          {
            description: "Paket 3 - Premium Plus",
            id: "premium_plus",
            price: "documents_procurement_promo_bundle_premium_plus",
            products: [
              {
                id: 101,
                price_override: null,
              },
              {
                id: 158,
                price_override: null,
              },
              {
                id: 200,
                price_override: null,
              },
              {
                id: 201,
                price_override: null,
              },
              {
                id: 105,
                price_override: null,
              },
            ],
          },
        ],
        docEstateProductCategories: [
          {
            description: "Neu: Bauakte",
            products: [700, 701, 702],
          },
          {
            description: "Grundbuchauszug / Grundakte",
            products: [100, 101, 102, 103, 120, 105, 1052],
          },
          {
            description: "Belastungen / Denkmalschutz",
            products: [200, 201, 202, 203, 204],
          },
          {
            description: "Kataster- und Geoauskünfte / Anliegerbeiträge / Kommunalabgaben",
            products: [104, 158, 401, 402, 403, 404, 405, 300, 301],
            // 406
          },
          {
            description: "Steuerliche Bescheide / Gesellschaftsunterlagen",
            products: [500, 501, 502, 503, 504],
          },
          {
            description: "Planungsrechtauskünfte",
            products: [600, 601, 602, 603, 604, 605, 606, 607, 608, 609],
          },
        ],
        docEstateProductList: [
          {
            id: 100,
            nameShort: "grundb",
            name_long: "Grundbuchauszug (beglaubigt)",
            name_show: null,
            starred: false,
            price: "documents_procurement_grundbuch_beglaubigt",
            description: `
                <b>Info:</b><br>
                Das Grundbuch ist ein öffentliches Register, in dem die Eigentumsverhältnisse sämtlicher Grundstücke verbindlich geregelt sind. Die dabei herausgegebene Abschrift wird als Grundbuchauszug bezeichnet.
                <br>
                Sie erhalten einen unbeglaubigten Grundbuchauszug.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                Die Bearbeitungsdauer ist stark vom Arbeitsaufkommen des verantwortlichen Grundbuchamts abhängig. In der Regel erhalten Sie Ihren Grundbuchauszug innerhalb von 7 bis 10 Tagen. In größeren Städten kann es durch die hohe Belastung der Ämter zu Verzögerungen kommen und weitaus länger dauern.
                <br><br>
                <b>Behördengebühr:</b><br>
                Grundbuchauszug (beglaubigt) 20 Euro`,
          },
          {
            id: 101,
            nameShort: "grunde",
            name_long: "Grundbuchauszug (unbeglaubigt)",
            name_show: null,
            starred: false,
            price: "documents_procurement_grundbuch_unbeglaubigt",
            description: `
                <b>Info:</b><br>
                Das Grundbuch ist ein öffentliches Register, in dem die Eigentumsverhältnisse sämtlicher Grundstücke verbindlich geregelt sind. Die dabei herausgegebene Abschrift wird als Grundbuchauszug bezeichnet.
                <br>
                Sie erhalten einen unbeglaubigten Grundbuchauszug.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                Die Bearbeitungsdauer ist stark vom Arbeitsaufkommen des verantwortlichen Grundbuchamts abhängig. In der Regel erhalten Sie Ihren Grundbuchauszug innerhalb von 7 bis 10 Tagen. In größeren Städten kann es durch die hohe Belastung der Ämter zu Verzögerungen kommen und weitaus länger dauern.
                <br><br>
                <b>Behördengebühr:</b><br>
                Grundbuchauszug (unbeglaubigt) 10 Euro`,
          },
          {
            id: 102,
            nameShort: "bewil",
            name_long: "Bewilligungsurkunde (Abteilung II)",
            name_show: null,
            starred: true,
            price: "documents_procurement_bewilligungsurkunde_abteilung2",
            description: `
                <b>Info:</b><br>
                Bei diesem Produkt erhalten Sie die gültigen Bewilligungsurkunden aus Abteilung II. Es werden alle für die Immobilie hinterlegten Grundbuchblätter berücksichtigt.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                In der Regel erhalten Sie die Bewilligungsurkunde innerhalb von 2 bis 4 Wochen. Die Bearbeitungsdauer variiert dabei stark vom Alter der Bewilligungsurkunde (physisch oder digital) und von dem Arbeitsaufkommen des zuständigen Amtes. Bitte berücksichtigen Sie, dass Insbesondere in Großstädten die Bearbeitungsdauer auch weitaus mehr als 4 Wochen betragen kann.
                <br><br>
                <b>Behördengebühr:</b><br>
                In der Regel kostenfrei. Manche Ämter verlangen eine geringe Kopiergebühr (0,50 Euro pro Seite).<br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 103,
            nameShort: "bewila",
            name_long: "Bewilligungsurkunde (Alle Abteilungen)",
            name_show: null,
            starred: true,
            price: "documents_procurement_bewilligungsurkunde_abteilungX",
            description: `
                <b>Info:</b><br>
                Bei diesem Produkt erhalten Sie die gültigen Bewilligungsurkunden aus allen Abteilungen. Es werden alle für die Immobilie hinterlegten Grundbuchblätter berücksichtigt.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                In der Regel erhalten Sie die Bewilligungsurkunde innerhalb von 2 bis 4 Wochen. Die Bearbeitungsdauer variiert dabei stark vom Alter der Bewilligungsurkunde (physisch oder digital) und von dem Arbeitsaufkommen des zuständigen Amtes. Bitte berücksichtigen Sie, dass Insbesondere in Großstädten die Bearbeitungsdauer auch weitaus mehr als 4 Wochen betragen kann.
                <br><br>
                <b>Behördengebühr:</b><br>
                In der Regel kostenfrei. Manche Ämter verlangen eine geringe Kopiergebühr (0,50 Euro pro Seite).
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                <br>
                Bitte beachten Sie, dass die Servicegebühr pro Grundbuchblatt anfällt.
                `,
          },
          {
            id: 120,
            nameShort: "bewild",
            name_long: "Bewilligungsurkunde (Abteilung III)",
            name_show: null,
            starred: false,
            price: "documents_procurement_bewilligungsurkunde_abteilung3",
            description: `
                <b>Info:</b><br>
                Bei diesem Produkt erhalten Sie die gültigen Bewilligungsurkunden aus Abteilung II. Es werden alle für die Immobilie hinterlegten Grundbuchblätter berücksichtigt.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                In der Regel erhalten Sie die Bewilligungsurkunde innerhalb von 2 bis 4 Wochen. Die Bearbeitungsdauer variiert dabei stark vom Alter der Bewilligungsurkunde (physisch oder digital) und von dem Arbeitsaufkommen des zuständigen Amtes. Bitte berücksichtigen Sie, dass Insbesondere in Großstädten die Bearbeitungsdauer auch weitaus mehr als 4 Wochen betragen kann.
                <br><br>
                <b>Behördengebühr:</b><br>
                In der Regel kostenfrei. Manche Ämter verlangen eine geringe Kopiergebühr (0,50 Euro pro Seite).
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                <br>
                Bitte beachten Sie, dass die Servicegebühr pro Grundbuchblatt anfällt.
                `,
          },
          {
            id: 105,
            nameShort: "teilun",
            name_long: "Teilungserklärung",
            name_show: null,
            starred: true,
            price: "documents_procurement_teilungserklaerung",
            description: `
                <b>Info:</b><br>
                Die Teilungserklärung ist die Erklärung des Grundstückseigentümers gegenüber dem Grundbuchamt, dass das Eigentum an dem Grundstück in Miteigentumsanteile aufgeteilt wird. Weitere Bewilligungen erhalten Sie in diesem Zusammenhang nicht.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                In der Regel erhalten Sie die Auskunft der Teilungserklärung innerhalb von 4 bis 6 Wochen. In Großstädten kann die Bearbeitungsdauer aufgrund des erhöhten Aufkommens auch mehr als 6 Wochen betragen.
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Bereitstellung der Teilungserklärung ist nach keiner festen Gebührenstruktur geregelt und beträgt in der Regel eine geringe Kopiergebühr (0,50 Euro pro Seite).
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.`,
          },
          {
            id: 1052,
            nameShort: "teilun",
            name_long: "Teilungserklärung (Vor-Ort-Service)",
            name_show: null,
            starred: true,
            price: "documents_procurement_teilun_onsite",
            description: `
                <b>Info:</b><br>
                Herzlichen Glückwunsch! Für die von Ihnen angegebene Adresse erhalten Sie unseren Vor-Ort-Service. Das bedeutet, dass Sie die Teilungserklärung nicht nur schneller erhalten, sondern auch vollumfänglich, also inkl. Abgeschlossenheitsbescheinigung und dem zugehörigen Aufteilungsplan, da das Dokument von einem unserer Agenten vor Ort gesichtet und digitalisiert wird.
                <br>
                Die Teilungserklärung ist die Erklärung des Grundstückseigentümers gegenüber dem Grundbuchamt, dass das Eigentum an dem Grundstück in Miteigentumsanteile aufgeteilt wird.
                <br><br>
                <b>Inhalt:</b><br>
                <ul>
                    <li>Abgeschlossenheitsbescheinigung</li>
                    <li>Aufteilungsplan für die angegebene Wohneinheit</li>
                    <li>Gemeinschaftsordnung</li>
                </ul>
                <br>
                <b>Bereitstellungsdauer:</b><br>
                In der Regel erhalten Sie die Teilungserklärung über den Vor-Ort-Service innerhalb von 21 Tagen. In vereinzelten Städten, wie z.B. Köln und in Teilen von Berlin kann die Terminvergabe jedoch bis zu 6 Wochen dauern. In Ferienzeiten und bei Krankheitsfällen kann sich die  durchschnittliche Bereitstellungsdauer ebenfalls verzögern. In diesen Fällen informieren wir Sie über unsere Benachrichtigungsfunktion.
                <br><br>
                <b>Wichtige Hinweise:</b><br>
                Bitte beachten Sie, dass die Vollständigkeit der Unterlagen nicht garantiert werden kann und der Behörde in Ausnahmefällen Teile oder die gesamte Teilungserklärung nicht vorliegen. Dies wird seitens der Behörden und Ämter leider nur in seltenen Fällen vorab kommuniziert. Eine Erstattung/Minderung der Kosten ist somit nicht möglich.
                <br><br>
                <b>Zusatzkosten:</b>
                In einigen Fällen (wie z.B. in Köln) können Zusatzkosten seitens der Behörden und Ämter hinzukommen. Diese sind abhängig vom Umfang der Teilungserklärung und betragen im Durchschnitt ca. 0,50 Euro/Seite.
                <br>`,
          },
          {
            id: 200,
            nameShort: "altlas",
            name_long: "Altlastenauskunft",
            name_show: null,
            starred: false,
            price: "documents_procurement_altlastenauskunft",
            description: `
                <b>Info:</b><br>
                Unter Altlasten versteht man Verunreinigungen der Flächen durch Gefahrenstoffe. Wurden an einem Grundstück Altlasten identifiziert, werden diese in dem zuständigen Register, auch Altlastenkataster genannt, aufgeführt.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                Die Bereitstellungsdauer liegt zwischen 1 bis 4 Wochen. In Großstädten kommt es in seltenen Fällen zu deutlich längeren Bearbeitungszeiten.
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Kosten für den Auszug aus dem Altlastenkataster lassen sich nicht pauschalisieren. Die Gebühren ordnen sich zwischen 0 bis 50 Euro pro Flurstück ein. Negativatteste werden oftmals gebührenfrei erteilt.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 201,
            nameShort: "baulas",
            name_long: "Baulastenauskunft",
            name_show: null,
            starred: false,
            price: "documents_procurement_baulastenauskunft",
            description: `
                <b>Info:</b><br>
                Baulasten stellen besondere Verpflichtungen eines Grundstückeigentümers gegenüber der Baubehörde (Bauaufsichtsverwaltung) zugunsten einer dritten Partei dar, welche über die allgemeingültigen Regularien des öffentlichen Rechts hinausgehen. Bei der Eintragung einer Baulast verpflichtet sich der Grundstückseigentümer etwas zu tun, zu unterlassen oder zu dulden.
                <br>
                Im Rahmen der Baulastenauskunft werden Baulastenblatt, Lageplan und Baulastenübernahmeerklärung beantragt.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                Die Bereitstellungsdauer liegt zwischen 1 bis 4 Wochen. In Großstädten kommt es in seltenen Fällen zu deutlich längeren Bearbeitungszeiten.
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Kosten für den Auszug aus dem Baulastenverzeichnis lassen sich nicht pauschalisieren. Die Gebühren ordnen sich zwischen 0 bis 50 Euro pro Flurstück ein. Negativatteste werden oftmals gebührenfrei erteilt.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 202,
            nameShort: "kampfm",
            name_long: "Kampfmittelauskunft",
            name_show: null,
            starred: true,
            price: "documents_procurement_kampfmittelauskunft",
            description: `
                <b>Info:</b><br>
                Zu Kampfmitteln zählen zur Kriegsführung bestimmte Stoffe und Gegenstände militärischer Herkunft sowie Teile solcher Gegenstände, die Explosivstoffe oder chemische Kampf-, Nebel-, Brand-, Reiz- oder Rauchstoffe enthalten. Die Kampfmittelauskunft bzw. Luftbildauswertung basiert auf der Auswertung von Luftbildaufnahmen, die Luftangriffe sowie Gefechte dokumentieren. Anhand dieser Dokumentationen können die Behörden eine Einschätzung geben, ob Kampfmittel in Form von Bombenblindgängern oder sonstigen Kriegsabfällen, wahrscheinlich sind.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                Die Belastungsprüfung wird in der Regel innerhalb von 2 Wochen durchgeführt. Anschließend erhalten Sie die entsprechende Auskunft.
                <b>Behördengebühr</b><br>
                Die Kosten der Kampfmittelerkundung hängen von vielen individuellen Faktoren des Grundstücks, u.a. Lage, Größe, historische Nutzung und geplante Eingriffe, etc. ab. I.d.R. belaufen sich die Kosten zwischen 50,00 Euro und 600,00 Euro. In den Bundesländern Bayern, Thüringen und Rheinland-Pfalz ist die Aufgabe der Luftbildauswertung an private Dienstleister übergeben worden. Hierdurch kommt es zu deutlich höheren Gebühren, die zwischen 1.500 Euro und 3.500 Euro liegen.
                `,
          },
          {
            id: 203,
            nameShort: "gedenkma",
            name_long: "Gebäudedenkmalschutz",
            name_show: null,
            starred: false,
            price: "documents_procurement_gebaeudedenkmalauskunft",
            description: `
                <b>Info:</b><br>
                Im Rahmen dieser Abfrage wird sowohl der Gebäude- als auch Kulturdenkmalschutz berücksichtigt.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                Die Bereitstellungsdauer liegt zwischen 1 bis 4 Wochen. In Großstädten kommt es in seltenen Fällen zu deutlich längeren Bearbeitungszeiten.
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Gebühren für eine Denkmalschutzauskunft reichen von 0 bis 50 Euro und sind abhängig von der Gebührenordnung der zuständigen Behörde. Die Abrechnung erfolgt dabei größtenteils pro Grundstück/Immobilie. Teilweise erheben die Ämter Gebühren pro Flurstück/Hausnummer, wodurch die Kosten für große Grundstücke auch mehr als 50 Euro betragen können. In vielen Fällen sind die Auskünfte jedoch gebührenfrei.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 204,
            nameShort: "bodendenkma",
            name_long: "Bodendenkmalauskunft",
            name_show: null,
            starred: false,
            price: "documents_procurement_bodendenkmalauskunft",
            description: `
                <b>Info:</b><br>
                Die archäologische Denkmalschutzauskunft bezieht sich rein auf das Grundstück und informiert über mögliche Vermutungen und besondere Auflagen im Zusammenhang mit der Bebauung.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                Je nach Gemeinde dauert die Bereitstellung der Auskunft 1 bis 6 Wochen. Abweichungen bezüglich der Dauer sind möglich, da die Bearbeitungszeit von dem gegenwärtigen Auftragsvolumen der bearbeitenden Stelle abhängt.
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Gebühren für eine Denkmalschutzauskunft reichen von 0 bis 50 Euro und sind abhängig von der Gebührenordnung der zuständigen Behörde. Die Abrechnung erfolgt dabei größtenteils pro Grundstück/Immobilie. Teilweise erheben die Ämter Gebühren pro Flurstück/Hausnummer, wodurch die Kosten für große Grundstücke auch mehr als 50 Euro betragen können. In vielen Fällen sind die Auskünfte jedoch gebührenfrei.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 300,
            nameShort: "erschl",
            name_long: "Erschließungsbeitragsbescheinigung",
            name_show: null,
            starred: false,
            price: "documents_procurement_erschliessungsbeitraege",
            description: `
                <b>Info:</b><br>
                Im Rahmen der Erschließungsbeitragsbescheinigung werden Beiträge zu Erschließungskosten und Straßenausbaubeiträgen abgefragt. Bitte beachten Sie, dass die Kanalanschlussgebühren eine separate Auskunft darstellen.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                Die Bereitstellung dauert durchschnittlich 4 bis 6 Wochen. Es kann durchaus länger dauern, wenn mehrere Stellen für das Auskunftsersuchen angesprochen werden müssen.
                <br><br>
                <b>Behördengebühr:</b><br>
                Die durchschnittlichen Kosten für eine Erschließungsbeitragsbescheinigung liegen zwischen 0 und 50 Euro pro Flurstück.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 301,
            nameShort: "kanalans",
            name_long: "Kanalanschlussgebühren",
            name_show: null,
            starred: false,
            price: "documents_procurement_kanalanschlussgebuehren",
            description: `
                <b>Info:</b><br>
                Die Auskunft gibt Information über noch offene bzw. künftig zu entrichtende Kanalanschlussgebühren. Diese könne auch lange nach der Fertigstellung einer Immobilie anfallen. Bitte beachten Sie, dass im Rahmen dieser Abfrage nicht die Erschließungskosten und Straßenausbaubeiträge abgefragt werden. Hierfür existiert eine separate Auskunft.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                Die Bereitstellung dauert durchschnittlich 4 bis 8 Wochen. Es kann länger dauern, wenn mehrere Stellen für das Auskunftsersuchen angesprochen werden müssen. Die dezentralen Strukturen erschweren den Behörden die Arbeit.
                <br><br>
                <b>Behördengebühr:</b><br>
                Die durchschnittlichen Kosten für eine Auskunft zu Kanalanschlussgebühren liegt zwischen 0 und 50 Euro pro Flurstück.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          // {
          //   'id': 302,
          //   'nameShort': 'strueck',
          //   'name_long': 'Negativtestat steuerliche Rückstände',
          //   'name_show': null,
          //   'starred':false,
          //   'price': 'documents_procurement_negativtestat',
          //   'description': `
          //       <b>Negativtestat steuerliche Rückstände</b><br><br>
          //     `
          // },
          // {
          //   'id': 303,
          //   'nameShort': 'kommunalabg',
          //   'name_long': 'Bescheinigung Kommunalabgaben',
          //   'name_show': null,
          //   'starred':true,
          //   'price': 'documents_procurement_kommunalabgaben',
          //   'description': `
          //       <b>Bescheinigung Kommunalabgaben</b><br><br>
          //     `
          // },
          {
            id: 104,
            nameShort: "liegen",
            name_long: "Liegenschaftskarte (amtlich)",
            name_show: null,
            starred: false,
            price: "documents_procurement_liegenschaftskarte_beglaubigt",
            description: `
                <b>Info:</b><br>
                Die Liegenschaftskarte ist eine maßstäbliche Darstellung aller Liegenschaften. Sie ist mit ihrem Nachweis der Lage und Abgrenzung die amtliche Kartengrundlage des Grundbuchs und Grundlage für die Beantragung diverser immobilienbezogener Auskünfte.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                2 bis 14 Tage, in seltenen Fällen auch länger.
                <br><br>
                <b>Behördengebühr:</b><br>
                10 bis 45 Euro pro Liegenschaft.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 158,
            nameShort: "eliegen",
            name_long: "Liegenschaftskarte (nicht amtlich)",
            name_show: null,
            starred: false,
            price: "documents_procurement_liegenschaftskarte",
            description: `
                <b>Info:</b><br>
                Die Liegenschaftskarte ist eine maßstäbliche Darstellung aller Liegenschaften. Sie ist mit ihrem Nachweis der Lage und Abgrenzung die Kartengrundlage des Grundbuchs und Grundlage für die Beantragung diverser immobilienbezogener Auskünfte.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                24 - 72h, sofern Grundstücksdaten nicht angegeben wurden und erst ermittelt werden müssen, kann die Bereitstellung auch länger dauern.
                <br><br>
                <b>Behördengebühr:</b><br>
                Für nicht amtliche Liegenschaftskarten fallen keine Behördengebühren an.
                `,
          },
          {
            id: 401,
            nameShort: "flurstb",
            name_long: "Bestandsnachweis",
            name_show: null,
            starred: false,
            price: "documents_procurement_bestandsnachweis",
            description: `
                <b>Info:</b><br>
                Der Bestandsnachweis listet sämtliche Flurstücke, die Informationen, die zu einer Liegenschaft zugehörig sind und dem Bestand eines Eigentümers zugeordnet werden können. Wird der Nachweis aus dem Liegenschaftsbuch für eine größere Liegenschaft benötigt, die aus mehreren Flurstücken besteht, ist meistens der Bestandsnachweis im Vergleich zum Flurstücks- und Eigentümernachweis die kostengünstigere Variante.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                7 bis 14 Tage, in seltenen Fällen auch länger.
                <br><br>
                <b>Behördengebühr:</b><br>
                10 bis 45 Euro pro Liegenschaft.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 402,
            nameShort: "flursta",
            name_long: "Flurstücks und Eigentümernachweis",
            name_show: null,
            starred: false,
            price: "documents_procurement_flurundeigentuemernachweis",
            description: `
                <b>Info:</b><br>
                Der Flurstücks- und Eigentümernachweis enthält ergänzende Informationen zum Grundstück, die im Grundbuch nicht enthalten sind. Hierzu gehört eine genaue Lagebezeichnung, die in Form eines Straßennamens angegeben wird, sowie sämtlicher Hausnummern eines Flurstücks. Handelt es sich um eine große Liegenschaft mit mehreren Flurstücken, empfiehlt sich die Beantragung des Bestandsnachweises.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                7 bis 14 Tage.
                <br><br>
                <b>Behördengebühr:</b><br>
                8 bis 20 Euro pro Flurstück
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 403,
            nameShort: "fortf",
            name_long: "Fortführungsnachweis",
            name_show: null,
            starred: true,
            price: "documents_procurement_fortfuehrungsnachweis",
            description: `
                <b>Info:</b><br>
                Der Fortführungsnachweis dokumentiert die Veränderungen am Grundstück und dient als Grundlage für die notarielle Beurkundung und die Eintragung im Grundbuch.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                4 Wochen, teilweise auch länger.
                <br><br>
                <b>Behördengebühr:</b><br>
                Für die Erstellung des Fortführungsnachweises werden durch die Behörde Recherchearbeiten getätigt, die nach Stundenaufwand abgerechnet werden und sich daher nach der Historie der Fortführung richten.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 404,
            nameShort: "grenzb",
            name_long: "Grenzbescheinigung",
            name_show: null,
            starred: true,
            price: "documents_procurement_grenzbescheinigung",
            description: `
                <b>Info:</b><br>
                In der Grenzbescheinigung bestätigt das Vermessungsamt, dass ein Gebäude auf dem richtigen Grundstück und innerhalb der rechtmäßigen Grenzen errichtet wurde.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                4 Wochen, teilweise auch länger.
                <br><br>
                <b>Behördengebühr:</b><br>
                Für die Erstellung des Fortführungsnachweises werden durch die Behörde Recherchearbeiten getätigt, die nach Stundenaufwand abgerechnet werden und sich insbesondere nach der Größe der Liegenschaft ergeben.
                `,
          },
          {
            id: 405,
            nameShort: "bodenr",
            name_long: "Bodenrichtwert (-Karte)",
            name_show: null,
            starred: false,
            price: "documents_procurement_bodenrichtwertkarte",
            description: `
                <b>Info:</b><br>
                Bei einer Bodenrichtwertkarte handelt es sich um eine grafische Darstellung von Bodenrichtwerten innerhalb einer bestimmten Region, einer Gemeinde, einer Stadt oder innerhalb von Stadtteilen.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                1 Tag bis 2 Wochen.
                <br><br>
                <b>Behördengebühr:</b><br>
                0 bis 50 Euro pro Richtwert
                <br>
                Für die Erstellung des Fortführungsnachweises werden durch die Behörde Recherchearbeiten getätigt, die nach Stundenaufwand abgerechnet werden und sich insbesondere nach der Größe der Liegenschaft ergeben.
                `,
          },
          // {
          //   'id': 406,
          //   'nameShort': 'bodrichtkart',
          //   'name_long': 'Bodenrichtwertauskunft',
          //   'name_show': null,
          //   'starred':false,
          //   'price': 'documents_procurement_bodenrichtwertauskunft',
          //   'description': `
          //       <b>Bodenrichtwertauskunft</b><br>
          //     `
          // },
          {
            id: 500,
            nameShort: "grunds",
            name_long: "Grundsteuerbescheid",
            name_show: null,
            starred: false,
            price: "documents_procurement_grundsteuerbescheid",
            description: `
                <b>Info:</b><br>
                Die Grundsteuer für Immobilie und Grundstück wird vom Finanzamt mit dem Grundsteuermessbescheid festgesetzt. Auf der Grundlage dieses Bescheids setzt die Gemeinde die Steuer fest und schickt Eigentümern einen Grundsteuerbescheid.
                <br>
                Im Rahmen der Abfrage wird eine Kopie des aktuellen Grundsteuerbescheides beantragt.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                7 bis 14 Tage
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Bereitstellung erfolgt oftmals gebührenfrei. Gelegentlich wird eine Kopier- und Versandpauschale in Höhe von 20 bis 40 Euro angesetzt.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 501,
            nameShort: "einhei",
            name_long: "Einheitswertbescheid",
            name_show: "",
            starred: false,
            price: "documents_procurement_einheitswertbescheid",
            description: `
                <b>Info:</b><br>
                Der Einheitswertbescheid ist ein Bescheid, den das Finanzamt ausstellt. Dieser beziffert den Einheitswert einer Immobilie.
                <br>
                Im Rahmen der Abfrage wird eine Kopie des aktuellen Einheitswertbescheides beantragt.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                2 bis 4 Wochen
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Bereitstellung erfolgt oftmals gebührenfrei. Gelegentlich wird eine Kopier- und Versandpauschale in Höhe von 20 bis 40 Euro angesetzt.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 502,
            nameShort: "grundm",
            name_long: "Grundsteuermessbescheid",
            name_show: "",
            starred: false,
            price: "documents_procurement_grundsteuermessbescheid",
            description: `
                <b>Info:</b><br>
                Der Grundsteuermessbetrag richtet sich nach dem Einheitswert des Grundstücks. Nach Ermittlung des Grundsteuermessbetrags ergeht der Grundsteuermessbescheid. Er stellt einen Grundlagenbescheid dar, der die steuerliche Erhebung auf das Grundstück festlegt.
                <br>
                Im Rahmen der Abfrage wird eine Kopie des aktuellen Einheitswertbescheides beantragt.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                2 bis 4 Wochen
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Bereitstellung erfolgt oftmals gebührenfrei. Gelegentlich wird eine Kopier- und Versandpauschale in Höhe von 20 bis 40 Euro angesetzt.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 503,
            nameShort: "sunbo",
            name_long: "Steuerlicher Unbedenklichkeitsbescheid (Objekt)",
            name_show: "",
            starred: true,
            price: "documents_procurement_steuerlicher_unbedenklichkeitsbescheid",
            description: `
                <b>Info:</b><br>
                Die Abfrage dient zur Prüfung steuerlicher Rückstände eines Objektes.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                2 bis 4 Wochen
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Gebühr liegt zwischen 0 und 60 Euro pro Objekt.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 504,
            nameShort: "handel",
            name_long: "Handelsregisterauszug",
            name_show: "",
            starred: false,
            price: "documents_procurement_handelsregisterauszug",
            description: `
                <b>Info:</b><br>
                Die Abfrage beinhaltet einen Handelsregisterauszug.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                i.d.R 1 Tag
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Gebühr beträgt 5 Euro.
                `,
          },

          {
            id: 600,
            nameShort: "fläche",
            name_long: "Flächennutzungsplan",
            name_show: null,
            starred: false,
            price: "documents_procurement_flaechennutzungsplan",
            description: `
                <b>Info:</b><br>
                Die Auskunft informiert Sie darüber in welchem rechtskräftigen Flächennutzungsplan die Flurstücke liegen. Darüber hinaus erhalten Sie eine Kopie der dazugehörigen Pläne.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                1 bis 4 Wochen.
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Gebühr richtet sich nach der Größe und dem Zustand der Planunterlagen (physisch/ digital). Durchschnittlich betragen die Gebühren 0 bis 50 Euro pro Grundstück.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 601,
            nameShort: "bebauu",
            name_long: "Bebauungsplan",
            name_show: null,
            starred: false,
            price: "documents_procurement_bebauungsplan_auskunft",
            description: `
                <b>Info:</b><br>
                Die Auskunft informiert Sie darüber in welchem rechtskräftigen Bebauungsplan die Flurstücke liegen. Darüber hinaus erhalten Sie eine Kopie der dazugehörigen Pläne, Textteil/e, Satzungen und Begründungen (PDF). Zusätzlich wird angefragt, ob das Grundstück nach §34 oder §35 BauGb behandelt wird und/ oder ob es einen Aufstellungsbeschluss zur Durchführung eines Bebauungsplanverfahrens gibt.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                1 bis 4 Wochen.
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Gebühr richtet sich nach der Größe und dem Zustand der Planunterlagen (physisch/ digital). Durchschnittlich betragen die Gebühren 0 bis 50 Euro pro Grundstück.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 602,
            nameShort: "städte",
            name_long: "Städtebaulicher Vertrag",
            name_show: null,
            starred: true,
            price: "documents_procurement_staedtebaulicher_vertrag",
            description: `
                <b>Info:</b><br>
                Mithilfe der städtebaulichen Verträge sollen die Belange der Gemeinde, aber auch die Interessen von privaten Akteuren im gegenseitigen Konsens abgebildet und verbindlich festgehalten werden.
                <br>
                Die Auskunft informiert Sie über ein mögliches Bestehen eines solchen Vertrags. Darüber hinaus wird eine Kopie des Vertrages beantragt. Bitte beachten Sie, dass die Behörden oftmals nur dem ursprünglichen Vertragspartner (eventuell Vorbesitzer) den Vertrag aushändigen.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                1 bis 4 Wochen, aufgrund von Archivarbeiten oftmals auch länger.
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Gebühr richtet sich nach dem Zustand der Unterlagen (physisch/ digital). Im Durchschnitt liegen die Gebühren zwischen 0 und 50 Euro). Bei tiefgründigen Archivarbeiten können die Gebühren stark zu nehmen.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 603,
            nameShort: "planun",
            name_long: "Satzungs- und Gebietsauskünfte",
            name_show: null,
            starred: false,
            price: "documents_procurement_satzungs_und_gebietsauskuenfte",
            description: `
                <b>Info:</b><br>
                Im Rahmen der Satzungs- u. Gebietsauskunft werden folgende Parameter abgefragt:
                <br>
                <ul>
                    <li>Umlegungsgebiete</li>
                    <li>Vereinfachte Umlegungen gem. § 80 ff. BauGB</li>
                    <li>Veränderungssperre gemäß §§ 14, 16 BauGB</li>
                    <li>Vorbereitende Untersuchungen zu einer geplanten städtebaulichen</li>
                    <li>Sanierungsmaßnahme gemäß § 141 BauGB</li>
                    <li>Das Bestehen eines umfassenden / vereinfachten Sanierungsverfahrens</li>
                    <li>Städtebauliche Entwicklungsmaßnahmen gemäß §§ 165 ff. BauGB</li>
                    <li>Vorbereitende Untersuchungen zu einer geplanten städtebaulichen</li>
                    <li>Entwicklungsmaßnahme gemäß § 165 (4) BauGB</li>
                    <li>Erhaltungssatzungen gemäß §§ 172 ff. BauGB</li>
                    <li>Gestaltungssatzungen</li>
                </ul>
                <br>Liegt das Grundstück in einem der aufgezählten Gebiete, können für den Grundstückeigentümer zusätzliche Kosten in relevanter Höhe entstehen.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                Die Bereitstellung dauert durchschnittlich 2 bis 6 Wochen. Es kann länger dauern, wenn mehrere Stellen für das Auskunftsersuchen angesprochen werden müssen.
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Gebühren richten sich nach der Größe des Grundstückes (Anzahl an Flurstücken) und betragen in der Regel zwischen 0 und 30 Euro pro Flurstück/ Abfrage. Teilweise fallen deutlich höhere Gebühren an.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 604,
            nameShort: "flurb",
            name_long: "Auskunft Flurbereinigungs- u. Stadtumbaugebiet",
            name_show: null,
            starred: false,
            price: "documents_procurement_flurbereinigung_stadtumbaugebiet",
            description: `
                <b>Info:</b><br>
                Das Flurbereinigungsgebiet ist das Gebiet, das alle Flurstücke umfasst, für die die Flurbereinigung angeordnet worden ist.
                <br>
                Das Stadtumbaugebiet bezeichnet städtebauliche Maßnahmen in Stadtteilen oder ganzen Städten, die in besonderem Maße von Strukturwandel und Rückgang der Bevölkerung betroffen sind. Dem damit einhergehenden Funktionsverlust versucht der Stadtumbau auf der Grundlage von städtebaulichen Entwicklungskonzepten entgegenzuwirken.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                2 bis 6 Wochen
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Gebühren richten sich nach der Größe des Grundstückes (Anzahl an Flurstücken) und betragen in der Regel zwischen 0 und 30 Euro pro Flurstück/ Abfrage. Teilweise fallen deutlich höhere Gebühren an.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 605,
            nameShort: "besche",
            name_long: "Städtisches Vorkaufsrecht",
            name_show: null,
            starred: false,
            price: "documents_procurement_vorkaufsrechtssatzung",
            description: `
                <b>Info:</b><br>
                Die Vorkaufsrechtsatzung beschreibt ein besonderes Vorkaufsrecht, das einer Gemeinde erlaubt im Geltungsbereich eines Bebauungsplans durch Satzung ihr Vorkaufsrecht an unbebauten Grundstücken zu begründen.
                <br>
                Im Rahmen der Abfrage wird Auskunft über das vorhanden sein einer Vorkaufsrechtsatzung/Gebiete nach § 25 Abs.1 Nr.1 + 2 BauGB beantragt.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                2 bis 6 Wochen
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Gebühren richten sich nach der Größe des Grundstückes (Anzahl an Flurstücken) und betragen in der Regel zwischen 0 und 30 Euro pro Flurstück/ Abfrage.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 606,
            nameShort: "bid",
            name_long: "Business Improvement District",
            name_show: null,
            starred: false,
            price: "documents_procurement_business_improvement_district",
            description: `
                <b>Info:</b><br>
                Ein Business Improvement District ist ein räumlich klar umrissener Bereich, in dem die Grundeigentümer und Gewerbetreibenden gemeinsam versuchen, die Standortqualität durch Maßnahmen zu verbessern, die aus dem Aufkommen einer selbst auferlegten und befristet erhobenen Abgabe finanziert werden. BID kommen insbesondere in Großstädten vor.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                2 bis 6 Wochen
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Gebühren betragen in der Regel zwischen 0 und 30 Euro pro Abfrage.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 607,
            nameShort: "baulsch",
            name_long: "Auskunft Bau- u. Lärmschutz",
            name_show: null,
            starred: false,
            price: "documents_procurement_bau_laermschutz",
            description: `
                <b>Info:</b><br>
                Dieser Bescheid gibt Ihnen Auskunft darüber, ob sich das Grundstück in einem Bau- oder Lärmschutzgebiet befindet.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                7 bis 14 Tage, teilweise auch länger.
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Gebühren betragen in der Regel zwischen 0 und 30 Euro pro Abfrage.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 608,
            nameShort: "nwlsch",
            name_long: "Auskunft Natur-, Wasser- u. Landschaftsschutz",
            name_show: null,
            starred: false,
            price: "documents_procurement_natur_wasser_landschaft",
            description: `
                <b>Info:</b><br>
                Gibt Ihnen Auskunft darüber, ob sich das Grundstück in einem Natur-, Wasser- oder Landschaftsschutzgebiet befindet.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                7 bis 14 Tage, teilweise auch länger.
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Gebühren betragen in der Regel zwischen 0 und 30 Euro pro Abfrage.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 609,
            nameShort: "hwsch",
            name_long: "Auskunft Hochwasserschutzgebiete",
            name_show: null,
            starred: false,
            price: "documents_procurement_hochwasserschutzgebiete",
            description: `
                <b>Info:</b><br>
                Gibt Ihnen Auskunft darüber, ob sich das Grundstück in einem Natur-, Wasser- oder Landschaftsschutzgebiet befindet.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                7 bis 14 Tage, teilweise auch länger.
                <br><br>
                <b>Behördengebühr:</b><br>
                Die Gebühren betragen in der Regel zwischen 0 und 30 Euro pro Abfrage.
                <br>
                Bitte beachten Sie, dass es sich hierbei ausschließlich um Schätzwerte und keine verbindlichen Angaben handelt.
                `,
          },
          {
            id: 700,
            nameShort: "bauakes",
            name_long: "Bauakte - Essentiell (Genehmigungen, Pläne, Baubeschreibung, etc.)",
            name_show: null,
            starred: false,
            price: "documents_procurement_bauakte_essentiell",
            description: `
                <b>Info:</b><br>
                Eine Bauakte ist die Sammlung aller Unterlagen, die zu einem Bauvorhaben gehören, soweit sie für die Baugenehmigungsbehörde von Relevanz sind. Die Bauakte beinhaltet Bauanträge sowie -genehmigungen, Bauzeichnungen, Flächenberechnungen, usw.
                <br><br>
                <b>Inhalt (Benennung und Sortierung inklusive):</b><br>
                <ul>
                  <li>Bauantragsformular</li>
                  <li>Baugenehmigungsbescheid</li>
                  <li>Berechnungen (Flächen, Baukosten, etc.)</li>
                  <li>Pläne (Lageplan, Grundrisse, Ansichten, Schnitte)</li>
                  <li>Baubeschreibung</li>
                  <li>Ggf. Betriebsbeschreibung</li>
                </ul>
                <br>
                <b>Bereitstellungsdauer:</b><br>
                In der Regel erfolgt die Bereitstellung innerhalb von 1 bis 8 Wochen. In seltenen Fällen kann es zu Verzögerungen kommen, die über unsere Nachrichtenfunktion im Auftrag kommuniziert werden.
                <br><br>
                <b>Wichtige Hinweise:</b><br>
                Bitte beachten Sie, dass die <b>Vollständigkeit</b> der Unterlagen <b>nicht gewährleistet</b> werden kann und der Behörde in Ausnahmefällen Teile oder die gesamte Akte nicht vorliegen.
                <br><br>
                <b>Zusatzkosten:</b><br>
                In Einzelfällen dürfen keine Fotos aufgenommen werden, wodurch weitere <b>Kopierkosten entstehen können.</b> Der Service ist auf den Umfang von herkömmlichen <b>Wohnimmobilien beschränkt.</b> Bei erheblichem Mehraufwand (>3 Aktenordner, ca. 1000 Blatt) durch andere Gebäudeklassen oder komplexe Bauten erhalten Sie vorab einen Hinweis über den entstehenden Mehrkostenaufwand bzw. den Vorschlag einer Eingrenzung.
                `,
          },
          {
            id: 701,
            nameShort: "bauakos",
            name_long: "Bauakte - Standard (Vollständige Bauakte exkl. Statikunterlagen)",
            name_show: null,
            starred: false,
            price: "documents_procurement_bauakte_standard",
            description: `
                <b>Info:</b><br>
                Eine Bauakte ist die Sammlung aller Unterlagen, die zu einem Bauvorhaben gehören, soweit sie für die Baugenehmigungsbehörde von Relevanz sind. Die Bauakte beinhaltet Bauanträge sowie -genehmigungen, Bauzeichnungen, Flächenberechnungen, usw.
                <br><br>
                <b>Inhalt:</b><br>
                Enthalten ist die gesamte Bauakte exkl. Statikunterlagen.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                In der Regel erfolgt die Bereitstellung innerhalb von 1 bis 8 Wochen. In seltenen Fällen kann es zu Verzögerungen kommen, die über unsere Nachrichtenfunktion im Auftrag kommuniziert werden.
                <br><br>
                <b>Wichtige Hinweise:</b><br>
                Bitte beachten Sie, dass die <b>Vollständigkeit</b> der Unterlagen <b>nicht gewährleistet</b> werden kann und der Behörde in Ausnahmefällen Teile oder die gesamte Akte nicht vorliegen. In Einzelfällen dürfen keine Fotos aufgenommen werden, wodurch weitere <b>Kopierkosten entstehen können.</b>
                <br><br>
                <b>Zusatzkosten:</b><br>
                In Einzelfällen dürfen keine Fotos aufgenommen werden, wodurch weitere <b>Kopierkosten entstehen können.</b> Der Service ist auf den Umfang von herkömmlichen <b>Wohnimmobilien beschränkt.</b> Bei erheblichem Mehraufwand (>3 Aktenordner, ca. 1000 Blatt) durch andere Gebäudeklassen oder komplexe Bauten erhalten Sie vorab einen Hinweis über den entstehenden Mehrkostenaufwand bzw. den Vorschlag einer Eingrenzung.
                `,
          },
          {
            id: 702,
            nameShort: "bauakms",
            name_long: "Bauakte - Premium (Vollständige Bauakte inkl. Statikunterlagen)",
            name_show: null,
            starred: false,
            price: "documents_procurement_bauakte_premium",
            description: `
                <b>Info:</b><br>
                Eine Bauakte ist die Sammlung aller Unterlagen, die zu einem Bauvorhaben gehören, soweit sie für die Baugenehmigungsbehörde von Relevanz sind. Die Bauakte beinhaltet Bauanträge sowie -genehmigungen, Bauzeichnungen, Flächenberechnungen, usw.
                <br><br>
                <b>Inhalt:</b><br>
                Enthalten ist die gesamte Bauakte inkl. Statikunterlagen.
                <br><br>
                <b>Bereitstellungsdauer:</b><br>
                In der Regel erfolgt die Bereitstellung innerhalb von 1 bis 8 Wochen. In seltenen Fällen kann es zu Verzögerungen kommen, die über unsere Nachrichtenfunktion im Auftrag kommuniziert werden.
                <br><br>
                <b>Wichtige Hinweise:</b><br>
                Bitte beachten Sie, dass die <b>Vollständigkeit</b> der Unterlagen <b>nicht gewährleistet</b> werden kann und der Behörde in Ausnahmefällen Teile oder die gesamte Akte nicht vorliegen. In Einzelfällen dürfen keine Fotos aufgenommen werden, wodurch weitere <b>Kopierkosten entstehen können.</b>
                <br><br>
                <b>Zusatzkosten:</b><br>
                In Einzelfällen dürfen keine Fotos aufgenommen werden, wodurch weitere <b>Kopierkosten entstehen können.</b> Der Service ist auf den Umfang von herkömmlichen <b>Wohnimmobilien beschränkt.</b> Bei erheblichem Mehraufwand (>3 Aktenordner, ca. 1000 Blatt) durch andere Gebäudeklassen oder komplexe Bauten erhalten Sie vorab einen Hinweis über den entstehenden Mehrkostenaufwand bzw. den Vorschlag einer Eingrenzung.
                `,
          },
        ],
        service_image: "service_images/Behordenauskunfte.svg",
      },
    ],
    packageDescription:
      '<p>Lästige Behördengänge gehören mit unseren <span style="color: #22BCA4"><strong>Behördenauskünften</strong></span> der Vergangenheit an! Alle für die Vermarktung erforderlichen Dokumente können Sie mit ein paar Klicks bestellen und sparen so im Schnitt 27 Stunden pro Projekt ein. <b>Kein Kontakt mit Ämtern - wir kümmern uns um alles</b>.</p><p>Einfach Basisinformationen angeben, Vollmacht hochladen und Ergebnisse durchschnittlich nach 2 Wochen erhalten.</p>',
    packageLink: "https://www.imogent.de/behoerdenauskuenfte",
    packageButtonText: "Mehr Informationen",
  },
  {
    displayPosition: 6,
    id: OrderTypeEnum.Drone_Media,
    packages: [
      {
        addUnit: "Foto",
        customerSpecificInfos: {
          OZthUnBReNW1F9Yqvai0:
            "<p><b>Basis Paket</b><br><ul><li>10 hochauflösende Luftaufnahmen aus verschiedenen Perspektiven inkl. Retusche</li><b>ODER</b><li>1 x 360 Drohnenpanorama inkl. Stitching , Hosting und 10 POI</li></ul></p>" +
            "<p><b>Panorama Paket</b><br><ul><li>10 hochauflösende Luftaufnahmen aus verschiedenen Perspektiven inkl. Retusche</li><b>UND</b><li>1 x 360 Drohnenpanorama inkl. Stitching , Hosting und 10 POI</li></ul></p>",
          M5IYpTepB9g0b9P74poO:
            "<p><b>Basis Paket</b><br><ul><li>10 hochauflösende Luftaufnahmen aus verschiedenen Perspektiven inkl. Retusche</li><b>ODER</b><li>1 x 360 Drohnenpanorama inkl. Stitching , Hosting und 10 POI</li></ul></p>" +
            "<p><b>Panorama Paket</b><br><ul><li>10 hochauflösende Luftaufnahmen aus verschiedenen Perspektiven inkl. Retusche</li><b>UND</b><li>1 x 360 Drohnenpanorama inkl. Stitching , Hosting und 10 POI</li></ul></p>",
        },
        customerSpecificServiceProviderInfos: {
          OZthUnBReNW1F9Yqvai0:
            "<p><b>Terminvereinbarung</b><br>Sobald\n" +
            "Sie den Buchungsvorgang abgeschlossen haben, prüfen wir die Verfügbarkeit unserer Piloten sowie " +
            "den Luftraum. Sie erhalten innerhalb der nächsten 48h eine Rückmeldung zum Bearbeitungsstatus über " +
            "unsere Kommentarfunktion und werden bei Verfügbarkeit direkt von einem unserer Dienstleister kontaktiert.</p>" +
            "<p><b>Zusatzkosten:</b><br>Bitte " +
            "beachten Sie, dass in einigen Gebieten Zusatzkosten anfallen können. Kosten bis 50,00€ werden ohne " +
            "weitere Rücksprache Ihrem Auftrag hinzugefügt. Zusatzkosten kommen nur in Flugverbotszonen vor.</p>" +
            "<p><b>Stornierungsbedingungen</b><br>" +
            "Drohnenaufnahmen " +
            "sind lediglich innerhalb der ersten zwei Stunden nach Bestellung kostenlos stornierbar. " +
            "Weitere Infos finden Sie in der linken Seitenleiste.</p>",
          M5IYpTepB9g0b9P74poO:
            "<p><b>Terminvereinbarung</b><br>Sobald\n" +
            "Sie den Buchungsvorgang abgeschlossen haben, prüfen wir die Verfügbarkeit unserer Piloten sowie " +
            "den Luftraum. Sie erhalten innerhalb der nächsten 48h eine Rückmeldung zum Bearbeitungsstatus über " +
            "unsere Kommentarfunktion und werden bei Verfügbarkeit direkt von einem unserer Dienstleister kontaktiert.</p>" +
            "<p><b>Zusatzkosten:</b><br>Bitte " +
            "beachten Sie, dass in einigen Gebieten Zusatzkosten anfallen können. Kosten bis 50,00€ werden ohne " +
            "weitere Rücksprache Ihrem Auftrag hinzugefügt. Zusatzkosten kommen nur in Flugverbotszonen vor.</p>" +
            "<p><b>Stornierungsbedingungen</b><br>" +
            "Drohnenaufnahmen " +
            "sind lediglich innerhalb der ersten zwei Stunden nach Bestellung kostenlos stornierbar. " +
            "Weitere Infos finden Sie in der linken Seitenleiste.</p>",
        },
        defaultQuantity: 10,
        details: [
          {
            label: "Nachbearbeitung",
            value:
              "In der Nachbearbeitung werden Helligkeit, Farbgebung und Winkel des Bildes optimal angepasst. Weitere Spezifikationen können im Bestellprozess ergänzt werden.",
          },
          {
            label: "Aufnahmedauer",
            value:
              'Die durchschnittliche Aufnahmedauer vor Ort ist <a href="https://www.imogent.de/dauer-aufnahmen" target="_blank">hier</a> einsehbar. Bitte planen Sie genug Zeit ein, damit vor Ort ein optimales Ergebnis erzielt werden kann.',
          },
          {
            label: "Bearbeitungszeiten",
            value:
              'In der Regel finden wir innerhalb von zwei Werktagen einen geeigneten Dienstleister für Sie. Die Bearbeitungszeiten für die Nachbearbeitung sind <a href="https://www.imogent.de/bearbeitungszeiten/" target="_blank">hier</a> einsehbar.',
          },
          {
            label: "Zusatzkosten",
            value:
              "Um in bestimmten Gebieten aufsteigen zu dürfen, ist die Beantragung einer Genehmigung bei der zuständigen Luftfahrtbehörde erforderlich. Hierbei können Kosten zwischen 50,00€ und 200,00€ entstehen. Sollte dies bei der von Ihnen angegebenen Adresse der Fall sein, informieren wir Sie vorab über die zusätzlichen Kosten. Sie haben dann die Möglichkeit den Auftrag zu stornieren. Bei einem Betrag von 50,00€ oder weniger behalten wir uns jedoch vor diesen ohne Rücksprache Ihrem Auftrag hinzuzufügen. Bitte beachten Sie außerdem, dass die Beantragung einer Genehmigung einige Tage in Anspruch nehmen kann.",
          },
          {
            label: "Stornierungsbedigungen",
            value: cancellationPolicyPhotographyProducts,
          },
          {
            label: "Ausgabeformat",
            value:
              "Sie erhalten die Fotos im JPG Format und haben die Möglichkeit diese in unterschiedlichen Auflösungen herunterzuladen.",
          },
        ],
        dateArrangementRequired: true,
        disabled: false,
        infoMessages: [
          {
            label: "Terminvereinbarung",
            value:
              "Sobald Sie den Buchungsvorgang abgeschlossen haben, prüfen wir die Verfügbarkeit unserer Fotografen. Sie erhalten innerhalb von zwei Werktagen eine Rückmeldung zum Bearbeitungsstatus und die angegebene Kontaktperson wird bei Verfügbarkeit umgehend kontaktiert.",
          },
          {
            label: "Stornierung",
            value:
              "Bitte beachten Sie unsere Stornierungsbedingungen in der linken Seitenleiste. Drohnenaufnahmen sind lediglich innerhalb der ersten Stunde nach Bestellung kostenlos stornierbar.",
          },
          {
            label: "Zusatzkosten",
            value:
              "Bitte beachten Sie, dass in einigen Gebieten Zusatzkosten für Aufstiegsgenehmigungen anfallen können. Kosten bis 50,00€ werden ohne weitere Rücksprache Ihrem Auftrag hinzugefügt. Zusatzkosten kommen nur in Flugverbotszonen vor. Die Einholung einer Genehmigung kann mehrere Tage in Anspruch nehmen. Rechnen Sie somit genügend Vorlaufzeit ein, sollten die Aufnahmen zu einem festen Termin gewünscht sein.",
          },
        ],
        name: "Fotos",
        newProperty: true,
        numberOfAdditionalPictures: 3,
        numberOfDrafts: 0,
        packageDesc: "aus verschiedenen Perspektiven",
        packageDescHeading: "Professionelle Fotos aus der Luft",
        package_contents: ["Professionelle Luftaufnahmen", "Aussagekräftige Perspektiven", "Inklusive Nachbearbeitung"],
        package_id: "drone_photos",
        package_images: ["Drohnenaufnahmen.jpg"],
        package_icons: ["Drohnenaufnahmen.svg"],
        package_images_companyId: {
          OZthUnBReNW1F9Yqvai0: ["fairfleet.JPG"],
          M5IYpTepB9g0b9P74poO: ["fairfleet.JPG"],
        },
        package_key: "2",
        panorama_available_company_ids: ["M5IYpTepB9g0b9P74poO", "OZthUnBReNW1F9Yqvai0"],
        panorama_price: ["dronemedia_panorama_only", "dronemedia_additional_panorama"],
        physicalProduct: false,
        price: "dronemedia_photo_price",
        photo_price: {
          0: ["dronemedia_photo_add_5_price", "dronemedia_photo_add_10_price", "dronemedia_photo_no_photo"],
          2: ["dronemedia_photo_add_10_price", "dronemedia_photo_add_20_price", "dronemedia_photo_no_photo"],
        },
        // 'priceAddUnits': 'dronemedia_photo_addUnits',
        extra_feedback_price: "dronemedia_photo_extra_feedback",
        extra_feedback_price_retouching: "dronemedia_photo_extra_feedback_price_retouching",
        feedbacks_included: -1,
        price_note: "(Preis exkl. MwSt.)",
        retouchingForbiddenCompanyIds: [],
        service_key: OrderTypeEnum.Drone_Media,
        service_title: "Luftaufnahmen",
        title: "Luftaufnahmen",
        service_image: "service_images/Luftaufnahmen.svg",
      },
      {
        background_music: [
          {
            id: 1,
            title: "Titel 1",
            src: "assets/audio/video_tour/Titel-01_Beautiful-Dream-by-Valdi-Sabev-Lets-Play.mp3",
          },
          {
            id: 2,
            title: "Titel 2",
            src: "assets/audio/video_tour/Titel-02_Call-On-Me-by-Kick-Lee.mp3",
          },
          {
            id: 3,
            title: "Titel 3",
            src: "assets/audio/video_tour/Titel-03_forever_by_ethan-rank.mp3",
          },
          {
            id: 4,
            title: "Titel 4",
            src: "assets/audio/video_tour/Titel-04_hey-now_by_campagna.mp3",
          },
          {
            id: 5,
            title: "Titel 5",
            src: "assets/audio/video_tour/Titel-05_jester-acoustic-version_by_ian-post.mp3",
          },
          {
            id: 6,
            title: "Titel 6",
            src: "assets/audio/video_tour/Titel-06_paper-planes_by_paper-planes.mp3",
          },
          {
            id: 7,
            title: "Titel 7",
            src: "assets/audio/video_tour/Titel-07_the-language-of-flowers_by_rew.mp3",
          },
          {
            id: 8,
            title: "Titel 8",
            src: "assets/audio/video_tour/Titel-08_then-i-came_by_ziv-moran.mp3",
          },
          {
            id: 9,
            title: "Titel 9",
            src: "assets/audio/video_tour/Titel-09_timeless_by_buzo.mp3",
          },
        ],
        helpTexts: {
          logo: {
            label: "Logo einfügen",
            text: "Bitte geben Sie die gewünschte Position des Logos an. Es ist auch möglich, mehrere Positionen zu kombinieren.",
          },
          blurred: {
            label: "Weichzeichnen",
            text: "Zur Bewahrung der Persönlichkeitsrechte wird bei Luftaufnahmen die Umgebung unkenntlich gemacht. Vertrauliche Details wie Dokumente oder Fotos werden im Video ebenfalls weichgezeichnet.",
          },
          backgroundMusic: {
            label: "Hintergrundmusik",
            text: "Das Video kann nach Wahl mit Musik hinterlegt werden. Bitte wählen Sie hier den gewünschten Titel.",
          },
          title: {
            label: "Titel wählen",
            text:
              "<p>Bitte wählen Sie den Titel des Videos.</p><p>Beispiel Haupttitel:<br><strong>3-Zimmer-Wohnung in Bielefeld</strong></p><p>Beispiel Nebentitel<br><strong>" +
              "Große Terrasse und direkter Zugang zum weitläufigen Garten – optimal für junge Familien.</strong></p>",
          },
          roomName: {
            label: "Raumbezeichnungen",
            text: "Während des Rundgangs werden die jeweiligen Raumbezeichnungen eingeblendet. Bitte laden Sie hierzu einen Grundriss mit den gewünschten Bezeichnungen hoch.",
          },
        },
        additional_video_link_price: "dronemedia_video_additional_video_link_price",
        addUnit: "Sekunden",
        defaultQuantity: 45,
        details: [
          {
            label: "Nachbearbeitung",
            value:
              "In der Nachbearbeitung wird das Video geschnitten und es werden die Helligkeit sowie die Farbgebung der Aufnahmen angepasst. Weitere Spezifikationen können im Bestellprozess ergänzt werden.",
          },
          {
            label: "Aufnahmedauer",
            value:
              'Die durchschnittliche Aufnahmedauer vor Ort ist <a href="https://www.imogent.de/dauer-aufnahmen" target="_blank">hier</a> einsehbar.  Bitte planen Sie genug Zeit ein, damit vor Ort ein optimales Ergebnis erzielt werden kann.',
          },
          {
            label: "Bearbeitungszeiten",
            value:
              'In der Regel finden wir innerhalb von zwei Werktagen einen geeigneten Dienstleister für Sie. Die Bearbeitungszeiten für die Nachbearbeitung sind <a href="https://www.imogent.de/bearbeitungszeiten/" target="_blank">hier</a> einsehbar.',
          },
          {
            label: "Zusatzkosten",
            value:
              "Um in bestimmten Gebieten aufsteigen zu dürfen, ist die Beantragung einer Genehmigung bei der zuständigen Luftfahrtbehörde erforderlich. Hierbei können Kosten zwischen 50,00€ und 200,00€ entstehen. Sollte dies bei der von Ihnen angegebenen Adresse der Fall sein, informieren wir Sie vorab über die zusätzlichen Kosten. Sie haben dann die Möglichkeit den Auftrag zu stornieren. Bei einem Betrag von 50,00€ oder weniger behalten wir uns jedoch vor diesen ohne Rücksprache Ihrem Auftrag hinzuzufügen. Bitte beachten Sie außerdem, dass die Beantragung einer Genehmigung einige Tage in Anspruch nehmen kann.",
          },
          {
            label: "Stornierungsbedigungen",
            value: cancellationPolicyPhotographyProducts,
          },
          {
            label: "Ausgabeformat",
            value:
              "Das Video wird in zwei Versionen zur Verfügung gestellt. Sonderwünsche müssen im Kommentarfeld angegeben werden.<br><strong>1920x1080p</strong> (max. 300 MB) <strong>MP4</strong><br><strong>1280x720p</strong> (max. 15 MB) <strong>MP4</strong>",
          },
        ],
        dateArrangementRequired: true,
        disabled: false,
        extra_feedback_price_retouching: "dronemedia_video_extra_feedback_price_retouching",
        feedbacks_included_retouching: 1,
        infoMessages: [
          {
            label: "Terminvereinbarung",
            value:
              "Sobald Sie den Buchungsvorgang abgeschlossen haben, prüfen wir die Verfügbarkeit unserer Fotografen. Sie erhalten innerhalb von zwei Werktagen eine Rückmeldung zum Bearbeitungsstatus und die angegebene Kontaktperson wird bei Verfügbarkeit umgehend kontaktiert.",
          },
          {
            label: "Stornierung",
            value:
              "Bitte beachten Sie unsere Stornierungsbedingungen in der linken Seitenleiste. Drohnenaufnahmen sind lediglich innerhalb der ersten Stunde nach Bestellung kostenlos stornierbar.",
          },
          {
            label: "Zusatzkosten",
            value:
              "Bitte beachten Sie, dass in einigen Gebieten Zusatzkosten für Aufstiegsgenehmigungen anfallen können. Kosten bis 50,00€ werden ohne weitere Rücksprache Ihrem Auftrag hinzugefügt. Zusatzkosten kommen nur in Flugverbotszonen vor. Die Einholung einer Genehmigung kann mehrere Tage in Anspruch nehmen. Rechnen Sie somit genügend Vorlaufzeit ein, sollten die Aufnahmen zu einem festen Termin gewünscht sein.",
          },
        ],
        maximalFileUploadNumber: 10,
        name: "Videos",
        newProperty: true,
        numberOfDrafts: 0,
        packageDesc: "Zusammenschnitt von verschiedenen Videosequenzen zu einem Video",
        package_contents: ["Flugvorbereitung", "Videoaufnahmen", "Professioneller Videoschnitt"],
        package_id: "drone_video",
        package_images: ["Drohnenaufnahmen_Video.jpg"],
        package_icons: ["Drohnenaufnahmen_Video.svg"],
        package_images_companyId: {
          OZthUnBReNW1F9Yqvai0: ["fairfleet_video.JPG"],
          M5IYpTepB9g0b9P74poO: ["fairfleet_video.JPG"],
        },
        package_key: "1",
        packageDescHeading: "Professionelle Luftaufnahmen",
        retouchingForbiddenCompanyIds: [],
        physicalProduct: false,
        price: "dronemedia_video_price",
        photo_price: {
          1: ["dronemedia_video_add_15_price", "dronemedia_video_add_45_price"],
          3: ["dronemedia_video_add_15_price"],
        },
        // 'priceAddUnits': 'dronemedia_video_addUnits',
        extra_feedback_price: "dronemedia_video_extra_feedback",
        feedbacks_included: -1,
        price_note: "(Preis exkl. MwSt.)",
        service_key: OrderTypeEnum.Drone_Media,
        service_title: "Luftaufnahmen",
        title: "Luftaufnahmen",
        service_image: "service_images/Luftaufnahmen.svg",
      },
      {
        disabled: true,
        name: "Pro",
        newProperty: true,
        package_contents: ["Inhalte Extended", "Videoaufnahmen", "Zusammenschnitt Video"],
        package_id: "drone_video_pro",
        package_images: ["Drohnenaufnahmen_komplett.jpg"],
        package_icons: [],
        package_key: "3",
        physicalProduct: false,
        price: "364,00",
        price_note: "(Preis exkl. MwSt.)",
        service_key: OrderTypeEnum.Drone_Media,
        service_title: "Luftaufnahmen",
        title: "Luftaufnahmen",
        service_image: "service_images/Luftaufnahmen.svg",
      },
    ],
    packageDescription:
      '<p><span style="color: #22BCA4"><strong>Luftaufnahmen</strong></span> ermöglichen Ihren Interessenten die beste Möglichkeit, die <b>direkte Umgebung zu erleben</b> und das komplette Gebäude wahrzunehmen. Durch die neuartige Perspektive <b>schaffen Sie Aha-Momente</b> und steigern durch diesen Service darüber hinaus den Wiedererkennungswert Ihres Unternehmens und Ihrer Immobilien.</p><p>Unser deutschlandweites Netzwerk an Spezialisten für Drohnen-Fotografien steht Ihnen in ein paar Klicks zur Verfügung und <b>wir kümmern uns um den Rest</b>.</p><p><i>Beliebte Produktkombinationen:</i> Videotour, Fotografie, Digital Staging</p>',
    packageLink: "https://www.imogent.de/fotografie",
  },
  {
    displayPosition: 4,
    id: OrderTypeEnum.Floor_Overview,
    packages: [
      {
        companies: ["^dummy_not_available"],
        details: [
          {
            label: "Notwendige Unterlagen",
            value: "Bemaßte Grundrisse <br/> Bemaßte Ansichtszeichnungen ",
          },
          {
            label: "Ausgabeformat",
            value:
              "Format: PNG, JPEG <br/> Auflösung: HD, 100 dpi <br/><br/>Sollten Sie SVG oder andere Formate benötigen, kontaktieren Sie uns bitte im Vorhinein.",
          },
          {
            label: "360°-Rundum Blick",
            value:
              "Die Ansichten werden für Sie in einer Online-Anwendung bereitgestellt, in der sich das Objekt über einen Schieber um 360° drehen lässt. Sie erhalten den Zugang zu dem Link, sobald Sie den Auftrag final akzeptiert haben. Die Anwendung lässt sich über einen i-Frame problemlos in Ihre Website einbinden.",
          },
          {
            label: "Wohnungsfinder",
            value:
              "Die Ansichten werden für Sie in einer Online-Anwendung bereitgestellt. Zur Erstellung des Wohnungsfinders nehmen wir Kontakt zu Ihnen auf,  sobald Sie den Auftrag final akzeptiert haben. Die Anwendung lässt sich über einen i-Frame problemlos in Ihre Website einbinden.",
          },
          {
            label: "Farbauswahl",
            value:
              "Bei der Farbauswahl haben Sie die Möglichkeit zwischen drei Farben zu wählen. <br/><br/><ol><li>Farbe der markierten Wohnung: Hier sind Farben passend zu dem Designkonzept des Projektes zu empfehlen</li><li>Farbe des Untergrundes: Hier sind Grüntöne zu empfehlen</li><li>Farbe des Gebäudekörpers: Hier ist die Farbe weiß zu empfehlen.</li></ol>",
          },
          {
            label: "Straßenreferenz",
            value:
              "Anhand der eingereichten Pläne können Straßenreferenzen und Straßennamen eingezeichnet werden. Die Straßenreferenz ist sehr zu empfehlen, da diese dem Kunden erheblich bei der Orientierung hilft.",
          },
          {
            label: "Nordfpeil",
            value:
              "Anhand der eingereichten Pläne kann ein Nordpfeil übernommen werden. Der Nordpfeil ist sehr zu empfehlen, da dieser dem Kunden erheblich bei der Orientierung hilft.",
          },
        ],
        disabled: true,
        example_flatfinder: "https://www.imogent.de/wohnungsfinder/",
        example_rundumblick: "https://www.imogent.de/wohnungsfinder/",
        extra_details: "",
        extra_feedback_price: "floor_overview_wohnungsfinder_extra_feedback",
        feedbacks_included: 1,
        name: "Wohnungsfinder",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Feature 1", "Feature 2", "Feature 3"],
        package_id: "flat_finder",
        package_images: ["flat_finder/flat_finder/premium.jpg", "flat_finder/flat_finder/standard.jpg"],
        package_icons: ["flat_finder/flat_finder/Wohnungsfinder.svg"],
        package_key: "15",
        packageDescHeading: "Wohnungsfinder",
        physicalProduct: false,
        price: "floor_overview_wohnungsfinder_price",
        price_pre_text: "ab",
        service_image: "service_images/Wohnungsfinder.svg",
        service_key: OrderTypeEnum.Floor_Overview,
        service_title: "Wohnungsfinder",
        styles: [
          {
            styleUrl: "../assets/images/flat_finder/flat_finder/premium.jpg",
            rundumsBlickUrl: "../assets/images/flat_finder/premium_360.gif",
            flatFinderUrl: "../assets/images/flat_finder/premium_flatfinder.gif",
            index: 0,
            name: "Standard",
            flat_price: "floor_overview_wohnungsfinder_standard_flat_price",
            building_price: "floor_overview_wohnungsfinder_standard_building_price",
            rundumsicht_price: "floor_overview_wohnungsfinder_standard_rundumsicht_price",
            einbindung_price: "floor_overview_wohnungsfinder_standard_einbindung_price",
          },
          {
            styleUrl: "../assets/images/flat_finder/flat_finder/premium.jpg",
            rundumsBlickUrl: "../assets/images/flat_finder/premium_360.gif",
            flatFinderUrl: "../assets/images/flat_finder/premium_flatfinder.gif",
            index: 1,
            name: "Premium",
            flat_price: "floor_overview_wohnungsfinder_premium_flat_price",
            building_price: "floor_overview_wohnungsfinder_premium_building_price",
            rundumsicht_price: "floor_overview_wohnungsfinder_premium_rundumsicht_price",
            einbindung_price: "floor_overview_wohnungsfinder_premium_einbindung_price",
          },
        ],
        title: "Wohnungsfinder",
      },
      {
        companies: ["^dummy_not_available"],
        details: [
          {
            label: "Notwendige Unterlagen",
            value: "Bemaßte Grundrisse <br/> Bemaßte Ansichtszeichnungen ",
          },
          {
            label: "Ausgabeformat",
            value:
              "Format: PNG, JPEG <br/> Auflösung: HD, 100 dpi <br/><br/>Sollten Sie SVG oder andere Formate benötigen, kontaktieren Sie uns bitte im Vorhinein. ",
          },
          {
            label: "360°-Rundum Blick",
            value:
              "Die Ansichten werden für Sie in einer Online-Anwendung bereitgestellt, in der sich das Objekt über einen Schieber um 360° drehen lässt. Sie erhalten den Zugang zu dem Link, sobald Sie den Auftrag final akzeptiert haben. Die Anwendung lässt sich über einen i-Frame problemlos in Ihre Website einbinden.",
          },
          {
            label: "Farbauswahl",
            value:
              "Bei der Farbauswahl haben Sie die Möglichkeit zwischen drei Farben zu wählen. <br/><br/><ol><li>Farbe des Untergrundes: Hier sind Grüntöne zu empfehlen</li><li>Farbe des Gebäudekörpers: Hier ist die Farbe weiß zu empfehlen.</li></ol>",
          },
          {
            label: "Straßenreferenz",
            value:
              "Anhand der eingereichten Pläne können Straßenreferenzen und Straßennamen eingezeichnet werden. Die Straßenreferenz ist sehr zu empfehlen, da diese dem Kunden erheblich bei der Orientierung hilft.",
          },
          {
            label: "Nordfpeil",
            value:
              "Anhand der eingereichten Pläne kann ein Nordpfeil übernommen werden. Der Nordpfeil ist sehr zu empfehlen, da dieser dem Kunden erheblich bei der Orientierung hilft.",
          },
        ],
        disabled: true,
        example_flatfinder: "https://www.imogent.de/produktseite-Wohnungsfinder/",
        example_rundumblick: "https://www.imogent.de/produktseite-Wohnungsfinder/",
        extra_details: "",
        extra_feedback_price: "floor_overview_360_extra_feedback",
        feedbacks_included: 2,
        name: "360°-Rundumsicht",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Feature 1", "Feature 2", "Feature 3"],
        package_id: "flat_finder_360",
        package_images: ["flat_finder/flat_finder_360/standard.gif", "flat_finder/flat_finder_360/premium.gif"],
        package_icons: ["flat_finder/flat_finder_360/360-grad-Rundumsicht.svg"],
        package_key: "27",
        packageDescHeading: "Etagenübersichten - 360°-Rundumsicht",
        physicalProduct: false,
        price: "floor_overview_360_price",
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Floor_Overview,
        service_title: "Wohnungsfinder",
        service_image: "service_images/Wohnungsfinder.svg",
        styles: [
          {
            styleUrl: "../assets/images/flat_finder/flat_finder_360/standard.jpg",
            rundumsBlickUrl: "../assets/images/flat_finder/standard_360.gif",
            flatFinderUrl: "../assets/images/flat_finder/standard_flatfinder.gif",
            index: 0,
            name: "Standard",
            flat_price: "floor_overview_360_standard_flat_price",
            building_price: "floor_overview_360_standard_building_price",
            rundumsicht_price: "floor_overview_360_standard_rundumsicht_price",
            einbindung_price: "floor_overview_360_standard_einbindung_price",
          },
          {
            styleUrl: "../assets/images/flat_finder/flat_finder_360/premium.jpg",
            rundumsBlickUrl: "../assets/images/flat_finder/premium_360.gif",
            flatFinderUrl: "../assets/images/flat_finder/premium_flatfinder.gif",
            index: 1,
            name: "Premium",
            flat_price: "floor_overview_360_premium_flat_price",
            building_price: "floor_overview_360_premium_building_price",
            rundumsicht_price: "floor_overview_360_premium_rundumsicht_price",
            einbindung_price: "floor_overview_360_premium_einbindung_price",
          },
        ],
        title: "Etagenübersichten",
      },
      {
        companies: ["^dummy_not_available"],
        details: [
          {
            label: "Notwendige Unterlagen",
            value: "Bemaßte Grundrisse <br/> Bemaßte Ansichtszeichnungen ",
          },
          {
            label: "Ausgabeformat",
            value:
              "Format: PNG, JPEG <br/> Auflösung: HD, 100 dpi <br/><br/>Sollten Sie SVG oder andere Formate benötigen, kontaktieren Sie uns bitte im Vorhinein.",
          },
          {
            label: "360°-Rundum Blick",
            value:
              "Die Ansichten werden für Sie in einer Online-Anwendung bereitgestellt, in der sich das Objekt über einen Schieber um 360° drehen lässt. Sie erhalten den Zugang zu dem Link, sobald Sie den Auftrag final akzeptiert haben. Die Anwendung lässt sich über einen i-Frame problemlos in Ihre Website einbinden.",
          },
          {
            label: "Spacefinder",
            value:
              "Die Ansichten werden für Sie in einer Online-Anwendung bereitgestellt. Zur Erstellung des Spacefinders nehmen wir Kontakt zu Ihnen auf,  sobald Sie den Auftrag final akzeptiert haben. Die Anwendung lässt sich über einen i-Frame problemlos in Ihre Website einbinden.",
          },
          {
            label: "Farbauswahl",
            value:
              "Bei der Farbauswahl haben Sie die Möglichkeit zwischen drei Farben zu wählen. <br/><br/><ol><li>Farbe der markierten Wohnung: Hier sind Farben passend zu dem Designkonzept des Projektes zu empfehlen</li><li>Farbe des Untergrundes: Hier sind Grüntöne zu empfehlen</li><li>Farbe des Gebäudekörpers: Hier ist die Farbe weiß zu empfehlen.</li></ol>",
          },
          {
            label: "Straßenreferenz",
            value:
              "Anhand der eingereichten Pläne können Straßenreferenzen und Straßennamen eingezeichnet werden. Die Straßenreferenz ist sehr zu empfehlen, da diese dem Kunden erheblich bei der Orientierung hilft.",
          },
          {
            label: "Nordfpeil",
            value:
              "Anhand der eingereichten Pläne kann ein Nordpfeil übernommen werden. Der Nordpfeil ist sehr zu empfehlen, da dieser dem Kunden erheblich bei der Orientierung hilft.",
          },
        ],
        disabled: true,
        example_flatfinder: "https://expose.imogent.com/expose_viewer.php?id=405",
        example_rundumblick: "https://expose.imogent.com/expose_viewer.php?id=404",
        extra_details: "",
        extra_feedback_price: "floor_overview_wohnungsfinder_extra_feedback",
        feedbacks_included: 1,
        name: "Spacefinder",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Feature 1", "Feature 2", "Feature 3"],
        package_id: "flat_finder",
        package_images: ["flat_finder/space_finder/Premium_1.jpg", "flat_finder/flat_finder/Premium_0.jpg"],
        package_icons: [],
        package_key: "49",
        packageDescHeading: "Spacefinder",
        physicalProduct: false,
        price: "floor_overview_wohnungsfinder_price",
        price_pre_text: "ab",
        service_image: "service_images/Wohnungsfinder.svg",
        service_key: OrderTypeEnum.Floor_Overview,
        service_title: "Spacefinder",
        styles: [
          {
            styleUrl: "../assets/images/flat_finder/space_finder/Standard_1.jpg",
            rundumsBlickUrl: "../assets/images/flat_finder/space_finder/Spacefinder-Turntable-Standard.gif",
            flatFinderUrl: "../assets/images/flat_finder/space_finder/Spacefinder-Standard.gif",
            index: 0,
            name: "Standard",
            flat_price: "floor_overview_wohnungsfinder_standard_flat_price",
            building_price: "floor_overview_wohnungsfinder_standard_building_price",
            rundumsicht_price: "floor_overview_wohnungsfinder_standard_rundumsicht_price",
            einbindung_price: "floor_overview_wohnungsfinder_standard_einbindung_price",
          },
          {
            styleUrl: "../assets/images/flat_finder/space_finder/Premium_1.jpg",
            rundumsBlickUrl: "../assets/images/flat_finder/space_finder/Spacefinder-Turntable-Premium.gif",
            flatFinderUrl: "../assets/images/flat_finder/space_finder/Spacefinder-Premium.gif",
            index: 1,
            name: "Premium",
            flat_price: "floor_overview_wohnungsfinder_premium_flat_price",
            building_price: "floor_overview_wohnungsfinder_premium_building_price",
            rundumsicht_price: "floor_overview_wohnungsfinder_premium_rundumsicht_price",
            einbindung_price: "floor_overview_wohnungsfinder_premium_einbindung_price",
          },
        ],
        title: "Spacefinder",
      },
    ],
    packageDescription:
      '<p>Der <span style="color: #22BCA4"><strong>Wohnungsfinder</strong></span> gibt Ihren Interessenten in einem 3D-Modell einen genauen Überblick über die Positionen der geplanten Wohnungen. Die Ergebnisse können <b>ganz unkompliziert auf der Objekthomepage eingebunden</b> werden, ermöglichen so eine Orientierung im Gebäude und unterstützen das Raumgefühl maßgeblich.</p><p>Wählen Sie den Wohnungsfinder oder die Variante mit 360°-Rundumsicht und schaffen Sie ein einmaliges Erlebnis für Ihre Immobilie über das Ihre Kunden sprechen werden.</p><p><i>Beliebte Produktkombinationen:</i> Visualisierungen, Grundrisse</p>',
    packageLink: "https://www.imogent.de/wohnungsfinder",
  },
  {
    displayPosition: 1,
    id: OrderTypeEnum.Floor_Plan,
    packages: [
      {
        delivery_time: "24 h",
        details: [
          {
            label: "Grundrissdesign",
            value:
              "Die Grundrissdesigns sind nicht änderbar. Sollten Sie dennoch Änderungswünsche bzgl. Böden, Wänden oder Einrichtungsdesign haben, geben Sie diese im Kommentarfeld an. Wir werden versuchen diese umzusetzen. Nachträgliche Anpassungen sind nur gegen Aufpreis von 10,00€ pro Grundriss möglich.",
          },
          {
            label: "Anpassungen",
            value:
              "Änderungen im Grundriss können händisch oder mit Hilfe digitaler Tools eingezeichnet werden. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.",
          },
          {
            label: "Zusatzkosten",
            value:
              "<p>Der Preis pro Grundriss gilt für Grundrisse bis 200m². Dabei sind Balkon- und Terrassenflächen mit berücksichtigt. Bei größeren Grundrissen bzw. weiteren Etagen multipliziert sich der Stückpreis entsprechend.</p><p>Die erste Feedbackschleife ist für Sie kostenfrei, sofern es sich um unten genannte, kostenfreie Änderungen handelt. Die aufgeführten, kostenpflichtigen Änderungen, werden bereits in der ersten Korrekturtschleife berechnet. Alle Änderungen ab der zweiten Korrekturschleife sind kostenpflichtig. Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.</p><p>Kostenfreie Änderungen: <ul><li>Textänderungen</li><li>Entfernen oder hinzufügen von einzelnen Möbelstücken oder Dekoelementen</li><li>Entfernen oder hinzufügen von kostenfreien Spezifikationen (Raumbezeichnungen, Logo etc.)</li><li>Strukturelle Änderungen, die keine Gesamtveränderung  des Grundrisses darstellen</li></ul></p><p>Kostenpflichtige Änderungen: <ul><li>Nachträgliches Hinzufügen kostenpflichtiger Spezifikationen (Möblierung, maßstabsgetreue Zeichnung)</li><li>Änderung der Raumfunktionen</li></ul></p><p>Änderungen, die eine erneute Aufgabe der Bestellung erfordern: <ul><li>Darstellung des Grundrisses in einem anderen Layout</li><li>Änderungen, die die Gesamtstruktur des Grundrisses verändern</li></ul></p><p>Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit, ein Feedback abzugeben. Anschließend wird der Auftrag abgeschlossen und Ihre Daten nach unserem Datenverarbeitungsvertrag gelöscht. Bei Änderungswünschen muss eine neue Bestellung aufgegeben werden.</p>",
          },
        ],
        dimension: "2d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_classic_extra_feedback",
        extra_feedback_price_onOffice: "floorplan_classic_extra_feedback_onOffice",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "bemasung",
            header: "Maßketten",
            text: "Bitte achten Sie darauf, dass alle Maßketten in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Grundrisse handschriftlich zu bemaßen. <br/><br/><strong>Beispiele für gut verwertbare Maßangaben:</strong>",
            samples: ["floor_plans/samples/massketten/1.jpg", "floor_plans/samples/massketten/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "trueToScale",
            header: "Maßstabsgetreu auf A4",
            text: "Standardmäßig werden Grundrisse so gezeichnet, dass die gesamte Fläche des Papiers bestmöglich genutzt wird, ohne hierbei einen Maßstab einzuhalten. Bei der Option „Maßstabsgetreu auf A4“ wird der Grundriss im Verhältnis 1:100 oder 1:75 auf einem DIN A4 Blatt gezeichnet (210 mm x  297 mm). Die Außenmaße des Gebäudes sollten für eine optisch ansprechende Darstellung nicht länger als 15 x 20 Meter betragen. Für größere Objekte geben Sie die gewünschte Papiergröße sowie den Maßstab bitte im Kommentarfeld an.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        feedbacks_included_onOffice: 0,
        name: "Klassisch",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Grundriss pro Wohnung/Etage", "Individualisierungsmöglichkeiten", "Outputformat: JPG"],
        package_id: "floorplan_classic",
        package_images: ["grundriss-2d-classic.jpg", "basic_ohneMoebel.jpg"],
        package_icons: ["floor_plans/grundriss-2d-klassisch.svg"],
        package_key: "64",
        packageDescHeading: "Hochwertige 2D-Grundrisse",
        price: "floorplan_price_classic_wohnen",
        price_onOffice: "floorplan_price_classic_wohnen_onOffice",
        package_price: {
          "0": "floorplan_price_classic_wohnen",
          "1": "floorplan_price_classic_gewerbe",
        },
        package_price_onOffice: {
          "0": "floorplan_price_classic_wohnen_onOffice",
          "1": "floorplan_price_classic_gewerbe_onOffice",
        },
        price_calculator: {
          "0": {
            step: 200,
            increment: 16,
          },
          "1": {
            step: 200,
            increment: 19,
          },
        },
        price_pre_text: "ab",
        service_image: "service_images/Grundrisse.svg",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          // TODO: The commented styles are not ready for the release but it is planned to add them later!
          // NOTE: The indexes must be adapted accordingly!
          {
            baseUrl: "../assets/images/floor_plans/2d_new/schwarz_weiss_akzent/",
            featuresUrl: "../assets/images/floor_plans/features_2d_new_black/",
            index: 0,
            name: "Schwarz Weiß",
          },
          {
            baseUrl: "../assets/images/floor_plans/2d_new/gruen/",
            featuresUrl: "../assets/images/floor_plans/features_2d_new_black//",
            index: 1,
            name: "Grün",
          },
          {
            baseUrl: "../assets/images/floor_plans/2d_new/rot/",
            featuresUrl: "../assets/images/floor_plans/features_2d_new_black/",
            index: 2,
            name: "Rot",
          },
          {
            baseUrl: "../assets/images/floor_plans/2d_new/marine/",
            featuresUrl: "../assets/images/floor_plans/features_2d_new_black/",
            index: 3,
            name: "Marine",
          },
          {
            baseUrl: "../assets/images/floor_plans/2d_new/gold/",
            featuresUrl: "../assets/images/floor_plans/features_2d_new_black/",
            index: 4,
            name: "Gold",
          },
          {
            baseUrl: "../assets/images/floor_plans/2d_new/blau/",
            featuresUrl: "../assets/images/floor_plans/features_2d_new_black/",
            index: 5,
            name: "Blau",
          },
          {
            baseUrl: "../assets/images/floor_plans/2d_new/braun/",
            featuresUrl: "../assets/images/floor_plans/features_2d_new_black/",
            index: 6,
            name: "Braun",
          },
          {
            baseUrl: "../assets/images/floor_plans/2d_new/schwarz_weiss/",
            featuresUrl: "../assets/images/floor_plans/features_2d_new_black/",
            index: 7,
            name: "Schwarz Weiß Basic",
          },
          {
            baseUrl: "../assets/images/floor_plans/2d_new/schwarz_weiss_detailliert/",
            featuresUrl: "../assets/images/floor_plans/features_2d_new_black/",
            index: 8,
            name: "Schwarz Weiß Detailliert",
          },
          {
            baseUrl: "../assets/images/floor_plans/2d_new/schwarz_weiss_hinterlegt/",
            featuresUrl: "../assets/images/floor_plans/features_2d_new_black/",
            index: 9,
            name: "Schwarz Weiß Hinterlegt",
          },
          {
            baseUrl: "../assets/images/floor_plans/2d_new/schwarz_weiss_premium/",
            featuresUrl: "../assets/images/floor_plans/features_2d_new_black/",
            index: 10,
            name: "Schwarz Weiß Premium",
          },
        ],
        title: "2D-Grundrisse",
        withModification: false,
        withPreRender: false,
      },
      {
        delivery_time: "48 h",
        details: [
          {
            label: "Grundrissdesign",
            value:
              'Die Grundrissdesigns sind nicht änderbar. Sollten Sie Änderungswünsche bzgl. der Bodenbeläge haben, wählen Sie die Option "Individuelle Böden". Hier haben Sie die Möglichkeit alle Bodenbeläge individuell auszuwählen.',
          },
          {
            label: "Anpassungen",
            value:
              "Änderungen im Grundriss können händisch oder mit Hilfe digitaler Tools eingezeichnet werden. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.",
          },
          {
            label: "Zusatzkosten",
            value:
              "<p>Der Preis pro Grundriss gilt für Grundrisse bis 200m². Dabei sind Balkon- und Terrassenflächen mit berücksichtigt. Bei größeren Grundrissen bzw. weiteren Etagen multipliziert sich der Stückpreis entsprechend.</p><p>Die erste Feedbackschleife ist für Sie kostenfrei, sofern es sich um unten genannte, kostenfreie Änderungen handelt. Die aufgeführten, kostenpflichtigen Änderungen, werden bereits in der ersten Korrekturtschleife berechnet. Alle Änderungen ab der zweiten Korrekturschleife sind kostenpflichtig. Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.</p><p>Kostenfreie Änderungen: <ul><li>Textänderungen</li><li>Entfernen oder hinzufügen von einzelnen Möbelstücken oder Dekoelementen</li><li>Entfernen oder hinzufügen von kostenfreien Spezifikationen (Raumbezeichnungen, Logo etc.)</li><li>Strukturelle Änderungen, die keine Gesamtveränderung  des Grundrisses darstellen</li></ul></p><p>Kostenpflichtige Änderungen: <ul><li>Nachträgliches Hinzufügen kostenpflichtiger Spezifikationen (Möblierung, maßstabsgetreue Zeichnung)</li><li>Änderung der Raumfunktionen</li></ul></p><p>Änderungen, die eine erneute Aufgabe der Bestellung erfordern: <ul><li>Darstellung des Grundrisses in einem anderen Layout</li><li>Änderungen, die die Gesamtstruktur des Grundrisses verändern</li></ul></p><p>Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit, ein Feedback abzugeben. Anschließend wird der Auftrag abgeschlossen und Ihre Daten nach unserem Datenverarbeitungsvertrag gelöscht. Bei Änderungswünschen muss eine neue Bestellung aufgegeben werden.</p>",
          },
        ],
        dimension: "2d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_premium_extra_feedback",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "trueToScale",
            header: "Maßstabsgetreu auf A4",
            text: "Standardmäßig werden Grundrisse so gezeichnet, dass die gesamte Fläche des Papiers bestmöglich genutzt wird, ohne hierbei einen Maßstab einzuhalten. Bei der Option „Maßstabsgetreu auf A4“ wird der Grundriss im Verhältnis 1:100 oder 1:75 auf einem DIN A4 Blatt gezeichnet (210 mm x  297 mm). Die Außenmaße des Gebäudes sollten für eine optisch ansprechende Darstellung nicht länger als 15 x 20 Meter betragen. Für größere Objekte geben Sie die gewünschte Papiergröße sowie den Maßstab bitte im Kommentarfeld an.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "modification",
            header: "Individuelle Böden",
            text: "Sie haben nun die Möglichkeit, Bodenbeläge individuell auszuwählen. Laden Sie dafür Beispiele der gewünschten Böden hoch und kennzeichnen Sie eindeutig wo diese verwendet werden sollen. Wir werden versuchen Ihre Vorgaben bestmöglich umzusetzen. Abweichungen können jedoch nicht ausgeschlossen werden.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei der Visualisierung helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen. Bitte zeichnen Sie die gewünschten Blickwinkelin die Pläne ein oder beschreiben Sie diese im Kommentarfeld.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Falls Sie unterschiedliche Böden in verschiedenen Räumen haben, können Sie unten den Kategorien die Räume den Boden zuordnen",
            title: "Was meinen wir mit Modifizieren?",
          },
          {
            keyPoints: ["Fußleisten", "Deckenleisten", "Wandfarben", "Zimmerpflanzen", "Dekoration"],
            text: "Kunden, die dieses Produkt bestellt haben, haben in der Regel immer Angaben zu folgenden Dingen gemacht: ",
            title: "Was meinen wir mit Besonderheiten?",
          },
        ],
        modification_price: "floorplan_premium_modification",
        name: "Premium",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Grundriss pro Wohnung/Etage", "Individualisierungsmöglichkeiten", "Outputformat: JPG"],
        package_id: "floorplan_premium",
        package_images: ["grundriss-2d-premium.jpg", "premium_ohneMoebel.jpeg"],
        package_icons: ["floor_plans/premium.svg"],
        package_key: "24",
        packageDescHeading: "Hochwertige 2D-Grundrisse",
        price: "floorplan_price_premium_wohnen",
        package_price: {
          "0": "floorplan_price_premium_wohnen",
          "1": "floorplan_price_premium_gewerbe",
        },
        price_calculator: {
          "0": {
            step: 150,
            increment: 29,
          },
          "1": {
            step: 200,
            increment: 35,
          },
          JLL: {
            step: 150,
            increment: 29,
          },
        },
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          {
            baseUrl: "../assets/images/floor_plans/2d/Modern_2D_Premium/",
            featuresUrl: "../assets/images/floor_plans/features_white/",
            index: 0,
            name: "Modern 2D-Premium",
          },
          {
            baseUrl: "../assets/images/floor_plans/2d/Modern_schwarz/",
            featuresUrl: "../assets/images/floor_plans/features_white/",
            index: 1,
            name: "Modern Schwarz",
          },
          {
            baseUrl: "../assets/images/floor_plans/2d/Neutral_bestend/",
            featuresUrl: "../assets/images/floor_plans/features_white/",
            index: 2,
            name: "Neutral Bestand",
          },
          {
            baseUrl: "../assets/images/floor_plans/2d/Neubau_grau/",
            featuresUrl: "../assets/images/floor_plans/features_white/",
            index: 3,
            name: "Neubau Grau",
          },
          {
            baseUrl: "../assets/images/floor_plans/2d/Neubau_weiss/",
            featuresUrl: "../assets/images/floor_plans/features_white/",
            index: 4,
            name: "Neubau Weiß",
          },
        ],
        title: "2D-Grundrisse",
        withModification: true,
        withPreRender: false,
        trueToScale_price: "floorplan_trueToScale",
        service_image: "service_images/Grundrisse.svg",
      },
      {
        additional_view_price: "floorplan_extravagant_additional_view",
        configuration: [
          {
            possibilities: [
              {
                img_url: "Bodendielen-alt.jpg",
                title: "Bodendielen alt",
              },
              {
                img_url: "Bodendielen-neu.jpg",
                title: "Bodendielen neu",
              },
              {
                img_url: "Parkett-Fischgraet.jpg",
                title: "Parkett Fischgrät",
              },
              {
                img_url: "Parkett-Matt-geraeuchert.jpg",
                title: "Parkett Matt geräuchert",
              },
              {
                img_url: "Parkett-Mosaik-Wuerfel.jpg",
                title: "Parkett Mosaik Würfel",
              },
              {
                img_url: "Parkett-Natur_1.jpg",
                title: "Parkett Natur 1",
              },
              {
                img_url: "Parkett-Natur_2.jpg",
                title: "Parkett Natur 2",
              },
              {
                img_url: "Parkett-Schiffsboden.jpg",
                title: "Parkett Schiffsboden",
              },
              {
                img_url: "Parkett_Chevron.jpg",
                title: "Parkett Chevron",
              },
            ],
            type: "Kategorie Holzböden 1",
            folder: "wooden_floors",
          },
          {
            possibilities: [
              {
                img_url: "Bodendielen-alt.jpg",
                title: "Bodendielen alt",
              },
              {
                img_url: "Bodendielen-neu.jpg",
                title: "Bodendielen neu",
              },
              {
                img_url: "Parkett-Fischgraet.jpg",
                title: "Parkett Fischgrät",
              },
              {
                img_url: "Parkett-Matt-geraeuchert.jpg",
                title: "Parkett Matt geräuchert",
              },
              {
                img_url: "Parkett-Mosaik-Wuerfel.jpg",
                title: "Parkett Mosaik Würfel",
              },
              {
                img_url: "Parkett-Natur_1.jpg",
                title: "Parkett Natur 1",
              },
              {
                img_url: "Parkett-Natur_2.jpg",
                title: "Parkett Natur 2",
              },
              {
                img_url: "Parkett-Schiffsboden.jpg",
                title: "Parkett Schiffsboden",
              },
              {
                img_url: "Parkett_Chevron.jpg",
                title: "Parkett Chevron",
              },
            ],
            type: "Kategorie Holzböden 2",
            folder: "wooden_floors",
          },
          {
            possibilities: [
              {
                img_url: "Bodenfliese-antrazit.jpg",
                title: "Bodenfliese antrazit",
              },
              {
                img_url: "Bodenfliese-Beton.jpg",
                title: "Bodenfliese Beton",
              },
              {
                img_url: "Bodenfliese-grau.jpg",
                title: "Bodenfliese grau",
              },
              {
                img_url: "Bodenfliese-Marmor.jpg",
                title: "Bodenfliese Marmor",
              },
              {
                img_url: "Bodenfliese-Travertino.jpg",
                title: "Bodenfliese Travertino",
              },
              {
                img_url: "Fliese-Betonoptik_1-grossformatik.jpg",
                title: "Fliese Betonoptik 1 großformatik",
              },
              {
                img_url: "Fliese-Betonoptik_2.jpg",
                title: "Fliese Betonoptik 2",
              },
              {
                img_url: "Fliese-schwarz.jpg",
                title: "Fliese schwarz",
              },
              {
                img_url: "Fliese-weiss.jpg",
                title: "Fliese weiß",
              },
            ],
            type: "Kategorie Fliesenböden 1",
            folder: "tiled_floors",
          },
          {
            possibilities: [
              {
                img_url: "Bodenfliese-antrazit.jpg",
                title: "Bodenfliese antrazit",
              },
              {
                img_url: "Bodenfliese-Beton.jpg",
                title: "Bodenfliese Beton",
              },
              {
                img_url: "Bodenfliese-grau.jpg",
                title: "Bodenfliese grau",
              },
              {
                img_url: "Bodenfliese-Marmor.jpg",
                title: "Bodenfliese Marmor",
              },
              {
                img_url: "Bodenfliese-Travertino.jpg",
                title: "Bodenfliese Travertino",
              },
              {
                img_url: "Fliese-Betonoptik_1-grossformatik.jpg",
                title: "Fliese Betonoptik 1 großformatik",
              },
              {
                img_url: "Fliese-Betonoptik_2.jpg",
                title: "Fliese Betonoptik 2",
              },
              {
                img_url: "Fliese-schwarz.jpg",
                title: "Fliese schwarz",
              },
              {
                img_url: "Fliese-weiss.jpg",
                title: "Fliese weiß",
              },
            ],
            type: "Kategorie Fliesenböden 2",
            folder: "tiled_floors",
          },
        ],
        delivery_time: "48 h",
        details: [
          {
            label: "Grundrissdesign",
            value:
              'Die Grundrissdesigns sind nicht änderbar. Sollten Sie Änderungswünsche bzgl. der Bodenbeläge haben, wählen Sie die Option "Individuelle Böden". Hier haben Sie die Möglichkeit alle Bodenbeläge individuell auszuwählen.',
          },
          {
            label: "Anpassungen",
            value:
              "Änderungen im Grundriss können händisch oder mit Hilfe digitaler Tools eingezeichnet werden. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.",
          },
          {
            label: "Zusatzkosten",
            value:
              "<p>Der Preis pro Grundriss gilt für Grundrisse bis 200m². Dabei sind Balkon- und Terrassenflächen mit berücksichtigt. Bei größeren Grundrissen bzw. weiteren Etagen multipliziert sich der Stückpreis entsprechend.</p><p>Die erste Feedbackschleife ist für Sie kostenfrei, sofern es sich um unten genannte, kostenfreie Änderungen handelt. Die aufgeführten, kostenpflichtigen Änderungen, werden bereits in der ersten Korrekturtschleife berechnet. Alle Änderungen ab der zweiten Korrekturschleife sind kostenpflichtig. Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.</p><p>Kostenfreie Änderungen: <ul><li>Textänderungen</li><li>Entfernen oder hinzufügen von einzelnen Möbelstücken oder Dekoelementen</li><li>Entfernen oder hinzufügen von kostenfreien Spezifikationen (Raumbezeichnungen, Logo etc.)</li><li>Strukturelle Änderungen, die keine Gesamtveränderung  des Grundrisses darstellen</li></ul></p><p>Kostenpflichtige Änderungen: <ul><li>Nachträgliches Hinzufügen kostenpflichtiger Spezifikationen (Möblierung, maßstabsgetreue Zeichnung)</li><li>Änderung der Raumfunktionen</li></ul></p><p>Änderungen, die eine erneute Aufgabe der Bestellung erfordern: <ul><li>Darstellung des Grundrisses in einem anderen Layout</li><li>Änderungen, die die Gesamtstruktur des Grundrisses verändern</li></ul></p><p>Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit, ein Feedback abzugeben. Anschließend wird der Auftrag abgeschlossen und Ihre Daten nach unserem Datenverarbeitungsvertrag gelöscht. Bei Änderungswünschen muss eine neue Bestellung aufgegeben werden.</p>",
          },
        ],
        dimension: "3d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_extravagant_extra_feedback",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "trueToScale",
            header: "Maßstabsgetreu auf A4",
            text: "Standardmäßig werden Grundrisse so gezeichnet, dass die gesamte Fläche des Papiers bestmöglich genutzt wird, ohne hierbei einen Maßstab einzuhalten. Bei der Option „Maßstabsgetreu auf A4“ wird der Grundriss im Verhältnis 1:100 oder 1:75 auf einem DIN A4 Blatt gezeichnet (210 mm x  297 mm). Die Außenmaße des Gebäudes sollten für eine optisch ansprechende Darstellung nicht länger als 15 x 20 Meter betragen. Für größere Objekte geben Sie die gewünschte Papiergröße sowie den Maßstab bitte im Kommentarfeld an.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "modification",
            header: "Individuelle Böden",
            text: "Sie haben nun die Möglichkeit, Bodenbeläge individuell auszuwählen. Laden Sie dafür Beispiele der gewünschten Böden hoch und kennzeichnen Sie eindeutig wo diese verwendet werden sollen. Wir werden versuchen Ihre Vorgaben bestmöglich umzusetzen. Abweichungen können jedoch nicht ausgeschlossen werden.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei der Visualisierung helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen. Bitte zeichnen Sie die gewünschten Blickwinkel in die Pläne ein oder beschreiben Sie diese im Kommentarfeld.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Falls Sie unterschiedliche Böden in verschiedenen Räumen haben, können Sie unten den Kategorien die Räume den Boden zuordnen",
            title: "Was meinen wir mit Modifizieren?",
          },
          {
            keyPoints: ["Fußleisten", "Deckenleisten", "Wandfarben", "Zimmerpflanzen", "Dekoration"],
            text: "Kunden, die dieses Produkt bestellt haben, haben in der Regel immer Angaben zu folgenden Dingen gemacht: ",
            title: "Was meinen wir mit Besonderheiten?",
          },
        ],
        modification_price: "floorplan_extravagant_modification",
        name: "Extravagant",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Grundriss pro Wohnung/Etage", "Individualisierungsmöglichkeiten", "Outputformat: JPG"],
        package_id: "floorplan_extravagant",
        package_images: ["grundriss-3d-extravagant.jpg", "3d_grundriss.jpg"],
        package_icons: ["floor_plans/extravagant.svg"],
        package_key: "5",
        packageDescHeading: "Hochwertige 3D-Grundrisse",
        price: "floorplan_price_extravagant_wohnen",
        package_price: {
          "0": "floorplan_price_extravagant_wohnen",
          "1": "floorplan_price_extravagant_gewerbe",
        },
        price_calculator: {
          "0": {
            step: 150,
            increment: 29,
          },
          "1": {
            step: 200,
            increment: 35,
          },
          JLL: {
            step: 150,
            increment: 29,
          },
        },
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          {
            baseUrl: "../assets/images/floor_plans/3d/Modern_weiss/",
            featuresUrl: "../assets/images/floor_plans/features_white/",
            index: 0,
            name: "Modern Weiß",
          },
          {
            baseUrl: "../assets/images/floor_plans/3d/Modern_grau/",
            featuresUrl: "../assets/images/floor_plans/features_white/",
            index: 1,
            name: "Modern Grau",
          },
          {
            baseUrl: "../assets/images/floor_plans/3d/Neutral_bestend/",
            featuresUrl: "../assets/images/floor_plans/features_white/",
            index: 2,
            name: "Neutral Bestand",
          },
          {
            baseUrl: "../assets/images/floor_plans/3d/Neubau_grau/",
            featuresUrl: "../assets/images/floor_plans/features_white/",
            index: 3,
            name: "Neubau Grau",
          },
          {
            baseUrl: "../assets/images/floor_plans/3d/Neubau_weiss/",
            featuresUrl: "../assets/images/floor_plans/features_white/",
            index: 4,
            name: "Neubau Weiß",
          },
        ],
        title: "3D-Grundrisse",
        withPreRender: false,
        withModification: true,
        trueToScale_price: "floorplan_trueToScale",
        service_image: "service_images/Grundrisse.svg",
      },
      {
        companies: [
          "Imogent GmbH",
          "Sparkasse",
          "^S Immobilien GmbH",
          "Naspa Immobilien GmbH - Agentur Hochtaunus Nord",
        ],
        delivery_time: "24 h",
        details: [
          {
            label: "Grundrissdesign",
            value:
              "Die Grundrissdesigns sind nicht änderbar. Sollten Sie dennoch Änderungswünsche bzgl. Böden, Wänden oder Einrichtungsdesign haben, geben Sie diese im Kommentarfeld an. Wir werden versuchen diese umzusetzen. Nachträgliche Anpassungen sind nur gegen Aufpreis von 10,00€ pro Grundriss möglich.",
          },
          {
            label: "Anpassungen",
            value:
              "Änderungen im Grundriss können händisch oder mit Hilfe digitaler Tools eingezeichnet werden. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.",
          },
          {
            label: "Zusatzkosten",
            value:
              "<p>Der Preis pro Grundriss gilt für Grundrisse bis 200m². Dabei sind Balkon- und Terrassenflächen mit berücksichtigt. Bei größeren Grundrissen bzw. weiteren Etagen multipliziert sich der Stückpreis entsprechend.</p><p>Die erste Feedbackschleife ist für Sie kostenfrei, sofern es sich um unten genannte, kostenfreie Änderungen handelt. Die aufgeführten, kostenpflichtigen Änderungen, werden bereits in der ersten Korrekturtschleife berechnet. Alle Änderungen ab der zweiten Korrekturschleife sind kostenpflichtig. Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.</p><p>Kostenfreie Änderungen: <ul><li>Textänderungen</li><li>Entfernen oder hinzufügen von einzelnen Möbelstücken oder Dekoelementen</li><li>Entfernen oder hinzufügen von kostenfreien Spezifikationen (Raumbezeichnungen, Logo etc.)</li><li>Strukturelle Änderungen, die keine Gesamtveränderung  des Grundrisses darstellen</li></ul></p><p>Kostenpflichtige Änderungen: <ul><li>Nachträgliches Hinzufügen kostenpflichtiger Spezifikationen (Möblierung, maßstabsgetreue Zeichnung)</li><li>Änderung der Raumfunktionen</li></ul></p><p>Änderungen, die eine erneute Aufgabe der Bestellung erfordern: <ul><li>Darstellung des Grundrisses in einem anderen Layout</li><li>Änderungen, die die Gesamtstruktur des Grundrisses verändern</li></ul></p><p>Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit, ein Feedback abzugeben. Anschließend wird der Auftrag abgeschlossen und Ihre Daten nach unserem Datenverarbeitungsvertrag gelöscht. Bei Änderungswünschen muss eine neue Bestellung aufgegeben werden.</p>",
          },
        ],
        dimension: "2d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_sparkasse_extra_feedback",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "bemasung",
            header: "Maßketten",
            text: "Bitte achten Sie darauf, dass alle Maßketten in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Grundrisse handschriftlich zu bemaßen. <br/><br/><strong>Beispiele für gut verwertbare Maßangaben:</strong>",
            samples: ["floor_plans/samples/massketten/1.jpg", "floor_plans/samples/massketten/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "watermark",
            header: "Wasserzeichen",
            text: "Das Wasserzeichen wird großflächig in der Mitte des Grundrisses eingefügt. Die Position ist nicht änderbar.",
            samples: [],
          },
          {
            field: "trueToScale",
            header: "Maßstabsgetreu auf A4",
            text: "Standardmäßig werden Grundrisse so gezeichnet, dass die gesamte Fläche des Papiers bestmöglich genutzt wird, ohne hierbei einen Maßstab einzuhalten. Bei der Option „Maßstabsgetreu auf A4“ wird der Grundriss im Verhältnis 1:100 oder 1:75 auf einem DIN A4 Blatt gezeichnet (210 mm x  297 mm). Die Außenmaße des Gebäudes sollten für eine optisch ansprechende Darstellung nicht länger als 15 x 20 Meter betragen. Für größere Objekte geben Sie die gewünschte Papiergröße sowie den Maßstab bitte im Kommentarfeld an.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        name: "Sparkasse",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Grundriss pro Wohnung/Etage", "Individualisierungsmöglichkeiten", "Outputformat: JPG"],
        package_id: "floorplan_sparkasse",
        package_images: ["grundriss-2d-sparkasse.jpg"],
        package_icons: ["grundriss-2d-sparkasse.jpg"],
        package_key: "26",
        packageDescHeading: "Hochwertige 2D Grundrisse",
        price: "floorplan_price_sparkasse_wohnen",
        package_price: {
          "0": "floorplan_price_sparkasse_wohnen",
          "1": "floorplan_price_sparkasse_gewerbe",
        },
        price_calculator: {
          "0": {
            step: 200,
            increment: 10,
          },
          "1": {
            step: 200,
            increment: 19,
          },
        },
        price_pre_text: "",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          {
            baseUrl: "../assets/images/floor_plans/sparkasse_1/",
            featuresUrl: "../assets/images/floor_plans/features_sparkasse_1/",
            index: 0,
            name: "Sparkasse Design 1",
          },
          {
            baseUrl: "../assets/images/floor_plans/sparkasse_2/",
            featuresUrl: "../assets/images/floor_plans/features_sparkasse_2/",
            index: 1,
            name: "Sparkasse Design 2",
          },
        ],
        title: "2D-Grundrisse",
        withModification: false,
        withPreRender: false,
        trueToScale_price: "floorplan_trueToScale",
        service_image: "grundriss-2d-sparkasse.jpg",
      },
      {
        companies: [
          "Imogent GmbH",
          "LBS Gebietsdirektion Bayern Süd",
          "LBS Bayerische Landesbausparkasse Amberg",
          "LBS Bayerische Landesbausparkasse Würzburg",
          "LBS Gebietsdirektion München Peter Zeiler",
          "LBS-Beratungscenter Nürnberg - Zentrum",
          "LBS Beratungscenter Bayreuth",
        ],
        delivery_time: "24 h",
        details: [
          {
            label: "Grundrissdesign",
            value:
              "Die Grundrissdesigns sind nicht änderbar. Sollten Sie dennoch Änderungswünsche bzgl. Böden, Wänden oder Einrichtungsdesign haben, geben Sie diese im Kommentarfeld an. Wir werden versuchen diese umzusetzen. Nachträgliche Anpassungen sind nur gegen Aufpreis von 10,00€ pro Grundriss möglich.",
          },
          {
            label: "Wasserzeichen",
            value: "Das LBS Symbol wird als Wasserzeichen großflächig in der Mitte des Grundrisses eingefügt.",
          },
          {
            label: "Anpassungen",
            value:
              "Änderungen im Grundriss können händisch oder mit Hilfe digitaler Tools eingezeichnet werden. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.",
          },
          {
            label: "Zusatzkosten",
            value:
              "<p>Der Preis pro Grundriss gilt für Grundrisse bis 200m². Dabei sind Balkon- und Terrassenflächen mit berücksichtigt. Bei größeren Grundrissen bzw. weiteren Etagen multipliziert sich der Stückpreis entsprechend.</p><p>Die erste Feedbackschleife ist für Sie kostenfrei, sofern es sich um unten genannte, kostenfreie Änderungen handelt. Die aufgeführten, kostenpflichtigen Änderungen, werden bereits in der ersten Korrekturtschleife berechnet. Alle Änderungen ab der zweiten Korrekturschleife sind kostenpflichtig. Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.</p><p>Kostenfreie Änderungen: <ul><li>Textänderungen</li><li>Entfernen oder hinzufügen von einzelnen Möbelstücken oder Dekoelementen</li><li>Entfernen oder hinzufügen von kostenfreien Spezifikationen (Raumbezeichnungen, Logo etc.)</li><li>Strukturelle Änderungen, die keine Gesamtveränderung  des Grundrisses darstellen</li></ul></p><p>Kostenpflichtige Änderungen: <ul><li>Nachträgliches Hinzufügen kostenpflichtiger Spezifikationen (Möblierung, maßstabsgetreue Zeichnung)</li><li>Änderung der Raumfunktionen</li></ul></p><p>Änderungen, die eine erneute Aufgabe der Bestellung erfordern: <ul><li>Darstellung des Grundrisses in einem anderen Layout</li><li>Änderungen, die die Gesamtstruktur des Grundrisses verändern</li></ul></p><p>Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit, ein Feedback abzugeben. Anschließend wird der Auftrag abgeschlossen und Ihre Daten nach unserem Datenverarbeitungsvertrag gelöscht. Bei Änderungswünschen muss eine neue Bestellung aufgegeben werden.</p>",
          },
        ],
        dimension: "2d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_lbs_extra_feedback",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "bemasung",
            header: "Maßketten",
            text: "Bitte achten Sie darauf, dass alle Maßketten in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Grundrisse handschriftlich zu bemaßen. <br/><br/><strong>Beispiele für gut verwertbare Maßangaben:</strong>",
            samples: ["floor_plans/samples/massketten/1.jpg", "floor_plans/samples/massketten/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "watermark",
            header: "Wasserzeichen",
            text: "Das Wasserzeichen wird großflächig in der Mitte des Grundrisses eingefügt. Die Position ist nicht änderbar.",
            samples: [],
          },
          {
            field: "trueToScale",
            header: "Maßstabsgetreu auf A4",
            text: "Standardmäßig werden Grundrisse so gezeichnet, dass die gesamte Fläche des Papiers bestmöglich genutzt wird, ohne hierbei einen Maßstab einzuhalten. Bei der Option „Maßstabsgetreu auf A4“ wird der Grundriss im Verhältnis 1:100 oder 1:75 auf einem DIN A4 Blatt gezeichnet (210 mm x  297 mm). Die Außenmaße des Gebäudes sollten für eine optisch ansprechende Darstellung nicht länger als 15 x 20 Meter betragen. Für größere Objekte geben Sie die gewünschte Papiergröße sowie den Maßstab bitte im Kommentarfeld an.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        name: "LBS",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Grundriss pro Wohnung/Etage", "Individualisierungsmöglichkeiten", "Outputformat: JPG"],
        package_id: "floorplan_lbs",
        package_images: ["grundriss-2d-lbs.jpg"],
        package_icons: ["grundriss-2d-lbs.jpg"],
        package_key: "40",
        packageDescHeading: "Hochwertige 2D Grundrisse",
        price: "floorplan_price_lbs_wohnen",
        package_price: {
          "0": "floorplan_price_lbs_wohnen",
          "1": "floorplan_price_lbs_gewerbe",
        },
        price_calculator: {
          "0": {
            step: 200,
            increment: 10,
          },
          "1": {
            step: 200,
            increment: 19,
          },
        },
        price_pre_text: "",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          {
            baseUrl: "../assets/images/floor_plans/lbs_1/",
            featuresUrl: "../assets/images/floor_plans/features_lbs_1/",
            index: 0,
            name: "LBS Design 1",
          },
          {
            baseUrl: "../assets/images/floor_plans/lbs_2/",
            featuresUrl: "../assets/images/floor_plans/features_lbs_2/",
            index: 1,
            name: "LBS Design 2",
          },
        ],
        title: "2D-Grundrisse",
        withModification: false,
        withPreRender: false,
        trueToScale_price: "floorplan_trueToScale",
        service_image: "grundriss-2d-lbs.jpg",
      },
      {
        companies: ["Imogent GmbH", "^FALC Immobilien"],
        delivery_time: "24 h",
        details: [
          {
            label: "Grundrissdesign",
            value:
              "Die Grundrissdesigns sind nicht änderbar. Sollten Sie dennoch Änderungswünsche bzgl. Böden, Wänden oder Einrichtungsdesign haben, geben Sie diese im Kommentarfeld an. Wir werden versuchen diese umzusetzen. Nachträgliche Anpassungen sind nur gegen Aufpreis von 10,00€ pro Grundriss möglich.",
          },
          {
            label: "Anpassungen",
            value:
              "Änderungen im Grundriss können händisch oder mit Hilfe digitaler Tools eingezeichnet werden. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.",
          },
          {
            label: "Zusatzkosten",
            value:
              "<p>Der Preis pro Grundriss gilt für Grundrisse bis 200m². Dabei sind Balkon- und Terrassenflächen mit berücksichtigt. Bei größeren Grundrissen bzw. weiteren Etagen multipliziert sich der Stückpreis entsprechend.</p><p>Die erste Feedbackschleife ist für Sie kostenfrei, sofern es sich um unten genannte, kostenfreie Änderungen handelt. Die aufgeführten, kostenpflichtigen Änderungen, werden bereits in der ersten Korrekturtschleife berechnet. Alle Änderungen ab der zweiten Korrekturschleife sind kostenpflichtig. Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.</p><p>Kostenfreie Änderungen: <ul><li>Textänderungen</li><li>Entfernen oder hinzufügen von einzelnen Möbelstücken oder Dekoelementen</li><li>Entfernen oder hinzufügen von kostenfreien Spezifikationen (Raumbezeichnungen, Logo etc.)</li><li>Strukturelle Änderungen, die keine Gesamtveränderung  des Grundrisses darstellen</li></ul></p><p>Kostenpflichtige Änderungen: <ul><li>Nachträgliches Hinzufügen kostenpflichtiger Spezifikationen (Möblierung, maßstabsgetreue Zeichnung)</li><li>Änderung der Raumfunktionen</li></ul></p><p>Änderungen, die eine erneute Aufgabe der Bestellung erfordern: <ul><li>Darstellung des Grundrisses in einem anderen Layout</li><li>Änderungen, die die Gesamtstruktur des Grundrisses verändern</li></ul></p><p>Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit, ein Feedback abzugeben. Anschließend wird der Auftrag abgeschlossen und Ihre Daten nach unserem Datenverarbeitungsvertrag gelöscht. Bei Änderungswünschen muss eine neue Bestellung aufgegeben werden.</p>",
          },
        ],
        dimension: "2d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_falc_extra_feedback",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "bemasung",
            header: "Maßketten",
            text: "Bitte achten Sie darauf, dass alle Maßketten in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Grundrisse handschriftlich zu bemaßen. <br/><br/><strong>Beispiele für gut verwertbare Maßangaben:</strong>",
            samples: ["floor_plans/samples/massketten/1.jpg", "floor_plans/samples/massketten/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "watermark",
            header: "Wasserzeichen",
            text: "Das Wasserzeichen wird großflächig in der Mitte des Grundrisses eingefügt. Die Position ist nicht änderbar.",
            samples: [],
          },
          {
            field: "trueToScale",
            header: "Maßstabsgetreu auf A4",
            text: "Standardmäßig werden Grundrisse so gezeichnet, dass die gesamte Fläche des Papiers bestmöglich genutzt wird, ohne hierbei einen Maßstab einzuhalten. Bei der Option „Maßstabsgetreu auf A4“ wird der Grundriss im Verhältnis 1:100 oder 1:75 auf einem DIN A4 Blatt gezeichnet (210 mm x  297 mm). Die Außenmaße des Gebäudes sollten für eine optisch ansprechende Darstellung nicht länger als 15 x 20 Meter betragen. Für größere Objekte geben Sie die gewünschte Papiergröße sowie den Maßstab bitte im Kommentarfeld an.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        name: "FALC",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Grundriss pro Wohnung/Etage", "Individualisierungsmöglichkeiten", "Outputformat: JPG"],
        package_id: "floorplan_falc",
        package_images: ["grundriss-2d-falc-2022.jpg"],
        package_icons: ["grundriss-2d-falc-2022.jpg"],
        package_key: "43",
        packageDescHeading: "Hochwertige 2D Grundrisse",
        price: "floorplan_price_falc_wohnen",
        package_price: {
          "0": "floorplan_price_falc_wohnen",
          "1": "floorplan_price_falc_gewerbe",
        },
        price_calculator: {
          "0": {
            step: 200,
            increment: 10,
          },
          "1": {
            step: 200,
            increment: 19,
          },
        },
        price_pre_text: "",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          {
            baseUrl: "../assets/images/floor_plans/falc-tiled-floor/",
            featuresUrl: "../assets/images/floor_plans/features_falc-tiled-floor/",
            index: 0,
            name: "FALC",
          },
        ],
        title: "2D-Grundrisse",
        withModification: false,
        withPreRender: false,
        trueToScale_price: "floorplan_trueToScale",
        service_image: "grundriss-2d-falc-2022.jpg",
      },
      {
        companies: ["Imogent GmbH", "^FALC Immobilien"],
        delivery_time: "24 h",
        details: [
          {
            label: "Grundrissdesign",
            value:
              "Die Grundrissdesigns sind nicht änderbar. Sollten Sie dennoch Änderungswünsche bzgl. Böden, Wänden oder Einrichtungsdesign haben, geben Sie diese im Kommentarfeld an. Wir werden versuchen diese umzusetzen. Nachträgliche Anpassungen sind nur gegen Aufpreis von 10,00€ pro Grundriss möglich.",
          },
          {
            label: "Anpassungen",
            value:
              "Änderungen im Grundriss können händisch oder mit Hilfe digitaler Tools eingezeichnet werden. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.",
          },
          {
            label: "Zusatzkosten",
            value:
              "<p>Der Preis pro Grundriss gilt für Grundrisse bis 200m². Dabei sind Balkon- und Terrassenflächen mit berücksichtigt. Bei größeren Grundrissen bzw. weiteren Etagen multipliziert sich der Stückpreis entsprechend.</p><p>Die erste Feedbackschleife ist für Sie kostenfrei, sofern es sich um unten genannte, kostenfreie Änderungen handelt. Die aufgeführten, kostenpflichtigen Änderungen, werden bereits in der ersten Korrekturtschleife berechnet. Alle Änderungen ab der zweiten Korrekturschleife sind kostenpflichtig. Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.</p><p>Kostenfreie Änderungen: <ul><li>Textänderungen</li><li>Entfernen oder hinzufügen von einzelnen Möbelstücken oder Dekoelementen</li><li>Entfernen oder hinzufügen von kostenfreien Spezifikationen (Raumbezeichnungen, Logo etc.)</li><li>Strukturelle Änderungen, die keine Gesamtveränderung  des Grundrisses darstellen</li></ul></p><p>Kostenpflichtige Änderungen: <ul><li>Nachträgliches Hinzufügen kostenpflichtiger Spezifikationen (Möblierung, maßstabsgetreue Zeichnung)</li><li>Änderung der Raumfunktionen</li></ul></p><p>Änderungen, die eine erneute Aufgabe der Bestellung erfordern: <ul><li>Darstellung des Grundrisses in einem anderen Layout</li><li>Änderungen, die die Gesamtstruktur des Grundrisses verändern</li></ul></p><p>Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit, ein Feedback abzugeben. Anschließend wird der Auftrag abgeschlossen und Ihre Daten nach unserem Datenverarbeitungsvertrag gelöscht. Bei Änderungswünschen muss eine neue Bestellung aufgegeben werden.</p>",
          },
        ],
        dimension: "2d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_falc_extra_feedback",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "bemasung",
            header: "Maßketten",
            text: "Bitte achten Sie darauf, dass alle Maßketten in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Grundrisse handschriftlich zu bemaßen. <br/><br/><strong>Beispiele für gut verwertbare Maßangaben:</strong>",
            samples: ["floor_plans/samples/massketten/1.jpg", "floor_plans/samples/massketten/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "watermark",
            header: "Wasserzeichen",
            text: "Das Wasserzeichen wird großflächig in der Mitte des Grundrisses eingefügt. Die Position ist nicht änderbar.",
            samples: [],
          },
          {
            field: "trueToScale",
            header: "Maßstabsgetreu auf A4",
            text: "Standardmäßig werden Grundrisse so gezeichnet, dass die gesamte Fläche des Papiers bestmöglich genutzt wird, ohne hierbei einen Maßstab einzuhalten. Bei der Option „Maßstabsgetreu auf A4“ wird der Grundriss im Verhältnis 1:100 oder 1:75 auf einem DIN A4 Blatt gezeichnet (210 mm x  297 mm). Die Außenmaße des Gebäudes sollten für eine optisch ansprechende Darstellung nicht länger als 15 x 20 Meter betragen. Für größere Objekte geben Sie die gewünschte Papiergröße sowie den Maßstab bitte im Kommentarfeld an.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        name: "FALC Variante 2",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Grundriss pro Wohnung/Etage", "Individualisierungsmöglichkeiten", "Outputformat: JPG"],
        package_id: "floorplan_falc",
        package_images: ["grundriss-2d-falc.jpg"],
        package_icons: ["grundriss-2d-falc.jpg"],
        package_key: "48",
        packageDescHeading: "Hochwertige 2D Grundrisse",
        price: "floorplan_price_falc_wohnen",
        package_price: {
          "0": "floorplan_price_falc_wohnen",
          "1": "floorplan_price_falc_gewerbe",
        },
        price_calculator: {
          "0": {
            step: 200,
            increment: 10,
          },
          "1": {
            step: 200,
            increment: 19,
          },
        },
        price_pre_text: "",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          {
            baseUrl: "../assets/images/floor_plans/falc/",
            featuresUrl: "../assets/images/floor_plans/features_falc/",
            index: 0,
            name: "Standard",
          },
        ],
        title: "2D-Grundrisse",
        withModification: false,
        withPreRender: false,
        trueToScale_price: "floorplan_trueToScale",
        service_image: "grundriss-2d-falc.jpg",
      },
      {
        companies: ["Imogent GmbH", "^BPD Immobilien"],
        delivery_time: "24 h",
        details: [
          {
            label: "Ausgabeformat inkl. Beispiel",
            value:
              "Sie erhalten alle BPD Factsheets im PDF-Format.<br /> <a href='https://firebasestorage.googleapis.com/v0/b/imogent-plattform.appspot.com/o/assets%2Fdocuments%2Ffactsheets%2FAPznzaa3r4cCTapv4KTe1pX.pdf?alt=media&token=051089a8-bc77-42e0-a764-2ecacaef0604' target='_blank'>Hier</a> Beispiel einsehen.",
          },
          {
            label: "Grundrissdesign",
            value:
              "Das Factsheet Design ist nicht änderbar. Alle Spezifikationen sind in der Guideline festgelegt. Die BPD Factsheet Guidelines sind <a href='https://firebasestorage.googleapis.com/v0/b/imogent-plattform.appspot.com/o/assets%2Fdocuments%2Ffactsheets%2FAPznzaa3r4cCTapv4KTe1pX.pdf?alt=media&token=051089a8-bc77-42e0-a764-2ecacaef0604' target='_blank'>hier</a> einsehbar.",
          },
          {
            label: "Zusatzkosten",
            value:
              "<b>Korrekturschleifen:</b> <br />1. Feedbackschleife: kostenfrei <br />2. Feedbackschleife: aufwandsabhängige Berechnung <br /><br />Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.",
          },
          {
            label: "Stornierungsbedingungen",
            value:
              "Stornierung vor dem Start der Bearbeitung: kostenfreie Stornierung möglich Stornierung nach dem Start der Bearbeitung: 100% des Rechnungsbetrages werden berechnet",
          },
        ],
        dimension: "2d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_bpd_extra_feedback",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "bemasung",
            header: "Maßketten",
            text: "Bitte achten Sie darauf, dass alle Maßketten in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Grundrisse handschriftlich zu bemaßen. <br/><br/><strong>Beispiele für gut verwertbare Maßangaben:</strong>",
            samples: ["floor_plans/samples/massketten/1.jpg", "floor_plans/samples/massketten/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "watermark",
            header: "Wasserzeichen",
            text: "Das Wasserzeichen wird großflächig in der Mitte des Grundrisses eingefügt. Die Position ist nicht änderbar.",
            samples: [],
          },
          {
            field: "trueToScale",
            header: "Maßstabsgetreu auf A4",
            text: "Standardmäßig werden Grundrisse so gezeichnet, dass die gesamte Fläche des Papiers bestmöglich genutzt wird, ohne hierbei einen Maßstab einzuhalten. Bei der Option „Maßstabsgetreu auf A4“ wird der Grundriss im Verhältnis 1:100 oder 1:75 auf einem DIN A4 Blatt gezeichnet (210 mm x  297 mm). Die Außenmaße des Gebäudes sollten für eine optisch ansprechende Darstellung nicht länger als 15 x 20 Meter betragen. Für größere Objekte geben Sie die gewünschte Papiergröße sowie den Maßstab bitte im Kommentarfeld an.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        name: "BPD Factsheet",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Factsheet pro Wohneinheit", "Exklusives BPD-Design", "Outputformat: PDF"],
        package_id: "floorplan_bpd",
        package_images: ["factsheet-2d-bpd.png"],
        package_icons: ["factsheet-2d-bpd.png"],
        package_key: "44",
        packageDescHeading: "Factsheets im BPD Design",
        price: "floorplan_price_bpd_wohnen",
        package_price: {
          "0": "floorplan_price_bpd_wohnen",
          "1": "floorplan_price_bpd_gewerbe",
        },
        price_calculator: {
          "0": {
            step: 200,
            increment: 10,
          },
          "1": {
            step: 200,
            increment: 19,
          },
        },
        price_pre_text: "",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          {
            baseUrl: "../assets/images/floor_plans/bpd/",
            featuresUrl: "../assets/images/floor_plans/features_bpd/",
            index: 0,
            name: "BPD Design 1",
          },
        ],
        title: "2D-Grundrisse",
        withModification: false,
        withPreRender: false,
        trueToScale_price: "floorplan_trueToScale",
        service_image: "factsheet-2d-bpd.png",
      },
      {
        companies: ["Imogent GmbH", "Helma"],
        delivery_time: "48 h",
        details: [
          {
            label: "Grundrissdesign",
            value:
              'Die Grundrissdesigns sind nicht änderbar. Sollten Sie Änderungswünsche bzgl. der Bodenbeläge haben, wählen Sie die Option "Individuelle Böden". Hier haben Sie die Möglichkeit alle Bodenbeläge individuell auszuwählen.',
          },
          {
            label: "Zusatzkosten",
            value:
              "<p>Der Preis pro Grundriss gilt für Grundrisse bis 200m². Dabei sind Balkon- und Terrassenflächen mit berücksichtigt. Bei größeren Grundrissen bzw. weiteren Etagen multipliziert sich der Stückpreis entsprechend.</p><p>Die erste Feedbackschleife ist für Sie kostenfrei, sofern es sich um unten genannte, kostenfreie Änderungen handelt. Die aufgeführten, kostenpflichtigen Änderungen, werden bereits in der ersten Korrekturtschleife berechnet. Alle Änderungen ab der zweiten Korrekturschleife sind kostenpflichtig. Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.</p><p>Kostenfreie Änderungen: <ul><li>Textänderungen</li><li>Entfernen oder hinzufügen von einzelnen Möbelstücken oder Dekoelementen</li><li>Entfernen oder hinzufügen von kostenfreien Spezifikationen (Raumbezeichnungen, Logo etc.)</li><li>Strukturelle Änderungen, die keine Gesamtveränderung  des Grundrisses darstellen</li></ul></p><p>Kostenpflichtige Änderungen: <ul><li>Nachträgliches Hinzufügen kostenpflichtiger Spezifikationen (Möblierung, maßstabsgetreue Zeichnung)</li><li>Änderung der Raumfunktionen</li></ul></p><p>Änderungen, die eine erneute Aufgabe der Bestellung erfordern: <ul><li>Darstellung des Grundrisses in einem anderen Layout</li><li>Änderungen, die die Gesamtstruktur des Grundrisses verändern</li></ul></p><p>Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit, ein Feedback abzugeben. Anschließend wird der Auftrag abgeschlossen und Ihre Daten nach unserem Datenverarbeitungsvertrag gelöscht. Bei Änderungswünschen muss eine neue Bestellung aufgegeben werden.</p>",
          },
        ],
        dimension: "2d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_premium_extra_feedback",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "modification",
            header: "Individuelle Böden",
            text: "Sie haben nun die Möglichkeit, Bodenbeläge individuell auszuwählen. Laden Sie dafür Beispiele der gewünschten Böden hoch und kennzeichnen Sie eindeutig wo diese verwendet werden sollen. Wir werden versuchen Ihre Vorgaben bestmöglich umzusetzen. Abweichungen können jedoch nicht ausgeschlossen werden.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei der Visualisierung helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen. Bitte zeichnen Sie die gewünschten Blickwinkel in die Pläne ein oder beschreiben Sie diese im Kommentarfeld.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Falls Sie unterschiedliche Böden in verschiedenen Räumen haben, können Sie unten den Kategorien die Räume den Boden zuordnen",
            title: "Was meinen wir mit Modifizieren?",
          },
          {
            keyPoints: ["Fußleisten", "Deckenleisten", "Wandfarben", "Zimmerpflanzen", "Dekoration"],
            text: "Kunden, die dieses Produkt bestellt haben, haben in der Regel immer Angaben zu folgenden Dingen gemacht: ",
            title: "Was meinen wir mit Besonderheiten?",
          },
        ],
        modification_price: "floorplan_premium_modification",
        name: "Helma Design 1",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Grundriss pro Wohnung/Etage", "Individualisierungsmöglichkeiten", "Outputformat: JPG"],
        package_id: "floorplan_helma1",
        package_images: ["grundriss-helma01.jpg"],
        package_icons: ["grundriss-helma01.jpg"],
        package_key: "20",
        packageDescHeading: "Hochwertige 2D Grundrisse",
        price: "floorplan_price_premium_wohnen",
        package_price: {
          "0": "floorplan_price_premium_wohnen",
          "1": "floorplan_price_premium_gewerbe",
        },
        price_calculator: {
          "0": {
            step: 150,
            increment: 29,
          },
          "1": {
            step: 200,
            increment: 35,
          },
        },
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          {
            baseUrl: "../assets/images/floor_plans/helma_design1/",
            featuresUrl: "",
            index: 0,
            name: "Helma",
          },
        ],
        title: "2D-Grundrisse",
        withModification: true,
        withPreRender: false,
        trueToScale_price: "floorplan_trueToScale",
        service_image: "grundriss-helma01.jpg",
      },
      {
        companies: ["Imogent GmbH", "Helma"],
        delivery_time: "48 h",
        details: [
          {
            label: "Grundrissdesign",
            value:
              'Die Grundrissdesigns sind nicht änderbar. Sollten Sie Änderungswünsche bzgl. der Bodenbeläge haben, wählen Sie die Option "Individuelle Böden". Hier haben Sie die Möglichkeit alle Bodenbeläge individuell auszuwählen.',
          },
          {
            label: "Anpassungen",
            value:
              "Änderungen im Grundriss können händisch oder mit Hilfe digitaler Tools eingezeichnet werden. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.",
          },
          {
            label: "Zusatzkosten",
            value:
              "<p>Der Preis pro Grundriss gilt für Grundrisse bis 200m². Dabei sind Balkon- und Terrassenflächen mit berücksichtigt. Bei größeren Grundrissen bzw. weiteren Etagen multipliziert sich der Stückpreis entsprechend.</p><p>Die erste Feedbackschleife ist für Sie kostenfrei, sofern es sich um unten genannte, kostenfreie Änderungen handelt. Die aufgeführten, kostenpflichtigen Änderungen, werden bereits in der ersten Korrekturtschleife berechnet. Alle Änderungen ab der zweiten Korrekturschleife sind kostenpflichtig. Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.</p><p>Kostenfreie Änderungen: <ul><li>Textänderungen</li><li>Entfernen oder hinzufügen von einzelnen Möbelstücken oder Dekoelementen</li><li>Entfernen oder hinzufügen von kostenfreien Spezifikationen (Raumbezeichnungen, Logo etc.)</li><li>Strukturelle Änderungen, die keine Gesamtveränderung  des Grundrisses darstellen</li></ul></p><p>Kostenpflichtige Änderungen: <ul><li>Nachträgliches Hinzufügen kostenpflichtiger Spezifikationen (Möblierung, maßstabsgetreue Zeichnung)</li><li>Änderung der Raumfunktionen</li></ul></p><p>Änderungen, die eine erneute Aufgabe der Bestellung erfordern: <ul><li>Darstellung des Grundrisses in einem anderen Layout</li><li>Änderungen, die die Gesamtstruktur des Grundrisses verändern</li></ul></p><p>Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit, ein Feedback abzugeben. Anschließend wird der Auftrag abgeschlossen und Ihre Daten nach unserem Datenverarbeitungsvertrag gelöscht. Bei Änderungswünschen muss eine neue Bestellung aufgegeben werden.</p>",
          },
        ],
        dimension: "2d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_premium_extra_feedback",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "trueToScale",
            header: "Maßstabsgetreu auf A4",
            text: "Standardmäßig werden Grundrisse so gezeichnet, dass die gesamte Fläche des Papiers bestmöglich genutzt wird, ohne hierbei einen Maßstab einzuhalten. Bei der Option „Maßstabsgetreu auf A4“ wird der Grundriss im Verhältnis 1:100 oder 1:75 auf einem DIN A4 Blatt gezeichnet (210 mm x  297 mm). Die Außenmaße des Gebäudes sollten für eine optisch ansprechende Darstellung nicht länger als 15 x 20 Meter betragen. Für größere Objekte geben Sie die gewünschte Papiergröße sowie den Maßstab bitte im Kommentarfeld an.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "modification",
            header: "Individuelle Böden",
            text: "Sie haben nun die Möglichkeit, Bodenbeläge individuell auszuwählen. Laden Sie dafür Beispiele der gewünschten Böden hoch und kennzeichnen Sie eindeutig wo diese verwendet werden sollen. Wir werden versuchen Ihre Vorgaben bestmöglich umzusetzen. Abweichungen können jedoch nicht ausgeschlossen werden.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei der Visualisierung helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen. Bitte zeichnen Sie die gewünschten Blickwinkel in die Pläne ein oder beschreiben Sie diese im Kommentarfeld.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Falls Sie unterschiedliche Böden in verschiedenen Räumen haben, können Sie unten den Kategorien die Räume den Boden zuordnen",
            title: "Was meinen wir mit Modifizieren?",
          },
          {
            keyPoints: ["Fußleisten", "Deckenleisten", "Wandfarben", "Zimmerpflanzen", "Dekoration"],
            text: "Kunden, die dieses Produkt bestellt haben, haben in der Regel immer Angaben zu folgenden Dingen gemacht: ",
            title: "Was meinen wir mit Besonderheiten?",
          },
        ],
        modification_price: "floorplan_premium_modification",
        name: "Helma Design 2",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Grundriss pro Wohnung/Etage", "Individualisierungsmöglichkeiten", "Outputformat: JPG"],
        package_id: "floorplan_helma2",
        package_images: ["grundriss-helma02.jpg"],
        package_icons: ["grundriss-helma02.jpg"],
        package_key: "35",
        price: "floorplan_price_premium_wohnen",
        package_price: {
          "0": "floorplan_price_premium_wohnen",
          "1": "floorplan_price_premium_gewerbe",
        },
        price_calculator: {
          "0": {
            step: 150,
            increment: 29,
          },
          "1": {
            step: 200,
            increment: 35,
          },
        },
        packageDescHeading: "Hochwertige 2D Grundrisse",
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          {
            baseUrl: "../assets/images/floor_plans/helma_design2/",
            featuresUrl: "",
            index: 0,
            name: "Helma",
          },
        ],
        title: "2D-Grundrisse",
        withModification: true,
        withPreRender: false,
        trueToScale_price: "floorplan_trueToScale",
        service_image: "grundriss-helma02.jpg",
      },
      {
        companies: ["Imogent GmbH", "^RE/MAX"],
        delivery_time: "24 h",
        details: [
          {
            label: "Grundrissdesign",
            value:
              "Die Grundrissdesigns sind nicht änderbar. Sollten Sie dennoch Änderungswünsche bzgl. Böden, Wänden oder Einrichtungsdesign haben, geben Sie diese im Kommentarfeld an. Wir werden versuchen diese umzusetzen. Nachträgliche Anpassungen sind nur gegen Aufpreis von 10,00€ pro Grundriss möglich.",
          },
          {
            label: "Anpassungen",
            value:
              "Änderungen im Grundriss können händisch oder mit Hilfe digitaler Tools eingezeichnet werden. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.",
          },
          {
            label: "Zusatzkosten",
            value:
              "<p>Der Preis pro Grundriss gilt für Grundrisse bis 200m². Dabei sind Balkon- und Terrassenflächen mit berücksichtigt. Bei größeren Grundrissen bzw. weiteren Etagen multipliziert sich der Stückpreis entsprechend.</p><p>Die erste Feedbackschleife ist für Sie kostenfrei, sofern es sich um unten genannte, kostenfreie Änderungen handelt. Die aufgeführten, kostenpflichtigen Änderungen, werden bereits in der ersten Korrekturtschleife berechnet. Alle Änderungen ab der zweiten Korrekturschleife sind kostenpflichtig. Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.</p><p>Kostenfreie Änderungen: <ul><li>Textänderungen</li><li>Entfernen oder hinzufügen von einzelnen Möbelstücken oder Dekoelementen</li><li>Entfernen oder hinzufügen von kostenfreien Spezifikationen (Raumbezeichnungen, Logo etc.)</li><li>Strukturelle Änderungen, die keine Gesamtveränderung  des Grundrisses darstellen</li></ul></p><p>Kostenpflichtige Änderungen: <ul><li>Nachträgliches Hinzufügen kostenpflichtiger Spezifikationen (Möblierung, maßstabsgetreue Zeichnung)</li><li>Änderung der Raumfunktionen</li></ul></p><p>Änderungen, die eine erneute Aufgabe der Bestellung erfordern: <ul><li>Darstellung des Grundrisses in einem anderen Layout</li><li>Änderungen, die die Gesamtstruktur des Grundrisses verändern</li></ul></p><p>Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit, ein Feedback abzugeben. Anschließend wird der Auftrag abgeschlossen und Ihre Daten nach unserem Datenverarbeitungsvertrag gelöscht. Bei Änderungswünschen muss eine neue Bestellung aufgegeben werden.</p>",
          },
        ],
        dimension: "2d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_remax_extra_feedback",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "bemasung",
            header: "Maßketten",
            text: "Bitte achten Sie darauf, dass alle Maßketten in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Grundrisse handschriftlich zu bemaßen. <br/><br/><strong>Beispiele für gut verwertbare Maßangaben:</strong>",
            samples: ["floor_plans/samples/massketten/1.jpg", "floor_plans/samples/massketten/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "watermark",
            header: "Wasserzeichen",
            text: "Das Wasserzeichen wird großflächig in der Mitte des Grundrisses eingefügt. Die Position ist nicht änderbar.",
            samples: [],
          },
          {
            field: "trueToScale",
            header: "Maßstabsgetreu auf A4",
            text: "Standardmäßig werden Grundrisse so gezeichnet, dass die gesamte Fläche des Papiers bestmöglich genutzt wird, ohne hierbei einen Maßstab einzuhalten. Bei der Option „Maßstabsgetreu auf A4“ wird der Grundriss im Verhältnis 1:100 oder 1:75 auf einem DIN A4 Blatt gezeichnet (210 mm x  297 mm). Die Außenmaße des Gebäudes sollten für eine optisch ansprechende Darstellung nicht länger als 15 x 20 Meter betragen. Für größere Objekte geben Sie die gewünschte Papiergröße sowie den Maßstab bitte im Kommentarfeld an.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        name: "RE/MAX",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Grundriss pro Wohnung/Etage", "Individualisierungsmöglichkeiten", "Outputformat: JPG"],
        package_id: "floorplan_remax",
        package_images: ["grundriss-2d-remax.jpg"],
        package_icons: ["grundriss-2d-remax.jpg"],
        package_key: "46",
        packageDescHeading: "Hochwertige 2D Grundrisse",
        price: "floorplan_price_remax_wohnen",
        package_price: {
          "0": "floorplan_price_remax_wohnen",
          "1": "floorplan_price_remax_gewerbe",
        },
        price_calculator: {
          "0": {
            step: 200,
            increment: 10,
          },
          "1": {
            step: 200,
            increment: 19,
          },
        },
        price_pre_text: "",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          {
            baseUrl: "../assets/images/floor_plans/remax-parkett/",
            featuresUrl: "../assets/images/floor_plans/features_remax-parkett/",
            index: 0,
            name: "Parkett",
          },
          {
            baseUrl: "../assets/images/floor_plans/remax-tiled-floor/",
            featuresUrl: "../assets/images/floor_plans/features_remax-tiled-floor/",
            index: 1,
            name: "Fliesen",
          },
          {
            baseUrl: "../assets/images/floor_plans/remax-carpet/",
            featuresUrl: "../assets/images/floor_plans/features_remax-carpet/",
            index: 2,
            name: "Teppich",
          },
        ],
        title: "2D-Grundrisse",
        withModification: false,
        withPreRender: false,
        trueToScale_price: "floorplan_trueToScale",
        service_image: "grundriss-2d-remax.jpg",
      },
      {
        companies: ["Imogent GmbH", "^DAHLER", "^Constate GmbH$"],
        delivery_time: "24 h",
        details: [
          {
            label: "Grundrissdesign",
            value:
              "Die Grundrissdesigns sind nicht änderbar. Sollten Sie dennoch Änderungswünsche bzgl. Böden, Wänden oder Einrichtungsdesign haben, geben Sie diese im Kommentarfeld an. Wir werden versuchen diese umzusetzen. Nachträgliche Anpassungen sind nur gegen Aufpreis von 10,00€ pro Grundriss möglich.",
          },
          {
            label: "Anpassungen",
            value:
              "Änderungen im Grundriss können händisch oder mit Hilfe digitaler Tools eingezeichnet werden. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.",
          },
          {
            label: "Zusatzkosten",
            value:
              "<p>Der Preis pro Grundriss gilt für Grundrisse bis 200m². Dabei sind Balkon- und Terrassenflächen mit berücksichtigt. Bei größeren Grundrissen bzw. weiteren Etagen multipliziert sich der Stückpreis entsprechend.</p><p>Die erste Feedbackschleife ist für Sie kostenfrei, sofern es sich um unten genannte, kostenfreie Änderungen handelt. Die aufgeführten, kostenpflichtigen Änderungen, werden bereits in der ersten Korrekturtschleife berechnet. Alle Änderungen ab der zweiten Korrekturschleife sind kostenpflichtig. Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.</p><p>Kostenfreie Änderungen: <ul><li>Textänderungen</li><li>Entfernen oder hinzufügen von einzelnen Möbelstücken oder Dekoelementen</li><li>Entfernen oder hinzufügen von kostenfreien Spezifikationen (Raumbezeichnungen, Logo etc.)</li><li>Strukturelle Änderungen, die keine Gesamtveränderung  des Grundrisses darstellen</li></ul></p><p>Kostenpflichtige Änderungen: <ul><li>Nachträgliches Hinzufügen kostenpflichtiger Spezifikationen (Möblierung, maßstabsgetreue Zeichnung)</li><li>Änderung der Raumfunktionen</li></ul></p><p>Änderungen, die eine erneute Aufgabe der Bestellung erfordern: <ul><li>Darstellung des Grundrisses in einem anderen Layout</li><li>Änderungen, die die Gesamtstruktur des Grundrisses verändern</li></ul></p><p>Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit, ein Feedback abzugeben. Anschließend wird der Auftrag abgeschlossen und Ihre Daten nach unserem Datenverarbeitungsvertrag gelöscht. Bei Änderungswünschen muss eine neue Bestellung aufgegeben werden.</p>",
          },
        ],
        dimension: "2d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_dahler-company_extra_feedback",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "bemasung",
            header: "Maßketten",
            text: "Bitte achten Sie darauf, dass alle Maßketten in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Grundrisse handschriftlich zu bemaßen. <br/><br/><strong>Beispiele für gut verwertbare Maßangaben:</strong>",
            samples: ["floor_plans/samples/massketten/1.jpg", "floor_plans/samples/massketten/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "trueToScale",
            header: "Maßstabsgetreu auf A4",
            text: "Standardmäßig werden Grundrisse so gezeichnet, dass die gesamte Fläche des Papiers bestmöglich genutzt wird, ohne hierbei einen Maßstab einzuhalten. Bei der Option „Maßstabsgetreu auf A4“ wird der Grundriss im Verhältnis 1:100 oder 1:75 auf einem DIN A4 Blatt gezeichnet (210 mm x  297 mm). Die Außenmaße des Gebäudes sollten für eine optisch ansprechende Darstellung nicht länger als 15 x 20 Meter betragen. Für größere Objekte geben Sie die gewünschte Papiergröße sowie den Maßstab bitte im Kommentarfeld an.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        name: "Dahler",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Grundriss pro Wohnung/Etage", "Individualisierungsmöglichkeiten", "Outputformat: JPG"],
        package_id: "floorplan_dahler-company",
        package_images: ["grundriss-2d-dahler-company.jpg"],
        package_icons: ["grundriss-2d-dahler-company.jpg"],
        package_key: "52",
        packageDescHeading: "Hochwertige 2D-Grundrisse",
        price: "floorplan_price_dahler-company_wohnen",
        package_price: {
          "0": "floorplan_price_dahler-company_wohnen",
          "1": "floorplan_price_dahler-company_gewerbe",
        },
        price_calculator: {
          "0": {
            step: 200,
            increment: 16,
          },
          "1": {
            step: 200,
            increment: 19,
          },
          JLL: {
            step: 400,
            increment: 19,
          },
        },
        price_pre_text: "ab",
        service_image: "grundriss-2d-dahler-company.jpg",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          {
            baseUrl: "../assets/images/floor_plans/dahler-company/",
            featuresUrl: "../assets/images/floor_plans/features_dahler-company/",
            index: 0,
            name: "Dahler & Company Design 1",
          },
        ],
        title: "2D-Grundrisse",
        withModification: false,
        withPreRender: false,
      },
      {
        companies: ["Imogent GmbH", "^Filla&Göth"],
        delivery_time: "48 h",
        details: [
          {
            label: "Grundrissdesign",
            value:
              'Die Grundrissdesigns sind nicht änderbar. Sollten Sie Änderungswünsche bzgl. der Bodenbeläge haben, wählen Sie die Option "Individuelle Böden". Hier haben Sie die Möglichkeit alle Bodenbeläge individuell auszuwählen.',
          },
          {
            label: "Anpassungen",
            value:
              "Änderungen im Grundriss können händisch oder mit Hilfe digitaler Tools eingezeichnet werden. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.",
          },
          {
            label: "Zusatzkosten",
            value:
              "<p>Der Preis pro Grundriss gilt für Grundrisse bis 200m². Dabei sind Balkon- und Terrassenflächen mit berücksichtigt. Bei größeren Grundrissen bzw. weiteren Etagen multipliziert sich der Stückpreis entsprechend.</p><p>Die erste Feedbackschleife ist für Sie kostenfrei, sofern es sich um unten genannte, kostenfreie Änderungen handelt. Die aufgeführten, kostenpflichtigen Änderungen, werden bereits in der ersten Korrekturtschleife berechnet. Alle Änderungen ab der zweiten Korrekturschleife sind kostenpflichtig. Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.</p><p>Kostenfreie Änderungen: <ul><li>Textänderungen</li><li>Entfernen oder hinzufügen von einzelnen Möbelstücken oder Dekoelementen</li><li>Entfernen oder hinzufügen von kostenfreien Spezifikationen (Raumbezeichnungen, Logo etc.)</li><li>Strukturelle Änderungen, die keine Gesamtveränderung  des Grundrisses darstellen</li></ul></p><p>Kostenpflichtige Änderungen: <ul><li>Nachträgliches Hinzufügen kostenpflichtiger Spezifikationen (Möblierung, maßstabsgetreue Zeichnung)</li><li>Änderung der Raumfunktionen</li></ul></p><p>Änderungen, die eine erneute Aufgabe der Bestellung erfordern: <ul><li>Darstellung des Grundrisses in einem anderen Layout</li><li>Änderungen, die die Gesamtstruktur des Grundrisses verändern</li></ul></p><p>Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit, ein Feedback abzugeben. Anschließend wird der Auftrag abgeschlossen und Ihre Daten nach unserem Datenverarbeitungsvertrag gelöscht. Bei Änderungswünschen muss eine neue Bestellung aufgegeben werden.</p>",
          },
        ],
        dimension: "2d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_premium_extra_feedback",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "trueToScale",
            header: "Maßstabsgetreu auf A4",
            text: "Standardmäßig werden Grundrisse so gezeichnet, dass die gesamte Fläche des Papiers bestmöglich genutzt wird, ohne hierbei einen Maßstab einzuhalten. Bei der Option „Maßstabsgetreu auf A4“ wird der Grundriss im Verhältnis 1:100 oder 1:75 auf einem DIN A4 Blatt gezeichnet (210 mm x  297 mm). Die Außenmaße des Gebäudes sollten für eine optisch ansprechende Darstellung nicht länger als 15 x 20 Meter betragen. Für größere Objekte geben Sie die gewünschte Papiergröße sowie den Maßstab bitte im Kommentarfeld an.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "modification",
            header: "Individuelle Böden",
            text: "Sie haben nun die Möglichkeit, Bodenbeläge individuell auszuwählen. Laden Sie dafür Beispiele der gewünschten Böden hoch und kennzeichnen Sie eindeutig wo diese verwendet werden sollen. Wir werden versuchen Ihre Vorgaben bestmöglich umzusetzen. Abweichungen können jedoch nicht ausgeschlossen werden.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei der Visualisierung helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen. Bitte zeichnen Sie die gewünschten Blickwinkel in die Pläne ein oder beschreiben Sie diese im Kommentarfeld.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Falls Sie unterschiedliche Böden in verschiedenen Räumen haben, können Sie unten den Kategorien die Räume den Boden zuordnen",
            title: "Was meinen wir mit Modifizieren?",
          },
          {
            keyPoints: ["Fußleisten", "Deckenleisten", "Wandfarben", "Zimmerpflanzen", "Dekoration"],
            text: "Kunden, die dieses Produkt bestellt haben, haben in der Regel immer Angaben zu folgenden Dingen gemacht: ",
            title: "Was meinen wir mit Besonderheiten?",
          },
        ],
        modification_price: "floorplan_premium_modification",
        name: "Modern-Pink 2D-Premium",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Grundriss pro Wohnung/Etage", "Individualisierungsmöglichkeiten", "Outputformat: JPG"],
        package_id: "floorplan_pink-2d-premium",
        package_images: ["grundriss-2d-premium-pink.jpeg"],
        package_icons: ["grundriss-2d-premium-pink.jpeg"],
        package_key: "53",
        price: "floorplan_price_premium_wohnen",
        package_price: {
          "0": "floorplan_price_premium_wohnen",
          "1": "floorplan_price_premium_gewerbe",
        },
        price_calculator: {
          "0": {
            step: 150,
            increment: 29,
          },
          "1": {
            step: 200,
            increment: 35,
          },
        },
        packageDescHeading: "Hochwertige 2D Grundrisse",
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          {
            baseUrl: "../assets/images/floor_plans/pink_premium/",
            featuresUrl: "../assets/images/floor_plans/features_2d-premium-pink/",
            index: 0,
            name: "Modern 2D Premium Pink",
          },
        ],
        title: "2D-Grundrisse",
        withModification: true,
        withPreRender: false,
        trueToScale_price: "floorplan_trueToScale",
        service_image: "grundriss-2d-premium-pink.jpeg",
      },
      {
        companies: ["Imogent GmbH", "^SIS-Sparkassen-Immobilien-Service GmbH"],
        delivery_time: "48 h",
        details: [
          {
            label: "Grundrissdesign",
            value:
              'Die Grundrissdesigns sind nicht änderbar. Sollten Sie Änderungswünsche bzgl. der Bodenbeläge haben, wählen Sie die Option "Individuelle Böden". Hier haben Sie die Möglichkeit alle Bodenbeläge individuell auszuwählen.',
          },
          {
            label: "Anpassungen",
            value:
              "Änderungen im Grundriss können händisch oder mit Hilfe digitaler Tools eingezeichnet werden. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.",
          },
          {
            label: "Zusatzkosten",
            value:
              "<p>Der Preis pro Grundriss gilt für Grundrisse bis 200m². Dabei sind Balkon- und Terrassenflächen mit berücksichtigt. Bei größeren Grundrissen bzw. weiteren Etagen multipliziert sich der Stückpreis entsprechend.</p><p>Die erste Feedbackschleife ist für Sie kostenfrei, sofern es sich um unten genannte, kostenfreie Änderungen handelt. Die aufgeführten, kostenpflichtigen Änderungen, werden bereits in der ersten Korrekturtschleife berechnet. Alle Änderungen ab der zweiten Korrekturschleife sind kostenpflichtig. Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.</p><p>Kostenfreie Änderungen: <ul><li>Textänderungen</li><li>Entfernen oder hinzufügen von einzelnen Möbelstücken oder Dekoelementen</li><li>Entfernen oder hinzufügen von kostenfreien Spezifikationen (Raumbezeichnungen, Logo etc.)</li><li>Strukturelle Änderungen, die keine Gesamtveränderung  des Grundrisses darstellen</li></ul></p><p>Kostenpflichtige Änderungen: <ul><li>Nachträgliches Hinzufügen kostenpflichtiger Spezifikationen (Möblierung, maßstabsgetreue Zeichnung)</li><li>Änderung der Raumfunktionen</li></ul></p><p>Änderungen, die eine erneute Aufgabe der Bestellung erfordern: <ul><li>Darstellung des Grundrisses in einem anderen Layout</li><li>Änderungen, die die Gesamtstruktur des Grundrisses verändern</li></ul></p><p>Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit, ein Feedback abzugeben. Anschließend wird der Auftrag abgeschlossen und Ihre Daten nach unserem Datenverarbeitungsvertrag gelöscht. Bei Änderungswünschen muss eine neue Bestellung aufgegeben werden.</p>",
          },
        ],
        dimension: "2d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_sis_extra_feedback",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "trueToScale",
            header: "Maßstabsgetreu auf A4",
            text: "Standardmäßig werden Grundrisse so gezeichnet, dass die gesamte Fläche des Papiers bestmöglich genutzt wird, ohne hierbei einen Maßstab einzuhalten. Bei der Option „Maßstabsgetreu auf A4“ wird der Grundriss im Verhältnis 1:100 oder 1:75 auf einem DIN A4 Blatt gezeichnet (210 mm x  297 mm). Die Außenmaße des Gebäudes sollten für eine optisch ansprechende Darstellung nicht länger als 15 x 20 Meter betragen. Für größere Objekte geben Sie die gewünschte Papiergröße sowie den Maßstab bitte im Kommentarfeld an.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "modification",
            header: "Individuelle Böden",
            text: "Sie haben nun die Möglichkeit, Bodenbeläge individuell auszuwählen. Laden Sie dafür Beispiele der gewünschten Böden hoch und kennzeichnen Sie eindeutig wo diese verwendet werden sollen. Wir werden versuchen Ihre Vorgaben bestmöglich umzusetzen. Abweichungen können jedoch nicht ausgeschlossen werden.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei der Visualisierung helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen. Bitte zeichnen Sie die gewünschten Blickwinkel in die Pläne ein oder beschreiben Sie diese im Kommentarfeld.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Falls Sie unterschiedliche Böden in verschiedenen Räumen haben, können Sie unten den Kategorien die Räume den Boden zuordnen",
            title: "Was meinen wir mit Modifizieren?",
          },
          {
            keyPoints: ["Fußleisten", "Deckenleisten", "Wandfarben", "Zimmerpflanzen", "Dekoration"],
            text: "Kunden, die dieses Produkt bestellt haben, haben in der Regel immer Angaben zu folgenden Dingen gemacht: ",
            title: "Was meinen wir mit Besonderheiten?",
          },
        ],
        modification_price: "floorplan_sis_modification",
        name: "SIS",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Grundriss pro Wohnung/Etage", "Individualisierungsmöglichkeiten", "Outputformat: JPG"],
        package_id: "floorplan_sis-2d",
        package_images: ["grundriss-2d-sis.jpeg"],
        package_icons: ["grundriss-2d-sis.jpeg"],
        package_key: "56",
        price: "floorplan_price_sis_wohnen",
        package_price: {
          "0": "floorplan_price_sis_wohnen",
          "1": "floorplan_price_sis_gewerbe",
        },
        price_calculator: {
          "0": {
            step: 150,
            increment: 29,
          },
          "1": {
            step: 200,
            increment: 35,
          },
        },
        packageDescHeading: "Hochwertige 2D Grundrisse",
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          {
            baseUrl: "../assets/images/floor_plans/sis/",
            featuresUrl: "../assets/images/floor_plans/features_2d_sis/",
            index: 0,
            name: "SIS",
          },
        ],
        title: "2D-Grundrisse",
        withModification: true,
        withPreRender: false,
        trueToScale_price: "floorplan_trueToScale",
        service_image: "grundriss-2d-sis.jpeg",
      },
      {
        companies: ["Imogent GmbH", "^PlanetHome"],
        delivery_time: "48 h",
        details: [
          {
            label: "Grundrissdesign",
            value:
              'Die Grundrissdesigns sind nicht änderbar. Sollten Sie Änderungswünsche bzgl. der Bodenbeläge haben, wählen Sie die Option "Individuelle Böden". Hier haben Sie die Möglichkeit alle Bodenbeläge individuell auszuwählen.',
          },
          {
            label: "Anpassungen",
            value:
              "Änderungen im Grundriss können händisch oder mit Hilfe digitaler Tools eingezeichnet werden. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.",
          },
          {
            label: "Zusatzkosten",
            value:
              "<p>Der Preis pro Grundriss gilt für Grundrisse bis 200m². Dabei sind Balkon- und Terrassenflächen mit berücksichtigt. Bei größeren Grundrissen bzw. weiteren Etagen multipliziert sich der Stückpreis entsprechend.</p><p>Die erste Feedbackschleife ist für Sie kostenfrei, sofern es sich um unten genannte, kostenfreie Änderungen handelt. Die aufgeführten, kostenpflichtigen Änderungen, werden bereits in der ersten Korrekturtschleife berechnet. Alle Änderungen ab der zweiten Korrekturschleife sind kostenpflichtig. Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.</p><p>Kostenfreie Änderungen: <ul><li>Textänderungen</li><li>Entfernen oder hinzufügen von einzelnen Möbelstücken oder Dekoelementen</li><li>Entfernen oder hinzufügen von kostenfreien Spezifikationen (Raumbezeichnungen, Logo etc.)</li><li>Strukturelle Änderungen, die keine Gesamtveränderung  des Grundrisses darstellen</li></ul></p><p>Kostenpflichtige Änderungen: <ul><li>Nachträgliches Hinzufügen kostenpflichtiger Spezifikationen (Möblierung, maßstabsgetreue Zeichnung)</li><li>Änderung der Raumfunktionen</li></ul></p><p>Änderungen, die eine erneute Aufgabe der Bestellung erfordern: <ul><li>Darstellung des Grundrisses in einem anderen Layout</li><li>Änderungen, die die Gesamtstruktur des Grundrisses verändern</li></ul></p><p>Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit, ein Feedback abzugeben. Anschließend wird der Auftrag abgeschlossen und Ihre Daten nach unserem Datenverarbeitungsvertrag gelöscht. Bei Änderungswünschen muss eine neue Bestellung aufgegeben werden.</p>",
          },
        ],
        dimension: "2d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_planethome_extra_feedback",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "trueToScale",
            header: "Maßstabsgetreu auf A4",
            text: "Standardmäßig werden Grundrisse so gezeichnet, dass die gesamte Fläche des Papiers bestmöglich genutzt wird, ohne hierbei einen Maßstab einzuhalten. Bei der Option „Maßstabsgetreu auf A4“ wird der Grundriss im Verhältnis 1:100 oder 1:75 auf einem DIN A4 Blatt gezeichnet (210 mm x  297 mm). Die Außenmaße des Gebäudes sollten für eine optisch ansprechende Darstellung nicht länger als 15 x 20 Meter betragen. Für größere Objekte geben Sie die gewünschte Papiergröße sowie den Maßstab bitte im Kommentarfeld an.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "modification",
            header: "Individuelle Böden",
            text: "Sie haben nun die Möglichkeit, Bodenbeläge individuell auszuwählen. Laden Sie dafür Beispiele der gewünschten Böden hoch und kennzeichnen Sie eindeutig wo diese verwendet werden sollen. Wir werden versuchen Ihre Vorgaben bestmöglich umzusetzen. Abweichungen können jedoch nicht ausgeschlossen werden.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei der Visualisierung helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen. Bitte zeichnen Sie die gewünschten Blickwinkel in die Pläne ein oder beschreiben Sie diese im Kommentarfeld.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Falls Sie unterschiedliche Böden in verschiedenen Räumen haben, können Sie unten den Kategorien die Räume den Boden zuordnen",
            title: "Was meinen wir mit Modifizieren?",
          },
          {
            keyPoints: ["Fußleisten", "Deckenleisten", "Wandfarben", "Zimmerpflanzen", "Dekoration"],
            text: "Kunden, die dieses Produkt bestellt haben, haben in der Regel immer Angaben zu folgenden Dingen gemacht: ",
            title: "Was meinen wir mit Besonderheiten?",
          },
        ],
        modification_price: "floorplan_planethome_modification",
        name: "PlanetHome",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Grundriss pro Wohnung/Etage", "Individualisierungsmöglichkeiten", "Outputformat: JPG"],
        package_id: "floorplan_planethome-2d",
        package_images: ["grundriss_planethome.jpeg"],
        package_icons: ["grundriss_planethome.jpeg"],
        package_key: "57",
        price: "floorplan_price_planethome_wohnen",
        package_price: {
          "0": "floorplan_price_planethome_wohnen",
          "1": "floorplan_price_planethome_gewerbe",
        },
        price_calculator: {
          "0": {
            step: 150,
            increment: 29,
          },
          "1": {
            step: 200,
            increment: 35,
          },
        },
        packageDescHeading: "Hochwertige 2D Grundrisse",
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          {
            baseUrl: "../assets/images/floor_plans/planet-home/",
            featuresUrl: "../assets/images/floor_plans/features_planet-home/",
            index: 0,
            name: "PlanetHome",
          },
        ],
        title: "2D-Grundrisse",
        withModification: true,
        withPreRender: false,
        trueToScale_price: "floorplan_trueToScale",
        service_image: "grundriss_planethome.jpeg",
      },
      {
        companies: ["Imogent GmbH", "^Profimeo Gruppe GmbH"],
        delivery_time: "48 h",
        details: [
          {
            label: "Grundrissdesign",
            value:
              'Die Grundrissdesigns sind nicht änderbar. Sollten Sie Änderungswünsche bzgl. der Bodenbeläge haben, wählen Sie die Option "Individuelle Böden". Hier haben Sie die Möglichkeit alle Bodenbeläge individuell auszuwählen.',
          },
          {
            label: "Anpassungen",
            value:
              "Änderungen im Grundriss können händisch oder mit Hilfe digitaler Tools eingezeichnet werden. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.",
          },
          {
            label: "Zusatzkosten",
            value:
              "<p>Der Preis pro Grundriss gilt für Grundrisse bis 200m². Dabei sind Balkon- und Terrassenflächen mit berücksichtigt. Bei größeren Grundrissen bzw. weiteren Etagen multipliziert sich der Stückpreis entsprechend.</p><p>Die erste Feedbackschleife ist für Sie kostenfrei, sofern es sich um unten genannte, kostenfreie Änderungen handelt. Die aufgeführten, kostenpflichtigen Änderungen, werden bereits in der ersten Korrekturtschleife berechnet. Alle Änderungen ab der zweiten Korrekturschleife sind kostenpflichtig. Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.</p><p>Kostenfreie Änderungen: <ul><li>Textänderungen</li><li>Entfernen oder hinzufügen von einzelnen Möbelstücken oder Dekoelementen</li><li>Entfernen oder hinzufügen von kostenfreien Spezifikationen (Raumbezeichnungen, Logo etc.)</li><li>Strukturelle Änderungen, die keine Gesamtveränderung  des Grundrisses darstellen</li></ul></p><p>Kostenpflichtige Änderungen: <ul><li>Nachträgliches Hinzufügen kostenpflichtiger Spezifikationen (Möblierung, maßstabsgetreue Zeichnung)</li><li>Änderung der Raumfunktionen</li></ul></p><p>Änderungen, die eine erneute Aufgabe der Bestellung erfordern: <ul><li>Darstellung des Grundrisses in einem anderen Layout</li><li>Änderungen, die die Gesamtstruktur des Grundrisses verändern</li></ul></p><p>Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit, ein Feedback abzugeben. Anschließend wird der Auftrag abgeschlossen und Ihre Daten nach unserem Datenverarbeitungsvertrag gelöscht. Bei Änderungswünschen muss eine neue Bestellung aufgegeben werden.</p>",
          },
        ],
        dimension: "2d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_profimeo_extra_feedback",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "trueToScale",
            header: "Maßstabsgetreu auf A4",
            text: "Standardmäßig werden Grundrisse so gezeichnet, dass die gesamte Fläche des Papiers bestmöglich genutzt wird, ohne hierbei einen Maßstab einzuhalten. Bei der Option „Maßstabsgetreu auf A4“ wird der Grundriss im Verhältnis 1:100 oder 1:75 auf einem DIN A4 Blatt gezeichnet (210 mm x  297 mm). Die Außenmaße des Gebäudes sollten für eine optisch ansprechende Darstellung nicht länger als 15 x 20 Meter betragen. Für größere Objekte geben Sie die gewünschte Papiergröße sowie den Maßstab bitte im Kommentarfeld an.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "modification",
            header: "Individuelle Böden",
            text: "Sie haben nun die Möglichkeit, Bodenbeläge individuell auszuwählen. Laden Sie dafür Beispiele der gewünschten Böden hoch und kennzeichnen Sie eindeutig wo diese verwendet werden sollen. Wir werden versuchen Ihre Vorgaben bestmöglich umzusetzen. Abweichungen können jedoch nicht ausgeschlossen werden.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei der Visualisierung helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen. Bitte zeichnen Sie die gewünschten Blickwinkel in die Pläne ein oder beschreiben Sie diese im Kommentarfeld.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Falls Sie unterschiedliche Böden in verschiedenen Räumen haben, können Sie unten den Kategorien die Räume den Boden zuordnen",
            title: "Was meinen wir mit Modifizieren?",
          },
          {
            keyPoints: ["Fußleisten", "Deckenleisten", "Wandfarben", "Zimmerpflanzen", "Dekoration"],
            text: "Kunden, die dieses Produkt bestellt haben, haben in der Regel immer Angaben zu folgenden Dingen gemacht: ",
            title: "Was meinen wir mit Besonderheiten?",
          },
        ],
        modification_price: "floorplan_profimeo_modification",
        name: "Profimeo",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Grundriss pro Wohnung/Etage", "Individualisierungsmöglichkeiten", "Outputformat: JPG"],
        package_id: "floorplan_profimeo-2d",
        package_images: ["grundriss_profimeo.jpeg"],
        package_icons: ["grundriss_profimeo.jpeg"],
        package_key: "62",
        price: "floorplan_price_profimeo_wohnen",
        package_price: {
          "0": "floorplan_price_profimeo_wohnen",
          "1": "floorplan_price_profimeo_gewerbe",
        },
        price_calculator: {
          "0": {
            step: 150,
            increment: 29,
          },
          "1": {
            step: 200,
            increment: 35,
          },
        },
        packageDescHeading: "Hochwertige 2D Grundrisse",
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          {
            baseUrl: "../assets/images/floor_plans/profimeo/",
            featuresUrl: "../assets/images/floor_plans/features_profimeo/",
            index: 0,
            name: "PROFIMEO",
          },
        ],
        title: "2D-Grundrisse",
        withModification: false,
        withPreRender: false,
        trueToScale_price: "floorplan_trueToScale",
        service_image: "grundriss_profimeo.jpeg",
      },
      {
        companies: ["Imogent GmbH", "Schmidt & Schurig Immobilien GmbH"],
        delivery_time: "48 h",
        details: [
          {
            label: "Grundrissdesign",
            value:
              'Die Grundrissdesigns sind nicht änderbar. Sollten Sie Änderungswünsche bzgl. der Bodenbeläge haben, wählen Sie die Option "Individuelle Böden". Hier haben Sie die Möglichkeit alle Bodenbeläge individuell auszuwählen.',
          },
          {
            label: "Anpassungen",
            value:
              "Änderungen im Grundriss können händisch oder mit Hilfe digitaler Tools eingezeichnet werden. Dabei ist es wichtig, dass Bezeichnungen nachvollziehbar und lesbar sind.",
          },
          {
            label: "Zusatzkosten",
            value:
              "<p>Der Preis pro Grundriss gilt für Grundrisse bis 200m². Dabei sind Balkon- und Terrassenflächen mit berücksichtigt. Bei größeren Grundrissen bzw. weiteren Etagen multipliziert sich der Stückpreis entsprechend.</p><p>Die erste Feedbackschleife ist für Sie kostenfrei, sofern es sich um unten genannte, kostenfreie Änderungen handelt. Die aufgeführten, kostenpflichtigen Änderungen, werden bereits in der ersten Korrekturtschleife berechnet. Alle Änderungen ab der zweiten Korrekturschleife sind kostenpflichtig. Korrekturen von Fehlern, die von IMOGENT verursacht wurden, sind selbstverständlich in allen Korrekturschleifen kostenfrei.</p><p>Kostenfreie Änderungen: <ul><li>Textänderungen</li><li>Entfernen oder hinzufügen von einzelnen Möbelstücken oder Dekoelementen</li><li>Entfernen oder hinzufügen von kostenfreien Spezifikationen (Raumbezeichnungen, Logo etc.)</li><li>Strukturelle Änderungen, die keine Gesamtveränderung  des Grundrisses darstellen</li></ul></p><p>Kostenpflichtige Änderungen: <ul><li>Nachträgliches Hinzufügen kostenpflichtiger Spezifikationen (Möblierung, maßstabsgetreue Zeichnung)</li><li>Änderung der Raumfunktionen</li></ul></p><p>Änderungen, die eine erneute Aufgabe der Bestellung erfordern: <ul><li>Darstellung des Grundrisses in einem anderen Layout</li><li>Änderungen, die die Gesamtstruktur des Grundrisses verändern</li></ul></p><p>Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit, ein Feedback abzugeben. Anschließend wird der Auftrag abgeschlossen und Ihre Daten nach unserem Datenverarbeitungsvertrag gelöscht. Bei Änderungswünschen muss eine neue Bestellung aufgegeben werden.</p>",
          },
        ],
        dimension: "2d",
        disabled: false,
        extra_details: "Unterschiedliche Designs können im nächsten Schritt ausgewählt werden",
        extra_feedback_price: "floorplan_schmidt_schurig_extra_feedback",
        feature_samples: [
          {
            field: "floorplan",
            header: "Anzahl der Grundrisse",
            text: "<strong>Beispiel 1:</strong> zwei Wohnungen auf einer Etage = 2 Grundrisse<br><strong>Beispiel 2:</strong> DHH mit zwei Etagen = 2 Grundrisse",
            samples: [],
          },
          {
            field: "mobliert",
            header: "Möblierung",
            text: "Die Möblierung wird vollständig aus dem Grundriss übernommen. Sollte keine Möblierung eingezeichnet sein, werden die Räume nach bestem Gewissen eingerichtet. Es besteht auch die Möglichkeit die Möbel zu skizzieren. Ein nachträgliches Einfügen der <strong>Möblierung</strong> entspricht einer Layoutänderung und wird mit 10,00€ pro Grundriss berechnet. <br/><br/><strong>Beispiele für eine gut skizzierte Möblierung:</strong>",
            samples: ["floor_plans/samples/mobliert/1.jpg", "floor_plans/samples/mobliert/2.jpg"],
          },
          {
            field: "sqrmeter",
            header: "Quadratmeterangaben",
            text: {
              "0": 'Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. Sollten die Flächen neu berechnet werden, wählen Sie die Option "Flächenberechnung" aus. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>',
              "1": "Bitte achten Sie darauf, dass alle Quadratmeterangaben in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Quadratmeterangaben handschriftlich einzutragen. <br/><br/><strong>Beispiele für gut verwertbare Quadratmeterangaben:</strong>",
            },
            samples: ["floor_plans/samples/sqrmeter/1.jpg", "floor_plans/samples/sqrmeter/2.jpg"],
          },
          {
            field: "nordpfeil",
            header: "Nordpfeil",
            text: "Bitte achten Sie darauf, dass auf jedem Grundriss der Nordpfeil vorhanden und vollständig zu erkennen ist. Es besteht auch die Möglichkeit den Nordpfeil handschriftlich in den Grundriss einzuzeichnen. <br/><br/><strong>Beispiele für eine gut gekennzeichnete Grundrissausrichtung:</strong>",
            samples: [
              "floor_plans/samples/nordpfeil/1.jpg",
              "floor_plans/samples/nordpfeil/2.jpg",
              "floor_plans/samples/nordpfeil/3.jpg",
              "floor_plans/samples/nordpfeil/4.jpg",
            ],
          },
          {
            field: "raumbezeichnung",
            header: "Raumbezeichnungen",
            text: "Bitte achten Sie darauf, dass alle Raumbezeichnungen in dem Grundriss vorhanden und vollständig zu erkennen sind. Es besteht auch die Möglichkeit die Raumbezeichnungen handschriftlich einzutragen. <br/><br/><strong>Beispiele für deutlich erkennbare Raumbezeichnungen:</strong>",
            samples: ["floor_plans/samples/raumbezeichnung/1.jpg", "floor_plans/samples/raumbezeichnung/2.jpg"],
          },
          {
            field: "apartmentName",
            header: "Wohnungsbezeichnungen",
            text: "Die einzelnen Wohnungen werden gemäß der Vorlage am Eingang mit bspw. Nummern markiert.",
            samples: [],
          },
          {
            field: "trueToScale",
            header: "Maßstabsgetreu auf A4",
            text: "Standardmäßig werden Grundrisse so gezeichnet, dass die gesamte Fläche des Papiers bestmöglich genutzt wird, ohne hierbei einen Maßstab einzuhalten. Bei der Option „Maßstabsgetreu auf A4“ wird der Grundriss im Verhältnis 1:100 oder 1:75 auf einem DIN A4 Blatt gezeichnet (210 mm x  297 mm). Die Außenmaße des Gebäudes sollten für eine optisch ansprechende Darstellung nicht länger als 15 x 20 Meter betragen. Für größere Objekte geben Sie die gewünschte Papiergröße sowie den Maßstab bitte im Kommentarfeld an.",
            samples: [],
          },
          {
            field: "floorplanName",
            header: "Grundrissbezeichnungen",
            text: "Standardmäßig wird die Grundrissbezeichnung links über dem Grundriss platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Die Bezeichnung bitte ebenfalls im Kommentarfeld angeben, sofern die bestehenden Angaben nicht übernommen werden sollen oder nicht eindeutig sind.",
            samples: [],
          },
          {
            field: "logo",
            header: "Logo",
            text: "Standardmäßig wird das Logo in einer der beiden unteren Ecken platziert. Sonderwünsche können Sie im Kommentarfeld vermerken. Sollten Sie standardmäßig kein Logo in Ihrem Profil hinterlegt haben, können Sie dieses im vorgesehenen Uploadfeld im dritten Prozessschritt hochladen.",
            samples: [],
          },
          {
            field: "alignNorth",
            header: "Nach Norden ausrichten",
            text: "Bei dieser Option wird der Grundriss so ausgerichtet, dass Norden oben ist. Dabei kann es jedoch vorkommen, dass die vorhandene Fläche nicht optimal genutzt wird. Wir empfehlen diese Option nicht bei großen Plänen.",
            samples: [],
          },
          {
            field: "modification",
            header: "Individuelle Böden",
            text: "Sie haben nun die Möglichkeit, Bodenbeläge individuell auszuwählen. Laden Sie dafür Beispiele der gewünschten Böden hoch und kennzeichnen Sie eindeutig wo diese verwendet werden sollen. Wir werden versuchen Ihre Vorgaben bestmöglich umzusetzen. Abweichungen können jedoch nicht ausgeschlossen werden.",
            samples: [],
          },
          {
            field: "meterBars",
            header: "Meterbalken",
            text: "Bitte beachten Sie, dass für diese Option eine vollständige Bemaßung vorliegen muss.",
            samples: [],
          },
          {
            field: "measurements",
            header: "Flächenberechnung",
            text: "Die Flächenberechnung ist weiterhin verfügbar und ist nun unter der Kachel “Wohnflächenberechnung” im Bereich Service Buchen zu finden.",
            samples: [],
          },
        ],
        feedbacks_included: 1,
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei der Visualisierung helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen. Bitte zeichnen Sie die gewünschten Blickwinkel in die Pläne ein oder beschreiben Sie diese im Kommentarfeld.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Falls Sie unterschiedliche Böden in verschiedenen Räumen haben, können Sie unten den Kategorien die Räume den Boden zuordnen",
            title: "Was meinen wir mit Modifizieren?",
          },
          {
            keyPoints: ["Fußleisten", "Deckenleisten", "Wandfarben", "Zimmerpflanzen", "Dekoration"],
            text: "Kunden, die dieses Produkt bestellt haben, haben in der Regel immer Angaben zu folgenden Dingen gemacht: ",
            title: "Was meinen wir mit Besonderheiten?",
          },
        ],
        modification_price: "floorplan_schmidt_schurig_modification",
        name: "Schmidt & Schurig",
        newProperty: true,
        numberOfDrafts: 0,
        package_contents: ["Ein Grundriss pro Wohnung/Etage", "Individualisierungsmöglichkeiten", "Outputformat: JPG"],
        package_id: "floorplan_schmidt_schurig-2d",
        package_images: ["grundriss_schmidt_schurig.png"],
        package_icons: ["grundriss_schmidt_schurig.png"],
        package_key: "63",
        price: "floorplan_price_schmidt_schurig_wohnen",
        package_price: {
          "0": "floorplan_price_schmidt_schurig_wohnen",
          "1": "floorplan_price_schmidt_schurig_gewerbe",
        },
        packageDescHeading: "Hochwertige 2D Grundrisse",
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Floor_Plan,
        service_title: "Grundrisse",
        styles: [
          {
            baseUrl: "../assets/images/floor_plans/schmidt_schurig/",
            featuresUrl: "../assets/images/floor_plans/features_schmidt_schurig/",
            index: 0,
            name: "Schmidt & Schurig",
          },
        ],
        title: "2D-Grundrisse",
        withModification: false,
        withPreRender: false,
        trueToScale_price: "floorplan_trueToScale",
        service_image: "grundriss_schmidt_schurig.png",
      },
    ],
    packageDescription:
      '<p><span style="color: #22BCA4"><strong>Grundrisse</strong></span> als Basis Ihrer Vermarktung erhalten Sie in <b>2D oder 3D, klassisch in schwarz-grau oder in Farbe</b>. Ihnen stehen verschiedene Designs zur Auswahl, sie können zwischen privater und gewerblicher Variante wählen und haben diverse Zusatzoptionen (Bemaßung, Beschriftung uvm.) zur Auswahl.</p><p><b>Laden Sie einfach Ihren Grundriss als Bild oder pdf hoch und wählen Sie die gewünschten Zusatzoptionen aus.</b></p><p>Gerne erstellen wir Grundrisse auch in Ihrem eigenen Design! Für eine Individualisierung kontaktieren Sie uns gerne.</p><p><i>Beliebte Produktkombinationen:</i> Bildbearbeitung, Digital Staging, Videotour</p>',
    packageLink: "https://www.imogent.de/grundrisse",
  },
  {
    displayPosition: 5,
    id: OrderTypeEnum.Hd_Photos,
    packages: [
      {
        companies: [
          "Imogent GmbH",
          "^DAHLER Berlin-Wannsee$",
          "^DAHLER Bielefeld / Gütersloh$",
          "^DAHLER Stuttgart$",
          "^DAHLER Tegernsee - Bad Tölz$",
          "^DAHLER Darmstadt$",
          "^DAHLER Ulm$",
        ],
        details: [
          {
            label: "Aufnahmedauer",
            value:
              'Die durchschnittliche Aufnahmedauer vor Ort ist <a href="https://www.imogent.de/dauer-aufnahmen" target="_blank">hier</a> einsehbar. Bitte planen Sie genug Zeit ein, damit unsere Dienstleister ein optimales Ergebnis erzielen können.',
          },
          {
            label: "Bearbeitungszeiten",
            value:
              "In der Regel finden wir innerhalb von 2 Werktagen eine/n geeignete/n Dienstleister:in für Sie. In Randgebieten kann es jedoch vorkommen, dass unser Netzwerk erweitert werden muss. Dies kann bis zu 7 Werktage in Anspruch nehmen. Sollte das bei Ihrer Bestellung erforderlich sein, informieren wir Sie rechtzeitig und es besteht die Möglichkeit Ihre Bestellung kostenfrei zu stornieren.",
          },
          {
            label: "Stornierungsgebühren",
            value:
              "<strong>Bis zu 72h vor dem geplanten Termin:</strong> kostenfrei<br><strong>Bis zu 24h vor dem geplanten Termin:</strong> 50% des Rechnungsbetrages<br><strong>Weniger als 24h vor dem geplanten Termin:</strong> 100% des Rechnungsbetrages",
          },
          {
            label: "Privatsphäre",
            value:
              "Bei Fotos im Innen- und Außenbereich werden vertrauliche Details wie private Fotos, lesbare Dokumente und Kennzeichen unkenntlich gemacht.",
          },
          {
            label: "Ausgabeformat",
            value:
              "Sie erhalten die Fotos im JPG Format und haben die Möglichkeit diese in unterschiedlichen Auflösungen herunterzuladen.",
          },
        ],
        dateArrangementRequired: true,
        disabled: false,
        infoMessages: [
          {
            label: "Produktdetails",
            value:
              "Sie erhalten weitwinklige und aussagekräftige Immobilienfotos. Außenfotos sind je nach Wetterbedingungen und individueller Absprachen enthalten. Sollten lediglich Fotos im Querformat gewünscht sein, teilen Sie uns dies bitte über das Kommentarfeld mit.",
          },
          {
            label: "Terminvereinbarung",
            value:
              "Sobald Sie den Buchungsvorgang abgeschlossen haben, leiten wir Ihre Anfrage an Backbone weiter. Je nach Präferenz werden Sie von einem Mitarbeiter zur Terminvereinbarung kontaktiert oder erhalten eine Bestätigung Ihres Wunschtermins per Email.",
          },
          {
            label: "Vorbereitung",
            value:
              'Bitte sorgen Sie dafür, dass die Immobilie gut vorbereitet ist und beachten Sie dazu unbedingt unsere <a href="https://app.imogent.com/assets/documents/services/Vorbereitungshilfen.zip" target="_blank">Vorbereitungshilfen</a> für Makler und Eigentümer.',
          },
          {
            label: "Stornierung",
            value:
              "Bitte beachten Sie unsere Stornierungsbedingungen in der linken Seitenleiste. Eine kostenfreie Stornierung ist lediglich bis zu 72h vor dem geplanten Aufnahmetermin möglich.",
          },
        ],
        name: "Backbone Fotografie",
        newProperty: false,
        numberOfDrafts: 0,
        package_contents: ["Professionelle Immobilienfotos", "Inkl. Nachbearbeitung", "Hochauflösend"],
        package_id: "hd_photos_backbone",
        package_images: [
          "hd_fotos/backbone/backbone_01.jpg",
          "hd_fotos/backbone/backbone_02.jpg",
          "hd_fotos/backbone/backbone_03.jpg",
          "hd_fotos/backbone/backbone_04.jpg",
          "hd_fotos/backbone/backbone_05.jpg",
        ],
        package_icons: [],
        package_key: "55",
        packageDescHeading: "Backbone Immobilienfotos",
        photo_price: {
          0: ["hdphoto_backbone_add_5_price", "hdphoto_backbone_add_10_price"],
        },
        qty_price_options: {
          0: {
            defaultQty: 10,
            options: [
              {
                title: "10 Fotos",
                value: "1",
                addQty: 0,
                price: "hdphoto_backbone_price",
              },
              {
                title: "15 Fotos",
                value: "2",
                addQty: 5,
                price: "hdphoto_backbone_add_5_price",
              },
              {
                title: "20 Fotos",
                value: "3",
                addQty: 10,
                price: "hdphoto_backbone_add_10_price",
              },
            ],
          },
        },
        phsysical_products: false,
        price: "hdphoto_backbone_price",
        extra_feedback_price: "hdphoto_extra_feedback",
        feedbacks_included: -1,
        price_note: "(Preis exkl. MwSt.)",
        service_key: OrderTypeEnum.Hd_Photos,
        service_title: "Fotografie",
        title: "Backbone Fotografie",
        service_image: "service_images/Fotografie.png",
      },
      {
        details: [
          {
            label: "Nachbearbeitung",
            value:
              "In der Nachbearbeitung werden Helligkeit, Farbgebung und Winkel des Bildes optimal angepasst. Weitere Spezifikationen können im Bestellprozess ergänzt werden.",
          },
          {
            label: "Aufnahmedauer",
            value:
              'Die durchschnittliche Aufnahmedauer vor Ort ist <a href="https://www.imogent.de/dauer-aufnahmen" target="_blank">hier</a> einsehbar. Bitte planen Sie genug Zeit ein, damit vor Ort ein optimales Ergebnis erzielt werden kann.',
          },
          {
            label: "Bearbeitungszeiten",
            value:
              'In der Regel finden wir innerhalb von zwei Werktagen einen geeigneten Dienstleister für Sie. Die Bearbeitungszeiten für die Nachbearbeitung sind <a href="https://www.imogent.de/bearbeitungszeiten/" target="_blank">hier</a> einsehbar.',
          },
          {
            label: "Zusatzkosten",
            value:
              "Verzögerungen, die durch die Erbringung zusätzlicher Leistungen vor Ort entstehen (z.B. das Aufräumen einer schlecht vorbereiteten Immobilie, Aufnahmen in mehr als einer abgeschlossenen Wohneinheit bzw. in mehr als einem Objekt), werden mit 60,00€ pro angefangenen 30 Minuten in Rechnung gestellt.",
          },
          {
            label: "Stornierungsbedigungen",
            value: cancellationPolicyPhotographyProducts,
          },
          {
            label: "Ausgabeformat",
            value:
              "Sie erhalten die Fotos im JPG Format und haben die Möglichkeit diese in unterschiedlichen Auflösungen herunterzuladen.",
          },
        ],
        dateArrangementRequired: true,
        disabled: false,
        infoMessages: [
          {
            label: "Produktdetails",
            value:
              "Sie erhalten weitwinklige und aussagekräftige Immobilienfotos. Außenfotos sind je nach Wetterbedingungen und individueller Absprachen enthalten.",
          },
          {
            label: "Terminvereinbarung",
            value:
              "Sobald Sie den Buchungsvorgang abgeschlossen haben, prüfen wir die Verfügbarkeit unserer Fotografen. Sie erhalten innerhalb von zwei Werktagen eine Rückmeldung zum Bearbeitungsstatus und die angegebene Kontaktperson wird bei Verfügbarkeit umgehend kontaktiert.",
          },
          {
            label: "Vorbereitung",
            value: `Bitte sorgen Sie dafür, dass die Immobilie gut vorbereitet ist und beachten Sie dazu unbedingt unsere <a href=${CONSTANTS.ASSETS.PHOTOGRAPHY.PREPARATION_TIPS}>Vorbereitungshilfen</a> für Makler und Eigentümer. Aufräumarbeiten, die von unseren Fotografen durchgeführt werden müssen und eine halbe Stunde übersteigen, werden mit 60,00€ pro angefangenen 30 Minuten in Rechnung gestellt.`,
          },
          {
            label: "Stornierung",
            value: cancellationHintPhotographyProducts,
          },
        ],
        name: CONSTANTS.PACKAGE_NAMES[OrderTypeEnum.Hd_Photos].HD_PHOTOS_REALESTATE,
        newProperty: false,
        numberOfDrafts: 0,
        package_contents: ["Professionelle Immobilienfotos", "Inkl. Nachbearbeitung", "Hochauflösend"],
        package_id: "hd_photos_realestate",
        package_images: ["hd_fotos/inside_1.jpg", "hd_fotos/inside_2.jpg"],
        package_icons: ["hd_fotos/Immobilienfotos.svg"],
        package_key: "23",
        packageDescHeading: "Hochwertige Immobilienfotos",
        photo_price: {
          0: ["hdphoto_add_5_price", "hdphoto_add_10_price"],
          2: [
            "hdphoto_add_5_price",
            "hdphoto_add_10_price",
            "hdphoto_add_15_price",
            "hdphoto_option_10_photo_3_objects",
            "hdphoto_option_25_photo_3_objects",
          ],
        },
        qty_price_options: {
          0: {
            defaultQty: 10,
            options: [
              {
                title: "10 Fotos",
                value: "1",
                addQty: 0,
                price: "hdphoto_price",
              },
              {
                title: "15 Fotos",
                value: "2",
                addQty: 5,
                price: "hdphoto_add_5_price",
              },
              {
                title: "20 Fotos",
                value: "3",
                addQty: 10,
                price: "hdphoto_add_10_price",
              },
            ],
          },
          2: {
            defaultQty: 5,
            options: [
              {
                title: "5 Fotos",
                value: "1",
                addQty: 0,
                price: "hdphoto_price",
              },
              {
                title: "10 Fotos",
                value: "2",
                addQty: 5,
                price: "hdphoto_add_5_price",
              },
              {
                title: "15 Fotos",
                value: "3",
                addQty: 10,
                price: "hdphoto_add_10_price",
              },
              {
                title: "20 Fotos",
                value: "4",
                addQty: 15,
                price: "hdphoto_add_15_price",
              },
              {
                title: "10 Fotos (von 3 Objekten)",
                value: "5",
                addQty: 5,
                price: "hdphoto_option_10_photo_3_objects",
              },
              {
                title: "25 Fotos (von 3 Objekten)",
                value: "6",
                addQty: 20,
                price: "hdphoto_option_25_photo_3_objects",
              },
            ],
          },
        },
        phsysical_products: false,
        price: "hdphoto_price",
        price_three_object_options: ["hdphoto_option_10_photo_3_objects", "hdphoto_option_25_photo_3_objects"],
        extra_feedback_price: "hdphoto_extra_feedback",
        feedbacks_included: -1,
        price_note: "(Preis exkl. MwSt.)",
        retouchingForbiddenCompanyIds: [],
        service_key: OrderTypeEnum.Hd_Photos,
        service_title: "Fotografie",
        title: "Fotografie",
        service_image: "service_images/Fotografie.svg",
      },
      {
        details: [
          {
            label: "Nachbearbeitung",
            value:
              "In der Nachbearbeitung werden Helligkeit, Farbgebung und Winkel des Bildes optimal angepasst. Weitere Spezifikationen können im Bestellprozess ergänzt werden.",
          },
          {
            label: "Aufnahmedauer",
            value:
              'Die durchschnittliche Aufnahmedauer vor Ort ist <a href="https://www.imogent.de/dauer-aufnahmen" target="_blank">hier</a> einsehbar.  Bitte planen Sie genug Zeit ein, damit vor Ort ein optimales Ergebnis erzielt werden kann.',
          },
          {
            label: "Bearbeitungszeiten",
            value:
              'In der Regel finden wir innerhalb von zwei Werktagen einen geeigneten Dienstleister für Sie. Die Bearbeitungszeiten für die Nachbearbeitung sind <a href="https://www.imogent.de/bearbeitungszeiten/" target="_blank">hier</a> einsehbar.',
          },
          {
            label: "Zusatzkosten",
            value:
              "Verzögerungen, die durch die Erbringung zusätzlicher Leistungen vor Ort entstehen (z.B. das Aufräumen einer schlecht vorbereiteten Immobilie, Aufnahmen in mehr als einer abgeschlossenen Wohneinheit bzw. in mehr als einem Objekt), werden mit 60,00€ pro angefangenen 30 Minuten in Rechnung gestellt.",
          },
          {
            label: "Stornierungsbedigungen",
            value: cancellationPolicyPhotographyProducts,
          },
          {
            label: "Ausgabeformat",
            value:
              "Sie erhalten die Fotos im JPG Format und haben die Möglichkeit diese in unterschiedlichen Auflösungen herunterzuladen.",
          },
        ],
        dateArrangementRequired: true,
        disabled: false,
        infoMessages: [
          {
            label: "Produktdetails",
            value:
              "Sie erhalten weitwinklige Immobilienfotos sowie stimmungsvolle Detailaufnahmen von Highlights. Außenfotos sind je nach Wetterbedingungen und individueller Absprachen enthalten. Um sicherzustellen, dass unsere Fotografen die wichtigsten Highlights erfassen, teilen Sie uns diese gerne vorab über das Kommentarfeld mit.",
          },
          {
            label: "Terminvereinbarung",
            value:
              "Sobald Sie den Buchungsvorgang abgeschlossen haben, prüfen wir die Verfügbarkeit unserer Fotografen. Sie erhalten innerhalb von zwei Werktagen eine Rückmeldung zum Bearbeitungsstatus und die angegebene Kontaktperson wird bei Verfügbarkeit umgehend kontaktiert.",
          },
          {
            label: "Vorbereitung",
            value: `Bitte sorgen Sie dafür, dass die Immobilie gut vorbereitet ist und beachten Sie dazu unbedingt unsere <a href=${CONSTANTS.ASSETS.PHOTOGRAPHY.PREPARATION_TIPS}>Vorbereitungshilfen</a> für Makler und Eigentümer. Aufräumarbeiten, die von unseren Fotografen durchgeführt werden müssen und eine halbe Stunde übersteigen, werden mit 60,00€ pro angefangenen 30 Minuten in Rechnung gestellt.`,
          },
          {
            label: "Stornierung",
            value: cancellationHintPhotographyProducts,
          },
        ],
        name: CONSTANTS.PACKAGE_NAMES[OrderTypeEnum.Hd_Photos].HD_PHOTOS_EDITORIAL,
        newProperty: false,
        numberOfDrafts: 0,
        package_contents: ["Immobilienfotos, die Emotionen wecken", "Inkl. hochwertiger Bearbeitung", "Hochauflösend"],
        package_id: "hd_photos_editorial",
        package_images: [
          "hd_fotos/editorial/2020_11_Editorial_Shooting_LU_09_500px.jpg",
          "hd_fotos/editorial/2020_11_Editorial_Shooting_LU_10_500px.jpg",
          "hd_fotos/editorial/2020_11_Editorial_Shooting_LU_18_500px.jpg",
          "hd_fotos/editorial/2020_11_Editorial_Shooting_LU_27_500px.jpg",
          "hd_fotos/editorial/2020_11_Editorial_Shooting_LU_44_500px.jpg",
        ],
        package_icons: ["hd_fotos/editorial/Editorial-Shooting.svg"],
        package_key: "12",
        packageDescHeading: "Hochwertige Immobilienfotos im Editorial Stil",
        photo_price: {
          1: ["hdphoto_editorial_add_15_price", "hdphoto_editorial_add_30_price"],
        },
        qty_price_options: {
          0: {
            defaultQty: 25,
            options: [
              {
                title: "25 Fotos",
                value: "1",
                addQty: 0,
                price: "hdphoto_editorial_price",
              },
              {
                title: "40 Fotos",
                value: "2",
                addQty: 15,
                price: "hdphoto_editorial_add_15_price",
              },
              {
                title: "55 Fotos",
                value: "3",
                addQty: 30,
                price: "hdphoto_editorial_add_30_price",
              },
            ],
          },
        },
        // 'photo_price': 'hdphoto_addUnits',
        phsysical_products: false,
        price: "hdphoto_editorial_price",
        extra_feedback_price: "hdphoto_extra_feedback",
        feedbacks_included: -1,
        price_note: "(Preis exkl. MwSt.)",
        service_key: OrderTypeEnum.Hd_Photos,
        service_title: "Fotografie",
        title: "Fotografie",
        service_image: "service_images/Fotografie.svg",
      },
    ],
    packageDescription:
      '<p>Durch professionelle <span style="color: #22BCA4"><strong>Fotografie</strong></span> rückt Ihre Immobilie ins richtige Licht. Ihre Kunden werden <b>durch erstklassige Bilder überzeugt </b>und Sie glänzen vor Verkäufern mit einem herausragenden Service!</p><p> Sie erhalten die Fotos im JPG Format und haben die Möglichkeit diese in unterschiedlichen Auflösungen herunterzuladen. Setzen Sie Ihrer Vermarktung einen visuellen Reiz durch Fotos vom Profi und verkaufen Sie wie ein Profi!</p><p><i>Beliebte Produktkombinationen:</i> Luftaufnahmen, Videotour, Digital Staging</p>',
    packageLink: "https://www.imogent.de/fotografie",
  },
  {
    displayPosition: 9,
    id: OrderTypeEnum.Retouching,
    packages: [
      {
        description_placeholder:
          "zB.: Kontrast erhöhen (alle Bilder), Helligkeit anpassen (alle Bilder), Horizont gerade ausrichten (Bild 2)",
        details: [
          {
            label: "Bildbearbeitung",
            value:
              "Bei der Bildbearbeitung werden Helligkeit, Farbgebung sowie die Winkel des Bildes optimal angepasst. Nicht inkludiert sind die Beseitigung und das Einfärben von Gegenständen. Nutzen Sie dafür bitte unsere Fotomontage.",
          },
          {
            label: "Ausgabeformat",
            value:
              "Sie erhalten die Fotos im JPG Format und haben die Möglichkeit diese in unterschiedlichen Auflösungen herunterzuladen.",
          },
        ],
        disabled: false,
        extra_feedback_price: "retouching_extra_feedback",
        feedbacks_included: 1,
        name: "Fotobearbeitung",
        newProperty: false,
        numberOfDrafts: 0,
        package_contents: ["Farbkorrekturen", "Licht- und Winkelanpassungen", "Download im gewünschten Format"],
        package_id: "retouching_basic",
        package_images: ["retouching/Standard.jpg"],
        package_icons: ["retouching/Fotobearbeitung.svg"],
        package_key: "6",
        packageDescHeading: "Hochwertige Bearbeitung Ihrer Immobilienfotos",
        physicalProduct: false,
        price: "retouching_price",
        price_note: "pro Bild (Preis exkl. MwSt.)",
        price_pre_text: "",
        service_key: OrderTypeEnum.Retouching,
        service_title: "Bildbearbeitung",
        title: "Bildbearbeitung",
        service_image: "service_images/Bildbearbeitung.svg",
      },
      {
        description:
          "Kleine bis mittelgroße Objekte können aus dem Bild retuschiert werden. Mittelgroße Objekte haben einen maximalen Durchmesser von ca. 60 Zentimetern (z.B. Büsche, Mülltonnen).",
        description_placeholder: "zB.: blauer Himmel (alle Bilder), grün-karierten Teppich entfernen (Bild 2)",
        details: [
          {
            label: "Bildbearbeitung - Fotomontage Standard",
            value:
              "Bei der Fotomontage - Basic können kleine bis mittelgroße Objekte aus dem Bild retuschiert werden. Mittelgroße Objekte haben einen maximalen Durchmesser von ca. 60 Zentimetern (z.B. Büsche, Mülltonnen). Die Bildbearbeitung – Standard ist in diesem Paket inkludiert.",
          },
          {
            label: "Logo einfügen",
            value:
              "Das Logo wird als Wasserzeichen unten rechts in das Bild eingefügt. Sollten Sie einen anderen Wunsch haben, können Sie uns diesen über das Kommentarfeld mitteilen.",
          },
          {
            label: "Ausgabeformat",
            value:
              "Sie können bis zu zwei Ausgabeformate wählen. Sollten Sie einen anderen Wunsch haben, können Sie uns diesen über das Kommentarfeld mitteilen.",
          },
        ],
        disabled: true,
        extra_feedback_price: "retouching_basic_extra_feedback",
        feedbacks_included: 2,
        name: "Fotomontage Standard",
        newProperty: false,
        numberOfDrafts: 0,
        package_contents: [
          "Entfernen von kleinen bis mittelgroßen Gegenständen ",
          "Farbkorrekturen",
          "Licht- und Winkelanpassungen",
          "Web optimiert und hochauflösend",
        ],
        package_id: "retouching_standard",
        package_images: ["retouching/Extended.jpg"],
        package_icons: ["retouching/Fotomontage.svg"],
        package_key: "7",
        packageDescHeading: "Hochwertige Bearbeitung Ihrer Immobilienfotos",
        physicalProduct: false,
        price: "retouching_price_basic",
        price_note: "pro Bild (Preis exkl. MwSt.)",
        price_pre_text: "",
        service_key: OrderTypeEnum.Retouching,
        service_title: "Bildbearbeitung",
        title: "Bildbearbeitung",
        tooltip:
          "Kleine bis mittelgroße Objekte können aus dem Bild retuschiert werden. Mittelgroße Objekte haben einen maximalen Durchmesser von ca. 60 Zentimetern (z.B. Büsche, Mülltonnen).",
        service_image: "service_images/Bildbearbeitung.svg",
      },
      {
        description:
          "Mittelgroße bis große Objekte können aus dem Bild retuschiert werden. Große Objekte haben einen maximalen Durchmesser von ca. 200 Zentimetern (z.B. Autos, Baustellentoiletten).",
        description_placeholder: "zB.: blauer Himmel (alle Bilder), grün-karierten Teppich entfernen (Bild 2)",
        details: [
          {
            label: "Bildbearbeitung",
            value:
              "Bei der Fotomontage können Objekte aus dem Bild retuschiert werden. Große Objekte haben einen maximalen Durchmesser von ca. 200 Zentimetern (z.B. Autos, Baustellentoiletten). Die Bildbearbeitung ist in diesem Produkt inkludiert.",
          },
          {
            label: "Ausgabeformat",
            value:
              "Sie erhalten die Fotos im JPG Format und haben die Möglichkeit diese in unterschiedlichen Auflösungen herunterzuladen.",
          },
        ],
        disabled: false,
        extra_feedback_price: "retouching_erweitert_extra_feedback",
        feedbacks_included: 2,
        name: "Fotomontage",
        newProperty: false,
        numberOfDrafts: 0,
        package_contents: ["Entfernen von Gegenständen", "Farbkorrekturen", "Licht- und Winkelanpassungen"],
        package_id: "retouching_premium",
        package_images: ["retouching/Erweitert.jpg"],
        package_icons: ["retouching/Fotomontage.svg"],
        package_key: "25",
        packageDescHeading: "Hochwertige Bearbeitung Ihrer Immobilienfotos",
        physicalProduct: false,
        price: "retouching_price_erweitert",
        price_note: "pro Bild (Preis exkl. MwSt.)",
        price_pre_text: "",
        service_key: OrderTypeEnum.Retouching,
        service_title: "Bildbearbeitung",
        title: "Bildbearbeitung",
        service_image: "service_images/Bildbearbeitung.svg",
      },
    ],
    packageDescription:
      '<p>Die <span style="color: #22BCA4"><strong>Bildbearbeitung</strong></span> beinhaltet das bequeme, kostengünstige Aufarbeiten von dunklen, schiefen, kontrastarmen und aussagelosen Fotos in helle, ansprechende und hochwertig anmutende Darstellungen!</p><p>Mit der Fotomontage lassen sich unschöne, störende Gegenstände entfernen und das Blauer Himmel Special trägt ebenso zur professionellen Außendarstellung bei wie Anpassungen der Winkel, Helligkeit uvm.</p><p><b>Ganz einfach Fotos hochladen und kurze Zeit später optimierte Ergebnisse erhalten.</b></p><p>Für größere Mengen, fragen Sie uns gerne nach unserer <b>Bildbearbeitungs-Flatrate!</b></p><p><i>Beliebte Produktkombinationen:</i> Grundrisse</p>',
    packageLink: "https://www.imogent.de/bildbearbeitung",
  },
  {
    displayPosition: 8,
    id: OrderTypeEnum.Virtual_Tour,
    packages: [
      {
        additional_unit_price: "matterport_addunits",
        details: [
          {
            label: "Aufnahmedauer",
            value:
              'Die durchschnittliche Aufnahmedauer vor Ort ist <a href="https://www.imogent.de/dauer-aufnahmen" target="_blank">hier</a> einsehbar. Bitte planen Sie genug Zeit ein, damit vor Ort ein optimales Ergebnis erzielt werden kann.',
          },
          {
            label: "Bearbeitungszeiten",
            value:
              'In der Regel finden wir innerhalb von zwei Werktagen einen geeigneten Dienstleister für Sie. Die Bearbeitungszeiten für die Nachbearbeitung sind <a href="https://www.imogent.de/bearbeitungszeiten/" target="_blank">hier</a> einsehbar.',
          },
          {
            label: "Zusatzkosten",
            value:
              "Verzögerungen, die durch die Erbringung zusätzlicher Leistungen vor Ort entstehen (z.B. das Aufräumen einer schlecht vorbereiteten Immobilie, Aufnahmen in mehr als einer abgeschlossenen Wohneinheit bzw. in mehr als einem Objekt), werden mit 60,00€ pro angefangenen 30 Minuten in Rechnung gestellt.",
          },
          {
            label: "Stornierungsbedigungen",
            value: cancellationPolicyPhotographyProducts,
          },
          {
            label: "Privatsphäre",
            value:
              "Zur Wahrung der Persönlichkeitsrechte werden vertrauliche Details wie Dokumente, Fotos oder Kennzeichen unkenntlich gemacht.",
          },
          {
            label: "Ausgabeformat",
            value:
              "Als Ausgabeformat wird ein Link zur Verfügung gestellt, der zum Weiterleiten der Tour an Interessenten genutzt werden kann.",
          },
          {
            label: "Kamerasystem",
            value:
              "Folgende Kameras werden zur Erstellung von Matterport Touren eingesetzt: Matterport Pro 2/3, Ricoh Theta Z1. Durch die Nutzung verschiedener Kameramodelle bietet sich uns ein größeres Netzwerk an Fotografen, wodurch Wartezeiten erheblich reduziert werden können. Alle Modelle bieten eine vergleichbare Bildqualität. Eine Auswahl des Kamerasystems ist nicht möglich.",
          },
          {
            label: "Mattertags",
            value: "Das Setzten von Mattertags ist nicht im Preis inbegriffen und wird nicht von IMOGENT angeboten.",
          },
          {
            label: "Außenspots",
            value:
              "Jede Tour enthält bis zu drei Außenspots. Diese können nur bei entsprechenden Wetterbedingungen erstellt werden. Bei Regen oder starker Sonneneinstrahlung liegt die Entscheidung bei dem Fotografen vor Ort, ob eine Aufnahme von Außenbereichen möglich ist.",
          },
          {
            label: "Hosting",
            value:
              "Im Preis sind sechs Monate Hosting inkludiert. Vor Ablauf der Zeit werden Sie von uns benachrichtigt und es besteht die Möglichkeit das Hosting für 49,00 € um weitere sechs Monate zu verlängern.",
          },
        ],
        dateArrangementRequired: true,
        disabled: false,
        extra_details: "",
        infoMessages: [
          {
            label: "Produktdetails",
            value:
              "Als Ergebnis erhalten Sie einen Link zur Matterport-Tour des Objektes. Bitte beachten Sie, dass wir den Preis bei falscher Flächenangabe nach dem Aufnahmetermin korrigieren. Sollte der Scan ganzer Etagen (Keller, Dachböden) nicht gewünscht sein, teilen Sie uns dies bitte über das Kommentarfeld mit.",
          },
          {
            label: "Terminvereinbarung",
            value:
              "Sobald Sie den Buchungsvorgang abgeschlossen haben, prüfen wir die Verfügbarkeit unserer Fotografen. Sie erhalten innerhalb von zwei Werktagen eine Rückmeldung zum Bearbeitungsstatus und die angegebene Kontaktperson wird bei Verfügbarkeit umgehend kontaktiert.",
          },
          {
            label: "Vorbereitung",
            value: `Bitte sorgen Sie dafür, dass die Immobilie gut vorbereitet ist und beachten Sie dazu unbedingt unsere <a href=${CONSTANTS.ASSETS.PHOTOGRAPHY.PREPARATION_TIPS}>Vorbereitungshilfen</a> für Makler und Eigentümer. Aufräumarbeiten, die von unseren Fotografen durchgeführt werden müssen und eine halbe Stunde übersteigen, werden mit 60,00€ pro angefangenen 30 Minuten in Rechnung gestellt.`,
          },
          {
            label: "Stornierung",
            value: cancellationHintPhotographyProducts,
          },
        ],
        name: "Matterport",
        numberOfDrafts: 0,
        newProperty: true,
        package_contents: [
          "Digitaler Zwilling Ihres Objektes",
          "Inkl. Dollhouse – Ansicht",
          "Hosting für 6 Monate inklusive",
        ],
        package_id: "virtual_tour_matterport",
        package_images: ["rundgang-matterport.jpg"],
        package_icons: ["Matterport.svg"],
        package_key: "13",
        packageDescHeading: "Virtuelle Objektbegehung",
        physicalProduct: false,
        price: "matterport_bis_99qm_price",
        price_options: {
          matterport_bis_99qm_price: { min: 0, max: 99 },
          matterport_bis_199qm_price: { min: 100, max: 199 },
          matterport_bis_299qm_price: { min: 200, max: 299 },
          matterport_bis_399qm_price: { min: 300, max: 399 },
          matterport_bis_499qm_price: { min: 400, max: 499 },
          matterport_bis_599qm_price: { min: 500, max: 599 },
          matterport_bis_699qm_price: { min: 600, max: 699 },
          matterport_bis_799qm_price: { min: 700, max: 799 },
          matterport_bis_899qm_price: { min: 800, max: 899 },
          matterport_bis_999qm_price: { min: 900, max: 999 },
          matterport_1000qm_price: { min: 1000, max: 2000 },
        },
        max_squaremeters: 1000,
        extra_feedback_price: "matterport_extra_feedback",
        feedbacks_included: 1,
        price_note: "(Preis exkl. MwSt.)",
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Virtual_Tour,
        service_title: "Virtueller Rundgang",
        title: "Virtueller Rundgang",
        tooltip:
          "Matterport Touren werden mithilfe von spezieller 360° Technik erstellt. Eine Kamera wird vor Ort in 2m Abständen im Objekt platziert. Diese Aufnahmen werden anschließend zusammengefügt und es entsteht ein digitaler Zwilling, durch den Sie sich, ähnlich wie in einem Computerspiel, bewegen können.",
        service_image: "service_images/Virtuelle-Rundgange.svg",
      },
      {
        companies: ["^dummy_not_available"],
        additional_unit_price: "mirror_reflex_addunits",
        details: [
          {
            label: "Hosting",
            value:
              "Das Hosting der virtuellen Tour ist auf 1 Jahr beschränkt. Falls das Hosting über einen längeren Zeitraum gewünscht ist, kontaktieren Sie bitte unseren Support: mailto:kontakt@imogent.com",
          },
          {
            label: "Ausgabeformat",
            value: "Als Ausgabeformat erhalten Sie einen Link zu dem 360°-Rundgang.",
          },
          {
            label: "Außenbereiche",
            value:
              "Außenbereiche wie Balkone / Terrassen werden bei passenden Bedingungen mit aufgenommen. Bei unvorteilhaften Begebenheiten wie starker Sonneneinstrahlung oder Regen, liegt die Entscheidung, ob Aufnahmen erstellt werden können, im Ermessen des zuständigen Fotografen. Mit Abschluss der Bestellung akzeptieren Sie diese Bedingung.",
          },
        ],
        dateArrangementRequired: true,
        disabled: false,
        extra_details: "inklusive Link zur Tour",
        name: "360° Rundgang Spiegelreflex-Qualität",
        numberOfDrafts: 0,
        newProperty: true,
        package_contents: [],
        package_id: "virtual_tour_mirror_reflex",
        package_images: ["rundgang-spiegelreflex.jpg"],
        package_icons: [],
        package_key: "36",
        packageDescHeading: "Virtuelle Objektbegehung",
        physicalProduct: false,
        price: "mirror_reflex_tour_price",
        extra_feedback_price: "mirror_reflex_tour_extra_feedback",
        feedbacks_included: 1,
        price_note: "(Preis exkl. MwSt.)",
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Virtual_Tour,
        service_title: "Virtueller Rundgang",
        title: "Virtueller Rundgang",
        service_image: "service_images/Virtuelle-Rundgange.svg",
      },
      {
        additional_drone_panorama_price: "virtual_tour_immoviewer_dronepanorama",
        additional_hosting_price: "virtual_tour_immoviewer_6_months_hosting_price",
        customerSpecificInfos: {
          "1xtGCdNse3d27fd6HqnI":
            "<p><b>Kundenspezifisches Infos</b></p><ul><li>Können hier je Unternehmen hinterlegt werden. Z.B.:</li><li>1x 360° Drohnenpanorama</li><li>Weitere Räume/Panoramen möglich</li></ul>",
        },
        details: [
          {
            label: "Aufnahmedauer",
            value:
              'Die durchschnittliche Aufnahmedauer vor Ort ist <a href="https://www.imogent.de/dauer-aufnahmen" target="_blank">hier</a> einsehbar. Bitte planen Sie genug Zeit ein, damit vor Ort ein optimales Ergebnis erzielt werden kann.',
          },
          {
            label: "Bearbeitungszeiten",
            value:
              'In der Regel finden wir innerhalb von zwei Werktagen einen geeigneten Dienstleister für Sie. Die Bearbeitungszeiten für die Nachbearbeitung sind <a href="https://www.imogent.de/bearbeitungszeiten/" target="_blank">hier</a> einsehbar.',
          },
          {
            label: "Zusatzkosten",
            value:
              "Verzögerungen, die durch die Erbringung zusätzlicher Leistungen vor Ort entstehen (z.B. das Aufräumen einer schlecht vorbereiteten Immobilie, Aufnahmen in mehr als einer abgeschlossenen Wohneinheit bzw. in mehr als einem Objekt), werden mit 60,00€ pro angefangenen 30 Minuten in Rechnung gestellt.",
          },
          {
            label: "Stornierungsbedigungen",
            value: cancellationPolicyPhotographyProducts,
          },
          {
            label: "Ausgabeformat",
            value:
              "<strong>Mit Immoviewer Account:</strong><br/>Nach Fertigstellung der Tour, wird diese in Ihren Immoviewer Account transferiert. Sie haben dann die Möglichkeit den Rundgang in Ihrem Account zu individualisieren.<br/><br/><strong>Ohne Immoviewer Account:</strong><br/>Als Ausgabeformat wird ein Link zur Verfügung gestellt, der zum Weiterleiten der Tour an Interessenten genutzt werden kann. Es können keine individuellen Anpassungen (Logo, Farbe etc.) vorgenommen werden.",
          },
          {
            label: "Kamerasystem",
            value:
              "Unsere Fotografen nutzen folgende Kameramodelle zur Erstellung von 360° Fotos:<br><br><ul><li>Ricoh Theta Z1</li><li>Ricoh Theta X</li><li>Insta360 X2</li><li>Insta360 ONE RS</li><li>Matterport Pro 2</li></ul>Die Angabe einer Präferenz ist leider nicht möglich.",
          },
          {
            label: "Außenspots",
            value:
              "Die gebuchte Anzahl an Fotos (Spots) kann beliebig für die Erstellung von Außenspots genutzt werden, sofern es die Wetterbedingungen zulassen. Sprechen Sie dies bitte vor Ort ab oder hinterlegen Sie eine entsprechende Info im Kommentartfeld.",
          },
          {
            label: "Hosting",
            value:
              "Im Preis sind sechs Monate Hosting inkludiert. Vor Ablauf der Zeit werden Sie von uns benachrichtigt und es besteht die Möglichkeit das Hosting für 49,00 € um weitere sechs Monate zu verlängern.",
          },
        ],
        dateArrangementRequired: true,
        disabled: false,
        extra_details: "inklusive Link zur Tour",
        extra_feedback_price: "virtual_tour_immoviewer_extra_feedback",
        feedbacks_included: -1,
        infoMessages: [
          {
            label: "Produktdetails",
            value: " Als Ergebnis erhalten 360°-Aufnahmen sowie einen Link zur virtuellen Tour des Objektes.",
          },
          {
            label: "Terminvereinbarung",
            value:
              "Sobald Sie den Buchungsvorgang abgeschlossen haben, prüfen wir die Verfügbarkeit unserer Fotografen. Sie erhalten innerhalb von zwei Werktagen eine Rückmeldung zum Bearbeitungsstatus und die angegebene Kontaktperson wird bei Verfügbarkeit umgehend kontaktiert.",
          },
          {
            label: "Vorbereitung",
            value: `Bitte sorgen Sie dafür, dass die Immobilie gut vorbereitet ist und beachten Sie dazu unbedingt unsere <a href=${CONSTANTS.ASSETS.PHOTOGRAPHY.PREPARATION_TIPS}>Vorbereitungshilfen</a> für Makler und Eigentümer. Aufräumarbeiten, die von unseren Fotografen durchgeführt werden müssen und eine halbe Stunde übersteigen, werden mit 60,00€ pro angefangenen 30 Minuten in Rechnung gestellt.`,
          },
          {
            label: "Stornierung",
            value: cancellationHintPhotographyProducts,
          },
        ],
        isNewBkbnProduct: true,
        name: "Immoviewer",
        numberOfDrafts: 0,
        newProperty: true,
        package_contents: [
          "Virtuelle Tour Ihres Objektes",
          "Übertragung in den eigenen Immoviewer Account möglich",
          "6 Monate Hosting für Premiumkunden inklusive",
        ],
        package_id: "virtual_tour_immoviewer",
        package_images: ["rundgang-ricoh.jpg"],
        package_icons: ["Immoviewer.svg"],
        package_key: "37",
        packageDescHeading: "Virtuelle Objektbegehung",
        physicalProduct: false,
        price: "virtual_tour_immoviewer_10-15_photos_price",
        package_price: {
          "0": [
            "virtual_tour_immoviewer_10-15_photos_price",
            "virtual_tour_immoviewer_16-25_photos_price",
            "virtual_tour_immoviewer_26-35_photos_price",
          ],
          "1": ["virtual_tour_immoviewer_10-15_photos_jll_price"],
        },
        price_note: "(Preis exkl. MwSt.)",
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Virtual_Tour,
        service_title: "Virtueller Rundgang",
        title: "Virtueller Rundgang",
        tooltip:
          "Unsere Immoviewer Touren werden mithilfe einer speziellen 360° Kamera erstellt. Diese wird in den einzelnen Räumen der Immobilie platziert. Die Fotos werden anschließend in Immoviewer zusammengefügt. Wenn ein Immoviewer Account vorliegt, kann die Tour in diesen übertragen werden, damit Sie von Ihnen individualisert werden kann. Ohne eigenen Account erhalten Sie einen Link zur Tour. Bei dieser Variante sind keine individuellen Anpassungen möglich.",
        service_image: "service_images/Virtuelle-Rundgange.svg",
      },
      {
        companies: ["^dummy_not_available"],
        details: [],
        dateArrangementRequired: false,
        disabled: false,
        extra_details: "inklusive Link zur Tour",
        // extra_feedback_price: "archilogic_3d_tour_extra_feedback", archilogic has been removed from the platform, this accounting position will be invalid is product enabled in future
        name: "Immoviewer DIY Tour",
        numberOfDrafts: 0,
        newProperty: true,
        package_contents: [],
        package_id: "virtual_tour_immoviewer_diy",
        package_images: ["rundgang-ricoh.jpg"],
        package_icons: [],
        package_key: "45",
        packageDescHeading: "Immoviewer DIY Tour",
        physicalProduct: false,
        price: "immoviewer_diy_tour_price",
        feedbacks_included: -1,
        price_note: "(Preis exkl. MwSt.)",
        price_pre_text: "",
        service_key: OrderTypeEnum.Virtual_Tour,
        service_title: "Virtueller Rundgang",
        title: "Virtueller Rundgang",
        service_image: "service_images/Virtuelle-Rundgange.svg",
      },
    ],
    packageDescription:
      '<p><span style="color: #22BCA4"><strong>Virtuelle Rundgänge</strong></span> bieten interaktive Erlebnisse und eine Möglichkeit ortsunabhängige Besichtigungen durchzuführen und Interessenten vorab zu qualifizieren. Ob als Matterport-, Ricoh Theta- oder Spiegelreflexkamera-Tour: Holen Sie Ihre Kunden zu jeder Tages- und Nachtzeit und auf allen Geräten ab.</p><p>Darüber hinaus ist die <b>Einbindung in alle gängigen Immobilienportale</b> und auf Ihrer Homepage problemlos möglich.</p><p><i>Beliebte Produktkombinationen:</i> Grundrisse, Fotografie, Luftaufnahmen</p>',
    packageLink: "https://www.imogent.de/virtueller-rundgang",
  },
  {
    displayPosition: 3,
    id: OrderTypeEnum.Visualisation,
    packages: [
      {
        additionalPrice: [
          "visualisation_indoor_additional_standard",
          "visualisation_indoor_additional_premium",
          "visualisation_indoor_additional_premium_plus",
          "visualisation_indoor_additional_insane",
        ],
        lengthMatrix: [30, 40, 50],
        priceMatrix: {
          photo: [
            "visualisation_indoor_photo_standard",
            "visualisation_indoor_photo_premium",
            "visualisation_indoor_photo_premium_plus",
            "visualisation_indoor_photo_insane",
          ],
          "360": [
            "visualisation_indoor_360_standard",
            "visualisation_indoor_360_premium",
            "visualisation_indoor_360_premium_plus",
            "visualisation_indoor_360_insane",
          ],
          video: [
            "visualisation_indoor_video_standard",
            "visualisation_indoor_video_premium",
            "visualisation_indoor_video_premium_plus",
            "visualisation_indoor_video_insane",
          ],
        },
        additional360Tour: [
          "visualisation_indoor_additional_360_standard",
          "visualisation_indoor_additional_360_premium",
          "visualisation_indoor_additional_360_premium_plus",
          "visualisation_indoor_additional_360_insane",
        ],
        additionalPhotos: [
          "visualisation_indoor_additional_photos_standard",
          "visualisation_indoor_additional_photos_premium",
          "visualisation_indoor_additional_photos_premium_plus",
          "visualisation_indoor_additional_photos_insane",
        ],
        configuration: [
          {
            possibilities: [
              {
                img_url: "Modern.jpg",
                title: "Individuelles Design",
              },
              {
                img_url: "Skandinavisch.jpg",
                title: "Skandinavisch",
              },
              {
                img_url: "Special.jpg",
                title: " Modern",
              },
            ],
            type: "Einrichtungsstil",
            folder: "interior-style",
          },
          {
            possibilities: [
              {
                img_url: "Bodendielen-alt.jpg",
                title: "Bodendielen alt",
              },
              {
                img_url: "Bodendielen-neu.jpg",
                title: "Bodendielen neu",
              },
              {
                img_url: "Parkett-Fischgraet.jpg",
                title: "Parkett Fischgrät",
              },
              {
                img_url: "Parkett-Matt-geraeuchert.jpg",
                title: "Parkett Matt geräuchert",
              },
              {
                img_url: "Parkett-Mosaik-Wuerfel.jpg",
                title: "Parkett Mosaik Würfel",
              },
              {
                img_url: "Parkett-Natur_1.jpg",
                title: "Parkett Natur 1",
              },
              {
                img_url: "Parkett-Natur_2.jpg",
                title: "Parkett Natur 2",
              },
              {
                img_url: "Parkett-Schiffsboden.jpg",
                title: "Parkett Schiffsboden",
              },
              {
                img_url: "Parkett_Chevron.jpg",
                title: "Parkett Chevron",
              },
            ],
            type: "Holzböden",
            folder: "wooden_floors",
          },
          {
            possibilities: [
              {
                img_url: "Bodenfliese-antrazit.jpg",
                title: "Bodenfliese antrazit",
              },
              {
                img_url: "Bodenfliese-Beton.jpg",
                title: "Bodenfliese Beton",
              },
              {
                img_url: "Bodenfliese-grau.jpg",
                title: "Bodenfliese grau",
              },
              {
                img_url: "Bodenfliese-Marmor.jpg",
                title: "Bodenfliese Marmor",
              },
              {
                img_url: "Bodenfliese-Travertino.jpg",
                title: "Bodenfliese Travertino",
              },
              {
                img_url: "Fliese-Betonoptik_1-grossformatik.jpg",
                title: "Fliese Betonoptik 1 großformatik",
              },
              {
                img_url: "Fliese-Betonoptik_2.jpg",
                title: "Fliese Betonoptik 2",
              },
              {
                img_url: "Fliese-schwarz.jpg",
                title: "Fliese schwarz",
              },
              {
                img_url: "Fliese-weiss.jpg",
                title: "Fliese weiß",
              },
            ],
            type: "Fliesenböden",
            folder: "tiled_floors",
          },
          {
            possibilities: [
              {
                img_url: "weiss.jpg",
                title: "Weiß - ohne Fräsungen",
              },
              {
                img_url: "weiss-modern.jpg",
                title: "Weiß modern - horizontale Fräsung",
              },
              {
                img_url: "weiss-klassisch1.jpg",
                title: "Klassisch weiß - Variante 1",
              },
              {
                img_url: "weiss-klassisch-2.jpg",
                title: "Klassisch weiß - Variante 2",
              },
              {
                img_url: "weiss-mit-glas.jpg",
                title: "Klassisch weiß - mit Glas",
              },
              {
                img_url: "holz.jpg",
                title: "Holztür - schlicht",
              },
              {
                img_url: "holz-landhaus.jpg",
                title: "Holztür - Landhaus",
              },
              {
                img_url: "Glastur.jpg",
                title: "Glastür",
              },
              {
                img_url: "schiebetur.jpg",
                title: "Schiebetür",
              },
            ],
            type: "Zimmertüren",
            folder: "doors",
          },
          {
            possibilities: [
              {
                img_url: "Basic-Kuche.jpg",
                title: "Schlicht",
              },
              {
                img_url: "Designer.jpg",
                title: "Designer",
              },
              {
                img_url: "Landhaus.jpg",
                title: "Landhaus",
              },
              {
                img_url: "Skandinavisch.jpg",
                title: "Skandinavisch",
              },
            ],
            type: "Küchen",
            folder: "kitchen",
          },
          {
            possibilities: [
              {
                img_url: "bestehendes-Foto-einarbeiten.jpg",
                title: "Bestehendes Foto einarbeiten",
              },
              {
                img_url: "Google-Maps.jpg",
                title: "Auf Basis von google Maps",
              },
              {
                img_url: "Grune-Wiese.jpg",
                title: "Grüne Wiese",
              },
              {
                img_url: "Klassicher-Garten.jpg",
                title: "Klassischer Garten",
              },
            ],
            type: "Ausblick",
            folder: "view",
          },
        ],
        description_placeholder: "Kommentar",
        disabled: false,
        extra_feedback_price: "visualisation_indoor_extra_feedback",
        feedbacks_included: 1,
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei der Visualisierung helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen. Bitte zeichnen Sie die gewünschten Blickwinkel in die Pläne ein oder beschreiben Sie diese im Kommentarfeld.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Mit Modifizieren meinen wir, dass Sie Angaben machen können, die nicht aus den Architektenplänen ersichtlich werden. ",
            title: "Was meinen wir mit Modifizieren?",
          },
          {
            keyPoints: ["Fußleisten", "Deckenleisten", "Wandfarben", "Zimmerpflanzen", "Dekoration"],
            text: "Kunden, die dieses Produkt bestellt haben, haben in der Regel immer Angaben zu folgenden Dingen gemacht: ",
            title: "Was meinen wir mit Besonderheiten?",
          },
        ],
        name: "Innenvisualisierung",
        newProperty: true,
        numberOfDrafts: {
          0: [
            /**Value of format starts from 1 so keep this empty */
          ],
          1: ["", 3, 4, 4, 5],
          2: ["", 3, 4, 4, 5],
          3: ["", 3, 4, 4, 5],
        },
        opFormats: [
          {
            quality_levels: [
              {
                extra_feedback_price: "visualisation_indoor_extra_feedback_standard",
                feedbacks_included: 2,
                name: "Standard",
                value: 1,
              },
              {
                extra_feedback_price: "visualisation_indoor_extra_feedback_premium",
                feedbacks_included: 3,
                name: "Premium",
                value: 2,
              },
              {
                extra_feedback_price: "visualisation_indoor_extra_feedback_premium_plus",
                feedbacks_included: 3,
                name: "Premium Plus",
                value: 3,
              },
              {
                extra_feedback_price: "visualisation_indoor_extra_feedback_insane",
                feedbacks_included: 4,
                name: "Insane",
                value: 4,
              },
            ],
            title1: "Standbild",
            value: 1,
          },
          {
            quality_levels: [
              {
                extra_feedback_price: "visualisation_indoor_extra_feedback_standard",
                feedbacks_included: 2,
                name: "Standard",
                value: 1,
              },
              {
                extra_feedback_price: "visualisation_indoor_extra_feedback_premium",
                feedbacks_included: 3,
                name: "Premium",
                value: 2,
              },
              {
                extra_feedback_price: "visualisation_indoor_extra_feedback_premium_plus",
                feedbacks_included: 3,
                name: "Premium Plus",
                value: 3,
              },
              {
                extra_feedback_price: "visualisation_indoor_extra_feedback_insane",
                feedbacks_included: 4,
                name: "Insane",
                value: 4,
              },
            ],
            title1: "360°-Tour",
            value: 2,
          },
          {
            quality_levels: [
              {
                extra_feedback_price: "visualisation_indoor_extra_feedback_standard",
                feedbacks_included: 2,
                name: "Standard",
                value: 1,
              },
              {
                extra_feedback_price: "visualisation_indoor_extra_feedback_premium",
                feedbacks_included: 3,
                name: "Premium",
                value: 2,
              },
              {
                extra_feedback_price: "visualisation_indoor_extra_feedback_premium_plus",
                feedbacks_included: 3,
                name: "Premium Plus",
                value: 3,
              },
              {
                extra_feedback_price: "visualisation_indoor_extra_feedback_insane",
                feedbacks_included: 4,
                name: "Insane",
                value: 4,
              },
            ],
            title1: "Rundflug",
            value: 3,
          },
        ],
        package_contents: [
          "Innenvisualisierungen auf Basis von Grundrissen",
          "Anpassungen auf gewünschtes Format",
          "Web optimiert und hochauflösend",
        ],
        package_id: "visualisation_interior",
        package_images: ["visualisation/indoor.jpg"],
        package_icons: ["visualisation/Innenvisualisierung.svg"],
        package_key: "10",
        packageDescHeading: "Hochwertige Innenvisualisierung",
        physicalProduct: false,
        price: "visualisation_indoor_price",
        price_note: "pro Bild (Preis exkl. MwSt.)",
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Visualisation,
        service_title: "Visualisierungen",
        title: "Visualisierungen",
        service_image: "service_images/Visualisierungen.svg",
      },
      {
        additionalPrice: [
          "visualisation_outdoor_additional_standard",
          "visualisation_outdoor_additional_premium",
          "visualisation_outdoor_additional_premium_plus",
          "visualisation_outdoor_additional_insane",
        ],
        lengthMatrix: [30, 40, 50],
        apartmentsMatrix: [
          "visualisation_outdoor_apartments_standard",
          "visualisation_outdoor_apartments_premium",
          "visualisation_outdoor_apartments_premium_plus",
          "visualisation_outdoor_apartments_insane",
        ],
        priceMatrix: {
          photo: [
            "visualisation_outdoor_photo_standard",
            "visualisation_outdoor_photo_premium",
            "visualisation_outdoor_photo_premium_plus",
            "visualisation_outdoor_photo_insane",
          ],
          "360": [
            "visualisation_outdoor_360_standard",
            "visualisation_outdoor_360_premium",
            "visualisation_outdoor_360_premium_plus",
            "visualisation_outdoor_360_insane",
          ],
          video: [
            "visualisation_outdoor_video_standard",
            "visualisation_outdoor_video_premium",
            "visualisation_outdoor_video_premium_plus",
            "visualisation_outdoor_video_insane",
          ],
        },
        buildingsMatrix: {
          standard: [
            "visualisation_outdoor_2_buildings_standard",
            "visualisation_outdoor_3_buildings_standard",
            "visualisation_outdoor_4_buildings_standard",
          ],
          premium: [
            "visualisation_outdoor_2_buildings_premium",
            "visualisation_outdoor_3_buildings_premium",
            "visualisation_outdoor_4_buildings_premium",
          ],
          premium_plus: [
            "visualisation_outdoor_2_buildings_premium_plus",
            "visualisation_outdoor_3_buildings_premium_plus",
            "visualisation_outdoor_4_buildings_premium_plus",
          ],
          insane: [
            "visualisation_outdoor_2_buildings_insane",
            "visualisation_outdoor_3_buildings_insane",
            "visualisation_outdoor_4_buildings_insane",
          ],
        },
        additional360Tour: [
          "visualisation_outdoor_additional_360_standard",
          "visualisation_outdoor_additional_360_premium",
          "visualisation_outdoor_additional_360_premium_plus",
          "visualisation_outdoor_additional_360_insane",
        ],
        additionalPhotos: [
          "visualisation_outdoor_additional_photos_standard",
          "visualisation_outdoor_additional_photos_premium",
          "visualisation_outdoor_additional_photos_premium_plus",
          "visualisation_outdoor_additional_photos_insane",
        ],
        configuration: [
          {
            possibilities: [
              {
                img_url: "Burokomplex.jpg",
                title: "Bürokomplex",
              },
              {
                img_url: "Klinkerhaus.jpg",
                title: "Klinkerhaus",
              },
              {
                img_url: "Mehrfamilienhaus-mit.jpg",
                title: "Mehrfamilienhaus mit Staffelgeschoss",
              },
              {
                img_url: "Mehrfamilienhaus-mit-Flachdach.jpg",
                title: "Mehrfamilienhaus mit Flachdach",
              },
              {
                img_url: "Mehrfamilienhaus-mit-Spitzdach.jpg",
                title: "Mehrfamilienhaus mit Spitzdach",
              },
              {
                img_url: "Mehrfamilienhaus-2.jpg",
                title: "Merhfamilienhaus - Modern",
              },
              {
                img_url: "Modern-1.jpg",
                title: "Einfamilienhaus - Modern mit Satteldach",
              },
              {
                img_url: "Modern-2.jpg",
                title: "Einfamilienhaus - Modern mit Flachdach",
              },
              {
                img_url: "Modern-3.jpg",
                title: "Einfamilienhaus - Architektenhaus",
              },
            ],
            type: "Haustyp",
            folder: "housetype",
          },
          {
            possibilities: [
              {
                img_url: "Dach-rot_2.jpg",
                title: "Dachziegel - Tonziegel Rot",
              },
              {
                img_url: "Dach-hellgrau_2.jpg",
                title: "Dachziegel - Tonziegel Hellgrau",
              },
              {
                img_url: "Dach-schwarz_2.jpg",
                title: "Dachziegel - Tonziegel Grau",
              },
              {
                img_url: "Dach-rot_1.jpg",
                title: "Dachziegel - Steinziegel Rot",
              },
              {
                img_url: "Dach-hellgrau_1.jpg",
                title: "Dachziegel - Steinziegel Hellgrau",
              },
              {
                img_url: "Dach-schwarz_1.jpg",
                title: "Dachziegel - Steinziegel Schwarz",
              },
              {
                img_url: "Dach-rot_3.jpg",
                title: "Dachziegel - Metallziegel Rot",
              },
              {
                img_url: "Dach-grau_3.jpg",
                title: "Dachziegel - Metallziegel Hellgrau",
              },
              {
                img_url: "Dach-schwarz_3.jpg",
                title: "Dachziegel - Metallziegel Schwarz",
              },
              {
                img_url: "Dachbegrunung-1.jpg",
                title: "Dachbegrünung - Ziegraser",
              },
              {
                img_url: "Dachbegrunung-2.jpg",
                title: "Dachbegrünung - Sukkulenten",
              },
              {
                img_url: "Dachbegrunung-3.jpg",
                title: "Flachdach - Kies",
              },
            ],
            type: "Dachbelag",
            folder: "roof",
          },
          {
            possibilities: [
              {
                img_url: "holz.jpg",
                title: "Fensterrahmen - Holz",
              },
              {
                img_url: "nuss.jpg",
                title: "Fensterrahmen - Nuss",
              },
              {
                img_url: "schwarz.jpg",
                title: "Fensterrahmen - Schwarz",
              },
              {
                img_url: "weiss.jpg",
                title: "Fensterrahmen - Weiß",
              },
            ],
            type: "Fenster",
            folder: "windows",
          },
          {
            possibilities: [
              {
                img_url: "Absturzsicherung-Edelstahl-rund.jpg",
                title: "Absturzsicherung - Edelstahl",
              },
              {
                img_url: "Absturzsicherung-Flachstahlgelaender-Verzinkt.jpg",
                title: "Absturzsicherung Flachstahlgeländer Verzinkt",
              },
              {
                img_url: "Absturzsicherung-Ganzglas.jpg",
                title: "Absturzsicherung - Absturzsicherung Ganzglas",
              },
              {
                img_url: "Edelstahl.jpg",
                title: "Edelstahl",
              },
              {
                img_url: "Edelstahl_Glas1.jpg",
                title: "Edelstahl mit Glas",
              },
              {
                img_url: "Edelstahl_Glas2.jpg",
                title: "Edelstahl / Chrom mit Glas",
              },
              {
                img_url: "Glas.jpg",
                title: "Glas",
              },
              {
                img_url: "Stahl.jpg",
                title: "Stahl",
              },
              {
                img_url: "Balkon-Bruestung-Flachstahlgelaender.jpg",
                title: "Balkon Brüstung Flachstahlgeländer",
              },
            ],
            type: "Balkon",
            folder: "balcony-parapet",
          },
          {
            possibilities: [
              {
                img_url: "Google-Maps.jpg",
                title: "Google Maps",
              },
              {
                img_url: "Grune-Wiese.jpg",
                title: "Grüne Wiese",
              },
              {
                img_url: "In-bestehendes-Fotoeinarbeiten.jpg",
                title: "Bestehendes Foto einarbeiten",
              },
              {
                img_url: "Klassischer-Garten.jpg",
                title: "Klassischer Garten",
              },
            ],
            type: "Umgebung",
            folder: "environment",
          },
          {
            possibilities: [
              {
                img_url: "Familiar.jpg",
                title: "Familiär",
              },
              {
                img_url: "Young-professionals.jpg",
                title: "Young Professionals",
              },
              {
                img_url: "Keine-Menschen.jpg",
                title: "Keine Menschen",
              },
            ],
            type: "Menschen",
            folder: "humans",
          },
          {
            possibilities: [
              {
                img_url: "Abendrot.jpg",
                title: "Abendrot",
              },
              {
                img_url: "Abendsonne.jpg",
                title: "Abendsonne",
              },
              {
                img_url: "Bewoelkt.jpg",
                title: "Bewölkt",
              },
              {
                img_url: "Herbstlich.jpg",
                title: "Herbstlich",
              },
              {
                img_url: "Mittagssonne.jpg",
                title: "Mittagssonne",
              },
              {
                img_url: "Morgensonne.jpg",
                title: "Morgensonne",
              },
              {
                img_url: "Nebelig.jpg",
                title: "Nebelig",
              },
              {
                img_url: "Regnerisch.jpg",
                title: "Regnerisch",
              },
              {
                img_url: "Winterabend.jpg",
                title: "Winterabend",
              },
            ],
            type: "Stimmung",
            folder: "moods",
          },
        ],
        description_placeholder: "Kommentar",
        disabled: false,
        extra_feedback_price: "visualisation_outdoor_extra_feedback",
        feedbacks_included: 1,
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei der Visualisierung helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen. Bitte zeichnen Sie die gewünschten Blickwinkel in die Pläne ein oder beschreiben Sie diese im Kommentarfeld.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Mit Modifizieren meinen wir, dass Sie Angaben machen können, die nicht aus den Architektenplänen ersichtlich werden. ",
            title: "Was meinen wir mit Modifizieren?",
          },
          {
            keyPoints: [
              "Balkon- bzw. Terrassenbelag",
              "Gartenutensilien",
              "Außenbeleuchtung",
              "Fassadenart u. -Farbe",
              "Absturzsicherung Fenster",
              "Bepflanzung",
            ],
            text: "Kunden, die dieses Produkt bestellt haben, haben in der Regel immer Angaben zu folgenden Dingen gemacht: ",
            title: "Was meinen wir mit Besonderheiten?",
          },
        ],
        name: "Außenvisualisierung",
        newProperty: true,
        numberOfDrafts: {
          0: [
            /**Value of format starts from 1 so keep this empty */
          ],
          1: ["", 3, 4, 4, 5],
          2: ["", 3, 4, 4, 5],
          3: ["", 3, 4, 4, 5],
        },
        opFormats: [
          {
            quality_levels: [
              {
                extra_feedback_price: "visualisation_outdoor_extra_feedback_standard",
                feedbacks_included: 2,
                name: "Standard",
                value: 1,
              },
              {
                extra_feedback_price: "visualisation_outdoor_extra_feedback_premium",
                feedbacks_included: 3,
                name: "Premium",
                value: 2,
              },
              {
                extra_feedback_price: "visualisation_outdoor_extra_feedback_premium_plus",
                feedbacks_included: 3,
                name: "Premium Plus",
                value: 3,
              },
              {
                extra_feedback_price: "visualisation_outdoor_extra_feedback_insane",
                feedbacks_included: 4,
                name: "Insane",
                value: 4,
              },
            ],
            title1: "Standbild",
            value: 1,
          },
          {
            quality_levels: [
              {
                extra_feedback_price: "visualisation_outdoor_extra_feedback_standard",
                feedbacks_included: 2,
                name: "Standard",
                value: 1,
              },
              {
                extra_feedback_price: "visualisation_outdoor_extra_feedback_premium",
                feedbacks_included: 3,
                name: "Premium",
                value: 2,
              },
              {
                extra_feedback_price: "visualisation_outdoor_extra_feedback_premium_plus",
                feedbacks_included: 3,
                name: "Premium Plus",
                value: 3,
              },
              {
                extra_feedback_price: "visualisation_outdoor_extra_feedback_insane",
                feedbacks_included: 4,
                name: "Insane",
                value: 4,
              },
            ],
            title1: "360°-Tour",
            value: 2,
          },
          {
            quality_levels: [
              {
                extra_feedback_price: "visualisation_outdoor_extra_feedback_standard",
                feedbacks_included: 2,
                name: "Standard",
                value: 1,
              },
              {
                extra_feedback_price: "visualisation_outdoor_extra_feedback_premium",
                feedbacks_included: 3,
                name: "Premium",
                value: 2,
              },
              {
                extra_feedback_price: "visualisation_outdoor_extra_feedback_premium_plus",
                feedbacks_included: 3,
                name: "Premium Plus",
                value: 3,
              },
              {
                extra_feedback_price: "visualisation_outdoor_extra_feedback_insane",
                feedbacks_included: 4,
                name: "Insane",
                value: 4,
              },
            ],
            title1: "Rundflug",
            value: 3,
          },
        ],
        package_contents: [
          "Außenvisualisierungen auf Basis von Ihren Bauplänen",
          "Anpassungen auf gewünschtes Format",
          "Web optimiert und hochauflösend",
        ],
        package_id: "visualisation_exterior",
        package_images: ["visualisation/outdoor.jpg"],
        package_icons: ["visualisation/Aussenvisualisierung.svg"],
        package_key: "11",
        packageDescHeading: "Hochwertige Außenvisualisierungen",
        physicalProduct: false,
        price: "visualisation_outdoor_price",
        price_note: "pro Bild (Preis exkl. MwSt.)",
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Visualisation,
        service_title: "Visualisierungen",
        title: "Visualisierungen",
        service_image: "service_images/Visualisierungen.svg",
      },
      {
        configuration: [
          // {
          //   'possibilities': [
          //     {
          //       'img_url': 'Modern.jpg',
          //       'title': 'Individuelles Design'
          //     },
          //     {
          //       'img_url': 'Skandinavisch.jpg',
          //       'title': 'Skandinavisch'
          //     },
          //     {
          //       'img_url': 'Special.jpg',
          //       'title': ' Modern'
          //     }
          //   ],
          //   'type': 'Einrichtungsstil',
          //   'folder': 'interior-style'
          // },
          {
            possibilities: [
              {
                img_url: "Bodendielen-alt.jpg",
                title: "Bodendielen alt",
              },
              {
                img_url: "Bodendielen-neu.jpg",
                title: "Bodendielen neu",
              },
              {
                img_url: "Parkett-Fischgraet.jpg",
                title: "Parkett Fischgrät",
              },
              {
                img_url: "Parkett-Matt-geraeuchert.jpg",
                title: "Parkett Matt geräuchert",
              },
              {
                img_url: "Parkett-Mosaik-Wuerfel.jpg",
                title: "Parkett Mosaik Würfel",
              },
              {
                img_url: "Parkett-Natur_1.jpg",
                title: "Parkett Natur 1",
              },
              {
                img_url: "Parkett-Natur_2.jpg",
                title: "Parkett Natur 2",
              },
              {
                img_url: "Parkett-Schiffsboden.jpg",
                title: "Parkett Schiffsboden",
              },
              {
                img_url: "Parkett_Chevron.jpg",
                title: "Parkett Chevron",
              },
            ],
            type: "Holzböden",
            folder: "wooden_floors",
          },
          {
            possibilities: [
              {
                img_url: "Bodenfliese-antrazit.jpg",
                title: "Bodenfliese antrazit",
              },
              {
                img_url: "Bodenfliese-Beton.jpg",
                title: "Bodenfliese Beton",
              },
              {
                img_url: "Bodenfliese-grau.jpg",
                title: "Bodenfliese grau",
              },
              {
                img_url: "Bodenfliese-Marmor.jpg",
                title: "Bodenfliese Marmor",
              },
              {
                img_url: "Bodenfliese-Travertino.jpg",
                title: "Bodenfliese Travertino",
              },
              {
                img_url: "Fliese-Betonoptik_1-grossformatik.jpg",
                title: "Fliese Betonoptik 1 großformatik",
              },
              {
                img_url: "Fliese-Betonoptik_2.jpg",
                title: "Fliese Betonoptik 2",
              },
              {
                img_url: "Fliese-schwarz.jpg",
                title: "Fliese schwarz",
              },
              {
                img_url: "Fliese-weiss.jpg",
                title: "Fliese weiß",
              },
            ],
            type: "Fliesenböden",
            folder: "tiled_floors",
          },
          {
            possibilities: [
              {
                img_url: "weiss.jpg",
                title: "Weiß - ohne Fräsungen",
              },
              {
                img_url: "weiss-modern.jpg",
                title: "Weiß modern - horizontale Fräsung",
              },
              {
                img_url: "weiss-klassisch1.jpg",
                title: "Klassisch weiß - Variante 1",
              },
              {
                img_url: "weiss-klassisch-2.jpg",
                title: "Klassisch weiß - Variante 2",
              },
              {
                img_url: "weiss-mit-glas.jpg",
                title: "Klassisch weiß - mit Glas",
              },
              {
                img_url: "holz.jpg",
                title: "Holztür - schlicht",
              },
              {
                img_url: "holz-landhaus.jpg",
                title: "Holztür - Landhaus",
              },
              {
                img_url: "Glastur.jpg",
                title: "Glastür",
              },
              {
                img_url: "schiebetur.jpg",
                title: "Schiebetür",
              },
            ],
            type: "Zimmertüren",
            folder: "doors",
          },
          {
            possibilities: [
              {
                img_url: "Basic-Kuche.jpg",
                title: "Schlicht",
              },
              {
                img_url: "Designer.jpg",
                title: "Designer",
              },
              {
                img_url: "Landhaus.jpg",
                title: "Landhaus",
              },
              {
                img_url: "Skandinavisch.jpg",
                title: "Skandinavisch",
              },
            ],
            type: "Küchen",
            folder: "kitchen",
          },
          {
            possibilities: [
              {
                img_url: "bestehendes-Foto-einarbeiten.jpg",
                title: "Bestehendes Foto einarbeiten",
              },
              {
                img_url: "Google-Maps.jpg",
                title: "Auf Basis von google Maps",
              },
              {
                img_url: "Grune-Wiese.jpg",
                title: "Grüne Wiese",
              },
              {
                img_url: "Klassicher-Garten.jpg",
                title: "Klassischer Garten",
              },
            ],
            type: "Ausblick",
            folder: "view",
          },
        ],
        description_placeholder: "Kommentar",
        disabled: false,
        extra_feedback_price: "visualisation_360_extra_feedback",
        feedbacks_included: 1,
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei der Visualisierung helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen. Bitte zeichnen Sie die gewünschten Blickwinkel in die Pläne ein oder beschreiben Sie diese im Kommentarfeld.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Mit Modifizieren meinen wir, dass Sie Angaben machen können, die nicht aus den Architektenplänen ersichtlich werden. ",
            title: "Was meinen wir mit Modifizieren?",
          },
          {
            keyPoints: ["Fußleisten", "Deckenleisten", "Wandfarben", "Zimmerpflanzen", "Dekoration"],
            text: "Kunden, die dieses Produkt bestellt haben, haben in der Regel immer Angaben zu folgenden Dingen gemacht: ",
            title: "Was meinen wir mit Besonderheiten?",
          },
        ],
        name: "360°-Rundgang",
        newProperty: true,
        package_contents: [
          "Beispiel Individuell: @https://app.immoviewer.com/portal/tour/1673850",
          "Beispiel Standard: @https://app.immoviewer.com/portal/tour/1592384",
        ],
        package_id: "visualisation_360",
        package_images: ["visualisation/immoviewer.jpg"],
        package_icons: ["visualisation/360-grad-Rundgang.svg"],
        package_key: "39",
        packageDescHeading: "Hochwertige 360°-Visualisierung",
        physicalProduct: false,
        price: "visualisation_360_price",
        individual_design_price: "visualisation_360_individual",
        additional_rooms_price: "visualisation_360_additional_rooms",
        individual_additional_rooms_price: "visualisation_360_individual_additional_rooms",
        spots_outside_price: "visualisation_360_spots_outside",
        price_note: "pro Tour (Preis exkl. MwSt.)",
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Visualisation,
        service_title: "Visualisierungen",
        title: "Visualisierungen",
        service_image: "service_images/Visualisierungen.svg",
      },
    ],
    packageDescription:
      '<p><span style="color: #22BCA4"><strong>Visualisierungen</strong></span> als erste Wahl für Ihr Neubauprojekt. Auf Basis Ihrer Planungsunterlagen wird Ihr Objekt in 3D modelliert, gemäß Ihrer Auswahl möbliert und entsprechend Ihrer Qualitätsansprüche visualisiert.</p><p>Sie können sowohl für den Innen- als auch den Außenbereich <b>Standbilder, virtuelle 360°-Touren oder Rundflüge</b> bestellen. Das Standbild als Basis im jpg-Format, die 360°-Tour zum eigenständigen Klicken durch die Immobilie und der Rundflug im Videoformat als Highend-Option für Ihre hochwertige Vermarktung.</p><p>Durch viele Modifikationsmöglichkeiten verkaufen Sie keine Immobilie, sondern ein Zuhause! <b>Setzen Sie Standards mit Visualisierungen, die Kunden zu Fans machen.</b></p><p><i>Beliebte Produktkombinationen:</i> Grundrisse, Wohnungsfinder, Objekthomepage, Luftaufnahmen</p>',
    packageLink: "https://www.imogent.de/visualisierungen",
  },
  {
    displayPosition: 2,
    id: OrderTypeEnum.V_Staging,
    packages: [
      {
        choose_furniture_price: "vstaging_standbild_choose_furniture_price",
        configuration: {
          standard: {
            Luxus: [
              {
                possibilities: [
                  {
                    img_url: "3210.jpeg",
                    title: "3210",
                  },
                  {
                    img_url: "3216.png",
                    title: "3216",
                  },
                  {
                    img_url: "3218.png",
                    title: "3218",
                  },
                ],
                type: "Wohnzimmer",
                folder: "01-Wohnzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "2402.png",
                    title: "2402",
                  },
                  {
                    img_url: "2407.png",
                    title: "2407",
                  },
                  {
                    img_url: "2408.png",
                    title: "2408",
                  },
                ],
                type: "Schlafzimmer",
                folder: "02-Schlafzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "4204N.jpeg",
                    title: "4204N",
                  },
                  {
                    img_url: "4216.png",
                    title: "4216",
                  },
                  {
                    img_url: "4217.png",
                    title: "4217",
                  },
                ],
                type: "Küche",
                folder: "03-Kueche",
              },
              {
                possibilities: [
                  {
                    img_url: "4111H.jpeg",
                    title: "4111H",
                  },
                  {
                    img_url: "4112H.jpeg",
                    title: "4112H",
                  },
                  {
                    img_url: "4203.jpeg",
                    title: "4203",
                  },
                ],
                type: "Esszimmer",
                folder: "04-Esszimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "6002.jpeg",
                    title: "6002",
                  },
                  {
                    img_url: "6036.jpeg",
                    title: "6036",
                  },
                  {
                    img_url: "6301.jpeg",
                    title: "6301",
                  },
                ],
                type: "Büro",
                folder: "05-Buero",
              },
              {
                possibilities: [
                  {
                    img_url: "5003.jpeg",
                    title: "5003",
                  },
                  {
                    img_url: "5004.jpeg",
                    title: "5004",
                  },
                  {
                    img_url: "5005H.jpeg",
                    title: "5005H",
                  },
                ],
                type: "Badezimmer",
                folder: "06-Badezimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "7024.jpeg",
                    title: "7024",
                  },
                  {
                    img_url: "7037.jpeg",
                    title: "7037",
                  },
                  {
                    img_url: "7053.jpeg",
                    title: "7053",
                  },
                ],
                type: "Outdoor",
                folder: "07-Outdoor",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodendielen-alt.jpg",
                    title: "Bodendielen alt",
                  },
                  {
                    img_url: "Bodendielen-neu.jpg",
                    title: "Bodendielen neu",
                  },
                  {
                    img_url: "Parkett-Fischgraet.jpg",
                    title: "Parkett Fischgrät",
                  },
                  {
                    img_url: "Parkett-Matt-geraeuchert.jpg",
                    title: "Parkett Matt geräuchert",
                  },
                  {
                    img_url: "Parkett-Mosaik-Wuerfel.jpg",
                    title: "Parkett Mosaik Würfel",
                  },
                  {
                    img_url: "Bodenfliese-Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Parkett-Natur_2.jpg",
                    title: "Parkett Natur 2",
                  },
                  {
                    img_url: "Parkett-Schiffsboden.jpg",
                    title: "Parkett Schiffsboden",
                  },
                  {
                    img_url: "Parkett_Chevron.jpg",
                    title: "Parkett Chevron",
                  },
                ],
                type: "Holzböden",
                folder: "08-Holzboeden",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodenfliese_antrazit.jpg",
                    title: "Bodenfliese antrazit",
                  },
                  {
                    img_url: "Bodenfliese_Beton.jpg",
                    title: "Bodenfliese Beton",
                  },
                  {
                    img_url: "Bodenfliese_grau.jpg",
                    title: "Bodenfliese grau",
                  },
                  {
                    img_url: "Bodenfliese_Marmor.jpg",
                    title: "Bodenfliese Marmor",
                  },
                  {
                    img_url: "Bodenfliese_Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Fliese_Betonoptik_1_grossformatik.jpg",
                    title: "Fliese Betonoptik_1 grossformatik",
                  },
                  {
                    img_url: "Fliese_Betonoptik_2.jpg",
                    title: "Fliese Betonoptik_2",
                  },
                  {
                    img_url: "Fliese_schwarz.jpg",
                    title: "Fliese schwarz",
                  },
                  {
                    img_url: "Fliese_weiss.jpg",
                    title: "Fliese weiss",
                  },
                ],
                type: "Fliesenböden",
                folder: "09-Fliesenboeden",
              },
            ],
            Modern: [
              {
                possibilities: [
                  {
                    img_url: "3211.jpeg",
                    title: "3211",
                  },
                  {
                    img_url: "3213.png",
                    title: "3213",
                  },
                  {
                    img_url: "3223.jpeg",
                    title: "3223",
                  },
                ],
                type: "Wohnzimmer",
                folder: "01-Wohnzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "2005H.jpeg",
                    title: "2005H",
                  },
                  {
                    img_url: "2028.jpeg",
                    title: "2028",
                  },
                  {
                    img_url: "2010N.jpeg",
                    title: "2010N",
                  },
                ],
                type: "Schlafzimmer",
                folder: "02-Schlafzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "4302.jpeg",
                    title: "4302",
                  },
                  {
                    img_url: "4205N.jpeg",
                    title: "4205N",
                  },
                  {
                    img_url: "4214.png",
                    title: "4214",
                  },
                ],
                type: "Küche",
                folder: "03-Kueche",
              },
              {
                possibilities: [
                  {
                    img_url: "4130.jpeg",
                    title: "4130",
                  },
                  {
                    img_url: "4111R.jpeg",
                    title: "4111R",
                  },
                  {
                    img_url: "4219.png",
                    title: "4219",
                  },
                ],
                type: "Esszimmer",
                folder: "04-Esszimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "6001H.jpeg",
                    title: "6001H",
                  },
                  {
                    img_url: "6004N.jpeg",
                    title: "6004N",
                  },
                  {
                    img_url: "6031.jpeg",
                    title: "6031",
                  },
                ],
                type: "Büro",
                folder: "05-Buero",
              },
              {
                possibilities: [
                  {
                    img_url: "5010.jpeg",
                    title: "5010",
                  },
                  {
                    img_url: "5009.jpeg",
                    title: "5009",
                  },
                  {
                    img_url: "5011.png",
                    title: "5011",
                  },
                ],
                type: "Badezimmer",
                folder: "06-Badezimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "7026.jpeg",
                    title: "7026",
                  },
                  {
                    img_url: "7066.png",
                    title: "7066",
                  },
                  {
                    img_url: "7107.jpeg",
                    title: "7107",
                  },
                ],
                type: "Outdoor",
                folder: "07-Outdoor",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodendielen-alt.jpg",
                    title: "Bodendielen alt",
                  },
                  {
                    img_url: "Bodendielen-neu.jpg",
                    title: "Bodendielen neu",
                  },
                  {
                    img_url: "Parkett-Fischgraet.jpg",
                    title: "Parkett Fischgrät",
                  },
                  {
                    img_url: "Parkett-Matt-geraeuchert.jpg",
                    title: "Parkett Matt geräuchert",
                  },
                  {
                    img_url: "Parkett-Mosaik-Wuerfel.jpg",
                    title: "Parkett Mosaik Würfel",
                  },
                  {
                    img_url: "Bodenfliese-Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Parkett-Natur_2.jpg",
                    title: "Parkett Natur 2",
                  },
                  {
                    img_url: "Parkett-Schiffsboden.jpg",
                    title: "Parkett Schiffsboden",
                  },
                  {
                    img_url: "Parkett_Chevron.jpg",
                    title: "Parkett Chevron",
                  },
                ],
                type: "Holzböden",
                folder: "08-Holzboeden",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodenfliese_antrazit.jpg",
                    title: "Bodenfliese antrazit",
                  },
                  {
                    img_url: "Bodenfliese_Beton.jpg",
                    title: "Bodenfliese Beton",
                  },
                  {
                    img_url: "Bodenfliese_grau.jpg",
                    title: "Bodenfliese grau",
                  },
                  {
                    img_url: "Bodenfliese_Marmor.jpg",
                    title: "Bodenfliese Marmor",
                  },
                  {
                    img_url: "Bodenfliese_Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Fliese_Betonoptik_1_grossformatik.jpg",
                    title: "Fliese Betonoptik_1 grossformatik",
                  },
                  {
                    img_url: "Fliese_Betonoptik_2.jpg",
                    title: "Fliese Betonoptik_2",
                  },
                  {
                    img_url: "Fliese_schwarz.jpg",
                    title: "Fliese schwarz",
                  },
                  {
                    img_url: "Fliese_weiss.jpg",
                    title: "Fliese weiss",
                  },
                ],
                type: "Fliesenböden",
                folder: "09-Fliesenboeden",
              },
            ],
            Skandinavisch: [
              {
                possibilities: [
                  {
                    img_url: "3005H.jpeg",
                    title: "3005H",
                  },
                  {
                    img_url: "3214.png",
                    title: "3214",
                  },
                  {
                    img_url: "3220.png",
                    title: "3220",
                  },
                ],
                type: "Wohnzimmer",
                folder: "01-Wohnzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "2008N.jpeg",
                    title: "2008N",
                  },
                  {
                    img_url: "2051.jpeg",
                    title: "2051",
                  },
                  {
                    img_url: "2022.jpeg",
                    title: "2022",
                  },
                ],
                type: "Schlafzimmer",
                folder: "02-Schlafzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "4300.jpeg",
                    title: "4300",
                  },
                  {
                    img_url: "4210.jpeg",
                    title: "4210",
                  },
                  {
                    img_url: "4219.png",
                    title: "4219",
                  },
                ],
                type: "Küche",
                folder: "03-Kueche",
              },
              {
                possibilities: [
                  {
                    img_url: "4131.jpeg",
                    title: "4131",
                  },
                  {
                    img_url: "4112N.jpeg",
                    title: "4112N",
                  },
                  {
                    img_url: "4206.png",
                    title: "4206",
                  },
                ],
                type: "Esszimmer",
                folder: "04-Esszimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "6038.jpeg",
                    title: "6038",
                  },
                  {
                    img_url: "6004R.jpeg",
                    title: "6004R",
                  },
                  {
                    img_url: "6005R.jpeg",
                    title: "6005R",
                  },
                ],
                type: "Büro",
                folder: "05-Buero",
              },
              {
                possibilities: [
                  {
                    img_url: "5004R.jpeg",
                    title: "5004R",
                  },
                  {
                    img_url: "5005R.jpeg",
                    title: "5005R",
                  },
                  {
                    img_url: "5006R.jpeg",
                    title: "5006R",
                  },
                ],
                type: "Badezimmer",
                folder: "06-Badezimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "7100.jpeg",
                    title: "7100",
                  },
                  {
                    img_url: "7104.jpeg",
                    title: "7104",
                  },
                  {
                    img_url: "7106.png",
                    title: "7106",
                  },
                ],
                type: "Outdoor",
                folder: "07-Outdoor",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodendielen-alt.jpg",
                    title: "Bodendielen alt",
                  },
                  {
                    img_url: "Bodendielen-neu.jpg",
                    title: "Bodendielen neu",
                  },
                  {
                    img_url: "Parkett-Fischgraet.jpg",
                    title: "Parkett Fischgrät",
                  },
                  {
                    img_url: "Parkett-Matt-geraeuchert.jpg",
                    title: "Parkett Matt geräuchert",
                  },
                  {
                    img_url: "Parkett-Mosaik-Wuerfel.jpg",
                    title: "Parkett Mosaik Würfel",
                  },
                  {
                    img_url: "Bodenfliese-Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Parkett-Natur_2.jpg",
                    title: "Parkett Natur 2",
                  },
                  {
                    img_url: "Parkett-Schiffsboden.jpg",
                    title: "Parkett Schiffsboden",
                  },
                  {
                    img_url: "Parkett_Chevron.jpg",
                    title: "Parkett Chevron",
                  },
                ],
                type: "Holzböden",
                folder: "08-Holzboeden",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodenfliese_antrazit.jpg",
                    title: "Bodenfliese antrazit",
                  },
                  {
                    img_url: "Bodenfliese_Beton.jpg",
                    title: "Bodenfliese Beton",
                  },
                  {
                    img_url: "Bodenfliese_grau.jpg",
                    title: "Bodenfliese grau",
                  },
                  {
                    img_url: "Bodenfliese_Marmor.jpg",
                    title: "Bodenfliese Marmor",
                  },
                  {
                    img_url: "Bodenfliese_Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Fliese_Betonoptik_1_grossformatik.jpg",
                    title: "Fliese Betonoptik_1 grossformatik",
                  },
                  {
                    img_url: "Fliese_Betonoptik_2.jpg",
                    title: "Fliese Betonoptik_2",
                  },
                  {
                    img_url: "Fliese_schwarz.jpg",
                    title: "Fliese schwarz",
                  },
                  {
                    img_url: "Fliese_weiss.jpg",
                    title: "Fliese weiss",
                  },
                ],
                type: "Fliesenböden",
                folder: "09-Fliesenboeden",
              },
            ],
          },
          premium: {
            Luxus: [
              {
                possibilities: [
                  {
                    img_url: "3212.jpeg",
                    title: "3212",
                  },
                  {
                    img_url: "3226.jpeg",
                    title: "3226",
                  },
                  {
                    img_url: "3228.jpeg",
                    title: "3228",
                  },
                ],
                type: "Wohnzimmer",
                folder: "01-Wohnzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "2505.png",
                    title: "2505",
                  },
                  {
                    img_url: "2507.jpeg",
                    title: "2507",
                  },
                  {
                    img_url: "2508.png",
                    title: "2508",
                  },
                ],
                type: "Schlafzimmer",
                folder: "02-Schlafzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "4213.png",
                    title: "4213",
                  },
                  {
                    img_url: "4613.jpeg",
                    title: "4613",
                  },
                  {
                    img_url: "4616.png",
                    title: "4616",
                  },
                ],
                type: "Küche",
                folder: "03-Kueche",
              },
              {
                possibilities: [
                  {
                    img_url: "4508.jpeg",
                    title: "4508",
                  },
                  {
                    img_url: "4509.jpeg",
                    title: "4509",
                  },
                  {
                    img_url: "4612.png",
                    title: "4612",
                  },
                ],
                type: "Esszimmer",
                folder: "04-Esszimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "6037.jpeg",
                    title: "6037",
                  },
                  {
                    img_url: "6033.jpeg",
                    title: "6033",
                  },
                  {
                    img_url: "6034.jpeg",
                    title: "6034",
                  },
                ],
                type: "Büro",
                folder: "05-Buero",
              },
              {
                possibilities: [
                  {
                    img_url: "5012.png",
                    title: "5012",
                  },
                  {
                    img_url: "5013.jpeg",
                    title: "5013",
                  },
                  {
                    img_url: "5014.jpeg",
                    title: "5014",
                  },
                ],
                type: "Badezimmer",
                folder: "06-Badezimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "7062.jpeg",
                    title: "7062",
                  },
                  {
                    img_url: "7063.jpeg",
                    title: "7063",
                  },
                  {
                    img_url: "7064.png",
                    title: "7064",
                  },
                ],
                type: "Outdoor",
                folder: "07-Outdoor",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodendielen-alt.jpg",
                    title: "Bodendielen alt",
                  },
                  {
                    img_url: "Bodendielen-neu.jpg",
                    title: "Bodendielen neu",
                  },
                  {
                    img_url: "Bodenfliese-Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Parkett-Fischgraet.jpg",
                    title: "Parkett Fischgraet",
                  },
                  {
                    img_url: "Parkett-Matt-geraeuchert.jpg",
                    title: "Parkett Matt geraeuchert",
                  },
                  {
                    img_url: "Parkett-Mosaik_Wuerfel.jpg",
                    title: "Parkett Mosaik Wuerfel",
                  },
                  {
                    img_url: "Parkett-Natur_2.jpg",
                    title: "Parkett Natur_2",
                  },
                  {
                    img_url: "Parkett-Schiffsboden.jpg",
                    title: "Parkett Schiffsboden",
                  },
                  {
                    img_url: "Parkett_Chevron.jpg",
                    title: "Parkett Chevron",
                  },
                ],
                type: "Holzböden",
                folder: "08-Holzboeden",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodenfliese_antrazit.jpg",
                    title: "Bodenfliese antrazit",
                  },
                  {
                    img_url: "Bodenfliese_Beton.jpg",
                    title: "Bodenfliese Beton",
                  },
                  {
                    img_url: "Bodenfliese_grau.jpg",
                    title: "Bodenfliese grau",
                  },
                  {
                    img_url: "Bodenfliese_Marmor.jpg",
                    title: "Bodenfliese Marmor",
                  },
                  {
                    img_url: "Bodenfliese_Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Fliese_Betonoptik_1_grossformatik.jpg",
                    title: "Fliese Betonoptik_1 grossformatik",
                  },
                  {
                    img_url: "Fliese_Betonoptik_2.jpg",
                    title: "Fliese Betonoptik_2",
                  },
                  {
                    img_url: "Fliese_schwarz.jpg",
                    title: "Fliese schwarz",
                  },
                  {
                    img_url: "Fliese_weiss.jpg",
                    title: "Fliese weiss",
                  },
                ],
                type: "Fliesenböden",
                folder: "09-Fliesenboeden",
              },
            ],
            Modern: [
              {
                possibilities: [
                  {
                    img_url: "3225.jpeg",
                    title: "3225",
                  },
                  {
                    img_url: "3402.jpeg",
                    title: "3402",
                  },
                  {
                    img_url: "3403.jpeg",
                    title: "3403",
                  },
                ],
                type: "Wohnzimmer",
                folder: "01-Wohnzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "2409.png",
                    title: "2409",
                  },
                  {
                    img_url: "2500.png",
                    title: "2500",
                  },
                  {
                    img_url: "2504.png",
                    title: "2504",
                  },
                ],
                type: "Schlafzimmer",
                folder: "02-Schlafzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "4079.jpeg",
                    title: "4079",
                  },
                  {
                    img_url: "4220.jpeg",
                    title: "4220",
                  },
                  {
                    img_url: "4221.jpeg",
                    title: "4221",
                  },
                ],
                type: "Küche",
                folder: "03-Kueche",
              },
              {
                possibilities: [
                  {
                    img_url: "4220.jpeg",
                    title: "4220",
                  },
                  {
                    img_url: "4221.jpeg",
                    title: "4221",
                  },
                  {
                    img_url: "4507.jpeg",
                    title: "4507",
                  },
                ],
                type: "Esszimmer",
                folder: "04-Esszimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "6802C.jpeg",
                    title: "6802C",
                  },
                  {
                    img_url: "6804C.jpeg",
                    title: "6804C",
                  },
                  {
                    img_url: "6036.jpeg",
                    title: "6036",
                  },
                ],
                type: "Büro",
                folder: "05-Buero",
              },
              {
                possibilities: [
                  {
                    img_url: "5012.png",
                    title: "5012",
                  },
                  {
                    img_url: "5013.jpeg",
                    title: "5013",
                  },
                  {
                    img_url: "5014.jpeg",
                    title: "5014",
                  },
                ],
                type: "Badezimmer",
                folder: "06-Badezimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "7014.jpeg",
                    title: "7014",
                  },
                  {
                    img_url: "7109.jpeg",
                    title: "7109",
                  },
                  {
                    img_url: "7102.jpeg",
                    title: "7102",
                  },
                ],
                type: "Outdoor",
                folder: "07-Outdoor",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodendielen-alt.jpg",
                    title: "Bodendielen alt",
                  },
                  {
                    img_url: "Bodendielen-neu.jpg",
                    title: "Bodendielen neu",
                  },
                  {
                    img_url: "Bodenfliese-Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Parkett-Fischgraet.jpg",
                    title: "Parkett Fischgraet",
                  },
                  {
                    img_url: "Parkett-Matt-geraeuchert.jpg",
                    title: "Parkett Matt geraeuchert",
                  },
                  {
                    img_url: "Parkett-Mosaik-Wuerfel.jpg",
                    title: "Parkett Mosaik Wuerfel",
                  },
                  {
                    img_url: "Parkett-Natur_2.jpg",
                    title: "Parkett Natur_2",
                  },
                  {
                    img_url: "Parkett-Schiffsboden.jpg",
                    title: "Parkett Schiffsboden",
                  },
                  {
                    img_url: "Parkett_Chevron.jpg",
                    title: "Parkett Chevron",
                  },
                ],
                type: "Holzböden",
                folder: "08-Holzboeden",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodenfliese_antrazit.jpg",
                    title: "Bodenfliese antrazit",
                  },
                  {
                    img_url: "Bodenfliese_Beton.jpg",
                    title: "Bodenfliese Beton",
                  },
                  {
                    img_url: "Bodenfliese_grau.jpg",
                    title: "Bodenfliese grau",
                  },
                  {
                    img_url: "Bodenfliese_Marmor.jpg",
                    title: "Bodenfliese Marmor",
                  },
                  {
                    img_url: "Bodenfliese_Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Fliese_Betonoptik_1_grossformatik.jpg",
                    title: "Fliese Betonoptik_1 grossformatik",
                  },
                  {
                    img_url: "Fliese_Betonoptik_2.jpg",
                    title: "Fliese Betonoptik_2",
                  },
                  {
                    img_url: "Fliese_schwarz.jpg",
                    title: "Fliese schwarz",
                  },
                  {
                    img_url: "Fliese_weiss.jpg",
                    title: "Fliese weiss",
                  },
                ],
                type: "Fliesenböden",
                folder: "09-Fliesenboeden",
              },
            ],
            Skandinavisch: [
              {
                possibilities: [
                  {
                    img_url: "3221.png",
                    title: "3221",
                  },
                  {
                    img_url: "3222.png",
                    title: "3222",
                  },
                  {
                    img_url: "3404.jpeg",
                    title: "3404",
                  },
                ],
                type: "Wohnzimmer",
                folder: "01-Wohnzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "2500.png",
                    title: "2500",
                  },
                  {
                    img_url: "2502.png",
                    title: "2502",
                  },
                  {
                    img_url: "2503.png",
                    title: "2503",
                  },
                ],
                type: "Schlafzimmer",
                folder: "02-Schlafzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "4211.jpeg",
                    title: "4211  ",
                  },
                  {
                    img_url: "4614.jpeg",
                    title: "4614",
                  },
                  {
                    img_url: "4616.jpeg",
                    title: "4616",
                  },
                ],
                type: "Küche",
                folder: "03-Kueche",
              },
              {
                possibilities: [
                  {
                    img_url: "4504.png",
                    title: "4504",
                  },
                  {
                    img_url: "4505.png",
                    title: "4505",
                  },
                  {
                    img_url: "4615.jpeg",
                    title: "4615",
                  },
                ],
                type: "Esszimmer",
                folder: "04-Esszimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "6004R.jpeg",
                    title: "6004R",
                  },
                  {
                    img_url: "6005R.jpeg",
                    title: "6005R",
                  },
                  {
                    img_url: "6035.png",
                    title: "6035",
                  },
                ],
                type: "Büro",
                folder: "05-Buero",
              },
              {
                possibilities: [
                  {
                    img_url: "5012.png",
                    title: "5012",
                  },
                  {
                    img_url: "5013.jpeg",
                    title: "5013",
                  },
                  {
                    img_url: "5014.jpeg",
                    title: "5014",
                  },
                ],
                type: "Badezimmer",
                folder: "06-Badezimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "7058.jpeg",
                    title: "7058",
                  },
                  {
                    img_url: "7065.png",
                    title: "7065",
                  },
                  {
                    img_url: "7102.jpeg",
                    title: "7102",
                  },
                ],
                type: "Outdoor",
                folder: "07-Outdoor",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodendielen-alt.jpg",
                    title: "Bodendielen alt",
                  },
                  {
                    img_url: "Bodendielen-neu.jpg",
                    title: "Bodendielen neu",
                  },
                  {
                    img_url: "Parkett-Fischgraet.jpg",
                    title: "Parkett Fischgrät",
                  },
                  {
                    img_url: "Parkett-Matt-geraeuchert.jpg",
                    title: "Parkett Matt geräuchert",
                  },
                  {
                    img_url: "Parkett-Mosaik-Wuerfel.jpg",
                    title: "Parkett Mosaik Würfel",
                  },
                  {
                    img_url: "Parkett-Natur_1.jpg",
                    title: "Parkett Natur 1",
                  },
                  {
                    img_url: "Parkett-Natur_2.jpg",
                    title: "Parkett Natur 2",
                  },
                  {
                    img_url: "Parkett-Schiffsboden.jpg",
                    title: "Parkett Schiffsboden",
                  },
                  {
                    img_url: "Parkett_Chevron.jpg",
                    title: "Parkett Chevron",
                  },
                ],
                type: "Holzböden",
                folder: "08-Holzboeden",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodenfliese_antrazit.jpg",
                    title: "Bodenfliese antrazit",
                  },
                  {
                    img_url: "Bodenfliese_Beton.jpg",
                    title: "Bodenfliese Beton",
                  },
                  {
                    img_url: "Bodenfliese_grau.jpg",
                    title: "Bodenfliese grau",
                  },
                  {
                    img_url: "Bodenfliese_Marmor.jpg",
                    title: "Bodenfliese Marmor",
                  },
                  {
                    img_url: "Bodenfliese_Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Fliese_Betonoptik_1_grossformatik.jpg",
                    title: "Fliese Betonoptik_1 grossformatik",
                  },
                  {
                    img_url: "Fliese_Betonoptik_2.jpg",
                    title: "Fliese Betonoptik_2",
                  },
                  {
                    img_url: "Fliese_schwarz.jpg",
                    title: "Fliese schwarz",
                  },
                  {
                    img_url: "Fliese_weiss.jpg",
                    title: "Fliese weiss",
                  },
                ],
                type: "Fliesenböden",
                folder: "09-Fliesenboeden",
              },
            ],
          },
        },
        quality_price: [
          "vstaging_standbild_premium-plus_price",
          "vstaging_standbild_premium_price",
          "vstaging_standbild_standard_price",
        ],
        quality_attributes: {
          0: [
            "Individuelle Stile und Möblierung",
            "Berücksichtigung Ihres individuellen Moodboards",
            "3 Korrekturschleifen",
          ],
          1: [
            "3 Stilrichtungen wählbar",
            "Sie wählen die Möblierung aus unserem <strong>Premium Einrichtungskatalog</strong>",
            "2 Korrekturschleifen",
          ],
          2: [
            "3 Stilrichtungen wählbar",
            "Sie wählen die Möblierung aus unserem <strong>Standard Einrichtungskatalog</strong>",
            "1 Korrekturschleife",
          ],
        },
        quality_levels: [
          {
            feedbacks_included: 3,
          },
          {
            feedbacks_included: 2,
          },
          {
            feedbacks_included: 1,
          },
        ],
        description_placeholder: "zB.: ...",
        details: [
          {
            label: "Digital Staging - Möblierung entfernen",
            value:
              "Das digitale Stagen ist nur möglich, wenn die Räume von Möbeln befreit sind. Sollten sich Möbel auf den Bildern befinden, wird eine Gebühr von $furnishingRemovalPrice € je Bild berechnet, um diese zu beseitigen.  Wenn Böden und Fenster ausgetauscht oder Decken und Wände digital aufgewertet werden sollen, wählen Sie bitte die Option “Renovierung”. Das Entfernen der Möblierung ist in letzterer Option ebenfalls inkludiert. Beispiele: @https://www.imogent.de/digital-staging",
          },
          {
            label: "Digitales Staging - Renovierung",
            value:
              "Bei der digitalen Renovierung werden Böden und Fenster ausgetauscht, Decken und Wände digital aufgewertet und der Raum anschließend mit neuen Möbeln ausgestattet. Das Entfernen der Möblierung ist bereits im Preis inkludiert. Beispiele: @https://www.imogent.de/digital-staging",
          },
          {
            label: "Wände versetzen",
            value:
              "Bei der digitalen Renovierung können Durchbrüche oder Wände neu eingezogen werden. Die Änderungen müssen hierzu nachvollziehbar in die Grundrisse oder Fotos eingezeichnet werden. Geben Sie bei der Auswahl nur die Anzahl der Bilder an, die davon betroffen sind. Beispiele: @https://www.imogent.de/digital-staging",
          },
          {
            label: "Kommentar",
            value:
              "Beschreiben Sie in dem Kommentarfeld, welche Besonderheiten bei dem digitalen Staging beachtet werden sollen und beziehen Sie sich dabei auf den Namen der Datei.",
          },
          {
            label: "Fotos",
            value:
              "Nutzen Sie die jeweiligen Raumbezeichnungen als Dateinamen der zu stagenden Fotos und beziehen Sie sich im Kommentarfeld unbedingt auf die Dateinamen der Fotos.",
          },
        ],
        disabled: false,
        extra_feedback_price: "vstaging_standbild_extra_feedback",
        feedbacks_included: 1,
        furniture_price: "vstaging_standbild_furniture",
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei dem Virtuellen Staging helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Bitte wählen Sie die Einrichtungsgegenstände für Ihre hochgeladenen Räume aus. Sie können zu jedem Einrichtungsgegenstand einen Kommentar verfassen und Ihre Bestellung so auf Ihre Bedürfnisse modifizieren. Sollten Sie Räume kombinieren wollen, können Sie die Nummernkennung der Einrichtungsgegenstände nutzen und in dem Kommentar darauf referenzieren.",
            title: "Was meinen wir mit Modifizieren?",
          },
        ],
        isVirtualTour: false,
        name: "Wohnen Standbilder",
        newProperty: false,
        numberOfDrafts: 0,
        package_contents: ["Virtuelles Einrichten", "moderne Accessoires", "Web optimiert und hochauflösend"],
        package_id: "vstaging_still_image",
        package_images: [
          "digital_staging/skandinavischer.jpg",
          "digital_staging/modern.jpg",
          "digital_staging/retro.jpg",
        ],
        package_icons: ["digital_staging/wohnen-standbilder.svg"],
        package_key: "8",
        // 'photo_price': '79,00',
        packageDescHeading: "Hochwertiges Digital Staging",
        physicalProduct: false,
        price: "vstaging_standbild_price",
        price_note: "pro Bild (Preis exkl. MwSt.)",
        price_pre_text: "ab",
        renovation_price: "vstaging_standbild_renovation",
        service_key: OrderTypeEnum.V_Staging,
        service_title: "Digital Staging",
        styles: [
          {
            styleImage: "../../assets/images/digital_staging/skandinavischer.jpg",
            folder: "../../assets/images/digital_staging/skandinavisch/",
            styleImages: {
              1: [
                "skandinavisch2.jpg",
                "skandinavisch3.jpg",
                "skandinavisch4.jpg",
                "skandinavisch5.jpg",
                "skandinavisch1.jpg",
              ],
              2: ["2503.png", "4614.jpeg", "3404.jpeg"],
              3: ["3220.png", "4210.jpeg", "2022.jpeg"],
            },
            styleLink: "Skandinavischer Stilkatalog",
            styleName: "Skandinavisch",
            selector: "Skandinavisch",
            styleUrl: "https://www.virtualfurniture.io/styles/14?c=gKRyevVjxW",
          },
          {
            styleImage: "../../assets/images/digital_staging/modern.jpg",
            folder: "../../assets/images/digital_staging/modern/",
            styleImages: {
              1: ["modern2.jpg", "modern3.jpg", "modern4.jpg", "modern5.jpg", "modern1.jpg"],
              2: ["3402.jpeg", "4221.jpeg", "2504.jpeg"],
              3: ["2010N.jpeg", "3211.jpeg", "4214.png"],
            },
            styleLink: "Moderner Stilkatalog",
            styleName: "Modern",
            selector: "Modern",
            styleUrl: "https://www.virtualfurniture.io/styles/16?c=gKRyevVjxW",
          },
          {
            styleImage: "../../assets/images/digital_staging/luxus.jpg",
            folder: "../../assets/images/digital_staging/luxus/",
            styleImages: {
              1: ["luxus2.jpg", "luxus3.jpg", "luxus4.jpg", "luxus5.jpg", "luxus1.jpg"],
              2: ["4213_C2.jpg", "2507.jpeg", "3212.jpeg"],
              3: ["4217.png", "3210.jpeg", "2407.png"],
            },
            styleLink: "Luxuriöser Stilkatalog",
            styleName: "Luxuriös",
            selector: "Luxus",
            styleUrl: "https://www.virtualfurniture.io/styles/15?c=gKRyevVjxW",
          },
        ],
        title: "Digital Staging",
        wall_move_price: "vstaging_standbild_wall_move",
        service_image: "service_images/Digital-Staging.svg",
      },
      {
        choose_furniture_price: "vstaging_360_choose_furniture_price",
        configuration: {
          standard: {
            Luxus: [
              {
                possibilities: [
                  {
                    img_url: "3210.jpeg",
                    title: "3210",
                  },
                  {
                    img_url: "3216.png",
                    title: "3216",
                  },
                  {
                    img_url: "3218.png",
                    title: "3218",
                  },
                ],
                type: "Wohnzimmer",
                folder: "01-Wohnzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "2402.png",
                    title: "2402",
                  },
                  {
                    img_url: "2407.png",
                    title: "2407",
                  },
                  {
                    img_url: "2408.png",
                    title: "2408",
                  },
                ],
                type: "Schlafzimmer",
                folder: "02-Schlafzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "4204N.jpeg",
                    title: "4204N",
                  },
                  {
                    img_url: "4216.png",
                    title: "4216",
                  },
                  {
                    img_url: "4217.png",
                    title: "4217",
                  },
                ],
                type: "Küche",
                folder: "03-Kueche",
              },
              {
                possibilities: [
                  {
                    img_url: "4111H.jpeg",
                    title: "4111H",
                  },
                  {
                    img_url: "4112H.jpeg",
                    title: "4112H",
                  },
                  {
                    img_url: "4203.jpeg",
                    title: "4203",
                  },
                ],
                type: "Esszimmer",
                folder: "04-Esszimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "6002.jpeg",
                    title: "6002",
                  },
                  {
                    img_url: "6036.jpeg",
                    title: "6036",
                  },
                  {
                    img_url: "6301.jpeg",
                    title: "6301",
                  },
                ],
                type: "Büro",
                folder: "05-Buero",
              },
              {
                possibilities: [
                  {
                    img_url: "5003.jpeg",
                    title: "5003",
                  },
                  {
                    img_url: "5004.jpeg",
                    title: "5004",
                  },
                  {
                    img_url: "5005H.jpeg",
                    title: "5005H",
                  },
                ],
                type: "Badezimmer",
                folder: "06-Badezimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "7024.jpeg",
                    title: "7024",
                  },
                  {
                    img_url: "7037.jpeg",
                    title: "7037",
                  },
                  {
                    img_url: "7053.jpeg",
                    title: "7053",
                  },
                ],
                type: "Outdoor",
                folder: "07-Outdoor",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodendielen-alt.jpg",
                    title: "Bodendielen alt",
                  },
                  {
                    img_url: "Bodendielen-neu.jpg",
                    title: "Bodendielen neu",
                  },
                  {
                    img_url: "Parkett-Fischgraet.jpg",
                    title: "Parkett Fischgrät",
                  },
                  {
                    img_url: "Parkett-Matt-geraeuchert.jpg",
                    title: "Parkett Matt geräuchert",
                  },
                  {
                    img_url: "Parkett-Mosaik-Wuerfel.jpg",
                    title: "Parkett Mosaik Würfel",
                  },
                  {
                    img_url: "Bodenfliese-Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Parkett-Natur_2.jpg",
                    title: "Parkett Natur 2",
                  },
                  {
                    img_url: "Parkett-Schiffsboden.jpg",
                    title: "Parkett Schiffsboden",
                  },
                  {
                    img_url: "Parkett_Chevron.jpg",
                    title: "Parkett Chevron",
                  },
                ],
                type: "Holzböden",
                folder: "08-Holzboeden",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodenfliese_antrazit.jpg",
                    title: "Bodenfliese antrazit",
                  },
                  {
                    img_url: "Bodenfliese_Beton.jpg",
                    title: "Bodenfliese Beton",
                  },
                  {
                    img_url: "Bodenfliese_grau.jpg",
                    title: "Bodenfliese grau",
                  },
                  {
                    img_url: "Bodenfliese_Marmor.jpg",
                    title: "Bodenfliese Marmor",
                  },
                  {
                    img_url: "Bodenfliese_Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Fliese_Betonoptik_1_grossformatik.jpg",
                    title: "Fliese Betonoptik_1 grossformatik",
                  },
                  {
                    img_url: "Fliese_Betonoptik_2.jpg",
                    title: "Fliese Betonoptik_2",
                  },
                  {
                    img_url: "Fliese_schwarz.jpg",
                    title: "Fliese schwarz",
                  },
                  {
                    img_url: "Fliese_weiss.jpg",
                    title: "Fliese weiss",
                  },
                ],
                type: "Fliesenböden",
                folder: "09-Fliesenboeden",
              },
            ],
            Modern: [
              {
                possibilities: [
                  {
                    img_url: "3211.jpeg",
                    title: "3211",
                  },
                  {
                    img_url: "3213.png",
                    title: "3213",
                  },
                  {
                    img_url: "3223.jpeg",
                    title: "3223",
                  },
                ],
                type: "Wohnzimmer",
                folder: "01-Wohnzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "2005H.jpeg",
                    title: "2005H",
                  },
                  {
                    img_url: "2028.jpeg",
                    title: "2028",
                  },
                  {
                    img_url: "2010N.jpeg",
                    title: "2010N",
                  },
                ],
                type: "Schlafzimmer",
                folder: "02-Schlafzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "4302.jpeg",
                    title: "4302",
                  },
                  {
                    img_url: "4205N.jpeg",
                    title: "4205N",
                  },
                  {
                    img_url: "4214.png",
                    title: "4214",
                  },
                ],
                type: "Küche",
                folder: "03-Kueche",
              },
              {
                possibilities: [
                  {
                    img_url: "4130.jpeg",
                    title: "4130",
                  },
                  {
                    img_url: "4111R.jpeg",
                    title: "4111R",
                  },
                  {
                    img_url: "4219.png",
                    title: "4219",
                  },
                ],
                type: "Esszimmer",
                folder: "04-Esszimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "6001H.jpeg",
                    title: "6001H",
                  },
                  {
                    img_url: "6004N.jpeg",
                    title: "6004N",
                  },
                  {
                    img_url: "6031.jpeg",
                    title: "6031",
                  },
                ],
                type: "Büro",
                folder: "05-Buero",
              },
              {
                possibilities: [
                  {
                    img_url: "5010.jpeg",
                    title: "5010",
                  },
                  {
                    img_url: "5009.jpeg",
                    title: "5009",
                  },
                  {
                    img_url: "5011.png",
                    title: "5011",
                  },
                ],
                type: "Badezimmer",
                folder: "06-Badezimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "7026.jpeg",
                    title: "7026",
                  },
                  {
                    img_url: "7066.png",
                    title: "7066",
                  },
                  {
                    img_url: "7107.jpeg",
                    title: "7107",
                  },
                ],
                type: "Outdoor",
                folder: "07-Outdoor",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodendielen-alt.jpg",
                    title: "Bodendielen alt",
                  },
                  {
                    img_url: "Bodendielen-neu.jpg",
                    title: "Bodendielen neu",
                  },
                  {
                    img_url: "Parkett-Fischgraet.jpg",
                    title: "Parkett Fischgrät",
                  },
                  {
                    img_url: "Parkett-Matt-geraeuchert.jpg",
                    title: "Parkett Matt geräuchert",
                  },
                  {
                    img_url: "Parkett-Mosaik-Wuerfel.jpg",
                    title: "Parkett Mosaik Würfel",
                  },
                  {
                    img_url: "Bodenfliese-Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Parkett-Natur_2.jpg",
                    title: "Parkett Natur 2",
                  },
                  {
                    img_url: "Parkett-Schiffsboden.jpg",
                    title: "Parkett Schiffsboden",
                  },
                  {
                    img_url: "Parkett_Chevron.jpg",
                    title: "Parkett Chevron",
                  },
                ],
                type: "Holzböden",
                folder: "08-Holzboeden",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodenfliese_antrazit.jpg",
                    title: "Bodenfliese antrazit",
                  },
                  {
                    img_url: "Bodenfliese_Beton.jpg",
                    title: "Bodenfliese Beton",
                  },
                  {
                    img_url: "Bodenfliese_grau.jpg",
                    title: "Bodenfliese grau",
                  },
                  {
                    img_url: "Bodenfliese_Marmor.jpg",
                    title: "Bodenfliese Marmor",
                  },
                  {
                    img_url: "Bodenfliese_Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Fliese_Betonoptik_1_grossformatik.jpg",
                    title: "Fliese Betonoptik_1 grossformatik",
                  },
                  {
                    img_url: "Fliese_Betonoptik_2.jpg",
                    title: "Fliese Betonoptik_2",
                  },
                  {
                    img_url: "Fliese_schwarz.jpg",
                    title: "Fliese schwarz",
                  },
                  {
                    img_url: "Fliese_weiss.jpg",
                    title: "Fliese weiss",
                  },
                ],
                type: "Fliesenböden",
                folder: "09-Fliesenboeden",
              },
            ],
            Skandinavisch: [
              {
                possibilities: [
                  {
                    img_url: "3005H.jpeg",
                    title: "3005H",
                  },
                  {
                    img_url: "3214.png",
                    title: "3214",
                  },
                  {
                    img_url: "3220.png",
                    title: "3220",
                  },
                ],
                type: "Wohnzimmer",
                folder: "01-Wohnzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "2008N.jpeg",
                    title: "2008N",
                  },
                  {
                    img_url: "2051.jpeg",
                    title: "2051",
                  },
                  {
                    img_url: "2022.jpeg",
                    title: "2022",
                  },
                ],
                type: "Schlafzimmer",
                folder: "02-Schlafzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "4300.jpeg",
                    title: "4300",
                  },
                  {
                    img_url: "4210.jpeg",
                    title: "4210",
                  },
                  {
                    img_url: "4219.png",
                    title: "4219",
                  },
                ],
                type: "Küche",
                folder: "03-Kueche",
              },
              {
                possibilities: [
                  {
                    img_url: "4131.jpeg",
                    title: "4131",
                  },
                  {
                    img_url: "4112N.jpeg",
                    title: "4112N",
                  },
                  {
                    img_url: "4206.png",
                    title: "4206",
                  },
                ],
                type: "Esszimmer",
                folder: "04-Esszimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "6038.jpeg",
                    title: "6038",
                  },
                  {
                    img_url: "6004R.jpeg",
                    title: "6004R",
                  },
                  {
                    img_url: "6005R.jpeg",
                    title: "6005R",
                  },
                ],
                type: "Büro",
                folder: "05-Buero",
              },
              {
                possibilities: [
                  {
                    img_url: "5004R.jpeg",
                    title: "5004R",
                  },
                  {
                    img_url: "5005R.jpeg",
                    title: "5005R",
                  },
                  {
                    img_url: "5006R.jpeg",
                    title: "5006R",
                  },
                ],
                type: "Badezimmer",
                folder: "06-Badezimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "7100.jpeg",
                    title: "7100",
                  },
                  {
                    img_url: "7104.jpeg",
                    title: "7104",
                  },
                  {
                    img_url: "7106.png",
                    title: "7106",
                  },
                ],
                type: "Outdoor",
                folder: "07-Outdoor",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodendielen-alt.jpg",
                    title: "Bodendielen alt",
                  },
                  {
                    img_url: "Bodendielen-neu.jpg",
                    title: "Bodendielen neu",
                  },
                  {
                    img_url: "Parkett-Fischgraet.jpg",
                    title: "Parkett Fischgrät",
                  },
                  {
                    img_url: "Parkett-Matt-geraeuchert.jpg",
                    title: "Parkett Matt geräuchert",
                  },
                  {
                    img_url: "Parkett-Mosaik-Wuerfel.jpg",
                    title: "Parkett Mosaik Würfel",
                  },
                  {
                    img_url: "Bodenfliese-Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Parkett-Natur_2.jpg",
                    title: "Parkett Natur 2",
                  },
                  {
                    img_url: "Parkett-Schiffsboden.jpg",
                    title: "Parkett Schiffsboden",
                  },
                  {
                    img_url: "Parkett_Chevron.jpg",
                    title: "Parkett Chevron",
                  },
                ],
                type: "Holzböden",
                folder: "08-Holzboeden",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodenfliese_antrazit.jpg",
                    title: "Bodenfliese antrazit",
                  },
                  {
                    img_url: "Bodenfliese_Beton.jpg",
                    title: "Bodenfliese Beton",
                  },
                  {
                    img_url: "Bodenfliese_grau.jpg",
                    title: "Bodenfliese grau",
                  },
                  {
                    img_url: "Bodenfliese_Marmor.jpg",
                    title: "Bodenfliese Marmor",
                  },
                  {
                    img_url: "Bodenfliese_Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Fliese_Betonoptik_1_grossformatik.jpg",
                    title: "Fliese Betonoptik_1 grossformatik",
                  },
                  {
                    img_url: "Fliese_Betonoptik_2.jpg",
                    title: "Fliese Betonoptik_2",
                  },
                  {
                    img_url: "Fliese_schwarz.jpg",
                    title: "Fliese schwarz",
                  },
                  {
                    img_url: "Fliese_weiss.jpg",
                    title: "Fliese weiss",
                  },
                ],
                type: "Fliesenböden",
                folder: "09-Fliesenboeden",
              },
            ],
          },
          premium: {
            Luxus: [
              {
                possibilities: [
                  {
                    img_url: "3212.jpeg",
                    title: "3212",
                  },
                  {
                    img_url: "3226.jpeg",
                    title: "3226",
                  },
                  {
                    img_url: "3228.jpeg",
                    title: "3228",
                  },
                ],
                type: "Wohnzimmer",
                folder: "01-Wohnzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "2505.png",
                    title: "2505",
                  },
                  {
                    img_url: "2507.jpeg",
                    title: "2507",
                  },
                  {
                    img_url: "2508.png",
                    title: "2508",
                  },
                ],
                type: "Schlafzimmer",
                folder: "02-Schlafzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "4213.png",
                    title: "4213",
                  },
                  {
                    img_url: "4613.jpeg",
                    title: "4613",
                  },
                  {
                    img_url: "4616.png",
                    title: "4616",
                  },
                ],
                type: "Küche",
                folder: "03-Kueche",
              },
              {
                possibilities: [
                  {
                    img_url: "4508.jpeg",
                    title: "4508",
                  },
                  {
                    img_url: "4509.jpeg",
                    title: "4509",
                  },
                  {
                    img_url: "4612.png",
                    title: "4612",
                  },
                ],
                type: "Esszimmer",
                folder: "04-Esszimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "6037.jpeg",
                    title: "6037",
                  },
                  {
                    img_url: "6033.jpeg",
                    title: "6033",
                  },
                  {
                    img_url: "6034.jpeg",
                    title: "6034",
                  },
                ],
                type: "Büro",
                folder: "05-Buero",
              },
              {
                possibilities: [
                  {
                    img_url: "5012.png",
                    title: "5012",
                  },
                  {
                    img_url: "5013.jpeg",
                    title: "5013",
                  },
                  {
                    img_url: "5014.jpeg",
                    title: "5014",
                  },
                ],
                type: "Badezimmer",
                folder: "06-Badezimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "7062.jpeg",
                    title: "7062",
                  },
                  {
                    img_url: "7063.jpeg",
                    title: "7063",
                  },
                  {
                    img_url: "7064.png",
                    title: "7064",
                  },
                ],
                type: "Outdoor",
                folder: "07-Outdoor",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodendielen-alt.jpg",
                    title: "Bodendielen alt",
                  },
                  {
                    img_url: "Bodendielen-neu.jpg",
                    title: "Bodendielen neu",
                  },
                  {
                    img_url: "Bodenfliese-Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Parkett-Fischgraet.jpg",
                    title: "Parkett Fischgraet",
                  },
                  {
                    img_url: "Parkett-Matt-geraeuchert.jpg",
                    title: "Parkett Matt geraeuchert",
                  },
                  {
                    img_url: "Parkett-Mosaik_Wuerfel.jpg",
                    title: "Parkett Mosaik Wuerfel",
                  },
                  {
                    img_url: "Parkett-Natur_2.jpg",
                    title: "Parkett Natur_2",
                  },
                  {
                    img_url: "Parkett-Schiffsboden.jpg",
                    title: "Parkett Schiffsboden",
                  },
                  {
                    img_url: "Parkett_Chevron.jpg",
                    title: "Parkett Chevron",
                  },
                ],
                type: "Holzböden",
                folder: "08-Holzboeden",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodenfliese_antrazit.jpg",
                    title: "Bodenfliese antrazit",
                  },
                  {
                    img_url: "Bodenfliese_Beton.jpg",
                    title: "Bodenfliese Beton",
                  },
                  {
                    img_url: "Bodenfliese_grau.jpg",
                    title: "Bodenfliese grau",
                  },
                  {
                    img_url: "Bodenfliese_Marmor.jpg",
                    title: "Bodenfliese Marmor",
                  },
                  {
                    img_url: "Bodenfliese_Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Fliese_Betonoptik_1_grossformatik.jpg",
                    title: "Fliese Betonoptik_1 grossformatik",
                  },
                  {
                    img_url: "Fliese_Betonoptik_2.jpg",
                    title: "Fliese Betonoptik_2",
                  },
                  {
                    img_url: "Fliese_schwarz.jpg",
                    title: "Fliese schwarz",
                  },
                  {
                    img_url: "Fliese_weiss.jpg",
                    title: "Fliese weiss",
                  },
                ],
                type: "Fliesenböden",
                folder: "09-Fliesenboeden",
              },
            ],
            Modern: [
              {
                possibilities: [
                  {
                    img_url: "3225.jpeg",
                    title: "3225",
                  },
                  {
                    img_url: "3402.jpeg",
                    title: "3402",
                  },
                  {
                    img_url: "3403.jpeg",
                    title: "3403",
                  },
                ],
                type: "Wohnzimmer",
                folder: "01-Wohnzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "2409.png",
                    title: "2409",
                  },
                  {
                    img_url: "2500.png",
                    title: "2500",
                  },
                  {
                    img_url: "2504.png",
                    title: "2504",
                  },
                ],
                type: "Schlafzimmer",
                folder: "02-Schlafzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "4079.jpeg",
                    title: "4079",
                  },
                  {
                    img_url: "4220.jpeg",
                    title: "4220",
                  },
                  {
                    img_url: "4221.jpeg",
                    title: "4221",
                  },
                ],
                type: "Küche",
                folder: "03-Kueche",
              },
              {
                possibilities: [
                  {
                    img_url: "4220.jpeg",
                    title: "4220",
                  },
                  {
                    img_url: "4221.jpeg",
                    title: "4221",
                  },
                  {
                    img_url: "4507.jpeg",
                    title: "4507",
                  },
                ],
                type: "Esszimmer",
                folder: "04-Esszimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "6802C.jpeg",
                    title: "6802C",
                  },
                  {
                    img_url: "6804C.jpeg",
                    title: "6804C",
                  },
                  {
                    img_url: "6036.jpeg",
                    title: "6036",
                  },
                ],
                type: "Büro",
                folder: "05-Buero",
              },
              {
                possibilities: [
                  {
                    img_url: "5012.png",
                    title: "5012",
                  },
                  {
                    img_url: "5013.jpeg",
                    title: "5013",
                  },
                  {
                    img_url: "5014.jpeg",
                    title: "5014",
                  },
                ],
                type: "Badezimmer",
                folder: "06-Badezimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "7014.jpeg",
                    title: "7014",
                  },
                  {
                    img_url: "7109.jpeg",
                    title: "7109",
                  },
                  {
                    img_url: "7102.jpeg",
                    title: "7102",
                  },
                ],
                type: "Outdoor",
                folder: "07-Outdoor",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodendielen-alt.jpg",
                    title: "Bodendielen alt",
                  },
                  {
                    img_url: "Bodendielen-neu.jpg",
                    title: "Bodendielen neu",
                  },
                  {
                    img_url: "Bodenfliese-Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Parkett-Fischgraet.jpg",
                    title: "Parkett Fischgraet",
                  },
                  {
                    img_url: "Parkett-Matt-geraeuchert.jpg",
                    title: "Parkett Matt geraeuchert",
                  },
                  {
                    img_url: "Parkett-Mosaik-Wuerfel.jpg",
                    title: "Parkett Mosaik Wuerfel",
                  },
                  {
                    img_url: "Parkett-Natur_2.jpg",
                    title: "Parkett Natur_2",
                  },
                  {
                    img_url: "Parkett-Schiffsboden.jpg",
                    title: "Parkett Schiffsboden",
                  },
                  {
                    img_url: "Parkett_Chevron.jpg",
                    title: "Parkett Chevron",
                  },
                ],
                type: "Holzböden",
                folder: "08-Holzboeden",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodenfliese_antrazit.jpg",
                    title: "Bodenfliese antrazit",
                  },
                  {
                    img_url: "Bodenfliese_Beton.jpg",
                    title: "Bodenfliese Beton",
                  },
                  {
                    img_url: "Bodenfliese_grau.jpg",
                    title: "Bodenfliese grau",
                  },
                  {
                    img_url: "Bodenfliese_Marmor.jpg",
                    title: "Bodenfliese Marmor",
                  },
                  {
                    img_url: "Bodenfliese_Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Fliese_Betonoptik_1_grossformatik.jpg",
                    title: "Fliese Betonoptik_1 grossformatik",
                  },
                  {
                    img_url: "Fliese_Betonoptik_2.jpg",
                    title: "Fliese Betonoptik_2",
                  },
                  {
                    img_url: "Fliese_schwarz.jpg",
                    title: "Fliese schwarz",
                  },
                  {
                    img_url: "Fliese_weiss.jpg",
                    title: "Fliese weiss",
                  },
                ],
                type: "Fliesenböden",
                folder: "09-Fliesenboeden",
              },
            ],
            Skandinavisch: [
              {
                possibilities: [
                  {
                    img_url: "3221.png",
                    title: "3221",
                  },
                  {
                    img_url: "3222.png",
                    title: "3222",
                  },
                  {
                    img_url: "3404.jpeg",
                    title: "3404",
                  },
                ],
                type: "Wohnzimmer",
                folder: "01-Wohnzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "2500.png",
                    title: "2500",
                  },
                  {
                    img_url: "2502.png",
                    title: "2502",
                  },
                  {
                    img_url: "2503.png",
                    title: "2503",
                  },
                ],
                type: "Schlafzimmer",
                folder: "02-Schlafzimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "4211.jpeg",
                    title: "4211  ",
                  },
                  {
                    img_url: "4614.jpeg",
                    title: "4614",
                  },
                  {
                    img_url: "4616.jpeg",
                    title: "4616",
                  },
                ],
                type: "Küche",
                folder: "03-Kueche",
              },
              {
                possibilities: [
                  {
                    img_url: "4504.png",
                    title: "4504",
                  },
                  {
                    img_url: "4505.png",
                    title: "4505",
                  },
                  {
                    img_url: "4615.jpeg",
                    title: "4615",
                  },
                ],
                type: "Esszimmer",
                folder: "04-Esszimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "6004R.jpeg",
                    title: "6004R",
                  },
                  {
                    img_url: "6005R.jpeg",
                    title: "6005R",
                  },
                  {
                    img_url: "6035.png",
                    title: "6035",
                  },
                ],
                type: "Büro",
                folder: "05-Buero",
              },
              {
                possibilities: [
                  {
                    img_url: "5012.png",
                    title: "5012",
                  },
                  {
                    img_url: "5013.jpeg",
                    title: "5013",
                  },
                  {
                    img_url: "5014.jpeg",
                    title: "5014",
                  },
                ],
                type: "Badezimmer",
                folder: "06-Badezimmer",
              },
              {
                possibilities: [
                  {
                    img_url: "7058.jpeg",
                    title: "7058",
                  },
                  {
                    img_url: "7065.png",
                    title: "7065",
                  },
                  {
                    img_url: "7102.jpeg",
                    title: "7102",
                  },
                ],
                type: "Outdoor",
                folder: "07-Outdoor",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodendielen-alt.jpg",
                    title: "Bodendielen alt",
                  },
                  {
                    img_url: "Bodendielen-neu.jpg",
                    title: "Bodendielen neu",
                  },
                  {
                    img_url: "Parkett-Fischgraet.jpg",
                    title: "Parkett Fischgrät",
                  },
                  {
                    img_url: "Parkett-Matt-geraeuchert.jpg",
                    title: "Parkett Matt geräuchert",
                  },
                  {
                    img_url: "Parkett-Mosaik-Wuerfel.jpg",
                    title: "Parkett Mosaik Würfel",
                  },
                  {
                    img_url: "Parkett-Natur_1.jpg",
                    title: "Parkett Natur 1",
                  },
                  {
                    img_url: "Parkett-Natur_2.jpg",
                    title: "Parkett Natur 2",
                  },
                  {
                    img_url: "Parkett-Schiffsboden.jpg",
                    title: "Parkett Schiffsboden",
                  },
                  {
                    img_url: "Parkett_Chevron.jpg",
                    title: "Parkett Chevron",
                  },
                ],
                type: "Holzböden",
                folder: "08-Holzboeden",
              },
              {
                possibilities: [
                  {
                    img_url: "Bodenfliese_antrazit.jpg",
                    title: "Bodenfliese antrazit",
                  },
                  {
                    img_url: "Bodenfliese_Beton.jpg",
                    title: "Bodenfliese Beton",
                  },
                  {
                    img_url: "Bodenfliese_grau.jpg",
                    title: "Bodenfliese grau",
                  },
                  {
                    img_url: "Bodenfliese_Marmor.jpg",
                    title: "Bodenfliese Marmor",
                  },
                  {
                    img_url: "Bodenfliese_Travertino.jpg",
                    title: "Bodenfliese Travertino",
                  },
                  {
                    img_url: "Fliese_Betonoptik_1_grossformatik.jpg",
                    title: "Fliese Betonoptik_1 grossformatik",
                  },
                  {
                    img_url: "Fliese_Betonoptik_2.jpg",
                    title: "Fliese Betonoptik_2",
                  },
                  {
                    img_url: "Fliese_schwarz.jpg",
                    title: "Fliese schwarz",
                  },
                  {
                    img_url: "Fliese_weiss.jpg",
                    title: "Fliese weiss",
                  },
                ],
                type: "Fliesenböden",
                folder: "09-Fliesenboeden",
              },
            ],
          },
        },
        quality_price: ["vstaging_360_premium-plus_price", "vstaging_360_premium_price", "vstaging_360_standard_price"],
        quality_attributes: {
          0: [
            "Individuelle Stile und Möblierung",
            "Berücksichtigung Ihres individuellen Moodboards",
            "3 Korrekturschleifen",
          ],
          1: [
            "3 Stilrichtungen wählbar",
            "Sie wählen die Möblierung aus unserem <strong>Premium Einrichtungskatalog</strong>",
            "2 Korrekturschleifen",
          ],
          2: [
            "3 Stilrichtungen wählbar",
            "Sie wählen die Möblierung aus unserem <strong>Standard Einrichtungskatalog</strong>",
            "1 Korrekturschleife",
          ],
        },
        quality_levels: [
          {
            feedbacks_included: 3,
          },
          {
            feedbacks_included: 2,
          },
          {
            feedbacks_included: 1,
          },
        ],
        description_placeholder: "zB.: Wohnung bitte im skandinavischen Stil einrichten",
        details: [
          {
            label: "Digital Staging - Möblierung entfernen",
            value:
              "Das digitale Stagen ist nur möglich, wenn die Räume von Möbeln befreit sind. Sollten sich Möbel auf den Bildern befinden, wird eine Gebühr von $furnishingRemovalPrice € je Bild berechnet, um diese zu beseitigen.  Wenn Böden und Fenster ausgetauscht oder Decken und Wände digital aufgewertet werden sollen, wählen Sie bitte die Option “Renovierung”. Das Entfernen der Möblierung ist in letzterer Option ebenfalls inkludiert. Beispiele: @https://www.imogent.de/digital-staging",
          },
          {
            label: "Digitales Staging - Renovierung",
            value:
              "Bei der digitalen Renovierung werden Böden und Fenster ausgetauscht, Decken und Wände digital aufgewertet und der Raum anschließend mit neuen Möbeln ausgestattet. Das Entfernen der Möblierung ist bereits im Preis inkludiert. Beispiele: @https://www.imogent.de/digital-staging",
          },
          {
            label: "Wände versetzen",
            value:
              "Bei der digitalen Renovierung können Durchbrüche oder Wände neu eingezogen werden. Die Änderungen müssen hierzu nachvollziehbar in die Grundrisse oder Fotos eingezeichnet werden. Geben Sie hier nur die Anzahl der Bilder an, die davon betroffen sind. Beispiele: @https://www.imogent.de/digital-staging",
          },
          {
            label: "Kommentar",
            value:
              "Beschreiben Sie in dem Kommentarfeld, welche Besonderheiten bei dem digitalen Staging beachtet werden sollen und beziehen Sie sich dabei auf den Namen der Datei.",
          },
          {
            label: "Fotos",
            value:
              "Nutzen Sie die jeweiligen Raumbezeichnungen als Dateinamen der zu stagenden Fotos und beziehen Sie sich im Kommentarfeld unbedingt auf die Dateinamen der Fotos.",
          },
          {
            label: "Virtuelle Tour",
            value:
              "Bei der virtuellen Tour werden die 360°-Fotos zu einer Tour zusammengesetzt. Die Tour wird von IMOGENT gehostet und als Link zur Verfügung gestellt. Die Einzelfotos erhalten Sie separat.",
          },
          {
            label: "Virtuelle Tour (Einzelspots)",
            value:
              "Bei der virtuellen Tour (Einzelspots) werden einzelne 360°-Fotos zu einer Tour zusammengesetzt. Die Tour wird von IMOGENT gehostet und als Link zur Verfügung gestellt. Die Einzelfotos erhalten Sie separat.",
          },
          {
            label: "Grundrisse",
            value:
              "Zur Orientierung in der virtuellen Tour können Grundrisse eingearbeitet werden. Die Standorte der 360°-Spots werden durch Punkte im Grundriss kenntlich gemacht.<br/>Das Einbinden der Grundrisse erzeugt keine Mehrkosten,  lediglich die Aufarbeitung der Grundrisse wird mit 29€ je Grundriss berechnet. Bei mehreren Etagen bitte die Anzahl an Grundrissen angeben und entsprechend die Grundrisse hochladen.",
          },
        ],
        disabled: false,
        extra_feedback_price: "vstaging_360_extra_feedback",
        feedbacks_included: 2,
        fp_price: "vstaging_360_fp",
        furniture_price: "vstaging_360_furniture",
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei dem Virtuellen Staging helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Bitte wählen Sie die Einrichtungsgegenstände für Ihre hochgeladenen Räume aus. Sie können zu jedem Einrichtungsgegenstand einen Kommentar verfassen und Ihre Bestellung so auf Ihre Bedürfnisse modifizieren. Sollten Sie Räume kombinieren wollen, können Sie die Nummernkennung der Einrichtungsgegenstände nutzen und in dem Kommentar darauf referenzieren.",
            title: "Was meinen wir mit Modifizieren?",
          },
        ],
        isVirtualTour: true,
        name: "Wohnen - 360° Bilder", // cannot change this string from '360° Bilder' to '360°-Bilder' because its being used as a reference in dependentField property under the language.ts file. If we need to change this in the future, we should make a refactor on that dependentField usage also (have in mind legacy orders with legacy name).
        newProperty: false,
        numberOfDrafts: 0,
        package_contents: ["Virtuelles Einrichten", "moderne Accessoires", "Web optimiert und hochauflösend"],
        package_id: "vstaging_360_image",
        package_images: [
          "digital_staging/skandinavischer.jpg",
          "digital_staging/modern.jpg",
          "digital_staging/retro.jpg",
        ],
        package_icons: ["digital_staging/wohnen-360-bilder.svg"],
        package_key: "9",
        // 'photo_price': '129,00',
        packageDescHeading: "Hochwertiges Digital Staging (360°)",
        physicalProduct: false,
        price: "vstaging_360_price",
        price_individual_tour: "vstaging_360_individual_tour",
        price_note: "pro Bild (Preis exkl. MwSt.)",
        price_pre_text: "ab",
        price_virtual_tour: "vstaging_360_virtual_tour",
        renovation_price: "vstaging_360_renovation",
        service_key: OrderTypeEnum.V_Staging,
        service_title: "Digital Staging",
        styles: [
          {
            styleImage: "../../assets/images/digital_staging/skandinavischer.jpg",
            folder: "../../assets/images/digital_staging/skandinavisch/",
            styleImages: {
              1: [
                "skandinavisch2.jpg",
                "skandinavisch3.jpg",
                "skandinavisch4.jpg",
                "skandinavisch5.jpg",
                "skandinavisch1.jpg",
              ],
              2: ["2503.png", "4614.jpeg", "3404.jpeg"],
              3: ["3220.png", "4210.jpeg", "2022.jpeg"],
            },
            styleLink: "Skandinavischer Stilkatalog",
            styleName: "Skandinavisch",
            selector: "Skandinavisch",
            styleUrl: "https://www.virtualfurniture.io/styles/14?c=gKRyevVjxW",
          },
          {
            styleImage: "../../assets/images/digital_staging/modern.jpg",
            folder: "../../assets/images/digital_staging/modern/",
            styleImages: {
              1: ["modern2.jpg", "modern3.jpg", "modern4.jpg", "modern5.jpg", "modern1.jpg"],
              2: ["3402.jpeg", "4221.jpeg", "2504.jpeg"],
              3: ["2010N.jpeg", "3211.jpeg", "4214.png"],
            },
            styleLink: "Moderner Stilkatalog",
            styleName: "Modern",
            selector: "Modern",
            styleUrl: "https://www.virtualfurniture.io/styles/16?c=gKRyevVjxW",
          },
          {
            styleImage: "../../assets/images/digital_staging/luxus.jpg",
            folder: "../../assets/images/digital_staging/luxus/",
            styleImages: {
              1: ["luxus2.jpg", "luxus3.jpg", "luxus4.jpg", "luxus5.jpg", "luxus1.jpg"],
              2: ["4213_C2.jpg", "2507.jpeg", "3212.jpeg"],
              3: ["4217.png", "3210.jpeg", "2407.png"],
            },
            styleLink: "Luxuriöser Stilkatalog",
            styleName: "Luxuriös",
            selector: "Luxus",
            styleUrl: "https://www.virtualfurniture.io/styles/15?c=gKRyevVjxW",
          },
        ],
        title: "Digital Staging",
        wall_move_price: "vstaging_360_wall_move",
        service_image: "service_images/Digital-Staging.svg",
      },
      {
        companies: ["Imogent GmbH", "^Jones Lang Lasalle"],
        details: [
          {
            label: "Kommentar",
            value:
              "Beschreiben Sie in dem Kommentarfeld, welche Besonderheiten bei dem digitalen Staging beachtet werden sollen und beziehen Sie sich dabei auf den Namen der Datei.",
          },
          {
            label: "Fotos",
            value:
              "Nutzen Sie die jeweiligen Raumbezeichnungen als Dateinamen der zu stagenden Fotos und beziehen Sie sich im Kommentarfeld unbedingt auf die Dateinamen der Fotos.",
          },
        ],
        disabled: true,
        extra_feedback_price: "vstaging_office-360_extra_feedback",
        feedbacks_included: 2,
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei dem Virtuellen Staging helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Bitte wählen Sie die Einrichtungsgegenstände für Ihre hochgeladenen Räume aus. Sie können zu jedem Einrichtungsgegenstand einen Kommentar verfassen und Ihre Bestellung so auf Ihre Bedürfnisse modifizieren. Sollten Sie Räume kombinieren wollen, können Sie die Nummernkennung der Einrichtungsgegenstände nutzen und in dem Kommentar darauf referenzieren.",
            title: "Was meinen wir mit Modifizieren?",
          },
        ],
        isVirtualTour: false,
        name: "Office - Immoviewer 360°",
        newProperty: false,
        numberOfDrafts: 0,
        package_contents: ["Virtuelles Einrichten", "moderne Accessoires", "Web optimiert und hochauflösend"],
        package_id: "vstaging_office-360_image",
        package_images: ["digital_office_staging/office_staging1.JPG", "digital_office_staging/office_staging2.JPG"],
        package_icons: [],
        package_key: "41",
        packageDescHeading: "Hochwertiges Digital Office Staging (360°)",
        physicalProduct: false,
        price: "vstaging_office-360_price",
        price_note: "pro Bild (Preis exkl. MwSt.)",
        price_pre_text: "ab",
        service_key: OrderTypeEnum.V_Staging,
        service_title: "Digital Staging",
        styleImage: "../../assets/images/digital_office_staging/office_staging1.JPG",
        title: "Digital Staging",
        service_image: "service_images/Digital-Staging.svg",
      },
      {
        choose_furniture_price: "vstaging_office_standbild_choose_furniture_price",
        quality_price: ["vstaging_office_standbild_premium-plus_price", "vstaging_office_standbild_premium_price"],
        quality_attributes: {
          0: [
            "Individuelle Stile und Möblierung",
            "Berücksichtigung Ihres individuellen Moodboards",
            "3 Korrekturschleifen",
          ],
          1: ["3 Stilrichtungen wählbar", "2 Korrekturschleifen"],
        },
        quality_levels: [
          {
            feedbacks_included: 3,
          },
          {
            feedbacks_included: 2,
          },
        ],
        description_placeholder: "zB.: ...",
        details: [
          {
            label: "Digital Staging - Möblierung entfernen",
            value:
              "Das digitale Stagen ist nur möglich, wenn die Räume von Möbeln befreit sind. Sollten sich Möbel auf den Bildern befinden, wird eine Gebühr von $furnishingRemovalPrice € je Bild berechnet, um diese zu beseitigen.  Wenn Böden und Fenster ausgetauscht oder Decken und Wände digital aufgewertet werden sollen, wählen Sie bitte die Option “Renovierung”. Das Entfernen der Möblierung ist in letzterer Option ebenfalls inkludiert. Beispiele: @https://www.imogent.de/digital-staging",
          },
          {
            label: "Digitales Staging - Renovierung",
            value:
              "Bei der digitalen Renovierung werden Böden und Fenster ausgetauscht, Decken und Wände digital aufgewertet und der Raum anschließend mit neuen Möbeln ausgestattet. Das Entfernen der Möblierung ist bereits im Preis inkludiert. Beispiele: @https://www.imogent.de/digital-staging",
          },
          {
            label: "Wände versetzen",
            value:
              "Bei der digitalen Renovierung können Durchbrüche oder Wände neu eingezogen werden. Die Änderungen müssen hierzu nachvollziehbar in die Grundrisse oder Fotos eingezeichnet werden. Geben Sie bei der Auswahl nur die Anzahl der Bilder an, die davon betroffen sind. Beispiele: @https://www.imogent.de/digital-staging",
          },
          {
            label: "Kommentar",
            value:
              "Beschreiben Sie in dem Kommentarfeld, welche Besonderheiten bei dem digitalen Staging beachtet werden sollen und beziehen Sie sich dabei auf den Namen der Datei.",
          },
          {
            label: "Fotos",
            value:
              "Nutzen Sie die jeweiligen Raumbezeichnungen als Dateinamen der zu stagenden Fotos und beziehen Sie sich im Kommentarfeld unbedingt auf die Dateinamen der Fotos.",
          },
        ],
        disabled: false,
        extra_feedback_price: "vstaging_office_standbild_extra_feedback",
        feedbacks_included: 1,
        furniture_price: "vstaging_office_standbild_furniture",
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei dem Virtuellen Staging helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Bitte wählen Sie die Einrichtungsgegenstände für Ihre hochgeladenen Räume aus. Sie können zu jedem Einrichtungsgegenstand einen Kommentar verfassen und Ihre Bestellung so auf Ihre Bedürfnisse modifizieren. Sollten Sie Räume kombinieren wollen, können Sie die Nummernkennung der Einrichtungsgegenstände nutzen und in dem Kommentar darauf referenzieren.",
            title: "Was meinen wir mit Modifizieren?",
          },
        ],
        isVirtualTour: false,
        name: "Office - Standbilder",
        newProperty: false,
        numberOfDrafts: 0,
        package_contents: ["Virtuelles Einrichten", "moderne Accessoires", "Web optimiert und hochauflösend"],
        package_id: "vstaging_office_standbild",
        package_images: [
          "digital_staging/basic.jpeg",
          "digital_staging/modern-office.jpeg",
          "digital_staging/industrial.jpeg",
        ],
        package_icons: ["digital_staging/office-standbilder.svg"],
        package_key: "50",
        // 'photo_price': '79,00',
        packageDescHeading: "Hochwertiges Digital Staging Office",
        physicalProduct: false,
        price: "vstaging_office_standbild_premium_price",
        price_note: "pro Bild (Preis exkl. MwSt.)",
        price_pre_text: "ab",
        renovation_price: "vstaging_office_standbild_renovation",
        service_key: OrderTypeEnum.V_Staging,
        service_title: "Digital Staging",
        styles: [
          {
            styleImage: "../../assets/images/digital_staging/basic.jpeg",
            folder: "../../assets/images/digital_staging/basic/",
            styleImages: {
              1: ["img1.jpeg", "img2.jpeg", "img3.jpeg", "img4.jpeg", "img5.jpeg"],
              2: ["img1.jpeg", "img2.jpeg", "img3.jpeg", "img4.jpeg", "img5.jpeg"],
            },
            styleLink: "Basic Stilkatalog",
            styleName: "Basic",
            selector: "Basic",
          },
          {
            styleImage: "../../assets/images/digital_staging/modern-office.jpeg",
            folder: "../../assets/images/digital_staging/modern-office/",
            styleImages: {
              1: ["img1.jpeg", "img2.jpeg", "img3.jpeg", "img4.jpeg", "img5.jpeg"],
              2: ["img1.jpeg", "img2.jpeg", "img3.jpeg", "img4.jpeg", "img5.jpeg"],
            },
            styleLink: "Modern Office Stilkatalog",
            styleName: "Modern",
            selector: "Modern Office",
          },
          {
            styleImage: "../../assets/images/digital_staging/industrial.jpeg",
            folder: "../../assets/images/digital_staging/industrial/",
            styleImages: {
              1: ["img1.jpeg", "img2.jpeg"],
              2: ["img1.jpeg", "img2.jpeg"],
            },
            styleLink: "Industrial Stilkatalog",
            styleName: "Industrial",
            selector: "Industrial",
          },
        ],
        title: "Digital Staging",
        wall_move_price: "vstaging_office_standbild_wall_move",
        service_image: "service_images/Digital-Staging.svg",
      },
      {
        choose_furniture_price: "vstaging_office_360_bilder_choose_furniture_price",
        quality_price: ["vstaging_office_360_bilder_premium-plus_price", "vstaging_office_360_bilder_premium_price"],
        quality_attributes: {
          0: [
            "Individuelle Stile und Möblierung",
            "Berücksichtigung Ihres individuellen Moodboards",
            "3 Korrekturschleifen",
          ],
          1: ["3 Stilrichtungen wählbar", "2 Korrekturschleifen"],
        },
        quality_levels: [
          {
            feedbacks_included: 3,
          },
          {
            feedbacks_included: 2,
          },
        ],
        description_placeholder: "zB.: Wohnung bitte im skandinavischen Stil einrichten",
        details: [
          {
            label: "Digital Staging - Möblierung entfernen",
            value:
              "Das digitale Stagen ist nur möglich, wenn die Räume von Möbeln befreit sind. Sollten sich Möbel auf den Bildern befinden, wird eine Gebühr von $furnishingRemovalPrice € je Bild berechnet, um diese zu beseitigen.  Wenn Böden und Fenster ausgetauscht oder Decken und Wände digital aufgewertet werden sollen, wählen Sie bitte die Option “Renovierung”. Das Entfernen der Möblierung ist in letzterer Option ebenfalls inkludiert. Beispiele: @https://www.imogent.de/digital-staging",
          },
          {
            label: "Digitales Staging - Renovierung",
            value:
              "Bei der digitalen Renovierung werden Böden und Fenster ausgetauscht, Decken und Wände digital aufgewertet und der Raum anschließend mit neuen Möbeln ausgestattet. Das Entfernen der Möblierung ist bereits im Preis inkludiert. Beispiele: @https://www.imogent.de/digital-staging",
          },
          {
            label: "Wände versetzen",
            value:
              "Bei der digitalen Renovierung können Durchbrüche oder Wände neu eingezogen werden. Die Änderungen müssen hierzu nachvollziehbar in die Grundrisse oder Fotos eingezeichnet werden. Geben Sie hier nur die Anzahl der Bilder an, die davon betroffen sind. Beispiele: @https://www.imogent.de/digital-staging",
          },
          {
            label: "Kommentar",
            value:
              "Beschreiben Sie in dem Kommentarfeld, welche Besonderheiten bei dem digitalen Staging beachtet werden sollen und beziehen Sie sich dabei auf den Namen der Datei.",
          },
          {
            label: "Fotos",
            value:
              "Nutzen Sie die jeweiligen Raumbezeichnungen als Dateinamen der zu stagenden Fotos und beziehen Sie sich im Kommentarfeld unbedingt auf die Dateinamen der Fotos.",
          },
          {
            label: "Virtuelle Tour",
            value:
              "Bei der virtuellen Tour werden die 360°-Fotos zu einer Tour zusammengesetzt. Die Tour wird von IMOGENT gehostet und als Link zur Verfügung gestellt. Die Einzelfotos erhalten Sie separat.",
          },
          {
            label: "Virtuelle Tour (Einzelspots)",
            value:
              "Bei der virtuellen Tour (Einzelspots) werden einzelne 360°-Fotos zu einer Tour zusammengesetzt. Die Tour wird von IMOGENT gehostet und als Link zur Verfügung gestellt. Die Einzelfotos erhalten Sie separat.",
          },
          {
            label: "Grundrisse",
            value:
              "Zur Orientierung in der virtuellen Tour können Grundrisse eingearbeitet werden. Die Standorte der 360°-Spots werden durch Punkte im Grundriss kenntlich gemacht.<br/>Das Einbinden der Grundrisse erzeugt keine Mehrkosten,  lediglich die Aufarbeitung der Grundrisse wird mit 29€ je Grundriss berechnet. Bei mehreren Etagen bitte die Anzahl an Grundrissen angeben und entsprechend die Grundrisse hochladen.",
          },
        ],
        disabled: false,
        extra_feedback_price: "vstaging_office_360_bilder_extra_feedback",
        feedbacks_included: 2,
        fp_price: "vstaging_office_360_bilder_fp",
        furniture_price: "vstaging_office_360_bilder_furniture",
        helperTexts: [
          {
            text: "Hier haben Sie die Möglichkeit Unterlagen, die bei dem Virtuellen Staging helfen können, einzureichen. Hierbei handelt es sich z.B. um Baupläne, Grundrisse oder Architekturskizzen.",
            title: "Welche Unterlagen werden benötigt?",
          },
          {
            text: "Bitte wählen Sie die Einrichtungsgegenstände für Ihre hochgeladenen Räume aus. Sie können zu jedem Einrichtungsgegenstand einen Kommentar verfassen und Ihre Bestellung so auf Ihre Bedürfnisse modifizieren. Sollten Sie Räume kombinieren wollen, können Sie die Nummernkennung der Einrichtungsgegenstände nutzen und in dem Kommentar darauf referenzieren.",
            title: "Was meinen wir mit Modifizieren?",
          },
        ],
        isVirtualTour: true,
        name: "Office - 360° Bilder",
        newProperty: false,
        numberOfDrafts: 0,
        package_contents: ["Virtuelles Einrichten", "moderne Accessoires", "Web optimiert und hochauflösend"],
        package_id: "vstaging_office_360_bilder",
        package_images: [
          "digital_staging/basic.jpeg",
          "digital_staging/modern-office.jpeg",
          "digital_staging/industrial.jpeg",
        ],
        package_icons: ["digital_staging/office-360-bilder.svg"],
        package_key: "51",
        // 'photo_price': '129,00',
        packageDescHeading: "Hochwertiges Digital Staging Office (360°)",
        physicalProduct: false,
        price: "vstaging_office_360_bilder_premium_price",
        price_individual_tour: "vstaging_office_360_bilder_individual_tour",
        price_note: "pro Bild (Preis exkl. MwSt.)",
        price_pre_text: "ab",
        price_virtual_tour: "vstaging_office_360_bilder_virtual_tour",
        renovation_price: "vstaging_office_360_bilder_renovation",
        service_key: OrderTypeEnum.V_Staging,
        service_title: "Digital Staging",
        styles: [
          {
            styleImage: "../../assets/images/digital_staging/basic.jpeg",
            folder: "../../assets/images/digital_staging/basic/",
            styleImages: {
              1: ["img1.jpeg", "img2.jpeg", "img3.jpeg", "img4.jpeg", "img5.jpeg"],
              2: ["img1.jpeg", "img2.jpeg", "img3.jpeg", "img4.jpeg", "img5.jpeg"],
            },
            styleLink: "Basic Stilkatalog",
            styleName: "Basic",
            selector: "Basic",
          },
          {
            styleImage: "../../assets/images/digital_staging/modern-office.jpeg",
            folder: "../../assets/images/digital_staging/modern-office/",
            styleImages: {
              1: ["img1.jpeg", "img2.jpeg", "img3.jpeg", "img4.jpeg", "img5.jpeg"],
              2: ["img1.jpeg", "img2.jpeg", "img3.jpeg", "img4.jpeg", "img5.jpeg"],
            },
            styleLink: "Modern Office Stilkatalog",
            styleName: "Modern",
            selector: "Modern Office",
          },
          {
            styleImage: "../../assets/images/digital_staging/industrial.jpeg",
            folder: "../../assets/images/digital_staging/industrial/",
            styleImages: {
              1: ["img1.jpeg", "img2.jpeg"],
              2: ["img1.jpeg", "img2.jpeg"],
            },
            styleLink: "Industrial Stilkatalog",
            styleName: "Industrial",
            selector: "Industrial",
          },
        ],
        title: "Digital Staging",
        wall_move_price: "vstaging_office_360_bilder_wall_move",
        service_image: "service_images/Digital-Staging.svg",
      },
    ],
    packageDescription:
      '<p>Das <span style="color: #22BCA4"><strong>Digital Staging</strong></span> ist nicht nur die digitale Variante des Home Staging. <b>Sie können sogar digital renovieren oder kernsanieren</b>. Sie können normale 2D-Fotos (Standbilder) oder 360°-Panorama-Bilder stagen lassen. Die 360°-Panoramen können auch zu einer virtuellen 360°-Tour zusammengesetzt werden. Immoviewer-Kunden erhalten die virtuelle Tour direkt in Ihrem Immoviewer-Account.</p><p>Das Staging ermöglicht Ihnen <b>die komplette Renovierung eines Objektes</b> und eine Einrichtung der Räumlichkeiten, die dem Geschmack Ihrer Kunden entspricht.</p><p>Schützen Sie mit einem Digital Staging die Privatsphäre Ihrer Verkäufer und steigern Sie das Vorstellungsvermögen Ihrer Interessenten. <b>Zeigen Sie das Potenzial von verwohnten Objekten und heben Sie sich von der Konkurrenz ab</b>. Alles was wir brauchen, ist ein Foto!</p><p><i>Beliebte Produktkombinationen:</i> Fotografie, 3D-Grundrisse</p>',
    packageLink: "https://www.imogent.de/digital-staging",
  },
  {
    displayPosition: 7,
    id: OrderTypeEnum.Video_Tour,
    packageDescription:
      '<p><span style="color: #22BCA4"><strong>Videotour</strong></span> statt Besichtigung! Präsentieren Sie Ihre Immobilien Tag und Nacht.</p><p>Die <b>DIY-Objektbegehung</b> beinhaltet die professionelle Bearbeitung Ihres selbst aufgenommenen Videos. Filmen Sie das gewünschte Objekt mit dem Smartphone und wir schneiden ein professionelles Video aus Ihrem Material. Kontaktieren Sie uns gern wenn sie Hilfe bei der Auswahl eines Bildstabilisators benötigen. Die <b>Objektbegehung</b> ist die richtige Wahl für Sie, wenn Sie die komplette Immobilie – innen und außen – präsentieren möchten. Der <b>Imagefilm</b> eignet sich zum Präsentieren von Highlights. Hierbei werden die schönsten Details und Besonderheiten der Immobilie in den Vordergrund gestellt. Dieses Produkt ist besonders zu empfehlen als zentraler Hingucker für Ihr digitales Exposé.</p><p><i>Beliebte Produktkombinationen:</i> Grundrisse, Fotografie, Luftaufnahmen</p>',
    packageLink: "https://www.imogent.de/videotour",
    packages: [
      {
        additional_room_price: "video_tour_additional_room_price",
        additional_video_link_price: "video_tour_additional_video_link_price",
        background_music: [
          {
            id: 1,
            src: "assets/audio/video_tour/Titel-01_Beautiful-Dream-by-Valdi-Sabev-Lets-Play.mp3",
            title: "Titel 1",
          },
          {
            id: 2,
            src: "assets/audio/video_tour/Titel-02_Call-On-Me-by-Kick-Lee.mp3",
            title: "Titel 2",
          },
          {
            id: 3,
            src: "assets/audio/video_tour/Titel-03_forever_by_ethan-rank.mp3",
            title: "Titel 3",
          },
          {
            id: 4,
            src: "assets/audio/video_tour/Titel-04_hey-now_by_campagna.mp3",
            title: "Titel 4",
          },
          {
            id: 5,
            src: "assets/audio/video_tour/Titel-05_jester-acoustic-version_by_ian-post.mp3",
            title: "Titel 5",
          },
          {
            id: 6,
            src: "assets/audio/video_tour/Titel-06_paper-planes_by_paper-planes.mp3",
            title: "Titel 6",
          },
          {
            id: 7,
            src: "assets/audio/video_tour/Titel-07_the-language-of-flowers_by_rew.mp3",
            title: "Titel 7",
          },
          {
            id: 8,
            src: "assets/audio/video_tour/Titel-08_then-i-came_by_ziv-moran.mp3",
            title: "Titel 8",
          },
          {
            id: 9,
            src: "assets/audio/video_tour/Titel-09_timeless_by_buzo.mp3",
            title: "Titel 9",
          },
        ],
        companies: ["IMOGENT GmbH", "Die BeratungsManufaktur GmbH"],
        dateArrangementRequired: true,
        defaultQuantity: 5,
        description_placeholder: "",
        details: [
          {
            label: "Nachbearbeitung",
            value:
              "In der Nachbearbeitung wird das Video geschnitten und es werden die Helligkeit sowie die Farbgebung der Aufnahmen angepasst. Weitere Spezifikationen können im Bestellprozess ergänzt werden.",
          },
          {
            label: "Aufnahmedauer",
            value:
              'Die durchschnittliche Aufnahmedauer vor Ort ist <a href="https://www.imogent.de/dauer-aufnahmen" target="_blank">hier</a> einsehbar. Bitte planen Sie genug Zeit ein, damit unsere Dienstleister:innen ein optimales Ergebnis erzielen können.',
          },
          {
            label: "Bearbeitungszeiten",
            value:
              "In der Regel finden wir innerhalb von 2 Werktagen eine/n geeignete/n Dienstleister:in für Sie. In Randgebieten kann es jedoch vorkommen, dass unser Netzwerk erweitert werden muss. Dies kann bis zu 7 Werktage in Anspruch nehmen. Sollte das bei Ihrer Bestellung erforderlich sein, informieren wir Sie rechtzeitig und es besteht die Möglichkeit Ihre Bestellung kostenfrei zu stornieren.",
          },
          {
            label: "Zusatzkosten",
            value:
              "Verzögerungen, die durch die Erbringung zusätzlicher Leistungen vor Ort entstehen (z.B. das Aufräumen einer schlecht vorbereiteten Immobilie, Aufnahmen in mehr als einer abgeschlossenen Wohneinheit bzw. in mehr als einem Objekt), werden mit 60,00€ pro angefangenen 30 Minuten in Rechnung gestellt.",
          },
          {
            label: "Logo einfügen",
            value:
              "Das Logo wird je nach Auswahl an verschiedenen Stellen im Video platziert. Es ist auch eine Kombination der Positionen möglich.",
          },
          {
            label: "Privatsphäre",
            value:
              "Zur Wahrung der Persönlichkeitsrechte wird bei Luftaufnahmen die Umgebung unkenntlich gemacht. Außerdem werden vertrauliche Details wie Dokumente, Fotos oder Kennzeichen verpixelt.",
          },
          {
            label: "Ausgabeformat",
            value:
              "Das Video wird in zwei Versionen zur Verfügung gestellt. Sonderwünsche müssen im Kommentarfeld angegeben werden.<br/><ul><li><strong>1920x1080p</strong> (max. 300 MB) <strong>MP4</strong></li><li><strong>1280x720p</strong> (max. 15 MB) <strong>MP4</strong></li></ul>",
          },
          {
            label: "Hintergrundmusik",
            value:
              "Das Video kann nach Wahl mit Musik hinterlegt werden. Bitte wählen Sie den entsprechenden Titel direkt im Bestellprozess.",
          },
          {
            label: "Intro/Outro",
            value:
              "Auf Wunsch kann ihr persönliches Intro/Outro ins Video integriert werden. Stellen Sie uns dazu die Dateien im MP4 oder MOV Format entsprechend gekennzeichnet zur Verfügung.",
          },
          {
            label: "Wichtige Infos",
            value:
              "Um die Tour bereits im Vorhinein gut planen zu können, sind jegliche Informationen zur Immobilie hilfreich. Laden Sie gerne Grundrisse oder Fotos in dem dafür vorgesehenen Feld hoch oder beschreiben Sie das Gebäude kurz im Kommentarfeld. Sollten bestimmte Eigenschaften den Videodreh erschweren (z.B. kleine, dunkle Räume), geben Sie dies ebenfalls im Kommentarfeld an.",
          },
        ],
        disabled: false,
        drone_footage_price: "video_tour_drone_footage",
        extra_feedback_price: "video_tour_extra_feedback_price",
        feedbacks_included: 1,
        helpTexts: {
          backgroundMusic: {
            label: "Hintergrundmusik",
            text: "Das Video kann nach Wahl mit Musik hinterlegt werden. Bitte wählen Sie hier den gewünschten Titel.",
          },
          blurred: {
            label: "Weichzeichnen",
            text: "Zur Bewahrung der Persönlichkeitsrechte wird bei Luftaufnahmen die Umgebung unkenntlich gemacht. Vertrauliche Details wie Dokumente oder Fotos werden im Video ebenfalls weichgezeichnet.",
          },
          logo: {
            label: "Logo einfügen",
            text: "Bitte geben Sie die gewünschte Position des Logos an. Es ist auch möglich, mehrere Positionen zu kombinieren.",
          },
          roomName: {
            label: "Raumbezeichnungen",
            text: "Während des Rundgangs werden die jeweiligen Raumbezeichnungen eingeblendet. Bitte laden Sie hierzu einen Grundriss mit den gewünschten Bezeichnungen hoch.",
          },
          title: {
            label: "Titel wählen",
            text: "<p>Bitte wählen Sie den Titel des Videos.</p><p>Beispiel Haupttitel:<br><strong>3-Zimmer-Wohnung in Bielefeld</strong></p><p>Beispiel Nebentitel<br><strong>Große Terrasse und direkter Zugang zum weitläufigen Garten – optimal für junge Familien.</strong></p>",
          },
        },
        infoMessages: [
          {
            label: "Produktdetails",
            value:
              "Es werden alle Räume der Immobilie gezählt, die gefilmt werden sollen außer Abstellkammern, WCs und Flure. Nutzen Sie das Kommentarfeld, um Räume oder Etagen auszuschließen.",
          },
          {
            label: "Terminvereinbarung",
            value:
              "Sobald Sie den Buchungsvorgang abgeschlossen haben, prüfen wir die Verfügbarkeit unserer Fotografen. Sie erhalten innerhalb von zwei Werktagen eine Rückmeldung zum Bearbeitungsstatus und die angegebene Kontaktperson wird bei Verfügbarkeit umgehend kontaktiert.",
          },
          {
            label: "Vorbereitung",
            value: `Bitte sorgen Sie dafür, dass die Immobilie gut vorbereitet ist und beachten Sie dazu unbedingt unsere <a href=${CONSTANTS.ASSETS.PHOTOGRAPHY.PREPARATION_TIPS}>Vorbereitungshilfen</a> für Makler und Eigentümer. Aufräumarbeiten, die von unseren Fotografen durchgeführt werden müssen und eine halbe Stunde übersteigen, werden mit 60,00€ pro angefangenen 30 Minuten in Rechnung gestellt.`,
          },
          {
            label: "Stornierung",
            value:
              "Bitte beachten Sie unsere Stornierungsbedingungen in der linken Seitenleiste. Eine kostenfreie Stornierung ist lediglich bis zu 72h vor dem geplanten Aufnahmetermin möglich.",
          },
        ],
        name: "Objektbegehung",
        newProperty: false,
        numberOfDrafts: 0,
        packageDescHeading: "Professionelles Imagevideo Ihrer Immobilie\n",
        package_contents: [
          "Videorundgang durch Ihre Immobilie",
          "Dreh und Schnitt inklusive",
          "2 Ausgabeformate",
          "Miniclip für Social Media",
        ],
        package_id: "video_tour",
        package_images: ["videotour.jpg"],
        package_icons: ["service_images/Videotour.svg"],
        package_key: "31",
        physicalProduct: false,
        price: "video_tour_price",
        price_note: "(Preis exkl. MwSt.)",
        price_options: ["video_tour_5_additional_rooms_price", "video_tour_10_additional_rooms_price"],
        price_pre_text: "ab",
        qty_price_options: {
          "0": {
            defaultQty: 5,
            options: [
              {
                addQty: 0,
                price: "video_tour_price",
                title: "Bis zu 5 Räume",
                value: "0",
              },
              {
                addQty: 5,
                price: "video_tour_5_additional_rooms_price",
                title: "Bis zu 10 Räume",
                value: "1",
              },
              {
                addQty: 10,
                price: "video_tour_10_additional_rooms_price",
                title: "Bis zu 15 Räume",
                value: "2",
              },
            ],
          },
        },
        service_image: "service_images/Videotour.svg",
        service_key: OrderTypeEnum.Video_Tour,
        service_title: "Videotour",
        title: "Videotour",
        tooltip:
          "Bei der Objektbegehung wird die Immobilie so realistisch wie möglich dargestellt. Idealerweise sollen  alle Räume sowie Außenbereiche innerhalb der Videotour präsentiert werden. Die Nachbearbeitung zeichnet sich durch wenige Schnitte aus. Das fertige Produkt kommt einer Vor-Ort-Begehung sehr nahe.",
      },
      {
        companies: ["^dummy_not_available"],
        dateArrangementRequired: true,
        description_placeholder: "",
        details: [],
        disabled: true,
        name: "Objektbegehung_Video",
        newProperty: false,
        numberOfDrafts: 0,
        package_contents: [],
        package_images: [],
        package_icons: [],
        package_key: "32",
        physicalProduct: false,
        price: "",
        price_note: "",
        price_pre_text: "",
        service_image: "service_images/Videotour.svg",
        service_key: OrderTypeEnum.Video_Tour,
        service_title: "Videotour",
        title: "Videotour",
      },
      {
        additional_sec_price: "image_video_additional_sec_price",
        additional_video_link_price: "image_video_additional_video_link_price",
        background_music: [
          {
            id: 1,
            src: "assets/audio/video_tour/Titel-01_Beautiful-Dream-by-Valdi-Sabev-Lets-Play.mp3",
            title: "Titel 1",
          },
          {
            id: 2,
            src: "assets/audio/video_tour/Titel-02_Call-On-Me-by-Kick-Lee.mp3",
            title: "Titel 2",
          },
          {
            id: 3,
            src: "assets/audio/video_tour/Titel-03_forever_by_ethan-rank.mp3",
            title: "Titel 3",
          },
          {
            id: 4,
            src: "assets/audio/video_tour/Titel-04_hey-now_by_campagna.mp3",
            title: "Titel 4",
          },
          {
            id: 5,
            src: "assets/audio/video_tour/Titel-05_jester-acoustic-version_by_ian-post.mp3",
            title: "Titel 5",
          },
          {
            id: 6,
            src: "assets/audio/video_tour/Titel-06_paper-planes_by_paper-planes.mp3",
            title: "Titel 6",
          },
          {
            id: 7,
            src: "assets/audio/video_tour/Titel-07_the-language-of-flowers_by_rew.mp3",
            title: "Titel 7",
          },
          {
            id: 8,
            src: "assets/audio/video_tour/Titel-08_then-i-came_by_ziv-moran.mp3",
            title: "Titel 8",
          },
          {
            id: 9,
            src: "assets/audio/video_tour/Titel-09_timeless_by_buzo.mp3",
            title: "Titel 9",
          },
        ],
        companies: ["^dummy_not_available"],
        customerSpecificInfos: {
          "1xtGCdNse3d27fd6HqnI":
            "<p><b>INNENAUFNAHMEN</b><br>60-Sekündiges Boden Innenvideo inkl. Videoschnitt und lizenzfreier Musik , JLL Logo sowie animiertes JLL Intro und Outro und Maklerinfos</p> <p><b>Innenaufnahmen - Zusätzlich zu gebuchten Film Paketen</b><br>Zusammen mit Filmpaketen: 60-Sekündiges Video aus Drohnen und Boden-Innenaufnahmen inkl. Videoschnitt und lizenzfreier Musik , JLL Logo sowie animiertes JLL-Intro und Outro und Maklerinfos</p>",
          M5IYpTepB9g0b9P74poO:
            "<p><b>INNENAUFNAHMEN</b><br>60-Sekündiges Boden Innenvideo inkl. Videoschnitt und lizenzfreier Musik , JLL Logo sowie animiertes JLL Intro und Outro und Maklerinfos</p> <p><b>Innenaufnahmen - Zusätzlich zu gebuchten Film Paketen</b><br>Zusammen mit Filmpaketen: 60-Sekündiges Video aus Drohnen und Boden-Innenaufnahmen inkl. Videoschnitt und lizenzfreier Musik , JLL Logo sowie animiertes JLL-Intro und Outro und Maklerinfos</p>",
          OZthUnBReNW1F9Yqvai0:
            "<p><b>INNENAUFNAHMEN</b><br>60-Sekündiges Boden Innenvideo inkl. Videoschnitt und lizenzfreier Musik , JLL Logo sowie animiertes JLL Intro und Outro und Maklerinfos</p> <p><b>Innenaufnahmen - Zusätzlich zu gebuchten Film Paketen</b><br>Zusammen mit Filmpaketen: 60-Sekündiges Video aus Drohnen und Boden-Innenaufnahmen inkl. Videoschnitt und lizenzfreier Musik , JLL Logo sowie animiertes JLL-Intro und Outro und Maklerinfos</p>",
        },
        dateArrangementRequired: true,
        defaultQuantity: 45,
        description_placeholder: "",
        details: [
          {
            label: "Nachbearbeitung",
            value:
              "In der Nachbearbeitung wird das Video geschnitten und es werden die Helligkeit sowie die Farbgebung der Aufnahmen angepasst. Weitere Spezifikationen können im Bestellprozess ergänzt werden.",
          },
          {
            label: "Aufnahmedauer",
            value:
              'Die durchschnittliche Aufnahmedauer vor Ort ist <a href="https://www.imogent.de/dauer-aufnahmen" target="_blank">hier</a> einsehbar. Bitte planen Sie genug Zeit ein, damit unsere Dienstleister:innen ein optimales Ergebnis erzielen können.',
          },
          {
            label: "Bearbeitungszeiten",
            value:
              "In der Regel finden wir innerhalb von 2 Werktagen eine/n geeignete/n Dienstleister:in für Sie. In Randgebieten kann es jedoch vorkommen, dass unser Netzwerk erweitert werden muss. Dies kann bis zu 7 Werktage in Anspruch nehmen. Sollte das bei Ihrer Bestellung erforderlich sein, informieren wir Sie rechtzeitig und es besteht die Möglichkeit Ihre Bestellung kostenfrei zu stornieren.",
          },
          {
            label: "Zusatzkosten",
            value:
              "Verzögerungen, die durch die Erbringung zusätzlicher Leistungen vor Ort entstehen (z.B. das Aufräumen einer schlecht vorbereiteten Immobilie, Aufnahmen in mehr als einer abgeschlossenen Wohneinheit bzw. in mehr als einem Objekt), werden mit 60,00€ pro angefangenen 30 Minuten in Rechnung gestellt.",
          },
          {
            label: "Logo einfügen",
            value:
              "Das Logo wird je nach Auswahl an verschiedenen Stellen im Video platziert. Es ist auch eine Kombination der Positionen möglich.",
          },
          {
            label: "Privatsphäre",
            value:
              "Zur Wahrung der Persönlichkeitsrechte wird bei Luftaufnahmen die Umgebung unkenntlich gemacht. Außerdem werden vertrauliche Details wie Dokumente, Fotos oder Kennzeichen verpixelt.",
          },
          {
            label: "Ausgabeformat",
            value:
              "Das Video wird in zwei Versionen zur Verfügung gestellt. Sonderwünsche müssen im Kommentarfeld angegeben werden. <br/><ul><li><strong>1920x1080p</strong> (max. 300 MB) <strong>MP4</strong></li><li><strong>1280x720p</strong> (max. 15 MB) <strong>MP4</strong></li></ul>",
          },
          {
            label: "Hintergrundmusik",
            value:
              "Das Video kann nach Wahl mit Musik hinterlegt werden. Bitte wählen Sie den entsprechenden Titel direkt im Bestellprozess.",
          },
          {
            label: "Intro / Outro",
            value:
              "Auf Wunsch kann Ihr persönliches Intro/Outro ins Video integriert werden. Stellen Sie uns dazu die Dateien im MP4 oder MOV Format entsprechend gekennzeichnet zur Verfügung.",
          },
          {
            label: "Wichtige Infos",
            value:
              "Um die Tour bereits im Vorhinein gut planen zu können, sind jegliche Informationen zur Immobilie hilfreich. Laden Sie gerne Grundrisse oder Fotos in dem dafür vorgesehenen Feld hoch oder beschreiben Sie das Gebäude kurz im Kommentarfeld. Sollten bestimmte Eigenschaften den Videodreh erschweren (z.B. kleine, dunkle Räume), geben Sie dies ebenfalls im Kommentarfeld an.",
          },
        ],
        disabled: false,
        drone_footage_price: "image_video_drone_footage",
        extra_feedback_price: "image_video_extra_feedback_price",
        feedbacks_included: 1,
        helpTexts: {
          backgroundMusic: {
            label: "Hintergrundmusik",
            text: "Das Video kann nach Wahl mit Musik hinterlegt werden. Bitte wählen Sie hier den gewünschten Titel.",
          },
          blurred: {
            label: "Weichzeichnen",
            text: "Zur Bewahrung der Persönlichkeitsrechte wird bei Luftaufnahmen die Umgebung unkenntlich gemacht. Vertrauliche Details wie Dokumente oder Fotos werden im Video ebenfalls weichgezeichnet.",
          },
          logo: {
            label: "Logo einfügen",
            text: "Bitte geben Sie die gewünschte Position des Logos an. Es ist auch möglich, mehrere Positionen zu kombinieren.",
          },
          roomName: {
            label: "Raumbezeichnungen",
            text: "Während des Rundgangs werden die jeweiligen Raumbezeichnungen eingeblendet. Bitte laden Sie hierzu einen Grundriss mit den gewünschten Bezeichnungen hoch.",
          },
          title: {
            label: "Titel wählen",
            text: "<p>Bitte wählen Sie den Titel des Videos.</p><p>Beispiel Haupttitel:<br><strong>3-Zimmer-Wohnung in Bielefeld</strong></p><p>Beispiel Nebentitel<br><strong>Große Terrasse und direkter Zugang zum weitläufigen Garten – optimal für junge Familien.</strong></p>",
          },
        },
        infoMessages: [
          {
            label: "Produktdetails",
            value:
              "Es werden lediglich Highlights der Immobilie gezeigt. Um sicherzugehen, dass alle gewünschten Bereiche im finalen Video enthalten sind, nutzen Sie bitte das Kommentarfeld. Wenn Sie das gesamte Objekt zeigen möchten, bieten wir auch eine Objektbegehung in Videoform an.",
          },
          {
            label: "Terminvereinbarung",
            value:
              "Sobald Sie den Buchungsvorgang abgeschlossen haben, prüfen wir die Verfügbarkeit unserer Fotografen. Sie erhalten innerhalb von zwei Werktagen eine Rückmeldung zum Bearbeitungsstatus und die angegebene Kontaktperson wird bei Verfügbarkeit umgehend kontaktiert.",
          },
          {
            label: "Vorbereitung",
            value: `Bitte sorgen Sie dafür, dass die Immobilie gut vorbereitet ist und beachten Sie dazu unbedingt unsere <a href=${CONSTANTS.ASSETS.PHOTOGRAPHY.PREPARATION_TIPS}>Vorbereitungshilfen</a> für Makler und Eigentümer. Aufräumarbeiten, die von unseren Fotografen durchgeführt werden müssen und eine halbe Stunde übersteigen, werden mit 60,00€ pro angefangenen 30 Minuten in Rechnung gestellt.`,
          },
          {
            label: "Stornierung",
            value:
              "Bitte beachten Sie unsere Stornierungsbedingungen in der linken Seitenleiste. Eine kostenfreie Stornierung ist lediglich bis zu 72h vor dem geplanten Aufnahmetermin möglich.",
          },
        ],
        maximalFileUploadNumber: 30,
        name: "Imagefilm",
        newProperty: false,
        numberOfDrafts: 0,
        packageDescHeading: "Professionelles Imagevideo Ihrer Immobilie",
        package_contents: [
          "Imagefilm Ihrer Immobilie",
          "Dreh und Schnitt inklusive",
          "2 Ausgabeformate",
          "Miniclip für Social Media",
        ],
        package_id: "image_video",
        package_images: ["video_tour/Imagefilm_square.jpg"],
        package_icons: [],
        package_key: "33",
        physicalProduct: false,
        postprocessingHiddenCompanyIds: ["OZthUnBReNW1F9Yqvai0"],
        price: "image_video_price",
        price_note: "(Preis exkl. MwSt.)",
        price_options: ["image_video_15_additional_seconds_price", "image_video_45_additional_seconds_price"],
        price_pre_text: "ab",
        qty_price_options: {
          "1": {
            defaultQty: 45,
            options: [
              {
                addQty: 0,
                price: "image_video_price",
                title: "45 Sekunden",
                value: "0",
              },
              {
                addQty: 15,
                price: "image_video_15_additional_seconds_price",
                title: "60 Sekunden",
                value: "1",
              },
              {
                addQty: 45,
                price: "image_video_45_additional_seconds_price",
                title: "90 Sekunden",
                value: "2",
              },
            ],
          },
          "3": {
            defaultQty: 45,
            options: [
              {
                addQty: 15,
                price: "image_video_15_additional_seconds_price",
                title: "60 Sekunden",
                value: "1",
              },
            ],
          },
        },
        service_image: "service_images/Videotour.svg",
        service_key: OrderTypeEnum.Video_Tour,
        service_title: "Videotour",
        title: "Videotour",
        tooltip:
          "Der Imagefilm stellt die schönsten Details und Besonderheiten der Immobilie heraus. Weniger schöne Bereiche des Objektes werden in dieser Art von Video bewusst nicht präsentiert.",
      },
      {
        companies: ["^dummy_not_available"],
        dateArrangementRequired: true,
        description_placeholder: "",
        details: [],
        disabled: true,
        name: "Imagefilm_Video",
        newProperty: false,
        numberOfDrafts: 0,
        package_contents: [],
        package_images: [],
        package_icons: [],
        package_key: "34",
        physicalProduct: false,
        price: "",
        price_note: "",
        price_pre_text: "",
        service_image: "service_images/Videotour.svg",
        service_key: OrderTypeEnum.Video_Tour,
        service_title: "Videotour",
        title: "Videotour",
      },
      {
        additional_sec_price: "video_tour_diy_extra_seconds_price",
        additional_video_link_price: "video_tour_diy_additional_video_link_price",
        background_music: [
          {
            id: 1,
            src: "assets/audio/video_tour/Titel-01_Beautiful-Dream-by-Valdi-Sabev-Lets-Play.mp3",
            title: "Titel 1",
          },
          {
            id: 2,
            src: "assets/audio/video_tour/Titel-02_Call-On-Me-by-Kick-Lee.mp3",
            title: "Titel 2",
          },
          {
            id: 3,
            src: "assets/audio/video_tour/Titel-03_forever_by_ethan-rank.mp3",
            title: "Titel 3",
          },
          {
            id: 4,
            src: "assets/audio/video_tour/Titel-04_hey-now_by_campagna.mp3",
            title: "Titel 4",
          },
          {
            id: 5,
            src: "assets/audio/video_tour/Titel-05_jester-acoustic-version_by_ian-post.mp3",
            title: "Titel 5",
          },
          {
            id: 6,
            src: "assets/audio/video_tour/Titel-06_paper-planes_by_paper-planes.mp3",
            title: "Titel 6",
          },
          {
            id: 7,
            src: "assets/audio/video_tour/Titel-07_the-language-of-flowers_by_rew.mp3",
            title: "Titel 7",
          },
          {
            id: 8,
            src: "assets/audio/video_tour/Titel-08_then-i-came_by_ziv-moran.mp3",
            title: "Titel 8",
          },
          {
            id: 9,
            src: "assets/audio/video_tour/Titel-09_timeless_by_buzo.mp3",
            title: "Titel 9",
          },
        ],
        companies: ["^dummy_not_available"],
        description_placeholder: "",
        details: [
          {
            label: "Nachbearbeitung",
            value:
              "In der Nachbearbeitung wird das Video geschnitten und es werden die Helligkeit sowie die Farbgebung der Aufnahmen angepasst. Weitere Spezifikationen können im Bestellprozess ergänzt werden.",
          },
          {
            label: "Logo einfügen",
            value:
              "Das Logo wird je nach Auswahl an verschiedenen Stellen im Video platziert. Es ist auch eine Kombination der Positionen möglich.",
          },
          {
            label: "Privatsphäre",
            value:
              "Zur Wahrung der Persönlichkeitsrechte werden vertrauliche Details wie Dokumente, Fotos oder Kennzeichen unkenntlich gemacht.",
          },
          {
            label: "Ausgabeformat",
            value:
              "Das Video wird in zwei Versionen zur Verfügung gestellt. Sonderwünsche müssen im Kommentarfeld angegeben werden.<br><strong>Volle Auflösung</strong> (abhängig vom verwendeten Smartphone, max. 300 MB) <strong>Webversion</strong> (max. 15 MB)",
          },
          {
            label: "Hintergrundmusik",
            value:
              "Das Video kann nach Wahl mit Musik hinterlegt werden. Bitte wählen Sie den entsprechenden Titel direkt im Bestellprozess.",
          },
          {
            label: "Intro / Outro",
            value:
              "Auf Wunsch kann Ihr persönliches Intro/Outro ins Video integriert werden. Stellen Sie uns dazu die Dateien im MP4 oder MOV Format entsprechend gekennzeichnet zur Verfügung.",
          },
          {
            label: "Wichtige Infos",
            value:
              "Bitte nummerien Sie die Dateien entsprechend der gewünschten Reihenfolge im finalen Video. Sollten Sie sich für eine Variante mit Raumbezeichnungen entscheiden, stellen Sie unbedingt einen beschrifteten Grundriss zur Verfügung oder geben Sie die gewünschten Bezeichnungen im Kommentarfeld an.",
          },
          {
            label: "Anleitung",
            value:
              'Unter folgendem <A href="https://app.imogent.com/assets/documents/services/videotour/Erstellen_von_Immobilienvideos.pdf" target="_blank">Link</a> finden Sie alle wichtigen Infos zum Thema „Filmen von Immobilienvideos mit dem Smartphone"',
          },
        ],
        disabled: false,
        extra_feedback_price: "video_tour_diy_extra_feedback_price",
        feedbacks_included: 1,
        helpTexts: {
          backgroundMusic: {
            label: "Hintergrundmusik",
            text: "Das Video kann nach Wahl mit Musik hinterlegt werden. Bitte wählen Sie hier den gewünschten Titel.",
          },
          blurred: {
            label: "Weichzeichnen",
            text: "Zur Bewahrung der Persönlichkeitsrechte wird bei Luftaufnahmen die Umgebung unkenntlich gemacht. Vertrauliche Details wie Dokumente oder Fotos werden im Video ebenfalls weichgezeichnet.",
          },
          logo: {
            label: "Logo einfügen",
            text: "Bitte geben Sie die gewünschte Position des Logos an. Es ist auch möglich, mehrere Positionen zu kombinieren.",
          },
          roomName: {
            label: "Raumbezeichnungen",
            text: "Während des Rundgangs werden die jeweiligen Raumbezeichnungen eingeblendet. Bitte laden Sie hierzu einen Grundriss mit den gewünschten Bezeichnungen hoch.",
          },
          title: {
            label: "Titel wählen",
            text: "<p>Bitte wählen Sie den Titel des Videos.</p><p>Beispiel Haupttitel:<br><strong>3-Zimmer-Wohnung in Bielefeld</strong></p><p>Beispiel Nebentitel<br><strong>Große Terrasse und direkter Zugang zum weitläufigen Garten – optimal für junge Familien.</strong></p>",
          },
        },
        infoMessages: [],
        maximalFileUploadNumber: 10,
        name: "DIY Objektbegehung",
        newProperty: false,
        numberOfDrafts: 0,
        packageDescHeading: "Hochwertige Aufbereitung Ihrer Videotour",
        package_contents: ["Professionelle Videobearbeitung", "2 Ausgabeformate", "Miniclip für Social Media"],
        package_id: "video_tour_diy",
        package_images: ["video_tour_diy.jpg"],
        package_icons: [],
        package_key: "38",
        physicalProduct: false,
        price: "video_tour_diy_price",
        price_additional_caption: "video_tour_diy_additional_caption",
        price_note: "(Preis exkl. MwSt.)",
        price_options: ["video_tour_diy_premium_package", "video_tour_diy_premium_plus_package"],
        price_pre_text: "ab",
        qty_price_options: {
          "0": {
            defaultQty: 0,
            options: [
              {
                addQty: 0,
                price: "video_tour_diy_price",
                title: "Standard",
                value: "0",
              },
              {
                addQty: 0,
                price: "video_tour_diy_premium_package",
                title: "Premium",
                value: "1",
              },
              {
                addQty: 0,
                price: "video_tour_diy_premium_plus_package",
                title: "Premium Plus",
                value: "2",
              },
            ],
          },
        },
        quality_attributes: {
          "0": [
            "Professioneller Schnitt Ihres Videos",
            "Integration eines Logos möglich",
            "Integration eines Intros / Outros möglich",
            "Bis zu 10 3D-Raumbezeichnungen",
          ],
          "1": [
            "Professioneller Schnitt Ihres Videos",
            "Integration eines Logos möglich",
            "Integration eines Intros / Outros möglich",
            "Bis zu 10 2D-Raumbezeichnungen",
          ],
          "2": [
            "Professioneller Schnitt Ihres Videos",
            "Integration eines Logos möglich",
            "Integration eines Intros / Outros möglich",
          ],
        },
        service_image: "service_images/Videotour.svg",
        service_key: OrderTypeEnum.Video_Tour,
        service_title: "Videotour",
        title: "Videotour",
        tooltip:
          "Bei der DIY Objektbegehung schneiden wir aus Ihrem vor Ort erstellen Videomaterial ein professionelles und ansprechendes Video. Alles was Sie dazu benötigen ist Ihr Smartphone.",
      },
    ],
  },
  {
    id: OrderTypeEnum.Price_Hubble,
    displayPosition: 17,
    packages: [
      {
        details: [
          {
            label: "Price Hubble",
            value: "",
          },
        ],
        dateArrangementRequired: false,
        disabled: false,
        extra_details: "",
        name: "Bewertungsdossier",
        numberOfDrafts: 0,
        newProperty: false,
        package_contents: [],
        package_id: "price_hubble_dossier",
        package_images: ["price_hubble/price_hubble_dossier.jpg"],
        package_icons: ["service_images/Bewertungen.svg"],
        package_key: "47",
        packageDescHeading: "PriceHubble Bewertungsdossier",
        physicalProduct: false,
        price: "price_hubble_price",
        price_free: "price_hubble_price_free_volume",
        extra_feedback_price: "price_hubble_feedback_price",
        feedbacks_included: 0,
        price_note: "(Preis exkl. MwSt.)",
        price_pre_text: "ab",
        service_key: OrderTypeEnum.Price_Hubble,
        service_title: "PriceHubble",
        title: "Bewertungsdossier",
        service_image: "service_images/Bewertungen.svg",
      },
    ],
  },
  {
    id: OrderTypeEnum.Area_Calculation,
    displayPosition: 21,
    packages: [
      {
        addressInputRequired: true,
        additional_room_price: "area_calculation_additional_room",
        certificate_price: "area_calculation_certificate",
        details: [
          {
            label: "Infos zur Bearbeitung",
            value:
              "Anhand Ihrer Vorlage erstellen wir einen aktuellen Grundriss inkl. aller benötigten Angaben. Dafür senden Sie uns im Idealfall die Baupläne/Bauzeichnungen der Immobilie, welche alle Maßangaben aufweisen, zu. Grundsätzlich ist es auch möglich, aus händischen Skizzen einen aktuellen Grundriss zu erstellen. Achten Sie dabei auf die Dachschrägen und kennzeichnen Sie die Höhenlinien (ein und zwei Meter).<br/><br/>Sollten wir aus den vorhandenen Maßen keine exakten Ergebnisse generieren können, behalten wir uns vor, statt einer Wohnflächenaufstellung eine Flächenaufstellung zu erstellen. Diese kommt einer Wohnflächenberechnung nach der DIN-Norm 277 am nächsten und weist die Grundflächen der Räume ohne Berücksichtigung von etwaigen niedrigen Decken aus.",
          },
          {
            label: "Haftungsausschluss",
            value:
              "Für die Richtigkeit der gelieferten Maße übernimmt IMOGENT keinerlei Haftung. Bitte prüfen Sie Ihre Angaben auf Richtigkeit und Vollständigkeit! Alternativ bieten wir eine Aufmaßerstellung vor Ort an. Diese finden Sie unter Service buchen > Wohnflächenberechnung > Aufmaßerstellung.",
          },
          {
            label: "Korrekturschleifen",
            value:
              "Eine kostenlose Korrekturschleife ist nicht im Preis enthalten. Nachträgliche Korrekturen, die auf falsche oder fehlende Angaben zurückzuführen sind, werden mit einem Aufpreis von 15€/Korrekturschleife berechnet. Hat die Korrektur Einfluss auf die Berechnungen, muss ein neuer Auftrag aufgegeben werden.  Sollte der Fehler auf unserer Seite liegen, ist die Änderung selbstverständlich kostenfrei. Nach Erhalt der Ergebnisse haben Sie 14 Tage Zeit Feedback abzugeben. Anschließend wird der Auftrag automatisch geschlossen.",
          },
          {
            label: "Bearbeitungszeiten",
            value:
              "Die Bearbeitungszeit beträgt in der Regel 24-48h, sofern alle Angaben vollständig geliefert wurden. Korrekturen werden innerhalb von 24h bearbeitet.",
          },
          {
            label: "Stornierungsbedingungen",
            value:
              "Sollten Sie Ihren Auftrag vor Beginn der Bearbeitung stornieren, ist eine kostenfreie Stornierung möglich. Bei einer Stornierung nach Bearbeitungsstart werden 100% des Rechnungsbetrages fällig.",
          },
          {
            label: "Preise",
            value:
              "<strong>Basispreis:</strong><br/>Pro Etage fallen für die Erstellung des Grundrisses und der Wohnflächentabelle Kosten in Höhe von 64,90€ an.<br/><br/><strong>Architektenstempel:</strong><br/>Für die Architektenprüfung inkl. Stempel berechnen wir einen Aufpreis von 25,00€.<br/><br/><strong>Raumpreis:</strong><br/>Der Basispreis enthält 7 Räume. Jeder weitere Raum wird mit 10,00€ berechnet.<br/><br/><strong>Beispiele:</strong><br/>Eine ETW mit 7 Räumen: 64,90€ (89,90€ inkl. Architektenstempel)<br/>Eine ETW mit 8 Räumen: 74,90€ (99,90€ inkl. Architektenstempel)",
          },
        ],
        disabled: false,
        extra_feedback_price: "area_calculation_feedback",
        feedbacks_included: 0,
        name: "Auf Basis bemaßter Grundrisse", //If you change this, please update src/app/language/language.ts file as well.
        numberOfDrafts: 0,
        packageDescHeading: "Flächenberechnung auf Basis bemaßter Grundrisse",
        package_contents: [
          "Auf Basis der aktuellen WoFlV",
          "Ausstellung eines Architektenstempel möglich",
          "Bemaßte Grundrisse inkl.",
        ],
        package_id: "area_calculation_floorplan_based",
        package_images: ["area_calculation/package_image.jpg"],
        package_icons: ["area_calculation/Auf-Basis-bemaster-Grundrisse.svg"],
        package_key: "60",
        price: "area_calculation_price",
        service_key: OrderTypeEnum.Area_Calculation,
        service_title: "Wohnflächenberechnung",
        title: "Wohnflächenberechnung",
        tooltip: "Auf Basis bemaßter Grundrisse",
        price_pre_text: "ab",
        service_image: "service_images/Wohnflachenberechnung.svg",
      },
      {
        addressInputRequired: true,
        details: [
          {
            label: "Terminvereinbarung",
            value:
              "Geben Sie bitte zwei mögliche Kalenderwochen an. Innerhalb von einem Werktag nach Auftragseingang werden Sie von einem unserer Vermessungstechnikern kontaktiert, um weitere Details zu besprechen und einen Termin zu vereinbaren.",
          },
          {
            label: "Dauer vor Ort",
            value: `Die durchnittliche Dauer der Aufmaßerstellung vor Ort ist von der Größe und Komplexität des zu vermessenden Objektes abhängig. Grobe Richtwerte sind <a href="https://www.imogent.de/dauer-aufnahmen/" target="_blank">hier</a> einsehbar. Bitte planen Sie genügend Zeit ein und stellen Sie sicher, dass das Objekt, sowie alle nötigen Räume zugänglich sind. Informieren Sie bei Mehrfamilienhäusern Mieter:innen vorab. Ein Musteraushang steht <a href="https://docs.google.com/document/d/1esdSr-wUVn_aDJq6BXscnrd96vcON5Zf/edit?usp=sharing&ouid=101483070913180848821&rtpof=true&sd=true" target="_blank">hier</a> zum Download bereit. Sprechen Sie zum Ausfüllen des Aushangs das Vorgehen mit dem/der zuständigen Servicetechniker:in ab.`,
          },
          {
            label: "Bearbeitungszeiten",
            value:
              "Die Erstellung der Unterlagen erfolgt innerhalb von zwei bis fünf Werktagen nach dem Vor-Ort-Termin. Die Bearbeitungsdauer ist maßgeblich von der Größe und Komplexität des Objektes abhängig.",
          },
          {
            label: "Zusatzkosten",
            value:
              "Die Rechnungsstellung für die beauftragten Leistungen basiert auf der tatsächlich gemessenen Gesamtfläche (Wohnflächen + Nutzflächen), sowie den vorhandenen Räumen (inkl. Flure, Bäder, WCs, Balkone, Kammern, sonstige Räume, Anbauten, weitere Gebäude, etc.). Fehlerhafte Angaben im Bestellprozess werden im Nachgang korrigiert. Sollten zusätzlich .ifc oder .dwg Dateien benötigt werden, geben Sie dies bitte im Kommentarfeld an. Bitte beachten Sie, dass die Erstellung der Dateien auf Stundenbasis abgerechnet wird (accPos:measurement_additional_charge:€/h).",
          },
          {
            label: "Stornierungsbedingungen",
            value:
              "Eine Stornierung oder Terminänderung ist bis zu 72h vor dem vereinbarten Termin kostenlos möglich. Kurzfristig abgesagte Termine werden mit einer Aufwandspauschale von accPos:measurement_cancellation_charge:€ berechnet. Gleiches gilt, wenn der Zutritt zur Immobilie nicht gewährleistet werden kann.",
          },
          {
            label: "Haftung",
            value: "Für die Richtigkeit der Wohnflächenberechnung haftet der Aufmaßdienstleister.",
          },
          {
            label: "Ausgabe",
            value:
              "Die Wohnflächenberechnung wird in einem PDF Dokument inkl. Stempel (Dekra-Zertifizierung) bereitgestellt. Gegen Aufpreis ist ebenfalls die Lieferung von .ifc oder .dwg Dateien möglich.",
          },
        ],
        disabled: false,
        feedbacks_included: -1,
        name: "Aufmaßerstellung vor Ort", //If you change this, please update src/app/language/language.ts file as well.
        numberOfDrafts: 0,
        packageDescHeading: "Professionelle Vermessung Ihrer Immobilie",
        package_contents: ["Dekra-zertifiziert", "Stichtagsbezogen", "Anerkannt bei Kreditinstituten und vor Gericht"],
        package_images: ["area_calculation/measurements.png"],
        package_icons: ["area_calculation/Aufmasserstellung-vor-Ort.svg"],
        package_id: "area_calculation_measurement_based",
        package_key: CONSTANTS.PACKAGE_KEYS.ON_SITE_AREA_CALC,
        price: "measurement_price",
        EFHprice: "measurement_EFH_price",
        ETWPrice: "measurement_ETW_price",
        basement_price: "measurement_basement_price",
        attic_price: "measurement_attic_price",
        visit_price: "measurement_visit_price",
        per_room_price: "measurement_per_room_price_price",
        by_size_price: "measurement_by_size_price",
        by_floor_price: "measurement_by_floor_price",
        by_residential_units_price: "measurement_by_residential_units_price",
        service_key: OrderTypeEnum.Area_Calculation,
        service_title: "Wohnflächenberechnung",
        title: "Vermessungsservice",
        dateArrangementRequired: true,
        price_pre_text: "ab",
        tooltip: "Aufmaßerstellung vor Ort",
        service_image: "service_images/Wohnflachenberechnung.svg",
      },
    ],
    packageDescription:
      '<p>Wir erstellen <span style="color: #22BCA4"><strong>Wohnflächenberechnungen</strong></span> nach der aktuellen Wohnflächenverordnung. Die Wohnflächenberechnung nach WoFlV ist die gesetzlich anerkannte Grundlage für Mietverträge und mit dem Architektenstempel bildet sie eine wichtige Ergänzung für Ihre Bankunterlagen. Die Flächenberechnung erfolgt auf Basis Ihrer bemaßten Grundrisse oder auf Basis eines Aufmaßes, das durch einen unserer zertifizierten Vermessungstechniker vor Ort durchgeführt wird.</p><p><b>Laden Sie einfach einen bemaßten Grundriss hoch oder vereinbaren Sie einen Termin mit unseren Vermessungstechnikern.</b></p><p><i>Beliebte Produktkombinationen:</i> Grundrisse, Bildbearbeitung, Digital Staging</p>',
    packageLink: "https://www.imogent.de/wohnflaechenberechnung",
  },
];
