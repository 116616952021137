import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { UploadService } from "@app/_services/upload.service";
import { Subscription } from "rxjs";
import { AuthService } from "@app/auth/auth.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { MediaTypeEnum } from "@app/models/media-type.model";
import { getFileExtension, getMediaType } from "@app/util/helper";
import { UserRoleEnum } from "@app/models/user-role-list";
import { NgxSmartModalService } from "ngx-smart-modal";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SubcollectionFileModel } from "@app/models/subcollectionFileModel";
import { NotificationsService } from "@app/_services/notifications.service";
import { environment } from "@environments/environment";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { MediaUrlOriginEnum } from "@app/models/media-url-origin-enum";
import { CONSTANTS } from "@app/util/constants";

@Component({
  selector: "app-imo-attachment-explorer",
  templateUrl: "./imo-attachment-explorer.component.html",
  styleUrls: ["./imo-attachment-explorer.component.css"],
})
export class ImoAttachmentExplorerComponent implements OnInit, OnDestroy {
  private readonly logComponent = "ImoAttachmentExplorerComponent :: ";

  attachments = [];
  $attachments: Subscription;

  @Input() parentType: string;
  @Input("id") parentId: string = "";
  @Input() folderName: string;
  @Input() canDelete: boolean;
  @Input() feedbackMode: boolean;
  @Input() resultRejectionMode: boolean;
  @Input() feedbackStep: number;
  @Input() suborder: SubOrder;
  @Output() onFetch: EventEmitter<any> = new EventEmitter();
  @Output() onFiles: EventEmitter<any> = new EventEmitter();
  @Input() mode: string;
  @Input() feedbackBy: string; // If feedback by Admin: Admin-Comments will be displayed.
  @Input() layoutCols = 1; // Possibility to indicate two columns layouts and change carousel accordingly.
  @Input() filterCriteria?: { [key: string]: any };
  @Input() isLargeImageHidden: boolean;
  // @ViewChild('carouselContainer') carouselContainer;
  showCustomerImages: boolean;
  tilesPerSlide: number = 1;
  indicators: boolean = true;
  showCarousel: boolean = false;
  selectedIndex = 0;
  selectedImg = null;
  carouselMessage = "";
  firstIndex = 1;
  lastIndex = 0;
  feedbackDesired: boolean = false;
  feedbackUnwanted: boolean = false;
  mediaTypeEnum = MediaTypeEnum;
  img_modal: DialogImageFullsize;
  feedbackStatus: any;
  UserRoleEnum = UserRoleEnum;
  displayMode: "list" | "carousel" | "tiles" = "carousel";
  displayedColumns: string[] = ["file_preview", "file_name", "createdOn", "file_type", "file_size", "action"];
  dataSource: MatTableDataSource<
    {
      createdOn: Date;
      download_url: string;
      download_url_xs: string;
      download_url_m: string;
      download_url_xl: string;
      file_size: number;
      file_type: string;
      media_type: MediaTypeEnum;
      id: string;
      shortendFilename: string;
      isImage: boolean;
    }[]
  >;
  @ViewChild(MatSort) sort: MatSort;
  downloadSizes: { label: string; url: string }[] = [];
  private subscriptions: Subscription[] = [];
  protected readonly MediaTypeEnum = MediaTypeEnum;

  constructor(
    private uploadService: UploadService,
    public auth: AuthService,
    public dialog: MatDialog,
    public ngxSmartModalService: NgxSmartModalService,
    private ns: NotificationsService
  ) {}

  ngOnInit() {
    this.showCustomerImages = !this.shouldHideCustomerImages();
    this.subscriptions.push(
      this.uploadService.onFilesUploaded.subscribe((data) => {
        if (
          this.parentType === data.collection &&
          this.parentId === data.document &&
          this.folderName === data.subcollection
        ) {
          this.loadImages(this.filterCriteria);
        }
      })
    );
    this.loadImages(this.filterCriteria);
  }

  /**
   * Determines whether customer images should be hidden.
   *
   * @returns {boolean} True if customer images should be hidden, otherwise false.
   */
  shouldHideCustomerImages() {
    return (
      (this.suborder?.orderType === OrderTypeEnum.Visualisation &&
        this.folderName === MediaUrlOriginEnum.OriginalPhotos) ||
      (this.attachments?.length > CONSTANTS.MAX_FILE_RENDER_THRESHOLD &&
        this.folderName === MediaUrlOriginEnum.OriginalPhotos)
    );
  }

  loadImages(filterCriteria?: { [key: string]: any }) {
    const fc = filterCriteria || this.filterCriteria;
    this.$attachments = this.uploadService
      .getSubcollection(this.parentType, this.parentId, this.folderName)
      .subscribe((files) => {
        files = files.filter((f) => !fc || this.areFilterCriteriaFulfilled(f, fc));
        files.forEach((fl) => {
          fl.isImage = this.isImage(fl.file_name);
          fl.mediaType = getMediaType(fl.file_name, fl.download_url_m); // type of the file: image / video / pdf / audio
          fl.isVideo = this.isVideo(fl.file_name);
          fl.fileType = getFileExtension(fl.file_name).toUpperCase(); // extension in uppercase letters
          fl.shortendFilename = this.shorten(fl.file_name);
        });
        this.attachments = files.sort((a, b) => (a.file_name > b.file_name ? 1 : -1)) || [];
        if (this.shouldHideCustomerImages()) {
          this.showCustomerImages = false;
        }
        const tourFileIndex = this.attachments.findIndex((f) => f.file_name.indexOf(".tour") >= 0);
        if (tourFileIndex >= 0) {
          const tourFile = this.attachments[tourFileIndex];
          this.attachments.splice(tourFileIndex, 1);
          this.attachments.unshift(tourFile);
        }
        this.dataSource = new MatTableDataSource(this.attachments);
        this.onFetch.emit(this.attachments.length);
        this.onFiles.emit(this.attachments);
        this.onImageExpand(0, files[0] || {});
        this.initializeCarousel();
      });
  }

  private areFilterCriteriaFulfilled(f: SubcollectionFileModel, filterCriteria?: { [key: string]: any }) {
    let isFulfilled = true;
    if (filterCriteria) {
      Object.keys(filterCriteria).forEach((key: string) => {
        if (f[key] === undefined || f[key] !== this.filterCriteria[key]) {
          isFulfilled = false;
        }
      });
    }

    return isFulfilled;
  }

  initializeCarousel() {
    const imglength = this.attachments.length;
    const width = window.innerWidth;
    this.tilesPerSlide = Math.min(Math.ceil(2 / this.layoutCols), imglength);
    if (width > 1024) {
      this.tilesPerSlide = Math.min(Math.ceil(8 / this.layoutCols), imglength);
    } else if (width >= 992) {
      this.tilesPerSlide = Math.min(Math.ceil(6 / this.layoutCols), imglength);
    } else if (width >= 768) {
      this.tilesPerSlide = Math.min(Math.ceil(4 / this.layoutCols), imglength);
    }
    if (this.tilesPerSlide === imglength) {
      this.indicators = false;
    }
    this.showCarousel = true;
    this.lastIndex = this.tilesPerSlide;
  }

  deleteAttachment(subColDocId: string, fileName: string, event: Event, mainDownloadUrl: string) {
    event.stopPropagation();
    event.preventDefault();
    const confirmed = confirm(
      "Sind Sie sicher, dass Sie diese Datei löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden!"
    );
    if (confirmed) {
      // decode main download url
      mainDownloadUrl = decodeURIComponent(mainDownloadUrl);
      // overriding fileName with main download url file name because feedbacks filenames get changed
      fileName = mainDownloadUrl.split("/").slice(-1)[0];
      // extract filename without the query string
      fileName = fileName.split("?")[0];
      const path = `${this.parentType}/${this.parentId}/${this.folderName}/${fileName}`;
      this.uploadService
        .deleteAttachment(this.parentType, this.parentId, this.folderName, subColDocId, path)
        .then(() => {
          this.loadImages(this.filterCriteria);
        });
    }
    this.selectedImg = null;
    this.selectedIndex = 0;
  }

  onImageExpand(index, image) {
    this.selectedIndex = index;
    this.selectedImg = image;
    this.selectedImg["isImage"] = this.isImage(image.file_name);
    if (this.selectedImg["isImage"]) {
      this.downloadSizes = [
        { label: "Original", url: image.download_url },
        { label: "Groß", url: image.download_url_xl },
        { label: "Mittel", url: image.download_url_m },
        { label: "Klein", url: image.download_url_xs },
      ];
    }
  }

  onClick(event: Event) {
    if (event.target["className"] && event.target["className"].indexOf("icon-next ") >= 0) {
      if (this.lastIndex < this.attachments.length) {
        this.lastIndex += 1;
        this.firstIndex += 1;
      }
    }
    if (event.target["className"] && event.target["className"].indexOf("icon-prev ") >= 0) {
      if (this.firstIndex > 1) {
        this.lastIndex -= 1;
        this.firstIndex -= 1;
      }
    }
  }

  ngOnDestroy() {
    this.$attachments && this.$attachments.unsubscribe();
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  openDialog(idx: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      attachments: this.attachments,
      index: idx,
      canDelete: this.canDelete,
      suborderId: this.parentId,
      folderName: this.folderName,
    };
    if (this.layoutCols === 2 && this.auth.myUserObservable.role === UserRoleEnum.Administrator) {
      dialogConfig.position = {
        right: "1vw",
      };
    }

    const dialogRef = this.dialog.open(DialogImageFullsize, dialogConfig);
    dialogRef.componentInstance.onDelete.subscribe(() => {
      this.loadImages(this.filterCriteria);
    });

    dialogRef.afterClosed().subscribe(() => {
      dialogRef.componentInstance.onDelete.unsubscribe();
    });
  }

  /**
   * Shortens the given file element string while preserving the file extension.
   *
   * @param {string} flElement - The file element string to be shortened.
   * @return {string} - The shortened file element string.
   */
  private shorten(flElement: string): string {
    const fileEnding = getFileExtension(flElement);
    if (flElement.length >= 13) {
      flElement = flElement.substring(0, 10);
      flElement += "..." + fileEnding;
    }
    return flElement;
  }

  /**
   * Determines if the given file is an image.
   *
   * @param {string} fileName - The name of the file to check.
   * @return {boolean} - True if the file is an image, false otherwise.
   */
  isImage(fileName: string): boolean {
    return getMediaType(fileName) === MediaTypeEnum.image;
  }

  /**
   * Determines if a given file is a PDF.
   *
   * @param {string} fileName - The name of the file to be checked.
   * @returns {boolean} - A boolean value indicating if the file is a PDF (true) or not (false).
   */
  isPDF(fileName: string): boolean {
    return getMediaType(fileName) === MediaTypeEnum.pdf;
  }

  /**
   * Checks whether the given file is a video file.
   *
   * @param {string} fileName - The name of the file to check.
   * @return {boolean} - Returns true if the file is a video file, otherwise false.
   */
  isVideo(fileName: string): boolean {
    return getMediaType(fileName) === MediaTypeEnum.video;
  }

  openDeleteModal() {
    this.ngxSmartModalService.setModalData(
      {
        path: "suborders/" + this.parentId + "/" + this.folderName,
        filterCriteria: this.filterCriteria,
        loadImagesCallback: this.loadImages.bind(this), // callback needed in order to know which instance to load the images, since we can have multiple instances of this component and apparently only one "deleteSubcollectionModal" which is shared by both. Without it, the modal will have a reference only to the latest instance created.
      },
      "deleteSubcollectionModal",
      true
    );
    this.ngxSmartModalService.open("deleteSubcollectionModal");
  }

  deleteSubcollection() {
    this.auth.showLoader.emit(true);
    const modalData = this.ngxSmartModalService.getModal("deleteSubcollectionModal").getData();
    this.ngxSmartModalService.close("deleteSubcollectionModal");
    this.uploadService
      .deleteSubcollection("suborders", this.parentId, this.folderName, true, modalData.filterCriteria)
      .then(() => {
        this.auth.showLoader.emit(false);
        this.ns.showNotification("Dateien erfolgreich gelöscht", "success");
        modalData.loadImagesCallback(modalData.filterCriteria);
      })
      .catch((e) => {
        this.auth.showLoader.emit(false);
        this.ns.showNotification(`Fehler beim Löschen der Dateien: ${e}`, "danger");
      });
  }

  setDisplayMode(mode: "carousel" | "list" | "tiles") {
    this.displayMode = mode;
  }

  sortData(sort: Sort) {
    const data = this.attachments;
    if (!sort.active || sort.direction === "") {
      this.attachments = data;
      return;
    }

    this.attachments = data.sort((a, b) => {
      const isAscending = sort.direction === "asc";
      switch (sort.active) {
        case "file_name":
          return this.compare(a.file_name, b.file_name, isAscending);
        case "file_type":
          return this.compare(a.file_type, b.file_type, isAscending);
        case "file_size":
          return this.compare(a.file_size, b.file_size, isAscending);
      }
    });

    this.dataSource.data = this.attachments;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if ((typeof a === "number" && typeof b === "number") || (!isNaN(Number(a)) && !isNaN(Number(b)))) {
      // If either a and b are Numbers or can be parsed to numbers compare mathematically.
      return (Number(a) - Number(b)) * (isAsc ? 1 : -1);
    } else {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  async toggleAttachmentMark(attachment: SubcollectionFileModel) {
    try {
      await this.uploadService
        .updateSubCollectionDocument(this.parentType, this.parentId, this.folderName, attachment.id, {
          isMarked: !attachment.isMarked,
        })
        .toPromise();
      attachment.isMarked = !attachment.isMarked;
      this.ns.showNotification(
        `Aktion erfolgreich. Nun sind ${this.attachments.filter((a) => a.isMarked).length} Dateien markiert`,
        "success"
      );
    } catch (e) {
      this.ns.showNotification("Status des Anhangs konnte nicht geändert werden. ", "danger");
    }
  }

  downloadAllFiles(e: Event, downloadSize?: any) {
    e.preventDefault();
    let zipFileUrl =
      environment.apiUrl +
      "files/downloadzip?oid=" +
      this.parentId +
      "&uid=" +
      this.suborder.createdBy +
      "&dir=" +
      this.folderName;
    if (downloadSize?.label === "Groß") {
      zipFileUrl =
        environment.apiUrl +
        "files/downloadzip?oid=" +
        this.parentId +
        "&uid=" +
        this.suborder.createdBy +
        "&dir=" +
        this.folderName +
        "&size=xl";
      this.downloadFile(zipFileUrl);
    } else if (downloadSize?.label === "Mittel") {
      zipFileUrl =
        environment.apiUrl +
        "files/downloadzip?oid=" +
        this.parentId +
        "&uid=" +
        this.suborder.createdBy +
        "&dir=" +
        this.folderName +
        "&size=m";
      this.downloadFile(zipFileUrl);
    } else if (downloadSize?.label === "klein") {
      zipFileUrl =
        environment.apiUrl +
        "files/downloadzip?oid=" +
        this.parentId +
        "&uid=" +
        this.suborder.createdBy +
        "&dir=" +
        this.folderName +
        "&size=xs";
      this.downloadFile(zipFileUrl);
    } else {
      this.downloadFile(zipFileUrl);
    }
  }

  downloadFile(url) {
    let temporaryDownloadLink = document.createElement("a");
    temporaryDownloadLink.style.display = "none";
    document.body.appendChild(temporaryDownloadLink);
    temporaryDownloadLink.setAttribute("href", url);
    temporaryDownloadLink.setAttribute("download", url);
    temporaryDownloadLink.click();
    document.body.removeChild(temporaryDownloadLink);
  }
}

@Component({
  selector: "app-dialog-image-fullsize",
  templateUrl: "dialog-image-fullsize.html",
  styleUrls: ["./imo-attachment-explorer.component.css"],
})
export class DialogImageFullsize implements OnInit {
  public filename: string;
  public createdOn: Date;
  public download_url: string;
  public download_url_xs: string;
  public download_url_m: string;
  public download_url_xl: string;
  public delete: boolean;
  public suborderId: string;
  public folderName: string;
  public attachments: Array<object>;
  public idx: number;
  onDelete = new EventEmitter();
  hideDownload = false;
  downloadSizes: { label: string; url: string }[] = [];
  fileType: MediaTypeEnum;
  mediaTypeEnum = MediaTypeEnum;

  // @Input('description') description: string;

  constructor(
    private dialogRef: MatDialogRef<DialogImageFullsize>,
    @Inject(MAT_DIALOG_DATA) data,
    private us: UploadService,
    public auth: AuthService
  ) {
    this.attachments = data.attachments;
    this.idx = data.index;
    this.download_url = data.attachments[data.index].download_url;
    this.download_url_xs = data.attachments[data.index].download_url_xs;
    this.download_url_m = data.attachments[data.index].download_url_m;
    this.download_url_xl = data.attachments[data.index].download_url_xl;
    this.delete = data.canDelete;
    this.suborderId = data.suborderId;
    this.folderName = data.folderName;
    this.filename = data.attachments[data.index].file_name;
    this.createdOn = data.attachments[data.index].createdOn;
    this.hideDownload = data.hideDownload || false;
    this.downloadSizes = [
      { label: "Groß", url: this.download_url_xl },
      { label: "Mittel", url: this.download_url_m },
      { label: "Klein", url: this.download_url_xs },
    ];
    this.fileType = getMediaType(this.filename);
  }

  ngOnInit() {}

  updateImage($event: KeyboardEvent) {
    let updateFile = false;
    if ($event.key === "ArrowLeft" && this.idx >= 1) {
      this.idx -= 1;
      updateFile = true;
    } else if ($event.key === "ArrowRight" && this.idx < this.attachments.length - 1) {
      this.idx += 1;
      updateFile = true;
    }

    if (updateFile) {
      this.download_url = this.attachments[this.idx]["download_url"];
      this.download_url_xs = this.attachments[this.idx]["download_url_xs"];
      this.download_url_m = this.attachments[this.idx]["download_url_m"];
      this.download_url_xl = this.attachments[this.idx]["download_url_xl"];
      this.filename = this.attachments[this.idx]["file_name"];
      this.createdOn = this.attachments[this.idx]["createdOn"];
    }
  }

  async deleteAttachement(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    const confirmed = confirm(
      "Sind Sie sicher, dass Sie diese Datei löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden!"
    );
    if (confirmed) {
      this.auth.showLoader.emit(true);
      const path = `${"suborders"}/${this.suborderId}/${this.folderName}/${this.filename}`;
      await this.us.deleteAttachment(
        "suborders",
        this.suborderId,
        this.folderName,
        this.attachments[this.idx]["id"],
        path
      );
      this.onDelete.emit(true);
      this.auth.showLoader.emit(false);
      this.dialogRef.close();
    } else {
      alert("Löschvorgang abgebrochen!");
    }
  }
}
