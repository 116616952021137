import { Injectable } from "@angular/core";
import { OnOfficeUploadEstateFileRequest } from "@app/interfaces/onOfficeUploadEstateFileRequest.interface";
import { ApisService } from "@app/_services/apis.service";
import { OnOfficeService } from "@app/_services/on-office.service";
import { OnOfficeSignatureResponse } from "@app/interfaces/onOfficeSignatureResponse.interface";
import { OnOfficeUnlockProviderResponse } from "@app/interfaces/onOfficeUnlockProviderResponse.interface";
import { OnOfficeRealEstate } from "@app/interfaces/integration.interface";
import { RealEstate } from "@app/interfaces/real-estate.interface";

@Injectable({
  providedIn: "root",
})
export class OnOfficeApiService {
  constructor(private apis: ApisService, private onOffice: OnOfficeService) {}

  async search(type: string, query: { [key: string]: string | number }) {
    return this.apis.callPost("onoffice/search/" + type, query).toPromise();
  }

  async uploadEstateFile(body: OnOfficeUploadEstateFileRequest) {
    return this.apis.callPost("onoffice/uploadEstateFile/", body).toPromise();
  }

  async calculateSignature(uri: string): Promise<OnOfficeSignatureResponse> {
    return this.onOffice
      .callPost("calculate-signature", {
        url: uri,
      })
      .toPromise() as Promise<OnOfficeSignatureResponse>;
  }

  async unlockProvider(
    apiToken: string,
    parameterCacheId: string,
    apiClaim: string,
    apiUserSecret: string,
    customerName: string,
    customerWebId: string,
    userId: string
  ) {
    return this.onOffice
      .callPost("unlock-marketplace-provider", {
        apiToken: apiToken,
        parameterCacheId: parameterCacheId,
        apiClaim: apiClaim,
        apiUserSecret: apiUserSecret,
        customerName: customerName,
        customerWebId: customerWebId,
        userId: userId,
      })
      .toPromise() as Promise<OnOfficeUnlockProviderResponse>;
  }

  async fetchEstate(onOfficeEstateId: string): Promise<OnOfficeRealEstate> {
    return this.onOffice.callGet("estate/" + onOfficeEstateId).toPromise();
  }

  async fetchAndUpdateEstate(onOfficeEstateId: string, ownerID: string): Promise<RealEstate> {
    return this.onOffice
      .callPost("fetch-and-update-estate/" + onOfficeEstateId, { ownerID: ownerID })
      .toPromise() as Promise<RealEstate>;
  }
}
