import { Injectable } from "@angular/core";
import * as _ from "underscore";
import { LANGUAGE, SUBORDER_DETAILS_MAP } from "@app/language/language";
import { environment } from "@environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { AccountingPositionsLogicService } from "./accounting-positions-logic.service";
import * as accounting from "accounting";
import { CountryCodeList, CountryEnum } from "@app/models/country-code-list";
import { CountryCode } from "@app/interfaces/countryCode.interface";
import { InvoiceRecipient } from "@app/interfaces/company.interface";
import { CONSTANTS } from "@app/util/constants";
import { JllService } from "./jll.service";
import { AuthService } from "@app/auth/auth.service";
import { BehaviorSubject } from "rxjs";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { isClassicFloorplanViaOffice, parseMixedVal } from "@app/util/helper";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { RealEstate } from "@app/interfaces/real-estate.interface";
import { SsoSourcesEnum } from "@app/models/sso-sources.enum";
import { Package } from "@app/models/package";
import { RealestateLogicService } from "@app/_services/realestate-logic.service";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  userId: any;
  languageSelected: string;
  germanCountryCodeDict: { [id: string]: string } = {};
  breadCrumb = new BehaviorSubject<string>("");
  aviableLanguages = [
    {
      name: "language.german",
      tag: "de",
    },
    {
      name: "language.english",
      tag: "en",
    },
  ];
  private componentDataSubject$ = new BehaviorSubject(null as any);
  public componentDataObs = this.componentDataSubject$.asObservable();
  globalQueryParamsSubject = new BehaviorSubject<any>({});
  private queryParams: any = {};
  ssoSource: SsoSourcesEnum;
  lockedRealEstate: Partial<RealEstate>;
  lockedRealEstateDisplayString: string;

  isSidebarDisabled = false;
  isNavbarDisabled = false;
  landingPageTrackingRef: string;
  isWhitelabelVersion = false;

  constructor(
    public translate: TranslateService,
    public apls: AccountingPositionsLogicService,
    private auth: AuthService,
    private jll: JllService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private rls: RealestateLogicService
  ) {
    this.translate.addLangs(["de", "en"]);
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
    translate.use("de"); // Use only German for now
    this.languageSelected = this.translate.currentLang;
    this.initiateGermanCountryCodeDict();
    this.parseQueryStringParams();
    this.isWhitelabelVersion = !CONSTANTS.IMOGENT_DOMAINS.includes(window.location.hostname);

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.globalQueryParamsSubject.next(this.activatedRoute.snapshot.queryParams);
      this.queryParams = this.activatedRoute.snapshot.queryParams;
      if (this.queryParams.objectNumber) {
        this.lockedRealEstate = {
          objectNumber: this.queryParams.objectNumber,
        };
      }
      if (this.queryParams.source) {
        this.ssoSource = this.queryParams.source;
      }
    });
  }

  getQueryParams() {
    return this.queryParams;
  }

  parseQueryStringParams() {
    const urlParams = new URLSearchParams(window.location.search);
    let ds = urlParams.get(CONSTANTS.QUERY_PARAMS.DISABLE_SIDEBAR);
    let dn = urlParams.get(CONSTANTS.QUERY_PARAMS.DISABLE_NAVBAR);

    // extract redirectPath to check if further general applicable parameters are included in the redirect path
    const redirectPath = decodeURIComponent(urlParams.get(CONSTANTS.QUERY_PARAMS.REDIRECT_PATH));
    const redirectPathParts = redirectPath?.split("?");

    // Means we have add parameters to analyze in the redirect Path
    let redirectDS = "";
    let redirectDN = "";

    if (redirectPathParts?.length > 1) {
      // extract url search params
      const redirectPathSearchParams = new URLSearchParams("?" + redirectPathParts[1]);
      redirectDS = redirectPathSearchParams.get(CONSTANTS.QUERY_PARAMS.DISABLE_SIDEBAR);
      redirectDN = redirectPathSearchParams.get(CONSTANTS.QUERY_PARAMS.DISABLE_NAVBAR);
    }

    this.landingPageTrackingRef = urlParams.get(CONSTANTS.QUERY_PARAMS.LANDING_PAGE_TRACKING_REF);
    this.isSidebarDisabled =
      this.isQueryParameterTrue(ds) ||
      this.isQueryParameterTrue(redirectDS) ||
      !!JSON.parse(sessionStorage.getItem("ds"));
    this.isNavbarDisabled =
      this.isQueryParameterTrue(dn) ||
      this.isQueryParameterTrue(redirectDN) ||
      !!JSON.parse(sessionStorage.getItem("dn"));
    sessionStorage.setItem("ds", JSON.stringify(this.isSidebarDisabled));
    sessionStorage.setItem("dn", JSON.stringify(this.isNavbarDisabled));
  }

  isQueryParameterTrue(value: string) {
    return value === "true" || value === "1";
  }

  initiateGermanCountryCodeDict() {
    CountryCodeList.forEach((cc: CountryCode) => {
      this.germanCountryCodeDict[cc.id] = cc.code;
    });
  }

  getCountryCode(country: CountryEnum) {
    return this.germanCountryCodeDict[country];
  }

  changeLanguage(language: string, uid?: string) {
    uid ? (this.userId = uid) : "";
    this.translate.use(language);
    this.languageSelected = this.translate.currentLang;
  }

  /**
   * Returns the label_key translation from LANGUAGE.app_strings constant in language.ts
   */
  translateAppStrings(label_key: string): string {
    if (_.isEmpty(label_key)) {
      return "";
    }
    if (!_.isEmpty(LANGUAGE["app_strings"][label_key])) {
      return LANGUAGE["app_strings"][label_key];
    }
    return label_key; // return the un translated label if translation not found
  }

  getSubOrderKeyValuePairs(subOrderDetails) {
    let details = [];
    const detailsMap = SUBORDER_DETAILS_MAP[subOrderDetails.orderType];
    const isLoggedInJLLCustomer = this.jll.checkJLLCustomer(this.auth.myUserObservable.company?.id);
    const hiddenKeys = [];
    if (
      subOrderDetails.packageNum === CONSTANTS.PACKAGE_KEYS.HD_PHOTOS_BACKBONE ||
      subOrderDetails.packageNum.endsWith("|" + CONSTANTS.PACKAGE_KEYS.HD_PHOTOS_BACKBONE)
    ) {
      hiddenKeys.push("gras");
      hiddenKeys.push("logoImage");
    }
    if (!isLoggedInJLLCustomer) {
      hiddenKeys.push("threeObjectsOption");
    }

    Object.keys(detailsMap)
      .filter((key) => !hiddenKeys.includes(key))
      .forEach((key: any) => {
        let entry = detailsMap[key];
        if (key === "watermarkImage" && subOrderDetails.watermark) {
          details.push({
            label: entry.label,
            value: subOrderDetails[key]?.url,
            type: entry.type,
          });
        } else if (key === "watermarkImage" && !subOrderDetails.watermark) {
          details.push({
            label: entry.label,
            value: subOrderDetails.watermark,
            type: "boolean",
          });
        } else if (key === "logoImage" && subOrderDetails.logo) {
          details.push({
            label: entry.label,
            value: subOrderDetails[key]?.url || "",
            type: entry.type,
          });
        } else if (key === "logoImage" && !subOrderDetails.logo) {
          details.push({
            label: entry.label,
            value: subOrderDetails.logo,
            type: "boolean",
          });
        } else if (!entry.dependentField) {
          details.push({
            label: entry.label,
            value: subOrderDetails[key],
            type: entry.type,
            isComment: entry.isComment || false,
          });
        } else if (
          entry.dependentField &&
          !Array.isArray(entry.dependentField.value) &&
          subOrderDetails[entry.dependentField.field] === entry.dependentField.value
        ) {
          details.push({
            label: entry.label,
            value: subOrderDetails[key],
            type: entry.type,
            isComment: entry.isComment || false,
          });
        } else if (
          entry.dependentField &&
          Array.isArray(entry.dependentField.value) &&
          (entry.dependentField.value.indexOf(subOrderDetails[entry.dependentField.field]) >= 0 ||
            (entry.dependentField.field === "packageNum" &&
              entry.dependentField.value.indexOf(parseMixedVal(subOrderDetails[entry.dependentField.field])) >= 0))
        ) {
          details.push({
            label: entry.label,
            value: subOrderDetails[key],
            type: entry.type,
            isComment: entry.isComment || false,
          });
        }
      });

    this.addFloorplanBasedAreaCalculationFields(subOrderDetails, details);

    this.addDocumentsProcurementDetailsFields(subOrderDetails, details);

    this.addEnergyPassDetailsFields(subOrderDetails, details);
    return details;
  }

  format(input?: number) {
    if (input) {
      return accounting.formatMoney(input, "", 2, ".", ",");
    } else {
      return "0,00";
    }
  }

  unformat(input?: string) {
    if (input) {
      return accounting.unformat(input, ",");
    } else {
      return 0.0;
    }
  }

  getEmailSubOrderKeyValuePairs(subOrderDetails, realEstate: RealEstate) {
    let details = [];
    const isLoggedInJLLCustomer = this.jll.checkJLLCustomer(this.auth.myUserObservable.company?.id);
    const detailsMap = SUBORDER_DETAILS_MAP[subOrderDetails.orderType];
    details.push({
      label: "Objektname",
      value: subOrderDetails.Desc,
    });
    details.push({
      label: "Produkt Referenznummer",
      value: subOrderDetails.id,
    });
    const hiddenKeys = [];
    if (
      subOrderDetails.packageNum === CONSTANTS.PACKAGE_KEYS.HD_PHOTOS_BACKBONE ||
      subOrderDetails.packageNum.endsWith("|" + CONSTANTS.PACKAGE_KEYS.HD_PHOTOS_BACKBONE)
    ) {
      hiddenKeys.push("gras");
      hiddenKeys.push("logoImage");
    }
    if (!isLoggedInJLLCustomer) {
      hiddenKeys.push("threeObjectsOption");
    }

    this.addPhotographyDetailFields(subOrderDetails, details);

    Object.keys(detailsMap)
      .filter((key) => !hiddenKeys.includes(key))
      .forEach((key: any) => {
        let entry = detailsMap[key];
        if (entry.label === "Produkt") {
          return;
        }
        if (key === "logoImage") {
          details.push({
            label: entry.label,
            value: subOrderDetails["logo"] ? "Ja" : "Nein",
          });
        } else if (key === "watermarkImage") {
          details.push({
            label: entry.label,
            value: subOrderDetails["watermark"] ? "Ja" : "Nein",
          });
        } else if (entry.type === "booleanString") {
          details.push({
            label: entry.label,
            value: subOrderDetails[key] ? subOrderDetails[key] : "Nein",
          });
        } else if (entry.type === "list" && subOrderDetails[key]) {
          details.push({
            label: entry.label,
            value: subOrderDetails[key].sort().join("; "),
          });
        } else if (!entry.dependentField) {
          details.push({
            label: entry.label,
            value: entry.type === "string" ? subOrderDetails[key] : subOrderDetails[key] ? "Ja" : "Nein",
          });
        } else if (
          entry.dependentField &&
          !Array.isArray(entry.dependentField.value) &&
          subOrderDetails[entry.dependentField.field] === entry.dependentField.value
        ) {
          details.push({
            label: entry.label,
            value: entry.type === "string" ? subOrderDetails[key] : subOrderDetails[key] ? "Ja" : "Nein",
          });
        } else if (
          entry.dependentField &&
          Array.isArray(entry.dependentField.value) &&
          (entry.dependentField.value.indexOf(subOrderDetails[entry.dependentField.field]) >= 0 ||
            (entry.dependentField.field === "packageNum" &&
              entry.dependentField.value.indexOf(parseMixedVal(subOrderDetails[entry.dependentField.field])) >= 0))
        ) {
          details.push({
            label: entry.label,
            value: entry.type === "string" ? subOrderDetails[key] : subOrderDetails[key] ? "Ja" : "Nein",
          });
        }
      });
    this.addDocumentsProcurementDetailsFields(subOrderDetails, details);

    this.addFloorplanBasedAreaCalculationFields(subOrderDetails, details);

    this.addEnergyPassDetailsFields(subOrderDetails, details);

    if (parseMixedVal(subOrderDetails.packageNum) === CONSTANTS.PACKAGE_KEYS.ENERGY_ASSESSMENT) {
      this.addRealEstateFields(realEstate, details);
    }

    return details;
  }

  private addDocumentsProcurementDetailsFields(subOrderDetails, details) {
    /**
     * Fields used specifically for documents_procurement service type
     */
    if (subOrderDetails.orderType !== "documents_procurement") {
      return;
    }

    const addressDataArray = this.buildDocumentsProcurementAddress(subOrderDetails);

    details.push({
      label: "Adresse",
      value: addressDataArray.join("<br>"),
    });

    this.addLandRegistrySheetList(subOrderDetails, details);
  }

  private addEnergyPassDetailsFields(subOrderDetails, details) {
    /**
     * Fields used specifically for Energy_Pass service type
     */
    if (
      subOrderDetails.orderType !== OrderTypeEnum.Energy_Pass ||
      parseMixedVal(subOrderDetails.packageNum) === CONSTANTS.PACKAGE_KEYS.ENERGY_ASSESSMENT
    ) {
      return;
    }
    details.push({
      label: "Baujahr",
      value: subOrderDetails?.common?.yearOfConstruction,
    });

    if (parseMixedVal(subOrderDetails.packageNum) === CONSTANTS.PACKAGE_KEYS.ENERGYPASS_DEMAND_CERTIFICATE) {
      details.push({
        label: "Gebäudeart",
        value: subOrderDetails?.commonInflated?.buildingType?.label,
      });
    } else {
      details.push({
        label: "Wohneinheiten",
        value: subOrderDetails?.common?.livingQuarters,
      });
      details.push({
        label: "Maßnahmen",
        value: subOrderDetails?.consumptionCertificate?.livingQuarters?.yearOfConstruction === "1" ? true : false,
        type: "boolean",
      });
    }
  }

  private addRealEstateFields(realEstate, details) {
    details.push({
      label: "Adresse",
      value: [realEstate.street + " " + realEstate.streetnumber, realEstate.city, realEstate.postalcode].join("<br>"),
    });
  }

  private buildDocumentsProcurementAddress(subOrderDetails): string[] {
    const addressDataArray: string[] = [];

    addressDataArray.push("Straße: " + subOrderDetails["street"]);
    addressDataArray.push("Nr: " + subOrderDetails["streetNumber"]);
    addressDataArray.push("PLZ: " + subOrderDetails["postalcode"]);
    addressDataArray.push("Ort: " + subOrderDetails["city"]);

    return addressDataArray;
  }

  private addLandRegistrySheetList(subOrderDetails, details) {
    if (subOrderDetails["landRegisterFormList"]) {
      let counter = 0;
      subOrderDetails["landRegisterFormList"].forEach((landRegistrySheet) => {
        const landRegistryDataArray: string[] = [];

        ++counter;

        landRegistryDataArray.push("Grundbuchblatt: " + landRegistrySheet["name"]);
        landRegistryDataArray.push("Germarkung: " + landRegistrySheet["district"]);

        if (landRegistrySheet["hallList"]) {
          landRegistrySheet["hallList"].forEach((hall) => {
            landRegistryDataArray.push("Flur: " + hall["name"]);

            if (hall["pieceList"]) {
              hall["pieceList"].forEach((piece) => {
                landRegistryDataArray.push("Flurstück: " + piece["name"]);
              });
            }
          });
        }

        details.push({
          label: "Objektdaten" + (subOrderDetails["landRegisterFormList"].length > 0 ? " " + counter : ""),
          value: landRegistryDataArray.join("<br>"),
        });
      });
    }
  }

  compareString(object1: string, object2: string) {
    return object1 && object2 && object2 === object1;
  }

  compareInvoiceRecipient(obj1: InvoiceRecipient, obj2: InvoiceRecipient) {
    return obj1 && obj2 && obj1.uid === obj2.uid;
  }

  /**
   * Return an environment configuration
   *
   * @param {string} name
   * @returns
   * @memberof GlobalService
   */
  getEnvironmentConfig(name: string) {
    return environment[name] ? environment[name] : {};
  }

  private addPhotographyDetailFields(subOrderDetails, details: any[]) {
    const isLoggedInJLLCustomer = this.jll.checkJLLCustomer(this.auth.myUserObservable.company?.id);

    if (subOrderDetails.contactPersonName) {
      details.push({
        label: "Kontaktperson",
        value: subOrderDetails.contactPersonName,
      });
    }

    if (subOrderDetails.contactPersonPhone) {
      details.push({
        label: "Telefonnummer 1",
        value: subOrderDetails.contactPersonPhone,
      });
    }

    if (subOrderDetails.contactPersonPhone2) {
      details.push({
        label: "Telefonnummer 2",
        value: subOrderDetails.contactPersonPhone2,
      });
    }
  }

  // Use this function to pass the data from/to any Component.
  passComponentData(data: object) {
    this.componentDataSubject$.next(data);
  }
  private addFloorplanBasedAreaCalculationFields(subOrderDetails: SubOrder, details: any[]) {
    /**
     * Fields used specifically for area calculation based on floorplan package
     */
    if (
      subOrderDetails.orderType !== OrderTypeEnum.Area_Calculation ||
      subOrderDetails.selectedPackage !== "Auf Basis bemaßter Grundrisse"
    ) {
      return;
    }
    const floorsValues = [];
    subOrderDetails.floors.forEach((f, i) => {
      details.push(
        {
          label: `Etage ${i + 1}`,
          value: f.name,
        },
        {
          label: `Anzahl der Räume auf Etage ${i + 1}`,
          value: f.numberOfRooms,
        }
      );
    });
  }

  updateLockedRealEstate(estate: RealEstate) {
    this.lockedRealEstate = estate;
    this.lockedRealEstateDisplayString = "Immobilie " + estate.objectNumber;

    // If we have any more information about the real estate we will show it, separated with a colon.
    if (
      this.lockedRealEstate?.street ||
      this.lockedRealEstate?.city ||
      this.lockedRealEstate?.postalcode ||
      this.lockedRealEstate?.title
    ) {
      this.lockedRealEstateDisplayString += ": ";
    }

    if (estate.street) {
      this.lockedRealEstateDisplayString += estate.street + " ";
      if (estate.streetnumber) {
        this.lockedRealEstateDisplayString += estate.streetnumber;
      }
      if (estate.postalcode || estate.city) {
        this.lockedRealEstateDisplayString += ", ";
      }
    }

    if (estate.postalcode) {
      this.lockedRealEstateDisplayString += estate.postalcode + " ";
    }
    if (estate.city) {
      this.lockedRealEstateDisplayString += estate.city + " ";
    }

    if (estate.title) {
      this.lockedRealEstateDisplayString += "- " + estate.title;
    }
  }

  getFloorplanPackageAccountingPosition(package_key: string, imoPackage: Package, typeIdx: string) {
    if (isClassicFloorplanViaOffice(package_key, this.ssoSource)) {
      return this.apls.getPositionData(imoPackage.package_price_onOffice[typeIdx] as string);
    } else if (imoPackage.service_key === OrderTypeEnum.Floor_Plan) {
      return this.apls.getPositionData(imoPackage.package_price[typeIdx] as string);
    } else {
      return undefined;
    }
  }
}
