<ng-container *ngIf="(auth.showLoader | async) === false">
  <ng-container *ngIf="isFeedbackAvailable">
    <app-feedback-tool
      *ngIf="!isFeedbackFinished"
      class="col-12"
      (onFinish)="onFinish()"
      [mode]="mode"
      [claim]="false"
      [outputFormat]="outputFormat"
      [subjectToCharge]="false"
      [isStandAlone]="true"
      [suborderId]="suborder.id"
      [suborder]="suborder"
    >
    </app-feedback-tool>
  </ng-container>
  <div *ngIf="!isFeedbackAvailable || isFeedbackFinished" class="d-flex align-items-center vh-100">
    <h5 class="text-center w-100">
      <ng-container *ngIf="isFeedbackAvailable && isFeedbackFinished && isSuborderAvailable">
        Vielen Dank für Ihr Feedback! Wir beginnen umgehend mit der Umsetzung Ihrer Änderungsvorschläge und
        benachrichtigen Sie per Mail, sobald die überarbeiteten Ergebnisse vorliegen.
      </ng-container>
      <ng-container *ngIf="!isFeedbackAvailable && isSuborderAvailable">
        Ihre Bestellung befindet sich momentan noch in Bearbeitung. Sobald neue Ergebnisse vorliegen, werden Sie von uns
        per Mail informiert.
        <ng-container *ngIf="latestEstimatedDeliveryDate">
          Das voraussichtliche Lieferdatum ist der {{ latestEstimatedDeliveryDate | date: "dd.MM.yyyy" }}
        </ng-container>
      </ng-container>
      <div class="container mt-4" *ngIf="isSuborderAvailable">
        <div class="row">
          <div class=".col-6 .offset-3">
            <app-suborder-feedback-info></app-suborder-feedback-info>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!isSuborderAvailable"> Auftrag nicht verfügbar! </ng-container>
    </h5>
  </div>
</ng-container>
