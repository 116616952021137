import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";
import { AnalyticsToolExportingDataViewEnum } from "@app/models/analytics-tool-exporting-data-view-list";

@Injectable({
  providedIn: "root",
})
export class AnalytisService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.ANALYTICS;

  constructor(private http: HttpClient) {}

  /**
   * Exports the CSV data.
   */
  exportCSV(
    dataView: AnalyticsToolExportingDataViewEnum,
    month: string,
    customerIds?: string[],
    companyIds?: string[],
    packageKeys?: string[],
    selectedCsvOutputFields?: string[]
  ) {
    const data = {
      dataView: dataView,
      month: month,
      customerIds: customerIds || [],
      companyIds: companyIds || [],
      packageKeys: packageKeys || [],
      csvOutputFields: selectedCsvOutputFields || [],
    };

    return this.http.post(this.endpoint + "/export-csv", data, { responseType: "text" }).toPromise();
  }
}
