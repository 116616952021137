import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";
import { HttpClient } from "@angular/common/http";
import { DocEstateCustomerInteractionsContainer } from "@app/interfaces/docEstate-ci.interface";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DocestateCiService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.DOCESTATE_CI;

  constructor(private http: HttpClient) {}

  getCustomerInteractionsForOrderId(orderId: string): Observable<DocEstateCustomerInteractionsContainer> {
    return this.http.get<DocEstateCustomerInteractionsContainer>(this.endpoint + "/orderid/" + orderId);
  }

  sendCustomerResponse(interactionId: string, formData: FormData): Observable<DocEstateCustomerInteractionsContainer> {
    return this.http.post<DocEstateCustomerInteractionsContainer>(
      this.endpoint + "/response/" + interactionId,
      formData
    );
  }

  getResponseFile(interactionId: string, responseId: string) {
    return this.http.get(this.endpoint + "/response-pdf/" + interactionId + "/" + responseId, {
      responseType: "arraybuffer",
    });
  }
}
