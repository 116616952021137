import { OnOfficeRealEstate } from "@app/interfaces/integration.interface";

export interface RealEstate {
  title: string;
  id?: string;
  objectNumber?: string;
  street?: string;
  streetnumber?: string;
  postalcode?: string;
  city?: string;
  ownerID?: string;
  createdOn?: Date;
  lastChanged?: Date;
  companyId?: string;
  externalInformation?: ExternalRealEstateInformation;
  floors?: RealestateFloors[];
  isActive?: boolean;
  onOffice?: OnOfficeRealEstate;
  isArchived?: boolean;
  brokerName?: string;
  brokerPhoneNumber?: string;
  brokerEmail?: string;
}

export interface ExternalRealEstateInformation {
  type: ExternalRealEstateTypeEnum;
  data: JLLRealEstate;
}

export enum ExternalRealEstateTypeEnum {
  JLL = "JLL",
}
export interface JLLRealEstate {
  buildingUID?: string;
  objectUID?: string;
  businessLine?: string;
  locationBusinessLine?: string;
  responsible?: string;
}

export interface RealestateFloors {
  name: string;
  createdOn: Date;
  index: number;
  isActive?: boolean;
  comment?: string;
  archived?: boolean;
}

export interface RealEstateAddress {
  streetnumber?: string;
  postalcode?: string;
  city?: string;
  street?: string;
  title?: string;
}
