import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { LinkyModule } from 'ngx-linky';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RatingModule } from 'ngx-bootstrap/rating';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    // BrowserAnimationsModule,
    // ReactiveFormsModule,
    // FormsModule,
    RxReactiveFormsModule,
    MatDialogModule,
    MatCardModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
    MatStepperModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatTableModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSortModule,
    MatProgressSpinnerModule,
    CarouselModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    NgbModule,
    PdfViewerModule,
    LinkyModule,
    MatSliderModule,
    MatTabsModule,
    MatListModule,
    DragDropModule,
    ClipboardModule,
    RatingModule.forRoot()
  ],
  exports: [
    MatDialogModule,
    MatCardModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
    MatStepperModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatTableModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSortModule,
    MatProgressSpinnerModule,
    CarouselModule,
    NgxSmartModalModule,
    BsDropdownModule,
    TooltipModule,
    ModalModule,
    CollapseModule,
    NgbModule,
    PdfViewerModule,
    LinkyModule,
    RxReactiveFormsModule,
    MatSliderModule,
    MatTabsModule,
    MatListModule,
    DragDropModule,
    ClipboardModule,
    RatingModule
  ]
})
export class MaterialModule { }

