<ng-container *ngIf="currentSuborder">
  <button
    (click)="sos.extendAutoClose()"
    *ngIf="!autoCloseExcludedPackages?.includes(packageNum)"
    class="btn btn-warning hover-success"
  >
    Datum des Auftragsschlusses um {{ autoCloseDaysIncrease }} Tage verlängern
  </button>
  <div *ngIf="sos.currentSuborderAutoCloseDate">
    Der Auftrag wird automatisch abgeschlossen am: {{ sos.currentSuborderAutoCloseDate | date: "dd.MM.yyyy" }}
  </div>
</ng-container>
