<div class="col-12 row justify-content-center" *ngIf="showFeedbackSection">
  <div class="col-12 mt-3 mb-4 feedback-description" *ngIf="orderDetails.status === orderStatusEnum.Completed">
    Die Resultate werden heruntergeladen. Nehmen Sie sich bitte einen Moment Zeit um Ihre Bestellung zu bewerten, damit wir Ihr Bestellerlebnis verbessern können.
  </div>
  <div class="col-12 mt-3 mb-4 feedback-container">
    <div class="col-12">
      <h4 class="text-center">
        <span *ngIf="orderDetails.status === orderStatusEnum.Completed">Wie zufrieden sind Sie mit den Ergebnissen?</span>
        <span *ngIf="orderDetails.status !== orderStatusEnum.Completed">Wie zufrieden sind Sie mit dem Bestellprozess für dieses Produkt?</span>
      </h4>
    </div>
    <div class="col-12 mt-4">
    <div class="col-12 d-flex">
      <small class="col-6">
        Gar nicht zufrieden
      </small>
      <small class="col-6 d-flex justify-content-end">
        Sehr zufrieden
      </small>
      </div>
      <div class="col-12 text-center">
        <rating [max]="11" [(ngModel)]="orderRating" [customTemplate]="ratingTemplate"
          [titles]="['0','1','2','3','4','5','6','7','8','9','10']"></rating>
        <ng-template #ratingTemplate let-index="index" let-value="value">
          <button class="btn btn-secondary p-3 rate-{{index < value ? index : 'secondary'}}">
            {{index}}
          </button>
        </ng-template>
      </div>
    </div>
    <div class="col-12 text-center mt-5">
      <h5>
        Bitte begründen Sie Ihre Auswahl zur vorherigen Frage.
      </h5>
      <div class="form-group mt-4">
        <textarea class="form-control feedback-comment" [(ngModel)]="feedbackComments"></textarea>
      </div>
    </div>
    <div class="col-12 mt-3 text-center">
      <button type="button" class="btn btn-secondary" (click)="next()">
        <span>Feedback schicken</span>
      </button>
    </div>
  </div>
</div>
