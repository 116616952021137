import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import {
  Router,
  ActivatedRoute,
  RouterEvent,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from "@angular/router";
import { SidebarService } from "@app/sidebar/sidebar.service";
import { LoaderService } from "@app/_services/loader.service";
import { AuthService } from "./auth/auth.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import PerfectScrollbar from "perfect-scrollbar";
import { TranslateService } from "@ngx-translate/core";
import { GlobalService } from "@app/_services/global.service";
import { RealestateLogicService } from "@app/_services/realestate-logic.service";
import { MatDialog } from "@angular/material/dialog";
import { ImoConsentBannerComponent } from "@app/imo-consent-banner/imo-consent-banner.component";
import { CookieService } from "ngx-cookie-service";
import { UsersLogicService } from "@app/_services/users-logic.service";
import { CONSTANTS } from "@app/util/constants";
import { init, routingInstrumentation } from "@sentry/angular";
import { environment } from "@environments/environment";
import { BrowserTracing } from "@sentry/tracing";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "app",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.css", "../styles/table.css"],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  isDownloadZip = false;
  isOnOfficeActivationRoute = false;
  constructor(
    private sidebarservice: SidebarService,
    public loader: LoaderService,
    public auth: AuthService,
    private rels: RealestateLogicService,
    public router: Router,
    public ngxSmartModalService: NgxSmartModalService,
    public activeRoute: ActivatedRoute,
    public translate: TranslateService,
    public gs: GlobalService,
    public dialog: MatDialog,
    private cookieService: CookieService,
    private uls: UsersLogicService
  ) {
    router.events.subscribe((routerEvent: RouterEvent) => {
      this.checkRouterEvent(routerEvent);
    });
  }

  public emailResent = false;
  showLoader: boolean = false;
  orderCreationPath = [
    "/createorder/selectpackage",
    "/createorder/selectservice",
    "/logout",
    "/login",
    "/register",
    "/analytics",
  ]; // The path where the ordercreation forms are displayed so we can use the full vertical space for the order forms
  currentRoutePath = "";

  async ngOnInit() {
    const isLoggedIn = await this.auth.checkAuthState();
    // Cookie needs to expire after 365 days, since we noticed issues on safari and sometimes on Chrome (Windows)
    const cookieExpiryTime = 365 * 24 * 60 * 60 * 1000;
    this.isOnOfficeActivationRoute = window.location.pathname.startsWith("/" + CONSTANTS.ON_OFFICE_ACTIVATION_ROUTE);
    if (this.isOnOfficeActivationRoute) {
      this.initBody();
    } else if (
      isLoggedIn &&
      Object.keys(this.auth.myUserObservable).includes("userConsentForSentry") &&
      !this.cookieService.check(CONSTANTS.USER_CONSENT_COOKIE_NAME)
    ) {
      this.cookieService.set(
        CONSTANTS.USER_CONSENT_COOKIE_NAME,
        this.auth.myUserObservable.userConsentForSentry.toString(),
        cookieExpiryTime
      );
      this.initBody();
    } else if (!this.cookieService.check(CONSTANTS.USER_CONSENT_COOKIE_NAME)) {
      const consentDialog = this.dialog.open(ImoConsentBannerComponent, {
        disableClose: true,
        maxWidth: 550,
        panelClass: "consent-banner",
      });
      consentDialog.afterClosed().subscribe(async (result) => {
        this.cookieService.set(CONSTANTS.USER_CONSENT_COOKIE_NAME, result.toString(), cookieExpiryTime);
        if (isLoggedIn) {
          await this.uls.updateUser(this.auth.myUserObservable.uid, {
            userConsentForSentry: result.toString(),
          });
        }
        this.initBody();
      });
    } else {
      this.initBody();
    }
  }

  initBody() {
    if (!this.isOnOfficeActivationRoute) {
      if (this.cookieService.get(CONSTANTS.USER_CONSENT_COOKIE_NAME) === "false") {
        init({});
      } else if (this.cookieService.get(CONSTANTS.USER_CONSENT_COOKIE_NAME) === "true") {
        init({
          dsn: environment.sentryService.dsn,
          environment: environment.env,
          integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            new BrowserTracing({
              tracingOrigins: ["localhost", environment.apiUrl],
              routingInstrumentation: routingInstrumentation,
            }),
          ],
          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production
          tracesSampleRate: environment.sentryService.tracesSampleRate,
        });
      }
    }
    this.isDownloadZip = false;
    if (document.URL.indexOf("downloadzip") < 0 && document.URL.indexOf("imo360") < 0) {
      // this.loader.getDataAfterAuthenticated();
      this.loader.loaded = true;
    } else {
      this.isDownloadZip = true;
    }
    this.auth.showLoader.subscribe((showLoader) => {
      this.showLoader = showLoader;
    });
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof RouteConfigLoadStart) {
        this.showLoader = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.showLoader = false;
      } else if (event instanceof NavigationEnd) {
        this.currentRoutePath = event.urlAfterRedirects;
      }
    });
  }

  ngAfterViewInit() {
    this.loader.initializeScroller.subscribe(() => {
      setTimeout(() => {
        if (!this.loader.scrollerInitialized) {
          const elemMainPanel = <HTMLElement>document.querySelector(".main-panel");
          const elemSidebar = <HTMLElement>document.querySelector(".sidebar .sidebar-wrapper");
          const html = document.getElementsByTagName("html")[0];
          if (!this.isMac() && window.matchMedia(`(min-width: 960px)`).matches) {
            let ps;
            if (elemMainPanel) {
              ps = new PerfectScrollbar(elemMainPanel);
            }
            if (elemSidebar) {
              ps = new PerfectScrollbar(elemSidebar);
            }
            html.classList.add("perfect-scrollbar-on");
          } else {
            html.classList.add("perfect-scrollbar-off");
          }
          this.loader.scrollerInitialized = true;
        }
      }, 100);
    });
  }

  checkRouterEvent(routerEvent: RouterEvent): void {
    if (routerEvent instanceof NavigationStart) {
      this.showLoader = true;
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.showLoader = false;
    }
  }

  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf("MAC") >= 0 || navigator.platform.toUpperCase().indexOf("IPAD") >= 0) {
      bool = true;
    }
    return bool;
  }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }

  get isLoggedIn(): boolean {
    return this.auth.authorised;
  }

  navigateToRealestates() {
    this.rels.add = true;
    this.router.navigate(["/realestate"]);
    this.ngxSmartModalService.close("firstImmoModal");
  }

  navigateToProfile() {
    this.router.navigate(["/profile"]);
    this.ngxSmartModalService.close("newDienstleisterModal");
  }

  reloadPage() {
    location.reload();
  }

  resendVerificationEmail() {
    try {
      this.auth
        .sendVerificationMail()
        .then((result) => {
          this.emailResent = true;
        })
        .catch((error) => {
          window.alert(error);
          this.emailResent = false;
        });
      setTimeout(() => {
        this.emailResent = false;
      }, 3500);
    } catch (error) {
      window.alert(error);
    }
  }

  skipTutorial() {
    this.ngxSmartModalService.close("firstImmoModal");
    this.auth.tutorialSkipped = true;
  }

  ngOnDestroy() {}
}
