import { Injectable } from "@angular/core";
import { SERVICES } from "@app/models/package";
import { ServicesService } from "@app/_services/services.service";
import { BehaviorSubject } from "rxjs";
import { ProductBundleLogicService } from "@app/_services/product-bundle-logic.service";
import { ProductBundle } from "@app/interfaces/product-bundle.interface";
import { ProductBundlePackage } from "@app/interfaces/product-bundle-package.interface";
import { AuthService } from "@app/auth/auth.service";
import { Package } from "@app/models/package";
import { DiscountsLogicService } from "@app/_services/discounts-logic.service";
import { TileModel } from "@app/shared-module/imo-tile/imo-tile.model";
import { GlobalService } from "@app/_services/global.service";
import { CartItemTypeEnum } from "@app/models/cart-item-type.enum";
import * as _ from "underscore";

@Injectable({
  providedIn: "root",
})
export class ServicesLogicService {
  public imoPackages: any;
  public imoServices: any[];
  public allServices: any[];
  productBundlesList: ProductBundle[] = []; // Bundles available for all companies. Filtered based on company-exclusive packages
  exclusiveBundlesList: ProductBundle[] = []; // Bundles exclusive for the users own company.
  availableServices: TileModel[] = [];
  exclusiveServices: TileModel[] = [];
  tile: TileModel;
  servicesList: any[] = [];
  cartItemTypeEnum = CartItemTypeEnum;
  currentServices = new BehaviorSubject<any[]>([]);
  company: string;

  constructor(
    private ss: ServicesService,
    private pbls: ProductBundleLogicService,
    public dls: DiscountsLogicService,
    public auth: AuthService,
    private gs: GlobalService
  ) {}

  /**
   * Updates and adds new services to firebase based on the SERVICES file.
   */
  updateServices(selectedServices: string[]) {
    this.ss
      .putServices(JSON.parse(JSON.stringify(SERVICES)).filter((s) => selectedServices.includes(s.id)))
      .subscribe((result) => {
        for (const id in result) {
          console.log("updated: " + result[id]["id"]);
        }
      });
  }

  /**
   * Returns all Services in an angular http observable
   */
  getAllServices() {
    return this.ss.getServices();
  }

  /**
   * Returns all packages and stores them into this.imoServices
   */
  getImoServices(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.getAllServices().subscribe((servs: any[]) => {
        this.allServices = servs;
        let services = [];
        this.imoPackages = {};
        servs.forEach((service) => {
          service["packages"].forEach((pack) => (pack["displayPosition"] = service["displayPosition"] || 999));
          services = services.concat(service["packages"]);
          this.imoPackages[service["id"]] = service["packages"];
        });
        this.imoServices = services;
        this.currentServices.next(services);
        resolve(services);
      });
    });
  }

  public fetchAvailableProductBundles() {
    const nonExclusiveProductBundlesList = [];
    this.exclusiveBundlesList = [];
    this.pbls.productBundlesList.forEach((pb: ProductBundle) => {
      let price = 0;
      pb.productsList.forEach((p: ProductBundlePackage) => {
        price += p.productPrice;
      });
      pb.price = price;

      // Exclusive Bundles
      if (pb.isBundleExclusive && this.auth.myUserObservable) {
        if (pb.companyIds && (!pb.externals || !pb.externals.length)) {
          if (this.auth.myUserObservable.company && pb.companyIds.includes(this.auth.myUserObservable.company.id)) {
            this.exclusiveBundlesList.push(pb);
          }
        }
        if ((!pb.companyIds || !pb.companyIds.length) && pb.externals) {
          if (this.auth.myUserObservable.external && pb.externals.includes(this.auth.myUserObservable.external)) {
            this.exclusiveBundlesList.push(pb);
          }
        }
        if (pb.companyIds && pb.externals) {
          if (
            this.auth.myUserObservable.company &&
            pb.companyIds.includes(this.auth.myUserObservable.company.id) &&
            pb.externals.includes(this.auth.myUserObservable.external)
          ) {
            this.exclusiveBundlesList.push(pb);
          }
        }
      }

      if (!pb.isBundleExclusive) {
        nonExclusiveProductBundlesList.push(pb);
      }
    });
    this.productBundlesList = nonExclusiveProductBundlesList.sort((a: ProductBundle, b: ProductBundle) => {
      return a.price - b.price;
    });

    this.exclusiveBundlesList = this.exclusiveBundlesList.sort((a: ProductBundle, b: ProductBundle) => {
      return a.price - b.price;
    });
  }

  isPackageAvailableToMyCompany(pack: Package) {
    if (!pack.companies || pack.companies.length === 0) {
      return true;
    } else {
      return this.isPackageFoundInMyCompany(pack);
    }
  }

  isPackageFoundInMyCompany(pack: Package) {
    this.company = this.auth.myUserObservable ? this.auth.myUserObservable["companyName"] : "";
    const packCompanies = pack.companies || [];
    return packCompanies.map((comp) => RegExp(comp.toLowerCase())).some((rx) => rx.test(this.company.toLowerCase()));
  }

  isPackageFoundInMyCompanyDiscounts(pack: Package) {
    return this.dls.discountsList.filter((discount) => discount.packageId?.includes(pack.package_id)).length > 0;
  }

  isPackAvailable(pack) {
    if (pack.disabled) {
      return false;
    }

    return this.isPackageAvailableToMyCompany(pack);
  }

  isDiscountAvailableForService(service) {
    if (service.packages && service.packages[0] && service.packages[0].service_key) {
      return (
        this.dls.discountsList.filter((discount) => discount.productId === service.packages[0].service_key).length > 0
      );
    } else {
      return false;
    }
  }

  fetchAvailableServices(): any[] {
    let availableServices: TileModel[] = [];
    const serviceList = [];
    this.servicesList.forEach((pack) => {
      if (serviceList.indexOf(pack.service_key) < 0) {
        const indexService = this.allServices.findIndex((item) => item.id === pack.service_key);
        let imageUrl = pack.service_image || pack.package_images[0];
        if (
          this.auth.myUserObservable?.company?.id &&
          pack.package_images_companyId &&
          pack.package_images_companyId[this.auth.myUserObservable.company.id]
        ) {
          imageUrl = pack.package_images_companyId[this.auth.myUserObservable.company.id][0];
        }
        availableServices.push({
          imageUrl: imageUrl,
          title: pack.service_title,
          newProperty: pack.newProperty,
          physicalProduct: pack.physicalProduct,
          id: pack.service_key,
          isDisabled: pack.disabled,
          isAvailableBkbnService: pack.isAvailableBkbnService || false,
          isUpcomingBkbnService: pack.isUpcomingBkbnService || false,
          packages: !pack.disabled && this.isPackAvailable(pack) ? [pack] : [],
          displayPosition: pack.displayPosition,
          packageDescription: this.allServices[indexService].packageDescription,
          packageLink: this.allServices[indexService].packageLink,
          packageButtonText: this.allServices[indexService].packageButtonText,
          priceValue: this.gs.apls.getPositionData(pack.price)?.price || 0,
        });
        serviceList.push(pack.service_key);
      } else {
        const service_index = serviceList.indexOf(pack.service_key);
        availableServices[service_index].newProperty = availableServices[service_index].newProperty && pack.newProperty;
        availableServices[service_index].isDisabled = availableServices[service_index].isDisabled && pack.disabled;

        availableServices[service_index].isAvailableBkbnService =
          availableServices[service_index].isAvailableBkbnService || pack.isAvailableBkbnService;
        availableServices[service_index].isUpcomingBkbnService =
          availableServices[service_index].isUpcomingBkbnService || pack.isUpcomingBkbnService;

        if (this.isPackAvailable(pack)) {
          availableServices[service_index].packages.push(pack);
        }
      }
    });

    //sort availableServices: If they are free for the customer place it on the top, else: 1. displayPosition and 2. disabled
    availableServices = availableServices.sort((a, b) => {
      if (a.priceValue === 0 && b.priceValue !== 0) {
        return -1;
      }
      return a.displayPosition - b.displayPosition;
    });
    // availableServices = _.sortBy(availableServices, "isDisabled");

    return availableServices;
  }
}
