import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AuthService } from "@app/auth/auth.service";
import { Company } from "@app/interfaces/company.interface";
import { MollieMandate } from "@app/interfaces/mollie-mandate.interface";
import { MolliePaymentMethodEnum } from "@app/models/mollie-payment-method.enum";
import { PaymentOptionEnum } from "@app/models/payment-option-list";
import { CompaniesLogicService } from "@app/_services/companies-logic.service";
import { PaymentsLogicService } from "@app/_services/payments-logic.service";
import { CollectiveInvoiceIntervalEnum } from "@app/models/collectiveInvoiceInterval-option-list";
import { Router } from "@angular/router";
import { BroadcasterService } from "@app/_services/broadcaster.service";
import { NotificationsService } from "@app/_services/notifications.service";
import { NotificationMessageTypeEnum } from "@app/models/notification-message-type.enum";

@Component({
  selector: "app-payment-options",
  templateUrl: "./payment-options.component.html",
  styleUrls: ["./payment-options.component.css"],
})
export class PaymentOptionsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private readonly logComponent = "PaymentOptionsComponent :: ";

  @Input() visiblePaymentOptions: PaymentOptionEnum[];
  @Input() disabledPaymentOptions: PaymentOptionEnum[];
  @Input() errorType: PaymentOptionEnum;
  @Input() errorMessage: string;
  @Input() collectiveInvoiceInterval: CollectiveInvoiceIntervalEnum;
  @Input() hasMainFormSubmitted?: boolean;
  @Input() userCompany: Company;
  @ViewChild("cardHolderError") cardHolderErrorElem: ElementRef;
  @ViewChild("cardNumberError") cardNumberErrorElem: ElementRef;
  @ViewChild("expiryDateError") expiryDateErrorElem: ElementRef;
  @ViewChild("verificationCodeError") verificationCodeErrorElem: ElementRef;
  @ViewChild("creditCardFormError") creditCardFormError: ElementRef;

  @Output() paymentOptionChanged = new EventEmitter<PaymentOptionEnum>();
  @Output() mandateIdChanged = new EventEmitter<string>();

  paymentForm: FormGroup;
  isCollectivePaymentOptionExpanded = false;
  selectedPaymentOption: PaymentOptionEnum;
  paymentOptionEnum = PaymentOptionEnum;
  paymentMandates: MollieMandate[] = [];
  directDebitMandates: MollieMandate[] = [];
  creditCardMandates: MollieMandate[] = [];
  paypalMandates: MollieMandate[] = [];
  creditCardComponents = {
    cardHolder: null,
    cardNumber: null,
    expiryDate: null,
    verificationCode: null,
  };
  collectiveInvoiceIntervalEnum = CollectiveInvoiceIntervalEnum;
  handleMessageReceivedEvent = this.onMessageReceive.bind(this);
  sepaTooltipText: string;
  disabledPaymentOptionTooltipText: string;
  canUseSEPA = true;

  constructor(
    public auth: AuthService,
    private fb: FormBuilder,
    public cls: CompaniesLogicService,
    private ps: PaymentsLogicService,
    private router: Router,
    private bs: BroadcasterService,
    private ns: NotificationsService
  ) {
    this.paymentForm = this.fb.group({
      mandateId: [""],
    });
  }

  ngOnInit() {
    this.bs.registerListener(this.handleMessageReceivedEvent);
  }

  private onMessageReceive(mandates: MollieMandate[]) {
    console.log("Message received:", mandates);
    this.directDebitMandates = mandates;

    const foundSelectedMandate = this.directDebitMandates.find((mandate) => mandate.id === this.mandateId.value);
    if (!foundSelectedMandate) {
      if (this.directDebitMandates?.length > 0) {
        this.selectMandate(this.directDebitMandates[0].id);
      } else {
        this.selectMandate("");
      }
    }
  }

  get mandateId() {
    return this.paymentForm.get("mandateId");
  }

  async ngAfterViewInit() {
    this.ps.addMollieRef();
    try {
      this.paymentMandates = await this.ps.getMandates().toPromise();
    } catch (err) {
      console.log(this.logComponent + "No payment mandates found.");
    }
    this.extractMandates();
    if (this.errorType === PaymentOptionEnum.Visa) {
      this.setCreditCardFormError(this.errorMessage);
    }
    this.mountCreditCardDataComponents();

    if (this.auth.myUserObservable.disabledPaymentOptions) {
      this.canUseSEPA = !this.auth.myUserObservable.disabledPaymentOptions.includes(PaymentOptionEnum.Sepa);

      [PaymentOptionEnum.Collective, PaymentOptionEnum.OnAccount, PaymentOptionEnum.Visa].forEach((paymentOption) => {
        if (this.auth.myUserObservable.disabledPaymentOptions.includes(paymentOption)) {
          this.disabledPaymentOptions.push(paymentOption);
        }
      });
    }

    if (this.canUseSEPA && this.disabledPaymentOptions?.includes(PaymentOptionEnum.Sepa)) {
      this.sepaTooltipText =
        "Für Bestellungen mit einem Wert von über 1000€ (brutto) ist diese Option leider nicht verfügbar. Bitte wählen Sie eine andere Zahlungsmethode, um fortzufahren.";
    } else if (!this.canUseSEPA) {
      this.sepaTooltipText = "Diese Zahlungsmethode steht Ihnen derzeit nicht zur Verfügung.";
    }

    this.disabledPaymentOptionTooltipText = "Diese Zahlungsmethode steht Ihnen derzeit nicht zur Verfügung.";

    this.selectDefaultPaymentOption();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.errorType &&
      changes.errorType.currentValue === PaymentOptionEnum.Visa &&
      changes.errorMessage.currentValue !== changes.errorMessage.previousValue
    ) {
      console.log(this.logComponent + "Credit card form error message changed.");
      this.errorMessage = changes.errorMessage.currentValue;
      this.setCreditCardFormError(this.errorMessage);
    }
  }

  selectDefaultPaymentOption() {
    const paymentOptionPriorities = [
      PaymentOptionEnum.Collective, // Highest Priority: Collective
      PaymentOptionEnum.OnAccount, // Prio 2: on account
      PaymentOptionEnum.Visa, // Prio 3: VISA
      PaymentOptionEnum.Sepa, // Prio 4: SEPA
    ];

    for (const paymentOption of paymentOptionPriorities) {
      if (this.checkAndSelectPaymentOption(paymentOption)) {
        return; // Exit if a payment option has been selected
      }
    }
    this.ns.showNotification(
      "Es steht Ihnen keine Zahlungsmethode zur Verfügung. Bitte wenden sie sich an unseren Support für weitere Informationen.",
      NotificationMessageTypeEnum.Danger
    );
  }

  checkAndSelectPaymentOption(paymentOption: PaymentOptionEnum) {
    // payment option must not be disabled but must be visible in order to be selected.
    if (!this.disabledPaymentOptions.includes(paymentOption) && this.visiblePaymentOptions.includes(paymentOption)) {
      this.selectPaymentOption(paymentOption);
      return true;
    } else {
      return false;
    }
  }

  toggleCollectivePaymentOptionExpansion() {
    this.isCollectivePaymentOptionExpanded = !this.isCollectivePaymentOptionExpanded;
  }

  selectPaymentOption(option: PaymentOptionEnum) {
    let mandateId = "";

    if (
      this.disabledPaymentOptions?.includes(option) ||
      this.auth.myUserObservable.disabledPaymentOptions?.includes(option)
    ) {
      console.log(this.logComponent + "Cannot select disabled payment options!");
      return;
    }

    this.selectedPaymentOption = option;

    if (this.selectedPaymentOption === PaymentOptionEnum.Sepa && this.directDebitMandates.length > 0) {
      mandateId = this.directDebitMandates[0].id;
    }
    if (this.selectedPaymentOption === PaymentOptionEnum.Visa && this.creditCardMandates.length > 0) {
      mandateId = this.creditCardMandates[0].id;
    }
    if (this.selectedPaymentOption === PaymentOptionEnum.Paypal && this.paypalMandates.length > 0) {
      mandateId = this.paypalMandates[0].id;
    }

    if (this.selectedPaymentOption !== PaymentOptionEnum.Visa) {
      this.clearCreditCardErrors();
    }
    this.selectMandate(mandateId);
  }

  private clearCreditCardErrors() {
    this.clearCreditCardElemError("card-holder", this.cardHolderErrorElem);
    this.clearCreditCardElemError("card-number", this.cardNumberErrorElem);
    this.clearCreditCardElemError("expiry-date", this.expiryDateErrorElem);
    this.clearCreditCardElemError("verification-code", this.verificationCodeErrorElem);
    this.clearCreditCardElemRefError(this.creditCardFormError);
  }

  private clearCreditCardElemError(elemId: string, elem: ElementRef) {
    const invalidEl = document.querySelector("#" + elemId + " .is-invalid");

    if (invalidEl) {
      invalidEl.classList.remove("is-invalid");
    }

    this.clearCreditCardElemRefError(elem);
  }

  private clearCreditCardElemRefError(elem: ElementRef) {
    elem.nativeElement.classList.remove("alert", "alert-warning");
    elem.nativeElement.textContent = "";
  }

  selectMandate(mandateId: string) {
    this.mandateId.setValue(mandateId);
    this.emitChanges();
  }

  emitChanges() {
    this.paymentOptionChanged.emit(this.selectedPaymentOption);
    this.mandateIdChanged.emit(this.mandateId.value);
  }

  extractMandates() {
    this.directDebitMandates = this.paymentMandates.filter(
      (item: MollieMandate) => item.method === MolliePaymentMethodEnum.directdebit
    );
    this.creditCardMandates = this.paymentMandates.filter(
      (item: MollieMandate) => item.method === MolliePaymentMethodEnum.creditcard
    );
    this.paypalMandates = this.paymentMandates.filter(
      (item: MollieMandate) => item.method === MolliePaymentMethodEnum.paypal
    );
  }

  mountCreditCardDataComponents() {
    if (!this.visiblePaymentOptions.includes(PaymentOptionEnum.Visa)) {
      return;
    }

    const options = {
      styles: {
        base: {
          color: "#616b7a",
          fontWeight: "400",
          fontSize: "14px",
          "::placeholder": {
            color: "#eee",
          },
        },
      },
    };

    this.creditCardComponents.cardHolder = this.ps.mollie.createComponent("cardHolder", options);
    this.creditCardComponents.cardHolder.mount("#card-holder");
    this.creditCardComponents.cardHolder.addEventListener(
      "change",
      this.onMollieComponentChange.bind(this, this.cardHolderErrorElem.nativeElement)
    );

    this.creditCardComponents.cardNumber = this.ps.mollie.createComponent("cardNumber", options);
    this.creditCardComponents.cardNumber.mount("#card-number");
    this.creditCardComponents.cardNumber.addEventListener(
      "change",
      this.onMollieComponentChange.bind(this, this.cardNumberErrorElem.nativeElement)
    );

    this.creditCardComponents.expiryDate = this.ps.mollie.createComponent("expiryDate", options);
    this.creditCardComponents.expiryDate.mount("#expiry-date");
    this.creditCardComponents.expiryDate.addEventListener(
      "change",
      this.onMollieComponentChange.bind(this, this.expiryDateErrorElem.nativeElement)
    );

    this.creditCardComponents.verificationCode = this.ps.mollie.createComponent("verificationCode", options);
    this.creditCardComponents.verificationCode.mount("#verification-code");
    this.creditCardComponents.verificationCode.addEventListener(
      "change",
      this.onMollieComponentChange.bind(this, this.verificationCodeErrorElem.nativeElement)
    );
  }

  private onMollieComponentChange(elem: HTMLElement, event: any) {
    if (event.error && event.touched) {
      elem.classList.add("alert", "alert-warning");
      elem.textContent = event.error;
    } else {
      elem.classList.remove("alert", "alert-warning");
      elem.textContent = "";
    }
  }

  setCreditCardFormError(message: string) {
    if (message) {
      this.creditCardFormError.nativeElement.classList.add("alert", "alert-warning");
      this.creditCardFormError.nativeElement.textContent = message;
    } else {
      this.creditCardFormError.nativeElement.classList.remove("alert", "alert-warning");
      this.creditCardFormError.nativeElement.textContent = "";
    }
  }

  openProfile() {
    const url = window.location.origin + this.router.createUrlTree(["profile", { fragment: "sepa" }]);

    window.open(url, "_blank").focus();
  }

  ngOnDestroy() {
    // Remove event listeners
    if (this.creditCardComponents.cardHolder) {
      this.creditCardComponents.cardHolder.removeEventListener(
        "change",
        this.onMollieComponentChange.bind(this, this.cardHolderErrorElem.nativeElement)
      );
      this.creditCardComponents.cardHolder.unmount();
    }
    if (this.creditCardComponents.cardNumber) {
      this.creditCardComponents.cardNumber.removeEventListener(
        "change",
        this.onMollieComponentChange.bind(this, this.cardNumberErrorElem.nativeElement)
      );
      this.creditCardComponents.cardNumber.unmount();
    }
    if (this.creditCardComponents.expiryDate) {
      this.creditCardComponents.expiryDate.removeEventListener(
        "change",
        this.onMollieComponentChange.bind(this, this.expiryDateErrorElem.nativeElement)
      );
      this.creditCardComponents.expiryDate.unmount();
    }
    if (this.creditCardComponents.verificationCode) {
      this.creditCardComponents.verificationCode.removeEventListener(
        "change",
        this.onMollieComponentChange.bind(this, this.verificationCodeErrorElem.nativeElement)
      );
      this.creditCardComponents.verificationCode.unmount();
    }

    this.ps.removeMollieRef();

    this.bs.unregisterListener(this.handleMessageReceivedEvent);
  }
}
