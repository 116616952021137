import { SubOrderDraftStatus } from "@app/interfaces/suborder-draft-status.interface";

export enum SubOrderDraftStatusEnum {
  Accepted = 'accepted',
  Finally_Accepted = 'finally_accepted',
  Feedback = 'feedback'
}

export const subOrderDraftStatusList: SubOrderDraftStatus[] = [
  {
    id: SubOrderDraftStatusEnum.Accepted,
    name: 'Accepted'
  },
  {
    id: SubOrderDraftStatusEnum.Finally_Accepted,
    name: 'Finally Accepted'
  }
];
