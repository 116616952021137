<form [formGroup]="companySearchForm">
  <mat-dialog-content>
    <div class="row">
      <div class="col-md-2">
        <i class="fas fa-arrow-left pr" (click)="closeCompanySearchModal()"></i>
        <label class="back-text">Züruck</label>
      </div>
    </div>
    <div class="row">
      <div class="col-10">
        <div class="form-group generic-search-input">
          <label>Suche</label>
          <input
            class="form-control"
            type="text"
            [formControl]="companyFilterCtrl"
            placeholder="Suche nach Unternehmen"
          />
          <i class="fas fa-search" [hidden]="companyFilterCtrl.value"></i>
        </div>
      </div>
      <div class="col-2">
        <div class="p-2">
          <button class="btn btn-primary mt-4" (click)="selectAllCompanies()">{{ selectAllText }}</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group generic-search-input">
          <label>Unternehmen auswählen</label>
        </div>
      </div>
    </div>
    <div *ngFor="let company of filteredCompany | async; let i = index; let even = even">
      <div class="row" *ngIf="even">
        <div class="col-md-6">
          <mat-checkbox
            *ngIf="company"
            color="primary"
            [checked]="company.isSelected"
            (change)="toggleCompanyChange($event, company)"
          >
            <span
              class="company-text"
              [ngClass]="{
                'company-bold': company.isCompanyBold
              }"
              >{{ company.name + (company.supplement ? " - " + company.supplement : "") }}</span
            >
          </mat-checkbox>
        </div>
        <div class="col-md-6" *ngIf="(filteredCompany | async).length !== i + 1">
          <mat-checkbox
            color="primary"
            *ngIf="(filteredCompany | async)[i + 1] as nextCompany"
            [checked]="nextCompany.isSelected"
            (change)="toggleCompanyChange($event, nextCompany)"
          >
            <span class="company-text" [ngClass]="{ 'company-bold': nextCompany.isCompanyBold }">
              {{ nextCompany.name + (company.supplement ? " - " + company.supplement : "") }}
            </span>
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Preis</label>
          <input class="form-control price-input" type="number" formControlName="price" [readonly]="priceReadOnly" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 form-group">
        <mat-checkbox formControlName="setAllPrice" (change)="changeCompanyPrice($event)">
          <span class="select-all" for="setPriceAll">Diesen Preis für alle ausgewählten Unternehmen anwenden</span>
        </mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-12 btn-save">
        <button class="btn border btn-cancel" (click)="closeCompanySearchModal()">ZÜRUCK</button>
        <button class="btn btn-primary" (click)="saveCompanySearch()">SPEICHERN</button>
      </div>
    </div>
  </mat-dialog-content>
</form>
