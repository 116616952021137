<!-- Hide page after user pressed register button -->
<ng-container *ngIf="!auth.registering || !adminFlag">
  <div *ngIf="gs.isWhitelabelVersion; else imogent" id="paddingdiv">
    <div class="justify-content-center limitWidth300">
      <!-- Logo -->
      <img
        class="mb-4 object-fit-contain"
        src="assets/images/Logo_png_RGB_dunkelgruen.png"
        height="94px"
        width="300px"
        *ngIf="!showSpkLogo"
      />
      <img class="mb-4 maxH140" src="assets/images/SparkassenImmo_negativ.png" *ngIf="showSpkLogo" />

      <!-- Registration Form -->
      <div class="font-size-xlarge text-center">{{ "register.title" | translate }}</div>
      <h4 class="text-center" *ngIf="currentLandingPageTrackingItem">
        {{ currentLandingPageTrackingItem.welcomeMessage }}
      </h4>
      <form [formGroup]="registerForm" class="mt-4" (ngSubmit)="submitHandler()">
        <!-- Form of address -->
        <div class="form-group text-center">
          <mat-select
            [formControl]="anrede"
            [required]="$any(true)"
            [placeholder]="'register.prefix' | translate"
            class="pl-1 pt-1"
          >
            <mat-option [value]="'Herr'">{{ "register.mr" | translate }}</mat-option>
            <mat-option [value]="'Frau'">{{ "register.mrs" | translate }}</mat-option>
          </mat-select>
          <!-- Form Validation Error -->
          <span *ngIf="anrede.invalid && (submitted || anrede.touched)">
            <div class="alert alert-warning">{{ "register.prefix_invalid" | translate }}</div>
          </span>
        </div>

        <!-- First Name -->
        <div class="form-group">
          <input
            formControlName="firstName"
            class="form-control"
            placeholder="{{ 'register.first_name' | translate }} *"
          />
          <!-- Form Validation Error -->
          <span *ngIf="firstName.invalid && (submitted || firstName.touched)">
            <br />
            <div class="alert alert-warning">{{ "register.first_name_invalid" | translate }}</div>
          </span>
        </div>

        <!-- Last Name -->
        <div class="form-group">
          <input formControlName="lastName" class="form-control" placeholder="{{ 'register.surname' | translate }} *" />
          <!-- Validation Error -->
          <span *ngIf="lastName.invalid && (submitted || lastName.touched)">
            <br />
            <div class="alert alert-warning">{{ "register.surname_invalid" | translate }}</div>
          </span>
        </div>

        <!-- Email -->
        <div class="form-group">
          <input
            type="email"
            autocomplete="username"
            formControlName="email"
            class="form-control"
            placeholder="{{ 'register.email' | translate }} *"
          />
          <!-- Validation Error -->
          <span *ngIf="email.invalid && (submitted || email.touched)">
            <br />
            <div class="alert alert-warning">{{ "register.email_invalid" | translate }}</div>
          </span>
        </div>

        <!-- Password -->
        <div class="form-group">
          <input
            autocomplete="new-password"
            type="password"
            formControlName="password1"
            class="form-control"
            placeholder="{{ 'register.password1' | translate }} *"
            #newPwd
            [ngbPopover]="popContent"
            [popoverTitle]="popTitle"
            [autoClose]="false"
            placement="{{ popoverPlacement }}"
            triggers="focus active"
          />
          <span *ngIf="password1.invalid && (submitted || password1.touched)">
            <br />
            <div class="alert alert-warning">{{ "register.password1_invalid" | translate }}</div>
          </span>
          <ng-template #popTitle
            ><b>{{ "register.password_requirements.requirements" | translate }}</b></ng-template
          >
          <ng-template #popContent>
            <ul>
              <li>
                <i
                  *ngIf="!password1.errors || password1.errors.passwordError.length; else notCorrect"
                  class="fas fa-check-circle text-success"
                ></i>
                {{ "register.password_requirements.number" | translate }}
              </li>
              <li>
                <i
                  *ngIf="!password1.errors || password1.errors.passwordError.lowerCase; else notCorrect"
                  class="fas fa-check-circle text-success"
                ></i>
                {{ "register.password_requirements.lowercase" | translate }}
              </li>
              <li>
                <i
                  *ngIf="!password1.errors || password1.errors.passwordError.upperCase; else notCorrect"
                  class="fas fa-check-circle text-success"
                ></i>
                {{ "register.password_requirements.capital" | translate }}
              </li>
              <li>
                <i
                  *ngIf="!password1.errors || password1.errors.passwordError.digit; else notCorrect"
                  class="fas fa-check-circle text-success"
                ></i>
                {{ "register.password_requirements.number2" | translate }}
              </li>
            </ul>
          </ng-template>
          <ng-template #notCorrect>
            <i class="fas fa-times-circle text-danger"></i>
          </ng-template>
        </div>

        <!-- Repeat Password -->
        <div class="form-group">
          <input
            autocomplete="new-password"
            type="password"
            formControlName="password2"
            class="form-control"
            placeholder="{{ 'register.password2' | translate }} *"
          />
          <!-- Validation Error -->
          <span *ngIf="password2.invalid && (submitted || password2.touched)">
            <br />
            <div *ngIf="password2.errors.required" class="alert alert-warning">
              {{ "register.password2_invalid" | translate }}
            </div>
            <div *ngIf="!password2.errors.required && password2.errors.passwordError" class="alert alert-warning">
              {{ password2.errors.passwordError.value }}
            </div>
            <div
              *ngIf="
                !password2.errors.required && !password2.errors.passwordError && password2.errors.repeatPasswordError
              "
              class="alert alert-warning"
            >
              {{ password2.errors.repeatPasswordError.value }}
            </div>
          </span>
        </div>
        <!-- is private individual customer -->
        <div class="form-group text-left">
          <label>Unternehmen oder Privatperson? *</label>
          <mat-radio-group formControlName="isPrivateIndividual" class="radio-group">
            <mat-radio-button class="radio-button" [value]="false"
              >Ich bin ein Unternehmen (Netto Preise)</mat-radio-button
            >
            <mat-radio-button class="radio-button" [value]="true"
              >Ich bin eine Privatperson (Brutto Preise)</mat-radio-button
            >
          </mat-radio-group>
          <!-- Form Validation Error -->
          <span *ngIf="isPrivateIndividual.invalid && (submitted || isPrivateIndividual.touched)">
            <br />
            <div class="alert alert-warning">{{ "register.isPrivateIndividual_invalid" | translate }}</div>
          </span>
        </div>

        <!-- CR: 29.11.2019 Only show the role field if user is created by Admin -->
        <!-- By default if a user registers to platform at his/her own, its registered as role : "Kunde" -->
        <!-- Choose Role -->
        <div class="form-group" *ngIf="adminFlag">
          <mat-form-field>
            <mat-label>Nutzerrolle</mat-label>
            <mat-select [formControl]="role" [required]="$any(true)">
              <mat-option [value]="'Kunde'">Kunde</mat-option>
              <mat-option [value]="'Dienstleister'">Dienstleister</mat-option>
            </mat-select>
          </mat-form-field>
          <!-- Validation Error -->
          <span *ngIf="role.invalid && (submitted || role.touched)">
            <div class="alert alert-warning">Bitte geben Sie eine Rolle an!</div>
          </span>
        </div>

        <!-- Accept the terms & conditions (AGB) -->
        <div class="form-group justify-content-start text-left">
          <div class="form-check">
            <mat-checkbox [formControl]="conditions"> </mat-checkbox>
            <span class="ml-2">
              {{ "register.agb_prefix" | translate }}
              <a href="https://www.imogent.de/agb" class="my-link" target="_blank">{{ "register.agb" | translate }}</a>
              {{ "register.agb_suffix" | translate }}
            </span>

            <div *ngIf="(submitted || conditions.touched) && !conditions.value">
              <br />
              <div class="alert alert-warning">{{ "register.agb_invalid" | translate }}</div>
            </div>
            <!-- Legal Clause next to the checkbox -->
          </div>
          <div class="form-check">
            <mat-checkbox [formControl]="newsletter"> </mat-checkbox>

            <span class="ml-2" [innerHtml]="'register.newsletter' | translate | safeHtml"></span>
          </div>
        </div>

        <!-- Buttons: Register or cancel -->
        <div class="col-12">
          <a routerLink="/login" *ngIf="!adminFlag" class="btn btn-danger">{{
            "register.button_cancel" | translate
          }}</a>
          <button class="btn btn-danger ml-2" *ngIf="adminFlag" routerLink="/users">Abbrechen</button>
          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{ 'btn-spk': showSpkLogo, 'btn-primary': !showSpkLogo }"
          >
            {{ "register.button_register" | translate }}
          </button>
        </div>
      </form>

      <!-- Footer -->
      <section *ngIf="!adminFlag" class="text-center">
        <p *ngIf="showSpkLogo">
          <i>powered by</i>
        </p>
        <p class="b nomarbot">&copy;{{ year }} IMOGENT GmbH</p>
        <!-- Privacy Policy & Imprint -->
        <a
          href="https://www.imogent.de/datenschutz"
          target="_blank"
          [ngClass]="{ 'my-link': !showSpkLogo, 'grey-link': showSpkLogo }"
          >Datenschutz</a
        ><span> | </span
        ><a [ngClass]="{ 'my-link': !showSpkLogo, 'grey-link': showSpkLogo }" href="https://www.imogent.de/impressum"
          >Impressum</a
        >
      </section>
    </div>
  </div>
  <ng-template #imogent>
    <div class="main">
      <div class="header p-0 pt-3 mb-2">
        <div class="col-md-7 col-sm-12 pt-2 row m-auto pl-4">
          <img
            class="mb-4 object-fit-contain"
            src="assets/images/Logo_png_RGB_dunkelgruen.png"
            height="40px"
            width="150px"
          />
        </div>
        <img class="mb-4 maxH140" src="assets/images/SparkassenImmo_negativ.png" *ngIf="showSpkLogo" />
      </div>
      <div class="content row col-12 justify-content-center p-0 m-auto">
        <div class="col-md-3 col-sm-12">
          <div class="col-12">
            <h3 class="font-weight-800 black-color letter-spacing-1 my-1">
              In ein paar Klicks zur<br />
              Bestellung
            </h3>
            <div class="font-size-normal my-3">
              Um Zugriff auf die IMOGENT-Plattform zu erhalten, können Sie sich einfach kostenlos registrieren. Nach der
              erfolgreichen Anmeldung stehen Ihnen unsere Leistungen zur Buchung zur Verfügung.
              <b>Ohne monatliche Kosten!</b><br /><br />
              Sie wollen sich die Plattform von unserem Team erstmal zeigen lassen? Dann vereinbaren Sie einen
              kostenfreien Beratungstermin!
            </div>
          </div>
          <div class="col-12 p-0">
            <img height="300px" width="300px" src="assets/images/imogent-thomas-oelssner.png" />
          </div>
          <div class="col-12">
            <h4 class="font-weight-800 black-color mb-0">
              Gerne stelle ich Ihnen die Vorteile der <br />Plattform in einer kostenlosen Demo vor!
            </h4>
            <br />
            <div class="text-black text-imo mb-0"><b>Thomas Oelßner</b></div>
            <div class="text-black text-imo mb-0">Sales Manager</div>
            <br />

            <div class="text-imo text-imo mb-0"><b>thomas@imogent.com</b></div>
            <div class="text-imo text-imo mb-0"><b>+49 (0) 1579 250 72 05</b></div>
            <br />
            <div class="text-black mb-0">Montag - Freitag, 9:00 - 17:00</div>
            <br />
            <a class="btn btn-primary font-weight-600 rounded-pill" href="https://www.imogent.de/kontakt/">{{
              "TERMIN SICHERN" | uppercase
            }}</a>
          </div>
        </div>
        <div class="col-md-3 col-sm-12 justify-content-center px-4 ml-4">
          <!-- Registration Form -->
          <h3 class="font-weight-800 black-color letter-spacing-1 my-1">{{ "register.title" | translate }}</h3>
          <h4 class="text-center" *ngIf="currentLandingPageTrackingItem">
            {{ currentLandingPageTrackingItem.welcomeMessage }}
          </h4>
          <form [formGroup]="registerForm" class="mt-5" (ngSubmit)="submitHandler()">
            <!-- Form of prefix -->
            <div class="form-group">
              <mat-select
                [formControl]="anrede"
                [required]="$any(true)"
                [placeholder]="'register.prefix' | translate"
                class="position-static"
              >
                <mat-option [value]="'Herr'">{{ "register.mr" | translate }}</mat-option>
                <mat-option [value]="'Frau'">{{ "register.mrs" | translate }}</mat-option>
              </mat-select>
              <!-- Form Validation Error -->
              <mat-error *ngIf="anrede.invalid && (submitted || anrede.touched)">
                {{ "register.prefix_invalid" | translate }}
              </mat-error>
            </div>

            <!-- First Name -->
            <div class="form-group">
              <input
                formControlName="firstName"
                class="form-control"
                placeholder="{{ 'register.first_name' | translate }} *"
              />
              <!-- Form Validation Error -->
              <mat-error *ngIf="firstName.invalid && (submitted || firstName.touched)">
                {{ "register.first_name_invalid" | translate }}
              </mat-error>
            </div>

            <!-- Last Name -->
            <div class="form-group">
              <input
                formControlName="lastName"
                class="form-control"
                placeholder="{{ 'register.surname' | translate }} *"
              />
              <!-- Validation Error -->
              <mat-error *ngIf="lastName.invalid && (submitted || lastName.touched)">
                {{ "register.surname_invalid" | translate }}
              </mat-error>
            </div>

            <!-- Email -->
            <div class="form-group">
              <input
                type="email"
                autocomplete="username"
                formControlName="email"
                class="form-control"
                placeholder="{{ 'register.email' | translate }} *"
              />
              <!-- Validation Error -->
              <mat-error *ngIf="email.invalid && (submitted || email.touched)">
                {{ "register.email_invalid" | translate }}
              </mat-error>
            </div>

            <!-- Password -->
            <div class="form-group">
              <input
                autocomplete="new-password"
                type="password"
                formControlName="password1"
                class="form-control"
                placeholder="{{ 'register.password1' | translate }} *"
                #newPwd
                [ngbPopover]="popContent"
                [popoverTitle]="popTitle"
                [autoClose]="false"
                placement="{{ popoverPlacement }}"
                triggers="focus active"
              />
              <mat-error *ngIf="password1.invalid && (submitted || password1.touched)">
                {{ "register.password1_invalid" | translate }}
              </mat-error>
              <ng-template #popTitle
                ><b>{{ "register.password_requirements.requirements" | translate }}</b></ng-template
              >
              <ng-template #popContent>
                <ul>
                  <li>
                    <i
                      *ngIf="!password1.errors || password1.errors.passwordError.length; else notCorrect"
                      class="fas fa-check-circle text-success"
                    ></i>
                    {{ "register.password_requirements.number" | translate }}
                  </li>
                  <li>
                    <i
                      *ngIf="!password1.errors || password1.errors.passwordError.lowerCase; else notCorrect"
                      class="fas fa-check-circle text-success"
                    ></i>
                    {{ "register.password_requirements.lowercase" | translate }}
                  </li>
                  <li>
                    <i
                      *ngIf="!password1.errors || password1.errors.passwordError.upperCase; else notCorrect"
                      class="fas fa-check-circle text-success"
                    ></i>
                    {{ "register.password_requirements.capital" | translate }}
                  </li>
                  <li>
                    <i
                      *ngIf="!password1.errors || password1.errors.passwordError.digit; else notCorrect"
                      class="fas fa-check-circle text-success"
                    ></i>
                    {{ "register.password_requirements.number2" | translate }}
                  </li>
                </ul>
              </ng-template>
              <ng-template #notCorrect>
                <i class="fas fa-times-circle text-danger"></i>
              </ng-template>
            </div>

            <!-- Repeat Password -->
            <div class="form-group">
              <input
                autocomplete="new-password"
                type="password"
                formControlName="password2"
                class="form-control"
                placeholder="{{ 'register.password2' | translate }} *"
              />
              <!-- Validation Error -->
              <mat-error *ngIf="password2.invalid && (submitted || password2.touched)">
                <ng-container *ngIf="password2.errors.required">{{
                  "register.password2_invalid" | translate
                }}</ng-container>
                <ng-container *ngIf="!password2.errors.required && password2.errors.passwordError">{{
                  password2.errors.passwordError.value
                }}</ng-container>
                <ng-container
                  *ngIf="
                    !password2.errors.required &&
                    !password2.errors.passwordError &&
                    password2.errors.repeatPasswordError
                  "
                  >{{ password2.errors.repeatPasswordError.value }}</ng-container
                >
              </mat-error>
            </div>

            <!-- is private individual customer -->
            <div class="form-group text-left imo-radio mt-4">
              <span class="font-weight-600 font-size-normal">Unternehmen oder Privatperson? *</span>
              <mat-radio-group formControlName="isPrivateIndividual" class="radio-group m-0">
                <mat-radio-button class="radio-button m-0" [value]="false">Unternehmen (Netto-Preise)</mat-radio-button>
                <mat-radio-button class="radio-button m-0" [value]="true"
                  >Privatperson (Brutto-Preise)</mat-radio-button
                >
              </mat-radio-group>
              <!-- Form Validation Error -->
              <mat-error *ngIf="isPrivateIndividual.invalid && (submitted || isPrivateIndividual.touched)">
                {{ "register.isPrivateIndividual_invalid" | translate }}
              </mat-error>
            </div>

            <!-- CR: 29.11.2019 Only show the role field if user is created by Admin -->
            <!-- By default if a user registers to platform at his/her own, its registered as role : "Kunde" -->
            <div class="form-group" *ngIf="adminFlag">
              <mat-form-field>
                <mat-label>Nutzerrolle</mat-label>
                <mat-select [formControl]="role" [required]="$any(true)">
                  <mat-option [value]="'Kunde'">Kunde</mat-option>
                  <mat-option [value]="'Dienstleister'">Dienstleister</mat-option>
                </mat-select>
              </mat-form-field>
              <!-- Validation Error -->
              <mat-error *ngIf="role.invalid && (submitted || role.touched)">
                Bitte geben Sie eine Rolle an!
              </mat-error>
            </div>

            <!-- Accept the terms & conditions (AGB) -->
            <div class="form-group justify-content-start text-left">
              <div class="form-check">
                <mat-checkbox [formControl]="conditions" class="">
                  <span class="text-black">
                    {{ "register.agb_prefix" | translate }}
                    <a href="https://www.imogent.de/agb" class="my-link" target="_blank">{{
                      "register.agb" | translate
                    }}</a>
                    {{ "register.agb_suffix" | translate }}
                  </span>
                </mat-checkbox>

                <mat-error *ngIf="(submitted || conditions.touched) && !conditions.value">
                  {{ "register.agb_invalid" | translate }}
                </mat-error>
                <!-- Legal Clause next to the checkbox -->
              </div>
              <div class="form-check">
                <mat-checkbox [formControl]="newsletter">
                  <span class="text-black" [innerHtml]="'register.newsletter' | translate | safeHtml"> </span>
                </mat-checkbox>
              </div>
            </div>

            <!-- Buttons: Register or cancel -->
            <div class="col-12 pl-0">
              <a routerLink="/login" *ngIf="!adminFlag" class="btn font-weight-600 rounded-pill cancel">{{
                "register.button_cancel" | translate | uppercase
              }}</a>
              <button class="btn btn-danger ml-2 font-weight-600 rounded-pill" *ngIf="adminFlag" routerLink="/users">
                ABBRECHEN
              </button>
              <button
                type="submit"
                class="btn btn-primary font-weight-600 rounded-pill ml-2"
                [ngClass]="{ 'btn-spk': showSpkLogo, 'btn-primary': !showSpkLogo }"
              >
                {{ "register.button_register" | translate | uppercase }}
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- Footer -->
      <div class="col-12 footer pt-4 font-weight-bold">
        <div class="col-12 pt-4 m-auto">
          <section *ngIf="!adminFlag">
            <p *ngIf="showSpkLogo">
              <i>powered by</i>
            </p>
            <p class="b nomarbot">&copy; {{ year }} IMOGENT GmbH</p>
            <!-- Privacy Policy & Imprint -->
            <a
              href="https://www.imogent.de/datenschutz"
              target="_blank"
              [ngClass]="{ 'my-link': !showSpkLogo, 'grey-link': showSpkLogo }"
              >Datenschutz</a
            ><span> | </span>
            <a href="https://www.imogent.de/agb/" target="_blank">AGB</a>
            <span> | </span>
            <a [ngClass]="{ 'my-link': !showSpkLogo, 'grey-link': showSpkLogo }" href="https://www.imogent.de/impressum"
              >Impressum</a
            >
          </section>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
