<div class="btn-group">
  <a class="btn bg-light" rel="noopener" [href]="selectedImage?.download_url" download target="_blank">
    <i
      class="fa fa-download fa-1x clickable text-primary"
      aria-hidden="true"
      *ngIf="selectedImage.mediaType !== mediaTypeEnum.tour"
    ></i>
    <i
      class="fas fa-external-link-alt clickable text-primary"
      *ngIf="selectedImage.mediaType === mediaTypeEnum.tour"
    ></i>
  </a>
  <button
    type="button"
    class="btn text-primary bg-light dropdown-toggle dropdown-toggle-split px-2"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    *ngIf="downloadSizes?.length && selectedImage.mediaType !== mediaTypeEnum.tour"
  >
    <span class="sr-only">Toggle Dropdown</span>
  </button>
  <div class="dropdown-menu" *ngIf="downloadSizes?.length">
    <a *ngFor="let downloadSize of downloadSizes" class="dropdown-item" [href]="downloadSize.url" download>{{
      downloadSize.label
    }}</a>
  </div>
</div>
<button
  *ngIf="selectedImage.mediaType === mediaTypeEnum.tour"
  class="btn btn-primary ml-2"
  [cdkCopyToClipboard]="selectedImage?.download_url"
>
  Link kopieren
</button>
