<h1 mat-dialog-title class="text-center mt-3">🚨 Wichtiger Hinweis 🚨</h1>
<div mat-dialog-content>
  <div class="my-4">
    <div class="row">
      <div class="col-12 font-size-large line">
        Ab 31. Dezember sind Bestellungen auf IMOGENT nicht mehr möglich. <br />
        Bis dahin: Bestellen Sie hier weiter oder gewöhnen Sie sich schon jetzt an Backbone:
        <a href="https://app.bkbn.com" target="_blank">app.bkbn.com</a>
      </div>
    </div>
    <hr />
    <div class="row mt-4 mb-3">
      <div class="col-12 d-flex justify-content-center">
        <button class="btn btn-bkbn-orange btn-block custom-width" (click)="openBkbnPlatform()">
          Zur Backbone-Plattform
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 d-flex justify-content-center">
        <button class="btn btn-primary btn-block custom-width" (click)="onCloseBanner(false)">
          Auf IMOGENT bestellen
        </button>
      </div>
    </div>

    <div class="row mb-5" *ngIf="showCloseForeverButton">
      <div class="col-12 d-flex justify-content-center">
        <button class="btn btn-back btn-block custom-width mb-3" (click)="onCloseBanner(true)">
          Nicht mehr anzeigen
        </button>
      </div>
    </div>
  </div>
</div>
