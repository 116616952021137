import { Component, OnInit, ViewChild, ElementRef, OnDestroy, IterableDiffers, AfterViewInit } from "@angular/core";
import { ROUTES } from "../../sidebar/sidebar.component";
import { Router, NavigationEnd, RouterEvent, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import { LoaderService } from "@app/_services/loader.service";
import { TranslateService } from "@ngx-translate/core";
import { GlobalService } from "@app/_services/global.service";
import { AuthService } from "@app/auth/auth.service";
import { UserRoleEnum } from "@app/models/user-role-list";
import { NgxSmartModalService } from "ngx-smart-modal";
import { CONSTANTS } from "@app/util/constants";
import { OrdersLogicService } from "@app/_services/orders-logic.service";
import { CartItem } from "@app/interfaces/cart-item.interface";
import { Package } from "@app/models/package";
import { ProductBundle } from "@app/interfaces/product-bundle.interface";
import { CartItemTypeEnum } from "@app/models/cart-item-type.enum";
import { DiscountsLogicService } from "@app/_services/discounts-logic.service";
import { MoneyService } from "@app/_services/money.service";
import { AddVatPipe } from "@app/shared-module/add-vat.pipe";
import { UsersLogicService } from "@app/_services/users-logic.service";
import { GetVatPipe } from "@app/shared-module/get-vat.pipe";
import { NewsSlideDataInterface } from "@app/interfaces/news-slide-data.interface";
import { NewsSlidesLogicService } from "@app/_services/news-slides-logic.service";

const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

declare var $: any;
@Component({
  selector: "app-navbar-cmp",
  templateUrl: "navbar.component.html",
  styleUrls: ["navbar.component.css"],
  providers: [AddVatPipe, GetVatPipe],
})
export class NavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  private listTitles: any[];
  location: Location;
  mobile_menu_visible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;
  public currentRoutePath: string;
  public hiddenNavbarUrlPathList = ["/login", "/logout", "/register", "/forgot"]; // url paths where the navbar shouldnt be showned
  public hiddenTopMenuPathList = [];
  private subscriptions: Subscription[] = [];
  userRoleEnum = UserRoleEnum;
  orderCreationPath = ["/createorder/selectpackage", "/createorder/selectservice"];

  @ViewChild("dotNav") dotNav;

  @ViewChild("app-navbar-cmp") button: any;
  newFeatures: NewsSlideDataInterface[] = [];
  featureIdx = 0;
  lastOpenedNewsModal: string;
  hasUnreadPopupNewsNotification = false;
  unreadNotifications = 0;
  state = false;
  cartItemPackages = [];
  selectedServiceAndPackages = {};
  activatedDiscountCode: string;
  invalidCode: string;
  isMenuOpened: boolean = false;
  public routerId: string = "";
  isValidCode: boolean;
  orderState: string;
  iterableDiffer: any;
  protected readonly CONSTANTS = CONSTANTS;

  constructor(
    location: Location,
    private element: ElementRef,
    public router: Router,
    public loader: LoaderService,
    public translate: TranslateService,
    public gs: GlobalService,
    public auth: AuthService,
    private uls: UsersLogicService,
    private ngxSmartModalService: NgxSmartModalService,
    public ols: OrdersLogicService,
    private dls: DiscountsLogicService,
    public ms: MoneyService,
    private iterableDiffers: IterableDiffers,
    private nsls: NewsSlidesLogicService
  ) {
    this.location = location;
    this.sidebarVisible = false;
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  get isLoggedIn(): boolean {
    return this.auth.authorised;
  }
  hideSidebar() {
    const body = document.getElementsByTagName("body")[0];
    const sidebar = document.getElementsByClassName("sidebar")[0];

    if (misc.hide_sidebar_active === true) {
      setTimeout(function () {
        body.classList.remove("hide-sidebar");
        misc.hide_sidebar_active = false;
      }, 300);
      setTimeout(function () {
        sidebar.classList.remove("animation");
      }, 600);
      sidebar.classList.add("animation");
    } else {
      setTimeout(function () {
        body.classList.add("hide-sidebar");
        misc.hide_sidebar_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  ngOnInit() {
    this.ols.onPackageChange.subscribe((p) => {
      if (p) {
        this.getSelectedPackagesOfServices();
      }
    });
    this.ols.cartItems.forEach((cartItem) => {
      const cartItemPackage = this.getCartItemPackages(cartItem);
      this.cartItemPackages.push(cartItemPackage);
    });

    this.listTitles = ROUTES.filter((listTitle) => listTitle);

    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    if (body.classList.contains("sidebar-mini")) {
      misc.sidebar_mini_active = true;
    }
    if (body.classList.contains("hide-sidebar")) {
      misc.hide_sidebar_active = true;
    }
    this.nsls
      .getNotificationSlides()
      .then(() => {
        this.newFeatures = this.nsls.currentNotifications;
        this.lastOpenedNewsModal = localStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.LAST_OPENED_NEWS_MODAL);

        if (this.lastOpenedNewsModal !== this.nsls.currentNotificationsVersion) {
          ++this.unreadNotifications;
          this.hasUnreadPopupNewsNotification = true;
          this.state = true;
        }
      })
      .catch(() => {
        this.newFeatures = [];
      });

    this.subscriptions.push(
      this.router.events.subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this.currentRoutePath = event.urlAfterRedirects;
          this.sidebarClose();

          const $layer = document.getElementsByClassName("close-layer")[0];
          if ($layer) {
            $layer.remove();
          }
        }
      })
    );

    this.subscriptions.push(
      this.gs.componentDataObs.subscribe((data) => {
        if (data?.removeCartItem) {
          this.selectedServiceAndPackages = [];
        }
        if (data?.hasOwnProperty("orderState")) {
          this.orderState = data?.orderState;
        }
        if (data?.clearDiscount) {
          this.updateDiscount(true);
        }
      })
    );
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.gs.componentDataObs.subscribe((data) => {
        if (data?.hasOwnProperty("source") && data["source"] === "onOffice" && data?.objectNumber) {
          if (!this.auth.myUserObservable?.hideLockedEstateModal) {
            this.openModal("lockedEstateModal");
          }
        }
      })
    );
  }

  // This function is added to detect the changes as we are changing cartItem from too many places and it is kind of hard to capture those changes.
  // Problem: It will be executed on every Angular change detection of the app.
  // TO-DO ::  Need to find a solution and logic to handle it. JIRA task - https://imogent.atlassian.net/browse/IMO-1173
  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.ols.cartItems);
    if (changes) {
      this.getSelectedPackagesOfServices();
    }
  }

  onResize(event) {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }
  sidebarOpen() {
    var $toggle = document.getElementsByClassName("navbar-toggler")[0];
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName("body")[0];
    setTimeout(function () {
      if (toggleButton) {
        toggleButton.classList.add("toggled");
      }
    }, 500);
    body.classList.add("nav-open");
    setTimeout(function () {
      if ($toggle) {
        $toggle.classList.add("toggled");
      }
    }, 430);

    var $layer = document.createElement("div");
    $layer.setAttribute("class", "close-layer");

    if (body.querySelectorAll(".main-panel")) {
      document.getElementsByClassName("main-panel")[0].appendChild($layer);
    } else if (body.classList.contains("off-canvas-sidebar")) {
      document.getElementsByClassName("wrapper-full-page")[0].appendChild($layer);
    }

    setTimeout(function () {
      $layer.classList.add("visible");
    }, 100);

    $layer.onclick = function () {
      //asign a function
      body.classList.remove("nav-open");
      this.mobile_menu_visible = 0;
      this.sidebarVisible = false;

      $layer.classList.remove("visible");
      setTimeout(function () {
        $layer.remove();
        $toggle.classList.remove("toggled");
      }, 400);
    }.bind(this);

    body.classList.add("nav-open");
    this.mobile_menu_visible = 1;
    this.sidebarVisible = true;
  }
  sidebarClose() {
    var $toggle = document.getElementsByClassName("navbar-toggler")[0];
    const body = document.getElementsByTagName("body")[0];
    if (this.toggleButton) {
      this.toggleButton.classList.remove("toggled");
    }
    var $layer = document.createElement("div");
    $layer.setAttribute("class", "close-layer");

    this.sidebarVisible = false;
    body.classList.remove("nav-open");
    // $('html').removeClass('nav-open');
    body.classList.remove("nav-open");
    if ($layer) {
      $layer.remove();
    }

    setTimeout(function () {
      if (typeof $toggle !== "undefined") {
        $toggle.classList.remove("toggled");
      }
    }, 400);

    this.mobile_menu_visible = 0;
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    let titlee: any = this.location.prepareExternalUrl(this.location.path());
    switch (titlee) {
      // All paths that are not displayed in the sidebar go here:
      case "/settings":
        return "Einstellungen";
      case "/profile":
        return "Profil";
      case "/changepassword":
        return "Passwort ändern";
      case "/createorder/selectpackage":
        return this.orderState || "Service buchen";
    }
    // All paths that are displayed in the sidebar are automatically handled here:
    for (let i = 0; i < this.listTitles.length; i++) {
      if (this.listTitles[i].type === "link" && this.listTitles[i].path === titlee) {
        return this.listTitles[i].title;
      } else if (this.listTitles[i].type === "sub") {
        for (let j = 0; j < this.listTitles[i].children.length; j++) {
          let subtitle = this.listTitles[i].path + "/" + this.listTitles[i].children[j].path;
          if (subtitle === titlee) {
            return this.listTitles[i].children[j].title;
          }
        }
      }
    }
    return "Dashboard";
  }
  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }

  ngOnDestroy() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  openModal(modalId: string) {
    this.ngxSmartModalService.getModal(modalId).open();

    // Handle news modal related notification logic
    if (modalId === "newsModal") {
      if (this.hasUnreadPopupNewsNotification) {
        --this.unreadNotifications;
        this.state = false;
      }

      this.hasUnreadPopupNewsNotification = false;
      this.lastOpenedNewsModal = this.nsls.currentNotificationsVersion;
      localStorage.setItem(CONSTANTS.LOCAL_STORAGE_KEYS.LAST_OPENED_NEWS_MODAL, this.lastOpenedNewsModal);
    }
  }

  goNextNews() {
    this.featureIdx += 1;
    this.dotNav.updateSelection(this.featureIdx);
  }

  changeNews(selectedIdx: number) {
    this.featureIdx = selectedIdx;
  }

  closeModal(modalId: string) {
    this.ngxSmartModalService.getModal(modalId).close();
  }

  closeAndHideModal(modalId: string) {
    this.uls.updateUser(this.auth.myUserObservable.uid, { hideLockedEstateModal: true }).then(() => {
      this.auth.myUserObservable.hideLockedEstateModal = true;
    });
    this.ngxSmartModalService.getModal(modalId).close();
  }

  closeAndAutoShowModal(modalId: string) {
    this.uls.updateUser(this.auth.myUserObservable.uid, { hideLockedEstateModal: false }).then(() => {
      this.auth.myUserObservable.hideLockedEstateModal = false;
    });
    this.ngxSmartModalService.getModal(modalId).close();
  }

  closeNewsModal() {
    this.ngxSmartModalService.getModal("newsModal").close();
  }

  getCartItemPackages(cartItem: CartItem) {
    let cartItemPackages: Package[];

    if (cartItem.type === CartItemTypeEnum.Service) {
      // single service
      cartItemPackages = (<any>cartItem.data).packages;
    } else {
      // Else = product-bundle
      cartItemPackages = (<ProductBundle>cartItem.data).productsList.map((item) => item.productData);
    }
    cartItemPackages.forEach((pack) => {
      pack.isDiscountAvailable = this.isDiscountAvailableForPackage(pack);
    });
    return cartItemPackages;
  }

  isDiscountAvailableForPackage(pack: any) {
    if (
      this.dls.discountsList.filter(
        (discount) =>
          discount.productId === pack.service_key &&
          (!discount.packageId || discount.packageId.includes(pack.package_id))
      ).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  getSelectedPackagesOfServices(): any {
    this.ols.cartItems.forEach((s: any) => {
      if (s.type === "product-bundle") {
        this.selectedServiceAndPackages[s.data.id] = s.data.productsList.map((p) => {
          return p.productData;
        });
      } else {
        const selectedPackages = s.data.packages.filter((p) => {
          return this.ols.selectedPackageIds.includes(p.package_key.split("|").pop());
        });
        if (selectedPackages.length) {
          this.selectedServiceAndPackages[s.data.id] = selectedPackages;
        } else {
          delete this.selectedServiceAndPackages[s.data.id];
        }
      }
    });
  }

  onPackageRemove(pack, serviceIndex: number) {
    this.ols.onPackageClick(pack);
    if (!this.selectedServiceAndPackages[pack.service_key]) {
      this.ols.cartItems.splice(serviceIndex, 1);
      this.ols.selectedServices.splice(this.ols.selectedServices.indexOf(pack.service_key), 1);
      this.gs.passComponentData({ emptyServiceDetected: true });
    }
    if (this.ols.cartItems.length === 0) {
      this.clearCartAndGoBack();
      this.toggledCart();
    }
  }

  updateDiscount(remove?: boolean) {
    if (remove) {
      this.activatedDiscountCode = "";
      this.invalidCode = "";
      this.isValidCode = false;
    } else {
      this.dls.getDiscountByCode(this.activatedDiscountCode).subscribe((result) => {
        if (result && result["length"]) {
          this.activatedDiscountCode = result[0].discountCodes[0];
          this.isValidCode = true;
          this.invalidCode = "";
        } else {
          this.invalidCode = this.activatedDiscountCode;
          this.isValidCode = false;
        }
      });
    }
    this.ols.getTotalPrice();
  }

  clearCartAndGoBack(): void {
    this.ols.cartItems = [];
    this.selectedServiceAndPackages = [];
    if (this.ols.editingSavedOrder) {
      this.router.navigate(["orderoverview/savedorders"]);
      return;
    }
    this.router.navigate(["createorder"]);
    this.ols.currentStep.next(1);
  }

  saveForLaterAndGoBack() {
    this.ols.initiateSaveForLater.next();
  }

  public onOutsideClick(event: any): void {
    event?.stopPropagation();
    if (this.isMenuOpened) {
      this.toggledCart();
    }
  }

  toggledCart(event?) {
    event?.stopPropagation();
    this.routerId = this.router.url;
    if (this.routerId.split("/").pop() === "selectservice") {
      this.ols.highlightCart.next();
    } else {
      this.isMenuOpened = !this.isMenuOpened;
    }
  }

  removeAllProducts() {
    this.toggledCart();
    this.router.navigate(["/selectservice"]);
  }

  getServicePrice(tile) {
    let servicePrice;
    tile.data["packages"].forEach((pack) => {
      const packPrice =
        pack.price === undefined
          ? undefined
          : pack.price === "0,00"
          ? 0.0
          : this.gs.apls.getPositionData(pack.price).price;
      if (!servicePrice && servicePrice !== 0) {
        servicePrice = packPrice;
      } else if (packPrice < servicePrice) {
        servicePrice = packPrice;
      }
    });
    return servicePrice;
  }
}
