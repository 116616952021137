import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { User } from "@app/interfaces/user.interface";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CONSTANTS } from "@app/util/constants";
import { UsersLogicService } from "@app/_services/users-logic.service";
import { BillingAddress } from "@app/interfaces/billing-address.interface";
import { NgxSmartModalService } from "ngx-smart-modal";
import { NotificationsService } from "@app/_services/notifications.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { CountryCodeList, CountryEnum } from "@app/models/country-code-list";
import { GlobalService } from "@app/_services/global.service";
import { BillingAddressSelectorModeEnum } from "@app/models/billingAddressSelectorMode.enum";
import { BillingAddressFormStatus } from "@app/interfaces/billing-address-form-status.interface";

@Component({
  selector: "app-imo-billing-address-selector",
  templateUrl: "./imo-billing-address-selector.component.html",
  styleUrls: ["./imo-billing-address-selector.component.css"],
})
export class ImoBillingAddressSelectorComponent implements OnInit {
  @Input("user") user: User;
  @Input("mode") mode: BillingAddressSelectorModeEnum;
  @Output() exit: EventEmitter<boolean> = new EventEmitter();
  @Output() addressSelected: EventEmitter<BillingAddress> = new EventEmitter();

  countryCodeList = CountryCodeList;
  deleteIndex: number;
  editIndex: number;
  isAddressFormDisplayed: boolean;
  isAddressFormValid: boolean;
  selectedIndex: number;
  selectedBillingAddress: BillingAddress;
  updatedBillingAddress: BillingAddress;
  userDetails: User;

  CONSTANTS = CONSTANTS;
  protected _onDestroy = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    public gs: GlobalService,
    private ns: NotificationsService,
    private uls: UsersLogicService,
    private ngxSmartModalService: NgxSmartModalService
  ) {}

  ngOnInit() {
    let firstNameValidators = [];
    let lastNameValidators = [];
    let companyValidators = [];

    if (this.user.isPrivateIndividual) {
      firstNameValidators.push(Validators.required);
      lastNameValidators.push(Validators.required);
    } else {
      companyValidators.push(Validators.required);
    }

    if (this.user.billingAddresses?.length) {
      this.selectedIndex = 0;
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  editInvoiceAddress(index?: number) {
    this.isAddressFormDisplayed = true;

    if (index !== undefined) {
      this.editIndex = index;
      this.selectedBillingAddress = this.user.billingAddresses[index];
    } else {
      this.selectedBillingAddress = undefined;
    }
  }

  onBillingAddressChanged(billingFormStatus?: BillingAddressFormStatus) {
    this.isAddressFormValid = billingFormStatus.isValid;
    this.updatedBillingAddress = billingFormStatus.billingAddress;
  }

  async saveAddress(billingAddress: BillingAddress) {
    if (this.editIndex !== undefined) {
      // Update user.billingAddresses[this.editIndex]
      this.user.billingAddresses[this.editIndex] = billingAddress;
    } else {
      // Push Address to user.billingAddresses
      if (this.user.billingAddresses) {
        this.user.billingAddresses.push(billingAddress);
      } else {
        this.user.billingAddresses = [billingAddress];
      }
    }
    if (!billingAddress.sentInvoiceToEndCustomer) {
      await this.uls.updateUser(this.user.uid, { billingAddresses: this.user.billingAddresses });
    }
  }

  closeInvoiceForm() {
    this.editIndex = undefined;
  }

  save() {
    // Save new address emitted from billing-address-form
    if (this.isAddressFormDisplayed) {
      this.saveAddress(this.updatedBillingAddress).then(() => {
        this.isAddressFormDisplayed = false;
        this.selectedIndex = this.user.billingAddresses.length - 1;
      });
    } else {
      // Save selection only (no new address)
      this.addressSelected.emit(this.user.billingAddresses[this.selectedIndex]);
      this.exit.emit(true);
    }
  }

  close() {
    if (this.isAddressFormDisplayed) {
      this.editIndex = undefined;
      this.isAddressFormDisplayed = false;
    } else {
      this.exit.emit(true);
      // Close modal.
    }
  }

  confirmAddressDeletion(i: number) {
    this.deleteIndex = i;
    this.ngxSmartModalService.open("confirmDelete");
  }

  async deleteInvoiceAddress(i: number) {
    this.user.billingAddresses.splice(i, 1);
    await this.uls.updateUser(this.user.uid, { billingAddresses: this.user.billingAddresses });
    this.ns.showNotification("Rechnungsadresse erfolgreich gelöscht", "success");
    this.deleteIndex = undefined;
    this.selectedIndex = undefined;

    this.ngxSmartModalService.close("confirmDelete");
  }

  abort() {
    if (!this.isAddressFormDisplayed && this.mode !== BillingAddressSelectorModeEnum.MANAGE) {
      this.exit.emit(true);
    } else {
      this.isAddressFormDisplayed = false;
    }
  }

  saveSelection() {
    this.addressSelected.emit(this.user.billingAddresses[this.selectedIndex]);
    this.exit.emit(true);
  }

  protected readonly BillingAddressSelectorModeEnum = BillingAddressSelectorModeEnum;
}
