import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dot-nav',
  templateUrl: './dot-nav.component.html',
  styleUrls: ['./dot-nav.component.css']
})
export class DotNavComponent implements OnInit {

  @Input() numberOfDots: number;
  @Input() selectDot: number;
  @Output() selectionChanged = new EventEmitter<number>();

  selectedDot = 0;
  dots: number[] = [];

  constructor() { }

  ngOnInit() {
    for (let i = 0; i < this.numberOfDots; i++) {
      this.dots.push(i);
    }
  }

  updateSelection(selection: number) {
    this.selectedDot = selection;
    this.selectionChanged.emit(selection);
  }

}
