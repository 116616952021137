<form [formGroup]="parentForm">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <div class="col-12 pl-2 light-blue-bg rounded">
            <mat-checkbox #backgroundMusic color="primary" formControlName="{{ videoEditingOptions[0].formName }}">
              {{ videoEditingOptions[0].label }} &nbsp;
              <i class="fas fa-info-circle text-primary" [tooltip]="videoEditingOptions[0].tooltip"></i>
            </mat-checkbox>
          </div>

          <div class="ml-4" *ngIf="backgroundMusic.checked">
            <app-imo-audio-player (selectedItemEmitter)="onTitleChange($event)" [audioItems]="audioItems">
            </app-imo-audio-player>
          </div>
        </div>

        <div class="col-12 mt-3">
          <div class="col-12 pl-2 light-blue-bg rounded">
            <mat-checkbox #logo color="primary" formControlName="{{ videoEditingOptions[1].formName }}">
              {{ videoEditingOptions[1].label }} &nbsp;
              <i class="fas fa-info-circle text-primary" [tooltip]="videoEditingOptions[1].tooltip"></i>
            </mat-checkbox>
          </div>

          <div class="ml-4" *ngIf="logo.checked">
            <mat-checkbox color="primary" formControlName="logoAtBeginning" [disabled]="!logo.checked">
              <span>Als Intro</span>
            </mat-checkbox>
            <br />
            <mat-checkbox color="primary" formControlName="logoAtEnd" [disabled]="!logo.checked">
              <span>Als Outro</span>
            </mat-checkbox>
            <br />
            <mat-checkbox color="primary" formControlName="logoAllTheTime" [disabled]="!logo.checked">
              <span>Dauerhaft</span>
            </mat-checkbox>
            <br />
          </div>
        </div>

        <div class="col-12 mt-3">
          <div class="col-12 pl-2 light-blue-bg rounded">
            <mat-checkbox
              color="primary"
              formControlName="{{ videoEditingOptions[2].formName }}"
              (change)="changeTitle($event)"
              #hasTitel
            >
              {{ videoEditingOptions[2].label }}
              <dfn class="videoedittooltip xl">
                &nbsp; <i class="fas fa-info-circle text-primary" (mouseover)="playVideo()" (mouseout)="stopVideo()"></i
                ><span role="tooltip"
                  >Beispiel: <br /><video width="400" [autoplay]="isPlayingVideo" controls loop #videoPlayer>
                    <source src="{{ videoEditingOptions[2]?.src }}" type="video/mp4" /></video></span
              ></dfn>
            </mat-checkbox>
          </div>
          <div class="col-12 ml-4" *ngIf="hasTitel.checked">
            <div class="row mt-2">
              <div class="col-4">
                <div>
                  Titel 1 <br />
                  <textarea
                    class="form-control limitTextarea"
                    formControlName="primaryTitle"
                    maxlength="60"
                    placeholder=""
                    [ngClass]="{ 'textbox-disabled': primaryTitle.disabled }"
                  >
                  </textarea>
                </div>
              </div>

              <div class="col-4">
                <div>Titel 2 (wird unter Titel 1 angezeigt)</div>
                <textarea
                  class="form-control limitTextarea mb-4"
                  formControlName="secondaryTitle"
                  maxlength="100"
                  placeholder=""
                  [ngClass]="{ 'textbox-disabled': secondaryTitle.disabled }"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-3">
          <div class="col-12 pl-2 light-blue-bg rounded">
            <mat-checkbox color="primary" formControlName="{{ videoEditingOptions[3].formName }}" #introOutro
              >{{ videoEditingOptions[3].label }} &nbsp;
              <i class="fas fa-info-circle text-primary" [tooltip]="videoEditingOptions[3].tooltip"></i>
            </mat-checkbox>
          </div>
          <div class="col-12">
            <p class="mt-4 ml-4" *ngIf="introOutro.checked">
              Bitte die entsprechenden Dateien eindeutig benannt zur Verfügung stellen und wichtige Informationen über
              das Kommentarfeld im nächsten Schritt mitteilen.
            </p>
          </div>
        </div>

        <div class="col-12 mt-3">
          <div class="col-12 pl-2 light-blue-bg rounded">
            <mat-checkbox color="primary" formControlName="{{ videoEditingOptions[4].formName }}" #blurred
              >{{ videoEditingOptions[4].label }}
              <i class="fas fa-info-circle text-primary font-size-14" [tooltip]="videoEditingOptions[4].tooltip"></i>
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
