<mat-card class="row" *ngIf="auth.myUserObservable.role === UserRoleEnum.Administrator">
  <mat-tab-group class="col-12" dynamicHeight>
    <mat-tab label="Logins">
      <button class="btn btn-primary" (click)="setShowLoginData(true)">Login-Daten abrufen</button>
      <ng-container *ngIf="showLoginData">
        <div class="row col-12 mt-4 mb-4">
          <mat-card class="col-3 ml-2 mr-4 p-3">
            <div class="mat-h2">Unique User (letzte 7 Tage)</div>
            <div class="row">
              <div class="col-3">
                <span class="font-key-fact">{{ uniqueVis["7days"] }}</span>
              </div>
              <div class="col-9">
                Vergleich vorherige 7 Tage:
                <span class="font-key-info"
                  >{{ uniqueVis["7to14days"] }}(<span
                    [ngClass]="{
                      'text-success': uniqueVis['7daysDif'] > 0,
                      'text-danger': uniqueVis['7daysDif'] < 0
                    }"
                    ><ng-container *ngIf="uniqueVis['7daysDif'] > 0">+</ng-container>
                    {{ uniqueVis["7daysDif"] | percent }}</span
                  >)</span
                >
              </div>
            </div>
          </mat-card>
          <mat-card class="col-3 ml-2 mr-4 p-3">
            <div class="col-12 row">
              <div class="mat-h2 col-12">Unique User (laufender Monat)</div>
              <div class="col-5">
                <span class="font-key-fact">{{ uniqueVis["curMonth"] }}</span>
              </div>
              <div class="col-7">
                <span>Vormonatszeitraum: </span
                ><span class="font-key-prediction">{{ uniqueVis["samePeriodLastMonth"] }}</span>
                <span class="font-key-info"
                  >(<span
                    [ngClass]="{
                      'text-success': uniqueVis['curMonthDif'] > 0,
                      'text-danger': uniqueVis['curMonthDif'] < 0
                    }"
                    ><ng-container *ngIf="uniqueVis['curMonthDif'] > 0">+</ng-container>
                    {{ uniqueVis["curMonthDif"] | percent }}</span
                  >)
                </span>
              </div>
            </div>
          </mat-card>
          <mat-card class="col-3 ml-2 mr-4 p-3">
            <div class="mat-h2">Unique User (letzte 30 Tage)</div>
            <div class="row">
              <div class="col-3">
                <span class="font-key-fact">{{ uniqueVis["30days"] }}</span>
              </div>
              <div class="col-9">
                Vergleich vorhergie 30 Tage:
                <span class="font-key-info">
                  {{ uniqueVis["30to60days"] }}(<span
                    [ngClass]="{
                      'text-success': uniqueVis['30daysDif'] > 0,
                      'text-danger': uniqueVis['30daysDif'] < 0
                    }"
                    ><ng-container *ngIf="uniqueVis['30daysDif'] > 0">+</ng-container>
                    {{ uniqueVis["30daysDif"] | percent }}</span
                  >)</span
                >
              </div>
            </div>
          </mat-card>
        </div>
        <form [formGroup]="loginConfigForm" *ngIf="!isLoginDataLoading">
          <div class="row col-12 mt-4">
            <div class="col-12 col-md-6 col-xl-4">
              <mat-form-field appearance="fill">
                <mat-label>Zeitraum wählen</mat-label>
                <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate">
                  <input matStartDate placeholder="Startdatum" formControlName="loginsStartDate" />
                  <input matEndDate placeholder="Enddatum" formControlName="loginsEndDate" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div class="col-11 col-md-5 col-xl-3 d-flex justify-content-center align-items-center">
              <!--            <mat-checkbox color="primary" class="example-margin" formControlName="unique">
                </mat-checkbox>
                Unique users-->
              <mat-form-field>
                <mat-label>Einheit</mat-label>
                <mat-select formControlName="unit">
                  <mat-option *ngFor="let unit of units" [value]="unit.id">
                    {{ unit.title }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
              <mat-form-field>
                <mat-label>Aggregation</mat-label>
                <mat-select formControlName="aggregation">
                  <mat-option *ngFor="let unit of groupingUnits" [value]="unit.id">
                    {{ unit.title }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-1 align-baseline">
              <i class="fa fa-2x fa-sync text-primary clickable" (click)="updateLoginData()"></i>
            </div>
          </div>
        </form>
        <div #containerRefLogin class="col-12">
          <ngx-charts-bar-vertical
            [view]="[containerRefLogin.offsetWidth || 1110, 500]"
            [results]="loginChartData"
            [xAxisLabel]="loginConfigForm.get('aggregation').value"
            [legendTitle]="loginConfigForm.get('aggregation').value"
            [yAxisLabel]="loginConfigForm.get('unit').value"
            [legend]="true"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxis]="true"
            [yAxis]="true"
            [gradient]="true"
            *ngIf="!isLoginDataLoading"
          >
          </ngx-charts-bar-vertical>
          <div *ngIf="isLoginDataLoading">
            Login Daten werden analysiert
            <mat-spinner [diameter]="30" [color]="'primary'"></mat-spinner>
          </div>
        </div>
        <div class="row col-12 mt-4">*Logins von Imogent werden nicht berücksichtigt</div>
      </ng-container>
    </mat-tab>
    <mat-tab label="Auftragseingang">
      <button class="btn btn-primary" (click)="activateAnalyticsTool()" *ngIf="!showAnalyticsTool">
        Analyse starten
      </button>
      <br />
      <ng-container *ngIf="!showAnalyticsTool">
        <b>Achtung:</b> Durch den Abruf der Daten wird eine komplexe und teure Datenbankabfrage und -analyse
        gestartet.<br />
        <b
          >Vor der Nutzung dieses Tools, prüfe bitte, ob die Daten nicht in dem Quick-Check-Tool oder den monatlichen
          Reportings verfügbar sind.</b
        >
      </ng-container>
      <ng-container *ngIf="showAnalyticsTool">
        <mat-card class="col-4 ml-2 mr-4 mt-4 p-3">
          <div class="col-12">
            <div class="row">
              <div class="mat-h2 col-6">Laufender Monat</div>
              <div class="mat-h2 col-6">Hochrechnung</div>
            </div>
            <div class="row">
              <div class="col-6">
                <span class="font-key-fact">{{ revenueValues["currentMonth_formatted"] }} €</span>
              </div>
              <div class="col-6">
                <span class="font-key-fact">{{ revenueValues["currentMonth_prediction_formatted"] }} €</span>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <span>Vergleich Vormonat </span
                ><span class="font-key-info"
                  >(<span
                    [ngClass]="{
                      'text-danger': revenueValues['lastMonthDif'] < 0,
                      'text-success': revenueValues['lastMonthDif'] > 0
                    }"
                    ><ng-container *ngIf="revenueValues['lastMonthDif'] > 0">+</ng-container>
                    {{ revenueValues["lastMonthDif"] | percent }}</span
                  >)</span
                >
              </div>
              <div class="col-6">
                <span>Vergleich Vormonat </span
                ><span class="font-key-info"
                  >(<span
                    [ngClass]="{
                      'text-danger': revenueValues['lastMonthDif'] < 0,
                      'text-success': revenueValues['lastMonthPredictionDif'] > 0
                    }"
                    ><ng-container *ngIf="revenueValues['lastMonthPredictionDif'] > 0">+</ng-container>
                    {{ revenueValues["lastMonthPredictionDif"] | percent }}</span
                  >)</span
                >
              </div>
            </div>
          </div>
        </mat-card>
        <div class="col-12" *ngIf="!isOrderDataLoading">
          <form [formGroup]="revenueConfigForm">
            <div class="row col-12 mt-4">
              <div class="col-12 col-md-6 col-xl-2">
                <mat-form-field appearance="fill">
                  <mat-label>Zeitraum wählen</mat-label>
                  <mat-date-range-input [rangePicker]="pickerRev" [min]="minDateRevenue" [max]="maxDate">
                    <input matStartDate placeholder="Startdatum" formControlName="revenueStartDate" />
                    <input matEndDate placeholder="Enddatum" formControlName="revenueEndDate" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="pickerRev"></mat-datepicker-toggle>
                  <mat-date-range-picker #pickerRev></mat-date-range-picker>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-5 col-xl-2">
                <mat-form-field>
                  <mat-label>Produkt</mat-label>
                  <mat-select formControlName="orderType">
                    <mat-option *ngFor="let type of orderTypes" [value]="type">
                      {{ type }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6 col-xl-2">
                <mat-form-field>
                  <mat-label>Aggregation</mat-label>
                  <mat-select formControlName="aggregation">
                    <mat-option *ngFor="let unit of groupingUnits" [value]="unit.id">
                      {{ unit.title }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6 col-xl-2">
                <mat-form-field>
                  <mat-label>Einheit</mat-label>
                  <mat-select formControlName="unit">
                    <mat-option *ngFor="let unit of revenueUnits" [value]="unit">
                      {{ unit }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-5 col-xl-2">
                <mat-form-field>
                  <mat-label>Unternehmen</mat-label>
                  <mat-select formControlName="companyName" [multiple]="true" panelClass="company-long-panel">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="companyFilterCtrl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option #allSelected [value]="0" (click)="selectAllCompanies()">Alle auswählen </mat-option>
                    <mat-option
                      (click)="selectSingleCompany()"
                      *ngFor="let companyName of filteredCompany | async"
                      [value]="companyName"
                    >
                      {{ companyName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6 col-xl-2">
                <mat-checkbox class="example-margin" formControlName="onlyImmoviewer" color="primary"
                  >Nur Immoviewer
                </mat-checkbox>
              </div>
              <div class="col-1 align-baseline">
                <i class="fa fa-2x fa-sync text-primary clickable" (click)="updateOrderData()"></i>
              </div>
            </div>
          </form>
        </div>
        <div #containerRef class="col-12">
          <ngx-charts-bar-vertical
            [view]="[containerRef.offsetWidth, 500]"
            [results]="revenueChartData"
            [xAxisLabel]="revenueConfigForm.get('aggregation').value"
            [legendTitle]="revenueConfigForm.get('aggregation').value"
            [yAxisLabel]="revenueConfigForm.get('unit').value"
            [legend]="true"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxis]="true"
            [yAxis]="true"
            [gradient]="true"
            *ngIf="!isOrderDataLoading"
          >
          </ngx-charts-bar-vertical>
          <div class="mt-5 mb-5" *ngIf="isOrderDataLoading">
            <span>Bestelldaten werden abgerufen und analysiert</span>
            <mat-spinner [diameter]="30" [color]="'primary'"></mat-spinner>
          </div>
        </div>
        <div col-12>
          <table
            mat-table
            [dataSource]="dataSource"
            class="mat-elevation-z8"
            matSort
            matSortActive="time"
            matSortDirection="asc"
          >
            <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

            <!-- time Column -->
            <ng-container matColumnDef="time">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
              <td mat-cell *matCellDef="let element">{{ element.time | date : "dd.MM.yyyy HH:mm" }}</td>
            </ng-container>

            <!-- suborderId Column -->
            <ng-container matColumnDef="suborderId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>suborderId</th>
              <td mat-cell *matCellDef="let element">{{ element.id }}</td>
            </ng-container>

            <!-- orderType Column -->
            <ng-container matColumnDef="orderType">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>orderType</th>
              <td mat-cell *matCellDef="let element">{{ element.orderType }}</td>
            </ng-container>

            <!-- value Column -->
            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Betrag</th>
              <td mat-cell *matCellDef="let element">{{ element.value | currency : "EUR" : "symbol" }}</td>
            </ng-container>

            <!-- units Column -->
            <ng-container matColumnDef="units">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Anzahl</th>
              <td mat-cell *matCellDef="let element">{{ element.units }}</td>
            </ng-container>

            <!-- email Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>email</th>
              <td mat-cell *matCellDef="let element">{{ element.email }}</td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="external">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>External</th>
              <td mat-cell *matCellDef="let element">{{ element.external }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </ng-container>
    </mat-tab>
    <mat-tab label="Auftragseingang (Quick Check)" (click)="updateRevenueData()">
      <ng-container *ngTemplateOutlet="liveTestingAlertBlock"></ng-container>
      <mat-card class="col-4 ml-2 mr-4 mt-4 p-3">
        <div class="col-12">
          <div class="row">
            <div class="mat-h2 col-6">Laufender Monat</div>
            <div class="mat-h2 col-6">Hochrechnung</div>
          </div>
          <div class="row">
            <div class="col-6">
              <span
                class="font-key-fact"
                >{{ monthlyRevenue?.[currentMonthYearString]?.total | currency: "EUR":"symbol" }}</span
              >
            </div>
            <div class="col-6">
              <span
                class="font-key-fact"
                >{{ monthlyRevenue?.[currentMonthYearString]?.total * linearInterpolationFactor | currency: "EUR":"symbol" }}</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6">
              <span>Vergleich Vormonat </span
              ><span class="font-key-info"
                >(<span
                  [ngClass]="{
                    'text-danger': monthByMonthDiffPct < 0,
                    'text-success': monthByMonthDiffPct > 0
                  }"
                  ><ng-container *ngIf="monthByMonthDiffPct > 0">+</ng-container>
                  {{ monthByMonthDiffPct | percent }}</span
                >)</span
              >
            </div>
          </div>
        </div>
      </mat-card>
      <div class="row mt-4 ml-2">
        <div class="col-4">
          <mat-form-field>
            <mat-label>Monat</mat-label>
            <mat-select [(ngModel)]="selectedAnalyticsMonth" (selectionChange)="updateRevenueData()">
              <mat-option *ngFor="let m of analyticsMonthOptions" [value]="m">
                {{ m }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-2">
          <mat-form-field>
            <mat-label>Limit</mat-label>
            <mat-select [(ngModel)]="limitAnalyticsValues" (selectionChange)="updateRevenueData()">
              <mat-option *ngFor="let o of limitAnalyticsOptions" [value]="o">
                {{ o }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row shadow">
        <div #containerRefQuickCheck class="col-11" style="max-width: 70vw">
          <ngx-charts-bar-horizontal
            [view]="[containerRefQuickCheck.offsetWidth || 1110, 500]"
            [results]="monthlyRevenueChartData"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="false"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxisLabel]="'Umsatz'"
            [yAxisLabel]="'Unternehmen'"
            [trimYAxisTicks]="false"
          >
          </ngx-charts-bar-horizontal>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Billing Analytics">
      <div class="col-12">
        <ng-container *ngTemplateOutlet="liveTestingAlertBlock"></ng-container>
        <mat-card class="col-9 ml-2 mr-4 mt-4 p-3">
          <div class="col-12">
            <div class="row">
              <div class="mat-h2 col-12">Rechnungsstellung</div>
            </div>
            <div class="row">
              <div class="col-3 font-key-info">Datum (bis)</div>
              <div class="col-3" *ngFor="let key of invoicePreviewKeys">
                <span class="font-key-info"> {{ key }} </span>
              </div>
              <div class="col-3 font-key-info">Summe (gesamt)</div>
              <div class="col-3" *ngFor="let key of invoicePreviewKeys">
                <span>
                  {{ invoicePreviewData?.[key]?.openAmountSum?.value | currency: "EUR":"symbol" }}
                </span>
                <br />
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <div #containerRefBilling class="col-12 mb-4">
        <h3>Status der Rechnungsstellung aggregiert pro Monat</h3>
        <ngx-charts-bar-vertical-stacked
          [view]="[containerRefBilling.offsetWidth || 1110, 500]"
          [customColors]="billingAnalyticsColorScheme"
          [results]="billingAnalyticsChartData"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="false"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          [xAxisLabel]="'Monat'"
          [yAxisLabel]="'Betrag'"
          [trimYAxisTicks]="false"
          (select)="onBillingChartSelect($event)"
        >
        </ngx-charts-bar-vertical-stacked>
      </div>
      <div class="mt-3">
        <span *ngIf="billingAnalyticsFilterProperty">
          <h3>Bestellungen mit {{ billingAnalyticsFilterProperty }} für den Monat {{ billingAnalyticsMonth }}</h3>
        </span>
        <span *ngIf="!billingAnalyticsFilterProperty">
          <h3>Bitte im Diagram Monat und Zahlstatus wählen, um die Liste der zugehörigen Bestellungen abzurufen.</h3>
        </span>
        <table
          mat-table
          [dataSource]="dataSourceBillingAnalytics"
          class="mat-elevation-z8"
          matSort
          matSortActive="createdOn"
          matSortDirection="desc"
          (matSortChange)="loadBillingAnalyticsOrders()"
        >
          <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

          <!-- createdOn Column -->
          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
            <td mat-cell *matCellDef="let element">{{ element.createdOn | date : "dd.MM.yyyy HH:mm" }}</td>
          </ng-container>

          <!-- orderId Column -->
          <ng-container matColumnDef="orderId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>OrderId</th>
            <td mat-cell *matCellDef="let element">
              {{ element.orderId }}
              <i
                class="fas fa-copy text-primary clickable ml-2"
                [tooltip]="'In Zwischenablage kopieren'"
                [adaptivePosition]="false"
                [cdkCopyToClipboard]="element.orderId"
              ></i>
            </td>
          </ng-container>

          <!-- email Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>E-Mail</th>
            <td mat-cell *matCellDef="let element">{{ element.email }}</td>
          </ng-container>

          <!-- nextInvoiceScheduledOn Column -->
          <ng-container matColumnDef="nextInvoiceScheduledOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Rechnungslauf</th>
            <td mat-cell *matCellDef="let element">{{ element.nextInvoiceScheduledOn | date : "dd.MM.yyyy" }}</td>
          </ng-container>

          <!-- unbilledAmount Column -->
          <ng-container matColumnDef="unbilledAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Unbilled</th>
            <td mat-cell *matCellDef="let element">{{ element.unbilledAmount | currency : "EUR" : "symbol" }}</td>
          </ng-container>

          <!-- billedAmount Column -->
          <ng-container matColumnDef="billedAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Billed</th>
            <td mat-cell *matCellDef="let element">{{ element.billedAmount | currency : "EUR" : "symbol" }}</td>
          </ng-container>

          <!-- paidAmount Column -->
          <ng-container matColumnDef="paidAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Paid</th>
            <td mat-cell *matCellDef="let element">{{ element.paidAmount | currency : "EUR" : "symbol" }}</td>
          </ng-container>

          <!-- totalPrice Column -->
          <ng-container matColumnDef="totalPrice">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
            <td mat-cell *matCellDef="let element">{{ element.totalPrice | currency : "EUR" : "symbol" }}</td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Aktion</th>
            <td mat-cell *matCellDef="let element">
              <a target="_blank" [href]="'orderoverview/suborder/'+element.suborderIds?.[0]"
                ><i class="fas fa-external-link-alt text-primary"></i
              ></a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsBillingAnalytics"></tr>
          <tr mat-row class="default-cursor" *matRowDef="let row; columns: displayedColumnsBillingAnalytics"></tr>
        </table>
        <mat-paginator
          #billingAnalyticsPaginator
          [pageSizeOptions]="[10, 50, 100]"
          [length]="billingAnalyticsOrderCount"
          (page)="loadBillingAnalyticsOrders()"
        >
        </mat-paginator>
      </div>
    </mat-tab>

    <mat-tab label="Exports">
      <button class="btn btn-primary" (click)="enableExportingTool()" *ngIf="!showExporting">
        Exporting Tool anzeigen
      </button>
      <mat-spinner [diameter]="30" [color]="'primary'" *ngIf="isLoadingExportingTool"></mat-spinner>
      <div class="col-12" *ngIf="showExporting">
        <form [formGroup]="suborderProcessingTimesExportForm" (ngSubmit)="exportCSV()" *ngIf="!isLoadingPackages">
          <div class="row mt-4">
            <div class="col-12 col-md-6 col-xl-6">
              <mat-radio-group formControlName="dataView">
                <mat-radio-button
                  class="mr-2"
                  *ngFor="let item of analyticsToolExportingDataViewList"
                  [value]="item.id"
                >
                  {{ item.name }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col-12 col-md-6 col-xl-6">
              <mat-form-field>
                <mat-label>Month</mat-label>
                <mat-select formControlName="month">
                  <mat-option *ngFor="let m of months" [value]="m">
                    {{ m }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 col-md-4 col-xl-4">
              <mat-form-field>
                <mat-label>Unternehmen</mat-label>
                <mat-select formControlName="uniqueCompanies" [multiple]="true" #companiesSelect>
                  <mat-option>
                    <ngx-mat-select-search [formControl]="companyFilterToExportCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option #allExportingCompaniesSelected [value]="'-'" (click)="selectAllExportingCompanies()"
                    >Alle auswählen
                  </mat-option>
                  <mat-option
                    *ngFor="let company of filteredCompaniesToExport | async"
                    [value]="company.id"
                    (click)="selectSingleExportingCompany()"
                  >
                    {{ company.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-4 col-xl-4">
              <mat-form-field>
                <mat-label>Customer</mat-label>
                <mat-select formControlName="customerIds" [multiple]="true" #customersSelect>
                  <mat-option>
                    <ngx-mat-select-search [formControl]="customerFilterCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option #allExportingUsersSelected [value]="'-'" (click)="selectAllExportingUsers()"
                    >Alle auswählen
                  </mat-option>
                  <mat-option
                    *ngFor="let customer of filteredCustomers | async"
                    [value]="customer.id"
                    (click)="selectSingleExportingUser()"
                  >
                    {{ customer.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-4 col-xl-4">
              <mat-form-field>
                <mat-label>Package</mat-label>
                <mat-select formControlName="packageKeys" [multiple]="true" #packagesSelect>
                  <mat-option>
                    <ngx-mat-select-search [formControl]="packageFilterCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option #allExportingPackagesSelected [value]="'-'" (click)="selectAllExportingPackages()"
                    >Alle auswählen
                  </mat-option>
                  <mat-option
                    *ngFor="let package of filteredPackages | async | keyvalue : packagesMapCompareFn"
                    [value]="package.key"
                    (click)="selectSingleExportingPackage()"
                  >
                    {{ package.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <mat-form-field>
                <mat-label>Output fields</mat-label>
                <mat-select formControlName="selectedCsvOutputFields" [multiple]="true" #outputFieldsSelect>
                  <mat-option>
                    <ngx-mat-select-search [formControl]="outputFieldFilterCtrl"></ngx-mat-select-search>
                  </mat-option>

                  <mat-option #allOutputFieldsSelected [value]="'-'" (click)="selectAllOutputFields()"
                    >Alle auswählen
                  </mat-option>
                  <mat-option
                    *ngFor="let csvOutputField of filteredOutputFields | async"
                    [value]="csvOutputField"
                    (click)="selectSingleOutputField()"
                  >
                    {{ csvOutputField }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <button type="submit" class="btn btn-primary" [disabled]="isExportingCsv">
                Export
                <i *ngIf="isExportingCsv" class="fa fas fa-spinner fa-pulse mx-1"></i>
              </button>
            </div>
          </div>
        </form>
        <div>
          <p class="mt-4 mb-0">Reporting Presets:</p>
          <ng-container *ngFor="let item of conf.reportingPresets">
            <a href="javascript: void(0);" (click)="loadReportingPreset(item)">{{ item.title }}</a
            ><br />
          </ng-container>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>

<ng-template #liveTestingAlertBlock>
  <mat-card class="col-12 bg-danger text-white p-3 ml-2">
    <b
      >Achtung: Diese Funktion befindet sich zur Zeit im Live Testing: Die hier abgebildeten Werte sind ggf. falsch.<br />
      Belastbare Werte können über "Auftragseingang" und das Exporting-Tool abgerufen werden.</b
    >
  </mat-card>
</ng-template>
