import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { NotificationsService } from '@app/_services/notifications.service';

@Component({
  selector: 'app-login-with-token',
  templateUrl: './login-with-token.component.html',
  styleUrls: ['./login-with-token.component.css']
})
export class LoginWithTokenComponent implements OnInit {
  text: string;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private ns: NotificationsService) { }

  ngOnInit() {
    this.text = 'Bitte warten...';
    this.auth.showLoader.emit(true);
    this.route.queryParams.subscribe(params => {
      const token = params.token;
      const redirectPath = params.redirectPath;
      this.auth.loginWithToken(token, redirectPath).then(response => {
        this.auth.showLoader.emit(false);
      }).catch((err) => {
        this.ns.showNotification('Ungültiges oder abgelaufenes Zugangstoken', 'danger');
        this.text = 'Anmeldung fehlgeschlagen: Ungültiges oder abgelaufenes Zugangstoken';
      }).finally(() => {
        this.auth.showLoader.emit(false);
      });
    });
  }
}
