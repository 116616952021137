import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AccountingPosition, SpecialCondition } from "@app/interfaces/accounting-position.interface";

@Component({
  selector: "app-price-delete-modal",
  templateUrl: "./price-delete-modal.component.html",
  styleUrls: ["./price-delete-modal.component.css"],
})
export class PriceDeleteModalComponent implements OnInit {
  dialogData: any = {};
  beforeSave = false;
  deletedCompaniesSpecialConditions: SpecialCondition[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<PriceDeleteModalComponent>) {
    this.dialogData = data;
  }

  ngOnInit(): void {
    this.deletedCompaniesSpecialConditions = this.dialogData.deletedCompaniesSpecialConditions;
    this.beforeSave = this.dialogData.beforeSave;
  }

  closeDeleteModal() {
    this.dialogRef.close({
      delete: false,
    });
  }

  saveDeleteModal() {
    this.dialogRef.close({
      delete: true,
    });
  }

  saveOnlyPrice() {
    this.dialogRef.close({
      delete: true,
      saveOnlyPrice: true,
    });
  }

  saveDeleteCompany() {
    this.dialogRef.close({
      delete: true,
      saveDeleteCompany: true,
    });
  }

  saveOnlyPirce() {
    this.dialogRef.close({
      delete: true,
      saveOnlyPrice: true,
    });
  }
}
