import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MarkerToolService } from "./marker-tool.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { UploadService } from "@app/_services/upload.service";
import { AuthService } from "@app/auth/auth.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { FileUploader } from "ng2-file-upload";
import * as _ from "underscore";

import { FunctionsService } from "@app/_services/functions.service";
import { SubOrdersLogicService } from "../../_services/sub-orders-logic.service";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { SubOrderDraftStatusEnum } from "@app/models/suborder-draft-status-list";
import { ImoFileUploadComponent } from "@app/shared-module/imo-file-upload/imo-file-upload.component";
import * as moment from "moment";
import { OrdersLogicService } from "../../_services/orders-logic.service";
import { SubcollectionFileModel } from "@app/models/subcollectionFileModel";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { CONSTANTS } from "@app/util/constants";
import { CounterMarker } from "src/assets/js/markerjs2-master/src";
import { McGrundrissLogicService } from "@app/_services/mc-grundriss-logic.service";
import { FeedbackToolModeEnum } from "@app/models/feedback-tool-mode.enum";
import { TranslationService } from "@app/_services/translation.service";
import { StatusLogicService } from "@app/_services/status-logic.service";
import { OrderMailService } from "@app/_services/order-mail.service";
import { NotificationTypeEnum } from "@app/models/notificationType.enum";
import { NotificationsService } from "@app/_services/notifications.service";
import { MediaTypeEnum } from "@app/models/media-type.model";
import { EsoftApiService } from "@app/_services/esoft-api.service";
import { OrderRejectionMail } from "@app/interfaces/order-rejection-mail.interface";
import { ConfigurationLogicService } from "@app/_services/configuration-logic.service";
import { McGrundrissConfig } from "@app/interfaces/mcgrundriss-config-data.interface";
import { getFileExtension, isImage, isImgPreviewFromPDF } from "@app/util/helper";
import { GrundrissSchmiedeService } from "@app/_services/grundriss-schmiede.service";

declare const $: any;

@Component({
  selector: "app-feedback-tool",
  templateUrl: "./feedback-tool.component.html",
  styleUrls: ["./feedback-tool.component.css"],
})
export class FeedbackToolComponent implements OnInit, OnDestroy {
  private readonly logComponent = "FeedbackToolComponent :: ";
  editbar: string;
  infooption: string;
  commentForm: FormGroup;
  download_url: any;
  photoCounter: number;
  hasFoundFiles = false;
  initialComment = "";
  initialAdminComment = "";

  @Input("suborderId") suborderID: string;
  @Input("suborder") suborder: SubOrder;
  @Input("outputFormat") outputFormat: string;
  @Input("mode") mode = FeedbackToolModeEnum.Normal;
  @Input() claim = false; // Indicates whether the feedback is a claim (and thus free)
  @Input() subjectToCharge = false; // Indicates whether the feedback costs money and the price will be displayed at the end of the process.
  @Input() isStandAlone = false;
  @Output() onFinish = new EventEmitter();
  @Output() onAbort = new EventEmitter();
  @ViewChild("canvas") canvas;
  @ViewChild("fileUploadComponent", { static: true }) fileUploadComponent: ImoFileUploadComponent;

  ofSubscribers: any;
  display_selector: any;
  loading_selector: any;
  comment_selector: any;
  comment_saved: any;
  feedback_concluded: any;
  givenSuborderId: any;
  photoCount = 0;
  comments = [];
  showToolbar = false;
  feedbackComment = "";
  counterMarkerComments: string[] = [];
  adminCounterMarkerComments: string[] = [];
  dataLoaded: boolean = false;
  firstIndex = 0;
  lastIndex = 0;
  indicators: boolean = true;
  tilesPerSlide = 0;
  showCarousel: boolean = false;
  public uploader: FileUploader = new FileUploader({});
  generalComment = "";
  commentForServiceProvider = "";
  feedbackCommentForServiceProvider = "";
  outputFolder: any;
  imageSelected: boolean = true;
  outputLabel = "Bilder";
  handleAddedCounterEvent = this.onAddedCounterMarker.bind(this);
  handleRemovedCounterEvent = this.onRemovedCounterMarker.bind(this);
  orderTypeEnum = OrderTypeEnum;
  hasAdminApproval: boolean;
  autoTranslationServices = [OrderTypeEnum.Retouching, OrderTypeEnum.V_Staging];
  isTourSelected = false;
  hasFeedback: boolean;
  debugLogs: string[] = [];

  constructor(
    public mts: MarkerToolService,
    public fb: FormBuilder,
    public sos: SubOrdersLogicService,
    private sls: StatusLogicService,
    public ols: OrdersLogicService,
    private oms: OrderMailService,
    public router: Router,
    public us: UploadService,
    private auth: AuthService,
    private modalService: NgxSmartModalService,
    private uploadService: UploadService,
    private ns: NotificationsService,
    private ts: TranslationService,
    private mcls: McGrundrissLogicService,
    private fs: FunctionsService,
    private es: EsoftApiService,
    private cls: ConfigurationLogicService,
    private gss: GrundrissSchmiedeService
  ) {
    this.commentForm = this.fb.group({
      comment: [""],
    });
  }

  ngOnInit() {
    this.hasFeedback = false;
    // auto pilot suborders dont need Admin approval step
    this.hasAdminApproval = this.mode === FeedbackToolModeEnum.Normal && this.suborder.hasAutoPilot;
    this.auth.showLoader.emit(true);
    if (!this.suborderID) {
      this.router.navigate(["orderoverview"]);
    }
    this.display_selector = "hidden";
    this.loading_selector = true;
    this.comment_selector = "visible";
    this.comment_saved = "hidden";
    this.feedback_concluded = "hidden";
    this.showCarousel = false;
    this.mts.suborderID = this.suborderID;
    if (this.mode === FeedbackToolModeEnum.Forward_Feedback) {
      this.outputFolder = "feedback" + (this.suborder.feedbackNumber - 1) + "_adminFeedbacks";
    } else if (this.mode === FeedbackToolModeEnum.Reject_Results) {
      this.outputFolder = "feedback" + this.suborder.feedbackNumber + "_supportingMaterials";
    } else {
      this.outputFolder = "feedback" + this.suborder.feedbackNumber + "_feedbacks";
    }
    this.mts.feedbacks = [];
    this.mts.approvedFeedbacks = [];
    this.mts.finalPhotos = [];
    if (this.suborderID !== undefined) {
      if (this.outputFormat !== "Video") {
        let self = this;
        this.submitHandler(this.suborderID).then(() => {
          self.mts.finalPhotos = self.mts.finalPhotos.sort(self.compareValues("index"));
          self.mts.feedbacks = self.mts.feedbacks.sort(self.compareValues("index"));

          // show the form even when no photos were loaded
          if (!this.hasFoundFiles) {
            this.showToolbar = false;
            this.display_selector = "visible";
          }
        });
      } else {
        this.outputLabel = "Videos";
        this.photoCounter = -1;
        this.display_selector = "visible";

        this.dataLoaded = true;
        this.showToolbar = false;
        this.loading_selector = false;
        this.auth.showLoader.emit(false);

        this.comment_selector = "visible";
        this.comment_saved = "hidden";
      }
    }
    this.auth.showLoader.emit(false);

    window.addEventListener(CONSTANTS.EVENTS.MARKER_TOOL.ADDED_COUNTER_MARKER, this.handleAddedCounterEvent);
    window.addEventListener(CONSTANTS.EVENTS.MARKER_TOOL.REMOVED_COUNTER_MARKER, this.handleRemovedCounterEvent);
  }

  onAddedCounterMarker(e: CustomEvent) {
    this.counterMarkerComments.push("");
  }

  onRemovedCounterMarker(e: CustomEvent) {
    const index = Number(e.detail) - 1;
    this.counterMarkerComments.splice(index, 1);
  }

  resetMarkerTool() {
    this.counterMarkerComments = [];
    CounterMarker.setCounter(this.counterMarkerComments.length);
    this.mts.closeMarkerArea(true);
  }

  ngOnDestroy() {
    window.removeEventListener(CONSTANTS.EVENTS.MARKER_TOOL.ADDED_COUNTER_MARKER, this.handleAddedCounterEvent);
    window.removeEventListener(CONSTANTS.EVENTS.MARKER_TOOL.REMOVED_COUNTER_MARKER, this.handleRemovedCounterEvent);

    this.mts.closeMarkerArea();
    if ($("body > div")[0]) {
      $("body > div")[0].style.zIndex = -1;
    }
  }

  goBack() {
    let confirmed = false;

    if (this.hasFeedback) {
      const confirmed = confirm(
        "Sind Sie sicher, dass Sie das Feedback abbrechen wollen? Ihre Änderungen werden nicht gespeichert!"
      );
    } else {
      confirmed = true;
    }

    if (confirmed) {
      this.onAbort.emit();
    }
  }

  async submitHandler(suborderID?: any) {
    console.log("Calling SubmitHandler?!");
    this.photoCounter = 0;
    this.display_selector = "hidden";
    this.loading_selector = true;
    this.showToolbar = false;
    if (this.mode === "forwardFeedback") {
      if (this.suborder.feedback_comments[this.suborder.feedbackNumber - 1]) {
        this.generalComment = this.suborder.feedback_comments[this.suborder.feedbackNumber - 1]["user_comment"] || "";
      }
    }

    const promise = new Promise<void>((resolve) => {
      this.dataLoaded = false;
      this.loading_selector = true;
      this.getMiniatureOverview(this.suborderID).then(() => {
        this.initializeAllPhotos().then(() => {
          this.dataLoaded = true;
          this.showToolbar = true;
          this.loading_selector = false;
          this.gotoImage(0);
          this.auth.showLoader.emit(false);
          resolve();
        });
      });
    });

    // retrieve order, get photos from storage and display them for annotation!
    return promise;
  }

  getMiniatureOverview(orderIdParam) {
    this.photoCount = 0;
    return new Promise<void>((resolve, reject) => {
      let inputFolder = "";
      switch (this.mode) {
        case "normal":
        case "rejectResults":
          inputFolder = "feedback" + this.suborder.feedbackNumber + "_outputPhotos";
          break;
        case "forwardFeedback":
          inputFolder = "feedback" + (this.suborder.feedbackNumber - 1) + "_feedbacks";
          break;
      }
      this.uploadService
        .getSubcollection("suborders", this.suborderID, inputFolder)
        .subscribe((response: SubcollectionFileModel[]) => {
          this.mts.finalPhotos = response.map((x) => {
            const originalData = {
              file_size: x.file_size,
              download_url: x.download_url,
              download_url_xs: x.download_url_xs,
              download_url_m: x.download_url_m,
              download_url_xl: x.download_url_xl,
              comment: x.comment,
              unitId: x.unitId,
              serviceProviderComment: x.serviceProviderComment,
              floorName: x.floorName,
            };
            return Object.assign(
              {},
              {
                ...originalData,
                fileName: x.file_name,
                comment: x.comment || "",
                isImage: isImage(x.file_name) || isImgPreviewFromPDF(x.file_name, x.download_url_m),
              }
            );
          });

          this.mts.feedbacks = response.map((x) => {
            const originalData = {
              file_size: x.file_size,
              download_url: x.download_url,
              download_url_xs: x.download_url_xs,
              download_url_m: x.download_url_m,
              download_url_xl: x.download_url_xl,
              comment: x.comment,
              unitId: x.unitId,
              serviceProviderComment: x.serviceProviderComment,
              floorName: x.floorName,
            };

            return Object.assign(
              {},
              {
                ...originalData,
                fileName: x.file_name,
                comment: x.comment || "",
                isImage: isImage(x.file_name) || isImgPreviewFromPDF(x.file_name, x.download_url_m),
                counterMarkerComments: x.counterMarkerComments || [],
              }
            );
          });
          this.photoCount = response.length;
          if (this.photoCount) {
            this.hasFoundFiles = true;
          } else {
            // when no files were found we should still show the form info to the user, therefore we simulate a file
            this.photoCount = 1;
          }
          this.lastIndex = response.length;
          const imglength = this.photoCount;
          const width = window.innerWidth;
          this.tilesPerSlide = Math.min(2, imglength);
          if (width > 1024) {
            this.tilesPerSlide = Math.min(6, imglength);
          } else if (width >= 992) {
            this.tilesPerSlide = Math.min(4, imglength);
          }
          if (this.tilesPerSlide === imglength) {
            this.indicators = false;
          }
          this.showCarousel = true;
          this.lastIndex = this.tilesPerSlide;
          resolve();
        });
    });
  }

  async addToFeedbacks(subOrderId) {
    if (this.fileUploadComponent.uploader.queue.length) {
      this.fileUploadComponent.uploadFiles();
    }
    if (this.outputFormat === "Video") {
      if (_.isEmpty(this.generalComment)) {
        this.ns.showNotification(
          'Sie haben keine Anmerkungen eingegeben. Bitte geben Sie Ihre Anmerkungen in das Textfeld ein, bevor Sie fortfahren. Falls Sie keine Anmerkungen haben gehen Sie zurück ("Feedback abbrechen") und Akzeptieren Sie die Bestellung final.',
          "danger"
        );
        return;
      }
      this.imageSelected = false;
      this.isTourSelected = false;
      this.photoCounter = 0;
      this.mts.closeMarkerArea();
      this.display_selector = "hidden";
      this.comment_selector = "hidden";
      this.comment_saved = "hidden";
      this.showCarousel = false;
      this.showToolbar = false;
      this.firstIndex = 0;
      this.lastIndex = this.tilesPerSlide;
    } else {
      const file = this.mts.finalPhotos[this.photoCounter];

      if (isImgPreviewFromPDF(file.fileName, file.download_url_m)) {
        // Rewrite file info so we upload the annotated png instead of the pdf
        file.fileName = file.fileName.replace(/\.pdf/i, ".png");
        file.download_url = file.download_url_m;
      }

      const fileName = file?.fileName;

      if (this.hasAdminApproval) {
        this.feedbackCommentForServiceProvider = this.feedbackComment;
        this.adminCounterMarkerComments = this.counterMarkerComments;
      }

      if (fileName) {
        this.mts
          .pushToFeedbacks(
            fileName,
            this.photoCounter,
            this.feedbackComment,
            this.feedbackCommentForServiceProvider,
            this.counterMarkerComments,
            this.adminCounterMarkerComments,
            this.hasAdminApproval,
            file.unitId,
            file.floorName
          )
          .then(() => {
            // this.selectNextPhoto();
            this.feedbackComment = " ";
            this.feedbackCommentForServiceProvider = "";
            this.counterMarkerComments = [];
            this.adminCounterMarkerComments = [];
            this.gotoImage(this.photoCounter + 1, false);
          });
      } else {
        this.gotoImage(this.photoCounter + 1, false);
      }
    }
  }

  async finishFeedBack(mode) {
    this.debugLogs = [];
    this.debugLogs.push("Default Mode: " + mode);
    this.debugLogs.push("Has Admin Approval: " + this.hasAdminApproval);
    this.debugLogs.push("Has autopilot: " + this.suborder.hasAutoPilot);
    const hasAutoTranslation = this.hasAdminApproval && this.autoTranslationServices.includes(this.suborder.orderType);
    // Save general comment
    if (!this.suborder.feedback_comments) {
      this.suborder["feedback_comments"] = [];
    }

    /* If feedback is given on old entries, fill feedback_comments */
    while (this.suborder.feedback_comments[this.suborder.feedbackNumber] === undefined) {
      this.suborder.feedback_comments.push({
        user_comment: "",
        admin_comment: "",
        rejection_comment: "",
        rejection_comments_archive: "",
      });
    }
    if (mode !== FeedbackToolModeEnum.Reject_Results) {
      this.suborder.feedback_comments[this.suborder.feedbackNumber]["user_comment"] = this.generalComment;
    }
    if (mode === FeedbackToolModeEnum.Forward_Feedback || this.hasAdminApproval) {
      // translate generic text automatically when having auto translation flag
      if (hasAutoTranslation) {
        await this.translateGenericComment();
      }

      let feedbackNumber: number;
      if (mode === FeedbackToolModeEnum.Forward_Feedback) {
        // when the Admin enters this mode, the feedbackNumber was already increased
        feedbackNumber = this.suborder.feedbackNumber - 1;
      } else {
        feedbackNumber = this.suborder.feedbackNumber;
      }

      let adminComment: string;
      if (this.hasAdminApproval) {
        adminComment = this.generalComment;
      } else {
        adminComment = this.commentForServiceProvider;
      }

      this.suborder.feedback_comments[feedbackNumber]["admin_comment"] = adminComment;
    }
    if (mode === FeedbackToolModeEnum.Reject_Results) {
      if (this.suborder.feedback_comments[this.suborder.feedbackNumber]["rejection_comment"]) {
        if (this.suborder.feedback_comments[this.suborder.feedbackNumber]["rejection_comments_archive"]) {
          this.suborder.feedback_comments[this.suborder.feedbackNumber]["rejection_comments_archive"] +=
            "<br><strong>" +
            moment(this.suborder.statusDates.feedbacks[this.suborder.feedbackNumber - 1]["resultRejection"]).format(
              "DD.MM.YYYY, HH:mm"
            ) +
            ": </strong>" +
            this.suborder.feedback_comments[this.suborder.feedbackNumber]["rejection_comment"];
        } else {
          this.suborder.feedback_comments[this.suborder.feedbackNumber]["rejection_comments_archive"] =
            "<strong>" +
            moment(this.suborder.statusDates.feedbacks[this.suborder.feedbackNumber - 1]["resultRejection"]).format(
              "DD.MM.YYYY, HH:mm"
            ) +
            ": </strong>" +
            this.suborder.feedback_comments[this.suborder.feedbackNumber]["rejection_comment"];
        }
      }
      this.suborder.feedback_comments[this.suborder.feedbackNumber]["rejection_comment"] = this.generalComment;
    }

    /**
     * emit finish event before changing the suborder to avoid reload of feedback-loop component.
     */
    if (mode === FeedbackToolModeEnum.Forward_Feedback) {
      const eventToEmit = {
        event: mode,
        data: this.mts.feedbacks,
      };
      this.onFinish.emit(eventToEmit);
    } else if (mode === FeedbackToolModeEnum.Reject_Results) {
      const eventToEmit = {
        event: mode,
        data: this.mts.feedbacks,
      };
      this.onFinish.emit(eventToEmit);
    } else {
      const eventToEmit = {
        event: true,
        data: this.mts.feedbacks || {},
      };
      this.onFinish.emit(eventToEmit);
    }

    // Add the general feedback to the database
    this.sos.changeSuborder(this.suborderID, "feedback_comments", this.suborder.feedback_comments);

    this.auth.showLoader.emit(true);

    try {
      this.debugLogs.push("Suborder feedback number: " + this.suborder.feedbackNumber);
      let feedbackNumber: number;
      if (mode === FeedbackToolModeEnum.Forward_Feedback) {
        // when the Admin enters this mode, the feedbackNumber was already increased
        feedbackNumber = this.suborder.feedbackNumber - 1;
      } else {
        feedbackNumber = this.suborder.feedbackNumber;
      }
      this.debugLogs.push("Current feedback number: " + feedbackNumber);
      this.debugLogs.push("Executing upload feedbacks for normal mode");
      await this.uploadFeedbacks(mode, this.mts.feedbacks, this.outputFolder, feedbackNumber);
      // upload feedbacks
      if (this.hasAdminApproval) {
        // No need to upload the files again, just copy the customer feedback subcollection contents to admin_feedback subcollection
        await this.uploadFeedbacks(
          FeedbackToolModeEnum.Forward_Feedback,
          this.mts.feedbacks,
          "feedback" + this.suborder.feedbackNumber + "_adminFeedbacks",
          feedbackNumber,
          !!this.suborder.mcgrundrissID || !!this.suborder.grundrissSchmiedeOrderIds?.length, // The first method call already triggers the submission of the feedback to McGrundriss / Grumdriss Schmiede!
          true,
          this.outputFolder,
          false
        );
        this.debugLogs.push("Order Type: " + this.suborder.orderType);
        if (this.suborder.orderType === OrderTypeEnum.Floor_Plan) {
          const mcGConfig = (await this.cls
            .getConfiguration(CONSTANTS.CONFIGURATIONS.MC_GRUNDRISS)
            .toPromise()) as McGrundrissConfig;
          if (this.suborder.assignedTo === mcGConfig.userIdMcGrundrissAtImogent) {
            await this.mcls.addSuborderIdToOpenFeedbackIds(this.suborder.id);
          }
        }
      }
    } catch (err) {
      this.debugLogs.push("Error occurred");
      this.debugLogs.push(JSON.stringify(err));
      console.error(err);
    } finally {
      await this.cls.addLogs(CONSTANTS.LOG_COLLECTIONS.FEEDBACK_LOGS, {
        subOrderId: this.suborderID,
        createdOn: new Date(),
        logs: this.debugLogs,
      });
    }

    this.auth.showLoader.emit(false);
  }

  /**
   * @param {FeedbackToolModeEnum} mode - The mode of the feedback tool (Forward_Feedback or Reject_Results)
   * @param {any[]} feedbacks - An array of feedbacks
   * @param {string} outputFolder - The output folder for the feedback files
   * @param {number} feedbackNumber - The number of the feedback
   * @param {boolean} [skipFeedbackSubmission] - Optional. Whether to skip the feedback submission
   * @param {boolean} [copyFeedbacks] - Optional. Whether to copy the feedbacks
   * @param {string} [originalFolder] - Optional. The original folder for the feedback files
   * @param {boolean} [isEmailRejectionEnabled] - Optional. Whether email rejection is enabled
   * @returns {Promise<void>} - A promise that resolves when the feedbacks are uploaded
   */
  async uploadFeedbacks(
    mode: FeedbackToolModeEnum,
    feedbacks: any[],
    outputFolder: string,
    feedbackNumber: number,
    skipFeedbackSubmission?: boolean,
    copyFeedbacks?: boolean,
    originalFolder?: string,
    isEmailRejectionEnabled = true
  ): Promise<void> {
    let finishFeedback;
    const collection = "suborders";
    // We need to update the status dates and action required flags in all scenarios
    if (mode === FeedbackToolModeEnum.Forward_Feedback) {
      setTimeout(() => {
        this.suborder.statusDates["feedbacks"][feedbackNumber - 1]["adminFeedback"] = new Date();
        this.sls.updateStatusDates(this.suborder, this.suborder.statusDates, false, false, false, true);
      }, 100);
    } else if (mode === FeedbackToolModeEnum.Reject_Results) {
      setTimeout(() => {
        const feedbacks = this.suborder.statusDates.feedbacks.length;
        this.suborder.statusDates["feedbacks"][feedbacks - 1]["resultRejection"] = new Date();
        delete this.suborder.statusDates["feedbacks"][feedbacks - 1]["uploaded"];
        this.sls.updateStatusDates(this.suborder, this.suborder.statusDates, false, false, false, true);
      }, 100);
    }
    if (!copyFeedbacks) {
      this.debugLogs.push("Uploading feedback files");
      finishFeedback = new Promise<void>(async (res, rej) => {
        const feedbacks_local = JSON.parse(JSON.stringify(feedbacks));
        const totalFiles = feedbacks_local.length;
        let uploadedCount = 0;
        const increaseUploadCountAndTryToResolvePromiseFn = () => {
          uploadedCount += 1;
          this.debugLogs.push("Processed " + uploadedCount + " out of " + totalFiles + " files");
          if (uploadedCount === totalFiles) {
            this.uploadService.onFilesUploaded.next({
              collection: collection,
              document: this.suborderID,
              subcollection: outputFolder,
            });
            res();
          }
        };

        if (
          this.suborder.statusDates.feedbacks[feedbackNumber - 1].resultRejection &&
          mode === FeedbackToolModeEnum.Reject_Results
        ) {
          // Copy existing files into archive collection
          this.uploadService.copyToOtherCollection(
            "suborders",
            this.suborder.id,
            "feedback" + feedbackNumber + "_supportingMaterials",
            "suborders",
            this.suborder.id,
            "feedback" + feedbackNumber + "_archivedSupportingMaterials",
            true
          );
        }

        if (totalFiles > 0) {
          // Upload general files
          try {
            // Upload feedbacked photos
            for (let i = 0; i < feedbacks_local.length; i++) {
              const file = feedbacks_local[i];
              const fileName = file.fileName;
              if (file.feedbackGiven) {
                if (file.file && fileName) {
                  //upload files only if existing
                  if (this.isImage(fileName)) {
                    const uploadFile = await this.urltoFile(file.file, fileName, "image/png"); // Photos from the feedback-tool are pngs.
                    const path = `suborders/${this.suborderID}/${outputFolder}/`;
                    // Upload to storage
                    this.uploadService
                      .uploadFile(uploadFile, path, true, "feedback_" + i + "_")
                      .toPromise()
                      .then((uploadResponse) => {
                        const uploadData = uploadResponse["body"];
                        const data = {
                          ...uploadData,
                          file_name: fileName,
                          comment: file.comment || " ",
                          adminComment: file.adminComment || "",
                          counterMarkerComments: file.counterMarkerComments,
                          adminCounterMarkerComments: file.adminCounterMarkerComments,
                        };

                        if (file.unitId !== undefined) {
                          data.unitId = file.unitId;
                        }

                        if (file.floorName) {
                          data.floorName = file.floorName;
                        }

                        this.uploadService
                          .addSubCollectionDocument(collection, this.suborderID, outputFolder, data)
                          .then(increaseUploadCountAndTryToResolvePromiseFn);
                      });
                  } else {
                    const data = {
                      ...file,
                      file_name: fileName,
                      createdOn: new Date(),
                      file_size: file.file_size || "0",
                      comment: file.comment || " ",
                      adminComment: file.adminComment || "",
                      counterMarkerComments: file.counterMarkerComments,
                      adminCounterMarkerComments: file.adminCounterMarkerComments,
                    };

                    if (file.unitId !== undefined) {
                      data.unitId = file.unitId;
                    }

                    if (file.floorName) {
                      data.floorName = file.floorName;
                    }

                    this.uploadService
                      .addSubCollectionDocument(collection, this.suborderID, outputFolder, data)
                      .then(increaseUploadCountAndTryToResolvePromiseFn);
                  }
                }
              } else {
                uploadedCount += 1;
                this.debugLogs.push("Processed " + uploadedCount + " out of " + totalFiles + " files");
                if (uploadedCount === totalFiles) {
                  res();
                }
              }
            }
          } catch (e) {
            console.error("upload error: " + e);
          }
        }
      });
    } else if (originalFolder) {
      this.debugLogs.push("Copying feedback files collection");
      finishFeedback = new Promise<void>(async (res, rej) => {
        this.uploadService
          .getSubcollection(CONSTANTS.COLLECTIONS.SUBORDERS, this.suborderID, originalFolder)
          .toPromise()
          .then((filesList) => {
            let uploadedCount = 0;
            filesList.forEach((fileData) => {
              this.uploadService
                .addSubCollectionDocument(CONSTANTS.COLLECTIONS.SUBORDERS, this.suborderID, outputFolder, fileData)
                .finally(() => {
                  uploadedCount += 1;
                  this.debugLogs.push("Processed " + uploadedCount + " out of " + filesList.length + " files");
                  if (uploadedCount === filesList.length) {
                    res();
                  }
                });
            });
          })
          .catch((error) => {
            rej(error);
          });
      });
    }

    return new Promise((resolve) => {
      finishFeedback
        .then(async () => {
          const files = await this.uploadService
            .getSubcollection("suborders", this.suborderID, outputFolder)
            .toPromise();
          const self = this;
          const feedbackDetails = files;
          let cmmnt =
            self.suborder.feedback_comments && self.suborder.feedback_comments[self.suborder.feedbackNumber]
              ? self.suborder.feedback_comments[self.suborder.feedbackNumber].user_comment || ""
              : "";
          if (mode && mode === FeedbackToolModeEnum.Forward_Feedback) {
            cmmnt =
              self.suborder.feedback_comments &&
              self.suborder.feedback_comments[self.suborder.feedbackNumber]["admin_comment"];
            feedbackDetails.forEach((feedback: SubcollectionFileModel) => {
              if (feedback.adminComment) feedback.comment = feedback.adminComment;
              const counterMarkerComments = feedback.counterMarkerComments || [];
              const adminCounterMarkerComments = feedback.adminCounterMarkerComments || [];
              for (let index = 0; index < counterMarkerComments.length; index++) {
                const adminCounterMarkerComment = adminCounterMarkerComments[index] || "";
                // override the original counter marker comments with the Admin ones, if they were translated
                if (adminCounterMarkerComment) {
                  counterMarkerComments[index] = adminCounterMarkerComment;
                }
              }
            });
          } else if (mode && mode === FeedbackToolModeEnum.Reject_Results) {
            cmmnt =
              this.suborder.feedback_comments && this.suborder.feedback_comments[feedbackNumber]["rejection_comment"];
          }
          let isDraft = true;
          if (
            !self.suborder.numberOfDrafts ||
            (self.suborder.drafts && self.suborder.drafts.indexOf(SubOrderDraftStatusEnum.Finally_Accepted) >= 0)
          ) {
            isDraft = false;
          }
          const emailData: OrderRejectionMail = {
            subOrderDetails: {
              feedback: cmmnt,
              serviceName: this.suborder.packageName,
              orderType: this.suborder.orderType,
              subOrderId: this.suborderID,
              isDraft: isDraft,
              isReject: true,
              feedbackDetails: files || [],
              esoftBatchId: this.suborder.esoft && this.suborder.esoft.esoftBatchId,
              packageNum: this.suborder.packageNum.split("|").pop(),
              realEstateId: this.suborder.realestateId,
              immoviewerId: this.suborder.immoviewer?.immoviewerOrderId || "",
              feedbackNumber: this.suborder.feedbackNumber,
            },
            orderId: this.suborder.orderId,
            mode: mode,
          };

          if (isEmailRejectionEnabled) {
            this.fs.callFunction("sendOrderRejectionEmail", emailData).subscribe();
          }
          if (
            [OrderTypeEnum.V_Staging, OrderTypeEnum.Retouching].includes(this.suborder.orderType) &&
            (mode === FeedbackToolModeEnum.Forward_Feedback || mode === FeedbackToolModeEnum.Reject_Results)
          ) {
            await this.es.sendFeedbackToEsoft(emailData);
          }
          if (this.claim || this.subjectToCharge) {
            await this.sos.buyFeedback(this.suborder, this.claim);
          }
          if (
            this.suborder.orderType === OrderTypeEnum.Floor_Plan &&
            this.suborder.mcgrundrissID &&
            !skipFeedbackSubmission
          ) {
            try {
              // NOTE: when the Admin enters the 'forwardFeedback' mode, the feedbackNumber was already increased, therefore we need to decrease it in order to fetch the files from the proper folder when sending the feedback.
              this.mcls.sendFeedbackBackend(
                this.suborder.id,
                mode === FeedbackToolModeEnum.Forward_Feedback ? feedbackNumber - 1 : feedbackNumber,
                mode
              );
            } catch (err) {
              // Fail silently. The imogent team will be noticed via our backend. The customer shouldn't notice the interruption.
              console.log(err);
            }
          }
          if (this.suborder.orderType === OrderTypeEnum.Area_Calculation && this.suborder.serviceProviderOrderId) {
            await this.gss.sendFeedback(this.suborder.id, feedbackNumber, this.claim);
          }
          if (this.hasAdminApproval) {
            if (
              (this.suborder.feedbackNumber >= 3 && this.suborder.orderType === OrderTypeEnum.Floor_Plan) ||
              (this.suborder.feedbackNumber >= 2 &&
                (this.suborder.orderType === OrderTypeEnum.V_Staging ||
                  this.suborder.orderType === OrderTypeEnum.Area_Calculation))
            ) {
              this.suborder.hasAutoPilot = false;
              await this.sos.updateSubOrder(this.suborder.id, {
                hasAutoPilot: this.suborder.hasAutoPilot,
              });
              this.oms.sendNotificationMail(NotificationTypeEnum.AutoPilotDisabled, this.suborder);
            }
          }
          resolve();
        })
        .catch((error) => {
          this.debugLogs.push("Error occurred in finish feedback promise");
          this.debugLogs.push(JSON.stringify(error));
          resolve();
        });
    });
  }

  initializeAllPhotos() {
    const self = this;
    const dataUrlPromises: Promise<any>[] = [];

    return new Promise<void>(async (res, rej) => {
      for (let i = 0; i < this.mts.finalPhotos.length; i++) {
        let url_m = "assets/images/no-image.png";
        let url_xs = "assets/images/no-image.png";

        const file = this.mts.finalPhotos[i];
        const fileName = file.fileName;
        const fileExtension = getFileExtension(fileName);
        console.log("fileName:", fileName);
        console.log("fileExtension:", fileExtension);
        if (CONSTANTS.SUPPORTED_IMAGE_EXTENSIONS.includes(fileExtension)) {
          url_m = file.download_url_m;
          url_xs = file.download_url_xs;
        }

        if (fileExtension === "pdf" && this.mts.finalPhotos[i].download_url_m) {
          url_m = file.download_url_m;
          url_xs = file.download_url_m;
        }

        // get medium image
        dataUrlPromises.push(
          self.mts.getDataURL(url_m).then((uri) => {
            self.mts.finalPhotos[i]["file"] = uri;
            self.mts.finalPhotos[i]["index"] = i;

            self.mts.feedbacks[i]["file"] = uri;
            self.mts.feedbacks[i]["index"] = i;
          })
        );
        // get thumb image
        dataUrlPromises.push(
          self.mts.getDataURL(url_xs).then((uri) => {
            self.mts.finalPhotos[i]["file_xs"] = uri;
            self.mts.feedbacks[i]["file_xs"] = uri;
          })
        );
      }

      await Promise.all(dataUrlPromises);
      res();
    });
  }

  resetFeedback(event: Event) {
    if (!confirm("Möchten Sie die Änderungen verwerfen?")) {
      event.preventDefault();
      return;
    }

    this.feedbackComment = "";
    if (!this.mts.feedbacks[this.photoCounter].feedbackGiven && this.mts.hasChanged()) {
      this.resetMarkerTool();
    }
    if (this.mts.feedbacks[this.photoCounter].feedbackGiven) {
      const index = this.mts.feedbacks[this.photoCounter].index;
      this.mts.feedbacks[this.photoCounter].feedbackGiven = false;
      const fl = this.mts.finalPhotos.find((fl) => fl.index === index);
      this.mts.feedbacks[this.photoCounter].file = fl.file;
      this.mts.feedbacks[this.photoCounter].comment = " ";
      this.download_url = fl.file;
      this.resetMarkerTool();
      this.display_selector = "visible";
      this.loading_selector = false;
      this.comment_selector = "visible";
      this.comment_saved = "hidden";
      this.mts.feedbacks[index] = this.mts.finalPhotos[index];
    }
  }

  gotoImage(index, checkForChanges?, showFeedbackAgain = false) {
    if (index < 0) {
      return;
    }

    if (this.outputFormat === "Video") {
      this.display_selector = "visible";
      this.photoCounter = -1;
      return;
    }
    if (checkForChanges === undefined) {
      checkForChanges = true;
    }
    const self = this;
    if (
      checkForChanges &&
      (this.mts.hasChanged() ||
        this.initialComment !== this.feedbackComment ||
        (this.mode === "forwardFeedback" && this.initialAdminComment !== this.feedbackCommentForServiceProvider))
    ) {
      // TODO: Add check if image comment has changed.
      this.modalService.getModal("unsavedChanges").open();

      return;
    }
    this.hasFeedback = this.mts.feedbacks?.some((item) => item.feedbackGiven === true);
    this.imageSelected = false;
    this.isTourSelected = false;
    if (showFeedbackAgain || (this.hasFoundFiles && index >= 0 && index < self.photoCount)) {
      self.photoCounter = index;
      this.showCarousel = true;
      this.display_selector = "visible";
      this.download_url = "";
      const feedback = this.mts.feedbacks[this.photoCounter];

      if (feedback) {
        // The load event on html image tag is not triggerred when the next image is identical in content as the previous one since we are loading base64 string and not the source url.
        // Delaying the assignment of base 64 string image to download_url property triggers the (load) event even if the images are identical in content
        setTimeout(() => {
          this.download_url = feedback.file;
          this.imageSelected =
            this.isImage(feedback.fileName) || isImgPreviewFromPDF(feedback.fileName, feedback.download_url_m);
          this.isTourSelected = this.isTour(feedback.fileName);
          if (this.isTourSelected) {
            this.download_url = feedback.download_url;
          }
          this.feedbackComment = feedback.comment;
          if (Array.isArray(feedback.counterMarkerComments)) {
            this.counterMarkerComments = feedback.counterMarkerComments;
          } else {
            this.counterMarkerComments = [];
          }
          if (Array.isArray(feedback.adminCounterMarkerComments)) {
            this.adminCounterMarkerComments = feedback.adminCounterMarkerComments;
          } else {
            this.adminCounterMarkerComments = [];
          }
          CounterMarker.setCounter(this.counterMarkerComments.length);
          if (this.mode === "forwardFeedback") {
            this.feedbackCommentForServiceProvider = feedback.adminComment || "";
          }
        }, 100);
      }
      this.initialComment = this.feedbackComment;
      this.initialAdminComment = this.feedbackCommentForServiceProvider;

      this.mts.closeMarkerArea();
      if (!this.imageSelected) {
        this.showToolbar = false;
      } else {
        this.showToolbar = true;
      }
      this.comment_selector = "visible";
      this.comment_saved = "hidden";
    } else if (index === self.photoCount) {
      this.photoCounter = index;
      this.mts.closeMarkerArea();
      this.display_selector = "hidden";
      this.comment_selector = "hidden";
      this.comment_saved = "hidden";
      this.showCarousel = false;
      this.showToolbar = false;
      this.firstIndex = 0;
      this.lastIndex = this.tilesPerSlide;
    }
  }

  onClick(event: Event) {
    if (event.target["className"] && event.target["className"].indexOf("icon-next ") >= 0) {
      if (this.lastIndex < this.photoCount) {
        this.lastIndex += 1;
        this.firstIndex += 1;
      }
    }
    if (event.target["className"] && event.target["className"].indexOf("icon-prev ") >= 0) {
      if (this.firstIndex >= 1) {
        this.lastIndex -= 1;
        this.firstIndex -= 1;
      }
    }
  }

  closeUnsavedChangeModal() {
    this.modalService.getModal("unsavedChanges").close();
  }

  isImage(fileName: string): boolean {
    const imageExtns = ["jpeg", "jpg", "png"];
    return imageExtns.indexOf(fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase()) >= 0;
  }

  isTour(fileName: string): boolean {
    fileName = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
    return fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase() === MediaTypeEnum.tour;
  }

  async translateGenericComment() {
    this.commentForServiceProvider = await this.translate(this.generalComment);
  }

  async translateFileComment(feedbackComment: string) {
    this.feedbackCommentForServiceProvider = await this.translate(feedbackComment);
  }

  async translateCounterMarkerComment(index: number) {
    this.adminCounterMarkerComments[index] = await this.translate(this.counterMarkerComments[index]);
  }

  async translate(text: string) {
    let translatedText: string;
    try {
      translatedText = await this.ts.translate(text);
    } catch (error) {
      console.warn(this.logComponent + "Error found while translating text:", text, error);
    }

    return translatedText || "";
  }

  compareValues(key, order = "asc") {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  }

  urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((buf) => {
        const ua = window.navigator.userAgent;
        const edge = ua.indexOf("Edge/");
        if (edge === -1) {
          // If not Edge
          const file = new Blob([buf]);
          return new File([buf], filename, { type: mimeType });
        } else {
          // If Edge
          let file = new Blob([buf]);
          file = this.blobToFile(file, filename);
          return file;
        }
      });
  }

  // to change Blob to File such that our backend handles it correctly
  private blobToFile(theBlob: Blob, fileName: string): File {
    const b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return b as File;
  }

  trackByIdx(index: number, obj: any): any {
    return index;
  }
}
