export enum BillingAddressTypeEnum {
  CompanyAddress = "CompanyAddress",
  ProfileAddress = "ProfileAddress",
  IndividualAddress = "IndividualAddress",
}

export const billingAddressTypeList: { id: BillingAddressTypeEnum; name: string }[] = [
  {
    id: BillingAddressTypeEnum.CompanyAddress,
    name: "Unternehmensadresse",
  },
  {
    id: BillingAddressTypeEnum.ProfileAddress,
    name: "Profiladdresse",
  },
  {
    id: BillingAddressTypeEnum.IndividualAddress,
    name: "Individuelle Adresse",
  },
];
