import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AudioItem } from "@app/interfaces/audio-item.interface";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatRadioChange } from "@angular/material/radio";

@Component({
  selector: "app-imo-audio-player",
  templateUrl: "./imo-audio-player.component.html",
  styleUrls: ["./imo-audio-player.component.css"],
})
export class ImoAudioPlayerComponent implements OnInit {
  private audio = new Audio();
  titles: AudioItem[];
  @Input() audioItems: AudioItem[];
  @Input() disabled: boolean;
  @Output() selectedItemEmitter = new EventEmitter<AudioItem>();
  selectedItem: AudioItem;
  currentlyPlayingId: number;
  private lastTimestamp: number;
  private lastSongPlayed: number;
  audioItemGroups: AudioItem[][];

  constructor() {}

  ngOnInit() {
    this.initiateAudioItemGroups();
  }

  initiateAudioItemGroups() {
    this.audioItemGroups = [];
    this.audioItemGroups[0] = [];
    let itemGroup = 0;
    let groupSize = 3;
    for (let i = 0; i < this.audioItems.length; i++) {
      this.audioItemGroups[itemGroup].push(this.audioItems[i]);
      if (i % groupSize === groupSize - 1) {
        itemGroup++;
        this.audioItemGroups[itemGroup] = [];
      }
    }
  }

  play(id: number) {
    if (this.lastSongPlayed && this.lastSongPlayed === id && this.lastTimestamp) {
      this.audio.load();
      this.audio.currentTime = this.lastTimestamp;
      this.audio.play().then(() => {
        this.currentlyPlayingId = id;
      });
    } else {
      this.audio.src = this.audioItems[this.audioItems.findIndex((item) => item.id === id)].src;
      this.audio.play().then(() => {
        this.currentlyPlayingId = id;
      });
    }
  }

  pause(id: number) {
    this.audio.pause();
    this.lastTimestamp = this.audio.currentTime;
    this.lastSongPlayed = id;
    this.currentlyPlayingId = -1;
  }

  setTitle(audioItemIdx: MatRadioChange) {
    this.selectedItem = this.audioItems[audioItemIdx.value];
    this.selectedItemEmitter.emit(this.selectedItem);
  }
}
