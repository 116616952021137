<ng-container *ngIf="auth.myUserObservable as user">
  <div
    *ngIf="mode === 'details'"
    class="mt-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-bottom"
  >
    <span class="page-title"
      >Detailansicht: {{ curCompany && curCompany.name }}
      <span>
        <a
          target="_blank"
          class="text-primary"
          [href]="firebaseCompaniesURL + curCompany?.cid"
          (click)="$event.stopPropagation()"
          *ngIf="user.role === userRoleEnum.Administrator"
          ><i
            class="fas fa-fire text-warning clickable ml-2"
            [tooltip]="'In Firebase öffnen'"
            [adaptivePosition]="false"
          ></i>
        </a>
      </span>
    </span>

    <button (click)="showList()" *ngIf="user.role === userRoleEnum.Administrator" class="btn btn-back float-right">
      Zurück
    </button>
  </div>
  <div *ngIf="mode === 'details'" class="marginTop"></div>
  <div class="card fluid cgrid mb-3" *ngIf="user.role === userRoleEnum.Administrator || curCompany">
    <div class="card-header" *ngIf="mode === 'list'">
      <div class="card-title font-sz-20">
        <button (click)="expandCompanyForm(false)" *ngIf="expanded" class="imogentGreen" mat-mini-fab>
          <i aria-hidden="true" class="fa fa-angle-up"></i>
        </button>
        <button (click)="expandCompanyForm(true)" *ngIf="!expanded" class="imogentGreen" color="primary" mat-mini-fab>
          <i aria-hidden="true" class="fa fa-angle-down"></i>
        </button>
        Unternehmen anlegen
      </div>
    </div>
    <div class="col-12" *ngIf="mode === 'details'">
      <h3>Adresse</h3>
      <span>(Die Unternehmensadresse ist gleichzeitig Rechnungsadresse des Unternehmens)</span>
    </div>
    <div *ngIf="expanded" class="card-body">
      <form [formGroup]="companyDetailForm">
        <div class="card-title smallHeading">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <div class="input-group mb-md-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-details-name">Name</span>
                  </div>
                  <input
                    class="form-control"
                    formControlName="name"
                    (change)="onNameChange()"
                    placeholder=""
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group">
                <div class="input-group mb-md-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-details-shortName">Kurzer Name</span>
                  </div>
                  <input
                    class="form-control"
                    formControlName="shortName"
                    [placeholder]="shortNameSuggestion"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group">
                <div class="input-group mb-md-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-details-UST-ID">USt.-ID</span>
                  </div>
                  <input class="form-control" formControlName="ustid" placeholder="" type="text" />
                </div>
                <div *ngIf="ustid.invalid && (ustid.dirty || ustid.touched)" class="alert alert-danger col-12">
                  {{ CONSTANTS.VALIDATION_ERROR_MSG.TAXID_INVALID }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-8 col-md-3">
              <div class="form-group">
                <div class="input-group mb-md-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-details-street">Straße</span>
                  </div>
                  <input class="form-control" formControlName="street" placeholder="" type="text" />
                </div>
              </div>
            </div>
            <div class="col-4 col-md-2">
              <div class="form-group">
                <div class="input-group mb-md-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="iinputGroup-details-streetNumber">Nr.</span>
                  </div>
                  <input class="form-control" formControlName="streetNumber" placeholder="" type="text" />
                </div>
              </div>
            </div>

            <div class="col-4 col-md-2">
              <div class="form-group">
                <div class="input-group mb-md-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-details-postalcode">PLZ</span>
                  </div>
                  <input class="form-control" formControlName="postalcode" placeholder="" type="text" />
                </div>
              </div>
            </div>
            <div class="col-8 col-md-2">
              <div class="form-group">
                <div class="input-group mb-md-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-details-city">Ort</span>
                  </div>
                  <input class="form-control" formControlName="city" placeholder="" type="text" />
                </div>
              </div>
            </div>

            <div class="col-8 col-md-3">
              <div class="form-group">
                <div class="input-group mb-md-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-details-country">Land</span>
                  </div>
                  <div class="select-wrapper fa fa-angle-down">
                    <select
                      formControlName="country"
                      class="form-control no-background"
                      [compareWith]="globalService.compareString"
                      (change)="updateValidators()"
                    >
                      <option *ngFor="let country of countryCodeList" [value]="country.id">
                        {{ country.id }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-8 col-md-3">
              <div class="form-group">
                <div class="input-group mb-md-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-details-invoiceSupplement"
                      >Zusatz für Rechnungskopf</span
                    >
                  </div>
                  <input class="form-control" formControlName="supplement" placeholder="" type="text" />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3 col-sm-5" *ngIf="auth.myUserObservable.role === userRoleEnum.Administrator">
              <div class="form-group">
                <div class="input-group mb-md-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-details-sharedEstatesScope">Geteilte Immobilien</span>
                  </div>
                  <div class="select-wrapper fa fa-angle-down">
                    <select
                      formControlName="sharedEstatesScope"
                      class="form-control no-background"
                      [compareWith]="globalService.compareString"
                    >
                      <option *ngFor="let scope of sharedEstatesScopeEnum" [value]="scope">
                        {{ scope }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-12 col-md-5"
              *ngIf="auth.myUserObservable.role === userRoleEnum.Administrator"
              formArrayName="companyPaths"
            >
              <div
                @slide-x-right-transition
                *ngFor="let pathListControl of companyPaths['controls']; let pathIndex = index"
                [formGroupName]="pathIndex"
                class="form-group"
              >
                <div class="companypath-control-grid">
                  <mat-icon
                    class="text-danger fab-imogent grid-icon"
                    (click)="removeCompanyPath(pathIndex)"
                    *ngIf="companyPaths['controls'].length > 1"
                    >remove_circle
                  </mat-icon>
                  <span class="input-group-text grid-label">Company Paths</span>
                  <input
                    class="form-control grid-input input-group"
                    formControlName="name"
                    placeholder=""
                    type="text"
                  />
                  <button
                    *ngIf="companyPaths['controls'].length === pathIndex + 1"
                    (click)="addCompanyPath(true)"
                    class="grid-addButton btn btn-round btn-imogent-pill-add btn-block p-1 d-flex justify-content-center"
                  >
                    Company Path
                  </button>
                </div>
              </div>
            </div>

            <div class="row col-12 m-3">
              <button
                class="btn btn-primary"
                *ngIf="curCompany && auth.myUserObservable.role === userRoleEnum.Administrator"
                (click)="importUsersViaPath(curCompany.cid)"
              >
                Importiere Nutzer via path
              </button>
            </div>
          </div>
          <hr />

          <div
            class="row"
            *ngIf="
              auth.myUserObservable.role === userRoleEnum.Administrator ||
              auth.myUserObservable.companyRole === companyRoleEnum.CompanyManager
            "
          >
            <div class="col-12">
              <h3>Rechnungsstellung</h3>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-8 col-md-5">
                  <div class="form-group">
                    <div class="input-group mb-md-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-details-country">Standard Rechnungsnutzer</span>
                      </div>
                      <div class="select-wrapper fa fa-angle-down">
                        <select
                          formControlName="mainInvoiceRecipient"
                          class="form-control no-background"
                          [compareWith]="globalService.compareString"
                        >
                          <option *ngFor="let recipient of mainInvoiceRecipientOptions" [value]="recipient.uid">
                            {{ recipient.email }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-8 col-md-4">
                  <div class="form-group">
                    <div class="input-group mb-md-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-details-invoiceRecipientMail"
                          >Rechnungsempfänger</span
                        >
                      </div>
                      <input class="form-control" formControlName="invoiceRecipientMail" placeholder="" type="email" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" [hidden]="this.auth.myUserObservable.role !== this.userRoleEnum.Administrator">
              <div class="form-group">
                <div class="input-group mb-md-3">
                  <label class="ml-5">Sammelrechnung</label>
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <mat-radio-group formControlName="collectiveInvoiceOption">
                        <mat-radio-button class="ml-2" [value]="collectiveInvoiceIntervalEnum.Monthly"
                          >Monatlich
                        </mat-radio-button>
                        <mat-radio-button class="ml-2" [value]="collectiveInvoiceIntervalEnum.WeeklyCompany"
                          >2x wöchentlich (zentral für Unternehmen)
                        </mat-radio-button>
                        <mat-radio-button class="ml-2" [value]="collectiveInvoiceIntervalEnum.Weekly"
                          >2x wöchentlich
                        </mat-radio-button>
                        <mat-radio-button class="ml-2" [value]="collectiveInvoiceIntervalEnum.Individual"
                          >Individuell
                        </mat-radio-button>
                      </mat-radio-group>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="user.role === userRoleEnum.Administrator">
              <div class="col-12 input-group-text">
                <span class="ml-5 mr-2">Mit lexoffice verknüpft</span>
                <span *ngIf="curCompany?.lexOfficeContactId"><i class="text-primary fas fa-check"></i> </span>
                <span *ngIf="!curCompany?.lexOfficeContactId"><i class="text-danger fas fa-times"></i> </span>
                <span class="ml-3" *ngIf="curCompany?.lexOfficeContactId"
                  >Kundennummer: {{ curCompany.lexOfficeNumber }}</span
                >
              </div>
            </div>
            <div class="row" *ngIf="user.role === userRoleEnum.Administrator">
              <div class="col-12 input-group-text">
                <span class="ml-5 mr-2">Bestimmte Produkte von Rechnungsstellung ausschließen</span>
                <mat-select
                  formControlName="invoicingExcludedProducts"
                  multiple
                  class="form-control no-background col-3"
                  [compareWith]="globalService.compareString"
                  (selectionChange)="onInvoicingExcludedProductsChanged()"
                >
                  <mat-option *ngFor="let orderType of invoiceExcludedOrderTypeOptions" [value]="orderType">
                    {{ orderType }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </div>

          <hr />
          <div class="row">
            <div class="col-12">
              <h3>Logos</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <div class="input-group mb-md-3">
                  <div class="row col-12 input-group-prepend">
                    <span class="input-group-text">Firmenlogo</span>
                  </div>
                  <div class="row col-12 input-group-prepend">
                    <span class="input-group-text">
                      <mat-checkbox color="primary" formControlName="isLogoMain"></mat-checkbox>
                      <label class="ml-2"
                        >Unternehmenslogo verpflichtend anstelle des Logos im Nutzerprofil verwenden</label
                      >
                    </span>
                  </div>
                  <div class="mb-4 form-group row uploadArea">
                    <div *ngIf="!curCompany?.logo?.file" class="col-12 buttonUpload">
                      <div class="col-12 upload-button-container">
                        <label class="col-12 row fileContainer text-center align-items-center">
                          <i class="material-icons col-12">cloud_upload</i>
                          <div class="col-12">
                            <span class="btn btn-dark btn-sm clickable">Datei auswählen</span>
                          </div>
                          <input
                            #uploadFileElem
                            (change)="onChange()"
                            [uploader]="uploader"
                            [writeFile]="true"
                            accept=".png,.jpeg,.jpg"
                            formControlName="uploadedFiles"
                            ng2FileSelect
                            type="file"
                          />
                        </label>
                      </div>
                      <div class="col-12 file-list">
                        <div *ngFor="let file of uploader.queue; let index = index" class="file-entry row">
                          <div (click)="openDialog(index)" class="col-8 file-name clickable">
                            {{ file?.file?.name }}
                          </div>
                          <div class="col-4 text-right">
                            <span (click)="onRemoveFile(file)" class="fas fa-trash-alt text-danger file-remove"></span>
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="uploadedFiles.invalid && (uploadedFiles.dirty || uploadedFiles.touched)"
                        class="alert alert-danger col-12"
                      >
                        <div *ngIf="uploadedFiles.errors.forbiddenFile">
                          Bitte laden Sie ein gültiges Dateiformat hoch
                        </div>
                      </div>
                    </div>
                    <div *ngIf="curCompany?.logo?.file" class="row col-12 align-bottom align-text-bottom pd-2">
                      <img
                        src="{{ curCompany.logo.file.download_url }}"
                        class="img-fluid col-12 col-md-4 col-lg-3 pt-3 pb-3"
                        alt="Company Logo"
                      />
                      <div class="col-12 logo-delete-icon">
                        <i (click)="deleteFile($event)" class="fas fa-trash align-bottom clickable"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- company authority / Vollmacht -->
          <!-- TODO: implement upload feature -->
          <ng-container *ngIf="curCompany?.authority">
            <hr />
            <div class="row">
              <div class="col-12 align-items-center">
                <h3>Vollmacht</h3>

                <a
                  [href]="curCompany.authority.download_url"
                  rel="noopener"
                  target="_blank"
                  class="text-gray btn btn-white w-100 row border-dark rounded-lg d-flex"
                >
                  <i class="col-3 material-icons download-icon m-0">cloud_download</i>
                  <h5 class="col-9 text-left m-0">
                    <strong>{{ curCompany.authority.file_name }}</strong>
                  </h5>
                </a>
              </div>
            </div>
            <hr />
          </ng-container>

          <ng-container *ngIf="user.role === userRoleEnum.Administrator">
            <hr />
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <b>Lexoffice Integration</b>
              </mat-expansion-panel-header>
              <div class="form-group col-12 col-md-6">
                <div class="input-group mb-md-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Kundennummer</span>
                  </div>
                  <input
                    class="form-control"
                    min="10000"
                    placeholder=""
                    type="number"
                    [disabled]="lexContact"
                    #lexOfficeContactNumber
                  />
                  <button (click)="searchLexOfficeContact()" class="btn btn-primary btn-sm" [disabled]="lexContact">
                    Suchen
                  </button>
                  <div *ngIf="isLexOfficeLoading" class="col-12 justify-content-center text-center">
                    <mat-spinner [color]="'primary'" [diameter]="30"></mat-spinner>
                  </div>
                </div>
              </div>
              <div class="col-12" *ngIf="!lexContact && showNoLexContactFoundError">
                <span class="text-danger">Kontakt konnte nicht gefunden werden.</span>
              </div>
              <div class="col-12 col-md-6 col-xl-3" *ngIf="lexContact">
                <p><b>Name:</b> {{ lexContact.companyName }}</p>
                <p><b>Straße:</b> {{ lexContact.billingAddress.street }}</p>
                <p><b>PLZ, Ort:</b> {{ lexContact.billingAddress.zip }}, {{ lexContact.billingAddress.city }}</p>
                <br />
                <p><b>Land:</b> {{ lexContact.billingAddress.countryCode }}</p>
                <p><b>contactId:</b> {{ lexContact.contactId }}</p>

                <button (click)="resetLexSearch()" class="btn btn-danger btn-sm">Zurücksetzen</button>
                <button (click)="saveLexOfficeContact()" class="btn btn-primary btn-sm">Verknüpfen</button>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <b>Rabatteinstellungen</b>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="form-group col-12 col-lg-6">
                  <div class="input-group mb-md-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="inputGroup-sizing-default">Gesamtvolumen</span>
                    </div>
                    <input class="form-control" formControlName="totalVolume" min="0" placeholder="" type="number" />
                  </div>
                </div>

                <div class="input-group mb-md-3 col-12 col-lg-6">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-default">Subventioniertes Volumen</span>
                  </div>
                  <input
                    class="form-control"
                    readonly
                    type="number"
                    value="{{ curCompany && curCompany.subsidisedVolume }}"
                  />
                </div>
              </div>
              <div
                class="row"
                *ngIf="curCompany?.availableFreePriceHubbleOrders || user.role === userRoleEnum.Administrator"
              >
                <div class="input-group mb-md-3 col-12 col-lg-6">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Zur Verfügung stehende Price-Hubble Dossiers</span>
                  </div>
                  <input class="form-control" type="number" formControlName="availableFreePriceHubbleOrders" />
                </div>
                <div class="input-group mb-md-3 col-12 col-lg-6">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Erstellte (kostenlose) Price-Hubble Dossiers</span>
                  </div>
                  <input class="form-control" readonly type="number" formControlName="redeemedPriceHubbleOrders" />
                </div>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <b>Automatisches Mapping per E-Mail Suffix</b>
              </mat-expansion-panel-header>
              <div class="row">
                <hr />
                <div class="col-12">
                  <div class="form-group">
                    <div class="input-group mb-md-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <span class="font-small-400">
                            Nutzer, die sich mit einem der E-Mail Suffixe registrieren, werden automatisch dem
                            Unternehmen zugeordnet.</span
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <div class="input-group mb-md-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"> E-Mail Suffix </span>
                      </div>
                      <input class="form-control" formControlName="addEmailSuffix" placeholder="" type="email" />
                      <button (click)="saveEmailSuffix()" class="btn btn-primary btn-sm">
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                    <div class="font-size-normal ml-3">
                      <strong>Achtung: </strong> Suffixe müssen über alle Unternehmen hinweg eindeutig sein!
                    </div>
                  </div>
                </div>
                <!--emailSuffixes-->
                <div class="col-12 col-md-3">
                  <span class="font-small-400 ml-4">Existierende E-Mail Suffixe</span>
                  <div class="form-group suffixContainer">
                    <div class="input-group mb-md-3">
                      <div class="input-group-prepend col-12">
                        <mat-selection-list
                          [(ngModel)]="selectedSuffixes"
                          class="col-12"
                          formControlName="selectedSuffixesCtrl"
                        >
                          <mat-list-option *ngFor="let suffix of curEmailSuffixes" [value]="suffix">
                            {{ suffix }}
                          </mat-list-option>
                        </mat-selection-list>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <button
                    (click)="removeSelectedSuffixes()"
                    [disabled]="!selectedSuffixes || !selectedSuffixes.length"
                    class="btn btn-danger btn-sm"
                  >
                    Entfernen
                  </button>
                </div>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <b>Integrationen</b>
              </mat-expansion-panel-header>

              <div formArrayName="integrations">
                <div formGroupName="onOfficeIntegration">
                  <div class="row">
                    <div class="col-8 col-md-4">
                      <mat-checkbox formControlName="onOfficeEnabled" color="primary"></mat-checkbox>
                      onOffice
                    </div>
                  </div>

                  <div [hidden]="!onOfficeEnabled.value">
                    <div class="row">
                      <div class="col-8 col-md-4">
                        <div class="form-group">
                          <div class="input-group mb-md-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="inputGroup-scope">Scope</span>
                            </div>
                            <mat-form-field class="pl-3">
                              <mat-select formControlName="scope" multiple>
                                <mat-option *ngFor="let key of scopeKeys" [value]="key">{{
                                  ScopeEnum[key]
                                }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-8 col-md-4">
                        <div class="form-group">
                          <div class="input-group mb-md-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="inputGroup-enabledServices">enabledServices</span>
                            </div>
                            <mat-form-field class="pl-3">
                              <mat-select formControlName="enabledServices" multiple>
                                <mat-option *ngFor="let key of enabledServicesKeys" [value]="orderTypeEnum[key]">
                                  {{ orderTypeEnum[key] }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row" formGroupName="accessData">
                      <div class="col-12">
                        <div class="input-group-prepend">
                          <span class="input-group-text">AccessData<span class="text-danger">*</span></span>
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="form-group">
                          <div class="input-group mb-md-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text">accessToken</span>
                            </div>
                            <input class="form-control" formControlName="accessToken" placeholder="" type="text" />
                          </div>
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="form-group">
                          <div class="input-group mb-md-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text">secret</span>
                            </div>
                            <input class="form-control" formControlName="secret" placeholder="" type="password" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Sonstiges</span>
                        </div>
                      </div>
                      <div class="col-12 ml-3">
                        <mat-checkbox formControlName="autoForwardRetouching" color="primary"></mat-checkbox>
                        autoForwardRetouching
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
            </mat-expansion-panel>
          </ng-container>
        </div>
        <hr />
        <button
          (click)="ngxSmartModalService.open('confirmCompanyRemovalModal')"
          *ngIf="mode === 'details' && user.role === userRoleEnum.Administrator"
          class="btn btn-danger"
        >
          Löschen
        </button>

        <button
          (click)="submitHandler()"
          *ngIf="user.role === userRoleEnum.Administrator || user.companyRole === companyRoleEnum.CompanyManager"
          [disabled]="!companyDetailForm.dirty || !companyDetailForm.valid"
          class="btn btn-primary"
        >
          <span *ngIf="mode === 'list'">Neues Unternehmen erstellen</span>
          <span *ngIf="mode === 'details'">Speichern</span>
        </button>
      </form>
      <ng-container *ngIf="user.role === userRoleEnum.Administrator">
        <hr />
        <div class="row">
          <div class="col-12">
            <h3>Webhooks</h3>
          </div>
          <form [formGroup]="webhooksForm">
            <div class="col-12" formArrayName="webhooks">
              <!-- Webhook Array -->
              <div
                [formGroupName]="webhookIdx"
                class="form-group col-12"
                *ngFor="let webhook of webhooks.controls; let webhookIdx = index"
              >
                <!-- Webhook Event -->
                <div class="input-group mb-md-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Event</span>
                  </div>
                  <div class="select-wrapper fa fa-angle-down">
                    <select
                      formControlName="eventType"
                      class="form-control no-background"
                      [compareWith]="globalService.compareString"
                    >
                      <option *ngFor="let eventType of webhookEventSelectionOptions" [value]="eventType">
                        {{ eventType }}
                      </option>
                    </select>
                  </div>

                  <!-- Callback Address -->
                  <div class="input-group-prepend">
                    <span class="input-group-text">Callback Address</span>
                  </div>
                  <input class="form-control" placeholder="" type="text" formControlName="callbackURL" />
                </div>

                <!-- Authentication Type -->
                <div class="input-group mb-md-6">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Authentication Type</span>
                  </div>
                  <div class="select-wrapper fa fa-angle-down">
                    <select
                      formControlName="authenticationType"
                      class="form-control no-background"
                      [compareWith]="globalService.compareString"
                    >
                      <option
                        *ngFor="let authenticationType of webhookAuthenticationTypeSelectionOptions"
                        [value]="authenticationType"
                      >
                        {{ authenticationType }}
                      </option>
                    </select>
                  </div>

                  <!-- Authentication Secret -->
                  <ng-container
                    *ngIf="webhooks.value[webhookIdx]?.['authenticationType'] !== webhookAuthenticationTypeEnum.None"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text">Authentication Secret</span>
                    </div>
                    <input class="form-control" placeholder="" type="text" formControlName="authenticationSecret" />
                  </ng-container>
                </div>
                <hr />
              </div>
              <i (click)="addWebhook()" class="fas fa-plus-circle text-success fa-lg changeNumber"></i>
              <!--<button class="btn btn-primary" type="button" >Hinzufügen</button>-->
              <button class="btn btn-primary" (click)="createNewEventSubscription()">Webhook speichern</button>
            </div>
          </form>
        </div>
      </ng-container>
    </div>
    <div
      *ngIf="
        mode === 'details' &&
        (user.role === userRoleEnum.Administrator || user.companyRole === companyRoleEnum.CompanyManager)
      "
      class="col-12"
    >
      <div *ngIf="user.role === userRoleEnum.Administrator">
        <hr />
        <h3>Benutzerverwaltung</h3>
        Hier können Sie Nutzer zum aktuellen Unternehmen hinzufügen:

        <!-- only platform admins can use the add email to company form -->
        <div class="col-12">
          <div [formGroup]="emailForm" class="row mt-3 mb-3 align-items-center">
            <mat-form-field class="col-12 col-md-4 col-lg-3">
              <mat-label>E-Mail</mat-label>
              <input
                formControlName="emailInput"
                matInput
                placeholder="E-Mail des Nutzers der zum Unternehmen hinzugefügt werden soll."
                type="email"
              />
              <mat-error *ngIf="emailInput.dirty && emailInput.invalid">
                <div *ngIf="emailInput.errors?.required || emailInput.errors?.pattern">
                  {{ CONSTANTS.VALIDATION_ERROR_MSG.EMAIL_INVALID }}
                </div>
                <div *ngIf="emailInput.errors?.invalidEmail">
                  {{ emailInput.errors.invalidEmail }}
                </div>
              </mat-error>
            </mat-form-field>
            <div>
              <i *ngIf="emailInput.pending" class="fa-2x fas fa-spinner fa-pulse text-primary ml-1 mr-1"></i>
            </div>
            <button
              (click)="addToCompany()"
              [disabled]="emailForm.invalid"
              class="btn"
              [ngClass]="{
                'btn-primary': emailForm.valid,
                'btn-secondary': !emailForm.valid
              }"
              mat-flat-button
            >
              Hinzufügen
            </button>
          </div>
        </div>
      </div>
      <div class="margintop">
        <hr />
        <h3>Benutzerverwaltung</h3>
        <div>
          Hier können Sie verwalten, welche Nutzer Administrationsrechte für Ihr Unternehmen besitzen und Nutzer aus
          Ihrem Unternehmen entfernen:
        </div>
        <table
          #userTable="matSort"
          [dataSource]="userDataSrc"
          class="marginBottom"
          mat-table
          matSort
          matSortActive="lastName"
          matSortDirection="asc"
        >
          <!-- ID Column -->
          <ng-container matColumnDef="firstName">
            <th
              *matHeaderCellDef
              mat-header-cell
              mat-sort-header
              width="{{ 15 * (6 / columnsToDisplayUserTable.length) }}%"
            >
              <div class="row">
                <div class="col-12">Vorname</div>
                <div class="col-12">
                  <input
                    (click)="noSort($event)"
                    (input)="filterUserTable($event, 'firstName')"
                    class="form-control"
                    placeholder="Suchen"
                    type="text"
                  />
                </div>
              </div>
            </th>
            <td *matCellDef="let row" mat-cell width="{{ 15 * (6 / columnsToDisplayUserTable.length) }}%">
              {{ row.firstName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="lastName">
            <th
              *matHeaderCellDef
              mat-header-cell
              mat-sort-header
              width="{{ 15 * (6 / columnsToDisplayUserTable.length) }}%"
            >
              <div class="row">
                <div class="col-12">Nachname</div>
                <div class="col-12">
                  <input
                    (click)="noSort($event)"
                    (input)="filterUserTable($event, 'lastName')"
                    class="form-control"
                    placeholder="Suchen"
                    type="text"
                  />
                </div>
              </div>
            </th>
            <td *matCellDef="let row" mat-cell width="{{ 15 * (6 / columnsToDisplayUserTable.length) }}%">
              {{ row.lastName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th
              *matHeaderCellDef
              mat-header-cell
              mat-sort-header
              width="{{ 25 * (6 / columnsToDisplayUserTable.length) }}%"
            >
              <div class="row">
                <div class="col-12">E-Mail</div>
                <div class="col-12">
                  <input
                    (click)="noSort($event)"
                    (input)="filterUserTable($event, 'email')"
                    class="form-control"
                    placeholder="Suchen"
                    type="text"
                  />
                </div>
              </div>
            </th>
            <td *matCellDef="let row" mat-cell width="{{ 25 * (6 / columnsToDisplayUserTable.length) }}%">
              {{ row.email }}
            </td>
          </ng-container>

          <ng-container matColumnDef="remove">
            <th *matHeaderCellDef mat-header-cell width="{{ 15 * (6 / columnsToDisplayUserTable.length) }}%">
              <div class="row">
                <div class="col-12">Aktion</div>
              </div>
            </th>
            <td *matCellDef="let row" mat-cell width="{{ 15 * (6 / columnsToDisplayUserTable.length) }}%">
              <button (click)="removeUserFromCompany(row)" color="warn" mat-flat-button>Entfernen</button>
            </td>
          </ng-container>

          <ng-container matColumnDef="admin">
            <th *matHeaderCellDef mat-header-cell width="{{ 20 * (6 / columnsToDisplayUserTable.length) }}%">
              <div class="row">
                <div class="col-12">
                  Rolle
                  <i
                    class="fas fa-question-circle text-primary clickable"
                    matTooltip="&#8226; Manager: Sieht alle Aufträge, Immobilien und Rechnungen des Unternehmens und kann diese bearbeiten. &#013; &#8226; Buchhalter: Sieht alle Aufträge, Immobilien und Rechnungen des Unternehmens, kann diese aber nicht bearbeiten. &#013; &#8226; Mitarbeiter: Sieht nur seine eigenen Aufträge, Immobilien und Rechnungen."
                    matTooltipPosition="above"
                  ></i>
                </div>
              </div>
            </th>
            <td *matCellDef="let row" mat-cell width="{{ 20 * (6 / columnsToDisplayUserTable.length) }}%">
              <mat-form-field *ngIf="!row.isPrivateIndividual">
                <mat-select (selectionChange)="onCompanyRoleChange(row, $event.value)" [value]="row.companyRole">
                  <mat-option *ngFor="let companyRole of companyRoleList" [value]="companyRole.id">
                    {{ companyRole.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="companyRoleHistory">
            <th *matHeaderCellDef mat-header-cell width="{{ 10 * (6 / columnsToDisplayUserTable.length) }}%">
              <div class="row">
                <div class="col-12">
                  History
                  <i
                    class="fas fa-question-circle text-primary clickable"
                    matTooltip="View role change history for the user"
                    matTooltipPosition="above"
                  ></i>
                </div>
              </div>
            </th>
            <td
              *matCellDef="let row"
              mat-cell
              width="{{ 15 * (6 / columnsToDisplayUserTable.length) }}%"
              class="text-center"
            >
              <i class="fas fa-history text-info clickable" (click)="onShowCompanyRoleChangeHistory(row)"></i>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="columnsToDisplayUserTable" mat-header-row></tr>
          <tr *matRowDef="let row; columns: columnsToDisplayUserTable" mat-row></tr>
        </table>
      </div>
    </div>
  </div>

  <ng-container *ngIf="user.role === 'Kunde' && !user.company">
    <div class="shadow card fluid cgrid">
      <div class="card-header">
        <h5>Details</h5>
      </div>
      <div class="card-body">
        <div>
          Wenn Sie sich für die Unternehmensverwaltung interessieren,
          <a href="https://www.imogent.de/kontakt/" rel="noopener noreferrer" target="_blank">kontaktieren Sie uns</a>.
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="user.role === 'Dienstleister'">
    <!-- If User == Company-Admin: Kunde can change Company Details and invite / add users to the company and remove
    users from the company -->

    <!-- If User != Company-Admin: User can see company information-->
  </ng-container>

  <ng-container *ngIf="mode === 'list' && user.role === userRoleEnum.Administrator">
    <!-- Imogent Admin can see a table of companies -->
    <!-- Show User list in a card -->
    <div class="mt-3 card cgrid">
      <div class="card-body">
        <div class="mat-elevation-z8 material-table">
          <table [dataSource]="companyDataSrc" mat-table matSort (matSortChange)="loadCompanies()">
            <!-- ID Column -->
            <ng-container matColumnDef="name">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="20%">
                <div class="row">
                  <div class="col-12">Name</div>
                  <div class="col-12">
                    <input
                      (click)="noSort($event)"
                      (input)="updateFilterValues($event, 'name')"
                      class="form-control"
                      (keydown.space)="addNoSortWhitespace($event)"
                      placeholder="Suchen"
                      type="text"
                    />
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="20%">{{ row.name }}</td>
            </ng-container>

            <ng-container matColumnDef="supplement">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="17%">
                <div class="row">
                  <div class="col-12">Zusatz für Rechnungskopf</div>
                  <div class="col-12">
                    <input
                      (click)="noSort($event)"
                      (input)="updateFilterValues($event, 'supplement')"
                      class="form-control"
                      (keydown.space)="addNoSortWhitespace($event)"
                      placeholder="Suchen"
                      type="text"
                    />
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="17%">{{ row.supplement }}</td>
            </ng-container>

            <ng-container matColumnDef="street">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="15%">
                <div class="row">
                  <div class="col-12">Straße</div>
                  <div class="col-12">
                    <input
                      (click)="noSort($event)"
                      (input)="updateFilterValues($event, 'street')"
                      (keydown.space)="addNoSortWhitespace($event)"
                      class="form-control"
                      placeholder="Suchen"
                      type="text"
                    />
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="15%">{{ row.street + " " + row.streetNumber }}</td>
            </ng-container>

            <ng-container matColumnDef="lexOfficeNumber">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="10%">
                <div class="row">
                  <div class="col-12">FirmenNr.</div>
                  <div class="col-12">
                    <input
                      (click)="noSort($event)"
                      (input)="updateFilterValues($event, 'lexOfficeNumber')"
                      (keydown.space)="addNoSortWhitespace($event)"
                      class="form-control"
                      placeholder="Suchen"
                      type="text"
                    />
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="10%">{{ row.lexOfficeNumber }}</td>
            </ng-container>

            <ng-container matColumnDef="collectiveInvoiceOption">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="13%">
                <div class="row">
                  <div class="col-12">Sammelrechnung</div>
                  <div class="col-12">
                    <mat-form-field>
                      <mat-select
                        (click)="noSort($event)"
                        (selectionChange)="updateFilterValues($event, 'collectiveInvoiceOption')"
                      >
                        <mat-option [value]="">zurücksetzen</mat-option>
                        <mat-option *ngFor="let entry of collectiveInvoiceOptionList" [value]="entry.id">
                          {{ entry.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="13%">
                {{ collectiveInvoiceMap.get(row.collectiveInvoiceOption) }}
              </td>
            </ng-container>

            <ng-container matColumnDef="postalcode">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="8%">
                <div class="row">
                  <div class="col-12">PLZ</div>
                  <div class="col-12">
                    <input
                      (click)="noSort($event)"
                      (input)="updateFilterValues($event, 'postalcode')"
                      (keydown.space)="addNoSortWhitespace($event)"
                      class="form-control"
                      placeholder="Suchen"
                      type="text"
                    />
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="8%">{{ row.postalcode }}</td>
            </ng-container>

            <ng-container matColumnDef="city">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="10%">
                <div class="row">
                  <div class="col-12">Stadt</div>
                  <div class="col-12">
                    <input
                      (click)="noSort($event)"
                      (input)="updateFilterValues($event, 'city')"
                      (keydown.space)="addNoSortWhitespace($event)"
                      class="form-control"
                      placeholder="Suchen"
                      type="text"
                    />
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="10%">{{ row.city }}</td>
            </ng-container>

            <ng-container matColumnDef="country">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="7%">
                <div class="row">
                  <div class="col-12">Land</div>
                  <div class="col-12">
                    <input
                      (click)="noSort($event)"
                      (input)="updateFilterValues($event, 'country')"
                      class="form-control"
                      (keydown.space)="addNoSortWhitespace($event)"
                      placeholder="Suchen"
                      type="text"
                    />
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="7%">{{ row.country | CountryCode }}</td>
            </ng-container>

            <ng-container matColumnDef="userCount">
              <th *matHeaderCellDef mat-header-cell mat-sort-header width="15%">
                <div class="row">
                  <div class="col-12">Anzahl Nutzer</div>
                  <div class="col-12">
                    <input class="form-control" placeholder="Suchen" type="text" />
                  </div>
                </div>
              </th>
              <td *matCellDef="let row" mat-cell width="10%">0</td>
            </ng-container>

            <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
            <tr (click)="showCompanyDetails(row.cid)" *matRowDef="let row; columns: columnsToDisplay" mat-row></tr>
          </table>
          <mat-paginator
            #paginator
            [pageSizeOptions]="[10, 20, 50]"
            [length]="companyCount"
            (page)="loadCompanies()"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ngx-smart-modal
  #confirmCompanyRemovalModal
  *ngIf="curCompany"
  [closable]="true"
  [dismissable]="true"
  [escapable]="true"
  identifier="confirmCompanyRemovalModal"
>
  <!-- Modal Title -->
  <h2 class="card-title b">Löschen bestätigen</h2>
  <div>
    Bitte bestätigen Sie, dass das Unternehmen {{ curCompany.name }} (id: {{ curCompany.cid }}) gelöscht werden soll.
  </div>
  <div>
    Hierdurch wird auch für alle Nutzer, die dem Unternehmen zugeordnet sind, das Unternehmen zu 'undefined' geändert!
  </div>
  <div>
    <button (click)="mode = 'list'; removeCompany(curCompany.cid)" class="btn btn-danger col-12 buttonSpacing">
      Löschen
    </button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #fileUploadErrorModal
  [closable]="true"
  [dismissable]="true"
  [escapable]="true"
  identifier="fileUploadErrorModal"
>
  <!-- Modal Title -->
  <h3 class="card-title b">Fehler beim Hinzufügen der Dateien</h3>
  <hr />
  <div *ngIf="fileUploadErrorModal.hasData()">
    Folgende Dateien können nicht hinzugefügt werden, da bereits Dateien mit dem gleichen Namen ausgewählt wurden:
    <ul>
      <li *ngFor="let file of fileUploadErrorModal.getData()">{{ file.file.name }}</li>
    </ul>
  </div>

  <strong>Bitte verwenden Sie für die Dateien eindeutige Dateinamen.</strong>
</ngx-smart-modal>
