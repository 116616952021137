import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { RealEstate } from "@app/interfaces/real-estate.interface";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RealestateService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.REAL_ESTATES;

  constructor(private http: HttpClient) {}

  getRealEstate(id: string): Observable<RealEstate> {
    return this.http.get<RealEstate>(this.endpoint + "/" + (id || ""));
  }

  getRealEstates(path?: string, data?) {
    return this.http.get<QueryResult<RealEstate>>(this.endpoint + "/" + (path || ""), { params: data });
  }

  deleteRealestate(realestate: RealEstate) {
    return this.http.delete<string>(this.endpoint + "/" + realestate.id).toPromise();
  }

  postRealEstate(data) {
    return this.http.post<RealEstate>(this.endpoint, data);
  }

  updatePlanetHomeBrokerDetails() {
    return this.http.put<number>(this.endpoint + "/update-planethome-broker-details", undefined);
  }

  /**
   * patch real estate. Either a single real estate or a patch-script
   * @param path usually the real estate id. Can also be path to a patch-script.
   * @param data Real Estate data.
   */
  patchRealEstate(path: string, data?: RealEstate) {
    return this.http.patch<RealEstate>(this.endpoint + "/" + path, data);
  }

  async changeStatus(realEstate: RealEstate, floorIndex?: number) {
    return this.http
      .put<string>(this.endpoint + "/change-status/" + realEstate.id, {
        realEstate: realEstate,
        floorIndex: floorIndex,
      })
      .subscribe(
        (val) => {
          console.log("PUT call successful value returned in body", val);
          console.log(val);
        },
        (response) => {
          console.log("PUT call in error", response);
        },
        () => {
          console.log("The PUT observable is now completed.");
        }
      );
  }
}
