<div [formGroup]="preferredDatesForm">
  <div class="row pr-2">
    <div class="col-12">
      <!-- Präferenztermin -->
      <div class="row">
        <div class="col-6">
          <div class="form-group imo-date-picker">
            <label class="font-weight-bold" required-asterisk>Präferenztermin</label>
            <span *ngIf="preferredDate1.invalid && preferredDate1.touched" class="text-danger ml-3 input-error"
              >(Pflichtfeld)
            </span>
            <input
              [matDatepicker]="picker1"
              [min]="nextAppointmentDate"
              (dateChange)="onDateChanged($event, true)"
              class="form-control"
              formControlName="preferredDate1"
            />
            <mat-datepicker-toggle [for]="picker1" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label class="font-weight-bold" required-asterisk>von</label>
            <span *ngIf="preferredTimeFrom1.invalid && preferredTimeFrom1.touched" class="text-danger ml-3 input-error"
              >(Pflichtfeld)
            </span>
            <mat-select
              (openedChange)="onOpenSelect($event, 'preferredTimeFrom1')"
              (selectionChange)="onBeginTimeChanged($event, true)"
              formControlName="preferredTimeFrom1"
            >
              <mat-option *ngFor="let time of timeList" [value]="time" id="{{ 'preferredTimeFrom1-' + time }}">
                {{ time }}
              </mat-option>
            </mat-select>
            <span class="text-danger ml-3 input-error" *ngIf="timeList.length === 0"
              >Kein Zeitraum verfügbar. Bitte anderes Datum wählen.</span
            >
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label class="font-weight-bold" required-asterisk>bis</label>
            <span *ngIf="preferredTimeTo1.invalid && preferredTimeTo1.touched" class="text-danger ml-3 input-error"
              >(Pflichtfeld)
            </span>
            <mat-select (openedChange)="onOpenSelect($event, 'preferredTimeTo1')" formControlName="preferredTimeTo1">
              <mat-option *ngFor="let time of timeListEnd" [value]="time" id="{{ 'preferredTimeTo1-' + time }}">
                {{ time }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>
      <!-- Präferenztermin 2 -->
      <div class="row">
        <div class="col-6">
          <div class="form-group imo-date-picker">
            <label class="font-weight-bold" required-asterisk>Alternativtermin</label>
            <span *ngIf="preferredDate2.invalid && preferredDate2.touched" class="text-danger ml-3 input-error"
              >(Pflichtfeld)
            </span>
            <input
              [matDatepicker]="picker2"
              [min]="nextAppointmentDate"
              (dateChange)="onDateChanged($event, false)"
              class="form-control"
              formControlName="preferredDate2"
            />
            <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label class="font-weight-bold" required-asterisk>von</label>
            <span *ngIf="preferredTimeFrom2.invalid && preferredTimeFrom2.touched" class="text-danger ml-3 input-error"
              >(Pflichtfeld)
            </span>
            <mat-select
              (openedChange)="onOpenSelect($event, 'preferredTimeFrom2')"
              formControlName="preferredTimeFrom2"
            >
              <mat-option *ngFor="let time of timeListAlt" [value]="time" id="{{ 'preferredTimeFrom2-' + time }}">
                {{ time }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label class="font-weight-bold" required-asterisk>bis</label>
            <span *ngIf="preferredTimeTo2.invalid && preferredTimeTo2.touched" class="text-danger ml-3 input-error"
              >(Pflichtfeld)
            </span>
            <mat-select (openedChange)="onOpenSelect($event, 'preferredTimeTo2')" formControlName="preferredTimeTo2">
              <mat-option *ngFor="let time of timeListAltEnd" [value]="time" id="{{ 'preferredTimeTo2-' + time }}">
                {{ time }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>
      <div *ngIf="!disableSetDatesLater" class="setDatesLaterContainer">
        <div class="pt-1">
          <mat-checkbox
            #setDatesLaterCheck
            (change)="onSetDatesLater($event)"
            color="primary"
            formControlName="setDatesLater"
          >
            <span class="check-label"
              >Der Termin ist flexibel und soll mit der Kontaktperson telefonisch vereinbart werden.</span
            >
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>
