<!-- Fixed Plugin configurator, used just for Demo Purpose -->
<div class="fixed-plugin">
    <div class="dropdown show-dropdown">
        <a href="#" data-toggle="dropdown" aria-expanded="true">
            <i class="cart-icon material-icons">shopping_cart</i>
            <span [hidden]="cartItems.length < 1" class="item-count">{{cartItems.length}}</span>
        </a>
        <div class="dropdown-menu text-center">
            <div class="cards pt-3 pb-3">
                <h4 class="card-title">
                    Warenkorb
                </h4>
                <a href="javascript:void(0)" class="switch-trigger">
                    <div class="mt-3 mb-4 cart-container" *ngIf="cartItems && cartItems.length > 0; else noItems">
                        <div class="text-truncate row align-items-center cart-item text-left">
                            <div class="col-3">
                                <strong>Artikel</strong>
                            </div>
                            <div class="col-9">
                                <strong>Beschreibung</strong>
                            </div>
                        </div>
                        <div *ngFor="let item of cartItems; let index = index"
                            class="text-truncate row align-items-center text-left cart-item">
                            <div class="col-3">
                                <img *ngIf="item.type === cartItemTypeEnum.Service" class="cart-item-thumbnail" src="../../../assets/images/{{$any(item.data).imageUrl}}"
                                    alt="Card image cap">
                                <i *ngIf="item.type === cartItemTypeEnum.Product_Bundle" class="material-icons text-dark cart-item-thumbnail">widgets</i>
                            </div>
                            <div class="col-7">
                                {{item.data.title}}
                            </div>
                            <div class="col-2">
                                <i class="material-icons text-danger remove-item-icon"
                                    (click)="parent.removeItemFromCart(item, index)">delete</i>
                            </div>
                        </div>
                    </div>
                </a>
                <ng-template #noItems>
                    <p class="pt-3 text-default text-center">
                        Ihr Warenkorb ist leer.
                    </p>
                </ng-template>
                <button *ngIf="cartItems && cartItems.length > 0" class="btn btn-sm btn-danger"
                    (click)="parent.removeAllItemsFromCart()">
                    Warenkorb leeren
                </button>
                <button *ngIf="cartItems && cartItems.length > 0" class="btn btn-sm btn-primary"
                    (click)="parent.onCheckout()">
                    Weiter
                </button>
            </div>
        </div>
    </div>
</div>
