import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FileDetails } from "@app/interfaces/file-details-data.interface";
import { FileUploadEntry } from "@app/interfaces/file-upload-entry.interface";
import { FileUploadListItem } from "@app/interfaces/file-upload-list-item.interface";
import { FileUploadListTargetEnum } from "@app/models/file-upload-list-target.enum";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { UploadErrorTypeEnum } from "@app/models/upload-error-type.enum";
import { CONSTANTS } from "@app/util/constants";
import { OrdersLogicService } from "@app/_services/orders-logic.service";
import { UploadService } from "@app/_services/upload.service";
import { FileItem, FileLikeObject, FileUploader, ParsedResponseHeaders } from "ng2-file-upload";
import { BehaviorSubject } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { FileContentEnum } from "@app/models/file-content.enum";
import { NotificationsService } from "@app/_services/notifications.service";
import { BytePipe } from "../byte.pipe";

@Component({
  selector: "app-imo-file-upload-list",
  templateUrl: "./imo-file-upload-list.component.html",
  styleUrls: ["./imo-file-upload-list.component.css"],
})
export class ImoFileUploadListComponent implements OnInit {
  private readonly logId = "FileUploadListComponent :: ";

  @Input() uploader: FileUploader;
  @Input() target: FileUploadListTargetEnum;
  @Input() uploadList?: FileUploadListItem[] = [];
  @Input() hasSingleFile?: boolean;
  @Input() suborderId?: string;
  @Input() isSuborderUpload?: boolean;
  @Input() index?: number = 0;
  @Input() isMarkedFile?: boolean;
  @Input() fileContent?: FileContentEnum;
  @Input() orderType?: OrderTypeEnum;
  @Input() extraErrorInfo?: string;
  @Input() currentAvailableSpace?: number;
  @Input() initialAvailableSpace?: number;
  @Output() entryAdded = new EventEmitter<FileUploadEntry>();
  @Output() entryAddedToList = new EventEmitter<FileItem>();
  @Output() entryRemoved = new EventEmitter<FileUploadEntry>();
  @Output() fileNameError? = new EventEmitter<String>();

  private fileUploadFolderPath: string;
  errorFilesList: string[] = [];
  errorTypeList: UploadErrorTypeEnum[] = [];
  CONSTANTS = CONSTANTS;

  constructor(
    private uploadService: UploadService,
    public ols: OrdersLogicService,
    private ns: NotificationsService,
    private bp: BytePipe
  ) {}

  ngOnInit() {
    if (this.isSuborderUpload && this.suborderId) {
      this.fileUploadFolderPath = CONSTANTS.COLLECTIONS.SUBORDERS + "/" + this.suborderId + "/" + this.target + "/";
    } else {
      this.fileUploadFolderPath = "temp/" + uuidv4() + "/";
    }

    this.uploader.onBuildItemForm = this.onBuildItemForm.bind(this);
    this.uploader.onSuccessItem = this.onSuccessItem.bind(this);
    this.uploader.onErrorItem = this.onErrorItem.bind(this);
    this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);
    this.uploader.onProgressItem = this.onProgressItem.bind(this);
    this.uploader.onWhenAddingFileFailed = this.onWhenAddingFileFailed.bind(this);
  }

  public resetErrorFilesList() {
    this.errorFilesList = [];
    this.errorTypeList = [];
  }

  private onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any) {
    if (this.hasSingleFile) {
      this.errorFilesList = [];
      this.errorTypeList = [];
    }
    switch (filter.name) {
      case UploadErrorTypeEnum.FILE_SIZE:
        this.errorFilesList.push(item.name + " <b>überschreitet die zulässige Dateigröße</b>.");
        this.errorTypeList.push(UploadErrorTypeEnum.FILE_SIZE);
        break;
      case UploadErrorTypeEnum.MIME_TYPE:
        this.errorFilesList.push(item.name + " <b>hat ein ungültiges Format</b>.");
        this.errorTypeList.push(UploadErrorTypeEnum.MIME_TYPE);
        break;
      case UploadErrorTypeEnum.QUEUE_LIMIT:
        this.errorFilesList.push(item.name + " <b>überschreitet maximale Anzahl Dateien.</b>");
        this.errorTypeList.push(UploadErrorTypeEnum.QUEUE_LIMIT);
        break;
    }
  }

  private onBuildItemForm(fileItem: FileItem, form: FormData) {
    form.append("path", this.fileUploadFolderPath);
    form.append("isAutoUpload", String(this.uploader.autoUpload));
    if (typeof this.isMarkedFile !== "undefined") {
      form.append("isMarkedFile", String(this.isMarkedFile));
    }
    fileItem.withCredentials = false;
    return { fileItem, form };
  }

  private onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) {
    const data = <FileDetails>JSON.parse(response); // success server response
    console.log("Upload file successful.", data);

    const uploadEntryFound = this.uploadList.find((entry) => entry.name === item.file.name);
    uploadEntryFound.fileDetails = { file_content: this.fileContent, ...data };
    uploadEntryFound.hasFinishedSuccessfully = true;

    this.entryAdded.emit({ target: this.target, fileDetails: uploadEntryFound.fileDetails, parentId: this.index });

    if (this.hasSingleFile) {
      this.errorTypeList = [];
      this.errorFilesList = [];
    }

    // suborder uploads should be removed from the list after the upload is finished, since they are being immediately stored to the suborder.
    if (this.isSuborderUpload) {
      this.onRemoveFile(uploadEntryFound.fileDetails);
    }
  }

  private onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) {
    const error = JSON.parse(response); // error server response
    console.error("Upload file error.", error);

    const uploadEntryFound = this.uploadList.find((entry) => entry.name === item.file.name);
    uploadEntryFound.error = error;
  }

  private changeFileName(item: FileItem) {
    console.log("Changing file name:", item);

    if (
      this.target !== FileUploadListTargetEnum.HelpFiles ||
      (this.orderType !== OrderTypeEnum.V_Staging && this.orderType !== OrderTypeEnum.Retouching)
    ) {
      console.log(
        "Cancelling change since file target or order type dont match the necessary conditions:",
        this.target,
        this.orderType
      );
      return;
    }

    const splitedFileName = item.file.name.split(".");
    const fileExtension = splitedFileName.pop();
    const newFileName = "z_" + splitedFileName.join(".") + "_supporting_file_do_not_edit." + fileExtension;
    item.file.name = newFileName;
  }

  private onAfterAddingFile(item: FileItem) {
    console.log("Adding file:", item);

    this.changeFileName(item);

    const foundFile = this.uploadList.find((upload) => upload.name === item.file.name);
    if (foundFile) {
      if (foundFile.error) {
        this.onCancelFile(foundFile.fileItem);
      } else {
        throw "File already exists!";
      }
    }

    if (this.hasSingleFile && this.uploadList.length > 0) {
      // remove existing file
      const uploadEntryFound = this.uploadList[0];
      this.onRemoveFile(uploadEntryFound.fileDetails);
    }
    const energyPassPackages = [
      CONSTANTS.PACKAGE_KEYS.ENERGYPASS_DEMAND_CERTIFICATE,
      CONSTANTS.PACKAGE_KEYS.ENERGYPASS_CONSUMPTION_CERTIFICATE,
    ];
    if (energyPassPackages.includes(this.ols.currentPackageId.value.split("|").pop())) {
      const fileName = item.file.name.toLowerCase();
      if (CONSTANTS.THUMBNAIL_SIZES_SUFFIXES.some((element) => fileName.includes(element.toLowerCase()))) {
        this.fileNameError.emit(UploadErrorTypeEnum.FILE_NAME);
        this.onCancelFile(item);
        throw "File name must not contains thumbXS, thumbM and thumbXL string in it!";
      }
    }

    if (this.currentAvailableSpace && this.currentAvailableSpace < item.file.size) {
      this.uploader.removeFromQueue(item);
      this.ns.showNotification(
        `Fehler (${item.file.name}): Die Gesamtgröße aller Dateien darf ${this.bp.transform(
          this.initialAvailableSpace
        )} nicht überschreiten. Dateien, durch die das Limit überschritten wird, wurden entfernt.`,
        "danger"
      );
      return;
    }

    this.uploadList.push({
      name: item.file.name,
      progress: new BehaviorSubject(0),
      fileItem: item,
    });

    this.entryAddedToList.emit(item);
  }

  private onProgressItem(item: FileItem, progress: any) {
    console.log("Progress upload:", item, progress);

    const uploadEntryFound = this.uploadList.find((entry) => entry.name === item.file.name);
    uploadEntryFound.progress.next(progress);
  }

  async onRemoveFile(fileDetails: FileDetails) {
    console.log(this.logId + "Removing file:", fileDetails);

    if (!this.isSuborderUpload) {
      const decodedDownloadUrl = decodeURIComponent(fileDetails.download_url);
      const index = decodedDownloadUrl.indexOf("/temp/");
      let path = decodedDownloadUrl.substring(index + 1); // file path should skip the initial '/' character
      path = path.split("?").shift(); // remove query string data

      await this.uploadService.deleteFile(path, this.suborderId).toPromise();
    }

    const indexFound = this.uploadList.findIndex((item) => {
      return item.fileDetails === fileDetails;
    });

    this.removeFileFromList(indexFound);
    this.entryRemoved.emit({ target: this.target, fileDetails: fileDetails, parentId: this.index });
  }

  onCancelFile(fileItem: FileItem) {
    console.log(this.logId + "Canceling file:", fileItem);

    fileItem.cancel();
    this.uploader.removeFromQueue(fileItem);

    const indexFound = this.uploadList.findIndex((item) => {
      return item.fileItem === fileItem;
    });

    this.removeFileFromList(indexFound);
  }

  removeFileFromList(index: number) {
    this.uploadList.splice(index, 1);
  }

  protected readonly UploadErrorTypeEnum = UploadErrorTypeEnum;
}
