import { Injectable, NgZone, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { HttpClient } from "@angular/common/http";
import { UserAuthModel } from "@app/models/user-auth.model";
import { environment } from "@environments/environment";
import { combineLatest, Subject } from "rxjs";
import { UserRoleEnum } from "@app/models/user-role-list";
import { CONSTANTS } from "@app/util/constants";
import { WebsocketsService } from "@app/_services/websockets.service";
import { CompaniesLogicService } from "@app/_services/companies-logic.service";
import { UsersService } from "@app/_services/users.service";
import { User } from "@app/interfaces/user.interface";
import { NotificationsService } from "@app/_services/notifications.service";
import { SendEmailService } from "@app/_services/send-email.service";
import { buildQueryString, removeFromSession } from "@app/util/helper";
import { configureScope } from "@sentry/angular";
import { CookieService } from "ngx-cookie-service";
import { SsoSourcesEnum } from "@app/models/sso-sources.enum";
import { Order } from "@app/interfaces/order.interface";
import { OrdersService } from "@app/_services/orders.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public sessionEnded = false;
  userData: any; // Save logged in user data
  role: any;
  userSub: any;
  emailVerificationFailed = false;
  floorplan: any;
  dronepilot: any;
  retouching: any;
  hdphotos: any;
  visualisation: any;
  public emailVerified = false;
  public profileCompleted = true;
  public myUserObservable: User;
  public authorised = false;
  public checkingEmail = false;
  public registering = false;
  uid = null;
  public adminFlag: boolean;
  public showLoader: EventEmitter<boolean> = new EventEmitter<boolean>();
  redirectUrl: string = "";
  apiBaseUrl = environment.apiUrl;
  tokenRefreshTimer;
  tutorialSkipped = false;
  closePartnerBannerOnce: boolean = false;
  hiddenPartnerContent: string[] = [];
  partnerContentChange = new Subject<void>();
  constructor(
    public router: Router,
    public ngZone: NgZone,
    public ngxSmartModalService: NgxSmartModalService,
    private ns: NotificationsService,
    private us: UsersService,
    private cls: CompaniesLogicService,
    private http: HttpClient,
    private socketService: WebsocketsService,
    private ses: SendEmailService,
    private os: OrdersService,
    private cookieService: CookieService
  ) {}

  checkAuthState(): Promise<boolean> {
    /* Since the router calls  the CanActivate() method on almost every route which in turn calls this,
    we would have many superflous database calls on every route-change, thus we set authorised = true;
    after the first AuthCheck, so we can catch every call after the successful-login with a simple if-statement.
    When the user logs out, this.authorised has to be set to false, because the page is not reloaded.
    */
    if (this.authorised === true) {
      configureScope((scope) => {
        scope.setUser({
          email: this.myUserObservable.email,
          username: this.myUserObservable.name,
          id: this.myUserObservable.uid,
        });
      });
      if (
        this.cookieService.check(CONSTANTS.USER_CONSENT_COOKIE_NAME) &&
        !Object.keys(this.myUserObservable).includes("userConsentForSentry")
      ) {
        this.myUserObservable.userConsentForSentry = this.cookieService.get(CONSTANTS.USER_CONSENT_COOKIE_NAME);
        return new Promise<boolean>((resolve) => {
          this.us
            .patchUser(this.myUserObservable.uid, {
              userConsentForSentry: this.cookieService.get(CONSTANTS.USER_CONSENT_COOKIE_NAME),
            })
            .subscribe(() => {
              resolve(true);
            });
        });
      } else {
        // Not needed to ask Firebase
        return new Promise<boolean>((resolve) => {
          resolve(true);
        });
      }
    } else {
      let user: UserAuthModel = JSON.parse(localStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER));
      if (!user) {
        sessionStorage.removeItem("user");
        return new Promise<boolean>((resolve) => {
          resolve(false);
        });
      } else if (user.idToken && user.refreshToken) {
        return this.refreshIdToken();
      }
    }
  }

  /**
   * Use the auth service to check if the user email is verified. If it is verified, we updated the emailVerified bit to true
   * in user document in the user collection.
   */
  verifyEmail() {
    return new Promise((resolve, reject) => {
      this.checkingEmail = true;
      this.getAuthUserDetails()
        .then(() => {
          if (this.myUserObservable && this.myUserObservable.emailVerified) {
            this.sendNewUserRegistered(this.myUserObservable.email);
            console.log("email verified in auth and user document");
            this.ngxSmartModalService.close("emailVerificationModal");
            this.checkTutorial();
            resolve(true);
          } else if (this.emailVerified === true) {
            this.sendNewUserRegistered(this.myUserObservable.email);
            console.log("email verified in auth, updating user document");
            this.us
              .patchUser(this.myUserObservable.uid, {
                emailVerified: true,
              })
              .subscribe(
                () => {
                  console.log("user doc updated for email verification");
                  this.ngxSmartModalService.close("emailVerificationModal");
                  this.checkTutorial();
                  resolve(true);
                },
                (error) => {
                  console.log("user doc update failed for email verification");
                  resolve(false);
                }
              );
          } else {
            console.warn("email not verified!");
            this.emailVerificationFailed = true;
            resolve(false);
            setTimeout(() => {
              this.emailVerificationFailed = false;
            }, 5000);
          }
        })
        .catch(() => {
          resolve(false);
        })
        .finally(() => {
          this.checkingEmail = false;
        });
    });
  }

  loginWithToken(token: string, redirectPath?: string) {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post(this.apiBaseUrl + "auth/login-with-token", {
          token: token,
        })
        .subscribe(
          async (loginResponse: any) => {
            if (redirectPath) {
              this.redirectUrl = "/" + redirectPath;
            }
            this.handleSuccessfulLogin(loginResponse, false).then(() => {
              resolve();
            });
          },
          (loginError) => {
            console.log("Login error!");
            console.log(loginError);
            reject(loginError);
          }
        );
    });
  }

  // Login with email & password
  login(email, password, isFirstLogin?) {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post(this.apiBaseUrl + "auth/login", {
          email: email,
          password: password,
        })
        .subscribe(
          (loginResponse: any) => {
            this.handleSuccessfulLogin(loginResponse, isFirstLogin)
              .then(() => {
                this.closePartnerBannerOnce = false;
                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          },
          (loginError) => {
            console.log(loginError);
            reject(loginError);
          }
        );
    });
  }

  handleSuccessfulLogin(loginResponse, isFirstLogin) {
    return new Promise<void>((resolve, reject) => {
      const user: UserAuthModel = {
        userid: loginResponse.localId || loginResponse.userid,
        email: loginResponse.email,
        refreshToken: loginResponse.refreshToken,
        idToken: loginResponse.idToken,
        userToken: loginResponse.userToken,
      };
      localStorage.setItem("user", JSON.stringify(user));
      this.socketService.connect({ idToken: user.idToken });
      this.autoTokenRefreshHandler();
      this.getAuthUserDetails()
        .then((u: any) => {
          this.ngZone.run(async () => {
            if (this.redirectUrl) {
              let goToServiceSelection = false;
              // extract url search params
              const redirectPathSearchParams = new URLSearchParams(this.redirectUrl);
              if (redirectPathSearchParams.get(CONSTANTS.QUERY_PARAMS.SOURCE) === SsoSourcesEnum.PROPSTACK) {
                const queryString = buildQueryString(
                  0,
                  1,
                  "createdOn",
                  "desc",
                  new Map([["Desc", redirectPathSearchParams.get(CONSTANTS.QUERY_PARAMS.OBJECT_NUMBER)]])
                );
                const myOrdersQueryResult: QueryResult<Order> = await this.os.getOrders("my" + queryString).toPromise();
                const hasOrders = myOrdersQueryResult.total_hits > 0;
                if (!hasOrders) {
                  goToServiceSelection = true;
                }
              }
              if (goToServiceSelection) {
                this.redirectUrl = "/createorder" + this.redirectUrl.substring(this.redirectUrl.indexOf("?"));
              }
            }

            let redirectUrl = this.redirectUrl || "/createorder";
            if (this.myUserObservable.role === UserRoleEnum.ServiceProvider) {
              redirectUrl = this.redirectUrl || "/orderoverview";
            }

            this.redirectUrl = "";
            this.router.navigateByUrl(redirectUrl);
          });
          if (this.emailVerified === false || (this.myUserObservable && !this.myUserObservable.emailVerified)) {
            this.ngxSmartModalService.getModal("emailVerificationModal").open();
          } else if (isFirstLogin) {
            this.checkTutorial();
          }
          resolve();
        })
        .catch(() => {
          console.log("Failed to fetch the logged in user details!");
          console.log("Logging out");
          this.logout();
          reject({
            error: "Failed to fetch the logged in user details!",
          });
        });
    });
  }

  // Register with email & password
  register(rFV) {
    return new Promise<void>((resolve, reject) => {
      try {
        if (!this.adminFlag) {
          this.http.post(this.apiBaseUrl + "auth/register", rFV).subscribe(
            (responseData) => {
              removeFromSession(CONSTANTS.SESSION_STORAGE_KEYS.LANDING_PAGE_TRACKING_REF);
              this.registering = false;
              this.login(rFV.email, rFV.password, true).then();
            },
            (error) => {
              reject(error);
            }
          );
        } else {
          this.showLoader.emit(true);
          this.http.post(this.apiBaseUrl + "auth/onboarduser", rFV).subscribe(
            () => {
              this.showLoader.emit(false);
              this.ns.showNotification("User created successfully", "success");
              resolve();
            },
            (error) => {
              this.ns.showNotification(error.message, "danger");
              reject(error);
            }
          );
        }
      } catch (error) {
        this.ns.showNotification(error.message, "danger");
        reject(error);
      }
    });
  }

  /**
   * Sends a verification mail to the user.
   *
   * @returns {Promise} - A promise that resolves when the mail is sent.
   */
  sendVerificationMail() {
    return this.http.get(this.apiBaseUrl + "auth/sendverificationlink").toPromise();
  }

  /**
   * If the e-mail is registered on our platform this function will trigger the backend to send the e-mail.
   * @param email The e-mail address of the user whose password is to be reset.
   * @param spk True, if the password reset link, shall bring the user to the sparkassen-design-screen.
   */
  sendPasswordResetLink(email, spk) {
    this.http.post(this.apiBaseUrl + "auth/request-password-reset", { email: email, spk: spk }).subscribe(
      () => {
        console.log("Password reset requested");
      },
      (error) => {
        this.ns.showNotification("Network error. Please check your internet connection and proxy-settings.");
      }
    );
  }

  /**
   * Resets the password if the given data is valid.
   * @param uid The users uid
   * @param token A valid password-reset-token
   * @param password The new password
   */
  resetPassword(uid: string, token: string, password: string) {
    return this.http.post(this.apiBaseUrl + "auth/reset-password", {
      uid: uid,
      token: token,
      password: password,
    });
  }

  /**
   * Method to logout the user.
   * This method removes the user from local storage and session storage.
   * It unsubscribes from user subscriptions and disconnects from socket service.
   * It also sets authorised and adminFlag properties to false.
   * Finally, it navigates to the logout page.
   *
   * @return {void}
   */
  logout() {
    localStorage.removeItem("user");
    sessionStorage.removeItem("user");
    this.userSub && this.userSub.unsubscribe();
    this.socketService.disconnect();
    this.authorised = false;
    this.adminFlag = false;
    this.router.navigate(["/logout"]);
  }

  /**
   * Changes the user's password.
   *
   * @param {string} password - The new password to be set.
   * @return {Promise} A promise that resolves when the password is successfully changed.
   */
  changePassword(password) {
    return this.http.put(
      this.apiBaseUrl + "auth/changepassword",
      {
        password: password,
      },
      {
        responseType: "text",
      }
    );
  }

  /**
   * Refreshes the id token for the user.
   *
   * @return {Promise<boolean>} A promise that resolves to true if the id token is refreshed successfully, or false if there was an error or the user is not authenticated.
   */
  refreshIdToken() {
    let user: UserAuthModel = JSON.parse(localStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER));
    if (!user || !user.idToken || !user.userid) {
      return new Promise<boolean>((resolve, reject) => {
        resolve(false);
      });
    }
    return new Promise<boolean>((resolve, reject) => {
      this.http
        .post(this.apiBaseUrl + "auth/refreshtoken", {
          refresh_token: user.refreshToken,
        })
        .subscribe(
          (authResponse: any) => {
            console.log("Id token refreshed");
            this.autoTokenRefreshHandler();
            user.idToken = authResponse.id_token;
            user.refreshToken = authResponse.refresh_token;
            localStorage.setItem("user", JSON.stringify(user));
            this.socketService.disconnect();
            this.socketService.connect({ idToken: authResponse.id_token });
            this.getAuthUserDetails()
              .then(() => {
                configureScope((scope) => {
                  scope.setUser({
                    email: this.myUserObservable.email,
                    username: this.myUserObservable.name,
                    id: this.myUserObservable.uid,
                  });
                });
                resolve(true);
              })
              .catch(() => {
                localStorage.removeItem("user");
                sessionStorage.removeItem("user");
                resolve(false);
              });
          },
          (error: any) => {
            console.log("Refresh token error!");
            console.log(error);
            localStorage.removeItem("user");
            sessionStorage.removeItem("user");
            resolve(false);
          }
        );
    });
  }

  /**
   * Retrieves the authenticated user details.
   *
   * @return {Promise<void>} - Resolved when the user details are successfully retrieved, rejected with an error
   * message if the user is invalid.
   */
  getAuthUserDetails() {
    let user: UserAuthModel = JSON.parse(localStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER));
    if (!user || !user.idToken || !user.userid) {
      return new Promise<void>((resolve, reject) => {
        reject("Invalid user");
      });
    }
    return new Promise<void>((resolve, reject) => {
      combineLatest([
        this.http.get(this.apiBaseUrl + "auth/getuserauthstate/" + user.userid),
        // TODO: Call function from users.service once it is ready
        this.http.get(this.apiBaseUrl + "users/uid/" + user.userid),
      ]).subscribe(
        async (combinedResponse) => {
          const authState: any = combinedResponse[0] || {};
          const userData: User = combinedResponse[1] ? combinedResponse[1][0] || {} : {};
          sessionStorage.setItem("user", JSON.stringify(authState));
          if (userData) {
            if (userData.userConsentForSentry !== this.cookieService.get(CONSTANTS.USER_CONSENT_COOKIE_NAME)) {
              userData.userConsentForSentry = this.cookieService.get(CONSTANTS.USER_CONSENT_COOKIE_NAME);
              await this.us
                .patchUser(userData.uid, {
                  userConsentForSentry: userData.userConsentForSentry,
                })
                .toPromise();
            }
            this.myUserObservable = userData;
            configureScope((scope) => {
              scope.setUser({
                email: userData.email,
                username: userData.name,
                id: userData.uid,
              });
            });
            this.role = userData.role;
            this.authorised = true;
            if (userData["company"]) {
              if (typeof userData["company"] !== "string") {
                // New company entries
                const company = await this.cls.getCompany(this.myUserObservable.company?.id, false, true).toPromise();
                if (company) {
                  userData["companyName"] = company.name;
                  userData.companyObj = company;
                }
              } else {
                // Fallback for old entries where company is just a string.
                userData["companyName"] = userData["company"];
              }
              this.myUserObservable = userData;
              this.cls.setCompanyLogoOptionAvailableFlag(!!this.myUserObservable?.companyObj?.logo?.file);
            }
            this.socketService.userDetails = userData;
          }
          this.emailVerified = authState.emailVerified;
          if (this.emailVerified === false || (this.myUserObservable && !this.myUserObservable.emailVerified)) {
            this.ngxSmartModalService.getModal("emailVerificationModal").open();
          }
          resolve();
        },
        (combinedError) => {
          console.log("Auth state and user details error");
          console.log(combinedError);
          localStorage.removeItem("user");
          sessionStorage.removeItem("user");
          reject();
        }
      );
    });
  }

  /**
   * Handles and schedules the automatic token refresh.
   *
   * @return {void}
   */
  autoTokenRefreshHandler() {
    this.tokenRefreshTimer && clearTimeout(this.tokenRefreshTimer);
    this.tokenRefreshTimer = setTimeout(() => {
      this.refreshIdToken().then((response) => {
        if (!response) {
          console.log("Id token was not refreshed");
          console.log("Logging out");
          this.logout();
        }
      });
    }, CONSTANTS.TOKEN_REFRESH_TIMER);
  }

  /**
   * Checks if the tutorial needs to be shown based on the user's role, profile completion and whether the user already
   * completed the tutorial.
   *
   * @function checkTutorial
   *
   * @returns {void}
   */
  checkTutorial() {
    if (!this.tutorialSkipped) {
      if (this.role === UserRoleEnum.Customer) {
        this.ngxSmartModalService.getModal("firstImmoModal").open();
      } else if (this.role === UserRoleEnum.ServiceProvider && this.profileCompleted === false) {
        this.ngxSmartModalService.getModal("newDienstleisterModal").open();
      }
    } else {
      console.log("Tutorial skipped!");
    }
  }

  sendNewUserRegistered(email: string) {
    console.log("entered registered method");
    const fromEmail = `Imogent Service <${CONSTANTS.SUPPORT_EMAILS.PRODUCTION}>`;
    let recipient = CONSTANTS.SALES_EMAILS.DEVELOP;
    if (environment.production) {
      recipient = CONSTANTS.SALES_EMAILS.PRODUCTION;
    }
    const subject = `Neuer Nutzer auf der IMOGENT Plattform ${email}`;
    this.ses.sendEmail({
      from: fromEmail,
      to: recipient,
      subject: subject,
      body: `Der Nutzer mit der E-mail ${email} hat sich neu auf der IMOGENT-Plattform registriert.`,
    });
  }

  setEMailVerified(userId: string) {
    return this.http.post(this.apiBaseUrl + "auth/set-email-verified", { userId: userId }).toPromise();
  }
}
