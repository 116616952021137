<div class="container col-xl-12">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-1  d-none d-md-block">

        </div>
        <div class="col-3 d-none d-md-block">
          Bezeichnung
        </div>
        <div class="col-4 d-none d-md-block">
          Raum
        </div>
        <div class="col-4 d-none d-md-block">
          Kommentar
        </div>
      </div>
      <hr/>
      <form [formGroup]="roomDescriptionsForm">
        <div class="row" formArrayName="rooms">
          <div *ngFor="let room of roomDescriptionsForm.get('rooms')['controls']; let i = index;" class="col-12">
            <div [formGroupName]="i" class="row">
              <div class="col-1">
                <i (click)="removeRoom(i)" class="fas fa-minus-circle text-danger fa-lg clickableButton"></i>
              </div>
              <div class="col-3">
                <input class="form-group form-control" formControlName="identifier" type="text" type="text">
              </div>
              <div class="col-4">
                <mat-form-field>
                  <mat-select formControlName="roomType">
                    <mat-option *ngFor="let key of roomTypeKeys" [value]="key">{{RoomTypeEnum[key]}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-4">
                <textarea class="form-group form-control" formControlName="comment"
                          placeholder="Spezifische Kommentare oder Anmerkungen"></textarea>
              </div>
            </div>
          </div>

          <div class="col-12">
            <i (click)="addRoom()" class="fas fa-plus-circle text-success fa-lg clickableButton"></i>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
