import { Component, OnInit, ViewChild } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { FileItem, FileUploader } from "ng2-file-upload";
import { CONSTANTS } from "@app/util/constants";
import { NgxSmartModalService } from "ngx-smart-modal";
import { NewsSlideDataInterface } from "@app/interfaces/news-slide-data.interface";
import { v4 as uuidv4 } from "uuid";
import { NewsSlidesLogicService } from "@app/_services/news-slides-logic.service";
import { AuthService } from "@app/auth/auth.service";
import { NotificationsService } from "@app/_services/notifications.service";

@Component({
  selector: "app-news-slides-manager",
  templateUrl: "./news-slides-manager.component.html",
  styleUrls: ["./news-slides-manager.component.css"],
})
export class NewsSlidesManagerComponent implements OnInit {
  slides: NewsSlideDataInterface[] = [];
  imageUploader = new FileUploader({
    additionalParameter: { isNew: true },
    maxFileSize: CONSTANTS.FILE_SIZE_LIMIT_5MB,
  });
  newSlideText = "";
  newSlideTitle = "";
  imageContent = "";
  imageName = "";
  showError = false;
  previewIndex = 0;
  @ViewChild("dotNav") dotNav;
  showPreview = false;
  hasChanged = false;
  isEditing = false;
  selectedIndex: number;

  constructor(
    private ngxSmartModalService: NgxSmartModalService,
    private nsls: NewsSlidesLogicService,
    private auth: AuthService,
    private ns: NotificationsService
  ) {}

  ngOnInit(): void {
    this.slides = this.nsls.currentNotifications;
  }

  addOrEditSlide(index?: number) {
    this.newSlideTitle = "";
    this.newSlideText = "";
    this.imageUploader.queue.pop();
    this.imageContent = "";
    this.imageName = "";
    if (index >= 0) {
      this.isEditing = true;
      this.selectedIndex = index;
      const selectedSlide = this.slides[index];
      this.newSlideTitle = selectedSlide.title;
      this.newSlideText = selectedSlide.text;
      this.imageUploader.addToQueue([<File>(<unknown>selectedSlide.image)]);
      this.imageContent = selectedSlide.image;
      this.imageName = selectedSlide.fileName;
    } else {
      this.selectedIndex = -1;
      this.isEditing = false;
    }
    this.ngxSmartModalService.open("addNewSlideModal");
  }

  deleteSlide(index?: number) {
    this.slides.splice(index, 1);
    this.hasChanged = true;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.slides, event.previousIndex, event.currentIndex);
    this.hasChanged = true;
  }

  removeFileFromQueue(file: FileItem) {
    this.imageUploader.removeFromQueue(file);
    this.imageContent = "";
    this.imageName = "";
  }

  onImageUpload() {
    const reader = new FileReader();
    reader.readAsDataURL(<File>(<unknown>this.imageUploader.queue[0].file.rawFile)); // read file as data url
    this.imageName = this.imageUploader.queue[0].file.name;
    reader.onload = (event) => {
      // called once readAsDataURL is completed
      this.imageContent = event.target.result as string;
    };
  }

  addNewEntry() {
    this.showError = false;
    if (!this.newSlideTitle || !this.newSlideText || !this.imageUploader.queue.length) {
      this.showError = true;
      return;
    }
    if (this.isEditing && this.selectedIndex >= 0) {
      this.slides[this.selectedIndex] = {
        title: this.newSlideTitle,
        text: this.newSlideText,
        image: this.imageContent,
        id: this.slides[this.selectedIndex].id,
        fileName: this.imageName,
      };
    } else {
      this.slides.push({
        title: this.newSlideTitle,
        text: this.newSlideText,
        image: this.imageContent,
        fileName: this.imageName,
        id: uuidv4(),
      });
    }
    this.newSlideTitle = "";
    this.newSlideText = "";
    this.imageUploader.queue.pop();
    this.imageContent = "";
    this.ngxSmartModalService.close("addNewSlideModal");
    this.hasChanged = true;
  }

  openPreview(modalId: string) {
    this.showPreview = true;
    this.ngxSmartModalService.getModal(modalId).open();
  }

  closePreview(modalId: string) {
    this.showPreview = true;
    this.ngxSmartModalService.getModal(modalId).close();
  }

  goToNextNews() {
    this.previewIndex += 1;
    this.dotNav.updateSelection(this.previewIndex);
  }

  changeNews(selectedIdx: number) {
    this.previewIndex = selectedIdx;
  }

  saveAllChanges() {
    this.auth.showLoader.emit(true);
    this.nsls
      .updateNewsSlides(this.slides)
      .then(() => {
        this.ns.showNotification("Notifiaction slides updated successfully", "success");
        this.hasChanged = false;
      })
      .catch((error) => {
        this.ns.showNotification("Could not update the notifiaction slides! Please contact the IT team", "danger");
      })
      .finally(() => {
        this.auth.showLoader.emit(false);
      });
  }
}
