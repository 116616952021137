import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { AutoPilotConfig } from "@app/interfaces/auto-pilot-config.interface";
import { CONSTANTS } from "@app/util/constants";
import { Observable } from "rxjs";
import { ConfigurationLogicService } from "./configuration-logic.service";
import { AuthService } from "@app/auth/auth.service";
import { UserRoleEnum } from "@app/models/user-role-list";
import { ReportingPreset } from "@app/interfaces/reporting-preset.interface";

@Injectable({
  providedIn: "root",
})
export class ReportingPresetsConfigResolverService implements Resolve<ReportingPreset[]> {
  constructor(private cs: ConfigurationLogicService, private auth: AuthService) {}

  resolve() {
    if (this.auth.myUserObservable.role === UserRoleEnum.Administrator) {
      return <Observable<ReportingPreset[]>>this.cs.getConfiguration(CONSTANTS.CONFIGURATIONS.REPORTING_PRESETS);
    }
  }
}
