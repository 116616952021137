<h5 *ngIf="heading && heading.length" class="mb-2 font-weight-light">{{ heading }}</h5>
<label
  class="imogent-file-upload-container"
  [class]="containerClass"
  [class.imogent-file-upload-has-file-over]="dropzoneActive"
  ng2FileDrop
  [uploader]="uploader"
  (fileOver)="hasFileOver($event)"
>
  <i class="material-icons download-icon text-dark">cloud_upload</i>
  <button class="btn btn-dark btn-sm">Datei Auswählen</button>
  <input
    (change)="inputChange($event)"
    (click)="inputClick($event)"
    (drop)="inputClick($event)"
    [uploader]="uploader"
    [multiple]="options.multiple"
    ng2FileSelect
    type="file"
    [accept]="accept"
  />
</label>
