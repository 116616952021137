export enum ComparatorEnum {
  Equal = "==",
  GreaterEqual = ">=",
  SmallerEqual = "<=",
  GreaterThan = ">",
  SmallerThan = "<",
  UnEqual = "!=",
  OneOf = "oneOf",
  NotIn = "notIn",
}
