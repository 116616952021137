import { Component, OnInit } from "@angular/core";
import { AuthService } from "@app/auth/auth.service";
import { GlobalService } from "@app/_services/global.service";

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.css"],
})
export class LogoutComponent implements OnInit {
  year: number;
  public sessionCompleted;
  constructor(public authservice: AuthService, public gs: GlobalService) {}

  ngOnInit() {
    this.year = new Date().getFullYear();
    this.authservice.logout();
  }
}
