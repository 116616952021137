import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FileUploader } from "ng2-file-upload";

/**
 * A consistent wrapper to be used in conjunction with app-imo-file-upload-list
 */
@Component({
  selector: "app-imo-file-upload-container",
  templateUrl: "./imo-file-upload-container.component.html",
  styleUrls: ["./imo-file-upload-container.component.scss"],
})
export class ImoFileUploadContainerComponent implements OnInit {
  @Input() uploader: FileUploader;
  @Input() options: { multiple: boolean } = { multiple: true };
  @Input() heading: string;
  @Input() containerClass: string;
  @Input() accept = "*";
  @Output() change = new EventEmitter();
  @Output() click = new EventEmitter();
  dropzoneActive: boolean;

  constructor() {}

  ngOnInit() {
    this.checkRequiredInputs();
  }

  inputChange(event: Event) {
    this.change.emit(event);
  }

  inputClick(event: Event) {
    this.click.emit(event);
  }

  private checkRequiredInputs() {
    if (!this.uploader) {
      throw new Error('Attribute "uploader" is required');
    }
  }

  hasFileOver($event: any) {
    this.dropzoneActive = $event;
  }
}
