<div class="carousel-container" *ngIf="showCarousel" [ngClass]="{
  'hide-left': firstIndex === 0,
  'hide-right': lastIndex === data.imagesList?.length - 1
}">
  <carousel [itemsPerSlide]="tilesPerSlide" [singleSlideOffset]="true"
    [interval]="0" [noWrap]="true" (click)="onClick($event)">
    <slide *ngFor="let slide of data.imagesList" (click)="onSelectThumbnail(slide)">
      <div class="img-container clickable" [ngClass]="{
        'active-img': data.selectedImage.url === slide.url
      }">
        <img [src]="slide.url" alt="image slide">
      </div>
    </slide>
  </carousel>
</div>

<div class="text-center m-2" *ngIf="data.imagesList?.length > 0">
  <span *ngIf="data.imagesList?.length == 1">{{'Bild ' + (firstIndex + 1) + ' von insgesamt ' + data.imagesList?.length}}</span>
  <span
    *ngIf="data.imagesList?.length > 1">{{'Bilder ' + (firstIndex + 1) + ' - ' + (lastIndex + 1) + ' von insgesamt ' + data.imagesList?.length}}</span>
</div>

<div class="text-center">
  <img [src]="data.selectedImage.url" class="img-fluid">
  <p class="m-2">{{data.imageCategory}}: {{data.selectedImage.name}}</p>
  <button *ngIf="data.isPicker" class="btn btn-primary" (click)="onSelectImage()">Wählen</button>
</div>
