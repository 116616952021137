import { Pipe, PipeTransform } from "@angular/core";
import { LANGUAGE } from "@app/language/language";

@Pipe({
  name: "applisttranslate",
})
export class AppListTranslatePipe implements PipeTransform {
  /**
   * @param value the (original) input value
   * @param args args[0]: app list name
   */
  transform(value: string, ...args): any {
    const list_name = args[0];
    const translationMap = LANGUAGE.app_list_strings[list_name];
    if (!value || !translationMap || !translationMap[value]) {
      return value;
    }
    return translationMap[value];
  }
}
