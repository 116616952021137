import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";
import { HttpClient } from "@angular/common/http";
import { NewsSlideConfigDataInterface, NewsSlideDataInterface } from "@app/interfaces/news-slide-data.interface";

@Injectable({
  providedIn: "root",
})
export class NewsSlidesService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.NEWS_SLIDES;

  constructor(private http: HttpClient) {}

  getNewsSlidesConfig() {
    return this.http.get<NewsSlideConfigDataInterface>(this.endpoint);
  }

  updateSlides(slides: NewsSlideDataInterface[]) {
    return this.http.patch(this.endpoint, slides);
  }
}
