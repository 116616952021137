import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { UserRoleEnum } from '@app/models/user-role-list';
import { CompaniesLogicService } from '@app/_services/companies-logic.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesResolverService {

  constructor(
    private cls: CompaniesLogicService,
    private auth: AuthService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.myUserObservable.role === UserRoleEnum.Administrator) {
      return this.cls.getCompanies();
    } else if (this.auth.myUserObservable.company) {
      return this.cls.getCompany(this.auth.myUserObservable.company.id, false, true);
    }
  }
}
