import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { CarouselImage } from "@app/interfaces/carousel-image.interface";

@Component({
  selector: "app-imo-carousel",
  templateUrl: "./imo-carousel.component.html",
  styleUrls: ["../imo-carousel-selector/imo-carousel-selector.component.css", "./imo-carousel.component.css"],
})
export class ImoCarouselComponent implements OnInit, OnDestroy {
  @Input() images: CarouselImage[] = [];
  currentImage: CarouselImage;
  private autoChangeTimeout: any;

  ngOnInit() {
    this.updateImages();
    this.startAutoChange(); // Start automatic image change on component initialization
  }

  ngOnDestroy() {
    this.stopAutoChange(); // Stop the automatic image change when the component is destroyed
  }

  public updateImages(carouselImages?: CarouselImage[]) {
    if (carouselImages) {
      this.images = carouselImages;
    }

    this.addOptionalImageIndices();
    this.currentImage = this.images?.[0];
  }

  addOptionalImageIndices() {
    const existingIndices = new Set<number>();
    let nextIndex = 0;

    this.images?.forEach((image) => {
      if (image.index === undefined) {
        // Assign the next available index
        while (existingIndices.has(nextIndex)) {
          nextIndex++;
        }

        image.index = nextIndex;
        existingIndices.add(nextIndex);
      } else {
        // If the index is provided, ensure it's unique
        while (existingIndices.has(image.index)) {
          image.index++;
        }

        existingIndices.add(image.index);
        nextIndex = Math.max(nextIndex, image.index + 1);
      }
    });
  }

  previousImage() {
    if (this.currentImage.index > 0) {
      this.currentImage = this.images[this.currentImage.index - 1];
    }
  }

  nextImage() {
    const nextIndex = this.currentImage.index + 1;

    if (nextIndex < this.images?.length) {
      this.currentImage = this.images[nextIndex];
    } else {
      // If the next index is beyond the last image, go back to the first image
      this.currentImage = this.images[0];
    }

    this.resetAutoChange();
  }

  private resetAutoChange() {
    this.stopAutoChange();
    this.startAutoChange();
  }

  private startAutoChange() {
    this.autoChangeTimeout = setTimeout(() => {
      this.nextImage();
      this.startAutoChange(); // Schedule the next automatic change
    }, 10000);
  }

  private stopAutoChange() {
    if (this.autoChangeTimeout) {
      clearTimeout(this.autoChangeTimeout); // Clear the timeout when the component is destroyed
    }
  }

  jumpToImage(index: number): void {
    if (index >= 0 && index < this.images?.length) {
      this.currentImage = this.images[index];
      this.resetAutoChange();
    }
  }
}
