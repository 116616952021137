<div class="carousel-container">
  Seite {{ currentPage }} von {{ totalPages }}
  <div class="carousel-wrapper">
    <mat-card
      appearance="outlined"
      (click)="onSelected(style)"
      *ngFor="let style of currentPageStyles"
      [ngClass]="{ active: selectedStyleIndex == style.index }"
      class="style-card col-3 card-package clickable"
    >
      <img
        alt="{{ style.name || 'style' }}"
        class="img-fluid"
        src="{{ style.baseUrl + 'Grundriss_unmoebliert.jpeg' }}"
      />
      <mat-card-content>
        {{ style.name }}
      </mat-card-content>
    </mat-card>
  </div>

  <div class="carousel-controls">
    <div class="arrow-icon p-2" (click)="prevPage()">
      <i
        class="fas fa-chevron-left text-center"
        [ngClass]="{ gray: currentPage === 1, 'text-primary clickable': currentPage !== 1 }"
      ></i>
    </div>
    <div class="arrow-icon p-2" (click)="nextPage()">
      <i
        class="fas fa-chevron-right text-center text-primary clickable"
        [ngClass]="{ gray: currentPage === totalPages, 'text-primary clickable': currentPage !== totalPages }"
      ></i>
    </div>
  </div>
</div>
