import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import * as _ from 'underscore';
import * as accounting from 'accounting';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { MoneyService } from '@app/_services/money.service';
import { Subscription } from 'rxjs';
import { GlobalService } from '@app/_services/global.service';
import { Router } from '@angular/router';
import { DiscountTypeEnum } from '@app/models/discount-type-list';
import { WebsocketsService } from '@app/_services/websockets.service';
import { SubOrdersLogicService } from '../_services/sub-orders-logic.service';
import { SubsidyStatusEnum } from '@app/models/subsidyStatusEnum';
import { InvoiceDetail, PaymentCreationDetail, PaymentInvoiceDetail } from '@app/interfaces/invoice-detail.interface';
import { BillingAddress } from '@app/interfaces/billing-address.interface';
import { PaymentStatusEnum } from '@app/models/paymentStatusEnum';
import { UserRoleEnum } from '@app/models/user-role-list';
import { OrdersLogicService } from '../_services/orders-logic.service';
import { UsersLogicService } from '@app/_services/users-logic.service';
import { InvoiceLogicService } from '@app/_services/invoice-logic.service';
import { PaymentsService } from '@app/_services/payments.service';
import { SubOrder } from '@app/interfaces/suborder.interface';
import { PaymentTableData } from '@app/interfaces/paymentTableData.interface';
import { MatRadioChange } from '@angular/material/radio';
import { PaymentGroupOptions } from '@app/models/paymentGroupOptions.enum';
import { CompaniesLogicService } from '@app/_services/companies-logic.service';
import { Company } from '@app/interfaces/company.interface';
import { Order } from '@app/interfaces/order.interface';
import { Invoice } from '@app/interfaces/invoice.interface';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CONSTANTS } from '@app/util/constants';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PaymentsComponent implements OnInit, OnDestroy {

  private readonly logName = '-- PaymentsComponent :: ';
  private readonly columnsUser = ['name', 'company', 'sum_formatted'];
  private readonly columnsCompany = ['company', 'sum_formatted'];
  columnsToDisplay = this.columnsUser;
  // columnsToDisplay = ['name', 'company', 'sum_formatted', 'invoice_sum', 'paid_sum'];
  columnsToDisplayDetails = ['quantity', 'description', 'total', 'createdOn', 'completedOn'];

  expandedElement = null;
  currentInvoiceDetailGroup: PaymentInvoiceDetail[] = [];
  userRef: any;
  invoiceData = new MatTableDataSource(<PaymentTableData[]>[]);
  startDate: any;
  endDate: any;
  filterValues = {};
  dateToPassStart;
  dateToPassEnd;
  subscriptions: Subscription[] = [];
  discount_invoice = 0.0;
  typeDiscount = 1;
  suborderList: SubOrder[] = [];
  userUIDCompanyNameMap: Map<string, string> = new Map();
  ordersList = [];
  restrictDates: FormControl;
  isTableLoading = true;
  availableBillingAddresses: BillingAddress[] = [];
  groupOptions = [PaymentGroupOptions.Company, PaymentGroupOptions.User];
  selectedGroupOption: PaymentGroupOptions = PaymentGroupOptions.User;
  chosenAddress: any;
  invoiceToCreate: Invoice;
  suborderIdSelectionDict: { [suborderId: string]: boolean } = {};
  private isInvoiceDataUpdated: boolean;
  private isOrderFetchCompleted: boolean;
  isInvoiceCreationExecuted: boolean;

  @ViewChild(MatSort) set sort(value: MatSort) {
    this.invoiceData.sort = value;
  }

  constructor(
    public auth: AuthService,
    public moneyService: MoneyService,
    private uls: UsersLogicService,
    private cls: CompaniesLogicService,
    private ivls: InvoiceLogicService,
    private ngxSmartModalService: NgxSmartModalService,
    public gs: GlobalService,
    private ps: PaymentsService,
    private router: Router,
    private ms: MoneyService,
    private webSocketService: WebsocketsService,
    private ols: OrdersLogicService
  ) { }

 ngOnInit() {
    if (this.auth.myUserObservable.role === UserRoleEnum.Administrator) {
      this.isTableLoading = true;
      // default dates
      const today = new Date();
      const startMonth = new Date(today.setDate(1));
      this.endDate = new Date();
      this.startDate = new Date(new Date().setMonth(today.getMonth() - 1)).setDate(1);
      this.restrictDates = new FormControl(true);
      this.dateToPassStart = new FormControl(new Date(this.startDate));
      this.dateToPassEnd = new FormControl(new Date(this.endDate));
      this.setTableFilter();
      this.fetchOrders().then(() => {
        this.updateInvoiceData();
      });
      this.uls.getAllUsers().subscribe(() => {
        // this.webSocketService.subscribeToPaymentsSubordersList();
      });
    }
  }

  async fetchOrders() {
    this.isTableLoading = true;
    const ordersResponse = await this.ols.getOrders(0, 9999).toPromise();
    this.ordersList = ordersResponse.data;
    console.log('ORDERS FETCHED');
    this.isOrderFetchCompleted = true;
    this.deactivateTableLoading();
  }

  getInvoiceDetails(row) {
    this.expandedElement = this.expandedElement === row ? null : row;
    this.currentInvoiceDetailGroup = undefined;
    this.availableBillingAddresses = [];
    if (this.expandedElement) {
      const allInvoiceData = _.find(this.invoiceData.data, function (invoice: any) { return invoice.uid === row.uid; }, this);
      this.currentInvoiceDetailGroup = allInvoiceData.invoice_details;

      this.currentInvoiceDetailGroup.forEach((realestateInvoiceDetail: PaymentInvoiceDetail) => {
        const orderList: string[] = [];
        let paymentsStatus = realestateInvoiceDetail.details[0].payments;
        realestateInvoiceDetail.isSelected = true;
        let sum_realestate = this.getRealEstateSum(realestateInvoiceDetail);
        realestateInvoiceDetail.details.forEach((detail) => {
          if (orderList.indexOf(detail.orderId) === -1) {
            orderList.push(detail.orderId);
          }
          sum_realestate += detail.total_unformatted;
          if (paymentsStatus !== detail.payments) {
            paymentsStatus = PaymentStatusEnum.Error;
          }
          detail.isSelected = true;
        });
        realestateInvoiceDetail.total = sum_realestate;
        realestateInvoiceDetail.paymentsStatus = paymentsStatus;
        orderList.forEach(element => {
          const orderTemp = _.find(this.ordersList, function (order: any) { return order.orderId === element; }, this);
          let isAdressInArray = false;

          const invoiceDetailsToCharge = realestateInvoiceDetail.details.filter(invDetail => invDetail.chargingInvoiceUserRef);
          if (invoiceDetailsToCharge.length) {
            const addBillingAddresses = []; // Add billing Addresses of charging invoiceUserRefs = Subsidiaries
            invoiceDetailsToCharge.forEach((detail: PaymentCreationDetail) => {
              addBillingAddresses.push(this.uls.allUsers.find(u => u.uid === detail.chargingInvoiceUserRef));
            });
            // If the orders are grouped by company only the company billing address should be visible!
            if (this.selectedGroupOption !== PaymentGroupOptions.Company) {
              addBillingAddresses.forEach((addr) => {
                this.availableBillingAddresses.forEach(billingAddr => {
                  if (_.isEqual(addr, billingAddr)) {
                    isAdressInArray = true;
                  }
                });
                if (!isAdressInArray) {
                  this.availableBillingAddresses.push(addr);
                }
                isAdressInArray = false;
              });
            }
          }
          // If the orders are grouped by company only the company billing address should be visible!
          if (this.selectedGroupOption !== PaymentGroupOptions.Company) {
            isAdressInArray = false;
            this.availableBillingAddresses.forEach(billingAddr => {
              if (_.isEqual(orderTemp?.billingAddress, billingAddr)) {
                isAdressInArray = true;
              }
            });
            if (!isAdressInArray) {
              this.availableBillingAddresses.push(orderTemp?.billingAddress);
            }
          }

          const company = this.cls.companiesList.find(comp => comp.cid === this.uls.allUsers.find(user => user.uid === orderTemp?.createdBy)?.company?.id);
          if (company) {
            this.addCompanyBillingAddress(company, orderTemp);
          }

        });
        this.chosenAddress = this.availableBillingAddresses[0];
      });
    }
  }

  getRealEstateSum(realestateInvoiceDetail: PaymentInvoiceDetail) {
    let sum = 0.0;
    if (realestateInvoiceDetail.isSelected) {
      realestateInvoiceDetail.details.forEach((detail: PaymentCreationDetail) => {
        if (detail.isSelected) {
          sum += detail.total_unformatted;
        }
      });
    }
    return sum;
  }

  private addCompanyBillingAddress(company: Company, correspondingOrder: Order) {
    const companyBillingAddress = {
      company: company.name,
      name: '',
      email: company.invoiceRecipientMail,
      taxId: company.ustid,
      street: company.street,
      number: company.streetNumber,
      postalCode: company.postalcode,
      city: company.city,
      phone: '',
      isCompanyAddress: true,
      lexOfficeNumber: company.lexOfficeNumber,
      lexOfficeContactId: company.lexOfficeContactId
    };
    let isAddressInArray = false;
    this.availableBillingAddresses.forEach(billingAddr => {
      if (_.isEqual(companyBillingAddress, billingAddr)) {
        isAddressInArray = true;
      }
    });
    if (!isAddressInArray) {
      this.availableBillingAddresses.push(companyBillingAddress);
    }
  }

  async updateInvoiceData() {
    this.isTableLoading = true;
    this.suborderList = await this.ps.getOpenIndividualPaymentSuborders();
    this.userUIDCompanyNameMap = this.getUserUIDCompanyNameMap();

    console.log(this.logName + 'Updating invoice data.');

    if (!this.suborderList || this.suborderList.length === 0) {
      console.log(this.logName + 'Can\'t update data because of empty array.');
      return;
    }

    this.invoiceData = new MatTableDataSource([]);
    let tableData: PaymentTableData[] = this.invoiceData.data;
    this.suborderList.forEach((suborder: SubOrder) => {
      if (suborder.invoice_details) {
        const completedOn = new Date(suborder.completedOn);
        const details = {
          discount: 0.0,
          createdOn: new Date(suborder.createdOn),
          realestateId: suborder.realestateId,
          realestateName: suborder.Desc,
          suborderId: suborder.id,
          orderId: suborder.orderId,
          payments: suborder.payments ? suborder.payments : '',
          completedOn: suborder.completedOn ? suborder.completedOn : '',
          discount_type: '%',
          chargingInvoiceUserRef: undefined,
          subsidisedVolume: undefined,
          total: undefined,
          total_formatted: undefined,
          total_unformatted: undefined,
          quantity: undefined,
          price_formatted: undefined,
          description: undefined,
          'accounting-position': undefined
        };

        // If for the the suborder is part of a product bundle which is subsidiesed then exactly one entry with the subsidyVolume has to be added.
        // Note that only one suborder of a (product-bundle) order has the entry chargingInvoiceUserRef set.
        if (suborder.productBundleData?.chargingInvoiceUserRef && suborder.productBundleData?.subsidisedVolume) {
          details.chargingInvoiceUserRef = suborder.productBundleData.chargingInvoiceUserRef;
          details.subsidisedVolume = suborder.productBundleData.subsidisedVolume;
          details.total = details.subsidisedVolume;
          details.total_unformatted = details.subsidisedVolume;
          details.total_formatted = accounting.formatMoney(details.total_unformatted, '', 2, '.', ',');
          details.quantity = 1;
          details.price_formatted = accounting.formatMoney(details.subsidisedVolume, '', 2, '.', ',');
          details.description = 'Zuschuss Produktpaket (' + suborder.productBundleData.title + ')';
          tableData = this.addEntry(tableData, suborder, details, suborder.productBundleData.subsidisedVolume, true); // Create Invoice entry for chargingInvoiceUserRef
        }
        const isPrepaymentSuborder = this.isPrepaymentSuborder(suborder);
        if (!this.restrictDates.value || this.startDate <= completedOn && this.endDate >= new Date(completedOn.setDate(completedOn.getDate() - 1)) || isPrepaymentSuborder) {
          suborder.invoice_details.forEach((invDetail) => {
           if (!invDetail['discount']) {
             invDetail['discount'] = 0.0;
            }
            if (!invDetail['discount_type']) {
              invDetail['discount_type'] = '%';
            }

            let total_sum = 0.0;
            if (invDetail['accounting-position']) {
              total_sum = invDetail.quantity * invDetail.total * (1 - invDetail.discount);
              if (isPrepaymentSuborder && ((invDetail.paid_amount || 0) === 0)) {
                total_sum /= 2;
              }
              invDetail['price_formatted'] = accounting.formatMoney(invDetail.total, '', 2, '.', ',');
              invDetail['total_formatted'] = accounting.formatMoney(total_sum - (invDetail.paid_amount || 0), '', 2, '.', ',');
            } else {
              total_sum = invDetail.total * (1 - invDetail.discount);
              invDetail['price_formatted'] = '';
              invDetail['total_formatted'] = accounting.formatMoney(this.moneyService.stringToFloat(total_sum - (invDetail.paid_amount || 0)), '', 2, '.', ',');
            }
            if (typeof total_sum === 'string') {
              total_sum = accounting.unformat(total_sum, ',');
            }
            invDetail['total_unformatted'] = total_sum;
            if (suborder.completedOn) {
              invDetail['completedOn'] = new Date(suborder.completedOn);
            }

            tableData = this.invoiceData.data;

            // when detail entry is a suborder discount then save the charging invoice user ID
            if (invDetail['accounting-position'] === 'totalDiscount' + DiscountTypeEnum.Percentage || invDetail['accounting-position'] === 'totalDiscount' + DiscountTypeEnum.Monetary) {
              invDetail['discountChargingInvoiceUserRef'] = suborder.discount && suborder.discount.chargingInvoiceUserRef;
            }

            // when detail entry is a position discount with a chargingInvoiceUser
            if (invDetail.chargingInvoiceUserRef) {
              invDetail.discountChargingInvoiceUserRef = invDetail.chargingInvoiceUserRef;
              const subsidy = invDetail.total * invDetail.discount;
              const subsidyTotal = subsidy * invDetail.quantity;

              // To create the "duplicate" for subsidies discounts overwrite the corresponding fields in the details copy.
              const detailCopy = {
                ...details,
                ...invDetail,
                price_formatted: accounting.formatMoney(subsidy, '', 2, '.', ','),
                total: subsidyTotal,
                total_formatted: accounting.formatMoney(subsidyTotal, '', 2, '.', ','),
                total_unformatted: subsidyTotal,
                description: invDetail.description + ' (Zuschuss von ' + invDetail.discount * 100 + '%)',
                uid: invDetail.chargingInvoiceUserRef,
                Desc: suborder.Desc
              };

              tableData = this.addEntry(tableData, suborder, detailCopy, total_sum, true); // Create Invoice entry for chargingInvoiceUserRef
              console.log(tableData);
            }

            if (total_sum - (invDetail.paid_amount || 0) !== 0) {
              tableData = this.addEntry(tableData, suborder, {...details, ...invDetail}, total_sum);
            }

            tableData.forEach((customer) => {
              customer['invoice_details'] = _.sortBy(customer['invoice_details'], 'realestateId');
            });

          });
          this.invoiceData = new MatTableDataSource(tableData);
          this.setTableFilter();
        }
        // }
      }
      // temporary method to reset payments status of suborder
      // if (suborder.payments === 'invoice' || suborder.payments === 'paid') {
      //   console.log(suborder, suborder.payments);
      //   this.os.changeSuborder(suborder.id, 'payments', '');
      // }
      this.isInvoiceDataUpdated = true;
      this.deactivateTableLoading();
    });
  }

  private deactivateTableLoading() {
    if (this.isInvoiceDataUpdated && this.isOrderFetchCompleted) {
      this.isTableLoading = false;
    }
  }

  private isPrepaymentSuborder(suborder: SubOrder): boolean {
    const curOrder = this.ordersList.find((order: Order) => order.orderId === suborder.orderId);
    const approvedOn = curOrder?.approvedOn ? new Date(curOrder?.approvedOn) : undefined;
    const completedOn = curOrder?.completedOn ? new Date(curOrder?.completedOn) : undefined;
    return curOrder && approvedOn && !completedOn && this.ms.stringToFloat(curOrder.price) > CONSTANTS.ORDER_PRICE_DEPOSIT_VALUE && this.startDate <= approvedOn && this.endDate >= new Date(approvedOn.setDate(approvedOn.getDate() - 1));
  }

  addEntry(tableData: PaymentTableData[], suborder, details, total_sum, subsidyEntry = false) {
    let index = tableData.findIndex(invoice => invoice.uid === suborder.createdBy);
    if (this.selectedGroupOption === PaymentGroupOptions.Company) {
      index = tableData.findIndex(invoice => invoice.company === this.userUIDCompanyNameMap.get(suborder.createdBy));
    }

    if (subsidyEntry && details.chargingInvoiceUserRef) {
      index = tableData.findIndex(invoice => invoice.uid === details.chargingInvoiceUserRef);
    }

    if (index !== -1) { // existing user / company
      const sum = subsidyEntry ? details.total : total_sum;
      details.discount = 0;
      const indexRealestate = _.findIndex(tableData[index].invoice_details, function (data: any) { return data.realestateId === suborder.realestateId; }, this);
      if (indexRealestate !== -1) { // Existing real estate
        tableData[index]['invoice_details'][indexRealestate]['details'].push(details);
        if (!tableData[index]['invoice_details'][indexRealestate].suborderIds.find(suborderId => suborderId === suborder.id)) {
          tableData[index]['invoice_details'][indexRealestate].suborderIds.push(suborder.id);
        }
        this.suborderIdSelectionDict[suborder.id] = true;
      } else { // new real estate
        tableData[index]['invoice_details'].push({
          realestateId: details.realestateId,
          realestateName: details.realestateName,
          details: [details],
          suborderIds: [suborder.id]
        });
        this.suborderIdSelectionDict[suborder.id] = true;
      }
      tableData[index]['sum'] += sum;
      tableData[index]['sum_formatted'] = accounting.formatMoney(tableData[index]['sum'], '', 2, '.', ',');
    } else {
      const uid = subsidyEntry ? details.chargingInvoiceUserRef : suborder.createdBy;
      const sum = subsidyEntry ? details.total : total_sum;
      details.discount = 0;
      const user = this.uls.allUsers.find(u => u.uid === uid);
      tableData.push({
        'uid': uid,
        'invoice_details': [{
          realestateId: details.realestateId,
          realestateName: details.realestateName,
          details: [details],
          suborderIds: [suborder.id]
        }],
        'sum': sum,
        'sum_formatted': accounting.formatMoney(sum, '', 2, '.', ','),
        'name': user.firstName + ' ' + user.lastName,
        'company': user.companyName
      });
      this.suborderIdSelectionDict[suborder.id] = true;
    }

    return tableData;
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    switch (type) {
      case 'start':
        this.startDate = event.value;
        break;
      case 'end':
        this.endDate = event.value;
        break;
    }
    if (this.startDate && this.endDate) {
      this.updateInvoiceData();
    }
  }

  private updateChargingUserInvoicePositions(chargingUserInvoicePositions: any, discountChargingInvoiceUserId: string, invoicePositionData: any) {
    // The charging invoice user should see the discounted price with a positive sign (eg: 100€), instead of negative (eg: -100€)
    const invoicePositionDataShallowClone = {
      ...invoicePositionData,
      total: Math.abs(invoicePositionData.total),
      total_unformatted: Math.abs(invoicePositionData.total_unformatted)
    };

    if (!chargingUserInvoicePositions[discountChargingInvoiceUserId]) {
      chargingUserInvoicePositions[discountChargingInvoiceUserId] = [];
    }

    chargingUserInvoicePositions[discountChargingInvoiceUserId].push(invoicePositionDataShallowClone);
  }

  createInvoice(element) {
    const selectedInvoiceDetailGroup = this.getSelectedInvoiceDetailGroup();
    // Create invoice data
    const invoice = {
      billingAddress: this.chosenAddress,
      'invoice_details': this.getInvoiceDetailPositions(selectedInvoiceDetailGroup),
      realestateIds: selectedInvoiceDetailGroup.map((paymentInvoiceDetail: PaymentInvoiceDetail) => paymentInvoiceDetail.realestateId),
      realestateNames: selectedInvoiceDetailGroup.map((paymentInvoiceDetail: PaymentInvoiceDetail) => paymentInvoiceDetail.realestateName),
      orderIds: this.getOrderIdsOfInvoiceDetailGroup(selectedInvoiceDetailGroup),
      status: PaymentStatusEnum.Invoice,
      total: this.getTotalInvoiceDetailGroupPrice(selectedInvoiceDetailGroup),
      uid: element.uid
    };
    // TODO: preferredEMail
    this.invoiceToCreate = invoice;
    this.ngxSmartModalService.resetModalData('invoiceCreationModal');
    this.ngxSmartModalService.setModalData(this.invoiceToCreate, 'invoiceCreationModal');
    this.ngxSmartModalService.open('invoiceCreationModal');
  }

  private getOrderIdsOfInvoiceDetailGroup(pids: PaymentInvoiceDetail[]): string[] {
    let suborderIds: string[];
    pids.forEach((pid: PaymentInvoiceDetail) => {
      suborderIds = pid.details.map((pcd: PaymentCreationDetail) => pcd.suborderId);
    });
    // Get unique Values
    suborderIds = suborderIds.filter((item, i, ar) => ar.indexOf(item) === i);

    const orderIds: string[] = [];
    suborderIds.forEach(sid => {
      const newOrderId = this.suborderList.find(suborder => suborder.id === sid).orderId;
      if (newOrderId && !orderIds.find(orderId => orderId === newOrderId)) {
        orderIds.push(newOrderId);
      }
    });

    return orderIds;
  }

  private getTotalInvoiceDetailGroupPrice(pids: PaymentInvoiceDetail[]): number {
    let sum = 0;
    pids.forEach((pid: PaymentInvoiceDetail) => {
      sum += pid.total || 0;
    });

    return sum;
  }

  private getSelectedInvoiceDetailGroup(): PaymentInvoiceDetail[] {
    const selectedInvoiceDetailGroup: PaymentInvoiceDetail[] = [];
    const currentInvoiceDetailGroup = JSON.parse(JSON.stringify(this.currentInvoiceDetailGroup));
    currentInvoiceDetailGroup.forEach((paymentInvoiceDetail: PaymentInvoiceDetail) => {
      if (paymentInvoiceDetail.isSelected) {
        paymentInvoiceDetail.details = paymentInvoiceDetail.details.filter((pcd: PaymentCreationDetail) => pcd.isSelected);
        selectedInvoiceDetailGroup.push(paymentInvoiceDetail);
      }
    });

    return selectedInvoiceDetailGroup;
  }

  private getInvoiceDetailPositions(pids: PaymentInvoiceDetail[]): InvoiceDetail[] {
    const invoiceDetails: InvoiceDetail[] = [];

    pids.forEach((pid: PaymentInvoiceDetail) => {
      pid.details.forEach((pcd: PaymentCreationDetail) => {
        invoiceDetails.push({
          quantity: pcd.quantity,
          description: pcd.description,
          sub_text: '', // TODO
          total: pcd.total, // Unit price!
          'accounting-position': pcd['accounting-position'],
          discount: pcd.discount,
          suborderId: pcd.suborderId,
          real_estate_name: pcd.realestateName
        });
      });
    });

    return invoiceDetails;
  }

  private createUserBillingAddress(user: any) {
    return {
      'name': user.firstName + ' ' + user.lastName,
      'email': user.email || '',
      'company': user.companyName || '',
      'taxId': user.ustid || '',
      'street': user.street || '',
      'number': user.streetNumber || '',
      'postalCode': user.postalcode || '',
      'city': user.city || '',
      'phone': user.phone || '',
      'reachability': user.reachability || ''
    };
  }

  filterTable(event: Event, column: string): void {
    this.noSort(event);
    const query = event.currentTarget['value'].trim().toLowerCase();
    this.filterValues[column] = query;
    /**
     * Setting it to random string because we need to call filter even if query is empty for current column as we might have filter on other columns.
     */
    this.invoiceData.filter = 'test';
  }

  noSort(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  setTableFilter(): void {
    const self = this;
    self.invoiceData.filterPredicate = (object, filter) => {
      let flag = true;
      Object.keys(self.filterValues).forEach(key => {
        if (self.filterValues[key] && object[key].toLowerCase().indexOf(self.filterValues[key]) < 0) {
          flag = false;
        }
      });
      return flag;
    };
  }

  ngOnDestroy() {
    console.log(this.logName + 'Destroying component.');
    this.subscriptions.forEach(sub => sub.unsubscribe());
/*    this.webSocketService.unsubscribeToSubordersList();
    this.webSocketService.unsubscribeOrders();*/
  }

  /**
   * @param id: Suborder Id
   * Sets the realestate view to the overview-mode and routes to the selected suborder.
   */
  showSuborder(id: string) {
    this.router.navigate(['orderoverview/suborder/' + id], { queryParams: { route: 'payments' } });
  }

  toggleSelectionRealEstate(event: MouseEvent, realestateIndex: number) {
    event.preventDefault();
    event.stopPropagation();
    const estateInvoiceDetails = this.currentInvoiceDetailGroup[realestateIndex];
    estateInvoiceDetails.isSelected = !estateInvoiceDetails.isSelected;
    estateInvoiceDetails.details.forEach((detail: PaymentCreationDetail) => {
      detail.isSelected = !detail.isSelected;
    });

    this.currentInvoiceDetailGroup[realestateIndex].suborderIds.forEach(suborderId => {
      if (!estateInvoiceDetails.isSelected) {
        this.suborderIdSelectionDict[suborderId] = false;
      }
    });
    this.currentInvoiceDetailGroup[realestateIndex].total = this.getRealEstateSum(estateInvoiceDetails);
    this.refreshSelectedInvoiceDetailPositions();
  }

  groupOptionChanged(event: MatRadioChange) {
    if (event.value === PaymentGroupOptions.User) {
      this.columnsToDisplay = this.columnsUser;
    } else {
      this.columnsToDisplay = this.columnsCompany;
    }
    this.selectedGroupOption = event.value;
    this.updateInvoiceData();
    console.log(event.value);
  }

  private getUserUIDCompanyNameMap() {
    const uidCompanyMap: Map<string, string> =  new Map();

    this.suborderList.forEach(suborder => {
      if (!uidCompanyMap.has(suborder.createdBy) && this.uls.allUsers.find(user => user.uid === suborder.createdBy)?.companyName) {
        uidCompanyMap.set(suborder.createdBy, this.uls.allUsers.find(user => user.uid === suborder.createdBy).companyName);
      }
    });

    return uidCompanyMap;
  }

  toggleSuborderSelection(event, realestate: PaymentInvoiceDetail, suborderId: string) {
    event.preventDefault(); // Else the checkbox would be wrongly en-/disabled.
    event.stopPropagation();
    if (realestate.isSelected) { // Because we prevent default behaviour.
      this.suborderIdSelectionDict[suborderId] = !this.suborderIdSelectionDict[suborderId];
      this.refreshSelectedInvoiceDetailPositions();
    }
  }

  private refreshSelectedInvoiceDetailPositions() {
    this.currentInvoiceDetailGroup.forEach((realestateInvoiceDetail: PaymentInvoiceDetail) => {
      realestateInvoiceDetail.details.forEach(detail => {
        detail.isSelected = this.suborderIdSelectionDict[detail.suborderId];
      });
      realestateInvoiceDetail.total = this.getRealEstateSum(realestateInvoiceDetail);
    });
  }

  async createAndSendInvoice() {
    this.isInvoiceCreationExecuted = true;
    this.auth.showLoader.emit(true);
    await this.ivls.createIndividualInvoice(this.invoiceToCreate);
    this.ngxSmartModalService.close('invoiceCreationModal');
    this.updateInvoiceData();
    this.auth.showLoader.emit(false);
    this.isInvoiceCreationExecuted = false;
  }
}
