import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldFilter'
})
export class FieldFilterPipe implements PipeTransform {

  transform(items: any[], field: string, value: string | boolean, sortField: string): any[] {
    if (!items || !field || !value) {
        return items;
    }

    const filteredItems = items.filter(item => item[field] === value);
    filteredItems.sort((a: any, b: any) => {
      return a[sortField] > b[sortField] ? 1 : -1;
    });
    return filteredItems;
}

}
