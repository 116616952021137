import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { CONSTANTS } from "@app/util/constants";
import { CountryCodeList, CountryEnum } from "@app/models/country-code-list";
import { BillingAddress } from "@app/interfaces/billing-address.interface";
import { GlobalService } from "@app/_services/global.service";
import { BillingAddressFormStatus } from "@app/interfaces/billing-address-form-status.interface";

@Component({
  selector: "app-billing-address-form",
  templateUrl: "./billing-address-form.component.html",
  styleUrls: ["./billing-address-form.component.css"],
})
export class BillingAddressFormComponent implements OnInit, AfterViewInit {
  billingAddressForm: FormGroup;
  protected _onDestroy = new Subject<void>();
  countryCodeList = CountryCodeList;

  @Input() isPrivateIndividualUser: boolean;
  @Input() showEndCustomerOptions: boolean;
  @Input() userEMail: string;
  @Input() initialBillingAddress: BillingAddress;
  @Input() isDisabled: boolean;
  @Output() billingAddressChanged: EventEmitter<BillingAddressFormStatus> = new EventEmitter();

  initialBillingAddressCopy: BillingAddress;
  CONSTANTS = CONSTANTS;

  constructor(private fb: FormBuilder, protected gs: GlobalService) {}

  get company() {
    return this.billingAddressForm.get("company");
  }

  get street() {
    return this.billingAddressForm.get("street");
  }

  get number() {
    return this.billingAddressForm.get("number");
  }

  get postalCode() {
    return this.billingAddressForm.get("postalCode");
  }

  get city() {
    return this.billingAddressForm.get("city");
  }

  get country() {
    return this.billingAddressForm.get("country");
  }

  get email() {
    return this.billingAddressForm.get("email");
  }

  get phone() {
    return this.billingAddressForm.get("phone");
  }

  get taxId() {
    return this.billingAddressForm.get("taxId");
  }

  get firstName() {
    return this.billingAddressForm.get("firstName");
  }

  get lastName() {
    return this.billingAddressForm.get("lastName");
  }

  get sentInvoiceToEndCustomer() {
    return this.billingAddressForm.get("sentInvoiceToEndCustomer");
  }

  get useAccountEmail() {
    return this.billingAddressForm.get("useAccountEmail");
  }

  ngOnInit() {
    // Creating a shallow copy of the initial billing address because we don't want to change the original address.
    this.initialBillingAddressCopy = { ...this.initialBillingAddress };
    let firstNameValidators = [];
    let lastNameValidators = [];
    let companyValidators = [];
    let taxIdValidators = [];

    if (this.isPrivateIndividualUser) {
      firstNameValidators.push(Validators.required);
      lastNameValidators.push(Validators.required);
    } else {
      companyValidators.push(Validators.required);
    }

    if (this.initialBillingAddressCopy?.country === CountryEnum.Germany) {
      taxIdValidators.push(CONSTANTS.VALIDATION_PATTERN.TAXID_GER);
    }

    this.billingAddressForm = this.fb.group({
      invoiceHeader: [""],
      firstName: [this.initialBillingAddressCopy?.firstName, firstNameValidators],
      lastName: [this.initialBillingAddressCopy?.lastName, lastNameValidators],
      company: ["", companyValidators],
      taxId: ["", taxIdValidators],
      street: ["", [Validators.required, Validators.pattern("^\\D+$")]],
      number: ["", [Validators.required]],
      postalCode: ["", [Validators.required, Validators.pattern("^[0-9]{4,5}$")]],
      city: ["", [Validators.required]],
      country: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.pattern(CONSTANTS.EMAILREGEX)]],
    });

    if (this.showEndCustomerOptions) {
      this.billingAddressForm.addControl("sentInvoiceToEndCustomer", this.fb.control(false));
      this.billingAddressForm.addControl("useAccountEmail", this.fb.control(false));
    } else {
      delete this.initialBillingAddressCopy?.sentInvoiceToEndCustomer;
      delete this.initialBillingAddressCopy?.useAccountEmail;
    }

    this.billingAddressForm.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => this.billingAddressFormChanged());

    if (this.initialBillingAddressCopy) {
      this.billingAddressForm.patchValue(this.initialBillingAddressCopy);
    }
  }

  ngAfterViewInit(): void {
    this.billingAddressForm.controls.sentInvoiceToEndCustomer?.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((value) => {
        if (value) {
          this.firstName.setValidators([Validators.required]);
          this.lastName.setValidators([Validators.required]);
        } else {
          this.firstName.clearValidators();
          this.lastName.clearValidators();
        }
        this.billingAddressForm.updateValueAndValidity();
      });

    this.billingAddressForm.controls.useAccountEmail?.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((value) => {
        if (value) {
          this.billingAddressForm.controls.email.setValue(this.userEMail);
        } else {
          this.billingAddressForm.controls.email.setValue(null);
        }
        this.billingAddressForm.updateValueAndValidity();
      });

    this.billingAddressForm.updateValueAndValidity();
  }

  billingAddressFormChanged() {
    this.billingAddressChanged.emit({
      billingAddress: this.billingAddressForm.value,
      isValid: !this.billingAddressForm.invalid,
    });
  }

  updateValidators() {
    if (this.country.value === CountryEnum.Germany) {
      this.taxId.setValidators([CONSTANTS.VALIDATION_PATTERN.TAXID_GER]);
    } else {
      this.taxId.clearValidators();
    }
    this.taxId.markAsTouched();
    this.taxId.updateValueAndValidity();
    this.billingAddressForm.updateValueAndValidity();
  }

  setBillingAddress(billingAddress: BillingAddress) {
    this.billingAddressForm.reset();
    this.billingAddressForm.patchValue(billingAddress);
  }

  disableFormControls() {
    Object.keys(this.billingAddressForm.controls).forEach((key) => {
      this.billingAddressForm.controls[key].disable();
    });
  }

  enableFormControls() {
    Object.keys(this.billingAddressForm.controls).forEach((key) => {
      this.billingAddressForm.controls[key].enable();
      this.billingAddressForm.controls[key].markAsTouched();
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
