import { Injectable } from '@angular/core';
import { CONSTANTS } from '@app/util/constants';

@Injectable({
  providedIn: 'root'
})
export class BroadcasterService {
  listeners: Function[] = [];

  constructor() {
    window.addEventListener(CONSTANTS.EVENTS.STORAGE, this.receiveMessage.bind(this));
  }

  // use local storage for messaging. Set message in local storage and clear it right away
  // This is a safe way how to communicate with other tabs while not leaving any traces
  //
  sendMessage(message: any) {
    localStorage.setItem(CONSTANTS.LOCAL_STORAGE_KEYS.MESSAGE, JSON.stringify(message));
    localStorage.removeItem(CONSTANTS.LOCAL_STORAGE_KEYS.MESSAGE);
  }


  // receive message
  //
  receiveMessage(ev: any) {
      if (ev.key !== CONSTANTS.LOCAL_STORAGE_KEYS.MESSAGE) return; // ignore other keys

      const message = JSON.parse(ev.newValue);

      if (!message) return; // ignore empty msg or msg reset

      // call all listeners with the received message data
      this.listeners.forEach((listenerFn) => {
        listenerFn(message);
      });
  }

  registerListener(listener: Function) {
    this.listeners.push(listener);
  }

  unregisterListener(listener: Function) {
    const index = this.listeners.indexOf(listener);
    this.listeners.splice(index, 1);
  }
}
