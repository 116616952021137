import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";
import { Observable } from "rxjs/internal/Observable";
import { LexofficeContact } from "@app/interfaces/lexoffice-contact.interface";

@Injectable({
  providedIn: "root",
})
export class ApisService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.APIS;

  constructor(private http: HttpClient) {}

  callGet<Type>(path: string): Observable<Type> {
    return this.http.get<Type>(this.endpoint + "/" + path);
  }

  callPut(path: string, body: any) {
    return this.http.put<LexofficeContact>(this.endpoint + "/" + path, body);
  }

  callPost(path: string, body: any) {
    return this.http.post(this.endpoint + "/" + path, body);
  }

  callDelete(path) {
    return this.http.delete(this.endpoint + "/" + path);
  }
}
