export enum PaymentStatusEnum {
  Undefined = '',
  Invoice = 'invoice',
  Paid = 'paid',
  None = 'none',
  Prepayment = 'prepayment',
  Underpaid = 'underpaid',
  Overpaid = 'overpaid',
  Canceled = 'canceled',
  Error = 'error',
  Feedback = 'feedback' // Used for when the user buys more feedback after a finished suborder
}
