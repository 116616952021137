<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-1 border-bottom bbcg">
  <h2>Benachrichtigungen</h2>
  <div class="btn-group float-right">
    <!-- <button class="btn btn-sm btn-outline-secondary">Änderungen speichern</button> -->
  </div>
</div>
<div class="shadow card cgrid">
  <div class="card-header">
    <a class="my-link" (click)="active = !active" [attr.aria-expanded]="true" aria-controls="collapse1">
      <h5 class="card-title text-left wsn">Information</h5>
    </a>
  </div>
  <div id="collapse1" [collapse]="!active">
    <div class="card-body">
      <p class="card-text">Hier können Sie alle für Sie relevanten Benachrichtigungen einsehen.</p>
      <p class="card-text mb-0">Dieses Feature befindet sich noch in der Entwicklung. Vielen Dank für Ihr Verständnis.
      </p>
    </div>
  </div>
</div>