import { Injectable } from "@angular/core";
import { RealEstate } from "@app/interfaces/real-estate.interface";
import { CONSTANTS } from "@app/util/constants";
import { RealestateService } from "@app/_services/realestate.service";
import { Observable, of, Subject } from "rxjs";
import { UserRoleEnum } from "@app/models/user-role-list";
import { CompanyRoleEnum } from "@app/models/company-role-list";
import { tap } from "rxjs/operators";
import { AuthService } from "@app/auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class RealestateLogicService {
  realEstateList: RealEstate[] = [];
  totalRealEstates: number;
  realEstateListChanged = new Subject<void>();
  providedRealEstate;
  currentRealEstateChanged = new Subject(); // For services that implicitly define a real estate (like priceHubble)

  // View States:
  add = false;
  edit = false;
  showSuborderList = false;

  constructor(private res: RealestateService, private auth: AuthService) {}

  /**
   * Gets real estates in the scope of the users. Sets list of internal real estates.
   */
  getRealestates(ignoreRole = false) {
    let realEstates$: Observable<QueryResult<RealEstate>>;

    if (
      this.auth.myUserObservable.role === UserRoleEnum.Customer &&
      this.auth.myUserObservable.companyRole === CompanyRoleEnum.CompanyManager &&
      this.auth.myUserObservable.company &&
      !ignoreRole
    ) {
      realEstates$ = this.getMyCompanyRealEstates();
    } else {
      realEstates$ = this.getMyRealestates();
    }

    return realEstates$.pipe(tap(this.setListData.bind(this)));
  }

  /**
   * Sets the real estate list.
   * @param data The data representing the real estates with a total items info.
   */
  private setListData(data: QueryResult<RealEstate>) {
    const estates = data.data;
    this.realEstateList = [...estates];
    this.totalRealEstates = data.total_hits;
  }

  /**
   * Get real estate by id or objectNumber
   * @param realEstateKey the real estate id OR real estate objectNumber
   */
  getRealestate(realEstateKey: string): Observable<RealEstate> {
    // search the item locally 1st
    const localRealEstate = this.realEstateList.find(
      (item) => item.id === realEstateKey || item.objectNumber === realEstateKey
    );

    if (localRealEstate) {
      // returning founded item
      return of(localRealEstate);
    } else {
      // fetching from database because it wasn't found locally
      return this.res.getRealEstate(realEstateKey).pipe(
        tap((realEstate: RealEstate) => {
          // adding to local list
          this.realEstateList.push(realEstate);
        })
      );
    }
  }

  /**
   * Creates a new real estate in the database
   * @param realEstateData The Real Estate Data
   */
  addRealEstate(realEstateData: RealEstate) {
    return this.res.postRealEstate(realEstateData).pipe(
      tap((realEstate: RealEstate) => {
        this.realEstateList.push(realEstate);
        // inform event subscribers that the list changed
        this.realEstateListChanged.next();
      })
    );
  }

  /**
   * Queries realEstates in the scope of the user using a keyValue Map as the query
   * @param keyValueQuery Typescript Dictionary with key, value pairs
   * @param from start from element number
   * @param to until element number
   */
  queryRealEstates(keyValueQuery: [string, string | number][], from = 0, to = 20): Observable<QueryResult<RealEstate>> {
    let query = "?";
    for (let i = 0; i < keyValueQuery?.length; i++) {
      if (i > 0) {
        query += "&";
      }
      query += keyValueQuery[i][0] + "=" + keyValueQuery[i][1];
    }
    query += "&" + "from=" + from + "&to=" + to;

    return this.res.getRealEstates(query);
  }

  /**
   * Updates the given realEstate. id will be automatically extracted from the real estate
   * @param realEstate The updated Real Estate
   */
  updateRealEstate(realEstate: RealEstate) {
    return this.res.patchRealEstate(realEstate.id, realEstate).pipe(
      tap((updatedRealEstate: RealEstate) => {
        const foundIndex = this.realEstateList.findIndex((item) => item.id === realEstate.id);

        // replace item with new data, but keep properties already changed in case the update is only partial
        this.realEstateList[foundIndex] = {
          ...this.realEstateList[foundIndex],
          ...updatedRealEstate,
        };

        // inform event subscribers that the list changed
        this.realEstateListChanged.next();
      })
    );
  }

  /**
   * Filter the real estates list.
   * @param inputArray The real estates list.
   * @param filterValueInput The filtering value.
   * @param isJLL A flag indicating if its JLL real estates.
   * @returns The filtered real estates list.
   */
  filterEstates(inputArray: RealEstate[], filterValueInput: string): RealEstate[] {
    const filterValue = filterValueInput.toLowerCase();
    const filteredRealEstateList = inputArray.filter((realEstate: RealEstate) => {
      const externalData = realEstate?.externalInformation?.data;
      return (
        realEstate?.isArchived !== true &&
        realEstate?.title !== CONSTANTS.REAL_ESTATE_SAVE_FOR_LATER.TITLE &&
        (realEstate?.title?.toLowerCase().includes(filterValue) ||
          realEstate?.street?.toLowerCase().includes(filterValue) ||
          realEstate?.streetnumber?.toLowerCase().includes(filterValue) ||
          realEstate?.city?.toLowerCase().includes(filterValue) ||
          realEstate?.postalcode?.toLowerCase().includes(filterValue))
      );
    });

    return filteredRealEstateList;
  }

  /**
   * Returns an Observable with the realEstate of the current user.
   * @param from from parameter, used for pagination
   * @param to to parameter, used for pagination
   */
  getMyRealestates(from = 0, to = CONSTANTS.REAL_ESTATES_DEFAULT_PAGE_SIZE): Observable<QueryResult<RealEstate>> {
    const data = {
      from: from.toString(),
      to: to.toString(),
    };

    return this.res.getRealEstates("my", data);
  }

  /**
   * Returns an Observable of real estates of the users company.
   * @param from from parameter, used for pagination
   * @param to to parameter, used for pagination
   */
  getMyCompanyRealEstates(
    from = 0,
    to = CONSTANTS.REAL_ESTATES_DEFAULT_PAGE_SIZE
  ): Observable<QueryResult<RealEstate>> {
    const data = {
      from: from.toString(),
      to: to.toString(),
    };

    return this.res.getRealEstates("my-company", data);
  }

  /**
   * Deletes a real estate.
   * @param realestate RealEstate object to be deleted.
   */
  async deleteRealestate(realestate: RealEstate) {
    await this.res.deleteRealestate(realestate);
    this.realEstateList = this.realEstateList.filter((item) => item.id !== realestate.id);
    this.realEstateListChanged.next();
  }

  /**
   * Sort a real estates list.
   * @param inputArray The real estate list to be sorted.
   * @returns The sorted real estate list.
   */
  sortEstates(inputArray: RealEstate[]): RealEstate[] {
    return inputArray.sort((a: RealEstate, b: RealEstate) => {
      const aTitle = a.title.toLowerCase();
      const bTitle = b.title.toLowerCase();
      return aTitle < bTitle ? -1 : aTitle > bTitle ? 1 : 0;
    });
  }

  /**
   * Deletes a floor of the realEstate
   * @param realEstate The realEstate Object
   * @param floorIndex The index of the floor that will be deleted
   */
  async deleteFloor(realEstate: RealEstate, floorIndex: number) {
    const index = realEstate.floors?.findIndex((x) => x.index === floorIndex);
    console.log(realEstate.floors, index, floorIndex);
    if (index > -1) {
      realEstate.floors.splice(index, 1);
    }
    console.log(realEstate.floors);
    try {
      await this.res.patchRealEstate(realEstate.id, realEstate).toPromise();
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Changs status of a single floor
   * @param realEstate The realEstate
   * @param floorIndex The floors whichs status will be changed.
   */
  async changeFloorStatus(realEstate: RealEstate, floorIndex: number) {
    return this.res.changeStatus(realEstate, floorIndex);
  }

  /**
   * Handles status changes (of the isActive flag) in the floors of the realEstate.
   * @param realEstate The realEstate with already updated isActive flag.
   */
  async changeRealestateStatus(realEstate: RealEstate) {
    realEstate.floors?.forEach((floor) => {
      floor.isActive = realEstate.isActive;
    });

    return this.res.changeStatus(realEstate);
  }

  /**
   * Updates the companyId of all real estates of the given user
   * @param uid The user whose real estates are going to be updated.
   */
  updateRealEstatesOfUser(uid: string) {
    return this.res.patchRealEstate("update-real-estates-of-user/" + uid).toPromise();
  }

  updatePlanetHomeBrokerDetails() {
    return this.res.updatePlanetHomeBrokerDetails().toPromise();
  }

  setProvidedRealEstate(providedRealEstate: Partial<RealEstate>) {
    this.currentRealEstateChanged.next(providedRealEstate);
    this.providedRealEstate = {
      ...providedRealEstate,
      streetNumber: providedRealEstate?.streetnumber,
    };
  }
}
