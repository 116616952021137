import { Injectable } from '@angular/core';
import { FeedbackToolModeEnum } from '@app/models/feedback-tool-mode.enum';
import { McGrundrissService } from '@app/_services/mc-grundriss.service';
import {SubOrder} from '@app/interfaces/suborder.interface';

@Injectable()
export class McGrundrissLogicService {

  constructor(
    private mcs: McGrundrissService
  ) {
  }

  /**
   * Creates order for McGrundriss via our Backend
   * @param suborderId suborder Id
   */
  createOrderBackend(suborderId: SubOrder['id']) {
    console.log('create order backend', suborderId);
    return this.mcs.callPost('create-order', { suborderId: suborderId }).subscribe(
      val => {
        console.log("POST call successful value returned in body",
          val);
        console.log(val);
      },
      response => {
        console.log("POST call in error", response);
      },
      () => {
        console.log("The POST observable is now completed.");
      }
    );
  }

  /**
   * Returns all McGrundriss orders from our Backend
   */
  getAllBackend() {
    console.log('get all orders backend');
    return this.mcs.callGet('').subscribe(
      val => {
        console.log("GET call successful value returned in body",
          val);
        console.log(val);
      },
      response => {
        console.log("GET call in error", response);
      },
      () => {
        console.log("The GET observable is now completed.");
      }
    );
  }

  /**
   * Gets infos about mcGrundriss order from our backend
   * @param orderId corresponding orderId
   */
  getOrderBackend(orderId: string) {
    console.log('get order details backend');
    return this.mcs.callGet(orderId).subscribe(
      val => {
        console.log("GET call successful value returned in body",
          val);
        console.log(val);
      },
      response => {
        console.log("GET call in error", response);
      },
      () => {
        console.log("The GET observable is now completed.");
      }
    );
  }

  /**
   * Sends feedback to McGrundriss for the specified suborder
   * @param suborderId The corresponding suborder id
   * @param feedbackNumber The feedbackNumber
   * @param mode FeedbackToolModeEnum: normal, rejectResults, forwardFeedback. Should be either rejectResults or forward Feedback in this case.
   */
  sendFeedbackBackend(suborderId: string, feedbackNumber: number, mode: FeedbackToolModeEnum) {
    console.log('send feedback backend', suborderId, feedbackNumber, mode);
    return this.mcs.callPost('send-feedback', { suborderId: suborderId, feedbackNumber: feedbackNumber, mode: mode }).subscribe(
      val => {
        console.log("POST call successful value returned in body",
          val);
        console.log(val);
      },
      response => {
        console.log("POST call in error", response);
      },
      () => {
        console.log("The POST observable is now completed.");
      }
    );
  }

  /**
   * Returns the currently available products from McGrundriss.
   */
  getProductsBackend() {
    console.log('get products backend');
    return this.mcs.callGet('product').subscribe(
      val => {
        console.log("GET call successful value returned in body",
          val);
        console.log(val);
      },
      response => {
        console.log("GET call in error", response);
      },
      () => {
        console.log("The GET observable is now completed.");
      }
    );
  }

  /**
   * Returns details about the product
   * @param productID McGrundriss productID.
   */
  getProductsByIdBackend(productID: number) {
    console.log('get order details backend');
    return this.mcs.callGet('product/' + productID).subscribe(
      val => {
        console.log("GET call successful value returned in body",
          val);
        console.log(val);
      },
      response => {
        console.log("GET call in error", response);
      },
      () => {
        console.log("The GET observable is now completed.");
      }
    );
  }

  /**
   * todo list for feedback submissions
   * @param suborderId The related suborderId
   */
  addSuborderIdToOpenFeedbackIds(suborderId: string) {
    return this.mcs.callPost('response-awaiting-feedback-submission', {suborderId: suborderId}).toPromise();
  }
}
