import { CountryCode } from '@app/interfaces/countryCode.interface';

export enum CountryEnum {
  Germany = 'Deutschland',
  Switzerland = 'Schweiz',
  Austria = 'Österreich',
  Belgium = 'Belgien',
  Denmark = 'Dänemark',
  Estonia = 'Estland',
  Finland = 'Finnland',
  France = 'Frankreich',
  Greece = 'Griechenland',
  Ireland = 'Irland',
  Italy = 'Italien',
  Croatia = 'Kroatien',
  Latvia = 'Lettland',
  Lithuania = 'Litauen',
  Luxembourg = 'Luxemburg',
  Malta = 'Malta',
  Netherlands = 'Niederlande',
  Poland = 'Polen',
  Portugal = 'Portugal',
  Romania = 'Rumänien',
  Sweden = 'Schweden',
  Slovakia = 'Slowakei',
  Slovenia = 'Slowenien',
  Spain = 'Spanien',
  Czechia = 'Tschechien',
  Hungary = 'Ungarn',
  Cyprus = 'Zypern'
}

export const CountryCodeList: CountryCode[] = [
  {
    id: CountryEnum.Germany,
    code: 'DE'
  },
  {
    id: CountryEnum.Switzerland,
    code: 'CH'
  },
  {
    id: CountryEnum.Austria,
    code: 'AU'
  },
  {
    id: CountryEnum.Belgium,
    code: 'BE'
  },
  {
    id: CountryEnum.Croatia,
    code: 'HR'
  },
  {
    id: CountryEnum.Cyprus,
    code: 'CY'
  },
  {
    id: CountryEnum.Czechia,
    code: 'CZ'
  },
  {
    id: CountryEnum.Denmark,
    code: 'DK'
  },
  {
    id: CountryEnum.Estonia,
    code: 'EE'
  },
  {
    id: CountryEnum.Finland,
    code: 'FI'
  },
  {
    id: CountryEnum.France,
    code: 'FR'
  },
  {
    id: CountryEnum.Greece,
    code: 'GR'
  },
  {
    id: CountryEnum.Hungary,
    code: 'HU'
  },
  {
    id: CountryEnum.Ireland,
    code: 'IE'
  },
  {
    id: CountryEnum.Italy,
    code: 'IT'
  },
  {
    id: CountryEnum.Latvia,
    code: 'LV'
  },
  {
    id: CountryEnum.Lithuania,
    code: 'LT'
  },
  {
    id: CountryEnum.Luxembourg,
    code: 'LU'
  },
  {
    id: CountryEnum.Malta,
    code: 'MT'
  },
  {
    id: CountryEnum.Netherlands,
    code: 'NL'
  },
  {
    id: CountryEnum.Poland,
    code: 'PL'
  },
  {
    id: CountryEnum.Portugal,
    code: 'PT'
  },
  {
    id: CountryEnum.Romania,
    code: 'RO'
  },
  {
    id: CountryEnum.Slovakia,
    code: 'SK'
  },
  {
    id: CountryEnum.Slovenia,
    code: 'SI'
  },
  {
    id: CountryEnum.Spain,
    code: 'ES'
  },
  {
    id: CountryEnum.Sweden,
    code: 'SE'
  }
];
