export enum OrderTypeEnum {
  Documents_Procurement = "documents_procurement",
  Drone_Media = "dronemedia",
  Energy_Pass = "energy_pass",
  Expose = "expose",
  Floor_Overview = "floor_overview",
  Floor_Plan = "floorplan",
  Hd_Photos = "hdphotos",
  Price_Hubble = "price_hubble",
  Retouching = "retouching",
  Virtual_Tour = "virtual_tour",
  Visualisation = "visualisation",
  V_Staging = "vstaging",
  Video_Tour = "video_tour",
  Area_Calculation = "area_calculation",
}
