import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from "@angular/forms";
import { ServicesLogicService } from "@app/_services/services-logic.service";
import { Package } from "@app/models/package";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { ProductBundle } from "@app/interfaces/product-bundle.interface";
import { AccountingPosition } from "@app/interfaces/accounting-position.interface";
import { AccountingPositionsLogicService } from "@app/_services/accounting-positions-logic.service";
import { ProductDefaultOption } from "@app/interfaces/product-default-option.interface";
import { Company, CompanyFilter } from "@app/interfaces/company.interface";
import { AuthService } from "@app/auth/auth.service";
import { ProductBundleLogicService } from "@app/_services/product-bundle-logic.service";
import { CompaniesLogicService } from "@app/_services/companies-logic.service";
import { NotificationsService } from "@app/_services/notifications.service";
import { MatSelect } from "@angular/material/select";
import { take, takeUntil } from "rxjs/operators";
import { MatOption } from "@angular/material/core";
import { MatPaginator } from "@angular/material/paginator";
import { SsoSourcesEnum } from "@app/models/sso-sources.enum";
import { addToTableDataSource, updateInTableDataSource } from "@app/util/helper";

@Component({
  selector: "app-product-bundles",
  templateUrl: "./product-bundles.component.html",
  styleUrls: ["./product-bundles.component.css"],
})
export class ProductBundlesComponent implements OnInit, AfterViewInit, OnDestroy {
  productBundlesForm: FormGroup;
  isFormExpanded: boolean;
  isEditing = false;
  externalList = Object.values(SsoSourcesEnum).sort((a, b) => a.localeCompare(b));
  packagesList: Package[] = [];
  companyList: Company[] = [];
  companyFilterList: CompanyFilter[] = [];
  accountingPositionsList: AccountingPosition[] = [];
  tableDataSrc = new MatTableDataSource([]);
  columnsToDisplay = ["title", "productNamesList", "action"];
  filterValues = {};
  editingProductBundle: ProductBundle;
  packageServiceMap: Map<string, string> = new Map();
  selectAllCompanyText = "Select All";

  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompany: ReplaySubject<CompanyFilter[]> = new ReplaySubject<CompanyFilter[]>(1);
  @ViewChild("multiSelect", { static: true }) multiSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  private subscriptions: Subscription[] = [];
  @ViewChild("allSelected") private allSelected: MatOption;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("paginator") paginator: MatPaginator;
  keyValueQueryMap = new Map();
  productBundleCount: number;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private ns: NotificationsService,
    private pbls: ProductBundleLogicService,
    private ss: ServicesLogicService,
    private apls: AccountingPositionsLogicService,
    private cls: CompaniesLogicService
  ) {}

  ngOnInit() {
    this.accountingPositionsList = this.apls.allPositions;
    this.packagesList = this.ss.imoServices.filter((item) => !item.disabled);

    // sort packages 1st by service name and then by package name
    this.packagesList.sort((a: Package, b: Package) => {
      if (a.service_key === b.service_key) {
        // package name is only important when package service key are the same
        return a.name > b.name ? 1 : -1;
      }
      return a.service_key > b.service_key ? 1 : -1;
    });
    this.packagesList.forEach((item) => {
      this.packageServiceMap.set(item.package_key, item.service_key);
    });
    this.companyList = this.cls.companiesList;
    this.productBundlesForm = this.fb.group({
      title: ["", [Validators.required]],
      companyIds: [""],
      externals: [""],
      productsList: this.fb.array([]),
      isBundleExclusive: [false],
    });

    this.addProduct();

    this.loadProductBundles();
    this.setTableFilter();

    this.subscriptions.push(
      this.pbls.productBundlesListChanged.subscribe(() => {
        this.loadProductBundles();
        this.setTableFilter();
      })
    );

    this.setCompanyFilters();
  }

  addProduct(
    productId = "",
    productPrice = 0,
    productDefaultOptionList: ProductDefaultOption[] = [],
    productName = ""
  ) {
    const fg = this.fb.group({
      productName: [productName, [Validators.required]],
      productId: [productId, [Validators.required]], // field to store the package_key of a package
      productPrice: [productPrice, [Validators.required]], // field to store the price value of a package
      productDefaultOptionList: this.fb.array([]),
    });
    this.productsList.push(fg);

    const newProductIndex = this.productsList.length - 1;

    if (productDefaultOptionList.length > 0) {
      productDefaultOptionList.forEach((item) => {
        this.addProductDefaultOption(newProductIndex, item.accountingPositionId, item.maxQuantity);
      });
    } else {
      this.addProductDefaultOption(newProductIndex);
    }
  }

  addProductDefaultOption(productIndex: number, accountingPositionId = "", maxQuantity = 1) {
    const fg = this.fb.group({
      accountingPositionId: [accountingPositionId, [Validators.required]],
      maxQuantity: [maxQuantity, [Validators.required]],
    });

    const product = <FormGroup>this.productsList.controls[productIndex];
    const productDefaultOptionList = <FormArray>product.get("productDefaultOptionList");

    productDefaultOptionList.push(fg);
  }

  removeProduct(index: number) {
    const product = <FormGroup>this.productsList.controls[index];
    const productDefaultOptionList = <FormArray>product.get("productDefaultOptionList");

    for (
      let productDefaultOptionIndex = productDefaultOptionList.controls.length - 1;
      productDefaultOptionIndex >= 0;
      productDefaultOptionIndex--
    ) {
      this.removeProductDefaultOption(index, productDefaultOptionIndex);
    }

    this.productsList.removeAt(index);
  }

  removeProductDefaultOption(productIndex: number, productDefaultOptionIndex: number) {
    const product = <FormGroup>this.productsList.controls[productIndex];
    const productDefaultOptionList = <FormArray>product.get("productDefaultOptionList");

    productDefaultOptionList.removeAt(productDefaultOptionIndex);
  }

  get title() {
    return this.productBundlesForm.get("title").value;
  }

  set title(value: string) {
    this.productBundlesForm.get("title").setValue(value);
  }

  get productsList() {
    return <FormArray>this.productBundlesForm.get("productsList");
  }

  get isBundleExclusive() {
    return this.productBundlesForm.get("isBundleExclusive");
  }

  toggleFormExpansion() {
    this.isFormExpanded = !this.isFormExpanded;
  }

  async onSubmit() {
    const updatedData = this.productBundlesForm.value;

    try {
      if (this.isEditing) {
        await this.pbls.updateProductBundle(this.editingProductBundle.id, updatedData);
        // replace item with new data, but keep properties already changed in case the update is only partial
        updateInTableDataSource(this.tableDataSrc, this.editingProductBundle.id, {
          ...this.editingProductBundle,
          ...updatedData,
        });
        this.cancelEditing();
      } else {
        await this.pbls.addProductBundle(updatedData);
        addToTableDataSource(this.tableDataSrc, updatedData);
        this.productBundlesForm.reset();
        this.resetProductsList();
        this.loadProductBundles();
      }

      this.ns.showNotification("Produktpaket hinzugefügt.", "success");
    } catch (err) {
      console.error(err);
      this.ns.showNotification(
        "Die Aktion konnte nicht abgeschlossen werden. Bitte versuchen Sie es erneut.",
        "danger"
      );
    }
  }

  editItem(id: string) {
    this.isEditing = true;
    this.isFormExpanded = true;

    this.editingProductBundle = this.tableDataSrc.data.find((item) => item.id === id);

    this.title = this.editingProductBundle.title;
    // clear productsList 1st
    this.clearProductsList();
    // populate productsList
    this.editingProductBundle.productsList.forEach((item) => {
      this.addProduct(item.productId, item.productPrice, item.productDefaultOptionList, item.productName);
    });
    this.productBundlesForm.get("isBundleExclusive").setValue(this.editingProductBundle.isBundleExclusive || false);
    this.productBundlesForm.patchValue({ companyIds: this.editingProductBundle.companyIds });
    this.productBundlesForm.patchValue({ externals: this.editingProductBundle.externals });
  }

  async removeItem(id: string) {
    const confirmed = confirm(
      "Möchten Sie den Produktpakete wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!"
    );
    if (confirmed) {
      try {
        await this.pbls.removeProductBundle(id).toPromise();
        this.tableDataSrc.data = this.tableDataSrc.data.filter((item) => item.id !== id);

        this.ns.showNotification("Produktpakete erfolgreich entfernt.", "success");
      } catch (err) {
        console.error(err);
        this.ns.showNotification(
          "Die Aktion konnte nicht abgeschlossen werden. Bitte versuchen Sie es erneut.",
          "danger"
        );
      }
    }
  }

  private clearProductsList() {
    while (this.productsList.length !== 0) {
      this.productsList.removeAt(0);
    }
  }

  private setCompanyFilters() {
    this.companyList.forEach((x) => {
      this.companyFilterList.push({ cid: x.cid, name: x.name + (x.supplement ? " - " + x.supplement : "") });
    });
    this.filteredCompany.next(this.companyFilterList);
    this.companyFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCompanyMulti();
    });
  }

  private resetProductsList() {
    this.clearProductsList();
    this.addProduct();
  }

  cancelEditing() {
    this.resetProductsList();
    this.isEditing = false;
    this.isFormExpanded = false;
    this.productBundlesForm.reset();
    this.productBundlesForm.enable();
  }

  private setTableFilter(): void {
    this.tableDataSrc.filterPredicate = (object) => {
      let flag = true;
      Object.keys(this.filterValues).forEach((key) => {
        if (this.filterValues[key] && object[key].toLowerCase().indexOf(this.filterValues[key]) < 0) {
          flag = false;
        }
      });
      return flag;
    };
  }

  updateFilterValues($event, column: string) {
    let value = $event.currentTarget?.["value"]?.trim()?.toLowerCase() || $event.value;
    if (this.keyValueQueryMap.has(column)) {
      this.keyValueQueryMap.delete(column);
    }
    if (value) {
      this.keyValueQueryMap.set(column, value);
    }
    this.filterTable($event, column);
  }

  filterTable(event: Event, column: string): void {
    const query = event.currentTarget["value"].trim().toLowerCase();
    this.filterValues[column] = query;
    this.paginator.pageIndex = 0;
    this.loadProductBundles();
  }

  loadProductBundles(): void {
    this.pbls
      .getAllProductBundlesWithPaginator(
        this.paginator?.pageIndex,
        this.paginator?.pageSize,
        this.sort?.active,
        this.sort?.direction,
        this.keyValueQueryMap
      )
      .subscribe((bundleList) => {
        this.tableDataSrc.data = bundleList.data;
        this.productBundleCount = bundleList.total_hits;
      });
  }

  noSort(event: Event) {
    event?.preventDefault();
    event?.stopPropagation();
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((x) => x.unsubscribe());
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  selectAllCompanies() {
    if (this.allSelected.selected) {
      this.productBundlesForm.controls.companyIds.patchValue([...this.companyList.map((item) => item.cid), 0]);
    } else {
      this.productBundlesForm.controls.companyIds.patchValue([]);
    }
  }

  selectSingleCompany(all) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.productBundlesForm.controls.companyIds.value.length == this.companyList.length) this.allSelected.select();
  }

  protected filterCompanyMulti() {
    if (!this.companyFilterList) {
      return;
    }
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filteredCompany.next(this.companyFilterList);
    } else {
      search = search.toLowerCase();
      this.filteredCompany.next(
        this.companyFilterList.filter((company) => company.name.toLowerCase().indexOf(search) > -1)
      );
    }
  }

  protected setInitialValue() {
    this.filteredCompany.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      if (this.multiSelect) {
        this.multiSelect.compareWith = (a: CompanyFilter, b: CompanyFilter) => a && b && a.cid === b.cid;
      }
    });
  }

  addNoSortWhitespace($event) {
    this.noSort($event);
    $event.target.value += " ";
  }
}
