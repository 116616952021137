<div class="imo-input-number white-bg py-1 d-flex mw-100 justify-content-center align-items-center">
  <i class="fas fa-minus clickable left-icon" (click)="onRemove()"></i>
  <div
    class="value-box px-2"
    [ngClass]="{
      'invalid-value': !isValid,
      'valid-value': isValid
    }"
  >
    {{ value }}
  </div>
  <i class="fas fa-plus clickable right-icon" (click)="onAdd()"></i>
</div>
