import { Injectable } from "@angular/core";
import { CONSTANTS } from "@app/util/constants";
import { environment } from "@environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class RenewaService {
  private readonly endpoint =
    environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.APIS + "/" + CONSTANTS.EXTERNAL_APIs.RENEWA;
  constructor(private http: HttpClient) {}

  /**
   * Calls the create-order order method for moovin in our backend
   * @param suborderId imogent suborderId
   */
  createOrder(suborderId: string) {
    return this.http.post(this.endpoint + "/create-order/" + suborderId, {}).toPromise();
  }

  getProductJSON(subOrderId) {
    return this.http.get(this.endpoint + "/generate-product-json/" + subOrderId, { responseType: "json" }).toPromise();
  }
}
