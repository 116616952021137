import { Injectable } from "@angular/core";
import { BackboneService } from "@app/_services/backbone.service";
import { HttpParams } from "@angular/common/http";
import { OffenblendePreferredPhotographer } from "@app/interfaces/offenblende-preferred-photographer.interface";

@Injectable({
  providedIn: "root",
})
export class BackboneLogicService {
  constructor(private bkbnService: BackboneService) {}

  /**
   * Create an order with the given order ID.
   *
   * @param {string} orderId - The ID of the order.
   * @returns {Promise<void>} A promise that resolves when the order is created successfully.
   */
  async createOrder(orderId: string) {
    await this.bkbnService.post("create-order", { orderId: orderId });
  }

  /**
   * Retrieves a list of surrounding photographers.
   *
   * @param {HttpParams} [httpParams] - Optional HTTP parameters.
   * @returns {Promise<OffenblendePreferredPhotographer[]>} The list of surrounding photographers.
   */
  async getSurroundingPhotographers(httpParams?: HttpParams): Promise<OffenblendePreferredPhotographer[]> {
    return await this.bkbnService.get<OffenblendePreferredPhotographer[]>("preferred-photographer", httpParams);
  }
}
