import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-info-icon',
    templateUrl: './info-icon.component.html',
    styleUrls: ['./info-icon.component.css']
})
export class InfoIconComponent implements OnInit {
    @Input() infoText: string;
    @Input() infoHeadline?: string;
    @Input() infoButton?: any;
    @Input() infoIcon = 'info-circle';
    expanded = false;

    constructor() {
    }

    ngOnInit() {
        this.checkRequired();
    }

    iconClicked() {
        this.expanded = !this.expanded;
    }

    private checkRequired() {
        if (!this.infoText) {
            throw new Error('Attribute "infoText" is required');
        }
    }
}
