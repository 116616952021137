export enum DocEstateInteractionTypeEnum {
  MISSING_AUTHORITY = "MISSING_AUTHORITY",
  INCORRECT_DATA = "INCORRECT_DATA",
}

export enum DocEstateCustomerInteractionStatusEnum {
  OPEN = "OPEN",
  HOLD = "HOLD",
  CLOSED = "CLOSED",
  WAITING_FOR_DOCESTATE = "WAITING_FOR_DOCESTATE",
}

export enum DocEstateCIMessageTypeEnum {
  FILE = "application/pdf",
  TEXT = "text/plain",
  JSON = "application/property-json",
}

export enum DocEstateMessageAuthorTypeEnum {
  USER = "USER",
  DOCESTATE = "DOCESTATE",
}

export enum CustomerResponseType {
  FILE = "file",
  TEXT = "text",
}

export enum MessageAuthorEnum {
  USER = "USER",
  DOCESTATE = "DOCESTATE",
}
