import { Component, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-suborder-feedback-info",
  templateUrl: "./suborder-feedback-info.component.html",
  styleUrls: ["./suborder-feedback-info.component.css"],
})
export class SuborderFeedbackInfoComponent implements OnInit {
  @ViewChild("videoPlayer") videoPlayer;
  isPlayingVideo = false;

  constructor() {}

  ngOnInit(): void {}

  playVideo() {
    this.videoPlayer.nativeElement.play();
  }

  stopVideo() {
    this.videoPlayer.nativeElement.pause();
  }
}
