<ngx-smart-modal
  #addNewSlideModal
  identifier="addNewSlideModal"
  [closable]="true"
  [dismissable]="true"
  [escapable]="true"
  [customClass]="'add-slide-modal'"
>
  <div class="row mt-3">
    <div class="col-12">
      <span class="font-size-large text-bold text-primary">Add New Slide</span>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-12 form-group">
              <input
                type="text"
                class="form-control white-bg rounded"
                [(ngModel)]="newSlideTitle"
                placeholder="Title"
              />
            </div>
            <div class="col-12 text-danger" *ngIf="showError && !newSlideTitle">Title is required</div>
            <div class="col-12 form-group">
              <textarea
                class="form-control white-bg rounded notification-text"
                [(ngModel)]="newSlideText"
                placeholder="Notification text"
              ></textarea>
            </div>
            <div class="col-12 text-danger" *ngIf="showError && !newSlideText">Text is required</div>
          </div>
        </div>
        <div class="col-6 form-group uploadArea" *ngIf="imageUploader.queue.length <= 0">
          <div class="buttonUpload">
            <label class="upload-label">
              <div class="row fileContainer text-center align-items-center">
                <i class="fas fa-file-upload col-12 mt-3"></i>
                <div class="col-12">
                  <span class="text-gray font-weight-bold"> Bitte laden Sie hier Ihre Unterlagen hoch. </span>
                </div>
              </div>
              <input
                [uploader]="imageUploader"
                [writeFile]="true"
                accept=".png,.jpeg,.jpg"
                multiple="false"
                ng2FileSelect
                type="file"
                (change)="onImageUpload()"
              />
            </label>
          </div>
          <div class="col-12 text-danger" *ngIf="showError">Image is required</div>
        </div>
        <div class="col-6 form-group file-list" *ngIf="imageUploader.queue.length > 0">
          <div class="file-entry row align-items-center" *ngFor="let file of imageUploader.queue">
            <div class="col-10 feature-image">
              <img class="img-fluid" src="{{ imageContent }}" alt="" />
            </div>
            <div class="col-2 text-left">
              <span class="fas fa-2x fa-trash-alt text-danger clickable" (click)="removeFileFromQueue(file)"></span>
            </div>
          </div>
        </div>
        <div class="col-2 offset-10 text-center">
          <button class="btn btn-primary btn-small" (click)="addNewEntry()">
            {{ isEditing ? "Save" : "Add" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ngx-smart-modal>
<ngx-smart-modal
  #previewModal
  identifier="previewModal"
  [closable]="false"
  [dismissable]="true"
  [escapable]="true"
  [customClass]="'pack-modal-custom'"
>
  <div class="row customMargin" *ngIf="showPreview">
    <div class="col-6 text-left">
      <p class="text-primary text-center news-header py-3">Das ist neu auf der Plattform!</p>
      <p class="text-center font-weight-bolder">{{ slides[previewIndex].title }}</p>
      <p class="text-center feature-text m-0" [innerHTML]="slides[previewIndex].text"></p>
      <div class="container">
        <div class="row align-items-end">
          <div class="col-12 text-center bottomdiv30">
            <button
              class="btn btn-primary btn-sm align-self-end"
              (click)="goToNextNews()"
              *ngIf="previewIndex + 1 < slides.length"
            >
              Weiter
            </button>
            <button
              class="btn btn-primary btn-sm align-self-end"
              (click)="closePreview('previewModal')"
              *ngIf="previewIndex + 1 === slides.length"
            >
              Schließen
            </button>
          </div>
          <div class="col-12 text-center bottomdiv" *ngIf="showPreview">
            <app-dot-nav
              class="align-self-end"
              [numberOfDots]="slides.length"
              (selectionChanged)="changeNews($event)"
              #dotNav
            ></app-dot-nav>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 p-0 text-right feature-image">
      <img [src]="slides[previewIndex].image" class="img-fluid" alt="" />
    </div>
  </div>
  <i class="fas fa-times-circle icon-close clickable text-white" (click)="closePreview('previewModal')"></i>
</ngx-smart-modal>
<div class="shadow card fluid cgrid">
  <div class="card-body">
    <div class="my-2">
      <div class="row">
        <div class="col text-right">
          <button class="btn btn-primary btn-small" [disabled]="!hasChanged" (click)="saveAllChanges()">
            Save Changes
          </button>
          <button class="btn btn-default btn-small" (click)="addOrEditSlide()">Add New Slide</button>
          <button class="btn btn-default btn-small" (click)="openPreview('previewModal')">Preview</button>
        </div>
      </div>
    </div>
    <div cdkDropList class="slides-list" (cdkDropListDropped)="drop($event)" *ngIf="slides.length">
      <div class="slide-box d-block" *ngFor="let slide of slides; let i = index" cdkDrag>
        <div class="row">
          <div class="col-12">
            <div class="reorder-icon">
              <i class="fa-solid fa-2x fa-grip-vertical"></i>
            </div>
            <div class="row align-items-center">
              <div class="col-11">
                <div class="pl-5">
                  <div class="font-weight-bold mb-2">
                    {{ slide.title }}
                  </div>
                  <div [innerHTML]="slide.text"></div>
                </div>
              </div>
              <div class="col-1">
                <i class="fa-solid fa-2x fa-edit clickable" (click)="addOrEditSlide(i)"></i>
                <i class="fa-solid fa-2x fa-trash-can clickable text-danger ml-2" (click)="deleteSlide(i)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
