import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NavbarComponent } from "./navbar.component";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MaterialModule } from "@app/material/material.module";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { DotNavComponent } from "../dot-nav/dot-nav.component";
import { AddVatPipe } from "@app/shared-module/add-vat.pipe";
import { ClickOutsideDirective } from "./click-outside.directive";
import { GetVatPipe } from "@app/shared-module/get-vat.pipe";

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    MaterialModule,
    FormsModule,
    TranslateModule.forChild(),
  ],
  declarations: [DotNavComponent, ClickOutsideDirective],
  exports: [DotNavComponent, ClickOutsideDirective],
  providers: [AddVatPipe, GetVatPipe],
})
export class NavbarModule {}
