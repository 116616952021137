import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@app/interfaces/user.interface';
import { AuthService } from '@app/auth/auth.service';
import { UserRoleEnum } from '@app/models/user-role-list';
import { UsersLogicService } from '@app/_services/users-logic.service';

@Component({
  selector: 'app-imo-user-selector',
  templateUrl: './imo-user-selector.component.html',
  styleUrls: ['./imo-user-selector.component.css']
})
export class ImoUserSelectorComponent implements OnInit {
  @Input() initialUser: User;
  @Output() userSelected: EventEmitter<User> = new EventEmitter<User>();
  userList: User[];
  filteredUserList: User[];
  selectedUser: User;
  selectionConfirmed: boolean;

  constructor(
    private uls: UsersLogicService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.selectionConfirmed = false;
    if (this.auth.myUserObservable.role === UserRoleEnum.Administrator) {
      this.uls.getAllUsers().subscribe(allUsers => {
        this.userList = allUsers;
        this.userList.sort((a, b) => (a.lastName > b.lastName) ? 1 : ((b.lastName > a.lastName) ? -1 : (a.firstName > b.firstName) ? 1 : 0));
        this.filteredUserList = this.userList;
      });

      this.selectedUser = this.initialUser || this.auth.myUserObservable;
    }
  }

  filterUserList(searchString: string) {
    this.filteredUserList = this.userList.filter((user) => {
      return (user.firstName?.includes(searchString) || user.lastName?.includes(searchString) || user.email?.includes(searchString));
    });
  }

  selectionChanged(event: any) {
    this.selectedUser = event.value;
  }

  confirmSelection() {
    this.selectionConfirmed = true;
    this.userSelected.emit(this.selectedUser);
  }
}
