import { Component, Input, OnInit } from "@angular/core";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { OrderStatusEnum } from "@app/models/order-status-list";
import { NotificationsService } from "@app/_services/notifications.service";
import { RatingsService } from "@app/_services/ratings.service";
import { SubOrdersLogicService } from "../../_services/sub-orders-logic.service";

@Component({
  selector: "app-order-feedback-rating",
  templateUrl: "./order-feedback-rating.component.html",
  styleUrls: ["./order-feedback-rating.component.css"],
})
export class OrderFeedbackRatingComponent implements OnInit {
  @Input() orderDetails: SubOrder;
  orderRating: number;
  feedbackComments: string;
  showFeedbackSection: boolean;
  orderStatusEnum = OrderStatusEnum;

  constructor(private sos: SubOrdersLogicService, private ns: NotificationsService, private rs: RatingsService) {}

  ngOnInit() {
    this.showFeedbackSection = !this.orderDetails?.isCompletedResultsFeedbackRatingSent;
  }

  async next() {
    if (this.orderRating || this.feedbackComments) {
      const requestData: any = this.orderDetails;
      requestData.orderRating = this.orderRating ? this.orderRating - 1 : "N/A";
      requestData.comments = this.feedbackComments || "N/A";

      this.rs.addOrderFeedbackRating(requestData);
    }
    this.showFeedbackSection = false;

    if (this.orderDetails.status !== this.orderStatusEnum.Completed) {
      this.ns.showNotification("Feedback erfolgreich gesendet. Dankeschön.", "success");
    } else {
      try {
        await this.sos.updateSubOrder(this.orderDetails.id, { isCompletedResultsFeedbackRatingSent: true });
        this.ns.showNotification("Erfolgreich gespeichert.", "success");
      } catch (e) {
        console.error(e);
        this.ns.showNotification(
          "Die Aktion konnte nicht abgeschlossen werden. Bitte versuchen Sie es erneut.",
          "danger"
        );
      }
    }
  }
}
