export enum AnalyticsToolExportingDataViewEnum {
  SuborderCreatedOn = "SuborderCreatedOn",
  SuborderCompletedOn = "SuborderCompletedOn",
}

export const analyticsToolExportingDataViewList: { id: AnalyticsToolExportingDataViewEnum; name: string }[] = [
  {
    id: AnalyticsToolExportingDataViewEnum.SuborderCreatedOn,
    name: "Unterauftrag erstellt am",
  },
  {
    id: AnalyticsToolExportingDataViewEnum.SuborderCompletedOn,
    name: "Unterauftrag abgeschlossen am",
  },
];
