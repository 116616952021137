<!-- Only showing content after new-user registration if user verified his e-mail. While he hasn't verified it, there will be a blank page with a loading spinner behind the verification modal. -->
<ng-container *ngIf="!auth.registering && auth.emailVerified">
  <!-- Page-Content -->
  <div class="row">
    <!-- Column for the greetings and role specific information -->
    <div class="col">
      <div class="shadow card fluid cgrid">
        <div class="card-body">
          <ng-container *ngIf="auth.myUserObservable as user">
            <ng-container *ngIf="user.profileCompleted === true || user.role === 'Kunde'; else profiledata">
              <!-- Customer greeting -->
              <div *ngIf="user.role === 'Kunde'">
                <div class="mat-h1" *ngIf="user.lastName !== undefined">
                  Herzlich willkommen {{ user.anrede }} {{ user.lastName }}!
                </div>
                <div class="mat-body">
                  <ng-container *ngIf="user.isPrivateIndividual">
                    <p>
                      Mit der IMOGENT-Plattform haben Sie die Möglichkeit, verschiedenste Produkte und Services rund um
                      Ihre Immobilie unkompliziert und schnell zu erwerben.
                    </p>
                    <p>
                      Sobald Sie ihre erste <a class="link" routerLink="/realestate">Immobilien angelegt</a> haben,
                      sehen Sie auf der <a class="link" routerLink="/createorder/selectservice">Bestellseite</a> unsere
                      derzeit verfügbaren Services. Dort können Sie aus verschiedenen Paketen oder Einzelleistungen
                      wählen und haben stets einen transparenten Überblick über die Kosten und den
                      <a class="link" routerLink="/orderoverview">aktuellen Status der Bestellungen</a>.
                    </p>
                  </ng-container>
                  <ng-container *ngIf="!user.isPrivateIndividual">
                    <p>
                      Mit der IMOGENT-Plattform haben Sie die Möglichkeit, unsere Services für die
                      Immobilienvisualisierung zu buchen.
                    </p>
                    <p>
                      Sobald Sie ihre erste <a class="link" routerLink="/realestate">Immobilien angelegt</a> haben,
                      sehen Sie auf der <a class="link" routerLink="/createorder/selectservice">Bestellseite</a> unsere
                      derzeit verfügbaren Services. Dort können Sie aus verschiedenen Paketen die passende
                      Visualisierung für Ihre Immobilie wählen und haben stets einen transparenten Überblick über die
                      Kosten und den <a class="link" routerLink="/orderoverview">aktuellen Status der Bestellungen</a>.
                    </p>
                  </ng-container>
                </div>
              </div>

              <!-- Dienstleister -->
              <ng-container *ngIf="user.role === 'Dienstleister'">
                <h3 *ngIf="user.lastName !== undefined" class="card-title b">
                  {{ "home.title" | translate }}
                  {{ user.anrede }}
                  {{ user.lastName }}!
                </h3>
                <br />
                <p>
                  Mit der IMOGENT-Plattform haben Sie Zugriff auf alle Aufträge, welche zu Ihren angegebenen Fähigkeiten
                  passen.
                </p>
                <p>
                  Sobald Sie ihre persönlichen Angaben und angebotenen Dienstleistungen im
                  <a class="link" routerLink="/profile">Profil</a> ausgefüllt haben und Ihre erforderlichen Dokumente
                  zur Überprüfung hochgeladen haben, sehen Sie auf unter
                  <a class="link" routerLink="/orderoverview">Aufträge</a> alle offenen, angefragten, zu bearbeitenden
                  und abgeschlossenen Aufträge.
                </p>

                <div *ngIf="user.dronepilot === true">
                  <p
                    *ngIf="user.verificationNeeded === false && user.pilotVerified === false"
                    class="card-text font-weight-normal"
                    style="color: #dc3545"
                  >
                    Sie haben noch nicht alle erforderliche Dokumente hochgeladen. Dies können Sie unter dem Menüpunkt
                    "Profil" erledigen. Erst dann können Sie sich Aufträge zuweisen und bearbeiten.
                  </p>
                  <p
                    *ngIf="user.verificationNeeded === true && user.pilotVerified === false"
                    class="card-text font-weight-normal"
                    style="color: orange"
                  >
                    Wir überprüfen aktuell Ihre hochgeladenen Dokumente. In kurzer Zeit können Sie die Plattform nutzen.
                  </p>
                  <p *ngIf="user.verificationFailed === true" class="card-text font-weight-bold" style="color: #dc3545">
                    Die Verifikation Ihrer Dokumente war nicht erfolgreich. Bitte laden Sie gültige Dokumente hoch.
                  </p>
                </div>
              </ng-container>

              <!-- Admin greeting -->
              <ng-container *ngIf="user.role === 'Administrator'">
                <h3 class="card-title b">Hallo {{ user.firstName }}!</h3>
                <p class="card-text" style="color: #dc3545">Du hast Administratoren-Berechtigungen!</p>
                <p class="card-text" style="color: #dc3545">
                  Du kannst unter "Aufträge" alle jemals erstellen Aufträge einsehen. Von Kunden neu erstellte Aufträge
                  müssen von dir erst geprüft werden, bezüglich eventueller Flugverbote. Wenn du in einem Auftrag auf
                  geprüft klickst, wird dieser in der Liste offener Aufträge den Drohnenpiloten angezeigt und Sie können
                  sich diesen zuweisen.
                </p>
                <p class="card-text" style="color: #dc3545">
                  Außerdem hast du einen weiteren Menüpunkt: "User-Management". Hier kannst du alle registrierten User
                  einsehen. Drohnenpiloten deren Dokumente noch nicht überprüft wurden werden in dieser Ansicht mit
                  einem Tag markiert. Du hast die Aufgabe diese Dokumente zu überprüfen und dann den Status des Piloten
                  auf verifziert zu setzen. Vorher werden dem Drohnenpiloten keine Aufträge angezeigt!
                </p>
              </ng-container>
            </ng-container>

            <!-- Card which is shown to all users except customers, if they have not yet completed their profile on the profile page. -->
            <ng-template #profiledata>
              <h3 class="card-title b">Hallo {{ user.anrede }} {{ user.lastName }}!</h3>
              <p class="card-text" style="color: #dc3545">
                Sie haben Ihre persönlichen Daten noch nicht eingepflegt. Um die Plattform vollumfänglich nutzen zu
                können, pflegen Sie bitte Ihre persönlichen Daten unter dem Menüpunkt "Profil" ein.
              </p>
            </ng-template>
          </ng-container>
        </div>
      </div>

      <div class="shadow card fluid cgrid">
        <div class="card-body">
          <div class="mat-h2 text-normal">Unser Produktportfolio</div>
          <p class="card-text text-left text-normal">
            Ob Neubau-, Bestands- oder Gewerbeobjekt, in unserem Portfolio finden Sie für jedes Objekt den idealen
            Service zur Vermarktung Ihrer Immobilie.
            <ng-container *ngIf="!auth.myUserObservable.isPrivateIndividual"
              >Begeistern Sie Ihre Kunden und begeistern Sie sich selbst durch eine völlig neue Art, Services in
              Anspruch zu nehmen.</ng-container
            >
            Eine Übersicht unserer Produkte finden Sie auch hier:
          </p>
          <a class="link" routerLink="/createorder" class="btn btn-primary" rel="noopener">Service buchen</a>
        </div>
      </div>
      <ngx-smart-modal #FeedbackComplete identifier="FeedbackComplete">
        <h2>Feedback wurde abgesendet!</h2>
      </ngx-smart-modal>
    </div>

    <!-- Column for general information which is not user/role specific -->
    <div class="col-sm-4 d-none d-lg-block">
      <!-- Featured quote -->
      <div class="shadow card fluid cgrid">
        <div class="card-header">
          <i class="fas fa-quote-left"></i>
        </div>
        <div class="card-body">
          <blockquote class="blockquote mb-0">
            <p>
              Modernste Digitaltechnik macht's möglich. Die virtuelle Immobilien-Besichtigung spart jede Menge Zeit und
              bringt Spaß.
            </p>
            <footer class="blockquote-footer">Robin Kroll, Gründer von IMOGENT</footer>
          </blockquote>
        </div>
      </div>

      <!-- Featured blog-entry from www.imogent.de/blog -->
      <div class="shadow card fluid cgrid">
        <div class="card-body">
          <div class="mat-h2 text-normal">Kontakt</div>

          Das IMOGENT-Team steht Ihnen bei Fragen jederzeit zur Verfügung. Gerne können Sie bei Unklarheiten zu Ihrer
          Bestellung, Informationen zu unseren Services oder auch technischen Problemen auf uns zukommen. Sie erreichen
          uns über das Kontaktformular, per Mail oder telefonisch.
          <div class="row mt-2">
            <div class="col-3">
              <a class="btn btn-primary" href="mailto:kontakt@imogent.de" role="button"
                ><i class="far fa-envelope"></i
              ></a>
            </div>
            <div class="col-3">
              <a
                class="btn btn-primary"
                [href]="constants.SERVICE_PHONE"
                role="button"
                [placement]="'bottom'"
                [tooltip]="constants.SERVICE_PHONE_GER"
                [adaptivePosition]="false"
                ><i class="fas fa-phone-alt"></i
              ></a>
            </div>
            <div class="col-3">
              <a class="btn btn-primary" href="https://www.imogent.de/kontakt" role="button" target="_blank"
                ><i class="fas fa-question"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #loading>
    <!-- CSS Loading Spinner -->
    <div class="lds-ring ring-default centered">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </ng-template>
</ng-container>

<!-- <ng-template #loading>
  <div class="lds-ring ring-default centered">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-template> -->
