import { Injectable } from "@angular/core";
import { CONSTANTS } from "@app/util/constants";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class ImmoviewerService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.IMMOVIEWER + "/";

  constructor(private http: HttpClient) {}

  post(path: string, data: { suborderId: string }) {
    return this.http.post(this.endpoint + path, data);
  }
}
