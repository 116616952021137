import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";
import { OnOfficeSignatureResponse } from "@app/interfaces/onOfficeSignatureResponse.interface";
import { Observable } from "rxjs";
import { OnOfficeUnlockProviderResponse } from "@app/interfaces/onOfficeUnlockProviderResponse.interface";
import { OnOfficeRealEstate } from "@app/interfaces/integration.interface";
import { RealEstate } from "@app/interfaces/real-estate.interface";

@Injectable({
  providedIn: "root",
})
export class OnOfficeService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.ON_OFFICE;

  constructor(private http: HttpClient) {}

  callGet(path: string) {
    return this.http.get<OnOfficeRealEstate>(this.endpoint + "/" + path);
  }

  callPost(
    path: string,
    body: any
  ): Observable<OnOfficeSignatureResponse | OnOfficeUnlockProviderResponse | RealEstate> {
    return this.http.post<OnOfficeSignatureResponse>(this.endpoint + "/" + path, body);
  }
}
