<div class="container-fluid">
  <div class="row" *ngIf="!isStandAlone">
    <div class="col-6">
      <ng-container *ngIf="mode == 'rejectResults'"
        ><strong>Ergebnisse Ablehnen - Feedback an DL geben</strong></ng-container
      >
      <ng-container *ngIf="mode == 'forwardFeedback'"
        ><strong>Kundenfeedback an Dienstleister weiterleiten</strong></ng-container
      >
    </div>

    <div class="col-6 justify-content-end">
      <button class="btn btn-danger float-right" (click)="goBack()">Feedback abbrechen</button>
    </div>
  </div>
  <ng-container>
    <ng-container>
      <div class="row justify-content-md-center align-items-top pos-rel">
        <div class="col-8 mt-5">
          <div class="row justify-content-center">
            <i
              class="fas fa-chevron-left d-none d-md-block col-md-1 col-xl-1 text-primary text-center vertical-center nav-icon clickable"
              *ngIf="
                hasFoundFiles &&
                dataLoaded &&
                photoCounter < photoCount &&
                display_selector === 'visible' &&
                outputFormat !== 'Video'
              "
              (click)="gotoImage(photoCounter - 1)"
            ></i>
            <div class="col-10">
              <div
                class="row"
                id="canvas-container"
                *ngIf="outputFormat !== 'Video' && !suborder.resultText"
                #canvascontainer
              >
                <img
                  src="{{ download_url }}"
                  *ngIf="imageSelected && !isTourSelected"
                  id="canvas"
                  alt="There should be a picture visible. If not contact support immediately"
                  class="col-12"
                  [hidden]="!dataLoaded || photoCounter >= photoCount || display_selector !== 'visible'"
                  (load)="mts.initialize(canvas)"
                  #canvas
                />
                <img
                  src="assets/images/no-image.png"
                  *ngIf="!imageSelected && !isTourSelected && photoCounter < photoCount"
                  class="col-12"
                  (load)="mts.initialize(canvasNoImg)"
                  #canvasNoImg
                />
                <iframe
                  [src]="download_url | safeURL"
                  width="100%"
                  height="450"
                  *ngIf="!imageSelected && isTourSelected"
                ></iframe>
              </div>
              <div
                class="row"
                id="canvas-container"
                *ngIf="suborder.resultText"
                [hidden]="!dataLoaded || photoCounter >= photoCount || display_selector !== 'visible'"
                #canvascontainer
              >
                <iframe width="100%" height="450" [src]="suborder.resultText | safeURL"></iframe>
              </div>
              <div class="row mt-2">
                <div
                  class="col-12 carousel-container"
                  *ngIf="showCarousel"
                  [ngClass]="{
                    'hide-left': firstIndex === 0,
                    'hide-right': lastIndex === photoCount
                  }"
                >
                  <carousel
                    [itemsPerSlide]="tilesPerSlide"
                    [singleSlideOffset]="true"
                    [showIndicators]="indicators"
                    [interval]="0"
                    [noWrap]="true"
                    (click)="onClick($event)"
                  >
                    <slide *ngFor="let slide of mts.finalPhotos; let index = index" (click)="gotoImage(index)">
                      <div
                        class="img-container clickable"
                        [ngClass]="{
                          'active-img': photoCounter === index
                        }"
                      >
                        <img
                          [src]="slide?.file_xs"
                          alt="image slide"
                          *ngIf="slide?.isImage"
                          style="display: block; width: 100%"
                          class="uploaded-img"
                        />
                        <img src="assets/images/no-image.png" *ngIf="!slide?.isImage" />
                      </div>
                      <div class="img-download-overlay clickable">
                        <span class="file-name text-white">
                          {{ slide?.fileName }}
                        </span>
                      </div>
                    </slide>
                  </carousel>
                </div>
              </div>
            </div>
            <!-- </ng-container> -->
            <div *ngIf="dataLoaded && photoCount === photoCounter" class="row justify-content-end marginbottom30">
              <div class="feedback-complete shadow card fluid col-6 align-items-center">
                <div class="card-header">
                  <h3>Information</h3>
                </div>
                <hr />
                <div class="col-12">
                  <div class="card-body">
                    <h4 class="card-title">Alle {{ outputLabel }} durchlaufen!</h4>
                    <ng-container *ngIf="hasFeedback">
                      <ng-container *ngIf="mode !== 'forwardFeedback'">
                        <p class="card-text">
                          Sie haben alle {{ outputLabel }} durchlaufen. Bitte senden Sie
                          <span *ngIf="claim; then die_reklamation; else das_feedback"></span> jetzt ab. Wenn Sie
                          <span *ngIf="claim; then die_reklamation; else das_feedback"></span> noch einmal überarbeiten
                          möchten, klicken Sie auf zurück.
                        </p>
                        <p class="card-text" *ngIf="claim">War es unser Fehler, ist die Änderung für Sie kostenlos.</p>
                        <p class="card-text" *ngIf="subjectToCharge">
                          <strong>Kostenpflichtiges Feedback:</strong><br />Pro Bild, an dem Änderungen vorgenommen
                          werden, berechnen wir {{ suborder.extra_feedback_price }} €.
                        </p>
                      </ng-container>
                      <ng-container *ngIf="mode === 'forwardFeedback'">
                        <p class="card-text" *ngIf="hasFeedback">
                          Sie haben alle Bilder durchlaufen. Das Feedback kann nun per E-Mail an den Dienstleister
                          gesendet werden.
                        </p>
                      </ng-container>
                    </ng-container>
                    <p class="card-text" *ngIf="!hasFeedback">
                      <ng-container *ngIf="!isStandAlone">
                        Keine Rückmeldung gefunden! Bitte gehen Sie zurück und überprüfen Sie, ob Sie das Feedback
                        gespeichert haben oder stornieren Sie es!
                      </ng-container>
                      <ng-container *ngIf="isStandAlone">
                        Keine Rückmeldung gefunden! Bitte gehen Sie zurück und überprüfen Sie, ob Sie das Feedback
                        gespeichert haben!
                      </ng-container>
                    </p>
                    <button class="btn btn-danger clickable mr-2 mt-4" (click)="gotoImage(0, false, true)">
                      Zurück
                    </button>
                    <button
                      *ngIf="hasFeedback"
                      class="btn btn-primary clickable mt-4"
                      [ngClass]="!subjectToCharge ? 'btn-primary' : 'btn-warning'"
                      (click)="finishFeedBack(this.mode)"
                      rel="noopener"
                    >
                      <ng-container *ngIf="claim">Reklamation absenden</ng-container>
                      <ng-container *ngIf="subjectToCharge">Feedback kostenpflichtig absenden</ng-container>
                      <ng-container *ngIf="!claim && !subjectToCharge">Feedback absenden</ng-container>
                    </button>
                    <button
                      *ngIf="!hasFeedback && !isStandAlone"
                      class="btn btn-danger clickable mt-4"
                      (click)="goBack()"
                      rel="noopener"
                    >
                      Feedback abbrechen
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <i
              class="fas fa-chevron-right d-none d-md-block col-md-2 col-xl-1 text-primary vertical-center text-center nav-icon clickable"
              *ngIf="
                hasFoundFiles &&
                dataLoaded &&
                photoCounter < photoCount &&
                display_selector === 'visible' &&
                outputFormat !== 'Video'
              "
              (click)="gotoImage(photoCounter + 1)"
            ></i>
          </div>
          <ng-container *ngIf="loading_selector == true">
            <div class="custom-spinner">
              <div class="spinner-overlay"></div>
              <div class="lds-ring ring-default centered">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-4 align-top">
          <div
            class="col-12 align-self-stretch feedback-action-section"
            [hidden]="photoCounter >= photoCount || display_selector !== 'visible'"
          >
            <!--!dataLoaded || -->
            <div class="card warning shadow col-12" style="padding-top: 10px">
              <div class="row">
                <div class="col-12">
                  <label for="feedback_general" class="text-white white" *ngIf="mode == 'forwardFeedback'"
                    >Feedback des Kunden</label
                  >
                  <div class="input-area col-12">
                    <textarea
                      class="form-control ontop"
                      id="feedback_general"
                      style="margin-bottom: 10px"
                      placeholder="Bspw.: Bitte in allen Räumen Französisches Fischgrätparkett Eiche leicht gebürstet matt lackiert 590x80x11mm Natur"
                      [(ngModel)]="generalComment"
                      [readOnly]="mode === 'forwardFeedback'"
                    >
                    </textarea>
                  </div>
                  <label for="translation_general" class="text-white white" *ngIf="mode == 'forwardFeedback'">
                    <button class="btn btn-primary btn-sm" (click)="translateGenericComment()">
                      Übersetze <i class="fas fa-angle-double-down"></i>
                    </button>
                  </label>
                  <div class="input-area col-12" *ngIf="mode == 'forwardFeedback'">
                    <textarea
                      class="form-control ontop"
                      id="translation_general"
                      style="margin-bottom: 10px"
                      placeholder="Übersetzung / Anmerkungen für den Dienstleister"
                      [(ngModel)]="commentForServiceProvider"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 card shadow">
              <div class="row" style="margin-left: 0px">
                <ng-container *ngIf="hasFoundFiles && outputFormat !== 'Video'">
                  <h4>Kommentar, der für das aktuelle Bild gilt</h4>
                  <label for="feedback_specific" class="text-black black" *ngIf="mode == 'forwardFeedback'"
                    >Feedback des Kunden</label
                  >
                  <div class="input-area col-12">
                    <textarea
                      class="form-control col-12"
                      id="feedback_specific"
                      placeholder="Bspw. Bitte im Wohnzimmer Fischgrätparkett Eiche leicht gebürstet matt lackiert 590x80x11mm Natur (Siehe angehangenes Bild)"
                      [(ngModel)]="feedbackComment"
                      [readOnly]="mode === 'forwardFeedback'"
                    >
                    </textarea>
                  </div>
                  <label for="translation_specific" class="text-black black" *ngIf="mode == 'forwardFeedback'">
                    <button class="btn btn-primary btn-sm" (click)="translateFileComment(feedbackComment)">
                      Übersetze <i class="fas fa-angle-double-down"></i>
                    </button>
                  </label>
                  <div class="input-area col-12" *ngIf="mode == 'forwardFeedback'">
                    <textarea
                      class="form-control col-12"
                      id="translation_specific"
                      placeholder="Übersetzung"
                      [(ngModel)]="feedbackCommentForServiceProvider"
                    >
                    </textarea>
                  </div>
                  <div class="col-12">
                    <ol class="col-12 mt-2">
                      <li *ngFor="let item of counterMarkerComments; let i = index; trackBy: trackByIdx">
                        <input
                          type="text"
                          placeholder="Markerkommentar"
                          class="form-control col-12"
                          [(ngModel)]="counterMarkerComments[i]"
                          [readOnly]="mode === 'forwardFeedback'"
                        />
                        <label
                          for="translate-counter-marker-comment-{{ i }}"
                          class="text-black black mb-0"
                          *ngIf="mode == 'forwardFeedback'"
                        >
                          <button class="btn btn-primary btn-sm" (click)="translateCounterMarkerComment(i)">
                            Übersetze <i class="fas fa-angle-double-down"></i></button
                        ></label>
                        <input
                          *ngIf="mode == 'forwardFeedback'"
                          id="translate-counter-marker-comment-{{ i }}"
                          type="text"
                          placeholder="Markerkommentar des Dienstleisters"
                          class="form-control col-12"
                          [(ngModel)]="adminCounterMarkerComments[i]"
                        />
                      </li>
                    </ol>
                  </div>
                </ng-container>
                <div class="col-12">
                  <button
                    class="btn btn-danger"
                    href=""
                    *ngIf="outputFormat !== 'Video' && hasFoundFiles"
                    (click)="resetFeedback($event)"
                    rel="noopener"
                  >
                    Feedback zurücksetzen
                  </button>
                  <button class="btn btn-primary mr-2" href="" (click)="addToFeedbacks(suborderID)" rel="noopener">
                    <ng-container *ngIf="photoCounter + 1 < photoCount"
                      >Speichern & nächstes Bild ({{ photoCounter + 1 }}/{{ photoCount }})</ng-container
                    >
                    <ng-container *ngIf="photoCounter + 1 == photoCount"
                      >Speichern & fertigstellen
                      <ng-container *ngIf="outputFormat !== 'Video' && hasFoundFiles"
                        >({{ photoCounter + 1 }}/{{ photoCount }})
                      </ng-container>
                    </ng-container>
                  </button>
                </div>
              </div>
              <hr />
              <h5 class="mb-2 col-12 font-weight-light">Upload Beispielmaterial (JPEG, PNG, PDF, PPTX)</h5>
              <app-imo-file-upload
                #fileUploadComponent
                [allowedExtn]="['jpeg', 'jpg', 'png', 'pdf', 'pptx']"
                [parentType]="'suborders'"
                [id]="suborder.id"
                [folderName]="outputFolder"
                [small]="true"
                [required]="false"
                [showUploadedFiles]="true"
                [showFileUploadComment]="true"
                [subOrder]="suborder"
              >
              </app-imo-file-upload>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #die_reklamation> die Reklamation </ng-template>
<ng-template #das_feedback> das Feedback </ng-template>
<ng-template #loading>
  <div class="lds-ring ring-default centered">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-template>
<ngx-smart-modal
  #unsavedChanges
  identifier="unsavedChanges"
  [closable]="false"
  [escapable]="false"
  [dismissable]="false"
>
  <h3>Ungespeicherte Änderungen</h3>
  <p>
    Das Feedback für das aktuelle Bild wurde noch nicht gesepeichert. Sie können das Feedback speichern und das nächste
    Bild bearbeiten oder Ihr Feedback zurücksetzen.
  </p>
  <button class="btn btn-primary" (click)="closeUnsavedChangeModal()">OK</button>
</ngx-smart-modal>
