import { Component, OnInit, ViewChild } from "@angular/core";
import { AccountingPositionsLogicService } from "@app/_services/accounting-positions-logic.service";
import { MatTableDataSource } from "@angular/material/table";
import { LoaderService } from "@app/_services/loader.service";
import { AuthService } from "@app/auth/auth.service";
import { AccountingPosition } from "@app/interfaces/accounting-position.interface";
import { NgxSmartModalService } from "ngx-smart-modal";
import { FormControl } from "@angular/forms";
import { Company } from "@app/interfaces/company.interface";
import { CompaniesLogicService } from "@app/_services/companies-logic.service";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { translateAppListStrings, updateInTableDataSource } from "@app/util/helper";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { UserRoleEnum } from "@app/models/user-role-list";
import { PriceDetailModalComponent } from "./price-detail-modal/price-detail-modal.component";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: "app-accounting-positions",
  templateUrl: "./accounting-positions.component.html",
  styleUrls: ["./accounting-positions.component.css"],
})
export class AccountingPositionsComponent implements OnInit {
  private readonly logComponent = "AccountingPositionsComponent :: ";
  UserRoleEnum = UserRoleEnum;
  selectdRows: boolean = false;
  columnsToDisplay = ["name", "price", "ordertype", "action"];
  companyList: Company[] = [];
  accountingPositionsDataSrc: MatTableDataSource<AccountingPosition> = new MatTableDataSource<AccountingPosition>();
  item = 1;
  editIndex = -1;
  public productFilterCtrl: FormControl = new FormControl();
  tempAccountingPosition: AccountingPosition = {
    id: "",
    name: "",
    ordertype: "",
    price: 0,
  };
  productList = [
    {
      label: "Drohnenaufnahmen",
      value: "dronemedia",
    },
    {
      label: "Etagenübersicht",
      value: "floor_overview",
    },
    {
      label: "Grundrisse",
      value: "floorplan",
    },
    {
      label: "HD Fotos",
      value: "hdphotos",
    },
    {
      label: "Bildbearbeitung",
      value: "retouching",
    },
    {
      label: "3D-Visualisierung",
      value: "visualisation",
    },
    {
      label: "Digitales Staging",
      value: "vstaging",
    },
    {
      label: "Dokumentenbeschaffung",
      value: "documents_procurement",
    },
    {
      label: "Virtuelle Tour",
      value: "virtual_tour",
    },
    {
      label: "Videotour",
      value: "video_tour",
    },
    {
      label: translateAppListStrings("order_type_list", OrderTypeEnum.Area_Calculation),
      value: OrderTypeEnum.Area_Calculation,
    },
  ];
  filterValues = {};
  accountingPositionsCount: number;
  keyValueQueryMap = new Map();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("paginator") paginator: MatPaginator;

  constructor(
    public auth: AuthService,
    public apls: AccountingPositionsLogicService,
    public cls: CompaniesLogicService,
    public loader: LoaderService,
    private dialog: MatDialog,
    public ngxSmartModalService: NgxSmartModalService
  ) {}

  ngOnInit() {
    this.companyList = this.cls.companiesList;
    this.loadAccountingPositions();
  }

  onProductFilter(event): void {
    this.filterValues["ordertype"] = event["value"];
    if (this.keyValueQueryMap.has("ordertype")) {
      this.keyValueQueryMap.delete("ordertype");
    }
    if (event?.value) {
      this.keyValueQueryMap.set("ordertype", event.value);
    }
    this.paginator.pageIndex = 0;
    this.loadAccountingPositions();
  }

  onEditClick(event, index) {
    this.setTempAccountingPosition(event);
    this.editIndex = index;
  }

  noSort(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onCancelClick() {
    this.editIndex = -1;
    this.setTempAccountingPosition();
  }

  async onSaveClick() {
    this.editIndex = -1;
    await this.apls.updateAccountingPositions([this.tempAccountingPosition]);
    this.updateDataSourceAndRefresh();
    this.setTempAccountingPosition();
  }

  updateDataSourceAndRefresh() {
    updateInTableDataSource(
      this.accountingPositionsDataSrc,
      this.tempAccountingPosition.id,
      this.tempAccountingPosition
    );
    this.setAccountingDataSrc(this.accountingPositionsDataSrc.data); // setting accounting data source again in order to refresh its values, since we are making changes to its inner data above.
  }

  openEditModal(row, i) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "120vh";
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      companiesList: this.companyList,
      tempAccountingPosition: row,
    };
    const dialogRef = this.dialog.open(PriceDetailModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async (val) => {
      if (val.cancel) {
      } else {
        this.tempAccountingPosition = val.tempAccountingPosition;
        await this.onSaveClick();
      }
    });
  }

  searchAccountingData() {
    let value = this.productFilterCtrl.value.toLowerCase().trim();
    if (this.keyValueQueryMap.has("name")) {
      this.keyValueQueryMap.delete("name");
    }
    if (value) {
      this.keyValueQueryMap.set("name", value);
    }
    this.paginator.pageIndex = 0;
    this.loadAccountingPositions();
  }

  setTempAccountingPosition(accountingPosition?: AccountingPosition) {
    if (!accountingPosition) {
      this.tempAccountingPosition = {
        id: "",
        name: "",
        ordertype: "",
        price: 0,
      };
    } else {
      this.tempAccountingPosition = {
        id: accountingPosition.id,
        name: accountingPosition.name,
        ordertype: accountingPosition.ordertype,
        price: accountingPosition.price,
      };
      if (accountingPosition.specialConditions) {
        this.tempAccountingPosition.specialConditions = accountingPosition.specialConditions.map((x) => {
          x.companyName = this.companyList.find((y) => y.cid === x.companyId)?.name;
          return x;
        });
      }
    }
  }

  loadAccountingPositions(): void {
    this.apls
      .getAllAccountingPositionsWithPaginator(
        this.paginator?.pageIndex,
        this.paginator?.pageSize,
        this.sort?.active,
        this.sort?.direction,
        this.keyValueQueryMap
      )
      .subscribe((apsList) => {
        this.setAccountingDataSrc(apsList.data);
        this.accountingPositionsCount = apsList.total_hits;
      });
  }

  setAccountingDataSrc(data: AccountingPosition[]) {
    this.accountingPositionsDataSrc = new MatTableDataSource<AccountingPosition>(data);
  }
}
