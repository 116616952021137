import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { disable } from "@rxweb/reactive-form-validators";

@Component({
  selector: "app-imo-consent-banner",
  templateUrl: "./imo-consent-banner.component.html",
  styleUrls: ["./imo-consent-banner.component.css"],
})
export class ImoConsentBannerComponent implements OnInit {
  consentForm: FormGroup;
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<ImoConsentBannerComponent>) {}

  ngOnInit(): void {
    this.consentForm = this.formBuilder.group({
      sentryConsent: [false],
      essentialConsent: [{ value: true, disabled: true }],
    });
  }

  get sentryConsent() {
    return <FormControl>this.consentForm.get("sentryConsent");
  }

  get essentialConsent() {
    return <FormControl>this.consentForm.get("essentialConsent");
  }

  onSelectedConsentSave() {
    this.dialogRef.close(this.sentryConsent.value);
  }
}
